import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { NavToOptions, useNavigateTo } from "../../_common/types/router";
import tmSlice from "../Slice";
import ThreatMonitoringAPI, {
  GetResultsV1ReqBody,
} from "../api/threatmonitoring.api";

export function useNavToThreatDetails(opts: NavToOptions = {}) {
  const dispatch = useAppDispatch();
  const navTo = useNavigateTo();

  return (uuid: string, startWithCommentsOpen?: boolean) => () => {
    let url = `/breachrisk/threatmonitoring/threat/${uuid}`;

    if (startWithCommentsOpen) {
      url = `${url}?open_comments=true`;
    }

    dispatch(tmSlice.actions.clearSessionSnapshot());

    navTo(url, opts);
  };
}

// useNavigateToDetailsWithSession returns a function which can be used to start a threat assessment session for the feedQuery.
// Starting a session means snapshotting the current page results and total results, and navigating to the threat detail view.
export function useNavigateToDetailsWithSession(
  query: GetResultsV1ReqBody,
  opts: NavToOptions = {}
) {
  const dispatch = useAppDispatch();
  const navTo = useNavigateTo({}, opts);

  const [fetchSessionUUIDs] =
    ThreatMonitoringAPI.useLazyGetResultUUIDsV1Query();

  return async function (uuid: string) {
    try {
      const resp = await fetchSessionUUIDs(query).unwrap();

      dispatch(
        tmSlice.actions.snapshotSession({
          uuids: resp.uuids,
          query,
        })
      );
      navTo(`/breachrisk/threatmonitoring/threat/${uuid}`);
    } catch (e) {
      console.error(e);
      dispatch(tmSlice.actions.clearSessionSnapshot());
      dispatch(addDefaultUnknownErrorAlert("Failed to load threats"));
    }
  };
}

export function useNavToRemediationRequest(opts: NavToOptions = {}) {
  const navTo = useNavigateTo({}, opts);

  return (remediationRequestID: number) => () =>
    navTo(`/selfremediation/${remediationRequestID}`);
}

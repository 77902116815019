import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { LogError } from "../../_common/helpers";
import { useCallback, useEffect, useState } from "react";
import { Location } from "history";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import SurveyViewer, {
  SurveyViewerType,
} from "../../survey_viewer/components/SurveyViewer";
import {
  fetchQuestionnaire,
  QuestionnaireAndAnswers,
} from "../../vendorrisk/reducers/questionnaireAnswers.actions";
import { SurveyLockState } from "../../survey_viewer/reducers/apiActions";
import ReportCard from "../../_common/components/ReportCard";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import CircleExclaimSvg from "../../vendorrisk/images/circle_exclaim.svg";
import Button from "../../_common/components/core/Button";
import { clearSurveyData } from "../../_common/reducers/commonActions";
import { fetchSurveyDetails } from "../../_common/reducers/surveyDetails.actions";
import { SurveyRiskVisibility } from "../../_common/types/survey";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  useBasicPermissions,
  UserWriteSurveyImportExport,
} from "../../_common/permissions";
import { GetQueryParams } from "../../_common/query";
import { SurveyUsageType } from "../../_common/types/surveyTypes";

import {
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
  vendorRiskTaskUrlPrefix,
} from "../../_common/helpers/navigation.helpers";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../verifiedvendors/api/trustpage.api";
import { fetchVendorAppRemediationRequestList } from "../reducers/remediationRequests.actions";

interface VendorQuestionnaireAnswersOwnProps
  extends DefaultRouteProps<{
    id: string;
  }> {
  location: Location<locationState>;
}

const VendorQuestionnaireAnswers = (
  props: VendorQuestionnaireAnswersOwnProps
) => {
  const dispatch = useAppDispatch();
  const perms = useBasicPermissions();

  const [questionnaire, setQuestionnaire] = useState<
    QuestionnaireAndAnswers | undefined
  >(undefined);
  const [lockState, setLockState] = useState<SurveyLockState | undefined>(
    undefined
  );

  const sId = parseInt(props.match.params.id, 10);
  const params = GetQueryParams(props.location.search);

  // On initial load
  useEffect(() => {
    // Get latest structure for questionnaire
    dispatch(fetchQuestionnaire(sId, params.editable, params.version, false))
      .then((s) => {
        setQuestionnaire(s);
        setLockState({
          isLocked: s.locked,
          lockedBy: s.lockedBy,
        });
      })
      .catch((e) => {
        LogError(e);
        dispatch(
          addDefaultUnknownErrorAlert("Error loading questionnaire answers")
        );
      });

    // Get details
    dispatch(fetchSurveyDetails(sId));
  }, []);

  const close = useCallback(
    (addedToSharedProfile?: boolean) => {
      if (addedToSharedProfile === true) {
        dispatch(
          TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
        );
        props.history.push("/trustpage", {
          noRemoveWhispers: true,
        });
        return;
      }

      let routePrefix = `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires`;
      if (questionnaire?.usageType === SurveyUsageType.Relationship) {
        routePrefix = `/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/questionnaires`;
      }

      if (props.location.state?.backContext?.goBack) {
        props.history.goBack();
      } else {
        props.history.push(
          props.location.state?.backContext?.backTo ?? `${routePrefix}/${sId}/`,
          {
            noRemoveWhispers: true,
          }
        );
      }
    },
    [props.history, props.location, sId, questionnaire]
  );

  useEffect(() => {
    const unblock = props.history.block((_location, _action) => {
      // If we move away from this page, refetch a couple of things

      // Re-fetch survey details by clearing it from state
      dispatch(clearSurveyData(sId));

      // Re-fetch any remediation requests (no need to wait for this either)
      dispatch(fetchVendorAppRemediationRequestList(true));
    });
    return unblock;
  }, [props.history, dispatch, sId]);

  if (questionnaire?.status === "CANCELLED") {
    return (
      <ReportCard newStyles>
        <EmptyCardWithAction
          iconSrc={CircleExclaimSvg}
          emptyText={"Questionnaire cancelled"}
          emptySubText={
            "This questionnaire has been cancelled by the sending organization."
          }
          actionButtonRenderOverride={
            <Button leftArrow onClick={() => close()}>
              Go back
            </Button>
          }
        />
      </ReportCard>
    );
  }

  const readOnlyMode =
    questionnaire?.isArchived ||
    params.version ||
    questionnaire?.editLocked ||
    // If this is an imported survey, we require the user to have the WriteSurveyImportExport permission.
    (questionnaire?.surveyImportUUID &&
      !perms.userPermissions[UserWriteSurveyImportExport]);

  return (
    <SurveyViewer
      viewerType={
        readOnlyMode ? SurveyViewerType.Viewing : SurveyViewerType.Editing
      }
      surveyId={sId}
      initialAnswers={questionnaire?.answers}
      vsaqQuestionStructure={questionnaire?.structure[0]}
      initialQuestionId={params.scroll_to}
      onClose={close}
      includeToc={questionnaire?.includeTOC ?? false}
      usageType={questionnaire?.usageType}
      userMessage={questionnaire?.userMessage}
      hasRequiredAttachments={questionnaire?.hasRequiredAttachments}
      initialLockState={lockState}
      orgName={questionnaire?.issuingOrgName}
      logoUrl={questionnaire?.logoUrl}
      hasCommentWritePermission={true}
      isArchived={questionnaire?.isArchived}
      vendorName={questionnaire?.vendorName}
      initiallyShowComments={params.show_comments === "true"}
      isVendorPortal={true}
      surveyLatestVersion={
        questionnaire && questionnaire.versions.length > 0
          ? questionnaire.versions[0]
          : undefined
      }
      riskVisibility={
        questionnaire?.riskVisibility ?? SurveyRiskVisibility.Hidden
      }
      alwaysVisibleRiskIDs={questionnaire?.alwaysVisibleRiskIDs}
      surveyImportUUID={questionnaire?.surveyImportUUID}
      managedAssessmentId={questionnaire?.managedAssessmentId}
      surveyStatus={questionnaire?.surveyStatus}
      isSystemSurvey={questionnaire?.isSystemSurvey}
      isGapSurvey={questionnaire?.isGapSurvey}
    />
  );
};

export default VendorQuestionnaireAnswers;

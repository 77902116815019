import { ISharedAssessment } from "../../vendorrisk/types/sharedAssessment";
import "../style/ManageTrustPageContentView.scss";

import TrustPageAboutCard from "../components/TrustPageAboutCard";
import ComplianceBadgesCard from "../components/ComplianceBadgesCard";
import TrustPageEditableCard from "../components/TrustPageEditableCard";
import IconButton from "../../_common/components/IconButton";
import {
  mapContentLibraryDocumentToIDocumentRecord,
  mapEvidenceToISecurityLink,
} from "../helpers/types";
import SecurityLinksCard from "../components/SecurityLinksCard";
import DocumentationTable from "../components/DocumentationTable";
import { useModalV2 } from "../../_common/components/ModalV2";
import EditSecurityLinksModal from "../components/modals/EditSecurityLinksModal";
import { useAddDocumentToSharedProfile } from "../hooks/useAddDocumentToSharedProfile";
import { ReactNode } from "react";
import { surveyType } from "../../_common/types/surveyTypes";
import QuestionnairesCard from "../components/QuestionnairesCard";
import { ISurveyListItemResponse } from "../../_common/types/survey";

interface IManageTrustPageContentViewProps {
  sharedAssessment: ISharedAssessment;
  surveyTypes: surveyType[];
  receivedSurveys: ISurveyListItemResponse[];
  userHasWritePrefilledSurveysPermission: boolean;
}

const ManageTrustPageContentView = ({
  sharedAssessment,
  surveyTypes,
  receivedSurveys,
  userHasWritePrefilledSurveysPermission,
}: IManageTrustPageContentViewProps) => {
  const [openEditSecurityLinksModal, editSecurityLinksModal] = useModalV2(
    EditSecurityLinksModal
  );

  const addDocument = useAddDocumentToSharedProfile();
  const vendorId = sharedAssessment.linkedVendor.id;

  const securityLinksCardElement: ReactNode = (
    <SecurityLinksCard
      className={"security-links-card"}
      securityLinks={
        sharedAssessment.evidencePages?.map((e) =>
          mapEvidenceToISecurityLink(e)
        ) ?? []
      }
      userCanMakeChanges={userHasWritePrefilledSurveysPermission}
      onUpdateSecurityLinks={() => {
        openEditSecurityLinksModal({
          evidencePages: sharedAssessment.evidencePages ?? [],
        });
      }}
    />
  );

  return (
    <>
      {editSecurityLinksModal}
      <div className="trust-page-content-wrapper">
        <>
          <div className="left-col">
            <TrustPageAboutCard
              {...sharedAssessment}
              companyName={sharedAssessment.linkedVendor.name}
              currentScore={
                sharedAssessment.currentPublicScore ??
                sharedAssessment.currentScore
              }
              industryAvg={sharedAssessment.industryAverage?.score}
              userCanMakeChanges={userHasWritePrefilledSurveysPermission}
            />

            {securityLinksCardElement}
          </div>
          <div className="right-col">
            <ComplianceBadgesCard
              badges={sharedAssessment.badges}
              userCanMakeChanges={userHasWritePrefilledSurveysPermission}
            />

            <QuestionnairesCard
              vendorId={vendorId}
              sharedAssessment={sharedAssessment}
              surveyTypes={surveyTypes}
              receivedSurveys={receivedSurveys ?? []}
              isOwnSharedProfile={true}
            />

            <TrustPageEditableCard
              className={"documents-card"}
              header={"Documents"}
              editButton={
                userHasWritePrefilledSurveysPermission ? (
                  <IconButton
                    icon={<div className="cr-icon-plus" />}
                    onClick={() => addDocument()}
                  />
                ) : null
              }
            >
              <DocumentationTable
                vendorId={vendorId}
                documents={sharedAssessment.contentLibraryDocuments.map((doc) =>
                  mapContentLibraryDocumentToIDocumentRecord(doc, true)
                )}
                userCanMakeChanges={userHasWritePrefilledSurveysPermission}
                userHasAccess={true}
              />
            </TrustPageEditableCard>
          </div>
        </>
      </div>
    </>
  );
};

export default ManageTrustPageContentView;

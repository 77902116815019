import { FC, useCallback, useEffect, useState } from "react";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import Button from "../../_common/components/core/Button";
import DragDropUpload from "../../_common/components/DragDropUpload";
import TextField, {
  useTextWithValid,
} from "../../_common/components/TextField";
import DatePicker from "../../_common/components/DatePicker";
import moment from "moment/moment";
import "../style/ImportQuestionnaireModal.scss";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { chatGPTAccessSelector } from "../../_common/chatgpt";
import SurveyImportAPI from "../api/surveyImportAPI";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { trimFileExtension } from "../../_common/helpers/string.helpers";
import { trackEvent } from "../../_common/tracking";
import ImportQuestionnaireModalConfigureStep from "./ImportQuestionnaireModalConfigureStep";

export const ImportQuestionnaireAcceptedFileTypes = [
  ".xlsx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

interface IImportQuestionnaireModalProps extends BaseModalProps {
  file?: File;
}

const ImportQuestionnaireModal: FC<IImportQuestionnaireModalProps> = ({
  active,
  onClose,
  file,
}) => {
  const dispatch = useAppDispatch();
  const { shouldDisplayChatGPT } = useAppSelector(chatGPTAccessSelector);

  const [currentStep, setCurrentStep] = useState<"upload" | "configure">(
    "upload"
  );
  const [surveyImportUUID, setSurveyImportUUID] = useState<string | undefined>(
    undefined
  );

  const [selectedFile, setSelectedFile] = useState(file);
  const [
    documentName,
    documentNameValid,
    documentNameOnChanged,
    documentNameHasChanged,
  ] = useTextWithValid();
  const [fromCompany, fromCompanyValid, fromCompanyOnChanged] =
    useTextWithValid();
  const [dueDate, setDueDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedFile && !documentNameHasChanged) {
      const filename = trimFileExtension(selectedFile.name);
      documentNameOnChanged(filename, filename.length >= 2);
    }
  }, [selectedFile, documentNameHasChanged, documentNameOnChanged]);

  const [createImportedSurveyStep1] =
    SurveyImportAPI.useCreateImportedSurveyStep1Mutation();

  const [submitDocumentLoading, setSubmitDocumentLoading] = useState(false);
  const onSubmitDocumentUpload = useCallback(
    async (acceptedTerms: boolean) => {
      if (!selectedFile) {
        return;
      }

      setSubmitDocumentLoading(true);
      try {
        const { surveyImportUUID } = await createImportedSurveyStep1({
          file: selectedFile,
          aiTermsAccepted: acceptedTerms,
        }).unwrap();
        setSubmitDocumentLoading(false);
        setSurveyImportUUID(surveyImportUUID);
        setCurrentStep("configure");
      } catch (e) {
        setSubmitDocumentLoading(false);
        throw e;
      }
    },
    [createImportedSurveyStep1, selectedFile]
  );

  const onSubmitUploadStep = useCallback(async () => {
    if (!selectedFile) {
      return;
    }

    try {
      await onSubmitDocumentUpload(shouldDisplayChatGPT);
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert("Error submitting document for processing")
      );
    }
  }, [dispatch, shouldDisplayChatGPT, selectedFile, onSubmitDocumentUpload]);

  const onCloseTrackEvent = () => {
    trackEvent("QuestionnaireImportExport_ImportModalClosed");
    onClose();
  };

  return (
    <>
      <ModalV2
        active={active && currentStep === "upload"}
        className="import-questionnaire-modal-upload"
        headerContent="Import questionnaire"
        subHeaderContent="Answer imported questionnaires using UpGuard's autofill tools and export them in the same format they were uploaded in."
        headerClassName="import-questionnaire-modal-header"
        onClose={onCloseTrackEvent}
        footerContent={
          <>
            <Button tertiary onClick={onCloseTrackEvent}>
              Cancel
            </Button>
            <Button
              primary
              loading={submitDocumentLoading}
              disabled={
                !documentNameValid || !fromCompanyValid || !selectedFile
              }
              onClick={onSubmitUploadStep}
            >
              Next
            </Button>
          </>
        }
      >
        <>
          <DragDropUpload
            acceptedFileTypeFilters={ImportQuestionnaireAcceptedFileTypes}
            onFileSelected={setSelectedFile}
            selectedFile={selectedFile}
            additionalText=".xlsx files accepted only"
          />

          <label htmlFor="name">Name</label>
          <TextField
            name="name"
            value={documentName}
            onChanged={documentNameOnChanged}
            placeholder="Questionnaire name"
            required
            minLength={2}
            maxLength={500}
          />

          <label htmlFor="requestedby">Requested by</label>
          <TextField
            name="requestedby"
            value={fromCompany}
            onChanged={fromCompanyOnChanged}
            placeholder="Company name"
            required
            minLength={2}
            maxLength={500}
          />

          <label htmlFor="duedate">Due date (optional)</label>
          <DatePicker
            name="duedate"
            min={moment().format("YYYY-MM-DD")}
            placeholder="Select due date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            canClear
            showCalendarIcon
          />
        </>
      </ModalV2>
      <ImportQuestionnaireModalConfigureStep
        active={active && currentStep === "configure"}
        onClose={onCloseTrackEvent}
        surveyImportUUID={surveyImportUUID}
        surveyName={documentName}
        fromCompany={fromCompany}
        dueDate={dueDate}
      />
    </>
  );
};

export default ImportQuestionnaireModal;

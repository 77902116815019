import { match, useRouteMatch } from "react-router-dom";

export function useDecodedRouteMatch<
  Params extends { [K in keyof Params]?: string } = Record<string, never>,
>(): match<Params> {
  const match = useRouteMatch<Params>();

  const decodedParams = Object.fromEntries(
    Object.entries(match.params).map(([key, value]) => [
      key,
      value ? decodeURIComponent(value as string) : value,
    ])
  ) as Params;

  return {
    ...match,
    params: decodedParams,
  };
}

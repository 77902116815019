import { FC } from "react";

import "../style/components/TextFieldWithActions.scss";
import TextField, { ITextEditProps } from "./TextField";
import classnames from "classnames";
import Button from "./core/Button";
import LoadingIcon from "./core/LoadingIcon";
import DismissablePopup, { PopupPosition } from "./DismissablePopup";

export interface IActionButtonProps {
  // the optional icon to display in the button
  actionIcon?: string;

  // the optional text to display in the button
  actionText?: string;

  // callback for clicks on the button
  onClick?: () => void;

  // popup message for the action
  popupMessage?: string;

  // classname for the button popup component
  popupClassName?: string;

  // optional width of the button popup
  popupWidth?: number;

  // the position for the popup if any. Defaults to top
  popupPosition?: PopupPosition;

  // toggle to disable the button
  disabled?: boolean;

  // flag to indicate the action is in progress. It will show the button as loading spinner
  isExecutingAction?: boolean;

  // class to assign to the outer element, for popup wrapping the entire component
  outerClassName?: string;

  // class to assign to the inner button
  innerClassName?: string;

  // Automatically dismiss after this, in ms. If explicitly set to 0, popup will start dismissed.
  autoDismissAfter?: number;
}

const ActionButton: FC<IActionButtonProps> = ({
  actionIcon,
  actionText,
  outerClassName,
  innerClassName,
  onClick,
  popupMessage,
  popupClassName,
  popupWidth,
  popupPosition = "top",
  disabled,
  isExecutingAction,
  autoDismissAfter,
}) => {
  if (!actionIcon && !actionText) return <></>;

  const innerDisabled = !onClick || disabled;

  return (
    <DismissablePopup
      text={popupMessage}
      position={popupPosition}
      popupHoverable={true}
      className={classnames("action-button", outerClassName, {
        disabled: innerDisabled,
      })}
      popupClassName={popupClassName}
      width={popupWidth}
      popupOnHoverToo
      autoDismissAfter={autoDismissAfter}
    >
      <Button
        tertiary
        onClick={!isExecutingAction ? onClick : undefined}
        disabled={innerDisabled}
        className={classnames(innerClassName)}
      >
        <LoadingIcon
          size={12}
          className={classnames("executing-action", {
            hidden: !isExecutingAction,
          })}
        />
        {!!actionIcon && (
          <div
            className={classnames(actionIcon, {
              hidden: isExecutingAction,
              "with-text": !!actionText,
            })}
          ></div>
        )}
        {!!actionText && (
          <span className={classnames({ hidden: isExecutingAction })}>
            {actionText}
          </span>
        )}
      </Button>
    </DismissablePopup>
  );
};

export interface ITextFieldWithActionProps
  extends Omit<ITextEditProps, "className"> {
  // class for the div wrapping the text field
  outerClassName?: string;

  // class for the inner text field
  innerClassName?: string;

  // action buttons to display
  // below the text field
  actionButtons?: IActionButtonProps[];
}

const TextFieldWithActions: FC<ITextFieldWithActionProps> = ({
  outerClassName,
  innerClassName,
  actionButtons,
  ...otherProps
}) => {
  // make sure we set the classname for the inner
  // text field if we were provided one
  const textFieldProps: ITextEditProps = otherProps;
  textFieldProps.className = classnames(innerClassName, {
    "with-buttons": !!actionButtons && actionButtons.length > 0,
  });
  textFieldProps.hideMessageTexts = true;

  return (
    <div className={classnames("text-field-with-action", outerClassName)}>
      <TextField {...textFieldProps} />
      {!!actionButtons && actionButtons.length > 0 && (
        <div
          className={classnames("actions", { multiline: otherProps.multiLine })}
        >
          {(actionButtons ?? []).map((actionButton, idx) => {
            return <ActionButton key={idx} {...actionButton} />;
          })}
        </div>
      )}
    </div>
  );
};

export default TextFieldWithActions;

import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import styles from "./CopyTextDisplay.module.scss";
import DismissablePopup, { DismissablePopupHandle } from "../DismissablePopup";
import { useDebounce } from "../../hooks";

interface CopyTextDisplayProps {
  value: string;
  disabled?: boolean;
}

const CopyTextDisplay: React.FC<CopyTextDisplayProps> = ({
  value,
  disabled = false,
}: CopyTextDisplayProps) => {
  // Keep a ref to the popup so we can control it
  const popupRef = useRef<DismissablePopupHandle | null>(null);

  // Handle user clicking copy button
  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(value);
    popupRef.current?.setDismissed(false);
    debouncedHide();
  }, [value, disabled]);

  // Callback to hide the 'copied' popup
  const debouncedHide = useDebounce(
    () => {
      popupRef.current?.setDismissed(true);
    },
    1000,
    [popupRef]
  );

  return (
    <div
      className={classNames(styles.copyTextDisplay, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.textField}>{value}</div>
      <DismissablePopup
        ref={popupRef}
        text={"Copied!"}
        micro
        initDismissed={true}
        autoDismissAfter={0}
        noWrap
      >
        <button
          type="button"
          className={styles.copyButton}
          onClick={!disabled ? handleCopy : undefined}
          disabled={disabled}
          aria-label="Copy text"
        >
          <i className="cr-icon-copy" aria-hidden="true" />
        </button>
      </DismissablePopup>
    </div>
  );
};

export default CopyTextDisplay;

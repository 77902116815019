import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import StepsWithSections, {
  IStep,
  Steps,
} from "../../_common/components/StepsWithSections";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
  IVendorSummary,
} from "../../_common/types/redux";
import { History, Location } from "history";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { InfoBar } from "../../_common/components/InfoBar";
import { DefaultRootState } from "react-redux";
import ActionBar from "../../_common/components/ActionBar";
import Button from "../../_common/components/core/Button";
import {
  AdditionalEvidenceInRemediation,
  CloudscanInRemediation,
  RemediationRequest,
  RemediationRequestStatus,
} from "../../_common/types/remediation";
import { ISurveyMini } from "../../_common/types/survey";
import { RiskDetail } from "../../_common/types/vendor";
import ReportCard from "../../_common/components/ReportCard";
import {
  createVendorContact,
  fetchCloudscanByHostname,
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
  fetchVulnRiskIDsByCVE,
} from "../reducers/cyberRiskActions";
import { get as _get, parseInt } from "lodash";

import "../style/views/RequestRemediationV2.scss";
import { RiskSource, RiskStatus } from "../../_common/types/risks";
import SelectVendorCard from "../components/SelectVendorCard";
import {
  existingUser,
  getBlankNewContact,
  newContact,
} from "../components/ContactSelector";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import {
  hasOrgPermission,
  OrgAccessAdditionalEvidenceRiskRemediation,
  OrgAccessSurveys,
  OrgQuestionnaireScoresInclude,
  usePermissions,
  UserSystemRoleVendorManagementAnalyst,
} from "../../_common/permissions";
import {
  getCurrentOrgFromUserData,
  validateEmail,
  vendorUrlPrefix,
} from "../../_common/helpers";
import { trackEvent } from "../../_common/tracking";
import {
  createNewRemediationRequest,
  fetchProjectionForNewRemediationRequest,
  fetchRemediationRequestListForAllSubsidiaries,
  fetchRemediationRequestListForAllVendors,
  fetchRemediationRequestListForSelf,
  fetchRemediationRequestListForVendor,
  fetchSurveysRemediationMini,
  IAdditionalEvidenceCheckToAdd,
  IAdditionalEvidenceDocumentToAdd,
  ICloudscanCheckAndWebsite,
  ISurveyCheckToAdd,
} from "../reducers/remediation.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { IsCPERisk } from "../components/CVEMiniList";
import { shareSurveyWithUser } from "../../_common/reducers/surveyDetails.actions";
import { VulnRiskIDsByCVE } from "../types/vulns";
import { WebscanResultResult } from "../types/webscans";
import ScoreProjectionRequestRemediation from "../../_common/components/ScoreProjectionRequestRemediation";
import SubsidiarySelector from "../components/SubsidiarySelector";
import {
  reminderSelectionType,
  useSchedule,
} from "../../_common/components/remediationDetails/UpdateDueDateModal";
import moment from "moment";
import { handleKnownErrors } from "../reducers/errors.actions";
import {
  editRemediationRequestRisks,
  fetchRemediationRequestDetails,
  fetchRemediationRequestDetailsForVendorAssessment,
  fetchRemediationRequestTimeline,
  fetchRemediationRequestUsers,
  getRemediationRequestDetailsForVendorAssessmentFromState,
  IRemediationRequestBaseRequest,
  updateRemediationRequestDraft,
} from "../../_common/reducers/remediationRequest.actions";
import PageHeader from "../../_common/components/PageHeader";
import {
  crumb,
  getSubsidiaryPageBreadcrumbs,
  getVendorPageBreadcrumbs,
} from "../../_common/components/Breadcrumbs";
import InfoBanner, { BannerType } from "../components/InfoBanner";
import { AssuranceType } from "../../_common/types/organisations";
import { fetchCustomerSummaryAndCloudscans } from "../reducers/customer.actions";
import { fetchCustomerAcceptedRisks } from "../reducers/customerAcceptedRisks.actions";
import { fetchVendorRiskWaivers } from "../reducers/vendorRiskWaiver.actions";
import {
  ISurveyCheckReducer,
  SurveyCheckReducer,
} from "./remediation_request/survey.reducer";
import {
  CloudscanCheckAndWebsiteReducer,
  ICloudscanCheckAndWebsiteReducer,
} from "./remediation_request/cloudscan.reducer";
import {
  AdditionalEvidenceCheckReducer,
  AdditionalEvidenceDocumentReducer,
  IAdditionalEvidenceCheckReducer,
  IAdditionalEvidenceDocumentReducer,
} from "./remediation_request/additional_evidence.reducer";
import { ConnectedSelectRiskStep } from "./remediation_request/SelectRiskStep";
import { SettingsStep } from "./remediation_request/SettingsStep";
import { RiskProfileRiskTypes } from "../../_common/constants";
import { fetchVendorMgtContacts } from "../reducers/vendorContacts.actions";
import { IRisk } from "../components/RiskSummaryDetails";
import {
  AdditionalEvidenceRiskEvidence,
  VendorSummaryRiskType,
} from "../../_common/types/vendorSummary";
import {
  DefaultTextType,
  OrgDefaultTexts,
  replaceVariablesInDefaultTexts,
} from "../../_common/types/orgDefaultTexts";
import { fetchOrganisationDefaultTexts } from "../reducers/orgDefaultTexts.actions";

import { ReviewAndSendStep } from "./remediation_request/ReviewAndSendStep";
import { ShareDocumentsStep } from "./remediation_request/ShareDocumentsStep";
import { DownloadEvidenceFromRiskEvidence } from "./AdditionalEvidenceDocumentsTab";
import classnames from "classnames";
import { ContactDisplay } from "../components/contacts/ContactSelect";
import { UserEmailAddress } from "../../_common/types/user";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import VendorAssessmentAPI, {
  invalidateVendorAssessmentDraftsForVendor,
} from "../reducers/vendorAssessmentAPI";
import { skipToken } from "@reduxjs/toolkit/query";
import { ConfettiFullPage } from "../../_common/components/ConfettiFullPage";
import { setPLGTaskCompleteIfIncomplete } from "../reducers/plgOnboardingChecklistActions";
import { GetQueryParams } from "../../_common/query";
import { fetchOrgUserEmailAddresses } from "../reducers/org.actions";
import { useBreadcrumbs } from "../../_common/hooks";

export interface remediationLocationState extends locationState {
  riskId?: string; // start with this risk selected (all)
  riskType?: "cloudscan" | "survey" | "evidence" | "other";
  surveyIdsWithRisk?: number[]; // if there are multiple surveys with the risk go to step 2, then on step 3 auto select
  publicSurveyIdsWithRisk?: number[];
  filterForWebsite?: string; // only show risks from this website
  filterForCVEName?: string; // start with only this CVE selected
  selectSingleWebsite?: string; // if combined with filterForCVEName, only this website is selected
  loadDraftId?: number; // if present then a draft with this ID will be loaded
  // if the user started creating a new remediation but move to editing an existing
  // one the risks he/she selected for the new remediation will be passed here
  preselectedRisks?: IPreselectedRisk[];
  goBackAfterSubmit?: boolean;
}

export const cloudscanPrefix = "[cs]-";
export const surveyPrefix = "[su]-";
export const additionalEvidencePrefix = "[ae]-";

const risksAvailableForRemediation = (
  risks: IRisk[],
  orgAccessAdditionalEvidenceRiskRemediation: boolean
) => {
  if (orgAccessAdditionalEvidenceRiskRemediation) return risks;
  return risks.filter((r) => r.riskType !== VendorSummaryRiskType.Evidence);
};

// shares surveys attached to a remediation request after it's creation
export const shareSurveyAfterCreate = async (
  prom: Promise<any>,
  dispatch: DefaultThunkDispatch,
  remediationSurveys: ISurveyMini[],
  selectedSurveyIDs: number[],
  vendorName: string,
  customerVendorName: string,
  newContacts: ContactDisplay[],
  existingContacts: existingUser[],
  currentRequest?: RemediationRequest
) => {
  // if we are creating a new request with non-public survey risks then we need to add colaborators to the survey
  // but only if they are not already part of the survey...
  const existingSurveyUsers = remediationSurveys
    .filter((s) => !s.isPublicSurvey)
    .reduce(
      (prev, s) => {
        prev[s.id] = s.sharedWith.map((u) => u.email);
        return prev;
      },
      {} as Record<number, string[]>
    );

  selectedSurveyIDs.forEach((sid) => {
    if (!!currentRequest) {
      if (currentRequest?.users?.sharedUsers) {
        currentRequest?.users?.sharedUsers?.forEach((c) => {
          if (c.email && !existingSurveyUsers[sid]?.includes(c.email)) {
            prom = prom.then(() =>
              dispatch(
                shareSurveyWithUser(
                  sid,
                  c.email as string,
                  c.name as string,
                  vendorName,
                  customerVendorName,
                  true
                )
              )
            );
          }
        });
      }
      if (currentRequest?.users?.invitedEmails) {
        currentRequest?.users?.invitedEmails?.forEach((email) => {
          if (email && !existingSurveyUsers[sid]?.includes(email)) {
            prom = prom.then(() =>
              dispatch(
                shareSurveyWithUser(
                  sid,
                  email as string,
                  "",
                  vendorName,
                  customerVendorName,
                  true
                )
              )
            );
          }
        });
      }
    } else {
      newContacts.forEach((c) => {
        if (!existingSurveyUsers[sid]?.includes(c.emailAddress)) {
          prom = prom.then(() =>
            dispatch(
              shareSurveyWithUser(
                sid as number,
                c.emailAddress,
                c.name,
                vendorName,
                customerVendorName,
                true
              )
            )
          );
        }
      });
      existingContacts
        .filter(
          (c) =>
            // if we have selected the contact or are editing a current request add the users to the survey
            c.email && c.selected
        )
        .forEach((c) => {
          if (c.email && !existingSurveyUsers[sid]?.includes(c.email)) {
            prom = prom.then(() =>
              dispatch(
                shareSurveyWithUser(
                  sid,
                  c.email as string,
                  c.name as string,
                  vendorName,
                  customerVendorName,
                  true
                )
              )
            );
          }
        });
    }
  });

  return prom;
};

export const useNewContacts = (): [
  newContact[],
  () => void,
  (tempId: string, fields: Partial<newContact>) => void,
  (tempId: string) => void,
  (contacts: Partial<newContact>[]) => void,
] => {
  const [newContacts, setNewContacts] = useState([] as newContact[]);

  const addNewContact = () =>
    setNewContacts((contacts) => [...contacts, getBlankNewContact()]);

  const updateNewContact = (tempId: string, fields: Partial<newContact>) =>
    setNewContacts((prevContacts) => {
      const contacts = [...prevContacts];
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].tempId === tempId) {
          contacts[i] = { ...contacts[i], ...fields };
          break;
        }
      }
      return contacts;
    });

  const deleteNewContact = (tempId: string) =>
    setNewContacts((prevContacts) => {
      const contacts = [...prevContacts];
      let idx = -1;
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].tempId === tempId) {
          idx = i;
          break;
        }
      }
      contacts.splice(idx, 1);
      return contacts;
    });

  const _setNewContacts = (contacts: Partial<newContact>[]) => {
    setNewContacts(
      contacts.map((c) => ({
        ...getBlankNewContact(),
        ...c,
      }))
    );
  };

  return [
    newContacts,
    addNewContact,
    updateNewContact,
    deleteNewContact,
    _setNewContacts,
  ];
};

const getDefaultBreadcrumbs = (
  isSelfRemediation: boolean,
  isSubsidiary: boolean,
  preSelectedVendorId: boolean,
  vendorID: number,
  vendorName: string,
  assuranceType: AssuranceType,
  requestId?: number
): crumb[] => {
  const crumbs = isSelfRemediation
    ? [
        { text: "Remediation", to: "/selfremediation" },
        requestId
          ? {
              text: "Remediation Request",
              to: `/selfremediation/${requestId}`,
            }
          : undefined,
      ]
    : preSelectedVendorId && isSubsidiary
      ? [
          ...getSubsidiaryPageBreadcrumbs(vendorID, vendorName),
          {
            text: "Remediation",
            to: `/subsidiaries/${vendorID}/remediation`,
          },
          requestId
            ? {
                text: "Remediation Request",
                to: `/subsidiaries/${vendorID}/remediation/${requestId}`,
              }
            : undefined,
        ]
      : preSelectedVendorId && !isSubsidiary
        ? [
            ...getVendorPageBreadcrumbs(vendorID, vendorName, assuranceType),
            { text: "Remediation", to: `/vendor/${vendorID}/remediation` },
            requestId
              ? {
                  text: "Remediation Request",
                  to: `/vendor/${vendorID}/remediation/${requestId}`,
                }
              : undefined,
          ]
        : [{ text: "Remediation", to: "/remediation" }];

  return crumbs.filter((c) => !!c) as crumb[];
};

interface IPreselectedRisk {
  source: RiskSource;
  checkId: string;
  isAllWebsites?: boolean;
  websites?: string[];
  cveNames?: string[];
  surveyIds?: number[];
  publicSurveyIds?: number[];
}

interface IRequestRemediationV2OwnProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  location: Location<remediationLocationState>;
  vendorID: number;
  preSelectedVendorId: boolean; // if true the select vendor step will be hidden
  setVendorID: (id: number) => void;
  isSubsidiary: boolean;
  isSelfRemediation: boolean;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  isManagedVendorAnalyst?: boolean;
  filterForWebsite: string;
  loadDraftId?: number;
  prePick?: string[]; // if true pre-pick the top 3 risks when they load
  requestId?: number;
  vendorAssessmentId?: number;
}

interface IRequestRemediationV2ConnectedProps {
  contacts: IVendorContactResponse[];
  defaultTexts?: OrgDefaultTexts;
  vendorName: string;
  customerVendorName: string;
  filteredRiskIDsForCVE?: VulnRiskIDsByCVE;
  selectSingleWebsiteCloudscan?: WebscanResultResult;
  canAccessSurveys: boolean;
  orgAccessSurveyScores: boolean;
  draftRequest?: RemediationRequest;
  currentRequest?: RemediationRequest;
  remediationSurveys: ISurveyMini[];
  preselectedRisks?: IPreselectedRisk[];
  existingRemediationRequestId?: number;
  assuranceType: AssuranceType;
  orgAccessAdditionalEvidenceRiskRemediation: boolean;
  orgUsers?: UserEmailAddress[];
}

type IRequestRemediationV2Props = IRequestRemediationV2ConnectedProps &
  IRequestRemediationV2OwnProps &
  DefaultThunkDispatchProp;

export enum RemediationStep {
  SelectVendor,
  SelectSubsidiary,
  SelectRisksAndAssets,
  ShareDocuments,
  Settings,
  ReviewAndSend,
  SelectUsers,
}

const isSelectVendorOrSubsidiaryStep = (step: RemediationStep): boolean =>
  step === RemediationStep.SelectVendor ||
  step === RemediationStep.SelectSubsidiary;

export const remediationStepLabels = {
  [RemediationStep.SelectVendor]: "Select vendor",
  [RemediationStep.SelectSubsidiary]: "Select subsidiary",
  [RemediationStep.SelectRisksAndAssets]: "Select risks and assets",
  [RemediationStep.ShareDocuments]: "Share documents",
  [RemediationStep.Settings]: "Settings",
  [RemediationStep.ReviewAndSend]: "Review and send",
  [RemediationStep.SelectUsers]: "Select users",
};

interface IVisibleRemediationStep {
  step: RemediationStep;
  completed: boolean;
}

export interface DocumentWithRisks {
  document: AdditionalEvidenceRiskEvidence;
  risks: RiskDetail[];
}

// getDocumentsFromSelectedRisks returns the additional evidence documents from
// within the selected additional evidence risks
export const getDocumentsFromSelectedRisks = (
  risks: RiskDetail[],
  selectedAdditionalEvidenceChecks: Record<
    string,
    IAdditionalEvidenceCheckToAdd
  >
): DocumentWithRisks[] => {
  const documentsWithSelectedRisks = risks
    .filter((r) => !!selectedAdditionalEvidenceChecks[r.id])
    .reduce(
      (docs, risk) => {
        risk.additionalEvidences?.forEach((ae) => {
          if (docs[ae.id]) {
            docs[ae.id].risks.push(risk);
          } else {
            docs[ae.id] = { document: ae, risks: [risk] };
          }
        });
        return docs;
      },
      {} as Record<number, DocumentWithRisks>
    );

  return Object.values(documentsWithSelectedRisks);
};

const RequestRemediationV2 = ({
  vendorID,
  setVendorID,
  preSelectedVendorId,
  dispatch,
  history,
  location,
  isManagementAnalystSession,
  isSelfRemediation,
  isSubsidiary,
  defaultTexts,
  vendorName,
  customerVendorName,
  contacts,
  isManagedVendorAnalyst,
  managedOrgId,
  filteredRiskIDsForCVE,
  selectSingleWebsiteCloudscan,
  filterForWebsite,
  canAccessSurveys,
  orgAccessSurveyScores,
  loadDraftId,
  draftRequest,
  currentRequest,
  remediationSurveys,
  prePick,
  requestId,
  preselectedRisks,
  existingRemediationRequestId,
  vendorAssessmentId,
  assuranceType,
  orgAccessAdditionalEvidenceRiskRemediation,
  orgUsers,
}: IRequestRemediationV2Props) => {
  const [cloudscansInRemediation, setCloudscansInRemediation] = useState<
    Record<string, CloudscanInRemediation>
  >({});
  const [additionalEvidenceInRemediation, setAdditionalEvidenceInRemediation] =
    useState<Record<string, AdditionalEvidenceInRemediation>>({});
  const [risks, setRisks] = useState<RiskDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [fireConfetti, setFireConfetti] = useState(false);

  const urlPrefix = vendorUrlPrefix(
    vendorID,
    isManagementAnalystSession,
    managedOrgId,
    isSubsidiary
  );

  const permissions = usePermissions();
  let writableDomainPortfolioIds: number[] | undefined = undefined;
  if (isSelfRemediation) {
    writableDomainPortfolioIds = permissions.editableDomainPortfolioIds;
  }
  // Fetch risks on page load. We'll always refetch and store in the component's state
  // to ensure we have an unfiltered set of risks, regardless of filters set outside this view.
  useEffect(() => {
    if (isSelfRemediation) {
      dispatch(fetchCustomerAcceptedRisks(false));
      dispatch(fetchOrgUserEmailAddresses(false));
      dispatch(
        fetchCustomerSummaryAndCloudscans(
          true,
          {
            // we don't want to use website label ids when fetching remediation risks
            websiteLabelIds: [],
            websiteIncludeUnlabeled: false,
            websiteLabelIdsDoNotMatch: false,
            websiteLabelIdsMatchAll: false,
            domainPortfolioIds: writableDomainPortfolioIds ?? [], // Filter down to only writable portfolio IDs if necessary
          },
          true
        )
      )
        .then((summary) => {
          setRisks(
            risksAvailableForRemediation(
              summary?.result?.risks ?? [],
              orgAccessAdditionalEvidenceRiskRemediation
            ).filter((r) => !r.isWaived) ?? []
          );
          setCloudscansInRemediation(
            removeCloudscanRemediationsForCurrentRequest(
              summary?.result?.cloudscansInRemediation ?? {},
              requestId
            )
          );
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("Error loading data"));
        });
    } else if (vendorID) {
      const proms = [
        dispatch(
          fetchVendorSummaryAndCloudscans(
            vendorID,
            true,
            false,
            false,
            isSubsidiary,
            {
              websiteLabelIds: [], // we don't want to use website label ids when fetching remediation risks
              websiteIncludeUnlabeled: false,
              websiteLabelIdsDoNotMatch: false,
              websiteLabelIdsMatchAll: false,
            } as any,
            true
          )
        ),
      ];

      if (!isSubsidiary) {
        proms.push(dispatch(fetchVendorWatchStatus(vendorID)));
        proms.push(dispatch(fetchVendorMgtContacts(vendorID)));
        proms.push(dispatch(fetchVendorRiskWaivers(vendorID)) as any);

        if (canAccessSurveys) {
          proms.push(dispatch(fetchSurveysRemediationMini(vendorID)) as any);
        }
      }

      Promise.all(proms)
        .then(([summary]) => {
          summary = summary as IVendorSummary;
          setRisks(
            risksAvailableForRemediation(
              summary?.result?.risks ?? [],
              orgAccessAdditionalEvidenceRiskRemediation
            ) ?? []
          );
          setCloudscansInRemediation(
            removeCloudscanRemediationsForCurrentRequest(
              summary?.result?.cloudscansInRemediation ?? {},
              requestId
            )
          );
          if (orgAccessAdditionalEvidenceRiskRemediation) {
            setAdditionalEvidenceInRemediation(
              removeAdditionalEvidenceRemediationsForCurrentRequest(
                summary?.result?.additionalEvidenceInRemediation ?? {},
                requestId
              )
            );
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("Error loading data"));
        });
    }
  }, [
    vendorID,
    isSubsidiary,
    isSelfRemediation,
    dispatch,
    isManagementAnalystSession,
    managedOrgId,
    canAccessSurveys,
    requestId,
    writableDomainPortfolioIds,
    orgAccessAdditionalEvidenceRiskRemediation,
  ]);

  // if we have any locaction state we may need to pre-load some data
  useEffect(() => {
    if (location.state && location.state.filterForCVEName) {
      dispatch(fetchVulnRiskIDsByCVE(location.state.filterForCVEName));
    }

    if (location.state && location.state.selectSingleWebsite) {
      dispatch(
        fetchCloudscanByHostname(
          location.state.selectSingleWebsite,
          false,
          isSelfRemediation
        )
      );
    } else if (filterForWebsite !== "") {
      dispatch(
        fetchCloudscanByHostname(filterForWebsite, false, isSelfRemediation)
      );
    }
  }, [location, filterForWebsite, dispatch, isSelfRemediation]);

  // if we are doing this for an assessment we need to get the evidence so we can filter by it
  const { data: linkedVendorAssessment } =
    VendorAssessmentAPI.useGetVendorAssessmentDataQuery(
      vendorAssessmentId && vendorID
        ? {
            vendorID,
            versionID: vendorAssessmentId,
          }
        : skipToken
    );

  // if we are editing a request make sure we fetch the users
  useEffect(() => {
    if (requestId) {
      dispatch(fetchRemediationRequestUsers(requestId, true));
    }
  }, [requestId, dispatch]);

  const [checksAndWebsites, checksAndWebsitesDispatch] =
    useReducer<ICloudscanCheckAndWebsiteReducer>(
      CloudscanCheckAndWebsiteReducer,
      {} as Record<string, ICloudscanCheckAndWebsite>
    );

  const [surveyChecks, surveyChecksDispatch] = useReducer<ISurveyCheckReducer>(
    SurveyCheckReducer,
    {} as Record<string, ISurveyCheckToAdd>
  );

  const [additionalEvidenceChecks, additionalEvidenceChecksDispatch] =
    useReducer<IAdditionalEvidenceCheckReducer>(
      AdditionalEvidenceCheckReducer,
      {} as Record<string, IAdditionalEvidenceCheckToAdd>
    );

  const [additionalEvidenceDocuments, additionalEvidenceDocumentsDispatch] =
    useReducer<IAdditionalEvidenceDocumentReducer>(
      AdditionalEvidenceDocumentReducer,
      {} as Record<number, IAdditionalEvidenceDocumentToAdd>
    );

  const documentsWithSelectedRisks = useMemo(
    () => getDocumentsFromSelectedRisks(risks, additionalEvidenceChecks),
    [risks, additionalEvidenceChecks]
  );

  const getSelectedSurveyIds = (publicSurveys = false) => {
    const idSet = new Set<number>();
    Object.values(surveyChecks).forEach((s) => {
      if (!publicSurveys) {
        s.surveys?.forEach((id) => idSet.add(id));
      } else {
        s.publicSurveys?.forEach((id) => idSet.add(id));
      }
    });
    return [...idSet.values()];
  };

  // if we are adding a new request request the list of remediation requests
  // so we can suggest to edit an existing remediation to the user
  useEffect(() => {
    if (!requestId) {
      if (isSelfRemediation) {
        dispatch(fetchRemediationRequestListForSelf(true));
      } else if (vendorID !== 0) {
        dispatch(
          fetchRemediationRequestListForVendor(vendorID, isSubsidiary, true)
        );
      }
    }
  }, [requestId, isSelfRemediation, vendorID, isSubsidiary, dispatch]);

  // load the draft if needed
  useEffect(() => {
    if (loadDraftId) {
      dispatch(fetchRemediationRequestDetails(loadDraftId));
    }
  }, [loadDraftId, dispatch]);

  // if we are editing an existing request fetch its details
  useEffect(() => {
    if (requestId) {
      dispatch(fetchRemediationRequestDetails(requestId));
    }
  }, [requestId, dispatch]);

  // if we are doing this for a vendor assessment check if we already have a request to load
  useEffect(() => {
    if (vendorAssessmentId) {
      dispatch(
        fetchRemediationRequestDetailsForVendorAssessment(vendorAssessmentId)
      );
    }
  }, [vendorAssessmentId, dispatch]);

  // we need to delay initializing the above state till everything is loaded and then pre-select
  // any risks that were passed on though the location state
  useEffect(() => {
    // don't do anything in this effect till all the loading is done
    if (loading || !risks || risks.length === 0 || !location.state) {
      return;
    }

    const showRemovedRiskWarning = () => {
      dispatch(
        addDefaultWarningAlert(
          "Some risks were deselected as they are already under remediation or waived."
        )
      );
    };

    const risk = risks.find(({ id }) => id === location.state.riskId);

    // if we have preselected a risk from a survey filter out the ones that are already under remediation
    if (
      risk &&
      risk.riskType === VendorSummaryRiskType.Survey &&
      ((location.state.surveyIdsWithRisk?.length ?? 0) > 0 ||
        (location.state.publicSurveyIdsWithRisk?.length ?? 0) > 0)
    ) {
      // filter out all the surveys and public surveys already under remediation
      const surveyIDs = location.state.surveyIdsWithRisk?.filter(
        (id) =>
          risk?.surveys?.find(
            (s) =>
              s.surveyId === id &&
              !s.publicSurvey &&
              !s.inRemediation &&
              !s.isWaived
          ) ?? false
      );
      const publicSurveyIDs = location.state.publicSurveyIdsWithRisk?.filter(
        (id) =>
          risk?.surveys?.find(
            (s) =>
              s.surveyId === id &&
              s.publicSurvey &&
              !s.inRemediation &&
              !s.isWaived
          ) ?? false
      );

      if (
        surveyIDs?.length !== location.state.surveyIdsWithRisk?.length ||
        publicSurveyIDs?.length !==
          location.state.publicSurveyIdsWithRisk?.length
      ) {
        showRemovedRiskWarning();
      }

      // if all the assets have been filter out because already under remediation than don't pre-select the risk
      if (!surveyIDs?.length && !publicSurveyIDs?.length) {
        return;
      }

      surveyChecksDispatch({
        type: "setCheckSurveys",
        riskId: risk.id,
        surveys: surveyIDs,
        publicSurveys: publicSurveyIDs,
      });
      return;
    } else if (risk && risk.riskType === VendorSummaryRiskType.Survey) {
      // Pre-select all the possible surveys
      // - Filter out inremediation,waived surveys
      const surveyIDs =
        risk?.surveys?.filter(
          (s) => !s.publicSurvey && !s.inRemediation && !s.isWaived
        ) ?? [];

      const publicSurveyIDs =
        risk?.surveys?.filter(
          (s) => s.publicSurvey && !s.inRemediation && !s.isWaived
        ) ?? [];

      if (
        surveyIDs.length + publicSurveyIDs.length <
        (risk.surveys?.length ?? 0)
      ) {
        showRemovedRiskWarning();
      }

      // if all the assets have been filter out because already under remediation than don't pre-select the risk
      if (!surveyIDs?.length && !publicSurveyIDs?.length) {
        return;
      }

      surveyChecksDispatch({
        type: "setCheckSurveys",
        riskId: risk.id,
        surveys: surveyIDs.map((s) => s.surveyId),
        publicSurveys: publicSurveyIDs.map((s) => s.surveyId),
      });
      return;
    }

    // if we have preselected an evidence risk just load it in the selection
    if (
      location.state.riskId &&
      location.state.riskType === RiskProfileRiskTypes.Evidence
    ) {
      additionalEvidenceChecksDispatch({
        type: "addCheckID",
        riskId: location.state.riskId,
      });
      return;
    }

    // we should never have multiple combinations of a riskID pre-selected and cve risks pre-selected
    const risksToSelect = [] as string[];
    if (location.state.riskId) {
      risksToSelect.push(location.state.riskId);
    } else if (filteredRiskIDsForCVE && filteredRiskIDsForCVE.riskIDs) {
      risksToSelect.push(...filteredRiskIDsForCVE.riskIDs);
    }

    if (risksToSelect.length === 0) {
      return; // nothing to do
    }

    let someRisksInapplicable = false;

    // otherwise work out the risks
    risksToSelect.forEach((riskId) => {
      const risk = risks.find(({ id }) => id === riskId);
      if (!risk) {
        return;
      }

      if (!IsCPERisk(riskId)) {
        if (location.state.selectSingleWebsite) {
          // make sure the website we are looking for is actually in the affected sites
          if (
            selectSingleWebsiteCloudscan &&
            selectSingleWebsiteCloudscan.checkResults.some(
              (check) => check.id === riskId && !check.pass
            )
          ) {
            checksAndWebsitesDispatch({
              type: "setCheckWebsites",
              checkId: riskId,
              websites: [location.state.selectSingleWebsite],
            });
          }
        } else if (filterForWebsite !== "") {
          checksAndWebsitesDispatch({
            type: "setCheckWebsites",
            checkId: riskId,
            websites: [filterForWebsite],
          });
        } else {
          // Double check this check can be selected (it may be fully remediated);
          if (
            (cloudscansInRemediation[risk.id] &&
              (Object.keys(cloudscansInRemediation[risk.id]?.websites ?? {})
                .length === risk.numFailedCloudscans ||
                cloudscansInRemediation[risk.id]?.isAllWebsites)) ||
            risk.surveys?.every((s) => s.inRemediation)
          ) {
            console.log(
              `NOT selecting risk ${risk.id} as it's already in remediation`
            );
            someRisksInapplicable = true;
          } else {
            checksAndWebsitesDispatch({
              type: "selectAll",
              checkId: riskId,
              selectAll: true,
            });
          }
        }
      } else {
        // pre-select the CVEs too if applicable
        // if we are also filtering for a website make sure it's included
        if (filterForWebsite !== "") {
          if (
            selectSingleWebsiteCloudscan &&
            selectSingleWebsiteCloudscan.checkResults.some(
              (check) => check.id === riskId && !check.pass
            )
          ) {
            checksAndWebsitesDispatch({
              type: "setSelectedCVEs",
              checkId: riskId,
              selectedCVEs: location.state.filterForCVEName
                ? [location.state.filterForCVEName]
                : null,
              websites: [filterForWebsite],
            });
          }
        } else {
          checksAndWebsitesDispatch({
            type: "setSelectedCVEs",
            checkId: riskId,
            selectedCVEs: location.state.filterForCVEName
              ? [location.state.filterForCVEName]
              : null,
          }); // null means select all
          checksAndWebsitesDispatch({
            type: "selectAll",
            checkId: riskId,
            selectAll: true,
          });
        }
      }
    });

    if (someRisksInapplicable) {
      showRemovedRiskWarning();
    }
  }, [
    loading,
    filteredRiskIDsForCVE,
    selectSingleWebsiteCloudscan,
    cloudscansInRemediation,
    risks,
    location,
    dispatch,
  ]);

  // if we are loading a draft or editing a request then we need to setup the state from the existing remediation request
  useEffect(() => {
    if (!loadDraftId && !requestId && !preselectedRisks) {
      return;
    }

    // wait for the risks and request to load
    if (
      !risks ||
      risks.length === 0 ||
      (!draftRequest && !currentRequest && !preselectedRisks)
    ) {
      return;
    }

    const currentRisks = draftRequest
      ? draftRequest.details.risks
      : currentRequest
        ? currentRequest.details.risks
        : [];

    // map the open risks from the existing request to the risks present on the vendor
    // filter out any full remediated/waived risks
    currentRisks
      .filter(
        (risk) =>
          risk.riskStatus == RiskStatus.Open ||
          risk.riskStatus == RiskStatus.Partial ||
          risk.riskStatus == RiskStatus.Draft
      )
      .forEach((risk) => {
        if (risk.source === RiskSource.Cloudscan) {
          if (risk.isAllWebsites || !risk.websites?.length) {
            checksAndWebsitesDispatch({
              type: "selectAll",
              checkId: risk.checkId,
              selectAll: true,
            });
          } else {
            checksAndWebsitesDispatch({
              type: "setCheckWebsites",
              checkId: risk.checkId,
              websites: risk.websites,
            });
          }
          if (risk.metadata) {
            checksAndWebsitesDispatch({
              type: "setSelectedCVEs",
              checkId: risk.checkId,
              selectedCVEs: risk.metadata.cveNames,
              websites: risk.websites,
            });
          }
        } else if (risk.source === RiskSource.Survey) {
          surveyChecksDispatch({
            type: "setCheckSurveys",
            riskId: risk.checkId,
            surveys: risk.surveys
              .filter((s) => !!s.surveyId)
              .map((s) => s.surveyId ?? 0),
            publicSurveys: risk.surveys
              .filter((s) => !!s.publicSurveyId)
              .map((s) => s.publicSurveyId ?? 0),
          });
        } else if (risk.source === RiskSource.AdditionalEvidence) {
          additionalEvidenceChecksDispatch({
            type: "addCheckID",
            riskId: risk.checkId,
          });
        }
      });

    // if we are editing a request and we got preselctedRisks from state load them as well
    if (currentRequest && preselectedRisks) {
      preselectedRisks.forEach((risk) => {
        if (risk.source === RiskSource.Cloudscan) {
          if (risk.isAllWebsites || !risk.websites?.length) {
            checksAndWebsitesDispatch({
              type: "selectAll",
              checkId: risk.checkId,
              selectAll: true,
            });
          } else {
            checksAndWebsitesDispatch({
              type: "setCheckWebsites",
              checkId: risk.checkId,
              websites: risk.websites ?? [],
            });
          }
          if (risk.cveNames) {
            checksAndWebsitesDispatch({
              type: "setSelectedCVEs",
              checkId: risk.checkId,
              selectedCVEs: risk.cveNames,
              websites: risk.websites,
            });
          }
        } else if (risk.source === RiskSource.Survey) {
          surveyChecksDispatch({
            type: "setCheckSurveys",
            riskId: risk.checkId,
            surveys: risk.surveyIds,
            publicSurveys: risk.publicSurveyIds,
          });
        }
      });
    }

    // load selected additional evidence documents
    (draftRequest
      ? draftRequest.details.additionalEvidenceIds
      : currentRequest
        ? currentRequest.details.additionalEvidenceIds
        : []
    )?.forEach((evidenceId) =>
      additionalEvidenceDocumentsDispatch({
        type: "addEvidenceID",
        evidenceId,
      })
    );
  }, [
    loadDraftId,
    draftRequest,
    requestId,
    currentRequest,
    risks,
    vendorID,
    preselectedRisks,
  ]);

  // if we've been asked to prepick the risks then wait for the risks to load and pick the risks we've been asked to
  // unless they have been resolved or already put under remediation
  useEffect(() => {
    if (!loading && prePick && risks && risks.length > 0) {
      risks
        .filter(
          (r) =>
            !r.passed &&
            !(
              r.id in cloudscansInRemediation &&
              (Object.keys(cloudscansInRemediation[r.id]?.websites ?? {})
                .length === r.numFailedCloudscans ||
                cloudscansInRemediation[r.id].isAllWebsites)
            ) &&
            prePick.includes(r.id)
        )
        .forEach((r) => {
          checksAndWebsitesDispatch({
            type: "selectAll",
            checkId: r.id,
            selectAll: true,
          });
        });
    }
  }, [prePick, risks, cloudscansInRemediation, loading]);

  const [numSelectedAssets, setNumSelectedAssets] = useState(0);
  const [numSelectedRisks, setNumSelectedRisks] = useState(0);
  useEffect(() => {
    setNumSelectedRisks(
      Object.keys(checksAndWebsites).length +
        Object.keys(surveyChecks).length +
        Object.keys(additionalEvidenceChecks).length
    );
  }, [surveyChecks, checksAndWebsites, additionalEvidenceChecks]);

  const isEditingMode = !!requestId;

  const [selectedContacts, setSelectedContacts] = useState(
    [] as ContactDisplay[]
  );

  const [emailMessage, setEmailMessage] = useState("");
  const [title, setTitle] = useState("");

  const visibleSteps: IVisibleRemediationStep[] = [];
  if (!isSelfRemediation && !preSelectedVendorId) {
    visibleSteps.push({
      step: isSubsidiary
        ? RemediationStep.SelectSubsidiary
        : RemediationStep.SelectVendor,
      completed: !!vendorID,
    });
  }
  visibleSteps.push({
    step: RemediationStep.SelectRisksAndAssets,
    completed: numSelectedRisks > 0,
  });
  if (Object.keys(additionalEvidenceChecks).length > 0) {
    visibleSteps.push({
      step: RemediationStep.ShareDocuments,
      completed: true,
    });
  }
  if (!isEditingMode) {
    const validContacts =
      selectedContacts.length > 0 &&
      selectedContacts.every((c) => !c.isChecking && !c.emailDomainNotAllowed);
    visibleSteps.push(
      {
        step: RemediationStep.Settings,
        completed: validContacts && title.length > 0 && emailMessage.length > 0,
      },
      {
        step: RemediationStep.ReviewAndSend,
        completed: true,
      }
    );
  }

  const [filterText, setFilterText] = useState("");
  const [currentStep, setCurrentStep] = useState(() => {
    // if we have a vendor ID we start on SelectRisksAndAssets
    if (preSelectedVendorId || vendorID) {
      return RemediationStep.SelectRisksAndAssets;
    }
    return visibleSteps[0].step;
  });

  const currentStepIndex = visibleSteps.findIndex(
    (s) => s.step === currentStep
  );
  const nextStep =
    currentStepIndex < visibleSteps.length - 1
      ? visibleSteps[currentStepIndex + 1].step
      : undefined;
  const prevStep =
    currentStepIndex > 0 ? visibleSteps[currentStepIndex - 1].step : undefined;

  const [scoreProjectionWorking, setScoreProjectionWorking] = useState(false);
  const [initialScore, setInitialScore] = useState(0);
  const [projectedScore, setProjectedScore] = useState(0);
  const [projectedScoreAt, setProjectedScoreAt] = useState("");
  const [
    dueDate,
    reminderDate,
    reminderSelection,
    setDueDate,
    setReminderDate,
    setReminderSelection,
  ] = useSchedule();

  // if we are loading a draft, use the due date from the draft
  useEffect(() => {
    if (!loadDraftId) {
      return;
    }

    if (draftRequest?.details?.dueDate) {
      setDueDate(draftRequest.details.dueDate);
      if (draftRequest?.details?.reminderDate) {
        setReminderDate(draftRequest.details.reminderDate);

        const numDays = moment(draftRequest.details.dueDate).diff(
          moment(draftRequest.details.reminderDate),
          "days"
        );

        let selection: reminderSelectionType = "custom";
        if (numDays === 7) {
          selection = "7b";
        } else if (numDays === 30) {
          selection = "30b";
        }

        setReminderSelection(selection);
      }
    }
  }, [loadDraftId, draftRequest]);

  // if we are loading a draft, and we have contacts saved, select them
  const [draftContactsLoaded, setDraftContactsLoaded] = useState(false);
  useEffect(() => {
    if (
      draftContactsLoaded ||
      !loadDraftId ||
      !draftRequest ||
      (vendorID !== 0 && !contacts)
    ) {
      return;
    }

    setDraftContactsLoaded(true);

    if (vendorID !== 0 && !isSubsidiary) {
      // make sure the contacts still exist in the vendor contacts before adding them in
      const contactsToAdd: ContactDisplay[] = contacts
        .filter(
          (c) =>
            c.emailAddress &&
            draftRequest.details.draftInviteEmails?.includes(c.emailAddress)
        )
        .map((c) => ({
          name: c.name ?? "",
          emailAddress: c.emailAddress ?? "",
          isNewContact: false,
          title: c.title,
          avatar: "",
          isVendorContact: true,
          existingId: c.id,
        }));
      setSelectedContacts((current) => [...current, ...contactsToAdd]);
    } else {
      // if this is self remediation then we need to set new contacts directly
      if (draftRequest.details.draftInviteEmails) {
        const contactsToAdd: ContactDisplay[] =
          draftRequest.details.draftInviteEmails.map((e) => ({
            emailAddress: e,
            name: "",
            isNewContact: true,
            title: "",
            avatar: "",
            isVendorContact: false,
            existingId: 0,
          }));
        setSelectedContacts((current) => [...current, ...contactsToAdd]);
      }
    }
  }, [vendorID, contacts, loadDraftId, draftRequest]);

  const [sendLoading, setSendLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);

  useEffect(() => {
    dispatch(
      fetchOrganisationDefaultTexts(
        false,
        isManagementAnalystSession,
        managedOrgId
      )
    );
  }, [dispatch, isManagementAnalystSession, managedOrgId]);

  useEffect(() => {
    if (defaultTexts) {
      let defaultTitle =
        defaultTexts[DefaultTextType.RemediationRequestTitle]?.defaultText ||
        "";
      let defaultEmailMessage =
        defaultTexts[DefaultTextType.RemediationRequestMessage]?.defaultText ||
        "";

      const name = isSelfRemediation ? customerVendorName : vendorName;

      [defaultTitle, defaultEmailMessage] = replaceVariablesInDefaultTexts(
        [defaultTitle, defaultEmailMessage],
        {
          "{{VendorName}}": name,
          "{{AccountName}}": customerVendorName,
        }
      );

      if (!loadDraftId) {
        setTitle(defaultTitle);
        setEmailMessage(defaultEmailMessage);
      }
    }
  }, [defaultTexts, loadDraftId, vendorName]);

  // if we are loading a draft use that for the title and message instead
  useEffect(() => {
    if (!loadDraftId || !draftRequest?.details?.title) {
      return;
    }

    if (draftRequest?.details?.title) {
      setTitle(draftRequest.details.title);
    }

    if (draftRequest?.details?.message) {
      setEmailMessage(draftRequest.details.message);
    }
  }, [loadDraftId, draftRequest]);

  let havePreviousIncompleteStep = false;
  const steps: IStep[] = visibleSteps.map((s) => {
    const stepDisabled = havePreviousIncompleteStep;
    if (!s.completed) {
      havePreviousIncompleteStep = true;
    }
    return {
      id: `step_${s.step}`,
      text: remediationStepLabels[s.step],
      disabled: stepDisabled,
      onClick: () => setCurrentStep(s.step),
    };
  });

  const goBack = () => {
    if (location.state?.backContext?.goBack) {
      history.goBack();
      return;
    }

    if (
      location.state &&
      location.state.backContext &&
      location.state.backContext?.backTo
    ) {
      history.push(
        location.state.backContext?.backTo,
        location.state.backContext?.backToContext
      );
      return;
    }

    if (vendorAssessmentId) {
      history.push(
        `${vendorUrlPrefix(
          vendorID,
          isManagementAnalystSession,
          managedOrgId
        )}/assessment`
      );
      return;
    }

    if (isManagementAnalystSession) {
      history.push(
        `/analysts/tpvm/${managedOrgId}/${vendorID}/remediation${
          requestId ? "/" + requestId : ""
        }`
      );
    } else if (isSelfRemediation) {
      history.push(`/selfremediation${requestId ? "/" + requestId : ""}`);
    } else if (isSubsidiary) {
      if (!preSelectedVendorId) {
        // Go back to self remediation screen
        history.push(`/selfremediation${requestId ? "/" + requestId : ""}`);
      } else {
        history.push(
          `/subsidiaries/${vendorID}/remediation${
            requestId ? "/" + requestId : ""
          }`
        );
      }
    } else if (preSelectedVendorId) {
      history.push(
        `/vendor/${vendorID}/remediation${requestId ? "/" + requestId : ""}`
      );
    } else {
      // Go back to the main remediation screen
      history.push("/remediation");
    }
  };

  const goNext = () => {
    if (nextStep) {
      setCurrentStep(nextStep);
    } else {
      submitRequest(false);
    }
  };

  let goPrev = undefined;
  if (prevStep) {
    goPrev = () => setCurrentStep(prevStep);
  }

  const submitRequest = (isDraft: boolean) => {
    const eventName = isEditingMode ? "EditRemediation" : "RequestRemediation";
    trackEvent(eventName, {
      isSelfRemediation: isSelfRemediation,
    });

    if (isDraft) {
      setDraftLoading(true);
    } else {
      setSendLoading(true);
    }

    let prom = Promise.resolve<void[] | void>([]);

    // Create new contacts if we need to
    const newContacts = selectedContacts.filter((c) => c.isNewContact);
    if (!isSelfRemediation && !isSubsidiary && newContacts.length > 0) {
      prom = prom.then(() =>
        Promise.all(
          newContacts.map((c) =>
            dispatch(
              createVendorContact(vendorID, {
                name: c.name,
                title: c.title,
                emailAddress: c.emailAddress,
              })
            )
          )
        )
      );
    }

    // Create all the risks to be sent as part of this request
    let sendingtoEmails = selectedContacts.map((c) => c.emailAddress);

    // if this is a draft filter out any invalid emails before submitting
    if (isDraft) {
      sendingtoEmails = sendingtoEmails.filter((e) => validateEmail(e));
    }

    const cloudscanChecksAndWebsites = Object.values(checksAndWebsites);
    const surveyRisks = Object.values(surveyChecks);
    surveyRisks.forEach((sr) => {
      if (sr.riskID.indexOf("|") !== -1) {
        // Get the base risk id
        const parts = sr.riskID.split("|");
        if (parts.length !== 2) {
          throw new Error(`invalid risk id: ${sr.riskID}`);
        }
        sr.riskID = parts[0];
      }
    });
    const additionalEvidenceRisks = Object.values(additionalEvidenceChecks);
    additionalEvidenceRisks.forEach((aer) => {
      if (aer.riskID.indexOf("|") !== -1) {
        // Get the base risk id
        const parts = aer.riskID.split("|");
        if (parts.length !== 2) {
          throw new Error(`invalid risk id: ${aer.riskID}`);
        }
        aer.riskID = parts[0];
      }
    });
    // get the selected evidence documents to include in the request, making
    // sure to remove any documents that are no longer valid e.g. because there
    // are no longer any selected risks that relate to those documents
    const additionalEvidenceDocumentsToAdd = Object.values(
      additionalEvidenceDocuments
    ).filter(
      (doc) =>
        !!documentsWithSelectedRisks.find(
          (docWithSelectedRisk) =>
            docWithSelectedRisk.document.id === doc.evidenceId
        )
    );
    const request: IRemediationRequestBaseRequest = {
      title,
      inviteEmails: sendingtoEmails,
      emailMessage,
      cloudscanChecksAndWebsites,
      surveyChecksToAdd: surveyRisks,
      additionalEvidenceChecksToAdd: additionalEvidenceRisks,
      additionalEvidenceDocumentsToAdd,
      dueDate: dueDate ? moment(dueDate).utc(true).format() : undefined,
      reminderDate: reminderDate
        ? moment(reminderDate).utc(true).format()
        : undefined,
      initialScore,
      projectedScore,
      projectedScoreAt: projectedScoreAt != "" ? projectedScoreAt : undefined,
      saasChecksToAdd: [],
    };

    let newRequestId: number;
    let emailAddressInactive: boolean;
    if (loadDraftId) {
      prom = prom.then(() =>
        dispatch(
          updateRemediationRequestDraft({
            ...request,
            requestId: loadDraftId,
            openRequest: !isDraft,
          })
        )
      );
    } else if (requestId) {
      prom = prom.then(() =>
        dispatch(
          editRemediationRequestRisks({
            requestId,
            cloudscanChecksAndWebsites,
            surveyChecks: surveyRisks,
            additionalEvidenceChecks: additionalEvidenceRisks,
            additionalEvidenceDocumentsToAdd,
            projectedScore,
            projectedScoreAt:
              projectedScoreAt != "" ? projectedScoreAt : undefined,
          })
        )
      );
    } else {
      prom = prom
        .then(() =>
          dispatch(
            createNewRemediationRequest({
              ...request,
              vendorId: isSelfRemediation ? undefined : vendorID,
              isSubsidiary,
              isDraft,
              vendorAssessmentId,
            })
          )
        )
        .then((resp) => {
          newRequestId = resp.newId;
          emailAddressInactive = resp.emailAddressInactive;
        });
    }

    // if we are creating a new request with non-public survey risks then we need to add colaborators to the survey
    // but only if they are not already part of the survey...
    if (surveyRisks.length > 0 && !isDraft) {
      const existingUsers = [
        ...contacts
          .filter((u) => u.emailAddress)
          .map(
            (u) =>
              ({
                name: u.name ?? "",
                email: u.emailAddress,
                isInvite: false,
                alreadyAdded: false,
                selected: !!selectedContacts.find(
                  (c) => c.emailAddress == u.emailAddress
                ),
              }) as existingUser
          ),
      ];

      prom = shareSurveyAfterCreate(
        prom,
        dispatch,
        remediationSurveys,
        getSelectedSurveyIds(),
        vendorName,
        customerVendorName,
        newContacts,
        existingUsers,
        currentRequest
      );
    }

    let plgTask = "Checklist_BreachSight_Remediation";
    if (!isSelfRemediation) {
      plgTask = "Checklist_VendorRisk_Remediation";
    }
    dispatch(setPLGTaskCompleteIfIncomplete(plgTask)).then((result) => {
      if (result) {
        setFireConfetti(true);
      }
    });

    prom
      .then(() => {
        // if this for a vendor assessment reload assessments return to that page
        if (vendorAssessmentId) {
          dispatch(invalidateVendorAssessmentDraftsForVendor(vendorID));

          // We should have a backContext to use:
          if (location.state?.backContext?.goBack) {
            history.goBack();
          } else if (location.state?.backContext?.backTo) {
            history.push(
              location.state.backContext?.backTo,
              location.state.backContext?.backToContext
            );
          } else {
            // Just direct back to the vendor assessment page
            history.push(
              `${vendorUrlPrefix(
                vendorID,
                isManagementAnalystSession,
                managedOrgId
              )}/assessments`
            );
          }
        } else if (location.state?.goBackAfterSubmit) {
          if (location.state?.backContext?.goBack) {
            history.goBack();
          } else if (location.state?.backContext?.backTo) {
            history.push(
              location.state.backContext?.backTo,
              location.state.backContext?.backToContext
            );
          }
        }

        //
        // did we come from the risk profile compliance report? If we did, then get-on-back thar ya'hear?
        //

        // --------------------
        else if (
          location.state &&
          location.state.backContext &&
          location.state.backContext?.backTo &&
          location.state.backContext?.backToContext &&
          location.state.backContext?.backToContext.selectedComplianceFramework
        ) {
          history.push(
            location.state.backContext?.backTo,
            location.state.backContext?.backToContext
          );
        } else {
          //
          // otherwise, get on back depending on context
          //

          const locationState = isDraft ? { goToDrafts: "draft" } : {};
          const requestIdUrlPart =
            (requestId || newRequestId) && !isDraft
              ? `/${requestId ?? newRequestId}`
              : "";
          if (isSelfRemediation) {
            history.push(`/selfremediation${requestIdUrlPart}`, locationState);
          } else if (isSubsidiary) {
            history.push(
              `/subsidiaries/${vendorID}/remediation${requestIdUrlPart}`,
              locationState
            );
          } else if (isManagementAnalystSession) {
            history.push(
              `/analysts/tpvm/${managedOrgId}/${vendorID}/remediation${requestIdUrlPart}`,
              locationState
            );
          } else {
            history.push(
              `/vendor/${vendorID}/remediation${requestIdUrlPart}`,
              locationState
            );
          }
        }

        dispatch(
          !!emailAddressInactive
            ? addDefaultWarningAlert(
                "The remediation request was created. One or more collaborator email addresses appear to be inactive and may not have received an email."
              )
            : addDefaultSuccessAlert(
                isDraft
                  ? "Draft remediation request saved"
                  : requestId
                    ? "Remediation request updated"
                    : `${title} was sent to ${vendorName}`
              )
        );

        if (isSelfRemediation) {
          dispatch(fetchCustomerSummaryAndCloudscans(true));
          dispatch(fetchRemediationRequestListForSelf(true));
        } else {
          if (vendorID !== 0) {
            dispatch(
              fetchRemediationRequestListForVendor(vendorID, isSubsidiary, true)
            );
            dispatch(
              fetchVendorSummaryAndCloudscans(
                vendorID,
                true,
                false,
                false,
                isSubsidiary
              )
            );
            if (isSubsidiary) {
              dispatch(fetchRemediationRequestListForAllSubsidiaries(true));
            } else {
              dispatch(fetchRemediationRequestListForAllVendors(true));
            }
          }
        }

        if (!isSelfRemediation && !isSubsidiary && newContacts.length > 0) {
          dispatch(fetchVendorMgtContacts(vendorID, true));
        }

        if (requestId) {
          // if it was a request edit then refresh its history
          dispatch(fetchRemediationRequestTimeline(requestId, true));
        }
      })
      .catch((e) => {
        setSendLoading(false);
        setDraftLoading(false);
        if (!dispatch(handleKnownErrors(e))) {
          dispatch(addDefaultUnknownErrorAlert(e.message));
        }
      });
  };

  const callQueue = useRef<(() => Promise<void>)[]>([]);

  const refreshProjection = useCallback(
    (
      isSelfRemediation: boolean,
      vendorID: number,
      isSubsidiary: boolean,
      checksAndWebsites: Record<string, ICloudscanCheckAndWebsite>,
      surveyChecks: Record<string, ISurveyCheckToAdd>,
      additionalEvidenceChecks: Record<string, IAdditionalEvidenceCheckToAdd>
    ) =>
      () => {
        setScoreProjectionWorking(true);
        return dispatch(
          fetchProjectionForNewRemediationRequest(
            isSelfRemediation ? 0 : vendorID,
            isSubsidiary,
            Object.values(checksAndWebsites),
            Object.values(surveyChecks),
            Object.values(additionalEvidenceChecks),
            requestId
          )
        )
          .then((result) => {
            if (result) {
              setInitialScore(result.initialScore);
              setProjectedScore(result.projectedScore);
              setNumSelectedAssets(result.numAffectedAssets);
              setProjectedScoreAt(result.projectedScoreAt);
            }
            const call = callQueue.current.pop();
            callQueue.current.splice(0, callQueue.current.length);
            if (!!call) {
              call();
            } else {
              setScoreProjectionWorking(false);
            }
          })
          .catch((e) => console.error(e));
      },
    [fetchProjectionForNewRemediationRequest]
  );

  const refreshCloudscanScoreProjection = () => {
    const thisCall = refreshProjection(
      isSelfRemediation,
      vendorID,
      isSubsidiary,
      checksAndWebsites,
      surveyChecks,
      additionalEvidenceChecks
    );
    if (!scoreProjectionWorking) {
      thisCall();
    } else {
      callQueue.current.push(thisCall);
    }
  };

  // if the checks change refresh the projection
  useEffect(() => {
    if (
      Object.keys(checksAndWebsites).length > 0 ||
      Object.keys(surveyChecks).length > 0 ||
      Object.keys(additionalEvidenceChecks).length > 0
    ) {
      refreshCloudscanScoreProjection();
    }
  }, [checksAndWebsites, surveyChecks, additionalEvidenceChecks]);

  const breadcrumbs = useBreadcrumbs(
    { text: requestId ? "Edit remediation" : "Request remediation" },
    () =>
      getDefaultBreadcrumbs(
        isSelfRemediation,
        isSubsidiary,
        preSelectedVendorId,
        vendorID,
        vendorName,
        assuranceType,
        requestId
      )
  );

  const pageTitle =
    isSelfRemediation || !vendorName
      ? `${isEditingMode ? "Edit" : "Request"} remediation`
      : `${isEditingMode ? "Edit" : "Request"} remediation ${
          isEditingMode ? "for" : "from"
        } ${vendorName}`;

  const onClickToAddToExistingRequest = existingRemediationRequestId
    ? () => {
        const url = `/${
          isSubsidiary ? "subsidiaries" : "vendor"
        }/${vendorID}/remediation/${existingRemediationRequestId}/edit`;

        const preselectedRisks = Object.values(checksAndWebsites).map(
          (cloudscanCheck: ICloudscanCheckAndWebsite): IPreselectedRisk => {
            return {
              source: RiskSource.Cloudscan,
              checkId: cloudscanCheck.checkId,
              isAllWebsites: cloudscanCheck.selectAll,
              websites: cloudscanCheck.websites,
              cveNames: cloudscanCheck?.metadata?.CVENames ?? undefined,
            };
          }
        );
        Object.values(surveyChecks).forEach(
          (surveyCheck: ISurveyCheckToAdd) => {
            preselectedRisks.push({
              source: RiskSource.Survey,
              checkId: surveyCheck.riskID,
              surveyIds: surveyCheck.surveys,
              publicSurveyIds: surveyCheck.publicSurveys,
            });
          }
        );

        history.push(url, {
          preselectedRisks: preselectedRisks,
          backContext: {
            backTo: location.pathname,
            backToText: "Back to Request remediation",
          },
        });
        setCurrentStep(RemediationStep.SelectRisksAndAssets);
      }
    : undefined;

  const setVendorAndReset = (id: number) => {
    setVendorID(id);
    surveyChecksDispatch({ type: "init" });
    checksAndWebsitesDispatch({ type: "init" });
    // setNewContacts([]);
    // selectedContactEmails.forEach((e) => setSelectedContactEmails(e, false));
  };

  const selectVendorOrSubsidaryStep = visibleSteps.find((s) =>
    isSelectVendorOrSubsidiaryStep(s.step)
  );

  return (
    <StepsWithSections
      className={classnames("request-remediation-v2", {
        loading,
      })}
    >
      {fireConfetti && <ConfettiFullPage />}
      {isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={history}
        title={pageTitle}
        breadcrumbs={breadcrumbs}
        vendorId={preSelectedVendorId ? vendorID : undefined}
        isSubsidiary={preSelectedVendorId ? isSubsidiary : undefined}
        isManagementAnalystSession={isManagementAnalystSession}
        managedOrgId={managedOrgId}
        backAction={
          location.state?.backContext?.goBack
            ? history.goBack
            : location.state && location.state.backContext
              ? () =>
                  history.push(
                    location.state.backContext?.backTo || "",
                    location.state.backContext?.backToContext
                  )
              : goBack
        }
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : "Back"
        }
      />
      {vendorAssessmentId && (
        <InfoBanner
          type={BannerType.WARNING}
          centered
          message={
            "This remediation request is part of your risk assessment for this vendor"
          }
        />
      )}
      {steps.length > 0 && (
        <Steps steps={steps} currentStep={currentStepIndex + 1} />
      )}
      <div className={"main-content"}>
        <div className={"step"}>
          <SelectVendorCard
            selectedVendorIDs={[vendorID]}
            onSelectVendor={setVendorAndReset}
            isVisible={currentStep === RemediationStep.SelectVendor}
            writablePortfoliosOnly
            filterText={filterText}
            setFilterText={setFilterText}
          />
          {currentStep === RemediationStep.SelectSubsidiary && (
            <ReportCard newStyles>
              <SubsidiarySelector
                selectedVendorId={vendorID}
                setSelectedVendorId={setVendorAndReset}
                topLevelSelectionDisabled
              />
            </ReportCard>
          )}
          {currentStep === RemediationStep.SelectRisksAndAssets && (
            <ConnectedSelectRiskStep
              cloudscansInRemediation={cloudscansInRemediation}
              additionalEvidenceInRemediation={additionalEvidenceInRemediation}
              selectedCloudscanChecks={checksAndWebsites}
              cloudscanDispatch={checksAndWebsitesDispatch}
              surveyChecks={surveyChecks}
              surveyDispatch={surveyChecksDispatch}
              dispatch={dispatch}
              isSelfRemediation={isSelfRemediation}
              isSubsidiary={isSubsidiary}
              vendorID={vendorID}
              isManagedVendorAnalyst={isManagedVendorAnalyst}
              managedOrgId={managedOrgId}
              loading={loading}
              risks={risks}
              filterForWebsite={filterForWebsite}
              selectedRisks={
                currentRequest
                  ? currentRequest.details?.risks
                  : draftRequest?.details.risks
              }
              filterForSurvey={
                !!location.state?.surveyIdsWithRisk ||
                !!location.state?.publicSurveyIdsWithRisk
              }
              forVendorAssessment={!!vendorAssessmentId}
              vendorAssessmentEvidence={linkedVendorAssessment?.evidence}
              vendorAssessmentIncludeWebscan={
                linkedVendorAssessment?.assessment.includeWebsiteRisks
              }
              requestId={requestId}
              writableDomainPortfolioIds={writableDomainPortfolioIds}
              selectedAdditionalEvidenceChecks={additionalEvidenceChecks}
              additionalEvidenceDispatch={additionalEvidenceChecksDispatch}
              history={history}
              location={location}
              urlPrefix={urlPrefix}
            />
          )}
          {currentStep === RemediationStep.ShareDocuments && (
            <ShareDocumentsStep
              documentsWithRisks={documentsWithSelectedRisks}
              selectedAdditionalEvidenceDocuments={additionalEvidenceDocuments}
              additionalEvidenceDocumentsDispatch={
                additionalEvidenceDocumentsDispatch
              }
              onDownloadDocument={(e) =>
                DownloadEvidenceFromRiskEvidence(e, false, dispatch)
              }
            />
          )}
          {currentStep === RemediationStep.Settings && (
            <SettingsStep
              existingUsers={contacts}
              onContactSelectionChanged={setSelectedContacts}
              selectedContacts={selectedContacts}
              hasInvalidVerifiedVendorContact={selectedContacts.some(
                (c) => c.emailDomainNotAllowed
              )}
              emailMessage={emailMessage}
              setEmailMessage={setEmailMessage}
              title={title}
              setTitle={setTitle}
              isSelfRemediation={isSelfRemediation}
              isSubsidiary={isSubsidiary}
              dueDate={dueDate}
              setDueDate={setDueDate}
              reminderDate={reminderDate}
              setReminderDate={setReminderDate}
              reminderSelection={reminderSelection}
              setReminderSelectionType={setReminderSelection}
              allSurveys={remediationSurveys}
              selectedSurveyIds={getSelectedSurveyIds()}
              onClickToAddToExistingRequest={onClickToAddToExistingRequest}
              vendorName={vendorName}
              selectedPublicSurveyIds={getSelectedSurveyIds(true)}
              vendorId={vendorID}
              orgUsers={orgUsers}
            />
          )}
          {currentStep === RemediationStep.ReviewAndSend && (
            <ReviewAndSendStep
              selectedContacts={selectedContacts}
              emailMessage={emailMessage}
              title={title}
              isSubsidiary={isSubsidiary}
              isSelfRemediation={isSelfRemediation}
              dueDate={dueDate}
              reminderDate={reminderDate}
              vendorName={vendorName}
              vendorID={vendorID}
              selectedCloudscanChecks={checksAndWebsites}
              selectedSurveyChecks={surveyChecks}
              risks={risks}
              selectedAdditionalEvidenceChecks={additionalEvidenceChecks}
              selectedDocuments={documentsWithSelectedRisks
                .filter((d) => !!additionalEvidenceDocuments[d.document.id])
                .map((d) => d.document)}
              onDownloadDocument={(e) =>
                DownloadEvidenceFromRiskEvidence(e, false, dispatch)
              }
              onEditVendor={
                // only set onEditVendor if there is an edit vendor/subsidiary step visible
                selectVendorOrSubsidaryStep
                  ? () => setCurrentStep(selectVendorOrSubsidaryStep.step)
                  : undefined
              }
              onEditRisks={() =>
                setCurrentStep(RemediationStep.SelectRisksAndAssets)
              }
              onEditDocuments={() =>
                setCurrentStep(RemediationStep.ShareDocuments)
              }
              onEditSettings={() => setCurrentStep(RemediationStep.Settings)}
              selectedSaasChecks={{}}
            />
          )}
        </div>
        {!isSelectVendorOrSubsidiaryStep(currentStep) && (
          <div className={"plan-side"}>
            <ScoreProjectionRequestRemediation
              projectedScore={projectedScore}
              nothingSelected={
                Object.keys(surveyChecks).length === 0 &&
                Object.keys(checksAndWebsites).length === 0 &&
                Object.keys(additionalEvidenceChecks).length === 0
              }
              numAffectedAssets={numSelectedAssets}
              numRisksSelected={numSelectedRisks}
              scoreDiff={projectedScore - initialScore}
              working={scoreProjectionWorking}
              surveyScoresNotMerged={
                !orgAccessSurveyScores && Object.keys(surveyChecks).length > 0
              }
              scoreOwner={vendorName}
            />
          </div>
        )}
      </div>
      <ActionBar active>
        <div className={"left-side"}>
          {!!goPrev && (
            <Button onClick={goPrev} leftArrow>
              Previous
            </Button>
          )}
        </div>
        <div className={"right-side"}>
          <Button onClick={goBack} tertiary>
            Cancel
          </Button>
          {!isEditingMode && (
            <Button
              disabled={numSelectedRisks === 0 || sendLoading}
              onClick={() => submitRequest(true)}
              loading={draftLoading}
            >
              Save draft
            </Button>
          )}
          <Button
            primary
            onClick={goNext}
            disabled={!visibleSteps[currentStepIndex].completed || draftLoading}
            arrow={!!nextStep}
            loading={sendLoading}
          >
            {!!nextStep ? "Next" : isEditingMode ? "Save" : "Submit request"}
          </Button>
        </div>
      </ActionBar>
    </StepsWithSections>
  );
};

RequestRemediationV2.defaultProps = {
  managedOrgId: undefined,
  defaultTexts: {},
  filteredRiskIDsForCVE: undefined,
  selectSingleWebsiteCloudscan: undefined,
};

// remove all the cloudscan remediations where the only remediation they are involved in is the current remediation request being edited
const removeCloudscanRemediationsForCurrentRequest = (
  cloudscansInRemediation: { [key: string]: CloudscanInRemediation },
  requestId?: number
): { [key: string]: CloudscanInRemediation } => {
  if (!requestId) return cloudscansInRemediation;
  const filteredCloudscans: { [key: string]: CloudscanInRemediation } = {};
  for (const k in cloudscansInRemediation) {
    if (
      cloudscansInRemediation[k].remediationRequestIds.length === 1 &&
      cloudscansInRemediation[k].remediationRequestIds[0] === requestId
    ) {
      continue;
    }
    filteredCloudscans[k] = cloudscansInRemediation[k];
  }
  return filteredCloudscans;
};

// remove all the additional evidence remediation where the only remediation they are involved in is the current remediation request being edited
const removeAdditionalEvidenceRemediationsForCurrentRequest = (
  additionalEvidenceInRemediation: {
    [key: string]: AdditionalEvidenceInRemediation;
  },
  requestId?: number
): { [key: string]: AdditionalEvidenceInRemediation } => {
  if (!requestId) return additionalEvidenceInRemediation;
  const filteredAdditionalEvidence: {
    [key: string]: AdditionalEvidenceInRemediation;
  } = {};
  for (const k in additionalEvidenceInRemediation) {
    if (
      additionalEvidenceInRemediation[k].remediationRequestIds.length === 1 &&
      additionalEvidenceInRemediation[k].remediationRequestIds[0] === requestId
    ) {
      continue;
    }
    filteredAdditionalEvidence[k] = additionalEvidenceInRemediation[k];
  }
  return filteredAdditionalEvidence;
};

const ConnectedRequestRemediationV2 = appConnect<
  IRequestRemediationV2ConnectedProps,
  undefined,
  IRequestRemediationV2OwnProps
>((state: DefaultRootState, props): IRequestRemediationV2ConnectedProps => {
  const currentOrg = getCurrentOrgFromUserData(state.common.userData);
  let customerVendorName = currentOrg ? currentOrg.name : "";
  let vendorData;

  if (props.isSelfRemediation) {
    const vendorName = currentOrg ? currentOrg.name : "";

    const defaultTexts = state.cyberRisk.orgDefaultTexts;

    // if we are adding a new request, i.e. !props.requestId, and there is a single
    // active remediation request than grab its ID so we can point the user to that
    let existingRemediationRequestId: number | undefined;
    if (!props.requestId) {
      const activeRequestIds =
        state.cyberRisk.customerData?.selfRemediationRequestIds?.filter(
          (id: number) => {
            const requestStatus =
              state.common.remediationRequests[id]?.details?.status;
            return (
              requestStatus === RemediationRequestStatus.Open ||
              requestStatus === RemediationRequestStatus.AwaitingReview
            );
          }
        );
      if (activeRequestIds && activeRequestIds.length === 1)
        existingRemediationRequestId = activeRequestIds[0];
    }

    vendorData = {
      vendorName,
      defaultTexts,
      contacts: [],
      remediationSurveys: [],
      existingRemediationRequestId,
    };
  } else {
    const { vendorID } = props;

    let vendorObj;
    if (props.isSubsidiary) {
      vendorObj = state.cyberRisk.subsidiaries[vendorID];
    } else if (props.isManagementAnalystSession) {
      vendorObj = _get(
        state.cyberRisk.managedVendorData,
        `[${props.managedOrgId}][${vendorID}]`
      );
      customerVendorName = _get(
        state.cyberRisk.managedVendorData,
        `[${props.managedOrgId}].name`,
        customerVendorName
      );
    } else {
      vendorObj = state.cyberRisk.vendors[vendorID];
    }

    const vendorName = _get(vendorObj, "display_name", "");
    const contacts = _get(vendorObj, "mgtlists.contacts.result", []) || [];

    const defaultTexts =
      props.isManagementAnalystSession &&
      props.managedOrgId &&
      state.cyberRisk.managedVendorData[props.managedOrgId]
        ? state.cyberRisk.managedVendorData[props.managedOrgId].orgDefaultTexts
        : state.cyberRisk.orgDefaultTexts;

    const remediationSurveys = vendorObj?.remediationSurveys ?? [];

    // if we are adding a new request, i.e. !props.requestId, and there is a single
    // active remediation request than grab its ID so we can point the user to that
    let existingRemediationRequestId: number | undefined;
    if (!props.requestId) {
      const activeRequestIds = vendorObj?.remediationRequestIds?.filter(
        (id: number) => {
          const requestStatus =
            state.common.remediationRequests[id]?.details?.status;
          return (
            requestStatus === RemediationRequestStatus.Open ||
            requestStatus === RemediationRequestStatus.AwaitingReview
          );
        }
      );
      if (activeRequestIds && activeRequestIds.length === 1)
        existingRemediationRequestId = activeRequestIds[0];
    }

    vendorData = {
      vendorName,
      contacts,
      defaultTexts,
      remediationSurveys,
      existingRemediationRequestId,
    };
  }

  let filteredRiskIDsForCVE;
  if (props.location.state && props.location.state.filterForCVEName) {
    filteredRiskIDsForCVE =
      state.cyberRisk.vulnRiskIDsByCVE[props.location.state.filterForCVEName];
  }

  let selectSingleWebsiteCloudscan;
  if (props.location.state && props.location.state.selectSingleWebsite) {
    selectSingleWebsiteCloudscan =
      state.cyberRisk.webscans[props.location.state.selectSingleWebsite]
        ?.result;
  } else if (props.filterForWebsite !== "") {
    selectSingleWebsiteCloudscan =
      state.cyberRisk.webscans[props.filterForWebsite]?.result;
  }

  let preselectedRisks: IPreselectedRisk[] | undefined;
  if (props.location.state && props.location.state.preselectedRisks) {
    preselectedRisks = props.location.state.preselectedRisks;
  }

  const permissions = state.common.permissions;

  let draftRequest: RemediationRequest | undefined;
  if (props.loadDraftId) {
    draftRequest = state.common.remediationRequests[props.loadDraftId];
  }
  let currentRequest: RemediationRequest | undefined;
  if (props.requestId) {
    currentRequest = state.common.remediationRequests[props.requestId];
  } else if (props.vendorAssessmentId) {
    currentRequest = getRemediationRequestDetailsForVendorAssessmentFromState(
      props.vendorAssessmentId,
      state
    );
  }

  return {
    ...vendorData,
    customerVendorName,
    filteredRiskIDsForCVE,
    selectSingleWebsiteCloudscan,
    canAccessSurveys: hasOrgPermission(permissions, OrgAccessSurveys),
    orgAccessSurveyScores: hasOrgPermission(
      permissions,
      OrgQuestionnaireScoresInclude
    ),
    orgAccessAdditionalEvidenceRiskRemediation: hasOrgPermission(
      permissions,
      OrgAccessAdditionalEvidenceRiskRemediation
    ),
    draftRequest,
    currentRequest,
    preselectedRisks,
    assuranceType: state.common.userData.assuranceType,
    orgUsers: state.cyberRisk.orgUserEmailAddresses.data,
  } as IRequestRemediationV2ConnectedProps;
})(RequestRemediationV2);

interface RequestRemediationV2WrapperConnectedProps {
  preSelectedVendorId?: number;
  isSubsidiary: boolean;
  isSelfRemediation: boolean;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  loadDraftId?: number;
  requestId?: number;
  vendorAssessmentId?: number;
  isManagedVendorAnalyst?: boolean;
}

interface RequestRemediationV2WrapperOwnProps {
  location: Location<remediationLocationState>;
  history: History;
}

type RequestRemediationV2Props = RequestRemediationV2WrapperOwnProps &
  RequestRemediationV2WrapperConnectedProps;

const RequestRemediationV2Wrapper = (props: RequestRemediationV2Props) => {
  const [vendorID, setVendorID] = useState(props.preSelectedVendorId ?? 0);
  const dispatch = useAppDispatch();

  const filterForWebsite = props.location?.state?.filterForWebsite ?? "";
  const params = GetQueryParams(props.location.search);
  const [prePick] = useState<string[] | undefined>(() => {
    const prePickString: string | undefined = params["prepick"];
    if (prePickString) {
      return prePickString.split(",");
    }
    return undefined;
  });

  return (
    <ConnectedRequestRemediationV2
      setVendorID={setVendorID}
      preSelectedVendorId={!!props.preSelectedVendorId}
      vendorID={vendorID}
      dispatch={dispatch}
      isSelfRemediation={props.isSelfRemediation}
      isSubsidiary={props.isSubsidiary}
      isManagementAnalystSession={props.isManagementAnalystSession}
      managedOrgId={props.managedOrgId}
      location={props.location}
      history={props.history}
      filterForWebsite={filterForWebsite}
      prePick={prePick}
      loadDraftId={props.loadDraftId}
      requestId={props.requestId}
      vendorAssessmentId={props.vendorAssessmentId}
    />
  );
};

RequestRemediationV2Wrapper.defaultProps = {
  vendorID: 0,
  managedOrgId: 0,
};

export default appConnect<
  RequestRemediationV2WrapperConnectedProps,
  undefined,
  DefaultRouteProps<
    { vendorId?: string; orgId?: string; requestId?: string },
    remediationLocationState
  >
>((state, props): RequestRemediationV2WrapperConnectedProps => {
  const preSelectedVendorId = props.match.params.vendorId
    ? parseInt(props.match.params.vendorId)
    : undefined;
  const isSelfRemediation = props.match.path.startsWith("/selfremediation");
  const isSubsidiary = props.match.path.startsWith("/subsidiaries");
  const userSystemRoles = state.common.userData.system_roles.reduce(
    (prev, perm) => {
      return { ...prev, [perm]: true };
    },
    {} as Record<string, boolean>
  );

  const userIsManagedVendorAnalyst =
    userSystemRoles[UserSystemRoleVendorManagementAnalyst];

  const isManagementAnalystSession =
    userIsManagedVendorAnalyst && props.match.path.startsWith("/analysts/tpvm");

  const managedOrgId = props.match.params.orgId
    ? parseInt(props.match.params.orgId)
    : undefined;

  const params = new URLSearchParams(props.location.search);
  const vendorAssessmentIdS = params.get("forVendorAssessment");
  const vendorAssessmentId = vendorAssessmentIdS
    ? parseInt(vendorAssessmentIdS)
    : undefined;

  const vendorAssessmentRemediationRequest = vendorAssessmentId
    ? getRemediationRequestDetailsForVendorAssessmentFromState(
        vendorAssessmentId,
        state
      )
    : undefined;

  const loadDraftId = props.location?.state?.loadDraftId
    ? props.location?.state?.loadDraftId
    : vendorAssessmentRemediationRequest &&
        vendorAssessmentRemediationRequest.details.status ===
          RemediationRequestStatus.Draft
      ? vendorAssessmentRemediationRequest?.details.id
      : undefined;

  const requestId = props.match.params.requestId
    ? parseInt(props.match.params.requestId)
    : vendorAssessmentRemediationRequest &&
        vendorAssessmentRemediationRequest.details.status !==
          RemediationRequestStatus.Draft
      ? vendorAssessmentRemediationRequest.details.id
      : undefined;

  return {
    preSelectedVendorId,
    isSelfRemediation,
    isSubsidiary,
    isManagementAnalystSession,
    managedOrgId,
    loadDraftId,
    requestId,
    vendorAssessmentId,
    isManagedVendorAnalyst: userIsManagedVendorAnalyst,
  };
})(RequestRemediationV2Wrapper);

import { FC, useCallback } from "react";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import Button from "../../../_common/components/core/Button";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import TrustPageCustomDomainStatusDisplay from "./TrustPageCustomDomainStatusDisplay";
import TrustPageCustomDomainsAPI, {
  TrustPageCustomDomainsTagTypes,
} from "../../api/trustPageCustomDomains.api";
import { TrustPageCustomDomain } from "../../api/trustpage.api.types";

interface TrustPageCustomDomainsTableProps {
  domains: TrustPageCustomDomain[];
  onDomainClick: (domain: string) => void;
}

const TrustPageCustomDomainsTable: FC<TrustPageCustomDomainsTableProps> = ({
  domains,
  onDomainClick,
}) => {
  const dispatch = useAppDispatch();
  const [deleteCustomDomain] =
    TrustPageCustomDomainsAPI.useDeleteTrustPageCustomDomainV1Mutation();

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const deleteDomain = useCallback(
    (domain: string) => {
      deleteCustomDomain({ domain })
        .unwrap()
        .then(() => {
          dispatch(addDefaultSuccessAlert("Custom domain removed."));

          // TODO: why do I need this?
          dispatch(
            TrustPageCustomDomainsAPI.util.invalidateTags([
              TrustPageCustomDomainsTagTypes.trustPageCustomDomains,
            ])
          );
        })
        .catch((err) => {
          dispatch(addDefaultUnknownErrorAlert("Error removing custom domain"));
          throw err;
        });
    },
    [deleteCustomDomain]
  );

  const confirmDeleteDomain = useCallback((domain: string) => {
    openConfirmationModal({
      title: "Are you sure you want to remove this custom domain?",
      description:
        "This will delete your custom domain and revert it to the default UpGuard domain.",
      filledDangerousAction: true,
      buttonAction: () => deleteDomain(domain),
      buttonText: "Yes, remove",
    });
  }, []);

  const rows: IXTableRow[] = domains.map((d) => ({
    id: d.domain,
    cells: [
      <XTableCell key={"domain"}>{d.domain}</XTableCell>,
      <XTableCell key={"status"}>
        <TrustPageCustomDomainStatusDisplay
          domain={d.domain}
          status={d.status}
          onClick={() => onDomainClick(d.domain)}
        />
      </XTableCell>,
      <XTableCell key={"remove"}>
        <Button tertiaryDanger onClick={() => confirmDeleteDomain(d.domain)}>
          <i className={"cr-icon-trash-2"} /> Remove
        </Button>{" "}
      </XTableCell>,
    ],
  }));

  return (
    <>
      <XTable className={"trust-page-custom-domains-table"} rows={rows} />
      {confirmationModal}
    </>
  );
};

export default TrustPageCustomDomainsTable;

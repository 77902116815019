import ReportCard, {
  ExpandableReportCard,
} from "../../_common/components/ReportCard";
import SlidePanel from "../../_common/components/SlidePanel";
import MessagesPanel from "../../_common/components/MessagesPanel";
import { DefaultRouteProps } from "../../_common/types/router";
import "./ThreatMonitoringAnalyticsView.scss";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { HistoryBackButton } from "../../_common/components/HistoryBackButton";
import "./ThreatDetailView.scss";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringResultState,
} from "../api/types";
import { useCurrentUser } from "../../_common/selectors/commonSelectors";
import { IUserMini } from "../../_common/types/user";
import ActionBar from "../../_common/components/ActionBar";
import { useResultSessionPagination } from "../funcs/usePagination";
import Button from "../../_common/components/core/Button";
import { ThreatDetailCard } from "../components/ThreatDetailCard";
import { ThreatDetailPreview } from "../components/ThreatDetailPreview";
import classnames from "classnames";
import DropdownButton from "../../_common/components/DropdownButton";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { ConsumeQueryParams, GetQueryParams } from "../../_common/query";
import ThreatTimeline from "../components/ThreatTimeline";
import { formatDateTimeAsLocal } from "../../_common/helpers";
import { ReactNode, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../components/ThreatMonitoringTabs.scss";
import { useModalV2 } from "../../_common/components/ModalV2";
import CreateRemediationdModal from "../components/CreateRemediationModal";
import ThreatCommentsButton from "../components/ThreatCommentsButton";
import useUpdateResultState from "../funcs/useUpdateResultState";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import RemediateThreatModal from "../components/RemediateThreatModal";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import moment from "moment";
import { threatRemediationOutcomeTypeToString } from "../funcs/domain";
import useOrganisationUsersAndInvites, {
  OrganisationUserEmailOrInvite,
} from "../../_common/hooks/useOrganisationUsersAndInvites";
import { upperFirst } from "lodash";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice from "../Slice";
import { useNavToRemediationRequest } from "../funcs/useNav";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";

type Params = {
  threatUUID: string;
};
interface ThreatDetailViewProps extends DefaultRouteProps<Params> {}

export enum Tab {
  Details = 0,
  Timeline = 1,
}

// If set (with any value) the comments draw will open when this view loads.
const openCommentsQueryParamName = "open_comments";

// ThreatDetailView is the top level view. It uses the environment to determine whether we're in a session or standalone mode.
export default function ThreatDetailView({ match }: ThreatDetailViewProps) {
  const session = useAppSelector(tmSlice.selectors.sessionSnapshot);

  const { threatUUID } = match.params;

  const history = useHistory();

  const [startWithCommentsOpen] = useState(
    GetQueryParams(location.search)[openCommentsQueryParamName] !== undefined
  );

  useEffect(() => {
    ConsumeQueryParams(history, [openCommentsQueryParamName], {
      preserveState: true,
    });
  }, []);

  return (
    <>
      {session.uuids.length ? (
        <ThreatDetailInSession threatUUID={threatUUID} />
      ) : (
        <ThreatDetailStandalone
          threatUUID={threatUUID}
          startWithCommentsOpen={startWithCommentsOpen}
        />
      )}
    </>
  );
}

type ThreatDetailInSessionProps = {
  threatUUID: string;
};

// ThreatDetailInSession is the view for viewing a threat in the context of a threat review session.
// It uses the useResultSessionPagination hook to manage pagination.
export function ThreatDetailInSession({
  threatUUID,
}: ThreatDetailInSessionProps) {
  const {
    result,
    actors,
    isLoading,
    error,
    idx,
    totalInSession,
    totalOpen,
    next,
    hasNext,
    prev,
    hasPrev,
  } = useResultSessionPagination({
    uuid: threatUUID,
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading || !result) {
    return <LoadingBanner />;
  }

  return (
    <>
      <OneThreatDetail threat={result} actors={actors} className="in-session">
        <div className="action-bar-offset" />
        <ActionBar active>
          <div
            className={classnames("arrow", { disabled: !hasPrev })}
            onClick={prev}
          >
            <div className={"cr-icon-chevron rot180"} />
          </div>
          <div className="threat-count">
            Viewing threat: <strong>{idx}</strong> of{" "}
            <strong>{totalInSession}</strong>
            {totalInSession !== totalOpen && (
              <span className="total-open">&nbsp;({totalOpen} open)</span>
            )}
          </div>
          <div
            className={classnames("arrow", { disabled: !hasNext })}
            onClick={next}
          >
            <div className={"cr-icon-chevron"} />
          </div>
        </ActionBar>
      </OneThreatDetail>
    </>
  );
}

type ThreatDetailStandaloneProps = {
  threatUUID: string;
  startWithCommentsOpen?: boolean;
};
function ThreatDetailStandalone({
  threatUUID,
  startWithCommentsOpen,
}: ThreatDetailStandaloneProps) {
  const { data, isLoading } = ThreatMonitoringAPI.useGetResultV1Query({
    uuid: threatUUID,
  });

  const { data: users, isFetching: isFetchingUsers } =
    useOrganisationUsersAndInvites();

  if (isLoading || !data || !users || isFetchingUsers) {
    return <LoadingBanner />;
  }

  return (
    <OneThreatDetail
      threat={data.result}
      actors={data.actors}
      remediatedBy={users.find((u) => u.id === data.result.remediatedByID)}
      startWithCommentsOpen={startWithCommentsOpen}
    />
  );
}

interface OneThreatDetailProps {
  threat: DisplayableThreatMonitoringResult;
  actors?: IUserMini[];
  remediatedBy?: OrganisationUserEmailOrInvite;
  children?: React.ReactNode;
  className?: string;
  startWithCommentsOpen?: boolean;
}

export function OneThreatDetail({
  threat,
  actors: actorList,
  remediatedBy,
  className,
  children,
  startWithCommentsOpen,
}: OneThreatDetailProps) {
  const selectedTab = useAppSelector(tmSlice.selectors.currentDetailTab);
  const dispatch = useAppDispatch();
  const setSelectedTab = (tab: Tab) =>
    dispatch(tmSlice.actions.setCurrentDetailTab(tab));

  const actors = (actorList || []).reduce(
    (acc, actor) => ({
      ...acc,
      [actor.id]: actor,
    }),
    {} as Record<string, IUserMini>
  );

  const dismissedBy = actors[threat.dismissedById || 0];
  let dismissedMessage: ReactNode = "";
  if (threat.dismissed) {
    if (!dismissedBy) {
      dismissedMessage = `This threat was dismissed at ${formatDateTimeAsLocal(
        threat.dismissedAt
      )}`;
    } else {
      dismissedMessage = (
        <>
          This threat was dismissed by&nbsp;
          <b>{dismissedBy.name}</b>, {formatDateTimeAsLocal(threat.dismissedAt)}
        </>
      );
    }
  }

  return (
    <div className={classnames("threat-monitoring-detail-view", className)}>
      <PageHeaderLite>
        <>
          <HistoryBackButton
            backText="Back to Threat Monitoring"
            fallbackLocation={"/breachrisk/threatmonitoring"}
          />
          <h3>Threat details</h3>
        </>
        <div className="controls">
          <Controls
            threat={threat}
            startWithCommentsOpen={startWithCommentsOpen}
          />
        </div>
      </PageHeaderLite>

      {threat.dismissed ? (
        <InfoBanner message={dismissedMessage} className="dismissed-info" />
      ) : threat.outcome ? (
        <InfoBanner
          className="remediated-banner"
          type={BannerType.SUCCESS}
          message={
            <>
              <div className="message">
                Threat remediated by&nbsp;<b>{remediatedBy?.name}</b>,{" "}
                {moment(threat.remediatedAt).format("ll h:mma")}
              </div>
              {threat.outcome && (
                <div className="message">
                  <b>Outcome:</b>{" "}
                  {threatRemediationOutcomeTypeToString(threat.outcome) ??
                    upperFirst(threat.outcome)}
                </div>
              )}
              {threat.outcomeNotes && (
                <div className="message">
                  <b>Additional information:</b> {threat.outcomeNotes}
                </div>
              )}
            </>
          }
        />
      ) : (
        threat.remediationRequestID && (
          <InfoBanner
            message={"Remediation has been requested for this threat."}
            className="remediation-requested-info"
          />
        )
      )}

      <div className={"threat-monitoring-tabs"}>
        <div
          className={classnames("tab", {
            selected: selectedTab == Tab.Details,
          })}
          onClick={() => setSelectedTab(Tab.Details)}
        >
          <div className="label">Details</div>
        </div>
        <div className="sep" />
        <div
          className={classnames("tab", {
            selected: selectedTab == Tab.Timeline,
          })}
          onClick={() => setSelectedTab(Tab.Timeline)}
        >
          <div className="label">Timeline</div>
        </div>
      </div>

      {selectedTab === Tab.Details && (
        <>
          <ExpandableReportCard
            newStyles
            className="threat-detail"
            headerContent={<>{threat.title}</>}
          >
            <ThreatDetailCard threat={threat} actors={actors} />
          </ExpandableReportCard>
          <ExpandableReportCard
            newStyles
            className="threat-preview"
            headerContent={<>Preview</>}
          >
            <ThreatDetailPreview threat={threat} />
          </ExpandableReportCard>
        </>
      )}

      {selectedTab === Tab.Timeline && (
        <ReportCard newStyles>
          <ThreatTimeline threatUUID={threat.uuid} />
        </ReportCard>
      )}
      {children}
    </div>
  );
}

interface ControlsProps {
  threat: DisplayableThreatMonitoringResult;
  startWithCommentsOpen?: boolean;
}

interface DismissedMessageProps {
  onUndo: () => void;
}
function DismissedMessage({ onUndo }: DismissedMessageProps) {
  return (
    <div className="dismissed-message">
      <div className="message">Threat dismissed. Nice work! 💪</div>
      <div className="undo">
        <Button tertiary onClick={onUndo}>
          <div className={"icon cr-icon-undo1"} />
          Undo
        </Button>
      </div>
    </div>
  );
}

function Controls({ threat, startWithCommentsOpen }: ControlsProps) {
  const [openRemediationModal, remediationModal] = useModalV2(
    CreateRemediationdModal
  );
  const [openRemediateThreatModal, remediateThreatModal] =
    useModalV2(RemediateThreatModal);
  const navToRemediationRequest = useNavToRemediationRequest({
    backToText: "Back to Threat Detail",
    useGoBack: true,
  });

  const [investigating, isLoadingInvestigating] = useUpdateResultState({
    state: ThreatMonitoringResultState.Investigating,
    successMsg: "Threat was moved to investigating successfully.",
    failMsg: "Failed to move threat to investigating.",
  });

  const [dismiss, isLoadingDismissing] = useUpdateResultState({
    state: ThreatMonitoringResultState.Dismissed,
    successMsg: <DismissedMessage onUndo={() => investigating(threat.uuid)} />,
    failMsg: "Failed to dismiss threat.",
  });

  const [remediating] = useUpdateResultState({
    state: ThreatMonitoringResultState.Remediating,
    successMsg: null,
    failMsg: "Failed to move threat to remediating.",
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const remediationItems = [
    {
      headerText: "Request remediation",
      description: "Ask another user to remediate this threat",
      hide: false,
      onClick: () => {
        openRemediationModal({
          threat,
          onCreateRemediationRequest: (remediationRequestId: number) =>
            remediating(threat.uuid, { target: { remediationRequestId } }),
        });
      },
    },
    {
      headerText: "Close threat",
      description: "Mark this threat as remediated",
      hide: false,
      onClick: () => {
        openRemediateThreatModal({
          threat,
        });
      },
    },
  ];

  if (
    threat.dismissed ||
    (threat.remediatedAt && !threat.remediationRequestID)
  ) {
    return (
      <>
        <ThreatCommentsControl
          threat={threat}
          startWithCommentsOpen={startWithCommentsOpen}
        />
        <Button
          onClick={() => setShowConfirmationModal(true)}
          loading={isLoadingInvestigating}
        >
          <div className={"icon cr-icon-undo1"} />
          Reopen
        </Button>
        <ConfirmationModalV2
          title={`Reopen threat?`}
          description={`This will move it back to investigating, and assign you as the Investigator.`}
          width={600}
          buttonText={`Reopen threat`}
          iconClass={"confirm-reopen-threat-modal cr-icon-undo1"}
          cancelText="Cancel"
          buttonAction={async () => {
            investigating(threat.uuid);
          }}
          active={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
        />
      </>
    );
  }

  if (threat.remediationRequestID) {
    return (
      <>
        <ThreatCommentsControl
          threat={threat}
          startWithCommentsOpen={startWithCommentsOpen}
        />
        <Button
          primary
          onClick={navToRemediationRequest(threat.remediationRequestID || 0)}
        >
          View remediation request
          <div className={"icon cr-icon-arrow-right"} />
        </Button>
      </>
    );
  }

  const common = (
    <>
      <ThreatCommentsControl
        threat={threat}
        startWithCommentsOpen={startWithCommentsOpen}
      />
      <Button
        danger
        onClick={() => dismiss(threat.uuid)}
        loading={isLoadingDismissing}
      >
        <div className={"icon cr-icon-cancel"} />
        Dismiss
      </Button>
      <DropdownButton title={"Address threat"} items={remediationItems} />
      {remediationModal}
      {remediateThreatModal}
    </>
  );

  if (threat.investigating) {
    return (
      <>
        <SidePopupV2
          text={
            <>
              Threats cannot be removed from Investigating.
              <br />
              Dismiss or Address the threat to continue.
            </>
          }
          position="bottom"
          width={350}
        >
          <Button tertiary disabled>
            <div className={"icon cr-icon-bookmark"} />
            Investigating
          </Button>
        </SidePopupV2>
        {common}
      </>
    );
  }

  // Open
  return (
    <>
      <Button
        tertiary
        onClick={() => investigating(threat.uuid)}
        loading={isLoadingInvestigating}
      >
        <div className={"icon cr-icon-add-bookmark"} />
        Add to investigating
      </Button>
      {common}
    </>
  );
}

interface ThreatCommentsControlProps {
  threat: DisplayableThreatMonitoringResult;
  startWithCommentsOpen?: boolean;
}

function ThreatCommentsControl({
  threat,
  startWithCommentsOpen,
}: ThreatCommentsControlProps) {
  const currentUser = useCurrentUser();
  const { data: usersData, isLoading: usersIsLoading } =
    ThreatMonitoringAPI.useGetReadWriteUsersV1Query();

  const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(
    startWithCommentsOpen ? true : false
  );

  const [threatResultCommentsAddV1] =
    ThreatMonitoringAPI.useThreatResultCommentsAddV1Mutation();

  const [threatResultCommentsEditV1] =
    ThreatMonitoringAPI.useThreatResultCommentsEditV1Mutation();

  const [
    threatResultCommentsTrigger,
    {
      data: threatResultCommentsData,
      isLoading: threatResultCommentsIsLoading,
    },
  ] = ThreatMonitoringAPI.useLazyThreatResultCommentsListV1Query({});

  return (
    <>
      <ThreatCommentsButton
        totalMessages={threat.numberOfComments}
        haveUnreadMessages={threat.hasUnreadComments}
        onClick={() => {
          setCommentsDrawerOpen(!commentsDrawerOpen);
        }}
      />
      <SlidePanel
        newStyles
        onClose={() => {
          setCommentsDrawerOpen(false);
        }}
        active={commentsDrawerOpen}
        dimContent
        className={"comments-panel-container"}
        title={"Comments"}
      >
        <MessagesPanel
          hasWritePermission={true}
          allowedPrivateMessages={false}
          currentUserId={currentUser.id}
          addMessage={(parentId: number | undefined, content: string) => {
            return threatResultCommentsAddV1({
              threatResultUUID: threat.uuid,
              commentContent: content,
              commentParentID: parentId,
            })
              .unwrap()
              .then(() => {
                return threatResultCommentsTrigger(
                  { threatResultUUID: threat.uuid },
                  false
                ).unwrap();
              });
          }}
          editMessage={(messageId: number, newContent: string) => {
            return threatResultCommentsEditV1({
              commentID: messageId,
              commentContent: newContent,
            })
              .unwrap()
              .then(() => {
                return threatResultCommentsTrigger(
                  { threatResultUUID: threat.uuid },
                  false
                ).unwrap();
              });
          }}
          fetchMessages={(_: boolean, noMarkRead?: boolean) => {
            return threatResultCommentsTrigger(
              { threatResultUUID: threat.uuid, markAsRead: !noMarkRead },
              false
            ).unwrap();
          }}
          messages={threatResultCommentsData}
          users={usersData?.users}
          loading={usersIsLoading || threatResultCommentsIsLoading}
          onSubmitParentCommentButtonText="Add comment"
          parentCommentInputPlaceholderText="Add a comment"
          preMessagesContent={
            <>
              <div className="date-detected">
                Date detected: {moment(threat.dateDetected).format("ll")},{" "}
                {moment(threat.dateDetected).format("HH:mm")}
              </div>
            </>
          }
        />
      </SlidePanel>
    </>
  );
}

interface PageHeaderLiteProps {
  children: React.ReactNode[] & { length: 2 };
}

function PageHeaderLite({
  children: [title, rightSection],
}: PageHeaderLiteProps) {
  return (
    <div className="page-header-lite">
      {title}
      <div className="page-title-right">{rightSection}</div>
    </div>
  );
}

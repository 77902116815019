import { useAppDispatch, useAppSelector } from "../../types/reduxHooks";
import { ClickNavLinkFunc } from "./BreachSightNavItems";
import { userIsVendorManagementAnalyst } from "../../helpers/userOrg.helpers";
import NavLink from "../NavLink";
import { matchPath, useHistory } from "react-router-dom";
import Icon from "../core/Icon";
import { pageDescriptions } from "../../pageDescriptions";
import * as Permissions from "../../permissions";
import { GroupAccessVendorAssetSharing } from "../../permissions";
import classnames from "classnames";
import {
  useManagedOrgID,
  useManagedVendorID,
  useVendorWords,
} from "../../hooks";
import { setViewingManagedVendorIdForOrgId } from "../../reducers/commonActions";
import { getSubtitleDisplay } from "../../helpers/string.helpers";
import { useEffect } from "react";

type AnalystPortalNavItemsProps = {
  clickNavLinkFunc: ClickNavLinkFunc;
};

const AnalystPortalNavItems = (props: AnalystPortalNavItemsProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.common.userData);

  const isVendorManagementAnalyst = userIsVendorManagementAnalyst(userData);
  const isCyberResearchAnalyst = userData.isAnalyst;

  const managedOrgId = useManagedOrgID();
  const managedVendorId = useManagedVendorID();

  const showManagedVendorAnalystVendorMenu =
    managedOrgId && managedVendorId && userIsVendorManagementAnalyst;

  const vendorWords = useVendorWords();

  const isOnAnalystManagedVendorRoute = !!managedVendorId && !!managedOrgId;

  const managedData = useAppSelector(
    (state) => state.cyberRisk.managedVendorData
  );

  const managedOrgData = managedOrgId ? managedData[managedOrgId] : undefined;

  const managedVendorData =
    managedOrgId && managedVendorId
      ? managedData[managedOrgId]?.[managedVendorId]
      : undefined;

  const managedOrgPermissions = managedOrgData?.entitlements ?? [];
  const managedOrgGroupPermissions = managedOrgData?.groupEntitlements ?? [];

  const hasManagedOrgPermission = (perm: string) => {
    return Object.values(managedOrgPermissions)?.includes(perm);
  };

  const hasManagedOrgGroupPermission = (perm: string) => {
    return managedOrgGroupPermissions?.includes(perm);
  };

  const closeVendorSubmenu = () => {
    dispatch(setViewingManagedVendorIdForOrgId(null, null));
    if (isOnAnalystManagedVendorRoute) {
      history.push("/analysts/tpvm");
    }
  };

  let vendorName;
  let sharedAssessmentPublished;

  if (managedVendorId && managedOrgId) {
    vendorName = managedVendorData?.display_name;
    sharedAssessmentPublished = managedVendorData?.sharedAssessmentPublished;
  }

  useEffect(() => {
    if (managedVendorId && managedOrgId) {
      dispatch(
        setViewingManagedVendorIdForOrgId(managedOrgId, managedVendorId)
      );
    }
  }, [managedOrgId, managedVendorId]);

  return (
    <>
      <div className="product-name">Analyst Portal</div>
      <div className="product-navigation-items">
        {isVendorManagementAnalyst && (
          <NavLink
            text="Vendor Assessments"
            onClick={props.clickNavLinkFunc(
              "/analysts/tpvm",
              "VendorManagement Analyst"
            )}
            to="/analysts/tpvm"
            isActive={(_, location) =>
              !!matchPath(location.pathname, {
                path: "/analysts/tpvm",
                exact: false,
              })
            }
          />
        )}
        {!!showManagedVendorAnalystVendorMenu && (
          <div
            className={classnames("nav-sub-section", {
              active: isOnAnalystManagedVendorRoute,
            })}
          >
            <div
              className={classnames("subtitle header-section-name", {
                active: isOnAnalystManagedVendorRoute,
              })}
              onClick={closeVendorSubmenu}
            >
              <div className="subtitle vendor-name">
                {getSubtitleDisplay(vendorName ?? "")}
              </div>
              <Icon name="x" />
            </div>
            <NavLink
              text={`${vendorWords.singularTitleCase} Summary`}
              hoverText={pageDescriptions.VendorSummary}
              exact
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}`,
                `${vendorWords.singularTitleCase} Summary`,
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}`}
            />
            <NavLink
              text="Risk Profile"
              hoverText={pageDescriptions.VendorRiskProfile}
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/risk_profile`,
                "Portfolio Risk Profile",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/risk_profile`}
            />
            <NavLink
              text={"Modified Risks"}
              hoverText={pageDescriptions.RiskWaivers}
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedVendorId}/riskwaivers`,
                "Modified Risks",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/riskwaivers`}
            />
            <NavLink
              text="Remediation"
              hoverText={pageDescriptions.VendorRemediation}
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/remediation`,
                "Vendor Remediation",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/remediation`}
            />
            {hasManagedOrgPermission(
              Permissions.OrgAccessVendorAssessments
            ) && (
              <NavLink
                text="Risk Assessment"
                hoverText={pageDescriptions.VendorAssessment}
                onClick={props.clickNavLinkFunc(
                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/assessment`,
                  "Vendor Risk Assessment",
                  managedVendorId?.toString()
                )}
                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/assessment`}
              />
            )}
            <NavLink
              text="Domains"
              hoverText={pageDescriptions.VendorWebsitesAndAPIs}
              exact
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/webscans`,
                "Domains",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/webscans`}
            />
            <NavLink
              text="IP Addresses"
              hoverText={pageDescriptions.VendorIPAddresses}
              exact
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/ips`,
                "IP Addresses",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/ips`}
            />
            {hasManagedOrgPermission(Permissions.OrgAccessSurveys) && (
              <NavLink
                text="Questionnaires"
                hoverText={pageDescriptions.VendorQuestionnaires}
                onClick={props.clickNavLinkFunc(
                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/surveys`,
                  "Vendor Questionnaires",
                  managedVendorId?.toString()
                )}
                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/surveys`}
              />
            )}
            {hasManagedOrgPermission(
              Permissions.OrgAccessAdditionalEvidence
            ) && (
              <NavLink
                text="Additional Evidence"
                hoverText={pageDescriptions.AdditionalEvidence}
                onClick={props.clickNavLinkFunc(
                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/evidence`,
                  "Additional Evidence"
                )}
                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/evidence`}
              />
            )}
            {hasManagedOrgPermission(Permissions.OrgAccessVulns) && (
              <NavLink
                text="Vulnerabilities"
                hoverText={pageDescriptions.VendorVulns}
                exact
                onClick={props.clickNavLinkFunc(
                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/vulns`,
                  "Vendor Vulnerabilities"
                )}
                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/vulns`}
              />
            )}
            {hasManagedOrgPermission(
              Permissions.OrgAccessVendorTechnologies
            ) && (
              <NavLink
                text="Fourth Parties"
                hoverText={pageDescriptions.SupplyChain}
                exact
                onClick={props.clickNavLinkFunc(
                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/fourthParty`,
                  "Vendor Fourth Parties",
                  managedVendorId?.toString()
                )}
                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/fourthParty`}
              />
            )}
            <NavLink
              text="Managed Assessments"
              exact
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/managedAssessments`,
                "Vendor Managed Assessments",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/managedAssessments`}
            />
            {hasManagedOrgGroupPermission(GroupAccessVendorAssetSharing) ? (
              <NavLink
                text="Shared Assets"
                hoverText={pageDescriptions.VendorSharedAssets}
                onClick={props.clickNavLinkFunc(
                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassets`,
                  "Vendor Documents",
                  managedVendorId?.toString()
                )}
                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassets`}
              />
            ) : (
              sharedAssessmentPublished && (
                <NavLink
                  text="Trust Page"
                  hoverText={pageDescriptions.SharedAssessment}
                  exact
                  onClick={props.clickNavLinkFunc(
                    `/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassessment`,
                    "Trust Page",
                    managedVendorId?.toString()
                  )}
                  to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassessment`}
                />
              )
            )}
            <NavLink
              text="Documents"
              hoverText={pageDescriptions.VendorDocuments}
              exact
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/documents`,
                "Vendor Documents",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/documents`}
            />
            <NavLink
              text="Contacts"
              hoverText={pageDescriptions.VendorContacts}
              exact
              onClick={props.clickNavLinkFunc(
                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/contacts`,
                "Vendor Contacts",
                managedVendorId?.toString()
              )}
              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/contacts`}
            />
          </div>
        )}
        {isCyberResearchAnalyst && (
          <div>
            <NavLink
              text="CyberResearch Analysis"
              onClick={props.clickNavLinkFunc(
                "/analysts/dataleaks",
                "Data Leak Analysis"
              )}
              to="/analysts/dataleaks"
            />
            <NavLink
              text="News Queue"
              onClick={props.clickNavLinkFunc(
                "/analysts/newsqueue",
                "News Queue"
              )}
              to="/analysts/newsqueue"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AnalystPortalNavItems;

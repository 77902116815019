import { IReportsLibraryItem } from "./ReportsLibrary";
import {
  CannedExportConfig,
  CustomCannedExportConfig,
  StarredExportType,
} from "../../../_common/types/exportConfig";
import { FC } from "react";
import { BadgeModule } from "./ReportTypeBadge";

import "../../style/components/reporting/ReportsLibraryGrid.scss";
import IconButton from "../../../_common/components/IconButton";
import DropdownV2, {
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import classnames from "classnames";
import Button from "../../../_common/components/core/Button";
import Dot from "../../../_common/components/Dot";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";

interface IReportsLibraryGridProps {
  libraryItems: IReportsLibraryItem[];
  onCustomizeAndGenerate: (conf: CannedExportConfig) => void;
  onQuickGenerate: (conf: CannedExportConfig) => void;
  onCloneTemplate: (conf: CannedExportConfig) => void;
  onEditTemplate: (conf: CustomCannedExportConfig) => void;
  onDeleteTemplate: (conf: CustomCannedExportConfig) => void;
  onStarTemplate: (
    starredExportType: StarredExportType,
    starred: boolean
  ) => void;
  canCreateEditOwnTemplates: boolean;
  canCreateEditOrgTemplates: boolean;
  currentUserId: number;
}

const ReportsLibraryGrid: FC<IReportsLibraryGridProps> = ({
  libraryItems,
  onCustomizeAndGenerate,
  onQuickGenerate,
  onCloneTemplate,
  onEditTemplate,
  onDeleteTemplate,
  onStarTemplate,
  canCreateEditOwnTemplates,
  canCreateEditOrgTemplates,
  currentUserId,
}) => {
  return libraryItems.length === 0 ? (
    <SearchEmptyCard searchItemText="templates" />
  ) : (
    <div className="reports-library-grid">
      {libraryItems.map((item) => (
        <div key={item.id} className="report-grid-item">
          <div
            className="item-top"
            style={{ backgroundColor: item.badgeImageRef.backgroundColor }}
          >
            <img
              className={classnames("item-bg", {
                landscape: item.badgeImageRef.landscape,
              })}
              alt={item.name}
              src={item.badgeImageRef.image}
            />
            <div className="item-overlay">
              <div className="item-desc">{item.description}</div>
              {item.defaultCannedConfig ?? item.customCannedConfig ? (
                <>
                  <Button
                    filledPrimary
                    onClick={() =>
                      onQuickGenerate(
                        item.defaultCannedConfig ?? item.customCannedConfig!
                      )
                    }
                  >
                    Quick generate
                  </Button>
                  <Button
                    tertiary
                    onClick={() =>
                      onCustomizeAndGenerate(
                        item.defaultCannedConfig ?? item.customCannedConfig!
                      )
                    }
                  >
                    Customize and generate
                  </Button>
                </>
              ) : (
                <Button filledPrimary onClick={item.adhocGenerateAction}>
                  Generate
                </Button>
              )}
            </div>
            <SidePopupV2
              title={item.starred ? undefined : "Add to favorites"}
              text={
                item.starred
                  ? "Remove from favorites"
                  : "Your favorites will be shown first in this list."
              }
              position="top"
              className="star"
              micro
              noWrap={item.starred}
              width={item.starred ? undefined : 150}
              popupDelay={600}
            >
              <IconButton
                onClick={() =>
                  onStarTemplate(
                    {
                      customCannedExportUUID: item.customCannedConfig?.uuid,
                      defaultCannedConfigID:
                        item.defaultCannedConfig?.defaultID,
                      otherReportID: item.otherReportID,
                    },
                    !item.starred
                  )
                }
                icon={
                  <div
                    className={
                      item.starred
                        ? "cr-icon-star-filled"
                        : "cr-icon-star-outline"
                    }
                  />
                }
              />
            </SidePopupV2>
            {canCreateEditOwnTemplates &&
              (item.defaultCannedConfig ?? item.customCannedConfig) && (
                <DropdownV2
                  className="actions-dropdown"
                  popupItem={
                    <IconButton icon={<div className="cr-icon-dots-menu" />} />
                  }
                >
                  <DropdownItem
                    onClick={() =>
                      onCloneTemplate(
                        item.defaultCannedConfig ?? item.customCannedConfig!
                      )
                    }
                  >
                    <div className="cr-icon-copy" /> Copy to new template
                  </DropdownItem>
                  {!!item.customCannedConfig &&
                    (canCreateEditOrgTemplates ||
                      item.customCannedConfig?.createdBy === currentUserId) && (
                      <>
                        <DropdownItem
                          onClick={() =>
                            onEditTemplate(item.customCannedConfig!)
                          }
                        >
                          <div className="cr-icon-edit-doc" /> Edit template
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            onDeleteTemplate(item.customCannedConfig!)
                          }
                        >
                          <div className="cr-icon-trash" /> Delete template
                        </DropdownItem>
                      </>
                    )}
                </DropdownV2>
              )}
          </div>
          <div className="item-bottom">
            <div className="report-type">
              <div className="module-container">
                <div
                  className={`module-i coloured ${
                    item.module === BadgeModule.Breachsight
                      ? "cr-icon-breachsight"
                      : item.module === BadgeModule.VendorRisk
                        ? "cr-icon-vendor-risk"
                        : "cr-icon-upguard-logo-pick"
                  }`}
                />
              </div>
              <div className="report-type-type">{item.reportType}</div>
            </div>
            <div className="report-name">
              {item.isNew && (
                <div className="dot-container">
                  <Dot color="blue" />
                </div>
              )}
              <div className="report-name-name">{item.name}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportsLibraryGrid;

import { ReactNode } from "react";

import "../style/ValidationErrorText.scss";

const ValidationErrorText = (props: { children: ReactNode }) => (
  <div className="validation-error">
    <div className="cr-icon-risk" /> {props.children}
  </div>
);

export default ValidationErrorText;

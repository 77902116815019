import { useState } from "react";
import {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import PaginatedTable from "../../vendorrisk/components/PaginatedTable";
import "./KeywordsTable.scss";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import moment from "moment";
import CircledIcon from "../../_common/components/CircledIcon";
import {
  SYSTEM_USER_ID,
  ThreatMonitoringCriticality,
  ThreatMonitoringKeyword,
} from "../api/types";
import IconButton from "../../_common/components/IconButton";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import classNames from "classnames";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import { useOrgUserMap } from "../funcs/useUserMap";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";
import { TMUserAndAvatar } from "./Users";
import EllipsizedText from "../../_common/components/EllipsizedText";
import { useSorting } from "../../_common/hooks";
import {
  criticalityColor,
  criticalityStr,
  keywordTypeStr,
} from "../funcs/domain";

const criticalitySortOrder = (
  criticality?: ThreatMonitoringCriticality
): number => {
  switch (criticality) {
    case ThreatMonitoringCriticality.High:
      return 3;
    case ThreatMonitoringCriticality.Medium:
      return 2;
    case ThreatMonitoringCriticality.Low:
      return 1;
    default:
      return 0;
  }
};

interface IThreatMonitoringKeywordsTableProps {
  keywords?: ThreatMonitoringKeyword[];
  noKeywordsMessage: string;
  onEditKeyword?: (keyword: ThreatMonitoringKeyword) => void;
  loading: boolean;
  conciseView?: boolean;
  pageSize?: number;
  fillEmptyRows?: boolean;
  activeOnly?: boolean;
}

const ThreatMonitoringKeywordsTable = (
  props: IThreatMonitoringKeywordsTableProps
) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const org = useCurrentOrg();

  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  const [toggleKeyword] = ThreatMonitoringAPI.useEditKeywordV1Mutation();
  const { userMap } = useOrgUserMap();

  const getUserAvatarByID = (id: number) => {
    if (!userMap) return <LoadingIcon />;

    if (id === SYSTEM_USER_ID) {
      return <TMUserAndAvatar userId={SYSTEM_USER_ID} name="Upguard" />;
    }

    const user = userMap[id];
    if (user) {
      return <TMUserAndAvatar avatar={user.avatar} name={user.name} />;
    }

    return <></>;
  };

  const onToggleKeyword = (
    keyword: ThreatMonitoringKeyword,
    active: boolean
  ) => {
    toggleKeyword({
      uuid: keyword.uuid,
      criticality: keyword.criticality ?? "",
      active: active,
    })
      .unwrap()
      .then(() => {
        dispatch(
          addDefaultSuccessAlert(
            active
              ? "Keyword unarchived successfully. This keyword will be actively monitored."
              : "Keyword archived successfully. This keyword will no longer be actively monitored."
          )
        );
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            `Failed to ${active ? "archive" : "unarchive"} keyword`
          )
        );
      });
  };

  const { keywords, onEditKeyword, conciseView, loading, fillEmptyRows } =
    props;
  const pageSize = props.pageSize ?? 10;

  const [sortedKeywords, sortedBy, onSortChange] = useSorting<
    ThreatMonitoringKeyword,
    "added_at" | "keyword" | "keyword_type" | "criticality" | "added_by_user_id"
  >(keywords || [], "added_at", SortDirection.DESC, {
    added_at: {
      orderFuncs: [(k) => k.createdAt],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    keyword: {
      orderFuncs: [(k) => k.keyword.toLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    keyword_type: {
      orderFuncs: [(k) => k.keywordType.toLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    criticality: {
      orderFuncs: [(k) => criticalitySortOrder(k.criticality)],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    added_by_user_id: {
      orderFuncs: [(k) => userMap?.[k.createdByUserID]?.name.toLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  // pad the keywords with nulls to fill out the last page
  const sortedAndPadded: (ThreatMonitoringKeyword | null)[] = [
    ...sortedKeywords,
  ];

  if (fillEmptyRows && sortedAndPadded.length % pageSize !== 0) {
    const emptyRows = pageSize - (sortedAndPadded.length % pageSize);
    for (let i = 0; i < emptyRows; i++) {
      sortedAndPadded.push(null);
    }
  }

  const columns: IXTableColumnHeader[] = [
    {
      id: "keyword",
      text: "Keyword",
      sortable: true,
      startingSortDir: SortDirection.ASC,
      className: "keyword",
    },
    {
      id: "keyword_type",
      text: "Type",
      sortable: true,
      startingSortDir: SortDirection.ASC,
      className: "keyword-type",
    },
  ];
  if (!conciseView) {
    columns.push(
      {
        id: "criticality",
        text: "Service criticality",
        sortable: true,
        startingSortDir: SortDirection.DESC,
      },
      /* hiding this for now since we only have one source
          columns.push(
          {
            id: "sources",
            text: "Sources",
            sortable: true,
            startingSortDir: SortDirection.DESC,
          },*/
      {
        id: "added_at",
        text: "Date added",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      }
    );
  }
  columns.push({
    id: "added_by_user_id",
    text: "Added by",
    sortable: true,
    startingSortDir: SortDirection.ASC,
    className: "added-by",
  });

  if (!conciseView) {
    columns.push({
      id: "actions",
      text: "Actions",
      sortable: false,
      className: "actions-header",
    });
  }

  const rows = sortedAndPadded.map((keyword, i): IXTableRow => {
    if (keyword === null) {
      return { id: `${i}`, cells: [], className: "keyword-row" };
    }

    const cells = [
      <XTableCell key="keyword" className="keyword-cell">
        <EllipsizedText text={keyword.keyword}>
          {keyword.keyword}
        </EllipsizedText>
        {keyword.keyword == org?.mainHostname ? (
          <PillLabel large>Primary Domain</PillLabel>
        ) : (
          ""
        )}
      </XTableCell>,
      <XTableCell key="keyword_type" className="keyword-type-cell">
        {keywordTypeStr(keyword.keywordType)}
      </XTableCell>,
    ];
    if (!conciseView) {
      cells.push(
        <XTableCell key="criticality" className="criticality-cell">
          <div
            className={classNames(
              "criticality-content",
              criticalityColor(keyword.criticality)
            )}
          >
            {criticalityStr(keyword.criticality)}
          </div>
        </XTableCell>,
        /*<XTableCell key="sources" className="sources-cell">
            {keyword.sources ?? "All"}
          </XTableCell>,*/
        <XTableCell key="added_at" className="added-at-cell">
          {moment(keyword.createdAt).format("DD MMM YYYY")}
        </XTableCell>
      );
    }

    cells.push(
      <XTableCell key="added_by" className="added-by-cell">
        {getUserAvatarByID(keyword.createdByUserID)}
      </XTableCell>
    );
    if (!conciseView) {
      cells.push(
        <XTableCell key="actions" className="actions-cell">
          <div className="actions-wrapper">
            {keyword.active && (
              <IconButton
                key="edit-new"
                icon={<div className="cr-icon-pencil-2" />}
                hoverText="Edit"
                disabled={!onEditKeyword}
                onClick={() => {
                  if (onEditKeyword) {
                    onEditKeyword(keyword);
                  }
                }}
              />
            )}
            <IconButton
              key="archive"
              className={keyword.active ? "" : "unarchive-button"}
              icon={<div className={"cr-icon-archive"} />}
              hoverText={keyword.active ? "Archive" : "Unarchive"}
              onClick={() =>
                openConfirmationModal({
                  title: `${keyword.active ? "Archive" : "Unarchive"} ${
                    keyword.keyword
                  }?`,
                  description: (
                    <p>
                      {keyword.active
                        ? `Archiving this keyword will stop continuously monitoring it
                  for threats, and remove any open threats from your feed. Are you sure you want to archive this
                  keyword?`
                        : `Unarchiving this keyword will begin continuously monitoring it
                  for threats. Are you sure you want to unarchive this
                  keyword?`}
                    </p>
                  ),
                  iconClass: "cr-icon-archive",
                  buttonText: `${
                    keyword.active ? "Archive" : "Unarchive"
                  } Keyword`,
                  cancelText: "Cancel",
                  dangerousAction: keyword.active,
                  buttonAction: () => onToggleKeyword(keyword, !keyword.active),
                })
              }
            />
          </div>
        </XTableCell>
      );
    }

    return { id: keyword.uuid, cells, className: "keyword-row" };
  });

  return (
    <>
      <PaginatedTable
        loading={loading || !userMap}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(p: number) => setCurrentPage(p - 1)}
        columnHeaders={columns}
        totalRows={sortedAndPadded.length}
        rows={rows}
        className={classNames("keywords-table", {
          "concise-view": conciseView,
        })}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        emptyContent={
          <EmptyCardWithAction
            iconJSX={<CircledIcon iconClass={"cr-icon-radar"} />}
            emptyText={"No keywords"}
            emptySubText={props.noKeywordsMessage}
          />
        }
      />
      {confirmationModalComponent}
    </>
  );
};

export default ThreatMonitoringKeywordsTable;

import {
  ISortedBy,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { sortBy as _sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { DefaultRootState } from "react-redux";
import {
  deleteIntegration,
  fetchOrgIntegrationSettings,
  setJiraProjectData,
} from "../reducers/cyberRiskActions";
import { enableIntegration } from "../reducers/integrations.actions";
import {
  deactivateCurrentOAuthConnection,
  fetchExistingOAuthConnections,
  fetchJiraProjectList,
  fetchNewOAuth2RequestURL,
  fetchNewOAuth2RequestURLResult,
  fetchOAuth2MessagingServiceChannelList,
  reprioritiseJiraGrant,
} from "../reducers/oauth.actions";
import {
  getJiraProjectData,
  getOAuth2MessagingChannelData,
  getOAuth2RequestURLData,
  getOAuthConnectionData,
  JiraProjectsData,
  OAuth2MessagingChannelData,
  OAuth2RequestUrlData,
} from "../reducers/oauth.selectors";
import { SurveyListTableStyles } from "./SurveyListCard";
import ReportCard from "../../_common/components/ReportCard";
import EmptyCard from "./EmptyCard";
import { LabelColor } from "../../_common/types/label";
import moment from "moment";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import ToggleWithLabel from "./ToggleWithLabel";
import Icon from "../../_common/components/core/Icon";
import Button from "../../_common/components/core/Button";
import ModalV2 from "../../_common/components/ModalV2";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import LayersSVG from "../../_common/images/layers.svg";
import SettingCogSVG from "../../_common/images/settings-cog.svg";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import WebhookSVG from "../images/webhooks.svg";
import ZapierSVG from "../images/zapier.svg";
import SlackSVG from "../images/slack.svg";
import JiraSVG from "../images/jira.svg";
import SlackGreyedSVG from "../images/slack_greyed.svg";
import JiraGreyedSVG from "../images/jira_greyed.svg";
import EmailSVG from "../images/mail.svg";
import { JIRA_SERVICE, SLACK_SERVICE } from "../views/OAuth2Callback";
import InfoBanner, { BannerType } from "./InfoBanner";
import classnames from "classnames";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import "../style/components/OrgIntegrationsConfig.scss";
import "../style/components/IntegrationSettingsModals.scss";
import ColorCheckbox from "./ColorCheckbox";
import TabButtons from "../../_common/components/TabButtons";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../_common/types/redux";
import { History } from "history";
import {
  getEmailDestinationDisabledText,
  IIntegration,
  IntegrationTypeString,
} from "../../_common/types/integration";
import {
  IOAuthError,
  IOAuthToken,
  OAuthState,
} from "../../_common/types/oauth";
import { DefaultRouteProps } from "../../_common/types/router";
import DismissableBanner from "../../_common/components/DismissableBanner";
import { fetchAllOrgNotifications } from "../reducers/org.actions";
import { appConnect } from "../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { popupCenter } from "../../_common/helpers";

export const WEBHOOK_INTEGRATION_TYPE = 1;
export const ZAPIER_INTEGRATION_TYPE = 2;
export const SLACK_INTEGRATION_TYPE = 3;
export const JIRA_INTEGRATION_TYPE = 4;
export const EMAIL_INTEGRATION_TYPE = 5;

const dateFormat = "MMM D, YYYY";

const supportArticleLink =
  "https://help.upguard.com/en/articles/6648563-how-to-set-up-an-integration-in-upguard";

const nameForIntegrationType = (integrationType: number) => {
  switch (integrationType) {
    case ZAPIER_INTEGRATION_TYPE:
      return "Zapier";
    case SLACK_INTEGRATION_TYPE:
      return SLACK_SERVICE;
    case JIRA_INTEGRATION_TYPE:
      return JIRA_SERVICE;
    case EMAIL_INTEGRATION_TYPE:
      return "Email";
  }
  return "Webhook";
};

const managedByZapierToggle =
  "The state of this integration is managed via Zapier";

const slackUnavailableToggle =
  "The Slack workspace is disconnected. To reconnect go to 'Manage Connections'.";
const jiraUnavailableToggle =
  "The Jira Cloud account is disconnected. To reconnect go to 'Manage Connections'.";

//
// ZapierAppDirectoryModal
// Provides an embedded version of the Zapier Application Directory.
// This was suggested by Zapier as one way we could get our official launch campaign fast-tracked.
// Our arm duly twisted, here it is.
//
const ZapierAppDirectoryModal = (props: {
  active: boolean;
  onClose: () => void;
}) => {
  const [loaded, setLoaded] = useState(false);
  const { active, onClose } = props;

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "module";
    script.nonce = window.CSP_NONCE;
    script.src =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";

    const stylesheet = document.createElement("link");
    stylesheet.rel = "stylesheet";
    stylesheet.nonce = window.CSP_NONCE;
    stylesheet.href =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";

    document.head.appendChild(script);
    document.head.appendChild(stylesheet);
    setLoaded(true);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(stylesheet);
      setLoaded(false);
    };
  }, []);

  return (
    <ModalV2
      className={"zapier-application-directory-modal"}
      active={active}
      onClose={onClose}
    >
      {loaded && (
        // @ts-ignore
        <zapier-app-directory app="upguard" theme="light" />
      )}
    </ModalV2>
  );
};

interface NewIntegrationModalProps {
  active: boolean;
  onClose: () => void;
  onSelectNew: (newType: IntegrationTypeString) => void;
  slackLoading: boolean;
  jiraLoading: boolean;
}

const NewIntegrationModal = (props: NewIntegrationModalProps) => {
  const { active, onClose, onSelectNew, slackLoading, jiraLoading } = props;
  const options = [];

  const onClickCard = (type: IntegrationTypeString) => {
    onSelectNew(type);
    onClose();
  };

  options.push(
    <ReportCard key={"web"} newStyles className="create-webhook-card">
      <img
        src={WebhookSVG}
        alt={"Webhook"}
        onClick={() => onClickCard("webhook")}
      />
      <div className={"button-text"} onClick={() => onClickCard("webhook")}>
        <div className={"title"}>Webhook</div>
        <div className={"label"}>Receive HTTP push notifications to a URL</div>
      </div>
      <div onClick={() => onClickCard("webhook")}>
        <Icon name="chevron" direction={90} className={"chevron"} />
      </div>
    </ReportCard>
  );

  options.push(
    <ReportCard key={"zap"} newStyles className="create-zapier-card">
      <img
        src={ZapierSVG}
        alt={"Zapier"}
        onClick={() => onClickCard("zapier")}
      />
      <div className={"button-text"} onClick={() => onClickCard("zapier")}>
        <div className={"title"}>Zapier</div>
        <div className={"label"}>
          Build automations and integrations with thousands of apps
        </div>
      </div>
      <div onClick={() => onClickCard("zapier")}>
        <Icon name="chevron" direction={90} className={"chevron"} />
      </div>
    </ReportCard>
  );

  options.push(
    <ReportCard key={"slack"} newStyles className="create-slack-card">
      {slackLoading && (
        <img
          src={SlackGreyedSVG}
          alt={"Slack"}
          onClick={() => onClickCard("slack")}
        />
      )}
      {!slackLoading && (
        <img
          src={SlackSVG}
          alt={"Slack"}
          onClick={() => onClickCard("slack")}
        />
      )}

      <div className={"button-text"} onClick={() => onClickCard("slack")}>
        <div className={classnames("title", { greyed: slackLoading })}>
          Slack
        </div>
        <div className={classnames("label", { greyed: slackLoading })}>
          Receive notifications as messages in a Slack channel
        </div>
      </div>
      <div onClick={() => onClickCard("slack")}>
        <Icon
          name="chevron"
          direction={90}
          className={classnames("chevron", { greyed: slackLoading })}
        />
      </div>
    </ReportCard>
  );

  options.push(
    <ReportCard key={"jira"} newStyles className="create-jira-card">
      {jiraLoading && (
        <img
          src={JiraGreyedSVG}
          alt={"Jira"}
          onClick={() => onClickCard("jira")}
        />
      )}
      {!jiraLoading && (
        <img src={JiraSVG} alt={"Jira"} onClick={() => onClickCard("jira")} />
      )}
      <div className={"button-text"} onClick={() => onClickCard("jira")}>
        <div className={classnames("title", { greyed: jiraLoading })}>
          Jira Cloud
        </div>
        <div className={classnames("label", { greyed: jiraLoading })}>
          Use notification triggers to create issues in your Jira Cloud projects
        </div>
      </div>
      <div onClick={() => onClickCard("jira")}>
        <Icon
          name="chevron"
          direction={90}
          className={classnames("chevron", { greyed: jiraLoading })}
        />
      </div>
    </ReportCard>
  );

  options.push(
    <ReportCard key={"email"} newStyles className="create-webhook-card">
      <img src={EmailSVG} alt={"Email"} onClick={() => onClickCard("email")} />
      <div className={"button-text"} onClick={() => onClickCard("email")}>
        <div className={"title"}>Email</div>
        <div className={"label"}>
          Receive notifications directly to any specified email address
        </div>
      </div>
      <div onClick={() => onClickCard("email")}>
        <Icon name="chevron" direction={90} className={"chevron"} />
      </div>
    </ReportCard>
  );

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent="Create a new integration"
    >
      <div className={"new-integration-modal"}>
        <div className={"buttons-container"}>{options}</div>
      </div>
    </ModalV2>
  );
};

interface SettingsModalProps {
  active: boolean;
  onClose: () => void;
  slackConnections: OAuthState;
  jiraConnections: OAuthState;
  slackPolling: boolean;
  jiraPolling: boolean;
  connectSlackWorkspaceWithPopup: (jump: boolean) => void;
  disconnectSlackWorkspace: () => void;
  connectJiraAccountWithPopup: (jump: boolean) => void;
  disconnectJiraAccount: () => void;
}

const SettingsModal = (props: SettingsModalProps) => {
  const {
    active,
    onClose,
    slackConnections,
    jiraConnections,
    slackPolling,
    jiraPolling,
    connectSlackWorkspaceWithPopup,
    disconnectSlackWorkspace,
    connectJiraAccountWithPopup,
    disconnectJiraAccount,
  } = props;
  const options = [];

  options.push(
    <ReportCard key={"slack"} newStyles className="create-slack-card">
      <img src={SlackSVG} alt={SLACK_SERVICE} />
      <div className={"button-text"}>
        <div className={"title"}>{"Slack"}</div>
        <div className={"label"}>
          {hasActiveConnection(slackConnections) ||
          hasDeactivedConnection(slackConnections)
            ? slackConnections.connections[0].teamName
            : ""}
        </div>
      </div>
      <Button
        danger={hasActiveConnection(slackConnections)}
        loading={slackConnections.loading || slackPolling}
        onClick={() => {
          if (hasActiveConnection(slackConnections)) {
            disconnectSlackWorkspace();
          } else {
            connectSlackWorkspaceWithPopup(false);
          }
        }}
        className={"add-button"}
      >
        {hasActiveConnection(slackConnections)
          ? "Disconnect"
          : hasDeactivedConnection(slackConnections)
            ? "Reconnect"
            : "Connect"}
      </Button>
    </ReportCard>
  );

  options.push(
    <ReportCard key={"jira"} newStyles className="create-slack-card">
      <img src={JiraSVG} alt={JIRA_SERVICE} />
      <div className={"button-text"}>
        <div className={"title"}>{"Jira Cloud"}</div>
        <div className={"label"}>
          {hasActiveConnection(jiraConnections) ||
          hasDeactivedConnection(jiraConnections)
            ? getJiraTokenSiteName(jiraConnections.connections[0].teamName)
            : ""}
        </div>
      </div>
      <Button
        danger={hasActiveConnection(jiraConnections)}
        loading={jiraConnections.loading || jiraPolling}
        onClick={() => {
          if (hasActiveConnection(jiraConnections)) {
            disconnectJiraAccount();
          } else {
            connectJiraAccountWithPopup(false);
          }
        }}
        className={"add-button"}
      >
        {hasActiveConnection(jiraConnections)
          ? "Disconnect"
          : hasDeactivedConnection(jiraConnections)
            ? "Reconnect"
            : "Connect"}
      </Button>
    </ReportCard>
  );

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent="Manage connections"
    >
      <div className={"settings-modal"}>
        <div className={"buttons-container"}>{options}</div>
      </div>
    </ModalV2>
  );
};

interface DeleteIntegrationModalProps {
  active: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteIntegrationModal = (props: DeleteIntegrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { active, onClose, onDelete } = props;

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent="Are you sure you want to delete this integration?"
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            danger
            loading={loading}
            onClick={async () => {
              setLoading(true);
              try {
                await onDelete();
              } catch (e) {
                setLoading(false);
                return;
              }
              setLoading(false);
              onClose();
            }}
          >
            <i className="cr-icon-trash" />
            Yes, delete
          </Button>
        </div>
      }
    >
      <div>
        <p>
          Deleting an integration will permanently remove it from the system.
          Any attached integration service will no longer receive notifications.
        </p>
      </div>
    </ModalV2>
  );
};

interface EnableIntegrationModalProps {
  active: boolean;
  onClose: () => void;
  onEnable: () => void;
  enable: boolean;
  slackConnections: OAuthState;
  enablingType: number;
}

const EnableIntegrationModal = (props: EnableIntegrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { active, onClose, onEnable, enable, enablingType, slackConnections } =
    props;

  let disallow = false;
  let descText =
    "Enabling an integration will allow notifications to be sent to the nominated API or integration service.";
  let headerText =
    "Are you sure you want to " +
    (enable ? "enable" : "disable") +
    " this integration?";

  if (!enable) {
    descText =
      "Disabling an integration will stop notifications being directed to the nominated API or integration service.";
  } else if (
    (!slackConnections || !hasActiveConnection(slackConnections)) &&
    enablingType == SLACK_INTEGRATION_TYPE
  ) {
    disallow = true;
    descText =
      "You do not currently have an active link to a Slack workspace configured. Please connect to a Slack workspace before attempting to enable existing Slack integrations.";
    headerText = "Slack workspace is disconnected";
  }

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent={headerText}
      footerContent={
        <div className="btn-group">
          {!disallow && (
            <Button tertiary disabled={loading} onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            primary
            loading={loading}
            onClick={async () => {
              if (disallow) {
                onClose();
                return;
              }
              setLoading(true);
              try {
                await onEnable();
              } catch (e) {
                setLoading(false);
                return;
              }
              setLoading(false);
              onClose();
            }}
          >
            {disallow ? "Close" : `Yes, ${enable ? "enable" : "disable"}`}
          </Button>
        </div>
      }
    >
      <div>
        <p>{descText}</p>
      </div>
    </ModalV2>
  );
};

interface SelectJiraPerferredSiteModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  sites: string[];
}

const SelectJiraPreferredSiteModal = (
  props: SelectJiraPerferredSiteModalProps
) => {
  const { dispatch, active, onClose, sites } = props;
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState(sites ? sites[0] : "");

  const headerText = "Select your Jira Cloud instance";
  const descText =
    "Your Jira Cloud user has granted the UpGuard application access to multiple Jira Cloud accounts. Please let us know which of these you" +
    " wish to attach to this UpGuard instance.";

  const options: React.ReactNode[] = [];
  sites.map((ss, idx) => {
    options.push(
      <div
        key={idx}
        onClick={() => {
          setSelectedSite(ss);
        }}
        className={"option"}
      >
        <ColorCheckbox
          checked={selectedSite === ss}
          color={LabelColor.Blue}
          radio={true}
          onClick={() => {
            setSelectedSite(ss);
          }}
        />
        <div className={"label"}>{ss}</div>
      </div>
    );
  });

  const onOK = async () => {
    if (selectedSite != "") {
      try {
        setLoading(true);
        await dispatch(reprioritiseJiraGrant(selectedSite));
        dispatch(fetchJiraProjectList(false, true));
        setLoading(false);
        onClose();
      } catch (e) {
        setLoading(false);
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            `Failed to select account ${selectedSite}.`
          )
        );
        return;
      }
    }
  };

  return (
    <ModalV2
      active={active}
      onClose={onOK}
      headerContent={headerText}
      footerContent={
        <div className="btn-group">
          <Button
            primary
            disabled={selectedSite == ""}
            loading={loading}
            onClick={async () => {
              onOK();
            }}
          >
            {"OK"}
          </Button>
        </div>
      }
      className={"jira-sites-selector-modal"}
    >
      <div>
        <p>{descText}</p>
        <div className={"options"}>{options}</div>
      </div>
    </ModalV2>
  );
};

interface IntergationsListTableProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading?: boolean;
  integrations: IIntegration[];
  stickyColumnHeaders?: boolean;
  slackConnections: OAuthState;
  slackChannelIndex?: Record<string, boolean>;
  jiraConnections: OAuthState;
  jiraProjects?: JiraProjectsData;
  currentTab: string;
  clearFilters: () => void;
  currentJiraAccountName?: string;
  currentSlackAccountName?: string;
}

interface IntegrationsListTableState {
  sortedBy: ISortedBy;
  deleteModalOpen: boolean;
  integrationToDelete?: IIntegration;
  enableModalOpen: boolean;
  editConfirmationModalOpen: boolean;
  editingIntegration?: IIntegration;
  integrationToEnable?: IIntegration;
}

class IntegrationsListTable extends React.Component<
  IntergationsListTableProps,
  IntegrationsListTableState
> {
  static defaultProps = {
    stickyColumnHeaders: true,
    slackChannelIndex: undefined,
    jiraProjects: {} as JiraProjectsData,
    currentJiraAccountName: "",
    currentSlackAccountName: "",
  };

  constructor(props: IntergationsListTableProps) {
    super(props);

    const sortedBy = {
      columnId: "created_at",
      direction: SortDirection.DESC,
    };

    this.state = {
      sortedBy,
      deleteModalOpen: false,
      enableModalOpen: false,
      editConfirmationModalOpen: false,
    };
  }

  enableIntegration = async () => {
    if (!this.state.integrationToEnable) return;

    const enable = !this.state.integrationToEnable.enabled;

    try {
      await this.props.dispatch(
        enableIntegration(this.state.integrationToEnable.id, enable)
      );
    } catch (e) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "Error " + (enable ? "enabling" : "disabling") + " this integration"
        )
      );
      throw e;
    }

    this.props.dispatch(
      addDefaultSuccessAlert("Integration " + (enable ? "enabled" : "disabled"))
    );
  };

  editIntegration = (integration: IIntegration) => {
    this.props.history.push(`/settings/edit_integration/${integration.id}`);
  };

  deleteIntegration = async () => {
    if (!this.state.integrationToDelete) return;

    try {
      await this.props.dispatch(
        deleteIntegration(this.state.integrationToDelete.id)
      );
    } catch (e) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert("Error deleting this integration")
      );
      throw e;
    }

    this.props.dispatch(addDefaultSuccessAlert("Integration deleted"));
  };

  getSortedIntegrations() {
    const { integrations } = this.props;
    const processedIntegrations = [];
    for (let i = 0; i < integrations.length; i++) {
      processedIntegrations.push(integrations[i]);
    }

    let sortedIntegrations;
    switch (this.state.sortedBy.columnId) {
      case "description":
        sortedIntegrations = _sortBy(processedIntegrations, ["description"]);
        break;
      case "webhook":
        sortedIntegrations = _sortBy(processedIntegrations, [
          (i) => i.url.toLowerCase(),
        ]);
        break;
      case "type":
        sortedIntegrations = _sortBy(processedIntegrations, ["type"]);
        break;
      case "status":
        sortedIntegrations = _sortBy(processedIntegrations, ["enabledText"]);
        break;
      case "created":
        sortedIntegrations = _sortBy(processedIntegrations, ["createdAt"]);
        break;
      case "lastFired":
        sortedIntegrations = _sortBy(processedIntegrations, ["lastFired"]);
        break;
      case "updated":
        sortedIntegrations = _sortBy(processedIntegrations, ["updatedAt"]);
        break;
      default:
        sortedIntegrations = _sortBy(processedIntegrations, ["createdAt"]);
        break;
    }

    if (this.state.sortedBy.direction !== "asc") {
      sortedIntegrations.reverse();
    }

    return sortedIntegrations;
  }

  getRows = () => {
    const slackAvailable = hasActiveConnection(this.props.slackConnections);
    const jiraAvailable = hasActiveConnection(this.props.jiraConnections);

    const { slackChannelIndex, jiraProjects } = this.props;
    const jiraProjectIndex = {} as {
      [idx: string]: boolean;
    };
    if (!!jiraProjects && !!jiraProjects.projects) {
      for (let i = 0; i < jiraProjects.projects.length; i++) {
        jiraProjectIndex[jiraProjects.projects[i].id] = true;
      }
    }

    const rows: IXTableRow[] = [];
    this.getSortedIntegrations().map((integration) => {
      const slackChannelDisconnected =
        integration.type === SLACK_INTEGRATION_TYPE &&
        integration.messagingChannel &&
        !!slackChannelIndex &&
        !!!slackChannelIndex[integration.messagingChannel];
      const slackIsInError =
        integration.type === SLACK_INTEGRATION_TYPE &&
        integration.enabled &&
        (!slackAvailable || slackChannelDisconnected);

      const jiraProjectUnavailable =
        integration.type === JIRA_INTEGRATION_TYPE &&
        integration.jiraProjectId &&
        !!!jiraProjects?.loading &&
        !!!jiraProjectIndex[integration.jiraProjectId];

      const jiraIsInError =
        integration.type === JIRA_INTEGRATION_TYPE &&
        integration.enabled &&
        (!!!jiraAvailable || jiraProjectUnavailable);

      const isEditableIntegration =
        integration.type === WEBHOOK_INTEGRATION_TYPE ||
        integration.type === SLACK_INTEGRATION_TYPE ||
        integration.type === JIRA_INTEGRATION_TYPE ||
        integration.type === EMAIL_INTEGRATION_TYPE;

      const isEnableableIntegration =
        integration.type === WEBHOOK_INTEGRATION_TYPE ||
        (integration.type === JIRA_INTEGRATION_TYPE &&
          ((jiraAvailable && !jiraProjectUnavailable) ||
            integration.enabled)) ||
        (integration.type === SLACK_INTEGRATION_TYPE &&
          ((slackAvailable && !slackChannelDisconnected) ||
            integration.enabled)) ||
        (integration.type === EMAIL_INTEGRATION_TYPE &&
          !integration?.emailDestination?.disabledReason);

      const isInErrorIntegration =
        (integration.enabled &&
          ((integration.type === JIRA_INTEGRATION_TYPE &&
            (!jiraAvailable || jiraProjectUnavailable)) ||
            (integration.type === SLACK_INTEGRATION_TYPE &&
              (!slackAvailable || slackChannelDisconnected)))) ||
        (integration.type == EMAIL_INTEGRATION_TYPE &&
          !!integration?.emailDestination?.disabledReason);

      if (
        (!integration.enabled || slackIsInError || jiraIsInError) &&
        this.props.currentTab == "enabled"
      ) {
        return;
      }
      if (
        integration.enabled &&
        !slackIsInError &&
        !jiraIsInError &&
        this.props.currentTab == "disabled"
      ) {
        return;
      }

      const onEditClick = () => {
        if (!isEditableIntegration) {
          return;
        }
        if (integration.enabled) {
          this.setState({
            editConfirmationModalOpen: true,
            editingIntegration: integration,
          });
        } else {
          this.editIntegration(integration);
        }
      };

      const onEnableClick = () => {
        if (isEnableableIntegration) {
          this.setState({
            enableModalOpen: true,
            integrationToEnable: integration,
          });
        }
      };

      const iconOptions = isEditableIntegration
        ? [
            {
              id: "delete",
              hoverText: "Delete integration",
              icon: <i className="cr-icon-trash" />,
              onClick: () => {
                this.setState({
                  deleteModalOpen: true,
                  integrationToDelete: integration,
                });
              },
            },
            {
              id: "update",
              hoverText: "Edit details",
              icon: <i className="cr-icon-pencil" />,
              onClick: onEditClick,
            },
          ]
        : [];

      const integrationType = nameForIntegrationType(integration.type);
      const toggle = (
        <ToggleWithLabel
          name={integration.id.toString()}
          selected={integration.enabled}
          className={integration.enabled ? "" : "disabled"}
          onClick={onEnableClick}
          disabled={!isEditableIntegration || !isEnableableIntegration}
        />
      );

      let descriptionSubText = "";
      let descriptionErrorPopupText = "";
      if (integration.type == SLACK_INTEGRATION_TYPE) {
        descriptionSubText = this.props.currentSlackAccountName ?? "";
      } else if (integration.type == JIRA_INTEGRATION_TYPE) {
        descriptionSubText = this.props.currentJiraAccountName ?? "";
      }

      let togglePopupMessage = "";

      if (
        !isEditableIntegration ||
        !isEnableableIntegration ||
        isInErrorIntegration
      ) {
        if (integration.type == ZAPIER_INTEGRATION_TYPE) {
          togglePopupMessage = managedByZapierToggle;
        } else if (integration.type == SLACK_INTEGRATION_TYPE) {
          const slackChannelUnavailable = `The nominated Slack channel '${integration.url.replace(
            "channel: ",
            ""
          )}' is unavailable. Edit the integration to select a new one.`;
          if (!slackAvailable) {
            togglePopupMessage = slackUnavailableToggle;
            if (isInErrorIntegration) {
              descriptionErrorPopupText = slackUnavailableToggle;
            }
          } else if (slackChannelUnavailable) {
            togglePopupMessage = slackChannelUnavailable;
            if (isInErrorIntegration) {
              descriptionErrorPopupText = slackChannelUnavailable;
            }
          }
        } else if (integration.type == JIRA_INTEGRATION_TYPE) {
          const jiraProjectUnavailableToggle = `The nominated Jira project '${integration.url.replace(
            "project: ",
            ""
          )}' is unavailable. Edit the integration to select a new one.`;
          if (!jiraAvailable) {
            togglePopupMessage = jiraUnavailableToggle;
            if (isInErrorIntegration) {
              descriptionErrorPopupText = jiraUnavailableToggle;
            }
          } else if (jiraProjectUnavailable) {
            togglePopupMessage = jiraProjectUnavailableToggle;
            if (isInErrorIntegration) {
              descriptionErrorPopupText = jiraProjectUnavailableToggle;
            }
          }
        } else if (integration.type == EMAIL_INTEGRATION_TYPE) {
          togglePopupMessage =
            "There are issues with the configuration of this integration";
          descriptionErrorPopupText = getEmailDestinationDisabledText(
            integration.emailDestination?.disabledReason
          );
        }
      }

      rows.push({
        id: integration.id,
        className: "integration-row clickable",
        iconOptions: iconOptions,
        cells: [
          <XTableCell key="statusToggle" className={"status-toggle"}>
            {isEditableIntegration && isEnableableIntegration ? (
              toggle
            ) : (
              <SidePopupV2 text={togglePopupMessage}>{toggle}</SidePopupV2>
            )}
          </XTableCell>,
          <XTableCell
            key="description"
            className={"description"}
            onClick={onEditClick}
          >
            <div className={"description-text"}>
              <div
                className={classnames("description-text", {
                  red: descriptionErrorPopupText,
                })}
              >
                {integration.description}
              </div>
              {descriptionErrorPopupText && (
                <SidePopupV2
                  className="help-icon"
                  text={descriptionErrorPopupText}
                >
                  <Icon name="info" />
                </SidePopupV2>
              )}
            </div>
            {descriptionSubText && (
              <div className={"description-subtext"}>
                <div className={"text"}>{descriptionSubText}</div>
              </div>
            )}
          </XTableCell>,
          <XTableCell key="type" className={"type"} onClick={onEditClick}>
            {integrationType}
          </XTableCell>,
          <XTableCell key="webhook" onClick={onEditClick}>
            <div className={"url"}>{integration.url}</div>
          </XTableCell>,
          // Disabled till we collect a couple weeks of data
          // <XTableCell key="lastFired" onClick={onEditClick}>
          //   {integration.lastFired
          //     ? moment(integration.lastFired).format(dateFormat)
          //     : "-"}
          // </XTableCell>,
          <XTableCell key="created" onClick={onEditClick}>
            {moment(integration.createdAt).format(dateFormat)}
          </XTableCell>,
          <XTableCell key="updated" onClick={onEditClick}>
            {moment(integration.updatedAt).format(dateFormat)}
          </XTableCell>,
        ],
      });
    });
    return rows;
  };

  getColumnHeaders = (): IXTableColumnHeader[] => {
    const columnHeaders = [] as IXTableColumnHeader[];
    columnHeaders.push(
      {
        id: "statusToggle",
        text: "",
        sortable: false,
      },
      {
        id: "description",
        text: "Name",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "type",
        text: "Type",
        sortable: true,
      },
      {
        id: "webhook",
        text: "Destination",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      // disabled till we collect a couple of weeks of data
      // {
      //   id: "lastFired",
      //   text: "Last fired",
      //   sortable: true,
      //   startingSortDir: "asc",
      // },
      {
        id: "created",
        text: "Created",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "updated",
        text: "Last updated",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      }
    );
    return columnHeaders;
  };

  render() {
    const columnHeaders = this.getColumnHeaders();
    const rows = this.getRows();

    return (
      <>
        {rows.length > 0 && (
          <SurveyListTableStyles
            loading={this.props.loading}
            iconOptions
            sortedBy={this.state.sortedBy}
            onSortChange={(columnId, direction) =>
              this.setState({ sortedBy: { columnId, direction } })
            }
            columnHeaders={columnHeaders}
            rows={rows}
            stickyColumnHeaders={this.props.stickyColumnHeaders}
          />
        )}
        {rows.length === 0 && (
          <div>
            <SearchEmptyCard
              onClear={() => {
                this.props.clearFilters();
              }}
              searchItemText="integrations"
              clearText={"Clear filters"}
              searchTextOverride={
                "Your search or filter did not match any integrations."
              }
              hideSubText
            />
          </div>
        )}
        <DeleteIntegrationModal
          active={this.state.deleteModalOpen}
          onClose={() =>
            this.setState({
              deleteModalOpen: false,
              integrationToDelete: undefined,
            })
          }
          onDelete={this.deleteIntegration}
        />
        <EnableIntegrationModal
          active={this.state.enableModalOpen}
          enable={
            this.state.integrationToEnable
              ? !this.state.integrationToEnable.enabled
              : true
          }
          onClose={() =>
            this.setState({
              enableModalOpen: false,
              integrationToEnable: undefined,
            })
          }
          onEnable={this.enableIntegration}
          slackConnections={this.props.slackConnections || {}}
          enablingType={
            this.state.integrationToEnable
              ? this.state.integrationToEnable.type || 0
              : 0
          }
        />
        <ConfirmationModalV2
          active={this.state.editConfirmationModalOpen}
          verticalCenter
          descriptionClassName={"vendor-report-confirmation-modal"}
          title={<h2>Integration is currently active</h2>}
          description={`An integration must be disabled before it can be edited.`}
          buttonText={"Disable and edit"}
          cancelText={"Cancel"}
          buttonAction={async () => {
            if (this.state.editingIntegration) {
              try {
                await this.props.dispatch(
                  enableIntegration(this.state.editingIntegration.id, false)
                );
                this.editIntegration(this.state.editingIntegration);
              } catch (e) {
                this.props.dispatch(
                  addDefaultUnknownErrorAlert(
                    "Failed to disable the integration: " + e
                  )
                );
                return false;
              }
            }
            return true;
          }}
          onClose={() => {
            this.setState({ editConfirmationModalOpen: false });
          }}
        />
      </>
    );
  }
}

export const hasActiveConnection = (connections: OAuthState) => {
  if (
    !connections ||
    !connections.connections ||
    connections.connections.length == 0
  ) {
    return false;
  }
  return !connections.connections[0].invalid;
};

export const hasDeactivedConnection = (connections: OAuthState) => {
  if (
    !connections ||
    !connections.connections ||
    connections.connections.length == 0
  ) {
    return false;
  }
  return connections.connections[0].invalid;
};

export const getJiraTokenSiteName = (tokenTeamName: string): string => {
  if (!tokenTeamName) {
    return "";
  }
  const sites = JSON.parse(tokenTeamName);
  if (sites.length > 0) {
    return sites[0].siteName;
  }
  return "site unknown";
};

export const getJiraTokenSitesList = (tokenTeamName: string): string[] => {
  const hosts = [] as string[];
  if (!tokenTeamName) {
    return hosts;
  }
  const sites = JSON.parse(tokenTeamName);
  sites.map((s: any) => {
    hosts.push(s.siteName);
  });
  return hosts;
};

interface IntegrationsListCardProps {
  history: History;
  loading?: boolean;
  error: IError | undefined;
  integrations: IIntegration[];
  dispatch: DefaultThunkDispatch;
  slackConnections: OAuthState;
  slackRequest: OAuth2RequestUrlData;
  slackChannelData: OAuth2MessagingChannelData;
  jiraProjects: JiraProjectsData;
  jiraConnections: OAuthState;
}

interface IntegrationListCardState {
  searchText: string;
  zapierModalVisible: boolean;
  jiraSelectSiteModalVisible: boolean;
  newIntegrationModalOpen: boolean;
  jiraGrantedSites?: string[];
  currentTab: string;
  jiraSiteSelectionJump?: unknown; // Todo
  slackRequestFetching?: boolean;
  jiraRequestFetching?: boolean;
  jiraPolling?: boolean;
  slackPolling?: boolean;
  settingsModalOpen?: boolean;
}

export class IntegrationsListCard extends React.Component<
  IntegrationsListCardProps,
  IntegrationListCardState
> {
  static defaultProps = {
    loading: true,
    error: undefined,
    slackConnections: {} as OAuthState,
    slackRequest: {} as OAuth2RequestUrlData,
    slackChannelData: {} as OAuth2MessagingChannelData,
    jiraProjects: {} as JiraProjectsData,
  };

  state: IntegrationListCardState = {
    searchText: "",
    zapierModalVisible: false,
    jiraSelectSiteModalVisible: false,
    newIntegrationModalOpen: false,
    jiraGrantedSites: [],
    currentTab: "all",
  };

  oAuthConnectTimer?: ReturnType<Window["setInterval"]>;
  pollingCount?: number;

  indexSlackChannels = (props: IntegrationsListCardProps) => {
    if (
      props.slackChannelData &&
      props.slackChannelData.channels &&
      props.slackChannelData.channels.length > 0
    ) {
      const channelIndex: Record<string, boolean> = {} as Record<
        string,
        boolean
      >;
      props.slackChannelData.channels.map((ch) => {
        channelIndex[ch.name] = true;
      });
      return channelIndex;
    }
    return undefined;
  };

  indexJiraProjects = (props: IntegrationsListCardProps) => {
    const jiraProjectIndex = {} as Record<string, boolean>;
    if (
      props.jiraProjects &&
      props.jiraProjects.projects &&
      props.jiraProjects.projects.length > 0
    ) {
      if (!!props.jiraProjects && !!props.jiraProjects.projects) {
        for (let i = 0; i < props.jiraProjects.projects.length; i++) {
          jiraProjectIndex[props.jiraProjects.projects[i].id] = true;
        }
      }
      props.jiraProjects.projects.map((p) => {
        jiraProjectIndex[p.name] = true;
      });
    }
    return jiraProjectIndex;
  };

  setZapierModalVisibility = (visible: boolean) => {
    this.setState({ zapierModalVisible: visible });
  };

  setSearchText = (value: string) => {
    this.setState({
      searchText: value,
    });
  };

  filteredIntegrations = () => {
    const { searchText } = this.state;
    if (!searchText) return this.props.integrations;
    return this.props.integrations.filter((integration) =>
      [
        integration.description || null,
        integration.url || null,
        integration.enabled ? "enabled" : "disabled",
        nameForIntegrationType(integration.type),
      ]
        .filter(Boolean)
        .map((s) => (s ? s.toLowerCase() : ""))
        .some((s) => s.includes(searchText.toLowerCase()))
    );
  };

  newWebhookIntegration = () => {
    this.props.history.push("/settings/create_integration/webhook");
  };

  newEmailIntegration = () => {
    this.props.history.push("/settings/create_integration/email");
  };

  setJiraSiteSelectionModalVisible = (
    visible: boolean,
    sites?: string[],
    jump?: unknown
  ) => {
    this.setState({
      jiraGrantedSites: sites,
      jiraSelectSiteModalVisible: visible,
      jiraSiteSelectionJump: jump,
    });
  };

  disconnectSlackWorkspace = async () => {
    try {
      await this.props.dispatch(
        deactivateCurrentOAuthConnection(
          nameForIntegrationType(SLACK_INTEGRATION_TYPE)
        )
      );
    } catch (e) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          `Error deactivating connection to Slack workspace: "${e}"`
        )
      );
    }
  };

  disconnectJiraAccount = async () => {
    try {
      await this.props.dispatch(
        deactivateCurrentOAuthConnection(
          nameForIntegrationType(JIRA_INTEGRATION_TYPE)
        )
      );
    } catch (e) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          `Error deactivating connection to Jira account: "${e}"`
        )
      );
    }
  };

  connectSlackWorkspaceWithPopup = async (jump = true) => {
    // if we have no connections available, then redirect to slack to get the ball rolling.
    // we then start a timer to poll our connections (or errors) table to see what the status of the request is
    if (!hasActiveConnection(this.props.slackConnections)) {
      // freshen up the request so we dont have any state left over from latent timer calls processing the previous request
      this.setState({ slackRequestFetching: true });
      let request: fetchNewOAuth2RequestURLResult;
      try {
        request = await this.props.dispatch(
          fetchNewOAuth2RequestURL(SLACK_SERVICE)
        );
        this.setState({ slackRequestFetching: false });
      } catch (e) {
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            `Error determining Slack redirect location: "${e}"`
          )
        );
        this.setState({ slackRequestFetching: false });
        return;
      }

      if (this.oAuthConnectTimer) {
        clearInterval(this.oAuthConnectTimer);
        this.oAuthConnectTimer = undefined;
      }

      // grab a new request url first, to make sure we dont have existing error records waiting for us.
      this.setState({ slackPolling: true });
      const popupWindow = popupCenter(request.url, SLACK_SERVICE, 800, 800);

      // now get polling until we see that either a token or an error has been returned from slack
      this.pollingCount = 0;
      this.oAuthConnectTimer = window.setInterval(async () => {
        this.pollingCount = (this.pollingCount ?? 0) + 1;
        let requestConnections = [] as IOAuthToken[];
        let requestErrors = [] as IOAuthError[];
        try {
          const resp = await this.props.dispatch(
            fetchExistingOAuthConnections(
              SLACK_SERVICE,
              request.uuid,
              true,
              true,
              true
            )
          );
          let connections = [] as IOAuthToken[];
          let errors = [] as IOAuthError[];
          if (resp) {
            connections = resp.connections;
            errors = resp.errors;
          }
          requestConnections = connections
            ? connections.filter((c) => c.uuid == request.uuid)
            : [];
          requestErrors = errors
            ? errors.filter((e) => e.uuid == request.uuid)
            : [];
        } catch (e) {
          requestErrors.push({
            error: "Failed to retrieve current Slack connection state",
          } as IOAuthError);
        }

        if (
          (requestConnections.length > 0 && !requestConnections[0].invalid) ||
          requestErrors.length > 0 ||
          !popupWindow ||
          popupWindow.closed ||
          this.pollingCount > 600 // after 10 minutes we close the window automatically and terminate the process...
        ) {
          if (this.oAuthConnectTimer) {
            clearInterval(this.oAuthConnectTimer);
            this.oAuthConnectTimer = undefined;
          }
          // if the user didnt reject the connection by pressing cancel, then display an error
          if (
            requestErrors.length > 0 &&
            requestErrors[0].error != "access_denied"
          ) {
            // set an error alert on the UI to let the user know
            this.props.dispatch(
              addDefaultUnknownErrorAlert(
                `Error connecting to Slack workspace: "${requestErrors[0].error}"`
              )
            );
          }
          if (requestConnections.length > 0) {
            this.props.dispatch(
              fetchOAuth2MessagingServiceChannelList(SLACK_SERVICE, false, true)
            );
          }
          if (requestConnections.length > 0 && jump) {
            // ok so looks like we've finally detected some connection data, so go for it!
            this.props.history.push("/settings/create_integration/slack", {
              justConnected: true,
            });
          }
          if (popupWindow) {
            popupWindow.close();
          }
          this.setState({ slackPolling: false });
        }
      }, 1000);
    } else {
      // ok we have a valid connection already - just go with it.
      this.props.history.push("/settings/create_integration/slack", {
        justConnected: false,
      });
    }
  };

  connectSlackWorkspaceInline = async () => {
    // if we have no connections available, then redirect to slack to get the ball rolling.
    // we then start a timer to poll our connections (or errors) table to see what the status of the request is
    if (
      !this.props.slackConnections.connections ||
      this.props.slackConnections.connections.length == 0
    ) {
      // freshen up the request so we dont have any state left over from latent timer calls processing the previous request
      this.setState({ slackRequestFetching: true });
      let request;
      try {
        // grab a new request url first, to make sure we dont have existing error records waiting for us.
        request = await this.props.dispatch(
          fetchNewOAuth2RequestURL(SLACK_SERVICE)
        );
        this.setState({ slackRequestFetching: false });
      } catch (e) {
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            `Error determining Slack redirect location: "${e}"`
          )
        );
        this.setState({ slackRequestFetching: false });
        return;
      }

      if (this.oAuthConnectTimer) {
        clearInterval(this.oAuthConnectTimer);
        this.oAuthConnectTimer = undefined;
      }
      window.location.href = request.url;
    } else {
      // ok we have a valid connection already - just go with it.
      this.props.history.push("/settings/create_slack_integration");
    }
  };

  connectJiraAccountWithPopup = async (jump = true) => {
    // if we have no connections available, then redirect to slack to get the ball rolling.
    // we then start a timer to poll our connections (or errors) table to see what the status of the request is
    if (!hasActiveConnection(this.props.jiraConnections)) {
      // freshen up the request so we dont have any state left over from latent timer calls processing the previous request
      this.setState({ jiraRequestFetching: true });
      let request: any;
      try {
        request = await this.props.dispatch(
          fetchNewOAuth2RequestURL(JIRA_SERVICE)
        );
        this.setState({ jiraRequestFetching: false });
      } catch (e) {
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            `Error determining Jira redirect location: "${e}"`
          )
        );
        this.setState({ jiraRequestFetching: false });
        return;
      }

      if (this.oAuthConnectTimer) {
        clearInterval(this.oAuthConnectTimer);
        this.oAuthConnectTimer = undefined;
      }

      // grab a new request url first, to make sure we dont have existing error records waiting for us.
      this.setState({ jiraPolling: true });
      const popupWindow = popupCenter(request.url, JIRA_SERVICE, 800, 800);

      // now get polling until we see that either a token or an error has been returned from slack
      this.pollingCount = 0;
      this.oAuthConnectTimer = window.setInterval(async () => {
        this.pollingCount = (this.pollingCount ?? 0) + 1;
        let requestConnections = [] as IOAuthToken[];
        let requestErrors = [] as IOAuthError[];
        try {
          const resp = await this.props.dispatch(
            fetchExistingOAuthConnections(
              JIRA_SERVICE,
              request.uuid,
              true,
              true,
              true
            )
          );
          if (resp) {
            requestConnections = resp.connections
              ? resp.connections.filter((c) => c.uuid == request.uuid)
              : [];
            requestErrors = resp.errors
              ? resp.errors.filter((e) => e.uuid == request.uuid)
              : [];
          }
        } catch (e) {
          requestErrors.push({
            error: "Failed to retrieve current Jira connection state",
          } as IOAuthError);
        }

        if (
          (requestConnections.length > 0 && !requestConnections[0].invalid) ||
          requestErrors.length > 0 ||
          !popupWindow ||
          popupWindow.closed ||
          this.pollingCount > 600 // after 10 minutes we close the window automatically and terminate the process...
        ) {
          if (this.oAuthConnectTimer) {
            clearInterval(this.oAuthConnectTimer);
            this.oAuthConnectTimer = undefined;
          }
          // if the user didnt reject the connection by pressing cancel, then display an error
          if (
            requestErrors.length > 0 &&
            requestErrors[0].error != "access_denied"
          ) {
            // set an error alert on the UI to let the user know
            this.props.dispatch(
              addDefaultUnknownErrorAlert(
                `Error connecting to Slack workspace: "${requestErrors[0].error}"`
              )
            );
          }

          if (requestConnections.length > 0 && !requestConnections[0].invalid) {
            //
            // has the current user granted access to multiple Jira accounts?
            // if so, we need to determine which account they want to use going forward.
            //

            const sites = getJiraTokenSitesList(requestConnections[0].teamName);
            if (sites.length > 1) {
              this.props.dispatch(setJiraProjectData(null, true, null));
              this.setJiraSiteSelectionModalVisible(true, sites, jump);
            } else {
              this.props.dispatch(fetchJiraProjectList(false, true));
              if (jump) {
                // ok so looks like we've finally detected some connection data, so go for it!
                this.props.history.push("/settings/create_integration/jira", {
                  justConnected: true,
                });
              }
            }
          }
          if (popupWindow) {
            popupWindow.close();
          }
          this.setState({ jiraPolling: false });
        }
      }, 1000);
    } else {
      // ok we have a valid connection already - just go with it.
      this.props.history.push("/settings/create_integration/jira", {
        justConnected: false,
      });
    }
  };

  connectSlack = () => {
    this.connectSlackWorkspaceWithPopup(false);
  };

  connectJira = () => {
    this.connectJiraAccountWithPopup(true);
  };

  showNewIntegrationModal = (show: boolean) => {
    this.setState({ newIntegrationModalOpen: show });
  };
  showSettingsModal = (show: boolean) => {
    this.setState({ settingsModalOpen: show });
  };
  setCurrentTab = (tabId: string) => {
    this.setState({ currentTab: tabId });
  };
  clearIntegrationFilters = () => {
    this.setSearchText("");
    this.setCurrentTab("all");
  };
  onSelectNewIntegration = (newType: IntegrationTypeString) => {
    switch (newType) {
      case "webhook":
        this.newWebhookIntegration();
        break;
      case "zapier":
        this.setZapierModalVisibility(true);
        break;
      case "slack":
        this.connectSlackWorkspaceWithPopup(true);
        break;
      case "jira":
        this.connectJiraAccountWithPopup(true);
        break;
      case "email":
        this.newEmailIntegration();
        break;
    }
  };

  render() {
    const { loading, error } = this.props;
    const { searchText } = this.state;
    const integrations = this.filteredIntegrations();

    if (error) {
      const { errorText } = error;
      const { actionText } = error;
      const { actionOnClick } = error;
      return (
        <EmptyCard
          text={errorText}
          buttons={
            actionText ? (
              <a className="btn btn-default" onClick={actionOnClick}>
                {actionText}
              </a>
            ) : null
          }
        />
      );
    }

    // determine if we have any enabled slack integrations, and whether their channels are missing
    const slackChannelIndex = this.indexSlackChannels(this.props);
    let enabledSlackIntegrations = false;
    let missingSlackChannels = false;
    this.props.integrations.map((integration) => {
      if (integration.type === SLACK_INTEGRATION_TYPE) {
        if (integration.enabled) {
          enabledSlackIntegrations = true;
          if (
            slackChannelIndex &&
            integration.messagingChannel &&
            !slackChannelIndex[integration.messagingChannel]
          ) {
            missingSlackChannels = true;
          }
        }
      }
    });

    // determine if we have any enabled jira integrations, and whether their projects are missing
    let enabledJiraIntegrations = false;
    let missingJiraProjects = false;

    if (!this.props.jiraProjects.loading) {
      const jiraProjectIndex = this.indexJiraProjects(this.props);
      this.props.integrations.map((integration) => {
        if (integration.type === JIRA_INTEGRATION_TYPE) {
          if (integration.enabled) {
            enabledJiraIntegrations = true;
            if (
              jiraProjectIndex &&
              integration.jiraProjectId &&
              !jiraProjectIndex[integration.jiraProjectId]
            ) {
              missingJiraProjects = true;
            }
          }
        }
      });
    }

    const tabs = [
      {
        id: "all",
        text: "View all",
      },
      {
        id: "enabled",
        text: "Enabled",
      },
      {
        id: "disabled",
        text: "Disabled",
      },
    ];

    const showInfoBanner =
      (enabledSlackIntegrations &&
        !hasActiveConnection(this.props.slackConnections)) ||
      (enabledSlackIntegrations &&
        hasActiveConnection(this.props.slackConnections) &&
        missingSlackChannels) ||
      (enabledJiraIntegrations &&
        !hasActiveConnection(this.props.jiraConnections)) ||
      (enabledJiraIntegrations &&
        hasActiveConnection(this.props.jiraConnections) &&
        missingJiraProjects);

    return (
      <>
        <DismissableBanner
          localStorageKey={"org-integrations-config"}
          message={
            "Manage your organization's integrations with other platforms"
          }
          subItems={[
            "Use integrations to seamlessly integrate notifications and events from UpGuard with your standard tools and processes across your organization.",
          ]}
          linkURL={supportArticleLink}
          linkText={"View support article"}
        />
        <ReportCard className="integrations-list-card new-styles">
          <div className="header">
            <div className="header-left">Integrations</div>
            <div className={"header-right"}>
              <img
                src={SettingCogSVG}
                className={"settings-icon"}
                onClick={() => this.showSettingsModal(true)}
              />
              <span onClick={() => this.showSettingsModal(true)}>
                {"Manage connections"}
              </span>
              <Button
                onClick={() => this.showNewIntegrationModal(true)}
                className={"add-button"}
                filledPrimary
              >
                <div className={"icon-x"} />
                New integration
              </Button>
            </div>
          </div>
          {showInfoBanner && (
            <div className={"info-banner-container"}>
              {enabledSlackIntegrations &&
                !hasActiveConnection(this.props.slackConnections) && (
                  <InfoBanner
                    type={BannerType.WARNING}
                    message={"Slack workspace is disconnected"}
                    subItems={[
                      <>
                        <span className={"msg"}>
                          {
                            "You have active Slack integrations with no Slack workspace configured. These integrations are currently disabled (disconnected). Please "
                          }
                        </span>
                        <span className={"link"} onClick={this.connectSlack}>
                          {"Authorize with Slack"}
                        </span>
                        <span className={"msg"}>{"to re-enable them."}</span>
                      </>,
                    ]}
                  />
                )}
              {enabledSlackIntegrations &&
                hasActiveConnection(this.props.slackConnections) &&
                missingSlackChannels && (
                  <InfoBanner
                    type={BannerType.WARNING}
                    message={"Active Slack integrations are disconnected"}
                    subItems={[
                      "You have active Slack integrations whose configured channel does not exist in the current Slack workspace. These integrations are currently disabled (disconnected). Please change the target channel to to re-enable them.",
                    ]}
                  />
                )}
              {enabledJiraIntegrations &&
                !hasActiveConnection(this.props.jiraConnections) && (
                  <InfoBanner
                    type={BannerType.WARNING}
                    message={"Jira Cloud account is disconnected"}
                    subItems={[
                      <>
                        <span className={"msg"}>
                          {
                            "You have active Jira integrations with no JIra Cloud account configured. These integrations are currently disabled (disconnected). Please "
                          }
                        </span>
                        <span className={"link"} onClick={this.connectJira}>
                          {"Authorize with Jira Cloud"}
                        </span>
                        <span className={"msg"}>{"to re-enable them."}</span>
                      </>,
                    ]}
                  />
                )}
              {enabledJiraIntegrations &&
                hasActiveConnection(this.props.jiraConnections) &&
                missingJiraProjects && (
                  <InfoBanner
                    type={BannerType.WARNING}
                    message={"Active Jira integrations are disconnected"}
                    subItems={[
                      "You have active Jira integrations whose configured project does not exist in the current Jira Cloud account. These integrations are currently disabled (disconnected). Please change the target project to to re-enable them.",
                    ]}
                  />
                )}
            </div>
          )}
          {!loading && searchText === "" && integrations.length === 0 ? (
            <EmptyCardWithAction
              emptyText="You haven't created an integration yet"
              emptySubText="Create an integration to notify other platforms of events that happen in your UpGuard account."
              supportLinkHref={supportArticleLink}
              iconSrc={LayersSVG}
              onActionClick={() => this.showNewIntegrationModal(true)}
              actionButtonText={"New integration"}
            />
          ) : (
            <>
              <div className="search-container">
                <div className={"tabs"}>
                  <TabButtons
                    onChangeTab={(tabId) => this.setCurrentTab(tabId)}
                    tabs={tabs}
                    activeTabId={this.state.currentTab}
                  />
                </div>
                <div className={"search-box"}>
                  <SearchBox
                    placeholder={
                      "Search integrations by name, type, endpoint and status"
                    }
                    onChanged={(q) => this.setSearchText(q)}
                    value={searchText}
                  />
                </div>
              </div>
              {!loading && searchText && !integrations.length ? (
                <SearchEmptyCard
                  onClear={() => {
                    this.setSearchText("");
                  }}
                  searchItemText="integrations"
                  clearText={"Clear search"}
                  searchTextOverride={
                    "Your search did not match any integrations."
                  }
                  hideSubText
                />
              ) : (
                <IntegrationsListTable
                  history={this.props.history}
                  dispatch={this.props.dispatch}
                  loading={loading}
                  integrations={integrations}
                  slackConnections={this.props.slackConnections}
                  slackChannelIndex={slackChannelIndex}
                  jiraConnections={this.props.jiraConnections}
                  jiraProjects={this.props.jiraProjects}
                  currentTab={this.state.currentTab}
                  clearFilters={this.clearIntegrationFilters}
                  currentJiraAccountName={getJiraTokenSiteName(
                    this.props.jiraConnections &&
                      this.props.jiraConnections.connections &&
                      this.props.jiraConnections.connections.length > 0
                      ? this.props.jiraConnections.connections[0].teamName
                      : ""
                  )}
                  currentSlackAccountName={
                    this.props.slackConnections &&
                    this.props.slackConnections.connections &&
                    this.props.slackConnections.connections.length > 0
                      ? this.props.slackConnections.connections[0].teamName
                      : ""
                  }
                  stickyColumnHeaders
                />
              )}
            </>
          )}
          {this.state.zapierModalVisible && (
            <ZapierAppDirectoryModal
              active={this.state.zapierModalVisible}
              onClose={() => {
                this.setZapierModalVisibility(false);
              }}
            />
          )}
          <SelectJiraPreferredSiteModal
            dispatch={this.props.dispatch}
            active={this.state.jiraSelectSiteModalVisible}
            onClose={() => {
              if (this.state.jiraSiteSelectionJump) {
                // ok so looks like we've finally detected some connection data, so go for it!
                this.props.history.push("/settings/create_integration/jira", {
                  justConnected: true,
                });
              }
              this.setJiraSiteSelectionModalVisible(false);
            }}
            sites={this.state.jiraGrantedSites || []}
          />
          <NewIntegrationModal
            active={!!this.state.newIntegrationModalOpen}
            onClose={() => this.showNewIntegrationModal(false)}
            onSelectNew={this.onSelectNewIntegration}
            slackLoading={
              this.props.slackConnections.loading ||
              this.state.slackRequestFetching ||
              this.state.slackPolling ||
              false
            }
            jiraLoading={
              this.props.jiraConnections.loading ||
              this.state.jiraRequestFetching ||
              this.state.jiraPolling ||
              false
            }
          />
          <SettingsModal
            active={!!this.state.settingsModalOpen}
            onClose={() => this.showSettingsModal(false)}
            slackConnections={this.props.slackConnections || {}}
            jiraConnections={this.props.jiraConnections || {}}
            slackPolling={
              this.state.slackRequestFetching ||
              this.state.slackPolling ||
              false
            }
            jiraPolling={
              this.state.jiraRequestFetching || this.state.jiraPolling || false
            }
            connectSlackWorkspaceWithPopup={this.connectSlackWorkspaceWithPopup}
            disconnectSlackWorkspace={this.disconnectSlackWorkspace}
            connectJiraAccountWithPopup={this.connectJiraAccountWithPopup}
            disconnectJiraAccount={this.disconnectJiraAccount}
          />
        </ReportCard>
      </>
    );
  }
}

interface OrgIntegrationsConfigBaseConnectedProps {
  loading: boolean;
}

interface OrgIntegrationsConfigRestConnectedProps {
  loading: boolean;
  error?: IError;
  integrations: IIntegration[];
  integrationsError?: unknown;
  slackConnections: OAuthState;
  jiraConnections: OAuthState;
  jiraProjects: JiraProjectsData;
  slackRequest: OAuth2RequestUrlData;
  slackChannelData: OAuth2MessagingChannelData;
}

type OrgIntegrationsConfigConnectedProps =
  OrgIntegrationsConfigBaseConnectedProps &
    OrgIntegrationsConfigRestConnectedProps;

interface IIntegrationsOAuth2Props {
  oauth2Success: string;
  oauth2Error: string;
}

type OrgIntegrationsConfigProps = OrgIntegrationsConfigConnectedProps &
  DefaultThunkDispatchProp &
  // OrgIntegrationsConfig only actually uses "location" and "history" from
  // DefaultRouteProps. We shouldn't be forced to supply the "match" or
  // "staticContext" props...
  Pick<
    DefaultRouteProps<never, IIntegrationsOAuth2Props>,
    "location" | "history"
  >;

class OrgIntegrationsConfig extends React.PureComponent<OrgIntegrationsConfigProps> {
  static defaultProps = {
    loading: false,
    error: undefined,
    integrations: [] as IIntegration[],
    slackConnections: {} as OAuthState,
    jiraConnections: {} as OAuthState,
    slackRequest: {} as OAuth2RequestUrlData,
    slackChannelData: {} as OAuth2MessagingChannelData,
  };

  constructor(props: OrgIntegrationsConfigProps) {
    super(props);

    props.dispatch(fetchOrgIntegrationSettings());
    props.dispatch(fetchAllOrgNotifications());

    props
      .dispatch(fetchExistingOAuthConnections(SLACK_SERVICE, "", false, true))
      .then((result) => {
        if (result && result.connections.length > 0) {
          props
            .dispatch(
              fetchOAuth2MessagingServiceChannelList(SLACK_SERVICE, true)
            )
            .catch((e) => {
              console.error(`Failed to get slack service channel list: ${e}`);
            });
        }
      })
      .catch((e) => {
        console.error("Failed to get Slack OAuth tokens list", e);
      });

    props
      .dispatch(fetchExistingOAuthConnections(JIRA_SERVICE, "", true, true))
      .then((result) => {
        if (result && result.connections.length > 0) {
          props.dispatch(fetchJiraProjectList(true, true)).catch((e) => {
            console.error(`Failed to get Jira projects list: ${e}`);
          });
        }
      })
      .catch((e) => {
        console.error(`Failed to get Jira OAuth tokens list: ${e}`);
      });
  }

  componentDidMount() {
    if (this.props.location.state?.oauth2Success) {
      this.props.dispatch(
        addDefaultSuccessAlert(this.props.location.state.oauth2Success)
      );
    }
    if (this.props.location.state?.oauth2Error) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(this.props.location.state.oauth2Error)
      );
    }
  }

  render() {
    if (this.props.loading) {
      return <LoadingBanner />;
    }

    return (
      <div className="org-integrations-config">
        <IntegrationsListCard
          history={this.props.history}
          loading={this.props.loading}
          error={this.props.error}
          integrations={this.props.integrations}
          dispatch={this.props.dispatch}
          slackConnections={this.props.slackConnections}
          jiraConnections={this.props.jiraConnections}
          jiraProjects={this.props.jiraProjects}
          slackRequest={this.props.slackRequest}
          slackChannelData={this.props.slackChannelData}
        />
      </div>
    );
  }
}

// BlankState is used to maintain a consistent object reference
// so that there are no unnecessary state updates/re-renders
// from blank object reference changes.
// We probably shouldn't be using this for defaultProps or
// anything else like that. Just appConnect.
const blankState: any = {};

export default appConnect<OrgIntegrationsConfigConnectedProps>(
  (state: DefaultRootState) => {
    const { orgIntegrationSettings } = state.cyberRisk;
    const slackConnections = getOAuthConnectionData(state, SLACK_SERVICE);
    const slackRequest = getOAuth2RequestURLData(state, SLACK_SERVICE);
    const slackChannelData = getOAuth2MessagingChannelData(
      state,
      SLACK_SERVICE
    );
    const jiraConnections = getOAuthConnectionData(state, JIRA_SERVICE);
    const jiraProjects = getJiraProjectData(state);

    if (orgIntegrationSettings) {
      const result: OrgIntegrationsConfigConnectedProps = {
        loading: false,
        integrations: orgIntegrationSettings.integrations,
        integrationsError: orgIntegrationSettings.error,
        slackConnections: slackConnections ? slackConnections : blankState,
        jiraConnections: jiraConnections ? jiraConnections : blankState,
        slackRequest: slackRequest ? slackRequest : blankState,
        slackChannelData: slackChannelData,
        jiraProjects: jiraProjects,
      };
      return result;
    }
    return {
      loading: true,
    } as OrgIntegrationsConfigConnectedProps;
  }
)(OrgIntegrationsConfig);

import { FC, ReactNode } from "react";
import TrustPageEditableCard from "./TrustPageEditableCard";
import classnames from "classnames";
import IconButton from "../../_common/components/IconButton";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import CircledIcon from "../../_common/components/CircledIcon";
import { ISecurityLink } from "../helpers/types";
import "../style/SecurityLinksCard.scss";
import { openInNewTab } from "../helpers/helpers";

interface ISecurityLinksCardProps {
  className?: string;
  securityLinks: ISecurityLink[];
  userCanMakeChanges: boolean;
  onUpdateSecurityLinks?: () => void;
}

const getSecurityLinksOrEmptyCard = (
  securityLinks: ISecurityLink[]
): ReactNode => {
  return (
    <>
      {securityLinks && securityLinks.length > 0 ? (
        securityLinks.map((link) => (
          <div
            key={link.title}
            className={classnames("security-link", "clickable")}
            onClick={() => openInNewTab(link.url)}
          >
            {link.title}
            <div className="actions-col">
              <i className={"cr-icon-external-link"} />
            </div>
          </div>
        ))
      ) : (
        <EmptyCardWithAction
          iconJSX={<CircledIcon iconClass={"cr-icon-link-2"} />}
          emptyText={"No published links"}
          emptySubText={
            "Share links to your organization's policies, terms and other important online resources."
          }
        />
      )}
    </>
  );
};

const SecurityLinksCard: FC<ISecurityLinksCardProps> = ({
  className,
  securityLinks,
  userCanMakeChanges,
  onUpdateSecurityLinks,
}: ISecurityLinksCardProps) => {
  const securityLinksSorted = securityLinks?.sort((a, b) => {
    const dateA = a.createdAt ? Date.parse(a.createdAt) : 0;
    const dateB = b.createdAt ? Date.parse(b.createdAt) : 0;

    if (dateA === dateB) {
      return a.category.localeCompare(b.category);
    }
    return dateA - dateB;
  });

  return (
    <TrustPageEditableCard
      className={"security-links-card"}
      header={"Security links"}
      editButton={
        userCanMakeChanges ? (
          <IconButton
            icon={<div className="cr-icon-pencil-2" />}
            onClick={onUpdateSecurityLinks}
          />
        ) : null
      }
    >
      <div className={classnames("security-links-card-inner", className)}>
        {getSecurityLinksOrEmptyCard(securityLinksSorted)}
      </div>
    </TrustPageEditableCard>
  );
};

export default SecurityLinksCard;

import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/securityprofile/ControlCheckPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import VendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import { LabelColor } from "../../../_common/types/label";
import PillLabel from "../PillLabel";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { upperFirst } from "lodash";
import moment from "moment";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { HoverLocation } from "../../../_common/components/IconButton";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { ControlState } from "../../types/securityProfile";

interface IControlCheckPanelProps {
  onClickBack?: () => void;
  vendorId: number;
  checkId: string;
}

const timeFormat = "D MMM YYYY HH:mm";

const ControlCheckPanel = ({
  checkId,
  onClickBack,
  vendorId,
}: IControlCheckPanelProps) => {
  const controlId = checkId.substring(0, 8);

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  const { data: documents } =
    VendorSecurityProfileAPI.useGetSecurityDocumentsQuery({ vendorId });

  const { data: controlData, isLoading: controlLoading } =
    VendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });
  const control = controlData?.control;

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const scanningRisk = securityProfile?.scanningChecks[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];

  if (controlLoading || !control || !check) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  return (
    <div className="check-panel">
      {!!onClickBack && <BackArrow popup={"Back"} onClick={onClickBack} />}
      {scanningRisk ? <h2>{scanningRisk.title}</h2> : <h2>{check.text}</h2>}
      <ScrollableDiv newStyles>
        <CheckItemSlidePanelSection title="Check details" startExpanded={true}>
          <div className="detail-table">
            <InfoTable>
              <InfoTableRow
                label="RESULT"
                rowClass="detail-row"
                valueClass="status-value"
                value={
                  <>
                    {checkState === ControlState.Passed && (
                      <PillLabel color={LabelColor.Green}>
                        Check passed
                      </PillLabel>
                    )}
                    {checkState === ControlState.Failed && (
                      <PillLabel color={LabelColor.Red}>
                        Risk detected
                      </PillLabel>
                    )}
                    {checkState === ControlState.Unknown && (
                      <PillLabel color={LabelColor.Grey}>
                        Evidence required
                      </PillLabel>
                    )}
                  </>
                }
              />
              {scanningRisk && (
                <InfoTableRow
                  rowClass="detail-row"
                  label="RISK SEVERITY"
                  value={
                    <>
                      <AdjustedSeverityIcon
                        severity={SeverityAsString(scanningRisk.severity)}
                        baseSeverity={
                          scanningRisk.baseSeverity
                            ? SeverityAsString(scanningRisk.baseSeverity)
                            : undefined
                        }
                      />
                      {upperFirst(SeverityAsString(scanningRisk.severity))}
                    </>
                  }
                />
              )}
              <InfoTableRow
                rowClass="detail-row"
                label="LAST SCANNED"
                value={moment(control.lastScannedAt).format(timeFormat)}
              />
            </InfoTable>
          </div>
        </CheckItemSlidePanelSection>
        {check.text && (
          <CheckItemSlidePanelSection title="Summary" startExpanded={true}>
            <p>{check.text}</p>
          </CheckItemSlidePanelSection>
        )}
        {!check.text && scanningRisk?.summary && (
          <CheckItemSlidePanelSection title="Summary" startExpanded={true}>
            <p>{scanningRisk.summary}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk?.riskDetails && (
          <CheckItemSlidePanelSection
            title="Risk details"
            startExpanded={false}
          >
            <p>{scanningRisk.riskDetails}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk?.recommendedRemediation && (
          <CheckItemSlidePanelSection
            title="Recommended remediation"
            startExpanded={false}
          >
            <p>{scanningRisk.recommendedRemediation}</p>
          </CheckItemSlidePanelSection>
        )}
        {documentCheck && documentCheck.length > 0 && (
          <CheckItemSlidePanelSection
            title={`Citations (${documentCheck.length})`}
            startExpanded={true}
          >
            <div className="citations-table">
              <XTable
                iconOptions
                rows={documentCheck.map((check) => {
                  // find the document from our security profile documents
                  const document = check.vendorDocumentID
                    ? documents?.sharedDocuments.find(
                        (d) => d.uuid == check.vendorDocumentID
                      )
                    : undefined; // TODO once we have non-shared documents

                  return {
                    id: `${check.vendorDocumentID}`,
                    cells: [
                      <XTableCell key="citation" className="citation">
                        <div className="document">
                          <a className="name">
                            {document?.name ?? document?.filename}
                          </a>
                          {/*{check.evidence.type === EvidenceType.Document && (*/}
                          <div className="source">{`Uploaded by UpGuard, ${moment(
                            document?.updatedAt
                          ).format(timeFormat)}`}</div>
                          {/*)}*/}
                          {/*{check.evidence.type ===*/}
                          {/*  EvidenceType.Questionnaire && (*/}
                          {/*  <div className="source">{`Submitted by ${*/}
                          {/*    check.evidence.lastUpdatedBy*/}
                          {/*  }, ${moment(check.evidence.lastUpdated).format(*/}
                          {/*    timeFormat*/}
                          {/*  )}`}</div>*/}
                          {/*)}*/}
                        </div>
                        {check.citations.map((c) => (
                          <div key={c.text} className={"extract"}>
                            {c.text}
                          </div>
                        ))}
                        {/*{check.question && check.answer && (*/}
                        {/*  <>*/}
                        {/*    <div className="question">*/}
                        {/*      <span className="label">Q</span>*/}
                        {/*      <span className="text">{check.question}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="answer">*/}
                        {/*      <span className="label">A</span>*/}
                        {/*      <span className="text">{check.answer}</span>*/}
                        {/*    </div>*/}
                        {/*  </>*/}
                        {/*)}*/}
                      </XTableCell>,
                    ],
                    iconOptions: [
                      {
                        id: `${check.id}-action`,
                        icon: <i className={"cr-icon-dots-menu"} />,
                        dropdownItems: [
                          <DropdownItem key="reject-citation">
                            <div className="dropdown-with-text">
                              <div className="dropdown-title">
                                Reject citation
                              </div>
                              <div className="dropdown-text">
                                Excludes this specific citation from scanning
                              </div>
                            </div>
                          </DropdownItem>,
                          <DropdownItem key="archive-document">
                            <div className="dropdown-with-text">
                              <div className="dropdown-title">
                                Archive document
                              </div>
                              <div className="dropdown-text">
                                Excludes entire document from scanning
                              </div>
                            </div>
                          </DropdownItem>,
                        ],
                        hoverText: "Manage this risk",
                        hoverLocation: HoverLocation.Left,
                      },
                    ],
                  };
                })}
              />
            </div>
          </CheckItemSlidePanelSection>
        )}
      </ScrollableDiv>
    </div>
  );
};

export default ControlCheckPanel;

import { DefaultRouteProps } from "../../../_common/types/router";
import {
  ExportFiletype,
  ExportSection,
  ExportType,
} from "../../../_common/types/exportReport";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../../_common/types/redux";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import StepsWithSections, {
  IStep,
  Steps,
} from "../../../_common/components/StepsWithSections";
import PageHeader from "../../../_common/components/PageHeader";
import { Redirect } from "react-router-dom";
import { get as _get } from "lodash";
import {
  AvailableExportConfig,
  ConfiguredExportConfig,
  exportConfigRequiresVendorWithCompletedAssessment,
  RequiredExtraStep,
} from "../../../_common/types/exportConfig";
import { getExportSections } from "../../reducers/cyberRiskSelectors";
import {
  exportReport,
  fetchExportSections,
  getScheduledExports,
  scheduleExport,
} from "../../reducers/export.actions";
import {
  ExportConfigurationOptions,
  ExportSectionsConfiguration,
} from "../../components/reporting/ExportConfiguration";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ActionBar from "../../../_common/components/ActionBar";
import Button from "../../../_common/components/core/Button";

import "../../style/views/reporting/ReportGenerate.scss";
import { crumb } from "../../../_common/components/Breadcrumbs";
import ReportCard from "../../../_common/components/ReportCard";
import SelectPortfoliosStep from "../../components/reporting/SelectPortfoliosStep";
import { PortfolioType } from "../../reducers/portfolios.actions";
import ReportDeliveryStep, {
  ReportDeliveryState,
  reportDeliveryStateValidationErr,
  useReportDeliveryState,
} from "../../components/reporting/ReportDeliveryStep";
import { calculateFirstRun } from "../../components/modals/ExportReportModal";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  ConfiguredExportConfigState,
  useConfiguredExportConfigReducer,
} from "../../components/reporting/exportConfigReducer";
import {
  OrgAccessCustomReportingTemplates,
  OrgAccessDomainPortfolios,
  OrgAccessVendorPortfolios,
  SelfSignupCustomer,
  UserWriteCustomReportingTemplates,
  UserWriteOwnOrganisation,
} from "../../../_common/permissions";
import {
  AssuranceType,
  organisationAccountType,
} from "../../../_common/types/organisations";
import { getVendorWords } from "../../../_common/constants";
import SelectVendorCard from "../../components/SelectVendorCard";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { useModalV2 } from "../../../_common/components/ModalV2";
import CreateTemplateAfterGenerateModal from "../../components/reporting/CreateTemplateAfterGenerateModal";
import { Action, Location } from "history";
import { useConfirmationModalV2Props } from "../../../_common/components/modals/ConfirmationModalV2";
import { useBlockRouterWithConfirmationModal } from "../../../_common/hooks";
import { BadgeImageID } from "../../components/reporting/ReportTypeBadge";
import MonitorVendorModal from "../../components/modals/MonitorVendorModal";
import {
  fetchCustomerLimitData,
  refreshVendorSearchResults,
  setVendorSearch,
} from "../../reducers/cyberRiskActions";
import { IVendorSearchVendorAndWatched } from "../../../_common/types/vendor";
import { appConnect } from "../../../_common/types/reduxHooks";

// are we supporting additional vendor selection for unmonitored vendors during trials?
export const SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS = true;

export interface IReportGenerateLocationState {
  reportType?: ExportType;
  initialFileType?: ExportFiletype;
  availableFileTypes?: ExportFiletype[];
  cannedConfig?: ConfiguredExportConfig;
  baseDefaultID?: string;
  baseUUID?: string;
  createdBy?: number;
  cannedReportName?: string;
  cannedReportDescription?: string;
  cannedReportBadgeID?: BadgeImageID;
  vendorIdRequired?: boolean;
  vendorId?: number;
  vendorName?: string;
  startInitial?: boolean;
}

type IReportGeneratePropsProviderProps = DefaultRouteProps<
  never,
  IReportGenerateLocationState
>;

interface IReportGenerateOwnProps extends IReportGeneratePropsProviderProps {
  reportType?: ExportType;
  initialFileType?: ExportFiletype;
  availableFileTypes?: ExportFiletype[];
  baseDefaultID?: string;
  baseUUID?: string;
  createdBy?: number;
  cannedReportName?: string;
  cannedReportDescription?: string;
  cannedReportBadgeID?: BadgeImageID;
  cannedConfig?: ConfiguredExportConfig;
  vendorId?: number;
  vendorName?: string;
  vendorHostname?: string;
  vendorWatched?: boolean;
  setVendor: (
    vendorId: number,
    vendorName: string,
    vendorHostname: string,
    vendorWatched: boolean
  ) => void;
  vendorIdStepRequired: boolean;
}

interface IReportGenerateConnectedProps {
  orgSupportsVendorPortfolios: boolean;
  orgSupportsDomainPortfolios: boolean;
  userCanWriteOwnCustomTemplates: boolean;
  userCanWriteOrgCustomTemplates: boolean;
  assuranceType: AssuranceType;
  currentUserId: number;
  supportUnwatchedVendors: boolean;

  unfilteredAvailableConfig?: {
    [t in ExportFiletype]: AvailableExportConfig;
  };
  unfilteredAvailableConfigWithoutVendorId?: {
    [t in ExportFiletype]: AvailableExportConfig;
  };
  vendorWatchLimit: number;
  vendorWatchCount: number;
  existingFilterText?: string;
}

type IReportGenerateProps = IReportGenerateOwnProps &
  IReportGenerateConnectedProps &
  DefaultThunkDispatchProp;

type reportGenerateStepID =
  | "set_vendor"
  | "config_options"
  | "config_sections"
  | "select_vendor_portfolios"
  | "select_domain_portfolios"
  | "report_schedule";

export const useSubmitGenerateReport = (
  dispatch: DefaultThunkDispatch,
  reportType: ExportType | undefined,
  filetype: ExportFiletype | undefined = ExportFiletype.PDF,
  cannedReportName: string | undefined = "",
  reportDeliveryState: ReportDeliveryState,
  configuredExportConfigState: ConfiguredExportConfigState,
  vendorId: number | undefined,
  vendorName: string | undefined = "",
  setLoading: (loading: boolean) => void,
  selectedVendorPortfolioIDs: number[],
  selectedDomainPortfolioIDs: number[],
  onSuccessfulSchedule: () => void,
  onSuccessfulGenerate: () => void,
  orgSupportsVendorPortfolios: boolean,
  orgSupportsDomainPortfolios: boolean,
  isCustom?: boolean,
  ignoreFiltersFromState?: boolean
) =>
  useCallback(async () => {
    if (!reportType) {
      return;
    }
    setLoading(true);

    const selectedEmailAddresses = reportDeliveryState.emailReport
      ? reportDeliveryState.emailRecipients
          .filter((e) => !e.unsubscribed && !e.unsubscribedAll)
          .map((e) => e.emailAddress)
      : undefined;

    const exportOptions: Record<string, any> = {};

    if (
      configuredExportConfigState.requiredExtraSteps.includes(
        RequiredExtraStep.VendorPortfolios
      ) &&
      orgSupportsVendorPortfolios
    ) {
      exportOptions.portfolio_ids = selectedVendorPortfolioIDs;
    }

    if (
      configuredExportConfigState.requiredExtraSteps.includes(
        RequiredExtraStep.DomainPortfolios
      ) &&
      orgSupportsDomainPortfolios
    ) {
      exportOptions.website_portfolio_ids = selectedDomainPortfolioIDs;
    }

    if (!!vendorId) {
      exportOptions.vendor_id = vendorId;
    }

    try {
      if (reportDeliveryState.scheduleExport) {
        const firstRun = calculateFirstRun(
          reportDeliveryState.scheduleInterval,
          reportDeliveryState.date,
          reportDeliveryState.time,
          reportDeliveryState.dayOfWeek
        );

        await dispatch(
          scheduleExport({
            exportType: reportType,
            fileType: filetype,
            cannedReportName,
            exportOptions,
            applyFilters: false, // Filtering not supported yet
            config: configuredExportConfigState.configuredExportConfig,
            emailRecipients: selectedEmailAddresses,
            first_run: firstRun,
            interval: reportDeliveryState.scheduleInterval,
            period: 1,
            ignoreFiltersFromState: ignoreFiltersFromState,
          })
        );

        await dispatch(getScheduledExports());

        dispatch(
          addDefaultSuccessAlert("Your report has been scheduled", [
            "Please refer to the Reports section on the left navigation for more details.",
          ])
        );

        onSuccessfulSchedule();
      } else {
        await dispatch(
          exportReport({
            exportType: reportType,
            fileType: filetype,
            cannedReportName,
            exportOptions,
            applyFilters: false, // Filtering not supported yet
            config: configuredExportConfigState.configuredExportConfig,
            emailRecipients: selectedEmailAddresses,
            isCustom,
            ignoreFiltersFromState: ignoreFiltersFromState,
          })
        );

        let successText = "Generating report";
        if (vendorName != "") {
          successText = `Generating report for ${vendorName}`;
        }

        dispatch(
          addDefaultSuccessAlert(successText, [
            "This report will take a few minutes to generate.",
          ])
        );

        onSuccessfulGenerate();
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(addDefaultUnknownErrorAlert("Error generating report"));
      return;
    }
  }, [
    dispatch,
    reportDeliveryState,
    reportType,
    filetype,
    cannedReportName,
    vendorId,
    configuredExportConfigState,
    selectedVendorPortfolioIDs,
    selectedDomainPortfolioIDs,
    setLoading,
    onSuccessfulGenerate,
    onSuccessfulSchedule,
    orgSupportsVendorPortfolios,
    orgSupportsDomainPortfolios,
  ]);

const ReportGenerate: FC<IReportGenerateProps> = ({
  dispatch,
  history,
  location,
  reportType,
  initialFileType = ExportFiletype.PDF,
  availableFileTypes,
  baseDefaultID,
  baseUUID,
  createdBy,
  cannedConfig,
  cannedReportBadgeID,
  cannedReportName = "",
  cannedReportDescription = "",

  vendorId,
  vendorWatched,
  vendorHostname,
  vendorName,
  setVendor,
  vendorIdStepRequired,
  unfilteredAvailableConfig,
  unfilteredAvailableConfigWithoutVendorId,
  orgSupportsVendorPortfolios,
  orgSupportsDomainPortfolios,
  userCanWriteOwnCustomTemplates,
  userCanWriteOrgCustomTemplates,
  assuranceType,
  currentUserId,
  supportUnwatchedVendors,
  vendorWatchLimit,
  vendorWatchCount,
  existingFilterText,
}) => {
  const vendorWords = getVendorWords(assuranceType);
  const [monitorModalVisible, setMonitorModalVisible] = useState(false);
  const [reportDeliveryState, setReportDeliveryState] =
    useReportDeliveryState();
  const [reportGenerateLoading, setReportGenerateLoading] = useState(false);
  const [currentStepID, setCurrentStepID] =
    useState<reportGenerateStepID>("config_options");
  const [selectedVendorPortfolioIDs, setSelectedVendorPortfolioIDs] = useState<
    number[]
  >([]);
  const [selectedDomainPortfolioIDs, setSelectedDomainPortfolioIDs] = useState<
    number[]
  >([]);
  const [
    openCreateTemplateAfterGenerateModal,
    createTemplateAfterGenerateModal,
  ] = useModalV2(CreateTemplateAfterGenerateModal);
  const [moveOnStepsRecalc, setMoveOnStepsRecalc] = useState(false);

  const [allVendorsSearchResult, setAllVendorsSearchResult] = useState(
    [] as IVendorSearchVendorAndWatched[]
  );
  const [filterText, setFilterText] = useState(existingFilterText ?? "");

  const [fileType, setFileType] = useState(
    initialFileType ?? ExportFiletype.PDF
  );

  // Set up a router block on going back, so we confirm the user wants to cancel.
  const shouldBlockHistoryFunc = useCallback(
    (_location: Location, action: Action) => {
      // We only want to pop up a confirmation modal when going back
      return action === "POP";
    },
    []
  );
  const blockConfirmationModalProps = useMemo<
    Omit<useConfirmationModalV2Props, "buttonAction">
  >(
    () => ({
      title: "Are you sure you want to cancel creating this report?",
      description: "You will lose any of your current changes.",
      buttonText: location.state?.backContext?.backToText ?? "Back",
      cancelText: "Continue editing",
    }),
    [location]
  );
  const blockConfirmationModal = useBlockRouterWithConfirmationModal(
    shouldBlockHistoryFunc,
    blockConfirmationModalProps
  );
  const availableConfig = unfilteredAvailableConfig?.[fileType];
  const availableConfigWithoutVendorId =
    unfilteredAvailableConfigWithoutVendorId?.[fileType];

  // For the steps only, we'll fall back to the available config without the vendor ID, if available.
  // This is so the steps don't jump around when switching vendor ID.
  const stepsAvailableConfig =
    availableConfig ?? availableConfigWithoutVendorId;

  const [configuredExportConfigState, configuredExportConfigDispatch] =
    useConfiguredExportConfigReducer(availableConfig, cannedConfig);

  useEffect(() => {
    if (availableConfig && cannedConfig) {
      configuredExportConfigDispatch({
        type: "UPDATE_AVAILABLE_CONFIG",
        availableConfig: availableConfig,
        configuredConfig: configuredExportConfigState.hasChanged
          ? undefined
          : cannedConfig,
      });
    }
  }, [configuredExportConfigDispatch, availableConfig, cannedConfig, vendorId]);

  useEffect(() => {
    dispatch(fetchCustomerLimitData());
  }, [dispatch]);

  useEffect(() => {
    if (!reportType) {
      return;
    }

    /* we only want to load the sections if this is a watched vendor. if it isn't then we'll wait for this effect to be triggered WITH a watched vendor */
    if (!unfilteredAvailableConfig && vendorWatched) {
      dispatch(
        fetchExportSections({
          exportType: reportType,
          exportOptions: vendorId
            ? {
                vendor_id: vendorId,
              }
            : {},
          vendorId,
          applyFilters: false,
        })
      );
    }
  }, [
    dispatch,
    unfilteredAvailableConfig,
    reportType,
    vendorId,
    vendorWatched,
  ]);

  // Make sure we default to a valid step, based on the available config
  useEffect(() => {
    if (!availableConfig) {
      return;
    }

    let stepID = currentStepID;

    const hasGlobalOptions =
      availableConfig.globalOptions && availableConfig.globalOptions.length > 0;
    const hasSections =
      availableConfig.sections && availableConfig.sections.length > 0;

    if (vendorIdStepRequired && !vendorId) {
      stepID = "set_vendor";
    } else {
      if (!hasGlobalOptions && stepID === "config_options") {
        stepID = "config_sections";
      }

      if (!hasSections && stepID === "config_sections") {
        if (hasGlobalOptions) {
          stepID = "config_options";
        } else {
          stepID = "report_schedule";
        }
      }
    }

    if (stepID !== currentStepID) {
      setCurrentStepID(stepID);
    }
  }, [availableConfig, currentStepID, vendorIdStepRequired, vendorId]);

  const steps = useMemo<IStep<reportGenerateStepID>[]>(() => {
    const steps: IStep<reportGenerateStepID>[] = [];
    let stepSelectionDisabled = false;

    if (vendorIdStepRequired) {
      if (!vendorId) {
        stepSelectionDisabled = true;
      }

      steps.push({
        id: "set_vendor",
        text: `Select ${vendorWords.singular}`,
        onClick: stepSelectionDisabled
          ? undefined
          : () => setCurrentStepID("set_vendor"),
      });
    }

    if (
      stepsAvailableConfig?.globalOptions &&
      stepsAvailableConfig.globalOptions.length > 0
    ) {
      steps.push({
        id: "config_options",
        text: "Configure options",
        onClick: stepSelectionDisabled
          ? undefined
          : () => setCurrentStepID("config_options"),
      });
    }

    if (
      stepsAvailableConfig?.sections &&
      stepsAvailableConfig.sections.length > 0
    ) {
      steps.push({
        id: "config_sections",
        text: "Customize report",
        onClick: stepSelectionDisabled
          ? undefined
          : () => setCurrentStepID("config_sections"),
      });
    }

    if (
      configuredExportConfigState.requiredExtraSteps.includes(
        RequiredExtraStep.VendorPortfolios
      ) &&
      orgSupportsVendorPortfolios
    ) {
      steps.push({
        id: "select_vendor_portfolios",
        text: "Select portfolios",
        onClick: stepSelectionDisabled
          ? undefined
          : () => setCurrentStepID("select_vendor_portfolios"),
      });
    }

    if (
      configuredExportConfigState.requiredExtraSteps.includes(
        RequiredExtraStep.DomainPortfolios
      ) &&
      orgSupportsDomainPortfolios
    ) {
      steps.push({
        id: "select_domain_portfolios",
        text: "Select portfolios",
        onClick: stepSelectionDisabled
          ? undefined
          : () => setCurrentStepID("select_domain_portfolios"),
      });
    }

    steps.push({
      id: "report_schedule",
      text: "Report delivery",
      onClick: stepSelectionDisabled
        ? undefined
        : () => setCurrentStepID("report_schedule"),
    });

    if (moveOnStepsRecalc) {
      const curStepIdx = steps.findIndex((s) => s.id === currentStepID);
      if (curStepIdx + 1 < steps.length) {
        setCurrentStepID(steps[curStepIdx + 1].id);
      }
      setMoveOnStepsRecalc(false);
    }

    return steps;
  }, [
    stepsAvailableConfig,
    configuredExportConfigState,
    orgSupportsVendorPortfolios,
    orgSupportsDomainPortfolios,
    vendorId,
    vendorIdStepRequired,
    vendorWords,
  ]);

  const breadcrumbs = useMemo<crumb[]>(
    () => [
      { text: "Reports", to: "/reportexports" },
      { text: "Generate report" },
    ],
    []
  );

  const goBack = useCallback(
    () =>
      location.state?.backContext?.goBack
        ? history.goBack()
        : location.state && location.state.backContext
          ? history.push(location.state.backContext?.backTo || "")
          : history.push("/reportexports"),
    [location, history]
  );

  const onSuccessfulSchedule = useCallback(
    () =>
      history.push("/reportexports/scheduled", {
        noRemoveWhispers: true,
      }),
    [history]
  );
  const onSuccessfulGenerate = useCallback(() => {
    if (
      userCanWriteOwnCustomTemplates &&
      configuredExportConfigState.hasChanged
    ) {
      openCreateTemplateAfterGenerateModal({
        baseDefaultID: baseDefaultID,
        baseUUID: baseUUID,
        baseTemplateName: cannedReportName,
        baseBadgeImageID: cannedReportBadgeID || BadgeImageID.Custom,
        baseTemplateDescription: cannedReportDescription,
        config: configuredExportConfigState.configuredExportConfig,
        userCanEditCustomTemplate:
          userCanWriteOrgCustomTemplates ||
          (userCanWriteOwnCustomTemplates && currentUserId === createdBy),
        onDone: () =>
          history.push("/reportexports/generated", {
            noRemoveWhispers: true,
          }),
      });
    } else {
      history.push("/reportexports/generated", {
        noRemoveWhispers: true,
      });
    }
  }, [
    history,
    configuredExportConfigState.configuredExportConfig,
    configuredExportConfigState.hasChanged,
    cannedReportName,
    cannedReportDescription,
    openCreateTemplateAfterGenerateModal,
    userCanWriteOwnCustomTemplates,
    userCanWriteOrgCustomTemplates,
    baseDefaultID,
    baseUUID,
    createdBy,
  ]);

  const submitGenerateReport = useSubmitGenerateReport(
    dispatch,
    reportType,
    fileType,
    cannedReportName,
    reportDeliveryState,
    configuredExportConfigState,
    vendorId,
    vendorName,
    setReportGenerateLoading,
    selectedVendorPortfolioIDs,
    selectedDomainPortfolioIDs,
    onSuccessfulSchedule,
    onSuccessfulGenerate,
    orgSupportsVendorPortfolios,
    orgSupportsDomainPortfolios,
    !!createdBy, // if createdBy is not undefined this is a custom report type
    true
  );

  const assessedVendorsOnly = useMemo(() => {
    return exportConfigRequiresVendorWithCompletedAssessment(cannedConfig);
  }, [cannedConfig]);

  const goPrevBtn = useMemo(() => {
    const curStepIdx = steps.findIndex((s) => s.id === currentStepID);
    const prevStepIdx = curStepIdx - 1;
    if (prevStepIdx < 0 || prevStepIdx >= steps.length) {
      return undefined;
    }

    return (
      <Button leftArrow onClick={() => setCurrentStepID(steps[prevStepIdx].id)}>
        Previous
      </Button>
    );
  }, [steps, currentStepID]);

  const goNextBtn = useMemo(() => {
    const curStepIdx = steps.findIndex((s) => s.id === currentStepID);
    const nextStepIdx = curStepIdx + 1;
    if (nextStepIdx === 0) {
      return undefined;
    }

    let goNext = () => {
      setCurrentStepID(steps[nextStepIdx].id);
    };
    let goNextText = "Next";
    let goNextEnabled = true;
    let goNextDisabledReason: string | undefined;
    let goNextLoading = false;
    let goNextArrow = true;

    if (
      currentStepID === "set_vendor" &&
      supportUnwatchedVendors &&
      !vendorId
    ) {
      goNextEnabled = false;
    } else if (
      currentStepID === "set_vendor" &&
      supportUnwatchedVendors &&
      vendorId &&
      !vendorWatched
    ) {
      goNext = () => setMonitorModalVisible(true);
    } else if (
      currentStepID === "set_vendor" &&
      (!vendorId || !unfilteredAvailableConfig)
    ) {
      goNextEnabled = false;
      if (vendorId) {
        goNextLoading = true;
      }
    } else if (supportUnwatchedVendors && currentStepID === "set_vendor") {
      goNext = () => {
        // make sure the vendors page doesn't inherit the search terms that are used here.
        dispatch(
          setVendorSearch({
            loading: false,
            query: "",
            results: {},
          })
        );
        setCurrentStepID(steps[nextStepIdx].id);
      };
    } else if (
      currentStepID === "config_options" &&
      !configuredExportConfigState.globalOptsValid
    ) {
      goNextEnabled = false;
      goNextDisabledReason =
        configuredExportConfigState.globalOptsValidationErr;
    } else if (
      currentStepID === "config_sections" &&
      !configuredExportConfigState.sectionsValid
    ) {
      goNextEnabled = false;
      goNextDisabledReason = configuredExportConfigState.sectionsValidationErr;
    } else if (
      currentStepID === "select_vendor_portfolios" &&
      selectedVendorPortfolioIDs.length === 0
    ) {
      goNextEnabled = false;
    } else if (currentStepID === "report_schedule") {
      // Final step. Make sure the report delivery settings are valid.
      goNext = submitGenerateReport;
      goNextText = "Generate report";
      goNextLoading = reportGenerateLoading;
      goNextArrow = false;

      const deliveryValidationErr =
        reportDeliveryStateValidationErr(reportDeliveryState);
      if (deliveryValidationErr) {
        goNextEnabled = false;
        goNextDisabledReason = deliveryValidationErr;
      } else if (!configuredExportConfigState.valid) {
        goNextEnabled = false;
        goNextDisabledReason = configuredExportConfigState.validationErr;
      }
    }

    return (
      <SidePopupV2
        inline
        text={!goNextEnabled ? goNextDisabledReason : undefined}
      >
        <Button
          primary
          onClick={goNext}
          disabled={!goNextEnabled}
          arrow={goNextArrow}
          loading={goNextLoading}
        >
          {goNextText}
        </Button>
      </SidePopupV2>
    );
  }, [
    submitGenerateReport,
    steps,
    currentStepID,
    configuredExportConfigState,
    selectedVendorPortfolioIDs,
    reportDeliveryState,
    vendorId,
    unfilteredAvailableConfig,
    reportGenerateLoading,
  ]);

  if (!reportType) {
    // This view requires a report type passed in via state to function.
    return <Redirect to="/reportexports" />;
  }

  const openAssessmentsView = () => {
    history.push(`/vendor/${vendorId}/assessment`, {
      startInitial: true,
    });
  };

  return (
    <StepsWithSections id="report_generate">
      <PageHeader
        history={history}
        title={
          cannedReportName
            ? `Customize your ${cannedReportName}${
                vendorName && currentStepID !== "set_vendor"
                  ? ` for ${vendorName}`
                  : ""
              }`
            : "Customize your report"
        }
        vendorId={undefined}
        backAction={goBack}
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : "Back to Reports"
        }
        breadcrumbs={breadcrumbs}
      />
      {!stepsAvailableConfig ? (
        <LoadingBanner />
      ) : (
        <>
          <Steps
            steps={steps}
            currentStep={steps.findIndex((s) => s.id === currentStepID) + 1}
          />
          <div className="main-content">
            {currentStepID === "set_vendor" ? (
              <SelectVendorCard
                className="step"
                filterText={filterText}
                setFilterText={setFilterText}
                selectedVendorIDs={vendorId ? [vendorId] : []}
                allVendorsSearchResult={allVendorsSearchResult}
                setAllVendorsSearchResult={setAllVendorsSearchResult}
                onSelectVendor={(
                  vendorId,
                  selected,
                  vendorName,
                  vendorHostname,
                  isWatched
                ) => {
                  if (selected) {
                    setVendor(
                      vendorId,
                      vendorName ?? vendorHostname,
                      vendorHostname,
                      isWatched
                    );
                  }
                }}
                assessedVendorsOnly={assessedVendorsOnly}
                isVisible
                supportUnwatchedVendors={supportUnwatchedVendors}
              />
            ) : currentStepID === "config_options" ? (
              <ReportCard className="step" newStyles>
                <div className="header">Configure options</div>
                <div className="config-options-card">
                  {availableConfig && availableConfig.globalOptions && (
                    <ExportConfigurationOptions
                      availableOptions={availableConfig.globalOptions}
                      configuredExportConfig={
                        configuredExportConfigState.configuredExportConfig
                      }
                      configuredExportConfigDispatch={
                        configuredExportConfigDispatch
                      }
                    />
                  )}
                </div>
              </ReportCard>
            ) : currentStepID === "config_sections" ? (
              <ReportCard className="step" newStyles>
                <div className="header">Configure sections</div>
                <div>
                  {availableConfig && (
                    <ExportSectionsConfiguration
                      availableExportConfig={availableConfig}
                      configuredExportConfig={
                        configuredExportConfigState.configuredExportConfig
                      }
                      configuredExportConfigDispatch={
                        configuredExportConfigDispatch
                      }
                    />
                  )}
                </div>
              </ReportCard>
            ) : currentStepID === "select_vendor_portfolios" ? (
              <ReportCard className="step" newStyles>
                <div className="header">Select portfolios</div>
                <div>
                  <SelectPortfoliosStep
                    portfolioType={PortfolioType.Vendor}
                    initialSelectedPortfolioIDs={selectedVendorPortfolioIDs}
                    onSelectionChange={(portfolioIDs) =>
                      setSelectedVendorPortfolioIDs(portfolioIDs)
                    }
                  />
                </div>
              </ReportCard>
            ) : currentStepID === "select_domain_portfolios" ? (
              <ReportCard className="step" newStyles>
                <div className="header">Select portfolios</div>
                <div>
                  <SelectPortfoliosStep
                    portfolioType={PortfolioType.Domain}
                    initialSelectedPortfolioIDs={selectedDomainPortfolioIDs}
                    onSelectionChange={(portfolioIDs) =>
                      setSelectedDomainPortfolioIDs(portfolioIDs)
                    }
                  />
                </div>
              </ReportCard>
            ) : currentStepID === "report_schedule" ? (
              <ReportCard className="step" newStyles>
                <div className="header">Report delivery</div>
                <div>
                  <ReportDeliveryStep
                    reportDeliveryState={reportDeliveryState}
                    setReportDeliveryState={setReportDeliveryState}
                    availableFileTypes={availableFileTypes}
                    fileType={fileType}
                    setFileType={setFileType}
                  />
                </div>
              </ReportCard>
            ) : undefined}
          </div>
        </>
      )}
      <MonitorVendorModal
        dispatch={dispatch}
        isActive={monitorModalVisible}
        vendorID={vendorId}
        vendorName={vendorName}
        vendorPrimaryHostname={vendorHostname}
        onClose={() => setMonitorModalVisible(false)}
        assessedVendorsOnly={assessedVendorsOnly}
        vendorWatchLimit={vendorWatchLimit}
        vendorWatchCount={vendorWatchCount}
        assuranceType={assuranceType}
        onSuccess={async () => {
          dispatch(refreshVendorSearchResults());
          dispatch(fetchCustomerLimitData());
          if (!assessedVendorsOnly) {
            setMoveOnStepsRecalc(true);
          }
          setVendor(
            vendorId as number,
            vendorName as string,
            vendorHostname as string,
            true
          );
          await dispatch(
            fetchExportSections({
              exportType: reportType,
              exportOptions: vendorId
                ? {
                    vendor_id: vendorId,
                  }
                : {},
              vendorId,
              applyFilters: false,
            })
          );
          dispatch(
            addDefaultSuccessAlert(
              vendorWatchLimit > 0
                ? `Monitored a new ${vendorWords.singular} (${
                    vendorWatchCount + 1
                  }/${vendorWatchLimit})`
                : `Monitored a new ${vendorWords.singular}`,
              [
                `Visit the ${vendorWords.singular} summary for ${vendorName} to edit vendor details. `,
              ]
            )
          );
          setMonitorModalVisible(false);
          // make sure the vendors page doesn't inherit the search terms that are used here.
          dispatch(
            setVendorSearch({
              loading: false,
              query: "",
              results: {},
            })
          );
          if (assessedVendorsOnly) {
            openAssessmentsView();
          }
        }}
      />
      <ActionBar active>
        <div className="left-side">{goPrevBtn}</div>
        <div className={"right-side"}>
          <Button onClick={goBack} tertiary>
            Cancel
          </Button>
          {goNextBtn}
        </div>
      </ActionBar>
      {createTemplateAfterGenerateModal}
      {blockConfirmationModal}
    </StepsWithSections>
  );
};

const ConnectedReportGenerate = appConnect<
  IReportGenerateConnectedProps,
  never,
  IReportGenerateOwnProps
>((state, props) => {
  const unfilteredAvailableConfig = (
    getExportSections(
      state,
      props.reportType,
      false,
      {
        vendor_id: props.vendorId,
      },
      props.vendorId,
      false,
      undefined
    ) as ExportSection
  ).data?.availableConfigByFiletype;

  const unfilteredAvailableConfigWithoutVendorId = (
    getExportSections(
      state,
      props.reportType,
      false,
      {},
      undefined,
      false,
      undefined
    ) as ExportSection
  ).data?.availableConfigByFiletype;

  const userCanWriteOwnCustomTemplates =
    state.common.userData.orgPermissions.includes(
      OrgAccessCustomReportingTemplates
    ) &&
    (state.common.userData.userPermissions.includes(
      UserWriteCustomReportingTemplates
    ) ||
      !!Object.values(
        state.common.userData.vendorPortfolioSpecificPermissions
      ).find((perms) => perms?.includes(UserWriteCustomReportingTemplates)));

  const userData = _get(state.common, "userData", undefined);
  let isSelfServiceTrial = false;
  let isVendorFreeTrial = false;
  let currentOrgIsTrial = false;
  if (userData && userData.orgList && userData.currentOrgID > 0) {
    for (let i = 0; i < userData.orgList.length; i++) {
      if (userData.orgList[i].id === userData.currentOrgID) {
        currentOrgIsTrial =
          userData.orgList[i].accountType === organisationAccountType.trial;
        isVendorFreeTrial = !!userData.orgList[i].isVendorTrial;
        isSelfServiceTrial =
          !!userData.orgList[i].permissions?.includes(SelfSignupCustomer);
        break;
      }
    }
  }

  const vendorWatchLimit = state.cyberRisk.customerData.vendorWatchLimit;
  const vendorWatchCount = state.cyberRisk.customerData.vendorWatchCount;
  const existingFilterText = ""; //state.cyberRisk.vendorSearch.query;

  const newProps: IReportGenerateConnectedProps = {
    unfilteredAvailableConfig,
    unfilteredAvailableConfigWithoutVendorId,
    orgSupportsVendorPortfolios: state.common.userData.orgPermissions.includes(
      OrgAccessVendorPortfolios
    ),
    orgSupportsDomainPortfolios: state.common.userData.orgPermissions.includes(
      OrgAccessDomainPortfolios
    ),
    userCanWriteOwnCustomTemplates: userCanWriteOwnCustomTemplates,
    userCanWriteOrgCustomTemplates:
      userCanWriteOwnCustomTemplates &&
      state.common.userData.userPermissions.includes(UserWriteOwnOrganisation),
    assuranceType: state.common.userData.assuranceType,
    currentUserId: state.common.userData.id,
    supportUnwatchedVendors:
      SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS &&
      currentOrgIsTrial &&
      (isSelfServiceTrial || isVendorFreeTrial),
    vendorWatchLimit: vendorWatchLimit ?? 0,
    vendorWatchCount: vendorWatchCount ?? 0,
    existingFilterText,
  };

  return newProps;
})(ReportGenerate);

const ReportGeneratePropsProvider: FC<IReportGeneratePropsProviderProps> = ({
  location,
  history,
  match,
}) => {
  const [vendorId, setVendorId] = useState(location.state?.vendorId);
  const [vendorName, setVendorName] = useState(location.state?.vendorName);
  const [vendorHostname, setVendorHostname] = useState("");
  const [vendorWatched, setVendorWatched] = useState(true);

  const setVendor = useCallback(
    (
      vendorId: number,
      vendorName: string,
      vendorHostname: string,
      vendorWatched: boolean
    ) => {
      setVendorId(vendorId);
      setVendorName(vendorName);
      setVendorHostname(vendorHostname);
      setVendorWatched(vendorWatched);
    },
    []
  );

  return (
    <ConnectedReportGenerate
      history={history}
      location={location}
      match={match}
      reportType={location.state?.reportType}
      initialFileType={location.state?.initialFileType}
      availableFileTypes={location.state?.availableFileTypes}
      baseDefaultID={location.state?.baseDefaultID}
      baseUUID={location.state?.baseUUID}
      createdBy={location.state?.createdBy}
      cannedConfig={location.state?.cannedConfig}
      cannedReportName={location.state?.cannedReportName}
      cannedReportBadgeID={location.state?.cannedReportBadgeID}
      cannedReportDescription={location.state?.cannedReportDescription}
      vendorId={vendorId}
      vendorName={vendorName}
      vendorHostname={vendorHostname}
      vendorWatched={vendorWatched}
      setVendor={setVendor}
      vendorIdStepRequired={
        !!location.state?.vendorIdRequired && !location.state?.vendorId
      }
    />
  );
};

export default ReportGeneratePropsProvider;

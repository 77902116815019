/*
 * This package is generated by gen.go, DO NOT MANUALLY EDIT.
 */

export enum RiskClassification {
  SecurityPoliciesAndProcesses = "security_policies_and_processes",
  SecurityPoliciesAndProcessesInformationSecurity = "security_policies_and_processes_information_security",
  SecurityPoliciesAndProcessesITOperationsManagement = "security_policies_and_processes_it_operations_management",
  SecurityPoliciesAndProcessesComplianceManagement = "security_policies_and_processes_compliance_management",
  SecurityPoliciesAndProcessesHumanResourcesSecurity = "security_policies_and_processes_human_resources_security",
  AssetManagement = "AM",
  AssetManagementAssetManagementProgram = "infrastructure_and_asset_management_asset_management",
  AssetManagementEndpointSecurity = "infrastructure_and_asset_management_endpoint_security",
  AssetManagementPhysicalSecurity = "infrastructure_and_asset_management_physical_security",
  InfrastructureManagement = "infrastructure_and_asset_management",
  InfrastructureManagementDNSSecurity = "IM.DS",
  InfrastructureManagementAttackSurface = "IM.AS",
  InfrastructureManagementWebsiteSecurity = "infrastructure_and_asset_management_website_security",
  InfrastructureManagementEncryption = "IM.EN",
  InfrastructureManagementDataLeakage = "IM.DL",
  InfrastructureManagementNetworkSecurity = "infrastructure_and_asset_management_network_security",
  InfrastructureManagementIPReputation = "IM.IP",
  InfrastructureManagementEmailSecurity = "infrastructure_and_asset_management_email_security",
  DataProtection = "data_classification_and_handling",
  DataProtectionAccessManagement = "data_classification_and_handling_access_management",
  DataProtectionPrivacyManagement = "data_classification_and_handling_privacy_management",
  ApplicationSecurity = "application_security",
  ApplicationSecuritySoftwareDevelopment = "application_security_software_development",
  ApplicationSecurityVulnerabilityManagement = "application_security_vulnerability_management",
  RiskManagement = "risk_management",
  RiskManagementEnterprise = "risk_management_enterprise",
  RiskManagement3rdNthPartyManagement = "risk_management_third_to_nth_party_management",
  RiskManagementBrandAndReputationRisk = "RM.BR",
  OperationalResilience = "recovery_and_response",
  OperationalResilienceBusinessContinuity = "recovery_and_response_business_continuity",
  OperationalResilienceCybersecurityIncidentManagement = "recovery_and_response_cybersecurity_incident_management",
}

export function RiskClassificationString(
  c: RiskClassification | undefined,
  defaultString: string,
  enabled: boolean
): string {
  if (!enabled) {
    return defaultString;
  }

  switch (c) {
    case RiskClassification.SecurityPoliciesAndProcesses:
      return "Security policies and processes";
    case RiskClassification.SecurityPoliciesAndProcessesInformationSecurity:
      return "Information security";
    case RiskClassification.SecurityPoliciesAndProcessesITOperationsManagement:
      return "IT operations management";
    case RiskClassification.SecurityPoliciesAndProcessesComplianceManagement:
      return "Compliance management";
    case RiskClassification.SecurityPoliciesAndProcessesHumanResourcesSecurity:
      return "Human resources security";
    case RiskClassification.AssetManagement:
      return "Asset Management";
    case RiskClassification.AssetManagementAssetManagementProgram:
      return "Asset management program";
    case RiskClassification.AssetManagementEndpointSecurity:
      return "Endpoint security";
    case RiskClassification.AssetManagementPhysicalSecurity:
      return "Physical security";
    case RiskClassification.InfrastructureManagement:
      return "Infrastructure management";
    case RiskClassification.InfrastructureManagementDNSSecurity:
      return "DNS security";
    case RiskClassification.InfrastructureManagementAttackSurface:
      return "Attack surface";
    case RiskClassification.InfrastructureManagementWebsiteSecurity:
      return "Website security";
    case RiskClassification.InfrastructureManagementEncryption:
      return "Encryption";
    case RiskClassification.InfrastructureManagementDataLeakage:
      return "Data leakage";
    case RiskClassification.InfrastructureManagementNetworkSecurity:
      return "Network security";
    case RiskClassification.InfrastructureManagementIPReputation:
      return "IP reputation";
    case RiskClassification.InfrastructureManagementEmailSecurity:
      return "Email security";
    case RiskClassification.DataProtection:
      return "Data protection";
    case RiskClassification.DataProtectionAccessManagement:
      return "Access management";
    case RiskClassification.DataProtectionPrivacyManagement:
      return "Privacy management";
    case RiskClassification.ApplicationSecurity:
      return "Application security";
    case RiskClassification.ApplicationSecuritySoftwareDevelopment:
      return "Software development";
    case RiskClassification.ApplicationSecurityVulnerabilityManagement:
      return "Vulnerability management";
    case RiskClassification.RiskManagement:
      return "Risk management";
    case RiskClassification.RiskManagementEnterprise:
      return "Enterprise risk management";
    case RiskClassification.RiskManagement3rdNthPartyManagement:
      return "Third to Nth party management";
    case RiskClassification.RiskManagementBrandAndReputationRisk:
      return "Brand and reputation risk";
    case RiskClassification.OperationalResilience:
      return "Operational resilience";
    case RiskClassification.OperationalResilienceBusinessContinuity:
      return "Business continuity";
    case RiskClassification.OperationalResilienceCybersecurityIncidentManagement:
      return "Cybersecurity incident management";

    default:
      return defaultString;
  }
}

// Group returns the parent category to which this Classification belongs.
// For categories with no parent, Group returns the receiver unchanged.
export function RiskClassificationGroup(
  c: RiskClassification | undefined
): RiskClassification | undefined {
  switch (c) {
    case RiskClassification.SecurityPoliciesAndProcesses:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesInformationSecurity:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesITOperationsManagement:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesComplianceManagement:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesHumanResourcesSecurity:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.AssetManagement:
      return RiskClassification.AssetManagement;
    case RiskClassification.AssetManagementAssetManagementProgram:
      return RiskClassification.AssetManagement;
    case RiskClassification.AssetManagementEndpointSecurity:
      return RiskClassification.AssetManagement;
    case RiskClassification.AssetManagementPhysicalSecurity:
      return RiskClassification.AssetManagement;
    case RiskClassification.InfrastructureManagement:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementDNSSecurity:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementAttackSurface:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementWebsiteSecurity:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementEncryption:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementDataLeakage:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementNetworkSecurity:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementIPReputation:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.InfrastructureManagementEmailSecurity:
      return RiskClassification.InfrastructureManagement;
    case RiskClassification.DataProtection:
      return RiskClassification.DataProtection;
    case RiskClassification.DataProtectionAccessManagement:
      return RiskClassification.DataProtection;
    case RiskClassification.DataProtectionPrivacyManagement:
      return RiskClassification.DataProtection;
    case RiskClassification.ApplicationSecurity:
      return RiskClassification.ApplicationSecurity;
    case RiskClassification.ApplicationSecuritySoftwareDevelopment:
      return RiskClassification.ApplicationSecurity;
    case RiskClassification.ApplicationSecurityVulnerabilityManagement:
      return RiskClassification.ApplicationSecurity;
    case RiskClassification.RiskManagement:
      return RiskClassification.RiskManagement;
    case RiskClassification.RiskManagementEnterprise:
      return RiskClassification.RiskManagement;
    case RiskClassification.RiskManagement3rdNthPartyManagement:
      return RiskClassification.RiskManagement;
    case RiskClassification.RiskManagementBrandAndReputationRisk:
      return RiskClassification.RiskManagement;
    case RiskClassification.OperationalResilience:
      return RiskClassification.OperationalResilience;
    case RiskClassification.OperationalResilienceBusinessContinuity:
      return RiskClassification.OperationalResilience;
    case RiskClassification.OperationalResilienceCybersecurityIncidentManagement:
      return RiskClassification.OperationalResilience;
    default:
      return undefined;
  }
}

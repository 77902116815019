import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import "../style/components/ReportVendorMonitor.scss";
import { IVendorWords } from "../constants";
import { IVendorSearchVendorAndWatched } from "../types/vendor";
import Button from "./core/Button";
import { contactSupport } from "../helpers";
import { locationState } from "../types/router";
import { SelfSignupCustomer, useHasOrgEntitlement } from "../permissions";
import { useHistory } from "react-router-dom";
import { History } from "history";

interface IReportVendorMonitorOwnProps {
  selectedVendorDetails: IVendorSearchVendorAndWatched;
  vendorWords: IVendorWords;
  vendorWatchCount?: number;
  vendorWatchLimit?: number;
  assessedVendorsOnly?: boolean;
}

type IReportVendorMonitorProps = IReportVendorMonitorOwnProps &
  DefaultThunkDispatchProp;

//
// ReportVendorMonitor
// A step in the ReportsLibraryModal that allows the user to elect to watch the vendor when required.
// The step also takes care of reporting that the vendor slots are exhausted (when required), and navigating to the vendor assessments page when needed.
//
export const ReportVendorMonitor = (props: IReportVendorMonitorProps) => {
  const isAtWatchLimit =
    props.vendorWatchCount &&
    props.vendorWatchCount >= 0 &&
    props.vendorWatchLimit &&
    props.vendorWatchLimit >= 0 &&
    props.vendorWatchCount >= props.vendorWatchLimit;

  const history = useHistory<locationState>();
  const selfSignupCustomer = useHasOrgEntitlement(SelfSignupCustomer);

  const overVendorMessage = (
    history: History<locationState>,
    selfSignupCustomer: boolean
  ) =>
    selfSignupCustomer ? (
      <>
        <p>{`A ${props.vendorWords.singular} must be monitored before you generate a report, and you've reached your ${props.vendorWords.singular} limit. Upgrade your plan to add more ${props.vendorWords.plural}.`}</p>
        <Button
          className="butt-support"
          onClick={() => history.push("settings/billing")}
        >
          Upgrade Plan
        </Button>
      </>
    ) : (
      <>
        <p>{`A ${props.vendorWords.singular} must be monitored before you generate a report, and you've reached your ${props.vendorWords.singular} limit. Contact us to add more ${props.vendorWords.plural}.`}</p>
        <Button className="butt-support" onClick={() => contactSupport()}>
          Contact us
        </Button>
      </>
    );

  return (
    <>
      <div id={"report-vendor-monitor"}>
        <div className={"body"}>
          {!!isAtWatchLimit &&
            !props.selectedVendorDetails.isWatched &&
            overVendorMessage(history, selfSignupCustomer)}
          {(!isAtWatchLimit || props.selectedVendorDetails.isWatched) &&
            props.assessedVendorsOnly && (
              <>
                <p>
                  {!props.selectedVendorDetails.isWatched && (
                    <>
                      {`A risk assessment must be completed on a monitored ${props.vendorWords.singular} before you generate a report.`}
                    </>
                  )}
                  {props.selectedVendorDetails.isWatched &&
                    !props.selectedVendorDetails.assessments?.some(
                      (a) => !!a.lastPublishedDate
                    ) && (
                      <>
                        {`A risk assessment must be completed before you generate a report.`}
                      </>
                    )}
                </p>
                <div>
                  {!props.selectedVendorDetails.isWatched && (
                    <div className={"link"}>
                      <a
                        href="https://help.upguard.com/en/articles/3765199-how-to-add-a-new-vendor"
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`Learn more about monitoring ${props.vendorWords.plural}`}</a>{" "}
                      <div className="cr-icon-arrow-right" />
                    </div>
                  )}
                  {props.selectedVendorDetails.isWatched &&
                    !props.selectedVendorDetails.assessments?.some(
                      (a) => a.lastPublishedDate
                    ) && (
                      <div className={"link"}>
                        <a
                          href="https://help.upguard.com/en/articles/6504360-using-the-risk-assessment-framework-in-upguard"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {"Learn more about risks assessments"}
                        </a>{" "}
                        <div className="cr-icon-arrow-right" />
                      </div>
                    )}
                </div>
              </>
            )}
          {!isAtWatchLimit &&
            !props.selectedVendorDetails.isWatched &&
            !props.assessedVendorsOnly && (
              <>
                <p>
                  {`A ${props.vendorWords.singular} must be monitored before you generate a report.`}
                </p>
                <div>
                  <div className={"link"}>
                    <a
                      href="https://help.upguard.com/en/articles/3765199-how-to-add-a-new-vendor"
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`Learn more about monitoring ${props.vendorWords.plural}`}</a>{" "}
                    <div className="cr-icon-arrow-right" />
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default ReportVendorMonitor;

import { useState } from "react";

import { ISharedAssessment, ProfileLogo } from "../../types/sharedAssessment";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import "../../style/components/SharedProfileLogo.scss";

interface ISharedProfileLogoProps {
  sharedAssessment: ISharedAssessment;
  displayName?: boolean;
}

export const ShareProfileLogo = ({
  sharedAssessment,
  displayName,
}: ISharedProfileLogoProps): JSX.Element => {
  const [imageFound, setImageFound] = useState(true);

  let logo = <></>;
  if (sharedAssessment.profileLogo === ProfileLogo.DefaultLogo) {
    logo = (
      <CompanyLogo
        name={sharedAssessment.linkedVendor.name}
        displayName={displayName}
        domain={sharedAssessment.linkedVendor.primary_hostname}
      />
    );
  } else if (
    sharedAssessment.profileLogo === ProfileLogo.CustomLogo &&
    sharedAssessment.profileLogoMeta?.logoUrl &&
    imageFound // this ensures we fall back to no logo instead of showing a broken image in case of error
  ) {
    logo = (
      <div className={"custom-logo"}>
        <div
          className={"circle-logo"}
          style={{
            backgroundImage: `url("${sharedAssessment.profileLogoMeta.logoUrl}")`,
          }}
        />
        <img
          style={{ display: "none" }}
          src={sharedAssessment.profileLogoMeta.logoUrl}
          onError={() => setImageFound(false)}
        />
        {displayName && (
          <div className={"company-label"}>
            {sharedAssessment.linkedVendor.name}
          </div>
        )}
      </div>
    );
  }

  return <div className={"shared-profile-logo"}>{logo}</div>;
};

import "./webpackRuntime";
import LoadingBanner from "../_common/components/core/LoadingBanner";
import { createBrowserHistory } from "history";
import { find as _find, get as _get, throttle as _throttle } from "lodash";
import PropTypes from "prop-types";
import { Component, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  fetchUserData,
  switchUserOrg,
} from "../_common/reducers/commonActions";
import {
  clearLocalStorageItem,
  getLocalStorageItemString,
  updateUserLastActive,
} from "../_common/session";
import "../_common/style/main.scss";
import { setGlobalDispatch, trackEvent } from "../_common/tracking";
import Navigation from "../_common/views/Navigation";
import lazyLoad from "../_common/lazyload";
import moment from "moment";
import {
  init as sentryInit,
  setUser as sentrySetUser,
  httpClientIntegration,
  browserTracingIntegration,
} from "@sentry/browser";
import MessageAlerts from "../_common/components/MessageAlerts";
import { clearAllMessageAlerts } from "../_common/reducers/messageAlerts.actions";
import store from "../_common/types/reduxStore";
import { appConnect } from "../_common/types/reduxHooks";
import { getPLGTasksIncompleteCount } from "../vendorrisk/reducers/plgOnboardingChecklistActions";
import {
  hasOrgPermission,
  OrgAccessMultiProductNavigation,
} from "../_common/permissions";
import { NavigationRouter } from "../_common/views/AppContentContainer";
import ErrorBoundary from "../_common/components/ErrorBoundary";
import CommonRouter from "../_common/views/CommonRouter";
import { initSentry } from "../_common/sentry";
import { ConsumeQueryParams } from "../_common/query";

// Lazy load the Auth0WrapperV2Container to better utilize caching on its chunk
const Auth0WrapperV2Container = lazyLoad(
  () => import("../_common/components/Auth0Wrapper.v2")
);

// Set the user's locale in moment
const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

initSentry();

setGlobalDispatch(store.dispatch);

const routerBasename = "";

let browserHistory;

class AppRouter extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userData: PropTypes.object,
    logout: PropTypes.func,
    orgLoading: PropTypes.bool.isRequired,
    permissions: PropTypes.object,
    alertsCount: PropTypes.number,
    activityStreamTaskCount: PropTypes.number,
    justLoggedIn: PropTypes.bool,
  };

  static defaultProps = {
    userData: null,
    permissions: null,
    logout: null,
    alertsCount: 0,
    activityStreamTaskCount: 0,
  };

  constructor(props) {
    super(props);

    if (!browserHistory) {
      browserHistory = createBrowserHistory({ basename: routerBasename });
    }
    this.state = { browserHistory };

    // Ensure the user goes to the right place initially
    const initialRedirect = this.getInitialRedirect();
    if (
      initialRedirect &&
      initialRedirect !== browserHistory.location.pathname
    ) {
      browserHistory.replace(initialRedirect);
    }

    // When deep linking, the user's current org may not line up with the
    // destination. If that's the case we switch the user's org.
    if (props.userData.currentOrgID) {
      const { orgId } = ConsumeQueryParams(browserHistory, ["orgId"]);
      if (orgId && parseInt(orgId) !== props.userData.currentOrgID) {
        props.dispatch(switchUserOrg(orgId));
      }
    }

    let orgName = null;
    let accountType = "";
    let orgCreatedAt = null;
    let internalOnly = null;
    let orgIsFreeTrial = false;
    if (props.userData.currentOrgID && props.userData.orgList) {
      const currentOrg = _find(
        props.userData.orgList,
        (o) => o.id === props.userData.currentOrgID
      );
      if (currentOrg) {
        orgName = currentOrg.name;
        accountType = currentOrg.accountType;
        orgCreatedAt = currentOrg.createdAt;
        internalOnly = currentOrg.internalOnly;
        orgIsFreeTrial = currentOrg.isFreeTrial;
      }
    }

    let { firstName, lastName, name } = props.userData;
    if (firstName === undefined && lastName === undefined && name) {
      [firstName, lastName] = props.userData.name.split(" ");
    }

    const jobTitle = props.userData.jobTitle ?? "";

    if (props.justLoggedIn) {
      // Only track our Common_UserLoggedIn event if the user had to reauthenticate
      // (not just on page reloads)
      trackEvent("Common_UserLoggedIn");
    }

    // Identify the user for Sentry
    sentrySetUser({ email: props.userData.emailAddress });

    if (window.zE !== undefined) {
      // Force close the zendesk widget if it's still cached
      window.zE("webWidget", "hide");
    }

    browserHistory.listen((location, action) => {
      if (this.prevPathname === location.pathname) {
        // Only do stuff if the path has actually changed.
        return;
      }

      this.prevPathname = location.pathname;

      // Scroll to the top whenever the route changes
      if (!location.state || !location.state.keepScrollPosition) {
        window.scroll(0, 0);
      }

      // Close any open alerts
      // - Skip if we used the goBack (action === "POP")
      if (
        (!location.state || !location.state.noRemoveWhispers) &&
        action !== "POP"
      ) {
        props.dispatch(clearAllMessageAlerts());
      }
    });

    // Refresh our user data
    props.dispatch(fetchUserData());

    // Register a postMessage event handler that will fetch config again when
    // we receive a "cyberRiskKeepAlive" message. This is for the purpose of
    // keeping the session alive while a separate assessment window is in use.
    const acceptableOrigins = [
      "local.external-risk.upguard.org:1450",
      "external-risk.upguard.org",
      "cyber-risk.upguard.com",
    ];

    // Throttled post message handler func invoked at most once per minute
    const postMessageHandler = _throttle((event) => {
      if (event.data !== "cyberRiskKeepAlive") {
        return;
      }

      let fromTrustedOrigin = false;
      for (let i = 0; i < acceptableOrigins.length; i++) {
        if (event.origin.endsWith(acceptableOrigins[i])) {
          fromTrustedOrigin = true;
          break;
        }
      }

      if (!fromTrustedOrigin) {
        return;
      }

      // Dispatch a fetchUserData action to refresh our session
      props.dispatch(fetchUserData());
    }, 60000);

    window.addEventListener("message", postMessageHandler, false);

    // No more than once every 10 seconds, update the userLastActive state so we can
    // tell when the tab was last active
    const throttledActivityWatcher = _throttle(updateUserLastActive, 10000);
    window.addEventListener("mousemove", throttledActivityWatcher);
    window.addEventListener("scroll", throttledActivityWatcher);
    window.addEventListener("keydown", throttledActivityWatcher);
  }

  getInitialRedirect() {
    // see if we have a redirect location stored in localStorage
    const redirectPath = getLocalStorageItemString("redirectPath");
    clearLocalStorageItem("redirectPath");

    if (redirectPath) {
      if (routerBasename && redirectPath.startsWith(routerBasename)) {
        // the redirect path should only have from the basename onwards, so splice it from the string
        return redirectPath.substring(routerBasename.length);
      }
      return redirectPath;
    }

    return null;
  }

  render() {
    if (this.props.orgLoading) {
      return <LoadingBanner />;
    }

    let orgAccessMultiProductNavigation = false;
    if (
      hasOrgPermission(this.props.permissions, OrgAccessMultiProductNavigation)
    ) {
      orgAccessMultiProductNavigation = true;
    }

    if (orgAccessMultiProductNavigation) {
      return <NavigationRouter history={this.state.browserHistory} />;
    }
    return (
      <Navigation
        dispatch={this.props.dispatch}
        history={this.state.browserHistory}
        userData={this.props.userData}
        onLogoutClick={this.props.logout}
        alertsCount={this.props.alertsCount}
        activityStreamTaskCount={this.props.activityStreamTaskCount}
      />
    );
  }
}

const AppAuthWrapper = ({
  dispatch,
  cyberRiskAuth,
  auth0Session,
  userData,
  orgLoading,
  permissions,
  alertsCount,
  activityStreamTaskCount,
}) => (
  <div id="page_react" className="react-view">
    <div id="page_external">
      <Suspense fallback={<LoadingBanner />}>
        <Auth0WrapperV2Container
          dispatch={dispatch}
          cyberRiskAuth={cyberRiskAuth}
        >
          <AppRouter
            dispatch={dispatch}
            userData={userData}
            orgLoading={orgLoading}
            permissions={permissions}
            alertsCount={alertsCount}
            activityStreamTaskCount={activityStreamTaskCount}
          />
        </Auth0WrapperV2Container>
      </Suspense>
    </div>
  </div>
);

AppAuthWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cyberRiskAuth: PropTypes.object.isRequired,
  auth0Session: PropTypes.object,
  userData: PropTypes.object,
  permissions: PropTypes.object,
  orgLoading: PropTypes.bool.isRequired,
  alertsCount: PropTypes.number,
};

AppAuthWrapper.defaultProps = {
  auth0Session: null,
  userData: null,
  permissions: null,
  alertsCount: 0,
};

const ConnectedAppAuthWrapper = appConnect((state) => {
  let numTasks = _get(state.common, "activityStream.numTasks", undefined);

  // if it's a plg person we'll be using our task count instead
  if (_get(state.common, "userData.plgOnboarding", undefined)) {
    if (!!state.common.userData.plgOnboarding?.completedTasks) {
      numTasks = getPLGTasksIncompleteCount(
        state.common.userData.plgOnboarding.applicableChecklists,
        state.common.userData.plgOnboarding.completedTasks
      );
    }
  }

  return {
    cyberRiskAuth: state.common.cyberRiskAuth,
    auth0Session: state.common.auth0Session,
    userData: state.common.userData,
    orgLoading: state.common.orgLoading,
    permissions: state.common.permissions,
    alertsCount: _get(state.cyberRisk, "customerData.alerts.count", 0),
    activityStreamTaskCount: numTasks,
  };
})(AppAuthWrapper);

const App = () => (
  <Provider store={store}>
    <div>
      <MessageAlerts />
      <ConnectedAppAuthWrapper />
    </div>
  </Provider>
);

document.addEventListener("DOMContentLoaded", () => {
  const root = document.createElement("div");

  createRoot(document.getElementById("react-root").appendChild(root)).render(
    <App />
  );
});

import {
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
} from "../helpers/navigation.helpers";

// breachSightProductDisplayName - use this when we need to display the product name in the UI
export const breachSightProductDisplayName = "Breach Risk";

export type product =
  | "vendor_risk"
  | "breachsight"
  | "user_risk"
  | "trust_exchange"
  | "analyst_portal"
  | undefined;

export type breachSightNavigationItems =
  | "executive_summary"
  | "risk_profile"
  | "remediation"
  | "risk_waivers"
  | "domains"
  | "ip_addresses"
  | "vulnerabilities"
  | "detected_products"
  | "subsidiaries"
  | "subsidiaries_menu"
  | "identity_breaches"
  | "typosquatting"
  | "cloud"
  | "threatmonitoring"
  | "remediation_tasks"
  | "risk_waiver_approvals"
  | "subsidiary_risk_waiver_approvals";

export type trustExchangeNavigationItems =
  | "content_library"
  | "trust_page"
  | "questionnaires"
  | "additional_evidence_requests"
  | "remediation_requests";

export type NavigationItem = {
  navigationPath: string;
};

export const TrustExchangeNavigationItems: {
  [key in trustExchangeNavigationItems]: NavigationItem;
} = {
  content_library: {
    navigationPath: "/contentlibrary",
  },
  trust_page: {
    navigationPath: "/trustpage",
  },
  questionnaires: {
    navigationPath: `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires`,
  },
  additional_evidence_requests: {
    navigationPath: `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/additionalevidence`,
  },
  remediation_requests: {
    navigationPath: `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/remediation`,
  },
};

import { batch, DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { Node } from "../types/types";
import { SurveyFramework } from "../types/frameworks";
import { Survey } from "./reducer";
import {
  clearHistory,
  setFullSurvey,
  setSurveyDraftInProgress,
  setSurveyLockedBy,
  setSurveySaveError,
  setSurveySaving,
  setSurveyValidationErrors,
} from "./actions";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { ValidationErrors } from "../types/validation";
import { IUserMini } from "../../_common/types/user";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import { trackEvent } from "../../_common/tracking";
import { addMessageAlert } from "../../_common/reducers/messageAlerts.actions";
import { BannerType } from "../../vendorrisk/components/InfoBanner";
import { SurveyUsageType } from "../../_common/types/surveyTypes";

interface createNewSurveyTypeDraftResp {
  surveyTypeId: number;
}

// Creates a new draft survey type with the given name, optionally
// from a specified type ID. Returns the ID of the new survey type.
export const createNewSurveyType = (
  name: string,
  framework: SurveyFramework,
  usageType: SurveyUsageType = SurveyUsageType.Security,
  draft = true,
  fromTypeId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<number> => {
    let result: createNewSurveyTypeDraftResp;

    try {
      result = await FetchCyberRiskUrl<createNewSurveyTypeDraftResp>(
        "surveybuilder/create/v1/",
        {
          name,
          framework,
          from_type_id: fromTypeId,
          usage_type: usageType,
          draft,
        },
        { method: "POST" },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    trackEvent("Custom Questionnaire Created", { fromTypeId: fromTypeId });

    return result.surveyTypeId;
  };
};

// Slimmed down version of the Survey interface used in the backend and stored in the db
interface backendSurvey {
  rootNodeId: string;
  nodes: { [nodeId: string]: Node | undefined };
  framework: SurveyFramework;
  includeTOC: boolean;
  customNumbering: boolean;
}

interface fetchSurveyTypeDraftResp {
  name: string;
  survey: backendSurvey;
  validationErrors: ValidationErrors;
  isPublished: boolean;
  draftInProgress: boolean;
  enabled: boolean;
  lockedBy?: IUserMini;
  usageType: SurveyUsageType;
  sourceTypeId?: number;
}

export const fetchSurveyTypeDraft = (typeId: number, force: boolean) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<Survey> => {
    let result: fetchSurveyTypeDraftResp;

    if (!force && getState().surveyBuilder.surveys[typeId.toString()]) {
      return getState().surveyBuilder.surveys[typeId.toString()];
    }

    try {
      result = (await FetchCyberRiskUrl(
        "surveybuilder/draft/v1/",
        { type_id: typeId },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      )) as fetchSurveyTypeDraftResp;
    } catch (e) {
      console.error(e);
      throw e;
    }

    const survey: Survey = {
      surveyId: typeId.toString(),
      name: result.name,
      rootNodeId: result.survey.rootNodeId,
      nodes: result.survey.nodes,
      framework: result.survey.framework,
      includeTOC: result.survey.includeTOC,
      customNumbering: result.survey.customNumbering,
      saving: false,
      validationErrors: result.validationErrors,
      isPublished: result.isPublished,
      draftInProgress: result.draftInProgress,
      enabled: result.enabled,
      trackHistory: true,
      actionHistory: [],
      actionHistoryUpdatedAt: 0,
      lockedBy: result.lockedBy,
      usageType: result.usageType,
      sourceTypeId: result.sourceTypeId,
    };

    dispatch(setFullSurvey(typeId.toString(), survey));

    return survey;
  };
};

interface autosaveQueueItem {
  runFunc: () => Promise<void>; // Main function to run when dequeued
  runFuncCallback?: () => void; // Function to run after runFunc completes
  onQueueEmpty: () => void; // Function to run if queue is empty after runFunc completes
}

// Queue class for handling autosaves. Autosaves for a given survey type ID are assumed to run
// in sequence. This class will ensure saves get queued if an autosave is triggered before the previous one has completed.
class AutosaveQueue {
  queuesBySurveyId: {
    [surveyId: string]: autosaveQueueItem[];
  } = {};

  async processNextItem(surveyId: string) {
    if (
      !this.queuesBySurveyId[surveyId] ||
      this.queuesBySurveyId[surveyId].length === 0
    ) {
      return;
    }

    const thisItem = this.queuesBySurveyId[surveyId][0];

    // Run the function first
    try {
      await thisItem.runFunc();
    } catch (e) {
      console.error("error processing func in autosaveQueue", e);
    }

    // If there is a callback on this item, run it now
    if (thisItem.runFuncCallback) {
      thisItem.runFuncCallback();
    }

    // Remove the item from the queue now we're done processing it.
    this.queuesBySurveyId[surveyId].splice(0, 1);

    if (this.queuesBySurveyId[surveyId].length === 0) {
      // The queue's now empty, so call onQueueEmpty on this item
      thisItem.onQueueEmpty();
    } else {
      // Run the next item in the queue
      this.processNextItem(surveyId);
    }
  }

  queue(surveyId: string, item: autosaveQueueItem) {
    if (!this.queuesBySurveyId[surveyId]) {
      this.queuesBySurveyId[surveyId] = [];
    }

    this.queuesBySurveyId[surveyId].push(item);

    // If this is the only item in the queue, start processing
    if (this.queuesBySurveyId[surveyId].length === 1) {
      this.processNextItem(surveyId);
    }
  }
}

// One autosave queue instance for the entire app - should be fine. We can break this up later if necessary
const globalAutosaveQueue = new AutosaveQueue();

interface runAutosaveResp {
  status: "OK" | "APPLY_FAILED" | "LOCKED";
  lockedBy?: IUserMini;
}

// runAutosave runs a partial update on the survey from the actions that have been queued up since the previous save.
export const runAutosave = (surveyTypeId: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    // Get the current list of actions and reset the history before we proceed.
    const survey = getState().surveyBuilder.surveys[surveyTypeId];
    const actionHistory = survey?.actionHistory;
    if (!survey || !actionHistory || actionHistory.length === 0) {
      // Nothing to save, we're done
      return;
    }

    dispatch(clearHistory(surveyTypeId));
    dispatch(setSurveySaving(surveyTypeId, true));

    // Start a new promise we can resolve when the callback runs
    return new Promise((resolve) => {
      // Queue our update via the autosave queue
      globalAutosaveQueue.queue(surveyTypeId, {
        runFunc: async () => {
          try {
            const resp = (await FetchCyberRiskUrl(
              "surveybuilder/draft/v1/",
              {},
              {
                method: "PUT",
                body: JSON.stringify({
                  surveyTypeId: parseInt(surveyTypeId),
                  actions: actionHistory,
                }),
              },
              dispatch,
              getState,
              undefined,
              undefined
            )) as runAutosaveResp;

            if (resp.status === "APPLY_FAILED") {
              // Partial update has failed, potentially because the backend and frontend are out of sync.
              // Queue a full update to try and reconcile.
              dispatch(runFullDraftSave(surveyTypeId));
            } else if (resp.status === "LOCKED") {
              // The survey has become locked for editing. Set it to locked here.
              dispatch(setSurveyLockedBy(surveyTypeId, resp.lockedBy));

              dispatch(
                addMessageAlert({
                  message: `This questionnaire template is currently locked for editing by ${resp.lockedBy?.name}`,
                  type: BannerType.WARNING,
                })
              );
            }
          } catch (e) {
            dispatch(
              addMessageAlert({
                message: "Questionnaire auto-save failed",
                subMessages: [
                  "The latest auto-save action failed with an unknown error. Please reload the questionnaire template to continue editing.",
                ],
              })
            );

            dispatch(setSurveySaveError(surveyTypeId, true));

            throw e;
          }
        },
        runFuncCallback: resolve,
        onQueueEmpty: () => {
          batch(() => {
            dispatch(setSurveySaving(surveyTypeId, false));
            dispatch(setSurveyDraftInProgress(surveyTypeId, true));
          });
        },
      });
    });
  };
};

interface runFullDraftSaveResp {
  status: "OK" | "LOCKED";
  lockedBy?: IUserMini;
  validationErrors: ValidationErrors;
}

// runFullDraftSave overwrites the full survey draft from the current redux state.
export const runFullDraftSave = (surveyTypeId: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<runFullDraftSaveResp> => {
    const surveyInState = getState().surveyBuilder.surveys[surveyTypeId];

    // Prepare the full survey data
    const survey: backendSurvey = {
      rootNodeId: surveyInState.rootNodeId,
      nodes: surveyInState.nodes,
      framework: surveyInState.framework,
      includeTOC: surveyInState.includeTOC,
      customNumbering: surveyInState.customNumbering,
    };

    // Reset the history before we proceed.
    dispatch(clearHistory(surveyTypeId));
    dispatch(setSurveySaving(surveyTypeId, true));

    // Start a new promise we can resolve when the callback runs
    return new Promise((resolve) => {
      // Queue our save via the autosave queue
      let resp: runFullDraftSaveResp;

      globalAutosaveQueue.queue(surveyTypeId, {
        runFunc: async () => {
          resp = (await FetchCyberRiskUrl(
            "surveybuilder/draft/v1/",
            {
              type_id: surveyTypeId,
            },
            {
              method: "POST",
              body: JSON.stringify(survey),
            },
            dispatch,
            getState,
            undefined,
            undefined
          )) as runFullDraftSaveResp;

          dispatch(
            setSurveyValidationErrors(surveyTypeId, resp.validationErrors)
          );

          if (resp.status === "LOCKED") {
            // The survey has become locked for editing. Set it to locked here.
            dispatch(setSurveyLockedBy(surveyTypeId, resp.lockedBy));

            dispatch(
              addMessageAlert({
                message: `This questionnaire template is currently locked for editing by ${resp.lockedBy?.name}`,
                type: BannerType.WARNING,
              })
            );
          }
        },
        runFuncCallback: () => resolve(resp),
        onQueueEmpty: () => {
          batch(() => {
            dispatch(setSurveySaving(surveyTypeId, false));
            dispatch(setSurveyDraftInProgress(surveyTypeId, true));
          });
        },
      });
    });
  };
};

// publishCurrentSurveyDraft publishes the current draft. This should only be run
// after doing a full draft save and verifying there are no validation errors.
export const publishCurrentSurveyDraft = (
  surveyTypeId: string,
  surveyUsageType: SurveyUsageType,
  turnOnEnabled: boolean,
  includeAutomation?: boolean,
  enableAutomationUUIDs?: string[]
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "surveybuilder/publish/v1",
        {
          type_id: surveyTypeId,
          turn_on_enabled: turnOnEnabled,
          include_automation: includeAutomation ? includeAutomation : false,
          enable_automation: enableAutomationUUIDs
            ? enableAutomationUUIDs.join(",")
            : [],
        },
        {
          method: "POST",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );

      // update activity stream to include new event
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      console.error(e);
      throw e;
    }

    trackEvent("Custom Questionnaire Published", {
      typeId: surveyTypeId,
      usageType: surveyUsageType,
    });
  };
};

export const revertCurrentSurveyDraft = (
  surveyTypeId: string,
  revertToSource = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "surveybuilder/revert/v1/",
        {
          type_id: surveyTypeId,
          revert_to_source: revertToSource,
        },
        {
          method: "POST",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );

      // update activity stream to include new event
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e: any) {
      if (e.toString().indexOf("[QUESTION-DEPENDENCY]") >= 0) {
        throw "We're unable to reset this questionnaire as linked automation rules exist. Delete your automation rules and try again.";
      } else {
        console.error(e);
        throw e;
      }
    }
  };
};

export const deleteSurveyType = (surveyTypeId: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "surveybuilder/delete/v1/",
        {
          type_id: surveyTypeId,
        },
        {
          method: "DELETE",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );

      // update activity stream to include new event
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

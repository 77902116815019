import { useAppDispatch } from "../../_common/types/reduxHooks";
import ContentLibraryAPI from "../../contentlibrary/api/contentLibraryAPI";
import { openModal } from "../../_common/reducers/commonActions";
import {
  DocumentModalName,
  IDocumentModalData,
} from "../../vendorrisk/components/modals/DocumentModal";
import TrustPageAPI, { TrustPageTagTypes } from "../api/trustpage.api";
import { addDefaultSuccessAlert } from "../../_common/reducers/messageAlerts.actions";
import { trackEvent } from "../../_common/tracking";
import { useCallback } from "react";

export const useAddDocumentToSharedProfile = () => {
  const dispatch = useAppDispatch();
  const [createContentLibraryDocument] =
    ContentLibraryAPI.useCreateContentLibraryDocumentMutation();
  const [updateIncludeInSharedProfile] =
    ContentLibraryAPI.useUpdateContentLibraryDocumentSharedProfileMutation();

  return useCallback(
    (file?: File) =>
      dispatch(
        openModal(
          DocumentModalName,
          {
            file: file,
            canEdit: true,
            overrideTitle: "Add document",
            onDocumentUpload: async (
              name: string,
              description: string,
              file: File
            ) => {
              try {
                // Create a new document in the content library...
                const { document } = await createContentLibraryDocument({
                  name,
                  description,
                  type: "Unknown", // TODO: Allow setting content library type via this modal
                  file,
                }).unwrap();

                // Next set it to be included in the shared profile
                await updateIncludeInSharedProfile({
                  uuid: document.uuid,
                  includeInSharedProfile: true,
                })
                  .unwrap()
                  .then(() => {
                    dispatch(
                      TrustPageAPI.util.invalidateTags([
                        TrustPageTagTypes.ownTrustPage,
                        TrustPageTagTypes.ownTrustPageAccess,
                      ])
                    );
                  });

                dispatch(addDefaultSuccessAlert("Document published"));

                trackEvent("VerifiedVendor_AddDocument");
              } catch (e) {
                console.error(e);
                throw new Error(
                  "Error uploading document to Trust Page. Please contact UpGuard Support."
                );
              }
            },
            onDocumentDownload: () => {
              // nothing to do
            },
          } as IDocumentModalData,
          true
        )
      ),
    [dispatch, createContentLibraryDocument, updateIncludeInSharedProfile]
  );
};

import React, { FC } from "react";
import ModalV2, { BaseModalProps } from "../ModalV2";
import "../../style/components/TipsModal.scss";
import Accordion from "../Accordion";
import Button from "../core/Button";

export interface tip {
  id: string;
  icon: React.ReactNode;
  title: string;
  content: React.ReactNode;
  image?: string;
  startExpanded?: boolean;
}

interface TipsModalProps extends BaseModalProps {
  headerText: string;
  introText: string;
  tips: tip[];
  footerContent?: React.ReactNode;
  showCancel?: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  supportLink?: string;
  supportLinkText?: string;
}

export const TipsModal: FC<TipsModalProps> = ({
  headerText,
  introText,
  tips,
  footerContent,
  active,
  onClose,
  showCancel,
  onConfirm,
  confirmText,
  supportLink,
  supportLinkText,
}) => (
  <ModalV2
    active={active}
    onClose={onClose}
    headerContent={headerText}
    className={"tips-modal"}
    footerContent={
      <>
        <div className={"footer-left"}>
          {supportLink && supportLinkText && (
            <Button
              tertiary
              arrow
              onClick={() => window.open(supportLink, "_blank")}
            >
              {supportLinkText}
            </Button>
          )}
        </div>
        <div className={"footer-right"}>
          {showCancel && (
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
          )}
          {onConfirm && confirmText && (
            <Button filledPrimary onClick={onConfirm}>
              {confirmText}
            </Button>
          )}
        </div>
      </>
    }
  >
    <div className={"intro-text"}>{introText}</div>
    <Accordion
      items={tips.map((t) => ({
        className: "tip-section",
        id: t.id,
        startExpanded: t.startExpanded,
        content: (
          <div className={"tip-content"}>
            {t.content}
            {t.image && <img className={"tips-image"} src={t.image} />}
          </div>
        ),
        header: (
          <>
            <span className={"icon"}>{t.icon}</span>
            <span className={"title"}>{t.title}</span>
          </>
        ),
      }))}
    />
    {footerContent && <div className={"tips-footer"}>{footerContent}</div>}
  </ModalV2>
);

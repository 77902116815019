import {
  IMySharedAssessmentAccess,
  IOrgNameAndDomain,
  IVerifiedVendorAccessItem,
  IVerifiedVendorAccessItemNda,
  IVerifiedVendorAccessRequest,
} from "../../types/sharedAssessment";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../../_common/types/redux";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ReportCard from "../../../_common/components/ReportCard";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { IUserMini, IUserMiniMap } from "../../../_common/types/user";
import { useSorting } from "../../../_common/hooks";
import { UserAvatarAndName } from "../../../_common/components/UserAvatar";
import moment from "moment/moment";
import Button, { TooltipButton } from "../../../_common/components/core/Button";
import ModalV2 from "../../../_common/components/ModalV2";
import { FC, useEffect, useState } from "react";
import TextField from "../../../_common/components/TextField";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

import "../../style/components/SharedProfileAccess.scss";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import IconButton, {
  HoverColor,
  HoverLocation,
} from "../../../_common/components/IconButton";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import ShareSharedProfileModal from "./ShareSharedProfileModal";
import NdasButton from "./NdasButton";
import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import {
  denyVerifiedVendorUserAccessRequest,
  manuallyApproveVerifiedVendorAccessNda,
  revokeVerifiedVendorAccessNda,
  revokeVerifiedVendorUserAccess,
} from "../../reducers/verifiedVendors.actions";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import TrustPageAPI from "../../../verifiedvendors/api/trustpage.api";

const OrgAndHostname = ({
  orgMini,
}: {
  orgMini: Partial<IOrgNameAndDomain>;
}) => (
  <div className="org-and-hostname">
    <CompanyLogo domain={orgMini.primaryHostname} displayDefault defaultGrey />
    <div className="name">
      {orgMini.name}
      <br />
      <div className="hostname">{orgMini.primaryHostname}</div>
    </div>
  </div>
);

interface modalRequestData {
  id: number;
  userName?: string;
  orgName?: string;
}

interface IDenyAccessRequestModalProps {
  dispatch: DefaultThunkDispatch;
  closeModal: () => void;
  requestData?: modalRequestData;
}

const DenyAccessRequestModal = ({
  dispatch,
  requestData,
  closeModal,
}: IDenyAccessRequestModalProps) => {
  const [reason, setReason] = useState("");
  useEffect(() => setReason(""), [requestData]);
  const [loading, setLoading] = useState(false);

  let from = "";
  if (requestData?.userName) {
    from += requestData.userName;
  }
  if (requestData?.orgName) {
    if (from === "") {
      from += requestData.orgName;
    } else {
      from += ` (from ${requestData.orgName})`;
    }
  }

  const onDeny = () => {
    setLoading(true);
    dispatch(denyVerifiedVendorUserAccessRequest(requestData?.id ?? 0, reason))
      .then(() => {
        dispatch(addDefaultSuccessAlert("Denied request"));
        closeModal();
      })
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error denying request"));
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ModalV2
      className="shared-profile-deny-access-modal"
      onClose={closeModal}
      active={!!requestData}
      headerContent="Deny access request"
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={closeModal}>
            Cancel
          </Button>
          <Button danger loading={loading} disabled={!reason} onClick={onDeny}>
            Deny request
          </Button>
        </div>
      }
    >
      <p>
        The request from {from} to access your Trust Page will be denied. Please
        provide a reason that will be sent to the requesting user.
      </p>
      <TextField
        placeholder="Provide a reason for denying this request"
        value={reason}
        onChanged={(val) => setReason(val)}
        multiLine
      />
    </ModalV2>
  );
};

interface IAccessRequestsTableProps {
  dispatch: DefaultThunkDispatch;
  usersById: IUserMiniMap;
  orgsById: Record<number, IOrgNameAndDomain>;
  accessRequests: IVerifiedVendorAccessRequest[];
  isNdaRequired: boolean;
}

const AccessRequestsTable = ({
  dispatch,
  usersById,
  orgsById,
  accessRequests,
  isNdaRequired,
}: IAccessRequestsTableProps) => {
  const [denyModalData, setDenyModalData] = useState<
    modalRequestData | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [inviteUserToTrustPage] =
    TrustPageAPI.useInviteUserToTrustPageV1Mutation();
  const [inviteOrgToTrustPage] =
    TrustPageAPI.useInviteOrgToTrustPageV1Mutation();

  const accessRequestsTableColumnHeaders: IXTableColumnHeader[] = [
    {
      id: "org_name",
      text: "Organization name",
      sortable: false,
    },
    {
      id: "representative",
      text: "Representative",
      sortable: false,
    },
    {
      id: "ndas",
      text: "NDA",
      helpText: (
        <div>
          <div className={"title"}>NDA (Non-disclosure agreement)</div>
          <p>
            This is where any NDA documents that have been agreed to can be
            viewed.{" "}
            <a
              href={
                "https://help.upguard.com/en/articles/6556203-how-to-add-nda-protection-to-your-trust-page"
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn more
              <i className="btn-arrow icon-arrow rotate-90" />
            </a>
          </p>
        </div>
      ),
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "requested",
      text: "Requested",
      sortable: false,
    },
    {
      id: "message",
      text: "Message",
    },
    { id: "action", text: "", sortable: false },
  ];

  const approveRequest = (r: IVerifiedVendorAccessRequest) => {
    let prom;
    let invitedName = "";
    if (r.orgId) {
      const org = orgsById[r.orgId];
      if (!org) {
        return;
      }

      invitedName = org.name;
      prom = inviteOrgToTrustPage({ orgID: r.orgId });
    } else if (r.userId) {
      const user = usersById[r.userId];
      if (!user) {
        return;
      }

      invitedName = user.name;
      prom = inviteUserToTrustPage({
        email: user.email,
      });
    } else {
      return;
    }

    setLoading(true);

    prom
      .unwrap()
      .then(() => {
        dispatch(addDefaultSuccessAlert(`Access approved for ${invitedName}.`));
      })
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error approving access"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rows: IXTableRow[] = accessRequests.map((r) => {
    let user: IUserMini | undefined;
    if (r.userId) {
      user = usersById[r.userId];
    }

    let orgMini: IOrgNameAndDomain | undefined;
    if (r.orgId) {
      orgMini = orgsById[r.orgId];
    }

    return {
      id: r.id,
      cells: [
        <XTableCell key="org_name">
          {orgMini && <OrgAndHostname orgMini={orgMini} />}
        </XTableCell>,
        <XTableCell key="representative">
          {user && (
            <UserAvatarAndName
              avatar={user.avatar}
              name={user.name}
              email={user.email}
            />
          )}
        </XTableCell>,
        <XTableCell key="ndas" className="shrink-cell">
          <NdaCellContents
            isNdaRequired={isNdaRequired}
            isNdaExempt={false}
            isManuallyApproved={false}
            signedNdas={r.signedNdasForUserOrOrg}
          />
        </XTableCell>,
        <XTableCell key="granted">
          {moment(r.updatedAt).format("ll")}
        </XTableCell>,
        <XTableCell key="message">
          {r.message && (
            <IconButton
              icon={<i className={"cr-icon-chat-messages"} />}
              className={"message-btn"}
              hoverLocation={HoverLocation.Top}
              hoverText={
                <div>
                  <div className={"message-title"}>Message from user:</div>
                  <br />
                  <div>{r.message}</div>
                </div>
              }
            />
          )}
        </XTableCell>,
        <XTableCell key="action" className="action-cell shrink-cell">
          <Button
            tertiary
            onClick={() =>
              setDenyModalData({
                id: r.id,
                userName: user?.name,
                orgName: orgMini?.name,
              })
            }
          >
            Deny request
          </Button>
          <Button onClick={() => approveRequest(r)}>
            <div className="cr-icon-check" /> Approve
          </Button>
        </XTableCell>,
      ],
    };
  });

  return (
    <>
      <XTable
        className="shared-profile-access-requests-table"
        rows={rows}
        loading={loading}
        columnHeaders={accessRequestsTableColumnHeaders}
      />
      <DenyAccessRequestModal
        dispatch={dispatch}
        closeModal={() => setDenyModalData(undefined)}
        requestData={denyModalData}
      />
    </>
  );
};

const pendingInvitesColumnHeaders: IXTableColumnHeader[] = [
  { id: "email", text: "Email address", sortable: false },
];

interface IPendingInvitesTableProps {
  dispatch: DefaultThunkDispatch;
  invitedEmails: string[];
}

const PendingInvitesTable = ({
  dispatch,
  invitedEmails,
}: IPendingInvitesTableProps) => {
  const [loading, setLoading] = useState(false);
  const [cancelInvite] = TrustPageAPI.useCancelInviteToTrustPageV1Mutation();

  return (
    <XTable
      iconOptions
      loading={loading}
      rows={invitedEmails.map((email) => ({
        id: email,
        iconOptions: [
          {
            id: "delete",
            hoverText: "Cancel invite",
            hoverColor: HoverColor.Red,
            icon: <div className="cr-icon-trash" />,
            onClick: () => {
              setLoading(true);
              cancelInvite({ email: email })
                .unwrap()
                .then(() =>
                  dispatch(
                    addDefaultSuccessAlert("Cancelled invite to " + email)
                  )
                )
                .catch((e) => {
                  console.error(e);
                  dispatch(
                    addDefaultUnknownErrorAlert("Error cancelling invite")
                  );
                })
                .then(() => setLoading(false));
            },
          },
        ],
        cells: [<XTableCell key="email">{email}</XTableCell>],
      }))}
      columnHeaders={pendingInvitesColumnHeaders}
    />
  );
};

const NdaCellContents: FC<{
  isNdaRequired: boolean;
  isNdaExempt: boolean;
  isManuallyApproved: boolean;
  signedNdas?: IVerifiedVendorAccessItemNda[];
}> = ({ isNdaRequired, isNdaExempt, isManuallyApproved, signedNdas }) => {
  let status = undefined;
  if (isManuallyApproved) {
    status = (
      <SidePopupV2
        position={"top"}
        text="NDA has been manually approved."
        noWrap
      >
        Manually Approved
      </SidePopupV2>
    );
  } else if (!isNdaRequired) {
    status = (
      <SidePopupV2
        position={"top"}
        text="No NDA is currently required for your Trust Page."
      >
        -
      </SidePopupV2>
    );
  } else if (isNdaExempt) {
    status = (
      <SidePopupV2
        position={"top"}
        text="Grandfathered access prior to any NDA requirement."
      >
        N/A
      </SidePopupV2>
    );
  } else if (!signedNdas?.some((s) => !s.revokedAt)) {
    status = (
      <SidePopupV2
        position={"top"}
        text="Waiting for recipient to agree to NDA."
        noWrap
      >
        Pending
      </SidePopupV2>
    );
  }
  return (
    <div className={"nda-cell-contents"}>
      {status !== undefined && status}
      <NdasButton signedNdas={signedNdas} />
    </div>
  );
};

interface IOrganisationsWithAccessTableProps {
  dispatch: DefaultThunkDispatch;
  usersById: IUserMiniMap;
  orgsById: Record<number, IOrgNameAndDomain>;
  usersAndOrgsWithAccess: IVerifiedVendorAccessItem[];
  published: boolean;
  onClickShare: () => void;
  vendorId: number;
  isNdaRequired: boolean;
  isPublicAccess: boolean;
}

const OrganisationsWithAccessTable = ({
  dispatch,
  usersById,
  orgsById,
  usersAndOrgsWithAccess,
  published,
  onClickShare,
  vendorId,
  isNdaRequired,
  isPublicAccess,
}: IOrganisationsWithAccessTableProps) => {
  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  const organisationsWithAccessColumnHeaders: IXTableColumnHeader[] = [
    {
      id: "org_name",
      text: "Organization name",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "representative",
      text: "Representative",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "ndas",
      text: "NDA",
      helpText: (
        <div>
          <div className={"title"}>NDA (Non-disclosure agreement)</div>
          <p>
            This is where any NDA documents that have been agreed to can be
            viewed.{" "}
            <a
              href={
                "https://help.upguard.com/en/articles/6556203-how-to-add-nda-protection-to-your-trust-page"
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn more
              <i className="btn-arrow icon-arrow rotate-90" />
            </a>
          </p>
        </div>
      ),
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "granted",
      text: "Granted",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "actions",
      text: "",
      sortable: false,
    },
    { id: "action", text: "", sortable: false },
  ];

  const [sortedItems, sortedBy, onSortChange] = useSorting<
    IVerifiedVendorAccessItem,
    "org_name" | "representative" | "ndas" | "granted"
  >(usersAndOrgsWithAccess, "granted", SortDirection.DESC, {
    org_name: {
      orderFuncs: [
        (i) => (i.orgId && orgsById[i.orgId] ? orgsById[i.orgId].name : ""),
        (i) => i.updatedAt,
      ],
      sortDirsDesc: ["desc", "desc"],
      sortDirsAsc: ["asc", "desc"],
    },
    representative: {
      orderFuncs: [
        (i) => (i.userId ? usersById[i.userId]?.name : ""),
        (i) => i.updatedAt,
      ],
      sortDirsDesc: ["desc", "desc"],
      sortDirsAsc: ["asc", "desc"],
    },
    ndas: {
      orderFuncs: [
        (i) => {
          if (i.ndaExempt) {
            return 2;
          } else if (i.ndaManuallyApproved) {
            return 3;
          } else if (
            i.signedNdasForUser &&
            i.signedNdasForUser.some((s) => !s.revokedAt)
          ) {
            return 4;
          }

          return 1;
        },
        (i) => i.updatedAt,
      ],
      sortDirsDesc: ["desc", "desc"],
      sortDirsAsc: ["asc", "desc"],
    },
    granted: {
      orderFuncs: [(i) => i.updatedAt],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
  });

  const revokeNdaAccess = (
    item: IVerifiedVendorAccessItem,
    orgMini?: IOrgNameAndDomain,
    user?: IUserMini
  ) => {
    let descSuffix =
      "will no longer be able to access your Trust Page until they accept the terms of your NDA.";
    if (!isNdaRequired) {
      descSuffix =
        "will still be able to access your Trust Page as an accepted NDA is not currently required.";
    }

    let verb = "Revoke";
    let verbed = "revoked";
    let verbing = "revoking";
    if (item.ndaExempt || item.ndaManuallyApproved) {
      verb = "Require";
      verbed = "required";
      verbing = "requiring";
    }

    let currentNda: IVerifiedVendorAccessItemNda | undefined = undefined;
    const currentNdas = item.signedNdasForUser?.filter((s) => !s.revokedAt);
    if (currentNdas && currentNdas.length > 0) {
      currentNda = currentNdas[0]; // Revoke the latest
    }

    openConfirmationModal({
      title: `${verb} NDA for ${orgMini?.name || user?.name || user?.email}?`,
      description: orgMini?.name
        ? `Users from ${orgMini.name} ${descSuffix}`
        : `${user?.name || user?.email} ${descSuffix}`,
      buttonText: `${verb} NDA`,
      dangerousAction: !item.ndaExempt && !item.ndaManuallyApproved,
      cancelText: "Cancel",
      buttonAction: async () => {
        try {
          await dispatch(
            revokeVerifiedVendorAccessNda(
              vendorId,
              item.accessId,
              currentNda?.id
            )
          );
          dispatch(addDefaultSuccessAlert(`Successfully ${verbed} NDA`));
        } catch (e) {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert(`Error ${verbing} NDA`));
          throw e;
        }
      },
    });
  };

  const manuallyApproveNdaAccess = (
    item: IVerifiedVendorAccessItem,
    orgMini?: IOrgNameAndDomain,
    user?: IUserMini
  ) => {
    const descSuffix =
      "will not be required to accept an NDA before accessing your Trust Page.";

    openConfirmationModal({
      title: `Manually approve NDA for ${
        orgMini?.name || user?.name || user?.email
      }?`,
      description: orgMini?.name
        ? `Users from ${orgMini.name} ${descSuffix}`
        : `${user?.name || user?.email} ${descSuffix}`,
      buttonText: `Manually approve NDA`,
      cancelText: "Cancel",
      buttonAction: async () => {
        try {
          await dispatch(
            manuallyApproveVerifiedVendorAccessNda(vendorId, item.accessId)
          );
          dispatch(addDefaultSuccessAlert(`Manually approved NDA`));
        } catch (e) {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert(`Error manually approving NDA`));
          throw e;
        }
      },
    });
  };

  const rows = sortedItems.map((i) => {
    let user: IUserMini | undefined;
    if (i.userId) {
      user = usersById[i.userId];
    }

    let orgMini: IOrgNameAndDomain | undefined;
    if (i.orgId) {
      orgMini = orgsById[i.orgId];
    }

    const ndaActions: JSX.Element[] = [];
    if (
      (isNdaRequired && (i.ndaExempt || i.ndaManuallyApproved)) ||
      i.signedNdasForUser?.some((s) => !s.revokedAt)
    ) {
      ndaActions.push(
        <DropdownItem onClick={() => revokeNdaAccess(i, orgMini, user)}>
          <i className={"cr-icon-risk"} />{" "}
          {i.ndaExempt || i.ndaManuallyApproved ? "Require NDA" : "Revoke NDA"}
        </DropdownItem>
      );

      if (i.ndaExempt) {
        ndaActions.push(
          <DropdownItem
            onClick={() => manuallyApproveNdaAccess(i, orgMini, user)}
          >
            <i className={"cr-icon-check"} />
            Manually approve NDA
          </DropdownItem>
        );
      }
    } else if (isNdaRequired) {
      ndaActions.push(
        <DropdownItem
          onClick={() => manuallyApproveNdaAccess(i, orgMini, user)}
        >
          <i className={"cr-icon-check"} />
          Manually approve NDA
        </DropdownItem>
      );
    }

    return {
      id: i.orgId
        ? `org_${i.orgId}`
        : i.userId
          ? `user_${i.userId}`
          : i.updatedAt,
      cells: [
        <XTableCell key="org_name">
          {
            <OrgAndHostname
              orgMini={
                orgMini ?? {
                  name: "No organization recorded",
                }
              }
            />
          }
        </XTableCell>,
        <XTableCell key="representative">
          {user && (
            <UserAvatarAndName
              avatar={user.avatar}
              name={user.name}
              email={user.email}
            />
          )}
        </XTableCell>,
        <XTableCell key="ndas" className="shrink-cell">
          <NdaCellContents
            isNdaRequired={isNdaRequired}
            isNdaExempt={i.ndaExempt}
            isManuallyApproved={i.ndaManuallyApproved}
            signedNdas={i.signedNdasForUser}
          />
        </XTableCell>,
        <XTableCell key="granted">
          {moment(i.updatedAt).format("ll")}
        </XTableCell>,
        <XTableCell key="actions" className="shrink-cell">
          {ndaActions.length > 0 && (
            <ButtonWithDropdownV2 icon={<i className={"cr-icon-dots-menu"} />}>
              {ndaActions}
            </ButtonWithDropdownV2>
          )}
        </XTableCell>,
        <XTableCell key="action" className="shrink-cell">
          {!isPublicAccess && (
            <TooltipButton
              danger
              disabled={isPublicAccess}
              tooltipContent={
                isPublicAccess
                  ? "Access can only be revoked when Access Protection is on"
                  : undefined
              }
              onClick={() =>
                openConfirmationModal({
                  title: `Revoke access for ${
                    orgMini?.name || user?.name || user?.email
                  }?`,
                  description: orgMini?.name
                    ? `Users from ${orgMini.name} will no longer be able to access your Trust Page.`
                    : `${
                        user?.name || user?.email
                      } will no longer be able to access your Trust Page.`,
                  buttonText: "Revoke",
                  dangerousAction: true,
                  cancelText: "Cancel",
                  buttonAction: async () => {
                    try {
                      await dispatch(
                        revokeVerifiedVendorUserAccess(i.accessId)
                      );
                      dispatch(
                        addDefaultSuccessAlert("Successfully revoked access")
                      );
                    } catch (e) {
                      console.error(e);
                      dispatch(
                        addDefaultUnknownErrorAlert("Error revoking access")
                      );
                      throw e;
                    }
                  },
                })
              }
            >
              Revoke access
            </TooltipButton>
          )}
        </XTableCell>,
      ],
    };
  });

  return (
    <>
      {rows.length > 0 ? (
        <XTable
          className={"organisations-with-access-table"}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          columnHeaders={organisationsWithAccessColumnHeaders}
          rows={rows}
          skipStopPropagation
        />
      ) : published ? (
        <EmptyCardWithAction
          emptyText="No other people have access"
          emptySubText="Invite people to view your Trust Page."
          actionButtonText="Share"
          actionButtonIconClass="cr-icon-share"
          onActionClick={onClickShare}
        />
      ) : (
        <EmptyCardWithAction
          emptyText="Your Trust Page is not published yet"
          emptySubText="Please complete and publish your Trust Page, then return here to invite other people to view it."
        />
      )}
      {confirmationModalComponent}
    </>
  );
};

interface SharedProfileAccessOwnProps {
  sharedAssessmentAccess?: IMySharedAssessmentAccess;
}

type SharedProfileAccessProps = SharedProfileAccessOwnProps &
  DefaultThunkDispatchProp;

const SharedProfileAccess: FC<SharedProfileAccessProps> = ({
  dispatch,
  sharedAssessmentAccess,
}) => {
  const [inviteModalActive, setInviteModalActive] = useState(false);

  if (!sharedAssessmentAccess) {
    return <LoadingBanner />;
  }

  return (
    <div className="shared-profile-access-tab">
      {sharedAssessmentAccess.access.published &&
        sharedAssessmentAccess.accessRequests.length > 0 && (
          <ReportCard newStyles>
            <div className="header">Access requests</div>
            <AccessRequestsTable
              dispatch={dispatch}
              usersById={sharedAssessmentAccess.usersById}
              orgsById={sharedAssessmentAccess.orgsById}
              accessRequests={sharedAssessmentAccess.accessRequests}
              isNdaRequired={sharedAssessmentAccess.access.requireNda}
            />
          </ReportCard>
        )}
      {sharedAssessmentAccess.access.published &&
        sharedAssessmentAccess.invitedEmails.length > 0 && (
          <ReportCard newStyles>
            <div className="header">Pending invites</div>
            <PendingInvitesTable
              dispatch={dispatch}
              invitedEmails={sharedAssessmentAccess.invitedEmails}
            />
          </ReportCard>
        )}
      <ReportCard newStyles>
        <div className="header">
          Access permissions
          {sharedAssessmentAccess.access.published && (
            <div className="header-right">
              <Button onClick={() => setInviteModalActive(true)}>
                <div className="cr-icon-share" /> Share
              </Button>
            </div>
          )}
        </div>
        <OrganisationsWithAccessTable
          dispatch={dispatch}
          usersById={sharedAssessmentAccess.usersById}
          orgsById={sharedAssessmentAccess.orgsById}
          usersAndOrgsWithAccess={sharedAssessmentAccess.usersAndOrgsWithAccess}
          published={sharedAssessmentAccess.access.published}
          onClickShare={() => setInviteModalActive(true)}
          vendorId={sharedAssessmentAccess.access.datastoreVendorId}
          isNdaRequired={sharedAssessmentAccess.access.requireNda}
          isPublicAccess={sharedAssessmentAccess.access.publicAccess}
        />
      </ReportCard>
      <ShareSharedProfileModal
        dispatch={dispatch}
        active={inviteModalActive}
        onClose={() => setInviteModalActive(false)}
        uuid={sharedAssessmentAccess?.access?.uuid}
        usersMustRequestAccess={!sharedAssessmentAccess?.access?.publicAccess}
      />
    </div>
  );
};

export default appConnect()(SharedProfileAccess);

import { ScopeRiskLevel } from "../api/types";

export enum RiskLevelColor {
  Basic = "#CCCFE0",
  Elevated = "#F15F5F",
}

export const riskLevelColor = (riskLevel: ScopeRiskLevel) => {
  return isElevatedRiskLevel(riskLevel)
    ? RiskLevelColor.Elevated
    : RiskLevelColor.Basic;
};

export const isElevatedRiskLevel = (riskLevel: ScopeRiskLevel) =>
  riskLevel >= ScopeRiskLevel.MediumScopeRiskLevel;

import animationData from "../../_common/lotties/tumbleweed.json";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useRef } from "react";

const TumbleweedSVG = () => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  return (
    <div className={"tumbleweed"} style={{ width: 200, height: 200 }}>
      <Lottie
        lottieRef={lottieRef}
        loop={true}
        autoplay={true}
        animationData={animationData}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default TumbleweedSVG;

import { DefaultAction } from "../types/redux";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../types/reduxHooks";
import {
  hasOrgPermission,
  OrgAccessHubspotIdentify,
  usePermissions,
} from "../permissions";

export function hubSpotIsAvailable(): boolean {
  return !!(window.HUBSPOT_APP_ID && window.HubSpotConversations);
}

export function openHubSpot() {
  if (!hubSpotIsAvailable()) return;

  const { loaded } = window.HubSpotConversations.widget.status();

  if (loaded) {
    window.HubSpotConversations.widget.open();
  } else {
    // We'll open as soon as the widget is loaded.
    const handleWidgetLoaded = () => {
      window.HubSpotConversations.widget.open();
      window.HubSpotConversations.off("widgetLoaded", handleWidgetLoaded);
    };

    window.HubSpotConversations.on("widgetLoaded", handleWidgetLoaded);
  }
}

type HubspotWidgetToken = {
  token: string;
};

const addHubspotTrackingCookie = (email: string) => {
  // @ts-ignore
  const _hsq = typeof window !== "undefined" && window._hsq ? window._hsq : [];
  _hsq.push([
    "identify",
    {
      email: email,
    },
  ]);
};

const fetchConversationToken = (): DefaultAction<
  HubspotWidgetToken | undefined
> => {
  return async (dispatch, getState) => {
    let token: HubspotWidgetToken;

    try {
      token = await FetchCyberRiskUrl(
        "plg/v1/hubspot/token",
        null,
        { method: "POST" },
        dispatch,
        getState
      );
      return token;
    } catch (e) {
      LogError("Error getting identification token", e);
      return undefined;
    }
  };
};

const HubspotWidgetWrapper = () => {
  const dispatch = useAppDispatch();

  const userEmail = useAppSelector(
    (state) => state.common.userData.emailAddress
  );

  const loadWidget = () => {
    const { loaded } = window.HubSpotConversations.widget.status();
    if (!loaded) {
      window.HubSpotConversations.widget.load();
    }
  };

  const removeWidget = () => {
    const { loaded } = window.HubSpotConversations.widget.status();
    if (loaded) {
      window.HubSpotConversations.widget.remove();
    }
  };

  const perms = usePermissions();
  const orgHubspotIdentifyEnabled = hasOrgPermission(
    perms.permissions,
    OrgAccessHubspotIdentify
  );

  useEffect(() => {
    if (hubSpotIsAvailable()) {
      if (orgHubspotIdentifyEnabled) {
        addHubspotTrackingCookie(userEmail);
      }

      dispatch(fetchConversationToken())
        .then((resp) => {
          if (!!resp) {
            window.hsConversationsSettings = {
              identificationEmail: userEmail,
              identificationToken: resp.token,
            };
            loadWidget();
          }
        })
        .catch((e) => {
          console.warn(
            "Error loading hubspot widget token. Widget is not active.",
            e
          );
          window.hsConversationsSettings = {
            loadImmediately: false,
          };
          removeWidget();
        });
    }
  }, [userEmail]);

  return <></>;
};

export default HubspotWidgetWrapper;

import { FC, ReactNode, useState } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import "../../style/EditTrustPageLogoModal.scss";
import TrustPageLogo, { TrustPageLogoSize } from "../TrustPageLogo";
import {
  LogoMeta,
  ProfileLogo,
} from "../../../vendorrisk/types/sharedAssessment";
import IconButton from "../../../_common/components/IconButton";
import TrustPageAPI from "../../api/trustpage.api";
import { useDispatch } from "react-redux";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { openInNewTab } from "../../helpers/helpers";
import { formatDateAsLocalCurrentTimezone } from "../../../_common/helpers";

interface IEditTrustPageLogoModalProps extends BaseModalProps {
  profileLogo: LogoMeta | undefined;
  coverImage: LogoMeta | undefined;
}

interface IEditImageTileProps {
  header: string;
  description?: ReactNode;
  isLogo: boolean;
  existingImage?: LogoMeta;
  onImageChanged: (file: File) => void;
  onImageDeleted: () => void;
}

const EditImageTile: FC<IEditImageTileProps> = ({
  header,
  description,
  isLogo,
  existingImage,
  onImageChanged,
  onImageDeleted,
}: IEditImageTileProps) => {
  const dispatch = useDispatch();
  const [imageFilePreview, setImageFilePreview] = useState(existingImage);

  const handleImageChange = (file: File) => {
    onImageChanged(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setImageFilePreview({
            logoUrl: reader.result,
            filename: file.name,
            createdAt: new Date().toISOString(),
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
    setImageFilePreview(undefined);
    onImageDeleted();
  };

  return (
    <div className="update-image-tile">
      <div className={"image-description"}>
        <div className={"header"}>{header}</div>
        <div className={"description"}>{description}</div>
      </div>
      <div className={"image-container"}>
        {imageFilePreview ? (
          <div className={"preview-container"}>
            {isLogo ? (
              <TrustPageLogo
                vendorName={"Company Name"}
                profileLogoType={ProfileLogo.CustomLogo}
                profileLogoUrl={imageFilePreview.logoUrl}
                size={TrustPageLogoSize.Small}
              />
            ) : (
              <div className={"cover-image"}>
                <img
                  src={imageFilePreview.logoUrl}
                  alt={"Cover image preview"}
                />
              </div>
            )}
            <div className="file-details">
              <div className="file-name">{imageFilePreview.filename}</div>
              <div className="file-uploaded-date">
                {formatDateAsLocalCurrentTimezone(imageFilePreview.createdAt)}
              </div>
            </div>

            <IconButton
              className={"delete-image"}
              icon={<div className="cr-icon-trash" />}
              onClick={() => {
                handleImageDelete();
              }}
            />
          </div>
        ) : (
          <div className={"upload-container"}>
            <DragDropUpload
              maxFileSize={5_000_000}
              acceptedFileTypeFilters={[".png", ".jpg"]}
              onFileSelected={(file) => {
                handleImageChange(file);
              }}
              onFileRejected={() => {
                dispatch(
                  addSimpleErrorAlert(
                    "File must be under 5 MB and be either .jpg or .png"
                  )
                );
              }}
            />
            <span>.jpg and .png files accepted. Maximum file size 5mb.</span>
          </div>
        )}
      </div>
    </div>
  );
};

const EditTrustPageLogoModal: FC<IEditTrustPageLogoModalProps> = ({
  active,
  onClose,
  profileLogo,
  coverImage,
}: IEditTrustPageLogoModalProps) => {
  const dispatch = useDispatch();
  const { data: sharedAssessment } =
    TrustPageAPI.useGetOwnSharedAssessmentV1Query();
  const [updateProfileLogo, { isLoading: isUpdatingLogo }] =
    TrustPageAPI.useUpdateOwnVerifiedVendorLogoV1Mutation();
  const [
    updateSharedAssessmentMeta,
    { isLoading: isUpdatingSharedAssessmentMeta },
  ] = TrustPageAPI.useUpdateOwnSharedAssessmentMetadataV1Mutation();
  const [deleteProfileLogo, { isLoading: isDeletingLogo }] =
    TrustPageAPI.useDeleteOwnVerifiedVendorLogoV1Mutation();
  const [updateCoverImage, { isLoading: isUpdatingCoverImage }] =
    TrustPageAPI.useUpdateOwnTrustPageCoverImageV1Mutation();
  const [deleteCoverImage, { isLoading: isDeletingCoverImage }] =
    TrustPageAPI.useDeleteOwnTrustPageCoverImageV1Mutation();

  const isLoading =
    isUpdatingLogo ||
    isDeletingLogo ||
    isUpdatingSharedAssessmentMeta ||
    isUpdatingCoverImage ||
    isDeletingCoverImage;

  const [existingProfileLogo, setExistingProfileLogo] = useState(profileLogo);
  const [profileLogoFile, setProfileLogoFile] = useState<File>();

  const [existingCoverImage, setExistingCoverImage] = useState(coverImage);
  const [coverImageFile, setCoverImageFile] = useState<File>();

  const canSaveChanges =
    profileLogoFile ||
    coverImageFile ||
    existingProfileLogo?.logoUrl !== profileLogo?.logoUrl ||
    existingCoverImage?.logoUrl !== coverImage?.logoUrl;

  const handleSaveChanges = () => {
    if (profileLogoFile) {
      const formData = new FormData();
      formData.append("file", profileLogoFile);
      updateProfileLogo({
        file: formData,
      })
        .unwrap()
        .then(() => {
          //Update profile logo type to custom logo
          updateSharedAssessmentMeta({
            ...sharedAssessment,
            profileLogo: ProfileLogo.CustomLogo,
          });
        })
        .then(() =>
          dispatch(addDefaultSuccessAlert(`Company Logo updated successfully`))
        )
        .catch((e) => {
          console.error(e);
          dispatch(
            addSimpleErrorAlert(
              "Error uploading Company Logo to Trust Page. Please contact UpGuard Support."
            )
          );
        });
    } else if (existingProfileLogo?.logoUrl !== profileLogo?.logoUrl) {
      deleteProfileLogo()
        .unwrap()
        .then(() => {
          //Reset profile logo type to default logo
          updateSharedAssessmentMeta({
            ...sharedAssessment,
            profileLogo: ProfileLogo.DefaultLogo,
          });
        })
        .then(() =>
          dispatch(addDefaultSuccessAlert(`Company Logo deleted successfully`))
        )
        .catch((e) => {
          console.error(e);
          dispatch(
            addSimpleErrorAlert(
              "Error deleting Company Logo from Trust Page. Please contact UpGuard Support."
            )
          );
        });
    }

    if (coverImageFile) {
      const formData = new FormData();
      formData.append("file", coverImageFile);
      updateCoverImage({
        file: formData,
      })
        .unwrap()
        .then(() =>
          dispatch(addDefaultSuccessAlert(`Cover Image updated successfully`))
        )
        .catch((e) => {
          console.error(e);
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error uploading Cover Image to Trust Page. Please contact UpGuard Support."
            )
          );
        });
    } else if (existingCoverImage?.logoUrl !== coverImage?.logoUrl) {
      deleteCoverImage()
        .unwrap()
        .then(() =>
          dispatch(addDefaultSuccessAlert(`Cover Image deleted successfully`))
        )
        .catch((e) => {
          console.error(e);
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error deleting Cover Image from Trust Page. Please contact UpGuard Support."
            )
          );
        });
    }
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      width={800}
      headerContent={"Edit company logo and cover"}
      className={"edit-logo-and-cover-modal"}
      footerClassName={"edit-logo-and-cover-modal-footer"}
      footerContent={
        <div className={"btn-group"}>
          <Button tertiary onClick={() => onClose()} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            primary
            onClick={() => {
              handleSaveChanges();
              onClose();
            }}
            loading={isLoading}
            disabled={!canSaveChanges}
          >
            Save changes
          </Button>
        </div>
      }
    >
      <div className="edit-logo-and-cover-modal-inner">
        <EditImageTile
          header={"Company logo"}
          description={
            <>
              Include a clear and simple logo. Recommended dimensions{" "}
              <span className={"highlighted"}>1000 x 1000px.</span>
            </>
          }
          isLogo={true}
          existingImage={existingProfileLogo}
          onImageChanged={(file: File) => {
            setProfileLogoFile(file);
          }}
          onImageDeleted={() => {
            if (profileLogoFile) {
              setProfileLogoFile(undefined);
            } else {
              setExistingProfileLogo(undefined);
            }
          }}
        />
        <hr />

        <EditImageTile
          header={"Cover image"}
          description={
            <>
              Include an image to enhance your company brand. Recommended
              dimensions <span className={"highlighted"}>2000 x 600px.</span>
              <div className={"note"}>
                <i className="cr-icon-bulb" />
                <div>
                  Plain or simple patterned images work best.{" "}
                  <a
                    onClick={() =>
                      openInNewTab(
                        "https://help.upguard.com/en/articles/3797568-what-is-an-upguard-trust-page"
                      )
                    }
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </>
          }
          isLogo={false}
          existingImage={existingCoverImage}
          onImageChanged={(file: File) => {
            setCoverImageFile(file);
          }}
          onImageDeleted={() => {
            if (coverImageFile) {
              setCoverImageFile(undefined);
            } else {
              setExistingCoverImage(undefined);
            }
          }}
        />
      </div>
    </ModalV2>
  );
};

export default EditTrustPageLogoModal;

import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import SeverityIcon from "../../_common/components/SeverityIcon";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import { IXTableRow, XTableCell } from "../../_common/components/core/XTable";
import { IUserMini } from "../../_common/types/user";
import { useRWUserMap } from "../funcs/useUserMap";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringFeedType,
} from "../api/types";
import FeedWithTable, {
  FeedTableEllipsisedText,
  GetRowsProps,
} from "./FeedWithTable";
import "./RemediatingFeed.scss";
import ThreatCommentsButton from "./ThreatCommentsButton";
import { TMUserList } from "./Users";
import { FeedDate } from "./ResultDate";
import { useNavToThreatDetails } from "../funcs/useNav";

export interface RemediatingFeedProps {
  hidden: boolean;
}

export default function RemediatingFeed({ hidden }: RemediatingFeedProps) {
  const navigateToThreatDetails = useNavToThreatDetails({
    backToText: "Back to Threat Monitoring",
  });

  // Note that a sortable column id must be a valid ThreatMonitoringSortBy in threatmonitoring/db_results_filtering.go
  const columns = [
    { id: "severity", text: "Sev.", sortable: true, className: "severity" },
    { id: "title", text: "Threat name", sortable: true, className: "title" },
    {
      id: "remediation_req_updated_at",
      text: "Last updated",
      sortable: true,
      className: "date",
    },
    {
      id: "actor",
      text: "Sent to",
      sortable: true,
      className: "actor",
    },
    {
      id: "comments",
      text: "Comments",
      sortable: false,
      className: "comments",
    }, // make unsortable until implemented
    { id: "actions", text: "Actions", sortable: false, className: "actions" },
  ];

  const getRows = (
    feed: DisplayableThreatMonitoringResult[],
    { queryResponse }: GetRowsProps
  ): IXTableRow<string>[] => {
    return feed
      .filter((result) => result.remediationRequestID !== undefined)
      .map((result) => {
        const remediationRequestID = result.remediationRequestID ?? 0;

        const remediatingUserIDs = [
          ...(queryResponse?.remediationUsers[remediationRequestID] || []),
        ];

        const remediationRequestInvitees =
          queryResponse?.remediationInvitees[remediationRequestID]?.length;

        const cells = [
          <XTableCell key={"severity"} className={"severity-cell"}>
            <SeverityIcon severity={result.severity} />
          </XTableCell>,
          <XTableCell key={"threatType"} className={"threat-type-cell"}>
            <FeedTableEllipsisedText text={result.title}>
              {result.title}
            </FeedTableEllipsisedText>
          </XTableCell>,
          <XTableCell key={"date"} className={"date-cell"}>
            <FeedDate date={result.remediationRequestUpdatedAt} />
          </XTableCell>,
          <XTableCell key={"actor"} className={"actor-cell"}>
            <RemediatingUser
              remediatingUserIDs={remediatingUserIDs}
              remediationRequestID={remediationRequestID}
              actors={queryResponse?.actors}
              sortActorId={result.sortActorId}
              inviteeCount={remediationRequestInvitees}
            />
          </XTableCell>,
          <XTableCell key={"comments"} className={"comments-cell"}>
            <ThreatCommentsButton
              totalMessages={result.numberOfComments}
              haveUnreadMessages={result.hasUnreadComments}
              onClick={navigateToThreatDetails(result.uuid, true)}
              hiddenVisibilityAttentionLeft={true}
            />
          </XTableCell>,
          <XTableCell key={"actions"} className={"action-cell"}>
            <div className={"actions"}>
              <SidePopupV2
                text={"View threat details"}
                position={"top"}
                width={103}
              >
                <div
                  className={"chevron-container hover-border"}
                  onClick={navigateToThreatDetails(result.uuid)}
                >
                  <div className={"cr-icon-chevron"} />
                </div>
              </SidePopupV2>
            </div>
          </XTableCell>,
        ];
        return {
          id: result.uuid,
          cells: cells,
        };
      })
      .filter((r) => r !== null) as IXTableRow<string>[];
  };

  return (
    <>
      <FeedWithTable
        className="remediating-feed"
        hidden={hidden}
        feedType={ThreatMonitoringFeedType.Remediating}
        columns={columns}
        getRows={getRows}
        feedFailedMessage="Failed to read threats under remediation"
        feedEmptyMessage="There are either no threats under remediation, or no threats matching your current filter settings."
        filterPanelDateFilterTitle="Last updated"
      />
    </>
  );
}

interface RemediatingUserProps {
  remediatingUserIDs: number[];
  remediationRequestID: number;
  actors?: IUserMini[];
  sortActorId?: number;
  // There may be pending invitees in addition to the remediating users.
  inviteeCount?: number;
}

function RemediatingUser({
  remediatingUserIDs,
  actors,
  sortActorId,
  inviteeCount,
}: RemediatingUserProps) {
  const { userMap } = useRWUserMap(actors);

  const remediationUsers = userMap
    ? remediatingUserIDs.map((ru) => userMap[ru])
    : undefined;

  if (remediationUsers === undefined) {
    return <LoadingIcon size={24} />;
  }

  return (
    <TMUserList
      users={remediationUsers}
      showUserId={sortActorId}
      extraUserCount={inviteeCount}
    />
  );
}

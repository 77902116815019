import { FC, ReactNode, useRef } from "react";
import { useGroupInputKeyTrap } from "../hooks/useGroupInputKeyTrap";
import { useActionOnClickOutsideContainer } from "../hooks/useActionOnClickOutsideContainer";

interface IFocusedInputGroupProps {
  className?: string;
  children?: ReactNode;
  onClickOutside: () => void;
  moveFocusOnEnter?: boolean;
}

// This is a wrapper for group of inputs that should be navigated and edit together.
// The wrapper traps focus inside the container on Tab key.
// OnSubmit action can be triggered:
// - by clicking outside the container
// - by pressing Enter on the last input
const FocusedInputGroup: FC<IFocusedInputGroupProps> = ({
  className,
  children,
  onClickOutside,
  moveFocusOnEnter = true,
}: IFocusedInputGroupProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useGroupInputKeyTrap(containerRef, onClickOutside, moveFocusOnEnter);
  useActionOnClickOutsideContainer(containerRef, onClickOutside);

  return (
    <div ref={containerRef} tabIndex={-1} className={className}>
      {children}
    </div>
  );
};

export default FocusedInputGroup;

import { surveyType } from "./surveyTypes";
import { SurveyRiskVisibility } from "./survey";

export enum organisationAccountType {
  trial = "trial",
  paid = "paid",
  free = "free",
  distributor = "distributor",
}

export enum organisationUserLimitType {
  none = "none",
  soft = "soft", // depreciated
  hard = "hard",
}

export enum RelationshipQuestionnaireStateType {
  initial = "initial",
  active = "active",
  inactive = "inactive",
}

export enum AssuranceType {
  None = "",
  Assurance = "assurance",
  MSSP = "mssp",
}

export interface IOrganisation {
  id: number;
  name: string;
  mainHostname: string;
  applianceName: string;
  externalOrganisationId: string;
  active: boolean;
  vendorLookupCount: number;
  vendorLookupLimit: number;
  vendorWatchCount: number;
  vendorWatchLimit: number;
  vendorDocumentLimit: number;
  vendorContactLimit: number;
  tpvmRiskAssessmentLimit?: number;
  tpvmEvidenceChasingLimit: number;
  tpvmManagedRemediationLimit: number;
  tpvmContractStart?: string;
  tpvmContractRollover?: string;
  vendorDataLeaksLimit?: number;
  vendorDataLeaksContractStart?: string;
  parentID?: number;
  createdAt: string;
  vendorLookupLimitIsHard: boolean;
  vendorWatchLimitIsHard: boolean;
  externalSurveyTypes: surveyType[];
  breachSightDueDate?: string;
  breachSightCadence?: string;
  breachSightTerms?: string;
  breachSightArchived: boolean;
  registeredHIBPDomains: string[];
  registeredConstellaDomains: string[];
  gcsBucketAlias: string;
  internalOnly: boolean;
  accountType: organisationAccountType;
  plan?: string;
  trialExpiresOn?: string;
  vendorLookupsOneTime: boolean;
  assuranceType: AssuranceType;
  isVerifiedVendor: boolean;
  isMFAEnabled?: boolean;
  maxSession: boolean;
  verifiedDatastoreVendorID?: number;
  organisationGroupId?: number;
  organisationGroupEntitlements?: string[];
}

export interface IOrganisationWithDistributor extends IOrganisation {
  distributorName: string;
  isDistributor: boolean;
}

export type DigestFrequency = "off" | "weekly" | "fortnightly" | "monthly";

export interface IOrgUserLimits {
  userLimit: number;
  userLimitType: organisationUserLimitType;
}

export interface IUserOrganisation extends IOrganisation {
  roles: string[];
  permissions?: string[];
  isSharedAssessmentPublished: boolean;
  hasSubsidiaries: boolean;
  namedRoleId?: number;
  namedRole?: string;
  completedOnboardingSteps?: string[];
  digestFrequency: DigestFrequency;
  vendorRiskDigestFrequency: DigestFrequency;
  region?: string;
  isVendorTrial?: boolean;
  isOnboardingBooked?: boolean;
  freeTrialSource?: string;
}

// See organisation_flags.go
export enum OrgFlagType {
  EnableMarkedAsCompleteEmailsToVendors = "EnableMarkedAsCompleteEmailsToVendors",
  EnableLogoUsageTypeQuestionnaires = "EnableLogoUsageTypeQuestionnaires",
  EnableLogoUsageTypeEmails = "EnableLogoUsageTypeEmails",
  EnableLogoUsageTypeReports = "EnableLogoUsageTypeReports",
  UserLimit = "UserLimit",
  UserLimitType = "UserLimitType",
  RelationshipQuestionnaireState = "RelationshipQuestionnaireState",
  PortfolioLimit = "PortfolioLimit",
  QuestionnaireRiskVisibility = "QuestionnaireRiskVisibility",
  QuestionnaireCompleteAllowEdit = "QuestionnaireCompleteAllowEdit",
  BreachSightRequireApprovalsRiskWaivers = "BreachSightRequireApprovalsRiskWaivers",
  BreachSightNominateApprovers = "BreachSightNominateApprovers",
  VendorRiskRequireApprovalsRiskWaivers = "VendorRiskRequireApprovalsRiskWaivers",
  VendorRiskRequireApprovalsRiskAdjustments = "VendorRiskRequireApprovalsRiskAdjustments",
  VendorRiskNominateApprovers = "VendorRiskNominateApprovers",
  VendorRiskRemediateWebsitesLimit = "VendorRiskRemediateWebsitesLimit",
}

export interface IOrgFlags {
  [OrgFlagType.EnableMarkedAsCompleteEmailsToVendors]: boolean;
  [OrgFlagType.EnableLogoUsageTypeQuestionnaires]: boolean;
  [OrgFlagType.EnableLogoUsageTypeEmails]: boolean;
  [OrgFlagType.EnableLogoUsageTypeReports]: boolean;
  [OrgFlagType.UserLimit]: number;
  [OrgFlagType.UserLimitType]: organisationUserLimitType;
  [OrgFlagType.RelationshipQuestionnaireState]: RelationshipQuestionnaireStateType;
  [OrgFlagType.PortfolioLimit]: number;
  [OrgFlagType.QuestionnaireRiskVisibility]: SurveyRiskVisibility;
  [OrgFlagType.QuestionnaireCompleteAllowEdit]: boolean;
  [OrgFlagType.BreachSightRequireApprovalsRiskWaivers]: boolean;
  [OrgFlagType.BreachSightNominateApprovers]: boolean;
  [OrgFlagType.VendorRiskRequireApprovalsRiskWaivers]: boolean;
  [OrgFlagType.VendorRiskRequireApprovalsRiskAdjustments]: boolean;
  [OrgFlagType.VendorRiskNominateApprovers]: boolean;
  [OrgFlagType.VendorRiskRemediateWebsitesLimit]: number;
}

export interface IOrganisationUserCountAndLimits {
  activeUserCount: number;
  userLimit: number;
  userLimitType: organisationUserLimitType;
}

export interface IOrgConfig {
  name?: string;
  mainHostname: string;
  assuranceType?: AssuranceType;
  accountType?: string;
  accountPlan?: string;
  trialExpiresOn?: string;
  mfaEnabled?: boolean;
  enabled?: boolean;
  isVerifiedVendor: boolean;
  isInternalOnly: boolean;
  selectedBucketAlias?: string;
  breachSight: {
    enabled: boolean;
    websites: {
      enabled?: boolean;
    };
    portfolios: {
      enabled: boolean;
      numPortfolios: number;
    };
    scoreHistory?: boolean;
    dataExposures?: {
      reporting?: boolean;
      enabled?: boolean;
      dueDate?: string;
      github?: string[];
      s3?: string[];
      gcs?: string[];
      cadence?: {
        oneTime: boolean;
        monthly: boolean;
        yearly: boolean;
      };
    };
    subsidiaries: {
      enabled?: boolean;
    };
    emailExposures?: {
      enabled?: boolean;
      domains?: string[];
      constellaDomains?: string[];
    };
    typosquatting: {
      enabled?: boolean;
    };
  };
  vendorRisk: {
    enabled: boolean;
    defaultVendor: boolean;
    newVendors: boolean;
    numVendors?: number;
    vendorWatchLimitIsHard?: boolean;
    numReports?: number;
    oneTimeReports?: boolean;
    vendorLookupLimitIsHard?: boolean;
    vendorWatchCount?: number;
    portfolios: {
      enabled: boolean;
      numPortfolios: number;
    };
    additionalEvidence: {
      enabled: boolean;
    };
    vendorWebsites: {
      enabled?: boolean;
    };
    vendorFourthParty: {
      enabled?: boolean;
    };
    surveys: {
      enabled: boolean;
      scores: {
        enabled: boolean;
        include: boolean;
      };
    };
    attributesLimit?: number;
    scoreHistory?: boolean;
    riskAssessments: {
      enabled?: boolean;
    };
    questionnaireBuilder: {
      enabled: boolean;
    };
    relationshipQuestionnaireAutomation: boolean;
  };
  cyberResearch: {
    vendorManagement: {
      enabled: boolean;
      evidenceChasingLimit?: number;
      riskAssessmentLimit?: number;
      managedRemediationLimit?: number;
      contractStart?: string;
      mva?: boolean;
    };
    vendorDataLeaks: {
      enabled: boolean;
      limit?: number;
      contractStart?: string;
      vendorDomains?: string[];
    };
    breachNewsFeedDarkWeb: boolean;
    visibleVendorDataLeakUrl: boolean;
  };
  distributor: { enabled: boolean };
  customLogo: { enabled: boolean };
  auditLog: { enabled: boolean };
  defaultTexts: { enabled: boolean };
  customReporting: { enabled: boolean };
  newRolesCreation: { enabled: boolean; displayed?: boolean };
  userLimit: number;
  userLimitType: organisationUserLimitType;
  bulkData: {
    hostnames: {
      enabled: boolean;
      limit?: number;
    };
  };
}

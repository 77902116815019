import BaseAPI from "../../_common/rtkQueryApi";
import {
  ThreatMonitoringKeyword,
  DisplayableThreatMonitoringResult,
  ThreatMonitoringPageOpts,
  ThreatMonitoringResultsFilter,
  ThreatMonitoringFeedType,
  ThreatRemediationUsers,
  ThreatMonitoringHistoryEvent,
  ThreatMonitoringRemediationOutcome,
  ThreatMonitoringResultState,
  ThreatMonitoringHistoryTarget,
} from "./types";
import { IUserMini } from "../../_common/types/user";
import { ICorrespondenceMessage } from "../../_common/types/correspondenceMessage";
import moment from "moment";
import { SeverityInt } from "../../_common/types/severity";
import { RiskCategory } from "../../_common/types/risks";
import { isArray } from "lodash";

export interface GetKeywordsV1Resp {
  keywords: ThreatMonitoringKeyword[];
}

export interface GetKeywordsV1Req {
  activeOnly?: boolean;
  [key: string]: any;
}

export interface ScanKeywordV1ReqBody {
  keyword: string;
}

export interface ScanKeywordV1Resp {
  totalResults: number;
}

export interface CreateKeywordV1ReqBody {
  keyword: string;
  description?: string;
  criticality?: string;
  active: boolean;
}

export interface EditKeywordV1ReqBody {
  uuid: string;
  keyword?: string;
  description?: string;
  criticality?: string;
  active: boolean;
}

export interface GetResultsV1ReqBody {
  feedUpTo?: Date;
  feedType: ThreatMonitoringFeedType;
  pageOpts: ThreatMonitoringPageOpts;
  filters: ThreatMonitoringResultsFilter;
}

export interface GetResultByRemediationIDV1ReqBody {
  remediationRequestID: number;
}

export interface GetResultsV1Resp {
  feedUpTo?: string;
  results: DisplayableThreatMonitoringResult[];
  actors: IUserMini[];
  remediationUsers: ThreatRemediationUsers;
  remediationInvitees: ThreatRemediationUsers;
  totalResults: number;
  pageNum: number;
  pageSize: number;
  sortOption: string;
  sortDesc: boolean;
}

export interface GetResultByRemediationIDV1Resp {
  result: DisplayableThreatMonitoringResult;
  actors: IUserMini[];
}

export interface GetThreatMonitoringResultV1Req {
  uuid: string;
  [key: string]: any;
}
export interface GetThreatMonitoringResultV1Resp {
  result: DisplayableThreatMonitoringResult;
  actors: IUserMini[];
}

export interface GetResultTotalsReq {
  feedType: ThreatMonitoringFeedType;
  feedUpTo: Date;
  filters: ThreatMonitoringResultsFilter;
}

export interface GetResultTotalsResp {
  feedUpTo: string;
  totalResults: number;
  totalOpen: number;
  totalInvestigating: number;
  totalClosed: number;
  totalArchived: number;
  totalRemediating: number;
}

interface feedTotals {
  open: number;
  closed: number;
  investigating: number;
  remediating: number;
  total: number;
}

export interface GetThreatMonitoringTotalsV2Req {
  open?: ThreatMonitoringResultsFilter;
  openFeedUpTo?: Date;

  investigating?: ThreatMonitoringResultsFilter;
  remediating?: ThreatMonitoringResultsFilter;
  closed?: ThreatMonitoringResultsFilter;
}

// filterToStringParams converts a ThreatMonitoringResultsFilter to a Record<string, string>
// so it can be used via util.Params on the backend.
function filterToStringParams(
  filter?: ThreatMonitoringResultsFilter
): Record<string, string> {
  if (!filter) {
    return {};
  }
  const result: Record<string, string> = {};

  for (const key in filter) {
    const value = filter[key as keyof ThreatMonitoringResultsFilter];
    if (value === undefined) {
      continue;
    }

    if (isArray(value)) {
      result[key] = value.join(",");
    } else {
      result[key] = `${value}`;
    }
  }

  return result;
}

// stringifyGetThreatMonitoringTotalsV2Req converts a GetThreatMonitoringTotalsV2Req to a JSON string
// Each filter is converted to a Record<string, string> so it can be used via util.Params on the backend.
function stringifyGetThreatMonitoringTotalsV2Req(
  req: GetThreatMonitoringTotalsV2Req
): string {
  return JSON.stringify({
    open: filterToStringParams(req.open),
    investigating: filterToStringParams(req.investigating),
    remediating: filterToStringParams(req.remediating),
    closed: filterToStringParams(req.closed),

    openFeedUpTo: dateToRFC3339(req.openFeedUpTo),
  });
}

// peer type GetThreatMonitoringTotalsV2Resp in api/threatmonitoring/results_v1.go
export interface GetThreatMonitoringTotalsV2Resp {
  filtered: feedTotals;
  unfiltered: feedTotals;
}

// peer: api/threatmonitoring/results_v1.go
export interface ThreatMonitoringTotalCountResponse {
  count: number;
}

// GetResultsTemporalDistributionReq is the request for [threatMonitoringRoutes.GetThreatMonitoringResultsTemporalDistributionV1] in [./api/threatmonitoring/results_v1.go].
export interface GetResultsTemporalDistributionReq {
  filters?: ThreatMonitoringResultsFilter;
  feedUpTo?: Date;
}

export interface IntervalFrequency {
  frequency: number;
  start: Date;
}

// GetResultsTemporalDistributionResp is the response for [threatMonitoringRoutes.GetThreatMonitoringResultsTemporalDistributionV1] in [./api/threatmonitoring/results_v1.go].
export interface GetResultsTemporalDistributionResp {
  end: Date;
  interval_calculations: IntervalFrequency[];
}

export interface UpdateResultStateV1Req {
  feedUpTo?: Date;
  openFeedOpts: ThreatMonitoringPageOpts;
  closedFeedPageOpts: ThreatMonitoringPageOpts;
  investigatingFeedPageOpts: ThreatMonitoringPageOpts;
  remediatingFeedPageOpts: ThreatMonitoringPageOpts;
  filters: ThreatMonitoringResultsFilter;
  uuids: string[];
  state: string;
}

export interface UpdateResultStateSimpleV1Req {
  uuids: string[];
  state: ThreatMonitoringResultState;
  target?: ThreatMonitoringHistoryTarget;
}

export interface UpdateResultStateV1Resp {
  results: {
    // uuid -> result
    [index: string]: DisplayableThreatMonitoringResult;
  };
}

export interface CloseResultAsRemediatedV1Req {
  uuid: string;
  outcome: ThreatMonitoringRemediationOutcome;
  additionalInformation?: string;
}

export interface CloseResultAsRemediatedV1Resp {
  status: string;
}

export interface GetNewResultsSinceV1Req {
  identifiedAfter: Date;
  filters: ThreatMonitoringResultsFilter;
}
export interface GetNewResultsSinceV1Resp {
  identifiedAfter: string;
  totalResults: number;
  totalResultsUnfiltered: number;
}

export interface GetReadWriteUsersResp {
  users: IUserMini[];
}

export interface UpdateAssigneeSimpleV1Req {
  uuids: string[];
  userID: number;
}

export interface UpdateAssigneeV1Req extends UpdateAssigneeSimpleV1Req {
  investigatingFeedPageOpts: ThreatMonitoringPageOpts;
  filters: ThreatMonitoringResultsFilter;
}

export enum ThreatMonitoringTagTypes {
  keywords = "keywords",
  keywordScanResult = "keywordScanResult",
  results = "results",
  result = "result",
  totals = "totals",
  resultsTemporalDistribution = "resultsTemporalDistribution",
  totalAfter = "totalAfter",
  users = "users",
  session = "session",
  history = "history",
  resultComments = "resultComments",
}

export interface GetResultTimelineV1Req {
  uuid: string;
}
export interface GetResultTimelineV1Resp {
  events: ThreatMonitoringHistoryEvent[];
  actors: IUserMini[];
}

export interface GetResultUUIDsV1Req {
  filters: ThreatMonitoringResultsFilter;
  feedUpTo?: Date;
}

export interface GetResultUUIDsV1Resp {
  uuids: string[];
}

export interface GetThreatRiskV1Req {
  uuid: string;
  [key: string]: any;
}

export interface GetThreatRiskV1Resp {
  checkID: string;
  findingUUID: string;
  riskSeverity: SeverityInt;
  factCategory: RiskCategory;
  failTitle: string;
  description: string;
}

// ThreatResultCommentsAddV1Req is the request for [threatMonitoringRoutes.ThreatResultCommentsAddV1] api/threatmonitoring/threat_comments_v1.go
export interface ThreatResultCommentsAddV1Req {
  threatResultUUID: string;
  commentContent: string;
  commentParentID?: number;
}

// ThreatResultCommentsEditV1Req is the request for [threatMonitoringRoutes.ThreatResultCommentsEditV1] api/threatmonitoring/threat_comments_v1.go
export interface ThreatResultCommentsEditV1Req {
  commentID: number;
  commentContent: string;
}

// ThreatResultCommentsListV1Req is the request for [threatMonitoringRoutes.ThreatResultCommentsListV1] api/threatmonitoring/threat_comments_v1.go
export interface ThreatResultCommentsListV1Req {
  threatResultUUID: string;
  markAsRead?: boolean;
}

// ThreatResultCommentsListV1Resp is the response for [threatMonitoringRoutes.ThreatResultCommentsListV1] api/threatmonitoring/threat_comments_v1.go
export type ThreatResultCommentsListV1Resp = ICorrespondenceMessage[];

export interface EmptyResponse {}

const ThreatMonitoringAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(ThreatMonitoringTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    /*
        getKeywordsV1
        - gets the list of active and disabled keywords for the user's org
       */
    getKeywordsV1: builder.query<GetKeywordsV1Resp, GetKeywordsV1Req>({
      query: (params) => ({
        url: "/threatmonitoring/keywords/v1",
        method: "GET",
        params,
      }),
      providesTags: [ThreatMonitoringTagTypes.keywords],
    }),
    /*
        scanKeywordV1
        - fetches the total count of results across dark web collectors
        for a keyword. This is used to provide the customer with an idea
        of the keyword noise level before they confirm they'd like to add
        it for continuous monitoring.
       */
    scanKeywordV1: builder.mutation<ScanKeywordV1Resp, ScanKeywordV1ReqBody>({
      query: (req) => ({
        url: "/threatmonitoring/keywords/scan/v1",
        method: "POST",
        body: JSON.stringify(req),
      }),
      invalidatesTags: [ThreatMonitoringTagTypes.keywordScanResult],
    }),
    /*
        createKeywordV1
        - adds a new (active) keyword to the user's org
       */
    createKeywordV1: builder.mutation<void, CreateKeywordV1ReqBody>({
      query: (req) => ({
        url: "/threatmonitoring/keywords/v1",
        method: "POST",
        body: JSON.stringify(req),
      }),
      invalidatesTags: [ThreatMonitoringTagTypes.keywords],
    }),
    /*
        editKeywordV1
        - updates an existing keyword
       */
    editKeywordV1: builder.mutation<void, EditKeywordV1ReqBody>({
      query: (req) => ({
        url: "/threatmonitoring/keywords/v1",
        method: "PUT",
        body: JSON.stringify(req),
      }),
      invalidatesTags: [ThreatMonitoringTagTypes.keywords],
    }),

    /*
      getResultsV1
      - gets a paged, filtered list of threat-monitoring results for the user's org
   */
    getResultsV1: builder.query<GetResultsV1Resp, GetResultsV1ReqBody>({
      query: (req) => ({
        url: "/threatmonitoring/results/v1",
        method: "GET",
        params: {
          feedUpTo: dateToRFC3339(req.feedUpTo),
          feedType: req.feedType,
          pageSize: req.pageOpts.pageSize,
          pageNum: req.pageOpts.pageNum,
          sortDesc: req.pageOpts.sortDesc,
          sortBy: req.pageOpts.sortBy,
          datePeriodTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...req.filters,
        },
      }),
      providesTags: (result, _, arg) =>
        result
          ? [{ type: ThreatMonitoringTagTypes.results, id: arg.feedType }]
          : [ThreatMonitoringTagTypes.results],
    }),

    /*
      getResultUUIDsV1
      - gets a filtered list of threat-monitoring result uuids for the user's org
   */
    getResultUUIDsV1: builder.query<GetResultUUIDsV1Resp, GetResultsV1ReqBody>({
      query: (req) => ({
        url: "/threatmonitoring/resultuuids/v1",
        method: "GET",
        params: {
          feedUpTo: dateToRFC3339(req.feedUpTo),
          feedType: req.feedType,
          sortDesc: req.pageOpts.sortDesc,
          sortBy: req.pageOpts.sortBy,
          datePeriodTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...req.filters,
        },
      }),
      providesTags: [ThreatMonitoringTagTypes.session],
    }),

    getResultV1: builder.query<
      GetThreatMonitoringResultV1Resp,
      GetThreatMonitoringResultV1Req
    >({
      query: (params) => ({
        url: "/threatmonitoring/result/v1",
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: ThreatMonitoringTagTypes.result,
                id: result.result.uuid,
              },
            ]
          : [],
    }),

    /*
      getResultTotalsV1
      - gets a set of total counts for various classes of threat monitoring result
    */
    getResultTotalsV1: builder.query<
      ThreatMonitoringTotalCountResponse,
      GetResultTotalsReq
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/totals/v1",
        method: "GET",
        params: {
          feedType: req.feedType,
          feedUpTo: dateToRFC3339(req.feedUpTo),
          datePeriodTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...req.filters,
        },
      }),
      providesTags: (res, _, args) =>
        res
          ? [
              ThreatMonitoringTagTypes.totals,
              { type: ThreatMonitoringTagTypes.totals, id: args.feedType },
            ]
          : [],
    }),

    /*
      getResultTotalsV2
      - gets a set of total counts for various classes of threat monitoring result
    */
    getResultTotalsV2: builder.query<
      GetThreatMonitoringTotalsV2Resp,
      GetThreatMonitoringTotalsV2Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/totals/v2",
        method: "POST",
        body: stringifyGetThreatMonitoringTotalsV2Req(req),
      }),
      providesTags: [
        ThreatMonitoringTagTypes.totals,
        { type: ThreatMonitoringTagTypes.totals, id: "open" },
        { type: ThreatMonitoringTagTypes.totals, id: "investigating" },
        { type: ThreatMonitoringTagTypes.totals, id: "closed" },
        { type: ThreatMonitoringTagTypes.totals, id: "remediating" },
      ],
    }),

    /*
      getNewResultsSinceV1
      - gets a total count of threats identified after a certain datetime
    */
    getNewResultsSinceV1: builder.query<
      GetNewResultsSinceV1Resp,
      GetNewResultsSinceV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/since/v1",
        method: "GET",
        params: {
          identifiedAfter: dateToRFC3339(req.identifiedAfter),
          datePeriodTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...req.filters,
        },
      }),
      providesTags: [ThreatMonitoringTagTypes.totalAfter],
    }),

    /*
      getResultByRemediationIDV1
      - gets the single result linked to the given remediation request ID
   */
    getResultByRemediationIDV1: builder.query<
      GetResultByRemediationIDV1Resp,
      GetResultByRemediationIDV1ReqBody
    >({
      query: (req) => ({
        url: "/threatmonitoring/result/remediation/v1",
        method: "GET",
        params: {
          remediationRequestID: req.remediationRequestID,
        },
      }),
      providesTags: (res) =>
        res && res.result
          ? [
              {
                type: ThreatMonitoringTagTypes.result,
                id: res.result.uuid,
              },
            ]
          : [],
    }),
    /*
      updateResultStateV1
      - sets the state on one or more results by uuid
    */
    updateResultStateV1: builder.mutation<
      UpdateResultStateV1Resp,
      UpdateResultStateV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/state/v1",
        method: "PUT",
        params: { uuids: req.uuids, state: req.state },
      }),
      invalidatesTags: [ThreatMonitoringTagTypes.totals],
      onQueryStarted: (req, { dispatch, queryFulfilled }) => {
        // how do we remove the item with req.uuid from the existing results list?

        const patchA = dispatch(
          ThreatMonitoringAPI.util.updateQueryData(
            "getResultsV1",
            {
              feedType: ThreatMonitoringFeedType.Open,
              feedUpTo: req.feedUpTo,
              pageOpts: req.openFeedOpts,
              filters: req.filters,
            },
            (draft) => {
              if (req.state != ThreatMonitoringFeedType.Open) {
                for (let idx = 0; idx < req.uuids.length; idx++) {
                  draft.results = draft.results.filter((result) => {
                    return !req.uuids.includes(result.uuid);
                  });
                }
              }
            }
          )
        );
        const patchB = dispatch(
          ThreatMonitoringAPI.util.updateQueryData(
            "getResultsV1",
            {
              feedType: ThreatMonitoringFeedType.Closed,
              pageOpts: req.closedFeedPageOpts,
              filters: req.filters,
            },
            (draft) => {
              if (req.state != ThreatMonitoringFeedType.Closed) {
                for (let idx = 0; idx < req.uuids.length; idx++) {
                  draft.results = draft.results.filter((result) => {
                    return !req.uuids.includes(result.uuid);
                  });
                }
              }
            }
          )
        );
        const patchC = dispatch(
          ThreatMonitoringAPI.util.updateQueryData(
            "getResultsV1",
            {
              feedType: ThreatMonitoringFeedType.Investigating,
              pageOpts: req.investigatingFeedPageOpts,
              filters: req.filters,
            },
            (draft) => {
              if (req.state != ThreatMonitoringFeedType.Investigating) {
                for (let idx = 0; idx < req.uuids.length; idx++) {
                  draft.results = draft.results.filter((result) => {
                    return !req.uuids.includes(result.uuid);
                  });
                }
              }
            }
          )
        );
        const patchD = dispatch(
          ThreatMonitoringAPI.util.updateQueryData(
            "getResultsV1",
            {
              feedType: ThreatMonitoringFeedType.Remediating,
              pageOpts: req.remediatingFeedPageOpts,
              filters: req.filters,
            },
            (draft) => {
              if (req.state != ThreatMonitoringFeedType.Remediating) {
                for (let idx = 0; idx < req.uuids.length; idx++) {
                  draft.results = draft.results.filter((result) => {
                    return !req.uuids.includes(result.uuid);
                  });
                }
              }
            }
          )
        );

        queryFulfilled.catch(() => {
          patchA.undo();
          patchB.undo();
          patchC.undo();
          patchD.undo();
        });

        queryFulfilled.then(() => {
          dispatch(
            // TODO also invalidate tag per result
            ThreatMonitoringAPI.util.invalidateTags([
              ThreatMonitoringTagTypes.results,
            ])
          );
        });
      },
    }),

    /*
      updateResultStateV1
      - sets the state on one or more results by uuid
    */
    updateResultStateSimpleV1: builder.mutation<
      UpdateResultStateV1Resp,
      UpdateResultStateSimpleV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/state/v1",
        method: "PUT",
        params: {
          uuids: req.uuids,
          state: req.state,
          target: JSON.stringify(req.target),
        },
      }),
      invalidatesTags: (result, _, arg) =>
        result
          ? [
              ThreatMonitoringTagTypes.totals,
              // Invalidate the destination feed
              { type: ThreatMonitoringTagTypes.results, id: arg.state },
              ...arg.uuids.flatMap((uuid) => [
                {
                  type: ThreatMonitoringTagTypes.result,
                  id: uuid,
                },
                {
                  type: ThreatMonitoringTagTypes.history,
                  id: uuid,
                },
              ]),
            ]
          : [],
    }),

    /*
      closeResultAsRemediatedV1
      - closes the threat associated with the given UUID as remediated
    */
    closeResultAsRemediatedV1: builder.mutation<
      CloseResultAsRemediatedV1Resp,
      CloseResultAsRemediatedV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/selfremediate/v1",
        method: "POST",
        params: {
          uuid: req.uuid,
          outcome: req.outcome,
          additionalInformation: req.additionalInformation,
        },
      }),
      invalidatesTags: (result, _, arg) =>
        result
          ? [
              ThreatMonitoringTagTypes.totals,
              ThreatMonitoringTagTypes.results,
              { type: ThreatMonitoringTagTypes.result, id: arg.uuid },

              {
                type: ThreatMonitoringTagTypes.history,
                id: arg.uuid,
              },
            ]
          : [],
    }),

    /*
      getResultsTemporalDistributionV1
      - See [threatMonitoringRoutes.GetThreatMonitoringResultsTemporalDistributionV1] in [./api/threatmonitoring/results_v1.go].
    */
    getResultsTemporalDistributionV1: builder.query<
      GetResultsTemporalDistributionResp,
      GetResultsTemporalDistributionReq
    >({
      query: (req) => ({
        url: "/threatmonitoring/results/temporal_distribution/v1",
        method: "GET",
        params: {
          feedUpTo: dateToRFC3339(req.feedUpTo),
          datePeriodTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...req.filters,
        },
      }),
      providesTags: [ThreatMonitoringTagTypes.resultsTemporalDistribution],
      transformResponse: (result: any): GetResultsTemporalDistributionResp => {
        if (result.end) {
          result.end = new Date(result.end);
        }

        if (result.interval_calculations) {
          for (let a = 0; a < result.interval_calculations.length; a++) {
            if (result.interval_calculations[a].start) {
              result.interval_calculations[a].start = new Date(
                result.interval_calculations[a].start
              );
            }
          }
        }

        return result;
      },
    }),

    /*
      getReadWriteUsersV1
      - gets a set of users with read/write access to threat monitoring
    */
    getReadWriteUsersV1: builder.query<GetReadWriteUsersResp, void>({
      query: () => ({
        url: "/threatmonitoring/users/v1",
        method: "GET",
      }),
      providesTags: [ThreatMonitoringTagTypes.users],
    }),

    /*
      updateAssigneeV1
      - sets the assignee (or unassigns) on one or more results by uuid
    */
    updateAssigneeV1: builder.mutation<void, UpdateAssigneeV1Req>({
      query: (req) => ({
        url: "/threatmonitoring/investigator/v1",
        method: "PUT",
        params: {
          uuids: req.uuids,
          user_id: req.userID > 0 ? req.userID : undefined,
        },
      }),

      onQueryStarted: (req, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          ThreatMonitoringAPI.util.updateQueryData(
            "getResultsV1",
            {
              feedType: ThreatMonitoringFeedType.Investigating,
              pageOpts: req.investigatingFeedPageOpts,
              filters: req.filters,
            },
            (draft) => {
              for (let idx = 0; idx < req.uuids.length; idx++) {
                for (let idx2 = 0; idx2 < draft.results.length; idx2++) {
                  if (draft.results[idx2].uuid == req.uuids[idx]) {
                    draft.results[idx2].actorId =
                      req.userID > 0 ? req.userID : undefined;
                  }
                }
              }
            }
          )
        );
        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    updateAssigneeSimpleV1: builder.mutation<void, UpdateAssigneeSimpleV1Req>({
      query: (req) => ({
        url: "/threatmonitoring/investigator/v1",
        method: "PUT",
        params: {
          uuids: req.uuids,
          user_id: req.userID > 0 ? req.userID : undefined,
        },
      }),
      invalidatesTags: (result, _, arg) =>
        result
          ? [
              ThreatMonitoringTagTypes.totals,
              {
                type: ThreatMonitoringTagTypes.results,
                id: ThreatMonitoringFeedType.Investigating,
              },
              ...arg.uuids.flatMap((uuid) => [
                {
                  type: ThreatMonitoringTagTypes.result,
                  id: uuid,
                },
                {
                  type: ThreatMonitoringTagTypes.history,
                  id: uuid,
                },
              ]),
            ]
          : [],
    }),
    getResultHistoryV1: builder.query<
      GetResultTimelineV1Resp,
      GetThreatMonitoringResultV1Req
    >({
      query: (params) => ({
        url: "/threatmonitoring/result/history/v1",
        method: "GET",
        params,
      }),
      providesTags: (result, _, args) =>
        result
          ? [
              {
                type: ThreatMonitoringTagTypes.history,
                id: args.uuid,
              },
            ]
          : [],
    }),
    // getThreatRiskV1 fetches a risk derived from the threat belonging to the given UUID.
    // Note that this risk doesn't exist, it's to show the potential risk the threat might pose.
    getThreatRiskV1: builder.query<GetThreatRiskV1Resp, GetThreatRiskV1Req>({
      query: (params) => ({
        url: "/threatmonitoring/risks/threat/v1",
        method: "GET",
        params,
      }),
    }),
    threatResultCommentsAddV1: builder.mutation<
      EmptyResponse,
      ThreatResultCommentsAddV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/result/comments/v1",
        method: "POST",
        params: {
          threatUUID: req.threatResultUUID,
        },
        body: JSON.stringify({
          content: req.commentContent,
          parentID: req.commentParentID,
        }),
      }),
      invalidatesTags: (result, _, arg) =>
        result
          ? [
              ThreatMonitoringTagTypes.resultComments,
              ThreatMonitoringTagTypes.results,
              {
                type: ThreatMonitoringTagTypes.result,
                id: arg.threatResultUUID,
              },
              {
                type: ThreatMonitoringTagTypes.history,
                id: arg.threatResultUUID,
              },
            ]
          : [],
    }),
    threatResultCommentsEditV1: builder.mutation<
      undefined,
      ThreatResultCommentsEditV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/result/comments/v1",
        method: "PUT",
        params: {
          id: req.commentID,
        },
        body: JSON.stringify({
          content: req.commentContent,
        }),
      }),
      invalidatesTags: [ThreatMonitoringTagTypes.resultComments],
    }),
    threatResultCommentsListV1: builder.query<
      ThreatResultCommentsListV1Resp,
      ThreatResultCommentsListV1Req
    >({
      query: (req) => ({
        url: "/threatmonitoring/result/comments/v1",
        method: "GET",
        params: {
          threatUUID: req.threatResultUUID,
          markAsRead: req.markAsRead,
        },
      }),
      providesTags: [ThreatMonitoringTagTypes.resultComments],
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            ThreatMonitoringAPI.util.invalidateTags([
              {
                type: ThreatMonitoringTagTypes.result,
                id: req.threatResultUUID,
              },
              ThreatMonitoringTagTypes.results,
            ])
          );
        });
      },
    }),
  }),
});

function dateToRFC3339(date?: Date): string | undefined {
  if (!date) {
    return undefined;
  }

  return moment(date).local().format("YYYY-MM-DDTHH:mm:ssZ");
}

export default ThreatMonitoringAPI;

import XTable, {
  IIconOption,
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import CircledIcon from "../../_common/components/CircledIcon";
import { useSorting } from "../../_common/hooks";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import QuestionnaireIcon from "../../_common/images/questionnaire-icon.svg";
import { IDocumentRecord } from "../helpers/types";
import { formatDateAsLocal } from "../../_common/helpers";
import { FC } from "react";
import "../style/DocumentationTable.scss";
import classNames from "classnames";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { ContentLibraryDocumentRedacted } from "../../contentlibrary/types/contentLibrary.types";
import { getCyberRiskAuth } from "../../_common/session";
import { trackEvent } from "../../_common/tracking";
import { appendParams } from "../../vendorrisk/helpers/util";
import { downloadContentLibraryDocumentFile } from "../../contentlibrary/api/downloadDocument";
import { DropdownItem } from "../../_common/components/core/DropdownV2";
import { useHistory } from "react-router-dom";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import ContentLibraryAPI from "../../contentlibrary/api/contentLibraryAPI";

const documentationTableColumnHeaders: IXTableColumnHeader[] = [
  {
    id: "document",
    text: "File name",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "published",
    text: "Last updated",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
];

interface IDocumentationTableProps {
  vendorId: number;
  documents: IDocumentRecord[];
  userCanMakeChanges: boolean;
  userHasAccess: boolean;
  onRequestAccess?: () => void;
}

const downloadDocument = (
  vendorId: number,
  doc: ContentLibraryDocumentRedacted
) => {
  const { apiKey, token } = getCyberRiskAuth();

  const url = appendParams(`/api/sharedassessment/documents/v1`, {
    apikey: encodeURIComponent(apiKey),
    token: encodeURIComponent(token),
    vendor_id: encodeURIComponent(vendorId),
    uuid: encodeURIComponent(doc.uuid),
  });

  trackEvent("VerifiedVendor_DownloadDocument");

  const a = document.createElement("a");
  a.href = url;
  a.download = doc.filename;
  a.click();
};

const DocumentationTable: FC<IDocumentationTableProps> = ({
  vendorId,
  documents,
  userCanMakeChanges,
  userHasAccess,
  onRequestAccess = () => {},
}: IDocumentationTableProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [updateIncludeInSharedProfile] =
    ContentLibraryAPI.useUpdateContentLibraryDocumentSharedProfileMutation();

  const canDownload = userHasAccess || userCanMakeChanges;
  const goToContentLibrary = (uuid: string) =>
    history.push(`/contentlibrary/document/${uuid}`, {
      backContext: {
        goBack: true,
        backToText: "Back to Trust Page",
      },
    });

  const handleExcludeDocument = (uuid: string, docName: string) => {
    updateIncludeInSharedProfile({
      uuid: uuid,
      includeInSharedProfile: false,
    })
      .unwrap()
      .then(() => {
        dispatch(
          addDefaultSuccessAlert(
            `${docName} has been removed from your Trust Page`
          )
        );
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error excluding document from Trust Page. Try again later."
          )
        );
      });
  };

  const [sortedDocuments, sortedBy, onSortChange] = useSorting<
    IDocumentRecord,
    "document" | "published"
  >(documents, "published", SortDirection.DESC, {
    document: {
      orderFuncs: [(a) => a.document.name.toLowerCase()],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    published: {
      orderFuncs: [(a) => a.document.updatedAt],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });

  const rows = sortedDocuments.map((record) => {
    let downloadFn = () => {};

    if (canDownload) {
      if (userCanMakeChanges) {
        downloadFn = () =>
          dispatch(downloadContentLibraryDocumentFile(record.document.uuid));
      } else {
        downloadFn = () => downloadDocument(vendorId, record.document);
      }
    }

    const iconOptions: IIconOption[] = [];

    if (userCanMakeChanges) {
      iconOptions.push({
        id: "meatball",
        icon: <div className={"cr-icon-dots-menu"} />,
        disabled: false,
        dropdownItems: [
          <DropdownItem
            key="exclude"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={() =>
              handleExcludeDocument(record.document.uuid, record.document.name)
            }
          >
            <div className="action-label">
              <i className="cr-icon-minus-circle" />
              <div>Exclude</div>
            </div>
          </DropdownItem>,
          <DropdownItem
            key="edit"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={() => goToContentLibrary(record.document.uuid)}
          >
            <div
              className="action-label"
              onClick={() => goToContentLibrary(record.document.uuid)}
            >
              <i className="cr-icon-pencil-2" />
              <div>Edit</div>
            </div>
          </DropdownItem>,
          <DropdownItem
            key="Download"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={() => downloadDocument(vendorId, record.document)}
          >
            <div
              className="action-label"
              onClick={() => downloadDocument(vendorId, record.document)}
            >
              <i className="cr-icon-download" />
              <div>Download</div>
            </div>
          </DropdownItem>,
        ],
      });
    } else {
      if (!record.userHasAccess) {
        iconOptions.push({
          id: "request-access",
          icon: <div className="cr-icon-lock" />,
          hoverText: "Request access",
          onClick: onRequestAccess,
        });
      }
    }

    return {
      className: classNames("doc-row", { clickable: record.userHasAccess }),
      id: record.document.uuid,
      onClick: downloadFn,
      iconOptions,
      cells: [
        <XTableCell key="document">
          <div className="doc-cell">
            <img
              className="file-type-icon"
              src={GetIconForFilename(
                record.document.filename,
                QuestionnaireIcon
              )}
              alt={record.document.filename}
            />
            <div className="name-and-desc">
              <div className={"cell-top-content"}>{record.document.name}</div>
              {record.document.description && (
                <div className="desc">{record.document.description}</div>
              )}
            </div>
          </div>
        </XTableCell>,
        <XTableCell key="published" className="date-cell">
          <div className="cell-top-content">
            {formatDateAsLocal(record.document.updatedAt)}
          </div>
        </XTableCell>,
      ],
    };
  });

  return documents.length > 0 ? (
    <XTable
      className="trust-page-documentation-table"
      sortedBy={sortedBy}
      onSortChange={onSortChange}
      columnHeaders={documentationTableColumnHeaders}
      iconOptions
      rows={rows}
    />
  ) : (
    <EmptyCardWithAction
      iconJSX={
        <CircledIcon iconClass="cr-icon-q-builder-document-attachment" />
      }
      emptyText={"No documents attached"}
      emptySubText={
        "Share important or frequently-requested documents for easy access."
      }
    />
  );
};

export default DocumentationTable;

import { useState, useEffect, useRef } from "react";
import {
  setCompletedOnboardingStepLocally,
  setOnboardingStepComplete,
} from "../../_common/reducers/commonActions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { OnboardingStepName } from "../../_common/types/onboardingStepsMeta";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import classnames from "classnames";
import ModalV2 from "../../_common/components/ModalV2";
import FeatureIntroModal, {
  FeatureIntroModalStep,
} from "../../_common/components/modals/FeatureIntroModal";

import "./WelcomeTour.scss";

import Step0Img from "../images/1-radar.svg";
import Step1Img from "../images/2-keywords.svg";
import Step2Img from "../images/3-action.svg";
import InfoBanner from "../../vendorrisk/components/InfoBanner";
import KeywordsTable from "./KeywordsTable";
import Button from "../../_common/components/core/Button";
import { SYSTEM_USER_ID } from "../api/types";

const tourSteps: FeatureIntroModalStep[] = [
  {
    title: "Continuously monitor for threats",
    description: (
      <p>
        A centralized place to detect, investigate and respond to threats before
        they become serious incidents.
      </p>
    ),
    image: Step0Img,
  },
  {
    title: "Scalable control with keywords",
    description: (
      <p>Add keywords to monitor across the deep, dark and open web.</p>
    ),
    image: Step1Img,
  },
  {
    title: "Take action on identified threats",
    description: (
      <p>
        Act swiftly to ensure credible threats are resolved, or track progress
        through remediation requests.
      </p>
    ),
    image: Step2Img,
  },
];

export default function WelcomeTour({ show }: { show: boolean }) {
  const dispatch = useAppDispatch();
  const closeWasCompletion = useRef(false);

  const [phase, setPhase] = useState<"tour" | "keywords">("tour");

  const dismissModal = () =>
    dispatch(
      setCompletedOnboardingStepLocally(
        OnboardingStepName.ThreatMonitoringFirstVisit
      )
    );

  const onCloseTour = () => {
    if (closeWasCompletion.current) {
      closeWasCompletion.current = false;
      return;
    }
    dismissModal();
  };
  const tourComplete = () => {
    closeWasCompletion.current = true;
    setPhase("keywords");
  };

  const onCompleteKeywords = () => {
    dismissModal();
    dispatch(
      setOnboardingStepComplete(OnboardingStepName.ThreatMonitoringFirstVisit)
    );
  };
  const onCloseKeywords = onCompleteKeywords;

  return (
    <>
      {phase === "tour" && (
        <FeatureIntroModal
          key="tour-modal"
          steps={tourSteps}
          active={show}
          onClose={onCloseTour}
          onComplete={tourComplete}
          noClose
        />
      )}
      {phase === "keywords" && (
        <Keywords
          key="tour-modal"
          active={show}
          onClose={onCloseKeywords}
          onComplete={onCompleteKeywords}
        />
      )}
    </>
  );
}

interface KeywordsProps {
  active: boolean;
  onClose: () => void;
  onComplete: () => void;
}
function Keywords({ active, onClose, onComplete }: KeywordsProps) {
  const dispatch = useAppDispatch();

  // grab the current org's keywords data
  const { data, isLoading, error } = ThreatMonitoringAPI.useGetKeywordsV1Query(
    { activeOnly: true },
    { skip: !active }
  );
  // if we have experienced a read error, display an error message once only
  useEffect(() => {
    if (error) {
      console.error(
        `#### error loading keywords set: ${JSON.stringify(error)}`
      );
      dispatch(
        addDefaultUnknownErrorAlert(
          "Failed to load threat monitoring keywords for account"
        )
      );
    }
  }, [error]);

  const pageSize = 5;
  let padTable = false;

  let layout: JSX.Element | null = null;
  if (
    data?.keywords?.length === 2 &&
    data.keywords.every((k) => k.createdByUserID === SYSTEM_USER_ID)
  ) {
    layout = (
      <>
        <InfoBanner
          className="keywords-info"
          message={
            <>
              <div className="main">
                We&apos;ve automatically detected threat keywords for your
                organisation.
              </div>
              <div className="sub">
                We use these keywords to search across various sources for
                threats.
              </div>
            </>
          }
        />
        <div className={"keywords"}>
          <KeywordsTable
            pageSize={2}
            conciseView
            noKeywordsMessage={"No keywords found"}
            loading={isLoading}
            keywords={data?.keywords}
          />
        </div>
      </>
    );
  } else {
    if (data?.keywords && data.keywords.length > pageSize) {
      padTable = true;
    }
    layout = (
      <>
        <InfoBanner
          className="keywords-info"
          message={
            <>
              <div className="sub">
                <strong>{data?.keywords?.length || 0}</strong> keywords are
                being actively monitored for your organization.
              </div>
            </>
          }
        />
        <div className={"keywords"}>
          <KeywordsTable
            pageSize={5}
            fillEmptyRows={padTable}
            conciseView
            noKeywordsMessage={"No keywords found"}
            loading={isLoading}
            keywords={data?.keywords}
          />
        </div>
      </>
    );
  }

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent="Keywords"
      containerClassName={classnames("welcome-modal-keywords")}
      onFormDefaultSubmit={onComplete}
      disallowClickOutside={true}
      footerContent={
        <div className={"footer"}>
          <div className="carousel-container"></div>
          <div className="buttons">
            <Button primary onClick={onComplete} loading={false} arrow>
              Continue to threats
            </Button>
          </div>
        </div>
      }
    >
      <div className="body">{layout}</div>
    </ModalV2>
  );
}

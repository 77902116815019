import ColorGrade from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import { FC, ReactNode } from "react";
import "../style/components/GradeWithScoreCard.scss";
import { PopupPosition, SidePopupV2 } from "./DismissablePopup";
import { getCSTARScoreBackgroundColorHex } from "../helpers/cstar.helpres";

interface GradeWithScoreCardProps {
  score: number;
  title?: string;
  tooltipText?: string;
  hideLearnMore?: boolean;
  outOf?: number;
  industryAvg?: number;
  tooltipPosition?: PopupPosition;
}

const scoreTooltipText = () =>
  `UpGuard security ratings range from 0 to 950. 
  The higher the rating, the more effective the organization's security practices.`;
const industryAvgTooltipText = `Shows the average security rating across organizations in the same sector, 
providing a benchmark for comparison.`;

const GradeWithScoreCard: FC<GradeWithScoreCardProps> = ({
  score,
  title,
  tooltipText,
  hideLearnMore,
  outOf,
  industryAvg,
  tooltipPosition = "top",
}) => {
  return (
    <div className="grade-with-score-card">
      <div
        style={{
          backgroundColor: getCSTARScoreBackgroundColorHex(score),
        }}
      >
        <SectionTitleWithTooltip
          title={title ?? "security rating"}
          tooltip={
            <>
              {tooltipText ?? scoreTooltipText()}{" "}
              {!hideLearnMore && (
                <a
                  href={
                    "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="learn-more-link"
                >
                  Learn more{" "}
                  <div
                    style={{ display: "inline" }}
                    className="cr-icon-arrow-right"
                  />
                </a>
              )}
            </>
          }
          tooltipPosition={tooltipPosition}
        />
        <div className="score-inner">
          <ColorGrade score={score} />
          <Score score={score} outOf={outOf} colored />
        </div>
      </div>
      {!!industryAvg && (
        <div
          style={{
            borderTop: `1px solid white`,
            backgroundColor: getCSTARScoreBackgroundColorHex(industryAvg),
          }}
        >
          <SectionTitleWithTooltip
            title="Industry Average"
            tooltip={<>{industryAvgTooltipText}</>}
            tooltipPosition={tooltipPosition}
          />
          <div className="score-inner">
            <ColorGrade score={industryAvg} />
            <Score
              className="score-inner-ind-avg"
              score={industryAvg}
              colored
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SectionTitleWithTooltip: FC<{
  title: string;
  tooltip: ReactNode;
  tooltipPosition: PopupPosition;
}> = ({ title, tooltip, tooltipPosition }) => {
  return (
    <>
      <div className="score-subheader">
        {title}
        <SidePopupV2
          text={tooltip}
          position={tooltipPosition}
          noWrap={false}
          popupHoverable
        >
          <span className="cr-icon-info" />
        </SidePopupV2>
      </div>
    </>
  );
};

export default GradeWithScoreCard;

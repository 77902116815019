import Button from "../../../_common/components/core/Button";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import GradeWithScoreCard from "../../../_common/components/GradeWithScoreCard";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import "../../style/ManageSecurityRatingModal.scss";
import useToggle from "../../hooks/useToggle";

interface ManageSecurityRatingModalProps extends BaseModalProps {
  currentScore?: number;
  industryAvg?: number;
  includeRiskRating: boolean;
  includeIndustryAverage: boolean;
  onRatingVisibilityChange: (
    includeRiskRating: boolean,
    includeIndustryAverage: boolean
  ) => void;
}

const ManageSecurityRatingModal = ({
  active,
  onClose,
  currentScore,
  industryAvg,
  includeRiskRating,
  includeIndustryAverage,
  onRatingVisibilityChange,
}: ManageSecurityRatingModalProps) => {
  const [riskRating, toggleRiskRating] = useToggle(includeRiskRating);
  const [industryAverage, toggleIndustryAverage] = useToggle(
    industryAvg !== undefined && includeIndustryAverage
  );

  const noChanges =
    riskRating === includeRiskRating &&
    industryAverage === includeIndustryAverage;

  const onSubmit = () => {
    if (noChanges) {
      // nothing to save
      onClose();
      return;
    } else {
      onRatingVisibilityChange(riskRating, industryAverage);
      onClose();
    }
  };

  return (
    <>
      <ModalV2
        className="manage-security-rating-modal"
        width={800}
        active={active}
        onClose={onClose}
        headerContent="Manage security rating"
        footerContent={
          <div className="footer-right">
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
            <Button primary={true} onClick={onSubmit}>
              Save changes
            </Button>
          </div>
        }
      >
        <div className="manage-security-rating-inner">
          <div className="section controls">
            <div className={"section-header"}>Show security rating</div>
            <div className={"section-content"}>
              <p>
                Display your organization&apos;s security rating on your Trust
                Page to highlight your security posture.
              </p>
              <ColorCheckbox
                checked={riskRating}
                onClick={() => toggleRiskRating()}
                label={"Include your security rating"}
              />

              <ColorCheckbox
                disabled={!riskRating || industryAvg === undefined}
                checked={riskRating && industryAverage}
                onClick={() => toggleIndustryAverage()}
                label={"Include industry average rating"}
              />
            </div>
          </div>
          <div className="section preview">
            <div className={"section-header"}>Preview</div>
            <div className={"section-content preview-content"}>
              {riskRating ? (
                <GradeWithScoreCard
                  score={currentScore!}
                  outOf={950}
                  industryAvg={industryAverage ? industryAvg : undefined}
                />
              ) : (
                <p>No rating will be displayed</p>
              )}
            </div>
          </div>
        </div>
      </ModalV2>
    </>
  );
};

export default ManageSecurityRatingModal;

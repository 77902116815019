import {
  FC,
  Fragment,
  PureComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DropDown from "../../../_common/components/core/DropDown";
import Button from "../../../_common/components/core/Button";
import Icon from "../../../_common/components/core/Icon";
import ColorCheckbox from "../ColorCheckbox";
import {
  cloudscanSeverityNumberToText,
  getCurrentOrgFromUserData,
  NumberWithCommas,
  severityMap,
} from "../../../_common/helpers";
import darkWebIconSrc from "../../../_common/images/dark-web-sev-icon.svg";
import {
  Account,
  Breach,
  BreachDataClass,
  BreachNotificationStatus,
  VIPAccount,
} from "../../../_common/types/emailExposures";
import TabButtons, { tabButton } from "../../../_common/components/TabButtons";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { History } from "history";

import { organisationAccountType } from "../../../_common/types/organisations";
import {
  UserManageIntegrations,
  UserWriteEmailExposures,
} from "../../../_common/permissions";
import {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { find as _find, flatMap as _flatMap, sortBy as _sortBy } from "lodash";
import {
  fetchPagedExposuresAccountData,
  resetPagedExposuresAccountData,
  resetPagedExposuresAccountDataForBreaches,
  sendEmailExposureNotificationToAllNotNotified,
  sendEmailExposureNotificationToSpecificAccounts,
  setArchivedIdentityBreaches,
  setIgnoredEmailAccounts,
  setVipAccounts,
  trackInProgressEmailExposureNotification,
} from "../../reducers/cyberRiskActions";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import {
  emailExposureSeverityNumberToLabelColor,
  IdentityBreachesCard,
} from "./IdentityBreachesCard";
import { LabelColor } from "../../../_common/types/label";
import classnames from "classnames";
import { fetchOrganisationDefaultTexts } from "../../reducers/orgDefaultTexts.actions";
import { closeModal, openModal } from "../../../_common/reducers/commonActions";
import { EmailExposureNotificationModalName } from "../modals/EmailExposureNotificationModal";
import { DefaultTextType } from "../../../_common/types/orgDefaultTexts";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import SearchBox from "../../../_common/components/SearchBox";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import InfoBanner from "../InfoBanner";
import PillLabel from "../PillLabel";
import LabelList from "../LabelList";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import Card from "../../../_common/components/core/Card";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import PaginatedTable from "../PaginatedTable";
import ActionBar from "../../../_common/components/ActionBar";
import { VIPCard } from "./VIPCard";
import "../../style/components/EmailExposures.scss";
import { IUserMini, IUserMiniMap } from "../../../_common/types/user";
import ReportCard from "../../../_common/components/ReportCard";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import UserAssignment from "../../../_common/components/UserAssignment";
import { setAssigneeForEmailExposuresBreach } from "../../reducers/emailExposures.actions";
import BreachHistoryPanel from "./BreachHistoryPanel";
import IconButton from "../../../_common/components/IconButton";
import { NotifyEmployeesButton } from "./NotifyEmployeesButton";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

// page size for server-side paging of exposed accounts
const EMAIL_ACCOUNTS_PAGE_SIZE = 30;
export const MAX_NOTIFICATION_SIZE = 1000;

const breachesMode = "breaches";
const accountsMode = "accounts";
const breachMode = "breach";
const vipMode = "vip";

export type PageMode =
  | typeof breachesMode
  | typeof accountsMode
  | typeof breachMode
  | typeof vipMode;

export const emailExposureSeverityNumberToIconLabel = (severity: number) => {
  const text = cloudscanSeverityNumberToText(severity + 1);
  return <>{severityMap[text].icon}</>;
};

export const getSeverityIconForBreach = (
  riskScore: number,
  isDarkWeb: boolean
) => {
  // Tag RaaS breaches with specific dark web severity icon
  if (isDarkWeb) {
    return (
      <div title="Severity: dark web">
        <>
          <img height={24} src={darkWebIconSrc} alt={"Dark Web"} />
        </>
      </div>
    );
  } else {
    return emailExposureSeverityNumberToIconLabel(riskScore);
  }
};

export const isDarkBreach = (breach: Breach) => {
  return (
    !!breach.HIBEXBreachSourceType && breach.HIBEXBreachSourceType == "raas"
  );
};

interface IEmailExposuresAssigneeCellProps {
  assignedUser?: IUserMini;
  currentUserCanEdit: boolean;
  availableUsers?: IUserMini[];
  onChangeAssignee: (newAssignee: IUserMini | undefined) => Promise<void>;
}

const EmailExposuresAssigneeCell: FC<IEmailExposuresAssigneeCellProps> = ({
  assignedUser,
  currentUserCanEdit,
  availableUsers,
  onChangeAssignee,
}) => {
  const assigneeUsers = useMemo(
    () => (assignedUser ? [assignedUser] : []),
    [assignedUser]
  );
  const onChangeAssignees = useCallback(
    (newAssignees: IUserMini[]) =>
      onChangeAssignee(newAssignees.length ? newAssignees[0] : undefined),
    [onChangeAssignee]
  );

  return (
    <UserAssignment
      assignedUsers={assigneeUsers}
      currentUserCanEdit={currentUserCanEdit}
      onChangeAssignees={onChangeAssignees}
      availableUsers={availableUsers}
    />
  );
};

interface IEmailDomainFilterProps {
  emailDomains: string[];
  selectedDomains: string[];
  onSelectToggle: (domain: string) => void;
}

const EmailDomainFilter: FC<IEmailDomainFilterProps> = ({
  emailDomains,
  selectedDomains,
  onSelectToggle,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const outsideClickListener = (e: MouseEvent) => {
      if (!(e.target as Element).closest(".email-domain-filter")) {
        setOpen(false);
      }
    };

    document.addEventListener("click", outsideClickListener);

    return () => document.removeEventListener("click", outsideClickListener);
  }, []);

  const filterActive = selectedDomains.length > 0;

  const domainText = selectedDomains.length === 1 ? "Domain" : "Domains";

  const filterText = filterActive
    ? `${domainText}: ${selectedDomains.join(", ")}`
    : "Filter by domain";

  if (emailDomains.length < 2) {
    return null;
  }

  return (
    <DropDown
      id="actions-btn"
      custom={"email-domain-filter"}
      action={
        <Button className="actions-button">
          {filterText} <Icon name="chevron" direction={open ? 0 : 180} />
        </Button>
      }
      stopPropagationOnOpen
    >
      {emailDomains.map((d) => {
        return (
          <div
            key={`div${d}`}
            onClick={() => {
              onSelectToggle(d);
            }}
          >
            <ColorCheckbox
              key={d}
              color="blue"
              checked={selectedDomains.indexOf(d) > -1}
              label={d}
            />
          </div>
        );
      })}
    </DropDown>
  );
};

interface ITopLevelFilterProps {
  currentMode: PageMode;
  onModeClick: (mode: PageMode) => void;
}

const TopLevelFilter: FC<ITopLevelFilterProps> = ({
  currentMode,
  onModeClick,
}) => {
  const tabs: tabButton<PageMode>[] = useMemo(
    () => [
      {
        id: breachesMode,
        text: "View by breach",
      },
      {
        id: accountsMode,
        text: "View by email",
      },
      {
        id: vipMode,
        text: "VIP list",
      },
    ],
    []
  );

  return (
    <div className="email-exposures-top-level-filters">
      <TabButtons
        tabs={tabs}
        activeTabId={currentMode}
        onChangeTab={onModeClick}
      />
    </div>
  );
};

export const getBreachDomain = (breach: Breach) => {
  if (breach.Domain && breach.Domain !== "") {
    return breach.Domain;
  }
  if (breach.Title && breach.Title.includes(".")) {
    return breach.Title;
  }
  if (breach.Name && breach.Name.includes(".")) {
    return breach.Name;
  }
  return "";
};

const ExpandableWebAddressList: FC<{ webAddresses?: string[] }> = ({
  webAddresses = [],
}) => {
  const [expanded, setExpanded] = useState(false);

  if (webAddresses.length === 0) {
    return null;
  }

  if (expanded) {
    return (
      <>
        {webAddresses.map((a) => (
          <Fragment key={a}>
            {a}
            <br />
          </Fragment>
        ))}
      </>
    );
  }

  const firstShown = webAddresses.slice(0, 3);
  const nextShown = webAddresses.slice(3);

  return (
    <>
      {firstShown.map((a) => (
        <Fragment key={a}>
          {a}
          <br />
        </Fragment>
      ))}
      {nextShown.length === 1 ? (
        nextShown[0]
      ) : nextShown.length > 0 ? (
        <span className="expand-items" onClick={() => setExpanded(true)}>
          + {nextShown.length} more
        </span>
      ) : null}
    </>
  );
};

interface IEmailExposuresOwnProps {
  history: History;
  mode?: PageMode;
  specificBreachID: number;
}

interface IEmailExposuresConnectedProps {
  breachesLoading?: boolean;
  accountsLoading?: boolean;
  vipAccountsLoading?: boolean;
  breaches?: Breach[];
  breachAssigneeSharedUsers?: IUserMiniMap;
  vipAccounts?: VIPAccount[];
  monitoredDomains?: string[];
  availableAssignees?: IUserMini[];
  prepagedSortedBy?: string;
  prepagedSortedDesc?: boolean;
  prepagedPresortedAccounts?: any;
  notificationStatuses: Record<
    number,
    {
      notifiedAt: string;
      notificationStatus: BreachNotificationStatus;
    }
  >;
  breachAccountsSortedBy: string;
  breachAccountsSortedDesc: boolean;
  userHasWriteEmailExposuresPermission: boolean;
  userHasManageIntegrationsPermissions: boolean;
  currentUserEmail: string;
  isPaidOrg: boolean;
  currentOrgName: string;
}

type IEmailExposuresProps = DefaultThunkDispatchProp &
  IEmailExposuresOwnProps &
  IEmailExposuresConnectedProps;

interface IEmailExposuresState {
  breachesSortedBy: {
    columnId: string;
    direction: SortDirection;
  };
  breachesPageNum: number;
  accountsPageNum: number;
  specificBreachPageNum: number;
  breachesSearchText: string;
  accountsSearchText: string;
  specificBreachSearchText: string;

  // supporting unsubmitted edits
  searchText: string;
  detailsSearchText: string;
  searchTextUnsubmitted: boolean;

  filterDomains: string[];
  archivedBreachFilter: string;
  ignoredAccountFilter: string;

  expandedAccountRows: Record<number, boolean | undefined>;
  selectedAccountIds: Record<number, boolean | undefined>;

  actionBarSubmitting: boolean;
  ignoringAccounts: boolean;
  activatingAccounts: boolean;
  taggingVIPs: boolean;
  untaggingVIPs: boolean;

  archiveBreachSubmitting: boolean;

  notification: {
    actionBarOpen: boolean;
    actionBarSubmitting: boolean;
    selectAllNotNotified: boolean;
    selectedAccountIds: Record<number, boolean | undefined>;
  };

  navigatedFrom?: PageMode;

  breachHistoryPanelOpen: boolean;
}

interface extendedTableRow extends IXTableRow {
  accountId?: number;
}

class EmailExposures extends PureComponent<
  IEmailExposuresProps,
  IEmailExposuresState
> {
  state: IEmailExposuresState = {
    breachesSortedBy: {
      columnId: "published_date",
      direction: SortDirection.DESC,
    },
    breachesPageNum: 0,
    accountsPageNum: 0,
    specificBreachPageNum: 0,
    breachesSearchText: "",
    accountsSearchText: "",
    specificBreachSearchText: "",

    // supporting unsubmitted edits
    searchText: "",
    detailsSearchText: "",
    searchTextUnsubmitted: false,

    filterDomains: [],
    archivedBreachFilter: "active_only",
    ignoredAccountFilter: "active_only",

    expandedAccountRows: {},
    selectedAccountIds: {},

    actionBarSubmitting: false,
    ignoringAccounts: false,
    activatingAccounts: false,
    taggingVIPs: false,
    untaggingVIPs: false,

    archiveBreachSubmitting: false,

    notification: {
      actionBarOpen: false,
      actionBarSubmitting: false,
      selectAllNotNotified: false,
      selectedAccountIds: {},
    },

    breachHistoryPanelOpen: false,
  };

  getCurrentMode = () => {
    let { mode } = this.props;
    if (!mode) {
      mode = breachesMode;
    }
    return mode;
  };

  onModeClick = (mode: PageMode) => {
    if (mode === breachesMode) {
      this.selectBreachesTab();
    } else if (mode === vipMode) {
      this.selectVipTab();
    } else {
      this.selectAccountsTab();
    }
  };

  getArchivedBreachFilterForMode = (mode: PageMode) => {
    switch (mode) {
      case accountsMode:
        return "active_only";
      case breachMode:
        return "all_breaches";
    }
    return "";
  };

  fetchData = (
    breachID: number,
    currentPagedExposuresData: unknown,
    pageNum: number,
    pageSize: number,
    sortBy: string,
    sortDesc: boolean,
    nameFilter: string,
    domainsFilter: string[],
    archivedBreachFilter: string,
    ignoredAccountFilter: string
  ) => {
    this.props.dispatch(
      fetchPagedExposuresAccountData(
        breachID,
        currentPagedExposuresData,
        pageNum,
        pageSize,
        sortBy,
        sortDesc,
        nameFilter,
        domainsFilter,
        archivedBreachFilter,
        ignoredAccountFilter
      )
    );
  };

  onClickBreachRow = (breach: Breach) => {
    this.setState({ navigatedFrom: breachesMode, specificBreachPageNum: 0 });
    this.closeActionBar();
    this.fetchData(
      breach.ID,
      this.props.prepagedPresortedAccounts,
      0,
      EMAIL_ACCOUNTS_PAGE_SIZE,
      this.props.breachAccountsSortedBy,
      this.props.breachAccountsSortedDesc,
      this.state.specificBreachSearchText,
      this.state.filterDomains,
      this.getArchivedBreachFilterForMode(breachMode),
      this.state.ignoredAccountFilter
    );
    const url = `/email_exposures/breach/${breach.ID}`;
    this.props.history.push(url);
  };

  onClickAccountRow = (account: Account) => {
    this.setState((state) => ({
      expandedAccountRows: {
        ...state.expandedAccountRows,
        [account.id]: !state.expandedAccountRows[account.id],
      },
    }));
  };

  onClickAccountBreach = (breach: Breach) => {
    if (!this.state.navigatedFrom) {
      this.setState({ navigatedFrom: accountsMode });
    }
    this.setState({ specificBreachPageNum: 0 });
    this.closeActionBar();
    const sortBy = this.props.breachAccountsSortedBy;
    const sortDesc = this.props.breachAccountsSortedDesc;
    this.fetchData(
      breach.ID,
      this.props.prepagedPresortedAccounts,
      0,
      EMAIL_ACCOUNTS_PAGE_SIZE,
      sortBy,
      sortDesc,
      this.state.specificBreachSearchText,
      this.state.filterDomains,
      this.getArchivedBreachFilterForMode(breachMode),
      this.state.ignoredAccountFilter
    );
    const url = `/email_exposures/breach/${breach.ID}`;
    this.props.history.push(url);
  };

  onPagedAccountsPageChange = (page: number) => {
    const mode = this.getCurrentMode();
    let sortBy = this.props.prepagedSortedBy;
    let sortDesc = this.props.prepagedSortedDesc;
    const searchText = this.state.accountsSearchText;
    if (mode === breachMode) {
      this.setState({ specificBreachPageNum: page - 1 });
      sortBy = this.props.breachAccountsSortedBy;
      sortDesc = this.props.breachAccountsSortedDesc;
    } else {
      this.setState({ accountsPageNum: page - 1 });
    }
    this.fetchData(
      this.props.specificBreachID,
      this.props.prepagedPresortedAccounts,
      page - 1,
      EMAIL_ACCOUNTS_PAGE_SIZE,
      sortBy ?? "",
      sortDesc ?? false,
      searchText,
      this.state.filterDomains,
      this.getArchivedBreachFilterForMode(mode),
      this.state.ignoredAccountFilter
    );
  };

  onPagedAccountsSortChange = (columnId: string, direction: SortDirection) => {
    const mode = this.getCurrentMode();
    let searchText = "";
    if (mode === breachMode) {
      searchText = this.state.detailsSearchText;
      this.setState({ specificBreachPageNum: 0, selectedAccountIds: [] });
    } else {
      searchText = this.state.accountsSearchText;
      this.setState({ accountsPageNum: 0, selectedAccountIds: [] });
    }
    this.props
      .dispatch(resetPagedExposuresAccountData())
      .then(() =>
        this.fetchData(
          this.props.specificBreachID,
          {},
          0,
          EMAIL_ACCOUNTS_PAGE_SIZE,
          columnId,
          direction === "desc",
          searchText,
          this.state.filterDomains,
          this.getArchivedBreachFilterForMode(mode),
          this.state.ignoredAccountFilter
        )
      );
  };

  onIgnoredAccountFilterChange = (filter: string) => {
    this.setState({ ignoredAccountFilter: filter });
    this.closeActionBar();
    this.reloadAccountsData();
  };

  reloadAccountsData = () => {
    const mode = this.getCurrentMode();
    let searchText = this.state.accountsSearchText;
    let sortBy = this.props.prepagedSortedBy;
    let sortDesc = this.props.prepagedSortedDesc;
    if (mode === breachMode) {
      searchText = this.state.specificBreachSearchText;
      sortBy = this.props.breachAccountsSortedBy;
      sortDesc = this.props.breachAccountsSortedDesc;
      this.setState({ specificBreachPageNum: 0 });
    } else {
      this.setState({ accountsPageNum: 0 });
    }

    this.props
      .dispatch(resetPagedExposuresAccountData())
      .then(() =>
        this.fetchData(
          this.props.specificBreachID,
          {},
          0,
          EMAIL_ACCOUNTS_PAGE_SIZE,
          sortBy ?? "",
          sortDesc ?? false,
          searchText,
          this.state.filterDomains,
          this.getArchivedBreachFilterForMode(mode),
          this.state.ignoredAccountFilter
        )
      );
  };

  onSearchTextChange = (val: string) => {
    const mode = this.getCurrentMode();
    if (mode === accountsMode) {
      this.setState(
        {
          searchText: val,
          searchTextUnsubmitted: true,
        },
        this.onSearchTextKeyPress
      );
    } else if (mode === breachMode) {
      this.setState(
        {
          detailsSearchText: val,
          searchTextUnsubmitted: true,
        },
        this.onSearchTextKeyPress
      );
    }
  };

  clearSearchAndFilters = () => {
    this.setState(
      {
        filterDomains: [],
        searchText: "",
        detailsSearchText: "",
        searchTextUnsubmitted: true,
        archivedBreachFilter: "active_only",
        ignoredAccountFilter: "active_only",
        specificBreachSearchText: "",
      },
      this.onSearchTextKeyPress
    );
  };

  onSearchTextKeyPress = () => {
    const mode = this.getCurrentMode();

    if (mode === accountsMode) {
      const sortBy = this.props.prepagedSortedBy;
      const sortDesc = this.props.prepagedSortedDesc;
      this.setState((state) => {
        return {
          accountsPageNum: 0,
          searchTextUnsubmitted: false,
          accountsSearchText: state.searchText,
        };
      });
      this.props
        .dispatch(resetPagedExposuresAccountData(0))
        .then(() =>
          this.fetchData(
            0,
            {},
            0,
            EMAIL_ACCOUNTS_PAGE_SIZE,
            sortBy ?? "",
            sortDesc ?? false,
            this.state.accountsSearchText,
            this.state.filterDomains,
            this.getArchivedBreachFilterForMode(mode),
            this.state.ignoredAccountFilter
          )
        );
    } else if (mode === breachMode) {
      const sortBy = this.props.breachAccountsSortedBy;
      const sortDesc = this.props.breachAccountsSortedDesc;
      this.setState((state) => {
        return {
          specificBreachPageNum: 0,
          searchTextUnsubmitted: false,
          specificBreachSearchText: state.detailsSearchText,
        };
      });
      this.props
        .dispatch(resetPagedExposuresAccountDataForBreaches())
        .then(() =>
          this.fetchData(
            this.props.specificBreachID,
            {},
            0,
            EMAIL_ACCOUNTS_PAGE_SIZE,
            sortBy,
            sortDesc,
            this.state.specificBreachSearchText,
            this.state.filterDomains,
            this.getArchivedBreachFilterForMode(mode),
            this.state.ignoredAccountFilter
          )
        );
    }
  };

  toggleFilterDomain = (domain: string) => {
    this.closeActionBar();
    this.setState(
      ({ accountsPageNum, specificBreachPageNum, filterDomains }) => {
        let sortBy = this.props.prepagedSortedBy;
        let sortDesc = this.props.prepagedSortedDesc;
        const idx = filterDomains.indexOf(domain);
        const mode = this.getCurrentMode();
        let searchText = this.state.accountsSearchText;
        if (mode === breachMode) {
          searchText = this.state.specificBreachSearchText;
          sortBy = this.props.breachAccountsSortedBy;
          sortDesc = this.props.breachAccountsSortedDesc;
          specificBreachPageNum = 0;
        } else {
          accountsPageNum = 0;
        }
        if (idx > -1) {
          const newFilterDomains = [...filterDomains];
          newFilterDomains.splice(idx, 1);
          this.props
            .dispatch(resetPagedExposuresAccountData())
            .then(() =>
              this.fetchData(
                this.props.specificBreachID,
                {},
                0,
                EMAIL_ACCOUNTS_PAGE_SIZE,
                sortBy ?? "",
                sortDesc ?? false,
                searchText,
                newFilterDomains,
                this.getArchivedBreachFilterForMode(mode),
                this.state.ignoredAccountFilter
              )
            );
          return {
            filterDomains: newFilterDomains,
            accountsPageNum,
            specificBreachPageNum,
          };
        }
        const newFilterDomains = [...filterDomains, domain];
        this.props
          .dispatch(resetPagedExposuresAccountData())
          .then(() =>
            this.fetchData(
              this.props.specificBreachID,
              {},
              0,
              EMAIL_ACCOUNTS_PAGE_SIZE,
              sortBy ?? "",
              sortDesc ?? false,
              searchText,
              newFilterDomains,
              this.getArchivedBreachFilterForMode(mode),
              this.state.ignoredAccountFilter
            )
          );

        return {
          filterDomains: newFilterDomains,
          accountsPageNum,
          specificBreachPageNum,
        };
      }
    );
  };

  selectBreachesTab = () => {
    this.closeActionBar();
    this.props.history.replace("/email_exposures/breaches");
  };

  selectVipTab = () => {
    this.closeActionBar();
    this.props.history.replace("/email_exposures/vip");
  };

  selectAccountsTab = () => {
    this.closeActionBar();
    const sortBy = this.props.prepagedSortedBy;
    const sortDesc = this.props.prepagedSortedDesc;
    const searchText = this.state.accountsSearchText;
    const pageNum = this.state.accountsPageNum;
    this.fetchData(
      this.props.specificBreachID,
      this.props.prepagedPresortedAccounts,
      pageNum,
      EMAIL_ACCOUNTS_PAGE_SIZE,
      sortBy ?? "",
      sortDesc ?? false,
      searchText,
      this.state.filterDomains,
      this.getArchivedBreachFilterForMode(accountsMode),
      this.state.ignoredAccountFilter
    );
    this.props.history.replace("/email_exposures/accounts");
  };

  renderSearchBox = () => {
    const searchPlaceHolder = "Search email addresses";
    const mode = this.getCurrentMode();
    let { searchText } = this.state;

    if (mode === breachMode) {
      searchText = this.state.detailsSearchText;
    }
    if (!searchText) {
      searchText = "";
    }

    return (
      <div className="search-row">
        {mode === accountsMode && (
          <TabButtons
            tabs={[
              {
                id: "active_only",
                text: "Active",
              },
              {
                id: "all_accounts",
                text: "All emails",
              },
              {
                id: "ignored_only",
                text: "Ignored",
              },
            ]}
            activeTabId={this.state.ignoredAccountFilter}
            onChangeTab={this.onIgnoredAccountFilterChange}
          />
        )}
        <SearchBox
          key={this.getCurrentMode()}
          placeholder={searchPlaceHolder}
          value={searchText}
          onChanged={this.onSearchTextChange}
        />
        {(mode === breachMode || mode === accountsMode) &&
          this.props.monitoredDomains &&
          this.props.monitoredDomains.length > 1 && (
            <EmailDomainFilter
              emailDomains={this.props.monitoredDomains}
              selectedDomains={this.state.filterDomains}
              onSelectToggle={this.toggleFilterDomain}
            />
          )}
      </div>
    );
  };

  returnFromDetails = () => {
    const { navigatedFrom } = this.state;
    this.setState({
      navigatedFrom: undefined,
      searchText: "",
      detailsSearchText: "",
      filterDomains: [],
      specificBreachSearchText: "",
    });
    const sortBy = this.props.prepagedSortedBy;
    const sortDesc = this.props.prepagedSortedDesc;
    switch (navigatedFrom) {
      case accountsMode:
        this.props.history.replace("/email_exposures/accounts");
        this.fetchData(
          0,
          this.props.prepagedPresortedAccounts,
          this.state.accountsPageNum,
          EMAIL_ACCOUNTS_PAGE_SIZE,
          sortBy ?? "",
          sortDesc ?? false,
          this.state.accountsSearchText,
          this.state.filterDomains,
          this.getArchivedBreachFilterForMode(accountsMode),
          this.state.ignoredAccountFilter
        );
        break;
      case breachesMode:
      default:
        this.props.history.replace("/email_exposures/breaches");
    }
  };

  getActiveBreach = () =>
    this.props.breaches?.find((b) => b.ID === this.props.specificBreachID);

  getNotificationState = (
    breachId: number,
    notifiedAt: string | undefined,
    status: BreachNotificationStatus | undefined
  ) => {
    // check for any active notification statuses
    const notificationStatus = this.props.notificationStatuses[breachId];
    if (notificationStatus) {
      ({ notifiedAt } = notificationStatus);
      status = notificationStatus.notificationStatus;
    }

    switch (status) {
      case "error":
        return {
          icon: (
            <div className="notified-icon not-notified">
              <Icon name="close" />
            </div>
          ),
          label: <span className="no-notification-label">Error</span>,
        };
      case "sending":
        return {
          icon: <LoadingIcon className="notification-sending-icon" />,
          label: "Sending",
        };
      case "sent":
        return {
          icon: null,
          label: <DateTimeFormat dateTime={notifiedAt} dateOnly />,
        };
    }
    return {
      icon: null,
      label: "None sent",
    };
  };

  getPaginatedAccountRows = (
    sortedAccounts: Account[],
    page: number,
    pageSize: number
  ) => {
    const MAX_BREACH_LIST_LEN = 4;
    if (!sortedAccounts) {
      return [];
    }
    sortedAccounts = sortedAccounts.slice(
      page * pageSize,
      page * pageSize + pageSize
    );

    const rows: extendedTableRow[] = [];
    sortedAccounts.forEach((account) => {
      const lastBreachWithDate = _find(account.breaches, "BreachDate");
      const cells = [];
      let { breaches } = account;
      let additional = 0;
      if (breaches && breaches.length > MAX_BREACH_LIST_LEN) {
        additional = breaches.length - MAX_BREACH_LIST_LEN;
        breaches = breaches.slice(0, MAX_BREACH_LIST_LEN);
      }

      if (account.blocked) {
        cells.push(
          <XTableCell key="highest_breach_risk" className="breach-risk">
            <PillLabel
              popupContent={
                "The receiving email server has previously indicated this is an invalid, inactive or blocked email address"
              }
              color={LabelColor.Grey}
            >
              Inactive / Blocked
            </PillLabel>
          </XTableCell>
        );
      } else if (account.ignored) {
        cells.push(
          <XTableCell key="highest_breach_risk" className="breach-risk">
            <PillLabel
              popupContent={"This email address has been marked as ignored"}
              color={LabelColor.Grey}
            >
              Ignored
            </PillLabel>
          </XTableCell>
        );
      } else {
        cells.push(
          <XTableCell key="highest_breach_risk" className="breach-risk">
            {emailExposureSeverityNumberToIconLabel(account.highestBreachRisk)}
          </XTableCell>
        );
      }

      cells.push(
        <XTableCell key="name" className="account-name">
          {account.name}
        </XTableCell>
      );
      cells.push(
        <XTableCell key="domain" className="account-domain">
          @{account.domain}
        </XTableCell>
      );
      if (this.state.ignoredAccountFilter !== "ignored_only") {
        cells.push(
          <XTableCell key="vip" className="account-vip">
            {account.isVip && (
              <PillLabel color={LabelColor.Yellow}>VIP</PillLabel>
            )}
          </XTableCell>
        );
      }
      cells.push(
        <XTableCell key="date_last_breach" className="account-date">
          {lastBreachWithDate ? (
            <DateTimeFormat dateTime={lastBreachWithDate.BreachDate} dateOnly />
          ) : (
            <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>
          )}
        </XTableCell>
      );
      cells.push(
        <XTableCell key="num_breaches" className="breaches-cell">
          <div className="breach-count-row">
            <div className="count-td">
              <span>{account.breaches?.length ?? 0}</span>
            </div>
            <div className="row-left-align">
              {breaches?.map((breach) => {
                return (
                  <div
                    className="spreader"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onClickAccountBreach(breach);
                    }}
                    key={`spread-${breach.ID}`}
                  >
                    <CompanyLogo
                      key={breach.ID}
                      domain={getBreachDomain(breach)}
                      name={breach.Title}
                      displayName={false}
                      hoverShadow={true}
                    />
                  </div>
                );
              })}
              {additional > 0 && (
                <div className="spreader" key={"spread-0"}>
                  <CompanyLogo key="br-0" additional={additional} />
                </div>
              )}
            </div>
          </div>
        </XTableCell>
      );
      cells.push(
        <XTableCell key="expand_row_button" className="expand-row-button">
          <Icon
            name="chevron"
            direction={this.state.expandedAccountRows[account.id] ? 0 : 180}
          />
        </XTableCell>
      );

      rows.push({
        id: account.id,
        accountId: account.id,
        selected: this.state.selectedAccountIds[account.id],
        className: classnames({
          "ignored-account-row": account.ignored || account.blocked,
        }),
        selectionDisabled: account.blocked,
        selectionDisabledHelpText:
          "Inactive or blocked email addresses cannot be marked as active",
        cells,
        onClick: () => this.onClickAccountRow(account),
      });

      if (this.state.expandedAccountRows[account.id]) {
        account.breaches?.forEach((breach) => {
          const breachDescription = this.props.breaches?.find(
            (b) => b.ID === breach.ID
          )?.Description;

          rows.push({
            id: account.id + "-" + breach.ID + "-detail",
            selectionDisabled: true,
            className: "expanded-breach-row",
            cells: [
              <XTableCell
                key="breach_name"
                className="expanded-breach-name"
                colSpan={2}
              >
                <CompanyLogo
                  name={breach.Title}
                  domain={getBreachDomain(breach)}
                />
              </XTableCell>,
              <XTableCell
                key="breach_detail"
                className="expanded-breach-detail"
                colSpan={4}
              >
                <div className="expanded-breach-detail-container">
                  {breachDescription && !breach.PasteURL ? (
                    <div
                      className="expanded-breach-description"
                      dangerouslySetInnerHTML={{
                        __html: breachDescription,
                      }}
                    />
                  ) : (
                    <div className="expanded-breach-description">
                      <a
                        href={breach.PasteURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {breach.PasteURL}
                      </a>
                    </div>
                  )}
                  <div className="expanded-breach-link">
                    <Button onClick={() => this.onClickAccountBreach(breach)}>
                      View breach <div className="cr-icon-arrow-right" />
                    </Button>
                  </div>
                </div>
              </XTableCell>,
              <XTableCell key="empty_cell" className="empty-cell" />,
            ],
          });
        });
      }
    });

    return rows;
  };

  accountIsNotified = (account: Account) =>
    !!account.notifiedBreachIDs &&
    account.notifiedBreachIDs.includes(this.props.specificBreachID);

  getPaginatedAccountRowsForBreach = (
    sortedAccounts: Account[],
    page: number,
    pageSize: number,
    showWebAddressCol?: boolean
  ) => {
    if (!sortedAccounts) {
      return [];
    }
    sortedAccounts = sortedAccounts.slice(
      page * pageSize,
      page * pageSize + pageSize
    );

    const rows: extendedTableRow[] = [];
    sortedAccounts.forEach((account) => {
      const isNotified = this.accountIsNotified(account);

      const cells = [];

      cells.push(
        <XTableCell key="name" className="account-name">
          {account.name}
        </XTableCell>
      );

      cells.push(
        <XTableCell key="domain" className="account-domain">
          @{account.domain}
        </XTableCell>
      );

      if (this.state.ignoredAccountFilter !== "ignored_only") {
        cells.push(
          <XTableCell key="vip" className="account-vip">
            {account.isVip && (
              <PillLabel color={LabelColor.Yellow}>VIP</PillLabel>
            )}
          </XTableCell>
        );
      }

      if (account.blocked) {
        cells.push(
          <XTableCell key="status" className="account-status">
            <PillLabel
              popupContent={
                "The receiving email server has previously indicated this is an invalid, inactive or blocked email address"
              }
              color={LabelColor.Grey}
            >
              Inactive / Blocked
            </PillLabel>
          </XTableCell>
        );
      } else if (account.ignored) {
        cells.push(
          <XTableCell key="status" className="account-status">
            <PillLabel
              popupContent={"This email address has been marked as ignored"}
              color={LabelColor.Grey}
            >
              Ignored
            </PillLabel>
          </XTableCell>
        );
      } else {
        const notificationPill = isNotified ? (
          <PillLabel color={LabelColor.Blue}>Notified</PillLabel>
        ) : (
          <PillLabel color={LabelColor.Red}>Not Notified</PillLabel>
        );

        cells.push(
          <XTableCell key="status" className="account-status">
            {notificationPill}
          </XTableCell>
        );
      }

      if (showWebAddressCol) {
        // Get a list of unique web addresses
        const webAddresses = new Set<string>();

        (account.metadata ?? []).forEach((m) =>
          !!m.webAddress ? webAddresses.add(m.webAddress) : undefined
        );

        cells.push(
          <XTableCell key="web_address" className="web-address-col">
            <ExpandableWebAddressList webAddresses={[...webAddresses]} />
          </XTableCell>
        );
      }

      rows.push({
        id: account.id,
        accountId: account.id,
        selected: this.state.notification.actionBarOpen
          ? this.isAccountSelectedForNotification(account)
          : this.state.selectedAccountIds[account.id],
        className: classnames({
          "ignored-account-row": account.ignored || account.blocked,
        }),
        selectionDisabled: account.blocked,
        selectionDisabledHelpText:
          "Inactive or blocked email addresses cannot be marked as active",
        cells,
      });
    });

    return rows;
  };

  renderPagedAccountsTable = () => {
    const breachID = 0;
    const pageNum = this.state.accountsPageNum;

    const sortedAccounts =
      this.props.prepagedPresortedAccounts?.[breachID]?.[pageNum] ?? [];

    // total rows is breach dependent
    const totalRowNum = this.props.prepagedPresortedAccounts[breachID]
      ? this.props.prepagedPresortedAccounts[breachID].totalNumber
      : 0;

    const rows = this.getPaginatedAccountRows(
      sortedAccounts,
      0,
      EMAIL_ACCOUNTS_PAGE_SIZE
    );
    if (!this.props.accountsLoading && rows.length === 0) {
      if (
        this.state.accountsSearchText !== "" ||
        this.state.filterDomains.length > 0
      ) {
        return (
          <SearchEmptyCard
            searchItemText="email addresses"
            onClear={this.clearSearchAndFilters}
          />
        );
      }

      const emptyText =
        this.state.ignoredAccountFilter === "ignored_only"
          ? "There are no ignored email addresses."
          : "There are no email addresses exposed in active identity breaches.";

      let emptySubText =
        "When we detect one of your email addresses was involved in an identity breach it will appear here.";
      if (this.state.ignoredAccountFilter === "ignored_only") {
        emptySubText = this.props.userHasWriteEmailExposuresPermission
          ? "You have not ignored any email addresses yet. " +
            "Email addresses can be ignored from the ‘Active’ tab."
          : "Ignored email addresses will appear here.";
      }
      return (
        <Card className="paged-table-card">
          <EmptyCardWithAction
            emptyText={emptyText}
            emptySubText={emptySubText}
          />
        </Card>
      );
    }

    const headers: IXTableColumnHeader[] = [
      {
        id: "highest_breach_risk",
        text: "Severity",
        sortable: true,
      },
      {
        id: "name",
        text: "Account",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "domain",
        text: "Domain",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
    ];
    if (this.state.ignoredAccountFilter !== "ignored_only")
      headers.push({
        id: "vip",
        text: "VIP",
        sortable: true,
        startingSortDir: SortDirection.DESC,
      });
    headers.push(
      {
        id: "date_last_breach",
        text: "Last exposed",
        sortable: true,
      },
      {
        id: "num_breaches",
        text: "Active breaches",
        sortable: true,
      },
      { id: "expand_row_button", text: "", sortable: false }
    );

    return (
      <>
        <div className="min-width-table">
          <PaginatedTable
            className={classnames("paged-table-card", "selection-active")}
            rows={rows}
            loading={this.props.accountsLoading}
            sortedBy={{
              columnId: this.props.prepagedSortedBy ?? "",
              direction: this.props.prepagedSortedDesc
                ? SortDirection.DESC
                : SortDirection.ASC,
            }}
            onSortChange={this.onPagedAccountsSortChange}
            columnHeaders={headers}
            stickyColumnHeaders
            page={pageNum}
            pageSize={EMAIL_ACCOUNTS_PAGE_SIZE}
            onPageChange={this.onPagedAccountsPageChange}
            totalRows={totalRowNum}
            prePagedRows={true}
            selectable={this.props.userHasWriteEmailExposuresPermission}
            onSelectClick={this.onSelectAccount}
            onSelectToggle={() => this.onSelectToggleAllAccounts(rows)}
            onSelectAllClick={() => this.onSelectAllAccounts(rows, true)}
            onSelectNoneClick={() => this.onSelectAllAccounts(rows, false)}
          />
        </div>
      </>
    );
  };

  renderPagedAccountsTableForBreach = () => {
    const breachID = this.props.specificBreachID;
    const pageNum = this.state.specificBreachPageNum;

    const sortedAccounts: Account[] = this.props.prepagedPresortedAccounts[
      breachID
    ]
      ? this.props.prepagedPresortedAccounts[breachID][pageNum]
      : [];

    // total rows is breach dependent
    const totalRowNum = this.props.prepagedPresortedAccounts[breachID]
      ? this.props.prepagedPresortedAccounts[breachID].totalNumber
      : 0;

    // Check if we have a web address for any of the breaches.
    // This will generally only be for Infostealer Malware breaches
    const hasWebAddressData =
      (sortedAccounts ?? []).findIndex(
        (acc) => (acc.metadata ?? []).findIndex((m) => !!m.webAddress) > -1
      ) > -1;

    const rows = this.getPaginatedAccountRowsForBreach(
      sortedAccounts,
      0,
      EMAIL_ACCOUNTS_PAGE_SIZE,
      hasWebAddressData
    );

    if (!this.props.accountsLoading && rows.length === 0) {
      if (
        this.state.specificBreachSearchText !== "" ||
        this.state.filterDomains.length > 0
      ) {
        return (
          <SearchEmptyCard
            searchItemText="email addresses"
            onClear={this.clearSearchAndFilters}
          />
        );
      }

      const emptyText =
        this.state.ignoredAccountFilter === "ignored_only"
          ? "There are no ignored email addresses."
          : "There are no email addresses exposed in active identity breaches.";

      let emptySubText =
        "When we detect one of your email addresses was involved in an identity breach it will appear here.";
      if (this.state.ignoredAccountFilter === "ignored_only") {
        emptySubText = this.props.userHasWriteEmailExposuresPermission
          ? "You have not ignored any email addresses yet. " +
            "Email addresses can be ignored from the ‘Active’ tab."
          : "Ignored email addresses will appear here.";
      }
      return (
        <Card className="paged-table-card">
          <EmptyCardWithAction
            emptyText={emptyText}
            emptySubText={emptySubText}
          />
        </Card>
      );
    }

    const headers: IXTableColumnHeader[] = [
      {
        id: "name",
        text: "Account",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "domain",
        text: "Domain",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
    ];
    if (this.state.ignoredAccountFilter !== "ignored_only") {
      headers.push({
        id: "vip",
        text: "VIP",
        sortable: true,
        startingSortDir: SortDirection.DESC,
      });
    }
    headers.push({
      id: "is_notified",
      text: "Status",
      sortable: true,
    });

    if (hasWebAddressData) {
      headers.push({
        id: "web_address",
        text: "Web addresses",
        sortable: false,
        helpText: "Websites this account is registered on, if known.",
      });
    }

    return (
      <>
        <div className="min-width-table">
          <PaginatedTable
            className={classnames("paged-table-card", "selection-active")}
            rows={rows}
            loading={this.props.accountsLoading}
            sortedBy={{
              columnId: this.props.breachAccountsSortedBy,
              direction: this.props.breachAccountsSortedDesc
                ? SortDirection.DESC
                : SortDirection.ASC,
            }}
            onSortChange={this.onPagedAccountsSortChange}
            columnHeaders={headers}
            stickyColumnHeaders
            page={pageNum}
            pageSize={EMAIL_ACCOUNTS_PAGE_SIZE}
            onPageChange={this.onPagedAccountsPageChange}
            totalRows={totalRowNum}
            prePagedRows={true}
            selectable={this.props.userHasWriteEmailExposuresPermission}
            onSelectClick={
              this.state.notification.actionBarOpen
                ? this.onSelectAccountForNotification
                : this.onSelectAccount
            }
            onSelectToggle={() => this.onSelectToggleAllAccounts(rows)}
            onSelectAllClick={() => this.onSelectAllAccounts(rows, true)}
            onSelectNoneClick={() => this.onSelectAllAccounts(rows, false)}
          />
        </div>
      </>
    );
  };

  getSelectedAccounts = () => {
    const selectedAccountIds = this.getSelectedAccountIDs();
    return this.getAllPagedAccounts().filter((a) =>
      selectedAccountIds.includes(a.id)
    );
  };

  getSelectedAccountIDs = () =>
    Object.entries(this.state.selectedAccountIds)
      .filter(([_, v]) => v)
      .map(([id, _]) => parseInt(id));

  getTotalAccountsSelected = () =>
    Object.values(this.state.selectedAccountIds).filter((v) => v).length;

  getNumSelectedActive = (selectedAccounts?: Account[]) =>
    (selectedAccounts ?? this.getSelectedAccounts()).filter((v) => !v.ignored)
      .length;

  getNumSelectedIgnored = (selectedAccounts?: Account[]) =>
    (selectedAccounts ?? this.getSelectedAccounts()).filter((v) => v.ignored)
      .length;

  getNumSelectedVips = (selectedAccounts?: Account[]) =>
    (selectedAccounts ?? this.getSelectedAccounts()).filter((a) => a.isVip)
      .length;

  getNumSelectedNonVips = (selectedAccounts?: Account[]) =>
    (selectedAccounts ?? this.getSelectedAccounts()).filter((a) => !a.isVip)
      .length;

  renderAccountsActionBar = () => {
    const selectedAccounts = this.getSelectedAccounts();
    const totalSelected = this.getTotalAccountsSelected();
    const totalSelectedActive = this.getNumSelectedActive(selectedAccounts);
    const totalSelectedIgnored = this.getNumSelectedIgnored(selectedAccounts);
    const totalVips = this.getNumSelectedVips(selectedAccounts);
    const totalNonVips = this.getNumSelectedNonVips(selectedAccounts);

    const { actionBarOpen: notificationActionBarOpen } =
      this.state.notification;
    let selectionMsg = "Select emails";
    if (this.getCurrentMode() === breachMode) {
      if (totalSelectedActive > 0 && totalSelectedIgnored === 0)
        selectionMsg +=
          ". When ignoring, the selected emails will also be ignored from all other breaches";
      if (totalSelectedIgnored > 0 && totalSelectedActive === 0)
        selectionMsg +=
          ". When marking as active, the selected emails will also be marked as active in all other breaches";
    }

    const breach = this.getActiveBreach();

    return (
      <ActionBar
        active={
          this.props.userHasWriteEmailExposuresPermission &&
          totalSelected > 0 &&
          !notificationActionBarOpen
        }
      >
        <div className="selection-action-bar">
          <div className="action-bar-description">{selectionMsg}</div>
          <div className="action-bar-selection-counter">
            {NumberWithCommas(totalSelected)}
            {totalSelected === 1 ? " email selected" : " emails selected"}
          </div>
          <div className="action-bar-buttons">
            <Button
              tertiary
              disabled={this.state.actionBarSubmitting}
              onClick={this.closeActionBar}
            >
              Cancel
            </Button>
            {this.state.ignoredAccountFilter !== "ignored_only" && (
              <Button
                className="action-bar-submit"
                disabled={
                  this.state.actionBarSubmitting ||
                  totalSelectedIgnored > 0 ||
                  totalSelectedActive === 0
                }
                loading={this.state.ignoringAccounts}
                danger
                onClick={() => this.onSetIgnoredAccounts(true)}
              >
                Ignore emails
              </Button>
            )}
            {this.state.ignoredAccountFilter !== "active_only" && (
              <Button
                className="action-bar-submit"
                disabled={
                  this.state.actionBarSubmitting ||
                  totalSelectedActive > 0 ||
                  totalSelectedIgnored === 0
                }
                loading={this.state.activatingAccounts}
                onClick={() => this.onSetIgnoredAccounts(false)}
              >
                Mark emails active
              </Button>
            )}
            {this.state.ignoredAccountFilter !== "ignored_only" && (
              <>
                <Button
                  className="action-bar-submit"
                  disabled={
                    this.state.actionBarSubmitting ||
                    totalNonVips > 0 ||
                    totalVips === 0 ||
                    totalSelectedIgnored > 0
                  }
                  loading={this.state.untaggingVIPs}
                  onClick={() => this.onSetVips(false)}
                >
                  Untag as VIP
                </Button>
                <Button
                  className="action-bar-submit"
                  disabled={
                    this.state.actionBarSubmitting ||
                    totalVips > 0 ||
                    totalNonVips === 0 ||
                    totalSelectedIgnored > 0
                  }
                  loading={this.state.taggingVIPs}
                  onClick={() => this.onSetVips(true)}
                >
                  Tag as VIP
                </Button>
                {!!breach && (
                  <NotifyEmployeesButton
                    breach={breach}
                    isPaidOrg={this.props.isPaidOrg}
                    actionBarSubmitting={this.state.actionBarSubmitting}
                    selectedAccounts={selectedAccounts}
                    currentOrgName={this.props.currentOrgName}
                    currentUserEmail={this.props.currentUserEmail}
                    onSendNotification={this.onSendNotification}
                  />
                )}
              </>
            )}
          </div>
          <div className="action-bar-close" onClick={this.closeActionBar}>
            <Icon name="x" />
          </div>
        </div>
      </ActionBar>
    );
  };

  isAccountSelectedForNotification = (account: Account) => {
    // check if the account is explicitly enabled or disabled by the user,
    const explicitSelection =
      this.state.notification.selectedAccountIds[account.id];
    if (explicitSelection !== undefined) {
      return explicitSelection;
    }
    // otherwise fallback to the default
    return (
      !this.accountIsNotified(account) &&
      this.state.notification.selectAllNotNotified
    );
  };

  getTotalAccountsSelectedForNotification = () => {
    const allPagedAccounts = this.getAllPagedAccounts();

    if (allPagedAccounts.length === 0) {
      return 0;
    }

    // keep track of invisible accounts which will also be considered selected
    let remainingNotNotifiedAccounts =
      this.props.prepagedPresortedAccounts[this.props.specificBreachID]
        .totalNumberNotNotified;

    let totalSelectedAccounts = 0;

    allPagedAccounts.forEach((account) => {
      if (!this.accountIsNotified(account)) {
        remainingNotNotifiedAccounts -= 1;
      }

      if (this.isAccountSelectedForNotification(account)) {
        totalSelectedAccounts += 1;
      }
    });

    return (
      totalSelectedAccounts +
      (this.state.notification.selectAllNotNotified
        ? remainingNotNotifiedAccounts
        : 0)
    );
  };

  getTotalAccountsNotNotified = () => {
    const pageData =
      this.props.prepagedPresortedAccounts[this.props.specificBreachID];
    if (pageData && pageData.totalNumberNotNotified) {
      return pageData.totalNumberNotNotified;
    }
    return 0;
  };

  onSelectAllNotNofified = () => {
    this.setState((state) => {
      const { selectedAccountIds } = state.notification;

      // first reset any selections for non-notified accounts
      for (const [id, selected] of Object.entries(selectedAccountIds)) {
        if (
          !selected &&
          !this.accountIsNotified(this.getAccountById(parseInt(id))!)
        ) {
          delete selectedAccountIds[parseInt(id)];
        }
      }

      return {
        notification: {
          ...state.notification,
          selectedAccountIds,
          selectAllNotNotified: true,
        },
      };
    });
  };

  renderNotificationActionBar = () => {
    const totalSelected = this.getTotalAccountsSelectedForNotification();
    const totalNotNotified = this.getTotalAccountsNotNotified();

    const { actionBarSubmitting, actionBarOpen } = this.state.notification;
    return (
      <ActionBar active={actionBarOpen}>
        <div className="selection-action-bar">
          <div className="action-bar-description">
            <div className="action-bar-description-main">
              {totalNotNotified < 2
                ? "Please select email addresses to notify"
                : `There are ${NumberWithCommas(
                    totalNotNotified
                  )} email accounts that have not yet been notified`}
              {totalNotNotified > 1 && (
                <Button
                  className="action-bar-select-all"
                  onClick={this.onSelectAllNotNofified}
                >
                  Select all unnotified accounts
                </Button>
              )}
            </div>
          </div>
          <div className="action-bar-selection-counter">
            {NumberWithCommas(totalSelected)}
            {totalSelected === 1 ? " email selected" : " emails selected"}
          </div>
          <div className="action-bar-buttons">
            <Button
              disabled={actionBarSubmitting}
              onClick={this.closeActionBar}
            >
              Cancel
            </Button>
            {totalSelected <= MAX_NOTIFICATION_SIZE && (
              <Button
                className="action-bar-submit"
                disabled={
                  totalSelected === 0 ||
                  totalSelected > MAX_NOTIFICATION_SIZE ||
                  actionBarSubmitting
                }
                loading={actionBarSubmitting}
                onClick={this.onOpenNotificationModal}
                primary
                arrow
              >
                Next
              </Button>
            )}
            {totalSelected > MAX_NOTIFICATION_SIZE && (
              <SidePopupV2
                position={"left"}
                text={
                  `Notifications may only be sent for up to ${NumberWithCommas(
                    MAX_NOTIFICATION_SIZE
                  )} accounts.` +
                  " Please contact UpGuard support to send larger notifications."
                }
              >
                <Button disabled>
                  Next <Icon name="arrow" direction={90} />
                </Button>
              </SidePopupV2>
            )}
          </div>
          <div className="action-bar-close" onClick={this.closeActionBar}>
            <Icon name="x" />
          </div>
        </div>
      </ActionBar>
    );
  };

  onOpenNotificationModal = () => {
    const exampleAccount = _find(this.getAllPagedAccounts(), (a) =>
      this.isAccountSelectedForNotification(a)
    );
    this.props
      .dispatch(fetchOrganisationDefaultTexts())
      .then((defaultTexts) => {
        this.props.dispatch(
          openModal(
            EmailExposureNotificationModalName,
            {
              orgName: this.props.currentOrgName,
              sender: this.props.currentUserEmail,
              breach: this.getActiveBreach(),
              totalRecipients: this.getTotalAccountsSelectedForNotification(),
              recipientDomain: exampleAccount?.domain ?? "",
              recipientName: exampleAccount?.name ?? "",
              onSend: this.onSendNotification,
              defaultSubject:
                defaultTexts[DefaultTextType.IdentityBreachSubject]
                  ?.defaultText,
              defaultMessage:
                defaultTexts[DefaultTextType.IdentityBreachMessage]
                  ?.defaultText,
            },
            true
          )
        );
      });
  };

  onSendNotification = async (
    subject: string,
    message: string,
    specificAccountIDs?: number[]
  ) => {
    try {
      if (this.state.notification.selectAllNotNotified) {
        // first figure out the exceptions to the rule
        const excludeAccountIds = [],
          notifiedAccountIdExceptions = [];
        const { selectedAccountIds } = this.state.notification;

        for (const [id, selected] of Object.entries(selectedAccountIds)) {
          const account = this.getAccountById(parseInt(id))!;
          const accountIsNotified = this.accountIsNotified(account);

          // notified accounts which have been explicitly selected
          if (accountIsNotified && selected === true) {
            notifiedAccountIdExceptions.push(id);
          }
          // unnotified accounts which have been explicitly deselected
          if (!accountIsNotified && selected === false) {
            excludeAccountIds.push(id);
          }
        }

        await this.props.dispatch(
          sendEmailExposureNotificationToAllNotNotified(
            this.props.specificBreachID,
            subject,
            message,
            this.state.specificBreachSearchText,
            this.state.filterDomains,
            this.state.ignoredAccountFilter === "active_only",
            this.state.ignoredAccountFilter === "ignored_only",
            excludeAccountIds,
            notifiedAccountIdExceptions
          )
        );
      } else {
        await this.props.dispatch(
          sendEmailExposureNotificationToSpecificAccounts(
            this.props.specificBreachID,
            subject,
            message,
            specificAccountIDs ??
              this.getAllPagedAccounts()
                .filter((a) => this.isAccountSelectedForNotification(a))
                .map((a) => a.id)
          )
        );
      }
      this.props.dispatch(closeModal());
      this.closeActionBar();
      this.props.dispatch(
        addDefaultSuccessAlert(
          "The email notifications are now being sent and will arrive within a few minutes"
        )
      );
      this.props.dispatch(
        trackInProgressEmailExposureNotification(this.props.specificBreachID)
      );
    } catch (e: any) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred sending the notification",
          [e.message]
        )
      );
    }
  };

  getSelectableAccountRows = (rows: extendedTableRow[]) =>
    rows.filter((r) => r.accountId && !r.selectionDisabled);

  getAllPagedAccounts = () =>
    _flatMap(
      this.props.prepagedPresortedAccounts[this.props.specificBreachID]
    ).filter((a) => typeof a === "object") as Account[];

  getAccountById = (id: number) =>
    _find(this.getAllPagedAccounts(), (a) => a.id == id);

  onSelectAccount = (accountId: string | number, isSelected: boolean) => {
    this.setState((state) => {
      const selectThisAccount =
        isSelected === null
          ? !state.selectedAccountIds[accountId as number]
          : isSelected;

      return {
        selectedAccountIds: {
          ...state.selectedAccountIds,
          [accountId]: selectThisAccount,
        },
      };
    });
  };

  onSelectAccountForNotification = (
    accountId: string | number,
    isSelected: boolean
  ) => {
    const account = this.getAccountById(accountId as number)!;

    const selectThisAccount =
      isSelected === null
        ? !this.isAccountSelectedForNotification(account)
        : isSelected;

    this.setState((state) => ({
      notification: {
        ...state.notification,
        selectedAccountIds: {
          ...state.notification.selectedAccountIds,
          [accountId]: selectThisAccount,
        },
      },
    }));
  };

  onSelectAllAccounts = (rows: extendedTableRow[], selected: boolean) => {
    this.getSelectableAccountRows(rows).forEach((r) => {
      if (this.state.notification.actionBarOpen) {
        this.onSelectAccountForNotification(r.accountId ?? 0, selected);
      } else {
        this.onSelectAccount(r.accountId ?? 0, selected);
      }
    });
  };

  onSelectToggleAllAccounts = (rows: extendedTableRow[]) => {
    // if any rows are not selected, select all, otherwise deselect all
    if (
      _find(this.getSelectableAccountRows(rows), (r) =>
        this.state.notification.actionBarOpen
          ? !this.isAccountSelectedForNotification(
              this.getAccountById(r.accountId ?? 0)!
            )
          : !this.state.selectedAccountIds[r.accountId ?? 0]
      )
    ) {
      this.onSelectAllAccounts(rows, true);
    } else {
      this.onSelectAllAccounts(rows, false);
    }
  };

  closeActionBar = () => {
    this.setState({
      selectedAccountIds: {},
      notification: {
        selectedAccountIds: {},
        actionBarOpen: false,
        actionBarSubmitting: false,
        selectAllNotNotified: false,
      },
    });
  };

  onSetIgnoredAccounts = async (ignored: boolean) => {
    this.setState({
      actionBarSubmitting: true,
      ignoringAccounts: ignored,
      activatingAccounts: !ignored,
    });
    const accountIds = [];
    for (const accountId in this.state.selectedAccountIds) {
      if (this.state.selectedAccountIds[accountId]) {
        accountIds.push(accountId);
      }
    }
    try {
      await this.props.dispatch(setIgnoredEmailAccounts(accountIds, ignored));
      this.props.dispatch(
        addDefaultSuccessAlert(
          "Emails have been " + (ignored ? "ignored" : "marked active")
        )
      );
      this.closeActionBar();
      this.reloadAccountsData();
    } catch (e: any) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred updating the email accounts",
          [e.message]
        )
      );
    }
    this.setState({
      actionBarSubmitting: false,
      ignoringAccounts: false,
      activatingAccounts: false,
    });
  };

  onSetVips = async (vip: boolean) => {
    this.setState({
      actionBarSubmitting: true,
      taggingVIPs: vip,
      untaggingVIPs: !vip,
    });
    const emails = this.getSelectedAccounts().map(
      ({ name, domain }) => `${name}@${domain}`
    );
    try {
      await this.props.dispatch(setVipAccounts(emails, vip));
      this.props.dispatch(
        addDefaultSuccessAlert(
          "Emails have been " + (vip ? "tagged" : "un-tagged") + " as VIP"
        )
      );
      this.closeActionBar();
      this.reloadAccountsData();
    } catch (e: any) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred updating the email accounts",
          [e.message]
        )
      );
    }
    this.setState({
      actionBarSubmitting: false,
      taggingVIPs: false,
      untaggingVIPs: false,
    });
  };

  onSetArchivedBreach = async (breachId: number, archived: boolean) => {
    this.setState({ archiveBreachSubmitting: true });

    try {
      await this.props.dispatch(
        setArchivedIdentityBreaches([breachId], archived)
      );
      this.props.dispatch(
        addDefaultSuccessAlert(
          "Breach has been " + (archived ? "archived" : "marked active")
        )
      );
      this.reloadAccountsData();
    } catch (e: any) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert("An error occurred updating the breach", [
          e.message,
        ])
      );
    }
    this.setState({ archiveBreachSubmitting: false });
  };

  onChangeAssignee = async (newAssignee: IUserMini | undefined) => {
    try {
      await this.props.dispatch(
        setAssigneeForEmailExposuresBreach(
          this.getActiveBreach()?.ID ?? 0,
          newAssignee
        )
      );
    } catch (e) {
      console.error(e);
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred setting the assignee. Please contact UpGuard Support."
        )
      );
      throw e;
    }
  };

  getSortedDataClasses = (dataClasses: BreachDataClass[]) =>
    _sortBy(dataClasses, "Severity", (dc) => -1 * dc.Class.length).reverse();

  render() {
    const mode = this.getCurrentMode();

    const topLevelFilter = (
      <TopLevelFilter
        onModeClick={this.onModeClick}
        currentMode={this.getCurrentMode()}
      />
    );
    let content = null;

    if (mode === breachMode) {
      let backPrompt = "Back to identity breaches list";
      if (this.state.navigatedFrom === accountsMode) {
        backPrompt = "Back to email address list";
      }

      const breach = this.getActiveBreach();

      if (!breach) {
        content = <LoadingBanner />;
      } else {
        const notificationState = this.getNotificationState(
          breach.ID,
          breach.NotifiedAt,
          breach.NotificationStatus
        );

        const anyActionBarOpen =
          this.getTotalAccountsSelected() > 0 ||
          this.state.notification.actionBarOpen;

        content = (
          <>
            <div className="back-from-breach" onClick={this.returnFromDetails}>
              <Icon name="arrow" direction={270} />
              &nbsp;&nbsp;
              {backPrompt}
            </div>
            <ReportCard
              newStyles
              className="exposures-card-container breach-details-card"
            >
              <div className="header">
                <div className="breach-detail-title">
                  <CompanyLogo
                    key={breach.ID}
                    domain={getBreachDomain(breach)}
                    name={breach.Title}
                  />
                </div>
                <div className="header-right">
                  <IconButton
                    className="comments-icon-btn"
                    icon={<div className="cr-icon-chat-messages" />}
                    text={breach.NumComments.toString()}
                    hoverMicro
                    onClick={() =>
                      this.setState({ breachHistoryPanelOpen: true })
                    }
                  />
                  {this.props.userHasWriteEmailExposuresPermission && (
                    <Button
                      className="archive-breach-button"
                      loading={this.state.archiveBreachSubmitting}
                      disabled={
                        this.state.archiveBreachSubmitting ||
                        anyActionBarOpen ||
                        this.props.accountsLoading
                      }
                      onClick={() =>
                        this.onSetArchivedBreach(breach.ID, !breach.Archived)
                      }
                    >
                      <i className="cr-icon-archive" />
                      {breach.Archived
                        ? "Mark breach active"
                        : "Archive breach"}{" "}
                    </Button>
                  )}
                </div>
              </div>

              {isDarkBreach(breach) && (
                <div className="breach-detail-title-row-info-banner">
                  <InfoBanner
                    message={
                      "Posts from dark web actors are presented here for informational purposes. While UpGuard does consider this a legitimate source, it may contain information that has not been confirmed, and may be misleading and/or false."
                    }
                  />
                </div>
              )}

              <div className="breach-detail-info-row">
                <div className="breach-detail-description">
                  {breach.Description && (
                    <>
                      <h4>Breach details</h4>
                      <div
                        className="breach-detail-description-text"
                        dangerouslySetInnerHTML={{
                          __html: breach.Description,
                        }}
                      />
                    </>
                  )}
                  {breach.PasteURL && (
                    <>
                      <h4>Paste details</h4>
                      <div className="breach-detail-description-text">
                        A paste is a data dump containing email addresses, often
                        found on &quot;paste&quot; sites like Pastebin. The
                        presence of an email address in this data doesn&apos;t
                        always mean it&apos;s been compromised in a breach. The
                        process that scans for addresses is entirely autonomous
                        - there&apos;s no human review. The data should be
                        reviewed to assess the impact to the listed accounts, as
                        it may include other data such as passwords or user
                        information.
                      </div>
                      <div className="breach-detail-description-text">
                        <a
                          href={breach.PasteURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {breach.PasteURL}
                        </a>
                      </div>
                      <div className="breach-detail-description-text">
                        <em>
                          Note: paste URLs are often transient and may be
                          deleted soon after they are published
                        </em>
                      </div>
                    </>
                  )}
                  <table>
                    <tbody>
                      <tr>
                        <td>Date of breach:</td>
                        <td>
                          {breach.BreachDate ? (
                            <DateTimeFormat
                              dateTime={breach.BreachDate}
                              dateOnly
                            />
                          ) : (
                            <PillLabel color={LabelColor.Grey}>
                              Unknown
                            </PillLabel>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Date published:</td>
                        <td>
                          <DateTimeFormat
                            dateTime={breach.PublishedDate}
                            dateOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Severity:</td>
                        <td>
                          {getSeverityIconForBreach(
                            breach.RiskScore,
                            isDarkBreach(breach)
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Breach type:</td>
                        <td>{breach.BreachType}</td>
                      </tr>
                      <tr>
                        {breach.PwnCount > 0 ? (
                          <>
                            <td>Total involved:</td>
                            <td>{NumberWithCommas(breach.PwnCount)}</td>
                          </>
                        ) : (
                          <>
                            <td />
                            <td />
                          </>
                        )}
                      </tr>
                      <tr>
                        <td className="top-align">Data exposed:</td>
                        <td
                          className="top-align breach-data-classes"
                          colSpan={3}
                        >
                          {breach.DataClasses && breach.DataClasses.length ? (
                            <LabelList
                              maxWidth={800}
                              labels={this.getSortedDataClasses(
                                breach.DataClasses
                              ).map((dc) => ({
                                id: dc.Class,
                                name: dc.Class,
                                color:
                                  emailExposureSeverityNumberToLabelColor[
                                    dc.Severity
                                  ],
                              }))}
                            />
                          ) : (
                            <PillLabel color={LabelColor.Grey}>
                              Unknown
                            </PillLabel>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="breach-detail-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>Assigned to:</td>
                        <td className="assignee-cell">
                          <EmailExposuresAssigneeCell
                            assignedUser={
                              breach.AssigneeUserID
                                ? this.props.breachAssigneeSharedUsers?.[
                                    breach.AssigneeUserID
                                  ]
                                : undefined
                            }
                            currentUserCanEdit={
                              this.props.userHasWriteEmailExposuresPermission
                            }
                            availableUsers={this.props.availableAssignees}
                            onChangeAssignee={this.onChangeAssignee}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Employees involved:</td>
                        <td>
                          {NumberWithCommas(breach.OrgCount)}{" "}
                          {breach.OrgCount === 1 ? "employee" : "employees"}
                        </td>
                      </tr>
                      <tr>
                        <td>Employees notified:</td>
                        <td>
                          {notificationState.icon} {notificationState.label}
                        </td>
                      </tr>
                      <tr>
                        <td>Breach status:</td>
                        <td className="breach-detail-info-status">
                          {breach.Archived ? (
                            <PillLabel
                              className="archived-pill-label"
                              color={LabelColor.Grey}
                            >
                              Archived
                            </PillLabel>
                          ) : (
                            <PillLabel
                              className="active-pill-label"
                              color={LabelColor.Blue}
                            >
                              Active
                            </PillLabel>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ReportCard>
            <ReportCard newStyles className="exposures-card-container">
              <div className="header">
                Exposed accounts
                {this.props.userHasWriteEmailExposuresPermission && (
                  <div className="header-right">
                    {this.props.isPaidOrg ? (
                      <Button
                        className="notify-employees-button"
                        disabled={
                          this.state.ignoredAccountFilter !== "active_only" ||
                          anyActionBarOpen ||
                          this.props.accountsLoading
                        }
                        onClick={() =>
                          this.setState((state) => ({
                            notification: {
                              ...state.notification,
                              actionBarOpen: true,
                            },
                          }))
                        }
                      >
                        <Icon name="email" /> Notify employees
                      </Button>
                    ) : (
                      <SidePopupV2
                        position={"left"}
                        noWrap
                        text={
                          "This feature is not available for trial accounts"
                        }
                      >
                        <Button disabled>
                          <Icon name="email" /> Notify employees
                        </Button>
                      </SidePopupV2>
                    )}
                  </div>
                )}
              </div>
              <div className="search-row">
                <TabButtons
                  tabs={[
                    { id: "active_only", text: "Active" },
                    { id: "all_accounts", text: "All emails" },
                    { id: "ignored_only", text: "Ignored" },
                  ]}
                  activeTabId={this.state.ignoredAccountFilter}
                  onChangeTab={this.onIgnoredAccountFilterChange}
                />
                <SearchBox
                  placeholder="Search email addresses"
                  value={this.state.detailsSearchText}
                  onChanged={this.onSearchTextChange}
                />
                <EmailDomainFilter
                  emailDomains={this.props.monitoredDomains ?? []}
                  selectedDomains={this.state.filterDomains}
                  onSelectToggle={this.toggleFilterDomain}
                />
              </div>
              {this.renderPagedAccountsTableForBreach()}
            </ReportCard>
            {this.renderAccountsActionBar()}
            {this.renderNotificationActionBar()}
            <BreachHistoryPanel
              breachID={breach.ID}
              onClose={() => this.setState({ breachHistoryPanelOpen: false })}
              active={this.state.breachHistoryPanelOpen}
            />
          </>
        );
      }
    } else if (mode === breachesMode) {
      content = (
        <>
          {topLevelFilter}
          <Card className="exposures-card-container">
            <IdentityBreachesCard
              userHasWriteEmailExposuresPermission={
                this.props.userHasWriteEmailExposuresPermission
              }
              breaches={this.props.breaches || []}
              breachAssigneeSharedUsers={this.props.breachAssigneeSharedUsers}
              isLoading={this.props.breachesLoading ?? false}
              onClickedBreachRow={this.onClickBreachRow}
              dispatch={this.props.dispatch}
              reloadData={this.reloadAccountsData}
              notificationStatuses={this.props.notificationStatuses}
            />
          </Card>
        </>
      );
    } else if (mode === vipMode) {
      content = (
        <>
          {topLevelFilter}
          <Card className="exposures-card-container">
            <VIPCard
              userHasWriteEmailExposuresPermission={
                this.props.userHasWriteEmailExposuresPermission
              }
              userHasManageIntegrationsPermissions={
                this.props.userHasManageIntegrationsPermissions
              }
              accounts={this.props.vipAccounts || []}
              isLoading={this.props.vipAccountsLoading ?? false}
              dispatch={this.props.dispatch}
              reloadData={this.reloadAccountsData}
              history={this.props.history}
            />
          </Card>
        </>
      );
    } else {
      let title = "Exposed email addresses";
      if (
        this.props.prepagedPresortedAccounts[0] &&
        !this.props.accountsLoading
      ) {
        title += ` (${this.props.prepagedPresortedAccounts[0].totalNumber})`;
      }

      content = (
        <>
          {topLevelFilter}
          <Card className="exposures-card-container">
            <div className="card-title-row">
              <div className="card-title">{title}</div>
            </div>
            {this.renderSearchBox()}
            {this.renderPagedAccountsTable()}
            {this.renderAccountsActionBar()}
          </Card>
        </>
      );
    }

    return <div className="email-exposures">{content}</div>;
  }
}

export default appConnect<
  IEmailExposuresConnectedProps,
  never,
  IEmailExposuresOwnProps
>((state, _props) => {
  const currentOrg = getCurrentOrgFromUserData(state.common.userData);

  const newProps: IEmailExposuresConnectedProps = {
    breachesLoading:
      state.cyberRisk.customerData.exposures.accounts_loading ?? false,
    accountsLoading:
      state.cyberRisk.customerData.exposures.accounts_loading ?? false,
    vipAccountsLoading:
      state.cyberRisk.customerData.exposures.accounts_loading ?? false,
    breaches: state.cyberRisk.customerData.exposures.breach_data ?? [],
    breachAssigneeSharedUsers:
      state.cyberRisk.customerData.exposures.breach_assignee_shared_users,
    vipAccounts: state.cyberRisk.customerData.exposures.vip_account_data ?? [],
    monitoredDomains:
      state.cyberRisk.customerData.exposures.monitoredDomains ?? [],
    prepagedSortedBy:
      state.cyberRisk.customerData.exposures.prepagedSortedBy ?? "",
    prepagedSortedDesc:
      state.cyberRisk.customerData.exposures.prepagedSortedDesc ?? false,
    prepagedPresortedAccounts:
      state.cyberRisk.customerData.exposures.prepagedAccountData ?? {},
    breachAccountsSortedBy:
      state.cyberRisk.customerData.exposures.breachAccountsSortedBy,
    breachAccountsSortedDesc:
      state.cyberRisk.customerData.exposures.breachAccountsSortedDesc,
    notificationStatuses:
      state.cyberRisk.customerData.exposures.notificationStatuses,
    availableAssignees:
      state.cyberRisk.customerData.exposures.availableAssignees,
    currentUserEmail: state.common.userData.emailAddress ?? "",
    isPaidOrg: currentOrg?.accountType === organisationAccountType.paid,
    currentOrgName: currentOrg?.name ?? "",
    userHasWriteEmailExposuresPermission:
      state.common.userData.userPermissions.includes(UserWriteEmailExposures),
    userHasManageIntegrationsPermissions:
      state.common.userData.userPermissions.includes(UserManageIntegrations),
  };

  return newProps;
})(EmailExposures);

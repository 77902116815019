import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import { AppScopeUser } from "../../api/types";
import { useDefaultHistory } from "../../../_common/types/router";
import { permissionAppsUrl } from "../../UserBaseAppRouter";
import ScrollableDiv from "../../../vendorrisk/components/ScrollableDiv";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import UserBaseAPI from "../../api/userbase.api";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import "./AppPermissionsUsersPanel.scss";
import { pluralise } from "../../../_common/helpers";
import { CheckItemSlidePanelSection } from "../../../vendorrisk/components/filter/SlidePanelSection";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import SlidePanelKVTable from "../SlidePanelKVTable";
import UserApprovalPill from "../users/UserApprovalPill";
import Score from "../../../vendorrisk/components/Score";
import { getPermissionReadWriteDisplayInformation } from "../../helpers/scopes";
import PillLabel from "../../../vendorrisk/components/PillLabel";

const AppPermissionsUsersPanel: FC<{
  scopeExternalId: string;
  appName: string;
}> = ({ scopeExternalId, appName }) => {
  const history = useDefaultHistory();

  const { data, isLoading } = UserBaseAPI.useGetUserBaseScopeAppUsersV1Query({
    appName: appName,
    scopeExternalId: scopeExternalId,
  });

  const { data: scopeSummary, isLoading: isScopeLoading } =
    UserBaseAPI.useGetUserBaseScopeSummaryV1Query({
      scopeExternalId: scopeExternalId,
    });

  const { displayString: scopeReadWriteToDisplay, color: scopeReadWriteColor } =
    getPermissionReadWriteDisplayInformation(scopeSummary?.scope.isReadWrite);

  const goToUserPage = (user: AppScopeUser) => {
    history.push(`/userbase/user/${user.uuid}/summary`, {
      backContext: {
        backTo: permissionAppsUrl(scopeExternalId),
        backToText: "Back to scope applications",
      },
    });
  };

  const userIntoXTableRow = (u: AppScopeUser): IXTableRow<string> => {
    return {
      id: u.uuid,
      className: "user-row",
      cells: [
        <XTableCell className="user" key="user">
          <div className="user-name">{u.name}</div>
        </XTableCell>,
        <XTableCell className="team" key="team">
          <div className="team-name">{u.teams[0]}</div>
        </XTableCell>,
        <XTableCell className="security-rating" key="security-rating">
          <div className="score-container">
            <ColorGrade score={u?.rating} size={ColorGradeSize.Small} />
            <Score score={u?.rating} small />
          </div>
        </XTableCell>,
        <XTableCell className="approval-status" key="approval-status">
          <UserApprovalPill approved={u.approved} waived={u.waived} />
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => {
            goToUserPage(u);
          },
        },
      ],
    };
  };

  const usersNumberString = isLoading
    ? "Users"
    : `${pluralise(data?.users.length, "User", "Users")} (${data?.users
        .length})`;
  return (
    <ReportCard newStyles className="userbase-app-permissions-users-panel">
      <div className="header">{scopeSummary?.scope.description}</div>
      {/* Consider extracting the section below to a common component */}
      <CheckItemSlidePanelSection
        title={"Permission overview"}
        startExpanded={true}
      >
        {isScopeLoading ? (
          <div className="loading-icon-container">
            <LoadingIcon />
          </div>
        ) : (
          <SlidePanelKVTable
            rows={[
              {
                key: "Exposure Level",
                value: (
                  <div className="exposure-level-container">
                    <ScopeRiskLevelDisplay
                      riskLevel={scopeSummary?.scope.riskLevel}
                    />
                  </div>
                ),
              },
              {
                key: "Scope",
                value: (
                  <div className="scope-description">
                    {scopeSummary?.scope.description}
                  </div>
                ),
              },
              {
                key: "Source",
                value: (
                  <div className="scope-source">{scopeSummary?.scope.name}</div>
                ),
              },
              {
                key: "Category",
                value: (
                  <div className="scope-category">
                    {scopeSummary?.scope.category}
                  </div>
                ),
              },
              {
                key: "Read/Write?",
                value: (
                  <PillLabel color={scopeReadWriteColor}>
                    {scopeReadWriteToDisplay}
                  </PillLabel>
                ),
              },
            ]}
          />
        )}
      </CheckItemSlidePanelSection>

      <div className="content">
        <ScrollableDiv newStyles>
          <CheckItemSlidePanelSection
            title={usersNumberString}
            startExpanded={true}
          >
            <XTable
              className="user-list"
              // sortedBy={sortedBy}
              // onSortChange={onSortChange}
              loading={isLoading}
              columnHeaders={[
                { id: "User", text: "User", sortable: false },
                { id: "Team", text: "Team", sortable: false },
                {
                  id: "Security Rating",
                  text: "Security Rating",
                  sortable: false,
                },
                {
                  id: "Status",
                  text: "Status",
                  sortable: false,
                },
              ]}
              rows={data?.users.map(userIntoXTableRow) ?? []}
              iconOptions={true}
            />
          </CheckItemSlidePanelSection>
        </ScrollableDiv>
      </div>
    </ReportCard>
  );
};

export default AppPermissionsUsersPanel;

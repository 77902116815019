import DropdownV2 from "../../_common/components/core/DropdownV2";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import { ThreatMonitoringFeedType, ThreatResultSortBy } from "../api/types";
import ThreatMonitoringFeedItem from "./ThreatMonitoringFeedItem";
import ThreatMonitoringAPI, {
  GetResultsV1ReqBody,
} from "../api/threatmonitoring.api";
import classnames from "classnames";
import "./ThreatMonitoringFeed.scss";
import TickShieldIMG from "../images/tick-shield.svg";
import Button from "../../_common/components/core/Button";
import ActionBar from "../../_common/components/ActionBar";
import ThreatMonitoringBulkActions from "./ThreatMonitoringBulkActions";
import { getDateOfLastFeedDownload } from "../views/ThreatMonitoringView";
import { useCurrentUser } from "../../_common/selectors/commonSelectors";
import { userHasWriteThreatMonitoring } from "../../_common/permissions";
import { useEffect, useMemo, useState } from "react";
import AddKeywordModal from "./EditKeywordModal";
import { useModalV2 } from "../../_common/components/ModalV2";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice, {
  OPENFEED_PAGESIZE,
  maxDatePeriod,
  selectFeedPageQuery,
} from "../Slice";
import { useXTablePagination } from "../funcs/usePagination";
import FilterPanel, {
  areFiltersDefaultExcludingDate,
  filterAtMaxDatePeriod,
} from "./FilterPanel";
import { FeedFailed } from "./FeedFailed";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import EventTimeSeriesChart from "../components/EventTimeSeriesChart";
import { useNavigateToDetailsWithSession } from "../funcs/useNav";
import { NewThreatsBanner } from "./NewThreatsBanner";
import { Module } from "../Slice";

// possible sort options for the main feed list
export type OpenFeedSortOption = {
  key: string;
  label: string;
  option: ThreatResultSortBy;
  desc: boolean;
};

// possible sort options for the main feed list
export const OpenFeedSortOptions: OpenFeedSortOption[] = [
  {
    key: "newest",
    label: "Newest",
    option: ThreatResultSortBy.Date,
    desc: true,
  },
  {
    key: "oldest",
    label: "Oldest",
    option: ThreatResultSortBy.Date,
    desc: false,
  },
  {
    key: "highest",
    label: "Severity (high to low)",
    option: ThreatResultSortBy.Severity,
    desc: true,
  },
];

interface ThreatMonitoringFeedProps {
  hidden?: boolean;
  feedType: ThreatMonitoringFeedType;
  module: Module;
}

// ThreatMonitoringFeed
// Displays the main feed of threat monitoring results. Results are rendered in a paged, sorted list and allows the
// user to classify, dismiss, or otherwise take action on the identified threats.
// @see ThreatMonitoringView, ThreatMonitoringFeedItem
const ThreatMonitoringFeed = ({
  feedType,
  hidden,
  module,
}: ThreatMonitoringFeedProps) => {
  let feedQuery = useAppSelector(selectFeedPageQuery(module, feedType));

  // add feed up to for Open feed
  feedQuery = { ...feedQuery, feedUpTo: getDateOfLastFeedDownload() };

  const { pageOpts } = feedQuery;

  const dispatch = useAppDispatch();

  const {
    data: feedData,
    isLoading: isFeedDataLoading,
    error: feedError,
    refetch: refetchFeedData,
  } = ThreatMonitoringAPI.useGetResultsV1Query(feedQuery, {
    refetchOnMountOrArgChange: true,
    skip: hidden,
  });
  useEffect(() => {
    if (feedError) {
      console.error(`#### error loading feed: ${JSON.stringify(feedError)}`);
      dispatch(
        addDefaultUnknownErrorAlert("Failed to load threat monitoring feed")
      );
    }
  }, [feedError]);

  const navigateToThreatDetails = useNavigateToDetailsWithSession(
    feedQuery,
    module,
    {
      backToText: "Back to Threat Feed",
    }
  );

  // user details for permission checking
  const userData = useCurrentUser();
  const userHasWritePermission = useMemo(() => {
    return userHasWriteThreatMonitoring(userData.userPermissions);
  }, [userData]);

  // indicates that the sort menu is currently expanded
  const [sortMenuActive, setSortMenuActive] = useState(false);

  // the set of selected feed items from the results list (via the checkbox)
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // setItemSelected
  // allows a threat to be marked as selected / unselected. Multiple concurrent selects are supported.
  const setItemSelected = (uuid: string, selected: boolean) => {
    const without = selectedItems.filter((item) => item !== uuid);
    if (selected) {
      setSelectedItems([...without, uuid]);
    } else {
      setSelectedItems(without);
    }
  };

  const selectedSortOption =
    OpenFeedSortOptions.find(
      ({ option, desc }) =>
        pageOpts.sortBy === option && pageOpts.sortDesc == desc
    ) || OpenFeedSortOptions[0];

  const feed = feedData?.results ?? [];

  // getFeedRows
  // Given the feed results returned from the back end, render the rows for the XTable component.
  const rows: IXTableRow[] = feed.map((result) => {
    return {
      id: result.uuid,
      cells: [
        <XTableCell key={result.uuid}>
          <ThreatMonitoringFeedItem
            module={module}
            result={result}
            active={true}
            onSelect={(selected: boolean) => {
              setItemSelected(result.uuid, selected);
            }}
            selected={selectedItems.includes(result.uuid)}
            userHasWritePermission={userHasWritePermission}
            onViewThreatDetails={() => navigateToThreatDetails(result.uuid)}
          />
        </XTableCell>,
      ],
    };
  });

  const pagination = useXTablePagination({
    feedType: ThreatMonitoringFeedType.Open,
    feedItems: feed,
    totalResults: feedData?.totalResults,
    pageSize: OPENFEED_PAGESIZE,
    module,
  });

  const emptyFeed = !feedError && pagination.totalResults == 0;
  const hasFeed = !feedError && pagination.totalResults > 0;

  return (
    <div className="feed-content">
      <FilterPanel
        filters={feedQuery.filters}
        feedType={feedType}
        hidden={hidden}
        module={module}
      />
      <div className={classnames("threat-monitoring-feed", { hidden: hidden })}>
        {hasFeed && (
          <>
            <EventTimeSeriesChart module={module} />
            <div className="content-separator"></div>
          </>
        )}
        {feedError && (
          <FeedFailed
            message={"Failed to read threat feed."}
            isLoading={isFeedDataLoading}
            refetch={refetchFeedData}
          />
        )}
        {emptyFeed && (
          <EmptyFeed
            module={module}
            feedQuery={feedQuery}
            hidden={hidden}
            refetchFeedData={refetchFeedData}
          />
        )}
        {hasFeed && (
          <>
            {pagination.totalResults > 0 && (
              <div className={"info-row"}>
                <div className={"counts"}>
                  <span>{"Displaying "}</span>
                  <span
                    className={"value"}
                  >{`${pagination.startIdx} – ${pagination.endIdx} `}</span>
                  <span>{"of"}</span>
                  <span
                    className={"value"}
                  >{`${pagination.totalResults}`}</span>
                  <span>{"results"}</span>
                </div>
                <div className={"actions"}>
                  <div>{"Sorted by:"}</div>
                  <DropdownV2
                    onActiveChange={(active) => setSortMenuActive(active)}
                    popupItem={
                      <div className={"selected-option"}>
                        <div>{selectedSortOption.label}</div>
                        <div
                          className={classnames("cr-icon-chevron", {
                            rot270: sortMenuActive,
                            rot90: !sortMenuActive,
                          })}
                        />
                      </div>
                    }
                    forceLeftAlign={true}
                  >
                    {OpenFeedSortOptions.map((option) => {
                      return (
                        <div
                          className={"sort-option"}
                          key={option.label}
                          onClick={() =>
                            dispatch(
                              tmSlice.actions.setSortBy({
                                module,
                                feed: ThreatMonitoringFeedType.Open,
                                sort: option,
                              })
                            )
                          }
                        >
                          <span>{option.label}</span>
                        </div>
                      );
                    })}
                  </DropdownV2>
                </div>
              </div>
            )}
            <NewThreatsBanner
              hidden={hidden}
              refetchFeedData={refetchFeedData}
              module={module}
            />
            <XTable
              loading={isFeedDataLoading && !feedError}
              className={"threat-monitoring-feed-table"}
              columnHeaders={[{ id: "result", text: "" }]}
              numLoadingRows={3}
              selectable={false}
              rows={rows}
              pagination={pagination}
            />
          </>
        )}
      </div>
      <ActionBar active={selectedItems.length > 0 && !hidden}>
        <ThreatMonitoringBulkActions
          module={module}
          feedType={ThreatMonitoringFeedType.Open}
          selectedUUIDs={selectedItems}
          clearSelection={() => setSelectedItems([])}
        />
      </ActionBar>
    </div>
  );
};

interface EmptyFeedProps {
  feedQuery: GetResultsV1ReqBody;
  refetchFeedData: () => void;
  hidden?: boolean;
  module: Module;
}

// emptyFeed
// Renders an empty message, with an appropriate call to action, based on the current state of the feed totals and the
// set of selected filters.
// Note that styles are shared from FeedFailed.scss
function EmptyFeed({
  feedQuery,
  hidden,
  refetchFeedData,
  module,
}: EmptyFeedProps) {
  const dispatch = useAppDispatch();
  const [openAddKeywordModal, addKeywordModal] = useModalV2(AddKeywordModal);

  // addKeyword
  // opens the add keyword modal to allow a new keyword to be created
  const addKeyword = () => {
    openAddKeywordModal({});
  };

  const resetFilters = () => {
    dispatch(
      tmSlice.actions.resetPageFiltersExcludingDateFilter({
        module,
        feed: ThreatMonitoringFeedType.Open,
      })
    );
  };
  const expandDateRange = () => {
    dispatch(
      tmSlice.actions.updatePageFilters({
        module,
        feed: ThreatMonitoringFeedType.Open,
        filters: { datePeriod: maxDatePeriod },
      })
    );
  };

  // strings and action for the default case - date range is max, and no filters are set.
  let message =
    "🎉 Nice work, no threats detected for your monitored keywords.";
  let callToAction = "Add more keywords to uncover additional threats.";
  let buttonText = "Add keyword";
  let buttonIcon = "cr-icon-plus";
  let buttonAction = addKeyword;

  if (
    !areFiltersDefaultExcludingDate(
      feedQuery.filters,
      ThreatMonitoringFeedType.Open,
      module
    )
  ) {
    message = "🎉 All clear! No threats match the current filters.";
    callToAction = "Reset filters to expand your search.";
    buttonText = "Reset filters";
    buttonIcon = "cr-icon-redo";
    buttonAction = resetFilters;
  } else if (!filterAtMaxDatePeriod(feedQuery.filters)) {
    message = "🎉 All clear! No threats within this date range.";
    callToAction = "Expand your date range to view more threats.";
    buttonText = "View last 12 months";
    buttonIcon = "cr-icon-cal";
    buttonAction = expandDateRange;
  }
  return (
    <>
      <NewThreatsBanner
        hidden={hidden}
        refetchFeedData={refetchFeedData}
        module={module}
      />

      <div className={"threat-monitoring-empty-feed"}>
        <img src={TickShieldIMG} width={100} height={100} />
        <div className={"primary"}>{message}</div>
        <div className={"sub"}>{callToAction}</div>
        <Button onClick={buttonAction}>
          <>
            <div className={buttonIcon} />
            {buttonText}
          </>
        </Button>
      </div>
      {addKeywordModal}
    </>
  );
}

export default ThreatMonitoringFeed;

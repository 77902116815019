import ModalV2, {
  BaseModalProps,
} from "../../../../_common/components/ModalV2";
import React, { useState } from "react";
import Button from "../../../../_common/components/core/Button";
import DragDropUpload from "../../../../_common/components/DragDropUpload";
import { addSimpleErrorAlert } from "../../../../_common/reducers/messageAlerts.actions";
import InfoBanner, {
  BannerType,
  SubItemType,
} from "../../../components/InfoBanner";
import { getCyberRiskAuth } from "../../../../_common/session";
import "../../../style/components/automation/WeightsUploadModal.scss";
import { ResponseError } from "../../../../_common/api";
import { Steps } from "../../../../_common/components/StepsWithSections";
import { contactSupport } from "../../../../_common/helpers";
import { IAutomationWeights } from "../../../types/automation";
import { uploadAutomationWeightsExcelFile } from "../../../reducers/questionnaireAutomation.actions";
import { useAppDispatch } from "../../../../_common/types/reduxHooks";

interface WeightsUploadModalProps extends BaseModalProps {
  recipeUUID: string;
  existingWeights?: IAutomationWeights;
  onSuccess: (weights: IAutomationWeights) => void;
  alreadyExported: boolean;
}

const AcceptedDocumentTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xlsx",
];

const SupportArticleURL =
  "https://help.upguard.com/en/articles/8073950-how-to-use-automation-to-apply-tiers-labels-portfolios-and-custom-attributes-to-your-vendors";

type uploadError = "unknown" | "managled" | "wrong_workbook" | "bad_upload";

const WeightsUploadModal: React.FC<WeightsUploadModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const [step, setStep] = useState(props.alreadyExported ? 2 : 1);
  const [error, setError] = useState<uploadError | undefined>(undefined);

  const onDownload = () => {
    const { apiKey, token } = getCyberRiskAuth();
    window.open(
      `/api/survey/automation/weights_xlsx/v1?apikey=${apiKey}&token=${token}&recipe_uuid=${props.recipeUUID}`
    );
    setStep(2);
  };

  const onClose = () => {
    setFile(undefined);
    setStep(1);
    setLoading(false);
    setError(undefined);
    props.onClose();
  };

  const onSubmit = () => {
    if (!file) {
      return;
    }

    setLoading(true);

    dispatch(uploadAutomationWeightsExcelFile(props.recipeUUID, file))
      .then((answers) => props.onSuccess(answers))
      .then(() => setStep(3))
      .catch((e: ResponseError) => {
        setFile(undefined);
        if (e.message.includes("[INCORRECT WORKBOOK]")) {
          setError("wrong_workbook");
        } else if (e.message.includes("[WORKBOOK MANGLED]")) {
          setError("managled");
        } else {
          setError("unknown");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNextButton = () => {
    switch (step) {
      case 1:
        return (
          <Button primary onClick={() => setStep(2)} arrow>
            Continue
          </Button>
        );
      case 2:
        return (
          <Button
            primary
            onClick={onSubmit}
            disabled={!file}
            loading={loading}
            arrow
          >
            Continue
          </Button>
        );
      case 3:
        return (
          <Button
            primary
            onClick={() => {
              onClose();
            }}
          >
            Close & Review
          </Button>
        );
      default:
        return <></>;
    }
  };

  const getBackButton = () => {
    switch (step) {
      case 2:
        return (
          <Button onClick={() => setStep(1)} leftArrow disabled={loading}>
            Previous
          </Button>
        );
      default:
        return <></>;
    }
  };

  const getError = () => {
    if (error == undefined) {
      return <></>;
    }
    let message = "";
    let subItems: React.ReactNode[] = [];

    switch (error) {
      case "bad_upload":
        message = "We were unable to upload your file.";
        subItems = [
          "Make sure you are uploading an .xlsx file. Please try again",
        ];
        break;
      case "wrong_workbook":
        message = "We were unable to upload your file.";
        subItems = [
          " Please ensure you have selected the correct file and try again.",
        ];
        break;
      case "managled":
        message = "We were unable to upload your file.";
        subItems = [
          "The workbook appears to have been modified in a way that has caused an error.",
          "Please ensure you have followed the instructions and only modified the answers column",
        ];
        break;
      case "unknown":
        message = "An unknown error has occurred.";
        subItems = [
          "Please try again",
          <Button key={1} tertiary onClick={contactSupport}>
            Contact support
          </Button>,
        ];
    }

    return (
      <InfoBanner
        type={BannerType.ERROR}
        className={"wrong-file-banner"}
        message={message}
        subItems={subItems}
        subItemType={SubItemType.PARAGRAPH}
      />
    );
  };

  return (
    <ModalV2
      active={props.active}
      onClose={onClose}
      disallowClose={loading}
      className={"weights-upload-modal"}
      headerClassName={"excel-upload-modal-header"}
      headerContent={
        <div className={"excel-upload-header"}>
          <h2 className={"title"}>Import question/answer weights</h2>
        </div>
      }
      footerContent={
        <>
          <div className={"footer-left"}>
            {getBackButton()}
            {step != 3 && (
              <Button tertiary onClick={() => window.open(SupportArticleURL)}>
                View support article
              </Button>
            )}
          </div>
          {getNextButton()}
        </>
      }
    >
      <Steps
        steps={[
          {
            id: "export",
            text: "Export",
            onClick: () => setStep(1),
          },
          {
            id: "import",
            text: "Import",
            onClick: step >= 2 ? () => setStep(2) : undefined,
          },
          {
            id: "review",
            text: "Review",
            onClick: step >= 3 ? () => setStep(3) : undefined,
          },
        ]}
        currentStep={step}
      />
      {step == 1 && (
        <div className={"download-step"}>
          <div className={"info"}>
            Export the weight definitions for this advanced questionnaire
            automation in .XLSX format, add your weight values, and then import
            the completed file. We&apos;ll extract the data from the completed
            file and populate the weight definitions for you.
          </div>
          <Button onClick={onDownload}>
            {"Export "}
            <i className={"cr-icon-export"} />
          </Button>
        </div>
      )}
      {step == 2 && (
        <div className={"upload-step"}>
          <div className={"upload-text"}>
            Upload the weight definitions and we&apos;ll extract and populate
            the weight definitions for you. For best results, make sure you:
            <ul>
              <li>
                Are uploading weights for this questionnaire type and not a
                different questionnaire type.
              </li>
              <li>
                Have followed the instructions on how to make changes to the
                excel file.
              </li>
            </ul>
          </div>
          <DragDropUpload
            onFileSelected={(f) => {
              setFile(f);
              setError(undefined);
            }}
            onFileRejected={() =>
              dispatch(
                addSimpleErrorAlert("File must be .xlsx file under 50 MB")
              )
            }
            loading={loading}
            acceptedFileTypeFilters={AcceptedDocumentTypes}
            selectedFile={file}
            clickText={"Click to upload your automation weight definitions"}
            doNotKeepState
          />
          {getError()}
          {file && !error && (
            <InfoBanner
              type={BannerType.INFO}
              message={
                "Uploading a new file will replace any existing weight definitions in this automation recipe."
              }
            />
          )}
        </div>
      )}
      {step == 3 && (
        <div className={"success-step"}>
          <i className={"cr-icon-check"} />
          <div className={"body"}>
            We were able to extract the weight definitions from your file.
            Please make sure that you review the extracted data (as shown below)
            to make sure they correspond to what you expect.
          </div>
        </div>
      )}
    </ModalV2>
  );
};

export default WeightsUploadModal;

import { ReactNode, useState } from "react";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import ThreatMonitoringAPI, {
  ThreatMonitoringTagTypes,
} from "../api/threatmonitoring.api";
import {
  ThreatMonitoringFeedType,
  ThreatMonitoringHistoryTarget,
  ThreatMonitoringResultStateType,
  ThreatMonitoringResultsFeedType,
} from "../api/types";
import { selectFeedPageQuery } from "../Slice";
import { Module } from "../Slice";

type UseUpdateResultStateProps = {
  sourceFeedType?: ThreatMonitoringResultsFeedType;
  state: ThreatMonitoringResultStateType;
  successMsg: ReactNode | ((uuids: string[]) => ReactNode) | null;
  failMsg: string | ((uuids: string[]) => string);
  finally?: () => void;
  module: Module;
};
// useUpdateResultState provides a hook for updating the state of a result in the context of a feed.
// If sourceFeedType is provided, the source feed will be optimistically updated to remove the mutated result.
// The destination feed is invalidated by the RTKQ query. We don't need to optimistically update the destination feed, since we aren't viewing it immediately.
export default function useUpdateResultState({
  sourceFeedType = ThreatMonitoringFeedType.Open,
  state,
  successMsg,
  failMsg,
  finally: finallyCb,
  module,
}: UseUpdateResultStateProps) {
  const dispatch = useAppDispatch();
  const [updateResultState] =
    ThreatMonitoringAPI.useUpdateResultStateSimpleV1Mutation();
  const feedQuery = useAppSelector(
    selectFeedPageQuery(module, sourceFeedType || ThreatMonitoringFeedType.Open)
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const update = async (
    uuidParam: string | string[],
    opts?: { target?: ThreatMonitoringHistoryTarget }
  ) => {
    const uuids = Array.isArray(uuidParam) ? uuidParam : [uuidParam];

    if (uuids.length === 0) {
      return;
    }

    try {
      setIsUpdating(true);
      await updateResultState({ uuids, state, target: opts?.target });

      if (sourceFeedType) {
        dispatch(
          ThreatMonitoringAPI.util.updateQueryData(
            "getResultsV1",
            feedQuery,
            (draft) => {
              draft.results = draft.results.filter(
                (result) => !uuids.includes(result.uuid)
              );
            }
          )
        );
      }

      if (successMsg === null) {
        // If successMsg is explicitly null, don't show a success message.
      } else if (typeof successMsg === "function") {
        dispatch(addDefaultSuccessAlert(successMsg(uuids)));
      } else {
        dispatch(addDefaultSuccessAlert(successMsg));
      }

      dispatch(
        ThreatMonitoringAPI.util.invalidateTags([
          {
            type: ThreatMonitoringTagTypes.results,
            id: sourceFeedType,
          },
        ])
      );
    } catch (error) {
      console.error("Error updating result state", error);

      if (typeof failMsg === "function") {
        dispatch(addDefaultUnknownErrorAlert(failMsg(uuids)));
      } else {
        dispatch(addDefaultUnknownErrorAlert(failMsg));
      }
    } finally {
      setIsUpdating(false);
      finallyCb?.();
    }
  };

  return [update, isUpdating] as const;
}

import { FC, useCallback } from "react";
import classnames from "classnames";
import "../../style/components/navigation/ProductSpecificNavigation.scss";
import { product } from "../../types/products";
import VendorRiskNavItems from "./VendorRiskNavItems";
import BreachSightNavItems from "./BreachSightNavItems";
import TrustExchangeNavItems from "./TrustExchangeNavItems";
import UserBaseNavItems from "../../../userbase/UserBaseNavItems";
import { trackEvent } from "../../tracking";
import AnalystPortalNavItems from "./AnalystPortalNavItems";

interface ProductSpecificNavigationProps {
  open: boolean;
  selectedProduct?: product;
  openContactUsModal?: () => void;
}

const ProductSpecificNavigation: FC<ProductSpecificNavigationProps> = (
  props
) => {
  const clickNavLinkFunc = useCallback(
    (pathTo: any, linkName: any, viewingVendorId: any) => () => {
      // then track an event
      const properties: Record<string, any> = { pathTo, linkName };
      if (viewingVendorId) {
        properties.viewingVendorId = viewingVendorId;
      }

      trackEvent("navigation menu item clicked", properties);
    },
    []
  );

  return (
    <div
      className={classnames("product-specific-navigation", {
        open: props.open,
      })}
    >
      {props.open && props.selectedProduct === "breachsight" && (
        <BreachSightNavItems clickNavLinkFunc={clickNavLinkFunc} />
      )}
      {props.open && props.selectedProduct === "vendor_risk" && (
        <VendorRiskNavItems
          clickNavLinkFunc={clickNavLinkFunc}
          openContactUsModal={props.openContactUsModal}
        />
      )}
      {props.open && props.selectedProduct === "trust_exchange" && (
        <TrustExchangeNavItems clickNavLinkFunc={clickNavLinkFunc} />
      )}
      {props.open && props.selectedProduct === "user_risk" && (
        <UserBaseNavItems clickNavLinkFunc={clickNavLinkFunc} />
      )}
      {props.open && props.selectedProduct === "analyst_portal" && (
        <AnalystPortalNavItems clickNavLinkFunc={clickNavLinkFunc} />
      )}
    </div>
  );
};

export default ProductSpecificNavigation;

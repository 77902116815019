import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  chunk as _chunk,
  orderBy as _orderBy,
  debounce as _debounce,
} from "lodash";
import {
  ISortedBy,
  IXTableColumnHeader,
  IXTableRow,
  OnSortChange,
  SortDirection,
} from "./components/core/XTable";
import { useHistory } from "react-router-dom";
import { getVendorWords } from "./constants";
import { getLocalStorageItem, setLocalStorageItem } from "./session";
import {
  useConfirmationModalV2,
  useConfirmationModalV2Props,
} from "./components/modals/ConfirmationModalV2";
import { Location, Action, UnregisterCallback } from "history";
import { useAppSelector } from "./types/reduxHooks";
import { AssuranceType, organisationAccountType } from "./types/organisations";
import { useCurrentOrg } from "./selectors/commonSelectors";
import { usePrevious } from "../vendorrisk/helpers/util";

const pageRestorationKey = (k: string) => `page-restoration-${k}`;
const sortRestorationKey = (k: string) => `sort-restoration-${k}`;

// usePagination is a hook for implementing client-side pagination for tables.
// It simply takes your full array of items and splits them into pages, returning
// the current page, page number, total pages and a method for setting the current page.
//
// if key is specified the hook will save and restore the current page
// by using sessionStorage with the provided key
// TODO: we can make this the default behaviour and use the URL path as
//       default key directly in usePagination once this stabilises
export const usePagination = <T>(
  items: T[],
  pageSize: number,
  initialPage?: number,
  key?: string
): [T[], number, number, (page: number) => void] => {
  if (key) {
    const existingPageJSON = sessionStorage.getItem(pageRestorationKey(key));
    if (existingPageJSON) {
      initialPage = JSON.parse(existingPageJSON) as number;
    }
  }

  const [currentPageNumber, setCurrentPage] = useState(initialPage ?? 1);

  const pages = _chunk(items, pageSize);
  const totalPages = Math.max(pages.length, 1);
  let actualCurrentPageNumber = currentPageNumber;
  if (actualCurrentPageNumber > totalPages) {
    actualCurrentPageNumber = totalPages;
  }

  let currentPage: T[] = [];
  if (pages.length > 0) {
    currentPage = pages[actualCurrentPageNumber - 1];
  }

  useEffect(() => {
    if (key) {
      sessionStorage.setItem(
        pageRestorationKey(key),
        JSON.stringify(actualCurrentPageNumber)
      );
    }
  }, [key, actualCurrentPageNumber]);

  const onSetCurrentPage = useCallback(
    (newPageNumber: number) => {
      setCurrentPage(newPageNumber);
      if (key) {
        sessionStorage.setItem(
          pageRestorationKey(key),
          JSON.stringify(newPageNumber)
        );
      }
    },
    [key]
  );

  return [currentPage, actualCurrentPageNumber, totalPages, onSetCurrentPage];
};

export interface columnSortDef<ItemType> {
  // Array of functions to be passed in to orderBy. The first will be the primary sort order,
  // then secondary, tertiary etc.
  orderFuncs: ((val: ItemType) => unknown)[];
  // The sort directions for each column if overall sorting is ascending.
  sortDirsAsc?: ("asc" | "desc")[];
  // The sort directions for each column if overall sorting is descending.
  sortDirsDesc?: ("asc" | "desc")[];
}

// useSorting is a hook for implementing sorting on table rows. It takes your list of items,
// the initial sorting, and definitions for how to sort each sortable column. The ColumnId can be
// optionally typed by using a union of strings, eg ("column1" | "column2").
//
// if key is specified the hook will save and restore the current sorting column and direction
// by using sessionStorage with the provided key
// TODO: we can make this the default behaviour and use the URL path as
//       default key directly in useSorting once this stabilises
export const useSorting = <ItemType, ColumnId extends string = string>(
  items: ItemType[],
  startingSortCol: ColumnId,
  startingSortDir: SortDirection,
  sortDefs: Record<ColumnId, columnSortDef<ItemType>>,
  key?: string
): [ItemType[], ISortedBy, OnSortChange] => {
  if (key) {
    const existingSortJSON = sessionStorage.getItem(sortRestorationKey(key));
    if (existingSortJSON) {
      startingSortCol = JSON.parse(existingSortJSON).sortCol ?? startingSortCol;
      startingSortDir = JSON.parse(existingSortJSON).sortDir ?? startingSortDir;
    }
  }

  const [sortedBy, setSortedBy] = useState({
    columnId: startingSortCol,
    direction: startingSortDir,
  });
  const onSortChange = useCallback(
    (columnId: string, direction: SortDirection) => {
      setSortedBy({ columnId: columnId as ColumnId, direction });
      if (key) {
        sessionStorage.setItem(
          sortRestorationKey(key),
          JSON.stringify({ sortCol: columnId, sortDir: direction })
        );
      }
    },
    [key]
  );

  const sortedItems = useMemo(() => {
    const sortDef = sortDefs[sortedBy.columnId];
    return sortDef
      ? _orderBy(
          items,
          sortDef.orderFuncs,
          sortedBy.direction === SortDirection.DESC
            ? sortDef.sortDirsDesc ?? SortDirection.DESC
            : sortDef.sortDirsAsc ?? SortDirection.ASC
        )
      : items;
  }, [items, sortDefs, sortedBy.columnId, sortedBy.direction]);

  return [sortedItems, sortedBy, onSortChange];
};

// useSortingWithPagination combines the useSorting and usePagination hooks.
// Specifically it manages resetting the current page to 1 on changes to sort
// direction and sort column which would otherwise need to be done manually.
export const useSortingWithPagination = <
  ItemType,
  ColumnId extends string = string,
>(
  items: ItemType[],
  startingSortCol: ColumnId,
  startingSortDir: SortDirection,
  sortDefs: Record<ColumnId, columnSortDef<ItemType>>,
  pageSize: number,
  initialPage?: number,
  key?: string
): [
  ItemType[],
  ISortedBy,
  OnSortChange,
  number,
  number,
  (page: number) => void,
] => {
  const [sortedItems, sortedBy, onSortChange] = useSorting(
    items,
    startingSortCol,
    startingSortDir,
    sortDefs,
    key
  );

  const [currentPage, currentPageNumber, totalPages, setCurrentPage] =
    usePagination(sortedItems, pageSize, initialPage, key);

  const previousSortedBy = usePrevious(sortedBy);

  useEffect(() => {
    if (
      previousSortedBy.columnId !== sortedBy.columnId ||
      previousSortedBy.direction !== sortedBy.direction
    ) {
      setCurrentPage(1);
    }
  }, [sortedBy.columnId, sortedBy.direction]);

  return [
    currentPage,
    sortedBy,
    onSortChange,
    currentPageNumber,
    totalPages,
    setCurrentPage,
  ];
};

// useColumnSelector is a hook for implementing selectable columns on a table
// it takes a list of display names and ids and returns the currently selected columns
// as well as functions for selecting and resetting column selection and filtering the cells
// cell filtering relies on the column and cell arrays being the same length and order which is generally the case anyway
export const useColumnSelector = (columns: IXTableColumnHeader[]) => {
  const [selected, setSelected] = useState(
    columns
      .filter((c) => !c.noDefaultDisplay || c.displayLocked)
      .map((c) => c.id)
  );

  const onReset = () =>
    setSelected(
      columns
        .filter((c) => c.displayLocked || !c.noDefaultDisplay)
        .map((c) => c.id)
    );

  const onSelect = (id: string, selected: boolean) => {
    // first find the column that we are looking for and check if it's locked
    const column = columns.find((c) => c.id == id);
    if (column?.displayLocked) {
      return;
    }
    setSelected((prevState) => {
      const newState = [...prevState];
      const idx = newState.indexOf(id);
      if (!selected && idx !== -1) {
        newState.splice(idx, 1);
      } else if (selected && idx === -1) {
        newState.push(id);
      }
      return newState;
    });
  };

  const filterCells = (cells: React.ReactNode[]): React.ReactNode[] =>
    cells.filter((_, i) => selected.includes(columns[i].id));

  const selectedColumns = columns.filter((c) => selected.includes(c.id));

  return [selectedColumns, selected, onSelect, onReset, filterCells] as const;
};

export const useSelectableArray = <ItemType>(
  init?: ItemType[]
): [
  ItemType[],
  (item: ItemType, selected: boolean) => void,
  (items: ItemType[]) => void,
] => {
  const [state, setState] = useState<ItemType[]>(init || []);

  const setItem = useCallback(
    (item: ItemType, selected: boolean) =>
      setState((prevState) => {
        const newState = [...prevState];
        const idx = newState.indexOf(item);
        if (!selected && idx !== -1) {
          newState.splice(idx, 1);
        } else if (selected && idx === -1) {
          newState.push(item);
        }
        return newState;
      }),
    []
  );

  const setArray = useCallback((items: ItemType[]) => setState(items), []);

  return [state, setItem, setArray];
};

export const useSelectionSet = <ItemType>(
  init?: Set<ItemType>
): [
  selected: Set<ItemType>,
  setItemSelected: (i: ItemType, selected?: boolean) => void,
  setItems: (items: Set<ItemType>) => void,
  reset: () => void,
] => {
  const [selected, setSelectedSet] = useState(init || new Set<ItemType>());

  const setItemSelected = useCallback(
    (item: ItemType, selected?: boolean) =>
      setSelectedSet((prevState) => {
        const newState = new Set(prevState);
        // toggle
        if (selected === undefined) {
          if (newState.has(item)) {
            newState.delete(item);
          } else {
            newState.add(item);
          }
        } else if (!selected) {
          newState.delete(item);
        } else {
          newState.add(item);
        }
        return newState;
      }),
    []
  );

  const reset = useCallback(
    () => setSelectedSet(init || new Set<ItemType>()),
    [init]
  );

  return [selected, setItemSelected, setSelectedSet, reset];
};

// useSelectable is a hook for managing a single selectable item from a list of items.
// If the list of items changes, it will attempt to reselect the same item using findBy.
export const useSelectable = <ItemType>(
  findBy: (
    newItems: ItemType[],
    existingItem: ItemType
  ) => ItemType | undefined,
  items: ItemType[]
): [ItemType | undefined, (i: ItemType | undefined) => void] => {
  const [selected, setSelected] = useState<ItemType | undefined>(undefined);

  useEffect(() => {
    if (selected !== undefined) {
      setSelected(findBy(items, selected));
    }
  }, [items]);

  return [selected, setSelected];
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

// This hook ensures we keep the location state up to date with specified properties before moving on to another page.
// Add a block on the history object that will run when a history.push occurs.
// We replace the state on the current item in the history stack before continuing.
export const useLocationStateUpdater = <
  LocationState extends Record<string, unknown>,
>(
  itemsToStoreInState: LocationState
) => {
  const history = useHistory<LocationState>();
  useEffect(() => {
    const unblock = history.block((_location, action) => {
      if (action === "PUSH") {
        // Replace the current location state in the stack before we move to the next page
        history.replace({
          state: itemsToStoreInState,
          search: history.location.search,
        });
      }
    });
    return unblock;
  }, [
    history,
    ...Object.values(itemsToStoreInState), // We only care when the values of the state object change, not when the object reference itself changes
  ]);
};

export const useAssuranceType = () =>
  useAppSelector((state) => {
    return state.common.userData.assuranceType;
  });

export const useIsFreeOrganisation = () => {
  const accountType = useCurrentOrg()?.accountType;

  if (!accountType) return true;

  return accountType === organisationAccountType.free;
};

export const useIsExpiredTrial = () => {
  const currentOrg = useCurrentOrg();

  if (currentOrg === undefined) {
    return false;
  }

  const isTrial = currentOrg.accountType == organisationAccountType.trial;

  if (!isTrial) {
    return false;
  }

  const trialExpiresOn = currentOrg.trialExpiresOn;

  if (trialExpiresOn === undefined) {
    return false;
  }

  return new Date(trialExpiresOn) < new Date();
};

export const useVendorWords = () =>
  getVendorWords(
    useAppSelector((state) => {
      return state.common.userData?.assuranceType ?? AssuranceType.None;
    })
  );

export const useVendorURLPrefix = (vendorId: number) => {
  const mOrgId = useManagedOrgID();

  if (mOrgId) {
    return `/analysts/tpvm/${mOrgId}/${vendorId}`;
  } else {
    return `/vendor/${vendorId}`;
  }
};

// useLocalStorageState should be used in a similar way to useState, but it will
// also retrieve/update the value in localStorage.
export const useLocalStorageState = <T>(
  storageKey: string,
  initialState?: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [val, setVal] = useState<T>(() => {
    const stateVal = getLocalStorageItem(storageKey, true);
    if (typeof stateVal === "undefined") {
      return initialState;
    }

    return stateVal;
  });

  useEffect(() => {
    // Whenever the value changes, update the local storage item
    setLocalStorageItem(storageKey, val);
  }, [val]);

  return [val, setVal];
};

// useStopPropagation wraps a mouse event handler with calling stopPropagation
// to stop the event from bubbling.
export const useStopPropagation = (f: (e: MouseEvent) => void) => {
  return useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      f(e);
    },
    [f]
  );
};

// useBlockRouterWithConfirmationModal wraps history.block in order to block routing with
// a confirmation modal. The shouldBlock func runs every time the router location will change,
// and will block with the specified confirmationModalProps if it returns true.
export const useBlockRouterWithConfirmationModal = (
  shouldBlock: (location: Location, action: Action) => boolean,
  confirmationModalProps: Omit<useConfirmationModalV2Props, "buttonAction">
) => {
  const history = useHistory();
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  // We're keeping the unblockFunc in a ref so that if the effect runs whilst the confirmation modal is open,
  // it will still be able to unblock the currently active router block.
  const unblockFunc = useRef<UnregisterCallback | undefined>(undefined);

  useEffect(() => {
    unblockFunc.current = history.block((location, action) => {
      if (shouldBlock(location, action)) {
        openConfirmationModal({
          ...confirmationModalProps,
          buttonAction: () => {
            // Unblock the router and continue navigation
            unblockFunc.current?.();

            if (action === "POP") {
              history.goBack();
            } else if (action === "REPLACE") {
              history.replace(location);
            } else {
              history.push(location);
            }
          },
        });

        return false;
      }

      return undefined;
    });

    return () => {
      unblockFunc.current?.();
    };
  }, [shouldBlock, history, confirmationModalProps, openConfirmationModal]);

  return confirmationModal;
};

// Inspired by usehooks: https://usehooks.com/useclickaway
export function useClickAway(cb: () => void): React.Ref<any> {
  const ref = useRef<any>(null);
  const refCb = useRef(cb);

  useEffect(() => {
    const handler = (e: UIEvent) => {
      const element = ref.current;
      if (element && !element.contains(e.target)) {
        refCb.current();
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, []);

  return ref;
}

export const useSelectableRows = <RowID extends string | number>(
  selectedIDs: Set<RowID>,
  setSelectedIDs: Dispatch<SetStateAction<Set<RowID>>>,
  rows: IXTableRow<RowID>[]
) => {
  const onSelectClick = useCallback(
    (rowID: RowID, selected: boolean) =>
      setSelectedIDs((selectedIDs) => {
        const newSet = new Set(selectedIDs);
        if (selected) {
          newSet.add(rowID);
        } else {
          newSet.delete(rowID);
        }
        return newSet;
      }),
    [setSelectedIDs]
  );

  const onSelectAllClick = useCallback(() => {
    setSelectedIDs(
      new Set([
        ...rows.filter((r) => !r.selectionDisabled).map((row) => row.id),
      ])
    );
  }, [setSelectedIDs, rows]);

  const onSelectNoneClick = useCallback(() => setSelectedIDs(new Set()), []);

  const onSelectToggle = useCallback(() => {
    if (selectedIDs.size === rows.length) {
      onSelectNoneClick();
    } else {
      onSelectAllClick();
    }
  }, [selectedIDs, rows, onSelectAllClick, onSelectNoneClick]);

  return {
    onSelectClick,
    onSelectAllClick,
    onSelectToggle,
    onSelectNoneClick,
  };
};

// useDebouncedText
// for use in a search field where we want to update the display text immediately
// but not update the text used for a search request until after a debouce to avoid too many requests
export const useDebouncedText = (
  initialValue: string = "",
  debounceTime: number = 500
) => {
  const [displayText, setDisplayText] = useState(initialValue);
  const [filterText, setFilterText] = useState(initialValue);

  const debouncedSetFilterText = useCallback(
    _debounce((val) => setFilterText(val), debounceTime),
    []
  );

  const setText = useCallback((val: string) => {
    setDisplayText(val);
    debouncedSetFilterText(val);
  }, []);

  return [displayText, filterText, setText] as const;
};

// useManagedOrgID
// select the managed org id from the tpvm session
// return undefined if not available
export const useManagedOrgID = () =>
  useAppSelector((state) =>
    state.common.tpvmSession?.tpvm_o
      ? parseInt(state.common.tpvmSession.tpvm_o)
      : undefined
  );

// useManagedVendorID - select the managed vendor id from the tpvm session
// returns undefined if no active TPVM session
export const useManagedVendorID = () =>
  useAppSelector((state) =>
    state.common.tpvmSession?.tpvm_v
      ? parseInt(state.common.tpvmSession.tpvm_v)
      : undefined
  );

let localeCompareCache:
  | {
      caseInsensitiveCompare: (x: string, y: string) => number;
    }
  | undefined = undefined;

// useLocaleCompare returns a set of function that can be used to sort strings
// e.g. the caseInsensitiveCompare can be used to sort string based on the locale not based on sensitivity
export const useLocaleCompare = () => {
  if (localeCompareCache) {
    return localeCompareCache;
  }
  const locale = navigator.language;
  localeCompareCache = {
    caseInsensitiveCompare: new Intl.Collator(locale, {
      // use accent instead of base as we want to preserve the
      // behaviour of `localeCompare` which considers accents different
      sensitivity: "accent",
    }).compare,
  };
  return localeCompareCache;
};

// useDebounce creates a debounced func that can be safely used with internal state
export const useDebounce = (
  callback: (...args: any) => any,
  debounceTime: number,
  deps: unknown[] = []
) => {
  // Keep a ref of the latest callback reference
  const ref = useRef(callback);

  ref.current = useCallback(callback, [...deps, callback]);

  return useMemo(() => {
    // Then in the func executed on timer call the latest callback ref with the args
    const f: (...args: any) => any = (...arg) => {
      ref.current?.(...arg);
    };

    return _debounce(f, debounceTime);
  }, []);
};

// useEnv returns the value of the ENV variable set in the window object
export function useEnv(): string | undefined {
  const [env, setEnv] = useState<string | undefined>(undefined);

  useEffect(() => {
    setEnv(window.ENV);
  }, []);

  return env;
}

export const useSessionStorage = <T>(
  key: string,
  initialValue: T
): [T, (t: T) => void] => {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

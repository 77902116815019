import PageHeader from "../../../_common/components/PageHeader";
import ReportCard from "../../../_common/components/ReportCard";
import { useDefaultHistory } from "../../../_common/types/router";
import PivotTabs, { PivotTab } from "../../../_common/components/PivotTabs";
import { tabButtonsStylingType } from "../../../_common/components/TabButtons";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

import UserBaseAPI from "../../api/userbase.api";
import "./UsersView.scss";

import { FC, useEffect, useState } from "react";
import MonitoredUsers from "../../components/users/MonitoredUsers";
import UnmonitoredUsers from "../../components/users/UnmonitoredUsers";
import {
  FilterBar,
  FilterLabelContainer,
  FilterPanelContainer,
  FilterTypes,
  isFilterActive,
} from "../../../vendorrisk/components/filter";
import { useFilters } from "../../../vendorrisk/reducers/filters.actions";
import SlidePanel from "../../../_common/components/SlidePanel";
import { parseUserRiskFilterFromQuery } from "../../helpers/filters";
import { getClearedFilters } from "../../../vendorrisk/components/filter/FilterPanel";
import { Filters } from "../../../vendorrisk/components/filter/types";
import { setCustomerDataFilters } from "../../../vendorrisk/reducers/cyberRiskActions";
import { DashboardStatToShow } from "../../components/stats/DashboardStat";
import TitleStat from "../../components/stats/TitleStat";
import Button from "../../../_common/components/core/Button";
import {
  ExportFiletypeXLSX,
  ExportType,
} from "../../../_common/types/exportReport";
import ExportReportModal from "../../../vendorrisk/components/modals/ExportReportModal";
import { useModalV2 } from "../../../_common/components/ModalV2";
import MonitoringRulesModal from "../../components/monitoring_rules/MonitoringRulesModal";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../../_common/permissions";

export const supportedFilters = [
  FilterTypes.USERRISK_USER_UUIDS,
  FilterTypes.USERRISK_APPS,
  FilterTypes.USERRISK_TEAMS,
  FilterTypes.USERRISK_ROLES,
  FilterTypes.USERRISK_USER_SCORES,
];

const UsersView: FC = () => {
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [openMonitoringRulesModal, monitoringRulesModal] =
    useModalV2(MonitoringRulesModal);

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const filters = useFilters();
  const filtersActive = isFilterActive(filters, supportedFilters);

  const { isFetching: monitoredIsFetching } =
    UserBaseAPI.useGetUserBaseUsersV1Query({
      userUUIDs: filters.userRiskUserUUIDs,
      excludeUsers: filters.userRiskExcludeUsers,
      appNames: filters.userRiskAppNames,
      excludeApps: filters.userRiskExcludeApps,
      teams: filters.userRiskTeams,
      excludeTeams: filters.userRiskExcludeTeams,
      roles: filters.userRiskRoles,
      excludeRoles: filters.userRiskExcludeRoles,
      minScore: filters.userRiskUserMinScore,
      maxScore: filters.userRiskUserMaxScore,
    });

  useEffect(() => {
    const queryParamFilters = {
      ...getClearedFilters(supportedFilters),
      ...parseUserRiskFilterFromQuery(history),
    } as Filters;
    if (isFilterActive(queryParamFilters, supportedFilters)) {
      dispatch(setCustomerDataFilters(queryParamFilters));
    }
  }, []);

  const { isFetching: unmonitoredIsFetching } =
    UserBaseAPI.useGetUserBaseUnmonitoredUsersV1Query();

  const isFetching = monitoredIsFetching || unmonitoredIsFetching;

  return (
    <div className="userbase-users">
      <FilterBar
        dispatch={dispatch}
        isCustomer={true}
        active={filtersActive}
        supportedFilters={supportedFilters}
      />
      <PageHeader
        history={history}
        title={<TitleStat statToShow={DashboardStatToShow.UserCount} />}
        infoSection={
          <>
            <p>
              Users shows all the users associated with your organization. Each
              user is given a security rating based on the user-related
              activities we have scanned. To get more information about a
              particular user, click on the user.
            </p>
          </>
        }
        rightSection={
          <>
            {userHasUserBaseWrite && (
              <Button tertiary onClick={() => openMonitoringRulesModal({})}>
                <div className="cr-icon-lightning" />
                Manage monitoring rules
              </Button>
            )}
            <FilterLabelContainer
              onClick={() => setFilterPanelOpen(!filterPanelOpen)}
              supportedFilters={supportedFilters}
              isCustomer={true}
              disabled={isFetching}
            />
            <Button onClick={() => setExportModalOpen(true)}>
              <div className="cr-icon-export" /> Export
            </Button>
          </>
        }
      />

      <ReportCard newStyles>
        <PivotTabs styling={tabButtonsStylingType.Flat}>
          <PivotTab
            id="monitored-users"
            headerText={
              <div className="monitored-users-tab">
                <i className="cr-icon-accepted" />
                <div className="tab-text">{"Monitored"}</div>
              </div>
            }
          >
            <MonitoredUsers />
          </PivotTab>

          <PivotTab
            id="unmonitored-users"
            headerText={
              <div className="unmonitored-users-tab">
                <i className="cr-icon-minus-circle" />
                <div className="tab-text">{"Not monitored"}</div>
              </div>
            }
          >
            <UnmonitoredUsers />
          </PivotTab>
        </PivotTabs>
      </ReportCard>
      {monitoringRulesModal}
      <SlidePanel
        active={filterPanelOpen}
        title="Filter by"
        newStyles
        dimContent
        onClose={() => setFilterPanelOpen(false)}
      >
        <FilterPanelContainer
          closePanel={() => setFilterPanelOpen(false)}
          supportedFilters={supportedFilters}
          isCustomer={true}
          startAllExpanded={true}
        />
      </SlidePanel>
      <ExportReportModal
        title={`Export Users`}
        exportType={ExportType.UserRiskUsersExport}
        supportedFilters={supportedFilters}
        active={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        supportedFileTypes={ExportFiletypeXLSX}
        shouldFetchExportSections
      />
    </div>
  );
};

export default UsersView;

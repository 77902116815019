import BaseAPI from "../../_common/rtkQueryApi";
import { GetGptAutofillSourcesResp } from "./autofill.actions";
import { GptUserPreferences } from "../components/types/autofill.types";
import { ExternalDocument } from "../../_common/components/types/excelConfig.types";
import { setGptAutofillCache } from "./actions";
import contentLibraryAPI, {
  contentLibraryDocumentCacheTag,
  contentLibraryDocumentTypesCacheTag,
  contentLibraryPartialListID,
} from "../../contentlibrary/api/contentLibraryAPI";
import { ContentLibraryDocument } from "../../contentlibrary/types/contentLibrary.types";

interface gptAutofillBeginReq {
  surveyID: number;
  isPublic: boolean;
  sources: GptUserPreferences;
  overrideExistingAnswers: boolean;
}

export const GptSourceListTag = "GptSourceList";

const SurveyViewerAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [GptSourceListTag],
}).injectEndpoints({
  endpoints: (builder) => ({
    getGptSources: builder.query<
      GetGptAutofillSourcesResp,
      { surveyID?: number; isPublic?: boolean }
    >({
      query: (args) => ({
        method: "GET",
        url: "/surveyresponse/sources/v1",
        params: {
          survey_id: args.surveyID,
          is_public: args.isPublic,
        },
      }),
      providesTags: (result, _error, args) =>
        result
          ? [
              {
                type: GptSourceListTag,
                id: `${args.surveyID}_${args.isPublic}`,
              },
            ]
          : [],
    }),

    uploadExternalDoc: builder.mutation<
      {
        document?: ExternalDocument;
        contentLibDoc?: ContentLibraryDocument;
      },
      { file: File }
    >({
      query: ({ file }) => ({
        method: "POST",
        url: "/surveyresponse/doc/upload/v1",
        file,
      }),
      invalidatesTags: (result) => (result ? [{ type: GptSourceListTag }] : []),
      onQueryStarted: (_arg, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(() => {
          dispatch(
            contentLibraryAPI.util.invalidateTags([
              {
                type: contentLibraryDocumentCacheTag,
                id: contentLibraryPartialListID,
              },
              contentLibraryDocumentTypesCacheTag,
            ])
          );
        });
      },
    }),

    deleteExternalDoc: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        method: "DELETE",
        url: "surveyresponse/doc/v1",
        params: {
          uuid,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: GptSourceListTag }] : []),
      onQueryStarted: (_arg, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(() => {
          dispatch(
            contentLibraryAPI.util.invalidateTags([
              {
                type: contentLibraryDocumentCacheTag,
                id: contentLibraryPartialListID,
              },
              contentLibraryDocumentTypesCacheTag,
            ])
          );
        });
      },
    }),

    gptBeginAutofill: builder.mutation<
      {
        asyncJobID: number;
        jobFinished: boolean;
        cacheDirty: boolean;
      },
      gptAutofillBeginReq
    >({
      query: (args) => ({
        method: "POST",
        url: "surveyresponse/gpt/start/v1",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            setGptAutofillCache({
              cacheDirty: result.data.jobFinished,
              jobStarted: true,
              jobFinished: result.data.jobFinished,
              jobProgress: 0.0,
              suggestions: {},
              asyncJobID: result.data.asyncJobID,
              overrideAnswers: arg.overrideExistingAnswers,
            })
          );
        });
      },
    }),
  }),
});

export const invalidateSources = (surveyID: number, isPublic: boolean) =>
  SurveyViewerAPI.util.invalidateTags([
    { type: GptSourceListTag, id: `${surveyID}_${isPublic}` },
  ]);

export default SurveyViewerAPI;

import { History } from "history";
import {
  ConsumeQueryParams,
  parseBooleanQueryParamValue,
} from "../../_common/query";
import { UserRiskFilters } from "../../vendorrisk/components/filter/types";

export const parseUserRiskFilterFromQuery = (
  history: History
): Partial<UserRiskFilters> => {
  const filterParamKeys: (keyof UserRiskFilters)[] = [
    "userRiskUserUUIDs",
    "userRiskExcludeUsers",
    "userRiskAppNames",
    "userRiskExcludeApps",
    "userRiskTeams",
    "userRiskExcludeTeams",
    "userRiskRoles",
    "userRiskExcludeRoles",
    "userRiskAppStatuses",
    "userRiskExcludeStatuses",
    "userRiskMinFirstDetected",
    "userRiskMaxFirstDetected",
    "userRiskMinRiskLevel",
    "userRiskMaxRiskLevel",
  ];
  const params = ConsumeQueryParams(history, filterParamKeys);
  return {
    ...params,
    userRiskExcludeUsers: parseBooleanQueryParamValue(
      params.userRiskExcludeUsers
    ),
    userRiskExcludeApps: parseBooleanQueryParamValue(
      params.userRiskExcludeApps
    ),
    userRiskExcludeTeams: parseBooleanQueryParamValue(
      params.userRiskExcludeTeams
    ),
    userRiskExcludeRoles: parseBooleanQueryParamValue(
      params.userRiskExcludeRoles
    ),
    userRiskExcludeStatuses: parseBooleanQueryParamValue(
      params.userRiskExcludeStatuses
    ),
    userRiskFirstDetectedBefore:
      !!params.userRiskMaxFirstDetected && !params.userRiskMinFirstDetected,
    userRiskFirstDetectedBetween:
      !!params.userRiskMaxFirstDetected && !!params.userRiskMinFirstDetected,
  };
};

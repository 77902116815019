import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import ListFilter from "./ListFilter";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";
import { useLocaleCompare } from "../../../_common/hooks";
import { appCategoryDescription } from "../../helpers/apps";

interface UserRisksAppCategoryFilterProps {
  selectedCategories: string[];
  excluded: boolean;
  onChange: (selectedCategories: string[], exclude: boolean) => void;
}

const UserRiskAppCategoryFilter: FC<UserRisksAppCategoryFilterProps> = ({
  selectedCategories,
  excluded,
  onChange,
}) => {
  const { data, isLoading } = userbaseApi.useGetAppCategoriesV1Query();
  const categories = data?.categories ?? [];

  const { caseInsensitiveCompare } = useLocaleCompare();
  const sortedCategories = [...categories].sort(caseInsensitiveCompare);

  return (
    <ListFilter
      title={"Application categories"}
      isLoading={isLoading}
      options={
        sortedCategories.map((category) => {
          return {
            // Force the unknown category to be an empty string for the filter
            value: category,
            label: appCategoryDescription(category),
          };
        }) ?? []
      }
      selectedValues={selectedCategories}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRiskAppCategoryFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);

import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { useEffect, useRef, useState } from "react";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { validateEmail } from "../../../_common/helpers";
import {
  deleteVerifiedVendorLogo,
  updateVerifiedVendorLogo,
} from "../../reducers/cyberRiskActions";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import ModalForm from "../modals/ModalForm";
import TextField from "../../../_common/components/TextField";
import ColorCheckbox from "../ColorCheckbox";
import ColorGrade from "../executive_summary/ColorGrade";
import Score from "../Score";
import "../../style/components/EditSharedProfileMetadataModal.scss";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import { ProfileLogo } from "../../types/sharedAssessment";
import { generatePngFromSvg } from "../../../_common/helpers/image.helpers";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import classnames from "classnames";
import trustPageApi, {
  TrustPageTagTypes,
} from "../../../verifiedvendors/api/trustpage.api";
import TrustPageAPI from "../../../verifiedvendors/api/trustpage.api";
import VerifiedVendorsPublicAPI, {
  VerifiedVendorsTagTypes,
} from "../../../verifiedvendors/api/verifiedvendors.api";

const previewElement = ".img-preview";

interface IEditSharedProfileMetadataProps extends BaseModalProps {
  dispatch: DefaultThunkDispatch;
  vendorId: number;
  uuid?: string;
  contactName: string;
  contactEmail: string;
  companyDescription: string;
  currentScore: number;
  industryAverage?: number;
  includeRiskRating: boolean;
  includeIndustryAverage: boolean;
  profileLogo: ProfileLogo;
  profileLogoUrl?: string;
  focusFieldOnMount?: "contact_name" | "company_description";
}

interface focusableInput {
  focus: () => void;
}

const EditSharedProfileMetadataModal = ({
  dispatch,
  onClose,
  active,
  vendorId,
  uuid,
  contactName: originalContactName,
  contactEmail: originalContactEmail,
  companyDescription: originalCompanyDescription,
  currentScore,
  industryAverage,
  includeRiskRating: originalIncludeRiskRating,
  includeIndustryAverage: originalIncludeIndustryAverage,
  profileLogo: originalProfileLogo,
  profileLogoUrl: originalProfileLogoUrl,
  focusFieldOnMount,
}: IEditSharedProfileMetadataProps) => {
  const [includeRiskRating, setIncludeRiskRating] = useState(
    originalIncludeRiskRating
  );
  const [includeIndustryAverage, setIncludeIndustryAverage] = useState(
    originalIncludeIndustryAverage
  );
  const [profileLogo, setProfileLogo] = useState(originalProfileLogo);
  const [profileLogoUrl, setProfileLogoUrl] = useState(originalProfileLogoUrl);
  const [contactName, setContactName] = useState(originalContactName);
  const [contactEmail, setContactEmail] = useState(originalContactEmail);
  const [companyDescription, setCompanyDescription] = useState(
    originalCompanyDescription
  );
  const [loading, setLoading] = useState(false);
  const [isUpdatingLogo, setIsUpdatingLogo] = useState(false);

  const contactNameRef = useRef<focusableInput>(null);
  const companyDescriptionRef = useRef<focusableInput>(null);

  const [updateOwnSharedAssessmentMetadataV1] =
    trustPageApi.useUpdateOwnSharedAssessmentMetadataV1Mutation();

  useEffect(() => {
    if (focusFieldOnMount === "contact_name" && contactNameRef.current) {
      contactNameRef.current.focus();
    } else if (
      focusFieldOnMount === "company_description" &&
      companyDescriptionRef.current
    ) {
      companyDescriptionRef.current.focus();
    }
  }, []);

  const deleteImage = () => {
    setIsUpdatingLogo(true);
    dispatch(deleteVerifiedVendorLogo())
      .then(() => setProfileLogoUrl(undefined))
      .then(() => setIsUpdatingLogo(false))
      .then(() => dispatch(addDefaultSuccessAlert("Logo deleted")))
      .catch(() =>
        dispatch(
          addSimpleErrorAlert("Unable to delete logo, please try again.")
        )
      );
  };

  const saveImage = (pngFile: File) => {
    setIsUpdatingLogo(true);
    dispatch(updateVerifiedVendorLogo(pngFile))
      .then(async (pngUrl) => setProfileLogoUrl(pngUrl))
      .then(() => setIsUpdatingLogo(false))
      .then(() => dispatch(addDefaultSuccessAlert("New logo uploaded")))
      .catch(() => {
        dispatch(
          addSimpleErrorAlert("Unable to upload logo, please try again.")
        );
        setIsUpdatingLogo(false);
      });
  };

  const fileSelected = async (file: File) => {
    if (file.type === "image/svg+xml") {
      generatePngFromSvg(
        file,
        (pngFile, _) => saveImage(pngFile), // verified vendors are only PNG for now so discard the svg
        previewElement
      );
    } else if (file.type === "image/png") {
      saveImage(file);
    }
  };

  const fileRejected = () => {
    dispatch(
      addSimpleErrorAlert(
        "File must be under 800 KB and be either .svg or .png"
      )
    );
  };

  const onSaveChanges = () => {
    if (profileLogo === ProfileLogo.CustomLogo && !profileLogoUrl) {
      dispatch(
        addDefaultWarningAlert("You must upload an image for custom logo.")
      );
      return;
    }

    if (contactName && !contactEmail) {
      dispatch(
        addDefaultWarningAlert(
          "You must set an email address, if adding a security contact."
        )
      );
      return;
    }

    if (!contactName && contactEmail) {
      dispatch(
        addDefaultWarningAlert(
          "You must set a contact name, if adding a security contact."
        )
      );
      return;
    }

    if (contactEmail && !validateEmail(contactEmail)) {
      dispatch(addDefaultWarningAlert("Invalid email address set."));
      return;
    }

    setLoading(true);

    updateOwnSharedAssessmentMetadataV1({
      contactName,
      contactEmail,
      companyDescription,
      includeRiskRating,
      includeIndustryAverage,
      profileLogo,
    })
      .unwrap()
      .then(() => {
        dispatch(addDefaultSuccessAlert("Successfully saved changes"));
        if (vendorId) {
          dispatch(
            TrustPageAPI.util.invalidateTags([
              {
                type: TrustPageTagTypes.trustPage,
                id: vendorId,
              },
            ])
          );
        }
        if (uuid) {
          dispatch(
            VerifiedVendorsPublicAPI.util.invalidateTags([
              {
                type: VerifiedVendorsTagTypes.publicTrustPage,
                id: uuid,
              },
            ])
          );
        }
        onClose();
      })
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error saving changes"));
      })
      .finally(() => setLoading(false));
  };

  return (
    <ModalV2
      active={active}
      className={"edit-shared-profile-modal"}
      onClose={onClose}
      headerContent={`Edit your company details`}
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            loading={loading || isUpdatingLogo}
            onClick={onSaveChanges}
          >
            Save changes
          </Button>
        </div>
      }
    >
      <ModalForm>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Show security rating</span>
            <p>Include your organisation security rating in the Trust Page.</p>
          </div>
          <div className="form-section-input">
            <div className={"scores"}>
              <div
                className={classnames({
                  greyed: !includeRiskRating,
                })}
              >
                <div className="score-subheader">Current security rating</div>
                <div className="score-inner">
                  <ColorGrade score={currentScore} />
                  <Score score={currentScore} outOf={950} colored />
                </div>
              </div>
              {industryAverage !== undefined && (
                <div
                  className={classnames({
                    greyed: !includeRiskRating || !includeIndustryAverage,
                  })}
                >
                  <div className="score-subheader">Industry average</div>
                  <div className="score-inner">
                    <ColorGrade score={industryAverage} />
                    <Score score={industryAverage} outOf={950} colored />
                  </div>
                </div>
              )}
            </div>
            <ColorCheckbox
              checked={includeRiskRating}
              onClick={() => setIncludeRiskRating(!includeRiskRating)}
              label={"Include your security rating"}
            />
            {industryAverage !== undefined && (
              <ColorCheckbox
                disabled={!includeRiskRating}
                checked={includeRiskRating && includeIndustryAverage}
                onClick={() =>
                  setIncludeIndustryAverage(!includeIndustryAverage)
                }
                label={"Include industry average rating"}
              />
            )}
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Add a company logo</span>
            <p>Provide a company logo to be displayed on your Trust Page</p>
          </div>
          <div className="form-section-input">
            <ColorCheckbox
              radio
              checked={profileLogo === ProfileLogo.DefaultLogo}
              onClick={() => setProfileLogo(ProfileLogo.DefaultLogo)}
              label={"Use default logo"}
            />
            <ColorCheckbox
              radio
              checked={profileLogo === ProfileLogo.NoLogo}
              onClick={() => setProfileLogo(ProfileLogo.NoLogo)}
              label={"Use no logo"}
            />
            <ColorCheckbox
              radio
              checked={profileLogo === ProfileLogo.CustomLogo}
              onClick={() => setProfileLogo(ProfileLogo.CustomLogo)}
              label={"Upload custom logo"}
            />
            {profileLogo === ProfileLogo.CustomLogo && (
              <div className={"custom-logo"}>
                <img
                  className="img-preview"
                  style={{
                    position: "absolute",
                    top: "-5000px",
                    left: "-5000px",
                  }}
                />
                {isUpdatingLogo && <LoadingBanner tight />}
                {!isUpdatingLogo && profileLogoUrl && (
                  <div className="preview">
                    <img className="png-preview" src={profileLogoUrl} />
                    <Button
                      danger
                      onClick={deleteImage}
                      className={"delete-image"}
                    >
                      <i className="cr-icon-trash" />
                      Delete
                    </Button>
                  </div>
                )}
                {!isUpdatingLogo && !profileLogoUrl && (
                  <DragDropUpload
                    key={profileLogoUrl}
                    onFileSelected={fileSelected}
                    onFileRejected={fileRejected}
                    maxFileSize={819200}
                    acceptedFileTypeFilters={[".svg", ".png"]}
                    clickText={"Click to upload a new logo"}
                    doNotKeepState={true}
                  />
                )}
                <div className={"custom-logo-desc"}>
                  <span className="cr-icon-info" />
                  Upload an SVG or PNG, sized 80x80 pixels, with a max size of
                  800kb
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Security contact</span>
            <p>
              Nominate a security contact whose details will be listed on your
              Trust Page.
            </p>
          </div>
          <div className="form-section-input">
            <TextField
              ref={contactNameRef}
              value={contactName}
              onChanged={(val) => setContactName(val)}
              placeholder="Contact name"
              type="text"
            />
            <TextField
              value={contactEmail}
              onChanged={(val) => setContactEmail(val)}
              placeholder="Contact email"
              type="email"
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Company description</span>
            <p>Write a short description of what your company does.</p>
          </div>
          <div className="form-section-input">
            <TextField
              ref={companyDescriptionRef}
              value={companyDescription}
              onChanged={(val) => setCompanyDescription(val)}
              multiLine
              placeholder="Company description"
              type="text"
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export default EditSharedProfileMetadataModal;

import { FC, useCallback, useEffect, useState } from "react";
import UpGuardLogo from "../../images/upguard-logo-white.svg";
import UpGuardLogoIcon from "../../images/upguard-logo-icon-white.svg";
import classnames from "classnames";
import "../../style/components/navigation/TopLevelNavigation.scss";
import NavLink from "../../components/NavLink";
import {
  breachSightProductDisplayName,
  product,
  TrustExchangeNavigationItems,
} from "../../types/products";
import {
  useCurrentOrg,
  useCurrentUser,
  useCurrentUserTasks,
} from "../../selectors/commonSelectors";
import {
  getUserRemediationRequests,
  getUserRiskAcceptanceTasks,
  isExpiredTrial,
  userOrgIsFreeOrg,
} from "../../helpers/userOrg.helpers";
import {
  userCurrentOrgIsDistributor,
  userIsOrgless,
  userIsVendorManagementAnalyst,
} from "../../helpers/userOrg.helpers";
import {
  getDefaultBreachRiskRouteForUser,
  getDefaultVendorRiskRouteForUser,
  getUserBreachSightSections,
  getUserTrustExchangeSections,
  userCanViewUserRisk,
  userCanViewVendorRisk,
} from "../../helpers/navigation.helpers";
import DismissablePopup, { SidePopupV2 } from "../DismissablePopup";
import { OrgFreeOrgHomePage, useHasOrgEntitlement } from "../../permissions";
import { trackEvent } from "../../tracking";
import { useAppSelector } from "../../types/reduxHooks";
import { userReportExportStatuses } from "../../../vendorrisk/views/reporting/ReportExportsV2";
import LoadingBanner from "../core/LoadingBanner";
import { usePrevious } from "../../../vendorrisk/helpers/util";
import Icon from "../core/Icon";
import { useHistory } from "react-router-dom";
import { RiskAssetType } from "../../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { useVendorWords } from "../../hooks";
import { getPLGTasksIncompleteCount } from "../../../vendorrisk/reducers/plgOnboardingChecklistActions";
import { RemediationType } from "../../types/remediation";

export type topLevelNavigationItem =
  | product
  | "home"
  | "reports"
  | "incidents_and_news";

interface TopLevelNavigationProps {
  open?: boolean;
  selectedItem?: topLevelNavigationItem;
}

const TopLevelNavigation: FC<TopLevelNavigationProps> = (props) => {
  const clickNavLinkFunc = useCallback(
    (pathTo: any, linkName: any, viewingVendorId: any) => () => {
      // then track an event
      const properties: Record<string, any> = { pathTo, linkName };
      if (viewingVendorId) {
        properties.viewingVendorId = viewingVendorId;
      }

      trackEvent("navigation menu item clicked", properties);
    },
    []
  );

  // Get relevant org and user configuration values
  const currentOrg = useCurrentOrg();
  const userData = useCurrentUser();
  const userTaskData = useCurrentUserTasks();

  const history = useHistory();

  const vendorWords = useVendorWords();

  const currentOrgIsFreeOrg = userOrgIsFreeOrg(currentOrg);
  const currentOrgIsDistributor = userCurrentOrgIsDistributor(userData);
  const showHomeForFreeOrg = useHasOrgEntitlement(OrgFreeOrgHomePage);

  const currentUserIsOrgless = userIsOrgless(userData);
  const currentUserIsVendorManagementAnalyst =
    userIsVendorManagementAnalyst(userData);

  const currentOrgIsExpiredTrial = isExpiredTrial(currentOrg);

  const showHome =
    !currentUserIsOrgless && (!currentOrgIsFreeOrg || showHomeForFreeOrg);

  const showReports =
    !currentOrgIsDistributor &&
    ((!currentUserIsOrgless && !currentOrgIsFreeOrg) ||
      currentUserIsVendorManagementAnalyst) &&
    !currentOrgIsExpiredTrial;

  const showIncidentsAndNews =
    !currentUserIsOrgless &&
    !currentOrgIsFreeOrg &&
    !currentOrgIsDistributor &&
    !currentOrgIsExpiredTrial;

  const breachSightNavSections = getUserBreachSightSections(
    userData,
    currentOrg,
    undefined,
    userTaskData
  );

  const showBreachSight = breachSightNavSections.length > 0;

  const showVendorRisk = userCanViewVendorRisk(userData);

  const defaultVendorRiskRoute = getDefaultVendorRiskRouteForUser();

  const defaultBreachRiskRoute = getDefaultBreachRiskRouteForUser();

  const hasUserRiskPermission = userCanViewUserRisk(userData);

  const { currentOrgRiskAcceptanceRiskIDs, otherOrgRiskAcceptanceRiskIDs } =
    getUserRiskAcceptanceTasks(
      userTaskData ?? {},
      true,
      true,
      [RiskAssetType.userbaseUser],
      [RiskAssetType.userbaseUser]
    );

  const {
    currentOrgRequestIDs: currentOrgUserRiskRemediationRequestIDs,
    otherOrgRequestIDs: otherOrgUserRiskRemediationRequestIDs,
  } = getUserRemediationRequests(
    userTaskData ?? {},
    true,
    true,
    [RemediationType.Userrisk],
    [RemediationType.Userrisk]
  );

  const showUserRiskRiskAcceptanceTasks =
    !!currentOrgRiskAcceptanceRiskIDs?.length ||
    !!otherOrgRiskAcceptanceRiskIDs?.length;
  const showUserRiskRemediationRequestsTasks =
    !!currentOrgUserRiskRemediationRequestIDs?.length ||
    !!otherOrgUserRiskRemediationRequestIDs?.length;

  const showUserRiskTasks =
    showUserRiskRiskAcceptanceTasks || showUserRiskRemediationRequestsTasks;

  const showUserRiskSection = hasUserRiskPermission || showUserRiskTasks;

  let defaultUserRiskRoute = "/userbase/users";

  // If the user can only see UR because they have risk waiver approvals, take them to the risk waiver approvals page
  if (showUserRiskSection && !hasUserRiskPermission) {
    defaultUserRiskRoute = "/tasks/userrisk/riskwaiverapprovals";
  }

  const showAnalystPortal =
    userData.isAnalyst || currentUserIsVendorManagementAnalyst;

  const trustExchangeNavItems = getUserTrustExchangeSections(
    userData,
    currentOrg,
    userTaskData
  );

  // Sections are returned in the order they should be displayed
  const defaultTrustExchangeItem =
    TrustExchangeNavigationItems[trustExchangeNavItems[0]];

  // Get the activity stream task count
  const activityStreamTaskCountRecord = useAppSelector(
    (state) => state.common?.activityStream?.numTasks
  );

  // PLG users have their own onboarding task list
  const plgOnboarding = useAppSelector(
    (state) => state.common?.userData?.plgOnboarding
  );

  let onboardingTaskCount: number = 0;

  if (!!plgOnboarding && !!plgOnboarding.completedTasks) {
    onboardingTaskCount = getPLGTasksIncompleteCount(
      plgOnboarding.applicableChecklists,
      plgOnboarding.completedTasks
    );
  } else {
    if (typeof activityStreamTaskCountRecord === "number") {
      onboardingTaskCount = activityStreamTaskCountRecord;
    }
  }

  let activityStreamTaskCount: string | undefined;

  if (onboardingTaskCount > 0) {
    if (onboardingTaskCount > 99) {
      activityStreamTaskCount = "99+";
    } else {
      activityStreamTaskCount = `${onboardingTaskCount}`;
    }
  }

  // Get report data
  const reportItems = useAppSelector(
    (state) => state.common?.exportItems?.data
  );

  const unreadReportsCount: number =
    reportItems?.filter(
      (item) => item.status === userReportExportStatuses.Complete && !item.read
    ).length ?? 0;

  const pendingReportsCount: number =
    reportItems?.filter(
      (item) => item.status === userReportExportStatuses.Pending
    ).length ?? 0;

  let unreadReportsCountString: string | undefined;

  if (unreadReportsCount > 0) {
    if (unreadReportsCount > 99) {
      unreadReportsCountString = "99+";
    } else {
      unreadReportsCountString = `${unreadReportsCount}`;
    }
  }

  const [showReportProcessingPopup, setShowReportProcessingPopup] = useState(
    pendingReportsCount > 0
  );

  const [showReportCompletePopup, setShowReportCompletePopup] = useState(false);

  // Track the previous pending reports count so we can handle the required popup states
  const previousPendingReportsCount = usePrevious(pendingReportsCount);

  useEffect(() => {
    // If pending reports changes from 0 to 1 or more, show the pending popup
    if (
      previousPendingReportsCount === 0 &&
      pendingReportsCount > 0 &&
      !showReportProcessingPopup
    ) {
      setShowReportCompletePopup(false);
      setShowReportProcessingPopup(true);
    }

    // If pending reports changes from 1 or more to 0, show the complete popup
    if (
      previousPendingReportsCount > 0 &&
      pendingReportsCount === 0 &&
      !showReportCompletePopup
    ) {
      setShowReportProcessingPopup(false);
      setShowReportCompletePopup(true);
    }
  }, [pendingReportsCount]);

  const getTopLevelItemIcon = (
    item: topLevelNavigationItem,
    iconName: string
  ) => {
    if (props.selectedItem === item) {
      return `${iconName}-fill`;
    }
    return `${iconName}-outline`;
  };

  // Wrap the report navlink in a popup if we have pending reports, or have finished processing pending reports
  const getReportNavlink = () => {
    const innerContent = (
      // Wrap navlink in the hovertext popup - but don't populate this if we are showing one of the report popups
      <SidePopupV2
        text={
          !props.open && !showReportProcessingPopup && !showReportCompletePopup
            ? "Reports"
            : undefined
        }
        position={"right"}
        noWrap={true}
        popupClassName="top-level-navigation-popup"
      >
        <NavLink
          className="reports-exports-nav-item"
          icon={getTopLevelItemIcon("reports", "reports")}
          text={props.open ? "Reports" : ""}
          onClick={clickNavLinkFunc("/reportexports", "Reports", undefined)}
          to={
            unreadReportsCountString
              ? "/reportexports/generated"
              : "/reportexports"
          }
          isActive={() => {
            return props.selectedItem === "reports";
          }}
          badge={unreadReportsCountString}
          showBadgeOnIcon={true}
        />
      </SidePopupV2>
    );

    if (showReportProcessingPopup) {
      return (
        <DismissablePopup
          popupClassName="top-level-navigation-popup reports-processing-popup"
          position="right"
          popupStyle={"light"}
          text={
            <div
              className="reports-processing-popup-content"
              onClick={() => {
                history.push("/reportexports/generated");
              }}
            >
              <LoadingBanner tight={true} size={20} />
              <span className="reports-processing-popup-text">
                {pendingReportsCount}{" "}
                {pendingReportsCount > 1 ? "reports" : "report"} processing
              </span>
            </div>
          }
          noWrap={true}
          onDismiss={() => setShowReportProcessingPopup(false)}
        >
          {innerContent}
        </DismissablePopup>
      );
    }

    if (showReportCompletePopup) {
      return (
        <DismissablePopup
          popupClassName="top-level-navigation-popup reports-complete-popup"
          position="right"
          text={
            <div
              className="reports-complete-popup-content"
              onClick={() => {
                setShowReportCompletePopup(false);
                history.push("/reportexports/generated");
              }}
            >
              <span>Reports complete</span>
              <div>
                View now <Icon name="arrow" direction={90} />
              </div>
            </div>
          }
          noWrap={true}
          autoDismissAfter={5000}
          onDismiss={() => setShowReportCompletePopup(false)}
        >
          {innerContent}
        </DismissablePopup>
      );
    }

    return innerContent;
  };

  const shouldShowSplit =
    (showHome || showReports || showIncidentsAndNews) &&
    (showBreachSight ||
      showVendorRisk ||
      showUserRiskSection ||
      trustExchangeNavItems.length > 0 ||
      showAnalystPortal);

  return (
    <div className="top-level-navigation">
      <div className="logo-container">
        <img
          src={props.open ? UpGuardLogo : UpGuardLogoIcon}
          alt="UpGuard Logo"
          className={classnames("ug-logo", {
            open: props.open,
          })}
        />
      </div>
      <div className="navlinks-container">
        {showHome && (
          <SidePopupV2
            text={!props.open ? "Home" : undefined}
            noWrap={true}
            position={"right"}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className="unstyled"
              badge={
                !userCurrentOrgIsDistributor(userData)
                  ? activityStreamTaskCount
                  : undefined
              }
              showBadgeOnIcon={true}
              icon={getTopLevelItemIcon("home", "home")}
              text={props.open ? "Home" : ""}
              exact
              onClick={clickNavLinkFunc("/", "Home", undefined)}
              to="/"
              isActive={() => {
                return props.selectedItem === "home";
              }}
            />
          </SidePopupV2>
        )}

        {showReports && getReportNavlink()}
        {showIncidentsAndNews && (
          <SidePopupV2
            text={!props.open ? "Incidents & News" : undefined}
            noWrap={true}
            position={"right"}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className="unstyled"
              icon={getTopLevelItemIcon(
                "incidents_and_news",
                "incidents-and-news"
              )}
              text={props.open ? "Incidents & News" : ""}
              onClick={clickNavLinkFunc(
                "/breachnewsfeed",
                "Incidents & News",
                undefined
              )}
              to="/breachnewsfeed"
              isActive={() => {
                return props.selectedItem === "incidents_and_news";
              }}
            />
          </SidePopupV2>
        )}
        {shouldShowSplit && <hr />}
        {showBreachSight && (
          <SidePopupV2
            text={!props.open ? `${breachSightProductDisplayName}` : undefined}
            noWrap={true}
            position={"right"}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className={classnames({ open: props.open })}
              icon={getTopLevelItemIcon("breachsight", "breachsight")}
              onClick={clickNavLinkFunc(
                defaultBreachRiskRoute,
                `${breachSightProductDisplayName}`,
                undefined
              )}
              text={props.open ? `${breachSightProductDisplayName}` : ""}
              to={defaultBreachRiskRoute}
              isActive={() => {
                return props.selectedItem === "breachsight";
              }}
            />
          </SidePopupV2>
        )}
        {showVendorRisk && (
          <SidePopupV2
            text={!props.open ? vendorWords.vendorRiskModuleName : undefined}
            noWrap={true}
            position={"right"}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className="unstyled"
              icon={getTopLevelItemIcon("vendor_risk", "vendor-risk")}
              text={props.open ? vendorWords.vendorRiskModuleName : ""}
              onClick={clickNavLinkFunc(
                defaultVendorRiskRoute,
                "Vendor Risk",
                undefined
              )}
              to={defaultVendorRiskRoute}
              isActive={() => {
                return props.selectedItem === "vendor_risk";
              }}
            />
          </SidePopupV2>
        )}
        {showUserRiskSection && (
          <SidePopupV2
            text={!props.open ? "User Risk" : undefined}
            noWrap={true}
            position={"right"}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className="unstyled"
              icon={getTopLevelItemIcon("user_risk", "user-risk")}
              text={props.open ? "User Risk" : ""}
              onClick={clickNavLinkFunc(
                defaultUserRiskRoute,
                "User Risk",
                undefined
              )}
              to={defaultUserRiskRoute}
              isActive={() => {
                return props.selectedItem === "user_risk";
              }}
            />
          </SidePopupV2>
        )}
        {/* All users should have at least 1 TrustExchange nav item, but we should check to be on the safe side */}
        {trustExchangeNavItems.length > 0 && (
          <SidePopupV2
            text={!props.open ? "Trust Exchange" : undefined}
            noWrap={true}
            position={"right"}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className="unstyled"
              icon={getTopLevelItemIcon("trust_exchange", "trust-center")}
              text={props.open ? "Trust Exchange" : ""}
              onClick={clickNavLinkFunc(
                defaultTrustExchangeItem.navigationPath,
                "Trust Exchange",
                undefined
              )}
              to={defaultTrustExchangeItem.navigationPath}
              isActive={() => {
                return props.selectedItem === "trust_exchange";
              }}
            />
          </SidePopupV2>
        )}
        {showAnalystPortal && (
          <SidePopupV2
            text={!props.open ? "Analyst Portal" : undefined}
            position={"right"}
            noWrap={true}
            popupClassName="top-level-navigation-popup"
          >
            <NavLink
              className="unstyled"
              icon={getTopLevelItemIcon("analyst_portal", "analyst-portal")}
              text={props.open ? "Analyst Portal" : ""}
              onClick={clickNavLinkFunc(
                currentUserIsVendorManagementAnalyst
                  ? "/analysts/tpvm"
                  : "analysts/dataleaks/activecustomers",
                "Analyst Portal",
                undefined
              )}
              to={
                currentUserIsVendorManagementAnalyst
                  ? "/analysts/tpvm"
                  : "analysts/dataleaks/activecustomers"
              }
              isActive={() => {
                return props.selectedItem === "analyst_portal";
              }}
            />
          </SidePopupV2>
        )}
      </div>
    </div>
  );
};

export default TopLevelNavigation;

import BaseAPI from "../rtkQueryApi";

interface SubscriptionProductDetails {
  selfServicable: boolean;
  priceUSD: number;
  purchased: boolean;
  billingPeriodEndsAtUNIX: number;
  cancelledAtPeriodEnd: boolean;
  stripeSubscriptionID: string | undefined;
  plan: string;
}

export interface GetSubscriptionPricingResponse {
  breachSight: SubscriptionProductDetails;
  vendorRisk: SubscriptionProductDetails;
  trustExchange: SubscriptionProductDetails;
  orgSettings: {
    vendorsMonitored: number;
    vendorMonitorLimit: number;
  };
}

// These should match the server-side enums
export enum ProductStatus {
  Free = 0,
  Paid = 1,
  Trial = 2,
}

export enum ProductType {
  VendorRisk = "vendorRisk",
  BreachSight = "breachSight",
  TrustExchange = "trustExchange",
  UserRisk = "userRisk",
}

export interface ProductStatusDetails {
  product: ProductType;
  status: ProductStatus;
  currentStatusEnd: string | null;
}

export interface GetProductStatusResponse {
  breachSight: ProductStatusDetails;
  vendorRisk: ProductStatusDetails;
  trustExchange: ProductStatusDetails;
}

export const SelfServiceAPI = BaseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionPricingV1: builder.query<
      GetSubscriptionPricingResponse,
      void
    >({
      query: () => ({
        url: "/selfservice/get_subscription_pricing/v1",
        method: "GET",
      }),
    }),
    getSelfServiceProductsV1: builder.query<GetProductStatusResponse, void>({
      query: () => ({
        url: "/selfservice/get_self_service_products/v1",
        method: "GET",
      }),
    }),
  }),
});

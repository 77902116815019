import { useBack } from "../../_common/types/router";
import { UserRouteParams } from "../UserBaseNavItems";
import { FC } from "react";
import userbaseApi from "../api/userbase.api";
import { userSummaryUrl, usersUrl } from "../UserBaseAppRouter";
import UserBaseUserHeader from "../components/UserBaseUserHeader";
import { useRouteMatch } from "react-router-dom";
import UserRisksOverview from "../components/UserRisksOverview";
import UserAppsOverview from "../components/UserAppsOverview";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import UserTimeline from "../components/UserTimeline";

import AboutUser from "../components/AboutUser";
import "./UserSummaryView.scss";
import UserPermissionsOverview from "../components/UserPermissionsOverview";
import UserScoresChart from "../components/UserScoresChart";

const UserSummaryView: FC = () => {
  const match = useRouteMatch<UserRouteParams>();

  const userUUID = match.params.userUUID;

  const { data, isLoading } = userbaseApi.useGetUserBaseUserSummaryV1Query({
    userUUID,
  });

  const { backAction, backText } = useBack();

  return (
    <div className="userbase-user-summary">
      <UserBaseUserHeader
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: data?.user.name ?? "User",
            to: userSummaryUrl(userUUID),
          },
          {
            text: "Summary",
            to: userSummaryUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Summary"}
      />

      {isLoading && <LoadingBanner size={70} />}
      {!isLoading && (
        <>
          <div className="risk-graph">
            <UserScoresChart userUUID={userUUID} />
          </div>

          <div className="two-grid no-stretch">
            <UserRisksOverview />
            <UserAppsOverview />
          </div>
          <div className="two-grid no-stretch">
            <AboutUser />
            <UserPermissionsOverview />
          </div>
          <UserTimeline />
        </>
      )}
    </div>
  );
};

export default UserSummaryView;

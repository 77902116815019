import CircledIcon from "../../_common/components/CircledIcon";
import TrustPageEditableCard from "./TrustPageEditableCard";
import IconButton from "../../_common/components/IconButton";
import "../style/ComplianceBadgesCard.scss";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import ComplianceBadge from "./ComplianceBadge";
import { ComplianceBadgeTypes } from "../helpers/types";
import classnames from "classnames";
import { useModalV2 } from "../../_common/components/ModalV2";
import UpdateComplianceBadgesModal from "./modals/UpdateComplianceBadgesModal";

interface ComplianceBadgesCardProps {
  className?: string;
  badges?: ComplianceBadgeTypes[];
  userCanMakeChanges: boolean;
}

const ComplianceBadgesCard = ({
  className,
  badges,
  userCanMakeChanges,
}: ComplianceBadgesCardProps) => {
  const [openUpdateComplianceBadgesModal, updateComplianceBadgesModal] =
    useModalV2(UpdateComplianceBadgesModal);

  const cardHeader = "Security and compliance";
  const editButton = (
    <IconButton
      icon={<div className="cr-icon-pencil-2" />}
      onClick={() => {
        openUpdateComplianceBadgesModal({ selectedBadges: badges ?? [] });
      }}
    />
  );

  return (
    <>
      {updateComplianceBadgesModal}
      <TrustPageEditableCard
        className={classnames("compliance-badges-card", className)}
        header={cardHeader}
        editButton={userCanMakeChanges ? editButton : null}
      >
        {badges && badges.length > 0 ? (
          <div className={"compliance-badges-card-inner"}>
            {badges.map((badge) => (
              <ComplianceBadge key={badge} badgeType={badge} />
            ))}
          </div>
        ) : (
          <EmptyCardWithAction
            iconJSX={<CircledIcon iconClass={"cr-icon-trust-center-outline"} />}
            emptyText={"No compliance badges"}
            emptySubText={
              "Add security and compliance badges to showcase the standards your organization meets."
            }
          />
        )}
      </TrustPageEditableCard>
    </>
  );
};

export default ComplianceBadgesCard;

import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import { useSorting } from "../../../_common/hooks";
import {
  ISurveyListItemResponse,
  PrefilledSurveyStatus,
} from "../../../_common/types/survey";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import CircledIcon from "../../../_common/components/CircledIcon";
import { trackEvent } from "../../../_common/tracking";
import { getSurveyEditorPath } from "../../../_common/views/SurveyDetails";
import { HoverColor } from "../../../_common/components/IconButton";
import {
  setPrefilledSurveyDeleted,
  setPrefilledSurveyPublished,
  updateVendorSurveyAddToSharedProfile,
} from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  openModal,
  rollbackPrefilledSurvey,
  submitPrefilledSurvey,
} from "../../../_common/reducers/commonActions";
import { EditPublicSurveyDetailsModalName } from "../modals/EditPublicSurveyDetailsModal";
import Button from "../../../_common/components/core/Button";
import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";
import moment from "moment/moment";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import "../../style/components/SharedProfileQuestionnairesTable.scss";
import {
  IAttachment,
  IAttachmentGroup,
} from "../../../_common/types/attachment";
import { fetchSurveyDetails } from "../../../_common/reducers/surveyDetails.actions";
import { ISharedAssessmentAddToProfileSurvey } from "../../types/sharedAssessment";
import { openSharedProfileSurveyURL } from "../../../_common/views/SharedProfileSurveyDetails";
import { Link } from "react-router-dom";
import SurveyName from "../surveys/SurveyName";
import { FC, useState } from "react";
import { separateQuestionNumberFromQuestion } from "../../../_common/components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import NodeTypeIcon, {
  NodeTypeIconType,
} from "../../../survey_builder/components/NodeTypeIcon";
import classnames from "classnames";
import { Location } from "history";
import { downloadSurveyAttachment } from "./SharedProfileSurveyDocumentationTable";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../../verifiedvendors/api/trustpage.api";
import { exportPublicSurveyInNewTab } from "../../../verifiedvendors/helpers/helpers";

type AttachmentMini = Pick<
  IAttachment,
  | "gcsObjectName"
  | "filename"
  | "updatedAt"
  | "description"
  | "attachmentName"
  | "questionText"
>;

const getUniqueAttachmentsFromAttachmentGroups = (
  attachmentGroups: IAttachmentGroup[]
): AttachmentMini[] => {
  const objectNames: Record<string, true | undefined> = {};
  const attachments: AttachmentMini[] = [];

  for (let i = 0; i < attachmentGroups.length; i++) {
    for (let j = 0; j < (attachmentGroups[i].attachments || []).length; j++) {
      const attachment = attachmentGroups[i].attachments[j];
      if (objectNames[attachment.gcsObjectName]) {
        continue;
      }

      objectNames[attachment.gcsObjectName] = true;
      attachments.push({
        gcsObjectName: attachment.gcsObjectName,
        filename: attachment.filename,
        updatedAt: attachment.updatedAt,
        description: attachment.description,
        attachmentName: attachment.attachmentName,
        questionText: attachmentGroups[i].friendlyName,
      });
    }
  }

  return attachments;
};

interface ISharedProfileAttachmentsSubtableProps {
  userHasNoAccess: boolean;
  isOwnSharedProfile: boolean;
  surveyId: number;
  location: Location;
  attachments: AttachmentMini[];
}

const attachmentsSubtableColumns: IXTableColumnHeader[] = [
  {
    id: "attachment_name",
    text: "Attachment",
  },
  {
    id: "description",
    text: "Description",
  },
];

const SharedProfileAttachmentsSubtable: FC<
  ISharedProfileAttachmentsSubtableProps
> = ({
  attachments,
  userHasNoAccess,
  surveyId,
  location,
  isOwnSharedProfile,
}) => {
  const rows: IXTableRow[] = attachments.map((a) => {
    const [questionNumber, questionText] = separateQuestionNumberFromQuestion(
      a.questionText || ""
    );

    const iconOptions: IIconOption[] = [];
    const downloadAttachment = () =>
      downloadSurveyAttachment(
        surveyId,
        a.gcsObjectName,
        a.attachmentName ?? "",
        a.filename
      );

    if (!userHasNoAccess) {
      iconOptions.push({
        id: "download",
        icon: <div className="cr-icon-export" />,
        hoverText: "Download attachment",
        onClick: downloadAttachment,
      });
    }

    const questionNameInner = (
      <>
        <NodeTypeIcon
          nodeType={NodeTypeIconType.Upload}
          questionNumber={questionNumber || "Extra"}
        />
        <div className="truncate-text" title={questionText}>
          {questionText}
        </div>
      </>
    );

    const questionNameClass = classnames("question-name", {
      clickable: !userHasNoAccess,
    });

    return {
      id: a.gcsObjectName,
      iconOptions,
      cells: [
        <XTableCell key="attachment_name" className="ellipsis-cell">
          <div className="question-and-filename">
            <div
              className={classnames("filename", {
                clickable: !userHasNoAccess,
              })}
              onClick={userHasNoAccess ? undefined : downloadAttachment}
              title={a.filename}
            >
              {a.filename}
            </div>
            {userHasNoAccess ? (
              <div className={questionNameClass}>{questionNameInner}</div>
            ) : (
              <Link
                to={getSurveyEditorPath({
                  surveyId,
                  publicSurvey: true,
                  questionId: a.attachmentName,
                  editMode: false,
                  location,
                })}
                className={questionNameClass}
              >
                {questionNameInner}
              </Link>
            )}
          </div>
        </XTableCell>,
        <XTableCell key="description" className="desc-cell">
          {a.description}
        </XTableCell>,
      ],
    };
  });

  return (
    <div className="q-attachments-list">
      <XTable
        className={classnames("attachments-subtable", {
          "own-shared-profile": isOwnSharedProfile,
        })}
        columnHeaders={attachmentsSubtableColumns}
        rows={rows}
        iconOptions
      />
    </div>
  );
};

interface ISharedProfileQuestionnairesTableProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  vendorName: string;
  vendorId: number;
  surveys: ISurveyListItemResponse[];
  addToSharedProfileSurveys: ISharedAssessmentAddToProfileSurvey[];
  isOwnSharedProfile: boolean;
  userHasNoAccess: boolean;
  userHasWritePrefilledSurveysPermission: boolean;
  receivedSurveys: ISurveyListItemResponse[];
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
}

const getQuestionnairesTableHeaders = (
  isEditable: boolean
): IXTableColumnHeader[] => {
  const colHeaders: IXTableColumnHeader[] = [
    {
      id: "questionnaires",
      text: "Questionnaires",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "date_published",
      text: "Date completed",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
  ];

  if (isEditable) {
    colHeaders.push({
      id: "status",
      text: "status",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    });
  }

  return colHeaders;
};

interface surveyRowItemISurveyListItemResponse extends ISurveyListItemResponse {
  kind: "ISurveyListItemResponse";
}

interface surveyRowItemISharedAssessmentAddToProfileSurvey
  extends ISharedAssessmentAddToProfileSurvey {
  kind: "ISharedAssessmentAddToProfileSurvey";
}

type surveyRowItem =
  | surveyRowItemISurveyListItemResponse
  | surveyRowItemISharedAssessmentAddToProfileSurvey;

const SharedProfileQuestionnairesTable = ({
  dispatch,
  history,
  surveys,
  addToSharedProfileSurveys,
  receivedSurveys,
  vendorName,
  isOwnSharedProfile,
  userHasNoAccess,
  userHasWritePrefilledSurveysPermission,
  isManagementAnalystSession,
  managedOrgId,
}: ISharedProfileQuestionnairesTableProps) => {
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [collapsedRows, setCollapsedRows] = useState<
    Record<string, true | undefined>
  >({});

  const toggleExpandRow = (rowId: string | number) =>
    setCollapsedRows((collapsedRows) => {
      const newCollapsedRows = { ...collapsedRows };
      if (newCollapsedRows[rowId]) {
        delete newCollapsedRows[rowId];
      } else {
        newCollapsedRows[rowId] = true;
      }
      return newCollapsedRows;
    });

  const allSurveys: surveyRowItem[] = [
    ...surveys.map(
      (s) =>
        ({
          ...s,
          kind: "ISurveyListItemResponse",
        }) as surveyRowItemISurveyListItemResponse
    ),
    ...addToSharedProfileSurveys.map(
      (s) =>
        ({
          ...s,
          kind: "ISharedAssessmentAddToProfileSurvey",
        }) as surveyRowItemISharedAssessmentAddToProfileSurvey
    ),
  ];

  const handleDownload = (surveyId: number) => {
    exportPublicSurveyInNewTab(
      surveyId,
      isManagementAnalystSession,
      managedOrgId
    );
    trackEvent("VerifiedVendor_ExportQuestionnairePDF");
  };

  const [sortedSurveys, sortedBy, onSortChange] = useSorting<
    surveyRowItem,
    "questionnaires" | "date_published" | "status"
  >(allSurveys, "date_published", SortDirection.DESC, {
    questionnaires: {
      orderFuncs: [
        (q) => q.name,
        (q) =>
          q.kind === "ISurveyListItemResponse" ? q.dateCompleted : undefined,
        (q) =>
          q.kind === "ISurveyListItemResponse" ? q.dateCreated : undefined,
      ],
      sortDirsAsc: ["asc", "desc", "desc"],
      sortDirsDesc: ["desc", "desc", "desc"],
    },
    date_published: {
      orderFuncs: [
        (q) =>
          q.kind === "ISurveyListItemResponse" ? q.dateCompleted : undefined,
        (q) =>
          q.kind === "ISurveyListItemResponse" ? q.dateCreated : undefined,
      ],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
    status: {
      orderFuncs: [
        (q) =>
          q.kind === "ISurveyListItemResponse"
            ? q.status === PrefilledSurveyStatus.Published
              ? "Included in Trust Page"
              : "Not included in Trust Page"
            : undefined,
        (q) =>
          q.kind === "ISurveyListItemResponse" ? q.dateCompleted : undefined,
      ],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
  });

  if (allSurveys.length === 0) {
    return (
      <EmptyCardWithAction
        iconJSX={<CircledIcon iconClass="cr-icon-questionnaire" />}
        emptyText={
          isOwnSharedProfile
            ? "No published questionnaires"
            : `${vendorName} has not published any questionnaires`
        }
        emptySubText={
          isOwnSharedProfile
            ? "Save time and reduce requests by publishing a security questionnaire on your page."
            : "Please check back later."
        }
      />
    );
  }

  const rows: IXTableRow[] = [];

  for (let i = 0; i < sortedSurveys.length; i++) {
    const q = sortedSurveys[i];

    if (q.kind === "ISurveyListItemResponse") {
      const iconOptions: IIconOption[] = [];
      const surveyIsLinkedToPrivateSurvey = q.originalID > 0;
      const hasUnpublishedChanges =
        q.versions && q.versions.length > 0 && !q.versions[0].shared;
      const shouldShowUnsavedChangesSection =
        isOwnSharedProfile &&
        userHasWritePrefilledSurveysPermission &&
        hasUnpublishedChanges &&
        q.status === PrefilledSurveyStatus.Published;

      // Make sure this user actually has permission to view/edit the original questionnaire
      const canViewPrivateSurvey =
        surveyIsLinkedToPrivateSurvey &&
        receivedSurveys.some((s) => s.id === q.originalID);

      let viewAnswers;
      let rowClick;

      if (!userHasNoAccess) {
        if (isOwnSharedProfile) {
          const canView = q.status === PrefilledSurveyStatus.Published;
          const canEdit = userHasWritePrefilledSurveysPermission;

          if ((canView || canEdit) && !canViewPrivateSurvey) {
            viewAnswers = () => {
              trackEvent("VerifiedVendor_ViewQuestionnaire");
              history.push(
                getSurveyEditorPath({
                  surveyId: q.id,
                  editMode: canEdit,
                  publicSurvey: true,
                  location: history.location,
                })
              );
            };
          }
        } else {
          // Anything shown should be clickable to view
          viewAnswers = () => {
            trackEvent("VerifiedVendor_ViewQuestionnaire");
            history.push(
              getSurveyEditorPath({
                surveyId: q.id,
                editMode: false,
                publicSurvey: true,
                location: history.location,
              })
            );
          };
          rowClick = () =>
            openSharedProfileSurveyURL({
              surveyId: q.id,
              vendorId: q.vendorId,
              history: history,
            });
        }
      }

      if (!userHasNoAccess) {
        if (isOwnSharedProfile && userHasWritePrefilledSurveysPermission) {
          if (q.status !== PrefilledSurveyStatus.Published) {
            iconOptions.push({
              id: "delete",
              icon: <div className="cr-icon-trash" />,
              hoverText: "Delete",
              hoverColor: HoverColor.Red,
              onClick: () =>
                openConfirmationModal({
                  title: "Delete questionnaire",
                  description:
                    "The questionnaire will be permanently deleted from your Trust Page.",
                  dangerousAction: true,
                  buttonText: "Delete",
                  iconClass: "cr-icon-trash",
                  cancelText: "Cancel",
                  buttonAction: async () => {
                    try {
                      await dispatch(setPrefilledSurveyDeleted(q.id));
                      dispatch(
                        TrustPageAPI.util.invalidateTags([
                          TrustPageTagTypes.ownTrustPage,
                          TrustPageTagTypes.ownTrustPageAccess,
                        ])
                      );
                      trackEvent("VerifiedVendor_DeleteQuestionnaire");
                    } catch (e) {
                      console.error(e);
                      dispatch(
                        addDefaultUnknownErrorAlert(
                          "Error deleting questionnaire"
                        )
                      );
                      throw e;
                    }
                  },
                }),
            });
          }

          if (surveyIsLinkedToPrivateSurvey) {
            if (canViewPrivateSurvey) {
              iconOptions.push({
                id: "view",
                icon: <div className="cr-icon-eye" />,
                hoverText: "View original questionnaire",
                onClick: () =>
                  history.push(
                    `/vendors/surveys/${
                      q.isPrefilledSurvey ? q.originalID : q.id
                    }`,
                    {
                      backContext: {
                        backTo: "/trustpage",
                        backToText: "Back to Trust Page",
                      },
                    }
                  ),
              });

              iconOptions.push({
                id: "edit",
                icon: <div className="cr-icon-pencil" />,
                hoverText: "Edit details",
                onClick: () => {
                  trackEvent("VerifiedVendor_EditQuestionnaireDetails");
                  dispatch(
                    openModal(
                      EditPublicSurveyDetailsModalName,
                      {
                        surveyId: q.id,
                        name: q.name,
                        description: q.description,
                        width: 450,
                      },
                      true
                    )
                  );
                },
              });

              if (q.status === PrefilledSurveyStatus.Published) {
                iconOptions.push({
                  id: "edit-answers",
                  icon: <div className="cr-icon-edit-doc" />,
                  hoverText: "Edit answers",
                  onClick: () =>
                    openConfirmationModal({
                      title:
                        "Any changes made will not be reflected in the original questionnaire",
                      description: (
                        <>
                          <p>
                            This questionnaire was originally added to your
                            Trust Page based on a questionnaire that was sent to
                            you. Any changes made will only be reflected on your
                            Trust Page.
                          </p>
                          <p>
                            If you would like to update the original too, you
                            should edit and resubmit the original questionnaire
                            instead.
                          </p>
                        </>
                      ),
                      cancelText: "Cancel",
                      buttonText: "Edit answers",
                      buttonAction: () => {
                        trackEvent("VerifiedVendor_EditQuestionnaire");
                        history.push(
                          getSurveyEditorPath({
                            surveyId: q.id,
                            editMode: true,
                            publicSurvey: true,
                            location: history.location,
                          })
                        );
                      },
                    }),
                });
              }
            }
          } else {
            iconOptions.push({
              id: "edit",
              icon: <div className="cr-icon-pencil" />,
              hoverText: "Edit details",
              onClick: () => {
                trackEvent("VerifiedVendor_EditQuestionnaireDetails");
                dispatch(
                  openModal(
                    EditPublicSurveyDetailsModalName,
                    {
                      surveyId: q.id,
                      name: q.name,
                      description: q.description,
                      width: 450,
                    },
                    true
                  )
                );
              },
            });

            iconOptions.push({
              id: "edit-answers",
              icon: <div className="cr-icon-edit-doc" />,
              hoverText: "Edit answers",
              onClick: viewAnswers,
            });
          }
        }

        if (q.status === PrefilledSurveyStatus.Published) {
          iconOptions.push({
            id: "download",
            icon: <div className="cr-icon-export" />,
            hoverText: "Download",
            onClick: () => handleDownload(q.id),
          });
        }
      }

      const cells = [
        <XTableCell key="questionnaire">
          <div className="q-cell">
            {isOwnSharedProfile && (
              <SidePopupV2
                text={
                  q.numAnswers === 0
                    ? "At least one answer must be provided before publishing"
                    : undefined
                }
              >
                <ToggleSwitch
                  name="published"
                  stopPropagation
                  disabled={
                    !userHasWritePrefilledSurveysPermission ||
                    q.numAnswers === 0
                  }
                  selected={q.status === PrefilledSurveyStatus.Published}
                  onClick={() => {
                    if (q.status === PrefilledSurveyStatus.Published) {
                      // Toggling off reverts this survey to draft
                      openConfirmationModal({
                        title: "Remove from Trust Page",
                        description:
                          "This questionnaire will no longer appear in your Trust Page. You can add it back at any time.",
                        cancelText: "Cancel",
                        buttonText: "Remove",
                        dangerousAction: true,
                        buttonAction: async () => {
                          try {
                            await dispatch(
                              setPrefilledSurveyPublished(q.id, false)
                            );

                            // If this was linked to an original survey, refresh it in the background
                            if (q.originalID > 0) {
                              dispatch(fetchSurveyDetails(q.originalID, true));
                            }

                            dispatch(
                              TrustPageAPI.util.invalidateTags([
                                TrustPageTagTypes.ownTrustPage,
                                TrustPageTagTypes.ownTrustPageAccess,
                              ])
                            );
                            trackEvent(
                              "VerifiedVendor_RevertQuestionnaireToDraft"
                            );
                            dispatch(
                              addDefaultSuccessAlert(
                                "Questionnaire removed from Trust Page"
                              )
                            );
                          } catch (e) {
                            console.error(e);
                            dispatch(
                              addDefaultUnknownErrorAlert(
                                "Error removing from Trust Page"
                              )
                            );
                            throw e;
                          }
                        },
                      });
                    } else {
                      // Toggling on adds this survey to the shared profile
                      openConfirmationModal({
                        title: "Publish to Trust Page",
                        description:
                          "Publishing a questionnaire will make it visible to all users with access to your Trust Page.",
                        cancelText: "Cancel",
                        buttonText: "Publish",
                        buttonAction: async () => {
                          try {
                            await dispatch(submitPrefilledSurvey(q.id, true));
                            await dispatch(
                              setPrefilledSurveyPublished(q.id, true)
                            );
                            dispatch(
                              TrustPageAPI.util.invalidateTags([
                                TrustPageTagTypes.ownTrustPage,
                                TrustPageTagTypes.ownTrustPageAccess,
                              ])
                            );
                            trackEvent("VerifiedVendor_PublishQuestionnaire");
                            dispatch(
                              addDefaultSuccessAlert(
                                "Questionnaire published to Trust Page"
                              )
                            );
                          } catch (e) {
                            console.error(e);
                            dispatch(
                              addDefaultUnknownErrorAlert(
                                "Error publishing to Trust Page"
                              )
                            );
                            throw e;
                          }
                        },
                      });
                    }
                  }}
                />
              </SidePopupV2>
            )}
            <CircledIcon iconClass="cr-icon-questionnaire" />
            <div
              className={classnames("q-cell-main", {
                "no-desc": !q.description && !shouldShowUnsavedChangesSection,
              })}
            >
              <SurveyName survey={q} />
              {q.description ? (
                <div className="q-description">{q.description}</div>
              ) : null}
              {shouldShowUnsavedChangesSection && (
                <div className="q-unsavedchanges">
                  <h3>
                    Unpublished changes have been made to this questionnaire
                  </h3>
                  <p>
                    Changes have been made to this questionnaire since it was
                    added to your Trust Page. Would you like to publish these
                    changes or discard them?
                  </p>
                  <div className="btn-group">
                    <Link
                      to={getSurveyEditorPath({
                        surveyId: q.id,
                        publicSurvey: true,
                        editMode: true,
                        location: history.location,
                      })}
                    >
                      <Button tertiary>Open</Button>
                    </Link>
                    <Button
                      onClick={() =>
                        openConfirmationModal({
                          title: "Discard changes to questionnaire",
                          description:
                            "The latest unpublished changes to the questionnaire will be discarded. The questionnaire will remain published.",
                          cancelText: "Cancel",
                          buttonText: "Discard changes",
                          dangerousAction: true,
                          buttonAction: async () => {
                            try {
                              await dispatch(rollbackPrefilledSurvey(q.id));

                              dispatch(
                                TrustPageAPI.util.invalidateTags([
                                  TrustPageTagTypes.ownTrustPage,
                                  TrustPageTagTypes.ownTrustPageAccess,
                                ])
                              );

                              trackEvent(
                                "VerifiedVendor_DiscardQuestionnaireChanges"
                              );
                              dispatch(
                                addDefaultSuccessAlert(
                                  "Discarded unpublished changes"
                                )
                              );
                            } catch (e) {
                              console.error(e);
                              dispatch(
                                addDefaultUnknownErrorAlert(
                                  "Error discarding changes"
                                )
                              );
                              throw e;
                            }
                          },
                        })
                      }
                    >
                      Discard changes
                    </Button>
                    <Button
                      primary
                      onClick={() =>
                        openConfirmationModal({
                          title: "Publish changes to questionnaire",
                          description:
                            "The latest changes to the questionnaire will become visible to all users with access to your Trust Page.",
                          cancelText: "Cancel",
                          buttonText: "Publish",
                          buttonAction: async () => {
                            try {
                              await dispatch(submitPrefilledSurvey(q.id));
                              dispatch(
                                TrustPageAPI.util.invalidateTags([
                                  TrustPageTagTypes.ownTrustPage,
                                  TrustPageTagTypes.ownTrustPageAccess,
                                ])
                              );
                              trackEvent(
                                "VerifiedVendor_PublishQuestionnaireChanges"
                              );
                              dispatch(
                                addDefaultSuccessAlert("Published changes")
                              );
                            } catch (e) {
                              console.error(e);
                              dispatch(
                                addDefaultUnknownErrorAlert(
                                  "Error publishing changes"
                                )
                              );
                              throw e;
                            }
                          },
                        })
                      }
                    >
                      <div className="cr-icon-check" />
                      Publish changes
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </XTableCell>,
        <XTableCell key="date_published" className="date-completed-cell">
          <div className="fortypx-container">
            {q.dateCompleted ? (
              moment(q.dateCompleted).format("ll")
            ) : (
              <em>Not completed</em>
            )}
          </div>
        </XTableCell>,
      ];

      if (userHasWritePrefilledSurveysPermission) {
        cells.push(
          <XTableCell key="status" className="status-cell">
            <div className="fortypx-container">
              {q.status === PrefilledSurveyStatus.Published ? (
                <PillLabel color={LabelColor.Green}>Included</PillLabel>
              ) : (
                <PillLabel color={LabelColor.Grey}>Not included</PillLabel>
              )}
            </div>
          </XTableCell>
        );
      }

      // We also need to add additional rows for each attachment added to a survey.
      const attachments = getUniqueAttachmentsFromAttachmentGroups(
        q.attachmentGroups || []
      );

      const rowId = `prefilled${q.id}`;
      rows.push({
        id: rowId,
        onClick: rowClick,
        iconOptions,
        expanded: attachments.length > 0 && !collapsedRows[rowId],
        expandDisabled: attachments.length === 0,
        expandContent:
          attachments.length > 0 ? (
            <SharedProfileAttachmentsSubtable
              isOwnSharedProfile={isOwnSharedProfile}
              attachments={attachments}
              userHasNoAccess={userHasNoAccess}
              surveyId={q.id}
              location={history.location}
            />
          ) : undefined,
        cells: cells,
      });
    } else if (q.kind === "ISharedAssessmentAddToProfileSurvey") {
      const iconOptions: IIconOption[] = [];

      // Make sure this user actually has permission to view/edit the original questionnaire
      const canViewPrivateSurvey = receivedSurveys.some(
        (s) => s.id === q.surveyId
      );

      if (canViewPrivateSurvey) {
        iconOptions.push({
          id: "edit",
          icon: <div className="cr-icon-pencil" />,
          hoverText: "View/edit original questionnaire",
          onClick: () =>
            history.push(`/vendors/surveys/${q.surveyId}`, {
              backContext: {
                backTo: "/trustpage",
                backToText: "Back to My Trust Page",
              },
            }),
        });
      }

      const row: IXTableRow = {
        id: `private${q.surveyId}`,
        iconOptions,
        expandDisabled: true,
        cells: [
          <XTableCell key="questionnaire">
            <div className="q-cell">
              {isOwnSharedProfile && (
                <ToggleSwitch
                  name="published"
                  stopPropagation
                  disabled={
                    !userHasWritePrefilledSurveysPermission ||
                    !canViewPrivateSurvey
                  }
                  selected
                  onClick={() => {
                    // Toggling off a private survey should just turn off the 'include in Trust Page' toggle.
                    openConfirmationModal({
                      title: "Do you want to remove this questionnaire?",
                      description: (
                        <>
                          If you remove this questionnaire it will not be added
                          to your Trust Page when it is submitted.
                        </>
                      ),
                      cancelText: "Cancel",
                      buttonText: "Remove questionnaire",
                      buttonAction: async () => {
                        try {
                          await dispatch(
                            updateVendorSurveyAddToSharedProfile(
                              q.surveyId,
                              false
                            )
                          );
                          await dispatch(fetchSurveyDetails(q.surveyId, true));
                          dispatch(
                            TrustPageAPI.util.invalidateTags([
                              TrustPageTagTypes.ownTrustPage,
                              TrustPageTagTypes.ownTrustPageAccess,
                            ])
                          );
                          trackEvent(
                            "VerifiedVendor_TurnOffIncludeInSharedProfile"
                          );
                          dispatch(
                            addDefaultSuccessAlert(
                              "Questionnaire removed from Trust Page"
                            )
                          );
                        } catch (e) {
                          console.error(e);
                          dispatch(
                            addDefaultUnknownErrorAlert(
                              "Error removing questionnaire from Trust Page"
                            )
                          );
                          throw e;
                        }
                      },
                    });
                  }}
                />
              )}
              <CircledIcon iconClass="cr-icon-questionnaire" />
              <div
                className={classnames("q-cell-main", {
                  "no-desc": !q.description,
                })}
              >
                <SurveyName survey={{ name: q.baseName, type: q.name }} />
                {q.description ? (
                  <div className="q-description">{q.description}</div>
                ) : null}
              </div>
            </div>
          </XTableCell>,
          <XTableCell key="date_published" className="date-completed-cell">
            <div className="fortypx-container">
              <em>Not completed</em>
            </div>
          </XTableCell>,
        ],
      };

      if (userHasWritePrefilledSurveysPermission) {
        row.cells.push(
          <XTableCell key="status" className="status-cell">
            <div className="fortypx-container">
              <PillLabel color={LabelColor.Orange}>
                Awaiting Completion
              </PillLabel>
            </div>
          </XTableCell>
        );
      }

      rows.push(row);
    }
  }

  return (
    <>
      <XTable
        iconOptions
        expandableRows
        onExpandToggle={toggleExpandRow}
        className="shared-profile-surveys-table"
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        columnHeaders={getQuestionnairesTableHeaders(
          userHasWritePrefilledSurveysPermission
        )}
        rows={rows}
      />
      {confirmationModal}
    </>
  );
};

export default SharedProfileQuestionnairesTable;

import {
  IMySharedAssessmentAccess,
  ISharedAssessment,
} from "../../../vendorrisk/types/sharedAssessment";
import DropdownV2, {
  DropdownItem,
  DropdownSeparator,
} from "../../../_common/components/core/DropdownV2";
import Button from "../../../_common/components/core/Button";
import { formatDateAsLocal } from "../../../_common/helpers";
import { FC } from "react";
import styles from "./PublishTrustPageButton.module.scss";
import CopyTextDisplay from "../../../_common/components/copyTextDisplay/CopyTextDisplay";
import { trustPageSettingsRoute } from "../../TrustPageAppRouter";
import { useDefaultHistory } from "../../../_common/types/router";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { updateSharedAssessmentSharingSettings } from "../../../vendorrisk/reducers/verifiedVendors.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import classNames from "classnames";

interface PublishTrustPageButtonProps {
  sharedAssessment: ISharedAssessment;
  sharedAssessmentAccess: IMySharedAssessmentAccess;
  primary?: boolean;
  buttonText?: string;
}

const PublishTrustPageButton: FC<PublishTrustPageButtonProps> = ({
  sharedAssessment,
  sharedAssessmentAccess,
  primary,
  buttonText = "Publish",
}) => {
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const goToSettings = () => {
    history.push(trustPageSettingsRoute, {
      backContext: {
        backTo: history.location.pathname,
        backToText: "Back",
      },
    });
  };

  const openPublicLink = () => {
    window.open(sharedAssessment.publicUrl, "_blank");
  };

  const setPublished = (publish: boolean) => {
    dispatch(
      updateSharedAssessmentSharingSettings(
        publish,
        !sharedAssessmentAccess.access.publicAccess,
        sharedAssessmentAccess.access.requireNda,
        sharedAssessmentAccess.access.ndaText
      )
    )
      .then(() => {
        // TODO replace this with publish modal when merged
        dispatch(addDefaultSuccessAlert("Publish status changed"));
      })
      .catch(() => {
        dispatch(addDefaultUnknownErrorAlert("Error changing publish status"));
      });
  };

  return (
    <DropdownV2
      noCloseOnClickInside={true}
      className={styles.publishTrustPageButton}
      popupItem={
        <Button primary={primary}>
          {buttonText} <i className={"cr-icon-chevron rotate-90"} />
        </Button>
      }
    >
      <DropdownItem
        className={classNames(styles.noHover, styles.customDropdownItem)}
      >
        <>
          <div
            className={classNames(
              styles.dropdownInnerItem,
              styles.copyTextDisplayContainer
            )}
          >
            <i className={"cr-icon-link-2"} />
            <CopyTextDisplay
              value={
                sharedAssessmentAccess.access.published
                  ? sharedAssessment.publicUrl
                  : "Link active when published"
              }
              disabled={!sharedAssessmentAccess.access.published}
            />
          </div>
          <div className={styles.dropdownInnerItem}>
            <i className={"cr-icon-timer"} /> Last updated:{" "}
            {sharedAssessment.updatedAt
              ? formatDateAsLocal(sharedAssessment.updatedAt)
              : "Never"}
          </div>
        </>
      </DropdownItem>
      <DropdownSeparator />
      <DropdownItem
        onClick={goToSettings}
        className={styles.customDropdownItem}
      >
        <div className={styles.dropdownInnerItem}>
          <i className={"cr-icon-cog2"} />
          Configure settings
        </div>
        <div
          className={classNames(styles.dropdownInnerItem, styles.description)}
        >
          Set up your Trust Page with access protection or an NDA.
        </div>
      </DropdownItem>
      <DropdownSeparator />
      <DropdownItem
        className={classNames(styles.noHover, styles.horizontalDropdownItem)}
      >
        {sharedAssessmentAccess.access.published && (
          <>
            <Button tertiaryDanger onClick={() => setPublished(false)}>
              Unpublish
            </Button>
            <Button primary onClick={openPublicLink}>
              Visit Trust Page
            </Button>
          </>
        )}
        {!sharedAssessmentAccess.access.published && (
          <Button
            primary
            className={styles.publishButton}
            onClick={() => setPublished(true)}
          >
            <i className={"cr-icon-check"} />
            Publish Trust Page
          </Button>
        )}
      </DropdownItem>
    </DropdownV2>
  );
};

export default PublishTrustPageButton;

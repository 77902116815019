import { RouteComponentProps, useHistory } from "react-router-dom";
import { Filters } from "../../vendorrisk/components/filter/types";
import { useCallback } from "react";

export interface backContext {
  backTo?: string;
  goBack?: boolean;
  backToText: string;
  backToContext?: any;
  restorePreviousFilters?: Filters;
}

export interface locationState {
  keepScrollPosition?: boolean;
  backContext?: backContext;
  sidePanelBackText?: string;
  noRemoveWhispers?: boolean;
  noBackButton?: boolean;
  sidePanelBackStackCount?: number;
  doNotBlock?: boolean;
}

// Wrap react-router's RouteComponentProps (the props that get passed to every route component)
// with our default locationState.
export type DefaultRouteProps<
  Params extends { [K in keyof Params]?: string } = object,
  ExtraLocationState = unknown,
> = RouteComponentProps<Params, any, locationState & ExtraLocationState>;

// useDefaultHistory is useHistory with the default locationState set.
export const useDefaultHistory = <LocationState = unknown>() =>
  useHistory<LocationState & locationState>();

type BackAction = (() => void) | undefined;

interface Back {
  backContext?: backContext;
  backAction?: BackAction;
  backText: string;
}

export const useBack = (fallback?: backContext): Back => {
  const history = useDefaultHistory();
  const backContext = history.location.state?.backContext ?? fallback;

  const goBack = backContext?.goBack;
  const backTo = backContext?.backTo;
  const backText = backContext?.backToText ?? "";

  const goBackTo = useCallback(
    function goBackTo() {
      if (backTo) history.push(backTo, backContext?.backToContext);
    },
    [backTo, backContext?.backToContext]
  );

  const backAction: BackAction = goBack
    ? history.goBack
    : backTo
      ? goBackTo
      : undefined;

  return { backAction, backContext, backText };
};

import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../types/correspondenceMessage";
import "../../style/components/MessagesPanel.scss";
import { IUserMiniMap } from "../../types/user";
import {
  addSurveyMessage,
  editSurveyMessage,
  fetchSurveyMessages,
} from "../../reducers/surveyDetails.actions";
import MessagesPanel, { MessageGroup } from "../MessagesPanel";
import Button from "../core/Button";
import { locationState } from "../../types/router";
import { Location } from "history";
import "../../style/components/surveydetails/SurveyDetailsMessagesPanel.scss";
import classnames from "classnames";
import SlidePanel, { IBaseSlidePanelProps } from "../SlidePanel";
import { useEffect, useState } from "react";
import {
  GENERAL_GROUP_ID,
  getGroupsForMessages,
  getGroupTitle,
  getQuestionPartsForGroup,
} from "./surveyDetails.helpers";
import {
  countUnreadMessages,
  setViewedInUI,
  updateMessageContent,
} from "../correspondence/correspondence.helpers";
import { setSurveyData } from "../../reducers/commonActions";
import { getSurveyEditorPath } from "../../views/SurveyDetails";
import { useHistory } from "react-router-dom";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import { SurveyUsageType } from "../../types/surveyTypes";
import { produce } from "immer";
import { useAppDispatch } from "../../types/reduxHooks";

export interface ISurveyDetailsMessagesPanelProps extends IBaseSlidePanelProps {
  surveyId: number;
  messages?: ICorrespondenceMessage<SurveyQuestionMessageMeta>[];
  users?: IUserMiniMap;
  currentUserId: number;
  hasWritePermission: boolean;
  otherVendorName: string;
  allowedPrivateMessages: boolean;
  isVendorPortal: boolean;
  location: Location<locationState>;
  surveyUsageType: SurveyUsageType;
  isManagementAnalystSession: boolean;
  userIsManagedVendorAnalyst: boolean;
  isAnalystWorkflowSurvey: boolean;
}

const SurveyDetailsMessagesPanel = (
  props: ISurveyDetailsMessagesPanelProps
) => {
  const {
    active,
    onClose,
    surveyId,
    messages,
    users,
    currentUserId,
    hasWritePermission,
    otherVendorName,
    allowedPrivateMessages,
    isVendorPortal,
    location,
    surveyUsageType,
    isManagementAnalystSession,
    userIsManagedVendorAnalyst,
    isAnalystWorkflowSurvey,
  } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [currentGroupId, setCurrentGroupId] = useState<string | undefined>(
    undefined
  );

  // Add a new message, add it to redux and mark it as read on the backend
  const addNewSurveyMessage = (
    parentId: number | undefined,
    content: string,
    isPrivate: boolean,
    questionId?: string
  ) => {
    return dispatch(
      addSurveyMessage(surveyId, parentId, content, isPrivate, questionId)
    )
      .then((resp) => {
        const newMessages = produce(messages, (draftMessages) => {
          if (!draftMessages) {
            return;
          }

          if (!parentId) {
            draftMessages.unshift(resp.message);
          } else {
            const parent = draftMessages.find((p) => p.id === parentId);
            if (parent) {
              const children = parent.children ?? [];
              children.unshift(resp.message);
              parent.children = children;
            }
          }
        });

        // If the user is a new user, we'll need to add them to the set of message users
        const existingUsers = { ...users };
        if (existingUsers[resp.user.id] === undefined) {
          existingUsers[resp.user.id] = resp.user;
        }

        // Update redux state
        dispatch(
          setSurveyData(surveyId, {
            messages: {
              messages: newMessages,
              users: existingUsers,
            },
          })
        );

        // Fire off a fetch to mark the message as read for the user
        dispatch(
          fetchSurveyMessages(
            surveyId,
            true,
            false,
            questionId,
            questionId === undefined,
            true
          )
        );
      })
      .catch((e) => {
        console.error("error adding message", e);
        dispatch(addDefaultUnknownErrorAlert("Error adding new message"));
      });
  };

  const [groups, setGroups] = useState<MessageGroup[] | undefined>(
    messages
      ? getGroupsForMessages(messages, addNewSurveyMessage, true)
      : undefined
  );

  // Respond to any updates to messages - update groups and set group id if needed
  useEffect(() => {
    const newGroups = messages
      ? getGroupsForMessages(messages, addNewSurveyMessage, true)
      : undefined;

    setGroups(newGroups);

    // If only general group, then just focus that
    if (
      newGroups &&
      newGroups.length === 1 &&
      newGroups[0].id === GENERAL_GROUP_ID
    ) {
      setCurrentGroupId(newGroups[0].id);
    }
  }, [messages]);

  const currentGroup = groups?.find((g) => g.id === currentGroupId);

  const isOnlyGeneralGroup =
    groups && groups.length === 1 && groups[0].id === GENERAL_GROUP_ID;

  const emptyContent = hasWritePermission ? (
    allowedPrivateMessages ? (
      <>
        <p>
          There are no messages here yet. Use the box below to send a message
          regarding this questionnaire. Any recipients of the questionnaire will
          be notified.
        </p>
        <p>
          Alternatively, you can add an internal note that can only be seen by
          you and members of your organization.
        </p>
      </>
    ) : (
      <>
        <p>
          There are no messages here yet. Use the box below to send a message
          regarding this questionnaire.
        </p>
        <p>
          The organisation who sent you this questionnaire will be notified.
        </p>
      </>
    )
  ) : undefined;

  const sendDisabled =
    !isVendorPortal &&
    isAnalystWorkflowSurvey &&
    (!isManagementAnalystSession || !userIsManagedVendorAnalyst);

  return (
    <SlidePanel
      newStyles
      dimContent
      className="survey-details-messages-panel"
      title={
        <div className={"title-content"}>
          <div className={"group-back"}>
            {currentGroup && (
              <Button
                tertiary
                onClick={() => {
                  if (isOnlyGeneralGroup) {
                    onClose();
                  } else {
                    setCurrentGroupId(undefined);
                  }
                }}
              >
                <i className={"cr-icon-chevron rot180"} /> Messages /{" "}
                {getQuestionPartsForGroup(currentGroup)[0]}
              </Button>
            )}
          </div>
          {currentGroup &&
            getGroupTitle(currentGroup, surveyId, location, isVendorPortal) && (
              <div className={"group-title"}>
                {getGroupTitle(
                  currentGroup,
                  surveyId,
                  location,
                  isVendorPortal
                )}
              </div>
            )}
          {!currentGroup && <>Messages</>}
        </div>
      }
      onClose={onClose}
      active={active}
    >
      <MessagesPanel
        className={"survey-details-messages"}
        currentUserId={currentUserId}
        hasWritePermission={hasWritePermission}
        sendDisabled={sendDisabled}
        otherVendorName={
          surveyUsageType == SurveyUsageType.Security
            ? otherVendorName
            : undefined
        }
        publicReplyHelp={
          "Public messages will be visible to members of your account as well as any users added to this questionnaire."
        }
        allowedPrivateMessages={allowedPrivateMessages}
        editMessage={(messageId, newContent) => {
          return dispatch(editSurveyMessage(messageId, newContent))
            .then(() => {
              const newMessages = produce(messages, (draftMessages) => {
                if (!draftMessages) {
                  return;
                }

                updateMessageContent(draftMessages, messageId, newContent);
              });

              dispatch(
                setSurveyData(surveyId, {
                  messages: {
                    messages: newMessages,
                    users: users,
                  },
                })
              );
            })
            .catch((e) => {
              console.error(e);
              dispatch(addDefaultUnknownErrorAlert("Error editing message"));
            });
        }}
        addMessage={(parentId, content, isPrivate) =>
          addNewSurveyMessage(parentId, content, isPrivate)
        }
        users={users}
        emptyContent={emptyContent}
        getClassForMessage={(
          message: ICorrespondenceMessage<SurveyQuestionMessageMeta>
        ) =>
          classnames({
            "system-generated": message.meta?.isQuestionAnswer,
          })
        }
        groups={groups}
        currentGroup={currentGroup}
        onGroupSelect={(group) => {
          if (!group && isOnlyGeneralGroup) {
            onClose();
          } else if (group?.id !== currentGroup) {
            if (group?.id === GENERAL_GROUP_ID) {
              setCurrentGroupId(group?.id ?? undefined);

              if (group && countUnreadMessages(group.messages) > 0) {
                // Mark groups messages as read
                dispatch(
                  fetchSurveyMessages(
                    surveyId,
                    true,
                    false,
                    group?.id !== GENERAL_GROUP_ID ? group?.id : undefined,
                    group?.id === GENERAL_GROUP_ID,
                    true
                  )
                ).then((resp) => {
                  // Update seen state
                  const allMessages = messages ? [...messages] : [];
                  setViewedInUI(allMessages, resp.messages);
                  // Update redux state
                  dispatch(
                    setSurveyData(surveyId, {
                      messages: {
                        messages: allMessages,
                        users: users,
                      },
                    })
                  );
                });
              }
            } else if (group) {
              // Assume it's a message group in regards to a question, so just navigate to the viewer
              const questionId =
                group.messages.length > 0 && group.messages[0].meta?.questionId
                  ? group.messages[0].meta?.questionId
                  : undefined;

              history.push(
                getSurveyEditorPath({
                  surveyId: surveyId,
                  publicSurvey: false,
                  editMode: isVendorPortal,
                  location: location,
                  questionId: questionId,
                  showComments: true,
                })
              );
            }
          }
        }}
      />
    </SlidePanel>
  );
};

export default SurveyDetailsMessagesPanel;

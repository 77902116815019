import React, { useCallback, useEffect } from "react";
import { debounce as _debounce } from "lodash";

import SearchPlain from "../../vendorrisk/images/search-plain.svg";
import Button from "./core/Button";

import "../style/components/SearchBox.scss";
import classNames from "classnames";

interface ISearchBoxProps {
  className?: string;

  // Optional placeholder value
  placeholder?: string;
  // Pass empty string via value prop to reset. Initial render will use initial value passed in.
  value: string;
  //Disable search box control
  disabled?: boolean;
  // Debounced callback triggered on input change
  onChanged: (val: string) => void;
  minLength?: number; // If set, callback only triggered when this minimum is reached
  maxLength?: number;

  // Optionally focus on the search box on component load
  autoFocus?: boolean;

  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;

  // Optional callback for when the searchbox is cleared by the user
  onClear?: () => void;

  // Optional ref for the <input> element
  inputRef?: React.RefObject<HTMLInputElement>;

  // indicate if focus should be re-applied on clear
  returnFocusOnClear?: boolean;
}

/**
 * Simple SearchBox component with a debounce.
 */
const SearchBox = (props: ISearchBoxProps) => {
  const [val, setVal] = React.useState(() => props.value || "");
  const inputRefLocal = React.useRef<HTMLInputElement>(null);

  const debouncedNotify = useCallback(_debounce(props.onChanged, 500), []);

  const changed = (newVal: string) => {
    setVal(newVal);

    if (!props.minLength || newVal === "" || newVal.length >= props.minLength) {
      debouncedNotify(newVal);
    }

    if (newVal === "" && props.onClear) {
      props.onClear();
    }
  };

  // Reset
  useEffect(() => {
    if (props.value === "") {
      setVal("");
    }
  }, [props.value]);

  // Autofocus if needed
  useEffect(() => {
    if (props.autoFocus) {
      const ref = props.inputRef ?? inputRefLocal;
      // timeout is needed to ensure all transition are completed before calling focus
      setTimeout(() => {
        if (ref && ref.current !== null) {
          ref.current.focus();
        }
      }, 1);
    }
  }, [props.autoFocus]);

  return (
    <div className={classNames("search-box-input-container", props.className)}>
      <div className="search-box-input">
        <input
          type="text"
          placeholder={props.placeholder}
          value={val}
          onChange={(event) => changed(event.target.value)}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          disabled={props.disabled}
          maxLength={props.maxLength}
          ref={props.inputRef ?? inputRefLocal}
        />
        <img
          alt="magnifying glass"
          src={SearchPlain}
          className="search-icon"
          height={"20"}
        />
        {val.length > 0 && (
          <Button
            tertiary
            onClick={(ev) => {
              ev.preventDefault();
              changed("");
              if (props.returnFocusOnClear) {
                const ref = props.inputRef ?? inputRefLocal;
                ref.current?.focus();
              }
            }}
            disabled={props.disabled}
          >
            <div className="icon-close" />
          </Button>
        )}
      </div>
    </div>
  );
};

SearchBox.defaultProps = {
  placeholder: "Search...",
  disabled: false,
  maxLength: undefined,
  autoFocus: false,
};

export default SearchBox;

export interface SimpleSearchBoxProps {
  value: string;
  placeholder?: string;
  showClear?: boolean;
  className?: string;

  onChanged: (val: string) => void;
  onClear?: () => void;

  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;

  disabled?: boolean;
  minLength?: number;
  maxLength?: number;

  autoFocus?: boolean;

  inputRef?: React.RefObject<HTMLInputElement>;
  role?: Exclude<HTMLInputElement["role"], null>;
}

// SimpleSearchBox is similar to SearchBox, but the value is always controlled by the parent component.
export function SimpleSearchBox({
  role = "searchbox",
  value,
  onChanged,
  showClear,
  minLength,
  maxLength,
  placeholder,
  autoFocus,
  onFocus,
  onBlur,
  onKeyDown,
  disabled,
  inputRef,
  className,
}: SimpleSearchBoxProps) {
  const inputRefLocal = React.useRef<HTMLInputElement>(null);
  const [pendingValue, setPendingValue] = React.useState<string | null>(null);

  const ref = inputRef ?? inputRefLocal;

  const committed = (newVal: string) => {
    onChanged(newVal);
    setPendingValue(null);
  };
  const debouncedCommit = useCallback(_debounce(committed, 500), []);

  const changed = (newVal: string) => {
    if (!minLength || newVal === "" || newVal.length >= minLength) {
      setPendingValue(newVal);
      debouncedCommit(newVal);
    }
  };

  const shownValue = pendingValue ?? value;
  showClear = showClear ?? !!shownValue;

  return (
    <div className={classNames("search-box-input-container", className)}>
      <div className="search-box-input">
        <input
          role={role}
          type="text"
          placeholder={placeholder || "Search..."}
          value={shownValue}
          autoFocus={autoFocus}
          onChange={(event) => changed(event.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
          maxLength={maxLength}
          ref={ref}
        />
        <img
          alt="magnifying glass"
          src={SearchPlain}
          className="search-icon"
          height={"20"}
        />
        {showClear && (
          <Button
            tertiary
            onClick={(ev) => {
              ev.preventDefault();
              committed("");
            }}
            disabled={disabled}
          >
            <div className="icon-close" />
          </Button>
        )}
      </div>
    </div>
  );
}

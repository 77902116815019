// Returns background color for CSTAR Scorecard
export const getCSTARScoreBackgroundColorHex = (score: number) => {
  switch (true) {
    case score < 0:
      return "#F9F9FB";
    case score < 201:
      return "#FDF2F2";
    case score < 401:
      return "#FFFAF2";
    case score < 601:
      return "#FFFCF3";
    case score < 801:
      return "#F4FBF7";
    default:
      return "#E9F7EF";
  }
};

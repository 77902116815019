import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { FC, useState } from "react";
import TrustPageAPI, { TrustPageTagTypes } from "../../api/trustpage.api";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import "../../style/ExcludeQuestionnaireConfirmationModal.scss";
import { fetchSurveyDetails } from "../../../_common/reducers/surveyDetails.actions";
import { updateVendorSurveyAddToSharedProfile } from "../../../vendorrisk/reducers/cyberRiskActions";

interface ExcludeQuestionnaireConfirmationModalProps extends BaseModalProps {
  prefilledSurveyId?: number;
  originalSurveyId?: number;
}

const ExcludeQuestionnaireConfirmationModal: FC<
  ExcludeQuestionnaireConfirmationModalProps
> = ({
  active,
  onClose,
  prefilledSurveyId,
  originalSurveyId,
}: ExcludeQuestionnaireConfirmationModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [updatePrefilledSurveyState] =
    TrustPageAPI.useSetPrefilledSurveyPublishedV1Mutation();

  const handleExcludeQuestionnaire = () => {
    setLoading(true);
    if (prefilledSurveyId && prefilledSurveyId > 0) {
      updatePrefilledSurveyState({
        surveyId: prefilledSurveyId,
        published: false,
      })
        .unwrap()
        .then(() => {
          if (originalSurveyId && originalSurveyId > 0) {
            //re-fetch original survey details to reflect the changes
            dispatch(fetchSurveyDetails(originalSurveyId, true));
          }
          dispatch(
            addDefaultSuccessAlert("Questionnaire removed from Trust Page")
          );
          onClose();
        })
        .catch((e) => {
          console.error(e);
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error removing questionnaire from Trust Page"
            )
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (originalSurveyId && originalSurveyId > 0) {
      // prefilled survey is not exist, mark original private survey as not shared
      dispatch(updateVendorSurveyAddToSharedProfile(originalSurveyId, false))
        .then(() =>
          dispatch(
            TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
          )
        )
        .then(() => dispatch(fetchSurveyDetails(originalSurveyId, true)))
        .then(() => {
          if (originalSurveyId && originalSurveyId > 0) {
            //re-fetch original survey details to reflect the changes
            dispatch(fetchSurveyDetails(originalSurveyId, true));
          }
          dispatch(
            addDefaultSuccessAlert("Questionnaire removed from Trust Page")
          );
          onClose();
        })
        .catch((e) => {
          console.error(e);
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error removing questionnaire from Trust Page"
            )
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <ModalV2
      className="exclude-questionnaire-modal"
      width={600}
      active={active}
      onClose={onClose}
      headerContent="Exclude questionnaire?"
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={"delete-button"}
            danger
            onClick={handleExcludeQuestionnaire}
            loading={loading}
          >
            <i className="cr-icon-trash" />
            Remove
          </Button>
        </div>
      }
    >
      <p>
        The questionnaire will be removed from your Trust Page but can be added
        back at any time.
      </p>
    </ModalV2>
  );
};

export default ExcludeQuestionnaireConfirmationModal;

import { useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice, { Module } from "../Slice";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { getDateOfLastFeedDownload } from "../views/ThreatMonitoringView";

export default function useFeedTotals(module: Module) {
  const feedPages = useAppSelector((s) =>
    tmSlice.selectors.feedPages(s, module)
  );

  const extra = {
    datePeriodTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return ThreatMonitoringAPI.useGetResultTotalsV2Query(
    {
      open: { ...feedPages.open.query.filters, ...extra },
      closed: { ...feedPages.closed.query.filters, ...extra },
      investigating: { ...feedPages.investigating.query.filters, ...extra },
      remediating: { ...feedPages.remediating.query.filters, ...extra },
      openFeedUpTo: getDateOfLastFeedDownload(),
    },
    { refetchOnMountOrArgChange: true }
  );
}

import { FC } from "react";
import UpGuardLogo from "../../_common/images/upguard-logo-black.svg";
import classnames from "classnames";
import "../style/TrustPageFooter.scss";
import { openInNewTab } from "../helpers/helpers";

interface TrustPageFooterProps {}

const TrustPageFooter: FC<TrustPageFooterProps> = () => {
  return (
    <div
      className="trust-page-footer"
      data-testid={"trust-page-footer"}
      onClick={() => openInNewTab("https://upguard.com")}
    >
      <div className="logo">
        <img
          src={UpGuardLogo}
          alt="UpGuard Logo"
          className={classnames("ug-logo", {
            open: true,
          })}
        />
      </div>
      {`Powered by UpGuard, the best platform for securing your organization's sensitive data.`}
    </div>
  );
};

export default TrustPageFooter;

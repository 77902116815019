import classnames from "classnames";

import "./ThreatMonitoringFeedItem.scss";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import SeverityIcon from "../../_common/components/SeverityIcon";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import {
  DisplayableThreatMonitoringResult,
  CookieAdditionalAttributes,
  ThreatMonitoringResultState,
  ThreatMonitoringThreatType,
  PasswordAdditionalAttributes,
  ChatAdditionalAttributes,
  PostAdditionalAttributes,
  ThreatMonitoringVendorNetworkType,
  ThreatMonitoringSourceType,
} from "../api/types";
import moment from "moment";
import { threatTypeToString } from "../funcs/domain";
import "./ThreatMonitoringFeedItem.scss";
import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import { Severity } from "../../_common/types/severity";
import EllipsizedText from "../../_common/components/EllipsizedText";
import useUpdateResultState from "../funcs/useUpdateResultState";
import { useModalV2 } from "../../_common/components/ModalV2";
import CreateRemediationdModal from "./CreateRemediationModal";
import { useParseSnippet } from "../funcs/useParseSnippet";
import useResizeObserver from "@react-hook/resize-observer";
import { useDebounceCallback } from "@react-hook/debounce";
import { PasswordRevealerWithTooltip } from "../../_common/components/PasswordRevealer";
import { ThreadEntry, ThreadEntryType } from "./Thread";
import EllipsisedURL from "./EllipsisedURL";
import ThreatSource from "./ThreatSource";
import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../_common/components/core/DropdownV2";
import RemediateThreatModal from "./RemediateThreatModal";
import { Module } from "../Slice";
import KeywordsPills from "./KeywordsPills";

const threatTypeNames = (threatTypes: ThreatMonitoringThreatType[]) => {
  return threatTypes
    .map((threatType) => threatTypeToString(threatType))
    .join(", ")
    .trim();
};

export interface ThreatMonitoringFeedItemProps {
  result: DisplayableThreatMonitoringResult;
  active: boolean;
  className?: string;
  selected?: boolean;
  onSelect: (selected: boolean) => void;
  userHasWritePermission: boolean;
  onViewThreatDetails: () => void;
  module: Module;
}

// ThreatMonitoringFeedItem
// A component that displays ...
const ThreatMonitoringFeedItem = (props: ThreatMonitoringFeedItemProps) => {
  const {
    result,
    selected,
    onSelect,
    userHasWritePermission,
    onViewThreatDetails,
    module,
  } = props;

  const [dismiss, isDismissing] = useUpdateResultState({
    state: ThreatMonitoringResultState.Dismissed,
    successMsg: "Threat was dismissed successfully.",
    failMsg: "Failed to dismiss threat.",
    module,
  });

  const [investigating, isInvestigating] = useUpdateResultState({
    state: ThreatMonitoringResultState.Investigating,
    successMsg: "Threat was moved to investigating successfully.",
    failMsg: "Failed to move threat to investigating.",
    module,
  });

  const [remediating, isRemediating] = useUpdateResultState({
    state: ThreatMonitoringResultState.Remediating,
    successMsg: null,
    failMsg: "Failed to move threat to remediating.",
    module,
  });

  const [openRemediationModal, remediationModal] = useModalV2(
    CreateRemediationdModal
  );

  const [openRemediateThreatModal, remediateThreatModal] =
    useModalV2(RemediateThreatModal);

  // TODO: for the time being, this message count of 0 keeps it hidden.
  const messageCount = 0;

  return (
    <>
      <div
        key={result.uuid}
        className={classnames("threat-monitoring-feed-item", {
          className: props.className,
          "sev-low": result.severity === Severity.Low,
          "sev-medium": result.severity === Severity.Medium,
          "sev-high": result.severity === Severity.High,
          "sev-critical": result.severity === Severity.Critical,
        })}
      >
        <div className={classnames("container")}>
          <div className={"title-row"}>
            <ColorCheckbox
              checked={selected}
              disabled={false}
              onClick={() => {
                onSelect(!selected);
              }}
            />
            <EllipsizedText text={result.title} position="top">
              <div onClick={onViewThreatDetails} className={"title"}>
                {result.title}
              </div>
            </EllipsizedText>
            <div className={"icons"}>
              {messageCount > 0 && (
                <>
                  <div className={"cr-icon-carousel-dot"} />
                  <SidePopupV2
                    text={"Add comment"}
                    position={"top"}
                    width={118}
                  >
                    <div className={"cr-icon-chat-messages cr-icon-border"} />
                  </SidePopupV2>
                  <span>{messageCount}</span>
                </>
              )}
              {!isInvestigating && (
                <SidePopupV2
                  text={"Add to Investigating"}
                  position={"top"}
                  width={158}
                >
                  <div
                    className={classnames(
                      "cr-icon-add-bookmark cr-icon-border",
                      {
                        grayed: !userHasWritePermission,
                      }
                    )}
                    onClick={() => {
                      if (userHasWritePermission) {
                        investigating(result.uuid);
                      }
                    }}
                  />
                </SidePopupV2>
              )}
              {isInvestigating && (
                <div className={"loading-container"}>
                  <LoadingIcon size={16} />
                </div>
              )}
              {isRemediating ? (
                <div className={"loading-container"}>
                  <LoadingIcon size={16} />
                </div>
              ) : (
                <SidePopupV2
                  text="Address threat"
                  position={"top"}
                  width={110}
                  noWrap
                >
                  <ButtonWithDropdownV2
                    icon={
                      <div
                        className={classnames(
                          "cr-icon-spanner-outline cr-icon-border",
                          {
                            grayed: !userHasWritePermission,
                          }
                        )}
                      />
                    }
                  >
                    {[
                      <DropdownItem
                        key="remediate"
                        onClick={() =>
                          openRemediationModal({
                            threat: result,
                            onCreateRemediationRequest: (
                              remediationRequestId: number
                            ) =>
                              remediating(result.uuid, {
                                target: { remediationRequestId },
                              }),
                          })
                        }
                      >
                        <div
                          className={"dropdown-button-dropdown-item-content"}
                        >
                          <div className={"header-text"}>
                            Request remediation
                          </div>
                          <div className={"description"}>
                            Ask another user to remediate this threat
                          </div>
                        </div>
                      </DropdownItem>,
                      <DropdownItem
                        key="close"
                        onClick={() => {
                          openRemediateThreatModal({
                            threat: result,
                          });
                        }}
                      >
                        <div
                          className={"dropdown-button-dropdown-item-content"}
                        >
                          <div className={"header-text"}>Close threat</div>
                          <div className={"description"}>
                            Mark this threat as remediated
                          </div>
                        </div>
                      </DropdownItem>,
                    ]}
                  </ButtonWithDropdownV2>
                </SidePopupV2>
              )}
              {isDismissing && (
                <div className={"loading-container"}>
                  <LoadingIcon size={16} />
                </div>
              )}
              {!isDismissing && (
                <SidePopupV2 text={"Dismiss"} position={"top"} width={77}>
                  <div
                    className={classnames("cr-icon-cancel cr-icon-border", {
                      grayed: !userHasWritePermission,
                    })}
                    onClick={() => {
                      if (userHasWritePermission) {
                        dismiss(result.uuid);
                      }
                    }}
                  />
                </SidePopupV2>
              )}
              <SidePopupV2
                text={"View threat details"}
                position={"top"}
                width={103}
              >
                <div
                  className={"chevron-container cr-icon-border"}
                  onClick={onViewThreatDetails}
                >
                  <div className={"cr-icon-chevron"} />
                </div>
              </SidePopupV2>
            </div>
          </div>
          <div className={"body"}>
            <div className={"lhs"}>
              <div className={"row"}>
                <div className={"column1"}>
                  {result.keywords.length > 1 ? "Keywords" : "Keyword"}
                </div>
                <div className={"column2"}>
                  <KeywordsPills keywords={result.keywords} />
                </div>
              </div>
              <div className={"row"}>
                <div className={"column1"}>Severity</div>
                <div className={"column2"}>
                  <SeverityIcon
                    severity={result.severity}
                    className={"severity"}
                    label
                  />
                </div>
              </div>
              <div className={"row"}>
                <div className={"column1"}>Date detected</div>
                <div className={"column2"}>
                  <div className={"sources"}>
                    <div className={"source"}>
                      {moment(result.dateDetected).format("ll")}
                    </div>
                    <div className={"module"}>
                      {moment(result.dateDetected).format("HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className={"column1"}>Threat type</div>
                <div className={"column2"}>
                  {threatTypeNames(result.threatTypes)}
                </div>
              </div>
              <div className={"row"}>
                <div className={"column1"}>Source</div>
                <div className={"column2"}>
                  <div className={"sources"}>
                    <ThreatSource
                      module={result.module}
                      source={result.source}
                      network={result.additionalAttributes?.post?.network}
                    />
                  </div>
                </div>
              </div>
              <OriginURL threat={result} />
            </div>
            <RightHand result={result} />
          </div>
        </div>
      </div>
      {remediationModal}
      {remediateThreatModal}
    </>
  );
};

function OriginURL({ threat }: { threat: DisplayableThreatMonitoringResult }) {
  if (
    threat.additionalAttributes?.cookie ||
    threat.additionalAttributes?.password
  ) {
    return null;
  }

  // telegram, discord, openweb urls are clickable
  if (
    threat.additionalAttributes?.chat ||
    threat.additionalAttributes?.post?.network ===
      ThreatMonitoringVendorNetworkType.WebzNetworkTypeOpenWeb ||
    threat.source == ThreatMonitoringSourceType.Github
  ) {
    return (
      <div className={"row"}>
        <div className={"column1"}>Origin</div>
        <div className={"column2"}>
          <EllipsisedURL url={threat.originURL} />
        </div>
      </div>
    );
  }

  return (
    <div className={"row"}>
      <div className={"column1"}>Origin</div>
      <div className={"column2"}>
        <div>
          <EllipsizedText text={threat.originURL}>
            {threat.originURL}
          </EllipsizedText>
        </div>
      </div>
    </div>
  );
}

function RightHand({ result }: { result: DisplayableThreatMonitoringResult }) {
  if (result.additionalAttributes?.cookie) {
    return (
      <CookieDetail
        threat={result}
        detail={result.additionalAttributes.cookie}
      />
    );
  }

  if (result.additionalAttributes?.password) {
    return (
      <PasswordDetail
        threat={result}
        detail={result.additionalAttributes.password}
      />
    );
  }

  if (result.additionalAttributes?.chat) {
    return (
      <ChatDetail threat={result} detail={result.additionalAttributes.chat} />
    );
  }

  if (result.additionalAttributes?.post) {
    return (
      <PostDetail threat={result} detail={result.additionalAttributes.post} />
    );
  }

  if (result.additionalAttributes?.github) {
    return <GithubDetail threat={result} />;
  }

  return <></>;
}

function URLRow({ threat }: { threat: DisplayableThreatMonitoringResult }) {
  return (
    <div className="row">
      <div className="column1">URL</div>
      <div className="column2">
        <EllipsisedURL url={threat.originURL} />
      </div>
    </div>
  );
}

function Row({
  label,
  value,
  noEllipsise,
}: {
  label: ReactNode;
  value: string | JSX.Element | null | undefined | boolean;
  noEllipsise?: boolean;
}) {
  if (!value) {
    return null;
  }

  return (
    <div className="row">
      <div className="column1">{label}</div>
      <div className="column2">
        {noEllipsise ? (
          value
        ) : (
          <EllipsizedText text={value}>
            <>{value}</>
          </EllipsizedText>
        )}
      </div>
    </div>
  );
}

// RHS detail of a cookie threat
function CookieDetail({
  threat,
  detail,
}: {
  threat: DisplayableThreatMonitoringResult;
  detail: CookieAdditionalAttributes;
}) {
  return (
    <div className="rhs">
      <URLRow threat={threat} />
      <Row label="Cookie name" value={detail.cookieName} />
      <Row label="Cookie value" value={detail.cookieValue} />
    </div>
  );
}

// RHS detail of a password threat
function PasswordDetail({
  threat,
  detail,
}: {
  threat: DisplayableThreatMonitoringResult;
  detail: PasswordAdditionalAttributes;
}) {
  return (
    <div className="rhs">
      <URLRow threat={threat} />
      <Row label="Username" value={detail.username} />
      <div className="row">
        <div className="column1">Password</div>
        <div className="column2">
          {detail.password ? (
            <PasswordRevealerWithTooltip password={detail.password} />
          ) : (
            <span className="secondary">
              Leaked password not available for this threat
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

const threadTruncationLength = 200;

// RHS detail of a structured chat.
// It destructures the data and delegates to ParsedThreadDetail.
function ChatDetail({
  threat,
  detail,
}: {
  threat: DisplayableThreatMonitoringResult;
  detail: ChatAdditionalAttributes;
}) {
  let parsedThread: ThreadEntryType[] = [];
  if (detail.discord) {
    parsedThread = detail.discord.parsedThread;
  } else if (detail.telegramGroup) {
    parsedThread = detail.telegramGroup.parsedThread;
  } else if (detail.telegramChannel) {
    parsedThread = detail.telegramChannel.parsedThread;
  }

  return <ParsedThreadDetail threat={threat} parsedThread={parsedThread} />;
}

// ParsedThreadDetail is a component that displays a parsed thread.
function ParsedThreadDetail({
  threat,
  parsedThread,
}: {
  threat: DisplayableThreatMonitoringResult;
  parsedThread: ThreadEntryType[];
}) {
  const lcKeywords = threat.keywords.map((kw) => kw.toLowerCase());
  const kwRegex = new RegExp(`(${lcKeywords.join("|")})`, "ig");

  const previewEntries: ThreadEntryType[] = useMemo(() => {
    // find the first entry that contains a keyword, either in the user or in the text
    const keywordIndex = parsedThread.findIndex((entry) => {
      return (
        lcKeywords.some((kw) => entry.user.toLowerCase().includes(kw)) ||
        kwRegex.test(entry.text)
      );
    });

    // we found the keyword
    if (keywordIndex >= 0) {
      const entry = parsedThread[keywordIndex];

      // Let's check if the text preceding the keyword is too long and pushes the keyword out of view
      const parts = entry.text.split(kwRegex);
      if (parts[0].length > threadTruncationLength) {
        parts[0] =
          " ..." + parts[0].substring(parts[0].length - threadTruncationLength);
      }

      return [
        {
          date: entry.date,
          user: entry.user,
          text: parts.join(""),
        },
      ];
    } else {
      return parsedThread.slice(0, 1);
    }
  }, [threat.keywords, parsedThread]);

  return (
    <div className="rhs with-snippet">
      <div className="tm-thread-entries">
        {previewEntries.map((entry, i) => (
          <ThreadEntry
            key={i}
            entry={entry}
            highlight={threat.keywords}
            className="in-feed"
          />
        ))}
      </div>
    </div>
  );
}

// RHS detail of a text post
function PostDetail({
  threat,
  detail,
}: {
  threat: DisplayableThreatMonitoringResult;
  detail: PostAdditionalAttributes;
}) {
  return (
    <TextPreview
      keywords={threat.keywords}
      text={detail.text}
      uniqueKey={threat.uuid}
    />
  );
}

function GithubDetail({
  threat,
}: {
  threat: DisplayableThreatMonitoringResult;
}) {
  return (
    <TextPreview
      text={threat.snippet}
      keywords={threat.keywords}
      uniqueKey={threat.uuid}
    />
  );
}

function TextPreview({
  keywords,
  text,
  uniqueKey,
}: {
  keywords: string[];
  text: string;
  uniqueKey: string;
}) {
  const lines = useParseSnippet(text, keywords);

  const [snippetParent, setSnippetParentRef] = useState<HTMLDivElement | null>(
    null
  );

  const scrollToHighlight = useCallback(() => {
    if (!snippetParent) return;

    const marks = snippetParent.getElementsByTagName("mark");

    if (!marks.length) return;

    snippetParent.scrollTop = marks[0].offsetTop - snippetParent.offsetTop - 40;
  }, [snippetParent]);

  useLayoutEffect(() => {
    scrollToHighlight();
  }, [scrollToHighlight, snippetParent]);

  const scrollToHighlightDebounced = useDebounceCallback(
    scrollToHighlight,
    100
  );
  useResizeObserver(snippetParent, scrollToHighlightDebounced);
  return (
    <>
      {lines.length > 0 && (
        <div className="rhs with-snippet">
          <div className="snippet-container">
            <div
              className="snippet"
              ref={setSnippetParentRef}
              key={`snippet-${uniqueKey}`}
            >
              {lines.map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ThreatMonitoringFeedItem;

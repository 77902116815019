import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce as _debounce } from "lodash";
import { runAutosave } from "../reducers/apiActions";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import { formatTimeAsLocal } from "../../_common/helpers";

import "../style/Autosave.scss";
import { appConnect } from "../../_common/types/reduxHooks";

// Only save after the user has stopped making changes for 2.5 secs
const autosaveDelayMs = 2500;

export interface IAutosaveOwnProps {
  surveyId: string;
  showIcon: boolean;
  absoluteTime?: boolean;
  postSaveCallback?: () => void;
}

export interface IAutosaveConnectedProps {
  actionHistoryUpdatedAt: number;
  saving: boolean;
}

type IAutosaveProps = DefaultThunkDispatchProp &
  IAutosaveOwnProps &
  IAutosaveConnectedProps;

// This component manages autosaving for a survey instance. It will monitor the redux store for updates
// and queue them
const Autosave = (props: IAutosaveProps) => {
  const {
    dispatch,
    surveyId,
    actionHistoryUpdatedAt,
    saving,
    showIcon,
    absoluteTime,
    postSaveCallback,
  } = props;
  const [lastSavedAt, setLastSavedAt] = useState(0);

  // Update the lastSavedAt time whenever saving becomes false
  const prevSaving = useRef(false);
  useEffect(() => {
    if (prevSaving.current && !saving) {
      // We were saving, but we're not anymore. Set the last saved at time
      setLastSavedAt(new Date().valueOf());
    }
    // Keep track of this prop value for when it changes again
    prevSaving.current = saving;
  }, [saving]);

  const debouncedAutosave = useCallback(
    _debounce(async (surveyId: string) => {
      await dispatch(runAutosave(surveyId));
      if (postSaveCallback) {
        postSaveCallback();
      }
    }, autosaveDelayMs),
    []
  );

  // Make sure we update the time text every 20 seconds or so, or it'll always say "Last saved a few seconds ago"
  const [, setForceUpdate] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setForceUpdate(new Date().valueOf());
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    debouncedAutosave(surveyId);
  }, [actionHistoryUpdatedAt, surveyId]);

  if (!showIcon) {
    return null;
  }

  return (
    <div className="autosave-container">
      {saving ? (
        <LoadingIcon />
      ) : (
        <span className="last-saved-text">
          {lastSavedAt ? (
            absoluteTime ? (
              <>
                Last saved at {formatTimeAsLocal(moment(lastSavedAt))}{" "}
                <i className={"cr-icon-check"} />
              </>
            ) : (
              `Last saved ${moment(lastSavedAt).fromNow()}`
            )
          ) : null}
        </span>
      )}
    </div>
  );
};

export default appConnect<IAutosaveConnectedProps, never, IAutosaveOwnProps>(
  (state, props) => {
    return {
      actionHistoryUpdatedAt:
        state.surveyBuilder.surveys[props.surveyId].actionHistoryUpdatedAt,
      saving: state.surveyBuilder.surveys[props.surveyId].saving,
    };
  }
)(Autosave);

import { PureComponent, Component, FC } from "react";
import { find as _find } from "lodash";
import moment from "moment";
import classnames from "classnames";

import "../style/components/Correspondence.scss";
import IconButton from "./IconButton";
import MessageInput from "./MessageInput";
import Markdown from "./core/Markdown";
import { ICorrespondenceMessage } from "../types/correspondenceMessage";
import { IUserMiniMap } from "../types/user";

// Shows a time "fromNow" element that auto updates every thirty seconds
interface TimeAgoProps {
  time: string;
}

interface TimeAgoState {
  time: string;
}

class TimeAgo extends PureComponent<TimeAgoProps, TimeAgoState> {
  interval: any;

  constructor(props: TimeAgoProps) {
    super(props);

    this.state = { time: moment(props.time).fromNow() };
  }

  componentDidMount() {
    this.interval = setInterval(this.calculateTime, 30000);
  }

  componentDidUpdate(prevProps: TimeAgoProps) {
    if (prevProps.time !== this.props.time) {
      this.setState({ time: moment(this.props.time).fromNow() });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateTime = () => {
    this.setState({ time: moment(this.props.time).fromNow() });
  };

  render() {
    return (
      <span className="time-ago" title={moment(this.props.time).format("LLL")}>
        {this.state.time}
      </span>
    );
  }
}

type ThreatMonitoringMeta = {
  isCopyFromThreat: boolean;
};
function isThreatMonitoringMeta(meta: unknown): meta is ThreatMonitoringMeta {
  return (
    !!meta &&
    typeof meta === "object" &&
    "isCopyFromThreat" in meta &&
    meta?.isCopyFromThreat !== undefined
  );
}

interface MessageProps extends ICorrespondenceMessage<ThreatMonitoringMeta> {
  currentUserId: number;
  users: IUserMiniMap;
  highlight?: boolean;
  editMessageFunc?: (id: number, content: string) => void;
  replyMessageFunc?: (id: number, content: string, isPrivate: boolean) => void;
  allowedPrivateMessages: boolean;
  privateMessagesOnly?: boolean;
  newStyles: boolean;
  allowSelfEdit: boolean;
  className?: string;

  getClassForMessage?: (message: ICorrespondenceMessage) => string | undefined;
}

interface MessageState {
  editing: boolean;
  editValue: string;
  editLoading: boolean;
  replying: boolean;
  replyValue: string;
  replyLoading: boolean;
}

class Message extends Component<MessageProps, MessageState> {
  static defaultProps = {
    content: "",
    highlight: false,
    allowedPrivateMessages: false,
    isPrivate: false,
    newStyles: false,
    allowSelfEdit: true,
  };

  state = {
    editing: false,
    editValue: "",
    editLoading: false,
    replying: false,
    replyValue: "",
    replyLoading: false,
  };

  startEditing = () =>
    this.setState({
      editing: true,
      editValue: this.props.content,
    });

  startReplying = () =>
    this.setState({
      replying: true,
      replyValue: "",
      replyLoading: false,
    });

  submitEdit = () => {
    if (this.props.editMessageFunc === undefined) {
      return;
    }

    this.setState({ editLoading: true });
    Promise.resolve(
      this.props.editMessageFunc(this.props.id, this.state.editValue)
    )
      .then(() => {
        // Success - no longer in edit mode
        this.setState({ editing: false, editLoading: false, editValue: "" });
      })
      .catch(() => {
        // Failure - Just go back to editing state. Assume the editMessageFunc has shown
        // any necessary alerts.
        this.setState({ editLoading: false });
      });
  };

  submitReply = (isPrivate: boolean) => {
    this.setState({ replyLoading: true });

    if (this.props.replyMessageFunc) {
      Promise.resolve(
        this.props.replyMessageFunc(
          this.props.id,
          this.state.replyValue,
          isPrivate ? isPrivate : false
        )
      )
        .then(() => {
          // Success - no longer in reply mode
          this.setState({
            replying: false,
            replyLoading: false,
            replyValue: "",
          });
        })
        .catch(() => {
          // Failure - Just go back to replying state. Assume the replyMessageFunc has shown
          // any necessary alerts.
          this.setState({ replyLoading: false });
        });
    }
  };

  render() {
    const {
      children,
      currentUserId,
      userId,
      users,
      content,
      isPrivate,
      unread,
      createdAt,
      updatedAt,
      deletedAt,
      editMessageFunc,
      replyMessageFunc,
      highlight,
      allowedPrivateMessages,
      newStyles,
      allowSelfEdit,
      className,
      getClassForMessage,
      privateMessagesOnly,
      meta,
    } = this.props;

    // When a remediation request is created from a threat monitoring threat,
    // the user can optionally copy the threat comments into the remediation request.
    // In this case, the copied comments are marked as "Threat comment", displayed with the "private" treatment,
    // and are not editable.
    let isCopyFromThreat = false;
    if (isThreatMonitoringMeta(meta)) {
      isCopyFromThreat = meta.isCopyFromThreat;
    }

    const user = _find(users, (u) => u !== undefined && u.id === userId) || {
      name: "Unknown",
      avatar: "",
    };

    const actions = [];
    if (
      editMessageFunc &&
      currentUserId === userId &&
      allowSelfEdit &&
      !isCopyFromThreat
    ) {
      actions.push(
        // Old styles
        <span key="edit" className="hover-action" onClick={this.startEditing}>
          Edit
        </span>,
        // New styles
        <IconButton
          key="edit-new"
          icon={<div className="cr-icon-pencil-2" />}
          hoverText="Edit"
          onClick={this.startEditing}
        />
      );
    }

    if (replyMessageFunc && !isCopyFromThreat) {
      actions.push(
        // Old styles
        <span key="reply" className="hover-action" onClick={this.startReplying}>
          Reply
        </span>,
        // New styles
        <IconButton
          key="reply-new"
          icon={<div className="cr-icon-reply" />}
          hoverText="Reply"
          onClick={this.startReplying}
        />
      );
    }

    const classes = classnames(
      isPrivate ? "message-private" : "message",
      isCopyFromThreat ? "message-copy" : "",
      className
    );

    return (
      <div className={classes}>
        <div className="avatar-col">
          <div
            className="avatar"
            style={{ backgroundImage: `url(${user.avatar})` }}
          />
        </div>
        <div className="content-col">
          <div className="content-col-inner">
            <div className="message-header-wrapper">
              <div className="message-header">
                {unread && newStyles && <div className="unread-circle" />}
                <span className="user-name">{user.name}</span>
                <TimeAgo time={createdAt} />
                {unread && !newStyles && <div className="unread-circle" />}
                {isPrivate && <span className="time-ago">(private)</span>}
                {isCopyFromThreat && (
                  <span className="time-ago">(Threat comment)</span>
                )}
              </div>
              {!this.state.editing && !this.state.replying && (
                <div className="header-actions">{actions}</div>
              )}
            </div>
            <div className="content">
              {deletedAt ? (
                <span className="edited-text">(deleted)</span>
              ) : this.state.editing ? (
                <MessageInput
                  value={this.state.editValue}
                  onChange={(value) => this.setState({ editValue: value })}
                  onCancel={() =>
                    this.setState({ editing: false, editValue: "" })
                  }
                  onSubmit={this.submitEdit}
                  onPrivate={isPrivate ? this.submitEdit : undefined}
                  onSubmitText="Save"
                  loading={this.state.editLoading}
                  showActions
                  placeholder="Edit message"
                  autofocus
                  isPrivate={isPrivate ?? undefined}
                  privateMessagesOnly={isPrivate ?? undefined}
                />
              ) : (
                <div>
                  <Markdown content={content} />
                  {createdAt !== updatedAt && (
                    <span
                      className="edited-text"
                      title={`Last edited ${moment(updatedAt).format("LLL")}`}
                    >
                      (edited)
                    </span>
                  )}
                </div>
              )}
            </div>
            {!newStyles && this.state.replying && (
              <MessageInput
                value={this.state.replyValue}
                onChange={(value) => this.setState({ replyValue: value })}
                onCancel={() =>
                  this.setState({ replying: false, replyValue: "" })
                }
                onSubmit={() => this.submitReply(false)}
                onSubmitText="Send"
                isPrivate={isPrivate}
                onPrivate={
                  isPrivate || allowedPrivateMessages
                    ? () => this.submitReply(true)
                    : undefined
                }
                loading={this.state.replyLoading}
                showActions
                placeholder="Enter a reply"
                privatePlaceholder="Add a private note (only visible to users of your account)"
                autofocus
                privateMessagesOnly={privateMessagesOnly}
              />
            )}
          </div>
          {children && children.length > 0 && (
            <Correspondence
              messages={children}
              users={users}
              currentUserId={currentUserId}
              editMessageFunc={editMessageFunc}
              highlight={highlight}
              allowedPrivateMessages={allowedPrivateMessages}
              // replyMessageFunc={replyMessageFunc} // Uncomment this line to enable replying to messages deeper in threads
              getClassForMessage={getClassForMessage}
              privateMessagesOnly={privateMessagesOnly}
            />
          )}
          {newStyles && this.state.replying && (
            <MessageInput
              value={this.state.replyValue}
              onChange={(value) => this.setState({ replyValue: value })}
              onCancel={() =>
                this.setState({ replying: false, replyValue: "" })
              }
              onSubmit={() => this.submitReply(false)}
              onSubmitText="Send"
              isPrivate={isPrivate}
              onPrivate={
                isPrivate || allowedPrivateMessages
                  ? () => this.submitReply(true)
                  : undefined
              }
              loading={this.state.replyLoading}
              showActions
              placeholder="Enter a reply"
              privatePlaceholder="Add a private note (only visible to users of your account)"
              autofocus
              privateMessagesOnly={privateMessagesOnly}
            />
          )}
        </div>
      </div>
    );
  }
}

interface CorrespondenceProps {
  newStyles?: boolean;
  reverseOrder?: boolean;
  messages: ICorrespondenceMessage[];
  users: IUserMiniMap;
  currentUserId: number;
  editMessageFunc?: (id: number, content: string) => void;
  replyMessageFunc?: (id: number, content: string, isPrivate: boolean) => void;
  highlight?: boolean;
  allowedPrivateMessages: boolean;
  privateMessagesOnly?: boolean;
  allowSelfEdit?: boolean;
  getClassForMessage?: (message: ICorrespondenceMessage) => string | undefined;
  preMessagesContent?: React.ReactNode;
}

const Correspondence: FC<CorrespondenceProps> = ({
  newStyles,
  reverseOrder,
  messages,
  users,
  currentUserId,
  editMessageFunc,
  replyMessageFunc,
  highlight,
  allowedPrivateMessages,
  getClassForMessage,
  privateMessagesOnly,
  preMessagesContent,
}) => {
  return (
    <div
      className={classnames("correspondence", {
        highlight: highlight,
        "new-styles": newStyles,
      })}
    >
      {(reverseOrder ? [...messages].reverse() : messages).map((m) => (
        <Message
          key={m.id}
          users={users}
          editMessageFunc={editMessageFunc}
          replyMessageFunc={replyMessageFunc}
          currentUserId={currentUserId}
          newStyles={newStyles}
          {...m}
          highlight={highlight}
          allowedPrivateMessages={allowedPrivateMessages}
          allowSelfEdit={m.allowSelfEdit ?? true}
          className={getClassForMessage ? getClassForMessage(m) : undefined}
          getClassForMessage={getClassForMessage}
          privateMessagesOnly={privateMessagesOnly}
        />
      ))}
      {preMessagesContent}
    </div>
  );
};

Correspondence.defaultProps = {
  newStyles: false,
  highlight: false,
  allowedPrivateMessages: false,
  reverseOrder: false,
};

export default Correspondence;

import {
  deleteNode,
  setEditRiskModalNodeId,
  updateNode,
} from "../reducers/actions";
import { RiskNode } from "../types/types";
import ModalV2 from "../../_common/components/ModalV2";
import ModalForm from "../../vendorrisk/components/modals/ModalForm";
import "../style/EditRiskModal.scss";
import SeveritySelector from "../../vendorrisk/components/SeveritySelector";
import Button from "../../_common/components/core/Button";
import { scrollToNodeId } from "../helpers";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import { appConnect } from "../../_common/types/reduxHooks";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { FC } from "react";
import TextFieldPerformant from "../../_common/components/TextFieldPerformant";

const defaultAskForCompensatingControlsText =
  "If you have compensating controls in place or feel that this issue does not constitute a risk in your specific circumstances, please explain here. If you're working to address this issue, include an estimate of when it will be resolved.";

interface IEditRiskModalOwnProps {
  surveyId: string;
}

type IEditRiskModalProps = IEditRiskModalOwnProps &
  IConnectedEditRiskModalProps &
  DefaultThunkDispatchProp;

export const EditRiskModal: FC<IEditRiskModalProps> = (props) => {
  if (!props.riskNode) {
    return null;
  }

  const onClose = () => {
    // Always scroll to the risk in question after we close.
    scrollToNodeId(props.nodeId, false);
    props.dispatch(setEditRiskModalNodeId(props.surveyId, undefined));
  };

  const isValid =
    !!props.riskNode.name &&
    !!props.riskNode.mainText &&
    !!props.riskNode.riskCategory &&
    !!props.riskNode.severity;

  return (
    <ModalV2
      active
      disallowClose
      className="edit-risk-modal"
      headerContent="Edit risk"
      footerContent={
        <>
          <div className="btn-group footer-left">
            <Button
              danger
              onClick={() => {
                props.dispatch(deleteNode(props.surveyId, props.nodeId));
                onClose();
              }}
            >
              <span className="cr-icon-trash" /> Delete
            </Button>
          </div>
          <div className="btn-group">
            <Button primary disabled={!isValid} onClick={onClose}>
              Done
            </Button>
          </div>
        </>
      }
    >
      <ModalForm>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Finding*</span>
          </div>
          <div className="form-section-input">
            <div className="form-prompt">
              What is the observation you have made that could lead to a risk?
            </div>
            <div className={"form-example em"}>
              Example: “No incident response procedure”
            </div>
            <input
              type="text"
              placeholder="Eg. No incident response procedure"
              value={props.riskNode.name}
              onChange={(e) =>
                props.dispatch(
                  updateNode<RiskNode>(props.surveyId, props.nodeId, {
                    name: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Risk*</span>
          </div>
          <div className="form-section-input">
            <div className="form-prompt">
              What would the impact be if this finding was present?
            </div>
            <div className={"form-example  em"}>
              Example: “Inability or lessened ability to respond to security
              incidents”
            </div>
            <input
              type="text"
              placeholder="Eg. Inability or lessened ability to respond to security incidents"
              value={props.riskNode.riskCategory}
              onChange={(e) =>
                props.dispatch(
                  updateNode<RiskNode>(props.surveyId, props.nodeId, {
                    riskCategory: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Description*</span>
          </div>
          <div className="form-section-input">
            <div className="form-prompt">
              Why does this finding create a risk and how can this risk be
              remediated?
            </div>
            <div className={"form-example  em"}>
              Example: “Incident response planning is an important risk
              mitigation”
            </div>
            <TextFieldPerformant
              multiLine
              strict
              placeholder="Eg. Incident response planning is an important risk mitigation strategy as it outlines how to respond to and who should be notified in the event of a security incident."
              value={props.riskNode.mainText}
              onChanged={(val) =>
                props.dispatch(
                  updateNode<RiskNode>(props.surveyId, props.nodeId, {
                    mainText: val,
                  })
                )
              }
            />
            <div className={"form-example vspaced-half"}>
              Note: this text will also be displayed if you ask for compensating
              control information
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Select severity*</span>
          </div>
          <div className="form-section-input">
            <div className="form-prompt">
              Severity determines how many points are deducted from a
              questionnaire&apos;s score. The higher the severity, the more
              points deducted.
            </div>
            <SeveritySelector
              severity={props.riskNode.severity}
              isDisabled={false}
              onChange={(severity) =>
                props.dispatch(
                  updateNode<RiskNode>(props.surveyId, props.nodeId, {
                    severity,
                  })
                )
              }
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Compensating controls</span>
          </div>
          <div className="form-section-input compensating-controls">
            <div className="form-prompt">
              Request compensating control information
            </div>
            <div className="form-example">
              Ask the questionnaire recipient for compensating control
              information if this risk is identified.
            </div>
            <textarea
              placeholder="Request compensating control information"
              value={props.riskNode.why}
              rows={4}
              disabled={!props.riskNode.askForCompensatingControls}
              onChange={(e) =>
                props.dispatch(
                  updateNode<RiskNode>(props.surveyId, props.nodeId, {
                    why: e.target.value,
                  })
                )
              }
            />
            <ColorCheckbox
              className={"vspaced-half"}
              checked={!props.riskNode.askForCompensatingControls}
              onClick={() => {
                props.dispatch(
                  updateNode<RiskNode>(props.surveyId, props.nodeId, {
                    askForCompensatingControls:
                      !props.riskNode?.askForCompensatingControls,
                    why:
                      props.riskNode?.why ??
                      defaultAskForCompensatingControlsText,
                  })
                );
              }}
              label="Disable compensating control request"
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export interface IConnectedEditRiskModalProps {
  riskNode?: RiskNode;
  nodeId: string;
}

export default appConnect<
  IConnectedEditRiskModalProps,
  never,
  IEditRiskModalOwnProps
>((state, props) => {
  const nodeId =
    state.surveyBuilder.surveys[props.surveyId].editRiskModalNodeId;

  let riskNode: RiskNode | undefined;
  if (nodeId) {
    riskNode = state.surveyBuilder.surveys[props.surveyId].nodes[
      nodeId
    ] as RiskNode;
  }

  return {
    riskNode,
    nodeId: nodeId || "",
  };
})(EditRiskModal);

import { useEffect, useRef, useState, memo } from "react";
import Button from "../../_common/components/core/Button";
import { CreatableV2, OptionType } from "../../_common/components/SelectV2";
import { updateNode } from "../reducers/actions";

import "../style/MapToFrameworkButton.scss";
import Select from "react-select/base";
import {
  SurveyFramework,
  SurveyFrameworkDisplayNames,
} from "../types/frameworks";
import { AppDispatch } from "../../_common/types/reduxStore";

interface IMapToFrameworkButtonProps {
  dispatch: AppDispatch;
  framework: SurveyFramework;
  surveyId: string;
  nodeId: string;
  controls: string[];
  readOnly: boolean;
}

const MapToFrameworkButton = (props: IMapToFrameworkButtonProps) => {
  const [mapButtonClicked, setMapButtonClicked] = useState(false);
  const creatableRef = useRef<Select<OptionType>>(null);

  useEffect(() => {
    if (mapButtonClicked && creatableRef.current) {
      creatableRef.current.focus();
    }
  }, [mapButtonClicked]);

  return (
    <div className="map-to-framework">
      {props.controls.length === 0 && !mapButtonClicked ? (
        <Button tertiary onClick={() => setMapButtonClicked(true)}>
          + Map to {SurveyFrameworkDisplayNames[props.framework]}
        </Button>
      ) : (
        <CreatableV2
          innerRef={creatableRef}
          value={props.controls.map((control) => ({
            value: control,
            label: control,
          }))}
          placeholder={`Type ${
            SurveyFrameworkDisplayNames[props.framework]
          } control...`}
          noOptionsMessage={() => null}
          isMulti
          isDisabled={props.readOnly}
          onChange={(newVals) => {
            if (!newVals || !Array.isArray(newVals) || newVals.length === 0) {
              setMapButtonClicked(false);
              props.dispatch(
                updateNode(props.surveyId, props.nodeId, {
                  controls: undefined,
                })
              );
              return;
            }

            props.dispatch(
              updateNode(props.surveyId, props.nodeId, {
                controls: newVals.map(
                  (val) => (val as OptionType).value as string
                ),
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default memo(MapToFrameworkButton);

import { FC, ReactNode, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Icon from "./core/Icon";
import "../style/components/ExpandableItem.scss";

interface ExpandableItemProps {
  header: ReactNode;
  content: ReactNode;
  startExpanded?: boolean;
}

const ExpandableItem: FC<ExpandableItemProps> = ({
  header,
  content,
  startExpanded,
}) => {
  const [expanded, setExpanded] = useState(!!startExpanded);

  return (
    <div className={"expandable-item"}>
      <div
        onClick={() => setExpanded(!expanded)}
        className="expandable-item-header"
      >
        <a className="expandable-item-header-link">
          {header}
          <Icon
            name={"chevron"}
            direction={expanded ? 0 : 180}
            className="expandable-item-header-icon"
          />
        </a>
      </div>
      <TransitionGroup>
        {expanded && (
          <CSSTransition key={"expand-row"} timeout={250} classNames="expand">
            <div className={"expandable-item-content"}>{content}</div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default ExpandableItem;

import { PageTitleSectionProps } from "../../_common/components/pageHeader/PageTitleSection";
import { UserRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";
import { HeaderActionGroup } from "../../_common/components/pageHeader/MainSection";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import PageHeaderV2, {
  PageHeaderV2Props,
} from "../../_common/components/pageHeader/PageHeaderV2";
import { FC, ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";
import "./UserBaseApplicationHeader.scss";

interface UserBaseUserHeaderProps extends PageTitleSectionProps {}

const UserBaseUserHeader: FC<UserBaseUserHeaderProps> = ({ ...restProps }) => {
  const match = useRouteMatch<UserRouteParams>();

  const { data } = UserBaseAPI.useGetUserBaseUserSummaryV1Query({
    userUUID: match.params.userUUID,
  });

  const user = data?.user;
  const userName = user?.name ?? "User";
  const userRating = user?.adjustedRating;

  const nameWidgets: ReactNode[] = [];
  const metadataWidgets: ReactNode[] = [];
  const actionGroups: HeaderActionGroup[] = [];

  if (user) {
    nameWidgets.push(
      <>
        <ColorGrade score={userRating} size={ColorGradeSize.Small} />
        <Score score={userRating} small />
      </>
    );
    const numRoles = user?.roles?.length ?? 0;
    const roleToShow = numRoles > 0 ? user?.roles[0] : "";
    // TODO: add highest exposure for the user when we have the data
    // const highestExposureLevel = ScopeRiskLevel.MediumLowScopeRiskLevel;

    metadataWidgets.push(
      <>
        <div className={`header-icon cr-icon-users-empty`} />
        <div className="header-label">{roleToShow}</div>
      </>
      // <>
      //   <div className={"exposure-level"}>Exposure level:</div>
      //   <ScopeRiskLevelDisplay riskLevel={highestExposureLevel} />
      // </>,
    );
  }

  const pageHeaderV2Props: PageHeaderV2Props = {
    ...restProps,
    name: userName,
    className: "userbase-header",
    nameWidgets,
    actionGroups,
    metadataWidgets,
  };

  return (
    <>
      <PageHeaderV2 {...pageHeaderV2Props} />
    </>
  );
};

export default UserBaseUserHeader;

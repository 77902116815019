import {
  DefaultRouteProps,
  useDefaultHistory,
} from "../../_common/types/router";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import {
  IMySharedAssessmentAccess,
  ISharedAssessment,
} from "../types/sharedAssessment";

import { useEffect, useState } from "react";
import {
  fetchSurveyTypes,
  getOrgUserInvites,
  getOrgUsers,
} from "../reducers/cyberRiskActions";

import "../style/views/MySharedProfile.scss";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  getCurrentOrgFromUserData,
  isExpiredTrial,
} from "../../_common/helpers";
import { organisationAccountType } from "../../_common/types/organisations";
import * as Permissions from "../../_common/permissions";
import {
  userHasBreachsightEnabled,
  UserWritePrefilledSurveys,
  UserWriteSharedProfileUsers,
} from "../../_common/permissions";
import InfoBanner, { BannerType } from "../components/InfoBanner";
import TabButtons from "../../_common/components/TabButtons";
import { SharedProfile } from "../components/shared_profile/SharedProfile";
import {
  ExpandableReportCard,
  ReportCard,
} from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import VerticalProgress, {
  IVerticalProgressStep,
} from "../../_common/components/VerticalProgress";
import EditSharedProfileMetadataModal from "../components/shared_profile/EditSharedProfileMetadataModal";
import { getNamedRoles } from "../../_common/reducers/commonActions";
import ProgressBar from "../../_common/components/ProgressBar";
import SharedProfileAccess from "../components/shared_profile/SharedProfileAccess";
import { IOrgUser, IOrgUserInvite } from "../../_common/types/user";
import { NamedRole } from "../../_common/types/namedRole";
import {
  anyCollaborators,
  getAdminAndTrustExchangeRoleIds,
  SharedProfileFreeOrgInviteUserModal,
} from "../components/shared_profile/SharedProfileFreeOrgUserList";
import { sortBy as _sortBy } from "lodash";
import { ISurveyListItemResponse } from "../../_common/types/survey";
import { surveyType, SurveyUsageType } from "../../_common/types/surveyTypes";
import AddQuestionnaireToSharedProfileModal from "../components/shared_profile/AddQuestionnaireToSharedProfileModal";
import ShareSharedProfileModal from "../components/shared_profile/ShareSharedProfileModal";
import PageHeader from "../../_common/components/PageHeader";
import { Link, Redirect } from "react-router-dom";
import SharedProfileAccessControlPill from "../components/shared_profile/SharedProfileAccessControlPill";
import SharedProfileAccessLog from "../components/shared_profile/SharedProfileAccessLog";
import EmptyStateCircle from "../../_common/components/EmptyStateCircle";
import {
  appConnect,
  useAppDispatch,
  useAppSelector,
} from "../../_common/types/reduxHooks";
import SurveyImportAPI from "../../vendor_portal/api/surveyImportAPI";
import { setPLGTaskCompleteIfIncomplete } from "../reducers/plgOnboardingChecklistActions";
import ManageTrustPageContentView from "../../verifiedvendors/views/ManageTrustPageContentView";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../verifiedvendors/api/trustpage.api";
import { useAddDocumentToSharedProfile } from "../../verifiedvendors/hooks/useAddDocumentToSharedProfile";
import useReceivedSurveys from "../../verifiedvendors/hooks/useReceivedSurveys";
import { fetchVendorAppSurveyList } from "../../vendor_portal/reducers/surveys.actions";
import EmptyCardWithAction, {
  ErrorCardWithAction,
} from "../../_common/components/EmptyCardWithAction";
import NoAccessSvg from "../../_common/images/icon-lock.svg";
import {
  trustPagePreviewRoute,
  trustPageRoute,
} from "../../verifiedvendors/TrustPageAppRouter";
import PublishTrustPageButton from "../../verifiedvendors/components/publishTrustPageButton/PublishTrustPageButton";

interface IMySharedProfileProgressCardProps {
  dispatch: DefaultThunkDispatch;
  isFreeAccount: boolean;
  sharedAssessment: ISharedAssessment;
  sharedAssessmentAccess: IMySharedAssessmentAccess;
  onPublishSharedProfile: () => void;
  anyCollaborators: boolean;
  openInviteCollaboratorsModal: () => void;
  openAddQuestionnaireModal: () => void;
}

const MySharedProfileProgressCard = ({
  dispatch,
  isFreeAccount,
  sharedAssessment,
  sharedAssessmentAccess,
  onPublishSharedProfile,
  anyCollaborators,
  openInviteCollaboratorsModal,
  openAddQuestionnaireModal,
}: IMySharedProfileProgressCardProps) => {
  const [editMetadataModal, setEditMetadataModal] = useState<{
    active: boolean;
    focusField?: "contact_name" | "company_description";
  }>({
    active: false,
  });
  const addDocument = useAddDocumentToSharedProfile();

  let steps: IVerticalProgressStep[] = [
    {
      id: "add_contact",
      name: "Add a security contact",
      stepDescription: (
        <>
          Share the contact information of the team who manages your Trust Page.
          {(!sharedAssessment.contactEmail ||
            !sharedAssessment.contactName) && (
            <Button
              onClick={() =>
                setEditMetadataModal({
                  active: true,
                  focusField: "contact_name",
                })
              }
            >
              Add contact
            </Button>
          )}
        </>
      ),
      completed:
        !!sharedAssessment.contactEmail && !!sharedAssessment.contactName,
    },
    {
      id: "add_description",
      name: "Add your company description",
      stepDescription: (
        <>
          Describe your business to help people understand what your company
          does.
          {!sharedAssessment.companyDescription && (
            <Button
              onClick={() =>
                setEditMetadataModal({
                  active: true,
                  focusField: "company_description",
                })
              }
            >
              Add description
            </Button>
          )}
        </>
      ),
      completed: !!sharedAssessment.companyDescription,
    },
    {
      id: "add_questionnaire",
      name: "Add a questionnaire",
      stepDescription: (
        <>
          Proactively share complete security questionnaires and reduce time
          spent on answering assessments.
          {sharedAssessment.questionnaires.length === 0 &&
            (sharedAssessment.addToSharedProfileQuestionnaires || []).length ===
              0 && (
              <Button onClick={openAddQuestionnaireModal}>
                Add questionnaire
              </Button>
            )}
        </>
      ),
      completed:
        sharedAssessment.questionnaires.length > 0 ||
        (sharedAssessment.addToSharedProfileQuestionnaires || []).length > 0,
    },
    {
      id: "upload_supporting_documentation",
      name: "Upload supporting documentation",
      stepDescription: (
        <>
          Share security-related documentation or compliance certifications such
          as PCI DSS, SOC 2, ISO 27001, FedRAMP, etc.
          {sharedAssessment.contentLibraryDocuments.length === 0 && (
            <Button onClick={() => addDocument()}>Upload document</Button>
          )}
        </>
      ),
      completed: sharedAssessment.contentLibraryDocuments.length > 0,
    },
    ...(isFreeAccount
      ? [
          {
            id: "invite_teammates",
            name: "Invite teammates to manage your page",
            stepDescription: (
              <>
                Spread the load by inviting your team to collaborate on your
                Trust Page.
                {!anyCollaborators && (
                  <Button onClick={openInviteCollaboratorsModal}>
                    Invite users
                  </Button>
                )}
              </>
            ),
            completed: anyCollaborators,
          },
        ]
      : []),
    {
      id: "publish_profile",
      name: "Publish your Trust Page",
      stepDescription: (
        <>
          You&apos;ll have complete control over who has access and can approve
          or deny each request.
          {!sharedAssessmentAccess.access.published && (
            <Button onClick={onPublishSharedProfile}>
              <div className="cr-icon-check" />
              Publish Trust Page
            </Button>
          )}
        </>
      ),
      completed: sharedAssessmentAccess.access.published,
    },
  ];

  // Do a stable sort of the steps, so completed ones will bubble to the top but otherwise preserve their order
  steps = _sortBy(steps, [(step) => !step.completed]);

  const totalSteps = steps.length;
  const completedSteps = steps.filter((s) => s.completed).length;

  // Default to closed if it's published.
  const [cardOpen, setCardOpen] = useState(
    !sharedAssessmentAccess.access.published
  );

  return (
    <>
      <ExpandableReportCard
        newStyles
        isExpanded={cardOpen}
        onExpandToggle={setCardOpen}
        className="my-shared-profile-progress-card"
        headerContent={
          <>
            <div>Complete your Trust Page</div>
            <ProgressBar
              progress={completedSteps > 0 ? completedSteps / totalSteps : 0}
            />
            <div className="progress-extra">
              {completedSteps}/{totalSteps} completed
            </div>
          </>
        }
      >
        <div className="main-content">
          <div className="intro">
            <div className="intro-header">
              The benefits of completing your Trust Page
              <Button
                tertiary
                onClick={() =>
                  window.open(
                    "https://help.upguard.com/en/articles/3797568-what-is-an-upguard-trust-page"
                  )
                }
              >
                Learn more <div className="cr-icon-arrow-right" />
              </Button>
            </div>
            <p>
              Your Trust Page allows you to proactively share security-related
              information with companies that need to assess you. This means
              less time spent filling in manual assessments, customers have an
              accurate and current view of your security posture, and you can
              focus on being proactive rather than just keeping up with
              assessments.
            </p>
          </div>
          <VerticalProgress steps={steps} />
        </div>
      </ExpandableReportCard>
      <EditSharedProfileMetadataModal
        dispatch={dispatch}
        active={editMetadataModal.active}
        onClose={() => setEditMetadataModal({ active: false })}
        vendorId={sharedAssessment.linkedVendor.id}
        uuid={sharedAssessmentAccess.access?.uuid}
        focusFieldOnMount={editMetadataModal.focusField}
        contactName={sharedAssessment.contactName}
        contactEmail={sharedAssessment.contactEmail}
        companyDescription={sharedAssessment.companyDescription}
        currentScore={
          sharedAssessment.currentPublicScore ?? sharedAssessment.currentScore
        }
        includeIndustryAverage={sharedAssessment.includeIndustryAverage}
        industryAverage={sharedAssessment.industryAverage?.score}
        includeRiskRating={sharedAssessment.includeRiskRating}
        profileLogo={sharedAssessment.profileLogo}
      />
    </>
  );
};

interface IMySharedProfileOwnProps
  extends DefaultRouteProps<
    never,
    {
      currentTab?: tabIds;
    }
  > {
  dispatch: DefaultThunkDispatch;
}

interface IMySharedProfileConnectedProps {
  isFreeOrg: boolean;
  orgUsers?: IOrgUser[];
  orgUserInvites?: IOrgUserInvite[];
  namedRoles?: NamedRole[];
  surveyTypes?: surveyType[];
}

type IMySharedProfileProps = IMySharedProfileOwnProps &
  IMySharedProfileConnectedProps;

type tabIds = "overview" | "access" | "your_organisation" | "log";

const MySharedProfile = ({
  location,
  isFreeOrg,
  orgUsers,
  orgUserInvites,
  namedRoles,
  surveyTypes,
}: IMySharedProfileProps) => {
  const perms = Permissions.useBasicPermissions();
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.common.userData);

  const currentUserId = userData.id;
  const canPublishImportedSurveys =
    !!perms.userPermissions[Permissions.UserWriteSurveyImportExport];
  const userHasWritePrefilledSurveysPermission =
    userData.userPermissions.includes(UserWritePrefilledSurveys);
  const userHasWriteSharedProfileUsersPermission =
    userData.userPermissions.includes(UserWriteSharedProfileUsers);
  const userHasBreachsight = userHasBreachsightEnabled(
    userData.userPermissions
  );
  const orgHasBreachsight = userData.orgPermissions.includes(
    Permissions.OrgAccessCustomerInfo
  );
  const userCanViewSharedProfile = userData.userPermissions.includes(
    Permissions.UserAccessPrefilledSurveys
  );

  const { data: importedSurveysData } =
    SurveyImportAPI.useGetImportedSurveysListQuery(undefined, {
      skip: !canPublishImportedSurveys,
    });

  let importedSurveys: ISurveyListItemResponse[] = [];
  if (importedSurveysData) {
    importedSurveys = importedSurveysData.surveys?.filter((s) => !s.archived);
  }
  const {
    data: sharedAssessment,
    isLoading,
    isError: isErrorLoadingSharedAssessment,
  } = TrustPageAPI.useGetOwnSharedAssessmentV1Query();
  const { data: sharedAssessmentAccess } =
    TrustPageAPI.useGetOwnSharedAssessmentAccessV1Query();

  const receivedSurveys: ISurveyListItemResponse[] = useReceivedSurveys(
    sharedAssessment?.linkedVendor.id
  );

  useEffect(() => {
    // Get any surveys the user has been sent, we'll need to reference these in the questionnaires list
    dispatch(fetchVendorAppSurveyList(false, true));

    if (
      userHasWriteSharedProfileUsersPermission ||
      userHasWritePrefilledSurveysPermission
    ) {
      if (isFreeOrg) {
        // Also get a list of organisation users, so we can populate the "Your organisation" screen
        dispatch(getOrgUsers());
        dispatch(getOrgUserInvites());
        dispatch(getNamedRoles());
      }
    }
    if (userHasWritePrefilledSurveysPermission) {
      dispatch(fetchSurveyTypes("external"));
    }
  }, [
    userHasWritePrefilledSurveysPermission,
    userHasWriteSharedProfileUsersPermission,
    isFreeOrg,
    dispatch,
  ]);

  // Add an event listener on the document visibility to refresh the data whenever we switch back to this tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        dispatch(
          TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
        );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [dispatch]);

  const [currentTab, setCurrentTab] = useState<tabIds>("overview");
  useEffect(() => {
    if (location.state && location.state.currentTab)
      setCurrentTab(location.state.currentTab);
  }, [location.state]);

  const [inviteCollaboratorsModalOpen, setInviteCollaboratorsModalOpen] =
    useState(false);
  const [inviteExternalUsersModalOpen, setInviteExternalUsersModalOpen] =
    useState(false);
  const [addQuestionnaireModalOpen, setAddQuestionnaireModalOpen] =
    useState(false);

  let adminNamedRoleId = 0;
  let trustExchangeReadOnlyNamedRoleId = 0;
  if (userHasWriteSharedProfileUsersPermission && isFreeOrg && namedRoles) {
    [adminNamedRoleId, trustExchangeReadOnlyNamedRoleId] =
      getAdminAndTrustExchangeRoleIds(namedRoles);
  }

  if (location.pathname === "/trustpage/access") {
    return (
      <Redirect
        to={{
          pathname: "/trustpage",
          state: {
            currentTab: "access",
          },
        }}
      />
    );
  }

  const navigateToSettings = () => {
    //looks like we're only hitting this on a publish... so capture the event for PLG here
    dispatch(
      setPLGTaskCompleteIfIncomplete("Checklist_Trust_Exchange_Profile")
    );

    history.push("/trustpage/settings", {
      backContext: {
        backTo: trustPageRoute,
        backToText: "Back to Trust Page",
      },
    });
  };

  const navigateToPreview = () => {
    history.push(trustPagePreviewRoute, {
      backContext: {
        backTo: trustPageRoute,
        backToText: "Back to Trust Page",
      },
    });
  };

  return (
    <div id="my_shared_profile">
      <PageHeader
        history={history}
        titleTag={"Trust Page"}
        title={
          <div className={"my-shared-profile-page-header-title"}>
            Trust Page{" "}
            <SharedProfileAccessControlPill
              accessProtection={!sharedAssessmentAccess?.access.publicAccess}
              requireNda={sharedAssessmentAccess?.access.requireNda ?? false}
            />
          </div>
        }
        isBreachsight={orgHasBreachsight && userHasBreachsight && !isFreeOrg}
        backAction={
          location.state?.backContext?.goBack
            ? history.goBack
            : location.state && location.state.backContext
              ? () => history.push(location.state.backContext?.backTo || "/")
              : undefined
        }
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : undefined
        }
        rightSection={
          <>
            {sharedAssessmentAccess && sharedAssessment && (
              <>
                {sharedAssessmentAccess.access.published ? (
                  <Button
                    className="settings-btn"
                    onClick={navigateToSettings}
                    disabled={!userHasWritePrefilledSurveysPermission}
                  >
                    Trust Page: Published
                    <div className="pipe" />
                    <div className="cr-icon-cog2" />
                  </Button>
                ) : (
                  <>
                    <Button onClick={navigateToPreview}>
                      <i className={"cr-icon-eye"} />
                      Preview
                    </Button>
                    <PublishTrustPageButton
                      primary
                      sharedAssessment={sharedAssessment}
                      sharedAssessmentAccess={sharedAssessmentAccess}
                    />
                  </>
                )}
              </>
            )}
            {sharedAssessmentAccess &&
              sharedAssessment &&
              userHasWriteSharedProfileUsersPermission &&
              sharedAssessmentAccess.access.published && (
                <>
                  <PublishTrustPageButton
                    sharedAssessment={sharedAssessment}
                    sharedAssessmentAccess={sharedAssessmentAccess}
                  />
                  <Button
                    primary
                    onClick={() => setInviteExternalUsersModalOpen(true)}
                  >
                    <div className="cr-icon-share" />
                    Share
                  </Button>
                </>
              )}
          </>
        }
      />
      {isLoading && <LoadingBanner />}
      {sharedAssessment &&
        receivedSurveys &&
        (!userHasWritePrefilledSurveysPermission ||
          !!sharedAssessmentAccess) && (
          <>
            {isFreeOrg && !userHasWritePrefilledSurveysPermission && (
              <InfoBanner
                type={BannerType.INFO}
                message="What is my Trust Page, and why can't I edit it?"
                subItems={[
                  `
                Your Trust Page allows you to proactively share security-related information with companies that need to
                assess you. This means less time spent filling in manual assessments, customers have an accurate and
                current view of your security posture, and you can focus on being proactive rather than just keeping up
                with assessments. You currently have read-only access as someone else at your organization has already
                claimed your Trust Page. We have sent a request on your behalf to the administrators of
                ${sharedAssessment.linkedVendor.name} to grant you full access. We'll notify you via email if they grant
                it to you. If you have any questions or believe that the administrator of your account is no longer
                working at ${sharedAssessment.linkedVendor.name}, please contact us via support@upguard.com.`,
                ]}
              />
            )}
            {userHasWritePrefilledSurveysPermission && (
              <MySharedProfileProgressCard
                dispatch={dispatch}
                isFreeAccount={isFreeOrg}
                sharedAssessment={sharedAssessment}
                sharedAssessmentAccess={sharedAssessmentAccess!}
                onPublishSharedProfile={navigateToSettings}
                anyCollaborators={anyCollaborators(
                  currentUserId,
                  orgUsers || [],
                  orgUserInvites || []
                )}
                openInviteCollaboratorsModal={() =>
                  setInviteCollaboratorsModalOpen(true)
                }
                openAddQuestionnaireModal={() =>
                  setAddQuestionnaireModalOpen(true)
                }
              />
            )}
            {/* Only write access users should be able to switch to the Users and Access Log tabs */}
            {userHasWriteSharedProfileUsersPermission && (
              <TabButtons
                onChangeTab={(tabId) => setCurrentTab(tabId as tabIds)}
                tabs={[
                  { id: "overview", text: "Trust Page" },
                  {
                    id: "access",
                    text:
                      sharedAssessmentAccess?.accessRequests &&
                      sharedAssessmentAccess.accessRequests.length > 0
                        ? `Access (${sharedAssessmentAccess.accessRequests.length})`
                        : "Access",
                  },
                  ...(isFreeOrg
                    ? [{ id: "your_organisation", text: "Your organization" }]
                    : []),
                  { id: "log", text: "Access log" },
                ]}
                activeTabId={currentTab}
              />
            )}
            {currentTab === "overview" ? (
              <>
                {sharedAssessment.hasAccessNewTrustExchangeView ? (
                  <ManageTrustPageContentView
                    sharedAssessment={sharedAssessment}
                    surveyTypes={surveyTypes || []}
                    receivedSurveys={[...receivedSurveys, ...importedSurveys]}
                    userHasWritePrefilledSurveysPermission={
                      userHasWritePrefilledSurveysPermission
                    }
                  />
                ) : (
                  <SharedProfile
                    dispatch={dispatch}
                    history={history}
                    isOwnSharedProfile
                    userHasWritePrefilledSurveysPermission={
                      userHasWritePrefilledSurveysPermission
                    }
                    sharedAssessment={sharedAssessment}
                    uuid={sharedAssessmentAccess?.access?.uuid}
                    receivedSurveys={[...receivedSurveys, ...importedSurveys]}
                    surveyTypes={surveyTypes}
                  />
                )}
              </>
            ) : currentTab === "access" ? (
              <SharedProfileAccess
                sharedAssessmentAccess={sharedAssessmentAccess}
              />
            ) : currentTab === "your_organisation" ? (
              <ReportCard newStyles>
                <EmptyStateCircle
                  imgName="PageMoved"
                  title="This page has moved"
                  subtext={
                    <>
                      You may now manage user permissions via the{" "}
                      <Link to="/settings/users">Settings</Link> page on the
                      left.
                    </>
                  }
                />
              </ReportCard>
            ) : currentTab === "log" ? (
              <SharedProfileAccessLog />
            ) : null}
            {userHasWriteSharedProfileUsersPermission && isFreeOrg && (
              <SharedProfileFreeOrgInviteUserModal
                dispatch={dispatch}
                active={inviteCollaboratorsModalOpen}
                onClose={() => setInviteCollaboratorsModalOpen(false)}
                adminNamedRoleId={adminNamedRoleId}
                trustExchangeReadOnlyNamedRoleId={
                  trustExchangeReadOnlyNamedRoleId
                }
                orgPrimaryHostname={
                  sharedAssessment.linkedVendor.primary_hostname
                }
              />
            )}
            {userHasWriteSharedProfileUsersPermission && (
              <ShareSharedProfileModal
                dispatch={dispatch}
                active={inviteExternalUsersModalOpen}
                onClose={() => setInviteExternalUsersModalOpen(false)}
                uuid={sharedAssessmentAccess?.access?.uuid}
                usersMustRequestAccess={
                  sharedAssessmentAccess?.access.publicAccess !== true
                }
              />
            )}
            <AddQuestionnaireToSharedProfileModal
              dispatch={dispatch}
              active={addQuestionnaireModalOpen}
              onClose={() => setAddQuestionnaireModalOpen(false)}
              receivedSurveys={[...receivedSurveys, ...importedSurveys]}
              sharedProfileSurveys={sharedAssessment.questionnaires || []}
              surveyTypes={surveyTypes || []}
            />
          </>
        )}
      {isErrorLoadingSharedAssessment &&
        (userCanViewSharedProfile ? (
          <ReportCard newStyles>
            <ErrorCardWithAction
              errorText={`An error occurred while loading the Trust Page`}
            />
          </ReportCard>
        ) : (
          <ReportCard newStyles>
            <EmptyCardWithAction
              iconSrc={NoAccessSvg}
              emptyText={"You don't have permission to access Trust Page"}
              emptySubText={
                "If you believe you should have access, please contact an administrator of your UpGuard account."
              }
            />
          </ReportCard>
        ))}
    </div>
  );
};

export default appConnect<
  IMySharedProfileConnectedProps,
  never,
  IMySharedProfileOwnProps
>((state) => {
  const currentOrg = getCurrentOrgFromUserData(state.common.userData);

  return {
    vendorPrimaryHostname: currentOrg ? currentOrg.mainHostname : "",
    isFreeOrg:
      currentOrg?.accountType === organisationAccountType.free ||
      isExpiredTrial(currentOrg), // We'll count expired trials as free orgs as well so they can access adding users
    orgUsers: state.cyberRisk.orgUsers.data,
    orgUserInvites: state.cyberRisk.orgUserInvites.data,
    namedRoles: state.common.namedRoles.data,
    surveyTypes: state.cyberRisk.customerData.surveyTypes?.external.filter(
      (st) => st.usageType === SurveyUsageType.Security
    ),
  };
})(MySharedProfile);

import BaseAPI from "../../_common/rtkQueryApi";
import {
  getTrustPageCustomDomainsV1Resp,
  verifyTrustPageCustomDomainV1Resp,
} from "./trustpage.api.types";

export enum TrustPageCustomDomainsTagTypes {
  trustPageCustomDomains = "trustPageCustomDomains",
}

const TrustPageCustomDomainsAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(TrustPageCustomDomainsTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getTrustPageCustomDomainsV1: builder.query<
      getTrustPageCustomDomainsV1Resp,
      void
    >({
      query: () => ({
        url: "/trustpage/customdomains/v1",
        method: "GET",
      }),
      providesTags: [TrustPageCustomDomainsTagTypes.trustPageCustomDomains],
    }),

    addTrustPageCustomDomainV1: builder.mutation<
      void,
      { domain: string; isPrimary: boolean }
    >({
      query: ({ domain, isPrimary }) => ({
        url: "/trustpage/customdomains/v1",
        method: "POST",
        body: JSON.stringify({
          domain,
          isPrimary,
        }),
        invalidatesTags: [
          TrustPageCustomDomainsTagTypes.trustPageCustomDomains,
        ],
      }),
    }),

    deleteTrustPageCustomDomainV1: builder.mutation<void, { domain: string }>({
      query: ({ domain }) => ({
        url: "/trustpage/customdomains/v1",
        method: "DELETE",
        params: {
          domain,
        },
        invalidatesTags: [
          TrustPageCustomDomainsTagTypes.trustPageCustomDomains,
        ],
      }),
    }),

    verifyTrustPageCustomDomainV1: builder.mutation<
      verifyTrustPageCustomDomainV1Resp,
      { domain: string }
    >({
      query: ({ domain }) => ({
        url: "/trustpage/customdomain/verify/v1",
        method: "PUT",
        body: JSON.stringify({
          domain,
        }),
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          if (data.status === "complete") {
            dispatch(
              TrustPageCustomDomainsAPI.util.invalidateTags([
                TrustPageCustomDomainsTagTypes.trustPageCustomDomains,
              ])
            );
          }
        });
      },
    }),
  }),
});

export default TrustPageCustomDomainsAPI;

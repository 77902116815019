import { RiskDetail } from "../../../_common/types/vendor";
import { AssetSelection } from "./SelectRiskTable";
import { AssuranceType } from "../../../_common/types/organisations";
import { getVendorWords } from "../../../_common/constants";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../../_common/components/InfoTable";
import RiskName from "../risk_profile/RiskName";
import { Severity, SeverityAsString } from "../../../_common/types/severity";
import QuestionnaireSelection from "./QuestionnaireSelection";
import { formatDate, pluralise } from "../../../_common/helpers";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import { SeverityChangeDisplay } from "../../../_common/components/SeverityIcon";

interface ConfirmStepProps {
  waiverType: WaiverType;
  vendorName?: string;
  risk: RiskDetail;
  selectedAssets: AssetSelection;
  approverEmail?: string;
  justification: string;
  expiry?: string;
  assuranceType: AssuranceType;
  adjustedSeverity?: Severity;
}

const ConfirmStep = (props: ConfirmStepProps) => {
  const vendorWords = getVendorWords(props.assuranceType);

  const getAssetsText = () => {
    let assetText = "";
    if (props.risk.numFailedCloudscans) {
      if (props.selectedAssets.hostnameSelection.includeFuture) {
        assetText = "All domains & IPs (including future detections)";
      } else {
        assetText = `${
          props.selectedAssets.hostnameSelection.selected.length
        } ${pluralise(
          props.selectedAssets.hostnameSelection.selected.length,
          "domain/IP",
          "domains & IPs"
        )}`;
      }
    } else if (props.risk.surveys) {
      if (props.selectedAssets.allQuestionnairesSelected) {
        assetText = "All questionnaires";
      } else {
        const totalCount = props.risk.surveys.filter(
          (s) =>
            props.selectedAssets.selectedQuestionnaires.indexOf(s.surveyId) !==
            -1
        ).length;
        assetText = `${totalCount} ${pluralise(
          totalCount,
          "questionnaire",
          "questionnaires"
        )}`;
      }
    } else if (props.risk.additionalEvidences) {
      assetText = "All documents";
    } else if (props.risk.securityProfiles) {
      assetText = "Security profile";
    }

    return assetText;
  };

  const selectedPrivateSurveys = props.risk.surveys
    ? props.risk.surveys.filter(
        (s) =>
          !s.publicSurvey &&
          props.selectedAssets.selectedQuestionnaires.indexOf(s.surveyId) !== -1
      )
    : [];
  const selectedPublicSurveys = props.risk.surveys
    ? props.risk.surveys.filter(
        (s) =>
          s.publicSurvey &&
          props.selectedAssets.selectedPublicQuestionnaires.indexOf(
            s.surveyId
          ) !== -1
      )
    : [];

  const selectedSurveys = props.risk.surveys
    ? [...selectedPrivateSurveys, ...selectedPublicSurveys]
    : [];

  return (
    <InfoTable
      bordered
      styling={InfoTableStyling.New}
      className={"vendor-risk-waiver-confirm-step"}
    >
      <InfoTableRow
        label={`${vendorWords.singularTitleCase} selected`}
        value={props.vendorName}
        hide={!props.vendorName}
      />
      <InfoTableRow
        label={
          props.waiverType === WaiverType.SeverityAdjustment
            ? "Risk"
            : "Risk to be waived"
        }
        rowClass={"label-middle"}
        value={
          <RiskName
            severity={SeverityAsString(props.risk.severity)}
            baseSeverity={
              props.risk.baseSeverity
                ? SeverityAsString(props.risk.baseSeverity)
                : undefined
            }
            riskName={props.risk.title}
            riskCategoryGroupName={props.risk.categoryTitle}
          />
        }
      />
      <InfoTableRow
        hide={!props.adjustedSeverity}
        label={"Severity adjustment"}
        value={
          <SeverityChangeDisplay
            baseSeverity={SeverityAsString(props.risk.severity)}
            severity={
              props.adjustedSeverity ?? SeverityAsString(props.risk.severity)
            }
          />
        }
      />
      <InfoTableRow label={"Justification"} value={props.justification} />
      <InfoTableRow
        label={"Assets affected"}
        value={getAssetsText()}
        hide={selectedSurveys.length !== 0}
      />
      <InfoTableRow
        label={"Assets affected"}
        value={
          <QuestionnaireSelection
            viewMode={true}
            selectedQuestionnaireIds={
              props.selectedAssets.selectedQuestionnaires
            }
            selectedPublicQuestionnaireIds={
              props.selectedAssets.selectedPublicQuestionnaires
            }
            questionnaires={selectedSurveys}
            showWaived
          />
        }
        hide={selectedSurveys.length === 0}
      />
      <InfoTableRow
        label={"Expires"}
        value={
          props.expiry
            ? formatDate(props.expiry)
            : `This ${
                props.waiverType === WaiverType.SeverityAdjustment
                  ? "adjustment"
                  : "waiver"
              } does not expire.`
        }
      />
      <InfoTableRow
        label={"To be approved by"}
        value={props.approverEmail}
        hide={props.approverEmail === undefined || props.approverEmail === ""}
      />
    </InfoTable>
  );
};

export default ConfirmStep;

import classNames from "classnames";
import { FC } from "react";
import "../../style/components/securityprofile/StatusIcon.scss";
import {
  ControlState,
  ControlStateIcon,
  ControlStateText,
} from "../../types/securityProfile";
import IconNA from "../../../_common/images/score-icon-na.svg";

interface StatusIconProps {
  controlState: ControlState;
  statusCount?: number;
  onClick?: () => void;
}

const StatusIcon: FC<StatusIconProps> = ({
  controlState,
  statusCount,
  onClick,
}) => (
  <div className={"status-icon-container"}>
    <div
      onClick={onClick}
      className={classNames("status-icon", controlState, {
        clickable: !!onClick,
      })}
    >
      {controlState == ControlState.NA ? (
        <img
          className={"svg-icon"}
          src={IconNA}
          aria-label={ControlStateText[controlState]}
          alt={ControlStateText[controlState]}
        />
      ) : (
        <div
          className={ControlStateIcon[controlState]}
          aria-label={ControlStateText[controlState]}
        />
      )}
    </div>
    {statusCount !== undefined && (
      <span className="status-count">{statusCount}</span>
    )}
  </div>
);

export default StatusIcon;

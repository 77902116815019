import { FC } from "react";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import LabelList from "../../../vendorrisk/components/LabelList";
import { appCategoryDescription } from "../../helpers/apps";

interface OrgCategoriesProps {
  categories: string[];
  maxWidth?: number;
  onClick?: (category: string) => void;
}

const CategoryList: FC<OrgCategoriesProps> = ({
  maxWidth,
  categories,
  onClick,
}) => {
  return (
    <div>
      {categories.length === 0 ? (
        <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>
      ) : (
        <LabelList
          maxWidth={maxWidth}
          labels={categories.slice().map((category) => ({
            id: category,
            name: appCategoryDescription(category),
            color: LabelColor.Grey,
            onClick: () => onClick?.(category),
          }))}
        />
      )}
    </div>
  );
};

export default CategoryList;

import { useEffect, useState } from "react";
import { ICorrespondenceMessage } from "../types/correspondenceMessage";
import { IUserMiniMap } from "../types/user";
import { addDefaultUnknownErrorAlert } from "../reducers/messageAlerts.actions";
import LoadingBanner from "./core/LoadingBanner";
import Correspondence from "./Correspondence";
import "../style/components/MessagesPanel.scss";
import MessageInput from "./MessageInput";
import classnames from "classnames";
import {
  countMessages,
  countUnreadMessages,
} from "./correspondence/correspondence.helpers";
import CircledIcon from "./CircledIcon";
import EmptyCardWithAction from "./EmptyCardWithAction";
import { useAppDispatch } from "../types/reduxHooks";

export type AddMessageFunc = (
  parentId: number | undefined,
  content: string,
  isPrivate: boolean
) => Promise<any>;

export interface MessageGroup {
  id: string;
  messages: ICorrespondenceMessage[];
  headerContent: React.ReactNode;
  content?: React.ReactNode;
  hideReply: boolean;
  onAddGroupMessage: AddMessageFunc;
}

interface IMessagesPanelProps {
  className?: string;
  messages?: ICorrespondenceMessage[];
  users?: IUserMiniMap;
  currentUserId: number;
  hasWritePermission: boolean;
  otherVendorName?: string;
  allowedPrivateMessages: boolean;
  privateMessagesOnly?: boolean;
  fetchMessages?: (force: boolean, noMarkRead?: boolean) => Promise<any>;
  editMessage: (messageId: number, newContent: string) => Promise<any>;
  addMessage: AddMessageFunc;

  // Optionally hide the reply in thread button
  hideReply?: boolean;

  // Optional callback to retrieve class for a message
  getClassForMessage?: (message: ICorrespondenceMessage) => string | undefined;

  // Optional message group funtionality
  groups?: MessageGroup[];
  currentGroup?: MessageGroup;
  onGroupSelect?: (group?: MessageGroup) => void;

  // Optionally,override empty content
  emptyContent?: React.ReactNode;

  publicReplyHelp?: string;
  sendDisabled?: boolean;
  editDisabled?: boolean;

  loading?: boolean;

  onSubmitParentCommentButtonText?: string;
  parentCommentInputPlaceholderText?: string;
  preMessagesContent?: React.ReactNode;
}

const MessagesPanel = (props: IMessagesPanelProps) => {
  const {
    className,
    messages,
    users,
    currentUserId,
    hasWritePermission,
    otherVendorName,
    allowedPrivateMessages,
    privateMessagesOnly,
    fetchMessages,
    editMessage,
    addMessage,
    hideReply,
    getClassForMessage,
    groups,
    currentGroup,
    onGroupSelect,
    emptyContent,
    publicReplyHelp,
    sendDisabled,
    editDisabled = false,
    loading,
    onSubmitParentCommentButtonText,
    parentCommentInputPlaceholderText,
    preMessagesContent,
  } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Even though we already have the data, refetch the messages on mount so all messages
    // get correctly marked as read.
    if (fetchMessages) {
      fetchMessages(true, false);
    }
  }, []);

  const [messageContent, setMessageContent] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);

  // Notify of group selection change
  useEffect(() => {
    if (onGroupSelect && currentGroup) {
      onGroupSelect(currentGroup);
    }
  }, [currentGroup]);

  const onEditMessage = (messageId: number, newContent: string) =>
    editMessage(messageId, newContent)
      .then(fetchMessages ? () => fetchMessages(true) : () => Promise.resolve())
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error editing message"));
        // Rethrow so the Message knows it failed
        throw e;
      });

  const onReplyMessage = (
    parentId: number,
    content: string,
    isPrivate: boolean
  ) =>
    addMessage(parentId, content, isPrivate)
      .then(fetchMessages ? () => fetchMessages(true) : () => Promise.resolve())
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error sending message"));
        // Rethrow so the Message knows it failed
        throw e;
      });

  const onSendMessage = (
    isPrivate: boolean,
    groupSendFunc?: AddMessageFunc
  ) => {
    setSendingMessage(true);
    let prom;
    if (groupSendFunc) {
      prom = groupSendFunc(undefined, messageContent, isPrivate);
    } else {
      prom = addMessage(undefined, messageContent, isPrivate);
    }

    prom
      .then(fetchMessages ? () => fetchMessages(true) : () => Promise.resolve())
      .then(() => {
        setMessageContent("");
        setSendingMessage(false);
      })
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error sending message"));
        setSendingMessage(false);
      });
  };

  let content = <LoadingBanner />;
  if ((!messages && !groups) || loading) {
    // Just show loading banner as-is
  } else if (groups && groups.length > 0 && !currentGroup) {
    // There are groups but no current group is selected so show the groups display
    const groupDisplays: JSX.Element[] = [];

    for (const group of groups) {
      const unreadCount = countUnreadMessages(group.messages);
      const total = countMessages(group.messages);

      groupDisplays.push(
        <div
          key={group.id}
          className={"message-group"}
          onClick={onGroupSelect ? () => onGroupSelect(group) : undefined}
        >
          <div className={"message-group-header"}>
            {group.headerContent}
            <div className={"message-group-action"}>
              {unreadCount > 0 && (
                <div className={"message-group-unread"}>{unreadCount}</div>
              )}
              <div className={"message-group-total"}>
                <i className={"cr-icon-chat-messages"} /> {total}
              </div>
              <i className={"cr-icon-chevron"} />
            </div>
          </div>
          {group.content && (
            <div className={"message-group-content"}>{group.content}</div>
          )}
        </div>
      );
    }

    content = (
      <>
        <div className={"message-groups"}>{groupDisplays}</div>
      </>
    );
  } else {
    const editMessageFunc = editDisabled ? undefined : onEditMessage;

    // No group data OR a group is selected, so show messages
    let messagesToShow = messages;
    let replyMessageFunc =
      !hideReply && hasWritePermission ? onReplyMessage : undefined;
    let groupSendFunc: AddMessageFunc | undefined = undefined;

    if (currentGroup) {
      messagesToShow = currentGroup.messages;
      replyMessageFunc = !currentGroup.hideReply ? replyMessageFunc : undefined;
      groupSendFunc = currentGroup.onAddGroupMessage;
    }

    const emptyMessageContent = emptyContent ? (
      emptyContent
    ) : (
      <p>There are no messages here yet.</p>
    );

    content = (
      <>
        {messagesToShow && messagesToShow.length > 0 && (
          <Correspondence
            newStyles
            currentUserId={currentUserId}
            messages={messagesToShow}
            // @ts-ignore
            users={users}
            editMessageFunc={editMessageFunc}
            replyMessageFunc={replyMessageFunc}
            allowedPrivateMessages={allowedPrivateMessages}
            privateMessagesOnly={privateMessagesOnly}
            getClassForMessage={getClassForMessage}
            preMessagesContent={preMessagesContent}
          />
        )}
        {(!messagesToShow || messagesToShow.length === 0) && (
          <EmptyCardWithAction
            iconJSX={<CircledIcon iconClass={"cr-icon-chat-messages"} />}
            emptySubText={emptyMessageContent}
          />
        )}
        {hasWritePermission && !sendDisabled && (
          <div className="message-input-section">
            <MessageInput
              value={messageContent}
              onChange={(val: string) => setMessageContent(val)}
              onCancel={() => setMessageContent("")}
              onSubmit={() => onSendMessage(false, groupSendFunc)}
              onPrivate={
                allowedPrivateMessages
                  ? () => onSendMessage(true, groupSendFunc)
                  : undefined
              }
              onSubmitText={onSubmitParentCommentButtonText || "Send"}
              loading={sendingMessage}
              placeholder={
                parentCommentInputPlaceholderText ||
                (otherVendorName
                  ? `Send a message to ${otherVendorName}`
                  : "Send a message")
              }
              showActions={messageContent.trim().length > 0}
              privatePlaceholder="Add a private note (only visible to users of your account)"
              publicReplyHelp={publicReplyHelp}
              internalNoteHelp="Internal notes will only be visible to members of your account."
              privateMessagesOnly={privateMessagesOnly}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div className={classnames("messages-panel", className)}>{content}</div>
  );
};

MessagesPanel.defaultProps = {
  messages: undefined,
  users: undefined,
};

export default MessagesPanel;

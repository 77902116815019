import { useBack } from "../../_common/types/router";
import { FC } from "react";
import userbaseApi from "../api/userbase.api";
import UserBaseUserHeader from "../components/UserBaseUserHeader";
import { userPermissionsUrl, usersUrl, userUrl } from "../UserBaseAppRouter";
import UserPermissions from "../components/UserPermissions";
import { UserRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";

const UserPermissionsView: FC = () => {
  const match = useRouteMatch<UserRouteParams>();

  const userUUID = match.params.userUUID;

  const { data } = userbaseApi.useGetUserBaseUserSummaryV1Query({ userUUID });

  const { backAction, backText } = useBack();

  return (
    <div className="user-profile">
      <UserBaseUserHeader
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: data?.user.name ?? "User",
            to: userUrl(userUUID),
          },
          {
            text: "Permissions",
            to: userPermissionsUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Permissions"}
      />

      <UserPermissions />
    </div>
  );
};

export default UserPermissionsView;

import "../../style/components/filter/SlidePanelSection.scss";
import { FC, ReactNode, useEffect, useState, useCallback } from "react";
import Icon from "../../../_common/components/core/Icon";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import classnames from "classnames";

export interface checkItemSlidePanelSectionProps {
  title: string; // Shown at the top of the row
  startExpanded: boolean; // Whether the row should start expanded
  children?: React.ReactNode;
}

// Represents a collapsable row within the RiskPanel.
export const CheckItemSlidePanelSection: FC<checkItemSlidePanelSectionProps> = (
  props
) => {
  const [expanded, setExpanded] = useState(props.startExpanded);

  const onToggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <div className="check-item">
      <SlidePanelSection
        toggleExpand={onToggleExpanded}
        title={<div className={"section-title"}>{props.title}</div>}
        expanded={expanded}
      >
        {props.children}
      </SlidePanelSection>
    </div>
  );
};

interface ISlidePanelSectionProps {
  children: ReactNode;
  title: ReactNode;
  expanded?: boolean;
  toggleExpand?: () => void;
  classname?: string;
}

export const SlidePanelSection: FC<ISlidePanelSectionProps> = ({
  children,
  title,
  expanded,
  toggleExpand,
  classname,
}) => {
  const [expandedInternal, setExpandedInternal] = useState(expanded ?? true);

  // Respond to parent changing expand state if managed externally
  useEffect(() => {
    if (expanded === true || expanded === false) {
      setExpandedInternal(expanded);
    }
  }, [expanded]);

  const expandToggle = () => {
    if (toggleExpand) {
      // Managed externally, so notify
      toggleExpand();
    } else {
      setExpandedInternal(!expandedInternal);
    }
  };

  const classes = classnames("slide-panel-section", classname);

  return (
    <div className={classes}>
      <div className="section-header" onClick={expandToggle}>
        <h3>{title}</h3>
        <Icon name="chevron" direction={expandedInternal ? 0 : 180} />
      </div>
      <TransitionGroup component={null}>
        {expandedInternal && (
          <CSSTransition
            key={"expand-section"}
            timeout={250}
            classNames="expand"
          >
            <div className="section-body">{children}</div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

interface IFixedSlidePanelSectionProps {
  children: ReactNode;
  title?: ReactNode;
  className?: string;
}

/* A section that is always expanded and does not have a toggle
 * The fixed variant shares styles with the expandable variant, but does not have the expand/collapse functionality.
 */
export const FixedSlidePanelSection: FC<IFixedSlidePanelSectionProps> = ({
  children,
  title,
  className,
}) => {
  return (
    <div className={classnames("slide-panel-section", className)}>
      {title ? (
        <div className="section-header">
          <h3>{title}</h3>
        </div>
      ) : null}
      <div className="section-body">{children}</div>
    </div>
  );
};

export default SlidePanelSection;

import { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import userbaseApi from "../../api/userbase.api";
import PageHeader from "../../../_common/components/PageHeader";
import { useBack } from "../../../_common/types/router";
import BreachSightCreateRiskWaiverFlow, {
  BreachSightCreateRiskWaiverFlowCreationDetails,
  getNewBlankWaiver,
} from "../../../vendorrisk/components/risk_waivers/BreachSightCreateRiskWaiverFlow";
import { ExpireType } from "../../../vendorrisk/components/risk_waivers/BreachSightWaiverDetailsStep";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { riskModificationUrl } from "../../UserBaseAppRouter";
import SaaSRiskWaiverUsersSelection from "../../components/risk_waivers/SaaSRiskWaiverUsersSelection";
import { computeUsersInRemediation } from "../RiskProfileView";
import UserBaseAPI from "../../api/userbase.api";

export interface SaaSRiskAssetSelection {
  selectedUuids: string[];
}

export interface CreateRiskModificationLocationState {
  riskId: string;
  userUUID?: string;
}

const CreateRiskModificationView: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { backAction, backText } = useBack();

  const location = useLocation<CreateRiskModificationLocationState>();
  const { riskId, userUUID } = location.state;

  const { data } = userbaseApi.useGetUserBaseRisksV1Query(
    { riskId },
    { skip: !riskId }
  );
  const { data: riskUsers } = userbaseApi.useGetUserBaseRiskUsersV1Query(
    { riskId: riskId ?? "" },
    { skip: !riskId } // don't fetch the risks unless a valid risk ID is provided
  );
  // we neglect the loading state here because it is handled in the SelectUsersStep
  // component which makes the same API call and doesn't show the full list of users
  // until this API has completed.
  const { data: remediationsData } =
    UserBaseAPI.useGetUserBaseRemediationsV1Query(
      undefined,
      { skip: !riskId } // don't fetch the remediations unless a valid risk ID is provided
    );
  const { data: riskWaiversData } =
    userbaseApi.useGetUserBaseRiskWaiversV1Query();

  const selectedRisk = (data?.risks ?? []).find((r) => r.id === riskId);

  const [createUserbaseRiskWaiverV1, submitStatus] =
    userbaseApi.useCreateUserBaseRiskWaiverV1Mutation();

  const [waiverDetails, setWaiverDetails] =
    useState<BreachSightCreateRiskWaiverFlowCreationDetails>(
      getNewBlankWaiver(selectedRisk)
    );

  useEffect(() => {
    if (riskUsers) {
      const inRemediations = computeUsersInRemediation(
        remediationsData?.requests ?? []
      );
      const usersUnderRemediation =
        riskId && inRemediations[riskId]
          ? inRemediations[riskId].userUUIDs
          : [];

      const waivedUsers = [
        ...new Set(
          (riskWaiversData?.waivers ?? [])
            .filter((w) => w.riskId === riskId)
            .map((w) => w.userInfos.map((u) => u.uuid))
            .flat()
        ),
      ];

      const userUUIDsToSelect = riskUsers.users
        .filter((u) => !usersUnderRemediation.includes(u.uuid))
        .filter((u) => !waivedUsers.includes(u.uuid))
        .filter((u) => !userUUID || userUUID === u.uuid)
        .map((u) => u.uuid);

      const waiverDetails = getNewBlankWaiver(selectedRisk);
      waiverDetails.assetSelection.saasSelection = {
        selectedUuids: userUUIDsToSelect,
      };

      setWaiverDetails(waiverDetails);
    }
  }, [selectedRisk, riskUsers, userUUID, riskId, remediationsData?.requests]);

  const submit = () => {
    createUserbaseRiskWaiverV1({
      riskId: waiverDetails.selectedRisk?.id ?? "",
      approverEmail: waiverDetails.approverEmail.isValid
        ? waiverDetails.approverEmail.value
        : undefined,
      justification: waiverDetails.justification.value,
      expiresAt:
        waiverDetails.expireType === ExpireType.Other
          ? waiverDetails.expireDate
          : undefined,
      userbaseUserUuids:
        waiverDetails.assetSelection.saasSelection?.selectedUuids,
    })
      .unwrap()
      .then(() => {
        dispatch(addDefaultSuccessAlert("Risk waiver created"));
      })
      .then(() => {
        history.push(riskModificationUrl, { noRemoveWhispers: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Failed to create risk waiver"));
      });
  };

  const onSelectionChange = (selectedUuids: string[]) => {
    setWaiverDetails({
      ...waiverDetails,
      assetSelection: {
        ...waiverDetails.assetSelection,
        saasSelection: {
          selectedUuids,
        },
      },
    });
  };

  return (
    <div id="userbase-create-risk-modification">
      <PageHeader
        history={history}
        title="Create Risk Waiver"
        backAction={backAction}
        backText={backText}
      />
      <BreachSightCreateRiskWaiverFlow
        requireApprovalsRiskWaivers={false}
        canUsePublicWaivers={false}
        waiverDetails={waiverDetails}
        onWaiverDetailsChanged={setWaiverDetails}
        onCancel={backAction!}
        isSubmitting={submitStatus.isLoading}
        onSubmit={submit}
        riskWaivers={riskWaiversData?.waivers}
        selectRiskUIOverride={
          <SaaSRiskWaiverUsersSelection
            riskId={riskId}
            selectedUserUUIDs={
              waiverDetails.assetSelection.saasSelection?.selectedUuids ?? []
            }
            onSelectionChange={onSelectionChange}
          />
        }
      />
    </div>
  );
};

export default CreateRiskModificationView;

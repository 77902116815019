import { useBack } from "../../../_common/types/router";
import { UserRouteParams } from "../../UserBaseNavItems";
import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import { userSummaryUrl, usersUrl } from "../../UserBaseAppRouter";
import UserBaseUserHeader from "../../components/headers/UserBaseUserHeader";
import UserRisksOverview from "../../components/users/UserRisksOverview";
import UserAppsOverview from "../../components/users/UserAppsOverview";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import UserTimeline from "../../components/users/UserTimeline";

import AboutUser from "../../components/users/AboutUser";
import "./UserSummaryView.scss";
import UserPermissionsOverview from "../../components/users/UserPermissionsOverview";
import UserScoresChart from "../../components/users/UserScoresChart";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const UserSummaryView: FC = () => {
  const match = useDecodedRouteMatch<UserRouteParams>();

  const userUUID = match.params.userUUID;

  const { data, isLoading } = userbaseApi.useGetUserBaseUserSummaryV1Query({
    userUUID,
  });

  const { backAction, backText } = useBack();

  return (
    <div className="userbase-user-summary">
      <UserBaseUserHeader
        userUUID={userUUID}
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: data?.user.name ?? "User",
            to: userSummaryUrl(userUUID),
          },
          {
            text: "Summary",
            to: userSummaryUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Summary"}
      />

      {isLoading && <LoadingBanner size={70} />}
      {!isLoading && (
        <>
          <div className="risk-graph">
            <UserScoresChart userUUID={userUUID} />
          </div>

          <div className="two-grid no-stretch">
            <UserRisksOverview userUUID={userUUID} />
            <UserAppsOverview userUUID={userUUID} />
          </div>
          <div className="two-grid no-stretch">
            <AboutUser userUUID={userUUID} />
            <UserPermissionsOverview userUUID={userUUID} />
          </div>
          <UserTimeline userUUID={userUUID} />
        </>
      )}
    </div>
  );
};

export default UserSummaryView;

import { IAttachment, IAttachmentGroup } from "../../_common/types/attachment";
import { useLayoutEffect, useState } from "react";
import {
  deleteSurveyFile,
  fetchSurveyAttachments,
  fetchSurveyPreviewAttachments,
  updateSurveyFileDescription,
  uploadSurveyFile,
} from "../reducers/apiActions";

import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../_common/types/redux";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import SurveyViewerItemIcon from "./SurveyViewerItemIcon";
import { NodeType } from "../../survey_builder/types/types";
import { NodeSummaryAndNode } from "../surveyViewer.helpers";
import { AcceptedDocumentFileExtensionsList } from "../../vendorrisk/components/modals/DocumentModal";
import DragDropUpload from "../../_common/components/DragDropUpload";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SurveyViewerDocument, {
  getSurveyFileDownloadUrl,
} from "./SurveyViewerDocument";
import Icon from "../../_common/components/core/Icon";
import Button from "../../_common/components/core/Button";
import { appConnect } from "../../_common/types/reduxHooks";
import {
  AcceptedDocumentFileExtensions,
  AcceptedDocumentMimeTypes,
} from "../../_common/types/fileRestrictions";

export const ExtraAttachmentsNodeId = "survey_viewer__extra_attachments";
export const ExtraAttachmentsLabel = "Extra";

interface SurveyViewerExtraAttachmentsOwnProps {
  surveyId?: number;
  disabled: boolean;
  isPublicSurvey: boolean;
  isPreview: boolean;
  previewTypeId?: string;
  previewSectionId?: string;
}

interface SurveyViewerExtraAttachmentsConnectedProps {
  isLocked: boolean;
}

type SurveyViewerExtraAttachmentsProps = SurveyViewerExtraAttachmentsOwnProps &
  SurveyViewerExtraAttachmentsConnectedProps &
  DefaultThunkDispatchProp;

// For older-style attachments on older questionnaires (e.g. ISO27001)
const SurveyViewerExtraAttachments = (
  props: SurveyViewerExtraAttachmentsProps
) => {
  const [attachmentGroups, setAttachmentGroups] = useState<IAttachmentGroup[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  // On load, retrieve any existing attachments
  useLayoutEffect(() => {
    if (props.surveyId) {
      setIsLoading(true);
      props
        .dispatch(fetchSurveyAttachments(props.surveyId, props.isPublicSurvey))
        .then((r) => {
          setAttachmentGroups(r.attachmentGroups);
          setIsLoading(false);
        })
        .catch(() => {
          props.dispatch(
            addDefaultUnknownErrorAlert(
              "Error loading existing attachment groups"
            )
          );
          setIsLoading(false);
        });
    } else if (props.isPreview) {
      setIsLoading(true);
      props
        .dispatch(
          fetchSurveyPreviewAttachments(
            props.previewTypeId,
            props.previewSectionId
          )
        )
        .then((r) => {
          setAttachmentGroups(r);
          setIsLoading(false);
        })
        .catch(() => {
          props.dispatch(
            addDefaultUnknownErrorAlert(
              "Error loading preview attachment groups"
            )
          );
          setIsLoading(false);
        });
    }
  }, [props.surveyId]);

  const groupDisplays = attachmentGroups?.map((ag) => (
    <AttachmentGroup
      dispatch={props.dispatch}
      key={ag.name}
      group={ag}
      surveyId={props.surveyId}
      isPublicSurvey={props.isPublicSurvey}
      disabled={props.disabled || props.isLocked}
      onFileUploaded={(groupName, uploaded) => {
        // Add uploaded file to the group
        const newGroups = [...attachmentGroups];

        const group = newGroups.find((g) => g.name === groupName);
        if (group) {
          if (group.attachments) {
            group.attachments.push(uploaded);
          } else {
            group.attachments = [uploaded];
          }

          setAttachmentGroups(newGroups);
        }
      }}
      onFileDeleted={(groupName, deleted) => {
        // Add uploaded file to the group
        const newGroups = [...attachmentGroups];

        const group = newGroups.find((g) => g.name === groupName);
        if (group) {
          group.attachments = group.attachments.filter(
            (a) => a.id !== deleted.id
          );
          setAttachmentGroups(newGroups);
        }
      }}
      onDescUpdated={(groupName, id, newDesc) => {
        // Update description
        const newGroups = [...attachmentGroups];
        const group = newGroups.find((g) => g.name === groupName);
        if (group) {
          const attachmentToUpdate = group.attachments.find((a) => a.id === id);
          if (attachmentToUpdate) {
            attachmentToUpdate.description = newDesc;
            setAttachmentGroups(newGroups);
          }
        }
      }}
    />
  ));

  const attachmentNodeSummary = {
    questionNumber: ExtraAttachmentsLabel,
    node: {
      type: NodeType.Upload,
    } as any as Node,
  } as any as NodeSummaryAndNode;

  return (
    <div
      className={"survey-viewer-extra-attachments question-answer-node"}
      data-node-id={ExtraAttachmentsNodeId}
    >
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"attachment-groups-container"}>
          <SurveyViewerItemIcon nodeSummary={attachmentNodeSummary} />
          <div className={"attachment-groups"}>{groupDisplays}</div>
        </div>
      )}
    </div>
  );
};

export default appConnect<
  SurveyViewerExtraAttachmentsConnectedProps,
  never,
  SurveyViewerExtraAttachmentsOwnProps
>((state) => {
  return {
    isLocked: state.surveyViewer.lock.isLocked,
  };
})(SurveyViewerExtraAttachments);

interface AttachmentGroupProps {
  dispatch: DefaultThunkDispatch;
  surveyId?: number;
  isPublicSurvey: boolean;
  disabled: boolean;
  group: IAttachmentGroup;
  onFileUploaded: (groupName: string, uploaded: IAttachment) => void;
  onDescUpdated: (groupName: string, id: number, newDesc: string) => void;
  onFileDeleted: (groupName: string, deleted: IAttachment) => void;
}

const AttachmentGroup = (props: AttachmentGroupProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  // Also disable file upload when we have no surveyId (e.g. previewing)
  const shouldDisable = !props.surveyId || props.disabled;

  const attachmentDisplays = props.group.attachments?.map((a) => (
    <CSSTransition key={a.id} timeout={250} classNames="expand">
      <SurveyViewerDocument
        disabled={props.disabled}
        filename={a.filename}
        onDelete={() => {
          return props
            .dispatch(
              deleteSurveyFile(
                props.surveyId ?? 0,
                a.gcsObjectName,
                props.group.name,
                props.isPublicSurvey
              )
            )
            .then(() => {
              props.onFileDeleted(props.group.name, a);
            })
            .catch((e) => {
              props.dispatch(
                addDefaultUnknownErrorAlert(
                  "Error updating attachment description"
                )
              );
              throw e;
            });
        }}
        description={a.description}
        onDescriptionEdited={(newDesc) => {
          return props
            .dispatch(
              updateSurveyFileDescription(
                props.surveyId ?? 0,
                a.gcsObjectName,
                props.group.name,
                newDesc,
                props.isPublicSurvey
              )
            )
            .then(() => {
              props.onDescUpdated(props.group.name, a.id, newDesc);
            })
            .catch((e) => {
              props.dispatch(
                addDefaultUnknownErrorAlert(
                  "Error updating attachment description"
                )
              );
              throw e;
            });
        }}
        onDownload={() => {
          window.open(
            getSurveyFileDownloadUrl(
              props.surveyId ?? 0,
              a.gcsObjectName,
              props.group.name,
              props.isPublicSurvey
            )
          );
        }}
      />
    </CSSTransition>
  ));

  return (
    <div className={"attachment-group"}>
      <div
        className={"group-header"}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={"group-name"}>{props.group.friendlyName}</div>
        <Button
          tertiary
          onClick={() => setIsExpanded(!isExpanded)}
          className={"expand-toggle"}
        >
          <Icon name={"chevron"} direction={!isExpanded ? 180 : 0} />
        </Button>
      </div>
      <TransitionGroup component={null}>
        {isExpanded && (
          <CSSTransition
            key={"group-content"}
            timeout={250}
            classNames="expand"
          >
            <div className={"group-content"}>
              <div className={"attachments"}>
                <TransitionGroup component={null}>
                  {attachmentDisplays}
                </TransitionGroup>
              </div>
              {!props.disabled && (
                <DragDropUpload
                  loading={isUploading}
                  disabled={shouldDisable}
                  doNotKeepState
                  acceptedFileTypeFilters={[
                    ...AcceptedDocumentFileExtensions,
                    ...AcceptedDocumentMimeTypes,
                  ]}
                  onFileRejected={() => {
                    props.dispatch(
                      addSimpleErrorAlert(
                        "File must be under 50 MB and be one of the following types: " +
                          AcceptedDocumentFileExtensionsList
                      )
                    );
                  }}
                  onFileSelected={(file) => {
                    setIsUploading(true);

                    props
                      .dispatch(
                        uploadSurveyFile(
                          props.surveyId ?? 0,
                          props.group.name,
                          file,
                          props.isPublicSurvey,
                          props.group.questionNumber ?? "",
                          props.group.friendlyName
                        )
                      )
                      .then((resp) => {
                        props.onFileUploaded(props.group.name, resp.attachment);
                        setIsUploading(false);
                      })
                      .catch(() => {
                        props.dispatch(
                          addDefaultUnknownErrorAlert("Error uploading file")
                        );
                        setIsUploading(false);
                      });
                  }}
                />
              )}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

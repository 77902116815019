import { DefaultRouteProps, useBack } from "../../_common/types/router";
import { UserRouteParams } from "../UserBaseNavItems";
import { FC, useCallback, useState } from "react";
import userbaseApi from "../api/userbase.api";
import UserBaseUserHeader from "../components/UserBaseUserHeader";
import UserScoresChart from "../components/UserScoresChart";
import {
  createRemediationRequestUrl,
  createRiskModificationUrl,
  userRiskProfileUrl,
  usersUrl,
  userUrl,
  userUrlPrefix,
} from "../UserBaseAppRouter";
import RiskSummaryTable from "../../vendorrisk/components/RiskSummaryTable";
import { useHistory } from "react-router-dom";
import {
  useHasOrgEntitlement,
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import { trackEvent } from "../../_common/tracking";
import { IRisk } from "../../vendorrisk/components/RiskSummaryDetails";
import { CreateRiskModificationLocationState } from "./CreateRiskModificationView";
import "./UserRiskProfileView.scss";
import DomainsAndIPsPanelGroup, {
  OpenSidePanel,
} from "../../vendorrisk/components/DomainsAndIPsPanelGroup";
import { RiskProfileRiskTypes } from "../../_common/constants";
import UserBaseAPI from "../api/userbase.api";
import { computeUsersInRemediation } from "./RiskProfileView";
import { GetVulnPanel } from "../../vendorrisk/components/DomainsAndIPsPanelGroup";
import * as Permissions from "../../_common/permissions";

type UserRiskProfileViewProps = DefaultRouteProps<UserRouteParams>;

const UserRiskProfileView: FC<UserRiskProfileViewProps> = ({
  match,
  location,
}) => {
  const history = useHistory();

  const orgHasUserBaseRemediationRequests = useHasOrgEntitlement(
    Permissions.OrgAccessUserBaseRemediationRequests
  );

  const userUUID = match.params.userUUID;

  const { data: userSummary } = userbaseApi.useGetUserBaseUserSummaryV1Query({
    userUUID,
  });
  const { data: userRiskProfile, isLoading: userRiskProfileLoading } =
    userbaseApi.useGetUserBaseUserRiskProfileV1Query({
      userUUID,
    });
  const { data: riskWaiverData, isLoading: isRiskWaiversLoading } =
    userbaseApi.useGetUserBaseRiskWaiversV1Query();
  const { data: remediationsData, isLoading: isRemediationsLoading } =
    UserBaseAPI.useGetUserBaseRemediationsV1Query(undefined, {
      skip: !orgHasUserBaseRemediationRequests,
    });

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const [includePassedRisks, setIncludePassedRisks] = useState(false);
  const [showWaivedRisks, setShowWaivedRisks] = useState(false);

  const { backAction, backText } = useBack();

  const getUrlPrefix = useCallback(
    () => `${userUrlPrefix}/${userUUID}`,
    [userUrlPrefix, userUUID]
  );

  const onIncludePassedRisk = (val: boolean) => {
    setIncludePassedRisks(val);
    trackEvent("UserBase_UserRiskProfile_IncludePassedRisks_Toggled", {
      value: val,
    });
  };

  const onShowWaivedRisks = (val: boolean) => {
    setShowWaivedRisks(val);
    trackEvent("UserBase_UserRiskProfile_ShowWaivedRisks_Toggled", {
      value: val,
    });
  };

  const onCreateWaiverForRisk = useCallback(
    (risk: IRisk) => {
      const context: CreateRiskModificationLocationState = {
        riskId: risk.id,
        userUUID: userUUID,
      };

      history.push(createRiskModificationUrl, {
        backContext: {
          goBack: true,
          backToText: `Back to Risk Profile`,
        },
        ...context,
      });
    },
    [history]
  );

  const userRiskWaivers = (riskWaiverData?.waivers ?? []).filter((w) =>
    w.userInfos.some((ui) => ui.uuid === userUUID)
  );

  const onRequestSaaSRemediation = useCallback(
    (risk: IRisk) => {
      history.push(createRemediationRequestUrl, {
        backContext: {
          goBack: true,
          backToText: `Back to Risk Profile`,
        },
        riskId: risk.id,
        userUUID: userUUID,
        riskType: RiskProfileRiskTypes.SaaS,
      });
    },
    [createRemediationRequestUrl, userUUID]
  );

  interface domainsAndIPsPanelGroupRiskParams {
    riskPanel?: JSX.Element;
    getVulnPanel?: GetVulnPanel;
  }

  const [selectedRiskParams, setSelectedRiskParams] = useState<
    domainsAndIPsPanelGroupRiskParams | undefined
  >(undefined);

  const onSelectRisk = (riskId: string | undefined) => {
    if (!riskId) {
      return;
    }

    setSelectedRiskParams(undefined);
    OpenSidePanel(history, { risk: riskId });
  };

  const onSelectedRiskParamsChange = useCallback(
    (riskPanel?: JSX.Element, getVulnPanel?: GetVulnPanel) => {
      setSelectedRiskParams({
        riskPanel: riskPanel,
        getVulnPanel: getVulnPanel,
      });
    },
    []
  );

  const usersUnderRemediation = computeUsersInRemediation(
    remediationsData?.requests ?? [],
    userUUID
  );

  const isLoading =
    userRiskProfileLoading || isRiskWaiversLoading || isRemediationsLoading;

  return (
    <div className="user-profile">
      <UserBaseUserHeader
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: userSummary?.user.name ?? "User",
            to: userUrl(userUUID),
          },
          {
            text: "Risk Profile",
            to: userRiskProfileUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Risk Profile"}
      />

      <UserScoresChart userUUID={userUUID} />

      <RiskSummaryTable
        className={"user-base-user-risk-profile"}
        history={history}
        loading={isLoading}
        title="Risk details"
        risks={userRiskProfile?.risks ?? []}
        filterActive={false}
        urlPrefix={getUrlPrefix}
        canManageVendorRiskWaivers={userHasUserBaseWrite}
        userHasWriteRiskWaiversPermission={userHasUserBaseWrite}
        userHasWriteRemediationPermission={userHasUserBaseWrite}
        hideShowWaivedRisksOption={false}
        hideShowPassedChecksOption={false}
        includePassedRisks={includePassedRisks}
        setIncludePassedRisks={onIncludePassedRisk}
        onCreateRiskWaiver={onCreateWaiverForRisk}
        showWaivedRisks={showWaivedRisks}
        onShowWaivedRisks={onShowWaivedRisks}
        customerRiskWaivers={userRiskWaivers}
        onRequestRemediationForRisk={
          orgHasUserBaseRemediationRequests
            ? onRequestSaaSRemediation
            : undefined
        }
        usersInRemediation={usersUnderRemediation}
        onSelectRisk={onSelectRisk}
        onSelectedRiskParamsChange={onSelectedRiskParamsChange}
        assetsColTitle={"Users affected"}
      />
      <DomainsAndIPsPanelGroup
        history={history}
        location={location}
        isSubsidiary={false}
        isVendorPortal={false}
        riskPanel={selectedRiskParams?.riskPanel}
        getVulnPanel={selectedRiskParams?.getVulnPanel}
      />
    </div>
  );
};

export default UserRiskProfileView;

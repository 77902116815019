import { FC } from "react";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";

interface PublicPrivatePillProps {
  isPrivate?: boolean;
  colorOverride?: LabelColor;
  privatePopupContent?: string;
}

const PublicPrivatePill: FC<PublicPrivatePillProps> = ({
  isPrivate,
  colorOverride,
  privatePopupContent,
}) => {
  switch (isPrivate) {
    case true:
      return (
        <PillLabel
          color={colorOverride ?? LabelColor.Green}
          popupContent={privatePopupContent}
        >
          Private
        </PillLabel>
      );
    case false:
      return (
        <PillLabel color={colorOverride ?? LabelColor.Red}>Public</PillLabel>
      );
    default:
      return <></>;
  }
};

export default PublicPrivatePill;

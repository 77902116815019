import { FC } from "react";
import { AppRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";
import UserBaseApplicationHeader from "../components/UserBaseApplicationHeader";
import { useBack } from "../../_common/types/router";
import UserBaseAPI from "../api/userbase.api";
import { AppStatus } from "../api/types";
import AppApprovalFlow from "../components/AppApprovalFlow";
import AppTimeline from "../components/AppTimeline";
import AppUsersOverview from "../components/AppUsersOverview";
import "./ApplicationSummaryView.scss";
import AppRisksOverview from "../components/AppRisksOverview";
import AppPermissionsOverview from "../components/AppPermissionsOverview";
import AboutApp from "../components/AboutApp";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { applicationsUrl, appUrlPrefix } from "../UserBaseAppRouter";

const ApplicationSummaryView: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const { backAction, backText } = useBack();

  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  return (
    <div className="userbase-app-summary">
      <UserBaseApplicationHeader
        breadcrumbs={[
          { text: "Applications", to: applicationsUrl },
          {
            text: appName,
            to: `${appUrlPrefix}/${appName}`,
          },
        ]}
        title={<div className="app-overview-score-container">Summary</div>}
        backAction={backAction}
        backText={backText}
      />

      {isLoading && <LoadingBanner size={70} />}
      {!isLoading && (
        <>
          {data?.app.status == AppStatus.NeedsReviewAppStatus && (
            <AppApprovalFlow app={data.app} />
          )}
          <div className="two-grid no-stretch">
            <AppRisksOverview />
            <AppUsersOverview />
          </div>
          <div className="two-grid no-stretch">
            <AppPermissionsOverview />
            <AboutApp />
          </div>
          <AppTimeline />
        </>
      )}
    </div>
  );
};

export default ApplicationSummaryView;

import { FC, useState } from "react";
import { ProfileLogo } from "../../vendorrisk/types/sharedAssessment";
import DefaultLogo from "../../_common/images/verifiedvendor/default-verified-vendor-logo.svg";
import "../style/TrustPageLogo.scss";
import ReportCard from "../../_common/components/ReportCard";
import classnames from "classnames";

interface TrustPageLogoProps {
  vendorName?: string;
  profileLogoType: ProfileLogo;
  profileLogoUrl?: string;
  size?: TrustPageLogoSize; //default - medium
}

export enum TrustPageLogoSize {
  XSmall = "x-small",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

// Returns custom logo for Trust Page when custom logo is set and available
// Otherwise - returns default logo placeholder
// Note: for both `default_logo` and `no_logo` returns DefaultLogo,
//        unlike other components that request default logo via `clearbit` when available.
//        Here we want to encourage vendors customizing they own Trust Page
const TrustPageLogo: FC<TrustPageLogoProps> = ({
  vendorName,
  profileLogoType,
  profileLogoUrl,
  size = TrustPageLogoSize.Medium,
}) => {
  const [imageFound, setImageFound] = useState(true);
  const onIMGNotFound = () => setImageFound(false);

  let logo = (
    <img
      className={"trust-page-logo"}
      src={DefaultLogo}
      alt={vendorName ? `${vendorName} Logo` : "Company Logo"}
    />
  );

  if (profileLogoType === ProfileLogo.CustomLogo && profileLogoUrl) {
    if (profileLogoUrl === "") {
      onIMGNotFound();
    } else {
      const element = (
        <img
          className={"trust-page-logo"}
          src={profileLogoUrl}
          alt={vendorName ? `${vendorName} Logo` : "Company Logo"}
          onError={onIMGNotFound}
        />
      );

      if (imageFound) {
        logo = element;
      }
    }
  }

  return (
    <ReportCard className={classnames("trust-page-logo-card", size)}>
      {logo}
    </ReportCard>
  );
};

export default TrustPageLogo;

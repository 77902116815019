import { FC, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { UserRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";

import SlidePanel from "../../_common/components/SlidePanel";
import UserAppPermissionsPanel from "./UserAppPermissionsPanel";
import UserAppsTable from "./UserAppsTable";
import { UserApp } from "../api/types";

import "./UserApps.scss";

const UserApps: FC = ({}) => {
  const match = useRouteMatch<UserRouteParams>();
  const userUUID = match.params.userUUID;

  // Get all the apps for the user
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserAppsV1Query({
    userUUID,
  });

  const [slidePanelApp, setSlidePanelApp] = useState<UserApp | undefined>(
    undefined
  );

  const onViewAppPermissions = (app: UserApp) => {
    setSlidePanelApp(app);
  };

  return (
    <div className="user-apps">
      <UserAppsTable
        isLoading={isLoading}
        appsToShow={data?.apps ?? []}
        onClick={onViewAppPermissions}
      />

      <SlidePanel
        newStyles
        dimContent
        // TODO: Removing this title for now until we decide whether we will standardise on this
        // title={"Permissions"}
        active={!!slidePanelApp}
        className={"user-apps-slide-panel"}
        onClose={() => {
          setSlidePanelApp(undefined);
        }}
      >
        {!!slidePanelApp && (
          <UserAppPermissionsPanel userUUID={userUUID} app={slidePanelApp} />
        )}
      </SlidePanel>
    </div>
  );
};

export default UserApps;

import { DefaultThunkDispatch } from "../../_common/types/redux";
import { useEffect, useState } from "react";
import { isNodeVisible, scrollToNodeId } from "../helpers";
import { setCurrentFocusedNodeId } from "../reducers/actions";
import { debounce as _debounce } from "lodash";

import "../style/ValidationToolbar.scss";
import IconButton from "../../_common/components/IconButton";
import classnames from "classnames";
import { MultiProductNavContentLeftClass } from "../../_common/helpers/navigation.helpers";

interface IValidationToolbarProps {
  dispatch: DefaultThunkDispatch;
  surveyId: string;
  validationErrorNodeIds: string[];
}

export const ValidationToolbar = ({
  validationErrorNodeIds,
  dispatch,
  surveyId,
}: IValidationToolbarProps) => {
  const [currentlyViewingNodeId, setCurrentlyViewingNodeId] = useState<{
    nodeId: string;
    isGhost: boolean; // Track if the currently viewing node has actually already been removed
    index: number;
  } | null>(null);

  useEffect(() => {
    if (
      currentlyViewingNodeId &&
      !currentlyViewingNodeId.isGhost &&
      validationErrorNodeIds.indexOf(currentlyViewingNodeId.nodeId) === -1
    ) {
      // The one we were already viewing has been removed -
      // set the index back one so we don't skip ahead an item.
      setCurrentlyViewingNodeId({
        nodeId: currentlyViewingNodeId.nodeId,
        isGhost: true,
        index: currentlyViewingNodeId.index - 1,
      });
    }

    const scrollListener = _debounce(() => {
      // After we stop scrolling, see if any of the nodeIds are visible on the screen, and set the current one to the first one.
      for (let i = 0; i < validationErrorNodeIds.length; i++) {
        const nodeId = validationErrorNodeIds[i];
        if (isNodeVisible(nodeId, false)) {
          setCurrentlyViewingNodeId({
            nodeId: nodeId,
            isGhost: false,
            index: i,
          });
          break;
        }
      }
    }, 50);

    const contentScroll =
      document.querySelector<HTMLDivElement>("#main-content-area");

    if (contentScroll) {
      contentScroll.addEventListener("scroll", scrollListener);

      return () => {
        contentScroll.removeEventListener("scroll", scrollListener);
      };
    }

    return;
  }, [validationErrorNodeIds]);

  const scrollToNextOrPrevious = (increment: number) => {
    let nextIndex = -1;
    if (currentlyViewingNodeId) {
      // We should go to the next (or previous) item in the array
      nextIndex = currentlyViewingNodeId.index + increment;
      if (nextIndex > validationErrorNodeIds.length - 1) {
        // Wrap around to the beginning
        nextIndex = 0;
      } else if (nextIndex < 0) {
        // Wrap around to the end
        nextIndex = validationErrorNodeIds.length - 1;
      }
    } else {
      nextIndex = 0;
    }

    const newNodeId = validationErrorNodeIds[nextIndex];

    // And scroll to the right element
    scrollToNodeId(newNodeId, true, "smooth");

    // Set it to focused
    dispatch(setCurrentFocusedNodeId(surveyId, newNodeId));
  };

  if (validationErrorNodeIds.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames(
        "validation-toolbar",
        MultiProductNavContentLeftClass
      )}
    >
      <div className="cr-icon-risk" />
      <div className="val-main">
        <div className="val-header">
          {validationErrorNodeIds.length}{" "}
          {validationErrorNodeIds.length === 1
            ? "item has issues"
            : "items have issues"}
        </div>
        <div>
          There{" "}
          {validationErrorNodeIds.length === 1
            ? "is one section or question with"
            : `are ${validationErrorNodeIds.length} sections or questions with`}{" "}
          issues. Please fix these before publishing.
          <br />
          Draft changes will still be saved.
        </div>
      </div>
      <IconButton
        onClick={() => scrollToNextOrPrevious(-1)}
        icon={<div className="cr-icon-chevron rot270" />}
      />
      <IconButton
        onClick={() => scrollToNextOrPrevious(1)}
        icon={<div className="cr-icon-chevron rot90" />}
      />
    </div>
  );
};

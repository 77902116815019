import BulkActions from "../../vendorrisk/components/BulkActions";
import Button from "../../_common/components/core/Button";
import "./ThreatMonitoringBulkActions.scss";
import { useState } from "react";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import {
  ThreatMonitoringFeedType,
  ThreatMonitoringResultState,
} from "../api/types";
import useUpdateResultState from "../funcs/useUpdateResultState";

export interface ThreatMonitoringBulkActionsProps {
  selectedUUIDs: string[];
  clearSelection: () => void;
  feedType: ThreatMonitoringFeedType;
}

const ThreatMonitoringBulkActions = (
  props: ThreatMonitoringBulkActionsProps
) => {
  const { selectedUUIDs, clearSelection, feedType } = props;

  const [dismiss, isDismissing] = useUpdateResultState({
    sourceFeedType: feedType,
    state: ThreatMonitoringResultState.Dismissed,
    successMsg: (u) => `Threat${u.length > 1 && "s"} dismissed successfully.`,
    failMsg: (u) => `Failed to dismiss threat${u.length > 1 && "s"}.`,
    finally: clearSelection,
  });

  const [investigating, isInvestigating] = useUpdateResultState({
    sourceFeedType: ThreatMonitoringFeedType.Open,
    state: ThreatMonitoringResultState.Investigating,
    successMsg: (u) =>
      `Threat${u.length > 1 ? "s" : ""} successfully moved to investigating.`,
    failMsg: (u) =>
      `Failed to move threat${u.length > 1 ? "s" : ""} to investigating.`,
    finally: clearSelection,
  });

  const [showingDismissConfirmation, setShowingDismissConfirmation] =
    useState(false);
  const [
    showingInvestigatingConfirmation,
    setShowingInvestigatingConfirmation,
  ] = useState(false);

  const noun = selectedUUIDs.length === 1 ? "threat" : "threats";
  return (
    <BulkActions className="threat-monitoring-bulk-actions">
      <div className="num-selected">{`${selectedUUIDs.length} ${noun} selected`}</div>
      <div className={"btn-group"}>
        <Button onClick={() => clearSelection()} tertiary>
          Cancel
        </Button>
        <Button
          loading={isInvestigating}
          onClick={() => setShowingInvestigatingConfirmation(true)}
        >
          <div className={"cr-icon-add-bookmark"} />
          {"Add to Investigating"}
        </Button>
        <Button
          loading={isDismissing}
          danger={true}
          onClick={() => setShowingDismissConfirmation(true)}
        >
          <div className={"cr-icon-cancel"} />
          {"Dismiss threats"}
        </Button>
      </div>
      <ConfirmationModalV2
        title={`Dismiss ${selectedUUIDs.length} ${noun}?`}
        description={
          selectedUUIDs.length > 1
            ? `This will move all ${selectedUUIDs.length} ${noun} to closed.`
            : `This will move the ${noun} to closed.`
        }
        dangerousAction={true}
        width={600}
        buttonText={`Dismiss ${selectedUUIDs.length} ${noun}`}
        cancelText="Cancel"
        buttonAction={() => dismiss(selectedUUIDs)}
        active={showingDismissConfirmation}
        onClose={() => setShowingDismissConfirmation(false)}
      />
      <ConfirmationModalV2
        title={`Add ${selectedUUIDs.length} ${noun} to investigating?`}
        description={
          selectedUUIDs.length > 1
            ? `This will move all ${selectedUUIDs.length} ${noun} to investigating, and assign you as the Investigator.`
            : `This will move the ${noun} to investigating, and assign you as the Investigator.`
        }
        width={600}
        primaryAction={true}
        buttonText={`Add to investigating`}
        cancelText="Cancel"
        buttonAction={() => investigating(selectedUUIDs)}
        active={showingInvestigatingConfirmation}
        onClose={() => setShowingInvestigatingConfirmation(false)}
      />
    </BulkActions>
  );
};

export default ThreatMonitoringBulkActions;

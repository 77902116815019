import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import { UserRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";
import { useRouteMatch } from "react-router-dom";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { isElevatedRiskLevel, RiskLevelColor } from "../helpers/scopes";
import Doughnut from "./Doughnut";
import Button from "../../_common/components/core/Button";
import { useDefaultHistory } from "../../_common/types/router";
import { userPermissionsUrl, userSummaryUrl } from "../UserBaseAppRouter";
import { pluralise } from "../../_common/helpers";
import "./UserPermissionsOverview.scss";

const UserPermissionsOverview: FC = ({}) => {
  const match = useRouteMatch<UserRouteParams>();
  const history = useDefaultHistory();

  const userUUID = match.params.userUUID;

  const { data: userData } = UserBaseAPI.useGetUserBaseUserSummaryV1Query({
    userUUID: userUUID,
  });
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserScopesV1Query({
    userUUID: userUUID,
  });

  const onViewPermissions = () => {
    history.push(userPermissionsUrl(userUUID), {
      backContext: {
        backTo: userSummaryUrl(userUUID),
        backToText: `Back to ${userData?.user.name ?? "User"}`,
      },
    });
  };

  const scopesToUse = data?.scopes ?? [];

  const scopesDougnutData = [
    {
      label: "Basic",
      count: scopesToUse.filter((s) => !isElevatedRiskLevel(s.riskLevel))
        .length,
      hexColor: RiskLevelColor.Basic,
    },
    {
      label: "Elevated",
      count: scopesToUse.filter((s) => isElevatedRiskLevel(s.riskLevel)).length,
      hexColor: RiskLevelColor.Elevated,
    },
  ].filter((d) => d.count > 0); // only display non-empty entries

  return (
    <ReportCard newStyles className="userbase-user-permissions-overview">
      <div className="header">
        <span>Permissions</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewPermissions}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <Doughnut
            data={scopesDougnutData}
            totalLabel={pluralise(
              scopesToUse.length,
              "Permission",
              "Permissions"
            )}
          />
        </div>
      )}
    </ReportCard>
  );
};

export default UserPermissionsOverview;

import { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Admin from "./views/AdminView";
import ApplicationsView from "./views/applications/ApplicationsView";
import ApplicationUsersView from "./views/applications/ApplicationUsersView";
import ApplicationSummaryView from "./views/applications/ApplicationSummaryView";
import ApplicationPermissionsView from "./views/applications/ApplicationPermissionsView";
import RiskProfileView from "./views/RiskProfileView";
import UsersView from "./views/users/UsersView";
import CreateRiskModificationView from "./views/risk_waivers/CreateRiskModificationView";
import RiskModificationsView from "./views/risk_waivers/RiskModificationsView";
import UserRiskProfileView from "./views/users/UserRiskProfileView";
import UserApplicationsView from "./views/users/UserApplicationsView";
import UserSummaryView from "./views/users/UserSummaryView";
import IdentityBreachesView from "./views/identity_breaches/IdentityBreachesView";
import IdentityBreachSummaryView from "./views/identity_breaches/IdentityBreachSummaryView";
import UserPermissionsView from "./views/users/UserPermissionsView";
import DashboardView from "./views/DashboardView";
import PermissionsView from "./views/permissions/PermissionsView";
import CreateRemediationRequestView from "./views/remediations/CreateRemediationRequestView";
import RemediationsView from "./views/remediations/RemediationsView";
import RemediationRequestDetailsV2 from "../_common/views/RemediationRequestDetailsV2";
import { locationState } from "../_common/types/router";
import { History, Location } from "history";
import PermissionSummaryView from "./views/permissions/PermissionSummaryView";
import PermissionUsersView from "./views/permissions/PermissionUsersView";
import PermissionApplicationsView from "./views/permissions/PermissionApplicationsView";

export const urlPrefix = `/userbase`;
export const appUrlPrefix = `${urlPrefix}/application`;
export const userUrlPrefix = `${urlPrefix}/user`;
export const permissionUrlPrefix = `${urlPrefix}/permission`;
export const identityBreachesUrlPrefix = `${urlPrefix}/identity_breach`;
export const remediationUrlPrefix = `${urlPrefix}/remediation`;

export const adminUrl = `${urlPrefix}/admin`;
export const riskProfileUrl = `${urlPrefix}/risk_profile`;
export const usersUrl = `${urlPrefix}/users`;
export const applicationsUrl = `${urlPrefix}/applications`;
export const permissionsUrl = `${urlPrefix}/permissions`;
export const identityBreachesUrl = `${urlPrefix}/identity_breaches`;
export const createRiskModificationUrl = `${urlPrefix}/risk_modifications/create`;
export const riskModificationUrl = `${urlPrefix}/risk_modifications`;
export const dashboardUrl = `${urlPrefix}/dashboard`;
export const createRemediationRequestUrl = `${remediationUrlPrefix}/add`;
export const remediationRequestsUrl = remediationUrlPrefix;

export const appUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}`;
};
export const appSummaryUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}/summary`;
};

export const appUsersUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}/users`;
};

export const appPermissionsUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}/permissions`;
};

export const userUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}`;
};

export const userSummaryUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/summary`;
};

export const userRiskProfileUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/risk_profile`;
};

export const userApplicationsUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/applications`;
};

export const userPermissionsUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/permissions`;
};

export const permissionUrl = (externalID: string) => {
  return `${permissionUrlPrefix}/${encodeURIComponent(externalID)}`;
};

export const permissionSummaryUrl = (externalID: string) => {
  return `${permissionUrlPrefix}/${encodeURIComponent(externalID)}/summary`;
};

export const permissionUsersUrl = (externalID: string) => {
  return `${permissionUrlPrefix}/${encodeURIComponent(externalID)}/users`;
};

export const permissionAppsUrl = (externalID: string) => {
  return `${permissionUrlPrefix}/${encodeURIComponent(
    externalID
  )}/applications`;
};

export const permissionAppUsersUrl = (externalID: string, appName: string) => {
  return `${permissionUrlPrefix}/${encodeURIComponent(
    externalID
  )}/applications/${encodeURIComponent(appName)}/users`;
};

export const identityBreachUrl = (identityBreachID: number) => {
  return `${identityBreachesUrlPrefix}/${identityBreachID}`;
};

export const identityBreachSummaryUrl = (identityBreachID: number) => {
  return `${identityBreachesUrlPrefix}/${identityBreachID}/summary`;
};

export const vendorPrefix = `/vendor`;

export const vendorSummaryUrl = (vendorId: number) => {
  return `${vendorPrefix}/${vendorId}/summary`;
};

const UserBaseAppRouter: FC = () => {
  return (
    <div>
      <Switch>
        <Route path={dashboardUrl} component={DashboardView} />
        <Route path={adminUrl} component={Admin} />
        <Route path={riskProfileUrl} component={RiskProfileView} />
        <Route path={usersUrl} component={UsersView} />
        <Route path={applicationsUrl} component={ApplicationsView} />
        <Route path={permissionsUrl} component={PermissionsView} />
        <Route path={identityBreachesUrl} component={IdentityBreachesView} />
        <Route
          path={createRiskModificationUrl}
          component={CreateRiskModificationView}
        />
        <Route path={riskModificationUrl} component={RiskModificationsView} />
        <Route
          path={`${appUrlPrefix}/:appName/users`}
          component={ApplicationUsersView}
        />
        <Route
          path={`${appUrlPrefix}/:appName/summary`}
          component={ApplicationSummaryView}
        />
        <Route
          path={`${appUrlPrefix}/:appName/permissions`}
          component={ApplicationPermissionsView}
        />
        <Route
          path={`${appUrlPrefix}/:appName`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${appUrlPrefix}/${props?.match?.params?.appName}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/summary`}
          component={UserSummaryView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/risk_profile`}
          component={UserRiskProfileView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/applications`}
          component={UserApplicationsView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/permissions`}
          component={UserPermissionsView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${userUrlPrefix}/${props?.match?.params?.userUUID}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
        <Route
          path={`${permissionUrlPrefix}/:externalID/summary`}
          component={PermissionSummaryView}
        />
        <Route
          path={`${permissionUrlPrefix}/:externalID/users`}
          component={PermissionUsersView}
        />
        <Route
          path={`${permissionUrlPrefix}/:externalID/applications`}
          component={PermissionApplicationsView}
        />
        <Route
          path={`${permissionUrlPrefix}/:externalID`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${permissionUrlPrefix}/${props?.match?.params?.externalID}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
        <Route
          path={`${identityBreachesUrlPrefix}/:breachID/summary`}
          component={IdentityBreachSummaryView}
        />
        <Route
          path={`${identityBreachesUrlPrefix}/:breachID`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${identityBreachesUrlPrefix}/${props?.match?.params?.breachID}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
        <Route
          path={createRemediationRequestUrl}
          component={CreateRemediationRequestView}
        />
        <Route
          path={`${remediationUrlPrefix}/:requestId`}
          render={(routeProps) => (
            <RemediationRequestDetailsV2
              isVendorPortal={false}
              {...routeProps}
              // manually override the history and location props so that we can
              // tell typescript the correct type instead of the default unknown
              // provided by React Router
              history={
                routeProps.history as History<
                  locationState & { openComments?: boolean }
                >
              }
              location={
                routeProps.location as Location<
                  locationState & { openComments?: boolean }
                >
              }
            />
          )}
        />
        <Route path={remediationRequestsUrl} component={RemediationsView} />
      </Switch>
    </div>
  );
};

export default UserBaseAppRouter;

import { History } from "history";
import { sharedAssessmentsUrlPrefix } from "../../_common/helpers";
import {
  ISharedAssessment,
  SharedAssessmentAccessResult,
} from "../../vendorrisk/types/sharedAssessment";
import { getAuthFromLocalStorage } from "../../_common/reducers/commonActions";
import { appendParams } from "../../vendorrisk/helpers/util";
import { ExportFiletype } from "../../_common/types/exportReport";

// Note: this method is adjusted sharedProfileSurveyUrl
// If user has access to Vendor Risk - we want to redirect user to the Questionnaire Details page
// For users that don't have access to VR - for now we redirect user to existing SharedProfileSurveyDetailOverview
export const getSurveyDetailsUrl = (
  surveyId: number,
  vendorId: number,
  currentLocation?: string
): string => {
  if (currentLocation && currentLocation.startsWith("/vendor")) {
    return `/vendor/${vendorId}/sharedassessment/surveys/${surveyId}`;
  }
  return `${sharedAssessmentsUrlPrefix(vendorId)}/surveys/${surveyId}`;
};

export const openSurveyDetailsURL = ({
  surveyId,
  vendorId,
  history,
}: {
  surveyId: number;
  vendorId: number;
  history: History<any>;
}): void => {
  const surveyUrl = getSurveyDetailsUrl(
    surveyId,
    vendorId,
    history.location.pathname
  );

  history.push(surveyUrl, {
    backContext: {
      backTo: `${history.location.pathname}`,
      backToText: "Back to Trust Page",
    },
  });
};

export interface ExportSurveyParams {
  surveyId: number;
  exportFileType?: ExportFiletype;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  byAssessmentId?: number;
}

export const buildExportPublicSurveyUrl = ({
  surveyId,
  exportFileType = ExportFiletype.PDF,
  isManagementAnalystSession = false,
  managedOrgId,
  byAssessmentId,
}: ExportSurveyParams): string => {
  const { apiKey, token } = getAuthFromLocalStorage(
    isManagementAnalystSession ? { tpvm: true, tpvm_o: managedOrgId } : null
  );

  const pathBase = byAssessmentId
    ? "/api/survey/export/byassessment/v1/"
    : "/api/survey/export/v1/";

  return appendParams(pathBase, {
    file_type: exportFileType,
    apikey: encodeURIComponent(apiKey),
    token: encodeURIComponent(token),
    survey_id: surveyId.toString(),
    by_assessment_id: byAssessmentId?.toString(),
    public: "true",
  });
};

export const exportPublicSurveyInNewTab = (
  surveyId: number,
  isManagementAnalystSession = false,
  managedOrgId?: number,
  byAssessmentId?: number
): void => {
  const url = buildExportPublicSurveyUrl({
    surveyId,
    exportFileType: ExportFiletype.PDF,
    isManagementAnalystSession,
    managedOrgId,
    byAssessmentId,
  });
  openInNewTab(url);
};

export const isNotEmpty = (arr: any[] | undefined): boolean => {
  return Array.isArray(arr) && arr.length > 0;
};

export const isEmpty = (arr: any[] | undefined): boolean => {
  return !isNotEmpty(arr);
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener noreferrer");
};

export const userHasAccessBySharedAssessment = (
  sharedAssessment?: ISharedAssessment
): boolean => {
  if (!sharedAssessment) return false;
  return (
    sharedAssessment.status === SharedAssessmentAccessResult.Success &&
    (sharedAssessment.orgLevelStatus
      ? sharedAssessment.orgLevelStatus === SharedAssessmentAccessResult.Success
      : true)
  );
};

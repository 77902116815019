/* eslint-disable global-require */

// core-js provides polyfills for newer JavaScript features
require("core-js/stable");
// needed for async/await support
require("regenerator-runtime/runtime");
// fetch API polyfill
require("whatwg-fetch");

// Polyfill for EventSource / Server-Sent Events
const nativeEventSource = require("event-source-polyfill").NativeEventSource;
global.EventSource =
  nativeEventSource || require("event-source-polyfill").EventSourcePolyfill;

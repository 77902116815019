import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC } from "react";
import TrustPageCustomDomainDnsDisplay from "./TrustPageCustomDomainDnsDisplay";
import Button from "../../../_common/components/core/Button";

interface TrustPageCustomDomainDnsConfigModalProps extends BaseModalProps {
  domain: string;
}

const TrustPageCustomDomainDnsConfigModal: FC<
  TrustPageCustomDomainDnsConfigModalProps
> = ({ active, onClose, domain }) => {
  return (
    <ModalV2
      width={600}
      active={active}
      onClose={onClose}
      className={"trust-page-custom-domain-dns-config-modal"}
      headerContent={"DNS Configuration"}
      footerClassName={"trust-page-custom-domain-dns-config-modal-footer"}
      footerContent={
        <Button tertiary arrow>
          See support article
        </Button>
      }
    >
      <TrustPageCustomDomainDnsDisplay domain={domain} />
    </ModalV2>
  );
};

export default TrustPageCustomDomainDnsConfigModal;

import { FC, ReactNode, useState } from "react";
import Button from "../../_common/components/core/Button";
import IconButton from "../../_common/components/IconButton";
import TextField from "../../_common/components/TextField";
import "../style/EditableContactSection.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import FocusedInputGroup from "./FocusedInputGroup";

interface IEditableContactSectionProps {
  userCanMakeChanges: boolean;
  contactName?: string;
  contactEmail?: string;
  onSaveChanges: (contactName: string, contactEmail: string) => void;
}

const getContactInfo = (name?: string, email?: string): ReactNode => {
  if (name || email) {
    return (
      <>
        {name && <div>{name}</div>}
        {email && <a href={`mailto:${email}`}>{email}</a>}
      </>
    );
  } else {
    return undefined;
  }
};

const contactInfoToContactState = (name?: string, email?: string) => {
  return {
    name: name ? name.split(",")[0].trim() : "",
    role:
      name && name.includes(",")
        ? name.substring(name.indexOf(",") + 1).trim()
        : "",
    email: email ?? "",
  };
};

const EditableContactSection: FC<IEditableContactSectionProps> = ({
  userCanMakeChanges,
  contactName,
  contactEmail,
  onSaveChanges,
}: IEditableContactSectionProps) => {
  const [editing, setEditing] = useState(false);
  const [contact, setContact] = useState(
    contactInfoToContactState(contactName, contactEmail)
  );

  const handleOnSave = () => {
    if (editing) {
      setEditing(false);
      if (contactName !== contact.name || contactEmail !== contact.email) {
        const nameAndRole = contact.role.trim()
          ? `${contact.name.trim()}, ${contact.role.trim()}`
          : contact.name.trim();
        onSaveChanges(nameAndRole, contact.email);
      }
    }
  };

  const handleOnCancel = () => {
    setContact(contactInfoToContactState(contactName, contactEmail));
    setEditing(false);
  };

  return (
    <FocusedInputGroup className={"section"} onClickOutside={handleOnSave}>
      {(userCanMakeChanges || getContactInfo(contactName, contactEmail)) && (
        <>
          <div className={"section-header"}>
            Contact
            {userCanMakeChanges && (
              <>
                {editing ? (
                  <Button tertiary onClick={handleOnCancel}>
                    Cancel
                  </Button>
                ) : (
                  <IconButton
                    icon={<div className="cr-icon-pencil-2" />}
                    onClick={() => {
                      setEditing(true);
                    }}
                  />
                )}
              </>
            )}
          </div>

          <TransitionGroup component={null}>
            <CSSTransition
              in={editing}
              timeout={250}
              classNames="fade-transition"
            >
              {editing ? (
                <div className={"contact-details-fields"}>
                  <div className="field-label">Contact Name</div>
                  <TextField
                    value={contact.name ?? ""}
                    autoFocus={true}
                    debounceTime={500}
                    onChanged={(val) => {
                      setContact({ ...contact, name: val });
                    }}
                  />

                  <div className="field-label">Role</div>
                  <TextField
                    value={contact.role}
                    onChanged={(val) => {
                      setContact({ ...contact, role: val });
                    }}
                    debounceTime={500}
                  />

                  <div className="field-label">Email</div>
                  <TextField
                    value={contact.email}
                    type={"email"}
                    onChanged={(val) => {
                      setContact({ ...contact, email: val });
                    }}
                    debounceTime={500}
                  />
                </div>
              ) : (
                <div className={"section-content"}>
                  {getContactInfo(contactName, contactEmail) ||
                    "Provide a security contact"}
                </div>
              )}
            </CSSTransition>
          </TransitionGroup>
        </>
      )}
    </FocusedInputGroup>
  );
};

export default EditableContactSection;

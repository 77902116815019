import { FC, useEffect, useState } from "react";
import ReportCard from "../ReportCard";
import PillLabel, {
  PillContentAlign,
} from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import SearchBox from "../SearchBox";
import { History } from "history";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../core/XTable";
import { AdjustedSeverityIcon } from "../SeverityIcon";
import {
  Severity,
  SeverityAsString,
  SeverityFromString,
} from "../../types/severity";
import {
  GetRemediatedStatus,
  RemediatedStatus,
  RiskSource,
  RiskStatus,
} from "../../types/risks";
import { DefaultThunkDispatchProp } from "../../types/redux";
import { formatDateAsLocal, LogError, pluralise } from "../../helpers";
import { usePagination, useSorting } from "../../hooks";
import SearchEmptyCard from "../SearchEmptyCard";
import CVEMiniList, {
  GetCPEFromRiskID,
  IsCPERisk,
} from "../../../vendorrisk/components/CVEMiniList";
import EmptyCardWithAction from "../EmptyCardWithAction";
import Search from "../../../_common/images/search.svg";
import {
  RemediationRequestRisk,
  RemediationRequestRiskAdditionalEvidence,
  RemediationRequestRiskHistory,
  RemediationRequestRiskHistoryItem,
  RemediationRequestRiskSaaSUser,
  RemediationRequestRiskSurvey,
  RemediationRequestSharedEvidence,
} from "../../types/remediation";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import Button from "../core/Button";
import CompensatingControlInfo from "../../../vendorrisk/components/questionnaires/CompensatingControlInfo";
import { useModalV2 } from "../ModalV2";
import { MarkRiskAsRemediatedModal } from "./MarkRiskRemediatedModal";
import { fetchRemediationRequestRiskHistory } from "../../reducers/remediationRequest.actions";
import { orderBy as _orderBy } from "lodash";
import "../../style/components/remediationDetails/RemediationDetailsRisksTable.scss";
import { UserAvatarAndName } from "../UserAvatar";
import { IUserMini } from "../../types/user";
import {
  factCategoryMeta,
  newFactCategoryMeta,
} from "../../factCategoryHelpers";
import { ButtonWithDropdownV2, DropdownItem } from "../core/DropdownV2";
import { SeveritySelectorButton } from "../../../vendorrisk/components/SeveritySelectorButton";
import ExpandableItem from "../ExpandableItem";
import { DocumentLink } from "../../../vendorrisk/components/DocumentLink";
import RiskName from "../../../vendorrisk/components/risk_profile/RiskName";
import ManageRiskButton from "../../../vendorrisk/components/risk_profile/ManageRiskButton";
import { WaiverType } from "../../types/vendorRiskWaivers";
import { appConnect, useAppDispatch } from "../../types/reduxHooks";
import { SidePopupV2 } from "../DismissablePopup";
import RemediationDetailsExpandedRiskTableV2 from "./RemediationDetailsExpandedRiskTableV2";
import RiskPanel, {
  RiskPanelRowData,
} from "./../../../vendorrisk/components/RiskPanel";
import { VendorSummaryRiskType } from "../../types/vendorSummary";
import VulnPanel from "./../../../vendorrisk/components/VulnPanel";
import Icon from "../../../_common/components/core/Icon";
import TeamsPills from "../../../userbase/components/TeamsPills";
import { GetVulnPanel } from "../../../vendorrisk/components/DomainsAndIPsPanelGroup";
import { GetQueryParams } from "../../query";

const calculateRiskNumberOfOccurrences = (risk: IRisksTableData) => {
  switch (risk.source) {
    case RiskSource.Cloudscan:
      if (risk.isAllWebsites) {
        return undefined;
      }

      return risk.websites?.length;
    case RiskSource.Survey:
      return risk.surveys?.length;
    case RiskSource.AdditionalEvidence:
      return risk.additionalEvidences?.length;
    case RiskSource.SaaS:
      return risk.saasUsers?.length;
    default:
      LogError("unhandled risk source");

      return undefined;
  }
};

const riskSourceToVendorSummaryRiskType = (riskSource: RiskSource) => {
  switch (riskSource) {
    case RiskSource.Cloudscan:
      return VendorSummaryRiskType.Cloudscan;
    case RiskSource.Survey:
      return VendorSummaryRiskType.Survey;
    case RiskSource.AdditionalEvidence:
      return VendorSummaryRiskType.Evidence;
    case RiskSource.SaaS:
      return VendorSummaryRiskType.SaaS;
    default:
      LogError("unhandled risk source.");

      return VendorSummaryRiskType.Other;
  }
};

interface cloudScanRiskPanelProps {
  titleButtonsContent: JSX.Element;
  risk: IRisksTableData;
  isCustomer?: boolean;
  onOpenCVEPanelFromRiskPanel?: (
    riskId: string,
    riskTitle: string,
    vuln: string,
    verified?: boolean
  ) => void;
  requestId: number;
  onClickRow: (id: string, risk?: string) => void;
}

const CloudScanRiskPanel: FC<cloudScanRiskPanelProps> = ({
  titleButtonsContent,
  risk,
  isCustomer,
  onOpenCVEPanelFromRiskPanel,
  requestId,
  onClickRow,
}) => {
  const riskPanelTrailingRows: RiskPanelRowData[] = [];

  const [
    selectedRiskPotentialVulnerabilitiesCount,
    setSelectedRiskPotentialVulnerabilitiesCount,
  ] = useState(0);

  if (IsCPERisk(risk.checkId)) {
    riskPanelTrailingRows.push({
      key: "potential_vulnerabilities",
      title:
        "Potential vulnerabilities" +
        (selectedRiskPotentialVulnerabilitiesCount
          ? " (" + selectedRiskPotentialVulnerabilitiesCount + ")"
          : ""),
      children: (
        <CVEMiniList
          cpeName={GetCPEFromRiskID(risk.checkId)}
          isCustomer={isCustomer}
          hostnames={risk.unresolvedWebsites}
          cveNamesFilter={risk?.cveNames}
          onClickCVE={(cveName: string) => {
            onOpenCVEPanelFromRiskPanel?.(
              risk.checkId,
              risk.failTitle,
              cveName,
              false
            );
          }}
          onVulnsCountChanged={(vulnsCount: number | undefined) => {
            setSelectedRiskPotentialVulnerabilitiesCount(vulnsCount ?? 0);
          }}
        />
      ),
      startExpanded: true,
    });
  }

  const [selectedRiskAssetsCount, setSelectedRiskAssetsCount] = useState(0);

  riskPanelTrailingRows.push({
    key: "assets_affected",
    title:
      "Assets affected" +
      (selectedRiskAssetsCount ? " (" + selectedRiskAssetsCount + ")" : ""),
    children: (
      <RemediationDetailsExpandedRiskTableV2
        onClickRow={onClickRow}
        requestId={requestId}
        checkId={risk.checkId}
        onAssetsCountChanged={(assetsCount: number | undefined) => {
          setSelectedRiskAssetsCount(assetsCount ?? 0);
        }}
      />
    ),
    startExpanded: true,
  });

  return (
    <RiskPanel
      risk={{
        title: risk.failTitle,
        riskType: riskSourceToVendorSummaryRiskType(risk.source),
        description: risk.failDescription,
        summary: risk.riskSummary,
        riskDetails: risk.riskDetails,
        recommendedRemediation: risk.riskRecommendedRemediation,
      }}
      titleButtonsContent={titleButtonsContent}
      trailingRows={riskPanelTrailingRows}
    />
  );
};

interface surveyRiskPanelProps {
  titleButtonsContent: JSX.Element;
  risk: IRisksTableData;
  goToSurvey: (surveyId: number, isPublicSurvey: boolean) => void;
  goToSurveyRisk: (
    surveyId: number,
    riskId: string,
    isPublicSurvey: boolean
  ) => void;
}

const SurveyRiskPanel: FC<surveyRiskPanelProps> = ({
  titleButtonsContent,
  risk,
  goToSurvey,
  goToSurveyRisk,
}) => {
  const riskPanelTrailingRows: RiskPanelRowData[] = [];

  riskPanelTrailingRows.push({
    key: "questionnaires_affected",
    title:
      "Questionnaires affected" +
      (calculateRiskNumberOfOccurrences(risk)
        ? " (" + calculateRiskNumberOfOccurrences(risk) + ")"
        : ""),
    children: (
      <RemediationDetailsExpandedSurveyTable
        risk={risk}
        goToSurvey={goToSurvey}
        goToSurveyRisk={goToSurveyRisk}
      />
    ),
    startExpanded: true,
  });

  return (
    <RiskPanel
      risk={{
        title: risk.failTitle,
        riskType: riskSourceToVendorSummaryRiskType(risk.source),
        description: risk.failDescription,
        summary: risk.riskSummary,
        riskDetails: risk.riskDetails,
        recommendedRemediation: risk.riskRecommendedRemediation,
      }}
      titleButtonsContent={titleButtonsContent}
      trailingRows={riskPanelTrailingRows}
    />
  );
};

interface additionalEvidenceRiskPanelProps {
  titleButtonsContent: JSX.Element;
  risk: IRisksTableData;
  isVendorPortal?: boolean;
  sharedDocuments: RemediationRequestSharedEvidence[];
  downloadDocument: (doc: RemediationRequestSharedEvidence) => void;
  goToAdditionalEvidence: (id: number) => void;
  requestId: number;
}

const AdditionalEvidenceRiskPanel: FC<additionalEvidenceRiskPanelProps> = ({
  titleButtonsContent,
  risk,
  isVendorPortal,
  sharedDocuments,
  downloadDocument,
  goToAdditionalEvidence,
  requestId,
}) => {
  const riskPanelTrailingRows: RiskPanelRowData[] = [];
  const dispatch = useAppDispatch();

  if (!isVendorPortal || risk.additionalEvidences?.length) {
    riskPanelTrailingRows.push({
      key: "documents_with_risk",
      title:
        "Shared documents with this risk" +
        (calculateRiskNumberOfOccurrences(risk)
          ? " (" + calculateRiskNumberOfOccurrences(risk) + ")"
          : ""),
      children: isVendorPortal ? (
        <RemediationDetailsExpandedAdditionalEvidenceRiskVendorPortal
          risk={risk}
          sharedDocuments={sharedDocuments}
          downloadDocument={downloadDocument}
        />
      ) : (
        <RemediationDetailsExpandedAdditionalEvidenceRisk
          requestId={requestId}
          risk={risk}
          sharedDocuments={sharedDocuments}
          downloadDocument={downloadDocument}
          goToAdditionalEvidence={goToAdditionalEvidence}
          dispatch={dispatch}
        />
      ),
      startExpanded: true,
    });
  }

  return (
    <RiskPanel
      risk={{
        title: risk.failTitle,
        riskType: riskSourceToVendorSummaryRiskType(risk.source),
        description: risk.failDescription,
        summary: risk.riskSummary,
        riskDetails: risk.riskDetails,
        recommendedRemediation: risk.riskRecommendedRemediation,
      }}
      titleButtonsContent={titleButtonsContent}
      trailingRows={riskPanelTrailingRows}
    />
  );
};

interface IRemediationDetailsExpandedSurveyTableProps {
  risk: IRisksTableData;
  goToSurvey: (surveyId: number, isPublicSurvey: boolean) => void;
  goToSurveyRisk: (
    surveyId: number,
    riskId: string,
    isPublicSurvey: boolean
  ) => void;
}

const RemediationDetailsExpandedSurveyTable = ({
  risk,
  goToSurvey,
  goToSurveyRisk,
}: IRemediationDetailsExpandedSurveyTableProps) => {
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "questionnaires",
      text: "Questionnaires triggering this risk",
      sortable: false,
    },
    {
      id: "status",
      text: "Remediation Status",
      sortable: false,
    },
  ];

  const rows: IXTableRow[] =
    risk.surveys?.map((s) => {
      const isPublicSurvey = !!s.publicSurveyId;
      const id = s.surveyId ?? s.publicSurveyId ?? 0;

      const sentOrPublishedVerb = isPublicSurvey ? "Published" : "Sent";
      const sentBy = s.surveyLastSentBy ? ` by ${s.surveyLastSentBy}` : "";
      const displayDateStr = isPublicSurvey ? s.sharedAt : s.surveyLastSent;

      return {
        id: `${isPublicSurvey ? "p_" : "s_"}${id}`,
        cells: [
          <XTableCell key={"questionnaires"} className={"questionnaire-cell"}>
            <div className={"questionnaire-title"}>
              <div
                className={"title-sent"}
                onClick={() => goToSurvey(id, isPublicSurvey)}
              >
                <div className={"title"}>{s.surveyName}</div>
                <div
                  className={"sent"}
                >{`${sentOrPublishedVerb}${sentBy} on ${formatDateAsLocal(
                  displayDateStr
                )}`}</div>
              </div>
              <div>
                {s.publicSurveyId && (
                  <PillLabel color={LabelColor.Orange}>Shared</PillLabel>
                )}
              </div>
              {!s.remediated && (
                <Button
                  arrow
                  onClick={() =>
                    goToSurveyRisk(
                      id,
                      risk.baseCheckId ?? risk.checkId,
                      isPublicSurvey
                    )
                  }
                >
                  View in questionnaire
                </Button>
              )}
            </div>
            <CompensatingControlInfo risk={s} />
          </XTableCell>,
          <XTableCell key={"status"} className={"status-cell shrink-cell"}>
            <PillLabel
              color={
                s.surveyRiskStatus === RiskStatus.Archived
                  ? LabelColor.Fuchsia
                  : s.remediated
                    ? LabelColor.Green
                    : LabelColor.Red
              }
            >
              {s.surveyRiskStatus === RiskStatus.Archived
                ? "Archived"
                : s.surveyRiskStatus === RiskStatus.Waived
                  ? "Waived"
                  : s.remediated
                    ? "Remediated"
                    : "Not remediated"}
            </PillLabel>
          </XTableCell>,
        ],
      } as IXTableRow;
    }) ?? [];

  return (
    <div className={"risk-summary-details"}>
      <XTable
        className={"questionnaires-table"}
        rows={rows}
        columnHeaders={columnHeaders}
      />
    </div>
  );
};

interface saasRiskPanelProps {
  titleButtonsContent: JSX.Element;
  risk: IRisksTableData;
}

const SaaSRiskPanel: FC<saasRiskPanelProps> = ({
  titleButtonsContent,
  risk,
}) => {
  const riskPanelTrailingRows: RiskPanelRowData[] = [];

  riskPanelTrailingRows.push({
    key: "users_affected",
    title:
      "Users affected" +
      (calculateRiskNumberOfOccurrences(risk)
        ? " (" + calculateRiskNumberOfOccurrences(risk) + ")"
        : ""),
    children: <RemediationDetailsExpandedSaaSTable risk={risk} />,
    startExpanded: true,
  });

  return (
    <RiskPanel
      risk={{
        title: risk.failTitle,
        riskType: riskSourceToVendorSummaryRiskType(risk.source),
        description: risk.failDescription,
        summary: risk.riskSummary,
        riskDetails: risk.riskDetails,
        recommendedRemediation: risk.riskRecommendedRemediation,
      }}
      titleButtonsContent={titleButtonsContent}
      trailingRows={riskPanelTrailingRows}
    />
  );
};

interface IRemediationDetailsExpandedSaaSTableProps {
  risk: IRisksTableData;
}

const RemediationDetailsExpandedSaaSTable = ({
  risk,
}: IRemediationDetailsExpandedSaaSTableProps) => {
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "users",
      text: "Users",
      sortable: false,
    },
    {
      id: "teams",
      text: "Teams",
      sortable: false,
    },
    {
      id: "status",
      text: "Remediation Status",
      sortable: false,
    },
  ];

  const rows: IXTableRow[] =
    risk.saasUsers?.map((u) => {
      return {
        id: u.userUUID,
        cells: [
          <XTableCell key={"users"} className={"user-cell"}>
            <div className={"user"}>{u.userName || u.userEmail}</div>
          </XTableCell>,
          <XTableCell key={"teams"}>
            <TeamsPills teams={u.userTeams} />
          </XTableCell>,
          <XTableCell key={"status"} className={"status-cell shrink-cell"}>
            <PillLabel
              color={
                u.userRiskStatus === RiskStatus.Closed ||
                u.userRiskStatus === RiskStatus.Waived
                  ? LabelColor.Green
                  : LabelColor.Red
              }
            >
              {u.userRiskStatus === RiskStatus.Waived
                ? "Waived"
                : u.userRiskStatus === RiskStatus.Closed
                  ? "Remediated"
                  : "Not remediated"}
            </PillLabel>
          </XTableCell>,
        ],
      } as IXTableRow;
    }) ?? [];

  return (
    <div className={"risk-summary-details"}>
      <XTable
        className={"users-table"}
        rows={rows}
        columnHeaders={columnHeaders}
      />
    </div>
  );
};

// findRemediationEvent takes a list of remediation request risk history events
// and walk it backwards in time until we find the event that marked the risk
// as remediated or undefined if no such event exists
const findRemediationEvent = (
  historyEvents: RemediationRequestRiskHistoryItem[]
): RemediationRequestRiskHistoryItem | undefined => {
  const ordered: RemediationRequestRiskHistoryItem[] = _orderBy(
    historyEvents,
    ["at"],
    ["desc"]
  );
  for (let i = 0; i < ordered.length; i++) {
    if (ordered[i].statusUpdate?.newStatus === "Remediated") {
      return ordered[i];
    }
  }
  return undefined;
};

interface IRemediationDetailsExpandedAdditionalEvidenceRiskOwnProps {
  vendorId?: number;
  requestId: number;
  risk: IRisksTableData;
  sharedDocuments: RemediationRequestSharedEvidence[];
  downloadDocument: (doc: RemediationRequestSharedEvidence) => void;
  goToAdditionalEvidence: (id: number) => void;
}

type IRemediationDetailsExpandedAdditionalEvidenceRiskProps =
  IRemediationDetailsExpandedAdditionalEvidenceRiskOwnProps &
    DefaultThunkDispatchProp;

const RemediationDetailsExpandedAdditionalEvidenceRisk: FC<
  IRemediationDetailsExpandedAdditionalEvidenceRiskProps
> = ({
  requestId,
  risk,
  sharedDocuments,
  downloadDocument,
  goToAdditionalEvidence,
  dispatch,
}) => {
  const [riskHistory, setRiskHistory] = useState<
    RemediationRequestRiskHistory | undefined
  >(undefined);

  const [markAsRemediatedEvent, setMarkAsRemediatedEvent] = useState<
    RemediationRequestRiskHistoryItem | undefined
  >(undefined);

  const [markAsRemediatedUser, setMarkAsRemediatedUser] = useState<
    IUserMini | undefined
  >(undefined);

  useEffect(() => {
    dispatch(
      fetchRemediationRequestRiskHistory(requestId, risk.id, risk.source, true)
    ).then((history) => setRiskHistory(history));
  }, [requestId, risk.id, risk.source, risk.status, dispatch]);

  useEffect(() => {
    if (riskHistory) {
      setMarkAsRemediatedEvent(findRemediationEvent(riskHistory.items));
    }
  }, [riskHistory]);

  useEffect(() => {
    if (riskHistory && markAsRemediatedEvent && markAsRemediatedEvent.userId) {
      setMarkAsRemediatedUser(riskHistory.users[markAsRemediatedEvent.userId]);
    }
  }, [riskHistory, markAsRemediatedEvent]);

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "evidences",
      text: "Evidence with this risk",
      sortable: false,
    },
    {
      id: "justification",
      text:
        risk.status === RemediatedStatus.Remediated &&
        !!markAsRemediatedEvent &&
        markAsRemediatedEvent.comment
          ? "Justification"
          : "",
      sortable: false,
    },
  ];

  let additionalEvidences: {
    evidenceID: number;
    evidenceName: string;
    sharedDocument?: RemediationRequestSharedEvidence;
  }[] =
    risk.additionalEvidences?.map(({ evidenceID, evidenceName }) => ({
      evidenceID,
      evidenceName,
      sharedDocument: sharedDocuments.find((s) => s.id === evidenceID),
    })) ?? [];
  if (risk.status === RemediatedStatus.Remediated && markAsRemediatedEvent) {
    // when the risk has been marked as remediated we
    // need to find the entry in the history for closing
    // the risk and fetch the evidences at that time
    additionalEvidences =
      markAsRemediatedEvent.metadata.evidences?.map((e) => ({
        evidenceID: e.id,
        evidenceName: e.name,
        sharedDocument: sharedDocuments.find((s) => s.id === e.id),
      })) ?? [];
  }

  const isLoading =
    risk.status === RemediatedStatus.Remediated && riskHistory === undefined;

  const evidencesCell = (
    <>
      {(additionalEvidences ?? []).map((e) => {
        const evidenceHeader = (
          <div className="evidence-header">
            <div
              className="evidence-name"
              onClick={() => goToAdditionalEvidence(e.evidenceID)}
              title={e.evidenceName}
            >
              {e.evidenceName}
            </div>
            <div className="shared-status">
              {e.sharedDocument ? "Document shared" : <em>Not shared</em>}
            </div>
          </div>
        );
        if (!e.sharedDocument) {
          return (
            <div
              key={e.evidenceName}
              className="evidence-with-shared-documents"
            >
              {evidenceHeader}
            </div>
          );
        }
        return (
          <div key={e.evidenceName} className="evidence-with-shared-documents">
            <ExpandableItem
              header={evidenceHeader}
              content={
                <DocumentLink
                  evidenceName={e.sharedDocument.name}
                  filename={e.sharedDocument.filename ?? ""}
                  label={e.sharedDocument.filename}
                  virusSafe={e.sharedDocument.virusSafe ?? false}
                  onDownload={() =>
                    e.sharedDocument && downloadDocument(e.sharedDocument)
                  }
                />
              }
            />
          </div>
        );
      })}
    </>
  );

  const justificationCell =
    risk.status === RemediatedStatus.Remediated &&
    !!markAsRemediatedEvent &&
    markAsRemediatedEvent.comment ? (
      <>
        <div className={"user-and-date"}>
          {markAsRemediatedUser && (
            <UserAvatarAndName
              avatar={markAsRemediatedUser.avatar}
              name={markAsRemediatedUser.name}
            />
          )}
          <div className={"date"}>
            {formatDateAsLocal(markAsRemediatedEvent.at)}
          </div>
        </div>
        <div className={"comment"}>{markAsRemediatedEvent.comment}</div>
      </>
    ) : (
      <></>
    );

  const rows: IXTableRow[] = [
    {
      cells: [
        <XTableCell className={"evidences-cell"} key={"evidences"}>
          {evidencesCell}
        </XTableCell>,
        <XTableCell className={"justification-cell"} key={"justification"}>
          {justificationCell}
        </XTableCell>,
      ],
      id: "first",
    },
  ];

  return (
    <div className={"risk-summary-details"}>
      <XTable
        className={"additional-evidence-table"}
        rows={rows}
        columnHeaders={columnHeaders}
        loading={isLoading}
      />
    </div>
  );
};

interface IRemediationDetailsExpandedAdditionalEvidenceRiskVendorPortalProps {
  risk: IRisksTableData;
  sharedDocuments: RemediationRequestSharedEvidence[];
  downloadDocument: (doc: RemediationRequestSharedEvidence) => void;
}

const RemediationDetailsExpandedAdditionalEvidenceRiskVendorPortal: FC<
  IRemediationDetailsExpandedAdditionalEvidenceRiskVendorPortalProps
> = ({ risk, sharedDocuments, downloadDocument }) => {
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "documents",
      text: "Shared documents with this risk",
      sortable: false,
    },
  ];

  const rows: IXTableRow[] = [
    {
      id: "first",
      cells: [
        <XTableCell key="documents" className="documents-cell">
          <div className="document-links-container">
            {risk.additionalEvidences
              ?.reduce<RemediationRequestSharedEvidence[]>((result, e) => {
                const match = sharedDocuments.find(
                  (s) => s.id === e.evidenceID
                );
                if (match) {
                  result.push(match);
                }

                return result;
              }, [])
              .map((doc) => (
                <DocumentLink
                  key={doc.id}
                  evidenceName={doc.name}
                  filename={doc.filename ?? ""}
                  virusSafe={doc.virusSafe ?? false}
                  onDownload={() => downloadDocument(doc)}
                />
              ))}
          </div>
        </XTableCell>,
      ],
    },
  ];

  return (
    <div className="risk-summary-details">
      <XTable
        className="additional-evidence-documents-list"
        columnHeaders={columnHeaders}
        rows={rows}
      />
    </div>
  );
};

const RemediatedStatusLabelColor = {
  Remediated: LabelColor.Green,
  ["Not remediated"]: LabelColor.Red,
  ["Partially remediated"]: LabelColor.Yellow,
  Archived: LabelColor.Fuchsia,
  Waived: LabelColor.Green,
};

interface IRisksTableData {
  severity: Severity;
  failTitle: string;
  failDescription: string;
  groupTitle: string;
  factName: string;
  description: string;
  status: RemediatedStatus;
  labelColour: LabelColor;
  id: number;
  source: RiskSource;
  checkId: string;
  isAllWebsites?: boolean;
  allWebsitesFailedCountSnapshot?: number;
  allWebsitesFailedCountCurrent?: number;
  websites?: string[];
  resolvedWebsites?: string[];
  waivedWebsites?: string[];
  movedWebsites?: string[];
  unresolvedWebsites?: string[];
  cveNames?: string[];
  surveys?: RemediationRequestRiskSurvey[];
  additionalEvidences?: RemediationRequestRiskAdditionalEvidence[];
  saasUsers?: RemediationRequestRiskSaaSUser[];
  baseSeverity?: Severity;
  baseCheckId?: string;
  riskSummary?: string;
  riskDetails?: string;
  riskRecommendedRemediation?: string;
}

interface IRemediationDetailsRiskTableOwnProps {
  vendorId?: number;
  isSubsidiary?: boolean;
  cloudscanRisks: RemediationRequestRisk[];
  surveyRisks: RemediationRequestRisk[];
  additionalEvidenceRisks: RemediationRequestRisk[];
  saasRisks: RemediationRequestRisk[];
  isVendorPortal?: boolean;
  isSelfRemediation?: boolean;
  onSelectRisk?: (riskId: string | undefined) => void;
  onSelectedRiskParamsChange?: (
    riskPanel: JSX.Element | undefined,
    getVulnPanel?: GetVulnPanel
  ) => void;
  onClickCve: (id: string) => void;
  onOpenCVEPanelFromRiskPanel?: (
    riskId: string,
    riskTitle: string,
    vuln: string,
    verified?: boolean
  ) => void;
  onOpenCloudscanPanelFromRiskPanel?: (
    id: string,
    riskTitle: string,
    risk?: string
  ) => void;
  onClickAssetInVulnPanel?: (currentPanelTitle: string, asset: string) => void;
  goToSurvey: (surveyId: number, isPublicSurvey: boolean) => void;
  goToSurveyRisk: (
    surveyId: number,
    risk: string,
    isPublicSurvey: boolean
  ) => void;
  onCreateRiskWaiver: (riskId: string, waiverType: WaiverType) => void;
  hasSurveyRisksRemoved?: boolean;
  isEditable?: boolean;
  history: History;
  vendorAssessmentId?: number;
  userHasWaiveRiskPermission?: boolean;
  domainsFilteredOut?: boolean;
  goToAdditionalEvidence: (id: number) => void;
  userHasRemediateRiskPermission?: boolean;
  onMarkAsRemediated?: (
    checkID: string,
    justification?: string
  ) => Promise<any>;
  id?: string;
  requestId: number;
  sharedDocuments: RemediationRequestSharedEvidence[];
  downloadDocument: (doc: RemediationRequestSharedEvidence) => void;
}

type IRemediationDetailsRiskTableProps = IRemediationDetailsRiskTableOwnProps &
  DefaultThunkDispatchProp;

const RemediationDetailsRisksTable = ({
  dispatch,
  vendorId,
  isSubsidiary,
  cloudscanRisks,
  surveyRisks,
  additionalEvidenceRisks,
  saasRisks,
  onSelectRisk,
  onSelectedRiskParamsChange,
  isVendorPortal,
  isSelfRemediation,
  onOpenCVEPanelFromRiskPanel,
  onClickAssetInVulnPanel,
  onOpenCloudscanPanelFromRiskPanel,
  goToSurvey,
  goToSurveyRisk,
  onCreateRiskWaiver,
  hasSurveyRisksRemoved,
  isEditable,
  history,
  vendorAssessmentId,
  userHasWaiveRiskPermission,
  domainsFilteredOut,
  goToAdditionalEvidence,
  userHasRemediateRiskPermission,
  onMarkAsRemediated,
  id,
  requestId,
  sharedDocuments,
  downloadDocument,
}: IRemediationDetailsRiskTableProps) => {
  const queryParams = GetQueryParams(location.search);

  const totalRisks =
    cloudscanRisks.length +
    surveyRisks.length +
    additionalEvidenceRisks.length +
    saasRisks.length;

  const [openMarkRiskRemediatedModal, markRiskRemediatedModal] = useModalV2(
    MarkRiskAsRemediatedModal
  );

  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedSeverity, setSelectedSeverity] = useState<
    Severity | undefined
  >(undefined);
  const [searchText, setSearchText] = useState("");

  const toggleStatus = (status: string) =>
    setSelectedStatus(selectedStatus === status ? "all" : status);

  const columns: IXTableColumnHeader[] = [
    {
      id: "severity",
      text: "Severity",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "finding",
      text: "Finding",
      sortable: true,
    },
    {
      id: "category",
      text: "Category",
      sortable: true,
    },
    {
      id: "status",
      text: "Status",
    },
    {
      id: "actions",
      text: "",
      className: "shrink-cell",
    },
  ];

  const [filteredRisks, setFilteredRisks] = useState([] as IRisksTableData[]);
  useEffect(() => {
    // Filter using the selected tab first
    let theseRisks: IRisksTableData[] = cloudscanRisks.map((r) => {
      const status = GetRemediatedStatus(r.riskStatus);

      const resolvedWebsites = new Set(r.resolvedWebsites);
      const unresolvedWebsites =
        r.websites?.filter((w) => !resolvedWebsites.has(w)) ?? [];

      return {
        id: r.id,
        severity: SeverityAsString(r.defaultSeverity),
        failTitle: r.failTitle,
        failDescription: r.failDescription,
        groupTitle: r.groupTitle,
        factName: newFactCategoryMeta[r.factCategory]
          ? newFactCategoryMeta[r.factCategory].name
          : factCategoryMeta[r.factCategory].name,
        status,
        labelColour: RemediatedStatusLabelColor[status],
        checkId: r.checkId,
        source: RiskSource.Cloudscan,
        isAllWebsites: r.isAllWebsites,
        allWebsitesFailedCountCurrent: r.allWebsitesFailedCountCurrent,
        allWebsitesFailedCountSnapshot: r.allWebsitesFailedCountSnapshot,
        websites: r.websites,
        resolvedWebsites: r.resolvedWebsites,
        unresolvedWebsites,
        waivedWebsites: r.waivedWebsites,
        movedWebsites: r.movedWebsites,
        cveNames: r.metadata?.cveNames,
        description: r.description,
        baseCheckId: r.baseCheckId,
        baseSeverity:
          r.baseSeverity !== undefined && r.baseSeverity !== null
            ? SeverityAsString(r.baseSeverity)
            : undefined,
        riskSummary: r.riskSummary,
        riskDetails: r.riskDetails,
        riskRecommendedRemediation: r.riskRecommendedRemediation,
      };
    });
    theseRisks.push(
      ...surveyRisks.map((r) => {
        const status = GetRemediatedStatus(r.riskStatus);

        return {
          id: r.id,
          severity: SeverityAsString(r.defaultSeverity),
          failTitle: r.failTitle,
          failDescription: r.failDescription,
          groupTitle: r.groupTitle,
          factName: newFactCategoryMeta[r.factCategory]
            ? newFactCategoryMeta[r.factCategory].name
            : factCategoryMeta[r.factCategory].name,
          status: status,
          labelColour: RemediatedStatusLabelColor[status],
          source: RiskSource.Survey,
          checkId: r.checkId,
          surveys: r.surveys,
          description: r.description,
          baseCheckId: r.baseCheckId,
          baseSeverity:
            r.baseSeverity !== undefined && r.baseSeverity !== null
              ? SeverityAsString(r.baseSeverity)
              : undefined,
          riskSummary: r.riskSummary,
          riskDetails: r.riskDetails,
          riskRecommendedRemediation: r.riskRecommendedRemediation,
        };
      })
    );
    theseRisks.push(
      ...additionalEvidenceRisks.map((r) => {
        const status = GetRemediatedStatus(r.riskStatus);

        return {
          id: r.id,
          severity: SeverityAsString(r.defaultSeverity),
          failTitle: r.failTitle,
          failDescription: r.failDescription,
          groupTitle: r.groupTitle,
          factName: newFactCategoryMeta[r.factCategory]
            ? newFactCategoryMeta[r.factCategory].name
            : factCategoryMeta[r.factCategory].name,
          status: status,
          labelColour: RemediatedStatusLabelColor[status],
          source: RiskSource.AdditionalEvidence,
          checkId: r.checkId,
          surveys: r.surveys,
          description: r.description,
          additionalEvidences: r.additionalEvidences,
          baseCheckId: r.baseCheckId,
          baseSeverity:
            r.baseSeverity !== undefined && r.baseSeverity !== null
              ? SeverityAsString(r.baseSeverity)
              : undefined,
          riskSummary: r.riskSummary,
          riskDetails: r.riskDetails,
          riskRecommendedRemediation: r.riskRecommendedRemediation,
        };
      })
    );
    theseRisks.push(
      ...saasRisks.map((r) => {
        const status = GetRemediatedStatus(r.riskStatus);

        return {
          id: r.id,
          severity: SeverityAsString(r.defaultSeverity),
          failTitle: r.failTitle,
          failDescription: r.failDescription,
          groupTitle: r.groupTitle,
          factName: newFactCategoryMeta[r.factCategory]
            ? newFactCategoryMeta[r.factCategory].name
            : factCategoryMeta[r.factCategory].name,
          status: status,
          labelColour: RemediatedStatusLabelColor[status],
          source: RiskSource.SaaS,
          checkId: r.checkId,
          saasUsers: r.saasUsers,
          description: r.description,
          baseCheckId: r.baseCheckId,
          baseSeverity:
            r.baseSeverity !== undefined && r.baseSeverity !== null
              ? SeverityAsString(r.baseSeverity)
              : undefined,
          riskSummary: r.riskSummary,
          riskDetails: r.riskDetails,
          riskRecommendedRemediation: r.riskRecommendedRemediation,
        };
      })
    );

    switch (selectedStatus) {
      case "remediated":
        theseRisks = theseRisks.filter((r) => r.status === "Remediated");
        break;
      case "in":
        theseRisks = theseRisks.filter(
          (r) => r.status === "Partially remediated"
        );
        break;
      case "not":
        theseRisks = theseRisks.filter((r) => r.status === "Not remediated");
        break;
      case "archived":
        theseRisks = theseRisks.filter((r) => r.status === "Archived");
        break;
      case "waived":
        theseRisks = theseRisks.filter((r) => r.status == "Waived");
        break;
    }

    if (searchText !== "") {
      const searchLower = searchText.toLowerCase();
      theseRisks = theseRisks.filter(
        (risk) =>
          risk.failTitle.toLowerCase().includes(searchLower) ||
          risk.groupTitle.toLowerCase().includes(searchLower)
      );
    }

    if (selectedSeverity) {
      theseRisks = theseRisks.filter(
        (risk) => risk.severity === selectedSeverity
      );
    }

    setFilteredRisks(theseRisks);
  }, [cloudscanRisks, searchText, selectedStatus, selectedSeverity]);

  const [sortedRisks, sortedBy, onSortChange] = useSorting<
    IRisksTableData,
    "severity" | "finding" | "category"
  >(filteredRisks, "severity", SortDirection.DESC, {
    severity: {
      orderFuncs: [(r) => SeverityFromString(r.severity), (r) => r.failTitle],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "desc"],
    },
    finding: {
      orderFuncs: [(r) => r.failTitle, (r) => SeverityFromString(r.severity)],
      sortDirsDesc: ["asc", "desc"],
      sortDirsAsc: ["desc", "asc"],
    },
    category: {
      orderFuncs: [(r) => r.factName, (r) => SeverityFromString(r.severity)],
      sortDirsDesc: ["asc", "desc"],
      sortDirsAsc: ["desc", "asc"],
    },
  });

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedRisks,
    20
  );

  const getManageRiskButton = (risk: IRisksTableData) => {
    return risk.status === RemediatedStatus.NotRemediated ||
      risk.status === RemediatedStatus.PartiallyRemediated ? (
      <ManageRiskButton
        autoCloseOnMouseLeave
        hideAdjust={
          !userHasWaiveRiskPermission ||
          !vendorId ||
          isSubsidiary ||
          risk.source === RiskSource.Cloudscan ||
          risk.source === RiskSource.SaaS ||
          (!!risk.baseSeverity && risk.baseSeverity !== risk.severity) // In this case risk is already adjusted
        }
        onAdjust={
          onCreateRiskWaiver
            ? () =>
                onCreateRiskWaiver(risk.checkId, WaiverType.SeverityAdjustment)
            : undefined
        }
        hideWaive={
          // UserRisks under remediation cannot be waived
          !userHasWaiveRiskPermission || risk.source === RiskSource.SaaS
        }
        onWaive={
          onCreateRiskWaiver
            ? () => onCreateRiskWaiver(risk.checkId, WaiverType.RiskWaiver)
            : undefined
        }
        hideMarkAsRemediated={
          !userHasRemediateRiskPermission ||
          risk.source !== RiskSource.AdditionalEvidence
        }
        onMarkAsRemediated={
          onMarkAsRemediated
            ? () =>
                openMarkRiskRemediatedModal({
                  dispatch,
                  onMarkAsRemediated: (justification) =>
                    onMarkAsRemediated(
                      risk.baseCheckId ?? risk.checkId,
                      justification
                    ),
                  riskName: risk.failTitle,
                })
            : undefined
        }
      />
    ) : undefined;
  };

  const selectedRisk = filteredRisks?.find(
    (r) => r.checkId === queryParams.risk
  );

  // An effect is needed here as parent state is set. Without an effect (which triggers outside rendering)
  // React complains about state being set during the rendering of the parent component.
  useEffect(() => {
    const getVulnPanel = (
      vendorID: number | undefined,
      isSubsidiary: boolean,
      isManagedVendorAnalyst: boolean | undefined,
      managedOrgId: number | undefined,
      cveName: string,
      verified: boolean
    ) => {
      return (
        <VulnPanel
          vendorId={vendorID}
          isSubsidiary={isSubsidiary}
          isManagedVendorAnalyst={isManagedVendorAnalyst}
          managedOrgId={managedOrgId}
          cveName={cveName}
          verified={verified}
          onClickAsset={onClickAssetInVulnPanel}
        />
      );
    };

    if (!selectedRisk) {
      onSelectedRiskParamsChange?.(undefined, getVulnPanel);
    } else {
      const manageRiskButton = getManageRiskButton(selectedRisk);

      if (selectedRisk.source === RiskSource.Cloudscan) {
        onSelectedRiskParamsChange?.(
          <CloudScanRiskPanel
            titleButtonsContent={manageRiskButton ? manageRiskButton : <></>}
            risk={selectedRisk}
            isCustomer={isSelfRemediation}
            onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
            requestId={requestId}
            onClickRow={(id: string, risk?: string) => {
              onOpenCloudscanPanelFromRiskPanel?.(
                id,
                selectedRisk.failTitle,
                risk
              );
            }}
          />,
          getVulnPanel
        );
      }

      if (selectedRisk.source === RiskSource.Survey) {
        onSelectedRiskParamsChange?.(
          <SurveyRiskPanel
            titleButtonsContent={manageRiskButton ? manageRiskButton : <></>}
            risk={selectedRisk}
            goToSurvey={goToSurvey}
            goToSurveyRisk={goToSurveyRisk}
          />,
          getVulnPanel
        );
      }

      if (selectedRisk.source === RiskSource.AdditionalEvidence) {
        onSelectedRiskParamsChange?.(
          <AdditionalEvidenceRiskPanel
            isVendorPortal={isVendorPortal}
            requestId={requestId}
            titleButtonsContent={manageRiskButton ? manageRiskButton : <></>}
            risk={selectedRisk}
            sharedDocuments={sharedDocuments}
            downloadDocument={downloadDocument}
            goToAdditionalEvidence={goToAdditionalEvidence}
          />,
          getVulnPanel
        );
      }

      if (selectedRisk.source === RiskSource.SaaS) {
        onSelectedRiskParamsChange?.(
          <SaaSRiskPanel
            titleButtonsContent={manageRiskButton ? manageRiskButton : <></>}
            risk={selectedRisk}
          />,
          getVulnPanel
        );
      }
    }
  }, [
    // This is the only dependency that changes as part of the event (clicking the risk row).
    // To similate running the above outside the render phase as the result of the table row click
    // this should remain the only value here.
    selectedRisk,
  ]);

  const rows: IXTableRow[] = pageItems.map((risk) => {
    const failedCountCurrent = risk.allWebsitesFailedCountCurrent ?? 0;
    const failedCountSnapshot = risk.allWebsitesFailedCountSnapshot ?? 0;
    const shouldShowStatusInfo =
      risk.isAllWebsites &&
      risk.status === RemediatedStatus.PartiallyRemediated &&
      failedCountCurrent < failedCountSnapshot;

    const statusInfo = shouldShowStatusInfo
      ? `${failedCountCurrent} of ${failedCountSnapshot} domains included in this request are not remediated`
      : undefined;

    const severity = risk.severity;

    const cells = [
      <XTableCell className={"severity-cell"} key={"severity"}>
        <AdjustedSeverityIcon
          severity={severity}
          baseSeverity={risk.baseSeverity}
          label
        />
      </XTableCell>,
      <XTableCell className={"finding-cell"} key={"finding"}>
        <RiskName
          riskName={risk.failTitle}
          riskCategoryGroupName={risk.groupTitle}
        />
      </XTableCell>,
      <XTableCell className={"category-cell"} key={"category"}>
        <PillLabel color={LabelColor.Grey}>{risk.factName}</PillLabel>
      </XTableCell>,
      <XTableCell className={"status-cell"} key={"status"}>
        <PillLabel color={risk.labelColour} align={PillContentAlign.Center}>
          {risk.status}
          {statusInfo && (
            <SidePopupV2
              initDismissed
              inline
              popupDelay={0}
              position="top"
              text={statusInfo}
              width={240}
            >
              <i className="cr-icon-info" aria-label={statusInfo} />
            </SidePopupV2>
          )}
        </PillLabel>
      </XTableCell>,
    ];

    cells.push(
      <XTableCell key={"chevron"} className={"chevron-cell"}>
        <Icon name="chevron" direction={90} />
      </XTableCell>
    );

    const rowId = `${risk.checkId}`;

    return {
      id: rowId,
      cells,
      expandDisabled: true,
      onClick: () => {
        onSelectRisk?.(risk.checkId);
      },
    };
  });

  let emptyText = "No risks are partially remediated";
  let emptySubText = "Risks that are partially remediated will appear here";
  switch (selectedStatus) {
    case "remediated":
      emptyText = "No risks have been remediated yet";
      emptySubText = "Risks that are remediated will appear here";
      break;
    case "not":
      emptyText = "No unremediated risks found";
      emptySubText = "Risks that have not been remediated will appear here";
      break;
    case "archived":
      emptyText = "No archived risks found";
      emptySubText =
        "Risks that are part of archived questionnaires will appear here";
      break;
    case "waived":
      emptyText = "No waived risks found";
      emptySubText = "Risks that have been waived will appear here";
      break;
  }

  const onEditRemediation = () => {
    history.push(
      `${history.location.pathname}/edit${
        vendorAssessmentId ? `?forVendorAssessment=${vendorAssessmentId}` : ""
      }`,
      {
        backTo: history.location.pathname,
        backToText: "Back to Remediation",
      }
    );
  };

  const statuses: { id: string; text: string }[] = [
    {
      id: "all",
      text: "All",
    },
    {
      id: "remediated",
      text: "Remediated",
    },
    {
      id: "waived",
      text: "Waived",
    },
    {
      id: "in",
      text: "Partially remediated",
    },
    {
      id: "not",
      text: "Not remediated",
    },
  ];

  if (!isSelfRemediation) {
    statuses.push({
      id: "archived",
      text: "Archived",
    });
  }
  return (
    <ReportCard newStyles className={"remediation-risks-table"} id={id}>
      <div className={"header"}>
        <div className={"top"}>
          Risks{" "}
          <PillLabel color={LabelColor.Blue}>
            {`${totalRisks} ${pluralise(totalRisks, "risk", "risks")}`}
          </PillLabel>{" "}
        </div>
        {isEditable && !isVendorPortal && (
          <div className={"header-right"}>
            <Button onClick={onEditRemediation}>
              <span className={"cr-icon-pencil-2"} />
              {" Edit risks"}
            </Button>
          </div>
        )}
      </div>
      <div className="info-banner-container">
        {hasSurveyRisksRemoved && (
          <InfoBanner
            type={BannerType.INFO}
            message={
              "Some risks have been removed due to related questionnaires being archived, cancelled or otherwise becoming unavailable."
            }
          />
        )}
        {domainsFilteredOut && (
          <InfoBanner
            type={BannerType.INFO}
            message={
              "Some risks or domains may have been filtered out as they are in portfolios you do not have access to."
            }
          />
        )}
      </div>
      <div className="search-filters">
        <SearchBox
          value={searchText}
          onChanged={setSearchText}
          placeholder={"Search"}
        />
        <div className="filters">
          <SeveritySelectorButton
            severity={selectedSeverity}
            onChange={(s) => setSelectedSeverity(s)}
          />
          <ButtonWithDropdownV2
            buttonProps={{
              tertiary: false,
              primary: selectedStatus !== "all",
            }}
            text={
              selectedStatus === "all"
                ? "Status"
                : statuses.find(({ id }) => id === selectedStatus)?.text
            }
            showChevron
          >
            {statuses.map(({ id, text }) => (
              <DropdownItem
                key={id}
                selected={id === selectedStatus}
                onClick={() => toggleStatus(id)}
              >
                {text}
              </DropdownItem>
            ))}
          </ButtonWithDropdownV2>
        </div>
      </div>
      <XTable
        rows={rows}
        columnHeaders={columns}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        pagination={{
          currentPage: currentPage,
          totalPages: totalPages,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
      />
      {rows.length === 0 && (searchText !== "" || !!selectedSeverity) && (
        <SearchEmptyCard
          onClear={() => {
            setSearchText("");
            setSelectedSeverity(undefined);
            setSelectedStatus("all");
          }}
          searchItemText={"risks"}
        />
      )}
      {rows.length === 0 &&
        searchText === "" &&
        !selectedSeverity &&
        selectedStatus !== "all" && (
          <EmptyCardWithAction
            emptyText={emptyText}
            emptySubText={emptySubText}
            iconSrc={Search}
          />
        )}
      {markRiskRemediatedModal}
    </ReportCard>
  );
};

export default appConnect()(RemediationDetailsRisksTable);

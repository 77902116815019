import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  clearSurveyData,
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
  setProjectionRequestTimestamp,
  setRemediationRequestData,
} from "../../_common/reducers/commonActions";
import { CloudscanRiskMetadata } from "../../_common/types/risks";
import {
  DefaultAction,
  DefaultThunkDispatch,
  ISingleVendorData,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import {
  RemediationProjectionResponse,
  RemediationRequestCounts,
  RemediationRequestWithMeta,
} from "../../_common/types/remediation";
import {
  getVendorData,
  setCustomerData,
  setVendorData,
} from "./cyberRiskActions";
import { SurveyStatus } from "../../_common/types/survey";
import { refreshLatestVendorAssessmentForVendor } from "../../analyst_portal/reducers/analystManagedVendors.actions";
import {
  CloudConnectionsFilters,
  WatchlistFilter,
} from "../components/filter/types";
import { isEqual as _isEqual, get as _get } from "lodash";
import { getSubsidiaryName } from "./subsidiary.actions";
import { IRemediationRequestBaseRequest } from "../../_common/reducers/remediationRequest.actions";
import { fetchCustomerRiskHostnames } from "./domains.actions";
import { fetchRiskVendorWebsites } from "./vendorRiskPortfolio.actions";
import moment from "moment";
import { AppDispatch, RootState } from "../../_common/types/reduxStore";
import { Severity } from "../../_common/types/severity";
import ThreatMonitoringAPI, {
  ThreatMonitoringTagTypes,
} from "../../threatmonitoring/api/threatmonitoring.api";

export interface ICloudscanCheckAndWebsite {
  checkId: string;
  selectAll: boolean;
  websites: string[];
  metadata?: CloudscanRiskMetadata;
}

export interface ISurveyCheckToAdd {
  riskID: string;
  surveys?: number[];
  publicSurveys?: number[];
}

export interface IAdditionalEvidenceCheckToAdd {
  riskID: string;
}

export interface IAdditionalEvidenceDocumentToAdd {
  evidenceId: number;
}

export interface ISaasCheckToAdd {
  riskID: string;
  userUUIDs: string[];
}

export interface IRemediationRequestCreateV1Reqeuest
  extends IRemediationRequestBaseRequest {
  vendorId?: number;
  isSubsidiary: boolean;
  isDraft: boolean;
  vendorAssessmentId?: number;
}

export interface IRemediationRequestCreateV1Response {
  newId: number;
  emailAddressInactive: boolean;
}

export const createNewRemediationRequest = (
  request: IRemediationRequestCreateV1Reqeuest
): DefaultAction<IRemediationRequestCreateV1Response> => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const tpvmSession = grabTPVMSession(getState);

    let resp: IRemediationRequestCreateV1Response;

    try {
      resp = await FetchCyberRiskUrl(
        "remediationrequest/v1",
        null,
        { method: "POST", body: JSON.stringify(request) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating new remediation request", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    if (request.vendorId) {
      // clear the surveys in case this has altered their state
      dispatch(clearSurveyData());
      dispatch(
        setVendorData(
          request.vendorId,
          {
            surveys: {
              loading: false,
              error: null,
              result: null,
            },
          },
          request.isSubsidiary,
          tpvmSession as any
        )
      );

      // clear the vendor vulns in case this changed their state
      setVendorData(
        request.vendorId,
        {
          vulns: {
            loading: true,
          },
        },
        request.isSubsidiary,
        tpvmSession as any
      );

      // if this is for a vendor assessment and part of a tpvm session then refresh that item
      if (tpvmSession.tpvm && request.vendorAssessmentId) {
        dispatch(refreshLatestVendorAssessmentForVendor(request.vendorId));
      }
    } else {
      // clear the customer vulns in case this changed their state
      dispatch(
        setCustomerData({
          vulns: {
            loading: true,
          },
        })
      );
    }

    return resp;
  };
};

export interface IThreatMonitoringRemediationRequestCreateV1Request {
  isDraft: boolean;
  threatFindingUUID: string;
  title: string;
  severity: Severity;
  inviteEmails: string[];
  emailMessage: string;
  dueDate?: string;
  reminderDate?: string;
  copyThreatComments: boolean;
}

export const createNewThreatMonitoringRemediationRequest = (
  request: IThreatMonitoringRemediationRequestCreateV1Request
): DefaultAction<IRemediationRequestCreateV1Response> => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: IRemediationRequestCreateV1Response;

    try {
      resp = await FetchCyberRiskUrl(
        "threatremediationrequest/v1",
        null,
        { method: "POST", body: JSON.stringify(request) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating new remediation request", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    ThreatMonitoringAPI.util.invalidateTags([
      {
        type: ThreatMonitoringTagTypes.result,
        id: request.threatFindingUUID,
      },
      ThreatMonitoringTagTypes.totals,
    ]);

    return resp;
  };
};

export const fetchProjectionForNewRemediationRequest = (
  vendorId: number,
  isSubsidiary: boolean,
  cloudscanChecksAndWebsites?: ICloudscanCheckAndWebsite[],
  surveyChecks?: ISurveyCheckToAdd[],
  additionalEvidenceChecks?: IAdditionalEvidenceCheckToAdd[],
  remediationRequestId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<RemediationProjectionResponse | null> => {
    const timestamp = moment().format();
    const timestampKey = "new";
    dispatch(setProjectionRequestTimestamp(timestamp, timestampKey));

    const opts = {
      cloudscanChecksAndWebsites,
      isSubsidiary,
      surveyChecks,
      additionalEvidenceChecks,
      remediationRequestId,
    } as any;

    if (vendorId) {
      opts.vendorId = vendorId;
    }

    let json;

    try {
      json = (await FetchCyberRiskUrl(
        "projection/remediationrequest/v1",
        null,
        { method: "POST", body: JSON.stringify(opts) },
        dispatch,
        getState
      )) as RemediationProjectionResponse;
    } catch (e) {
      LogError(
        "Error retrieving score projection for new remediation request",
        e
      );

      throw e;
    }

    if (
      timestamp == getState().common.projection.requestTimestamps[timestampKey]
    ) {
      return json;
    }
    return null;
  };
};

// fetchSurveysRemediationMini
// Fetches a surveyMini list of any surveys that could have remediation requested on them
export const fetchSurveysRemediationMini =
  (vendorId: number, force = false, includePublicSurveys = false) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(
      getState,
      vendorId,
      false,
      tpvmSession
    ) as ISingleVendorData;
    if (!force && vendor && vendor.remediationSurveys) {
      return vendor.remediationSurveys;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/list/mini/v1",
        {
          vendor_id: vendorId,
          has_status: [
            SurveyStatus.Complete,
            SurveyStatus.InProgress,
            SurveyStatus.AwaitingReview,
            SurveyStatus.InReview,
          ].join(","),
          include_public: includePublicSurveys,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching remediation surveys for vendor", e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          remediationSurveys: json ?? [],
        },
        false,
        tpvmSession as any
      )
    );

    return json || [];
  };

const countRemediations = (count: RemediationRequestCounts) => {
  const { inProgress, awaitingReview, completed, archived, draft } = count;
  return {
    inProgress: inProgress ?? 0,
    awaitingReview: awaitingReview ?? 0,
    completed: completed ?? 0,
    archived: archived ?? 0,
    draft: draft ?? 0,
  };
};

export interface remediationRequestListRespV1 {
  requests: RemediationRequestWithMeta[];
  count: RemediationRequestCounts;
}

export const fetchRemediationRequestListForAllVendors = (
  force = false,
  filters?: WatchlistFilter,
  pageSize?: number,
  pageNum?: number,
  sortBy?: string,
  sortDesc?: boolean,
  statuses?: string[],
  archived?: boolean,
  searchText?: string,
  clearCounts = false // if true and a new request is made it will force the counts reset
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const vendorRemediations =
      getState().cyberRisk.customerData.vendorRemediations;
    const existingRequestIds =
      getState().cyberRisk.customerData.remediationRequestIds;
    const currentFilters = getState().cyberRisk.customerData.filters;

    // set the pagination query params
    // the order is the passed arg, then the cache state in redux then the default values
    pageSize = pageSize ?? vendorRemediations?.pageSize ?? 20;
    pageNum = pageNum ?? vendorRemediations?.pageNum ?? 0;
    sortBy = sortBy ?? vendorRemediations?.sortBy ?? "last_updated";
    sortDesc = sortDesc ?? vendorRemediations?.sortDesc ?? true;
    statuses = statuses ?? vendorRemediations?.statuses ?? [];
    archived = archived ?? vendorRemediations?.archived ?? false;
    searchText = searchText ?? vendorRemediations?.searchText ?? "";

    if (
      !force &&
      existingRequestIds &&
      vendorRemediations &&
      vendorRemediations.pageSize === pageSize &&
      vendorRemediations.pageNum === pageNum &&
      vendorRemediations.sortBy === sortBy &&
      vendorRemediations.sortDesc === sortDesc &&
      vendorRemediations.statuses === statuses &&
      vendorRemediations.archived === archived &&
      vendorRemediations.searchText === searchText &&
      (!filters || _isEqual(filters, currentFilters))
    ) {
      // cached, don't fetch again
      return existingRequestIds;
    }

    const newFilters = filters || currentFilters;

    // we want to reset the remediations ahead of fetching data if and only if the filters change
    // this will ensure that a filter change will result in the numbers in the tab being hidden during the load
    // in all other circumstances, e.g. a page change, the numbers should remain
    const newCustomerData: any = { remediationRequestIdsLoading: true };
    if (
      !_isEqual(newFilters, currentFilters) ||
      vendorRemediations?.searchText !== searchText ||
      clearCounts
    ) {
      // reset the state but keep the search text as it's connected to the
      // user's search input
      newCustomerData.vendorRemediations = { requests: [], searchText };
    }

    // Mark the remediation request IDs as loading
    dispatch(setCustomerData(newCustomerData));

    let resp: remediationRequestListRespV1;

    try {
      resp = await FetchCyberRiskUrl(
        "remediationrequest/list/v1",
        {
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          vendor_tiers: newFilters.vendorTiers,
          vendor_assessment_classifications:
            newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          portfolio_ids: newFilters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          page_size: pageSize,
          page_num: pageNum,
          sort_desc: sortDesc,
          sort_by: sortBy,
          statuses: statuses,
          archived: archived,
          search_text: searchText,
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
          vendor_date_added_start: newFilters.vendorDateAddedStartDate,
          vendor_date_added_end: newFilters.vendorDateAddedEndDate,
          vendor_date_added_before: newFilters.vendorDateAddedDateBefore,
          vendor_date_added_between: newFilters.vendorDateAddedDateBetween,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching list of remediation requests for vendor", e);

      dispatch(setCustomerData({ remediationRequestIdsLoading: false }));
      return null;
    }

    if (!resp) {
      resp = { requests: [], count: {} as RemediationRequestCounts };
    }

    // For each request, update the risks in common state first
    const requestIds: number[] = [];
    const {
      inProgress: numInProgress,
      awaitingReview: numAwaitingReview,
      completed: numCompleted,
      archived: numArchived,
    } = countRemediations(resp.count);

    // Set the remediation request lists for each vendor too
    const remediationRequestIdsByVendor: Record<number, number[]> = {};
    for (let i = 0; i < resp.requests.length; i++) {
      const request = resp.requests[i];
      requestIds.push(request.id);

      dispatch(
        setRemediationRequestData(request.id, {
          details: request,
        })
      );

      if (request.vendorId !== undefined) {
        if (remediationRequestIdsByVendor[request.vendorId]) {
          remediationRequestIdsByVendor[request.vendorId].push(request.id);
        } else {
          remediationRequestIdsByVendor[request.vendorId] = [request.id];
        }
      }
    }

    dispatch(
      setCustomerData({
        remediationRequestIds: requestIds,
        remediationRequestIdsLoading: false,
        remediationRequestCounts: {
          inProgress: numInProgress,
          awaitingReview: numAwaitingReview,
          completed: numCompleted,
          archived: numArchived,
        },
        vendorRemediations: {
          requests: resp.requests,
          count: resp.count,
          pageSize,
          pageNum,
          sortBy,
          sortDesc,
          statuses,
          archived,
          searchText,
        },
      })
    );

    for (const vendorId in remediationRequestIdsByVendor) {
      dispatch(
        setVendorData(vendorId, {
          remediationRequestIds: remediationRequestIdsByVendor[vendorId],
        })
      );
    }

    return requestIds;
  };
};

interface SelfRemediationFilter
  extends WatchlistFilter,
    CloudConnectionsFilters {
  websiteLabelIds?: number[];
  domainPortfolioIds?: number[];
  websiteLabelIdsDoNotMatch: boolean;
  websiteLabelIdsMatchAll: boolean;
  websiteIncludeUnlabeled: boolean;
}

export const fetchRemediationRequestListForSelf = (
  force = false,
  filters?: SelfRemediationFilter
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const existingList = _get(
      getState().cyberRisk.customerData,
      "selfRemediationRequestIds"
    );
    const currentFilters = getState().cyberRisk.customerData.filters;
    if (
      !force &&
      existingList &&
      (!filters || _isEqual(filters, currentFilters))
    ) {
      // We have cached data, don't fetch again
      return existingList;
    }

    // mark as loading
    dispatch(setCustomerData({ selfRemediationRequestIdsLoading: true }));

    const newFilters = filters || currentFilters;

    let resp: remediationRequestListRespV1;

    try {
      resp = await FetchCyberRiskUrl(
        "remediationrequest/list/v1",
        {
          self_remediation: true,
          website_label_ids: newFilters.websiteLabelIds,
          website_portfolio_ids: newFilters.domainPortfolioIds,
          website_label_ids_match_all: newFilters.websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: newFilters.websiteLabelIdsDoNotMatch,
          website_include_unlabeled: newFilters.websiteIncludeUnlabeled,
          appguard_cloud_providers: newFilters.cloudConnectionProviderTypes,
          appguard_cloud_connection_uuids: newFilters.cloudConnectionUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching list of remediation requests for self", e);

      dispatch(setCustomerData({ selfRemediationRequestIdsLoading: false }));
      return null;
    }

    if (!resp) {
      resp = { requests: [], count: {} as RemediationRequestCounts };
    }

    // For each request, update the risks in common state first
    const requestIds: number[] = [];
    const { inProgress, awaitingReview, completed, archived } =
      countRemediations(resp.count);

    for (let i = 0; i < resp.requests.length; i++) {
      const request = resp.requests[i];
      requestIds.push(request.id);
      dispatch(
        setRemediationRequestData(request.id, {
          details: request,
        })
      );
    }

    dispatch(
      setCustomerData({
        selfRemediationRequestIds: requestIds,
        selfRemediationRequestIdsLoading: false,
        selfRemediationRequestCounts: {
          inProgress,
          awaitingReview,
          completed,
          archived,
        },
      })
    );

    return requestIds;
  };
};

export const fetchRemediationRequestListForAllSubsidiaries = (
  force = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const existingList = _get(
      getState().cyberRisk.customerData,
      "subsidiaryRemediationRequestIds"
    );

    if (!force && existingList) {
      // We have cached data, don't fetch again
      return existingList;
    }

    // Mark as loading
    dispatch(setCustomerData({ subsidiaryRemediationRequestIdsLoading: true }));

    let resp: remediationRequestListRespV1;

    try {
      resp = await FetchCyberRiskUrl(
        "remediationrequest/list/v1",
        {
          self_remediation: false,
          subsidiary: true,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "Error fetching list of remediation requests for subsidiaries",
        e
      );
      dispatch(
        setCustomerData({ subsidiaryRemediationRequestIdsLoading: false })
      );

      return null;
    }

    if (!resp) {
      resp = { requests: [], count: {} as RemediationRequestCounts };
    }

    // For each request, update the risks in common state first
    const requestIds: number[] = [];
    const { inProgress, awaitingReview, completed, archived } =
      countRemediations(resp.count);

    for (let i = 0; i < resp.requests.length; i++) {
      const request = resp.requests[i];
      requestIds.push(request.id);

      dispatch(
        setRemediationRequestData(request.id, {
          details: request,
        })
      );
    }

    dispatch(
      setCustomerData({
        subsidiaryRemediationRequestIds: requestIds,
        subsidiaryRemediationRequestIdsLoading: false,
        subsidiaryRemediationRequestCounts: {
          inProgress,
          awaitingReview,
          completed,
          archived,
        },
      })
    );

    return requestIds;
  };
};

export const fetchRemediationRequestListForVendor = (
  vendorId: number,
  isSubsidiary: boolean,
  force = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, isSubsidiary, tpvmSession);
    const existingList = _get(vendor, "remediationRequestIds");
    const existingCounts = _get(vendor, "remediationRequestCounts");

    if (!force && existingList && existingCounts) {
      // We have cached data, don't fetch again
      return existingList;
    }

    dispatch(
      setVendorData(
        vendorId,
        { remediationRequestIdsLoading: true },
        isSubsidiary,
        tpvmSession as any
      )
    );

    let resp: remediationRequestListRespV1;

    try {
      resp = await FetchCyberRiskUrl(
        "remediationrequest/list/v1",
        {
          vendor_id: vendorId,
          subsidiary: isSubsidiary,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching list of remediation requests for vendor", e);

      dispatch(
        setVendorData(
          vendorId,
          { remediationRequestIdsLoading: false },
          isSubsidiary,
          tpvmSession as any
        )
      );
      return null;
    }

    if (!resp) {
      resp = { requests: [], count: {} as RemediationRequestCounts };
    }

    // For each request, update the risks in common state first
    const requestIds = [];
    const { inProgress, awaitingReview, completed, archived } =
      countRemediations(resp.count);

    for (let i = 0; i < resp.requests.length; i++) {
      const request = resp.requests[i];
      requestIds.push(request.id);
      dispatch(
        setRemediationRequestData(request.id, {
          details: request,
        })
      );
    }

    const vendorData: any = {
      remediationRequestIds: requestIds,
      remediationRequestIdsLoading: false,
      remediationRequestCounts: {
        inProgress,
        awaitingReview,
        completed,
        archived,
      },
    };

    // If this is a subsidiary vendor, lookup the vendor name from the subsidiaries hierarchy and add the field to the data object
    let vendorName: string | undefined;
    if (isSubsidiary) {
      const subsidiaries = getState().cyberRisk.customerData.subsidiaries;

      if (subsidiaries) {
        vendorName = getSubsidiaryName(vendorId, subsidiaries);
        vendorData["display_name"] = vendorName;
      }
    }

    dispatch(
      setVendorData(vendorId, vendorData, isSubsidiary, tpvmSession as any)
    );

    return requestIds;
  };
};

export const fetchCustomerOrVendorRiskWebsites = (
  riskID: string,
  vendorID: number | undefined,
  isSelfRemediation: boolean,
  isSubsidiary: boolean,
  writableDomainPortfolioIds: number[] | undefined
) =>
  isSelfRemediation
    ? fetchCustomerRiskHostnames(riskID, {
        websiteLabelIds: [],
        domainPortfolioIds: writableDomainPortfolioIds ?? [], // Only request writable portfolio IDs if necessary
      })
    : (fetchRiskVendorWebsites(
        riskID,
        vendorID ?? 0,
        isSubsidiary,
        false,
        true // No filter
      ) as any);

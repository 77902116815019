import EllipsizedText from "../../_common/components/EllipsizedText";
import SeverityIcon from "../../_common/components/SeverityIcon";
import TwoColumnDisplay, {
  TwoColumnDisplayRow,
  TwoColumnDisplayRowProps,
} from "../../_common/components/TwoColumnDisplay";
import { IUserMini } from "../../_common/types/user";
import {
  PasswordAdditionalAttributes,
  DisplayableThreatMonitoringResult,
  CookieAdditionalAttributes,
  ThreatMonitoringVendorNetworkType,
  ChatAdditionalAttributes,
  MalwareAdditionalAttributes,
  ThreatMonitoringSourceType,
  PostAdditionalAttributes,
  TelegramChatAdditionalAttributes,
  BaseChatAdditionalAttributes,
  DiscordChatAdditionalAttributes,
  GithubAdditionalAttributes,
} from "../api/types";
import {
  threatTypeToString,
  vendorNetworkTypeDescription,
  vendorNetworkTypeToString,
} from "../funcs/domain";
import ResultDate from "./ResultDate";
import ThreatSource from "./ThreatSource";
import "./ThreatDetailCard.scss";
import Investigator from "./Investigator";
import React, { ReactNode } from "react";
import { ExternalLink } from "../../_common/components/ExternalLink";
import {
  PopupPosition,
  SidePopupV2,
} from "../../_common/components/DismissablePopup";
import { PasswordRevealerWithTooltip } from "../../_common/components/PasswordRevealer";
import { capitalizeFirstLetter } from "../../_common/helpers/string.helpers";
import EllipsisedURL from "./EllipsisedURL";
import KeywordsPills from "./KeywordsPills";

interface ThreatOnlyDetailProps {
  threat: DisplayableThreatMonitoringResult;
}

interface ThreatDetailProps extends ThreatOnlyDetailProps {
  actors: Record<number, IUserMini>;
}

export function ThreatDetailCard({ threat, actors }: ThreatDetailProps) {
  return (
    <div className="threat-detail-card">
      <div className="left">
        <TwoColumnDisplay key="left">
          <TwoColumnDisplayRow label="Keyword" key="keyword">
            <KeywordsPills keywords={threat.keywords} />
          </TwoColumnDisplayRow>
          <TwoColumnDisplayRow label="Severity" key="severity">
            <SeverityIcon severity={threat.severity} label />
          </TwoColumnDisplayRow>
          <TwoColumnDisplayRow label="Date detected" key="date-detected">
            <ResultDate date={threat.dateDetected} />
          </TwoColumnDisplayRow>
          <TwoColumnDisplayRow label="Threat type" key="threat-type">
            {threat.threatTypes.map(threatTypeToString).join(", ")}
          </TwoColumnDisplayRow>
          <TwoColumnDisplayRow label="Source" key="source">
            <ThreatSource
              source={threat.source}
              module={threat.module}
              network={threat.additionalAttributes?.post?.network}
            />
          </TwoColumnDisplayRow>
          <OriginURL threat={threat} />
          {threat.investigating ? (
            <TwoColumnDisplayRow label="Investigator" key="investigator">
              <Investigator
                threatUUID={threat.uuid}
                investigatorId={threat.investigatorId}
                actors={Object.values(actors)}
              />
            </TwoColumnDisplayRow>
          ) : (
            <></>
          )}
        </TwoColumnDisplay>
      </div>
      <div className="right">
        <SpecificThreatDetail threat={threat} />
      </div>
    </div>
  );
}

function OriginURL({ threat }: ThreatOnlyDetailProps) {
  if (
    threat.additionalAttributes?.cookie ||
    threat.additionalAttributes?.password ||
    threat.additionalAttributes?.post ||
    threat.additionalAttributes?.github
  ) {
    return null;
  }

  if (threat.additionalAttributes?.chat) {
    return (
      <TwoColumnDisplayRow label="Origin" className="origin" key="origin">
        <EllipsisedURL url={threat.originURL} />
      </TwoColumnDisplayRow>
    );
  }

  return (
    <TwoColumnDisplayRow label="Origin" className="origin" key="origin">
      <EllipsizedText text={threat.originURL}>
        {threat.originURL}
      </EllipsizedText>
    </TwoColumnDisplayRow>
  );
}

function SpecificThreatDetail({ threat }: ThreatOnlyDetailProps) {
  if (!threat.additionalAttributes) {
    return <></>;
  }

  if (threat.additionalAttributes.chat) {
    return (
      <ChatDetail details={threat.additionalAttributes.chat} threat={threat} />
    );
  }
  if (threat.additionalAttributes.post) {
    return (
      <PostDetail details={threat.additionalAttributes.post} threat={threat} />
    );
  }
  if (threat.additionalAttributes.cookie) {
    return (
      <CookieDetail
        details={threat.additionalAttributes.cookie}
        threat={threat}
      />
    );
  }
  if (threat.additionalAttributes.password) {
    return (
      <PasswordDetail
        details={threat.additionalAttributes.password}
        threat={threat}
      />
    );
  }

  if (threat.additionalAttributes.github) {
    return (
      <GithubDetail
        threat={threat}
        details={threat.additionalAttributes.github}
      />
    );
  }

  return <></>;
}

interface ThreadDetailProps<D> {
  details: D;
  threat: DisplayableThreatMonitoringResult;
}

function ChatDetail({
  details,
  threat,
}: ThreadDetailProps<ChatAdditionalAttributes>) {
  if (details.discord) {
    return <DiscordDetail details={details.discord} threat={threat} />;
  }
  if (details.telegramGroup) {
    return (
      <TelegramDetail
        kind="group"
        details={details.telegramGroup}
        threat={threat}
      />
    );
  }
  if (details.telegramChannel) {
    return (
      <TelegramDetail
        kind="channel"
        details={details.telegramChannel}
        threat={threat}
      />
    );
  }

  // If we don't have any chat details, we can't render anything.
  // If we implement a fallback chat type in the backend, add it here.
  return <></>;
}

interface TelegramDetailProps
  extends ThreadDetailProps<TelegramChatAdditionalAttributes> {
  kind: "group" | "channel";
}
function TelegramDetail({ details, kind }: TelegramDetailProps) {
  return (
    <TwoColumnDisplay key="right">
      {kind === "channel" && (
        <>
          <TwoColumnDisplayRow label="Type" key="type" className="flex-row">
            Channel
            <Info text="A Telegram Channel is a one-way broadcast tool where only admins post content, and subscribers receive updates." />
          </TwoColumnDisplayRow>
          <TwoColumnDisplayRow label="Channel name" key="name">
            {details.name}
          </TwoColumnDisplayRow>
        </>
      )}
      {kind === "group" && (
        <>
          <TwoColumnDisplayRow label="Type" key="type" className="flex-row">
            Group
            <Info text="A Telegram Group is a space for two-way communication where both members and administrators can post content." />
          </TwoColumnDisplayRow>
          <TwoColumnDisplayRow label="Group name" key="name">
            {details.name}
          </TwoColumnDisplayRow>
        </>
      )}
      <CommonChatDetail details={details} />
    </TwoColumnDisplay>
  );
}

function DiscordDetail({
  details,
}: ThreadDetailProps<DiscordChatAdditionalAttributes>) {
  return (
    <>
      <TwoColumnDisplay key="right">
        <TwoColumnDisplayRow label="Server name" key="server">
          {details.server}
        </TwoColumnDisplayRow>
        <TwoColumnDisplayRow label="Channel name" key="channel">
          {details.channel}
        </TwoColumnDisplayRow>
        <CommonChatDetail details={details} />
      </TwoColumnDisplay>
    </>
  );
}

function CommonChatDetail({
  details,
}: {
  details: BaseChatAdditionalAttributes;
}) {
  return (
    <>
      <TwoColumnDisplayRow label="Language" key="language">
        {capitalizeFirstLetter(details.language)}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Message author" key="author">
        {details.author}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="No. of participants" key="participants">
        {details.participants.length}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Participants" key="threat-partic">
        <RevealableList list={details.participants} show={5} />
      </TwoColumnDisplayRow>
    </>
  );
}

function PostDetail({
  details,
  threat,
}: ThreadDetailProps<PostAdditionalAttributes>) {
  let label = "Name";
  switch (threat.source) {
    case ThreatMonitoringSourceType.RansomwareBlog:
      label = "Blog name";
      break;
    case ThreatMonitoringSourceType.Blogs:
      label = "Forum name";
      break;
  }

  return (
    <TwoColumnDisplay key="right">
      <NetworkRow network={details.network} />
      <TwoColumnDisplayRow label={label} key="name">
        {details.siteName}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Post URL" key="url">
        {details.network ===
        ThreatMonitoringVendorNetworkType.WebzNetworkTypeOpenWeb ? (
          <EllipsisedURL url={details.postURL} />
        ) : (
          <EllipsizedText text={details.postURL}>
            {details.postURL}
          </EllipsizedText>
        )}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Post title" key="title">
        {details.postTitle}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Post author" key="author">
        {details.author}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="No. of participants" key="participants">
        {details.threadParticipants}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Replies" key="replies">
        {details.postReplies}
      </TwoColumnDisplayRow>
    </TwoColumnDisplay>
  );
}

// Currently only supports cookie threats.
// Kaduu should support e.g. password threats once we have backend support.
function CookieDetail({
  details,
  threat,
}: ThreadDetailProps<CookieAdditionalAttributes>) {
  return (
    <TwoColumnDisplay key="right">
      <TwoColumnDisplayRow label="URL" key="url">
        <EllipsisedURL url={threat.originURL} />
      </TwoColumnDisplayRow>
      <>
        <TwoColumnDisplayRow label="Cookie name" key="cookie-name">
          {details.cookieName}
        </TwoColumnDisplayRow>
        <TwoColumnDisplayRow label="Cookie value" key="cookie-value">
          <EllipsizedText text={details.cookieValue}>
            {details.cookieValue}
          </EllipsizedText>
        </TwoColumnDisplayRow>
        <MalwareRows details={details} />
      </>
    </TwoColumnDisplay>
  );
}

function PasswordDetail({
  details,
  threat,
}: ThreadDetailProps<PasswordAdditionalAttributes>) {
  return (
    <TwoColumnDisplay key="right">
      <TwoColumnDisplayRow label="URL" key="url">
        <EllipsisedURL url={threat.originURL} />
      </TwoColumnDisplayRow>
      <OptionalRow label="Username" value={details.username} key="username" />
      <TwoColumnDisplayRow label="Password" key="password">
        {details.password ? (
          <PasswordRevealerWithTooltip password={details.password} />
        ) : (
          <span className="secondary">
            Leaked password not available for this threat
          </span>
        )}
      </TwoColumnDisplayRow>
      <MalwareRows details={details} />
    </TwoColumnDisplay>
  );
}

function MalwareRows({ details }: { details: MalwareAdditionalAttributes }) {
  return (
    <>
      <OptionalRow
        label="Device name"
        key="device-name"
        value={details.deviceName}
      />
      <OptionalRow
        label="Device serial no."
        key="device-serial"
        value={details.deviceSerialNumber}
      />
      <OptionalRow
        label="Device account"
        key="device-account"
        value={details.deviceAccount}
      />
      <OptionalRow
        label="Device user agent"
        key="device-user-agent"
        value={details.deviceUserAgent}
      />
      <OptionalRow label="Device IP" key="device-ip" value={details.ip} />
      <OptionalRow label="Device OS" key="device-os" value={details.deviceOS} />
      <OptionalRow
        label="Device country"
        key="device-country"
        value={details.deviceLocation}
      />
      {!!(details.asn && details.asnText) && (
        <TwoColumnDisplayRow
          label={
            <>
              ASN{" "}
              <Info text="The Autonomous System (AS) is the network responsible for routing traffic to an IP address." />
            </>
          }
          key="asn"
        >
          <EllipsizedText text={`${details.asn} / ${details.asnText}`}>
            <>
              {details.asn} /{" "}
              <span className="secondary">{details.asnText}</span>
            </>
          </EllipsizedText>
        </TwoColumnDisplayRow>
      )}
      <OptionalRow
        label="Malware name"
        key="malware-name"
        value={details.malwareType}
      >
        {details.malwareRefURL ? (
          <ExternalLink
            url={details.malwareRefURL}
            text={details.malwareType}
          />
        ) : (
          <>{details.malwareType}</>
        )}
      </OptionalRow>
      <OptionalRow
        label="Malware path"
        key="malware-path"
        value={details.malwarePath}
      />
      <OptionalRow
        label="Malware version"
        key="malware-version"
        value={details.malwareVersion}
      />
    </>
  );
}

function GithubDetail({
  details,
  threat,
}: ThreadDetailProps<GithubAdditionalAttributes>) {
  return (
    <TwoColumnDisplay key="right">
      <TwoColumnDisplayRow label="GitHub" key="github">
        <EllipsisedURL url={threat.originURL} />
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="Repository" key="repo">
        {details.repository}
      </TwoColumnDisplayRow>
      <TwoColumnDisplayRow label="File" key="file">
        {details.targetFile}
      </TwoColumnDisplayRow>
    </TwoColumnDisplay>
  );
}

function NetworkRow({
  network,
}: {
  network: ThreatMonitoringVendorNetworkType;
}) {
  const networkDesc = vendorNetworkTypeDescription(network);

  return (
    <TwoColumnDisplayRow label="Network" key="network" className="flex-row">
      {vendorNetworkTypeToString(network)}{" "}
      {networkDesc && <Info text={networkDesc} />}
    </TwoColumnDisplayRow>
  );
}

function Info({
  text,
  position,
}: {
  text: ReactNode;
  position?: PopupPosition;
}) {
  return (
    <SidePopupV2
      className="info-hover"
      text={text}
      position={position || "top"}
      popupHoverable
    >
      <div className="cr-icon-info" />
    </SidePopupV2>
  );
}

function OptionalRow(
  props: Omit<TwoColumnDisplayRowProps, "content"> & { value?: string }
) {
  if (!props.value) {
    return <></>;
  }

  const children = React.Children.toArray(props.children).filter(Boolean);
  if (children.length === 0) {
    const content = (
      <EllipsizedText text={props.value}>{props.value}</EllipsizedText>
    );
    return <TwoColumnDisplayRow {...props} content={content} />;
  }

  return <TwoColumnDisplayRow {...props} />;
}

interface RevealableListProps {
  list: string[];
  show?: number;
}
function RevealableList({ list, show }: RevealableListProps) {
  const [revealed, setRevealed] = React.useState(false);

  show = show || 5;

  const join = (list: string[]) => list.join(", ");

  if (list.length <= show) {
    return <>{join(list)}</>;
  }

  return (
    <>
      {revealed ? (
        <>{join(list)}</>
      ) : (
        <>
          {join(list.slice(0, show))},
          <div className="reveal" onClick={() => setRevealed(true)}>
            + {list.length - show} more
          </div>
        </>
      )}
    </>
  );
}

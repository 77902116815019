import React, { useCallback, useEffect, useState } from "react";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import {
  AvailableSection,
  DisplayableScheduledReportRecipient,
  ExportFiletype,
  ExportFiletypesBoth,
  ExportSection,
  ExportType,
  Section,
  SectionStatus,
} from "../../../_common/types/exportReport";
import { FilterTypes, isFilterActive } from "../filter";
import { getExportSections } from "../../reducers/cyberRiskSelectors";
import { Filters } from "../filter/types";
import Button from "../../../_common/components/core/Button";
import ColorCheckbox from "../ColorCheckbox";

import "../../style/components/ExportReportModal.scss";
import classnames from "classnames";
import FileTypeIconPDF from "../../../_common/images/file-type-icon-pdf.svg";
import FileTypeIconExcel from "../../../_common/images/file-type-icon-excel.svg";
import FileTypeIconWord from "../../../_common/images/file-type-icon-word.svg";
import { CheckboxGroup } from "../CheckboxGroup";
import InfoBanner, { BannerType } from "../InfoBanner";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import {
  exportReport,
  fetchExportSections,
  getScheduledExports,
  scheduleExecSummaryReport,
  scheduleExport,
} from "../../reducers/export.actions";
import Modal from "../../../_common/components/ModalV2";
import { requestExecSummaryPDFExport } from "../../reducers/cyberRiskActions";
import {
  CreatableV2,
  OptionType,
  SelectV2,
} from "../../../_common/components/SelectV2";
import DatePicker from "../../../_common/components/DatePicker";
import moment, { Moment } from "moment";
import { fetchOrgUserEmailAddresses } from "../../reducers/org.actions";
import { UserEmailAddress } from "../../../_common/types/user";
import { ValueType } from "react-select";
import { validateEmail } from "../../../_common/helpers";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { getFiltersFromState } from "../../reducers/filters.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { trackEvent } from "../../../_common/tracking";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { usePermissions } from "../../../_common/permissions";

const containsLockedSection = (sections: AvailableSection[]): boolean =>
  sections.some(
    ({ children, status }) =>
      status === SectionStatus.LOCKED ||
      (children && containsLockedSection(children))
  );

const containsLockedFilterSection = (sections: AvailableSection[]): boolean =>
  sections.some(
    ({ children, status }) =>
      status === SectionStatus.LOCKEDFILTER ||
      (children && containsLockedFilterSection(children))
  );

interface selectedSection {
  id: string;
  title: string;
  description: string;
  warning?: string;
  value: boolean;
  status: SectionStatus;
  children?: selectedSection[];
  disabled: boolean;
}

export const getSubscribedEmails = (
  emails: DisplayableScheduledReportRecipient[]
): string[] =>
  emails
    .filter((e) => !(e.unsubscribedAll || e.unsubscribed))
    .map((e) => e.emailAddress);

// Calculates a datetime string base on the selected options
// Note that if the schedule is earlier on the same day then it will run today
export const calculateFirstRun = (
  scheduleInterval: string,
  selectedDate: string,
  selectedTime: number,
  dayOfWeek: string
): string => {
  let date: Moment;

  // if it's in weekly mode then we need to find the next weekday
  const today = moment().day();
  if (scheduleInterval === "w") {
    const selected = moment().day(dayOfWeek).day();
    // if (selected < today) {
    //   today += 7;
    // }
    let nextDay = selected - today;
    if (nextDay < 0) {
      nextDay += 7;
    }
    date = moment().add(nextDay, "d");
  } else {
    date = moment(selectedDate);
  }

  // set the hour and minute
  date = date.hour(Math.floor(selectedTime));
  date = date.minute((selectedTime % 1) * 60);
  date = date.seconds(0);

  // convert to UTC for storage in the db
  return date.utc().format();
};

interface IScheduleFrequencyPickerProps {
  interval: string;
  onChangeInterval: (i: string) => void;
  dayOfWeek: string;
  onChangeDayOfWeek: (s: string) => void;
  date: string;
  onChangeDate: (s: string) => void;
  time: number;
  onChangeTime: (s: number) => void;
}

export class ScheduleFrequencyPicker extends React.PureComponent<IScheduleFrequencyPickerProps> {
  render() {
    const intervalOptions: OptionType[] = [
      { value: "w", label: "Weekly" },
      { value: "m", label: "Monthly" },
      { value: "q", label: "Quarterly" },
      { value: "y", label: "Yearly" },
    ];

    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const formatTime = (time: number) => {
      const suffix = time < 12 ? "AM" : "PM";
      if (time >= 13) {
        time -= 12;
      } else if (time === 0) {
        time = 12;
      }
      const prefix = `${Math.floor(time)}`.padStart(2, "0");
      const middle = `${(time % 1) * 60}`.padStart(2, "0");

      return `${prefix}:${middle} ${suffix}`;
    };

    // Generate an array with all the values for half hourly times
    const times = Array.from({ length: 48 }, (_, k) => ({
      value: k / 2,
      label: formatTime(k / 2),
    }));

    return (
      <>
        <SelectV2
          className={"interval-select"}
          options={intervalOptions}
          value={intervalOptions.find(
            ({ value }) => value === this.props.interval
          )}
          onChange={(selected) => {
            if (selected) {
              this.props.onChangeInterval(selected.value as string);
            }
          }}
          menuPosition="fixed"
        />
        <div className={"date-time-select"}>
          <p>starting on</p>
          <div className={"date-time-select-sub"}>
            <div className={"date-day-select"}>
              {this.props.interval === "w" ? (
                <SelectV2
                  className={"dayofweek-select"}
                  options={daysOfWeek.map((day) => ({
                    value: day,
                    label: day,
                  }))}
                  value={
                    daysOfWeek
                      .filter((day) => day === this.props.dayOfWeek)
                      .map((day) => ({
                        value: day,
                        label: day,
                      }))[0]
                  }
                  onChange={(selected) => {
                    if (selected) {
                      this.props.onChangeDayOfWeek(selected.value as string);
                    }
                  }}
                  menuPosition="fixed"
                />
              ) : (
                <DatePicker
                  onChange={(evt) => this.props.onChangeDate(evt.target.value)}
                  value={this.props.date}
                  min={moment().format("YYYY-MM-DD")}
                />
              )}
            </div>
            <p>at</p>
            <div className={"time-select-div"}>
              <SelectV2
                className={"time-select"}
                options={times}
                onChange={(selected) => {
                  if (selected) {
                    this.props.onChangeTime(selected.value as number);
                  }
                }}
                menuPosition="fixed"
                value={times.find((value) => value.value === this.props.time)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export interface IExportReportDeliveryProps {
  orgUserEmailAddresses: {
    loading: boolean;
    data?: UserEmailAddress[];
  };
  emailRecipients: DisplayableScheduledReportRecipient[];
  onSelectEmail: (selectedOptions: ValueType<OptionType, true>) => void;
  emailReport: boolean;
  onEmailReportChange: (shouldEmail: boolean) => void;
  onRemoveEmail: (email: DisplayableScheduledReportRecipient) => void;
  scheduleExport: boolean;
}

export class ExportReportDelivery extends React.PureComponent<IExportReportDeliveryProps> {
  render() {
    const selectedEmails = this.props.emailRecipients.map((e) =>
      e.unsubscribed || e.unsubscribedAll ? (
        <SidePopupV2
          className={"unsubscribed popup"}
          key={e.emailAddress}
          text={
            <>
              <div>This recipient has unsubscribed</div>
              <br />
              <div>
                {`${e.emailAddress} has unsubscribed from `}
                {e.unsubscribedAll ? `all reports ` : `these emails `}
                {`and will not receive this reports export until they resubscribe.`}
              </div>
            </>
          }
        >
          <PillLabel
            key={e.emailAddress}
            color={LabelColor.Red}
            removeable
            large
            constrained
            onRemoveClick={() => this.props.onRemoveEmail(e)}
          >
            {e.emailAddress}
          </PillLabel>
        </SidePopupV2>
      ) : (
        <PillLabel
          key={e.emailAddress}
          color={LabelColor.Blue}
          removeable
          large
          constrained
          onRemoveClick={() => this.props.onRemoveEmail(e)}
        >
          {e.emailAddress}
        </PillLabel>
      )
    );

    return (
      <>
        <div className={"left grid-section"}>
          <h3>Report delivery</h3>
          <p>
            How would you like to receive this{" "}
            {this.props.scheduleExport && "recurring "}report?
          </p>
        </div>
        <div className={"right grid-section"}>
          <ColorCheckbox
            radio
            checked={this.props.emailReport}
            onClick={() => this.props.onEmailReportChange(true)}
            label={"Send report via email and save to reports"}
          />
          <ColorCheckbox
            radio
            checked={!this.props.emailReport}
            onClick={() => this.props.onEmailReportChange(false)}
            label={"Save to reports only"}
          />
        </div>
        {this.props.emailReport && (
          <>
            <div className={"left grid-section"}>
              <h3>Email recipients</h3>
              <p>Type to search or add in a new email recipient.</p>
            </div>
            <div className={"right grid-section"}>
              <div className={"label-list"}> {selectedEmails} </div>
              <CreatableV2
                className={"email-select"}
                isLoading={this.props.orgUserEmailAddresses.loading}
                options={this.props.orgUserEmailAddresses.data?.map((e) => ({
                  label: e.emailAddress,
                  value: e.emailAddress,
                }))}
                value={this.props.emailRecipients.map((e) => ({
                  value: e.emailAddress,
                  label: e.emailAddress,
                }))}
                isClearable
                isMulti
                isSearchable
                controlShouldRenderValue={false}
                onChange={this.props.onSelectEmail}
                isValidNewOption={validateEmail}
                placeholder={"Type to search or enter an email address"}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

interface IExportReportModalOwnProps {
  vendorId?: number;
  isSubsidiary?: boolean;
  className?: string;
  title: string;
  bannerText?: string;
  exportType: ExportType;
  supportedFilters: FilterTypes[];
  supportedFileTypes: Set<ExportFiletype>;
  active: boolean;
  onClose: () => void;
  children?: JSX.Element | JSX.Element[];
  customFilterSection?: JSX.Element;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  exportOptions?: Record<string, any>;
  shouldFetchExportSections?: boolean;
  overrideFilters?: Partial<Filters>;
  exportDisabled?: boolean;
  isFilterActive?: boolean;
  hideFormatSelectorForSingleFormatExport?: boolean;
  eventTrackingOptions?: Record<string, never>;
  onApplyFiltersChange?: (newState: boolean) => void;
  isIncludingSubsidiaries?: boolean;
  isOnlySubsidiaries?: boolean;
  disableScheduledExport?: boolean;
  exportTypeSelected?: (selected: ExportFiletype) => void;
  fieldRetentionActive?: boolean;
  selectedFields?: string[];
}

interface IExportReportModalConnectedProps {
  isFilterActive: boolean;
  isSinglePortfolioFilterActive: boolean;
  unfilteredExportSections: ExportSection;
  filteredExportSections: ExportSection;
  orgUserEmailAddresses: {
    loading: boolean;
    data?: UserEmailAddress[];
  };
  userEmailAddress: string;
}

type IExportReportModalProps = IExportReportModalOwnProps &
  IExportReportModalConnectedProps &
  DefaultThunkDispatchProp;

const ExportReportModal: React.FC<IExportReportModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const [selectedExportType, setSelectedExportType] = useState(
    props.supportedFileTypes.has(ExportFiletype.PDF)
      ? ExportFiletype.PDF
      : ExportFiletype.XLSX
  );
  const [applyFilters, _setApplyFilters] = useState(
    props.isFilterActive || props.isSinglePortfolioFilterActive
  );
  const [retainFields, setRetainFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [useScheduleExport, setUseScheduledExport] = useState(false);
  const [emailReport, setEmailReport] = useState(false);
  const [scheduleInterval, setScheduleIntival] = useState("m");
  const [dayOfWeek, setDayOfWeek] = useState("Monday");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState(9);
  const [emailRecipients, setEmailRecipients] = useState<
    DisplayableScheduledReportRecipient[]
  >([
    {
      // Create a fake email recipient that is unsubscribed
      emailAddress: props.userEmailAddress,
      unsubscribed: false,
      unsubscribedAll: false,
    },
  ]);
  const [exportDisabled, setExportDisabled] = useState(false);

  const [selectedSections, setSelectedSections] = useState<selectedSection[]>(
    []
  );
  // if our useFilter state changes we need to reset the selected sections
  useEffect(() => {
    const sectionsData = applyFilters
      ? props.filteredExportSections
      : props.unfilteredExportSections;
    const sections =
      selectedExportType == ExportFiletype.PDF
        ? sectionsData.data?.sectionsByFiletype?.pdf
        : sectionsData.data?.sectionsByFiletype?.xlsx;
    if (sections) {
      setSelectedSections(
        sections.map((s) => ({
          id: s.id,
          title: s.title,
          description: s.description,
          warning: s.warning,
          status: s.status,
          value:
            s.status == SectionStatus.REQUIRED ||
            s.status == SectionStatus.DEFAULT ||
            s.status === SectionStatus.ONEREQUIRED,
          disabled:
            s.status == SectionStatus.LOCKED ||
            s.status === SectionStatus.LOCKEDFILTER ||
            s.status == SectionStatus.REQUIRED,
          children: s.children?.map((c) => ({
            id: c.id,
            title: c.title,
            description: c.description,
            warning: c.warning,
            status: s.status,
            value:
              c.status == SectionStatus.REQUIRED ||
              c.status == SectionStatus.DEFAULT,
            disabled:
              c.status == SectionStatus.LOCKED ||
              s.status === SectionStatus.LOCKEDFILTER ||
              c.status == SectionStatus.REQUIRED,
          })),
        }))
      );
    }
  }, [
    applyFilters,
    props.filteredExportSections,
    props.unfilteredExportSections,
    selectedExportType,
  ]);

  // Report the selected export type to the provided func on mount and whenever it changes
  useEffect(() => {
    if (props.exportTypeSelected) {
      props.exportTypeSelected(selectedExportType);
    }
  }, [
    selectedExportType,
    // Purposely not including props.exportTypeSelected as its signature is likely to change often
  ]);

  const onCheckSelection = (parentId: string, childId?: string) => {
    setSelectedSections((prev) =>
      prev.map((s) => ({
        ...s,
        value: s.id == parentId && !childId ? !s.value : s.value,
        children: s.children?.map((c) => ({
          ...c,
          value: s.id == parentId && c.id == childId ? !c.value : c.value,
        })),
      }))
    );
  };

  useEffect(() => {
    if (props.active) {
      dispatch(fetchOrgUserEmailAddresses());
    }
  }, [props.active]);

  const fetchSections = useCallback(
    (applyFilters: boolean, overrideFilters: Partial<Filters> | undefined) => {
      setExportDisabled(true);
      dispatch(
        fetchExportSections({
          exportType: props.exportType,
          exportOptions: props.exportOptions ?? {},
          applyFilters,
          overrideFilters,
          vendorId: props.vendorId,
          isSubsidiary: props.isSubsidiary,
        })
      )
        .then(() => {
          setExportDisabled(false);
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error loading available report options",
              ["Please try refreshing the page."]
            )
          );
          setExportDisabled(false);
        });
    },
    [
      dispatch,
      props.exportOptions,
      props.exportOptions,
      props.vendorId,
      props.isSubsidiary,
      setExportDisabled,
    ]
  );

  useEffect(() => {
    if (props.active && props.shouldFetchExportSections) {
      fetchSections(false, {});
      if (props.isFilterActive) {
        fetchSections(true, props.overrideFilters);
      }
    }
  }, [props.shouldFetchExportSections, props.isFilterActive, props.active]);

  useEffect(() => {
    _setApplyFilters(
      props.isFilterActive || props.isSinglePortfolioFilterActive
    );
  }, [props.isFilterActive, props.isSinglePortfolioFilterActive]);

  useEffect(() => {
    if (props.disableScheduledExport) {
      setUseScheduledExport(false);
    }
  }, [props.disableScheduledExport]);

  const onScheduleExport = () => {
    setLoading(true);

    const { overrideFilters, isIncludingSubsidiaries, isOnlySubsidiaries } =
      props;

    const exportSections: Section[] = selectedSections.map(
      ({ id, value, children }) => ({
        ID: id,
        Value: value,
        Children: (children ?? []).map(({ id, value }) => ({
          ID: id,
          Value: value,
          Children: [],
        })),
      })
    );

    const firstRun = calculateFirstRun(
      scheduleInterval,
      selectedDate,
      selectedTime,
      dayOfWeek
    );

    const exportPromise: Promise<void> =
      props.exportType === ExportType.ExecSummaryReports
        ? dispatch(
            scheduleExecSummaryReport(
              props.exportOptions ?? {},
              applyFilters,
              firstRun,
              scheduleInterval,
              1,
              emailReport ? getSubscribedEmails(emailRecipients) : [],
              undefined,
              isIncludingSubsidiaries,
              isOnlySubsidiaries
            )
          )
        : dispatch(
            scheduleExport({
              fileType: selectedExportType,
              applyFilters,
              overrideFilters,
              sections: exportSections,
              first_run: firstRun,
              interval: scheduleInterval,
              period: 1,
              exportOptions: props.exportOptions ?? {},
              exportType: props.exportType,
              emailRecipients: emailReport
                ? getSubscribedEmails(emailRecipients)
                : [],
              vendorId: props.vendorId,
              isSubsidiary: props.isSubsidiary,
              selectedFields: retainFields ? props.selectedFields : undefined,
            })
          );

    Promise.resolve(exportPromise)
      .then(() => {
        trackEvent("ExportReport", {
          ...props.exportOptions,
          ...(props.eventTrackingOptions || {}),
          exportType: props.exportType,
          fileType: selectedExportType,
          scheduled: true,
        });

        dispatch(
          addDefaultSuccessAlert("Your export has been scheduled", [
            "Please refer to the Reports section on the left navigation for more details.",
          ])
        );
      })
      .then(props.onClose)
      .then(() => dispatch(getScheduledExports()))
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert("Error requesting scheduled export")
        );
      })
      .finally(() => setLoading(false));
  };

  const onExport = () => {
    setLoading(true);

    const { overrideFilters } = props;

    const exportSections: Section[] = selectedSections.map(
      ({ id, value, children }) => ({
        ID: id,
        Value: value,
        Children: (children ?? []).map(({ id, value }) => ({
          ID: id,
          Value: value,
          Children: [],
        })),
      })
    );

    const exportPromise: Promise<any> =
      props.exportType === ExportType.ExecSummaryReports
        ? props.dispatch(
            requestExecSummaryPDFExport(
              props.exportOptions,
              applyFilters,
              emailReport ? getSubscribedEmails(emailRecipients) : [],
              props.isIncludingSubsidiaries,
              props.isOnlySubsidiaries
            )
          )
        : dispatch(
            exportReport({
              exportType: props.exportType,
              fileType: selectedExportType,
              vendorId: props.vendorId,
              isSubsidiary: props.isSubsidiary,
              exportOptions: props.exportOptions ?? {},
              applyFilters,
              overrideFilters,
              sections: exportSections,
              emailRecipients: emailReport
                ? getSubscribedEmails(emailRecipients)
                : [],
              selectedFields: retainFields ? props.selectedFields : undefined,
            })
          );

    Promise.resolve(exportPromise)
      .then(() => {
        trackEvent("ExportReport", {
          ...props.exportOptions,
          ...(props.eventTrackingOptions || {}),
          exportType: props.exportType,
          fileType: selectedExportType,
          scheduled: false,
        });

        dispatch(
          addDefaultSuccessAlert("Your export has been queued for processing", [
            "Please refer to the Reports section on the left navigation for updates on its status.",
          ])
        );
      })
      .then(props.onClose)
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error requesting export"));
      })
      .finally(() => setLoading(false));
  };

  const onSelectEmail = (selectedOptions: ValueType<OptionType, true>) => {
    if (selectedOptions) {
      setEmailRecipients(
        selectedOptions.map((o) => ({
          emailAddress: o.value as string,
          unsubscribedAll: false,
          unsubscribed: false,
        }))
      );
    } else {
      setEmailRecipients([]);
    }
  };

  const submit = async (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.preventDefault();

    if (useScheduleExport) {
      onScheduleExport();
    } else {
      onExport();
    }
  };

  const setApplyFilters = (newState: boolean) => {
    _setApplyFilters(newState);
    if (props.onApplyFiltersChange) props.onApplyFiltersChange(newState);
  };

  const {
    filteredExportSections,
    unfilteredExportSections,
    shouldFetchExportSections,
    supportedFilters,
    children,
  } = props;

  const availableSections = applyFilters
    ? filteredExportSections
    : unfilteredExportSections;

  const hasSelectableSections =
    shouldFetchExportSections &&
    (availableSections.loading ||
      availableSections.error ||
      (availableSections.data &&
        availableSections.data.sectionsByFiletype?.[selectedExportType]));

  // Disable button if any section marked "ONEREQUIRED" is available, but none are selected.
  const requiredSectionNotSelected =
    hasSelectableSections &&
    !!availableSections.data?.sectionsByFiletype?.[selectedExportType]?.find(
      (s) => s.status == SectionStatus.ONEREQUIRED
    ) &&
    !selectedSections.find(
      (s) => s.status == SectionStatus.ONEREQUIRED && s.value
    );

  const perms = usePermissions();
  const hasEditableReports = perms.canAccessEditableReports;

  const pdfSupported = props.supportedFileTypes.has(ExportFiletype.PDF);
  const xlsxSupported = props.supportedFileTypes.has(ExportFiletype.XLSX);
  const docxSupported = props.supportedFileTypes.has(ExportFiletype.DOCX);

  const showFormatSelector =
    !props.hideFormatSelectorForSingleFormatExport ||
    props.supportedFileTypes.size > 1;

  return (
    <Modal
      className={"export-report-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={props.title}
      footerContent={
        <>
          {/*{TODO - add reset button }*/}
          <Button tertiary onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            onClick={submit}
            disabled={
              !!props.exportDisabled ||
              exportDisabled ||
              requiredSectionNotSelected
            }
            loading={loading}
            primary
          >
            <div className={"cr-icon-export"} />
            {" Export"}
          </Button>
        </>
      }
    >
      <>
        {props.bannerText && (
          <div className={"banner"}>
            <div className={"cr-icon-info"} />
            <div className={"text"}>{props.bannerText}</div>
          </div>
        )}
        <div className={"form-grid"}>
          {props.isFilterActive && (
            <>
              <div className={"left grid-section"}>
                <h3>Include filters</h3>
                <p>Would you like to include filters applied in the export?</p>
              </div>
              <div className={"right grid-section"}>
                <ColorCheckbox
                  radio
                  checked={applyFilters}
                  onClick={() => setApplyFilters(true)}
                  label={"Yes, include filters"}
                />
                <ColorCheckbox
                  radio
                  checked={!applyFilters}
                  onClick={() => setApplyFilters(false)}
                  label={"No, do not include filters"}
                />
              </div>
            </>
          )}
          {props.customFilterSection && <>{props.customFilterSection}</>}
          {showFormatSelector && (
            <>
              <div className={"left grid-section"}>
                <h3>Format</h3>
                <p>Select the export format</p>
              </div>
              <div className={"right grid-section export-formats"}>
                <div
                  className={classnames({
                    active: selectedExportType === ExportFiletype.PDF,
                    inactive: selectedExportType !== ExportFiletype.PDF,
                    disabled: !pdfSupported,
                  })}
                  onClick={
                    pdfSupported
                      ? () => setSelectedExportType(ExportFiletype.PDF)
                      : undefined
                  }
                >
                  <img className="active-img" src={FileTypeIconPDF} alt="pdf" />
                  <img
                    className="inactive-img"
                    src={FileTypeIconPDF}
                    alt="pdf"
                  />
                  Export PDF
                </div>
                <div
                  className={classnames({
                    active: selectedExportType === ExportFiletype.XLSX,
                    inactive: selectedExportType !== ExportFiletype.XLSX,
                    disabled: !xlsxSupported,
                  })}
                  onClick={
                    xlsxSupported
                      ? () => setSelectedExportType(ExportFiletype.XLSX)
                      : undefined
                  }
                >
                  <img
                    className="active-img"
                    src={FileTypeIconExcel}
                    alt="excel"
                  />
                  <img
                    className="inactive-img"
                    src={FileTypeIconExcel}
                    alt="excel"
                  />
                  Export Excel
                </div>
                {hasEditableReports && docxSupported && (
                  <div
                    className={classnames({
                      active: selectedExportType === ExportFiletype.DOCX,
                      inactive: selectedExportType !== ExportFiletype.DOCX,
                      disabled: !docxSupported,
                    })}
                    onClick={
                      docxSupported
                        ? () => setSelectedExportType(ExportFiletype.DOCX)
                        : undefined
                    }
                  >
                    <img
                      className="active-img"
                      src={FileTypeIconWord}
                      alt="word"
                    />
                    <img
                      className="inactive-img"
                      src={FileTypeIconWord}
                      alt="word"
                    />
                    Export Word
                  </div>
                )}
              </div>
            </>
          )}
          {props.fieldRetentionActive &&
            selectedExportType == ExportFiletype.XLSX && (
              <>
                <div className={"left grid-section"}>
                  <h3>Retain field selection</h3>
                  <p>
                    Would you like to include only selected fields in the
                    export?
                  </p>
                </div>
                <div className={"right grid-section"}>
                  <ColorCheckbox
                    radio
                    checked={retainFields}
                    onClick={() => setRetainFields(true)}
                    label={"Yes, retain field selection"}
                  />
                  <ColorCheckbox
                    radio
                    checked={!retainFields}
                    onClick={() => setRetainFields(false)}
                    label={"No, include all fields"}
                  />
                </div>
              </>
            )}
          {children}
          {hasSelectableSections && (
            <>
              <div className={"left grid-section"}>
                <h3>Report Sections</h3>
                <p>
                  Select the report sections you want to include in the report
                </p>
              </div>
              <div className={"right grid-section"}>
                {availableSections.loading ? (
                  <LoadingBanner tight />
                ) : (
                  <>
                    {availableSections.error && (
                      <InfoBanner
                        type={BannerType.ERROR}
                        message={
                          "An error occurred while fetching available report sections."
                        }
                      />
                    )}
                    {!availableSections.error && (
                      <>
                        <CheckboxGroup
                          checkboxes={selectedSections}
                          onClick={onCheckSelection}
                        />
                        {availableSections.data &&
                          containsLockedSection(
                            availableSections.data.sectionsByFiletype[
                              selectedExportType
                            ]
                          ) && (
                            <InfoBanner
                              message={
                                (supportedFilters || []).length > 0
                                  ? "This dataset is very large. Try filtering the data down to enable any locked sections."
                                  : "This dataset is too large to include certain sections."
                              }
                              type={BannerType.WARNING}
                            />
                          )}
                        {availableSections.data &&
                          containsLockedFilterSection(
                            availableSections.data.sectionsByFiletype[
                              selectedExportType
                            ]
                          ) && (
                            <InfoBanner
                              message={
                                "One or more sections are disabled due to the current filters."
                              }
                              type={BannerType.WARNING}
                            />
                          )}
                        {selectedSections.map((s) => (
                          <React.Fragment key={s.id}>
                            {s.warning && s.value ? (
                              <InfoBanner
                                message={s.warning}
                                type={BannerType.WARNING}
                              />
                            ) : undefined}
                            {s.children?.map((s) =>
                              s.warning && s.value ? (
                                <InfoBanner
                                  key={s.id}
                                  message={s.warning}
                                  type={BannerType.WARNING}
                                />
                              ) : undefined
                            )}
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <>
            <div className={"left grid-section"}>
              <h3>Frequency</h3>
              <p>How often do you want to receive this report?</p>
            </div>
            <div className={"right grid-section"}>
              <ColorCheckbox
                radio
                checked={!useScheduleExport}
                onClick={() => setUseScheduledExport(false)}
                label={"Export report once"}
                disabled={props.disableScheduledExport}
              />
              <ColorCheckbox
                radio
                checked={useScheduleExport}
                onClick={() => setUseScheduledExport(true)}
                label={"Set up recurring report export"}
                disabled={props.disableScheduledExport}
              />
              {useScheduleExport && (
                <ScheduleFrequencyPicker
                  interval={scheduleInterval}
                  onChangeInterval={setScheduleIntival}
                  dayOfWeek={dayOfWeek}
                  onChangeDayOfWeek={setDayOfWeek}
                  date={selectedDate}
                  onChangeDate={setSelectedDate}
                  time={selectedTime}
                  onChangeTime={setSelectedTime}
                />
              )}
            </div>
            <ExportReportDelivery
              orgUserEmailAddresses={props.orgUserEmailAddresses}
              emailRecipients={emailRecipients}
              onSelectEmail={onSelectEmail}
              emailReport={emailReport}
              onEmailReportChange={setEmailReport}
              scheduleExport={useScheduleExport}
              onRemoveEmail={(email) =>
                setEmailRecipients(emailRecipients.filter((e) => e !== email))
              }
            />
          </>
        </div>
      </>
    </Modal>
  );
};

export default appConnect<
  IExportReportModalConnectedProps,
  never,
  IExportReportModalOwnProps
>((state, props) => {
  const stateFilters = getFiltersFromState(
    state,
    props.vendorId,
    props.isSubsidiary
  );

  const isSinglePortfolioFilterActive =
    (props.supportedFilters.includes(FilterTypes.VENDOR_PORTFOLIO) &&
      stateFilters.portfolioIds?.length === 1) ||
    (props.supportedFilters.includes(FilterTypes.DOMAIN_PORTFOLIO) &&
      stateFilters.domainPortfolioIds?.length === 1);

  return {
    isFilterActive:
      typeof props.isFilterActive !== "undefined"
        ? props.isFilterActive
        : isFilterActive(stateFilters, props.supportedFilters) ||
          isSinglePortfolioFilterActive,
    isSinglePortfolioFilterActive,
    unfilteredExportSections: getExportSections(
      state,
      props.exportType,
      false,
      props.exportOptions,
      props.vendorId,
      props.isSubsidiary,
      props.isOnlySubsidiaries,
      props.overrideFilters
    ),
    filteredExportSections: getExportSections(
      state,
      props.exportType,
      true,
      props.exportOptions,
      props.vendorId,
      props.isSubsidiary,
      props.isOnlySubsidiaries,
      props.overrideFilters
    ),
    supportedFileTypes: props.supportedFileTypes || ExportFiletypesBoth,
    orgUserEmailAddresses: state.cyberRisk.orgUserEmailAddresses,
    userEmailAddress: state.common.userData.emailAddress,
  };
})(ExportReportModal);

import { useEffect, useState } from "react";

import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import ReportCard from "../../../_common/components/ReportCard";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import moment from "moment/moment";

import "../../style/components/SharedProfileAccessLog.scss";
import { UserAvatarAndName } from "../../../_common/components/UserAvatar";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import { appConnect } from "../../../_common/types/reduxHooks";
import trustPageApi from "../../../verifiedvendors/api/trustpage.api";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { useDispatch } from "react-redux";
import { IMySharedAssessmentAccessLog } from "../../types/sharedAssessment";

type ISharedProfileAccessLogOwnProps = unknown;

interface ISharedProfileAccessLogConnectedProps {}

type ISharedProfileAccessLogProps = ISharedProfileAccessLogOwnProps &
  ISharedProfileAccessLogConnectedProps &
  DefaultThunkDispatchProp;

const columnHeaders: IXTableColumnHeader[] = [
  { id: "name", text: "Name", sortable: false },
  { id: "email", text: "Email", sortable: false },
  { id: "accessed", text: "Accessed", sortable: false },
];

const SharedProfileAccessLog = ({}: ISharedProfileAccessLogProps) => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const limit = 40;

  const [loadAccessLog, { isFetching, isError }] =
    trustPageApi.useLazyGetOwnSharedAssessmentAccessLogV1Query();
  const [accessLog, setAccessLog] = useState<IMySharedAssessmentAccessLog>();

  useEffect(() => {
    if (isError) {
      dispatch(addDefaultUnknownErrorAlert("Error getting access log"));
    }
  }, [isError]);

  const handleLoadAccessLog = () => {
    loadAccessLog({
      offset,
      limit,
    })
      .unwrap()
      .then((response) => {
        const usersById = { ...accessLog?.usersById };
        const logItems = accessLog ? [...accessLog?.accessLog] : [];

        setAccessLog({
          accessLog: logItems.concat(response.accessLog),
          usersById: { ...usersById, ...response.usersById },
          more: response.more,
        });
        setOffset(offset + response.accessLog.length + 1);
      })
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error getting access log"));
      });
  };

  useEffect(() => {
    if (!accessLog) {
      handleLoadAccessLog();
    }
  }, [isError, accessLog]);

  const fetchMoreAccessLog = () => {
    handleLoadAccessLog();
  };

  const rows: IXTableRow[] = [];

  if (accessLog) {
    for (let i = 0; i < accessLog.accessLog.length; i++) {
      const log = accessLog.accessLog[i];
      const user = accessLog.usersById[log.userId];
      if (!user) {
        continue;
      }

      rows.push({
        id: `${log.createdAt}${log.userId}`,
        cells: [
          <XTableCell key="name">
            <UserAvatarAndName avatar={user.avatar} name={user.name} />
          </XTableCell>,
          <XTableCell key="email">{user.email}</XTableCell>,
          <XTableCell key="accessed">
            {moment(log.createdAt).format("LLL")}
          </XTableCell>,
        ],
      });
    }

    if (accessLog.more || isFetching) {
      rows.push({
        id: "more",
        cells: [
          <XTableCell
            key="more"
            colSpan={3}
            className="load-more-cell"
            onClick={fetchMoreAccessLog}
          >
            Load more
          </XTableCell>,
        ],
      });
    }
  }

  return (
    <ReportCard newStyles className="shared-profile-access-log">
      <div className="header">Access log</div>
      {!accessLog || rows.length > 0 ? (
        <XTable
          loading={!accessLog || isFetching}
          columnHeaders={columnHeaders}
          sortedBy={{
            columnId: "accessed",
            direction: SortDirection.DESC,
          }}
          rows={rows}
        />
      ) : (
        <EmptyCardWithAction
          emptyText="No logs"
          emptySubText="There are no access logs for your Trust Page yet."
        />
      )}
    </ReportCard>
  );
};

const connector = appConnect<
  ISharedProfileAccessLogConnectedProps,
  never,
  ISharedProfileAccessLogOwnProps
>((_) => {
  return {};
});

export default connector(SharedProfileAccessLog);

import { FC, useEffect, useState } from "react";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import TextField from "../../_common/components/TextField";
import "../style/components/RiskSummaryComment.scss";
import classnames from "classnames";

interface RiskSummaryCommentProps {
  comment: string;
  title: string;
  readonly: boolean;
  readOnlyClassname?: string;
  onUpdateComment?: (val: string) => void;
  hideSideContent?: boolean;
}

const RiskSummaryComment: FC<RiskSummaryCommentProps> = ({
  comment: orignalComment,
  title,
  onUpdateComment,
  readonly,
  readOnlyClassname,
  hideSideContent = false,
}) => {
  const [comment, setComment] = useState(orignalComment);
  // only update our comment from props if we are in read only
  useEffect(() => {
    if (orignalComment != comment && readonly) {
      setComment(orignalComment);
    }
  }, [orignalComment, comment, readonly]);

  if (readonly) {
    return (
      <div
        className={classnames(
          "risk-assessment-comment-readonly",
          readOnlyClassname
        )}
      >
        <div className={"sub-title"}>{title}</div>
        <div className={"comment"}>{comment}</div>
      </div>
    );
  } else {
    return (
      <div className={"risk-assessment-comment"}>
        {!hideSideContent && (
          <div className={"right-side"}>
            <div className={"sub-title"}>{title}</div>
            <SidePopupV2
              text={"These comments will only be shown in this assessment"}
              position={"bottom"}
            >
              <i className={"cr-icon-info"} />
            </SidePopupV2>
          </div>
        )}
        <div className={"text-entry"}>
          <TextField
            value={comment}
            onChanged={(val) => {
              setComment(val);
              if (onUpdateComment) {
                onUpdateComment(val);
              }
            }}
            multiLine
            placeholder={
              "Leave your comments here on any compensating controls or things to consider as part of this assessment"
            }
          />
        </div>
      </div>
    );
  }
};

export default RiskSummaryComment;

import { DefaultThunkDispatch } from "../../../_common/types/redux";
import {
  ISharedAssessment,
  IVerifiedVendorAccessItemNda,
  SharedAssessmentAccessResult,
} from "../../types/sharedAssessment";
import { useState } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";

import "../../style/components/SharedProfile.scss";
import { History } from "history";
import SharedProfileQuestionnairesTable from "./SharedProfileQuestionnairesTable";
import EditSharedProfileMetadataModal from "./EditSharedProfileMetadataModal";
import SharedProfileSupportingDocumentationTable from "./SharedProfileSupportingDocumentationTable";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import CircledIcon from "../../../_common/components/CircledIcon";
import RequestVerifiedVendorAccessModal from "../../../_common/components/modals/RequestVerifiedVendorAccessModal";
import { ISurveyListItemResponse } from "../../../_common/types/survey";
import AddQuestionnaireToSharedProfileModal from "./AddQuestionnaireToSharedProfileModal";
import { surveyType } from "../../../_common/types/surveyTypes";
import { SharedProfileAboutCard } from "./SharedProfileAboutCard";
import NdaSigningModal from "./NdaSigningModal";
import NdaSignedModal from "./NdaSignedModal";
import SharedProfileEvidencePagesTable from "./SharedProfileEvidencePagesTable";
import SharedProfileEditEvidenceModal from "./SharedProfileEditEvidenceModal";
import { useAddDocumentToSharedProfile } from "../../../verifiedvendors/hooks/useAddDocumentToSharedProfile";

interface ISharedProfileProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  isOwnSharedProfile: boolean;
  userHasWritePrefilledSurveysPermission: boolean;
  sharedAssessment: ISharedAssessment;
  uuid?: string;
  receivedSurveys?: ISurveyListItemResponse[]; // Only needed if isOwnSharedProfile is true
  surveyTypes?: surveyType[];
  disableAccessRequests?: boolean;
  userEmailAddress?: string;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
}

export const SharedProfile = ({
  dispatch,
  history,
  isOwnSharedProfile,
  userHasWritePrefilledSurveysPermission,
  sharedAssessment,
  uuid,
  receivedSurveys = [],
  surveyTypes = [],
  disableAccessRequests = false,
  userEmailAddress = "",
  isManagementAnalystSession,
  managedOrgId,
}: ISharedProfileProps) => {
  const [editMetadataModalOpen, setEditMetadataModalOpen] = useState(false);
  const [addQuestionnaireModalOpen, setAddQuestionnaireModalOpen] =
    useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [isShowingNdaModal, setIsShowingNdaModal] = useState(false);
  const [signedNda, setSignedNda] = useState<
    IVerifiedVendorAccessItemNda | undefined
  >(undefined);
  const [isShowingSignedNdaModal, setIsShowingSignedNdaModal] = useState(false);
  const [isShowingEditEvidenceModalOpen, setIsShowingEditEvidenceModalOpen] =
    useState(false);

  const addDocument = useAddDocumentToSharedProfile();

  if (
    !isOwnSharedProfile &&
    sharedAssessment.status === SharedAssessmentAccessResult.NotPublished
  ) {
    return (
      <div className="shared-profile">
        <ReportCard newStyles>
          <EmptyCardWithAction
            emptyText="Not published"
            emptySubText="This company does not currently publish a Trust Page."
          />
        </ReportCard>
      </div>
    );
  }

  let topCard;

  const openRequestModal = () => {
    setRequestModalOpen(true);
  };

  if (!isOwnSharedProfile) {
    const canUpgradeOrgLevelAccess =
      sharedAssessment.status === SharedAssessmentAccessResult.Success &&
      (sharedAssessment.orgLevelStatus ===
        SharedAssessmentAccessResult.MustRequest ||
        sharedAssessment.orgLevelStatus ===
          SharedAssessmentAccessResult.NdaRequired);

    if (
      sharedAssessment.status === SharedAssessmentAccessResult.PendingRequest
    ) {
      topCard = (
        <ReportCard newStyles>
          <EmptyCardWithAction
            iconJSX={<CircledIcon iconClass="cr-icon-lock" />}
            emptyText="Access pending"
            emptySubText="You will be notified when access to this Trust Page is granted."
            onActionClick={
              sharedAssessment.canReRequestAccess && !disableAccessRequests
                ? openRequestModal
                : undefined
            }
            actionButtonText={
              sharedAssessment.canReRequestAccess && !disableAccessRequests
                ? "Request access again"
                : ""
            }
          />
        </ReportCard>
      );
    } else if (
      sharedAssessment.status === SharedAssessmentAccessResult.MustRequest ||
      (canUpgradeOrgLevelAccess &&
        sharedAssessment.orgLevelStatus ===
          SharedAssessmentAccessResult.MustRequest)
    ) {
      topCard = (
        <ReportCard newStyles>
          <EmptyCardWithAction
            iconJSX={<CircledIcon iconClass="cr-icon-lock" />}
            emptyText={`${sharedAssessment.linkedVendor.name}'s Trust Page is private`}
            emptySubText={
              !disableAccessRequests
                ? `This Trust Page is not publicly accessible. ${
                    canUpgradeOrgLevelAccess
                      ? "You can extend access to your entire organization."
                      : "Please request access to view content."
                  }`
                : "This Trust Page is not publicly accessible."
            }
            actionButtonRenderOverride={
              !disableAccessRequests ? (
                <Button primary onClick={openRequestModal}>
                  {canUpgradeOrgLevelAccess
                    ? "Extend access"
                    : "Request access"}
                </Button>
              ) : undefined
            }
          />
        </ReportCard>
      );
    } else if (
      sharedAssessment.status === SharedAssessmentAccessResult.NdaRequired ||
      (canUpgradeOrgLevelAccess &&
        sharedAssessment.orgLevelStatus ===
          SharedAssessmentAccessResult.NdaRequired)
    ) {
      topCard = (
        <ReportCard newStyles>
          <EmptyCardWithAction
            iconJSX={<CircledIcon iconClass="cr-icon-edit-doc" />}
            emptyText={`${sharedAssessment.linkedVendor.name}'s Trust Page requires an NDA`}
            emptySubText={
              !disableAccessRequests
                ? `The owner of this profile has requested that ${
                    canUpgradeOrgLevelAccess ? "your organization" : "you"
                  } agree to an NDA before you can access the content.`
                : "This Trust Page is not publicly accessible."
            }
            actionButtonRenderOverride={
              !disableAccessRequests ? (
                <Button primary onClick={() => setIsShowingNdaModal(true)}>
                  View NDA
                </Button>
              ) : undefined
            }
          />
        </ReportCard>
      );
    }
  }

  const userCanMakeChanges =
    isOwnSharedProfile && userHasWritePrefilledSurveysPermission;
  const userCanOnlyView =
    (!isOwnSharedProfile &&
      sharedAssessment.status === SharedAssessmentAccessResult.Success) ||
    (isOwnSharedProfile && !userCanMakeChanges);
  const userHasNoAccess = !userCanMakeChanges && !userCanOnlyView;

  return (
    <div className="shared-profile">
      {topCard}
      <SharedProfileAboutCard
        sharedAssessment={sharedAssessment}
        isOwnSharedProfile={isOwnSharedProfile}
        userHasWritePrefilledSurveysPermission={
          userHasWritePrefilledSurveysPermission
        }
        onCustomiseClick={() => setEditMetadataModalOpen(true)}
      />
      {(sharedAssessment.questionnaires.length > 0 || isOwnSharedProfile) && (
        <ReportCard newStyles>
          <div className="header">
            <div className="header-with-subtext">
              <div>
                {sharedAssessment.linkedVendor.name}&apos;s security
                questionnaires
              </div>
              {isOwnSharedProfile && (
                <div className="subtext">
                  Proactively share completed questionnaires and stop answering
                  the same questions again and again.
                </div>
              )}
            </div>
            {userCanMakeChanges && (
              <Button onClick={() => setAddQuestionnaireModalOpen(true)}>
                <div className="cr-icon-plus" /> Add questionnaire
              </Button>
            )}
          </div>
          <SharedProfileQuestionnairesTable
            dispatch={dispatch}
            history={history}
            vendorName={sharedAssessment.linkedVendor.name}
            vendorId={sharedAssessment.linkedVendor.id}
            surveys={sharedAssessment.questionnaires}
            addToSharedProfileSurveys={
              sharedAssessment.addToSharedProfileQuestionnaires || []
            }
            isOwnSharedProfile={isOwnSharedProfile}
            userHasNoAccess={userHasNoAccess}
            userHasWritePrefilledSurveysPermission={
              userHasWritePrefilledSurveysPermission
            }
            receivedSurveys={receivedSurveys}
            isManagementAnalystSession={!!isManagementAnalystSession}
            managedOrgId={managedOrgId}
          />
        </ReportCard>
      )}
      {(sharedAssessment.contentLibraryDocuments.length > 0 ||
        isOwnSharedProfile) && (
        <ReportCard newStyles>
          <div className="header">
            <div className="header-with-subtext">
              <div>
                {sharedAssessment.linkedVendor.name}&apos;s supporting
                documentation
              </div>
              {isOwnSharedProfile && (
                <div className="subtext">
                  Share security-related documentation or compliance
                  certifications such as PCI DSS, SOC 2, ISO 27001, etc.
                </div>
              )}
            </div>
            {userCanMakeChanges && (
              <Button onClick={() => addDocument()}>
                <div className="cr-icon-plus" /> Add document
              </Button>
            )}
          </div>
          <SharedProfileSupportingDocumentationTable
            vendorId={sharedAssessment.linkedVendor.id}
            documents={sharedAssessment.contentLibraryDocuments}
            isOwnSharedProfile={isOwnSharedProfile}
            userHasNoAccess={userHasNoAccess}
            userHasWritePrefilledSurveysPermission={
              userHasWritePrefilledSurveysPermission
            }
          />
        </ReportCard>
      )}
      {(sharedAssessment.evidencePages?.length || isOwnSharedProfile) && (
        <ReportCard newStyles>
          <div className="header">
            <div className="header-with-subtext">
              <div>
                {sharedAssessment.linkedVendor.name}&apos;s security and privacy
                pages
              </div>
              {isOwnSharedProfile && (
                <div className="subtext">
                  Share links to any webpages relevant to those assessing your
                  organization e.g. your privacy page, terms, or certifications.
                </div>
              )}
            </div>
            {userCanMakeChanges && (
              <Button onClick={() => setIsShowingEditEvidenceModalOpen(true)}>
                <div className="cr-icon-pencil" /> Edit pages
              </Button>
            )}
          </div>
          <SharedProfileEvidencePagesTable
            evidencePages={sharedAssessment.evidencePages ?? []}
            isOwnSharedProfile={isOwnSharedProfile}
            vendorId={sharedAssessment.linkedVendor.id}
          />
        </ReportCard>
      )}
      <EditSharedProfileMetadataModal
        dispatch={dispatch}
        active={editMetadataModalOpen}
        onClose={() => setEditMetadataModalOpen(false)}
        vendorId={sharedAssessment.linkedVendor.id}
        uuid={uuid}
        contactName={sharedAssessment.contactName}
        contactEmail={sharedAssessment.contactEmail}
        companyDescription={sharedAssessment.companyDescription}
        currentScore={sharedAssessment.currentScore}
        industryAverage={sharedAssessment.industryAverage?.score}
        includeRiskRating={sharedAssessment.includeRiskRating}
        includeIndustryAverage={sharedAssessment.includeIndustryAverage}
        profileLogo={sharedAssessment.profileLogo}
        profileLogoUrl={sharedAssessment.profileLogoMeta?.logoUrl}
      />
      <AddQuestionnaireToSharedProfileModal
        dispatch={dispatch}
        active={addQuestionnaireModalOpen}
        onClose={() => setAddQuestionnaireModalOpen(false)}
        receivedSurveys={receivedSurveys}
        sharedProfileSurveys={sharedAssessment.questionnaires}
        surveyTypes={surveyTypes}
      />
      <RequestVerifiedVendorAccessModal
        vendorId={sharedAssessment.linkedVendor.id}
        vendorName={sharedAssessment.linkedVendor.name}
        dispatch={dispatch}
        active={requestModalOpen}
        onClose={() => setRequestModalOpen(false)}
        onRequested={() => setRequestModalOpen(false)}
      />
      <NdaSigningModal
        vendorName={sharedAssessment.linkedVendor.name}
        vendorId={sharedAssessment.linkedVendor.id}
        ndaId={sharedAssessment.ndaId}
        ndaText={sharedAssessment.ndaText ?? ""}
        dispatch={dispatch}
        active={isShowingNdaModal}
        onClose={() => setIsShowingNdaModal(false)}
        onAccepted={(r) => {
          setIsShowingSignedNdaModal(true);
          setIsShowingNdaModal(false);
          setSignedNda(r.signedNda);
        }}
      />
      <NdaSignedModal
        dispatch={dispatch}
        vendorId={sharedAssessment.linkedVendor.id}
        vendorName={sharedAssessment.linkedVendor.name}
        userEmailAddress={userEmailAddress}
        mustRequestAccess={
          sharedAssessment.status ===
            SharedAssessmentAccessResult.MustRequest ||
          sharedAssessment.status ===
            SharedAssessmentAccessResult.PendingRequest
        }
        signedNdaId={signedNda?.id ?? 0}
        active={isShowingSignedNdaModal}
        onClose={() => setIsShowingSignedNdaModal(false)}
      />
      <SharedProfileEditEvidenceModal
        dispatch={dispatch}
        active={isShowingEditEvidenceModalOpen}
        onClose={() => setIsShowingEditEvidenceModalOpen(false)}
        evidencePages={sharedAssessment.evidencePages ?? []}
      />
    </div>
  );
};

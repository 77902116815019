import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
} from "../../_common/components/core/XTable";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice, { FEEDTABLES_PAGESIZE } from "../Slice";
import ThreatMonitoringAPI, {
  GetResultsV1Resp,
} from "../api/threatmonitoring.api";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringFeedType,
} from "../api/types";
import { useXTablePagination } from "../funcs/usePagination";
import {} from "../views/ThreatMonitoringView";
import FilterPanel from "./FilterPanel";
import { useSelectionSet } from "../../_common/hooks";
import classnames from "classnames";
import Button from "../../_common/components/core/Button";

import AttentionIconImg from "../../vendorrisk/images/search.svg";
import ActionBar from "../../_common/components/ActionBar";
import ThreatMonitoringBulkActions from "./ThreatMonitoringBulkActions";

import "./FeedWithTable.scss";
import { FeedFailed } from "./FeedFailed";
import { useEffect, useMemo } from "react";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { IUserMini } from "../../_common/types/user";
import EllipsizedText from "../../_common/components/EllipsizedText";

export type GetRowsProps = {
  selectedItems?: Set<string>;
  queryResponse?: GetResultsV1Resp;
  actors?: IUserMini[];
};
export interface FeedWithTableProps {
  className?: string;
  feedType: ThreatMonitoringFeedType;
  hidden: boolean;
  columns: IXTableColumnHeader[];
  getRows: (
    feed: DisplayableThreatMonitoringResult[],
    props: GetRowsProps
  ) => IXTableRow<string>[];
  selectable?: boolean;
  feedFailedMessage?: string;
  feedEmptyMessage?: string;
  isLoading?: boolean;
  filterPanelDateFilterTitle?: string;
}

// possible sort options for the main feed list
export type FeedTableSortOption = {
  option: string;
  desc: boolean;
};

// default sort options for feeds tables. Must match ThreatMonitoringSortBy enum in threatmonitoring/db_results_filtering.go
export type SortableFeedColumn =
  | "date_closed"
  | "severity"
  | "date"
  | "actor"
  | "threat_type"
  | "num_comments"
  | "keyword"
  | "closed_status";

export default function FeedWithTable({
  className,
  feedType,
  hidden,
  columns,
  getRows,
  selectable,
  feedFailedMessage,
  feedEmptyMessage,
  isLoading: isLoadingExternal,
  filterPanelDateFilterTitle,
}: FeedWithTableProps) {
  const feedState = useAppSelector(
    (s) => tmSlice.selectSlice(s).feeds[feedType]
  );
  const dispatch = useAppDispatch();
  const feedQuery = feedState.query;

  const {
    data,
    isLoading,
    error,
    refetch: refetchFeedData,
  } = ThreatMonitoringAPI.useGetResultsV1Query(feedQuery, {
    skip: hidden,
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (error) {
      console.error(`#### error loading feed: ${JSON.stringify(error)}`);
      dispatch(
        addDefaultUnknownErrorAlert("Failed to load threat monitoring feed")
      );
    }
  }, [error]);

  const feed = data?.results || [];

  const pagination = useXTablePagination({
    feedType,
    feedItems: feed,
    totalResults: data?.totalResults,
    pageSize: FEEDTABLES_PAGESIZE,
  });

  const sortableColumns = useMemo(
    () =>
      columns
        .filter((c) => c.sortable)
        .reduce((acc, c) => {
          acc.add(c.id);
          return acc;
        }, new Set<string>()),
    [columns]
  );

  const [selectedItems, setItemSelected, setItemsSelected, resetItemsSelected] =
    useSelectionSet<string>();

  const selectAll = (selected: boolean) => {
    if (selected) {
      setItemsSelected(new Set(...feed.map((f) => f.uuid)));
    } else {
      resetItemsSelected();
    }
  };

  const resetFilters = () => {
    dispatch(tmSlice.actions.resetPageFiltersExcludingDateFilter(feedType));
  };

  return (
    <div className={classnames("feed-content", className)}>
      <FilterPanel
        filters={feedQuery.filters}
        feedType={feedType}
        allowAllTimeDatePeriod={true}
        hidden={hidden}
        dateFilterTitle={filterPanelDateFilterTitle}
      />
      <div
        className={classnames("tm-feed-with-table", {
          hidden: hidden,
          wide: !feedState.filtersExpanded,
        })}
      >
        {error && (
          <FeedFailed
            message={feedFailedMessage || ""}
            isLoading={isLoading}
            refetch={refetchFeedData}
          />
        )}
        {!error && !isLoading && pagination.totalResults == 0 && (
          <FeedEmpty
            message={feedEmptyMessage || ""}
            resetFilters={resetFilters}
          />
        )}
        {!error && (isLoading || pagination.totalResults > 0) && (
          <XTable
            loading={isLoading || isLoadingExternal}
            className={classnames("threat-monitoring-table", className, {
              wide: !feedState.filtersExpanded,
            })}
            numLoadingRows={6}
            selectable={selectable}
            onSelectToggle={(selected) => selectAll(selected)}
            onSelectAllClick={() => selectAll(true)}
            onSelectNoneClick={() => selectAll(false)}
            sortedBy={{
              columnId: feedQuery.pageOpts.sortBy,
              direction: feedQuery.pageOpts.sortDesc
                ? SortDirection.DESC
                : SortDirection.ASC,
            }}
            onSortChange={(col, dir) => {
              if (!sortableColumns.has(col)) {
                return;
              }
              dispatch(
                tmSlice.actions.setSortBy({
                  feed: feedType,
                  sort: {
                    option: col,
                    desc: dir === SortDirection.DESC,
                  },
                })
              );
            }}
            columnHeaders={columns}
            rows={getRows(feed, {
              selectedItems,
              queryResponse: data,
              actors: data?.actors,
            })}
            pagination={pagination}
            onSelectClick={(uuid: string) => {
              setItemSelected(uuid);
            }}
          />
        )}
      </div>
      <ActionBar active={!!(selectable && selectedItems.size > 0 && !hidden)}>
        <ThreatMonitoringBulkActions
          feedType={feedType}
          selectedUUIDs={Array.from(selectedItems)}
          clearSelection={resetItemsSelected}
        />
      </ActionBar>
    </div>
  );
}

// emptyFeed
// Renders an empty message, with an appropriate call to action, based on the current state of the feed totals
// Note that styles are shared from FeedFailed.scss
export function FeedEmpty({
  message,
  resetFilters,
}: {
  message: string;
  resetFilters: () => void;
}) {
  return (
    <div className={"threat-monitoring-empty-feed"}>
      <img src={AttentionIconImg} width={100} height={100} />
      <div className={"primary"}>No threats</div>
      <div className={"sub"}>{message}</div>
      <Button onClick={resetFilters}>
        <>
          <div className={"icon-refresh"} />
          {"Reset filters"}
        </>
      </Button>
    </div>
  );
}

export function FeedTableEllipsisedText({
  text,
  children,
}: {
  text: string;
  children: string | JSX.Element;
}) {
  return (
    <EllipsizedText popupClassName="tm-feed-table-ellipsised" text={text}>
      {children}
    </EllipsizedText>
  );
}

import { LabelColor } from "../../_common/types/label";
import PillLabel from "../../vendorrisk/components/PillLabel";

export default function KeywordsPills({ keywords }: { keywords: string[] }) {
  const pillColours = [LabelColor.Grey, LabelColor.Yellow, LabelColor.Fuchsia];
  return keywords.map((keyword, idx) => (
    <PillLabel key={keyword} color={pillColours[idx % pillColours.length]}>
      {keyword}
    </PillLabel>
  ));
}

import ModalV2 from "../../../../_common/components/ModalV2";
import Button from "../../../../_common/components/core/Button";
import "../../../style/components/automation/PublishDraftWithAutomationModal.scss";
import { IAutomationRecipe } from "../../../types/automation";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../../_common/components/core/XTable";
import ToggleSwitch from "../../../../_common/components/core/ToggleSwitch";
import { AutomationAttributeTypes } from "../../../views/EditQuestionnaireAutomation";
import { useEffect, useState } from "react";
import InfoBanner from "../../InfoBanner";

interface IPublishDraftWithAutomationModalProps {
  active: boolean;
  loading?: boolean;
  updatedAutomations?: IAutomationRecipe[];
  numberOfDeletedAutomationRules: number;
  onClose: (didPublish: boolean) => void;
  setIsPublishing: (publishing: boolean) => void;
  publishSurvey: (enableAutomationUUIDs: string[]) => Promise<boolean>;
  surveyChangesExist: boolean;
}

const PublishDraftWithAutomationModal: React.FC<
  IPublishDraftWithAutomationModalProps
> = (props) => {
  const [publishRunning, setPublishRunning] = useState(false);
  const [title, setTitle] = useState("");
  const [changesCopy, setChangesCopy] = useState("");
  const [numNew, setNumNew] = useState(0);
  const [numUpdated, setNumUpdated] = useState(0);
  const [enableList, setEnableList] = useState(
    {} as { [uuid: string]: boolean }
  );

  // Given the supplied set of updated automations, determine our initial set of enable flags, and the state of various
  // related copy
  useEffect(() => {
    if (!publishRunning) {
      const list = {
        ...enableList,
      };
      props.updatedAutomations?.map((a) => {
        list[a.uuid] = true;
      });
      setEnableList(list);

      setNumNew(
        props.updatedAutomations?.filter((a) => !a.parent)?.length ?? 0
      );
      setNumUpdated(
        props.updatedAutomations?.filter((a) => !!a.parent)?.length ?? 0
      );

      setTitle("Confirm your changes");
      setChangesCopy(
        numNew + numUpdated + props.numberOfDeletedAutomationRules > 0
          ? "Summary of changes:"
          : ""
      );
    }
    return () => {
      setEnableList({});
    };
  }, [props.updatedAutomations, props.surveyChangesExist]);

  const setEnabledDisabled = (uuid: string, enabled: boolean) => {
    const list = {
      ...enableList,
    };
    list[uuid] = enabled;
    setEnableList(list);
  };
  const getActionDescription = (a: IAutomationRecipe) => {
    switch (a?.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        return "Assign vendor tier";
        break;
      case AutomationAttributeTypes.Portfolios:
        return "Assign vendor portfolio";
        break;
      case AutomationAttributeTypes.Labels:
        return "Assign vendor label";
        break;
      case AutomationAttributeTypes.Custom:
        return `Assign vendor attribute: ${a?.name || ""}`;
        break;
    }
    return "unknown task";
  };

  const renderAutomationList = () => {
    const rows = [] as IXTableRow[];
    props.updatedAutomations?.map((a) => {
      rows.push({
        id: a.uuid,
        cells: [
          <XTableCell key={"enable"} className={"enable-td"}>
            <div className={"enable"}>
              <ToggleSwitch
                name={a.uuid}
                selected={!!enableList[a.uuid]}
                onClick={() => setEnabledDisabled(a.uuid, !enableList[a.uuid])}
              />
            </div>
          </XTableCell>,
          <XTableCell key={"title"} className={"title-td"}>
            <div className={"title"}>
              <div className={"name"}>{a.name}</div>
              <div className={"action"}>{getActionDescription(a)}</div>
            </div>
          </XTableCell>,
        ],
      });
    });
    return (
      <XTable
        columnHeaders={[
          {
            id: "active",
            text: "Active",
          },
          {
            id: "rule",
            text: "Automation rule",
          },
        ]}
        rows={rows}
      />
    );
  };

  const getEnabledUUIDs = () => {
    const enabled: string[] = [];
    Object.keys(enableList).map((k) => {
      if (enableList[k]) {
        enabled.push(k);
      }
    });
    return enabled;
  };

  return (
    <ModalV2
      className={"publish-edits-modal"}
      active={props.active}
      onClose={() => {
        props.onClose(false);
      }}
      headerContent={title}
      footerContent={
        <>
          <Button
            tertiary
            onClick={() => {
              props.onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={async () => {
              setPublishRunning(true);
              const published = await props.publishSurvey(getEnabledUUIDs());
              props.onClose(published);
              setPublishRunning(false);
            }}
            loading={publishRunning || props.loading}
          >
            Publish
          </Button>
        </>
      }
    >
      <p>{changesCopy}</p>
      <ul>
        {numNew > 0 && (
          <li>{`${numNew} automation ${
            numNew == 1 ? "rule has" : "rules have"
          } been added`}</li>
        )}
        {numUpdated > 0 && (
          <li>{`${numUpdated} automation ${
            numUpdated == 1 ? "rule has" : "rules have"
          } been updated or re-ordered`}</li>
        )}
        {props.numberOfDeletedAutomationRules > 0 && (
          <li>{`${props.numberOfDeletedAutomationRules} automation ${
            props.numberOfDeletedAutomationRules == 1
              ? "rule has"
              : "rules have"
          } been deleted`}</li>
        )}
      </ul>
      <InfoBanner message="Publishing this draft will overwrite your existing questionnaire template and automation rules will start running." />
      {numNew + numUpdated > 0 && (
        <div className={"automation-list"}>{renderAutomationList()}</div>
      )}
    </ModalV2>
  );
};

export default PublishDraftWithAutomationModal;

import "./BookSalesCall.scss";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { ChiliBooking } from "../../../registration/ChiliBooking";
import { trackEvent } from "../../../_common/tracking";

export type salesTopic = "sales" | "expand";

interface BookSalesCallModalProps extends BaseModalProps {
  firstName: string;
  lastName: string;
  countryName: string;
  email: string;
}

const BookSalesCallModal = (props: BookSalesCallModalProps) => {
  const { CHILI_PIPER_ROUTER } = window;

  const { onClose, firstName, lastName, countryName, email, active } = props;

  const handleMeetingBooked = () => {
    trackEvent("Nav_Time_Booked");
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalV2
      active={active}
      onClose={handleClose}
      width={1000}
      className="book-expert-modal"
      headerClassName="book-expert-modal-header"
      headerContent="Chat to one of our security experts"
    >
      <div className="body-content">
        During your call they&apos;ll explore your current situation to better
        understand your security needs, provide recommendations for how UpGuard
        can help you achieve your goals, and answer any questions you have about
        the platform, pricing and alternatives.
        <div className="calendar-container">
          <ChiliBooking
            firstName={firstName}
            lastName={lastName}
            countryName={countryName}
            email={email}
            bookingConfirmationTimeout={3000}
            router={CHILI_PIPER_ROUTER || ""}
            onMeetingBooked={handleMeetingBooked}
          />
        </div>
      </div>
    </ModalV2>
  );
};

export default BookSalesCallModal;

import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../_common/types/redux";
import { useEffect, useState } from "react";

import ReportCard from "../../_common/components/ReportCard";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import RootNode from "../../survey_builder/components/RootNode";
import {
  fetchSurveyTypeDraft,
  publishCurrentSurveyDraft,
  revertCurrentSurveyDraft,
  runAutosave,
  runFullDraftSave,
} from "../../survey_builder/reducers/apiActions";

import Autosave from "../../survey_builder/components/Autosave";
import Button from "../../_common/components/core/Button";
import { IUserMini } from "../../_common/types/user";
import classnames from "classnames";
import TemplateSettingsModal from "../../survey_builder/components/TemplateSettingsModal";
import { SurveyFramework } from "../../survey_builder/types/frameworks";
import QuestionnaireBuilderHelpGuide from "../../survey_builder/components/QuestionnaireBuilderHelpGuide";
import { ValidationToolbar } from "../../survey_builder/components/ValidationToolbar";
import ModalV2 from "../../_common/components/ModalV2";
import EditRiskModal from "../../survey_builder/components/EditRiskModal";
import { SectionNode } from "../../survey_builder/types/types";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { fetchSurveyTypes } from "../../vendorrisk/reducers/cyberRiskActions";
import "../style/SurveyBuilder.scss";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import ListQuestionnaireAutomation from "../../vendorrisk/components/automation/ListQuestionnaireAutomation";
import {
  createNewAutomationRecipe,
  fetchAutomationForSurveyType,
  fetchQuestionsForSurveyType,
  revertCurrentAutomationDrafts,
} from "../../vendorrisk/reducers/questionnaireAutomation.actions";
import { WriteQuestionnaireAutomation } from "../../_common/permissions";
import { IAutomationRecipe } from "../../vendorrisk/types/automation";
import { Location, History } from "history";
import { locationState } from "../../_common/types/router";
import PublishDraftWithAutomationModal from "../../vendorrisk/components/automation/modals/PublishDraftWithAutomationModal";
import ActionBar from "../../_common/components/ActionBar";
import { appConnect } from "../../_common/types/reduxHooks";

interface IPublishSurveyModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: (didPublish?: boolean) => void;
  surveyTypeId: number;
  surveyUsageType: SurveyUsageType;
  title?: string;
  text?: string;
  setIsPublishing: (publishing: boolean) => void;
  confirmButtonText?: string;
  supportsAutomation?: boolean;
}

const publishSurvey = async (
  dispatch: DefaultThunkDispatch,
  surveyTypeId: number,
  surveyUsageType: SurveyUsageType,
  turnOnEnabled: boolean,
  supportsAutomation?: boolean,
  enableAutomationUUIDs?: string[]
): Promise<boolean> => {
  try {
    // First run a full draft save
    const saveResp = await dispatch(runFullDraftSave(surveyTypeId.toString()));
    if (saveResp.validationErrors.invalidNodeIds.length > 0) {
      // We can't proceed with publish, validation errors banner is already up from the redux state.
      return false;
    }
    // Now run the publish
    await dispatch(
      publishCurrentSurveyDraft(
        surveyTypeId.toString(),
        surveyUsageType,
        turnOnEnabled,
        supportsAutomation ? supportsAutomation : false,
        enableAutomationUUIDs ? enableAutomationUUIDs : []
      )
    );

    // Finally refresh the survey types and the current draft before exiting the modal
    await Promise.all([
      dispatch(fetchSurveyTypes("external", true)),
      dispatch(fetchSurveyTypeDraft(surveyTypeId, true)),
      supportsAutomation
        ? dispatch(fetchAutomationForSurveyType(surveyTypeId, true))
        : undefined,
    ]);

    dispatch(addDefaultSuccessAlert("Questionnaire template published"));
    return true;
  } catch (e) {
    console.error(e);
    dispatch(
      addDefaultUnknownErrorAlert(
        "An error occurred while publishing this template"
      )
    );
    return false;
  }
};

const PublishSurveyModal = (props: IPublishSurveyModalProps) => {
  const [loading, setLoading] = useState(false);
  const {
    active,
    onClose,
    dispatch,
    surveyTypeId,
    surveyUsageType,
    setIsPublishing,
  } = props;

  return (
    <ModalV2
      className={"publish-survey-modal"}
      active={active}
      disallowClose={loading}
      onClose={onClose}
      headerContent={
        props.title || "Are you sure you want to publish this template?"
      }
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            primary
            loading={loading}
            onClick={async () => {
              setIsPublishing(true);
              setLoading(true);
              const published = await publishSurvey(
                dispatch,
                surveyTypeId,
                surveyUsageType,
                true,
                props.supportsAutomation
              );
              onClose(published);
              setIsPublishing(false);
              setLoading(false);
            }}
          >
            {props.confirmButtonText || "Publish"}
          </Button>
        </div>
      }
    >
      <div>
        <p>
          {props.text ||
            "Publishing a questionnaire template will make it available for use across your UpGuard account."}
        </p>
      </div>
    </ModalV2>
  );
};

const RevertSurveyModal = (props: IPublishSurveyModalProps) => {
  const [loading, setLoading] = useState(false);
  const {
    active,
    onClose,
    dispatch,
    surveyTypeId,
    supportsAutomation,
    setIsPublishing,
  } = props;

  return (
    <ModalV2
      className={"revert-survey-modal"}
      active={active}
      disallowClose={loading}
      onClose={onClose}
      headerContent={props.title || "Revert your changes to published"}
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            danger
            loading={loading}
            onClick={async () => {
              try {
                setIsPublishing(true);
                setLoading(true);
                await Promise.all([
                  dispatch(
                    revertCurrentSurveyDraft(surveyTypeId.toString(), false)
                  ),
                  supportsAutomation
                    ? dispatch(revertCurrentAutomationDrafts(surveyTypeId))
                    : undefined,
                ]);

                // Refresh the survey types and the current draft details before exiting the modal
                await Promise.all([
                  dispatch(fetchSurveyTypes("external", true)),
                  dispatch(fetchSurveyTypeDraft(surveyTypeId, true)),
                  supportsAutomation
                    ? dispatch(fetchAutomationForSurveyType(surveyTypeId))
                    : undefined,
                ]);
                dispatch(addDefaultSuccessAlert("Changes reverted"));
                onClose(true);
              } catch (e) {
                console.error(e);
                dispatch(
                  addDefaultUnknownErrorAlert(
                    "An error occurred while reverting changes"
                  )
                );
                throw e;
              } finally {
                setLoading(false);
                setIsPublishing(false);
              }
            }}
          >
            {props.confirmButtonText || "Revert"}
          </Button>
        </div>
      }
    >
      <div>
        <p>
          {props.text &&
            "Reverting the draft changes to a questionnaire template and automation will discard all changes and return to the published version(s)."}
          {!props.text &&
            props.supportsAutomation &&
            "All changes to both questions and automation rules made since this questionnaire template was last published will be lost."}
          {!props.text &&
            !props.supportsAutomation &&
            "All changes since this questionnaire template was last published will be lost."}
        </p>
      </div>
    </ModalV2>
  );
};

export const openPreviewSurveyTab = (surveyTypeId: number, draft: boolean) => {
  let urlParams = `&type_id=${surveyTypeId}`;
  if (draft) {
    urlParams += "&draft=true";
  }

  const url = `/questionnairepreview?${urlParams.substring(1)}`;
  window.open(url, "_blank");
};

interface SurveyBuilderOwnProps {
  history: History;
  location: Location<locationState>;
  surveyTypeId: number;
  hideTitleAndDescription?: boolean;
  customDefaultHelpGuide?: JSX.Element;
  allowResettingToSourceTemplate?: boolean;
  onPostDraftSave: () => void;
  onPostDraftSaveAndExport?: () => void;
  onPostPublish?: () => void;
  onPostRevert?: () => void;
  publishText?: string;
  saveDraftText?: string;
  publishModalTitle?: string;
  publishModalText?: string;
  supportsAutomation?: boolean;
  initialTab?: string;
}

interface SurveyBuilderConnectedProps {
  surveyLoaded: boolean;
  surveyName: string;
  validationErrorNodeIds: string[];
  surveyLockedBy?: IUserMini;
  surveyFramework: SurveyFramework;
  surveyIsPublished: boolean;
  surveyDraftInProgress: boolean;
  surveyIsPublishable: boolean; // Determines whether a survey can be previewed or published, subject to an additional validation check.
  surveyChangesExist: boolean;
  usageType: SurveyUsageType;
  hasSourceTemplate: boolean;
  userHasWriteAutomationEnabled: boolean;
  numberOfDraftAutomationRules: number;
  numberOfDeletedAutomationRules: number;
  draftAutomationList?: IAutomationRecipe[];
  saveError?: boolean;
}

type SurveyBuilderProps = DefaultThunkDispatchProp &
  SurveyBuilderOwnProps &
  SurveyBuilderConnectedProps;

const SurveyBuilder = (props: SurveyBuilderProps) => {
  useEffect(() => {
    // Make sure we fetch the latest draft on load
    Promise.all([
      props.dispatch(fetchSurveyTypeDraft(props.surveyTypeId, true)),
      props.supportsAutomation
        ? props.dispatch(fetchAutomationForSurveyType(props.surveyTypeId))
        : undefined,
    ]).catch(() => {
      props.dispatch(
        addDefaultUnknownErrorAlert("Error loading questionnaire for editing")
      );
    });
  }, [props.surveyTypeId]);

  const [draftSaving, setDraftSaving] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [revertModalOpen, setRevertModalOpen] = useState(false);
  const [publishAutomationModalOpen, setPublishAutomationModalOpen] =
    useState(false);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [currentTab, setCurrentTab] = useState("questions");
  const [isCreatingAutomation, setIsCreatingAutomation] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  useEffect(() => {
    if (props.initialTab) {
      setCurrentTab(props.initialTab);
    }
  }, [props.initialTab]);

  const fullDraftSave = async () => {
    setDraftSaving(true);
    try {
      await props.dispatch(runFullDraftSave(props.surveyTypeId.toString()));
      props.dispatch(addDefaultSuccessAlert("Your draft has been saved"));
    } catch (e) {
      props.dispatch(
        addDefaultUnknownErrorAlert("An error occurred saving draft")
      );
    }

    setDraftSaving(false);
  };

  const previewSurvey = async () => {
    // If there have been no edits on this questionnaire, simply open up the published version without saving.
    if (props.surveyIsPublished && !props.surveyDraftInProgress) {
      openPreviewSurveyTab(props.surveyTypeId, false);
      return;
    }

    setDraftSaving(true);

    let hadValidationErrors = false;
    try {
      // First initiate a save
      const saveResp = await props.dispatch(
        runFullDraftSave(props.surveyTypeId.toString())
      );

      // If there are any validation errors, we can't preview.
      if (saveResp.validationErrors.invalidNodeIds.length > 0) {
        hadValidationErrors = true;
      }
    } catch (e) {
      props.dispatch(
        addDefaultUnknownErrorAlert("An error occurred saving draft")
      );
      setDraftSaving(false);
      return;
    }

    setDraftSaving(false);

    if (hadValidationErrors) {
      props.dispatch(
        addSimpleErrorAlert("One or more questions have issues", [
          "Please fix these errors before previewing the template.",
        ])
      );
    } else {
      openPreviewSurveyTab(props.surveyTypeId, true);
    }
  };

  const resetToDefaultTemplate = () => {
    openConfirmationModal({
      title: "Are you sure you want to reset the template?",
      description:
        "Any changes you have made and published for this template will be lost.",
      buttonAction: async () => {
        try {
          await props.dispatch(
            revertCurrentSurveyDraft(props.surveyTypeId.toString(), true)
          );

          // Refresh the survey types and detach the new draft before exiting the modal
          await Promise.all([
            props.dispatch(fetchSurveyTypes("external", true)),
            props.dispatch(fetchSurveyTypeDraft(props.surveyTypeId, true)),
            props.supportsAutomation
              ? props.dispatch(fetchQuestionsForSurveyType(props.surveyTypeId))
              : undefined,
          ]);
          props.dispatch(addDefaultSuccessAlert("Template reset to default"));
        } catch (e) {
          console.error(e);
          props.dispatch(
            addDefaultUnknownErrorAlert(
              `Failed to reset to default template: ${e}.`
            )
          );
        }
      },
    });
  };

  const openAddAutomation = async () => {
    try {
      const recipeUUID = await createNewAutomation(props.surveyTypeId);
      if (recipeUUID != "") {
        openAutomation(props.surveyTypeId, recipeUUID);
      }
    } catch (e) {
      props.dispatch(
        addDefaultUnknownErrorAlert("Error opening new automation")
      );
    }
  };

  const openAutomation = (surveyTypeId: number, recipeUUID: string) => {
    props.history.push(
      `/settings/automation/edit/${surveyTypeId}/${recipeUUID}/true`,
      {
        backContext: {
          backTo: props.history.location.pathname,
          backToText: "Back",
          backToContext: {
            surveyTypeId: surveyTypeId,
            currentTab: "automation",
          },
        },
      }
    );
  };

  const createNewAutomation = async (surveyTypeId: number) => {
    setIsCreatingAutomation(true);
    let recipeUUID = "";
    try {
      const uuid = await props.dispatch(
        createNewAutomationRecipe(surveyTypeId)
      );
      recipeUUID = uuid;
      if (props.supportsAutomation) {
        await props.dispatch(
          fetchAutomationForSurveyType(surveyTypeId, true, true)
        );
      }
    } catch (e) {
      props.dispatch(
        addDefaultUnknownErrorAlert("Error creating new automation")
      );
    }
    setIsCreatingAutomation(false);
    return recipeUUID;
  };

  const refreshAutomationQuestions = () => {
    if (props.supportsAutomation) {
      props.dispatch(fetchQuestionsForSurveyType(props.surveyTypeId, true));
    }
  };

  const renderHeaderButtons = () => {
    return (
      <div className="header-right">
        {/* Our autosave component doubles as a loading icon when saving */}
        {currentTab === "questions" && (
          <Autosave
            surveyId={props.surveyTypeId.toString()}
            showIcon
            postSaveCallback={refreshAutomationQuestions}
          />
        )}
        {props.surveyLockedBy ? (
          <div className="survey-locked-message">
            <span className="cr-icon-lock" /> {props.surveyLockedBy.name} is
            currently editing this template
          </div>
        ) : (
          <>
            {((props.surveyIsPublished && props.surveyDraftInProgress) ||
              props.numberOfDraftAutomationRules > 0) && (
              <Button
                tertiary
                disabled={draftSaving || props.saveError}
                className="revert-button"
                onClick={() => setRevertModalOpen(true)}
              >
                <div className="cr-icon-redo" /> Revert to published
              </Button>
            )}
            {!props.supportsAutomation && currentTab === "questions" && (
              <Button
                tertiary
                disabled={
                  draftSaving || !props.surveyIsPublishable || props.saveError
                }
                className="preview-button"
                onClick={previewSurvey}
              >
                <div className="cr-icon-eye" /> Preview
              </Button>
            )}

            <Button
              disabled={draftSaving || props.saveError}
              onClick={() =>
                fullDraftSave().then(() => props.onPostDraftSave())
              }
            >
              <div className="cr-icon-save" />
              {props.saveDraftText || "Save and exit"}
            </Button>
            {props.onPostDraftSaveAndExport && (
              <Button
                disabled={draftSaving || props.saveError}
                onClick={() =>
                  fullDraftSave().then(() => {
                    if (props.onPostDraftSaveAndExport) {
                      props.onPostDraftSaveAndExport();
                    }
                  })
                }
              >
                <div className="cr-icon-export-thin" />
                {props.saveDraftText || "Save and export to Excel"}
              </Button>
            )}

            <Button
              primary
              disabled={
                draftSaving || !props.surveyIsPublishable || props.saveError
              }
              onClick={() => {
                props
                  .dispatch(runAutosave(`${props.surveyTypeId}`))
                  .then(() => {
                    setPublishModalOpen(true);
                  });
              }}
            >
              <div className="cr-icon-check" />
              {props.publishText || "Publish"}
            </Button>
          </>
        )}
      </div>
    );
  };

  const renderActionBarButtons = () => {
    return (
      <ActionBar active newStyles>
        <div className="left-content">
          {props.surveyLockedBy ? (
            <div className="survey-locked-message">
              <span className="cr-icon-lock" /> {props.surveyLockedBy.name} is
              currently editing this template
            </div>
          ) : (
            ((props.surveyIsPublished && props.surveyDraftInProgress) ||
              props.numberOfDraftAutomationRules > 0 ||
              props.numberOfDeletedAutomationRules > 0) && (
              <Button
                tertiary
                disabled={draftSaving || props.saveError}
                className="revert-button"
                onClick={() => setRevertModalOpen(true)}
              >
                <div className="cr-icon-undo" /> Revert to published
              </Button>
            )
          )}
        </div>
        <div className={"middle-section"}>
          <Autosave
            surveyId={props.surveyTypeId.toString()}
            showIcon
            absoluteTime
            postSaveCallback={refreshAutomationQuestions}
          />
        </div>
        <div className="right-content">
          <Button
            disabled={draftSaving || props.saveError}
            onClick={() => fullDraftSave().then(() => props.onPostDraftSave())}
          >
            <i className="cr-icon-save" />
            Save draft
          </Button>
          <Button
            primary
            disabled={
              draftSaving || !props.surveyIsPublishable || props.saveError
            }
            onClick={() => {
              props.dispatch(runAutosave(`${props.surveyTypeId}`)).then(() => {
                const unsaved = props.draftAutomationList?.filter(
                  (a) => a.editsExist
                );
                if (unsaved && unsaved.length > 0) {
                  props.dispatch(
                    addSimpleErrorAlert(
                      `There is an unsaved automation rule '${unsaved[0].name}'. Please finish editing the rule to publish this draft.`
                    )
                  );
                  return;
                }
                props.numberOfDraftAutomationRules > 0 ||
                props.numberOfDeletedAutomationRules > 0
                  ? setPublishAutomationModalOpen(true)
                  : setPublishModalOpen(true);
              });
            }}
          >
            <i className="cr-icon-check" />
            Publish
          </Button>
        </div>
      </ActionBar>
    );
  };

  return (
    <div className={"survey-builder"}>
      {props.surveyLoaded ? (
        <>
          {props.usageType === SurveyUsageType.Relationship &&
            renderActionBarButtons()}
          <ReportCard newStyles className="editor-report-card">
            {props.usageType === SurveyUsageType.Security && (
              <div className="header">
                <div className="header-inner">
                  <div className="header-left">
                    {!props.supportsAutomation || currentTab === "questions"
                      ? "Edit Questionnaire"
                      : "Edit Automation"}
                    {props.hasSourceTemplate &&
                      props.allowResettingToSourceTemplate &&
                      currentTab === "questions" && (
                        <Button tertiary onClick={resetToDefaultTemplate}>
                          <i className={"cr-icon-redo rot180"} />
                          Reset to default template
                        </Button>
                      )}
                  </div>
                  {props.supportsAutomation &&
                    currentTab === "automation" &&
                    props.userHasWriteAutomationEnabled && (
                      <Button
                        disabled={!!props.surveyLockedBy || props.saveError}
                        onClick={() => openAddAutomation()}
                        loading={isCreatingAutomation}
                      >
                        <div className={"cr-icon-plus"} /> New automation rule
                      </Button>
                    )}
                  {props.supportsAutomation && currentTab === "questions" && (
                    <Button
                      tertiary
                      disabled={draftSaving || !props.surveyIsPublishable}
                      className="preview-button"
                      onClick={previewSurvey}
                    >
                      <div className="cr-icon-eye" /> Preview
                    </Button>
                  )}
                  {!props.supportsAutomation && renderHeaderButtons()}
                </div>
              </div>
            )}
            {currentTab === "questions" && (
              <>
                <div className="editor-container">
                  <div
                    className={classnames("editor-left-pane", {
                      locked: !!props.surveyLockedBy || props.saveError,
                    })}
                  >
                    <RootNode
                      surveyTypeId={props.surveyTypeId}
                      surveyId={props.surveyTypeId.toString()}
                      usageType={props.usageType}
                      hideTitleAndDescription={props.hideTitleAndDescription}
                    />
                  </div>
                  {props.usageType === SurveyUsageType.Security && (
                    <div className="editor-right-pane">
                      <QuestionnaireBuilderHelpGuide
                        surveyId={props.surveyTypeId.toString()}
                        customDefaultHelpGuide={props.customDefaultHelpGuide}
                      />
                    </div>
                  )}
                </div>
                <TemplateSettingsModal
                  dispatch={props.dispatch}
                  active={settingsModalOpen}
                  onClose={() => setSettingsModalOpen(false)}
                  surveyTypeId={props.surveyTypeId.toString()}
                  surveyFramework={props.surveyFramework}
                />
                <EditRiskModal surveyId={props.surveyTypeId.toString()} />
                <ValidationToolbar
                  dispatch={props.dispatch}
                  surveyId={props.surveyTypeId.toString()}
                  validationErrorNodeIds={props.validationErrorNodeIds}
                />
                {confirmationModal}
              </>
            )}
            {currentTab === "automation" && (
              <ListQuestionnaireAutomation
                loading={isPublishing}
                history={props.history}
                location={props.location}
                isEditing={true}
                surveyTypeId={props.surveyTypeId}
                locked={!!props.surveyLockedBy || props.saveError}
                draftTemplateExists={true}
              />
            )}
          </ReportCard>
          <PublishSurveyModal
            dispatch={props.dispatch}
            active={publishModalOpen}
            onClose={(didPublish) => {
              setPublishModalOpen(false);
              if (didPublish && props.onPostPublish) {
                props.onPostPublish();
              }
            }}
            surveyTypeId={props.surveyTypeId}
            surveyUsageType={props.usageType}
            title={props.publishModalTitle}
            text={props.publishModalText}
            confirmButtonText={"Confirm"}
            setIsPublishing={setIsPublishing}
            supportsAutomation={props.supportsAutomation}
          />
          {props.supportsAutomation && (
            <PublishDraftWithAutomationModal
              active={publishAutomationModalOpen}
              updatedAutomations={props.draftAutomationList?.filter(
                (a) => a.draft
              )}
              numberOfDeletedAutomationRules={
                props.numberOfDeletedAutomationRules
              }
              onClose={(didPublish: boolean) => {
                setPublishAutomationModalOpen(false);
                if (didPublish && props.onPostPublish) {
                  props.onPostPublish();
                }
              }}
              setIsPublishing={setIsPublishing}
              publishSurvey={async (enableAutomationUUIDs: string[]) => {
                return await publishSurvey(
                  props.dispatch,
                  props.surveyTypeId,
                  props.usageType,
                  true,
                  props.supportsAutomation,
                  enableAutomationUUIDs
                );
              }}
              surveyChangesExist={props.surveyChangesExist}
            />
          )}
          <RevertSurveyModal
            dispatch={props.dispatch}
            active={revertModalOpen}
            onClose={(didRevert) => {
              setRevertModalOpen(false);
              if (didRevert && props.onPostRevert) {
                props.onPostRevert();
              }
            }}
            surveyTypeId={props.surveyTypeId}
            surveyUsageType={props.usageType}
            supportsAutomation={props.supportsAutomation}
            setIsPublishing={setIsPublishing}
          />
        </>
      ) : (
        <LoadingBanner />
      )}
    </div>
  );
};

export default appConnect<
  SurveyBuilderConnectedProps,
  never,
  SurveyBuilderOwnProps
>((state, props) => {
  const survey = state.surveyBuilder.surveys[props.surveyTypeId];

  const draftAutomationList =
    state.cyberRisk.questionnaireAutomation?.[props.surveyTypeId]?.draft;
  let numberOfDraftAutomationRules = 0;
  const numberOfDeletedAutomationRules =
    state.cyberRisk.questionnaireAutomation?.[props.surveyTypeId]
      ?.numDraftDeletions || 0;
  if (props.supportsAutomation && draftAutomationList) {
    draftAutomationList.map((r) => {
      numberOfDraftAutomationRules += r.draft ? 1 : 0;
    });
  }

  let surveyLoaded = false;
  let surveyName = "";
  let validationErrorNodeIds: string[] = [];
  let surveyLockedBy: IUserMini | undefined;
  let surveyFramework = SurveyFramework.NONE;
  let surveyIsPublished = false;
  let surveyDraftInProgress = false;
  let surveyIsPublishable = false;
  let usageType = SurveyUsageType.Security;
  let hasSourceTemplate = false;
  let surveyChangesExist = false;
  let saveError: boolean | undefined;

  if (survey) {
    surveyLoaded = true;
    surveyName = survey.name;
    validationErrorNodeIds = survey.validationErrors.invalidNodeIds;
    surveyLockedBy = survey.lockedBy;
    surveyFramework = survey.framework;
    surveyIsPublished = survey.isPublished;
    surveyDraftInProgress = survey.draftInProgress;
    surveyIsPublishable =
      (survey.nodes[survey.rootNodeId] as SectionNode).childNodeIds.length > 0;
    usageType = survey.usageType;
    hasSourceTemplate = !!survey.sourceTypeId;
    surveyChangesExist = survey.draftInProgress;
    saveError = survey.saveError;
  }

  const userPerms = state.common.userData.userPermissions;
  const userHasWriteAutomationEnabled = userPerms.includes(
    WriteQuestionnaireAutomation
  );

  return {
    surveyLoaded,
    surveyName,
    validationErrorNodeIds,
    surveyLockedBy,
    surveyFramework,
    surveyIsPublished,
    surveyDraftInProgress,
    surveyIsPublishable,
    surveyChangesExist,
    usageType,
    hasSourceTemplate,
    userHasWriteAutomationEnabled,
    numberOfDraftAutomationRules,
    numberOfDeletedAutomationRules,
    draftAutomationList,
    saveError,
  };
})(SurveyBuilder);

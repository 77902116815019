import { useBack } from "../../../_common/types/router";
import { PermissionRouteParams } from "../../UserBaseNavItems";
import { FC } from "react";
import userbaseApi from "../../api/userbase.api";

import UserBasePermissionHeader from "../../components/headers/UserBasePermissionHeader";
import {
  permissionAppsUrl,
  permissionSummaryUrl,
  permissionsUrl,
} from "../../UserBaseAppRouter";
import PermissionApps from "../../components/permissions/PermissionApps";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const PermissionApplicationsView: FC = () => {
  const match = useDecodedRouteMatch<PermissionRouteParams>();
  const { backAction, backText } = useBack();

  const scopeExternalId = match.params.externalID;

  const { data } = userbaseApi.useGetUserBaseScopeSummaryV1Query({
    scopeExternalId,
  });

  const permission = data?.scope;

  if (!permission) {
    return <></>;
  }

  return (
    <div className="userbase-permission-apps">
      <UserBasePermissionHeader
        scopeExternalId={scopeExternalId}
        breadcrumbs={[
          { text: "Permissions", to: permissionsUrl },
          {
            text: permission.description ?? "Permission Description",
            to: permissionSummaryUrl(scopeExternalId),
          },
          {
            text: "Applications",
            to: permissionAppsUrl(scopeExternalId),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Applications"}
      />

      <PermissionApps scopeExternalId={permission.externalID} />
    </div>
  );
};

export default PermissionApplicationsView;

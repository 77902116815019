import { useSorting } from "../../../_common/hooks";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import QuestionnaireIcon from "../../../_common/images/questionnaire-icon.svg";

import "../../style/components/SharedProfileSupportingDocumentationTable.scss";
import moment from "moment/moment";
import { getCyberRiskAuth } from "../../../_common/session";
import { trackEvent } from "../../../_common/tracking";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import { FC, HTMLProps } from "react";
import CircledIcon from "../../../_common/components/CircledIcon";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import { GetIconForFilename } from "../../helpers/icons";
import classNames from "classnames";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { ContentLibraryDocumentRedacted } from "../../../contentlibrary/types/contentLibrary.types";
import { AcceptedDocumentFileExtensionsList } from "../modals/DocumentModal";
import { downloadContentLibraryDocumentFile } from "../../../contentlibrary/api/downloadDocument";
import {
  useBasicPermissions,
  UserReadContentLibrary,
} from "../../../_common/permissions";
import { useDefaultHistory } from "../../../_common/types/router";
import {
  AcceptedDocumentFileExtensions,
  AcceptedDocumentMimeTypes,
  MaxFileSizeB,
  MaxFileSizeDisplay,
} from "../../../_common/types/fileRestrictions";
import { useAddDocumentToSharedProfile } from "../../../verifiedvendors/hooks/useAddDocumentToSharedProfile";

export const SharedProfileSupportingDocumentationTableStyles: FC<
  HTMLProps<HTMLDivElement>
> = ({ className, children, ...rest }) => {
  return (
    <div
      className={classNames(
        "shared-profile-supporting-documentation-card",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export const downloadDocument = (
  vendorId: number,
  d: ContentLibraryDocumentRedacted
) => {
  const { apiKey, token } = getCyberRiskAuth();
  const url = `/api/sharedassessment/documents/v1/?apikey=${encodeURIComponent(
    apiKey
  )}&token=${encodeURIComponent(token)}&vendor_id=${encodeURIComponent(
    vendorId
  )}&uuid=${encodeURIComponent(d.uuid)}`;
  // window.open(url, "_blank");
  trackEvent("VerifiedVendor_DownloadDocument");

  const a = document.createElement("a");
  a.href = url;
  a.download = d.filename;
  a.click();
};

interface ISharedProfileSupportingDocumentationTableProps {
  vendorId: number;
  documents: ContentLibraryDocumentRedacted[];
  isOwnSharedProfile: boolean;
  userHasNoAccess: boolean;
  userHasWritePrefilledSurveysPermission: boolean;
}

const supportingDocumentationColumnHeaders: IXTableColumnHeader[] = [
  {
    id: "document",
    text: "Document",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "published",
    text: "Date published",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
];

const SharedProfileSupportingDocumentationTable: FC<
  ISharedProfileSupportingDocumentationTableProps
> = ({
  vendorId,
  isOwnSharedProfile,
  userHasNoAccess,
  userHasWritePrefilledSurveysPermission,
  documents,
}) => {
  const dispatch = useAppDispatch();
  const permissions = useBasicPermissions();
  const userHasReadContentLibrary =
    !!permissions.userPermissions[UserReadContentLibrary];
  const history = useDefaultHistory();

  const [sortedDocuments, sortedBy, onSortChange] = useSorting<
    ContentLibraryDocumentRedacted,
    "document" | "published"
  >(documents, "published", SortDirection.DESC, {
    document: {
      orderFuncs: [(a) => a.name.toLowerCase()],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    published: {
      orderFuncs: [(a) => a.updatedAt],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });
  const addDocument = useAddDocumentToSharedProfile();

  const rows = sortedDocuments.map((d) => {
    let download;
    if (!userHasNoAccess) {
      if (isOwnSharedProfile) {
        download = () => dispatch(downloadContentLibraryDocumentFile(d.uuid));
      } else {
        download = () => downloadDocument(vendorId, d);
      }
    }

    const goToContentLibrary = () =>
      history.push(`/contentlibrary/document/${d.uuid}`, {
        backContext: {
          goBack: true,
          backToText: "Back to Trust Page",
        },
      });

    const iconOptions: IIconOption[] = [];

    if (!userHasNoAccess) {
      if (isOwnSharedProfile && userHasWritePrefilledSurveysPermission) {
        // Push them to the content library to edit
        iconOptions.push({
          id: "edit",
          hoverText: "Edit in Content Library",
          icon: <div className="cr-icon-pencil" />,
          onClick: goToContentLibrary,
        });
      }

      iconOptions.push({
        id: "download",
        hoverText: "Download",
        icon: <div className="cr-icon-export" />,
        onClick: download,
      });
    }

    return {
      id: d.uuid,
      onClick:
        isOwnSharedProfile && userHasReadContentLibrary
          ? goToContentLibrary
          : download,
      iconOptions,
      cells: [
        <XTableCell key="document">
          <div className="doc-cell">
            <img
              className="file-type-icon"
              src={GetIconForFilename(d.filename, QuestionnaireIcon)}
            />
            <div className="name-and-desc">
              {d.name}
              {d.description && (
                <>
                  <br />
                  <div className="desc">{d.description}</div>
                </>
              )}
            </div>
          </div>
        </XTableCell>,
        <XTableCell key="published" className="shrink-cell">
          {moment(d.updatedAt).format("ll")}
        </XTableCell>,
      ],
    };
  });

  const showUploadDocument =
    isOwnSharedProfile && userHasWritePrefilledSurveysPermission;

  return (
    <div className="shared-profile-supporting-documentation-card">
      {showUploadDocument && (
        <DragDropUpload
          maxFileSize={MaxFileSizeB}
          acceptedFileTypeFilters={[
            ...AcceptedDocumentFileExtensions,
            ...AcceptedDocumentMimeTypes,
          ]}
          onFileRejected={() =>
            dispatch(
              addSimpleErrorAlert(
                `File must be under ${MaxFileSizeDisplay} and be one of the following types: ${AcceptedDocumentFileExtensionsList}`
              )
            )
          }
          onFileSelected={addDocument}
          doNotKeepState
        />
      )}
      {rows.length > 0 && (
        <XTable
          className="shared-profile-supporting-documentation-table"
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          columnHeaders={supportingDocumentationColumnHeaders}
          iconOptions
          rows={rows}
        />
      )}
      {rows.length === 0 && !showUploadDocument && isOwnSharedProfile && (
        <EmptyCardWithAction
          iconJSX={<CircledIcon iconClass="cr-icon-q-builder-attachment" />}
          emptyText={"You have not added any supporting documentation"}
          emptySubText={"Get started by uploading a document."}
        />
      )}
    </div>
  );
};

export default SharedProfileSupportingDocumentationTable;

import { FlattenedNodeSummaryMap } from "../surveyViewer.helpers";

import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { FC, HTMLProps, useEffect, useState } from "react";
import classnames from "classnames";
import SurveyViewerComments from "./SurveyViewerComments";
import { surveyViewerRightPanelMode } from "../reducers/reducer";

import "../style/SurveyViewerRightPanel.scss";
import { IAutomationRecipe } from "../../vendorrisk/types/automation";
import SurveyViewerAutomationResultsPanel from "./SurveyViewerAutomationResultsPanel";
import SurveyViewerGptAutofillPanel from "./SurveyViewerGptAutofillPanel";
import { appConnect } from "../../_common/types/reduxHooks";

export const SurveyViewerRightPanelHeader: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <div
      className={`survey-viewer-right-panel-header ${className ?? ""}`}
      {...otherProps}
    >
      {children}
    </div>
  );
};

interface ISurveyViewerRightPanelConnectedProps {
  active: boolean;
  mode: surveyViewerRightPanelMode;
  questionId: string | undefined;
  currentUserId: number;
  isQuestionVisible: boolean;
}

interface ISurveyViewerRightPanelOwnProps {
  surveyId: number;
  surveyTypeId: number;
  isPublicSurvey?: boolean;
  otherVendorName: string;
  flattenedNodeMap: FlattenedNodeSummaryMap;
  privateMessages: boolean;
  hasWritePermission: boolean;
  orgName: string;
  diffModeLocked: boolean; // should be true if we can't post messages due to diff mode
  automation?: IAutomationRecipe;
  automationUUID?: string;
  setSelectedAutomationUUID: (u: string) => void;
  automationList: IAutomationRecipe[];
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  isCollaborator?: boolean;
}

type ISurveyViewerRightPanelProps = ISurveyViewerRightPanelConnectedProps &
  ISurveyViewerRightPanelOwnProps &
  DefaultThunkDispatchProp;

const SurveyViewerRightPanel: FC<ISurveyViewerRightPanelProps> = ({
  dispatch,
  active,
  mode,
  questionId,
  currentUserId,
  isQuestionVisible,
  surveyId,
  surveyTypeId,
  isPublicSurvey = false,
  otherVendorName,
  flattenedNodeMap,
  privateMessages,
  hasWritePermission,
  orgName,
  diffModeLocked,
  automation,
  automationList,
  automationUUID,
  setSelectedAutomationUUID,
  isManagementAnalystSession,
  managedOrgId,
  isCollaborator,
}) => {
  // The autosizeTextArea component doesn't respond well to animating the parent width from 0px -> 400px
  // So we'll need to force a re-render after the animation has run far enough
  const [, setRenderCount] = useState(0);
  useEffect(() => {
    if (active) {
      setTimeout(() => {
        setRenderCount((renderCount) => renderCount + 1);
      }, 120);
    }
  }, [active]);

  return (
    <div className={classnames("survey-viewer-right-panel", { active })}>
      {!isPublicSurvey &&
      (mode === surveyViewerRightPanelMode.Comments ||
        mode === surveyViewerRightPanelMode.CommentsPrivateOnly) ? (
        <SurveyViewerComments
          dispatch={dispatch}
          panelActive={active}
          questionId={questionId}
          surveyId={surveyId}
          otherVendorName={otherVendorName}
          flattenedNodeMap={flattenedNodeMap}
          privateMessages={privateMessages}
          hasWritePermission={hasWritePermission}
          orgName={orgName}
          currentUserId={currentUserId}
          isQuestionVisible={isQuestionVisible}
          diffModeLocked={diffModeLocked}
          privateMessagesOnly={
            mode === surveyViewerRightPanelMode.CommentsPrivateOnly
          }
          isCollaborator={isCollaborator}
        />
      ) : mode === surveyViewerRightPanelMode.Automation ? (
        <SurveyViewerAutomationResultsPanel
          automation={automation}
          automationList={automationList}
          setSelectedAutomationUUID={setSelectedAutomationUUID}
          automationUUID={automationUUID}
          surveyId={surveyId}
          surveyTypeId={surveyTypeId}
        />
      ) : mode === surveyViewerRightPanelMode.GptAutofillSuggestions ? (
        <SurveyViewerGptAutofillPanel
          questionId={questionId ?? ""}
          dispatch={dispatch}
          nodeSummary={flattenedNodeMap[questionId ?? ""]}
          isManagementAnalystSession={isManagementAnalystSession}
          managedOrgId={managedOrgId}
        />
      ) : null}
    </div>
  );
};

export default appConnect<
  ISurveyViewerRightPanelConnectedProps,
  never,
  ISurveyViewerRightPanelOwnProps
>((state) => {
  return {
    mode: state.surveyViewer.rightPanel.mode,
    questionId: state.surveyViewer.rightPanel.questionId,
    active: state.surveyViewer.rightPanel.active,
    currentUserId: state.common.userData.id,
    isQuestionVisible: state.surveyViewer.rightPanel.questionId
      ? state.surveyViewer.nodeVisibilities[
          state.surveyViewer.rightPanel.questionId
        ] ?? false
      : true,
  };
})(SurveyViewerRightPanel);

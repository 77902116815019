import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl, ResponseError } from "../../_common/api";
import {
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { RootState } from "../../_common/types/reduxStore";

// downloadContentLibraryDocumentFile downloads a specific document in the content library.
// If version is supplied, it downloads that version. Otherwise it downloads the latest version.
export const downloadContentLibraryDocumentFile = (
  uuid: string,
  version?: number,
  surveyId?: number,
  publicSurvey?: boolean
) => {
  return async (dispatch: DefaultThunkDispatch, getState: () => RootState) => {
    const opts: Record<string, any> = { uuid };
    if (version) {
      opts.version = version;
    }

    let endpoint = "contentlibrary/version/v1";

    if (surveyId) {
      // Use a different endpoint when authorising via a survey
      endpoint = "contentlibrary/survey/version/v1";
      opts.survey_id = surveyId;
      opts.public_survey = !!publicSurvey;
    }

    try {
      await FetchCyberRiskUrl(endpoint, opts, null, dispatch, getState);
    } catch (e) {
      console.error(e);

      if (
        e instanceof ResponseError &&
        e.response.status === 422 &&
        e.json.error === "document not ready for download"
      ) {
        dispatch(
          addDefaultWarningAlert(
            `This document is still being scanned. Please try again in a few minutes.`
          )
        );
      } else {
        dispatch(addDefaultUnknownErrorAlert("Error downloading document"));
      }
    }
  };
};

import { FC, HTMLProps } from "react";
import classnames from "classnames";
import "../../style/components/reporting/ReportTypeBadge.scss";
import { getVendorWords, IVendorWords } from "../../../_common/constants";
import VendorSummaryReport from "../../../_common/images/reporting/VendorSummaryReport.svg";
import CustomerSummaryReport from "../../../_common/images/reporting/CustomerSummaryReport.svg";
import VendorDetailedReport from "../../../_common/images/reporting/VendorDetailedReport.svg";
import CustomerDetailedReport from "../../../_common/images/reporting/CustomerDetailedReport.svg";
import VendorAssessmentReport from "../../../_common/images/reporting/VendorAssessmentReport.svg";
import VendorAssessmentReportDisabled from "../../../_common/images/reporting/VendorAssessmentReportDisabled.svg";
import RiskAssessmentSummaryReport from "../../../_common/images/reporting/RiskAssessmentSummaryReport.svg";
import RiskAssessmentSummaryReportDisabled from "../../../_common/images/reporting/RiskAssessmentSummaryReportDisabled.svg";
import CustomerAssessmentReport from "../../../_common/images/reporting/CustomerAssessmentReport.svg";
import CustomerAssessmentReportDisabled from "../../../_common/images/reporting/CustomerAssessmentReportDisabled.svg";
import BreachsightSummaryReport from "../../../_common/images/reporting/BreachsightSummaryReport.svg";
import BreachsightDetailedReport from "../../../_common/images/reporting/BreachsightDetailedReport.svg";
import BoardReport from "../../../_common/images/reporting/BoardReport.svg";
import BoardPresentation from "../../../_common/images/reporting/BoardPresentation.svg";
import VendorPortfolioSummaryReport from "../../../_common/images/reporting/VendorPortfolioSummaryReport.svg";
import VendorComparisonReport from "../../../_common/images/reporting/VendorComparisonReport.svg";
import CustomerComparisonReport from "../../../_common/images/reporting/CustomerComparisonReport.svg";
import SubsidiariesSummaryReport from "../../../_common/images/reporting/SubsidiariesSummaryReport.svg";
import BreachsightCustomReport from "../../../_common/images/reporting/BreachsightCustomReport.svg";
import VendorCustomReport from "../../../_common/images/reporting/VendorCustomReport.svg";
import CustomReport from "../../../_common/images/reporting/CustomReport.svg";
import Button from "../../../_common/components/core/Button";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { AssuranceType } from "../../../_common/types/organisations";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import classNames from "classnames";

export enum BadgeModule {
  Unknown = "Unknown",
  Upguard = "UpGuard",
  Breachsight = "BreachSight",
  VendorRisk = "Vendor Risk",
}

export enum BadgeImageID {
  Custom = "Custom",
  BreachsightSummaryReport = "BreachsightSummaryReport",
  BreachsightDetailedReport = "BreachsightDetailedReport",
  VendorSummaryReport = "VendorSummaryReport",
  VendorDetailedReport = "VendorDetailedReport",
  VendorAssessmentReport = "VendorAssessmentReport",
  BoardSummaryReport = "BoardSummaryReport",
  BoardSummaryPresentation = "BoardSummaryPresentation",
  VendorPortfolioSummaryReport = "VendorPortfolioSummaryReport",
  VendorComparisonReport = "VendorComparisonReport",
  GenericVendorReport = "GenericVendorReport",
  RiskAssessmentSummaryReport = "RiskAssessmentSummaryReport",
  SubsidiariesSummaryReport = "SubsidiariesSummaryReport",
  BreachsightCustomReport = "BreachsightCustomReport",
  VendorCustomReport = "VendorCustomReport",
}

export const BadgeImageRefs: Record<BadgeImageID, imageRef> = {
  [BadgeImageID.Custom]: {
    image: CustomReport,
    className: "custom",
    backgroundColor: "#bce7ce",
  },
  [BadgeImageID.BreachsightSummaryReport]: {
    image: BreachsightSummaryReport,
    className: "bsr",
    backgroundColor: "#ffbfbf",
  },
  [BadgeImageID.BreachsightDetailedReport]: {
    image: BreachsightDetailedReport,
    className: "bdr",
    backgroundColor: "#ffe69a",
  },
  [BadgeImageID.VendorSummaryReport]: {
    image: VendorSummaryReport,
    imageAssurance: CustomerSummaryReport,
    className: "vsr",
    backgroundColor: "#81c3d8",
  },
  [BadgeImageID.VendorDetailedReport]: {
    image: VendorDetailedReport,
    imageAssurance: CustomerDetailedReport,
    className: "vdr",
    backgroundColor: "#bce7ce",
  },
  [BadgeImageID.VendorAssessmentReport]: {
    image: VendorAssessmentReport,
    imageDisabled: VendorAssessmentReportDisabled,
    imageAssurance: CustomerAssessmentReport,
    imageAssuranceDisabled: CustomerAssessmentReportDisabled,
    className: "var",
    backgroundColor: "#8babe1",
  },
  [BadgeImageID.BoardSummaryReport]: {
    image: BoardReport,
    className: "bor",
    backgroundColor: "#cccfe0",
  },
  [BadgeImageID.BoardSummaryPresentation]: {
    image: BoardPresentation,
    landscape: true,
    className: "bop short",
    backgroundColor: "#cccfe0",
  },
  [BadgeImageID.VendorPortfolioSummaryReport]: {
    image: VendorPortfolioSummaryReport,
    className: "ves",
    backgroundColor: "#5d6598",
  },
  [BadgeImageID.VendorComparisonReport]: {
    image: VendorComparisonReport,
    imageAssurance: CustomerComparisonReport,
    className: "vcr",
    backgroundColor: "#3f68ac",
  },
  [BadgeImageID.GenericVendorReport]: {
    image: VendorDetailedReport,
    className: "vdr",
    backgroundColor: "#bce7ce",
  },
  [BadgeImageID.RiskAssessmentSummaryReport]: {
    image: RiskAssessmentSummaryReport,
    imageDisabled: RiskAssessmentSummaryReportDisabled,
    imageAssurance: RiskAssessmentSummaryReport,
    imageAssuranceDisabled: RiskAssessmentSummaryReportDisabled,
    className: "var",
    backgroundColor: "#8babe1",
  },
  [BadgeImageID.SubsidiariesSummaryReport]: {
    image: SubsidiariesSummaryReport,
    className: "ssr",
    backgroundColor: "#fbd8b8",
  },
  [BadgeImageID.BreachsightCustomReport]: {
    image: BreachsightCustomReport,
    className: "bcur",
    backgroundColor: "#F7ABAB",
  },
  [BadgeImageID.VendorCustomReport]: {
    image: VendorCustomReport,
    imageAssurance: VendorCustomReport,
    className: "vcur",
    backgroundColor: "#93CAED",
  },
};

// takes a base badge name and returns an image ref for that badge, altering it if a custom version exists
export const CustomImageRef = (baseBadgeID: BadgeImageID): imageRef => {
  switch (baseBadgeID) {
    case BadgeImageID.VendorSummaryReport:
      return BadgeImageRefs[BadgeImageID.VendorCustomReport];
    case BadgeImageID.VendorDetailedReport:
      return BadgeImageRefs[BadgeImageID.VendorCustomReport];
    case BadgeImageID.VendorAssessmentReport:
      return BadgeImageRefs[BadgeImageID.VendorCustomReport];
    case BadgeImageID.BreachsightSummaryReport:
      return BadgeImageRefs[BadgeImageID.BreachsightCustomReport];
    case BadgeImageID.BreachsightDetailedReport:
      return BadgeImageRefs[BadgeImageID.BreachsightCustomReport];
  }
  return BadgeImageRefs[baseBadgeID];
};

export enum ExecutiveReportType {
  Unknown = "Unknown",
  VendorRiskExecSummaryReport = "VendorRiskExecSummaryReport",
  VendorComparisonReport = "VendorComparisonReport",
  PortfolioReport = "PortfolioReport",
  RiskAssessmentSummary = "RiskAssessmentSummary",
}

export interface imageRef {
  image: any;
  landscape?: boolean;
  className: string;
  backgroundColor: string;
  imageDisabled?: any;
  imageAssurance?: any;
  imageAssuranceDisabled?: any;
}

export interface IButtonDef {
  module: BadgeModule;
  imageRef: imageRef;
  title: string;
  subtext: React.ReactNode;
  disabled?: boolean;
  disabledReason?: string;
}

interface ReportTypeBadgeProps {
  reportDef: IButtonDef;
  assuranceType: AssuranceType;
  selected?: boolean;
  module?: boolean;
  busy?: boolean;
  disabledReason?: string;
  onClick?: () => void;
  onGenerateClick?: () => void;
  onQuickExportClick?: () => void;
}

export const getReportButtonDefinition = (
  reportType: ExecutiveReportType,
  vendorWords: IVendorWords,
  publishedRiskAssessment: boolean,
  orgHasAssessmentsEnabled: boolean
): IButtonDef => {
  switch (reportType) {
    case ExecutiveReportType.VendorRiskExecSummaryReport:
      return {
        module: BadgeModule.VendorRisk,
        imageRef: BadgeImageRefs[BadgeImageID.VendorPortfolioSummaryReport],
        title: `${vendorWords.vendorRiskModuleName} Executive Summary Report`,
        subtext: `Gain insight into your entire ${vendorWords.singular} portfolio with this executive level summary showing current postures, high level trends and areas for improvement.`,
      };
    case ExecutiveReportType.VendorComparisonReport:
      return {
        module: BadgeModule.VendorRisk,
        imageRef: BadgeImageRefs[BadgeImageID.VendorComparisonReport],
        title: `${vendorWords.singularTitleCase} Comparison Report`,
        subtext: `Quickly compare up to 4 ${vendorWords.plural} across a multitude of risk categories.`,
      };
    case ExecutiveReportType.RiskAssessmentSummary:
      return {
        module: BadgeModule.VendorRisk,
        imageRef: BadgeImageRefs[BadgeImageID.RiskAssessmentSummaryReport],
        title: `Risk Assessment Summary Report`,
        subtext: `Get a snapshot of your risk assessment activity and status across your ${vendorWords.plural}.`,
        disabled: !publishedRiskAssessment || !orgHasAssessmentsEnabled,
        disabledReason: !publishedRiskAssessment
          ? `Conduct a risk assessment on any one of your ${vendorWords.plural} to enable the Risk Assessment Summary Report`
          : !orgHasAssessmentsEnabled
            ? "Risk assessments are not enabled for your account"
            : "",
      };
    default:
      return {} as IButtonDef;
  }
};

const moduleIcon = (module: BadgeModule, disabled: boolean): any => {
  switch (module) {
    case BadgeModule.VendorRisk:
      return (
        <i
          className={classnames("cr-icon-vendor-risk", { coloured: !disabled })}
        />
      );
    case BadgeModule.Breachsight:
      return (
        <i
          className={classnames("cr-icon-breachsight", { coloured: !disabled })}
        />
      );
    case BadgeModule.Upguard:
      return (
        <i
          className={classnames("cr-icon-upguard-logo-pick", {
            coloured: !disabled,
          })}
        />
      );
  }
  return undefined;
};

export const ReportTypeBadgeStyles: FC<HTMLProps<HTMLDivElement>> = ({
  className,
  ...rest
}) => <div className={classNames("report-type-badge", className)} {...rest} />;

const ReportTypeBadge: FC<ReportTypeBadgeProps> = ({
  selected,
  reportDef,
  module,
  assuranceType,
  onClick,
  onGenerateClick,
  onQuickExportClick,
  busy,
  disabledReason,
}) => {
  const vendorWords = getVendorWords(assuranceType);

  return (
    <SidePopupV2
      className="report-type-badge-container"
      text={
        reportDef.disabled
          ? disabledReason ?? reportDef.disabledReason
          : undefined
      }
    >
      <ReportTypeBadgeStyles
        className={classnames({
          "report-type-badge-selected": selected,
          "report-type-badge-disabled": reportDef.disabled,
          "report-type-badge-hoverable": !reportDef.disabled && !!onClick,
        })}
        onClick={reportDef.disabled ? undefined : onClick}
      >
        <div className={`lhs`}>
          {reportDef.module !== BadgeModule.Unknown && module && (
            <div
              className={classnames("module", {
                disabled: !!reportDef.disabled,
              })}
            >
              {moduleIcon(reportDef.module, !!reportDef.disabled)}
              {reportDef.module == BadgeModule.VendorRisk
                ? vendorWords.vendorRiskModuleName
                : reportDef.module}
            </div>
          )}
          <div
            className={`report-title ${reportDef.disabled ? "disabled" : ""}`}
          >
            {reportDef.title}
          </div>
          <div
            className={`report-subtext ${reportDef.disabled ? "disabled" : ""}`}
          >
            {reportDef.subtext}
          </div>
          {onQuickExportClick ? (
            <>
              <Button
                onClick={onQuickExportClick}
                disabled={reportDef.disabled}
              >
                Quick generate
              </Button>
              <Button
                className="customize-export-btn"
                onClick={onGenerateClick}
                disabled={reportDef.disabled}
                tertiary
              >
                Customize and generate
              </Button>
            </>
          ) : !onClick ? (
            <>
              <Button onClick={onGenerateClick} disabled={reportDef.disabled}>
                Generate report
              </Button>
            </>
          ) : undefined}
        </div>
        <div
          className={`rhs ${
            reportDef.disabled ? "disabled" : reportDef.imageRef.className
          }`}
        >
          <img
            src={
              (assuranceType !== AssuranceType.None
                ? reportDef.disabled
                  ? reportDef.imageRef.imageAssuranceDisabled
                  : reportDef.imageRef.imageAssurance
                : reportDef.disabled
                  ? reportDef.imageRef.imageDisabled
                  : reportDef.imageRef.image) ?? reportDef.imageRef.image
            }
          />
          {busy && (
            <div className={"busy"}>
              <LoadingIcon />
            </div>
          )}
        </div>
      </ReportTypeBadgeStyles>
    </SidePopupV2>
  );
};

export default ReportTypeBadge;

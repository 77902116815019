import { FC } from "react";
import UserBaseAPI from "../api/userbase.api";
import { useDefaultHistory } from "../../_common/types/router";
import {
  applicationsUrl,
  dashboardUrl,
  permissionsUrl,
  usersUrl,
} from "../UserBaseAppRouter";
import SingleStat, { SingleStatSize } from "./SingleStat";
import { pluralise } from "../../_common/helpers";
import { DashboardStats } from "../api/types";

export enum DashboardStatToShow {
  UserCount,
  AppCount,
  PermissionCount,
}

export interface DashboardStatProps {
  statToShow: DashboardStatToShow;
  statSize?: SingleStatSize;
  className?: string;
}

const DashboardStat: FC<DashboardStatProps> = ({
  statToShow,
  statSize,
  className,
}) => {
  // Call the API
  const { data, isLoading } = UserBaseAPI.useGetUserBaseDashboardStatsV1Query();

  const { buttonURL, statNum, statDescription, statLinkText } = dashInfoForStat(
    statToShow,
    data?.stats
  );

  const history = useDefaultHistory();
  const onClick = () => {
    history.push(buttonURL, {
      backContext: {
        backTo: dashboardUrl,
        backToText: `Back to Dashboard`,
      },
    });
  };

  return (
    <div>
      <SingleStat
        size={statSize}
        isLoading={isLoading}
        statNum={statNum}
        statDescription={statDescription}
        onClick={onClick}
        statLinkText={statLinkText}
        className={className}
      />
    </div>
  );
};

export default DashboardStat;

interface DashInfo {
  buttonURL: string;
  statNum: number;
  statDescription: string;
  statLinkText: string;
}

function dashInfoForStat(
  statToShow: DashboardStatToShow,
  stats?: DashboardStats
): DashInfo {
  // Determine the base stats number
  const numApps = stats?.numApps ?? 0;
  const numUsers = stats?.numUsers ?? 0;
  const numPermissions = stats?.numPermissions ?? 0;

  switch (statToShow) {
    case DashboardStatToShow.AppCount:
      return {
        statNum: numApps,
        buttonURL: applicationsUrl,
        statDescription: pluralise(numApps, "App", "Total apps"),
        statLinkText: pluralise(numUsers, "View app", "View all apps"),
      };
    case DashboardStatToShow.PermissionCount:
      return {
        statNum: numPermissions,
        buttonURL: permissionsUrl,
        statDescription: pluralise(
          numPermissions,
          "Permission",
          "Total permissions"
        ),
        statLinkText: pluralise(
          numUsers,
          "View permission",
          "View all permissions"
        ),
      };
    default:
      return {
        statNum: numUsers,
        buttonURL: usersUrl,
        statDescription: pluralise(numUsers, "User", "Total users"),
        statLinkText: pluralise(numUsers, "View user", "View all users"),
      };
  }
}

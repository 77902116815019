import React, { FC } from "react";
import classnames from "classnames";

import LoadingIcon from "./LoadingIcon";
import DropDown from "./DropDown";
import Icon from "./Icon";
import Pagination from "./Pagination";

import "../../style/components/core/XTable.scss";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import IconButton, { IIconButtonProps } from "../IconButton";
import { SidePopupV2 } from "../DismissablePopup";
import DropdownV2, { DropdownItem } from "./DropdownV2";

export const stopPropagationFunc =
  (clickFunc?: () => void, skip?: boolean) => (evt: React.MouseEvent) => {
    if (evt && !skip) {
      evt.stopPropagation();
    }

    if (clickFunc) {
      clickFunc();
    }
  };

export interface IXTableCellProps
  extends React.TdHTMLAttributes<HTMLTableCellElement> {
  /** Called when the specific cell is clicked */
  onClick?: () => void;
  /** Apply a class to this td element */
  className?: string;
  /** True if this specific cell is loading */
  loading?: boolean;
  /** The cell contents */
  children?: React.ReactNode;
  /** Optionally skip rendering this cell (NOTE: need to hide column header as well) */
  hide?: boolean;
  /** Optionally only show cell content when the row is hovered */
  showContentOnlyOnHover?: boolean;
  /** Optional override to always show cell content when using showContentOnlyOnHover */
  showContent?: boolean;

  suppressInHotjar?: boolean;
}

/**
 * <XTableCell />
 * XTableCell is a simple td component that adds a "clickable" class to a cell when
 * an onClick option is provided.
 */
export const XTableCell: FC<IXTableCellProps> = ({
  onClick,
  className = "",
  children,
  loading = false,
  hide,
  showContentOnlyOnHover,
  showContent,
  suppressInHotjar,
  ...otherProps
}) => {
  if (hide) {
    return <></>;
  }

  return (
    <td
      className={classnames(className, {
        clickable: typeof onClick === "function",
        loading,
        "show-content-on-hover": showContentOnlyOnHover && !showContent,
      })}
      onClick={onClick ? stopPropagationFunc(onClick) : undefined}
      data-hj-suppress={suppressInHotjar}
      {...otherProps}
    >
      {children}
    </td>
  );
};

interface IXTableRowProps<RowID extends string | number = string | number>
  extends IXTableRow<RowID> {
  skipStopPropagation?: boolean;
  radioSelector?: boolean;
  tableSelectable?: boolean;
  tableSelectionLoading?: boolean;
  tableSelectableColIdx?: number;
  tableIconOptionsEnabled?: boolean;
  tableMeatballsEnabled?: boolean;
  tableExpandableRows?: boolean;
  tableNumColumns: number;
  onExpandToggle?: (rowId: RowID, newExpandState: boolean) => void;
  onSelectClick?: (rowId: RowID, newSelectedState: boolean) => void;
}

function XTableRowInner<RowID extends string | number = string | number>({
  id,
  className,
  selected = false,
  partiallySelected = false,
  onClick,
  meatballDisabled = false,
  expanded = false,
  cells,
  selectionDisabled,
  selectionDisabledHelpText,
  selectionHidden,
  selectionHelpText,
  tableSelectable = false,
  tableSelectionLoading = false,
  skipStopPropagation = false,
  radioSelector = false,
  onSelectClick,
  checkboxRenderOverride,
  tableSelectableColIdx,
  tableIconOptionsEnabled = false,
  iconOptions,
  tableMeatballsEnabled = false,
  meatballOptions,
  tableExpandableRows = false,
  expandDisabled,
  onExpandToggle,
  tableNumColumns,
  expandContent,
  extraContent,
  alwaysShowCellContent,
}: IXTableRowProps<RowID>) {
  // fixes a bug where if the table isn't being rendered every render a new selection box is added each render
  cells = [...cells];

  if (tableSelectable) {
    let selectCell: React.ReactNode;
    if (selectionHidden) {
      selectCell = <td key={"selection-box"} className={"selection-box"} />;
    } else if (tableSelectionLoading) {
      selectCell = (
        <td key={"selection-box"} className={"selection-box"}>
          <LoadingIcon size={12} />
        </td>
      );
    } else if (selectionDisabled && !!selectionDisabledHelpText) {
      selectCell = (
        <td
          key={"selection-box"}
          className="selection-box"
          onClick={stopPropagationFunc(undefined, skipStopPropagation)}
        >
          <SidePopupV2 text={selectionDisabledHelpText} position={"right"}>
            <ColorCheckbox
              disabled
              checked={selected && !partiallySelected}
              radio={radioSelector}
            />
          </SidePopupV2>
        </td>
      );
    } else {
      const checkbox = (
        <ColorCheckbox
          radio={radioSelector}
          checked={selected && !partiallySelected}
          indeterminate={partiallySelected}
          disabled={selectionDisabled}
          onClick={(ev) => {
            if (!skipStopPropagation) {
              ev.stopPropagation();
            }
            onSelectClick
              ? onSelectClick(id, !selected && !partiallySelected)
              : undefined;
          }}
          helpPopup={selectionHelpText}
        />
      );

      const checkBoxRendered = checkboxRenderOverride
        ? checkboxRenderOverride(checkbox)
        : checkbox;

      selectCell = (
        <td
          key={"selection-box"}
          className="selection-box"
          onClick={stopPropagationFunc(undefined, skipStopPropagation)}
        >
          {checkBoxRendered}
        </td>
      );
    }
    cells.splice(
      Math.min(tableSelectableColIdx ?? 0, cells.length - 1),
      0,
      selectCell
    );
  }

  return (
    <>
      <tr
        className={classnames(className, {
          selected,
          clickable: !!onClick,
          expanded,
          "always-show-cell-content": alwaysShowCellContent,
        })}
        key={id}
        onClick={stopPropagationFunc(onClick, skipStopPropagation)}
        aria-selected={selected ? "true" : "false"}
      >
        {cells}
        {tableIconOptionsEnabled && (
          <td className="options-icons-cell">
            {iconOptions &&
              iconOptions.map((i) => {
                const iconButton = (
                  <IconButton
                    className={i.className}
                    key={i.id}
                    hoverText={i.hoverText}
                    hoverColor={i.hoverColor}
                    isPrimaryAction={i.isPrimaryAction}
                    disabled={i.disabled}
                    onClick={i.onClick}
                    icon={i.icon}
                    loading={i.loading}
                    hidden={i.hidden}
                    attention={i.attention}
                    hoverLocation={i.hoverLocation}
                    hoverMicro={i.hoverMicro}
                    popupWrap={i.popupWrap}
                  />
                );
                return !i.dropdownItems ? (
                  iconButton
                ) : (
                  <DropdownV2
                    key={i.id}
                    className={i.dropdownClassName}
                    popupItem={iconButton}
                    stopPropagation
                    testId={i.testId ?? i.id}
                    autoCloseOnMouseLeave={i.autoCloseOnMouseLeave ?? true}
                  >
                    {i.dropdownItems}
                  </DropdownV2>
                );
              })}
          </td>
        )}
        {tableMeatballsEnabled && (
          <td className="meatball-cell">
            {meatballOptions && meatballOptions.length > 0 && (
              <DropdownV2
                popupItem={<Icon className="pointer" name="meatball" />}
                disabled={meatballDisabled}
                stopPropagation
                shouldBottomAlign
              >
                {meatballOptions.map((opt) => (
                  <DropdownItem
                    key={opt.id}
                    onClick={stopPropagationFunc(
                      opt.onClick,
                      skipStopPropagation
                    )}
                  >
                    {opt.text}
                  </DropdownItem>
                ))}
              </DropdownV2>
            )}
          </td>
        )}
        {tableExpandableRows && (
          <td
            className={classnames("expand-cell", {
              disabled: !!expandDisabled,
            })}
            onClick={
              !expandDisabled
                ? (e) => {
                    if (onExpandToggle) {
                      if (!skipStopPropagation) {
                        e.stopPropagation();
                      }
                      onExpandToggle(id, !expanded);
                    }
                  }
                : undefined
            }
          >
            {!expandDisabled && (
              <Icon name="chevron" direction={expanded ? 0 : 180} />
            )}
          </td>
        )}
      </tr>
      {!!extraContent && (
        <tr className={"extra-content-row"}>
          <td colSpan={tableNumColumns} className={"extra-content-cell"}>
            {extraContent}
          </td>
        </tr>
      )}
      {/* We need to always load the <tr> due to height behaviour on <tr>'s. We apply the transition to the content. */}
      {(tableExpandableRows || expanded) && (
        <tr className="expanded-content-row">
          <td
            colSpan={tableNumColumns}
            className={`expanded-content-cell ${
              expanded ? "expanded" : "collapsed"
            }`}
          >
            <TransitionGroup component={null}>
              {expanded && expandContent && (
                <CSSTransition
                  key={"expand-row"}
                  timeout={250}
                  classNames="expand"
                >
                  {expandContent}
                </CSSTransition>
              )}
            </TransitionGroup>
          </td>
        </tr>
      )}
    </>
  );
}

export enum SortDirection {
  DESC = "desc",
  ASC = "asc",
}

export interface ISortedBy {
  columnId: string;
  direction: SortDirection;
}

export type OnSortChange<T extends string = string> = (
  columnId: T,
  newSortDir: SortDirection
) => void;

export interface IXTableColumnHeader<ColumnID extends string = string> {
  /** Unique ID of column header. This is used in the onSortChange callback if it is a sortable column. */
  id: ColumnID;
  /** Text of column header. Pass empty string for no header. */
  text: React.ReactNode;
  /** Classname to apply to th element */
  className?: string;
  /** Help text for a tooltip on the column header. */
  helpText?: React.ReactNode;
  /** If true, help text will occupy a single line, with ellipsis used for overflow. */
  helpTextNoWrap?: boolean;
  /** If true, help text font size will be smaller **/
  helpTextMicro?: boolean;
  /** Determines the width of the help tooltip **/
  helpTextWidth?: number;
  /** When starting to sort by this column, what should be the sortDir. */
  startingSortDir?: SortDirection;
  /** If true, column header is clickable and will display sort direction */
  sortable?: boolean;
  leftalign?: boolean;
  /** Optionally skip rendering column header (NOTE: need to hide row cell as well) */
  hide?: boolean;
  /** If true can not be unselected when using selectable columns **/
  displayLocked?: boolean;
  /** If true defaults to not displaying when using selectable columns **/
  noDefaultDisplay?: boolean;
}

export interface IXTableRow<RowID extends string | number = string | number> {
  /** Unique ID of a row, used in the onSelectClick callback. */
  id: RowID;
  /** Class name to apply to the tr element */
  className?: string;
  /** If included, entire row will become clickable and have hover styles applied */
  onClick?: () => void;
  /** If true, show row and checkbox in selected state. */
  selected?: boolean;
  /** If true, show checkbox in an "indeterminate" state */
  partiallySelected?: boolean;
  /** If true, when the table has selection enabled this specific row will not be selectable */
  selectionDisabled?: boolean;
  /** Optional explanation of why this row is not selectable (shown as tooltip). */
  selectionDisabledHelpText?: string;
  /** Hide the checkbox if this is a selectable table **/
  selectionHidden?: boolean;
  /** Optional explanation shown as tooltip around the select checkbox. */
  selectionHelpText?: string;
  /** If true, show expandContent in a row directly below this one. */
  expanded?: boolean;
  /** If true, when the table has expanding enabled this specific row will not be expandable. */
  expandDisabled?: boolean;
  /** The content to show when the row is expanded. */
  expandContent?: React.ReactNode;
  /** Extra content to show below the row regardless of if the row is expanded or not **/
  extraContent?: React.ReactNode;
  /** If true, show the meatball in disabled state. */
  meatballDisabled?: boolean;
  /** Array of meatball option objects. Dropdown menu will display if this array has any elements. */
  meatballOptions?: {
    /** Unique ID of option in dropdown */
    id: string | number;
    /** Text of dropdown option */
    text: React.ReactNode;
    /** Function called on dropdown item click */
    onClick: () => void;
  }[];
  /** Array of icon options, displayed on the right edge of the row */
  iconOptions?: IIconOption[];
  /** Array of React elements to use for the row's cells. They should be provided in column order and generally be wrapped in a <td> or <XTableCell> tag. */
  cells: React.ReactNode[];
  /** Optional override for rendering the selection checkbox for a row. Callback passes the default checkbox render if the parent wants to use that. */
  checkboxRenderOverride?: (
    defaultCheckbox: React.ReactNode
  ) => React.ReactNode;
  /** Optionally always show cell content even if would be hidden via XTableCell.showContentOnlyOnHover */
  alwaysShowCellContent?: boolean;
}

export interface IIconOption extends Omit<IIconButtonProps, "popupNoWrap"> {
  /** Unique id of icon */
  id: string;
  dropdownClassName?: string;
  dropdownItems?: React.ReactNode;
  autoCloseOnMouseLeave?: boolean;
  testId?: string;

  // Default no-wrap on for table-based tooltips
  popupWrap?: boolean;
}

export interface IPagination {
  /** The current page number, starting at 1 */
  currentPage: number;
  /** The total number of pages, must be >= 1 */
  totalPages: number;
  /** Called with the new page when the page is changed */
  onPageChange: (newPage: number) => void;

  hidePaginationIfSinglePage?: boolean;
}

export interface IXTableProps<
  RowID extends string | number = string | number,
  ColumnID extends string = string,
> {
  /** Classname to add to the surrounding table element */
  className?: string;
  /** If true, a loading icon will display overlaid on the table's contents */
  loading?: boolean;
  /** Set the number of placeholder rows shown when the table is loading and there is no row data */
  numLoadingRows?: number;
  /** If true, show selection icons next to each row */
  selectable?: boolean;
  /** If true, selection icons will be radio buttons */
  radioSelector?: boolean;
  /** If true, rows should be expandable */
  expandableRows?: boolean;
  /** Must be set to true if any rows have meatballs set. Not compatible with iconOptions */
  meatballs?: boolean;
  /** Ignored unless iconOptions is true. Sets the column header for the icon options column. */
  iconOptionsHeader?: string;
  /** Must be set to true if any rows have iconOptions set. Not compatible with meatballs */
  iconOptions?: boolean;
  /** Called when a specific row is selected */
  onSelectClick?: (rowId: RowID, newSelectedState: boolean) => void;
  /** Called when the select all control is toggled */
  onSelectToggle?: (selectAll: boolean) => void;
  /** Called when the select none button is clicked */
  onSelectNoneClick?: () => void;
  /** Called when the select all button is clicked */
  onSelectAllClick?: () => void;
  /** Selected options are loading, shows a loading spinner instead of checkboxes **/
  selectionLoading?: boolean;
  /** Called when a column is clicked to change the sort column and/or direction */
  onSortChange?: OnSortChange<ColumnID>;
  /** Called when a row expansion is toggled */
  onExpandToggle?: (rowId: RowID, newExpandState: boolean) => void;
  /** Defines the state of sorting for displaying the column headers correctly */
  sortedBy?: ISortedBy;
  /** Defines the column headers. Number of columns must exactly match the number of cells included in each row */
  columnHeaders?: IXTableColumnHeader<ColumnID>[];
  /** Defines the number of columns if columnHeaders is not incuded */
  numColumns?: number;
  /** If true, column headers are shown as sticky on scroll */
  stickyColumnHeaders?: boolean;
  /** The array of rows to show in the table */
  rows: IXTableRow<RowID>[];
  /** If included, pagination will be shown at the bottom of the table */
  pagination?: IPagination;
  /** Optionally hide column headers */
  hideColumnHeaders?: boolean;
  /** Optional 0-based index for the selectable column. Defaults to 0. */
  selectableColIdx?: number;
  /** Optional content to display when there are no rows displayable and not loading */
  emptyContent?: React.ReactNode;
  /** Optional width to be used for the table */
  width?: string;
  /** Optional span for the pagination row this is used for the vendor list card where the table needs horizontal scrolling but the pagination row needs to be sticky */
  paginationRowColSpan?: number;
  /** Optionally don't stopPropagation() on cell/row click events */
  skipStopPropagation?: boolean;
  /** Apply compact styles to the table */
  compact?: boolean;
}

/**
 * <XTable />
 * XTable is a fully controlled table component. The wrapper of this component is responsible
 * for providing row data, and handling selections and sorting via callback props.
 */
class XTable<
  RowID extends string | number = string | number,
  ColumnID extends string = string,
> extends React.Component<IXTableProps<RowID, ColumnID>> {
  static defaultProps = {
    className: "",
    loading: false,
    numLoadingRows: 5,
    selectable: false,
    radioSelector: false,
    expandableRows: false,
    meatballs: false,
    iconOptions: false,
    stickyColumnHeaders: false,
  };

  anySelected(): boolean {
    for (let i = 0; i < this.props.rows.length; i++) {
      if (this.props.rows[i].selected) {
        return true;
      }
    }

    return false;
  }

  anySelectable(): boolean {
    for (let i = 0; i < this.props.rows.length; i++) {
      if (!this.props.rows[i].selectionDisabled) {
        return true;
      }
    }

    return false;
  }

  allSelected(): boolean {
    for (let i = 0; i < this.props.rows.length; i++) {
      if (
        this.props.rows[i].selected !== true &&
        this.props.rows[i].selectionDisabled !== true
      ) {
        return false;
      }
    }

    return true;
  }

  loadingRows = (): IXTableRow<RowID>[] => {
    const loadingRows: IXTableRow<RowID>[] = [];
    const numLoadingRows = this.props.numLoadingRows ?? 5;
    for (let i = 0; i < numLoadingRows; i++) {
      const cells = [];
      if (this.props.columnHeaders) {
        for (
          let j = 0;
          j < this.props.columnHeaders.filter((ch) => !ch.hide).length;
          j++
        ) {
          cells.push(<XTableCell loading key={`loading-${j}`} />);
        }
      }
      loadingRows.push({
        id: `loading-row-${i}` as RowID,
        cells,
      });
    }

    return loadingRows;
  };

  renderColumnHeader = (col: IXTableColumnHeader<ColumnID>) => {
    const active =
      this.props.sortedBy && this.props.sortedBy.columnId === col.id;

    const newSortDir = ((): SortDirection => {
      if (!active) return col.startingSortDir || SortDirection.DESC;
      if (this.props.sortedBy?.direction === SortDirection.DESC)
        return SortDirection.ASC;
      return SortDirection.DESC;
    })();
    let rotateCls = "";
    if (
      (active && this.props.sortedBy?.direction === SortDirection.DESC) ||
      (!active &&
        (!col.startingSortDir || col.startingSortDir === SortDirection.DESC))
    ) {
      rotateCls = "rotate-180";
    }

    return (
      <th
        key={col.id}
        className={classnames(col.className || "", {
          sortable: col.sortable,
          "sort-active": active,
          "left-align": col.leftalign,
          "with-help-text": !!col.helpText,
        })}
        onClick={
          col.sortable
            ? () => {
                if (this.props.onSortChange) {
                  this.props.onSortChange(col.id, newSortDir);
                }
              }
            : undefined
        }
      >
        {col.text}
        {!!col.helpText && (
          <SidePopupV2
            className="help-icon"
            text={col.helpText}
            popupHoverable
            noWrap={col.helpTextNoWrap}
            micro={col.helpTextMicro}
            width={col.helpTextWidth}
          >
            <Icon name="info" />
          </SidePopupV2>
        )}
        {(col.sortable || active) && (
          <Icon name="arrow" className={rotateCls} />
        )}
      </th>
    );
  };

  renderRow = (row: IXTableRow<RowID>) => {
    return (
      <XTableRowInner<RowID>
        key={row.id}
        {...row}
        skipStopPropagation={this.props.skipStopPropagation}
        radioSelector={this.props.radioSelector}
        tableSelectable={this.props.selectable}
        tableSelectionLoading={this.props.selectionLoading}
        tableSelectableColIdx={this.props.selectableColIdx}
        tableIconOptionsEnabled={this.props.iconOptions}
        tableMeatballsEnabled={this.props.meatballs}
        tableExpandableRows={this.props.expandableRows}
        tableNumColumns={this.getNumColumns()}
        onExpandToggle={this.props.onExpandToggle}
        onSelectClick={this.props.onSelectClick}
      />
    );
  };

  getNumColumns() {
    let numColumns = 0;
    if (this.props.numColumns) {
      numColumns = this.props.numColumns;
    } else if (this.props.columnHeaders) {
      numColumns = this.props.columnHeaders.filter((ch) => !ch.hide).length;
    }

    if (this.props.selectable) {
      numColumns += 1;
    }
    if (this.props.meatballs) {
      numColumns += 1;
    }
    if (this.props.iconOptions) {
      numColumns += 1;
    }
    if (this.props.expandableRows) {
      numColumns += 1;
    }
    return numColumns;
  }

  render() {
    const { selectable, loading } = this.props;
    const anySelected = this.props.selectable && this.anySelected();

    const numColumns = this.getNumColumns();

    const paginationRowSpan = this.props.paginationRowColSpan
      ? this.props.paginationRowColSpan
      : numColumns;

    const colHeaders = this.props.columnHeaders
      ? this.props.columnHeaders
          .filter((ch) => !ch.hide)
          .map(this.renderColumnHeader)
      : [];

    if (this.props.selectable) {
      const selectColHeader = (
        <th className="selection-box" key={"selection-box-header"}>
          {this.props.onSelectAllClick && this.anySelectable() && (
            <DropDown
              id="selection-box"
              action={
                <div className="selection-dropdown">
                  <ColorCheckbox
                    checked={this.allSelected()}
                    onClick={(e) => {
                      if (!this.props.skipStopPropagation) {
                        e.stopPropagation();
                      }
                      if (this.props.onSelectToggle) {
                        this.props.onSelectToggle(!this.allSelected());
                      }
                    }}
                  />
                  <Icon name="chevron" />
                </div>
              }
            >
              <div
                key="all"
                className="pointer"
                onClick={this.props.onSelectAllClick}
              >
                All
              </div>
              <div
                key="none"
                className="pointer"
                onClick={this.props.onSelectNoneClick}
              >
                None
              </div>
            </DropDown>
          )}
        </th>
      );

      colHeaders.splice(
        Math.min(this.props.selectableColIdx ?? 0, colHeaders.length - 1),
        0,
        selectColHeader
      );
    }

    const style: React.CSSProperties = {};
    if (this.props.width) {
      style.width = this.props.width;
    }

    return (
      <div
        className={classnames("x-table", this.props.className, {
          loading,
          sticky: this.props.stickyColumnHeaders,
          compact: this.props.compact,
        })}
        style={style}
      >
        <div className="loading-spinner">
          <LoadingIcon />
        </div>
        <table className={classnames({ selectable, anySelected })}>
          {this.props.columnHeaders && !this.props.hideColumnHeaders && (
            <thead>
              <tr>
                {colHeaders}
                {this.props.iconOptions && (
                  <th className="options-icons-cell">
                    {this.props.iconOptionsHeader}
                  </th>
                )}
                {this.props.meatballs && <th className="meatball-cell" />}
                {this.props.expandableRows && <th className="expand-cell" />}
              </tr>
            </thead>
          )}
          <tbody>
            {this.props.loading && this.props.rows.length === 0
              ? this.loadingRows().map(this.renderRow)
              : this.props.rows.map(this.renderRow)}
            {this.props.pagination &&
              (this.props.pagination.totalPages > 1 ||
                !this.props.pagination.hidePaginationIfSinglePage) && (
                <tr className="pagination-row">
                  <td colSpan={paginationRowSpan}>
                    <Pagination
                      currentPage={this.props.pagination.currentPage}
                      totalPages={this.props.pagination.totalPages}
                      onPageChange={this.props.pagination.onPageChange}
                    />
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        {!this.props.loading &&
          this.props.rows.length === 0 &&
          this.props.emptyContent &&
          this.props.emptyContent}
      </div>
    );
  }
}

export default XTable;

import { FC, memo, ReactNode } from "react";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { NavLink as _NavLink, NavLinkProps } from "react-router-dom";
import classnames from "classnames";
import { LabelColor } from "../types/label";
import InAppMessage, {
  InAppMessageDismissBehaviour,
  InAppMessageType,
} from "./InAppMessage";
import { SidePopupV2 } from "./DismissablePopup";

import "../style/components/NavLink.scss";
import BetaLabel from "./BetaLabel";

interface INewFeatureInAppMessagePopupContentProps {
  featureName: string;
  description: string;
  learnMoreLink?: string;
}

export const NewFeatureInAppMessagePopupContent: FC<
  INewFeatureInAppMessagePopupContentProps
> = ({ featureName, description, learnMoreLink }) => (
  <div className="new-feature-popup-content">
    <div className="title">
      {featureName}{" "}
      <PillLabel filled color={LabelColor.Blue}>
        New Feature
      </PillLabel>
    </div>
    <div className="description">{description}</div>
    {learnMoreLink && (
      <a
        href={learnMoreLink}
        target="_blank"
        rel="noopener noreferrer"
        className="learn-more-link"
      >
        Learn more <div className="cr-icon-arrow-right" />
      </a>
    )}
  </div>
);

interface INavLinkProps {
  icon?: string;
  text: ReactNode;
  hoverText?: string;
  exact?: boolean;
  onClick?: () => void;
  isSubMenuItem?: boolean;
  disabled?: boolean;
  className?: string;
  to: string;
  badge?: string;
  showBadgeOnIcon?: boolean;
  featurePreviewAvailable?: boolean;
  hasOrgPermission?: boolean;
  hasUserPermission?: boolean;
  isActive?: NavLinkProps["isActive"];

  // If set, show an in-app message popup next to nav item that will be dismissed on click
  inAppMessageType?: InAppMessageType;
  inAppMessageText?: ReactNode;
  isBeta?: boolean; // If set, show a beta label next to nav item instead of unread dot, only applicable when inAppMessageText being set

  // If set, show an in-app alert popup next to nav item
  inAppAlertText?: ReactNode;
}

const NavLink: FC<INavLinkProps> = ({
  icon = "",
  text,
  hoverText = "",
  exact = false,
  to,
  onClick,
  isSubMenuItem = false,
  disabled = false,
  className = "",
  badge = "",
  showBadgeOnIcon = false,
  featurePreviewAvailable = false,
  hasOrgPermission,
  hasUserPermission,
  isActive,
  inAppMessageType,
  inAppMessageText,
  inAppAlertText,
  isBeta,
}) => {
  const link = (
    <_NavLink
      className={classnames("nav-link", className, {
        clickable: !disabled && !!onClick,
        disabled,
        "sub-menu-item": isSubMenuItem,
      })}
      onClick={!disabled ? onClick : undefined}
      to={to}
      exact={exact}
      isActive={isActive}
    >
      {icon && !isSubMenuItem && (
        <div className={"nav-icon-container"}>
          <span className={`nav-icon cr-icon-${icon}`} />
          {badge && showBadgeOnIcon && (
            <span className="badge-parent">
              <span className={"badge"}>{`${badge}`}</span>
            </span>
          )}
        </div>
      )}
      {isSubMenuItem && <div className="sub-menu-icon" />}
      {badge && !showBadgeOnIcon && (
        <span className="badge-parent">
          <span className={"badge"}>{`${badge}`}</span>
        </span>
      )}
      {inAppMessageType && inAppMessageText && (
        <InAppMessage
          messageType={inAppMessageType}
          dismissBehaviour={InAppMessageDismissBehaviour.OnClick}
          renderIfMessageShown={
            <SidePopupV2
              text={inAppMessageText}
              position="right"
              width={335}
              popupHoverable
            >
              <div className="nav-link-in-app-message">
                {text}{" "}
                {isBeta ? (
                  <BetaLabel color={LabelColor.LightBlue} />
                ) : (
                  <div className="unread-dot" />
                )}
              </div>
            </SidePopupV2>
          }
          renderIfMessageHidden={<span title={hoverText}>{text}</span>}
        />
      )}
      {inAppAlertText && (
        <SidePopupV2
          className={"nav-link-alert-popup"}
          text={inAppAlertText}
          position="right"
          width={320}
          popupHoverable
        >
          <div className="nav-link-in-app-message">
            {text} <div className="alert-dot" />
          </div>
        </SidePopupV2>
      )}
      {(!inAppMessageType || !inAppMessageText) && !inAppAlertText && (
        <span title={hoverText}>{text}</span>
      )}
    </_NavLink>
  );

  // If checking permissions within <NavLink> component
  if (hasOrgPermission !== undefined && hasUserPermission !== undefined) {
    if (featurePreviewAvailable && !hasOrgPermission) {
      // Feature preview available, so still show link
      return link;
    } else if (!hasOrgPermission || !hasUserPermission) {
      // Org or user doesn't have access, so hide link
      return <></>;
    }
  } else if (
    hasOrgPermission !== undefined ||
    hasUserPermission !== undefined
  ) {
    throw new Error(
      "Must provide values for both hasOrgPermission and hasUserPermission"
    );
  }

  return link;
};

export default memo(NavLink);

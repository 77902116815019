import Button from "../../../_common/components/core/Button";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";
import { Surface, SurfacePosition } from "../../../_common/components/Surface";
import { FC, useState } from "react";
import DateFilter, {
  IDateFilterState,
  isDateFilterActive,
} from "../../../vendorrisk/components/filter/DateFilter";
import "../../style/components/modals/AutofillModalFilter.scss";
import PillLabel, {
  getColorForKey,
} from "../../../vendorrisk/components/PillLabel";
import { sortBy } from "lodash";

export interface DocumentFilterState {
  trustPageOnly: boolean;
  selectedDocumentTypes: string[];
  dateFilter: IDateFilterState;
  filterText: string;
}

export const initialDocumentFilterState = (): DocumentFilterState => ({
  trustPageOnly: false,
  selectedDocumentTypes: [],
  dateFilter: {},
  filterText: "",
});

export const documentsFilterActive = (filter: DocumentFilterState): boolean =>
  filter.trustPageOnly ||
  filter.selectedDocumentTypes.length > 0 ||
  isDateFilterActive(filter.dateFilter);

export const documentFilterText = (filter: DocumentFilterState): string => {
  const parts = [];

  if (filter.trustPageOnly) {
    parts.push("Trust pages");
  }
  if (filter.selectedDocumentTypes.length > 0) {
    parts.push("Type");
  }
  if (isDateFilterActive(filter.dateFilter)) {
    parts.push("Last updated");
  }

  return parts.join(", ");
};

interface DocumentSelectionFilterProps {
  initialFilterState: DocumentFilterState;
  setFilterState: (filterState: DocumentFilterState) => void;
  documentTypes: string[];
  active: boolean;
  onClose: () => void;
}

export const DocumentSelectionFilter: FC<DocumentSelectionFilterProps> = ({
  active,
  initialFilterState,
  setFilterState,
  documentTypes,
  onClose,
}) => {
  const [documentFilterState, _setDocumentFilterState] =
    useState(initialFilterState);
  const setDocumentFilterState = (val: Partial<DocumentFilterState>) =>
    _setDocumentFilterState((prev) => ({ ...prev, ...val }));

  const docTypeOptions = sortBy(
    [
      {
        value: "Platform questionnaire",
        label: "Platform Questionnaires",
      },
      ...documentTypes.map((dt) => ({
        value: dt,
        label: dt,
      })),
    ],
    "label"
  );

  return (
    <Surface
      position={SurfacePosition.Left}
      active={active}
      width={420}
      classNames={"document-filter-panel"}
    >
      <div className={"row"}>
        <div className={"left bold"}>Show trust page documents only</div>
        <ToggleSwitch
          name={"shared-only"}
          selected={documentFilterState.trustPageOnly}
          onClick={() =>
            setDocumentFilterState({
              trustPageOnly: !documentFilterState.trustPageOnly,
            })
          }
        />
      </div>
      <div className={"filter-grid"}>
        <div className={"left bold"}>Type</div>
        <div className={"select-area"}>
          <SelectV2Multi<OptionType<string>>
            className={"doc-types-filter"}
            options={docTypeOptions}
            value={documentFilterState.selectedDocumentTypes.map((t) => ({
              value: t,
              label: t,
            }))}
            usePortal
            placeholder={"Type to search"}
            isSearchable
            hideSelectedOptions
            controlShouldRenderValue={false}
            onChange={(selectedOptions) =>
              setDocumentFilterState({
                selectedDocumentTypes:
                  selectedOptions?.map((o) => o.value) ?? [],
              })
            }
          />
          {documentFilterState.selectedDocumentTypes.length > 0 && (
            <div className={"row selected-doc-types"}>
              {documentFilterState.selectedDocumentTypes.map((d) => (
                <PillLabel
                  key={d}
                  removeable
                  onRemoveClick={() =>
                    setDocumentFilterState({
                      selectedDocumentTypes:
                        documentFilterState.selectedDocumentTypes.filter(
                          (dd) => d != dd
                        ),
                    })
                  }
                  color={getColorForKey(d)}
                >
                  {d}
                </PillLabel>
              ))}
            </div>
          )}
        </div>
        <div className={"left bold"}>Last updated</div>
        <DateFilter
          title={""}
          onChange={(val) => setDocumentFilterState({ dateFilter: val })}
          noSidePanel
          state={documentFilterState.dateFilter}
        />
      </div>
      <div className={"row bottom"}>
        <div className={"left"}>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className={"buttons"}>
          <Button
            onClick={() =>
              setDocumentFilterState({
                dateFilter: {},
                selectedDocumentTypes: [],
                trustPageOnly: false,
              })
            }
          >
            Reset
          </Button>
          <Button
            filledPrimary
            onClick={() => {
              setFilterState(documentFilterState);
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Surface>
  );
};

export default DocumentSelectionFilter;

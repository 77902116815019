import {
  Button,
  Dialog,
  DialogTrigger,
  OverlayArrow,
  OverlayTriggerStateContext,
  Popover,
  PopoverProps,
} from "react-aria-components";
import {
  Children,
  PropsWithChildren,
  ReactNode,
  isValidElement,
  useContext,
} from "react";

import styles from "./PopOver.module.scss";
import classNames from "classnames";

interface PopOverProps extends PropsWithChildren {
  /*
    The placement of the popover relative to the reference element 
  */
  placement: "top" | "right" | "bottom" | "left";
  /**
    Controls the location of where the arrow is relative to the dialog box. 
    The arrow will always appear over the target element as determined by 
    the @placement option

    None removes the arrow

    Note: bottom, left and right placements can only be center or none. setting
    left and right yield no effect. This is a contraint from design not a techincal one
  */
  arrowLocation: "left" | "center" | "right" | "none";
  variant: "dark" | "light"; // switches between the dark and light variant
}

interface PopOverHeaderProps extends PropsWithChildren {
  variant?: "dark" | "light";
}

const PopOverHeader = ({ children, variant = "light" }: PopOverHeaderProps) => {
  // Provides a hook back into the popup trigger
  const state = useContext(OverlayTriggerStateContext)!;

  const titleClassName = classNames(styles.title, {
    [styles.dark]: variant === "dark",
  });

  return (
    <div className={styles.popOverHeader}>
      <div className={titleClassName}>{children}</div>
      <Button
        aria-label="close-button"
        onPress={() => state.close()}
        className={styles.closeButton}
      >
        <i className={classNames("icon-close", styles.closeIcon)} />
      </Button>
    </div>
  );
};

type PopOverFooterProps = PropsWithChildren & PopoverProps;

const PopOverFooter = ({ children }: PopOverFooterProps) => {
  return (
    <div role="pop-over-footer" className={styles.popOverFooter}>
      {children}
    </div>
  );
};

/**
 * Provides a Aria Compliant Popover component. For more information on the
 * inner workings, see here https://react-spectrum.adobe.com/react-aria/Popover.html
 * The component must be wrapped in a PopOver.Trigger that wraps the trigger element
 * as the first child prop.
 *
 * Example usage:
 * Below is an example implementation of the component. Note that since our
 * existing button component doesn't comply with the DialogTrigger interface we
 * have to wrap the Button in a AriaButton from the aria library.
 *
 * import Button from "./core/Button";
 * import { Button as AriaButton, DialogTrigger } from "react-aria-components";
 * import { PopOver } from "./PopOver";
 *
 * <PopOver.Trigger>
 *   <AriaButton>
 *     <Button>Test the Popup</Button>
 *   </AriaButton>
 *   <PopOver placement="right" arrowLocation="center" variant="light">
 *     <PopOver.Header>Im a test header</PopOver.Header>
 *     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
 *     tempor incididunt ut labore et dolore magna aliqua. Facilisi nullam vehicula
 *     ipsum a arcu cursus vitae. Sed blandit libero volutpat sed cras ornare arcu.
 *     Enim diam vulputate ut pharetra sit amet. Placerat duis ultricies lacus sed
 *     turpis tincidunt id aliquet.
 *     <PopOver.Footer>
 *       <a href="https://example.com">
 *         <Button primary link arrow>
 *           View support article
 *         </Button>
 *       </a>
 *     </PopOver.Footer>
 *   </PopOver>
 * </PopOver.Trigger>;
 */
export const PopOver = ({
  placement,
  arrowLocation,
  variant,
  children,
}: PopOverProps) => {
  const childrenArray = Children.toArray(children);

  // Separate children into Header, Footer, and "Body Content"
  const header = childrenArray.find(
    (child) => isValidElement(child) && child.type === PopOverHeader
  );
  const footer = childrenArray.find(
    (child) => isValidElement(child) && child.type === PopOverFooter
  );
  const bodyContent: ReactNode[] = childrenArray.filter(
    (child) =>
      !isValidElement(child) ||
      (child.type !== PopOverHeader && child.type !== PopOverFooter)
  );

  const baseClasses = classNames(styles.popOver, {
    [styles.dark]: variant === "dark",
    [styles.hasTitle]: header !== undefined,
    [styles.hasFooter]: footer !== undefined,
    [styles.arrowLeft]: arrowLocation === "left",
    [styles.arrowRight]: arrowLocation === "right",
  });

  return (
    <Popover className={baseClasses} placement={placement}>
      {arrowLocation !== "none" && (
        <OverlayArrow className={styles.overlayArrow}>
          <svg width="16" height="9" viewBox="0 0 16 9">
            <path d="M1.92894 8.51471C1.03804 8.51471 0.591868 7.43757 1.22183 6.8076L7.2929 0.736529C7.68342 0.346004 8.31658 0.346005 8.70711 0.736529L14.7782 6.8076C15.4081 7.43757 14.962 8.51471 14.0711 8.51471L1.92894 8.51471Z" />
          </svg>
        </OverlayArrow>
      )}
      <Dialog className={styles.dialog}>
        {header}
        {bodyContent.length > 0 && (
          <div className={styles.body}>{bodyContent}</div>
        )}
        {footer && (
          <>
            <hr className={styles.divider} />
            {footer}
          </>
        )}
      </Dialog>
    </Popover>
  );
};

PopOver.Header = PopOverHeader;
PopOver.Footer = PopOverFooter;
PopOver.Trigger = DialogTrigger;

import XTable, {
  IPagination,
  ISortedBy,
  IXTableColumnHeader,
  IXTableProps,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import React from "react";
import { getVendorWords } from "../../_common/constants";
import { formatDateAsLocal, pluralise } from "../../_common/helpers";
import ActionsButton from "../../_common/components/ActionsButton";
import {
  IWithPermissionsProps,
  OrgExtraQuestionnaireStatusChanges,
  OrgQuestionnaireScores,
  withPermissions,
} from "../../_common/permissions";
import {
  excludeSurveyFromScoring,
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
  fetchVendorSurveyCounts,
} from "../reducers/cyberRiskActions";
import "../style/components/SurveyListCard.scss";
import EmptyCard from "./EmptyCard";
import PillLabel from "./PillLabel";
import {
  deleteQuestionnaire,
  restoreCancelledQuestionnaire,
} from "../../_common/components/QuestionnaireStatusCard";
import ReportCard from "../../_common/components/ReportCard";
import TabButtons from "../../_common/components/TabButtons";
import { LabelColor } from "../../_common/types/label";
import EmptyCardWithAction, {
  ErrorCardWithAction,
} from "../../_common/components/EmptyCardWithAction";
import CircleDocAttachmentSvg from "../images/circle_doc_attachment.svg";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import ModifyResendDateModal, {
  IModifyResendDateModalProps,
} from "../../_common/components/surveydetails/ModifyResendDateModal";
import { getSurveyEditorPath } from "../../_common/views/SurveyDetails";
import ConfirmationModalV2, {
  useConfirmationModalV2Props,
} from "../../_common/components/modals/ConfirmationModalV2";
import { surveyListPageLimit, SurveyTab } from "../views/Questionnaires";
import ColorGrade, { ColorGradeSize } from "./executive_summary/ColorGrade";
import Score from "./Score";
import SurveyName from "./surveys/SurveyName";
import UnwatchedVendorCard from "./UnwatchedVendorCard";
import {
  getSurveyListV2,
  SurveyListV2RespItem,
} from "../reducers/survey.actions";
import SurveyStatusDisplay from "./surveys/SurveyStatusDisplay";
import {
  SurveyUsageType,
  SurveyUsageTypeDisplayName,
} from "../../_common/types/surveyTypes";
import {
  DefaultThunkDispatch,
  VendorSurveyCount,
} from "../../_common/types/redux";
import { History } from "history";
import { SurveyStatus } from "../../_common/types/survey";
import UserAvatar from "../../_common/components/UserAvatar";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import ExcludeSharedProfileFromRiskProfileModal from "../../_common/components/modals/ExcludeSharedSurveyFromRiskProfileModal";
import { AssuranceType } from "../../_common/types/organisations";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { CorrespondenceIconButton } from "./CorrespondenceIconButton";
import { appConnect } from "../../_common/types/reduxHooks";
import {
  markAsInReview,
  markAsAwaitingReview,
  markAsComplete,
  archiveOrUnarchiveSurvey,
  cancelSurvey,
} from "../../_common/components/surveydetails/SurveyDetailsStatusDropdown";
import { archiveOrUnarchiveSharedSurvey } from "../../_common/components/surveydetails/SharedSurveyDetailsStatusDropdown";
import CompanyLogo from "../../_common/components/CompanyLogo";

interface SurveyListTableStylesProps extends IXTableProps {
  className?: string;
}
// Wrapper for use whenever the survey-list-table styles are required for use elsewhere in the app. Using
// this component ensures this component's styles will be loaded.
export const SurveyListTableStyles = ({
  className,
  ...rest
}: SurveyListTableStylesProps) => (
  <XTable className={`survey-list-table ${className}`} {...rest} />
);

interface ISurveyListTableProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  loading: boolean;
  userHasWriteSurveysPermission: boolean;
  surveys: SurveyListV2RespItem[];
  sortedBy: ISortedBy;
  pagination?: IPagination;
  onSortChange?: (columnId: string, direction: string) => void;
  showVendorColumn?: boolean;
  onCreateSurvey: (
    resendSurvey?: SurveyListV2RespItem | undefined,
    vendorId?: number
  ) => void;
  stickyColumnHeaders?: boolean;
  isResendList?: boolean;
  vendorId?: number;
  vendorName?: string;
  vendorIsManaged?: boolean;
  urlPrefix: (vendorId: number) => string;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  userIsManagedVendorAnalyst?: boolean;
  backTo: string;
  backToText: string;
  assuranceType: AssuranceType;
  currentOrgIsInAccountGroup: boolean;
  resendOnClick?: boolean;
  currentTab: SurveyTab;
  setCurrentTab: (newTab: SurveyTab) => void;
  ownOrgID: number;
  hasScoreFeaturesEnabled: boolean;
  orgHasExtraQuestionnaireStatusChanges: boolean;
}

interface ISurveyListTableState {
  showExcludeFromScoreModal?: {
    surveyID?: number;
    exclude?: boolean;
    show: boolean;
    vendorID?: number;
    vendorName?: string;
  };
  modifyResendModalProps?: Omit<
    IModifyResendDateModalProps,
    "dispatch" | "active" | "onClose"
  >;
  excludeRiskProfileModal?: {
    exclude: boolean;
    surveyId: number;
    vendorId: number;
  };
  confirmationModalProps?: useConfirmationModalV2Props;
}

export class SurveyListTable extends React.Component<
  ISurveyListTableProps,
  ISurveyListTableState
> {
  static defaultProps = {
    isFullList: false,
    showVendorColumn: false,
    isSharedList: false,
    resendOnClick: false,
    vendorId: null,
    vendorName: null,
    isManagementAnalystSession: false,
    managedOrgId: 0,
    userIsManagedVendorAnalyst: false,
    assuranceType: AssuranceType.None,
    currentOrgIsInAccountGroup: false,
    currentTab: "active",
    setCurrentTab: null,
    hasScoreFeaturesEnabled: true,
    orgHasExtraQuestionnaireStatusChanges: false,
  };

  state = {} as ISurveyListTableState;

  openConfirmationModal = (
    confirmationModalProps: useConfirmationModalV2Props
  ) => {
    this.setState({ confirmationModalProps });
  };

  resendSurvey = (survey: SurveyListV2RespItem, vendorId: number) => {
    this.props.onCreateSurvey(survey, vendorId);
  };

  setToggleOnExcludeFromRiskProfileModal = (
    show: boolean,
    exclude?: boolean,
    surveyID?: number,
    vendorID?: number
  ) => {
    this.setState({
      excludeRiskProfileModal: show
        ? {
            exclude: exclude ?? false,
            surveyId: surveyID ?? 0,
            vendorId: vendorID ?? 0,
          }
        : undefined,
    });
  };

  setToggleOnExcludeFromScoreModal = (
    show: boolean,
    surveyID?: number,
    vendorID?: number,
    vendorName?: string,
    exclude?: boolean
  ) => {
    this.setState({
      showExcludeFromScoreModal: {
        show: show,
        surveyID: surveyID,
        vendorID: vendorID,
        vendorName: vendorName,
        exclude: exclude,
      },
    });
  };

  setExcludedFromVendorScore = async () => {
    const vendorWords = getVendorWords(this.props.assuranceType);
    try {
      await this.props.dispatch(
        excludeSurveyFromScoring(
          this.state.showExcludeFromScoreModal?.surveyID,
          this.state.showExcludeFromScoreModal?.exclude
        )
      );
      const proms: Promise<any>[] = [
        this.props.dispatch(
          getSurveyListV2(
            true,
            undefined,
            0,
            surveyListPageLimit,
            "date_due",
            "desc"
          )
        ),
      ];
      if (this.state.showExcludeFromScoreModal?.vendorID) {
        proms.push(
          this.props.dispatch(
            getSurveyListV2(
              true,
              this.state.showExcludeFromScoreModal.vendorID,
              0,
              surveyListPageLimit,
              "date_due",
              "desc"
            )
          )
        );
        proms.push(
          this.props.dispatch(
            fetchVendorSurveyCounts(
              this.state.showExcludeFromScoreModal.vendorID,
              true
            )
          )
        );
      }

      await Promise.all(proms);
      this.props.dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
      this.props.dispatch(
        fetchVendorSummaryAndCloudscans(
          this.state.showExcludeFromScoreModal?.vendorID ?? 0,
          true
        )
      );
      this.props.dispatch(
        addDefaultSuccessAlert(
          this.state.showExcludeFromScoreModal?.exclude
            ? `Successfully excluded questionnaire from ${vendorWords.singular} scoring.`
            : `Successfully included questionnaire in ${vendorWords.singular} scoring`
        )
      );
    } catch (e: any) {
      this.props.dispatch(addSimpleErrorAlert(e.message));
      throw e;
    }
  };

  getRows = () => {
    const vendorWords = getVendorWords(this.props.assuranceType);
    return this.props.surveys.map((survey) => {
      const currentOrgId =
        this.props.managedOrgId && this.props.managedOrgId > 0
          ? this.props.managedOrgId
          : this.props.ownOrgID;
      const ownSurvey = !survey.isPublic && survey.orgId == currentOrgId;

      const userHasWriteSurveysPermission =
        (this.props.userHasWriteSurveysPermission &&
          !this.props.vendorIsManaged) ||
        // (survey.sharable && !this.props.vendorIsManaged) || TODO: not sure why shareable (but not write permission) drives being able to archive etc?
        (this.props.userIsManagedVendorAnalyst && this.props.vendorIsManaged);
      const userHasWriteOwnSurveysPermission =
        ownSurvey && userHasWriteSurveysPermission;

      const hasRisks = !!survey.numPossibleRisks;

      const meatballActions = {} as Record<string, () => void>;

      if (!survey.hasAccess && this.props.userHasWriteSurveysPermission) {
        meatballActions["Request access"] = () => {
          this.props.history.push(
            survey.isPublic && this.props.currentOrgIsInAccountGroup
              ? `${this.props.urlPrefix(
                  survey.datastoreVendorID
                )}/sharedassets/vendor`
              : survey.isPublic
                ? `${this.props.urlPrefix(
                    survey.datastoreVendorID
                  )}/sharedassessment`
                : `${this.props.urlPrefix(
                    survey.datastoreVendorID
                  )}/sharedassets/${survey.orgId}`,
            {
              backContext: {
                backTo: this.props.backTo,
                backToText: this.props.backToText,
              },
            }
          );
        };
      }

      if (this.props.isResendList) {
        meatballActions["Details"] = () => {
          this.props.history.push(`/surveys/${survey.surveyId}`, {
            backTo: this.props.backTo,
            backToText: this.props.backToText,
          });
        };
      }

      if (survey.archived) {
        if (userHasWriteSurveysPermission) {
          meatballActions["Unarchive"] = () => {
            if (!ownSurvey) {
              archiveOrUnarchiveSharedSurvey(
                this.props.dispatch,
                this.openConfirmationModal,
                {
                  id: survey.surveyId,
                  vendorId: survey.datastoreVendorID,
                  isPrefilledSurvey: survey.isPublic,
                },
                false
              );
            } else {
              archiveOrUnarchiveSurvey(
                this.props.dispatch,
                this.openConfirmationModal,
                {
                  id: survey.surveyId,
                  usageType: SurveyUsageType.Security,
                  vendorId: survey.datastoreVendorID,
                  risksInRemediation: survey.numRisksInRemediation,
                },
                false
              );
            }
          };
          if (userHasWriteOwnSurveysPermission) {
            meatballActions["Delete"] = () => {
              deleteQuestionnaire(
                this.props.dispatch,
                survey.surveyId,
                survey.datastoreVendorID
              );
            };
          }
        }
      } else {
        switch (survey.status) {
          case SurveyStatus.Sent:
          case SurveyStatus.InProgress: {
            if (userHasWriteOwnSurveysPermission) {
              meatballActions["Cancel"] = () =>
                cancelSurvey(this.props.dispatch, this.openConfirmationModal, {
                  id: survey.surveyId,
                  vendorId: survey.datastoreVendorID,
                  risksInRemediation: survey.numRisksInRemediation,
                });
            }
            break;
          }
          case SurveyStatus.AwaitingReview:
          case SurveyStatus.InReview: {
            if (survey.hasAccess) {
              meatballActions["View answers"] = () =>
                this.props.history.push(
                  getSurveyEditorPath({
                    surveyId: survey.surveyId,
                    editMode: false,
                    isManagementAnalystSession:
                      this.props.isManagementAnalystSession,
                    managedOrgId: this.props.managedOrgId,
                    location: this.props.history.location,
                  })
                );
            }

            if (userHasWriteOwnSurveysPermission) {
              if (survey.status === SurveyStatus.AwaitingReview) {
                meatballActions["Mark as 'In Review'"] = () =>
                  markAsInReview(
                    this.props.dispatch,
                    this.openConfirmationModal,
                    {
                      id: survey.surveyId,
                      vendorId: survey.datastoreVendorID,
                    },
                    false,
                    this.props.isManagementAnalystSession
                  );
              }

              if (survey.status === SurveyStatus.InReview) {
                meatballActions["Mark as 'Awaiting Review'"] = () =>
                  markAsAwaitingReview(
                    this.props.dispatch,
                    this.openConfirmationModal,
                    {
                      id: survey.surveyId,
                      vendorId: survey.datastoreVendorID,
                    },
                    false,
                    this.props.isManagementAnalystSession
                  );
              }

              if (
                survey.status === SurveyStatus.AwaitingReview ||
                survey.status === SurveyStatus.InReview
              ) {
                meatballActions["Mark as 'Complete'"] = () =>
                  markAsComplete(
                    this.props.dispatch,
                    this.openConfirmationModal,
                    {
                      id: survey.surveyId,
                      vendorId: survey.datastoreVendorID,
                    },
                    this.props.isManagementAnalystSession
                  );
              }

              meatballActions["Cancel"] = () =>
                cancelSurvey(this.props.dispatch, this.openConfirmationModal, {
                  id: survey.surveyId,
                  vendorId: survey.datastoreVendorID,
                  risksInRemediation: survey.numRisksInRemediation,
                });
            }
            break;
          }
          case SurveyStatus.Complete: {
            if (survey.hasAccess) {
              meatballActions["View answers"] = () =>
                this.props.history.push(
                  getSurveyEditorPath({
                    surveyId: survey.surveyId,
                    editMode: false,
                    isManagementAnalystSession:
                      this.props.isManagementAnalystSession,
                    managedOrgId: this.props.managedOrgId,
                    location: this.props.history.location,
                  })
                );
            }
            if (userHasWriteOwnSurveysPermission) {
              meatballActions["Resend questionnaire"] = () =>
                this.resendSurvey(survey, survey.datastoreVendorID);
              if (this.props.orgHasExtraQuestionnaireStatusChanges) {
                meatballActions["Reopen as 'In Review'"] = () =>
                  markAsInReview(
                    this.props.dispatch,
                    this.openConfirmationModal,
                    {
                      id: survey.surveyId,
                      vendorId: survey.datastoreVendorID,
                    },
                    true,
                    this.props.isManagementAnalystSession
                  );
                meatballActions["Reopen as 'Awaiting Review'"] = () =>
                  markAsAwaitingReview(
                    this.props.dispatch,
                    this.openConfirmationModal,
                    {
                      id: survey.surveyId,
                      vendorId: survey.datastoreVendorID,
                    },
                    true,
                    this.props.isManagementAnalystSession
                  );
              }
            }
            break;
          }
          case SurveyStatus.Cancelled: {
            if (userHasWriteOwnSurveysPermission) {
              if (this.props.orgHasExtraQuestionnaireStatusChanges) {
                meatballActions["Restore questionnaire"] = () => {
                  restoreCancelledQuestionnaire(
                    this.props.dispatch,
                    survey.surveyId,
                    survey.datastoreVendorID,
                    survey.previousStatus,
                    survey.dueDate,
                    this.props.isManagementAnalystSession
                  );
                };
                meatballActions["Resend questionnaire"] = () =>
                  this.resendSurvey(survey, survey.datastoreVendorID);
              }
            }
            break;
          }
        }

        if (userHasWriteOwnSurveysPermission) {
          if (survey.status !== SurveyStatus.Cancelled) {
            meatballActions["Modify resend schedule"] = () =>
              this.setState({
                modifyResendModalProps: {
                  surveyId: survey.surveyId,
                  vendorId: survey.datastoreVendorID,
                  resendDate: survey.resendDueDate,
                },
              });
          }

          if (
            hasRisks &&
            !survey.archived &&
            this.props.hasScoreFeaturesEnabled
          ) {
            meatballActions[
              survey.isExcludedFromScoring
                ? `Include in ${vendorWords.singular} scoring`
                : `Exclude from ${vendorWords.singular} scoring`
            ] = () => {
              this.setToggleOnExcludeFromScoreModal(
                true,
                survey.surveyId,
                survey.datastoreVendorID,
                survey.vendorName,
                !survey.isExcludedFromScoring
              );
            };
          }
        }

        if (userHasWriteSurveysPermission) {
          meatballActions["Archive"] = () => {
            if (!ownSurvey) {
              archiveOrUnarchiveSharedSurvey(
                this.props.dispatch,
                this.openConfirmationModal,
                {
                  id: survey.surveyId,
                  vendorId: survey.datastoreVendorID,
                  isPrefilledSurvey: survey.isPublic,
                },
                true
              );
            } else {
              archiveOrUnarchiveSurvey(
                this.props.dispatch,
                this.openConfirmationModal,
                {
                  id: survey.surveyId,
                  usageType: SurveyUsageType.Security,
                  vendorId: survey.datastoreVendorID,
                  risksInRemediation: survey.numRisksInRemediation,
                },
                true
              );
            }
          };
        }
      }

      if (survey.isPublic && survey.hasAccess) {
        meatballActions["View answers"] = () =>
          this.props.history.push(
            getSurveyEditorPath({
              surveyId: survey.surveyId,
              editMode: false,
              isManagementAnalystSession: this.props.isManagementAnalystSession,
              managedOrgId: this.props.managedOrgId,
              location: this.props.history.location,
              publicSurvey: true,
              vendorId: survey.datastoreVendorID,
            })
          );
      }

      if (
        survey.isPublic &&
        survey.hasAccess &&
        survey.canInclude &&
        !survey.archived &&
        hasRisks
      ) {
        meatballActions[
          survey.isExcludedFromScoring
            ? "Include in risk profile"
            : "Exclude from risk profile"
        ] = () =>
          this.setToggleOnExcludeFromRiskProfileModal(
            true,
            survey.isExcludedFromScoring,
            survey.surveyId,
            survey.datastoreVendorID
          );
      }

      let progress = `0%`;
      if (survey.numQuestions > 0) {
        progress = `${Math.floor(
          (survey.numAnswers / survey.numQuestions) * 100
        )}%`;
      }

      const maybeVendorCell = this.props.showVendorColumn
        ? [
            <XTableCell key="vendor" className="vendor-name">
              <CompanyLogo
                size={"small"}
                name={survey.vendorName}
                domain={survey.vendorHostname}
              />
            </XTableCell>,
          ]
        : [];

      const resendDueCell = [
        <XTableCell key="resend_date" className="date-due">
          {ownSurvey &&
          survey.resendDueDate &&
          survey.status !== SurveyStatus.Cancelled ? (
            <>{formatDateAsLocal(survey.resendDueDate)}</>
          ) : (
            <span>—</span>
          )}
        </XTableCell>,
      ];

      // only show risks detected cell if we don't have to show the vendor column to save space
      const maybeRisksDetectedCell = !this.props.showVendorColumn
        ? [
            <XTableCell key="risks-detected" className="risks-detected">
              {survey.numPossibleRisks > 0 ? (
                <>
                  {survey.numActiveRisks}{" "}
                  {survey.numRisksInRemediation > 0 &&
                    `(${survey.numRisksInRemediation} in remediation)`}
                </>
              ) : (
                "—"
              )}
            </XTableCell>,
          ]
        : [];

      const score = !survey.isExcludedFromScoring ? survey.score : -1;

      const navigateToSurveyDetails = (openComments?: boolean) => {
        if (survey.isPublic) {
          this.props.history.push(
            `${this.props.urlPrefix(survey.datastoreVendorID)}/${
              this.props.currentOrgIsInAccountGroup
                ? "sharedassets/vendor"
                : "sharedassessment"
            }/surveys/${survey.surveyId}`,
            {
              backContext: {
                backTo: this.props.backTo,
                backToText: this.props.backToText,
              },
            }
          );
        } else {
          const currentOrgId =
            this.props.managedOrgId && this.props.managedOrgId > 0
              ? this.props.managedOrgId
              : this.props.ownOrgID;

          if (survey.orgId != currentOrgId) {
            this.props.history.push(
              `${this.props.urlPrefix(survey.datastoreVendorID)}/sharedassets/${
                survey.orgId
              }/surveys/${survey.surveyId}`,
              {
                backContext: {
                  backTo: this.props.backTo,
                  backToText: this.props.backToText,
                },
              }
            );
          } else if (this.props.showVendorColumn) {
            this.props.history.push(`/surveys/${survey.surveyId}`, {
              openComments: openComments,
              backContext: {
                backTo: this.props.backTo,
                backToText: this.props.backToText,
              },
            });
          } else {
            this.props.history.push(
              `${this.props.urlPrefix(survey.datastoreVendorID)}/surveys/${
                survey.surveyId
              }`,
              {
                openComments: openComments,
                backContext: {
                  backTo: this.props.backTo,
                  backToText: this.props.backToText,
                },
              }
            );
          }
        }
      };

      const dateDueCell = [
        <XTableCell key="date_due" className="date-due">
          {survey.dueDate ? formatDateAsLocal(survey.dueDate) : ""}
        </XTableCell>,
      ];

      const sourceCell = ownSurvey
        ? [
            <XTableCell key={"source"} className={"sent-by"}>
              <UserAvatar
                avatar={survey.source.fromUser?.avatar ?? ""}
                name={survey.source.fromUser?.name}
                hoverPopup={
                  survey.source.fromUser?.name ? (
                    <span>
                      {survey.source.fromUser?.name}
                      <br />
                      <em>{survey.source.fromUser?.email}</em>
                    </span>
                  ) : (
                    <span>
                      <em>{survey.source.fromUser?.email}</em>
                    </span>
                  )
                }
              />
            </XTableCell>,
          ]
        : [
            <XTableCell key="org" className="org-name-cell">
              {survey.isPublic ? (
                <div className={"org-name-contents"}>
                  <div className={"org-name"}>{survey.vendorName}</div>
                  <PillLabel color={LabelColor.Grey}>
                    {vendorWords.singularTitleCase}
                  </PillLabel>
                </div>
              ) : (
                survey.orgName
              )}
            </XTableCell>,
          ];

      const maybeScoreCell = this.props.hasScoreFeaturesEnabled
        ? [
            <XTableCell key="score" className="cstar-score-cell">
              {survey.status != "pending" &&
                survey.status != "cancelled" &&
                survey.status != "opened" &&
                survey.status != "autofilled" &&
                survey.status != "autofillreview" &&
                hasRisks && (
                  <SidePopupV2
                    className={"grade-with-score"}
                    position={"top"}
                    micro
                    text={
                      survey.isExcludedFromScoring
                        ? `This questionnaire does not count towards the ${vendorWords.singular}'s overall questionnaire score rating.`
                        : undefined
                    }
                  >
                    {score !== -1 && (
                      <ColorGrade size={ColorGradeSize.Small} score={score} />
                    )}
                    <Score
                      score={score}
                      small
                      colored
                      qualifierText={
                        survey.isExcludedFromScoring ? "*" : undefined
                      }
                    />
                  </SidePopupV2>
                )}
            </XTableCell>,
          ]
        : [];

      const cells = [
        <XTableCell key="name">
          <SurveyName
            survey={{
              name: survey.questionnaireName,
              type: survey.questionnaireTypeName,
            }}
          />
        </XTableCell>,
        ...maybeVendorCell,
        ...dateDueCell,
        <XTableCell key="status" className="survey-status">
          <SurveyStatusDisplay
            survey={{
              dueDate: survey.dueDate,
              status: survey.status,
              numQuestions: survey.numQuestions,
              numAnswers: survey.numAnswers,
              risksInRemediation: survey.numRisksInRemediation,
            }}
            displaySharedPill={{
              display: !ownSurvey,
              tooltip: survey.orgOriginalPrivateSurveyID
                ? {
                    title: "Why are there two of the same questionnaire?",
                    text: "This questionnaire comes from this organization's Trust Page and has been shared with you.",
                  }
                : undefined,
            }}
            hideStatusPill={survey.status === SurveyStatus.Published}
          />
        </XTableCell>,
        <XTableCell key="progress" className="survey-status">
          {progress}
        </XTableCell>,
        ...sourceCell,
        ...maybeScoreCell,
        ...maybeRisksDetectedCell,
        ...resendDueCell,
        <XTableCell key="messages" className="messages">
          {!survey.isPublic && survey.numMessages > 0 && (
            <CorrespondenceIconButton
              totalMessages={survey.numMessages}
              haveUnreadMessages={survey.numUnreadMessages > 0}
              disabled={!survey.hasAccess}
              onClick={() => navigateToSurveyDetails(true)}
            />
          )}
        </XTableCell>,
        <XTableCell key="icons" className="icons">
          <div className="icons-container">
            {survey.numAttachments > 0 && (
              <SidePopupV2
                text={`${survey.numAttachments} ${pluralise(
                  survey.numAttachments,
                  "document",
                  "documents"
                )}`}
                micro
                noWrap
              >
                <i className={"cr-icon-q-builder-attachment"} />
              </SidePopupV2>
            )}
          </div>
        </XTableCell>,
        <XTableCell key="actions" className="actions-cell">
          <>
            {this.props.userHasWriteSurveysPermission && (
              <ActionsButton
                disabled={Object.keys(meatballActions).length === 0}
                actions={Object.keys(meatballActions).map((key) => {
                  const func = meatballActions[key];
                  return { text: key, onClick: () => func() };
                })}
              />
            )}
          </>
        </XTableCell>,
      ];

      return {
        id: `${survey.surveyId}:${survey.isPublic}`,
        className: !survey.hasAccess ? "disabled-row" : "",
        onClick: !survey.hasAccess
          ? undefined
          : () => navigateToSurveyDetails(),
        cells: cells,
      };
    });
  };

  render() {
    const vendorWords = getVendorWords(this.props.assuranceType);
    const columnHeaders: IXTableColumnHeader[] = [];
    columnHeaders.push({
      id: "name",
      text: "Name",
      sortable: !!this.props.onSortChange,
      startingSortDir: SortDirection.ASC,
    });

    if (this.props.showVendorColumn) {
      columnHeaders.push({
        id: "vendor_name",
        text: vendorWords.singularTitleCase,
        sortable: !!this.props.onSortChange,
        startingSortDir: SortDirection.ASC,
      });
    }

    columnHeaders.push({
      id: "date_due",
      text: "Date due",
      sortable: !!this.props.onSortChange,
      startingSortDir: SortDirection.DESC,
    });

    columnHeaders.push({
      id: "status",
      text: "Status",
      sortable: !!this.props.onSortChange,
      startingSortDir: SortDirection.DESC,
    });
    columnHeaders.push({
      id: "progress",
      text: "% complete",
      sortable: !!this.props.onSortChange,
      startingSortDir: SortDirection.DESC,
    });

    columnHeaders.push({
      id: "source",
      text: "Source",
      sortable: !!this.props.onSortChange,
      startingSortDir: SortDirection.ASC,
    });

    if (this.props.hasScoreFeaturesEnabled) {
      columnHeaders.push({
        id: "score",
        text: "Score",
        sortable: !!this.props.onSortChange,
        startingSortDir: SortDirection.DESC,
      });
    }

    if (!this.props.showVendorColumn) {
      columnHeaders.push({
        id: "risks_detected",
        text: "Risks detected",
        sortable: false,
      });
    }

    columnHeaders.push({
      id: "resend_date",
      text: "Resend due",
      sortable: !!this.props.onSortChange,
      startingSortDir: SortDirection.DESC,
    });

    columnHeaders.push({
      id: "messages",
      text: "Messages",
      sortable: false,
      startingSortDir: SortDirection.DESC,
    });

    columnHeaders.push(
      { id: "icons", text: "", sortable: false },
      { id: "actions", text: "", sortable: false }
    );

    return (
      <>
        <SurveyListTableStyles
          loading={this.props.loading}
          sortedBy={this.props.sortedBy}
          onSortChange={this.props.onSortChange}
          columnHeaders={columnHeaders}
          rows={this.getRows()}
          stickyColumnHeaders={this.props.stickyColumnHeaders}
          pagination={this.props.pagination}
        />
        {this.state.modifyResendModalProps && (
          <ModifyResendDateModal
            dispatch={this.props.dispatch}
            active
            onClose={() => this.setState({ modifyResendModalProps: undefined })}
            {...this.state.modifyResendModalProps}
          />
        )}
        <ConfirmationModalV2
          title={
            this.state.showExcludeFromScoreModal &&
            this.state.showExcludeFromScoreModal.exclude
              ? `Exclude from ${vendorWords.singular} scoring?`
              : `Include in ${vendorWords.singular} scoring?`
          }
          description={
            this.state.showExcludeFromScoreModal
              ? this.state.showExcludeFromScoreModal.exclude
                ? `Do you want this questionnaire to be excluded from the overall questionnaire score for ${vendorWords.singular} ${this.state.showExcludeFromScoreModal.vendorName}?`
                : `Do you want this questionnaire to contribute to the overall questionnaire score for ${vendorWords.singular} ${this.state.showExcludeFromScoreModal.vendorName}?`
              : `Do you want this questionnaire to contribute to the overall questionnaire score for this ${vendorWords.singular}?`
          }
          buttonText="Confirm"
          cancelText="Cancel"
          buttonAction={async () => {
            await this.setExcludedFromVendorScore();
          }}
          active={
            this.state.showExcludeFromScoreModal
              ? this.state.showExcludeFromScoreModal.show
              : false
          }
          onClose={() => {
            this.setToggleOnExcludeFromScoreModal(false);
          }}
        />
        {!!this.state.confirmationModalProps && (
          <ConfirmationModalV2
            active
            onClose={() => this.setState({ confirmationModalProps: undefined })}
            {...this.state.confirmationModalProps}
          />
        )}
        {this.state.excludeRiskProfileModal && (
          <ExcludeSharedProfileFromRiskProfileModal
            dispatch={this.props.dispatch}
            active
            onClose={() => this.setToggleOnExcludeFromRiskProfileModal(false)}
            {...this.state.excludeRiskProfileModal}
          />
        )}
      </>
    );
  }
}

interface ISurveyListCardConnectedProps {
  currentOrgIsInAccountGroup?: boolean;
  ownOrgID: number;
  hasScoreFeaturesEnabled: boolean;
  orgHasExtraQuestionnaireStatusChanges: boolean;
}

interface ISurveyListCardOwnProps {
  history: History;
  loading: boolean;
  error: boolean;
  onCreateSurvey: (
    resendSurvey?: SurveyListV2RespItem | undefined,
    vendorId?: number
  ) => void;
  surveys: SurveyListV2RespItem[];
  resendDueSurveys: SurveyListV2RespItem[];
  pagination: IPagination;
  sortedBy: ISortedBy;
  onSortChange: (columnId: string, direction: string) => void;
  watchedState?: boolean;
  watchedStateLoading?: boolean;
  onVendorWatched: () => void;
  dispatch: DefaultThunkDispatch;
  vendorId?: string;
  vendorName?: string;
  vendorPrimaryHostname?: string;
  isFullList?: boolean;
  filterActive?: boolean;
  userHasWriteSurveysPermission: boolean;
  vendorIsManaged: boolean;
  userIsManagedVendorAnalyst: boolean;
  urlPrefix: (vendorId: number) => string;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  currentTab: SurveyTab;
  setCurrentTab: (newTab: SurveyTab) => void;
  mayHaveSharedAssets: boolean;
  surveyCounts?: VendorSurveyCount;
  assuranceType: AssuranceType;
  filterText: string;
  onFilterTextChange: (val: string) => void;
}

type ISurveyListCardProps = ISurveyListCardOwnProps &
  ISurveyListCardConnectedProps &
  IWithPermissionsProps;

// SurveyListCard displays a list of surveys, or loading or errors states
// dependant on the props passed in.
export class SurveyListCard extends React.Component<ISurveyListCardProps> {
  static defaultProps = {
    loading: false,
    surveyIds: [],
    watchedState: false,
    watchedStateLoading: true,
    isFullList: false,
    filterActive: false,
    isManagementAnalystSession: false,
    managedOrgId: 0,
    assuranceType: AssuranceType.None,
  };

  render() {
    const {
      loading,
      error,
      surveys,
      resendDueSurveys,
      watchedState,
      watchedStateLoading,
      assuranceType,
      hasScoreFeaturesEnabled,
      orgHasExtraQuestionnaireStatusChanges,
    } = this.props;

    const vendorWords = getVendorWords(assuranceType);

    const noun = "questionnaires";
    const userHasWriteSurveysPermission =
      (this.props.userHasWriteSurveysPermission &&
        !this.props.vendorIsManaged) ||
      (this.props.userIsManagedVendorAnalyst && this.props.vendorIsManaged);

    if (this.props.vendorId && !watchedStateLoading && !watchedState) {
      // The customer is not following this vendor. (The list is showing surveys for only one vendor)
      return (
        <UnwatchedVendorCard
          vendorId={parseInt(this.props.vendorId ?? "0")}
          vendorName={this.props.vendorName ?? ""}
          vendorPrimaryHostname={this.props.vendorPrimaryHostname ?? ""}
          text={`This ${vendorWords.singular} must be monitored in order to access ${noun}.`}
          onWatched={this.props.onVendorWatched}
        />
      );
    }

    let emptyResults;
    const surveysToUse =
      this.props.currentTab == "upcoming" ? resendDueSurveys : surveys;

    if (
      error ||
      (!(watchedStateLoading || loading) && surveysToUse.length === 0)
    ) {
      if (error) {
        emptyResults = (
          <ErrorCardWithAction
            errorText={"An error occurred loading questionnaire data"}
          />
        );
      } else if (this.props.filterText != "") {
        emptyResults = (
          <SearchEmptyCard
            searchString={this.props.filterText}
            searchItemText={"questionnaires"}
            onClear={() => this.props.onFilterTextChange("")}
          />
        );
      } else if (this.props.filterActive) {
        emptyResults = (
          <EmptyCard text="No questionnaires match the current filter." />
        );
      } else if (this.props.currentTab === "archived") {
        emptyResults = (
          <EmptyCardWithAction
            iconSrc={CircleDocAttachmentSvg}
            emptyText={"No questionnaires are archived."}
          />
        );
      } else if (this.props.vendorId && !this.props.vendorIsManaged) {
        emptyResults = (
          <EmptyCardWithAction
            iconSrc={CircleDocAttachmentSvg}
            emptyText={"You have no active questionnaires"}
            emptySubText={`You haven't sent any questionnaires to this ${vendorWords.singular} yet, or all questionnaires have been archived or cancelled.`}
            actionButtonText={"Send questionnaire"}
            onActionClick={() => this.props.onCreateSurvey()}
            actionDisabled={!userHasWriteSurveysPermission}
          />
        );
      } else if (this.props.vendorId && this.props.vendorIsManaged) {
        emptyResults = (
          <EmptyCardWithAction
            iconSrc={CircleDocAttachmentSvg}
            emptyText={`There are no active questionnaires for this managed ${vendorWords.singular}.`}
          />
        );
      } else {
        emptyResults = (
          <EmptyCardWithAction
            iconSrc={CircleDocAttachmentSvg}
            emptyText={"You have no active questionnaires"}
            emptySubText={`You haven't sent any questionnaires yet, or all questionnaires have been archived or cancelled.`}
            actionButtonText={"Send questionnaire"}
            onActionClick={() => this.props.onCreateSurvey()}
            actionDisabled={!userHasWriteSurveysPermission}
          />
        );
      }
    }

    return (
      <>
        <ReportCard newStyles className="survey-list-card">
          <div className="header">
            {SurveyUsageTypeDisplayName[SurveyUsageType.Security]}
            <SearchBox
              value={this.props.filterText}
              onChanged={this.props.onFilterTextChange}
              placeholder={"Search questionnaires"}
            />
          </div>
          <TabButtons
            onChangeTab={this.props.setCurrentTab}
            tabs={[
              {
                id: "active",
                text: this.props.surveyCounts
                  ? `Active (${this.props.surveyCounts.active})`
                  : "Active",
              },
              {
                id: "archived",
                text: this.props.surveyCounts
                  ? `Archived (${this.props.surveyCounts.archived})`
                  : "Archived",
              },
              ...((this.props.surveyCounts?.dueForResend ?? 0) > 0
                ? [
                    {
                      id: "upcoming" as SurveyTab,
                      text: `Upcoming (${this.props.surveyCounts?.dueForResend})`,
                    },
                  ]
                : []),
            ]}
            disabled={watchedStateLoading || loading}
            noDisabledStyles
            activeTabId={this.props.currentTab}
          />
          {emptyResults ? (
            emptyResults
          ) : (
            <SurveyListTable
              dispatch={this.props.dispatch}
              history={this.props.history}
              loading={watchedStateLoading || !!loading}
              pagination={
                this.props.currentTab == "upcoming"
                  ? undefined
                  : this.props.pagination
              }
              sortedBy={this.props.sortedBy}
              onSortChange={this.props.onSortChange}
              userHasWriteSurveysPermission={userHasWriteSurveysPermission}
              surveys={surveysToUse}
              isResendList={false}
              assuranceType={this.props.assuranceType}
              showVendorColumn={this.props.isFullList}
              onCreateSurvey={this.props.onCreateSurvey}
              vendorId={
                this.props.vendorId ? parseInt(this.props.vendorId) : undefined
              }
              vendorName={this.props.vendorName}
              stickyColumnHeaders
              urlPrefix={this.props.urlPrefix}
              isManagementAnalystSession={this.props.isManagementAnalystSession}
              managedOrgId={this.props.managedOrgId}
              userIsManagedVendorAnalyst={this.props.userIsManagedVendorAnalyst}
              backTo={
                this.props.vendorId
                  ? `${this.props.urlPrefix(
                      parseInt(this.props.vendorId)
                    )}/surveys`
                  : "/surveys"
              }
              backToText="Back to Questionnaires List"
              currentOrgIsInAccountGroup={this.props.currentOrgIsInAccountGroup}
              currentTab={this.props.currentTab}
              setCurrentTab={this.props.setCurrentTab}
              ownOrgID={this.props.ownOrgID}
              hasScoreFeaturesEnabled={hasScoreFeaturesEnabled}
              orgHasExtraQuestionnaireStatusChanges={
                orgHasExtraQuestionnaireStatusChanges
              }
            />
          )}
        </ReportCard>
      </>
    );
  }
}

export default withPermissions(
  appConnect<ISurveyListCardConnectedProps, never, ISurveyListCardOwnProps>(
    (state, _) => {
      const { userData } = state.common;
      let currentOrgIsInAccountGroup = false;
      if (userData.orgList && userData.currentOrgID > 0) {
        for (let i = 0; i < userData.orgList.length; i++) {
          if (userData.orgList[i].id === userData.currentOrgID) {
            currentOrgIsInAccountGroup =
              !!userData.orgList[i].organisationGroupId;
            break;
          }
        }
      }

      const hasScoreFeaturesEnabled =
        !!state.common.permissions.org[OrgQuestionnaireScores];

      const orgHasExtraQuestionnaireStatusChanges =
        !!state.common.permissions.org[OrgExtraQuestionnaireStatusChanges];

      const c: ISurveyListCardConnectedProps = {
        currentOrgIsInAccountGroup,
        ownOrgID: userData.currentOrgID,
        hasScoreFeaturesEnabled: hasScoreFeaturesEnabled,
        orgHasExtraQuestionnaireStatusChanges:
          orgHasExtraQuestionnaireStatusChanges,
      };

      return c;
    }
  )(SurveyListCard)
);

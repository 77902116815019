import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  VendorOverlayInjectedProps,
  wrapInVendorOverlay,
} from "./VendorOverlayWrapper";

import {
  fetchVendorRiskWaivers,
  getPublicVendorRiskWaiversFromState,
  getVendorRiskWaiversFromState,
} from "../reducers/vendorRiskWaiver.actions";
import VendorRiskWaiversCard, {
  mergeWaiverArrays,
} from "../components/VendorRiskWaiversCard";
import {
  IVendorRiskWaiver,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import {
  getUserPermissionsForVendorFromState,
  UserManageVendorRiskWaivers,
  UserSystemRoleVendorManagementAnalyst,
} from "../../_common/permissions";
import { InfoBar } from "../../_common/components/InfoBar";
import { getSearchParamAsNumber } from "../../_common/helpers/search.helpers";
import PageHeader from "../../_common/components/PageHeader";
import { getVendorPageBreadcrumbs } from "../../_common/components/Breadcrumbs";
import Button from "../../_common/components/core/Button";
import UnwatchedVendorCard from "../components/UnwatchedVendorCard";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { getVendorWords, RiskProfileRiskTypes } from "../../_common/constants";
import { AssuranceType } from "../../_common/types/organisations";
import { PublicRiskWaiver } from "../types/sharedAssessment";
import { vendorUrlPrefix } from "../../_common/helpers";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { useModalV2 } from "../../_common/components/ModalV2";
import ExportReportModal from "../components/modals/ExportReportModal";
import {
  ExportFiletypeXLSX,
  ExportType,
} from "../../_common/types/exportReport";
import { fetchVendorSummaryAndCloudscans } from "../reducers/cyberRiskActions";
import PivotTabs, { PivotTab } from "../../_common/components/PivotTabs";
import { History } from "history";
import { appConnect } from "../../_common/types/reduxHooks";
import { tabButtonsStylingType } from "../../_common/components/TabButtons";
import { useBreadcrumbs } from "../../_common/hooks";

export interface VendorRiskWaiversLocationState extends locationState {
  waiverId?: number;
  publicWaiverId?: number;
  currentTab?: VendorRiskWaiverTabType;
}

type VendorRiskWaiversOwnProps = DefaultRouteProps<
  { currentTab?: VendorRiskWaiverTabType },
  VendorRiskWaiversLocationState
> &
  VendorOverlayInjectedProps;

interface VendorRiskWaiversConnectedProps {
  riskWaivers?: IVendorRiskWaiver[];
  publicWaivers?: PublicRiskWaiver[];
  canManageVendorRiskWaivers: boolean;
  assuranceType: AssuranceType;
  hasWaivableRisks: boolean;
  hasAdjustableRisks: boolean;
}

type VendorRiskWaiversProps = VendorRiskWaiversOwnProps &
  VendorRiskWaiversConnectedProps &
  DefaultThunkDispatchProp;

export enum VendorRiskWaiverTabType {
  Waivers = "waivers",
  SeverityAdjustments = "severityadjustments",
}

const VendorRiskWaivers: FC<VendorRiskWaiversProps> = ({
  dispatch,
  location,
  history,
  riskWaivers,
  publicWaivers,
  canManageVendorRiskWaivers,
  isManagementAnalystSession,
  managedOrgId,
  assuranceType,
  vendorId,
  vendorName,
  vendorPrimaryHostname,
  watching,
  match,
  hasWaivableRisks,
  hasAdjustableRisks,
}) => {
  const [openExportReportModal, exportReportModal] =
    useModalV2(ExportReportModal);
  const [isLoading, setIsLoading] = useState(false);
  const [initialVendorRiskWaiverId] = useState(
    () =>
      getSearchParamAsNumber(location.search, "vendorRiskWaiverId") ??
      location?.state?.waiverId
  );
  const [initialPublicRiskWaiverId] = useState(location?.state?.publicWaiverId);

  const fetchData = useCallback(() => {
    if (!riskWaivers && !publicWaivers && vendorId && watching && !isLoading) {
      setIsLoading(true);

      // Ensure we've loaded the vendor summary
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, false));

      dispatch(fetchVendorRiskWaivers(vendorId))
        .catch((e) => {
          console.error(e);
          dispatch(
            addDefaultUnknownErrorAlert("Error retrieving risk waivers")
          );
        })
        .then(() => setIsLoading(false));
    }
  }, [riskWaivers, publicWaivers, vendorId, watching, isLoading, dispatch]);

  const changeTab = useCallback(
    (tabId: string) => {
      const waiverUrl = `${vendorUrlPrefix(
        vendorId,
        isManagementAnalystSession,
        managedOrgId
      )}/riskwaivers`;

      (history as History<VendorRiskWaiversLocationState>).replace(
        `${waiverUrl}/${tabId}`,
        {
          ...location.state,
          currentTab: tabId as VendorRiskWaiverTabType,
        }
      );
    },
    [vendorId, isManagementAnalystSession, managedOrgId, history, location]
  );

  const currentTab: VendorRiskWaiverTabType =
    match?.params?.currentTab ??
    location?.state?.currentTab ??
    VendorRiskWaiverTabType.Waivers;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createWaiver = useCallback(() => {
    const prefix = vendorUrlPrefix(
      vendorId,
      isManagementAnalystSession,
      managedOrgId
    );

    const path =
      currentTab === VendorRiskWaiverTabType.SeverityAdjustments
        ? "riskwaivers/severityadjustments"
        : "riskwaivers";

    history.push(`${prefix}/${path}/add`, {
      backContext: {
        backTo: `${prefix}/${path}`,
        backToText: "Back to modified risks",
      },
    });
  }, [history, vendorId, isManagementAnalystSession, managedOrgId, currentTab]);

  const mergedWaivers = useMemo(
    () =>
      !isLoading && riskWaivers
        ? mergeWaiverArrays(riskWaivers, publicWaivers)
        : undefined,
    [isLoading, riskWaivers, publicWaivers]
  );

  const riskWaiversOnly = useMemo(
    () => mergedWaivers?.filter((w) => w.waiverType === WaiverType.RiskWaiver),
    [mergedWaivers]
  );

  const adjustedRisksOnly = useMemo(
    () =>
      mergedWaivers?.filter(
        (w) => w.waiverType === WaiverType.SeverityAdjustment
      ),
    [mergedWaivers]
  );

  const openExportReportModalBtnClick = useCallback(
    () =>
      openExportReportModal({
        title: "Export modified risks",
        exportType: ExportType.RiskWaiversExport,
        supportedFilters: [],
        supportedFileTypes: ExportFiletypeXLSX,
        shouldFetchExportSections: true,
        exportOptions: {
          vendor_id: vendorId,
        },
      }),
    [openExportReportModal, vendorId, hasAdjustableRisks]
  );

  const vendorWords = getVendorWords(assuranceType);

  const pageTitle = "Modified Risks";

  const infoBlock = (
    <>
      <p>
        Waive risks identified through automated scanning, security
        questionnaires, and additional evidence to remove them from the{" "}
        {vendorWords.possessive} risk profile. Or, adjust the severity of risks
        raised in security questionnaires and evidence.
      </p>
      <p>
        Risk waivers and severity adjustments will only impact the risk severity
        and vendor score shown to your organization.
      </p>
    </>
  );

  const supportArticleLinks = (
    <>
      <Button
        tertiary
        onClick={() =>
          window.open(
            "https://help.upguard.com/en/articles/4862611-what-are-vendor-risk-waivers"
          )
        }
      >
        Learn more about risk waivers <div className="cr-icon-arrow-right" />
      </Button>
      <Button
        tertiary
        onClick={() =>
          window.open(
            "https://help.upguard.com/en/articles/8668528-how-to-adjust-the-severity-of-a-vendor-risk"
          )
        }
      >
        Learn more about adjusting risks <div className="cr-icon-arrow-right" />
      </Button>
    </>
  );

  const breadcrumbs = useBreadcrumbs({ text: pageTitle }, () => [
    ...getVendorPageBreadcrumbs(vendorId ?? 0, vendorName, assuranceType),
  ]);

  return (
    <div className={"vendor-risk-waivers"}>
      {isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={history}
        title={pageTitle}
        breadcrumbs={breadcrumbs}
        backAction={
          location.state?.backContext?.goBack
            ? history.goBack
            : location.state && location.state.backContext
              ? () =>
                  history.push(
                    location?.state?.backContext?.backTo ?? "",
                    location?.state?.backContext?.backToContext
                  )
              : undefined
        }
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : undefined
        }
        vendorId={vendorId}
        isManagementAnalystSession={isManagementAnalystSession}
        managedOrgId={managedOrgId}
        rightSection={
          <>
            <Button
              disabled={!watching}
              onClick={openExportReportModalBtnClick}
            >
              <div className={"cr-icon-export"} />
              Export
            </Button>
          </>
        }
        infoSectionPageKey="infoSection_vendorriskRiskWaivers"
        infoSection={infoBlock}
        infoSectionButtons={supportArticleLinks}
      />
      {!watching && (
        <UnwatchedVendorCard
          vendorId={vendorId ?? 0}
          vendorName={vendorName}
          vendorPrimaryHostname={vendorPrimaryHostname}
          text={`This ${vendorWords.singular} must be monitored in order to access risk waivers.`}
          onWatched={fetchData}
        />
      )}
      {watching && (
        <>
          <PivotTabs
            styling={tabButtonsStylingType.FullWidthBanner}
            selectedTab={currentTab}
            onTabChange={changeTab}
            hideSingleTabButton
          >
            <PivotTab
              headerText={"Adjusted risks"}
              id={VendorRiskWaiverTabType.SeverityAdjustments}
            >
              <VendorRiskWaiversCard
                waiverType={WaiverType.SeverityAdjustment}
                headerTextOverride={"Adjusted Risks"}
                dispatch={dispatch}
                history={history}
                vendorId={vendorId}
                vendorName={vendorName}
                riskWaivers={adjustedRisksOnly}
                allowEdit={canManageVendorRiskWaivers}
                allowCreate={canManageVendorRiskWaivers}
                hasPossibleRisks={hasAdjustableRisks}
                onCreateClick={createWaiver}
                shouldTriggerDataFetchOnUpdate={true}
                initiallySelectedId={initialVendorRiskWaiverId}
                initiallySelectedPublicId={initialPublicRiskWaiverId}
                assuranceType={assuranceType}
                isManagementAnalystSession={isManagementAnalystSession}
                managedOrgId={managedOrgId}
              />
            </PivotTab>
            <PivotTab
              headerText={"Risk waivers"}
              id={VendorRiskWaiverTabType.Waivers}
            >
              <VendorRiskWaiversCard
                headerTextOverride={"Risk Waivers"}
                dispatch={dispatch}
                history={history}
                vendorId={vendorId}
                vendorName={vendorName}
                riskWaivers={riskWaiversOnly}
                allowEdit={canManageVendorRiskWaivers}
                allowCreate={canManageVendorRiskWaivers}
                hasPossibleRisks={hasWaivableRisks}
                onCreateClick={createWaiver}
                shouldTriggerDataFetchOnUpdate={true}
                initiallySelectedId={initialVendorRiskWaiverId}
                initiallySelectedPublicId={initialPublicRiskWaiverId}
                assuranceType={assuranceType}
                isManagementAnalystSession={isManagementAnalystSession}
                managedOrgId={managedOrgId}
              />
            </PivotTab>
          </PivotTabs>
        </>
      )}
      {exportReportModal}
    </div>
  );
};

export default wrapInVendorOverlay(
  appConnect<VendorRiskWaiversConnectedProps, never, VendorRiskWaiversOwnProps>(
    (state, props) => {
      const userSystemRoles = state.common.userData.system_roles.reduce(
        (prev: any, perm: any) => {
          prev[perm] = true;
          return prev;
        },
        {}
      );

      const userIsManagedVendorAnalyst =
        !!userSystemRoles[UserSystemRoleVendorManagementAnalyst];

      const isManagedVendorAnalyst =
        userIsManagedVendorAnalyst &&
        props.isManagementAnalystSession &&
        (props.managedOrgId ?? 0) > 0;

      const userPerms = getUserPermissionsForVendorFromState(
        state,
        props.isSubsidiary ? 0 : props.vendorId || 0
      );

      const canManageVendorRiskWaivers =
        isManagedVendorAnalyst || !!userPerms[UserManageVendorRiskWaivers];

      const riskWaiverState = props.vendorId
        ? getVendorRiskWaiversFromState(state, props.vendorId)
        : undefined;

      const publicWaiversState = props.vendorId
        ? getPublicVendorRiskWaiversFromState(state, props.vendorId)
        : undefined;

      const data = isManagedVendorAnalyst
        ? state.cyberRisk.managedVendorData?.[props.managedOrgId ?? 0]?.[
            props.vendorId ?? 0
          ]
        : state.cyberRisk.vendors[props.vendorId ?? 0];

      const connectedProps: VendorRiskWaiversConnectedProps = {
        riskWaivers: riskWaiverState,
        publicWaivers: publicWaiversState,
        canManageVendorRiskWaivers: canManageVendorRiskWaivers,
        assuranceType: state.common.userData.assuranceType,
        hasWaivableRisks:
          data?.summary?.result?.risks?.some((r) => !r.isWaived && !r.passed) ??
          false,
        hasAdjustableRisks:
          data?.summary?.result?.risks?.some(
            (r) =>
              !r.passed &&
              r.riskType !== RiskProfileRiskTypes.Cloudscan &&
              !r.baseSeverity
          ) ?? false,
      };

      return connectedProps;
    }
  )(VendorRiskWaivers)
);

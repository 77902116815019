import { DefaultRootState } from "react-redux";
import {
  OrgAccessSubsidiaries,
  UserSystemRoleVendorManagementAnalyst,
} from "../permissions";
import { IAuthInfo, ICyberRiskState, IUserData } from "../types/redux";
import {
  IUserOrganisation,
  organisationAccountType,
} from "../types/organisations";
export const getCurrentOrgFromUserData = (
  userData?: IUserData
): IUserOrganisation | null =>
  userData?.orgList?.find((o) => o.id === userData.currentOrgID) ?? null;

export const getCustomerOrgNameFromState = (
  authInfo: IAuthInfo,
  userData: IUserData,
  customerData: ICyberRiskState["customerData"]
): string | null => {
  const orgNameFromUserData = getCurrentOrgFromUserData(userData)?.name;
  if (orgNameFromUserData) return orgNameFromUserData;

  if (authInfo.orgName) return authInfo.orgName;

  return customerData?.summary?.result?.business?.display_name ?? null;
};

export const isExpiredTrial = (currentOrg?: IUserOrganisation | null) => {
  if (currentOrg?.accountType === organisationAccountType.trial) {
    // @ts-ignore
    return new Date(currentOrg.trialExpiresOn) - new Date() <= 0;
  }

  return false;
};

export const userOrgHasSubsidiaries = (state: DefaultRootState): boolean =>
  !!(
    state.common.permissions?.org?.[OrgAccessSubsidiaries] &&
    getCurrentOrgFromUserData(state.common.userData)?.hasSubsidiaries
  );

export const userOrgIsFreeOrg = (
  currentOrg?: IUserOrganisation | null
): boolean => {
  return currentOrg?.accountType === organisationAccountType.free;
};

export const userIsVendorManagementAnalyst = (userData: IUserData): boolean => {
  return (
    !!userData.system_roles &&
    userData.system_roles?.includes(UserSystemRoleVendorManagementAnalyst)
  );
};

export const userCurrentOrgIsDistributor = (userData: IUserData): boolean => {
  return (
    userData.currentOrgRoles && userData.currentOrgRoles.includes("Distributor")
  );
};

export const userIsOrgless = (user: IUserData): boolean => {
  return user.currentOrgID == 0;
};

export const userCurrentOrgHasSubsidiaries = (
  currentOrg?: IUserOrganisation
) => {
  return !!currentOrg?.hasSubsidiaries;
};

import LoadingIcon from "../../_common/components/core/LoadingIcon";
import ThreatMonitoringAPI, {
  GetResultTimelineV1Resp,
} from "../api/threatmonitoring.api";
import { groupBy as _groupBy } from "lodash";
import "./ThreatTimeline.scss";
import {
  BreachRiskUserName,
  SYSTEM_USER_ID,
  ThreatActionValue,
  ThreatMonitoringHistoryEvent,
  ThreatMonitoringRemediationOutcome,
} from "../api/types";
import { IUserMini } from "../../_common/types/user";
import { formatDateAsLocal, formatTimeAsLocal } from "../../_common/helpers";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import classnames from "classnames";
import { useNavToRemediationRequest } from "../funcs/useNav";
import moment, { Moment } from "moment";
import { TMUserAndAvatar } from "./Users";

export interface ThreatTimelineProps {
  threatUUID: string;
}

type AssignedToAction = { type: "assigned-to"; investigatorId: number };
type RemediatingAction = { type: "remediating"; remediationRequestId: number };
type RemediatedAction = {
  type: "remediated";
  remediationOutcome: ThreatMonitoringRemediationOutcome;
  remediationRequestId?: number;
};
type ThreatAction =
  | { type: "unknown" }
  | { type: "open" }
  | { type: "dismissed" }
  | { type: "unassigned" }
  | RemediatedAction
  | RemediatingAction
  | AssignedToAction;

interface ThreatEvent extends ThreatMonitoringHistoryEvent {
  occurredAtDate: Date;
  firstAtTime: boolean;
  actor?: IUserMini;
  action: ThreatAction;
  prevAction?: ThreatAction;
}

type ActorMap = Record<number, IUserMini>;

export default function ThreatTimeline({ threatUUID }: ThreatTimelineProps) {
  const { data, isLoading } = ThreatMonitoringAPI.useGetResultHistoryV1Query({
    uuid: threatUUID,
  });

  if (isLoading || !data) {
    return <LoadingIcon />;
  }

  return <ThreatTimelineActual data={data} />;
}

interface ThreatTimelineActualProps {
  data: GetResultTimelineV1Resp;
}
function ThreatTimelineActual({ data }: ThreatTimelineActualProps) {
  const [days, actorMap] = parseEvents(data);

  return (
    <div className="threat-timeline">
      {days.map((day, i) => (
        <div className={classnames("day", { first: i == 0 })} key={day.dateStr}>
          <div className="date">{day.dateStr}</div>
          {day.events.map((event, i) => (
            <ThreatEventCard key={i} event={event} actorMap={actorMap} />
          ))}
        </div>
      ))}
    </div>
  );
}

interface ThreatEventCardProps {
  event: ThreatEvent;
  actorMap: ActorMap;
}
function ThreatEventCard({ event, actorMap }: ThreatEventCardProps) {
  return (
    <div className="threat-event-card">
      <div className="occurred-at">
        {event.firstAtTime ? formatTimeAsLocal(event.occurredAtDate) : ""}
      </div>
      <div className="action">
        {event.eventType === "state_changed" ? (
          event.action.type === "assigned-to" ? (
            <TLAssignedTo
              event={event}
              action={event.action}
              actorMap={actorMap}
            />
          ) : event.action.type === "dismissed" ? (
            <TLDismissed
              event={event}
              action={event.action}
              actorMap={actorMap}
            />
          ) : event.action.type === "remediating" ? (
            <TLRemediation
              event={event}
              action={event.action}
              actorMap={actorMap}
            />
          ) : event.action.type === "remediated" ? (
            <TLRemediated
              event={event}
              action={event.action}
              actorMap={actorMap}
            />
          ) : event.action.type === "unassigned" ? (
            <TLUnassigned
              event={event}
              action={event.action}
              actorMap={actorMap}
            />
          ) : (
            <TLAction event={event} action={event.action} actorMap={actorMap} />
          )
        ) : event.eventType === "created" ? (
          <TLCreated event={event} />
        ) : (
          <TLAction event={event} action={event.action} actorMap={actorMap} />
        )}
      </div>
    </div>
  );
}

interface ThreatActionProps<A = ThreatAction> {
  event: ThreatEvent;
  action: A;
  actorMap: ActorMap;
}

function TLAssignedTo({
  event,
  action,
  actorMap,
}: ThreatActionProps<AssignedToAction>) {
  const actor = actorMap[action.investigatorId];

  if (event.prevAction?.type === "dismissed") {
    return (
      <div className="col">
        <div className="row">
          <UserAvatar actor={event.actor} />
          <div className="action">
            assigned <strong>{actor.name || actor.email}</strong> as
            investigator
          </div>
        </div>
        <div className="row">
          <UserAvatar actor={event.actor} />
          <div className="action">reopened this threat</div>
        </div>
        <StateChange event={event} />
      </div>
    );
  }

  return (
    <div className="col">
      <div className="row">
        <UserAvatar actor={event.actor} />
        <div className="action">
          assigned <strong>{actor.name || actor.email}</strong> as investigator
        </div>
      </div>
      <div className="row">
        <UserAvatar actor={event.actor} />
        added this threat to investigating
      </div>
      <StateChange event={event} />
    </div>
  );
}

function TLUnassigned({ event }: ThreatActionProps) {
  return (
    <div className="col">
      <div className="row">
        <UserAvatar actor={event.actor} />
        unassigned this threat
      </div>
      <StateChange event={event} />
    </div>
  );
}

function TLDismissed({ event }: ThreatActionProps) {
  return (
    <div className="col">
      <div className="row">
        <UserAvatar actor={event.actor} />
        dismissed this threat
      </div>
      <StateChange event={event} />
    </div>
  );
}

function TLRemediation({ event }: ThreatActionProps<RemediatingAction>) {
  return (
    <div className="col">
      <div className="row">
        <UserAvatar actor={event.actor} />
        requested remediation
      </div>
      <StateChange event={event} />
    </div>
  );
}

function TLRemediated({ event }: ThreatActionProps<RemediatedAction>) {
  return (
    <div className="col">
      <div className="row">
        <UserAvatar actor={event.actor} />
        closed this threat as remediated
      </div>
      <StateChange event={event} />
    </div>
  );
}

function TLCreated({ event }: { event: ThreatEvent }) {
  return (
    <>
      <UserAvatar actor={event.actor} /> threat detected
    </>
  );
}

function TLAction({ action }: ThreatActionProps<ThreatAction>) {
  return <>Action: {action.type}</>;
}

function StateChange({ event }: { event: ThreatEvent }) {
  if (!event.prevAction) {
    return null;
  }

  return (
    <div className="row state-change">
      <StateChangePill state={event.prevAction} />

      <div className="icon">
        <i className="cr-icon-arrow-right" />
      </div>
      <StateChangePill state={event.action} current />
    </div>
  );
}

function StateChangePill({
  state,
  current,
}: {
  state: ThreatAction;
  current?: boolean;
}) {
  const navToRemediationRequest = useNavToRemediationRequest({
    backToText: "Back to Threat Detail",
    useGoBack: true,
  });

  switch (state.type) {
    case "assigned-to":
      return (
        <PillLabel large color={LabelColor.Orange}>
          Investigating
        </PillLabel>
      );
    case "dismissed":
      return (
        <PillLabel large color={LabelColor.Blue}>
          Dismissed
        </PillLabel>
      );
    case "remediating": {
      const onClick = current
        ? navToRemediationRequest(state.remediationRequestId)
        : undefined;
      return (
        <MaybeLinkPill color={LabelColor.Blue} onClick={onClick}>
          In remediation
        </MaybeLinkPill>
      );
    }
    case "remediated": {
      const onClick =
        current && state.remediationRequestId
          ? navToRemediationRequest(state.remediationRequestId)
          : undefined;

      switch (state.remediationOutcome) {
        case ThreatMonitoringRemediationOutcome.Waived:
          return (
            <MaybeLinkPill color={LabelColor.Grey} onClick={onClick}>
              Waived
            </MaybeLinkPill>
          );
        default:
          return (
            <MaybeLinkPill color={LabelColor.Green} onClick={onClick}>
              Remediated
            </MaybeLinkPill>
          );
      }
    }
    case "open":
      return (
        <PillLabel large color={LabelColor.Red}>
          Open
        </PillLabel>
      );
    case "unassigned":
      return (
        <PillLabel large color={LabelColor.Grey}>
          Unassigned
        </PillLabel>
      );
    case "unknown":
      return <></>;
  }
}

interface MaybeLinkPillProps {
  children: React.ReactNode;
  onClick?: () => void;
  color: LabelColor;
}

function MaybeLinkPill({ children, onClick, color }: MaybeLinkPillProps) {
  if (onClick) {
    return (
      <PillLabel large color={color} onClick={onClick}>
        {children}
        <div className="icon">
          <div className="chevron cr-icon-chevron" />
        </div>
      </PillLabel>
    );
  }

  return (
    <PillLabel large color={color}>
      {children}
    </PillLabel>
  );
}

function UserAvatar({ actor }: { actor?: IUserMini }) {
  if (!actor) {
    return <TMUserAndAvatar name="Missing" />;
  }

  if (actor.id === SYSTEM_USER_ID) {
    return <TMUserAndAvatar userId={actor.id} name={BreachRiskUserName} />;
  }

  return <TMUserAndAvatar {...actor} email={undefined} />;
}

// parseEvents takes the response from the API and groups the events by day.
// Note that this assumes that the dates are ordered in descending order.
// It also
// - sorts the events by date and time.
// - adds the actor to each event.
function parseEvents(resp: GetResultTimelineV1Resp) {
  const dateMap: Record<string, ThreatEvent[]> = {};
  const actorMap: ActorMap = resp.actors.reduce(
    (acc, actor) => ({
      ...acc,
      [actor.id]: actor,
    }),
    {}
  );

  const allEvents: ThreatEvent[] = [];
  const addEvent = (dayStr: string, event: ThreatEvent) => {
    if (!dateMap[dayStr]) {
      dateMap[dayStr] = [];
    }
    allEvents.push(event);
    dateMap[dayStr].push(event);
  };

  let prevDate: Moment | undefined;
  resp.events.forEach((event) => {
    const occurredAtDate = new Date(event.occurredAt);
    const dayStr = formatDateAsLocal(occurredAtDate);

    const action = parseAction(event);

    // check whether this time was first occurring at the same minute
    const m = moment(occurredAtDate).startOf("minute");
    const firstAtTime = prevDate ? !m.isSame(prevDate) : true;
    prevDate = m;

    addEvent(dayStr, {
      ...event,
      occurredAtDate,
      firstAtTime,
      actor: actorMap[event.userID],
      action,
    });
  });

  // Do another pass to link each event's the previous action.
  allEvents.forEach((event, i) => {
    if (i < allEvents.length - 1) {
      event.prevAction = allEvents[i + 1].action;
    }
  });

  const tlEvents = Object.entries(dateMap).map(([dateStr, events]) => {
    events.sort(
      (a, b) => b.occurredAtDate.getTime() - a.occurredAtDate.getTime()
    );

    return {
      date: new Date(dateStr),
      dateStr,
      events,
    };
  });

  tlEvents.sort((a, b) => b.date.getTime() - a.date.getTime());

  return [tlEvents, actorMap] as const;
}

function parseAction(event: ThreatMonitoringHistoryEvent): ThreatAction {
  let action: ThreatAction | undefined;

  if (event.eventType === "created") {
    action = { type: "open" };
  } else {
    switch (event.value) {
      case ThreatActionValue.AssignedTo:
        if (event.target?.investigatorId) {
          const investigatorId = event.target.investigatorId;
          action = { type: "assigned-to", investigatorId };
        }
        break;

      case ThreatActionValue.Remediating: {
        if (event.target?.remediationRequestId) {
          const remediationRequestId = event.target.remediationRequestId;
          action = { type: "remediating", remediationRequestId };
        }
        break;
      }

      case ThreatActionValue.Remediated: {
        if (event.target?.remediationOutcome) {
          action = {
            type: "remediated",
            remediationRequestId: event.target.remediationRequestId,
            remediationOutcome: event.target.remediationOutcome,
          };
        }
        break;
      }

      case ThreatActionValue.Dismissed:
        action = { type: "dismissed" };
        break;

      default:
      // NOTE we should explicitly reject unknown values here
    }
  }

  if (!action) {
    return { type: "unknown" };
  }
  return action;
}

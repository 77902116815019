import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useEffect, useState } from "react";
import { CheckboxBig } from "../../../_common/components/CheckboxBig";
import "../../style/UpdateComplianceBadgesModal.scss";
import { ComplianceBadgeMap, ComplianceBadgeTypes } from "../../helpers/types";
import Button from "../../../_common/components/core/Button";
import SearchBox from "../../../_common/components/SearchBox";
import { debounce as _debounce } from "lodash";
import TrustPageAPI from "../../api/trustpage.api";
import { useDispatch } from "react-redux";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

interface UpdateComplianceBadgesModalProps extends BaseModalProps {
  selectedBadges: ComplianceBadgeTypes[];
}

export interface selectableBadge {
  type: ComplianceBadgeTypes;
  selected: boolean;
  visible: boolean;
}

const compareSelectableBadges = (
  a: selectableBadge,
  b: selectableBadge
): number =>
  //isSelected first then alphabetically
  Number(b.selected) - Number(a.selected) || a.type.localeCompare(b.type);

const mapToSelectableBadge = (selectedBadges: ComplianceBadgeTypes[]) => {
  const isBadgeSelected = (badgeType: string) => {
    return selectedBadges.some((selectedBadge) => selectedBadge === badgeType);
  };
  return Object.values(ComplianceBadgeTypes).map((badgeType) => ({
    type: badgeType,
    selected: isBadgeSelected(badgeType),
    visible: true,
  }));
};

const UpdateComplianceBadgesModal: FC<UpdateComplianceBadgesModalProps> = ({
  selectedBadges,
  active,
  onClose,
}: UpdateComplianceBadgesModalProps) => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");
  const [availableBadges, setAvailableBadges] = useState(
    mapToSelectableBadge(selectedBadges)
  );
  const [updateComplianceBadges] =
    TrustPageAPI.useUpdateOwnTrustPageComplianceBadgesV1Mutation();

  const _onSearchChange = _debounce((searchString: string) =>
    setSearchString(searchString)
  );

  const onSearchChange = (searchString: string) => {
    if (searchString.length == 0 || searchString.length > 2) {
      _onSearchChange(searchString);
    }
  };

  const handleBadgeChecked = (
    badgeType: ComplianceBadgeTypes,
    selected: boolean
  ) => {
    const badges = [...availableBadges];
    const idx = badges.findIndex((i) => i.type === badgeType);
    if (idx !== -1) {
      badges[idx].selected = selected;
    }
    setAvailableBadges(badges);
  };

  useEffect(() => {
    const badges: selectableBadge[] = [];
    availableBadges.forEach((badge) => {
      if (!!searchString && searchString.length > 0) {
        badges.push({
          ...badge,
          // visible is matches the search but also if already selected
          visible:
            ComplianceBadgeMap[badge.type].title
              .toLowerCase()
              .includes(searchString.toLowerCase()) || badge.selected,
        });
      } else {
        badges.push({
          ...badge,
          visible: true,
        });
      }
    });
    badges.sort(compareSelectableBadges);
    setAvailableBadges(badges);
  }, [searchString]);

  const handleSaveChanges = () => {
    updateComplianceBadges({
      badges: availableBadges
        .filter((badge) => badge.selected)
        .map((badge) => badge.type),
    })
      .unwrap()
      .then(() => {
        dispatch(
          addDefaultSuccessAlert("Compliance badges updated successfully")
        );
      })
      .catch(() => {
        dispatch(addSimpleErrorAlert("Failed to update compliance badges"));
      });
  };

  return (
    <ModalV2
      className="update-compliance-badges-modal"
      width={800}
      active={active}
      onClose={onClose}
      headerContent="Update compliance badges"
      footerContent={
        <>
          <Button tertiary onClick={() => onClose()}>
            Cancel
          </Button>

          <Button
            primary={true}
            onClick={() => {
              handleSaveChanges();
              onClose();
            }}
          >
            Save changes
          </Button>
        </>
      }
    >
      <p>
        Select the security and compliance standards your organization meets.
      </p>

      <SearchBox
        value={searchString}
        onChanged={onSearchChange}
        placeholder="Search compliance badge"
      />

      <div className="compliance-badges">
        {availableBadges &&
          availableBadges
            .filter((badge) => badge.visible)
            .map((badge) => {
              const { title, badgeImg } = ComplianceBadgeMap[badge.type];
              return (
                <CheckboxBig
                  key={badge.type}
                  checked={badge.selected}
                  setChecked={(val) => handleBadgeChecked(badge.type, val)}
                  label={title}
                  iconImage={badgeImg}
                  tooltipText={""}
                />
              );
            })}
      </div>
    </ModalV2>
  );
};

export default UpdateComplianceBadgesModal;

import { DefaultThunkDispatch } from "../../_common/types/redux";
import ModalV2 from "../../_common/components/ModalV2";
import ModalForm from "../../vendorrisk/components/modals/ModalForm";
import "../style/TemplateSettingsModal.scss";
import Button from "../../_common/components/core/Button";
import {
  SurveyFramework,
  SurveyFrameworkDisplayNames,
} from "../types/frameworks";
import { OptionType, SelectV2 } from "../../_common/components/SelectV2";
import { setFramework } from "../reducers/actions";

interface IFrameworkSelectorProps {
  selectedFramework: SurveyFramework;
  onSelectFramework: (framework: SurveyFramework) => void;
}

const frameworkSelectorOpts = Object.entries(SurveyFrameworkDisplayNames).map(
  ([framework, displayName]) => ({
    value: framework,
    label: displayName,
  })
);
export const FrameworkSelector = (props: IFrameworkSelectorProps) => {
  return (
    <SelectV2
      options={frameworkSelectorOpts}
      value={{
        value: props.selectedFramework,
        label: SurveyFrameworkDisplayNames[props.selectedFramework],
      }}
      onChange={(newVal) => {
        if (newVal && !Array.isArray(newVal)) {
          props.onSelectFramework(
            (newVal as OptionType).value as SurveyFramework
          );
        }
      }}
    />
  );
};

interface ITemplateSettingsModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  surveyTypeId: string;
  surveyFramework: SurveyFramework;
}

const TemplateSettingsModal = (props: ITemplateSettingsModalProps) => {
  const isValid = true;

  const selectedFramework =
    props.surveyFramework.toString() === ""
      ? SurveyFramework.NONE
      : props.surveyFramework;

  return (
    <ModalV2
      className="template-settings-modal"
      active={props.active}
      disallowClose={!isValid}
      onClose={props.onClose}
      headerContent="Template settings"
      footerContent={
        <>
          <Button disabled={!isValid} onClick={props.onClose}>
            Done
          </Button>
        </>
      }
    >
      <ModalForm>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Framework</span>
            <p>
              If questions should map to a specific cybersecurity framework,
              select it here.
            </p>
          </div>
          <div className="form-section-input">
            <FrameworkSelector
              selectedFramework={selectedFramework}
              onSelectFramework={(newFramework) =>
                props.dispatch(
                  setFramework(props.surveyTypeId.toString(), newFramework)
                )
              }
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export default TemplateSettingsModal;

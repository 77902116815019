import { FC } from "react";
import { Doughnut as ReactDoughnut } from "react-chartjs-2";
import { ConvertHexToRGBA } from "../../_common/helpers";
import classnames from "classnames";
import "./Doughnut.scss";

export interface DoughnutDataPoint {
  label: string;
  count: number;
  hexColor: string;

  // Optional click handler for the data point
  onClick?: () => void;
}
interface DonutProps {
  // optional class name for the component
  className?: string;

  // label to be shown at the center of the donut chart together with the total
  totalLabel: string;

  // Data to use
  data: DoughnutDataPoint[];

  // onClick for the graph component (used primarily to navigate to a more detailed data view)
  onClick?: () => void;
}

const Doughnut: FC<DonutProps> = ({ className, totalLabel, data, onClick }) => {
  const total = data.reduce((sum, current) => sum + current.count, 0);

  const filteredData = data.filter((d) => d.count > 0);

  // In general we use a white border around doughnut segments.
  // This makes the thickness of the segments a little smaller
  // due to the border.
  // When we have only one data segment we don't use the white border
  // and the white border looks weird. In this case we change the border
  // color to be the same as the segment and increase the cutoutPercentage
  // so that it appears to have the same thickness as a doughnut with
  // multiple segments
  let borderColor = ConvertHexToRGBA("#ffffff", 100);
  let cutoutPercentage = 80;
  if (filteredData.length === 1) {
    borderColor = ConvertHexToRGBA(filteredData[0].hexColor, 100);
    cutoutPercentage = 85;
  }

  // Click handler for a segment of the chart
  const handleClick = (_: any, activeElements: any[]) => {
    if (activeElements.length > 0) {
      // No type for activeElements, but it's an array of elements
      // that have an _index property that we can use to get the
      // index of the clicked element
      const index = activeElements[0]._index;
      if (index < filteredData.length) {
        const dataPoint = filteredData[index];
        dataPoint.onClick?.();
      }
    }
  };

  const hasSegmentOnClickHandlers = filteredData.some((d) => !!d.onClick);

  return (
    <div className={classnames("doughnut-chart-and-breakdown", className)}>
      <div className={"chart"} onClick={() => onClick?.()}>
        <ReactDoughnut
          data={{
            labels: filteredData.map((d) => d.label),
            datasets: [
              {
                data: filteredData.map((d) => d.count),
                backgroundColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
                borderColor: borderColor,
                hoverBackgroundColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
                hoverBorderColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
              },
            ],
          }}
          options={{
            legend: { display: false },
            tooltips: { enabled: false },
            cutoutPercentage: cutoutPercentage,
            responsive: true,
            maintainAspectRatio: false,
            onClick: handleClick,
            // Only apply hover mode if we have onClick handlers for the segments
            hover: {
              mode: hasSegmentOnClickHandlers === false ? undefined : "nearest",
            },
          }}
        />
        <div className={"total-permissions"}>
          <span className={"number"}>{total}</span>
          <span className={"label"}>{totalLabel}</span>
        </div>
      </div>
      <div className={"breakdown"}>
        {filteredData.map((d) => (
          <div key={d.label} onClick={() => d.onClick?.()}>
            <span
              className={"square"}
              style={{ backgroundColor: ConvertHexToRGBA(d.hexColor, 100) }}
            ></span>
            {d.label}: {d.count}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Doughnut;

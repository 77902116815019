import { FC } from "react";
import classnames from "classnames";

export interface IconProps {
  className?: string;
  name: string;
  direction?: number;
  onClick?: () => void;
  id?: string;
}

const directions = [90, 180, 270, Infinity, -Infinity];

const Icon: FC<IconProps> = ({
  className = "",
  name,
  direction = 0,
  onClick,
  id,
}) => {
  const cls = classnames(
    `icon-${name}`,
    {
      [`rotate-${direction}`]: directions.includes(direction),
    },
    className
  );

  return <span id={id} className={cls} onClick={onClick} />;
};

export default Icon;

import { useEffect } from "react";
import { organisationAccountType } from "../types/organisations";
import { useAppSelector } from "../types/reduxHooks";

// This component loads the Hotjar tracking script under certain conditions:
// - Current user region is not Europe and not blank
// - Current org region not EMEA
//   - Note: we'll allow blank here
// - Current org is a trial org
// - User only has 1 org
const HotjarWrapper = () => {
  const currentOrgId = useAppSelector(
    (state) => state.common.userData.currentOrgID
  );
  const orgs = useAppSelector((state) => state.common.userData.orgList);

  const currentOrg = orgs?.find((o) => o.id === currentOrgId);
  const userRegion = useAppSelector(
    (state) => state.common.userData.userRegion
  );

  // On org change, load the script if needed
  useEffect(() => {
    if (
      window.HOTJAR_SITE_ID &&
      userRegion &&
      userRegion !== "Europe" &&
      currentOrg?.region !== "EMEA" &&
      currentOrg?.accountType === organisationAccountType.trial &&
      orgs?.length === 1
    ) {
      // Only load it if we haven't yet
      if (!isHotjarLoaded()) {
        const script = document.createElement("script");
        const body = document.getElementsByTagName("body")[0];

        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${window.HOTJAR_SITE_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        body.appendChild(script);
      }
    }
  }, [currentOrg, userRegion, orgs]);

  return <></>;
};

// Check if the Hotjar script is currently loaded
const isHotjarLoaded = () => {
  return !!window.hj && typeof window.hj === "function";
};

export default HotjarWrapper;

import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import {
  ThreatMonitoringModule,
  ThreatMonitoringSourceType,
  ThreatMonitoringVendorNetworkType,
} from "../api/types";
import DarkWebSourceIcon from "../images/darkweb_icon.svg";
import TelegramSourceIcon from "../images/telegram_icon.svg";
import DiscordSourceIcon from "../images/discord_icon.svg";
import RansomwareBlogsSourceIcon from "../images/ransomware_blogs_icon.svg";
import MarketplaceSourceIcon from "../images/marketplace_icon.svg";
import ForumsSourceIcon from "../images/forums_icon.svg";
import GitHubSourceIcon from "../images/github_icon.svg";
import StackOverflowSourceIcon from "../images/stackoverflow_icon.svg";
import DockerSourceIcon from "../images/docker_icon.svg";
import JenkinsSourceIcon from "../images/jenkins_icon.svg";
import BitBucketSourceIcon from "../images/bitbucket_icon.svg";
import GitlabSourceIcon from "../images/gitlab_icon.svg";
import AtlassianSourceIcon from "../images/atlassian_icon.svg";
import TrelloSourceIcon from "../images/trello_icon.svg";
import CodeRepoSourceIcon from "../images/code_repo_icon.svg";
import IssueTrackerSourceIcon from "../images/issue_tracker_icon.svg";
import PackageRepoSourceIcon from "../images/package_repo_icon.svg";
import LinkedInSourceIcon from "../images/linkedin_icon.svg";
import FacebookSourceIcon from "../images/facebook_icon.svg";
import TwitterSourceIcon from "../images/x_icon.svg";

import "./ThreatSource.scss";
import {
  ModuleNameDarkWeb,
  moduleToString,
  sourceTypeToString,
  vendorNetworkTypeIsDarkWeb,
} from "../funcs/domain";

interface ModuleDefn {
  icon: any;
  popupWidth: number;
  popupText?: string;
  moduleText?: string;
}

interface ThreatSourceProps {
  module: ThreatMonitoringModule;
  source: ThreatMonitoringSourceType;
  network?: ThreatMonitoringVendorNetworkType;
}

export default function ThreatSource({
  module: moduleType,
  source,
  network,
}: ThreatSourceProps) {
  let module: ModuleDefn;
  if (vendorNetworkTypeIsDarkWeb(network)) {
    module = {
      icon: DarkWebSourceIcon,
      popupWidth: 90,
      popupText: moduleToString(moduleType),
      moduleText: moduleToString(moduleType),
    };
  } else {
    switch (source) {
      case ThreatMonitoringSourceType.Telegram:
        module = {
          icon: TelegramSourceIcon,
          popupWidth: 90,
          popupText: "Telegram",
          moduleText: "Messaging platforms",
        };
        break;
      case ThreatMonitoringSourceType.Discord:
        module = {
          icon: DiscordSourceIcon,
          popupWidth: 90,
          popupText: "Discord",
          moduleText: "Messaging platforms",
        };
        break;
      case ThreatMonitoringSourceType.Forum:
        module = {
          icon: ForumsSourceIcon,
          popupWidth: 70,
          popupText: "Forum",
        };
        break;
      case ThreatMonitoringSourceType.Marketplace:
        module = {
          icon: MarketplaceSourceIcon,
          popupWidth: 110,
          popupText: "Illicit marketplace",
        };
        break;

      // Note that stealer logs is an exception
      case ThreatMonitoringSourceType.StealerLogs:
        module = {
          icon: DarkWebSourceIcon,
          popupWidth: 90,
          popupText: "Stealer log",
          moduleText: ModuleNameDarkWeb,
        };
        break;
      case ThreatMonitoringSourceType.RansomwareBlog:
        module = {
          icon: RansomwareBlogsSourceIcon,
          popupWidth: 110,
          popupText: "Ransomware blog",
        };
        break;
      case ThreatMonitoringSourceType.Github:
        module = {
          icon: GitHubSourceIcon,
          popupWidth: 90,
          popupText: "GitHub",
          moduleText: "Public repository",
        };
        break;
      case ThreatMonitoringSourceType.Gitlab:
        module = {
          icon: GitlabSourceIcon,
          popupWidth: 90,
          popupText: "Gitlab",
          moduleText: "Public repository",
        };
        break;
      case ThreatMonitoringSourceType.Bitbucket:
        module = {
          icon: BitBucketSourceIcon,
          popupWidth: 90,
          popupText: "Bitbucket",
          moduleText: "Public repository",
        };
        break;
      case ThreatMonitoringSourceType.Atlassian:
        module = {
          icon: AtlassianSourceIcon,
          popupWidth: 90,
          popupText: "Atlassian",
          moduleText: "Issue tracker",
        };
        break;
      case ThreatMonitoringSourceType.JenkinsIssues:
        module = {
          icon: JenkinsSourceIcon,
          popupWidth: 90,
          popupText: "Jenkins",
          moduleText: "Issue tracker",
        };
        break;
      case ThreatMonitoringSourceType.Trello:
        module = {
          icon: TrelloSourceIcon,
          popupWidth: 90,
          popupText: "Trello",
          moduleText: "Issue tracker",
        };
        break;
      case ThreatMonitoringSourceType.DockerHub:
        module = {
          icon: DockerSourceIcon,
          popupWidth: 90,
          popupText: "Docker Hub",
          moduleText: "Package repository",
        };
        break;
      case ThreatMonitoringSourceType.StackOverflow:
        module = {
          icon: StackOverflowSourceIcon,
          popupWidth: 90,
          popupText: "Stack Overflow",
          moduleText: "Forum",
        };
        break;
      case ThreatMonitoringSourceType.LinkedIn:
        module = {
          icon: LinkedInSourceIcon,
          popupWidth: 90,
          popupText: "LinkedIn",
          moduleText: "Social media",
        };
        break;
      case ThreatMonitoringSourceType.Facebook:
        module = {
          icon: FacebookSourceIcon,
          popupWidth: 90,
          popupText: "Facebook",
          moduleText: "Social media",
        };
        break;
      case ThreatMonitoringSourceType.Twitter:
        module = {
          icon: TwitterSourceIcon,
          popupWidth: 90,
          popupText: "Twitter/X",
          moduleText: "Social media",
        };
        break;
      case ThreatMonitoringSourceType.CodeRepository:
        module = {
          icon: CodeRepoSourceIcon,
          popupWidth: 90,
          popupText: "Code repository",
          moduleText: "Code repository",
        };
        break;
      case ThreatMonitoringSourceType.IssueTracker:
        module = {
          icon: IssueTrackerSourceIcon,
          popupWidth: 90,
          popupText: "Issue tracker",
          moduleText: "Issue tracker",
        };
        break;
      case ThreatMonitoringSourceType.PackageRepository:
        module = {
          icon: PackageRepoSourceIcon,
          popupWidth: 90,
          popupText: "Package repository",
          moduleText: "Package repository",
        };
        break;
      default:
        module = {
          icon: ForumsSourceIcon,
          popupWidth: 0,
        };
    }
  }

  return (
    <div className="threat-source">
      <SidePopupV2
        text={module?.popupText}
        position="top"
        width={module?.popupWidth}
      >
        <img src={module?.icon} alt={module?.popupText} />
      </SidePopupV2>
      <div className="sources">
        <span className="source">{sourceTypeToString(source)}</span>
        <span className="module">{module?.moduleText}</span>
      </div>
    </div>
  );
}

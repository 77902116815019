import { FC, useCallback, useEffect, useMemo, useState } from "react";
import RiskSummaryTable, { RiskTableRiskFilter } from "../RiskSummaryTable";
import { useHistory } from "react-router-dom";
import { IRisk } from "../RiskSummaryDetails";
import {
  getUserPermissionsForVendorFromState,
  UserManageVendorRiskWaivers,
  UserWriteRemediation,
} from "../../../_common/permissions";
import {
  IVendorRiskWaiver,
  WaiverType,
} from "../../../_common/types/vendorRiskWaivers";
import { PublicRiskWaiver } from "../../types/sharedAssessment";
import {
  fetchVendorRiskWaivers,
  getPublicVendorRiskWaiversFromState,
  getVendorRiskWaiversFromState,
} from "../../reducers/vendorRiskWaiver.actions";
import {
  RemediationRequestStatus,
  RemediationRequestWithMeta,
} from "../../../_common/types/remediation";
import { VendorAssessmentKeyRisk } from "../../types/vendorAssessments";
import { debounce as _debounce } from "lodash";
import "../../style/components/vendor_assessment/VendorAssessmentV3RisksCard.scss";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";
import VendorAssessmentAPI, {
  commentsUpdate,
} from "../../reducers/vendorAssessmentAPI";
import InfoBanner, { BannerType } from "../InfoBanner";
import { GetVulnPanel } from "../../../vendorrisk/components/DomainsAndIPsPanelGroup";

interface VendorAssessmentV3RisksCardOwnProps {
  readonly: boolean;
  urlPrefix: string;
  assessmentId: number;
  isPublishedAssessment: boolean;
  vendorId: number;
  managedOrgId?: number;
  includeRiskWaivers: boolean;
  remediationRequest?: RemediationRequestWithMeta;
  goToRemediationRequest?: () => void;
  onRequestRemediationForRisk?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean
  ) => void;
  onCreateVendorRiskWaiverForRisk?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean,
    waiverType?: WaiverType
  ) => void;
  onOpenCloudscanPanel: (scan: string, risk: string) => void;
  onUpdateComments?: (update: commentsUpdate) => void;
  displayKeyRisks?: boolean;
  enableKeyRisks?: boolean;
  keyRisks?: VendorAssessmentKeyRisk[];
  onToggleKeyRisk?: (risk: VendorAssessmentKeyRisk, selected: boolean) => void;
  calculatingRisks: boolean;
  isManagedAssessment?: boolean;
  showRiskClassification?: boolean;
  showRiskAdjustmentJustification?: boolean;
  onSelectRisk?: (riskId: string | undefined) => void;
  onSelectedRiskParamsChange?: (
    riskPanel: JSX.Element | undefined,
    getVulnPanel?: GetVulnPanel
  ) => void;
  onOpenCVEPanelFromRiskPanel?: (
    riskId: string,
    riskTitle: string,
    vuln: string,
    verified?: boolean
  ) => void;
  onOpenCloudscanPanelFromRiskPanel?: (
    id: string,
    riskTitle: string,
    risk?: string
  ) => void;
  onClickAssetInVulnPanel?: (currentPanelTitle: string, asset: string) => void;
}

interface VendorAssessmentV3RisksCardConnectedProps {
  userHasWriteRiskWaiversPermission: boolean;
  userHasWriteRemediationPermission: boolean;
  vendorRiskWaivers?: IVendorRiskWaiver[];
  vendorPublicRiskWaivers?: PublicRiskWaiver[];
}

type VendorAssessmentV3RisksCardProps = VendorAssessmentV3RisksCardOwnProps &
  VendorAssessmentV3RisksCardConnectedProps;

const VendorAssessmentV3RisksCard: FC<VendorAssessmentV3RisksCardProps> = ({
  readonly,
  urlPrefix,
  assessmentId,
  isPublishedAssessment,
  vendorId,
  remediationRequest,
  userHasWriteRiskWaiversPermission,
  userHasWriteRemediationPermission,
  vendorRiskWaivers,
  vendorPublicRiskWaivers,
  includeRiskWaivers,
  onCreateVendorRiskWaiverForRisk,
  goToRemediationRequest,
  onRequestRemediationForRisk,
  onOpenCloudscanPanel,
  onUpdateComments,
  keyRisks,
  onToggleKeyRisk,
  displayKeyRisks,
  enableKeyRisks,
  calculatingRisks,
  isManagedAssessment = false,
  showRiskClassification,
  showRiskAdjustmentJustification,
  onSelectRisk,
  onSelectedRiskParamsChange,
  onOpenCVEPanelFromRiskPanel,
  onOpenCloudscanPanelFromRiskPanel,
  onClickAssetInVulnPanel,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVendorRiskWaivers(vendorId, false));
  }, [vendorId, assessmentId, dispatch]);

  const {
    data: assessmentRisks,
    isFetching: risksLoading,
    refetch: refreshRisks,
  } = VendorAssessmentAPI.useGetRisksForAssessmentQuery({
    vendorId: vendorId,
    assessmentId: assessmentId,
  });

  useEffect(() => {
    if (!calculatingRisks) {
      refreshRisks();
    }
  }, [calculatingRisks]);

  const getUrlPrefix = useCallback(() => urlPrefix, [urlPrefix]);

  const getRisks = useMemo(() => {
    return assessmentRisks?.risks ?? [];
  }, [assessmentRisks]);

  const [_, setPendingCommentUpdates] = useState<Record<string, string>>({});

  const riskComments = useMemo(() => {
    return assessmentRisks?.riskComments?.reduce(
      (prev, comment) => {
        if (!!comment.comments) {
          prev[comment.riskID] = comment.comments;
        }
        return prev;
      },
      {} as Record<string, string>
    );
  }, [assessmentRisks?.riskComments]);

  const doSavePendingComments = useCallback(
    _debounce((pendingCommentUpdates: Record<string, string>) => {
      const pendingUpdates = Object.entries(pendingCommentUpdates).map(
        ([riskID, text]) => ({ riskID, text })
      );
      if (pendingUpdates.length > 0 && onUpdateComments) {
        onUpdateComments(pendingUpdates);
        setPendingCommentUpdates({});
      }
    }, 500),
    []
  );

  // make sure pending comments are saved on unmount
  useEffect(() => {
    return () => doSavePendingComments.flush();
  }, [doSavePendingComments]);

  const onUpdateRiskComment = (riskID: string, comment: string) => {
    setPendingCommentUpdates((prev) => {
      const newUpdates = {
        ...prev,
        [riskID]: comment,
      };
      doSavePendingComments(newUpdates);
      return newUpdates;
    });
  };

  const getRisksCount = includeRiskWaivers
    ? getRisks.filter((r) => !r.isWaived).length
    : getRisks.length;
  let title = readonly ? `Risk details` : `Manage risks`;

  if (getRisksCount > 0) {
    title += ` (${getRisksCount})`;
  }

  return (
    <div className={"vendor-assessment-v3-risk-card"}>
      {calculatingRisks && (
        <InfoBanner
          type={BannerType.INFO}
          message={
            "We are calculating the risks included in this assessment based on your domain and IP selection. This may take a few minutes."
          }
        />
      )}
      <RiskSummaryTable
        risks={getRisks}
        loading={risksLoading || calculatingRisks}
        history={history}
        title={title}
        readOnly={readonly}
        filterActive={false}
        noDetectedDates
        urlPrefix={getUrlPrefix}
        canManageVendorRiskWaivers={
          userHasWriteRiskWaiversPermission && includeRiskWaivers
        }
        userHasWriteRiskWaiversPermission={
          userHasWriteRiskWaiversPermission && includeRiskWaivers
        }
        userHasWriteRemediationPermission={userHasWriteRemediationPermission}
        hideFilters={readonly}
        hideShowPassedChecksOption={true}
        hideShowWaivedRisksOption={true}
        forSpecificAssessment={assessmentId}
        forPublishedAssessment={isPublishedAssessment}
        vendorId={vendorId}
        onRequestRemediationForRisk={
          remediationRequest?.status === RemediationRequestStatus.Closed
            ? undefined
            : onRequestRemediationForRisk
        }
        onCreateRiskWaiver={
          !readonly && includeRiskWaivers
            ? onCreateVendorRiskWaiverForRisk
            : undefined
        }
        additionalEvidenceInRemediation={assessmentRisks?.evidenceInRemediation}
        cloudscansInRemediation={assessmentRisks?.cloudscansInRemediation}
        vendorPublicRiskWaivers={
          includeRiskWaivers ? vendorPublicRiskWaivers : undefined
        }
        vendorRiskWaivers={includeRiskWaivers ? vendorRiskWaivers : undefined}
        onOpenCloudscanPanel={onOpenCloudscanPanel}
        isPassive={isPublishedAssessment}
        remediationRequest={remediationRequest}
        onRequestRemediation={goToRemediationRequest}
        showRemediationSummary={true}
        backToText={"Back to risk assessment"}
        hideColumnHeaders={getRisks.length == 0}
        riskComments={riskComments}
        onUpdateComment={onUpdateRiskComment}
        showComponentFilters={!readonly}
        excludedFilters={
          new Set<RiskTableRiskFilter>([
            RiskTableRiskFilter.StatusWaived,
            RiskTableRiskFilter.StatusWaiverPending,
          ])
        }
        displayKeyRiskSelection={displayKeyRisks}
        displayKeyRiskTabByDefault={
          displayKeyRisks && readonly && !enableKeyRisks
        }
        enableKeyRiskSelection={enableKeyRisks}
        keyRisks={displayKeyRisks ? keyRisks : undefined}
        onToggleKeyRisk={enableKeyRisks ? onToggleKeyRisk : undefined}
        showWaivedRisks={!includeRiskWaivers}
        hideWaivedOrAdjustedStatus={!includeRiskWaivers}
        isManagedAssessment={isManagedAssessment}
        showRiskClassification={showRiskClassification}
        showRiskAdjustmentJustification={
          showRiskAdjustmentJustification && readonly
        }
        onSelectRisk={onSelectRisk}
        onSelectedRiskParamsChange={onSelectedRiskParamsChange}
        onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
        onOpenCloudscanPanelFromRiskPanel={onOpenCloudscanPanelFromRiskPanel}
        onClickAssetInVulnPanel={onClickAssetInVulnPanel}
      />
    </div>
  );
};

export default appConnect<
  VendorAssessmentV3RisksCardConnectedProps,
  never,
  VendorAssessmentV3RisksCardOwnProps
>((state, props) => {
  const userPerms = getUserPermissionsForVendorFromState(state, props.vendorId);

  const conProps: VendorAssessmentV3RisksCardConnectedProps = {
    userHasWriteRiskWaiversPermission:
      userPerms[UserManageVendorRiskWaivers] ?? false,
    userHasWriteRemediationPermission: userPerms[UserWriteRemediation] ?? false,
    vendorPublicRiskWaivers: getPublicVendorRiskWaiversFromState(
      state,
      props.vendorId
    ),
    vendorRiskWaivers: getVendorRiskWaiversFromState(state, props.vendorId),
  };
  return conProps;
})(VendorAssessmentV3RisksCard);

import { IUserData } from "../types/redux";
import {
  breachSightNavigationItems,
  trustExchangeNavigationItems,
} from "../types/products";
import * as Permissions from "../permissions";
import {
  getSubsidiaryRiskWaiverTasks,
  getUserRemediationRequests,
  getUserRiskAcceptanceTasks,
  getUserVendorRiskWaiverTasks,
  isExpiredTrial,
  userCurrentOrgHasSubsidiaries,
  userCurrentOrgIsDistributor,
  userIsOrgless,
  userOrgIsFreeOrg,
} from "./userOrg.helpers";
import { AssuranceType, IUserOrganisation } from "../types/organisations";
import { RemediationType } from "../types/remediation";
import { IUserTaskData } from "../types/user";
import { RiskAssetType } from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { WaiverType } from "../types/vendorRiskWaivers";

export const tasksRouterUrlPrefix = "tasks";
export const trustExchangeTaskUrlPrefix = "trustexchange";
export const breachRiskTaskUrlPrefix = "breachrisk";
export const vendorRiskTaskUrlPrefix = "vendorrisk";
export const userRiskTaskUrlPrefix = "userrisk";

// MultiProductNavigationMaxWidthClass - CSS class name for the maximum width of the content pane with multi-product navigation
// This width varies based on the open/closed state of the 2 navigation menus
// The value is set in AppContentContainer.scss
// Any component that needs to use this max-width should include this CSS classname
export const MultiProductNavContentMaxWidthClass =
  "multi-product-nav-content-max-width";

// MultiProductNavContentLeftClass - CSS Class name for the left position of content that needs to pushed out by the width of the navigation
// This width varies based on the open/closed state of the navigation menues
// The value is set in AppContentContainer.scss
// Any component that needs to use this left margin should include this CSS classname
export const MultiProductNavContentLeftClass = "multi-product-nav-content-left";

// getUserBreachSightSections - gets a set of BreachSight sections that the user has access to
export const getUserBreachSightSections = (
  userData: IUserData,
  orgData?: IUserOrganisation,
  selectedSubsidiaryVendorId?: number,
  userTaskData?: IUserTaskData
): breachSightNavigationItems[] => {
  const result: breachSightNavigationItems[] = [];

  // Short-circuit for distributor org
  if (userCurrentOrgIsDistributor(userData)) {
    return result;
  }

  const currentOrgHasSubsidiaries = userCurrentOrgHasSubsidiaries(orgData);
  const currentOrgIsExpiredTrial = isExpiredTrial(orgData);

  const userHasBreachsight =
    (userData.userPermissions.includes(Permissions.UserBreachsightEnabled) ||
      Object.keys(userData.domainPortfolioSpecificPermissions).length > 0) &&
    !currentOrgIsExpiredTrial;

  const userHasDomainPortfolioSpecificPermissions =
    userData.domainPortfolioSpecificPermissions &&
    Object.keys(userData.domainPortfolioSpecificPermissions).length > 0;

  // if org has Breach Risk and user specifically does not, they get nothing
  if (
    !userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo)
  ) {
    return result;
  }

  // everyone who has made it to this point gets this at least
  result.push("risk_profile");

  // Add nav items based on user and org configuration

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo)
  ) {
    result.push(
      "executive_summary",
      "risk_waivers",
      "domains",
      "ip_addresses",
      "detected_products"
    );
  }

  if (
    userHasBreachsight &&
    userData.assuranceType === AssuranceType.None &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo)
  ) {
    result.push("remediation");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo) &&
    userData.orgPermissions.includes(Permissions.OrgAccessVulns)
  ) {
    result.push("vulnerabilities");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo) &&
    userData.orgPermissions.includes(Permissions.OrgAccessSubsidiaries) &&
    currentOrgHasSubsidiaries &&
    !userHasDomainPortfolioSpecificPermissions
  ) {
    result.push("subsidiaries");
  }

  if (
    userHasBreachsight &&
    selectedSubsidiaryVendorId &&
    (userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo) ||
      userData.orgPermissions.includes(Permissions.OrgAccessSubsidiaries))
  ) {
    result.push("subsidiaries_menu");
  }

  // Identity Breaches and Typosquatting features can be toggled on for users who do not have access to the
  // core features, so we don't check the userHasBreachsight flag for them
  if (
    userData.orgPermissions.includes(Permissions.OrgAccessEmailExposures) &&
    userData.userPermissions.includes(Permissions.UserEmailExposureRead) &&
    !currentOrgIsExpiredTrial
  ) {
    result.push("identity_breaches");
  }

  if (
    userData.orgPermissions.includes(Permissions.OrgAccessTyposquatting) &&
    userData.userPermissions.includes(Permissions.UserTyposquattingRead) &&
    !currentOrgIsExpiredTrial
  ) {
    result.push("typosquatting");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessAppGuard)
  ) {
    result.push("cloud");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessThreatMonitoring) &&
    userData.userPermissions.includes(Permissions.UserThreatMonitoringRead)
  ) {
    result.push("threatmonitoring");
  }

  // Check if the user has any BreachSight remediation requests (Self or Subsidiary type)
  const breachSightRemediationRequests = getUserRemediationRequests(
    userTaskData ?? {},
    true,
    true,
    [RemediationType.Self, RemediationType.Subsidiary],
    [RemediationType.Self, RemediationType.Subsidiary]
  );

  if (
    breachSightRemediationRequests.currentOrgRequestIDs?.length ||
    breachSightRemediationRequests.otherOrgRequestIDs?.length
  ) {
    result.push("remediation_tasks");
  }

  // Check if the user has any BreachSight risk acceptance approval tasks
  const breachSightRiskWaiverApprovalTasks = getUserRiskAcceptanceTasks(
    userTaskData ?? {},
    true,
    true,
    [RiskAssetType.cloudscan],
    [RiskAssetType.cloudscan]
  );

  if (
    breachSightRiskWaiverApprovalTasks.currentOrgRiskAcceptanceRiskIDs
      ?.length ||
    breachSightRiskWaiverApprovalTasks.otherOrgRiskAcceptanceRiskIDs?.length
  ) {
    result.push("risk_waiver_approvals");
  }

  // Check if the user has any BreachRisk subsidiary risk waiver approval tasks
  const breachRiskSubsidiaryRiskWaiverApprovalTasks =
    getSubsidiaryRiskWaiverTasks(userTaskData ?? {}, true, true);

  if (
    breachRiskSubsidiaryRiskWaiverApprovalTasks.currentOrgRiskWaiverIDs
      ?.length ||
    breachRiskSubsidiaryRiskWaiverApprovalTasks.otherOrgRiskWaiverIDs?.length
  ) {
    result.push("subsidiary_risk_waiver_approvals");
  }

  return result;
};

// Show cyberresearch if:
// - Org is not a distributor
// AND
// - Org is not an MSSP type org
// AND
// - Org/user has access to any of the relevant features for the requested product:
//  - BREACHSIGHT: DataLeaks
//  - VENDOR RISK: VendorDataLeaks OR Managed Vendor Assessments
// OR
//  - The org has access to none of the relevant features (show for feature preview purposes)
//  - AND it is not a free/vendor-only user
export const userCanViewCyberResearch = (
  userData: IUserData,
  product: "breachsight" | "vendor_risk",
  orgData?: IUserOrganisation
): boolean => {
  const currentOrgIsDistributor = userCurrentOrgIsDistributor(userData);
  const currentOrgIsExpiredTrial = isExpiredTrial(orgData);

  // Don't show CR for distributors
  if (currentOrgIsDistributor) {
    return false;
  }

  // Don't show CR for MSSPs
  if (userData.assuranceType === AssuranceType.MSSP) {
    return false;
  }

  let userHasProductCyberResearchFeatures = false;

  if (product === "breachsight") {
    const userOrgHasBreachSight = userData.orgPermissions.includes(
      Permissions.OrgAccessBreachSight
    );

    const userHasBreachSightPermission =
      userData.userPermissions.includes(Permissions.UserBreachsightEnabled) ||
      Object.keys(userData.domainPortfolioSpecificPermissions).length > 0;

    const userHasDataLeaksPermission = userData.userPermissions.includes(
      Permissions.UserAccessDataLeaks
    );

    // If the Org has Data Leaks (which is called BreachSight), but the User doesn't have BreachSight AND Data Leaks, never show BreachSight CyberResearch
    if (
      userOrgHasBreachSight &&
      (!userHasBreachSightPermission || !userHasDataLeaksPermission)
    ) {
      return false;
    }

    // Check if the user has access to the DataLeaks BreachSight feature
    userHasProductCyberResearchFeatures =
      userOrgHasBreachSight && userHasDataLeaksPermission;
  }

  if (product === "vendor_risk") {
    // Check the VR Vendor Data Leaks and Managed Vendor Assessments features
    const userHasVendorDataLeaks =
      userData.orgPermissions.includes(Permissions.OrgAccessVendorDataLeaks) &&
      userData.userPermissions.includes(Permissions.UserAccessDataLeaks);

    const userHasManagedVendors =
      userData.orgPermissions.includes(
        Permissions.OrgAccessVendorsUnderManagement
      ) &&
      userData.userPermissions.includes(
        Permissions.UserManageVendorsUnderManagement
      );

    userHasProductCyberResearchFeatures =
      userHasVendorDataLeaks || userHasManagedVendors;
  }

  if (userHasProductCyberResearchFeatures) {
    return true;
  }

  // The user doesn't have access to the CyberResearch features for the product
  // They might still be able to see a feature preview though
  const currentOrgIsFreeOrg = userOrgIsFreeOrg(orgData);
  const currentUserIsOrgless = userIsOrgless(userData);

  return (
    !currentOrgIsFreeOrg &&
    !currentUserIsOrgless &&
    !userHasProductCyberResearchFeatures &&
    !currentOrgIsExpiredTrial
  );
};

export const userHasVendorRiskAccessForOrg = (userData: IUserData): boolean => {
  const userHasVendorRisk =
    userData.userPermissions.includes(Permissions.UserVendorRiskEnabled) ||
    Object.keys(userData.vendorPortfolioSpecificPermissions).length > 0;

  return (
    userHasVendorRisk &&
    (userData.orgPermissions.includes(Permissions.OrgAccessVendors) ||
      userData.orgPermissions.includes(Permissions.OrgAccessSurveys))
  );
};

export const userHasNoVendorRiskAccessOrgDoes = (
  userData: IUserData
): boolean => {
  const userHasVendorRisk =
    userData.userPermissions.includes(Permissions.UserVendorRiskEnabled) ||
    Object.keys(userData.vendorPortfolioSpecificPermissions).length > 0;

  return (
    !userHasVendorRisk &&
    (userData.orgPermissions.includes(Permissions.OrgAccessVendors) ||
      userData.orgPermissions.includes(Permissions.OrgAccessSurveys))
  );
};

export const userCanViewVendorRisk = (userData: IUserData): boolean => {
  const currentOrgIsDistributor = userCurrentOrgIsDistributor(userData);
  const userHasNoVRAccessOrgDoes = userHasNoVendorRiskAccessOrgDoes(userData);

  // everyone should see VR in the nav except for:
  // - distributors
  // - users who have no VR access while the org does (no upsell needed)
  return !userHasNoVRAccessOrgDoes || currentOrgIsDistributor;
};

export const userCanViewUserRisk = (userData: IUserData): boolean => {
  return (
    userData.orgPermissions.includes(Permissions.OrgAccessUserBase) &&
    (userData.userPermissions.includes(Permissions.UserUserBaseEnabled) ||
      userData.userPermissions.includes(Permissions.UserUserBaseWrite))
  );
};

// getUserTrustExchangeSections - get a set of TrustExchange sections that the user has access to
// The first item is the default display item for the TrustExchange product
export const getUserTrustExchangeSections = (
  userData: IUserData,
  orgData?: IUserOrganisation,
  userTaskData?: IUserTaskData
): trustExchangeNavigationItems[] => {
  const result: trustExchangeNavigationItems[] = [];

  // Short-circuit for distributor org
  if (userCurrentOrgIsDistributor(userData)) {
    return result;
  }

  if (
    userData.currentOrgID !== 0 &&
    orgData?.isVerifiedVendor &&
    userData.userPermissions.includes(Permissions.UserAccessPrefilledSurveys)
  ) {
    result.push("trust_page");
  }

  if (
    userData.userPermissions.includes(Permissions.UserReadContentLibrary) &&
    !userCurrentOrgIsDistributor(userData)
  ) {
    result.push("content_library");
  }

  if (
    userData.hasAssessments ||
    userData.userPermissions.includes(Permissions.UserReadSurveyImportExport)
  ) {
    result.push("questionnaires");
  }

  // Trust Exchange remediation request tasks will include all Vendor Risk remediation requests
  const { currentOrgRequestIDs, otherOrgRequestIDs } =
    getUserRemediationRequests(
      userTaskData ?? {},
      true,
      true,
      [RemediationType.Vendor],
      [RemediationType.Vendor]
    );
  if (currentOrgRequestIDs?.length || otherOrgRequestIDs?.length) {
    result.push("remediation_requests");
  }

  if (userData.hasAdditionalEvidenceRequests) {
    result.push("additional_evidence_requests");
  }

  return result;
};

export const userHasVendorRiskTasks = (
  userData: IUserData,
  userTaskData?: IUserTaskData
) => {
  const userRiskWaivers = getUserVendorRiskWaiverTasks(
    userTaskData ?? {},
    true,
    true,
    [WaiverType.RiskWaiver],
    [WaiverType.RiskWaiver]
  );

  const userRiskAdjustments = getUserVendorRiskWaiverTasks(
    userTaskData ?? {},
    true,
    true,
    [WaiverType.SeverityAdjustment],
    [WaiverType.SeverityAdjustment]
  );

  const showRiskWaiverApprovals =
    !!userRiskWaivers?.currentOrgRiskWaiverIDs?.length ||
    !!userRiskWaivers?.otherOrgRiskWaiverIDs?.length;
  const showRiskAdjustmentApprovals =
    !!userRiskAdjustments?.currentOrgRiskWaiverIDs?.length ||
    !!userRiskAdjustments?.otherOrgRiskWaiverIDs?.length;

  const showAnswerRelationshipQuestionnaires =
    userData.hasRelationshipQuestionnaires;

  return (
    showRiskWaiverApprovals ||
    showRiskAdjustmentApprovals ||
    showAnswerRelationshipQuestionnaires
  );
};

export const getDefaultBreachRiskRouteForUser = (): string => {
  // via https://app.asana.com/0/1209647723263004/1209647724833530
  // here's where we would check for various conditions if we wanted
  // people to default-route to other places (such as the task pages)
  // for now, we just send everyone here
  return "/risk_profile";
};

export const getDefaultVendorRiskRouteForUser = (): string => {
  return "/vendorlist";
};

import { Component } from "react";

import Button from "../core/Button";
import { closeModal } from "../../reducers/commonActions";
import "../../style/main.scss";
import { DefaultThunkDispatch } from "../../types/redux";
import classnames from "classnames";

export const ConfirmationModalName = "ConfirmationModalName";

export interface IConfirmationModalData {
  title: string;
  buttonAction: () => any | Promise<any>;
  description?: string | JSX.Element;
  buttonText?: string;
  cancelText?: string;
  hideCancel?: boolean;
  dangerousAction?: boolean;

  // Optionally use v2 layout for display (appears as floating surface)
  isV2Layout?: boolean;

  // Optional icon class to add an icon to the action button
  iconClass?: string;

  // Optional class to add to the modal
  className?: string;
}

interface IConfirmationModalProps {
  modalData: IConfirmationModalData;
  dispatch: DefaultThunkDispatch;
}

interface IConfirmationModalState {
  loading: boolean;
}

/**
 * <ConfirmationModal />
 * This is a generic modal that can be used to show a simple modal to confirm or cancel an action.
 * This can take title, description, buttonText, buttonOnClick and hideCancel properties in the modalData.
 * buttonOnClick must be the function that's called when the primary button is clicked. If it is a promise,
 * the confirmation modal will go into a loading state and close when the promise resolves, or stay open if
 * the promise throws.
 */
class ConfirmationModal extends Component<
  IConfirmationModalProps,
  IConfirmationModalState
> {
  state = { loading: false } as IConfirmationModalState;

  onClick = () => {
    this.setState({ loading: true });

    // Using Promise.resolve to handle "thenable" promises as well as regular synchronous functions.
    // Returning true from the buttonAction will ensure the closeModal action is not called, such as
    // for opening another modal as a result of the action.
    Promise.resolve(this.props.modalData.buttonAction())
      .then((noClose = false) => {
        if (!noClose) {
          this.props.dispatch(closeModal());
        }
      })
      .catch(() => {
        // On error returned from promise, take us out of loading state but keep modal open.
        this.setState({ loading: false });
      });
  };

  close = () => {
    this.props.dispatch(closeModal());
  };

  render() {
    return this.props.modalData.isV2Layout ? this.renderV2() : this.renderV1();
  }

  // Render as a floating surface
  renderV2() {
    const {
      title,
      description,
      buttonText,
      hideCancel,
      dangerousAction,
      iconClass,
    } = this.props.modalData;
    let { cancelText } = this.props.modalData;
    if (!cancelText) {
      cancelText = "Cancel";
    }

    const descContent =
      typeof description === "string" ? (
        <p>{description}</p>
      ) : (
        <>{description}</>
      );

    return (
      <form className={classnames(this.props.modalData.className)}>
        <div className={"modal-header"}>
          <h2>{title}</h2>
        </div>
        <div className={"modal-content"} style={{ minWidth: 450 }}>
          {descContent}
        </div>
        <div className="modal-footer">
          {!hideCancel && (
            <Button disabled={this.state.loading} onClick={this.close} tertiary>
              {cancelText}
            </Button>
          )}
          <Button
            danger={dangerousAction}
            loading={this.state.loading}
            onClick={this.onClick}
            filledPrimary={!dangerousAction}
          >
            {iconClass && <i className={iconClass} />}
            {buttonText || "OK"}
          </Button>
        </div>
      </form>
    );
  }

  // Render as full screen with modal area
  renderV1() {
    const { title, description, buttonText, hideCancel, dangerousAction } =
      this.props.modalData;
    let { cancelText } = this.props.modalData;
    if (!cancelText) {
      cancelText = "Cancel";
    }

    const descContent =
      typeof description === "string" ? (
        <p>{description}</p>
      ) : (
        <>{description}</>
      );

    return (
      <div className="modal-content">
        <h2>{title}</h2>
        {description ? descContent : <p />}
        <form>
          <div className="btn-group no-margin">
            {!hideCancel && (
              <Button
                disabled={this.state.loading}
                onClick={this.close}
                tertiary
              >
                {cancelText}
              </Button>
            )}
            <Button
              primary={!dangerousAction}
              danger={dangerousAction}
              loading={this.state.loading}
              onClick={this.onClick}
            >
              {buttonText || "OK"}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default ConfirmationModal;

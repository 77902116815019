import { Node, NodeType, SelectNodeAnswer } from "./types/types";
import { Survey } from "./reducers/reducer";
import {
  nodeToNodeTypeIconType,
  NodeTypeIconType,
} from "./components/NodeTypeIcon";
import { produce } from "immer";

export interface NodeSummary {
  nodeId: string;
  nodeType: NodeTypeIconType;
  questionNumber: string; // Auto-derived question number that can be used in lieu of a question number in the text
  parentId: string;
  titleOrMainText: string;
  answers?: SelectNodeAnswer[];
  children?: NodeSummary[]; // Used when getting the full questionnaire structure
  skipNumbering?: boolean;
  // hasCustomRisk?: boolean;
  // customRiskID?: number;
}

export interface getAllNodesMatchingTypesResult {
  asArray: NodeSummary[];
  asMap: { [nodeId: string]: NodeSummary | undefined };
}

// Given the full Survey, find every question matching the given NodeTypes,
// in order of how they appear on the page.
export const getAllNodesMatchingTypes = (
  store: Survey,
  typesToMatch: NodeType[], // If empty, get all types
  beforeQuestionNumber?: string // If specified, only return results appearing before that questionNumber
): getAllNodesMatchingTypesResult => {
  const typesMap = new Map();
  for (let i = 0; i < typesToMatch.length; i++) {
    typesMap.set(typesToMatch[i], true);
  }

  const results: NodeSummary[] = [];
  const resultsMap: { [nodeId: string]: NodeSummary | undefined } = {};

  const processNodeAndChildren = (
    node: Node,
    questionNumber: string
  ): boolean => {
    if (beforeQuestionNumber && beforeQuestionNumber === questionNumber) {
      // We're done here
      return false;
    }

    if (
      node.nodeId !== store.rootNodeId &&
      (typesToMatch.length === 0 || typesMap.has(node.type))
    ) {
      const summaryItem: NodeSummary = {
        nodeId: node.nodeId,
        nodeType: nodeToNodeTypeIconType(node),
        questionNumber:
          node.skipNumbering || node.type === NodeType.Risk
            ? ""
            : questionNumber,
        parentId: node.parentId || "",
        titleOrMainText: "",
        skipNumbering: node.skipNumbering,
      };

      if (node.type === NodeType.Section) {
        summaryItem.titleOrMainText = node.title;
      } else if (node.type === NodeType.Risk) {
        summaryItem.titleOrMainText = node.name || node.mainText;
      } else {
        summaryItem.titleOrMainText = node.mainText;
      }

      if (node.type === NodeType.Select) {
        summaryItem.answers = node.answers;
      }

      results.push(summaryItem);
      resultsMap[summaryItem.nodeId] = summaryItem;
    }

    // Now go through each of this node's children to find further down the tree
    if (node.type === NodeType.Section) {
      let curQuestionNumberIndex = -1;
      for (let i = 0; i < node.childNodeIds.length; i++) {
        const childNode = store.nodes[node.childNodeIds[i].id];
        if (!childNode) {
          continue;
        }

        if (
          node.childNodeIds[i].type !== NodeType.Risk &&
          !childNode.skipNumbering
        ) {
          curQuestionNumberIndex += 1;
        }

        const shouldContinue = processNodeAndChildren(
          childNode,
          getChildNodeQuestionNumber(questionNumber, curQuestionNumberIndex)
        );

        if (!shouldContinue) {
          return false;
        }
      }
    }

    return true;
  };

  const rootNode = store.nodes[store.rootNodeId];
  if (!rootNode) {
    return { asArray: [], asMap: {} };
  }

  processNodeAndChildren(rootNode, "");

  return { asArray: results, asMap: resultsMap };
};

// getAllNodesAsTree returns the full structure of the survey state
// as a tree structure. This does not include the root node.
export const getAllNodesAsTree = (store: Survey): NodeSummary[] => {
  const results: NodeSummary[] = [];

  const processNodeAndChildren = (
    node: Node,
    questionNumber: string
  ): NodeSummary => {
    const summaryItem: NodeSummary = {
      nodeId: node.nodeId,
      nodeType: nodeToNodeTypeIconType(node),
      questionNumber: !node.skipNumbering
        ? node.customNumber ?? questionNumber
        : "",
      parentId: node.parentId || "",
      titleOrMainText: "",
      skipNumbering: node.skipNumbering,
    };

    if (node.type === NodeType.Section) {
      summaryItem.titleOrMainText = node.title;
    } else if (node.type === NodeType.Risk) {
      summaryItem.titleOrMainText = node.name || node.mainText;
    } else {
      summaryItem.titleOrMainText = node.mainText;
    }

    if (node.type === NodeType.Select) {
      summaryItem.answers = node.answers;
    }

    // Now get all the children down the tree for this node
    if (node.type === NodeType.Section) {
      summaryItem.children = [];

      let curQuestionNumberIndex = -1;
      for (let i = 0; i < node.childNodeIds.length; i++) {
        const childNode = store.nodes[node.childNodeIds[i].id];
        if (childNode) {
          if (
            !childNode.skipNumbering &&
            node.childNodeIds[i].type !== NodeType.Risk
          ) {
            curQuestionNumberIndex += 1;
          }

          summaryItem.children.push(
            processNodeAndChildren(
              childNode,
              node.childNodeIds[i].type !== NodeType.Risk
                ? getChildNodeQuestionNumber(
                    questionNumber,
                    curQuestionNumberIndex
                  )
                : ""
            )
          );
        }
      }
    }

    return summaryItem;
  };

  const rootNode = store.nodes[store.rootNodeId];
  if (!rootNode || rootNode.type !== NodeType.Section) {
    return [];
  }

  let curQuestionNumberIndex = -1;
  for (let i = 0; i < rootNode.childNodeIds.length; i++) {
    const childNode = store.nodes[rootNode.childNodeIds[i].id];
    if (childNode) {
      if (!childNode.skipNumbering) {
        curQuestionNumberIndex += 1;
      }

      results.push(
        processNodeAndChildren(
          childNode,
          getChildNodeQuestionNumber("", curQuestionNumberIndex)
        )
      );
    }
  }

  return results;
};

// moveNodeInTree moves a node from a specified index in a tree structure to a new index.
// A full copy of the tree is returned with the change enacted.
export const moveNodeInTree = (
  tree: NodeSummary[],
  moveFromIndex: number[],
  moveToIndex: number[]
): NodeSummary[] => {
  return produce(tree, (draft) => {
    let nodeToMove: NodeSummary;

    if (moveFromIndex.length === 1) {
      // If we're moving something on the top level, simply mutate the tree array
      [nodeToMove] = draft.splice(moveFromIndex[0], 1);
    } else {
      // First dig down to find the parent node
      const indexWithoutLast = [...moveFromIndex];
      const lastIndex = indexWithoutLast.pop() || 0;
      const parentNode = digIntoNodeSummaryTree(draft, indexWithoutLast);
      if (!parentNode || !parentNode.children) {
        console.error(
          "attempted to move a node in tree structure with invalid from index",
          moveFromIndex
        );
        return;
      }

      // Mutate the children of this node to remove the target
      [nodeToMove] = parentNode.children.splice(lastIndex, 1);
    }

    // We may have mutated the tree in a way that would change the moveToIndex.
    // Check if that's the case and fix up the new index.
    if (moveToIndex.length >= moveFromIndex.length) {
      let shouldCorrect = true;
      // Check that the TO index matches the path that we took the node FROM
      for (let i = 0; i < moveFromIndex.length - 1; i++) {
        if (moveFromIndex[i] !== moveToIndex[i]) {
          shouldCorrect = false;
          break;
        }
      }

      if (
        shouldCorrect &&
        moveToIndex[moveFromIndex.length - 1] >
          moveFromIndex[moveFromIndex.length - 1]
      ) {
        moveToIndex = [...moveToIndex];

        // Subtract one from the path as we've removed an element since the index was first calculated.
        moveToIndex[moveFromIndex.length - 1] =
          moveToIndex[moveFromIndex.length - 1] - 1;
      }
    }

    if (moveToIndex.length === 1) {
      // If we're moving somewhere on the top level, simply mutate the tree array
      draft.splice(moveToIndex[0], 0, nodeToMove);
    } else {
      // First dig down to find the parent node
      const indexWithoutLast = [...moveToIndex];
      const lastIndex = indexWithoutLast.pop() || 0;
      const parentNode = digIntoNodeSummaryTree(draft, indexWithoutLast);
      if (!parentNode || !parentNode.children) {
        console.error(
          "attempted to move a node in tree structure with invalid to index",
          moveToIndex
        );
        return;
      }

      // Mutate the children of this node to add the target
      parentNode.children.splice(lastIndex, 0, nodeToMove);
    }
  });
};

// digIntoNodeSummaryTree returns the node at a specified index in the tree
const digIntoNodeSummaryTree = (
  tree: NodeSummary[],
  index: number[]
): NodeSummary | undefined => {
  let curNode = tree[index[0]];
  for (let i = 1; i < index.length; i++) {
    const thisIndex = index[i];

    if (!curNode.children || curNode.children.length - 1 < thisIndex) {
      return undefined;
    }

    curNode = curNode.children[thisIndex];
  }

  return curNode;
};

// Question numbers go in the form 1.1.1. etc. When creating the question number for the next
// level down, we simply append a new number to the existing one.
export const getChildNodeQuestionNumber = (
  parentQuestionNumber: string,
  questionIndex: number
): string => {
  return `${parentQuestionNumber}${questionIndex + 1}.`;
};

const alphabet = "abcdefghijklmnopqrstuvwxyz";
export const maxPossibleAnswers = alphabet.length;

// Taking an index, return the letter for the specified answer
export const getAnswerLetters = (index: number): string => {
  return alphabet[index];
};

export const questionNodeDOMId = (nodeId: string) =>
  "question_node_" + nodeId.replace(/\./g, "_");

export const scrollToNodeId = (
  nodeId: string,
  scrollIfVisible: boolean,
  scrollBehavior?: "auto" | "smooth"
) => {
  const node = document.getElementById(questionNodeDOMId(nodeId));
  const contentScroll =
    document.querySelector<HTMLDivElement>("#main-content-area");
  if (!node || !contentScroll) {
    return;
  }

  // Check if this element is already in view
  const { top, bottom } = node.getBoundingClientRect();
  const isVisible = top >= 0 && bottom <= contentScroll.scrollTop;

  if (scrollIfVisible || !isVisible) {
    setTimeout(
      () =>
        contentScroll.scrollTo({
          top: contentScroll.scrollTop + top,
          behavior: scrollBehavior,
        }),
      0
    );
  }
};

export const isNodeVisible = (
  nodeId: string,
  fullyVisible: boolean
): boolean => {
  const node = document.getElementById(questionNodeDOMId(nodeId));
  const contentScroll =
    document.querySelector<HTMLDivElement>("#main-content-area");
  if (!node || !contentScroll) {
    return false;
  }

  // Check if this element is already in view
  const { top, bottom } = node.getBoundingClientRect();
  return top >= 0 && (!fullyVisible || bottom <= contentScroll.scrollTop);
};

// Creates new UUID with all dashes replaced by underscores, as dashes can be problematic
// in the questionnaire expression parser.
export const createUUID = (): string => crypto.randomUUID().replace(/-/g, "_");

import classNames from "classnames";
import { FC } from "react";
import "../../style/components/securityprofile/StatusIcon.scss";

interface StatusIconProps {
  className?: string;
  iconClass: string;
  ariaLabel?: string;
  statusCount?: number;
}

const StatusIcon: FC<StatusIconProps> = ({
  className,
  iconClass,
  statusCount,
  ariaLabel,
}) => (
  <>
    <div className={classNames("status-icon", className)}>
      <div className={iconClass} aria-label={ariaLabel} />
    </div>
    {statusCount && <span className="status-count">{statusCount}</span>}
  </>
);

export default StatusIcon;

import { FC } from "react";
import CircledIcon from "../../_common/components/CircledIcon";
import IconButton from "../../_common/components/IconButton";
import "../style/QuestionnaireItem.scss";
import { formatDateAsLocal } from "../../_common/helpers";

interface IQuestionnaireItemProps {
  name: string;
  description?: string;
  updatedAt?: string;
  readOnly: boolean;
  onRequestAccess: () => void;
}

const QuestionnaireItem: FC<IQuestionnaireItemProps> = ({
  name,
  description,
  updatedAt,
  readOnly,
  onRequestAccess,
}) => {
  return (
    <div className="qn-inner">
      <CircledIcon iconClass="cr-icon-questionnaire" />

      <div className={"qn-details"}>
        {name}
        {updatedAt && (
          <div className="qn-last-updated">
            Last updated {formatDateAsLocal(updatedAt)}
          </div>
        )}
        {description && <div className="qn-description">{description}</div>}
      </div>
      {readOnly && (
        <div className="qn-btn">
          <IconButton
            icon={<div className="cr-icon-lock" />}
            onClick={onRequestAccess}
            hoverText="Request access"
          />
        </div>
      )}
    </div>
  );
};

export default QuestionnaireItem;

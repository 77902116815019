import {
  Ref,
  forwardRef,
  HTMLProps,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  FC,
  useLayoutEffect,
  useMemo,
  MouseEvent as ReactMouseEvent,
  useCallback,
  PropsWithChildren,
} from "react";
import { throttle } from "lodash";
import classnames from "classnames";
import "../../style/components/core/DropdownV2.scss";
import Button, { IButtonProps } from "./Button";
import IconButton, { HoverLocation } from "../IconButton";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Icon from "./Icon";
import { debounce } from "lodash";
import DismissablePopupMemo from "../DismissablePopup";

interface IDropdownAutoscrollerProps extends PropsWithChildren {
  className?: string;
  popsUpFromBottom?: boolean;
}

// DropdownAutoscroller should be used inside a DropdownV2 to ensure the
// height of the popup does not exceed the height of the page. It will automatically
// become scrollable when necessary.
// This is not currently compatible with HoverSubmenuItem.
export const DropdownAutoscroller: FC<IDropdownAutoscrollerProps> = ({
  className,
  children,
  popsUpFromBottom,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const observeFunc = throttle((entries: ResizeObserverEntry[]) => {
      if (entries.length > 0 && mainRef.current && innerRef.current) {
        const { top: distanceFromTop, bottom: bottomDistanceFromTop } =
          mainRef.current.getBoundingClientRect();
        const { height: childrenHeight } =
          innerRef.current.getBoundingClientRect();
        const viewportHeight = entries[0].contentRect.height;

        let newHeight;
        if (popsUpFromBottom) {
          if (childrenHeight - bottomDistanceFromTop < 0) {
            newHeight = 0;
          } else {
            newHeight = bottomDistanceFromTop - 20;
          }
        } else {
          if (distanceFromTop + childrenHeight < viewportHeight) {
            newHeight = 0;
          } else {
            newHeight = viewportHeight - distanceFromTop - 20;
          }
        }

        setHeight(newHeight);
      }
    }, 100);

    const observer = new ResizeObserver(observeFunc);
    observer.observe(document.documentElement);

    return () => {
      observer.disconnect();
    };
  }, [popsUpFromBottom]);

  const styleObj = useMemo(
    () => ({
      height: height > 0 ? `${height}px` : "auto",
    }),
    [height]
  );

  return (
    <div
      className={classnames("dropdown-autoscroll", className)}
      style={styleObj}
      ref={mainRef}
    >
      <div className="dropdown-autoscroll-inner" ref={innerRef}>
        {children}
      </div>
    </div>
  );
};

type IDropdownSubheaderProps = HTMLProps<HTMLHeadingElement>;

export const DropdownSubheader: FC<IDropdownSubheaderProps> = ({
  children,
  ...otherProps
}) => <h3 {...otherProps}>{children}</h3>;

type IDropdownSeparatorProps = HTMLProps<HTMLHRElement>;

export const DropdownSeparator: FC<IDropdownSeparatorProps> = ({
  className = "",
  ...otherProps
}) => <hr className={`dropdown-separator ${className}`} {...otherProps} />;

interface IDropdownItemProps extends HTMLProps<HTMLDivElement> {
  stopPropagation?: boolean;
  disabled?: boolean;
  icon?: string;
}

export const DropdownItem: FC<IDropdownItemProps> = ({
  className = "",
  selected,
  children,
  stopPropagation,
  onClick: outerOnClick,
  disabled,
  icon,
  ...otherProps
}) => {
  const onClick = useMemo(
    () =>
      outerOnClick
        ? (e: ReactMouseEvent<HTMLDivElement>) => {
            if (stopPropagation) {
              e.stopPropagation();
            }
            // if this is disabled we still want to stop propagation or the row action will be performed
            // but not perform our outer click action
            if (!disabled) {
              outerOnClick(e);
            }
          }
        : disabled && stopPropagation
          ? (e: ReactMouseEvent<HTMLDivElement>) => e.stopPropagation
          : undefined,
    [disabled, outerOnClick, stopPropagation]
  );

  return (
    <div
      className={classnames(`dropdown-item`, className, {
        selected: selected,
        disabled: disabled,
        icon: icon,
      })}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </div>
  );
};

interface IHoverSubmenuItemProps extends HTMLProps<HTMLDivElement> {
  itemText: ReactNode;
}

export const HoverSubmenuItem: FC<IHoverSubmenuItemProps> = ({
  className = "",
  children,
  itemText,
  ...otherProps
}) => {
  const [leftAlign, setLeftAlign] = useState(false);
  const [visible, setVisible] = useState(false);
  const dropdownItemRef = useRef<HTMLDivElement>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);

  const onMouseEnter = () => {
    if (dropdownItemRef.current) {
      // Make sure we have about 300px of space on the right before we pop stuff up.
      // If there's not enough room, default to left aligning.
      // When the inner popup becomes visible, we'll do another check to see if we need
      // to switch sides again.
      const { right } = dropdownItemRef.current.getBoundingClientRect();
      if (innerWidth - right < 300) {
        setLeftAlign(true);
      }
    }

    setVisible(true);
  };

  const onMouseLeave = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      // Now it's visible, double check if this element has enough room to pop up right aligned. Otherwise left align.
      if (popupContentRef.current) {
        const { right } = popupContentRef.current.getBoundingClientRect();
        if (right > window.innerWidth) {
          setLeftAlign(true);
        }
      }
    } else {
      setLeftAlign(false);
    }
  }, [visible]);

  return (
    <div
      ref={dropdownItemRef}
      className={`dropdown-item sub-menu-item ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(e) => {
        if ((e.target as Element).classList.contains("sub-menu-item")) {
          // Don't handle click event if the sub menu item itself is clicked
          e.stopPropagation();
        }
      }}
      {...otherProps}
    >
      {itemText} <div className="cr-icon-chevron" />
      {visible && (
        <div
          ref={popupContentRef}
          className={`popup-content sub-menu-popup ${leftAlign ? "left" : ""} ${
            visible ? "visible" : ""
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export interface DropdownV2Handle {
  setOpen: (open: boolean) => void;
}

interface IDropdownV2Props {
  className?: string;
  popupItem: ReactNode;
  noCloseOnClickInside?: boolean; // If true, do not auto close the dropdown when something inside it is clicked.
  noCloseOnClickOutside?: boolean; // If true, do not autoclose the dropdown when we click outside of it
  children: ReactNode; // DropdownItem and DropdownSeparator components are intended to be used as children
  onActiveChange?: (active: boolean) => void;
  forceLeftAlign?: boolean;
  disabled?: boolean;
  stopPropagation?: boolean; // If true, stop propagation when the popup item is clicked
  shouldBottomAlign?: boolean; // If true, bottom align the content if there is not enough room below the dropdown
  testId?: string;
  autoCloseOnMouseLeave?: boolean; // If true, dropdown will be autoclosed 400ms after mouseleave (and no re-renter)
}

const DropdownV2 = forwardRef(function DropdownV2(
  {
    className = "",
    popupItem,
    children,
    noCloseOnClickInside = false,
    noCloseOnClickOutside = false,
    onActiveChange,
    forceLeftAlign,
    disabled,
    shouldBottomAlign,
    stopPropagation,
    testId,
    autoCloseOnMouseLeave,
  }: IDropdownV2Props,
  ref: Ref<DropdownV2Handle>
) {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [leftAlign, setLeftAlign] = useState(false);
  const [bottomAlign, setBottomAlign] = useState(false);

  const popupContentRef = useRef<HTMLDivElement>(null);

  // Keep a ref of the latest mouseover/mouseout effect and fire event to auto close if needed
  const hoveredRef = useRef(false);
  const debouncedHoverExitCallback = useCallback(
    debounce(() => {
      if (!hoveredRef.current) {
        setOpen(false);
      }
    }, 400),
    []
  );

  // Expose a method on the passed in ref, so the open state can be controlled by its parent.
  useImperativeHandle(ref, () => ({
    setOpen: (open: boolean) => setOpen(open),
  }));

  useEffect(() => {
    if (open) {
      // Set a click handler on the window, to close the dropdown
      const outsideClickHandler = (e: MouseEvent) => {
        if (
          noCloseOnClickInside &&
          (e.target as Element).closest(".popup-content")
        ) {
          // Clicked inside the popup-content and no close on inside click is set so just return
          return;
        } else if ((e.target as Element).closest(".popup-target")) {
          // Clicked on the popup target so let the popup-target handler take care of it
          return;
        }

        if (noCloseOnClickOutside) {
          return;
        }

        setOpen(false);
      };

      // Add our event listener ouside the current call stack so it doesn't catch the click event that
      // caused the dropdown to open.
      setTimeout(
        () => document.addEventListener("mouseup", outsideClickHandler),
        0
      );

      if (popupContentRef.current) {
        const { left, bottom } =
          popupContentRef.current.getBoundingClientRect();

        // Check if this element has enough room to pop up right aligned. Otherwise left align.
        if (left < 0 || forceLeftAlign) {
          setLeftAlign(true);
        }

        // Check if this element has enough room to pop up below. Otherwise bottom align.
        if (shouldBottomAlign) {
          const bottomDistanceFromTop = window.scrollY + bottom;

          if (document.body.scrollHeight - bottomDistanceFromTop - 60 < 0) {
            setBottomAlign(true);
          }
        }
      }

      // Set visibility now that our left alignment has been set
      setVisible(true);

      return () => document.removeEventListener("mouseup", outsideClickHandler);
    } else {
      // Reset our left alignment and visibility
      setLeftAlign(false);
      setBottomAlign(false);
      setVisible(false);
    }

    return;
  }, [
    open,
    noCloseOnClickInside,
    forceLeftAlign,
    shouldBottomAlign,
    noCloseOnClickOutside,
  ]);

  // Fire callback when the open state changes
  useEffect(() => {
    if (onActiveChange) {
      onActiveChange(open);
    }
  }, [open, onActiveChange]);

  return (
    <div
      className={classnames("dropdown-v2", className, {
        open,
        disabled: disabled,
      })}
      onMouseOver={
        autoCloseOnMouseLeave
          ? () => {
              hoveredRef.current = true;
            }
          : undefined
      }
      onMouseLeave={
        autoCloseOnMouseLeave
          ? () => {
              hoveredRef.current = false;
              debouncedHoverExitCallback();
            }
          : undefined
      }
    >
      <div
        className="popup-target"
        data-testid={testId}
        onClick={
          disabled
            ? undefined
            : (e) => {
                if (stopPropagation) {
                  e.stopPropagation();
                }
                setOpen(!open);
              }
        }
      >
        {popupItem}
      </div>
      <TransitionGroup component={null}>
        {open && (
          <CSSTransition
            key={"menu-popup"}
            timeout={250}
            classNames="fade-transition"
          >
            <div
              className={classnames("popup-content", {
                left: leftAlign,
                bottom: bottomAlign,
                visible,
              })}
              ref={popupContentRef}
            >
              {children}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
});

export default memo(DropdownV2);

interface ButtonWithDropdownV2Props extends PropsWithChildren {
  dropdownProps?: Omit<IDropdownV2Props, "popupItem" | "children">;
  buttonProps?: IButtonProps; // Override any of the dervied button props
  icon?: React.ReactNode;
  text?: React.ReactNode;
  loading?: boolean;
  overrideLoadingColor?: string;
  disabled?: boolean;
  showChevron?: boolean;
  tertiary?: boolean;
  primary?: boolean;
  hoverText?: string;
  hoverLocation?: HoverLocation;
  persistentHover?: boolean;
  onDismissHover?: () => void;
  onClick?: () => void;
}

// A wrapper for a button with the dropdown to provide additional styling automatically
export const ButtonWithDropdownV2: FC<ButtonWithDropdownV2Props> = ({
  dropdownProps,
  buttonProps,
  icon,
  text,
  children,
  loading,
  overrideLoadingColor,
  disabled,
  showChevron,
  tertiary = true,
  primary = false,
  hoverText,
  hoverLocation,
  persistentHover,
  onDismissHover,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(false);

  const buttonClasses = classnames("dropdown-button", {
    "dropdown-active": isActive,
  });
  let buttonElement = undefined;

  if (icon && !text) {
    buttonElement = (
      <IconButton
        loading={loading}
        disabled={disabled}
        icon={icon}
        className={buttonClasses}
        hoverText={hoverText}
        hoverLocation={hoverLocation}
      />
    );
  } else {
    buttonElement = (
      <Button
        loading={loading}
        overrideLoadingColor={overrideLoadingColor}
        disabled={disabled}
        primary={primary}
        tertiary={tertiary}
        className={buttonClasses}
        onClick={onClick}
        {...buttonProps}
      >
        {icon && icon}
        {text}
        {showChevron && (
          <Icon name={"chevron"} direction={!isActive ? 180 : 0} />
        )}
      </Button>
    );
    if (hoverText) {
      buttonElement = (
        <DismissablePopupMemo
          autoDismissAfter={!persistentHover ? 0 : undefined}
          popupOnHoverToo
          onDismiss={onDismissHover}
          initDismissed={!persistentHover}
          text={hoverText}
          position={
            hoverLocation == HoverLocation.Left
              ? "left"
              : hoverLocation == HoverLocation.Top
                ? "top"
                : hoverLocation == HoverLocation.Bottom
                  ? "bottom"
                  : "right"
          }
        >
          {buttonElement}
        </DismissablePopupMemo>
      );
    }
  }

  return (
    <DropdownV2
      {...dropdownProps}
      popupItem={buttonElement}
      disabled={!!loading || !!disabled}
      onActiveChange={(active) => {
        setIsActive(active);

        // Bubble if necessary
        if (dropdownProps?.onActiveChange) {
          dropdownProps.onActiveChange(active);
        }
      }}
    >
      {children}
    </DropdownV2>
  );
};

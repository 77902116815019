import { useAppSelector } from "../../_common/types/reduxHooks";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";

export interface RiskOptions {
  isCustomer: boolean;
  isSubsidiary: boolean;
  vendorID?: number;
}

/**
 * useRisk retrieves risk data from the Redux store based on risk ID and entity type.
 * This hook provides a simpler interface for components to access risk data.
 *
 * @param riskID - Unique identifier for the risk
 * @param opts - Options to determine which entity type to retrieve the risk for
 * @returns The found risk object or undefined if not found
 */
export const useRisk = (
  riskID: string,
  opts: RiskOptions
): VendorSummaryRisk | undefined => {
  return useAppSelector((state) => {
    if (opts.isCustomer) {
      return state.cyberRisk.customerData.summary?.result?.risks?.find(
        (r) => r.id === riskID
      );
    }

    if (!opts.vendorID) {
      return undefined;
    }

    if (opts.isSubsidiary) {
      return state.cyberRisk.subsidiaries[
        opts.vendorID
      ]?.summary?.result?.risks?.find((r) => r.id === riskID);
    }

    return state.cyberRisk.vendors[opts.vendorID]?.summary?.result?.risks?.find(
      (r) => r.id === riskID
    );
  });
};

import { useCallback, useEffect } from "react";

export const useActionOnClickOutsideContainer = (
  containerRef: React.RefObject<HTMLElement>,
  onClickOutsideContainer: () => void
) => {
  const handleClickOutsideContainer = useCallback(
    (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onClickOutsideContainer();
      }
    },
    [containerRef, onClickOutsideContainer]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      document.addEventListener("mousedown", handleClickOutsideContainer);
    }
    return () => {
      if (container) {
        document.removeEventListener("mousedown", handleClickOutsideContainer);
      }
    };
  }, [containerRef, handleClickOutsideContainer]);
};

import { FC, useCallback, useEffect, useState } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import StepsWithSections, {
  Steps,
} from "../../../_common/components/StepsWithSections";
import Button from "../../../_common/components/core/Button";
import TextField, {
  useTextWithValid,
} from "../../../_common/components/TextField";
import { QueryError } from "../../../_common/rtkQueryApi";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import TrustPageCustomDomainDnsDisplay from "./TrustPageCustomDomainDnsDisplay";
import TrustPageCustomDomainsAPI, {
  TrustPageCustomDomainsTagTypes,
} from "../../api/trustPageCustomDomains.api";
import "./TrustPageAddCustomDomainModal.scss";
import { AddCustomDomainError } from "../../api/trustpage.api.types";
import { validateDomainName } from "../../../_common/helpers";

interface TrustPageAddCustomDomainModalProps extends BaseModalProps {
  existingDomain?: string;
}

const TrustPageAddCustomDomainModal: FC<TrustPageAddCustomDomainModalProps> = ({
  active,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [addCustomDomain, { isLoading: isSaving }] =
    TrustPageCustomDomainsAPI.useAddTrustPageCustomDomainV1Mutation();

  const [currentStep, setCurrentStep] = useState(1);
  const [domain, domainValid, setDomain] = useTextWithValid("");
  const [errorTexts, setErrorTexts] = useState<string[]>([]);

  useEffect(() => {
    if (!validateDomainName(domain)) {
      setErrorTexts(["Invalid domain"]);
    } else {
      setErrorTexts([]);
    }
  }, [domain]);

  const saveClick = useCallback(() => {
    // Attempt to add the domain
    addCustomDomain({ domain, isPrimary: true })
      .unwrap()
      .then(() => {
        setCurrentStep(2);

        // TODO: why do I need this?
        dispatch(
          TrustPageCustomDomainsAPI.util.invalidateTags([
            TrustPageCustomDomainsTagTypes.trustPageCustomDomains,
          ])
        );
      })
      .catch((err: QueryError) => {
        if (err && err.status === 422) {
          switch (true) {
            case err.message === AddCustomDomainError.NotScanned:
              setErrorTexts(["Domain and parent domain not yet scanned"]);
              break;
            case err.message === AddCustomDomainError.NotPartOfVendor:
              setErrorTexts([
                "This domain and/or a parent domain are not currently attributed to your organization - please contact support",
              ]);
              break;
            default:
              setErrorTexts([err.message]);
              break;
          }
        } else {
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error adding custom domain for trust page"
            )
          );
        }
      });
  }, [domain]);

  return (
    <ModalV2
      width={600}
      active={active}
      onClose={onClose}
      className="trust-page-add-custom-domain-modal"
      headerContent={
        currentStep === 1 ? "Add custom domain" : "DNS Configuration"
      }
      footerContent={
        currentStep === 1 ? (
          <div className="btn-group">
            <Button tertiary disabled={isSaving} onClick={onClose}>
              Cancel
            </Button>
            <Button
              primary
              disabled={!domainValid || errorTexts.length > 0}
              loading={isSaving}
              onClick={saveClick}
            >
              Save
            </Button>
          </div>
        ) : undefined
      }
    >
      <StepsWithSections>
        <Steps
          currentStep={currentStep}
          steps={[
            {
              id: "domain",
              text: "Your domain",
            },
            {
              id: "dns",
              text: "Configure DNS",
            },
          ]}
        />
      </StepsWithSections>
      <br />
      {currentStep === 1 && (
        <>
          <label>What is your domain?</label>
          <p>The host name you want to use</p>
          <TextField
            required
            placeholder={"e.g. yourdomain.com"}
            value={domain}
            onChanged={setDomain}
            errorTexts={errorTexts}
          />
        </>
      )}
      {currentStep === 2 && <TrustPageCustomDomainDnsDisplay domain={domain} />}
    </ModalV2>
  );
};

export default TrustPageAddCustomDomainModal;

import { Severity } from "../../_common/types/severity";
import { ILabel } from "../../_common/types/label";
import { LabelColor } from "../../_common/types/label";

export const SYSTEM_USER_ID = -99;
export const BreachRiskUserName = "Breach Risk";

export enum ThreatMonitoringKeywordType {
  Domain = "domain",
  IPAddress = "ip_address",
  EmailAddress = "email_address",
  PhoneNumber = "phone_number",
  FreeText = "free_text",
  /*
   BIN/CC keyword types will only be enabled when we have a safe solution
  CreditCardNumber = "credit_card_number",
  BankIdentificationNumber = "bank_identification_number",
  */
}

export enum ThreatMonitoringCriticality {
  High = "high",
  Medium = "medium",
  Low = "low",
}

export const MinKeywordLength = 3;

export interface ThreatMonitoringKeyword {
  uuid: string;
  keyword: string;
  keywordType: ThreatMonitoringKeywordType;
  criticality?: ThreatMonitoringCriticality;
  createdAt: Date;
  createdByUserID: number;
  active: boolean;
}

export enum ThreatMonitoringModule {
  // Note that the "Dark Web" threat monitoring module can return
  // results from the clear/open & deep web as well (e.g. a pastebin entry (open), a telegram post (deep))
  ModuleDarkWeb = "dark_web",
  ModuleDataLeaks = "data_leaks",
  ModuleSocialMedia = "social_media",
}

export enum ThreatMonitoringThreatType {
  Unknown = "unknown",
  ExposedCredentials = "exposed_credentials",
  ExposedPII = "exposed_pii",
  Malware = "malware",
  KeywordMention = "keyword_mention",
  ConfidentialBusiness = "confidential_business",
  CompromisedUser = "compromised_user",
}

export function IsThreatMonitoringThreatType(
  s: string
): s is ThreatMonitoringThreatType {
  return Object.values(ThreatMonitoringThreatType).includes(
    s as ThreatMonitoringThreatType
  );
}

export enum ThreatMonitoringSourceType {
  Unknown = "unknown",
  StealerLogs = "stealer_logs",
  Forum = "forum",
  Marketplace = "marketplace",
  RansomwareBlog = "ransomware_blog",
  Telegram = "telegram",
  Discord = "discord",
  ICQ = "icq",
  OpenBazaar = "openbazaar",
  Paste = "paste",
  Blogs = "blogs",
  News = "news",
  SocialMedia = "social_media",
}

// peer: threatmonitoring/domain.go
export enum ThreatMonitoringVendorNetworkType {
  WebzNetworkTypeTOR = "tor",
  WebzNetworkTypeI2P = "i2P", // the capital P matches the Webz-defined value
  WebzNetworkTypeOpenBazaar = "openbazaar",
  WebzNetworkTypeTelegram = "telegram",
  WebzNetworkTypeDiscord = "discord",
  WebzNetworkTypeOpenWeb = "openweb",
  WebzNetworkTypeICQ = "icq",
  WebzNetworkTypePublicChat = "public_chat",
}

export function IsThreatMonitoringSourceType(
  s: string
): s is ThreatMonitoringSourceType {
  return Object.values(ThreatMonitoringSourceType).includes(
    s as ThreatMonitoringSourceType
  );
}

// DisplayableThreatMonitoringResultStatus maps to [threatmonitoring/domain.go.DisplayableThreatMonitoringResultStatus].
export enum DisplayableThreatMonitoringResultStatus {
  Open = "open",
  Dismissed = "dismissed",
  SmartFiltered = "smart_filtered",
  Remediated = "remediated",
  Waived = "waived",
}

export interface DisplayableThreatMonitoringResultStatusLabel {
  value: string;
  color: LabelColor;
}

export const DisplayableThreatMonitoringResultStatusToLabel = (
  s: DisplayableThreatMonitoringResultStatus
): DisplayableThreatMonitoringResultStatusLabel => {
  switch (s) {
    case DisplayableThreatMonitoringResultStatus.Dismissed:
      return {
        value: "Dismissed",
        color: LabelColor.Blue,
      };
    case DisplayableThreatMonitoringResultStatus.SmartFiltered:
      return {
        value: "Smart filtered",
        color: LabelColor.Orange,
      };
    case DisplayableThreatMonitoringResultStatus.Remediated:
      return {
        value: "Remediated",
        color: LabelColor.Green,
      };
    case DisplayableThreatMonitoringResultStatus.Waived:
      return {
        value: "Waived",
        color: LabelColor.Grey500,
      };
    default:
      return {
        value: "Unknown",
        color: LabelColor.TrendyPink,
      };
  }
};

export interface DisplayableThreatMonitoringResult {
  uuid: string;
  title: string;
  keyword: string;
  severity: Severity;
  dateDetected: string;
  threatTypes: ThreatMonitoringThreatType[];
  source: ThreatMonitoringSourceType;
  module: ThreatMonitoringModule;
  actorId?: number;
  sortActorId?: number;
  originURL: string;
  snippet: string;
  labels?: ILabel[];

  dismissed?: boolean;
  dismissedAt?: string;
  autoDismissed?: boolean;
  dismissedById?: number;

  investigating?: boolean;
  investigatorId: number;

  archived?: boolean;
  additionalAttributes?: DisplayableThreatMonitoringResultAdditionalAttributes;
  status: DisplayableThreatMonitoringResultStatus;
  remediationRequestID?: number;
  outcome?: ThreatMonitoringRemediationOutcome;
  outcomeNotes?: string;
  remediatedAt?: string;
  remediatedByID?: number;

  updatedAt: string;
  remediationRequestUpdatedAt?: string;

  numberOfComments: number;
  hasUnreadComments: boolean;
}

export interface ChatAdditionalAttributes {
  discord?: DiscordChatAdditionalAttributes;
  telegramGroup?: TelegramChatAdditionalAttributes;
  telegramChannel?: TelegramChatAdditionalAttributes;
}

export function getChatEntries(chat?: ChatAdditionalAttributes): ThreadEntry[] {
  if (!chat) {
    return [];
  }

  return (
    chat.discord?.parsedThread ??
    chat.telegramGroup?.parsedThread ??
    chat.telegramChannel?.parsedThread ??
    []
  );
}

export interface BaseChatAdditionalAttributes {
  language: string;
  parsedThread: ThreadEntry[];
  author: string;
  participants: string[];
}

export interface DiscordChatAdditionalAttributes
  extends BaseChatAdditionalAttributes {
  channel: string;
  server: string;
}

export interface TelegramChatAdditionalAttributes
  extends BaseChatAdditionalAttributes {
  name: string;
}

export interface PostAdditionalAttributes {
  text: string;

  author: string;
  postTitle: string;
  postReplies: number;
  postURL: string;
  threadParticipants: number;

  siteName: string;
  type: string;
  network: ThreatMonitoringVendorNetworkType;
  language: string;
}

export interface ThreadEntry {
  date: string;
  text: string;
  user: string;
  message_id: number;
  forwarded: boolean;
  forwarded_from?: string;
  file_info?: string;
}

export interface MalwareAdditionalAttributes {
  ip?: string;
  asn?: number;
  asnText?: string;
  deviceAccount?: string;
  deviceLocation?: string;
  deviceName?: string;
  deviceOS?: string;
  deviceSerialNumber?: string;
  deviceUserAgent?: string;
  malwareType?: string;
  malwareVersion?: string;
  malwareRefURL?: string;
  malwarePath?: string;
}

export interface CookieAdditionalAttributes
  extends MalwareAdditionalAttributes {
  cookieName: string;
  cookieValue: string;
}

export interface PasswordAdditionalAttributes
  extends MalwareAdditionalAttributes {
  username?: string;
  password?: string;
}

export interface DisplayableThreatMonitoringResultAdditionalAttributes {
  chat?: ChatAdditionalAttributes;
  post?: PostAdditionalAttributes;
  cookie?: CookieAdditionalAttributes;
  password?: PasswordAdditionalAttributes;
}

export type ThreatRemediationUsers = { [remediationID: number]: number[] };

// peer: ThreatFeedType in threatmonitoring/domain.go
export enum ThreatMonitoringFeedType {
  Open = "open",
  Investigating = "investigating",
  Remediating = "remediating",
  Closed = "closed",
}

export type ThreatMonitoringResultsFeedType =
  | ThreatMonitoringFeedType.Open
  | ThreatMonitoringFeedType.Investigating
  | ThreatMonitoringFeedType.Remediating
  | ThreatMonitoringFeedType.Closed;

// peer: ThreatMonitoringResultState in threatmonitoring/domain.go
export enum ThreatMonitoringResultState {
  Open = "open",
  Investigating = "investigating",
  Dismissed = "dismissed",
  Remediating = "remediating",
  Remediated = "remediated",
}

export type ThreatMonitoringResultStateType =
  | ThreatMonitoringResultState.Open
  | ThreatMonitoringResultState.Investigating
  | ThreatMonitoringResultState.Dismissed
  | ThreatMonitoringResultState.Remediating
  | ThreatMonitoringResultState.Remediated;

export enum ThreatMonitoringResultsDatePeriodOption {
  LastTwentyFourHours = "last_twenty_four_hours",
  LastSevenDays = "last_seven_days",
  LastThirtyDays = "last_thirty_days",
  LastThreeMonths = "last_three_months",
  LastSixMonths = "last_six_months",
  LastTwelveMonths = "last_twelve_months",

  // AllTime is used for looking up a threat that may be older than 13 months, for example,
  // historical remediation requests.
  AllTime = "all_time",
}

const allThreatMonitoringResultsDatePeriodOptions: ThreatMonitoringResultsDatePeriodOption[] =
  [
    ThreatMonitoringResultsDatePeriodOption.LastTwentyFourHours,
    ThreatMonitoringResultsDatePeriodOption.LastSevenDays,
    ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
    ThreatMonitoringResultsDatePeriodOption.LastThreeMonths,
    ThreatMonitoringResultsDatePeriodOption.LastSixMonths,
    ThreatMonitoringResultsDatePeriodOption.LastTwelveMonths,
  ];

export const ParseThreatMonitoringResultsDatePeriodOption = (
  str: string
): ThreatMonitoringResultsDatePeriodOption | undefined => {
  return allThreatMonitoringResultsDatePeriodOptions.find(
    (option) => option === str
  );
};

export interface ThreatMonitoringResultsFilter {
  datePeriod: ThreatMonitoringResultsDatePeriodOption;
  keywords?: string[];
  severities?: Severity[];
  threatTypes?: ThreatMonitoringThreatType[];
  sourceTypes?: ThreatMonitoringSourceType[];
  investigatorUserIDs?: number[];
  notBeingInvestigated?: boolean;
  remediatorUserIDs?: number[];
  closedByUserIDs?: number[];
  autoDismissed?: boolean;
  containsText?: string;
  doesNotContainText?: string;
  statuses?: DisplayableThreatMonitoringResultStatus[];
  remediationRequestID?: number;
}

export enum ThreatResultSortBy {
  Severity = "severity",
  Date = "date",
}

export interface ThreatMonitoringSortOpts {
  sortBy: string;
  sortDesc?: boolean;
}

export interface ThreatMonitoringPageOpts extends ThreatMonitoringSortOpts {
  pageSize: number;
  pageNum: number;
}

// peer: ThreatMonitoringHistoryEventType in threatmonitoring/domain.go
export enum ThreatMonitoringHistoryEventType {
  Created = "created",
  StateChanged = "state_changed",
}

export enum ThreatActionValue {
  AssignedTo = "assigned-to",
  Dismissed = "dismissed",
  Remediated = "remediated",
  Remediating = "remediating",
}

export interface ThreatMonitoringHistoryTarget {
  investigatorId?: number;
  remediationRequestId?: number;
  remediationOutcome?: ThreatMonitoringRemediationOutcome;
}

// peer: ThreatMonitoringHistoryEvent in threatmonitoring/domain.go
export interface ThreatMonitoringHistoryEvent {
  eventType: ThreatMonitoringHistoryEventType;
  value: ThreatActionValue;
  target?: ThreatMonitoringHistoryTarget;
  userID: number;
  occurredAt: string;
}

// peer: ThreatOutcome in threatmonitoring/domain.go
// Note that Remediated and Waived are only part of this enum to match the backend type.
// On the frontend, they are considered part of ThreatMonitoringRemediationStatus.
// This should probably be fixed on the backend.
export enum ThreatMonitoringRemediationOutcome {
  ResponsiblePartyContacted = "responsible_party_contacted",
  AffectedUsersNotified = "affected_users_notified",
  AffectedUsersCredentialsReset = "affected_users_credentials_reset",
  RequestedTakedown = "requested_takedown",
  Other = "other",
  Remediated = "remediated",
  Waived = "waived",
}

export const ThreatMonitoringRemediationOutcomeSelectableOptions: ThreatMonitoringRemediationOutcome[] =
  [
    ThreatMonitoringRemediationOutcome.ResponsiblePartyContacted,
    ThreatMonitoringRemediationOutcome.AffectedUsersNotified,
    ThreatMonitoringRemediationOutcome.AffectedUsersCredentialsReset,
    ThreatMonitoringRemediationOutcome.RequestedTakedown,
    ThreatMonitoringRemediationOutcome.Other,
  ];

export enum ThreatMonitoringRemediationStatus {
  Remediated = "remediated",
  Waived = "waived",
}

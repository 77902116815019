import { isEqual as _isEqual, isNumber as _isNumber } from "lodash";
import { Filters, FilterTypes } from "./types";
import { DefaultManagedVendorServiceStatusTypes } from "../../../analyst_portal/components/filter/TprmsFilter.helpers";

export const isFilterActive = (
  {
    includeUnlabeled,
    minScore,
    maxScore,
    vendorLabelIds = [],
    websiteLabelIds = [],
    websiteIncludeUnlabeled,
    riskCategories = [],
    vendorIds = [],
    riskIds = [],
    vulnsCveNames = [],
    vulnsSoftware = [],
    vulnsExcludeVerified = false,
    vulnsExcludeUnverified = false,
    vulnsOnlyKEV = false,
    vulnsExcludedSeverities = [],
    vulnsEPSSScores = {},
    typosquatReasonIds = [],
    typosquatTypeIds = [],
    typosquatMaliciousActivities = [],
    subsidiaryLabelIds = [],
    subsidiaryCountries = [],
    subsidiaryIds = [],
    subsidiaryMinScore,
    subsidiaryMaxScore,
    vendorTiers = [],
    vendorAssessmentClassifications = [],
    vendorAssessmentAuthors = [],
    vendorReassessmentStartDate = "",
    vendorReassessmentEndDate = "",
    vendorDateAddedStartDate = "",
    vendorDateAddedEndDate = "",
    portfolioIds = [],
    domainPortfolioIds = [],
    selectedSurveyStatuses = [],
    selectedSurveyAttributes = [],
    selectedAttributes = {},
    dataLeaksClassifications = [],
    dataLeaksKeywords = [],
    dataLeaksSources = [],
    dataLeaksReportedDate = undefined,
    vendorSurveyTypes = [],
    vendorEvidenceTypes = [],
    orgNames = [],
    excludedServiceLevels = undefined,
    includedServiceStatuses = DefaultManagedVendorServiceStatusTypes,
    fourthPartyProductUUIDs = [],
    cloudConnectionProviderTypes = [],
    cloudConnectionUUIDs = [],
    userRiskUserUUIDs = [],
    userRiskAppNames = [],
    userRiskTeams = [],
    userRiskRoles = [],
    userRiskAppStatuses = [],
    userRiskMinFirstDetected = undefined,
    userRiskMaxFirstDetected = undefined,
    userRiskMinRiskLevel = undefined,
    userRiskMaxRiskLevel = undefined,
    userRiskScopeReadOnly = true,
    userRiskScopeReadWrite = true,
    userRiskScopeCategories = [],
    userRiskScopes = [],
    userRiskAppIsUsingAI = undefined,
    userRiskScopeMinRiskLevel = undefined,
    userRiskScopeMaxRiskLevel = undefined,
    userRiskUserMinScore = 0,
    userRiskUserMaxScore = 950,
    userRiskAppMinScore = 0,
    userRiskAppMaxScore = 950,
    userRiskAppCategories = [],
  }: Filters,
  supportedFilters: FilterTypes[]
) => {
  const isActive = !!(
    (supportedFilters.includes(FilterTypes.VENDOR_LABEL) &&
      (includeUnlabeled || vendorLabelIds.length !== 0)) ||
    (supportedFilters.includes(FilterTypes.WEBSITE_LABEL) &&
      websiteLabelIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.WEBSITE_LABEL_ADVANCED) &&
      (websiteIncludeUnlabeled || websiteLabelIds.length !== 0)) ||
    (supportedFilters.includes(FilterTypes.DOMAIN_PORTFOLIO) &&
      domainPortfolioIds.length > 1) ||
    (supportedFilters.includes(FilterTypes.SCORE) &&
      (_isNumber(minScore) || _isNumber(maxScore))) ||
    (supportedFilters.includes(FilterTypes.RISK_CATEGORY) &&
      riskCategories.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR) && vendorIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.SUBSIDIARY) &&
      subsidiaryIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.RISK) && riskIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VULNS_CVE_NAME) &&
      vulnsCveNames.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VULNS_SOFTWARE) &&
      vulnsSoftware.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VULNS_VERIFIED_STATUS) &&
      vulnsExcludeVerified === true) ||
    (supportedFilters.includes(FilterTypes.VULNS_VERIFIED_STATUS) &&
      vulnsExcludeUnverified === true) ||
    (supportedFilters.includes(FilterTypes.VULNS_VERIFIED_STATUS) &&
      vulnsOnlyKEV === true) ||
    (supportedFilters.includes(FilterTypes.VULNS_CVSS_SEVERITY) &&
      vulnsExcludedSeverities.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VULNS_EPSS_SEVERITY) &&
      (!!vulnsEPSSScores.lowerVal || !!vulnsEPSSScores.upperVal)) ||
    (supportedFilters.includes(FilterTypes.TYPOSQUAT_REASONS) &&
      typosquatReasonIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.TYPOSQUAT_TYPES) &&
      typosquatTypeIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.TYPOSQUAT_MALICIOUS_ACTIVITIES) &&
      typosquatMaliciousActivities.length !== 0) ||
    (supportedFilters.includes(FilterTypes.SUBSIDIARY_LABEL) &&
      subsidiaryLabelIds.length !== 0) ||
    (supportedFilters.includes(FilterTypes.SUBSIDIARY_COUNTRY) &&
      subsidiaryCountries.length !== 0) ||
    (supportedFilters.includes(FilterTypes.SUBSIDIARY_SCORE) &&
      (_isNumber(subsidiaryMinScore) || _isNumber(subsidiaryMaxScore))) ||
    (supportedFilters.includes(FilterTypes.VENDOR_TIER) &&
      vendorTiers.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_STATUS) &&
      vendorAssessmentClassifications.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_AUTHOR) &&
      vendorAssessmentAuthors.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_REASSESSMENT_DATE) &&
      vendorReassessmentStartDate.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_REASSESSMENT_DATE) &&
      vendorReassessmentEndDate.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_DATE_ADDED) &&
      vendorDateAddedStartDate.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_DATE_ADDED) &&
      vendorDateAddedEndDate.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_PORTFOLIO) &&
      portfolioIds.length > 1) ||
    (supportedFilters.includes(FilterTypes.SURVEY_STATUS) &&
      selectedSurveyStatuses.length > 0) ||
    (supportedFilters.includes(FilterTypes.SURVEY_STATUS) &&
      selectedSurveyAttributes.length > 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_ATTRIBUTES) &&
      Object.values(selectedAttributes).some(
        (v) => v.selectedValues?.length || v.startDate || v.endDate
      )) ||
    (supportedFilters.includes(FilterTypes.DATALEAKS_SOURCE) &&
      dataLeaksSources.length !== 0) ||
    (supportedFilters.includes(FilterTypes.DATALEAKS_CLASSIFICATION) &&
      dataLeaksClassifications.length !== 0) ||
    (supportedFilters.includes(FilterTypes.DATALEAKS_KEYWORD) &&
      dataLeaksKeywords.length !== 0) ||
    (supportedFilters.includes(FilterTypes.DATALEAKS_REPORTED_DATE) &&
      !!dataLeaksReportedDate) || // Only count portfolio filtering if there is more than one in the filter
    (supportedFilters.includes(FilterTypes.VENDOR_SURVEY_TYPES) &&
      vendorSurveyTypes.length !== 0) ||
    (supportedFilters.includes(FilterTypes.VENDOR_EVIDENCE_TYPES) &&
      vendorEvidenceTypes.length !== 0) ||
    (supportedFilters.includes(FilterTypes.FOURTH_PARTY_PRODUCT) &&
      fourthPartyProductUUIDs.length !== 0) ||
    (supportedFilters.includes(
      FilterTypes.APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE
    ) &&
      cloudConnectionProviderTypes.length !== 0) ||
    (supportedFilters.includes(FilterTypes.APPGUARD_CLOUD_CONNECTION_UUID) &&
      cloudConnectionUUIDs.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_USER_UUIDS) &&
      userRiskUserUUIDs.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APPS) &&
      userRiskAppNames.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_TEAMS) &&
      userRiskTeams.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_ROLES) &&
      userRiskRoles.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_STATUS) &&
      userRiskAppStatuses.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_APPROVED_TEAMS) &&
      userRiskTeams.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_APPROVED_ROLES) &&
      userRiskRoles.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_FIRST_DETECTED) &&
      (userRiskMinFirstDetected || userRiskMaxFirstDetected)) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_RISK_LEVEL) &&
      (!!userRiskMinRiskLevel || !!userRiskMaxRiskLevel)) ||
    (supportedFilters.includes(FilterTypes.USERRISK_SCOPE_RISK_LEVEL) &&
      (!!userRiskScopeMinRiskLevel || !!userRiskScopeMaxRiskLevel)) ||
    (supportedFilters.includes(FilterTypes.USERRISK_SCOPE_READWRITE) &&
      (!userRiskScopeReadOnly || !userRiskScopeReadWrite)) ||
    (supportedFilters.includes(FilterTypes.USERRISK_SCOPE_CATEGORIES) &&
      userRiskScopeCategories.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_SCOPES) &&
      userRiskScopes.length !== 0) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_IS_USING_AI) &&
      userRiskAppIsUsingAI !== undefined) ||
    (supportedFilters.includes(FilterTypes.USERRISK_USER_SCORES) &&
      (userRiskUserMinScore > 0 || userRiskUserMaxScore < 950)) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_SCORES) &&
      (userRiskAppMinScore > 0 || userRiskAppMaxScore < 950)) ||
    (supportedFilters.includes(FilterTypes.USERRISK_APP_CATEGORIES) &&
      userRiskAppCategories.length !== 0)
  );

  if (!isActive) {
    switch (true) {
      case supportedFilters.includes(FilterTypes.TPRMS_ORG_NAMES) &&
        orgNames &&
        orgNames?.length > 0:
      case supportedFilters.includes(
        FilterTypes.TPRMS_EXCLUDED_SERVICE_LEVELS
      ) &&
        excludedServiceLevels &&
        excludedServiceLevels?.length > 0:
      case supportedFilters.includes(
        FilterTypes.TPRMS_INCLUDED_SERVICE_STATUSES
      ) &&
        !_isEqual(
          [...includedServiceStatuses].sort(),
          [...DefaultManagedVendorServiceStatusTypes].sort()
        ):
        return true;
      default:
        return false;
    }
  }

  return isActive;
};

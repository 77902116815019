import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { NavToOptions, useNavigateTo } from "../../_common/types/router";
import tmSlice, { Module } from "../Slice";
import ThreatMonitoringAPI, {
  GetResultsV1ReqBody,
} from "../api/threatmonitoring.api";

export const rootPath = "/breachrisk/threatmonitoring";
export const rootPathRegex = new RegExp(`^${rootPath}/`);

export function threatLink(
  uuid: string,
  module?: Module,
  startWithCommentsOpen?: boolean
) {
  let url = `/breachrisk/threatmonitoring`;

  if (module !== "all") {
    url = `${url}/${module}`;
  }

  url = `${url}/threat/${uuid}`;

  if (startWithCommentsOpen) {
    url = `${url}?open_comments=true`;
  }

  return url;
}

export function useNavToThreatDetails(
  module: Module | undefined,
  opts: NavToOptions = {}
) {
  const dispatch = useAppDispatch();
  const navTo = useNavigateTo();

  return (uuid: string, startWithCommentsOpen?: boolean) => () => {
    const url = threatLink(uuid, module, startWithCommentsOpen);

    if (module !== undefined) {
      dispatch(tmSlice.actions.clearSessionSnapshot(module));
    }

    navTo(url, opts);
  };
}

// useNavigateToDetailsWithSession returns a function which can be used to start a threat assessment session for the feedQuery.
// Starting a session means snapshotting the current page results and total results, and navigating to the threat detail view.
export function useNavigateToDetailsWithSession(
  query: GetResultsV1ReqBody,
  module: Module,
  opts: NavToOptions = {}
) {
  const dispatch = useAppDispatch();
  const navTo = useNavigateTo({}, opts);

  const [fetchSessionUUIDs] =
    ThreatMonitoringAPI.useLazyGetResultUUIDsV1Query();

  return async function (uuid: string) {
    try {
      const resp = await fetchSessionUUIDs(query).unwrap();

      dispatch(
        tmSlice.actions.snapshotSession({
          module,
          snapshot: {
            uuids: resp.uuids,
            query,
          },
        })
      );

      console.log("navigating to ", threatLink(uuid, module));

      navTo(threatLink(uuid, module));
    } catch (e) {
      console.error(e);
      dispatch(tmSlice.actions.clearSessionSnapshot(module));
      dispatch(addDefaultUnknownErrorAlert("Failed to load threats"));
    }
  };
}

export function useNavToRemediationRequest(opts: NavToOptions = {}) {
  const navTo = useNavigateTo({}, opts);

  return (remediationRequestID: number) => () =>
    navTo(`/selfremediation/${remediationRequestID}`);
}

import { FC, PropsWithChildren } from "react";
import styles from "./MessageBar.module.scss";
import classNames from "classnames";

interface MessageBarProps extends PropsWithChildren {
  variant?: "blue";
}

const MessageBar: FC<MessageBarProps> = ({ variant = "blue", children }) => {
  const classes = classNames(styles.messageBar, {
    [styles.blue]: variant === "blue",
  });

  return <div className={classes}>{children}</div>;
};

export default MessageBar;

import { FC, ReactNode } from "react";
import "./DashboardContainer.scss";

interface DashboardContainerProps {
  title: string;
  children: ReactNode;
}

const DashboardContainer: FC<DashboardContainerProps> = ({
  title,
  children,
}) => {
  return (
    <div className="userbase-dashboard-container">
      <div className="dashboard-title">{title}</div>
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardContainer;

import { FC } from "react";
import { applicationsUrl } from "../../UserBaseAppRouter";
import userbaseApi from "../../api/userbase.api";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { useLocaleCompare } from "../../../_common/hooks";
import { useNavigateWithUserRiskFilters } from "../../helpers/hooks";
import CategoryList from "../applications/CategoryList";
import { UserRiskFilters } from "../../../vendorrisk/components/filter/types";
import { supportedFilters } from "../../views/applications/ApplicationsView";
import DashboardContainer from "./DashboardContainer";

const OrgAppCategoriesOverview: FC = ({}) => {
  const navigateWithUserRiskFilter =
    useNavigateWithUserRiskFilters(supportedFilters);

  const { data, isLoading } = userbaseApi.useGetAppCategoriesV1Query();
  const categories = data?.categories ?? [];

  // Build up a unique, sorted list of application categories
  const { caseInsensitiveCompare } = useLocaleCompare();

  const sortedCategories = [...categories].sort(caseInsensitiveCompare);

  const header = `App categories`;

  // When a category is clicked, go to the applications page filtered by category
  const onClick = (category: string) => {
    const filters: Partial<UserRiskFilters> = {
      userRiskAppCategories: [category],
    };

    navigateWithUserRiskFilter(applicationsUrl, "Back to Dashboard", filters);
  };

  return (
    <div className="userbase-org-app-categories-overview">
      <DashboardContainer title={header}>
        {isLoading && <LoadingBanner />}
        {!isLoading && (
          <CategoryList categories={sortedCategories} onClick={onClick} />
        )}
      </DashboardContainer>
    </div>
  );
};

export default OrgAppCategoriesOverview;

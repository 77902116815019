import { useEffect, useRef, useState } from "react";
import { History, LocationDescriptor } from "history";
import { DefaultThunkDispatch } from "../types/redux";
import { DefaultRouteProps } from "../types/router";
import "../style/components/SharedProfileSurveyDetails.scss";
import { formatDateAsLocal, sharedAssessmentsUrlPrefix } from "../helpers";
import LoadingBanner from "../components/core/LoadingBanner";
import { ISurveyListItemResponse } from "../types/survey";
import PageHeader from "../components/PageHeader";
import SurveyDetailsRisksCard, {
  getRisksList,
} from "../components/surveydetails/SurveyDetailsRisksCard";
import SurveyDetailsUnansweredQuestionsCard from "../components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import { getSurveyEditorPath } from "./SurveyDetails";
import ReportCard from "../components/ReportCard";
import { Link } from "react-router-dom";
import { usePermissions } from "../permissions";
import Button from "../components/core/Button";
import InfoTable, { InfoTableRow } from "../components/InfoTable";
import moment from "moment";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import ProgressBar from "../components/ProgressBar";
import { fetchVendorWatchStatus } from "../../vendorrisk/reducers/cyberRiskActions";
import ToggleSwitch from "../components/core/ToggleSwitch";
import ExcludeSharedProfileFromRiskProfileModal from "../components/modals/ExcludeSharedSurveyFromRiskProfileModal";
import SurveyName from "../../vendorrisk/components/surveys/SurveyName";
import { IAttachment } from "../types/attachment";
import { AssuranceType } from "../types/organisations";
import { getVendorPageBreadcrumbs } from "../components/Breadcrumbs";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import { removeByAssessmentPathSuffix } from "../../vendorrisk/views/VendorAssessmentV3";
import { InfoBar } from "../components/InfoBar";
import SharedProfileSurveyDocumentationTable from "../../vendorrisk/components/shared_profile/SharedProfileSurveyDocumentationTable";
import { appConnect, useAppSelector } from "../types/reduxHooks";
import VendorAssessmentAPI from "../../vendorrisk/reducers/vendorAssessmentAPI";
import { skipToken } from "@reduxjs/toolkit/query";
import { SidePopupV2 } from "../components/DismissablePopup";
import TrustPageAPI from "../../verifiedvendors/api/trustpage.api";
import { exportPublicSurveyInNewTab } from "../../verifiedvendors/helpers/helpers";

export interface ISharedProfileSurveyDetailOverview {
  survey: ISurveyListItemResponse;
  assessmentId?: number;
  getSurveyPath: () => LocationDescriptor;
  hasUnansweredQuestions: boolean;
  onUnansweredQuestionsClick?: () => void;
  userHasWriteSurveysPermission: boolean;
  onInclusionToggleClick?: () => void;
  vendorWatched: boolean;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
}

const SharedProfileSurveyDetailOverview = (
  props: ISharedProfileSurveyDetailOverview
) => {
  const {
    survey,
    assessmentId,
    getSurveyPath,
    hasUnansweredQuestions,
    onUnansweredQuestionsClick,
    userHasWriteSurveysPermission,
    onInclusionToggleClick,
    vendorWatched,
    isManagementAnalystSession,
    managedOrgId,
  } = props;
  const progressText =
    survey.numQuestions && survey.numQuestions > 0
      ? `${survey.numAnswers} of ${
          survey.numQuestions
        } Questions answered (${Math.floor(
          (survey.numAnswers / survey.numQuestions) * 100
        )}%)`
      : "";

  const hasRisks = !!survey.risks;

  return (
    <ReportCard newStyles>
      <div className="header">
        <div className="header-with-subtext">
          <div>Details</div>
        </div>
        <div className="header-right">
          {vendorWatched &&
            userHasWriteSurveysPermission &&
            survey.canIncludeInVendorScore &&
            hasRisks &&
            onInclusionToggleClick && (
              <div className="include-survey-toggle">
                <div className={"toggle-text"}>Include in Risk Profile</div>
                <ToggleSwitch
                  name="include-survey-toggle"
                  selected={!survey.excludeFromVendorScore}
                  onClick={onInclusionToggleClick}
                />
              </div>
            )}
          <Button
            onClick={() =>
              exportPublicSurveyInNewTab(
                survey.id,
                isManagementAnalystSession,
                managedOrgId,
                assessmentId
              )
            }
            className="download-btn"
          >
            Download
          </Button>
          <Link to={getSurveyPath()}>
            <Button primary className="view-answers-btn">
              View answers <div className="cr-icon-arrow-right" />
            </Button>
          </Link>
        </div>
      </div>
      <InfoTable className="overview-table">
        <InfoTableRow label="Name" value={<SurveyName survey={survey} />} />
        <InfoTableRow
          label="Date published"
          value={
            survey.dateCompleted
              ? moment(survey.dateCompleted).format("ll")
              : ""
          }
        />
        <InfoTableRow
          rowClass={"taller-row"}
          label={"Progress"}
          value={
            <div className={"survey-progress"}>
              <ProgressBar
                progress={
                  survey.numQuestions && survey.numQuestions > 0
                    ? survey.numAnswers / survey.numQuestions
                    : 0
                }
                leftText={progressText}
              />
              {hasUnansweredQuestions && onUnansweredQuestionsClick && (
                <div
                  className={"unanswered-question-link"}
                  onClick={onUnansweredQuestionsClick}
                >
                  View unanswered questions
                </div>
              )}
            </div>
          }
        />
        <InfoTableRow
          label="Score"
          rowClass={"label-middle"}
          valueClass={"survey-score"}
          value={
            <>
              {hasRisks && (
                <SidePopupV2
                  className={"questionnaire-score-container"}
                  position={"top"}
                  text={
                    survey.excludeFromVendorScore && vendorWatched
                      ? "This questionnaire does not count towards the vendor's overall questionnaire score rating."
                      : hasUnansweredQuestions
                        ? "This score has been reduced due to unanswered questions"
                        : ""
                  }
                  width={250}
                >
                  {!survey.excludeFromVendorScore && (
                    <ColorGrade
                      score={Math.floor(survey.adjustedRiskScore * 950)}
                      size={ColorGradeSize.Small}
                    />
                  )}
                  <Score
                    colored={survey.excludeFromVendorScore}
                    score={
                      survey.excludeFromVendorScore
                        ? -1
                        : Math.floor(survey.adjustedRiskScore * 950)
                    }
                    small
                    qualifierText={
                      (survey.excludeFromVendorScore && vendorWatched) ||
                      hasUnansweredQuestions
                        ? "*"
                        : undefined
                    }
                  />
                </SidePopupV2>
              )}
              {!hasRisks && (
                <>
                  This questionnaire has no score as there are no associated
                  risks.
                </>
              )}
            </>
          }
        />
      </InfoTable>
    </ReportCard>
  );
};

interface matchParams {
  id: string;
  vendorId: string;
  assessmentId?: string;
  orgId?: string;
  sharedAssetOrgId?: string;
}

interface ISharedProfileSurveyDetailsOwnProps
  extends DefaultRouteProps<matchParams> {
  dispatch: DefaultThunkDispatch;
}

interface ISharedProfileSurveyDetailsConnectedProps {
  surveyId: number;
  vendorIdParam?: number;
  assessmentId?: number;
  assuranceType: AssuranceType;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
}

type ISharedProfileProps = ISharedProfileSurveyDetailsOwnProps &
  ISharedProfileSurveyDetailsConnectedProps;

export const sharedProfileSurveyUrl = ({
  surveyId,
  vendorId,
  currentLocation = undefined,
}: {
  surveyId: number;
  vendorId: number;
  currentLocation?: string;
}): string => {
  if (currentLocation && currentLocation.startsWith("/vendor")) {
    return `${currentLocation}/surveys/${surveyId}`;
  }
  return `${sharedAssessmentsUrlPrefix(vendorId)}/surveys/${surveyId}`;
};

export const openSharedProfileSurveyURL = ({
  surveyId,
  vendorId,
  history,
}: {
  surveyId: number;
  vendorId: number;
  history: History<any>;
}): void => {
  const surveyUrl = sharedProfileSurveyUrl({
    surveyId,
    vendorId,
    currentLocation: history.location.pathname,
  });

  history.push(surveyUrl, {
    backContext: {
      backTo: `${history.location.pathname}`,
      backToText: "Back to Trust Page",
    },
  });
};

const usePrefilledSurveyDetails = (surveyId: number, assessmentId?: number) => {
  const prefilledSurveyDetailsByAssessment =
    TrustPageAPI.useGetPrefilledSurveyDetailsByAssessmentV1Query(
      { surveyId, assessmentId: assessmentId! },
      { skip: !assessmentId }
    );

  const prefilledSurveyDetails =
    TrustPageAPI.useGetPrefilledSurveyDetailsV1Query(
      { surveyId, publishedOnly: true },
      { skip: !!assessmentId }
    );

  return assessmentId
    ? prefilledSurveyDetailsByAssessment
    : prefilledSurveyDetails;
};

const SharedProfileSurveyDetails = (props: ISharedProfileProps) => {
  const {
    dispatch,
    location,
    history,
    surveyId,
    vendorIdParam,
    assessmentId,
    assuranceType,
    isManagementAnalystSession,
    managedOrgId,
  } = props;

  const { data: surveyData } = usePrefilledSurveyDetails(
    surveyId,
    assessmentId
  );

  const vendorId = surveyData?.survey?.vendorId ?? vendorIdParam;
  const permissions = usePermissions({ vendorId: vendorId || 0 });
  const userHasWriteSurveysPermission =
    permissions.userHasWriteSurveysPermission && !assessmentId;

  const vendorsState = useAppSelector((state) => state.cyberRisk.vendors);
  const vendorWatched = vendorId
    ? vendorsState[vendorId]?.watching?.result?.watching ?? false
    : false;

  const survey = surveyData?.survey;

  const [showExcludeModal, setShowExcludeModal] = useState(false);

  const unansweredQuestionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (vendorId) dispatch(fetchVendorWatchStatus(vendorId));
  }, [dispatch, vendorId]);

  const vendorSharedAssessmentUrl = sharedAssessmentsUrlPrefix(vendorId);
  const vendorSharedProfilePageTile = survey?.vendorName
    ? `Trust Page for ${survey?.vendorName}`
    : `Trust Page`;

  const { backToContext } = location.state?.backContext || {};
  let { backTo, backToText } = location.state?.backContext || {};

  if (!backTo) {
    backTo = vendorSharedAssessmentUrl;
    backToText = `Back to ${vendorSharedProfilePageTile}`;
  }

  const { data: assessment } =
    VendorAssessmentAPI.useGetVendorAssessmentDataQuery(
      assessmentId && vendorId
        ? {
            vendorID: vendorId,
            versionID: assessmentId,
          }
        : skipToken
    );

  if (!survey || assessment?.assessment.publishedAt) {
    return (
      <div id="survey_details">
        <PageHeader
          history={history}
          title="Questionnaire details"
          backText={backToText}
          backAction={() => history.push(backTo as string, backToContext)}
        />
        <LoadingBanner />
      </div>
    );
  }

  const risksCount = getRisksList(survey.risks || [], [], false).length;
  const supportingDocumentation = (survey.attachmentGroups || []).reduce(
    (prev: IAttachment[], next) => {
      prev.push(...next.attachments);
      return prev;
    },
    []
  );

  const hasUnansweredQuestions =
    survey.unansweredQuestions && survey.unansweredQuestions.length > 0;

  const onUnansweredQuestionsClick = () =>
    unansweredQuestionsRef.current?.scrollIntoView();

  const isVendorPage = history.location.pathname.startsWith("/vendor");

  const getSurveyPath = (surveyId?: number, questionId?: string) => {
    // if we're in an assessment context, make sure we always load the answers from the snapshot data
    let answerId: number | undefined;
    if (assessmentId && survey.versions) {
      answerId = survey.versions[0].answerId;
    }
    return getSurveyEditorPath({
      surveyId: surveyId || survey.id,
      answerId: answerId,
      editMode: false,
      isManagementAnalystSession: isManagementAnalystSession,
      managedOrgId: managedOrgId,
      questionId,
      publicSurvey: true,
      location: props.history.location,
    });
  };

  let breadcrumbs = [];
  if (assessmentId) {
    breadcrumbs = [
      ...getVendorPageBreadcrumbs(
        vendorId ?? 0,
        survey?.vendorName ?? "",
        assuranceType
      ),
      {
        text: "Risk Assessment",
        to: `/vendor/${vendorId}/assessment`,
      },
      { text: "Questionnaire Details" },
    ];
  } else if (
    history.location.pathname.includes("sharedassets") &&
    survey?.vendorName
  ) {
    breadcrumbs = [
      ...getVendorPageBreadcrumbs(
        vendorId ?? 0,
        survey?.vendorName ?? "",
        assuranceType
      ),
      {
        text: "Shared Assets",
        to: `/vendor/${vendorId}/sharedassets`,
      },
      {
        text: "Trust Page",
        to: `/vendor/${vendorId}/sharedassets/vendor`,
      },
      { text: "Questionnaire Details" },
    ];
  } else if (
    (history.location.pathname.includes("sharedassessment") ||
      history.location.pathname.includes("trustpage")) &&
    isVendorPage &&
    survey?.vendorName
  ) {
    breadcrumbs = [
      ...getVendorPageBreadcrumbs(
        vendorId ?? 0,
        survey?.vendorName ?? "",
        assuranceType
      ),
      {
        text: "Trust Page",
        to: `/vendor/${vendorId}/sharedassessment`,
      },
      { text: "Questionnaire Details" },
    ];
  } else {
    breadcrumbs = [
      { text: "Shared With Me", to: "/sharedassessments" },
      {
        text: vendorSharedProfilePageTile,
        to: sharedAssessmentsUrlPrefix(vendorId),
      },
      { text: "Questionnaire Details" },
    ];
  }

  return (
    <div
      id="shared_profile_survey_details"
      className={"shared-profile-survey-details"}
    >
      {isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={history}
        breadcrumbs={breadcrumbs}
        title={survey.name || "Questionnaire details"}
        vendorId={isVendorPage ? vendorId : undefined}
        backAction={
          location.state?.backContext?.goBack
            ? history.goBack
            : () =>
                history.push(
                  backTo as string,
                  location.state?.backContext?.backToContext
                )
        }
        backText={backToText}
      />
      {assessmentId && assessment?.assessment.publishedAt && (
        <InfoBanner
          className={"old-version-banner"}
          type={BannerType.WARNING}
          overrideIcon={BannerType.INFO}
          message={`This is a snapshot of the questionnaire on ${formatDateAsLocal(
            assessment.assessment.publishedAt
          )}`}
          button={
            <Link to={removeByAssessmentPathSuffix(props.location.pathname)}>
              View latest version{" "}
              <span className={"cr-icon-arrow-right"}></span>
            </Link>
          }
        />
      )}
      <SharedProfileSurveyDetailOverview
        survey={survey}
        assessmentId={assessmentId}
        getSurveyPath={getSurveyPath}
        hasUnansweredQuestions={hasUnansweredQuestions || false}
        onUnansweredQuestionsClick={onUnansweredQuestionsClick}
        userHasWriteSurveysPermission={userHasWriteSurveysPermission}
        onInclusionToggleClick={() => setShowExcludeModal(true)}
        vendorWatched={vendorWatched}
        isManagementAnalystSession={isManagementAnalystSession}
        managedOrgId={managedOrgId}
      />
      {supportingDocumentation.length > 0 && (
        <ReportCard newStyles>
          <div className="header">
            <div className="header-with-subtext">
              <div>{survey.vendorName}&apos;s supporting documentation</div>
            </div>
          </div>
          <SharedProfileSurveyDocumentationTable
            documents={supportingDocumentation}
            surveyId={surveyId}
          />
        </ReportCard>
      )}
      {risksCount > 0 && (
        <SurveyDetailsRisksCard
          history={history}
          location={location}
          surveyId={survey.id}
          vendorId={survey.vendorId}
          surveyRisks={survey.risks || []}
          riskWaivers={survey.riskWaivers || []}
          userHasWaivePermission={userHasWriteSurveysPermission}
          userHasRemediationPermission={userHasWriteSurveysPermission}
          isManagementAnalystSession={isManagementAnalystSession}
          managedOrgId={managedOrgId || 0}
          title={"Risks from questionnaire"}
          getSurveyPath={(
            _editMode: any,
            _surveyId1: any,
            questionId: string | undefined
          ) => getSurveyPath(surveyId, questionId)}
          publicSurvey={true}
          publicSurveyNotIncludedInProfile={survey.excludeFromVendorScore}
          isVendorPortal={false}
          surveyStatus={survey.status}
        />
      )}
      {hasUnansweredQuestions && (
        <SurveyDetailsUnansweredQuestionsCard
          ref={unansweredQuestionsRef}
          history={history}
          surveyId={surveyId}
          unansweredQuestions={survey.unansweredQuestions || []}
          fromCompanyName={survey.fromCompany}
          numQuestions={survey.numQuestions}
          numAnswers={survey.numAnswers}
          isVendorPortal={false}
          getSurveyPath={(_editMode, surveyId, questionId) =>
            getSurveyPath(surveyId, questionId)
          }
          survey={survey}
          isCurrentUserRecipient={false}
        />
      )}
      <ExcludeSharedProfileFromRiskProfileModal
        exclude={survey.excludeFromVendorScore}
        dispatch={dispatch}
        vendorId={vendorId ?? 0}
        surveyId={surveyId}
        active={showExcludeModal}
        onClose={() => setShowExcludeModal(false)}
      />
    </div>
  );
};

export default appConnect<
  ISharedProfileSurveyDetailsConnectedProps,
  never,
  ISharedProfileSurveyDetailsOwnProps
>((state, props) => {
  const surveyId = parseInt(props.match.params.id);
  let assessmentId: number | undefined;
  if (props.match.params.assessmentId) {
    assessmentId = parseInt(props.match.params.assessmentId);
  }
  let vendorIdParam;
  if (props.match.params.vendorId) {
    vendorIdParam = parseInt(props.match.params.vendorId);
  }

  const isAnalystManagedVendor = props.match.path.startsWith("/analysts/tpvm");
  let managedOrgId;
  if (isAnalystManagedVendor) {
    managedOrgId = parseInt(props.match.params.orgId || "");
  }

  return {
    surveyId,
    vendorIdParam,
    assessmentId,
    assuranceType: state.common.userData.assuranceType ?? AssuranceType.None,
    isManagementAnalystSession: isAnalystManagedVendor,
    managedOrgId,
  };
})(SharedProfileSurveyDetails);

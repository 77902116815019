import { NodeType } from "../../../survey_builder/types/types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";

import * as React from "react";
import {
  deleteRiskNodeFromTree,
  setAddRiskModalState,
  setAnswer,
  setCurrentCommentQuestion,
  setRightPanelState,
} from "../../reducers/actions";
import { isEqual as _isEqual } from "lodash";
import SurveyViewerItemIcon from "../SurveyViewerItemIcon";
import { NodeSummaryAndNode, SelectAnswers } from "../../surveyViewer.helpers";
import { BaseNodeContentProps } from "./baseNode";
import SectionNodeContent from "./SectionNodeContent";
import InputTextNodeContent from "./InputNodeContent";
import UploadNodeContent from "./UploadNodeContent";
import RiskNodeContent from "./RiskNodeContent";
import SelectNodeContent from "./SelectNodeContent";
import classnames from "classnames";
import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../../_common/types/correspondenceMessage";
import "../../style/QuestionAnswerNode.scss";
import MessageIcon, { countSurveyMessages } from "../MessageIcon";
import InfoNodeContent from "./InfoNodeContent";
import {
  surveyViewerRightPanelMode,
  SurveyViewFilterType,
} from "../../reducers/reducer";
import SurveyViewerIconButton from "../SurveyViewerIconButton";
import NodeHeading from "./NodeHeading";
import MaxHeightExpandableSection from "../MaxHeightExpandableSection";
import { shouldDisplayChatGPT } from "../../../_common/chatgpt";
import { IActionButtonProps } from "../../../_common/components/TextFieldWithActions";
import { enhanceSurveyAnswer } from "../../reducers/apiActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { ResponseError } from "../../../_common/api";
import { trackEvent } from "../../../_common/tracking";
import {
  getAnswerFromGptSuggestion,
  gptAutofillAnswerForQuestion,
  GptAutofillSuggestion,
  markGptSuggestionAsUsed,
} from "../../reducers/autofill.actions";
import GptSuggestionBox from "../GptSuggestionBox";
import { SurveyRiskVisibility } from "../../../_common/types/survey";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SurveyUsageType } from "../../../_common/types/surveyTypes";
import { NodeTypeIconType } from "../../../survey_builder/components/NodeTypeIcon";
import ManualRisksAPI, {
  ManualRisk,
} from "../../../vendorrisk/reducers/manualRisksAPI";
import { useModalV2 } from "../../../_common/components/ModalV2";
import confirmationModalV2 from "../../../_common/components/modals/ConfirmationModalV2";
import DropdownV2, {
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import IconButton, {
  HoverLocation,
} from "../../../_common/components/IconButton";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import { useVendorWords } from "../../../_common/hooks";

interface QuestionAnswerNodeWrapperOwnProps {
  surveyId?: number;
  usageType?: SurveyUsageType;
  nodeSummary: NodeSummaryAndNode;
  disabled: boolean;
  isPublicSurvey: boolean;
  showComments: boolean;
  isEditMode: boolean;
  showDiff: boolean; // show this node in diff mode
  changedOnly?: boolean; // won't be rendered if no change
  riskVisibility: SurveyRiskVisibility;
  alwaysVisibleRiskIDs?: string[];
  canAddManualRisk?: boolean;
  availableManualRisks?: ManualRisk[];
  onRiskRemoved: (id: number) => void;
}

interface QuestionAnswerNodeWrapperConnectedProps {
  answer?: undefined | string | SelectAnswers;
  isVisible?: boolean; // if in diff mode this is if the right side is visible
  leftIsVisible?: boolean; // left side node visibility in diff mode
  isLocked: boolean;
  isTargetNode: boolean;
  questionComments?: ICorrespondenceMessage<SurveyQuestionMessageMeta>[];
  rightPanelIsActiveForQuestion?: surveyViewerRightPanelMode; // if defined, gives the mode of the right panel
  gptSuggestion?: GptAutofillSuggestion;
  leftAnswer?: undefined | string | SelectAnswers; // the old answer that we are comparing to
  isChanged: boolean; // in diff mode indicates if this answer is changed
  shouldDisplayChatGptFeatures: boolean;
  isInCurrentFilter?: boolean;
}

type QuestionAnswerNodeWrapperProps = QuestionAnswerNodeWrapperOwnProps &
  QuestionAnswerNodeWrapperConnectedProps &
  DefaultThunkDispatchProp;

// Redux-connected wrapper for question nodes
// Once mounted, should only receive new props via redux
const QuestionAnswerNodeWrapper = (props: QuestionAnswerNodeWrapperProps) => {
  const [isEnhancingAnswer, setIsEnhancingAnswer] = React.useState(false);

  // the user entered answered that was used to generate the enhanced answer
  // we want to keep track of this in case the user wants to undo the enhanced answer
  const [enhancedAnswerPrompt, setEnhancedAnswerPrompt] = React.useState<
    string | undefined
  >(undefined);

  const hasValidSuggestion =
    !!props.gptSuggestion &&
    !props.gptSuggestion.noSuggestion &&
    !props.gptSuggestion.used &&
    !props.gptSuggestion.rejected;

  const nodeClasses = `question-answer-node ${getClassForNodeType(
    props.nodeSummary.node.type
  )}`;

  const ContentDisplayTypeComponent = getContentComponentTypeForNodeType(
    props.nodeSummary.node.type
  );

  const shouldShowCommentButton =
    props.showComments &&
    (props.nodeSummary.node.type !== NodeType.Info ||
      props.questionComments?.length);

  // If risks are hidden then only allow private commentary if the risk is not under remediation
  // - If the risk shouldn't be shown to the current user then it will have been removed from the structure on the backend
  const nodeHiddenFromRecipient =
    props.nodeSummary.node.type === NodeType.Risk &&
    props.riskVisibility === SurveyRiskVisibility.Hidden &&
    props.alwaysVisibleRiskIDs?.find(
      (rId) => rId === props.nodeSummary.nodeId
    ) === undefined;

  const commentsButton = shouldShowCommentButton ? (
    <MessageIcon
      highlight={
        props.rightPanelIsActiveForQuestion ===
        surveyViewerRightPanelMode.Comments
      }
      messageCount={countSurveyMessages(props.questionComments)}
      onClick={() => {
        props.dispatch(
          setCurrentCommentQuestion(
            props.nodeSummary.nodeId,
            true,
            nodeHiddenFromRecipient
          )
        );
      }}
    />
  ) : undefined;

  const canAddManualRisk =
    props.canAddManualRisk &&
    (props.nodeSummary.nodeType == NodeTypeIconType.InputText ||
      props.nodeSummary.nodeType == NodeTypeIconType.SelectCheckbox ||
      props.nodeSummary.nodeType == NodeTypeIconType.SelectRadio ||
      props.nodeSummary.nodeType == NodeTypeIconType.Upload) &&
    props.usageType == SurveyUsageType.Security &&
    !props.nodeSummary.node.hasCustomRisk;

  const dropdownItems: React.ReactElement<typeof DropdownItem>[] = [];

  if (canAddManualRisk) {
    dropdownItems.push(
      <DropdownItem
        onClick={() =>
          props.dispatch(setAddRiskModalState(true, props.nodeSummary.nodeId))
        }
      >
        <i className={"cr-icon-add-risk"} /> Add a manual risk
      </DropdownItem>
    );
  }

  const manualRiskEditable = props.availableManualRisks?.find(
    (r) => r.id == props.nodeSummary.node.customRiskID
  )?.editable;

  if (props.canAddManualRisk && props.nodeSummary.node.customRiskID) {
    dropdownItems.push(
      <SidePopupV2
        text={
          !manualRiskEditable
            ? "Risks under remediation can not be edited"
            : undefined
        }
        position={"left"}
      >
        <DropdownItem
          onClick={() =>
            props.dispatch(
              setAddRiskModalState(
                true,
                props.nodeSummary.node.customRiskParentId,
                props.nodeSummary.node.customRiskID,
                props.nodeSummary.node.nodeId
              )
            )
          }
          disabled={!manualRiskEditable}
        >
          <i className={"cr-icon-pencil"} /> Edit risk
        </DropdownItem>
      </SidePopupV2>
    );
  }

  const [deleteManualRisk] =
    ManualRisksAPI.useDeleteManualRiskFromSurveyMutation();
  const [openConfirmationModal, confirmModal] = useModalV2(confirmationModalV2);
  const onDeleteManualRisk = () =>
    openConfirmationModal({
      title: "Delete risk?",
      dangerousAction: true,
      buttonText: "Delete",
      description: "Are you sure you'd like to remove this risk?",
      buttonAction: () =>
        deleteManualRisk({
          surveyID: props.surveyId ?? 0,
          riskID: props.nodeSummary.nodeId,
        })
          .unwrap()
          .then(() => {
            props.dispatch(
              deleteRiskNodeFromTree(
                props.nodeSummary.nodeId,
                props.nodeSummary.node.customRiskParentId ?? ""
              )
            );
            props.onRiskRemoved(props.nodeSummary.node.customRiskID ?? 0);
          })
          .then(() =>
            props.dispatch(addDefaultSuccessAlert("Successfully removed risk"))
          )
          .catch(() =>
            props.dispatch(addDefaultUnknownErrorAlert("Error removing risk"))
          ),
    });

  if (props.canAddManualRisk && props.nodeSummary.node.customRiskID) {
    dropdownItems.push(
      <DropdownItem onClick={onDeleteManualRisk}>
        <i className={"cr-icon-trash"} /> Delete risk
      </DropdownItem>
    );
  }

  const gptSuggestionBox =
    props.shouldDisplayChatGptFeatures &&
    props.usageType == SurveyUsageType.Security &&
    hasValidSuggestion ? (
      <GptSuggestionBox
        onAcceptReject={(accept) => {
          if (!!props.gptSuggestion) {
            if (accept) {
              props.dispatch(
                gptAutofillAnswerForQuestion(
                  props.gptSuggestion.questionID,
                  props.gptSuggestion.result
                )
              );
            }
            return props.dispatch(
              markGptSuggestionAsUsed(props.gptSuggestion.id, accept)
            );
          }
          return Promise.resolve();
        }}
        onClickDetails={() => {
          trackEvent("AiSourcesOpened");
          props.dispatch(
            setRightPanelState(
              surveyViewerRightPanelMode.GptAutofillSuggestions,
              props.nodeSummary.nodeId,
              true
            )
          );

          trackEvent("QRD_GPTSidePanelOpen", {
            SurveyID: props.surveyId ?? 0,
            QuestionID: props.nodeSummary.nodeId,
          });
        }}
      />
    ) : undefined;

  const gptUsedSuggestionButton =
    props.shouldDisplayChatGptFeatures &&
    props.usageType == SurveyUsageType.Security &&
    props.gptSuggestion &&
    !props.gptSuggestion.noSuggestion &&
    (props.gptSuggestion.used || props.gptSuggestion.rejected) ? (
      <SurveyViewerIconButton
        popupWidth={240}
        tooltipContent={
          "See the autofill suggestions we have for this response"
        }
        onClick={() => {
          props.dispatch(
            setRightPanelState(
              surveyViewerRightPanelMode.GptAutofillSuggestions,
              props.nodeSummary.nodeId,
              true
            )
          );
        }}
      >
        <i className="cr-icon-book-checkmark" />
      </SurveyViewerIconButton>
    ) : undefined;

  const showEnhanceAction =
    props.shouldDisplayChatGptFeatures &&
    props.usageType == SurveyUsageType.Security &&
    (props.nodeSummary.node.type == NodeType.InputText ||
      (props.nodeSummary.node.type == NodeType.Risk &&
        props.nodeSummary.node.askForCompensatingControls)) &&
    !!props.surveyId &&
    props.surveyId > 0 &&
    props.isEditMode;

  const undoEnhancedAnswerClick = () => {
    props.dispatch(setAnswer(props.nodeSummary.nodeId, enhancedAnswerPrompt));
    setEnhancedAnswerPrompt(undefined);
    trackEvent("EnhanceSurveyAnswer_Undo", {
      SurveyID: props.surveyId ?? 0,
      QuestionID: props.nodeSummary.nodeId,
    });
  };

  const enhanceAnswer = () => {
    if (!props.answer) {
      return;
    }

    setIsEnhancingAnswer(true);
    props
      .dispatch(
        enhanceSurveyAnswer(
          props.surveyId ?? 0,
          props.isPublicSurvey,
          props.nodeSummary.nodeId,
          props.answer as string
        )
      )
      .then((resp) => {
        setEnhancedAnswerPrompt(props.answer as string);
        props.dispatch(setAnswer(props.nodeSummary.nodeId, resp.answer));
      })
      .catch((e: ResponseError) => {
        if (e.message.includes("[QUOTA EXCEEDED]")) {
          props.dispatch(
            addDefaultUnknownErrorAlert("AI Enhance is currently unavailable")
          );
        } else {
          props.dispatch(
            addDefaultUnknownErrorAlert("Failed to rephrase and enhance answer")
          );
        }
      })
      .finally(() => setIsEnhancingAnswer(false));
  };

  const enhanceActionButtons: IActionButtonProps[] = [];
  if (showEnhanceAction) {
    let enhanceButtonPopupMsg: string | undefined = undefined;
    if (!!enhancedAnswerPrompt) {
      enhanceButtonPopupMsg = "Your answer has been rephrased";
    } else if (props.answer) {
      enhanceButtonPopupMsg =
        "Click to rephrase and enhance your answer with ChatGPT";
    } else {
      enhanceButtonPopupMsg =
        "Add a first draft to enhance and rephrase with ChatGPT";
    }

    enhanceActionButtons.push({
      actionIcon: "cr-icon-magic-wand",
      actionText: "AI Enhance",
      isExecutingAction: isEnhancingAnswer,
      popupMessage: enhanceButtonPopupMsg,
      popupWidth: 250,
      autoDismissAfter: !!enhancedAnswerPrompt ? 1000 : 0,
      onClick: enhanceAnswer,
      disabled: !props.answer || !!enhancedAnswerPrompt,
    });

    enhanceActionButtons.push({
      actionIcon: "cr-icon-undo",
      actionText: "Undo",
      disabled: !enhancedAnswerPrompt || isEnhancingAnswer,
      onClick: !!enhancedAnswerPrompt ? undoEnhancedAnswerClick : undefined,
    });
  }

  const icon = <SurveyViewerItemIcon nodeSummary={props.nodeSummary} />;
  const heading = (
    <NodeHeading node={props.nodeSummary.node} isDiffMode={props.showDiff} />
  );

  const suggestedAnswer =
    props.gptSuggestion && props.gptSuggestion.result
      ? getAnswerFromGptSuggestion(
          props.nodeSummary.nodeId,
          props.gptSuggestion.result,
          props.nodeSummary
        )
      : undefined;
  const answer = props.isVisible ? (
    <ContentDisplayTypeComponent
      surveyId={props.surveyId}
      surveyUsageType={props.usageType}
      node={props.nodeSummary.node}
      answer={props.answer}
      suggestedAnswer={
        props.gptSuggestion && hasValidSuggestion
          ? getAnswerFromGptSuggestion(
              props.nodeSummary.nodeId,
              props.gptSuggestion.result,
              props.nodeSummary
            )
          : undefined
      }
      isPublicSurvey={props.isPublicSurvey}
      onAnswerChanged={(nodeId, newAnswers) => {
        if (
          (hasValidSuggestion && !_isEqual(suggestedAnswer, newAnswers)) ||
          (props.isEditMode &&
            !_isEqual(props.answer, newAnswers) &&
            !(hasValidSuggestion && _isEqual(suggestedAnswer, newAnswers)))
        ) {
          props.dispatch(setAnswer(nodeId, newAnswers));

          // if there's a suggestion, when the answer is edited we count this as the answer being accepted if it's a text answer
          // and count it as being rejected if not
          // we don't need to update the answer if it's an accepted text box as this will be handled by the editing process
          if (hasValidSuggestion && props.gptSuggestion) {
            trackEvent("AiAutofillSuggestionUsed", { action: "edit" });
            props.dispatch(
              markGptSuggestionAsUsed(props.gptSuggestion.id, true)
            );
          }

          // fire a tracking event if the user edited an answers that came from ChatGPT
          // as we want the signal that the answer wasn't good enough
          if (enhancedAnswerPrompt) {
            trackEvent("EnhanceSurveyAnswer_Edited", {
              SurveyID: props.surveyId ?? 0,
              QuestionID: props.nodeSummary.nodeId,
            });
          }

          // reset the enhancedAnswerPrompt if the user edited the answer manually
          // we treat any edit made by the user as a new answer so that the enhance button
          // reverts from the undo state to the enhance state again
          setEnhancedAnswerPrompt(undefined);
        }
      }}
      disabled={props.disabled || props.isLocked}
      diffSide={props.showDiff ? "right" : undefined}
      otherAnswer={props.leftAnswer}
      actionButtons={enhanceActionButtons}
      gptSuggestionBox={gptSuggestionBox}
      nodeHiddenFromRecipient={nodeHiddenFromRecipient}
    />
  ) : (
    <div className={"diff-hidden"}>
      <span>Does not exist in this version</span>
    </div>
  );

  const leftAnswer = props.showDiff ? (
    props.leftIsVisible ? (
      <ContentDisplayTypeComponent
        surveyId={props.surveyId}
        surveyUsageType={props.usageType}
        node={props.nodeSummary.node}
        answer={props.leftAnswer}
        isPublicSurvey={props.isPublicSurvey}
        onAnswerChanged={() => {
          //no op
        }}
        disabled
        diffSide={"left"}
        otherAnswer={props.answer}
        nodeHiddenFromRecipient={nodeHiddenFromRecipient}
      />
    ) : (
      <div className={"diff-hidden"}>
        <span>Did not exist in previous version</span>
      </div>
    )
  ) : undefined;

  const hideAnswerSection =
    (props.nodeSummary.node.type == NodeType.Section ||
      props.nodeSummary.node.type == NodeType.Info) &&
    props.showDiff;

  const vendorWords = useVendorWords();

  const manualRiskWarning =
    !!props.nodeSummary.node.customRiskID &&
    manualRiskEditable &&
    props.canAddManualRisk ? (
      <InfoBanner
        className={"manual-risk-banner"}
        message={`${vendorWords.pluralTitleCase} are not able to see manual risks, unless remediation is requested by your organisation.`}
        type={BannerType.WARNING}
      />
    ) : undefined;

  const classes = classnames(nodeClasses, {
    "target-node": props.isTargetNode,
    "is-right-panel-target-question":
      props.rightPanelIsActiveForQuestion !== undefined,
    diff: props.showDiff,
    "manual-risk-warning": !!manualRiskWarning,
  });

  // props.changedOnly is always false outside of diff mode so the second part of this check is always true if not in diff mode
  return (
    <TransitionGroup component={null}>
      {(props.isVisible || (props.showDiff && props.leftIsVisible)) && (
        <CSSTransition
          key={props.nodeSummary.nodeId}
          timeout={250}
          classNames="expand"
        >
          <div className={classes} data-node-id={props.nodeSummary.nodeId}>
            {manualRiskWarning}
            {(!props.changedOnly || props.isChanged) && (
              <div
                className={classnames("question-container", {
                  diff: props.showDiff,
                  filtered: props.isInCurrentFilter && !props.showDiff,
                })}
              >
                {props.showDiff ? (
                  <>
                    <div
                      className={classnames("heading-div", {
                        ["answers-hidden"]: hideAnswerSection,
                      })}
                    >
                      <div className={"left"}>
                        <MaxHeightExpandableSection maxHeight={200}>
                          {icon}
                          {heading}
                        </MaxHeightExpandableSection>
                      </div>
                      <div className={"right"}>{commentsButton}</div>
                    </div>
                    {!hideAnswerSection && (
                      <>
                        <div className={"old-answer"}>{leftAnswer}</div>
                        <div className={"new-answer"}>{answer}</div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {icon}
                    <div className={"heading-and-content"}>
                      {heading}
                      {answer}
                    </div>
                    {gptUsedSuggestionButton}
                    {dropdownItems.length > 0 && (
                      <div className={"survey-viewer-icon-button-container"}>
                        <DropdownV2
                          className={"dropdown-icon"}
                          popupItem={
                            <IconButton
                              icon={
                                <div className="cr-icon-typosquatting-1 rot90" />
                              }
                              hoverText={"More actions"}
                              popupWidth={110}
                              hoverLocation={HoverLocation.Top}
                            />
                          }
                        >
                          {dropdownItems}
                        </DropdownV2>
                      </div>
                    )}
                    {commentsButton}
                  </>
                )}
              </div>
            )}
          </div>
        </CSSTransition>
      )}
      {confirmModal}
    </TransitionGroup>
  );
};

const QuestionAnswerNodeWrapperConnected = appConnect<
  QuestionAnswerNodeWrapperConnectedProps,
  never,
  QuestionAnswerNodeWrapperOwnProps
>((state, props) => {
  const activeFilter =
    state.surveyViewer.filteredNodeData.activeFilter ??
    SurveyViewFilterType.None;
  const activeFilterIndex =
    state.surveyViewer.filteredNodeData.setIndexes[activeFilter] ?? {};
  const nodeIsInFilter =
    activeFilterIndex[props.nodeSummary.nodeId] > 0 ?? false;

  return {
    answer: state.surveyViewer.answers[props.nodeSummary.nodeId],
    leftAnswer: state.surveyViewer.leftAnswers[props.nodeSummary.nodeId],
    isVisible: state.surveyViewer.nodeVisibilities[props.nodeSummary.nodeId],
    leftIsVisible:
      state.surveyViewer.leftNodeVisibilities[props.nodeSummary.nodeId],
    isChanged:
      state.surveyViewer.answersChanged.answers[props.nodeSummary.nodeId] ??
      false,
    isLocked: state.surveyViewer.lock.isLocked,
    isTargetNode:
      state.surveyViewer.scrollTargetNodeId === props.nodeSummary.nodeId,
    questionComments:
      state.surveyViewer.questionComments[props.nodeSummary.nodeId],
    rightPanelIsActiveForQuestion:
      state.surveyViewer.rightPanel.active &&
      state.surveyViewer.rightPanel.questionId === props.nodeSummary.nodeId
        ? state.surveyViewer.rightPanel.mode
        : undefined,
    shouldDisplayChatGptFeatures: shouldDisplayChatGPT(state.common.userData),
    gptSuggestion:
      state.surveyViewer.gptAutofill?.suggestions[props.nodeSummary.nodeId],
    isInCurrentFilter: nodeIsInFilter,
  };
})(QuestionAnswerNodeWrapper);

export default QuestionAnswerNodeWrapperConnected;

export const getContentComponentTypeForNodeType = (
  type: NodeType
): React.ComponentType<BaseNodeContentProps> => {
  switch (type) {
    case NodeType.Section:
      return SectionNodeContent;
    case NodeType.InputText:
      return InputTextNodeContent;
    case NodeType.Select:
      return SelectNodeContent;
    case NodeType.Upload:
      return UploadNodeContent;
    case NodeType.Risk:
      return RiskNodeContent;
    case NodeType.Info:
      return InfoNodeContent;
    default:
      throw new Error(`Node type ${type} not supported`);
  }
};

export const getClassForNodeType = (type: NodeType): string => {
  switch (type) {
    case NodeType.Section:
      return "section-node";
    case NodeType.InputText:
      return "input-text-node";
    case NodeType.Select:
      return "select-node";
    case NodeType.Upload:
      return "upload-node";
    case NodeType.Risk:
      return "risk-node";
    case NodeType.Info:
      return "info-node";
    default:
      throw new Error(`Node type ${type} not supported`);
  }
};

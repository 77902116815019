import { FC } from "react";
import "../style/ComplianceBadge.scss";
import { ComplianceBadgeMap, ComplianceBadgeTypes } from "../helpers/types";

interface ComplianceBadgeProps {
  badgeType: ComplianceBadgeTypes;
}

const ComplianceBadge: FC<ComplianceBadgeProps> = ({ badgeType }) => {
  const { title, badgeImg } = ComplianceBadgeMap[badgeType];

  return (
    <div className="badge-card">
      {badgeImg && (
        <div className={"badge-icon"}>
          <img src={badgeImg} alt={`compliance badge ${title} icon`} />
        </div>
      )}
      <span>{title}</span>
    </div>
  );
};

export default ComplianceBadge;

import { ScopeRiskLevel } from "../api/types";
import { FC } from "react";
import classnames from "classnames";

import "./ScopeRiskLevelDisplay.scss";
import { isElevatedRiskLevel } from "../helpers/scopes";

export interface ScopeRiskLevelDisplayProps {
  riskLevel: ScopeRiskLevel | undefined;
}

const ScopeRiskLevelDisplay: FC<ScopeRiskLevelDisplayProps> = ({
  riskLevel,
}) => {
  if (riskLevel === undefined) {
    return <></>;
  }

  const isElevated = isElevatedRiskLevel(riskLevel);

  return (
    <div
      className={classnames("scope-risk-level-display", {
        elevated: isElevated,
        basic: !isElevated,
      })}
    >
      <span className={"dot"}></span>
      {isElevated ? "Elevated" : "Basic"}
    </div>
  );
};

export default ScopeRiskLevelDisplay;

import { SeverityInt } from "../../_common/types/severity";

export enum ControlState {
  Unknown = "unknown",
  Passed = "passed",
  Failed = "failed",
  Partial = "partial",
  NA = "NA",
}

export const ControlStateIcon: Record<ControlState, string> = {
  [ControlState.Unknown]: "cr-icon-question", // TODO - replace with real icon
  [ControlState.Failed]: "cr-icon-cross",
  [ControlState.Partial]: "cr-icon-dash",
  [ControlState.Passed]: "cr-icon-check",
  [ControlState.NA]: "cr-icon-question", // TODO - replace with real icon
};
export const ControlStateText: Record<ControlState, string> = {
  [ControlState.Passed]: "Fully implemented",
  [ControlState.Failed]: "Not implemented (risks found)",
  [ControlState.Partial]: "Partially implemented",
  [ControlState.Unknown]: "No evidence",
  [ControlState.NA]: "Not applicable",
};

export interface RiskControl {
  controlFamily: string;
  id: string;
  text: string;
}

export interface ControlFamily {
  domain: string;
  id: string;
  name: string;
  controls: RiskControl[];
}

export interface RiskDomain {
  id: string;
  name: string;
  controlFamilies: ControlFamily[];
}

export interface ControlTotals {
  passedCount: number;
  failedCount: number;
  partiallyMetCount: number;
  unknownCount: number;
  totalChecks: number;
  coveredChecks: number;
}

export interface ControlCheck {
  id: string;
  text: string;
  riskId?: string[];
  severity: SeverityInt;
}

export interface CheckWithSource extends SecurityProfileCheck {
  documentID: SecurityProfileDocumentID;
  scannedAt: string;
  scanCheckID?: number;
  publicScanCheckID?: number;
}

export interface CheckWithSourceAndSeverity extends CheckWithSource {
  severity: SeverityInt;
}

export interface SecurityProfileCitation {
  text: string;
}

export interface SecurityProfileCheck {
  id: string;
  pass: boolean;
  citations: SecurityProfileCitation[];
}

export interface SharedVendorDocument {
  uuid: string;
  vendorId: number;
  name?: string;
  filename: string;
  createdAt: string;
  updatedAt: string;
}

export interface ControlDetail {
  id: string;
  text: string;
  objective: string;
  lastScannedAt: string;
  checks: ControlCheck[];
}

export type SecurityProfileDocumentSource =
  | "PrivateSurvey"
  | "GapSurvey"
  | "AdditionalEvidence"
  | "UpguardDoc"
  | "TrustPageSurvey"
  | "TrustPageDoc";

export type SecurityProfileDocumentID = string;

export const DocTypeFromID = (id: SecurityProfileDocumentID) =>
  id.split(":")[0] as SecurityProfileDocumentSource;

export const DocUUIDFromSourceID = (id: SecurityProfileDocumentID): string =>
  id.split(":")[1];

export const DocIDFromSourceID = (id: SecurityProfileDocumentID): number =>
  parseInt(id.split(":")[1]);

export const DocTypeStringFromID = (id: SecurityProfileDocumentID) => {
  const docType = DocTypeFromID(id);
  switch (docType) {
    case "PrivateSurvey":
      return "Questionnaire";
    case "GapSurvey":
      return "Gap questionnaire";
    case "AdditionalEvidence":
      return "Additional evidence";
    case "UpguardDoc":
      return "UpGuard sourced document";
    case "TrustPageDoc":
      return "Trust page document";
    case "TrustPageSurvey":
      return "Trust page questionnaire";
    default:
      return "";
  }
};

export interface SecurityProfileDocument {
  id: SecurityProfileDocumentID;
  type: SecurityProfileDocumentSource; // TODO - this should probably be a string
  name: string;
  author: string;
  lastUpdated?: string;
  linkUrl: string;
  mustRequestAccess: boolean;
  fileType?: "pdf" | "xls";
  configState?: "missing" | "existing" | "in_progress";
}

export interface DocumentScanProgress {
  progress: number;
  updatedAt: string;
  nFailed: number;
  checkCounts: Record<SecurityProfileDocumentID, number>;
  totalDocs: number;
}

export const GetControlCounts = (
  tree: RiskDomain[],
  controlStates: Record<string, ControlState>,
  excludedControls: string[]
) => {
  const totals: ControlTotals = {
    passedCount: 0,
    failedCount: 0,
    partiallyMetCount: 0,
    unknownCount: 0,
    totalChecks: 0,
    coveredChecks: 0,
  };

  tree.forEach((domain) => {
    domain.controlFamilies.forEach((cf) => {
      cf.controls.forEach((control) => {
        if (excludedControls.includes(control.id)) {
          return;
        }

        switch (controlStates[control.id]) {
          case ControlState.Failed:
            totals.failedCount = totals.failedCount + 1;
            break;
          case ControlState.Passed:
            totals.passedCount = totals.passedCount + 1;
            break;
          case ControlState.Partial:
            totals.partiallyMetCount = totals.partiallyMetCount + 1;
            break;
          case ControlState.Unknown:
            totals.unknownCount = totals.unknownCount + 1;
        }
      });
    });
  });

  return totals;
};

export interface SecurityProfileRiskComment {
  securityProfileID: number;
  riskID: string;
  comments: string;
  orgID: number;
}

import React, { FC } from "react";
import { NumberWithCommas, pluralise } from "../../../_common/helpers";
import "./DisplayingCount.scss";

interface DisplayingCountProps {
  /** The number of items to display */
  count: number;
  /** The singular form of the item label */
  singular: string;
  /** The plural form of the item label */
  plural: string;
  /** Optional icon to display */
  displayIcon?: React.ReactNode;
  /** Optional text to display before the count (defaults to Displaying) */
  displayText?: string;
}

const DisplayingCount: FC<DisplayingCountProps> = ({
  count,
  singular,
  plural,
  displayIcon,
  displayText = "Displaying",
}) => {
  return (
    <div className="displaying-users">
      {displayIcon}
      {displayText} {NumberWithCommas(count)}{" "}
      {pluralise(count, singular, plural)}
    </div>
  );
};

export default DisplayingCount;

import { FC } from "react";
import CircledIcon from "../../_common/components/CircledIcon";
import IconButton from "../../_common/components/IconButton";
import "../style/DocumentItem.scss";
import { formatDateAsLocal } from "../../_common/helpers";

interface DocumentItemProps {
  name: string;
  dateUpdated?: string;
  readOnly: boolean;
  onRequestAccess: () => void;
}

const DocumentItem: FC<DocumentItemProps> = ({
  name,
  dateUpdated,
  readOnly,
  onRequestAccess,
}) => {
  return (
    <div className="doc-inner">
      <CircledIcon iconClass="cr-icon-document" />

      <div className={"doc-details"}>
        {name}
        {dateUpdated && (
          <div className="doc-last-updated">
            Last updated {formatDateAsLocal(dateUpdated)}
          </div>
        )}
      </div>
      {readOnly && (
        <div className="doc-request-access-btn">
          <IconButton
            icon={<div className="cr-icon-lock" />}
            onClick={onRequestAccess}
            hoverText="Request access"
          />
        </div>
      )}
    </div>
  );
};

export default DocumentItem;

import BaseAPI from "../../_common/rtkQueryApi";
import { UserEmailAddress } from "../../_common/types/user";
import { INamedSurveyType } from "../../_common/types/survey";
import { SurveyFramework } from "../../survey_builder/types/frameworks";
import {
  IOrgFlags,
  IOrganisationWithDistributor,
} from "../../_common/types/organisations";
import { FeatureFlag } from "../../_common/types/features";

export enum SystemAdminTagTypes {
  impersonateUsers = "impersonateUsers",
  systemSurveyType = "systemSurveyType",
  minFeatureTag = "minFeatureTag",
}

export interface ListImpersonateOrgUsersV1Response {
  users: UserEmailAddress[];
}

interface GetMinEntitlementsResponse {
  features: FeatureFlag[];
}

interface SetMinEntitlementRequest {
  entitlement: string;
  enabled: boolean;
}

const SystemAdminAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(SystemAdminTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    listAllOrganisationsV1: builder.query<
      {
        status: string;
        orgs: IOrganisationWithDistributor[];
        orgFlags: Record<number, IOrgFlags>;
      },
      {
        accountTypes?: string[];
        includeInactive?: boolean;
        includeActive?: boolean;
        includeNoPlan?: boolean;
        accountPlans?: string[];
      }
    >({
      query: ({
        accountTypes,
        includeInactive,
        includeActive,
        includeNoPlan,
        accountPlans,
      }) => ({
        url: "/admin/organisations/v1/",
        method: "GET",
        params: {
          account_types: accountTypes,
          include_inactive: includeInactive,
          include_active: includeActive,
          include_no_plan: includeNoPlan,
          account_plans: accountPlans,
        },
      }),
    }),

    listImpersonateOrgUsersV1: builder.query<
      ListImpersonateOrgUsersV1Response,
      {
        orgId: number;
      }
    >({
      providesTags: [SystemAdminTagTypes.impersonateUsers],
      query: ({ orgId }) => ({
        url: "/accounts/impersonateorg/users/v1/",
        method: "GET",
        params: {
          new_org_id: orgId,
        },
      }),
    }),

    listSystemSurveyTypes: builder.query<INamedSurveyType[], void>({
      providesTags: [SystemAdminTagTypes.systemSurveyType],
      query: () => ({
        url: "/admin/sectionlist/v1/",
        method: "GET",
      }),
    }),
    uploadSystemSurveyStructure: builder.mutation<
      {
        execLog: string;
      },
      {
        layoutFile: File;
        questionsFile: File;
        framework: SurveyFramework;
        surveyTypeID: number;
        surveySectionID: number;
        verifyAllQuestions: boolean;
        writeUpdates: boolean;
        surveyName?: string;
        surveySectionName?: string;
      }
    >({
      query: ({
        layoutFile,
        questionsFile,
        framework,
        surveyTypeID,
        surveySectionID,
        verifyAllQuestions,
        writeUpdates,
        surveyName,
        surveySectionName,
      }) => {
        const body = new FormData();
        body.append("layout_file", layoutFile);
        body.append("questions_file", questionsFile);

        return {
          url: "/admin/surveydata/v1",
          method: "POST",
          params: {
            survey_id: surveyTypeID,
            section_id: surveySectionID,
            framework,
            all_questions_covered: verifyAllQuestions,
            update_section: writeUpdates,
            survey_name: surveyName,
            surveySectionName: surveySectionName,
          },
          body,
        };
      },
    }),
    updateSurveyTypeOrgRestrictions: builder.mutation<
      void,
      {
        surveyTypeId: number;
        orgIds?: number[];
      }
    >({
      query: ({ surveyTypeId, orgIds }) => ({
        url: "/admin/surveytypeorgrestrictions/v1",
        method: "POST",
        params: {
          survey_type_id: surveyTypeId,
          org_ids: orgIds,
        },
      }),
      invalidatesTags: [SystemAdminTagTypes.systemSurveyType],
    }),
    deleteSystemSurveyType: builder.mutation<
      void,
      {
        surveyTypeId: number;
      }
    >({
      query: ({ surveyTypeId }) => ({
        url: "/admin/systemsurveytype/v1",
        method: "DELETE",
        params: {
          survey_type_id: surveyTypeId,
        },
      }),
      invalidatesTags: [SystemAdminTagTypes.systemSurveyType],
    }),
    createSystemSurveyType: builder.mutation<
      INamedSurveyType,
      {
        name: string;
        description: string;
        sectionNames: string[];
        isSystemSurvey: boolean;
        customNumbering: boolean;
      }
    >({
      query: (body) => ({
        url: "/admin/systemsurveytype/v1",
        method: "POST",
        body: JSON.stringify(body),
      }),
      invalidatesTags: [SystemAdminTagTypes.systemSurveyType],
    }),
    updateSystemSurveyType: builder.mutation<
      void,
      {
        surveyTypeId: number;
        name: string;
        description: string;
        sectionNames: string[];
        isSystemSurvey: boolean;
        customNumbering: boolean;
      }
    >({
      query: (body) => ({
        url: "/admin/systemsurveytype/v1",
        method: "PUT",
        body: JSON.stringify(body),
      }),
      invalidatesTags: [SystemAdminTagTypes.systemSurveyType],
    }),

    getMinOrgEntitlements: builder.query<GetMinEntitlementsResponse, void>({
      query: () => ({
        url: "admin/feature/min_entitlements/v1/",
        method: "GET",
      }),
      providesTags: [SystemAdminTagTypes.minFeatureTag],
    }),

    setMinOrgEntitlement: builder.mutation<void, SetMinEntitlementRequest>({
      query: (params) => ({
        url: "admin/feature/min_entitlements/v1/",
        method: "PUT",
        params: { ...params },
      }),
      invalidatesTags: [SystemAdminTagTypes.minFeatureTag],
    }),
  }),
});

export default SystemAdminAPI;

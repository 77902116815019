import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetResultsV1ReqBody } from "./api/threatmonitoring.api";
import {
  ThreatMonitoringFeedType,
  ThreatMonitoringResultsDatePeriodOption,
  ThreatMonitoringResultsFilter,
  ThreatMonitoringModule,
} from "./api/types";
import { Tab } from "./views/ThreatMonitoringView";
import { Tab as DetailViewTab } from "./views/ThreatDetailView";
import { RootState } from "../_common/types/reduxStore";
import { OpenFeedSortOptions } from "./components/ThreatMonitoringFeed";
import {
  OrgAccessThreatMonitoringSocialMedia,
  OrgAccessThreatMonitoringDarkWeb,
  hasOrgPermission,
  OrgAccessThreatMonitoringDataLeaks,
} from "../_common/permissions";
import { usePermissions } from "../_common/permissions";

export type FeedTypes =
  | ThreatMonitoringFeedType.Investigating
  | ThreatMonitoringFeedType.Closed
  | ThreatMonitoringFeedType.Open
  | ThreatMonitoringFeedType.Remediating;

// default page sizes
export const OPENFEED_PAGESIZE = 20;
export const FEEDTABLES_PAGESIZE = 30;

type FeedState = {
  query: GetResultsV1ReqBody;
  filtersExpanded: boolean;
};
type SessionSnapshot =
  | { uuids: [] }
  | {
      uuids: string[];
      query: GetResultsV1ReqBody;
    };

export function isEmptySnapshot(
  snapshot: SessionSnapshot
): snapshot is { uuids: [] } {
  return snapshot.uuids.length === 0;
}

type CountSince = { filtered: number; unfiltered: number };

interface ThreatmonState {
  feeds: Record<FeedTypes, FeedState>;
  sessionSnapshot: SessionSnapshot;
  currentTab: Tab;
  countSince: CountSince;
  currentDetailTab: DetailViewTab;
}

export type Module = ThreatMonitoringModule | "all";

export function isModule(m?: string): m is Module {
  return (
    m === "all" ||
    Object.values(ThreatMonitoringModule).includes(m as ThreatMonitoringModule)
  );
}

// toModule
// Convert a module string to a Module enum value.
// If the module is undefined or "all", return 'all'.
// If the module is a valid ThreatMonitoringModule, return it as a Module enum value.
// Otherwise, return undefined.
export function toModule(m?: string): Module | undefined {
  if (m === undefined || m === "all") {
    return "all";
  }

  if (
    Object.values(ThreatMonitoringModule).includes(m as ThreatMonitoringModule)
  ) {
    return m as ThreatMonitoringModule;
  }

  return undefined;
}

// useModule takes any string and returns a Module enum value.
// It asserts the string into Module, but also checks whether the user has permission to access the module.
export function useModule(maybeModule?: string): Module | undefined {
  const { permissions } = usePermissions();

  const module = toModule(maybeModule);

  if (module === undefined) {
    return undefined;
  }

  // check perms
  const hasDarkWeb = hasOrgPermission(
    permissions,
    OrgAccessThreatMonitoringDarkWeb
  );
  const hasSocialMedia = hasOrgPermission(
    permissions,
    OrgAccessThreatMonitoringSocialMedia
  );
  const hasDataLeaks = hasOrgPermission(
    permissions,
    OrgAccessThreatMonitoringDataLeaks
  );

  if (module === "all" && (hasDarkWeb || hasSocialMedia || hasDataLeaks)) {
    return "all";
  }

  if (module === ThreatMonitoringModule.ModuleDarkWeb && hasDarkWeb) {
    return ThreatMonitoringModule.ModuleDarkWeb;
  }

  if (module === ThreatMonitoringModule.ModuleSocialMedia && hasSocialMedia) {
    return ThreatMonitoringModule.ModuleSocialMedia;
  }

  if (module === ThreatMonitoringModule.ModuleDataLeaks && hasDataLeaks) {
    return ThreatMonitoringModule.ModuleDataLeaks;
  }

  return undefined;
}

// New top-level state structure
interface TopLevelThreatMonitoring {
  modules: Record<Module, ThreatmonState>;
}

export const maxDatePeriod =
  ThreatMonitoringResultsDatePeriodOption.LastTwelveMonths;

export const defaultFilters = (module: Module) => ({
  [ThreatMonitoringFeedType.Open]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
    module,
  },
  [ThreatMonitoringFeedType.Investigating]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.AllTime,
    module,
  },
  [ThreatMonitoringFeedType.Remediating]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.AllTime,
    module,
  },
  [ThreatMonitoringFeedType.Closed]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
    module,
  },
});

const newThreatmonState = (module: Module): ThreatmonState => ({
  currentTab: Tab.Open,
  currentDetailTab: DetailViewTab.Details,
  countSince: { filtered: 0, unfiltered: 0 },
  sessionSnapshot: { uuids: [] },
  feeds: {
    [ThreatMonitoringFeedType.Open]: {
      query: {
        feedType: ThreatMonitoringFeedType.Open,
        filters: defaultFilters(module)[ThreatMonitoringFeedType.Open],
        pageOpts: {
          pageSize: OPENFEED_PAGESIZE,
          pageNum: 0,
          sortBy: OpenFeedSortOptions[0].option,
          sortDesc: OpenFeedSortOptions[0].desc,
        },
      },
      filtersExpanded: true,
    },
    [ThreatMonitoringFeedType.Investigating]: {
      query: {
        feedType: ThreatMonitoringFeedType.Investigating,
        filters: defaultFilters(module)[ThreatMonitoringFeedType.Investigating],
        pageOpts: {
          pageSize: FEEDTABLES_PAGESIZE,
          pageNum: 0,
          sortBy: "updated_at",
          sortDesc: true,
        },
      },
      filtersExpanded: true,
    },
    [ThreatMonitoringFeedType.Closed]: {
      query: {
        feedType: ThreatMonitoringFeedType.Closed,
        filters: defaultFilters(module)[ThreatMonitoringFeedType.Closed],
        pageOpts: {
          pageSize: FEEDTABLES_PAGESIZE,
          pageNum: 0,
          sortBy: "date_closed",
          sortDesc: true,
        },
      },
      filtersExpanded: true,
    },
    [ThreatMonitoringFeedType.Remediating]: {
      query: {
        feedType: ThreatMonitoringFeedType.Remediating,
        filters: defaultFilters(module)[ThreatMonitoringFeedType.Remediating],
        pageOpts: {
          pageSize: FEEDTABLES_PAGESIZE,
          pageNum: 0,
          sortBy: "remediation_req_updated_at",
          sortDesc: true,
        },
      },
      filtersExpanded: true,
    },
  },
});

const initialState: TopLevelThreatMonitoring = {
  modules: {
    all: newThreatmonState("all"),
    [ThreatMonitoringModule.ModuleDarkWeb]: newThreatmonState(
      ThreatMonitoringModule.ModuleDarkWeb
    ),
    [ThreatMonitoringModule.ModuleDataLeaks]: newThreatmonState(
      ThreatMonitoringModule.ModuleDataLeaks
    ),
    [ThreatMonitoringModule.ModuleSocialMedia]: newThreatmonState(
      ThreatMonitoringModule.ModuleSocialMedia
    ),
  },
};

const tmSlice = createSlice({
  name: "threatmon",
  reducerPath: "threatmon",
  initialState,
  reducers: {
    setCurrentTab: (
      state,
      action: PayloadAction<{
        module: Module;
        tab: Tab;
      }>
    ) => {
      state.modules[action.payload.module].currentTab = action.payload.tab;
    },
    setCurrentDetailTab: (
      state,
      action: PayloadAction<{
        module: Module;
        tab: DetailViewTab;
      }>
    ) => {
      state.modules[action.payload.module].currentDetailTab =
        action.payload.tab;
    },
    setPageFilters: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
        filters: ThreatMonitoringResultsFilter;
      }>
    ) => {
      const { module, feed, filters } = action.payload;
      state.modules[module].feeds[feed].query.filters = filters;
      state.modules[module].feeds[feed].query.pageOpts.pageNum = 0;
    },
    updatePageFilters: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
        filters: Partial<ThreatMonitoringResultsFilter>;
      }>
    ) => {
      const { module, feed, filters } = action.payload;
      state.modules[module].feeds[feed].query.filters = {
        ...state.modules[module].feeds[feed].query.filters,
        ...filters,
      };
      state.modules[module].feeds[feed].query.pageOpts.pageNum = 0;
    },
    resetPageFiltersExcludingDateFilter: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
      }>
    ) => {
      const { module, feed } = action.payload;
      const datePeriod =
        state.modules[module].feeds[feed].query.filters.datePeriod;

      const filters = defaultFilters(module)[feed];
      state.modules[module].feeds[feed].query.filters = {
        ...filters,
        datePeriod: datePeriod,
      };
    },
    setPageNumber: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
        page: number;
      }>
    ) => {
      const { module, feed, page } = action.payload;
      state.modules[module].feeds[feed].query.pageOpts.pageNum = page;
    },
    nextPage: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
      }>
    ) => {
      const { module, feed } = action.payload;
      state.modules[module].feeds[feed].query.pageOpts.pageNum += 1;
    },
    prevPage: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
      }>
    ) => {
      const { module, feed } = action.payload;
      if (state.modules[module].feeds[feed].query.pageOpts.pageNum > 0)
        state.modules[module].feeds[feed].query.pageOpts.pageNum -= 1;
    },
    setSortBy: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
        sort: { option: string; desc: boolean };
      }>
    ) => {
      const { module, feed, sort } = action.payload;
      const pageOpts = state.modules[module].feeds[feed].query.pageOpts;

      pageOpts.sortBy = sort.option;
      pageOpts.sortDesc = sort.desc;
      pageOpts.pageNum = 0;
    },
    snapshotSession: (
      state,
      action: PayloadAction<{
        module: Module;
        snapshot: SessionSnapshot;
      }>
    ) => {
      const { module, snapshot } = action.payload;
      state.modules[module].sessionSnapshot = snapshot;
    },
    clearSessionSnapshot: (state, action: PayloadAction<Module>) => {
      state.modules[action.payload].sessionSnapshot = { uuids: [] };
    },
    setCountSince: (
      state,
      action: PayloadAction<{
        module: Module;
        countSince: CountSince;
      }>
    ) => {
      const { module, countSince } = action.payload;
      state.modules[module].countSince = { ...countSince };
    },
    resetCountSince: (state, action: PayloadAction<Module>) => {
      state.modules[action.payload].countSince = { filtered: 0, unfiltered: 0 };
    },
    updateFeedState: (
      state,
      action: PayloadAction<{
        module: Module;
        feed: FeedTypes;
        state: Partial<FeedState>;
      }>
    ) => {
      const { module, feed, state: feedState } = action.payload;
      state.modules[module].feeds[feed] = {
        ...state.modules[module].feeds[feed],
        ...feedState,
      };
    },
  },
  selectors: {
    selectModuleState: (state, module: Module) => state.modules[module],
    selectOpenFeedPageReq: (state, module: Module) =>
      state.modules[module].feeds[ThreatMonitoringFeedType.Open],
    feedPages: (state, module: Module) => state.modules[module].feeds,
    selectPageOpts: (state, module: Module) =>
      state.modules[module].feeds[ThreatMonitoringFeedType.Open].query.pageOpts,
    pageNumber: (state, module: Module) =>
      state.modules[module].feeds[ThreatMonitoringFeedType.Open].query.pageOpts
        .pageNum,
    pageNumberForFeed: (state, params: { module: Module; feed: FeedTypes }) =>
      state.modules[params.module].feeds[params.feed].query.pageOpts.pageNum,
    feedPageReq: (state, params: { module: Module; feed: FeedTypes }) =>
      state.modules[params.module].feeds[params.feed],
    sessionSnapshot: (state, module: Module | undefined) =>
      module
        ? state.modules[module].sessionSnapshot
        : ({ uuids: [] } as SessionSnapshot),
    currentTab: (state, module: Module) => state.modules[module].currentTab,
    currentDetailTab: (state, module: Module) =>
      state.modules[module].currentDetailTab,
    countSince: (state, module: Module) => state.modules[module].countSince,
  },
});

export const selectFeedPageState =
  (module: Module, feed: FeedTypes) => (s: RootState) =>
    tmSlice.selectSlice(s).modules[module].feeds[feed];

export const selectFeedPageQuery =
  (module: Module, feed: FeedTypes) => (s: RootState) =>
    tmSlice.selectSlice(s).modules[module].feeds[feed].query;

export default tmSlice;

import BaseAPI from "../../_common/rtkQueryApi";
import { ISurveyListItemResponse } from "../../_common/types/survey";
import { ExcelQnExtractorConfig } from "../../_common/components/types/excelConfig.types";
import { RootState } from "../../_common/types/reduxStore";
import { produce } from "immer";
import { setSurveyData } from "../../_common/reducers/commonActions";
import { IUserMini } from "../../_common/types/user";

export enum SurveyImportCacheTag {
  ImportedSurveys = "SurveyImportCacheTag_ImportedSurveys",
  EligibleOrgUsers = "SurveyImportCacheTag_EligibleOrgUsers",
}

export interface getImportedSurveysListResp {
  surveys: ISurveyListItemResponse[];
  surveyIDsToAssigneeUserIDs: Record<number, number[] | undefined>;
}

const SurveyImportAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(SurveyImportCacheTag)],
}).injectEndpoints({
  endpoints: (builder) => ({
    // Gets a list of survey instances that were created via importing excel files
    getImportedSurveysList: builder.query<getImportedSurveysListResp, void>({
      query: () => ({
        url: "/surveyimport/list/v1",
        method: "GET",
      }),
      providesTags: [SurveyImportCacheTag.ImportedSurveys],
    }),

    // Gets the status of a survey import created with createImportedSurveyStep1
    getImportedSurveyStatus: builder.query<
      {
        autodetectColumnsJobValid: boolean;
        autodetectColumnsJobProgress: number;
        autodetectColumnsJobFailed: boolean;
        excelConfig: ExcelQnExtractorConfig[];
      },
      {
        uuid: string;
      }
    >({
      query: ({ uuid }) => ({
        url: "/surveyimport/status/v1",
        method: "GET",
        params: {
          uuid,
        },
      }),
    }),

    // Uploads an excel file and pulls out the excel config, optionally kicking
    // off a job to automatically determine the column configuration.
    createImportedSurveyStep1: builder.mutation<
      {
        surveyImportUUID: string;
      },
      {
        aiTermsAccepted: boolean;
        file: File;
      }
    >({
      query: ({ file, aiTermsAccepted }) => ({
        url: "/surveyimport/step1/v1",
        method: "POST",
        params: {
          ai_terms_accepted: aiTermsAccepted,
        },
        file,
      }),
    }),

    createImportedSurveyStep2: builder.mutation<
      {
        surveyID: number;
      },
      {
        surveyImportUUID: string;
        surveyName: string;
        fromCompany: string;
        dueDate?: string;
        documentConfig: ExcelQnExtractorConfig[];
      }
    >({
      query: (args) => {
        return {
          url: "/surveyimport/step2/v1",
          method: "POST",
          body: JSON.stringify(args),
        };
      },
      invalidatesTags: [SurveyImportCacheTag.ImportedSurveys],
    }),

    setImportedSurveyArchived: builder.mutation<
      void,
      {
        surveyID: number;
        archived: boolean;
      }
    >({
      query: ({ surveyID, archived }) => {
        return {
          url: "/surveyarchive/v1",
          method: "PUT",
          params: {
            survey_id: surveyID,
            archived,
          },
        };
      },
      onQueryStarted(
        { surveyID, archived },
        { dispatch, queryFulfilled, getState }
      ) {
        const patchResult = dispatch(
          SurveyImportAPI.util.updateQueryData(
            "getImportedSurveysList",
            undefined,
            (draft) => {
              const s = draft?.surveys?.find((s) => s.id === surveyID);
              if (s) {
                s.archived = archived;
              }
            }
          )
        );

        queryFulfilled.catch(patchResult.undo).then(() => {
          // Find any fetched survey details and update it too
          const state = getState() as RootState;
          const surveyInState = state.common.surveys[surveyID];
          if (surveyInState) {
            const newSurvey = produce(surveyInState, (draft) => {
              if (draft.survey) {
                draft.survey.archived = archived;
              }
            });
            dispatch(setSurveyData(surveyID, newSurvey));
          }
        });
      },
    }),

    deleteImportedSurvey: builder.mutation<
      void,
      {
        surveyID: number;
      }
    >({
      query: ({ surveyID }) => {
        return {
          url: "/survey/v1",
          method: "DELETE",
          params: {
            survey_id: surveyID,
          },
        };
      },
      onQueryStarted({ surveyID }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          SurveyImportAPI.util.updateQueryData(
            "getImportedSurveysList",
            undefined,
            (draft) => {
              if (draft.surveys) {
                draft.surveys = draft.surveys.filter((s) => s.id !== surveyID);
              }
            }
          )
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),

    getImportedSurveyEligibleOrgUsers: builder.query<
      {
        users: IUserMini[];
      },
      void
    >({
      query: () => {
        return {
          url: "/surveyimport/orgusers/v1",
          method: "GET",
        };
      },
      providesTags: [SurveyImportCacheTag.EligibleOrgUsers],
    }),

    updateImportedSurveyAssignees: builder.mutation<
      void,
      {
        surveyID: number;
        uuid: string;
        assignees: number[];
      }
    >({
      query: ({ uuid, assignees }) => {
        return {
          url: "/surveyimport/assignees/v1",
          method: "PUT",
          params: {
            uuid,
            assignees,
          },
        };
      },
      onQueryStarted(
        { surveyID, assignees },
        { dispatch, getState, queryFulfilled }
      ) {
        const origSurveyState = (getState() as RootState).common.surveys[
          surveyID
        ];
        if (origSurveyState) {
          const newSurveyState = produce(origSurveyState, (draft) => {
            if (draft.surveyImport) {
              draft.surveyImport.assigneeUserIDs = assignees;
            }
          });

          dispatch(setSurveyData(surveyID, newSurveyState));

          // Revert to previous survey state if the update fails
          queryFulfilled.catch(() =>
            dispatch(setSurveyData(surveyID, origSurveyState))
          );
        }

        // And update the list view too
        const patchResult = dispatch(
          SurveyImportAPI.util.updateQueryData(
            "getImportedSurveysList",
            undefined,
            (draft) => {
              draft.surveyIDsToAssigneeUserIDs[surveyID] = assignees;
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export default SurveyImportAPI;

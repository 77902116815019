import { useEffect, useState } from "react";
import Modal from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import TextField from "../../../_common/components/TextField";
import { approveVendorRiskWaiver } from "../../../vendor_portal/reducers/vendorRiskWaiver.actions";
import { trackEvent } from "../../../_common/tracking";
import { fetchAlertsOrActivityStreamForOrgUser } from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  approveRejectAcceptedRisk,
  fetchVendorAppAcceptedRisks,
} from "../../../vendor_portal/reducers/actions";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { approveSubsidiaryRiskWaiver } from "../../reducers/vendorRiskWaiver.actions";
import { fetchBreachsightRiskProfileIncludingSubsidiaries } from "../../reducers/breachsightSubsidiaries.actions";

interface IApproveRiskWaiverModalProps {
  isShowing: boolean;
  waiverId: number;
  isApproving: boolean;
  onClose: (didUpdate: boolean) => void;
  isVendorRiskWaiver: boolean;
  waiverType?: WaiverType;
  isSubsidiary?: boolean;
}

const ApproveRiskWaiverModal = (props: IApproveRiskWaiverModalProps) => {
  const dispatch = useAppDispatch();
  const [justificationText, setJustificationText] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    setJustificationText("");
    setIsValid(false);
    setIsSaving(false);
    setIsForbidden(false);
  }, [props.isShowing]);

  const waiverText =
    props.waiverType === WaiverType.SeverityAdjustment
      ? "adjustment"
      : "waiver";

  const approveWaiver: () => Promise<void> = async () => {
    if (props.waiverId) {
      setIsSaving(true);

      try {
        if (!props.isVendorRiskWaiver) {
          await dispatch(
            approveRejectAcceptedRisk(
              props.waiverId,
              props.isApproving,
              justificationText
            )
          );
          dispatch(
            addDefaultSuccessAlert(
              props.isApproving
                ? `Successfully approved the risk ${waiverText}`
                : `Successfully rejected the risk ${waiverText}`
            )
          );
          dispatch(fetchVendorAppAcceptedRisks(true));
        } else {
          props.isSubsidiary
            ? await dispatch(
                approveSubsidiaryRiskWaiver({
                  ID: props.waiverId,
                  approve: props.isApproving,
                  justification: justificationText,
                })
              )
            : await dispatch(
                approveVendorRiskWaiver({
                  ID: props.waiverId,
                  approve: props.isApproving,
                  justification: justificationText,
                })
              );

          if (props.isApproving) {
            props.isSubsidiary
              ? trackEvent("SubsidiaryRiskWaiver_Approved")
              : trackEvent("VendorRiskWaiver_Approved");
          } else {
            props.isSubsidiary
              ? trackEvent("SubsidiaryRiskWaiver_Rejected")
              : trackEvent("VendorRiskWaiver_Rejected");
          }
        }

        if (props.isSubsidiary) {
          await dispatch(
            fetchBreachsightRiskProfileIncludingSubsidiaries(true)
          );
        }
        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
        props.onClose(true);
        setIsSaving(false);
      } catch (e: unknown) {
        setIsSaving(false);
        if (e instanceof Error && e.message === "Forbidden") {
          setIsForbidden(true);
        } else {
          dispatch(
            addDefaultUnknownErrorAlert(`Failed to update risk ${waiverText}`)
          );
        }
      }
    }
  };

  const actionText = props.isApproving ? "Approve" : "Reject";
  const actionSubText = props.isApproving
    ? `accepting this ${waiverText}.`
    : `rejecting this ${waiverText}.`;

  const buttonIconClass = props.isApproving
    ? "cr-icon-accepted"
    : "cr-icon-exclamation";

  if (isForbidden) {
    return (
      <Modal
        width={600}
        headerContent="You no longer have access to manage this request"
        active={props.isShowing && isForbidden}
        onClose={() => props.onClose(false)}
        footerContent={
          <div className="btn-group">
            <Button primary onClick={() => props.onClose(false)}>
              Close
            </Button>
          </div>
        }
      >
        <p>
          If you think an error has happened, get in touch with your account
          admin.
        </p>
      </Modal>
    );
  }

  return (
    <Modal
      width={600}
      headerContent={`${actionText} risk ${waiverText}`}
      className={"approve-risk-waiver-modal"}
      active={props.isShowing}
      onClose={() => props.onClose(false)}
      footerContent={
        <div className={"btn-group"}>
          <Button tertiary onClick={() => props.onClose(false)}>
            Cancel
          </Button>
          <Button
            onClick={approveWaiver}
            loading={isSaving}
            danger={!props.isApproving}
            disabled={!isValid}
          >
            <i className={buttonIconClass} />
            {actionText}
          </Button>
        </div>
      }
    >
      <>
        <p>{`Please provide a justification for ${actionSubText}`}</p>
        <TextField
          multiLine
          maxLength={4000}
          allowTextOverflow
          required
          value={justificationText}
          onChanged={(v, isValid) => {
            setJustificationText(v);
            setIsValid(isValid);
          }}
        />
      </>
    </Modal>
  );
};

export default ApproveRiskWaiverModal;

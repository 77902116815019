import BaseAPI from "../../_common/rtkQueryApi";
import {
  IMySharedAssessmentAccess,
  IMySharedAssessmentAccessLog,
  ISharedAssessment,
  ProfileLogo,
} from "../../vendorrisk/types/sharedAssessment";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import { ComplianceBadgeTypes } from "../helpers/types";

export enum TrustPageTagTypes {
  trustPage = "trustPage",
  ownTrustPage = "ownTrustPage",
  ownTrustPageAccess = "ownTrustPageAccess",
  ownTrustPageAccessLog = "ownTrustPageAccessLog",
}

export interface UpdateSharedAssessmentMetadataV1Req {
  contactName?: string;
  contactEmail?: string;
  companyDescription?: string;
  includeRiskRating?: boolean;
  includeIndustryAverage?: boolean;
  profileLogo?: string;
}

export interface TrustPageCoverImageV1Resp {
  coverImageUrl: string;
}

export interface UpdateTrustPageCoverImageV1Req {
  file: FormData;
}

export interface VerifiedVendorLogoV1Resp {
  logoUrl: string;
}

export interface UpdateVerifiedVendorLogoV1Req {
  file: FormData;
}

export interface TrustPageComplianceBadgesV1Req {
  badges: ComplianceBadgeTypes[];
}

export interface TrustPageComplianceBadgesV1Resp {
  badges: ComplianceBadgeTypes[];
}

const TrustPageAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(TrustPageTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    /*
         getOwnTrustPage
         - gets the trust page for current org
         */
    getOwnSharedAssessmentV1: builder.query<ISharedAssessment, void>({
      query: () => ({
        url: "/mysharedassessment/v1",
        method: "GET",
      }),
      providesTags: [TrustPageTagTypes.ownTrustPage],
    }),

    getOwnSharedAssessmentAccessV1: builder.query<
      IMySharedAssessmentAccess,
      void
    >({
      query: () => ({
        url: "/verifiedvendor/access/v1",
        method: "GET",
      }),
      providesTags: [TrustPageTagTypes.ownTrustPageAccess],
    }),

    /*
    getOwnSharedAssessmentAccessLogV1
    - gets the access log for current org trust page with limit and offset
     */
    getOwnSharedAssessmentAccessLogV1: builder.query<
      IMySharedAssessmentAccessLog,
      { limit: number; offset?: number }
    >({
      query: (params) => ({
        url: "/verifiedvendor/accesslog/v1",
        method: "GET",
        params: {
          limit: params.limit,
          offset: params.offset ?? 0,
        },
      }),
      providesTags: (result) =>
        result?.accessLog ? [TrustPageTagTypes.ownTrustPageAccessLog] : [],
    }),

    /*
      updateOwnSharedAssessmentMetadataV1
      - updates the trust page metadata for current org
     */
    updateOwnSharedAssessmentMetadataV1: builder.mutation<
      void,
      UpdateSharedAssessmentMetadataV1Req
    >({
      query: ({
        contactName,
        contactEmail,
        companyDescription,
        includeRiskRating,
        includeIndustryAverage,
        profileLogo,
      }) => {
        const opts: Record<string, any> = {};

        if (contactName !== undefined) {
          opts.contact_name = contactName;
          if (contactName === "") opts.clear_contact_name = true;
        }
        if (contactEmail !== undefined) {
          opts.contact_email = contactEmail;
          if (contactEmail === "") opts.clear_contact_email = true;
        }
        if (companyDescription !== undefined) {
          opts.company_description = companyDescription;
          if (companyDescription === "") opts.clear_company_description = true;
        }
        if (includeRiskRating !== undefined) {
          opts.include_risk_rating = includeRiskRating;
        }
        if (includeIndustryAverage !== undefined) {
          opts.include_industry_average = includeIndustryAverage;
        }
        if (profileLogo !== undefined) {
          opts.profile_logo = profileLogo;
        }

        return {
          url: "/mysharedassessment/meta/v1/",
          method: "PUT",
          body: opts,
        };
      },
      invalidatesTags: [TrustPageTagTypes.ownTrustPage],
    }),

    /*
      inviteUserToTrustPageV1
      - creates invite for user by email address to access trust page for current org
     */
    inviteUserToTrustPageV1: builder.mutation<
      void,
      { email: string; noRefresh?: boolean }
    >({
      query: ({ email }) => ({
        url: "/verifiedvendor/users/v1",
        method: "POST",
        params: {
          recipient_email: email,
        },
      }),
      onQueryStarted(args, { dispatch, queryFulfilled }) {
        const shouldRefetch = args.noRefresh ? !args.noRefresh : true;
        queryFulfilled
          .then(() => {
            if (shouldRefetch) {
              dispatch(conditionalRefreshActivityStreamForOrgUser());
              dispatch(
                TrustPageAPI.util.invalidateTags([
                  TrustPageTagTypes.ownTrustPageAccess,
                ])
              );
            }
          })
          .catch((e) => {
            console.error("Error inviting user to trust page", e);
          });
      },
    }),

    /*
      inviteOrgToTrustPageV1
      - approves org users by org id to access trust page for current org
     */
    inviteOrgToTrustPageV1: builder.mutation<void, { orgID: number }>({
      query: ({ orgID }) => ({
        url: "/verifiedvendor/orgs/v1",
        method: "POST",
        params: {
          org_id: orgID,
        },
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(conditionalRefreshActivityStreamForOrgUser());
            dispatch(
              TrustPageAPI.util.invalidateTags([
                TrustPageTagTypes.ownTrustPageAccess,
              ])
            );
          })
          .catch((e) => {
            console.error("Error inviting org", e);
          });
      },
    }),

    /*
    cancelInviteToTrustPageV1
    - cancels invite for user by email address to access trust page for current org
    */
    cancelInviteToTrustPageV1: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: "verifiedvendor/invites/v1/",
        method: "DELETE",
        params: {
          email_address: email,
        },
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(conditionalRefreshActivityStreamForOrgUser());
            dispatch(
              TrustPageAPI.util.invalidateTags([
                TrustPageTagTypes.ownTrustPageAccess,
              ])
            );
          })
          .catch((e) => {
            console.error("Error deleting invite", e);
          });
      },
    }),

    /*
    updateOwnVerifiedVendorLogoV1
    - updates profile logo for current org Trust Page
     */
    updateOwnVerifiedVendorLogoV1: builder.mutation<
      VerifiedVendorLogoV1Resp,
      UpdateVerifiedVendorLogoV1Req
    >({
      query: ({ file }) => ({
        url: "/mysharedassessment/logo/v1",
        method: "POST",
        body: file,
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              TrustPageAPI.util.updateQueryData(
                "getOwnSharedAssessmentV1",
                undefined,
                (draft) => {
                  draft.profileLogoUrl = data.logoUrl;
                  draft.profileLogo = ProfileLogo.CustomLogo;
                }
              )
            );
          })
          .catch((e) => {
            console.error("Error updating logo", e);
          });
      },
    }),

    /*
    deleteOwnVerifiedVendorLogoV1
    - deletes verified vendors logo for current org Trust Page
     */
    deleteOwnVerifiedVendorLogoV1: builder.mutation<void, void>({
      query: () => ({
        url: "/mysharedassessment/logo/v1",
        method: "DELETE",
      }),
      invalidatesTags: [TrustPageTagTypes.ownTrustPage],
    }),

    /*
    updateOwnTrustPageCoverImageV1
    - updates cover image for current org Trust Page
     */
    updateOwnTrustPageCoverImageV1: builder.mutation<
      TrustPageCoverImageV1Resp,
      UpdateTrustPageCoverImageV1Req
    >({
      query: ({ file }) => ({
        url: "/trustpage/coverimage/v1",
        method: "POST",
        body: file,
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(
            TrustPageAPI.util.updateQueryData(
              "getOwnSharedAssessmentV1",
              undefined,
              (draft) => {
                draft.coverImageUrl = data.coverImageUrl;
              }
            )
          );
        });
      },
    }),

    /*
    deleteOwnTrustPageCoverImageV1
    - deletes cover image for current org Trust Page
     */
    deleteOwnTrustPageCoverImageV1: builder.mutation<void, void>({
      query: () => ({
        url: "/trustpage/coverimage/v1",
        method: "DELETE",
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            TrustPageAPI.util.updateQueryData(
              "getOwnSharedAssessmentV1",
              undefined,
              (draft) => {
                draft.coverImageUrl = "";
              }
            )
          );
        });
      },
    }),

    /*
    updateOwnTrustPageComplianceBadgesV1
    - updates compliance badges for a verified vendor
    - replaces existing badges with new set of badges
     */
    updateOwnTrustPageComplianceBadgesV1: builder.mutation<
      TrustPageComplianceBadgesV1Resp,
      TrustPageComplianceBadgesV1Req
    >({
      query: (req) => ({
        url: "/trustpage/badges/v1",
        method: "PUT",
        body: JSON.stringify(req),
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(
            TrustPageAPI.util.updateQueryData(
              "getOwnSharedAssessmentV1",
              undefined,
              (draft) => {
                draft.badges = data.badges;
              }
            )
          );
        });
      },
    }),

    /*
      getVendorsSharedAssessmentV1
      - gets the trust page by verified vendor id
     */
    getVendorsSharedAssessmentV1: builder.query<
      ISharedAssessment,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/sharedassessment/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (_1, _2, { vendorId }) => {
        return [
          {
            type: TrustPageTagTypes.trustPage,
            id: vendorId,
          },
        ];
      },
    }),

    requestAccessToTrustPageV1: builder.mutation<
      void,
      { vendorId: number; message?: string }
    >({
      query: ({ vendorId, message }) => ({
        url: "/verifiedvendor/requestaccess/v1",
        method: "POST",
        params: {
          vendor_id: vendorId,
          message: message,
        },
      }),
      invalidatesTags: (_, error, { vendorId }) =>
        error ? [] : [{ type: TrustPageTagTypes.trustPage, id: vendorId }],
    }),
  }),
});

export default TrustPageAPI;

import {
  FC,
  Fragment,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  childNodeIdAndType,
  InputTextNode,
  Node,
  nodeToFriendlyName,
  NodeType,
  SectionNode,
  SelectNode,
} from "../types/types";
import {
  deleteCustomNumbers,
  deleteNode,
  reorderTree,
  setCurrentFocusedNodeId,
  setCustomNumbering,
  setEditRiskModalNodeId,
  setIncludeTOC,
  SimpleErrorAlert,
  swapQuestionNode,
  updateNode,
} from "../reducers/actions";
import classnames from "classnames";
import "../style/QuestionNode.scss";
import AddItemButton from "./AddItemButton";
import NodeTypeIcon, {
  nodeToNodeTypeIconType,
  NodeTypeIconType,
  nodeTypeIconTypeToNodeType,
} from "./NodeTypeIcon";
import NodeTypeSelector from "./NodeTypeSelector";
import IconButton, { HoverColor } from "../../_common/components/IconButton";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import ConditionalVisibilityStatus from "./ConditionalVisibilityStatus";
import {
  createUUID,
  getChildNodeQuestionNumber,
  questionNodeDOMId,
} from "../helpers";
import MoveQuestionModal from "./MoveQuestion";
import { SurveyFramework } from "../types/frameworks";
import MapToFrameworkButton from "./MapToFrameworkButton";
import RiskQuestion, {
  getMissingFieldsTextForRiskQuestion,
  RiskQuestionIcon,
} from "./RiskQuestion";
import SelectNodeAnswers from "./SelectNodeAnswers";
import { ValidationError } from "../types/validation";
import ValidationErrorText from "./ValidationErrorText";
import { openModal } from "../../_common/reducers/commonActions";
import {
  ConfirmationModalName,
  IConfirmationModalData,
} from "../../_common/components/modals/ConfirmationModal";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import {
  IAutomationRecipe,
  IAutomationTreeNode,
} from "../../vendorrisk/types/automation";
import { OrgAccessRelationshipQuestionnaireAutomation } from "../../_common/permissions";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { Severity } from "../../_common/types/severity";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import Icon from "../../_common/components/core/Icon";
import { AppDispatch } from "../../_common/types/reduxStore";
import { appConnect } from "../../_common/types/reduxHooks";
import TextFieldPerformant from "../../_common/components/TextFieldPerformant";

const onClickDeleteNode = (
  dispatch: DefaultThunkDispatch,
  surveyId: string,
  nodeId: string,
  hasChildren: boolean
) => {
  dispatch(
    openModal(
      ConfirmationModalName,
      {
        title: "Are you sure you want to delete this item?",
        description: hasChildren
          ? "This action is not reversible. This section's children will also be deleted."
          : "This action is not reversible.",
        buttonText: "Delete item",
        dangerousAction: true,
        iconClass: "cr-icon-trash",
        isV2Layout: true,
        buttonAction: () => {
          dispatch(deleteNode(surveyId, nodeId));
        },
      } as IConfirmationModalData,
      true
    )
  );
};

const openCustomNumberingModal = (
  dispatch: DefaultThunkDispatch,
  onConfirm: () => void
) => {
  dispatch(
    openModal(
      ConfirmationModalName,
      {
        title: "Discard custom question numbers",
        description:
          "You will lose custom numbering for each question in your questionnaire.",
        buttonText: "Discard custom numbering",
        cancelText: "Keep",
        dangerousAction: true,
        isV2Layout: true,
        buttonAction: () => {
          onConfirm();
        },
      } as IConfirmationModalData,
      true
    )
  );
};

const getMainTextPlaceholder = (
  nodeType: NodeType,
  isRootNode: boolean
): string => {
  if (isRootNode) {
    return "Enter a questionnaire description";
  }
  switch (nodeType) {
    case NodeType.Section:
      return "Enter a section description";
    case NodeType.InputText:
      return "Enter your question";
    case NodeType.Select:
      return "Enter your question";
    case NodeType.Upload:
      return "Describe the evidence you want to be uploaded";
    case NodeType.Risk:
      return "Describe the risk";
    case NodeType.Info:
      return "Enter information to be displayed";
  }
};

const maxIndentLevel = 6;

interface IQuestionNodeProps {
  dispatch: AppDispatch;
  surveyId: string;
  surveyTypeId?: number;
  usageType: SurveyUsageType;
  surveyFramework: SurveyFramework;
  questionNumber?: string;
  isRootNode?: boolean;
  includeTOC?: boolean; // Only included for the root node
  customNumbering: boolean;
  indentLevel: number;
  nodeId: string;
  node?: Node;
  isLastChild: boolean;
  index: number;
  validationErrors?: {
    [validationError: string]: true; // The keys should map to the ValidationError enum
  };
  readOnly: boolean;
  isFocused: boolean;
  hideEditFields?: boolean;
  orgHasRelationshipQuestionnaireAutomation: boolean;
  draftAutomationList?: IAutomationRecipe[];
}

const noop = () => undefined;

// QuestionNode is the component used for any questionnaire node type.
// This component is the basis of the survey builder. Every NodeType specified in
// types/types.ts uses this same component to render, and Section nodes can
// render more QuestionNodes as children.
// QuestionNodes are connected directly to redux to retrieve their own node by nodeId,
// to avoid re-rendering the entire subtree when any part of the node changes.
const QuestionNode: FC<IQuestionNodeProps> = (props) => {
  if (!props.node) {
    throw new Error(
      `QuestionNode tried to mount with an unknown nodeId: ${props.nodeId}`
    );
  }

  // The initial render of the full component tree can be potentially very expensive, and on lower powered
  // computers can cause the browser tab to appear to stop responding. To avoid hanging the UI thread
  // while react attempts to render the entire component tree, we defer rendering of child nodes to the end of
  // the call stack. This makes the overall initial load time longer, but will feel more responsive.
  const [shouldRenderChildNodes, setShouldRenderChildNodes] = useState(false);
  useEffect(() => {
    let timeout: number;
    if (props.node?.type === NodeType.Section) {
      timeout = window.setTimeout(() => setShouldRenderChildNodes(true), 0);
    }

    // Clear our timeout on unmounting, in case we need to unmount before its given a chance to render
    // its children.
    return () => clearTimeout(timeout);
  }, []);

  const [collapsed, setCollapsed] = useState(false);

  // Question types can be swapped between one another. When the user clicks on the "Change question type" button,
  // a popup will appear with a list of question types to choose from. An event listener is added to close the popup
  // when the user clicks outside of it.
  const changeQuestionTypePopupClickHandlerRef = useRef<EventListener>();
  const [changeQuestionTypePopupOpen, setChangeQuestionTypePopupOpen] =
    useState(false);
  const togglePopup = (open: boolean) => {
    if (open) {
      // Add an event listener on the document that will set the popup to closed
      changeQuestionTypePopupClickHandlerRef.current = (ev: Event) => {
        // Don't do anything if we've clicked inside the popup.
        if ((ev.target as Element).closest(".add-btn-popup")) {
          return;
        }
        togglePopup(false);
      };
      document.addEventListener(
        "click",
        changeQuestionTypePopupClickHandlerRef.current
      );
      setChangeQuestionTypePopupOpen(true);
    } else {
      // Remove the click handler on the document
      document.removeEventListener(
        "click",
        changeQuestionTypePopupClickHandlerRef.current as EventListener
      );
      setChangeQuestionTypePopupOpen(false);
    }
  };
  useEffect(() => {
    // Clean up the click handler on unmount
    return () =>
      document.removeEventListener(
        "click",
        changeQuestionTypePopupClickHandlerRef.current as EventListener
      );
  }, []);

  const [moveQuestionModalOpen, setMoveQuestionModalOpen] = useState(false);

  let collapsedTitle;
  if (collapsed) {
    if (props.node.type === NodeType.Section) {
      collapsedTitle = props.node.title || "Untitled section";
    } else if (props.node.type === NodeType.Risk) {
      collapsedTitle = props.node.name;
    } else {
      collapsedTitle = props.node.mainText || "Untitled";
    }
  }

  const validationErrors = props.validationErrors || {};

  const setFocused = !props.isFocused
    ? () =>
        props.dispatch(setCurrentFocusedNodeId(props.surveyId, props.nodeId))
    : noop;

  const questionIsInAutomationNode = (
    node: IAutomationTreeNode | undefined,
    questionId: string,
    answerId?: string
  ): boolean => {
    if (node == undefined) {
      return false;
    }
    for (let idx = 0; idx < node.tests?.length; idx++) {
      if (
        node.tests[idx].questionId == questionId &&
        (!answerId || node.tests[idx].value == answerId)
      ) {
        return true;
      }
    }
    for (let idx = 0; idx < node.branches?.length; idx++) {
      if (
        questionIsInAutomationNode(node.branches[idx], questionId, answerId)
      ) {
        return true;
      }
    }
    return false;
  };

  const automationRefersToQuestion = (questionId: string): boolean => {
    if (
      props.orgHasRelationshipQuestionnaireAutomation &&
      props.draftAutomationList
    ) {
      for (let idx = 0; idx < props.draftAutomationList.length; idx++) {
        if (
          props.draftAutomationList[idx]?.isWeighted &&
          props.draftAutomationList[
            idx
          ].userConstructs.masterExpression.indexOf(`[${questionId}]`) >= 0
        ) {
          return true;
        } else if (!props.draftAutomationList[idx].isWeighted) {
          for (
            let rIdx = 0;
            rIdx <
            (props.draftAutomationList[idx].userConstructs?.simpleRules
              ?.length ?? 0);
            rIdx++
          ) {
            if (
              questionIsInAutomationNode(
                props.draftAutomationList[idx].userConstructs?.simpleRules?.[
                  rIdx
                ]?.logic,
                questionId
              )
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const automationRefersToQuestionAnswer = (
    questionId: string,
    answerId: string
  ): boolean => {
    if (
      props.orgHasRelationshipQuestionnaireAutomation &&
      props.draftAutomationList
    ) {
      for (let idx = 0; idx < props.draftAutomationList.length; idx++) {
        if (
          props.draftAutomationList[idx].isWeighted &&
          props.draftAutomationList[
            idx
          ].userConstructs?.masterExpression.indexOf(
            `[${questionId}__${answerId}]`
          ) >= 0
        ) {
          return true;
        } else if (!props.draftAutomationList[idx].isWeighted) {
          for (
            let rIdx = 0;
            rIdx <
            (props.draftAutomationList[idx].userConstructs?.simpleRules
              ?.length ?? 0);
            rIdx++
          ) {
            if (
              questionIsInAutomationNode(
                props.draftAutomationList[idx].userConstructs?.simpleRules?.[
                  rIdx
                ]?.logic,
                questionId,
                answerId
              )
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const nodeCanBeDeleted = (nodeId: string) => {
    return !automationRefersToQuestion(nodeId);
  };
  const answerCanBeDeleted = (nodeId: string, answerId: string) => {
    return !automationRefersToQuestionAnswer(nodeId, answerId);
  };

  const renderChildNodes = (childNodeIds: childNodeIdAndType[]) => {
    const nodesExceptLast: ReactNode[] = [];
    let lastNode: ReactNode;

    let curQuestionNumberIndex = -1;

    for (let i = 0; i < childNodeIds.length; i++) {
      const childNodeIdAndType = childNodeIds[i];

      if (
        childNodeIdAndType.type !== NodeType.Risk &&
        childNodeIdAndType.type !== NodeType.Info
      ) {
        curQuestionNumberIndex += 1;
      }

      const isLastChild = i === childNodeIds.length - 1;

      const node: ReactNode = (
        <Fragment key={childNodeIdAndType.id}>
          <ConnectedQuestionNode
            surveyTypeId={props.surveyTypeId}
            surveyId={props.surveyId}
            customNumbering={props.customNumbering}
            usageType={props.usageType}
            nodeId={childNodeIdAndType.id}
            indentLevel={props.isRootNode ? 0 : props.indentLevel + 1}
            questionNumber={getChildNodeQuestionNumber(
              props.questionNumber || "",
              curQuestionNumberIndex
            )}
            index={i}
            isLastChild={isLastChild}
            readOnly={props.readOnly}
          />
          <div className="separator-line">
            {props.indentLevel < maxIndentLevel && !props.readOnly && (
              <AddItemButton
                dispatch={props.dispatch}
                surveyId={props.surveyId}
                parentNodeId={props.nodeId}
                indentLevel={props.isRootNode ? 0 : props.indentLevel + 1}
                newQuestionNumber={
                  props.customNumbering
                    ? ""
                    : getChildNodeQuestionNumber(
                        props.questionNumber || "",
                        curQuestionNumberIndex + 1
                      )
                }
                addAtIndex={i + 1}
                usageType={props.usageType}
              />
            )}
          </div>
        </Fragment>
      );

      if (isLastChild) {
        lastNode = node;
      } else {
        nodesExceptLast.push(node);
      }
    }

    return [nodesExceptLast, lastNode];
  };

  const swapQuestionNodeType = (newNodeTypeIcon: NodeTypeIconType) => {
    if (props.node) {
      const newNodeType = nodeTypeIconTypeToNodeType(newNodeTypeIcon);

      // If we're swapping between select node types (radio/checkbox), keep the answers.
      // If we're swapping to a select node from a non-select node, add two blank answers.
      // If we're not swapping to a select node, don't add any answers.
      const answers =
        props.node?.type === NodeType.Select && newNodeType === NodeType.Select
          ? props.node.answers
          : newNodeType === NodeType.Select
            ? [
                {
                  id: createUUID(),
                  text: "",
                },
                {
                  id: createUUID(),
                  text: "",
                },
              ]
            : [];

      props.dispatch(
        swapQuestionNode(
          props.surveyId,
          props.nodeId,
          newNodeType,
          newNodeTypeIcon === NodeTypeIconType.SelectRadio ? true : false,
          answers
        )
      );
    }
  };

  const canEditQuestionNumber =
    props.customNumbering &&
    !props.hideEditFields &&
    !props.node.skipNumbering &&
    !props.readOnly &&
    !collapsed;

  return (
    <>
      <div
        id={questionNodeDOMId(props.node.nodeId)}
        className={classnames("survey-node", {
          "risk-node": props.node.type === NodeType.Risk,
        })}
      >
        <div
          className={classnames(
            "survey-node-contents",
            `indent-${props.indentLevel}`,
            {
              "root-node": props.isRootNode,
              focused: props.isFocused,
            }
          )}
          onClick={(e) => {
            if (changeQuestionTypePopupOpen) {
              togglePopup(false);
            }
            e.stopPropagation();
            setFocused();
          }}
        >
          {!props.isRootNode && (
            <div className="node-header">
              {props.indentLevel >= 1 && (
                <div
                  className={`tree-line-connector ${
                    props.isLastChild
                      ? canEditQuestionNumber
                        ? "arc tall"
                        : "arc"
                      : ""
                  }`}
                />
              )}
              <div className="node-header-left">
                {props.node.type === NodeType.Risk ? (
                  <>
                    <RiskQuestionIcon
                      severity={props.node.severity || Severity.Low}
                      questionNumber=""
                    />
                    <div
                      className="collapsed-section-title"
                      title={props.node.name}
                    >
                      {props.node.name}
                    </div>
                  </>
                ) : (
                  <NodeTypeIcon
                    nodeType={nodeToNodeTypeIconType(props.node)}
                    withInput={canEditQuestionNumber}
                    placeholder={"Question number"}
                    inputValue={props.node.customNumber || ""}
                    title={nodeToFriendlyName(props.node) || ""}
                    onFocus={setFocused}
                    onChange={(val: string) => {
                      props.dispatch(
                        updateNode<SectionNode>(props.surveyId, props.nodeId, {
                          customNumber: val,
                        })
                      );
                    }}
                    questionNumber={
                      props.node.skipNumbering
                        ? ""
                        : props.customNumbering
                          ? props.node.customNumber || ""
                          : props.questionNumber || ""
                    }
                  />
                )}
                {collapsed && props.node.type !== NodeType.Risk ? (
                  <div
                    className="collapsed-section-title"
                    title={collapsedTitle}
                  >
                    {collapsedTitle}
                  </div>
                ) : (
                  <>
                    {!collapsed &&
                      !!props.surveyFramework &&
                      props.surveyFramework !== SurveyFramework.NONE && (
                        <MapToFrameworkButton
                          dispatch={props.dispatch}
                          framework={props.surveyFramework}
                          surveyId={props.surveyId}
                          nodeId={props.node.nodeId}
                          controls={props.node.controls || []}
                          readOnly={props.readOnly}
                        />
                      )}
                  </>
                )}
              </div>
              <div className="node-header-right">
                {!props.readOnly && (
                  <>
                    {props.node.type === NodeType.Risk && (
                      <IconButton
                        hoverText="Edit risk"
                        icon={<div className="cr-icon-pencil" />}
                        onClick={() =>
                          props.dispatch(
                            setEditRiskModalNodeId(props.surveyId, props.nodeId)
                          )
                        }
                        keepPropagation
                      />
                    )}

                    {(props.node.type === NodeType.Select ||
                      props.node.type === NodeType.InputText ||
                      props.node.type === NodeType.Upload) && (
                      <div className="change-question-btn-wrapper">
                        <SidePopupV2
                          text={`Change ${nodeToFriendlyName(
                            props.node
                          )} question type`}
                          noWrap={true}
                          position="top"
                          popupDelay={250}
                        >
                          <IconButton
                            icon={<div className="cr-icon-convert" />}
                            active={changeQuestionTypePopupOpen}
                            onClick={() => {
                              togglePopup(!changeQuestionTypePopupOpen);
                            }}
                            keepPropagation
                          />
                        </SidePopupV2>
                        {changeQuestionTypePopupOpen && (
                          <NodeTypeSelector
                            // Only allow swapping to other question types
                            excludeOptions={[
                              NodeTypeIconType.Info,
                              NodeTypeIconType.Risk,
                              NodeTypeIconType.Section,
                              nodeToNodeTypeIconType(props.node),
                            ]}
                            questionNumber={props.questionNumber}
                            nodeSelectedCallback={(nodeTypeIcon) => {
                              togglePopup(false);
                              swapQuestionNodeType(nodeTypeIcon);
                            }}
                          ></NodeTypeSelector>
                        )}
                      </div>
                    )}

                    <SidePopupV2
                      text="Move"
                      noWrap={true}
                      position="top"
                      popupDelay={250}
                    >
                      <IconButton
                        icon={<div className="cr-icon-sort" />}
                        onClick={() => setMoveQuestionModalOpen(true)}
                        keepPropagation
                      />
                    </SidePopupV2>

                    <SidePopupV2
                      text="Delete"
                      noWrap={true}
                      position="top"
                      popupDelay={250}
                    >
                      <IconButton
                        hoverColor={HoverColor.Red}
                        icon={<div className="cr-icon-trash" />}
                        onClick={() => {
                          if (!nodeCanBeDeleted(props.nodeId)) {
                            // raise an error
                            props.dispatch(
                              SimpleErrorAlert(
                                "This question cannot be deleted as automation rules exist which refer to it. Update your automation rules and try again."
                              )
                            );
                            return;
                          }
                          onClickDeleteNode(
                            props.dispatch,
                            props.surveyId,
                            props.nodeId,
                            props.node?.type === NodeType.Section &&
                              props.node.childNodeIds.length > 0
                          );
                        }}
                        keepPropagation
                      />
                    </SidePopupV2>
                  </>
                )}

                <IconButton
                  icon={
                    <div
                      className={`cr-icon-chevron ${
                        collapsed ? "rot270" : "rot90"
                      }`}
                    />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                />
              </div>
            </div>
          )}
          {!collapsed && (
            <>
              {validationErrors[ValidationError.nodeMissingNumber] && (
                <ValidationErrorText>
                  You must enter a question number
                </ValidationErrorText>
              )}
              {validationErrors[ValidationError.nodeNonUniqueNumber] && (
                <ValidationErrorText>
                  Question number must be unique
                </ValidationErrorText>
              )}
              {props.node.type === NodeType.Section &&
                !props.hideEditFields && (
                  <TextFieldPerformant
                    strict
                    value={props.node.title}
                    onFocus={setFocused}
                    onChanged={(val) => {
                      props.dispatch(
                        updateNode<SectionNode>(props.surveyId, props.nodeId, {
                          title: val,
                        })
                      );
                    }}
                    placeholder={
                      props.isRootNode
                        ? "Enter a name for the questionnaire"
                        : "Enter a section title"
                    }
                    readonly={props.readOnly}
                    maxLength={props.isRootNode ? 120 : undefined}
                  />
                )}

              {validationErrors[ValidationError.rootNodeMissingTitle] && (
                <ValidationErrorText>
                  This questionnaire template must have a title
                </ValidationErrorText>
              )}

              {validationErrors[ValidationError.rootNodeTitleTooLong] && (
                <ValidationErrorText>
                  Title can be a maximum of 120 characters
                </ValidationErrorText>
              )}

              {props.node.type !== NodeType.Risk && !props.hideEditFields && (
                <TextFieldPerformant
                  multiLine
                  strict
                  value={props.node.mainText}
                  placeholder={getMainTextPlaceholder(
                    props.node.type,
                    !!props.isRootNode
                  )}
                  readonly={props.readOnly}
                  onFocus={setFocused}
                  onChanged={(val) =>
                    props.dispatch(
                      updateNode<Node>(props.surveyId, props.nodeId, {
                        mainText: val,
                      })
                    )
                  }
                />
              )}

              {validationErrors[ValidationError.sectionMissingText] && (
                <ValidationErrorText>
                  You must set either a title or description
                </ValidationErrorText>
              )}

              {props.node.type === NodeType.Risk && (
                <>
                  <RiskQuestion dispatch={props.dispatch} node={props.node} />
                  {(validationErrors[ValidationError.riskMissingMainText] ||
                    validationErrors[ValidationError.riskMissingName] ||
                    validationErrors[ValidationError.riskMissingPassName] ||
                    validationErrors[ValidationError.riskMissingCategory] ||
                    validationErrors[ValidationError.riskMissingWhy]) && (
                    <ValidationErrorText>
                      {getMissingFieldsTextForRiskQuestion(validationErrors)}
                    </ValidationErrorText>
                  )}
                </>
              )}

              {props.isRootNode && !props.hideEditFields && (
                <ColorCheckbox
                  label="Show table of contents"
                  checked={!!props.includeTOC}
                  disabled={props.readOnly}
                  onClick={() =>
                    props.dispatch(
                      setIncludeTOC(props.surveyId, !props.includeTOC)
                    )
                  }
                />
              )}

              {props.isRootNode && !props.hideEditFields && (
                <ColorCheckbox
                  label={
                    <>
                      Custom question numbers
                      <SidePopupV2
                        className="help-icon"
                        text="Set custom numbering for each question in your questionnaire."
                        inline={true}
                        position="right"
                        popupDelay={250}
                      >
                        <Icon name="info" />
                      </SidePopupV2>
                    </>
                  }
                  checked={!!props.customNumbering}
                  disabled={props.readOnly}
                  onClick={() => {
                    const dispatchCheckboxUpdate = () => {
                      props.dispatch(
                        setCustomNumbering(
                          props.surveyId,
                          !props.customNumbering
                        )
                      );
                    };
                    if (props.customNumbering) {
                      openCustomNumberingModal(props.dispatch, () => {
                        props.dispatch(deleteCustomNumbers(props.surveyId));
                        dispatchCheckboxUpdate();
                      });
                    } else {
                      dispatchCheckboxUpdate();
                    }
                  }}
                />
              )}

              {!props.isRootNode && (
                <>
                  <ConditionalVisibilityStatus
                    dispatch={props.dispatch}
                    surveyId={props.surveyId}
                    node={props.node}
                    questionNumber={props.questionNumber || ""}
                    validationErrors={validationErrors}
                    readOnly={props.readOnly}
                  />
                  {/* InputText specific options: */}
                  {props.node.type === NodeType.InputText && (
                    <>
                      <ColorCheckbox
                        checked={props.node.multiLine}
                        disabled={props.readOnly}
                        onClick={() =>
                          props.dispatch(
                            updateNode<InputTextNode>(
                              props.surveyId,
                              props.nodeId,
                              {
                                multiLine: !(props.node as InputTextNode)
                                  .multiLine,
                              }
                            )
                          )
                        }
                        label="Support multi-line answers"
                        helpPopup="If checked, this question will support multi-line answers rather than a single line input."
                      />
                    </>
                  )}

                  {/* SelectNode specific options: */}
                  {props.node.type === NodeType.Select && (
                    <>
                      <SelectNodeAnswers
                        dispatch={props.dispatch}
                        surveyId={props.surveyId}
                        parentId={props.node.parentId || ""}
                        nodeId={props.nodeId}
                        index={props.index}
                        answers={props.node.answers}
                        radio={props.node.radio}
                        validationErrors={validationErrors}
                        onFocus={setFocused}
                        readOnly={props.readOnly}
                        usageType={props.usageType}
                        answerCanBeDeletedFunc={answerCanBeDeleted}
                      />
                      <div className="select-node-allow-notes">
                        <ColorCheckbox
                          checked={props.node.allowNotes}
                          disabled={props.readOnly}
                          onClick={() =>
                            props.dispatch(
                              updateNode<SelectNode>(
                                props.surveyId,
                                props.nodeId,
                                {
                                  allowNotes: !(props.node as SelectNode)
                                    .allowNotes,
                                }
                              )
                            )
                          }
                          label="Allow users to add additional information"
                          helpPopup="If checked, an optional field will be added for the user to enter additional information."
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {/*
            tree-line-container is used to house the line that runs alongside all of the children.
            We render all nodes except the last one inside tree-line-container, so the last node can
            connect its title to the tree-line without having it run al the way down.
        */}
        {!collapsed &&
          props.node.type === NodeType.Section &&
          (() => {
            if (!shouldRenderChildNodes) {
              return null;
            }

            const [nodesExceptLast, lastNode] = renderChildNodes(
              props.node.childNodeIds
            );
            return (
              <>
                <div className="tree-line-container">
                  {!props.isRootNode && props.node.childNodeIds.length > 0 && (
                    <div className={`tree-line indent-${props.indentLevel}`} />
                  )}
                  <div className="separator-line">
                    {props.indentLevel < maxIndentLevel && !props.readOnly && (
                      <AddItemButton
                        dispatch={props.dispatch}
                        surveyId={props.surveyId}
                        parentNodeId={props.nodeId}
                        indentLevel={
                          props.isRootNode ? 0 : props.indentLevel + 1
                        }
                        newQuestionNumber={
                          props.customNumbering
                            ? ""
                            : getChildNodeQuestionNumber(
                                props.questionNumber || "",
                                0
                              )
                        }
                        addAtIndex={0}
                        usageType={props.usageType}
                      />
                    )}
                  </div>

                  {/* Render all but the last child node inside the tree-line-container */}
                  {nodesExceptLast}
                </div>
                {/* Render the final child node outside of tree-line-container */}
                {lastNode}
              </>
            );
          })()}
      </div>
      {moveQuestionModalOpen && (
        <MoveQuestionModal
          surveyId={props.surveyId}
          scrollToNodeIdOnMount={props.node.nodeId}
          closeModal={() => setMoveQuestionModalOpen(false)}
          saveChanges={(allQuestions) => {
            props.dispatch(reorderTree(props.surveyId, allQuestions));
            setMoveQuestionModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export interface IConnectedQuestionNodeProps {
  surveyTypeId?: number;
  surveyId: string;
  questionNumber?: string;
  indentLevel: number;
  nodeId: string;
  index: number;
  readOnly: boolean;
}

const ConnectedQuestionNode = appConnect(
  (state, props: IConnectedQuestionNodeProps) => {
    const survey = state.surveyBuilder.surveys[props.surveyId];
    const draftAutomationList = props.surveyTypeId
      ? state.cyberRisk.questionnaireAutomation?.[props.surveyTypeId]?.draft
      : undefined;

    const orgPerms = state.common.userData.orgPermissions
      ? state.common.userData.orgPermissions
      : [];

    const orgHasRelationshipQuestionnaireAutomation = orgPerms.includes(
      OrgAccessRelationshipQuestionnaireAutomation
    );

    // ATTENTION!
    // This component is very sensitive to performance issues when re-rendering.
    // When adding new props here, be careful that they do not change signature often,
    // and that their data changing means that this particular node should re-render.
    // For example, adding `survey` as a prop here would cause this component to re-render on any change
    // to any node in the entire tree, since `survey` contains a map of all nodes.

    return {
      surveyTypeId: props.surveyTypeId,
      node: survey.nodes[props.nodeId],
      validationErrors: survey.validationErrors.errors[props.nodeId],
      surveyFramework: survey.framework,
      isFocused:
        !!survey.currentFocusedNodeId &&
        survey.currentFocusedNodeId === props.nodeId,
      draftAutomationList,
      orgHasRelationshipQuestionnaireAutomation,
    };
  }
)(memo(QuestionNode));

export default ConnectedQuestionNode;

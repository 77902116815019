import { FC } from "react";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
} from "../../_common/types/vendorSummary";
import "../style/components/RiskPanel.scss";
import {
  CheckItemSlidePanelSection,
  checkItemSlidePanelSectionProps,
} from "./filter/SlidePanelSection";
import ScrollableDiv from "./ScrollableDiv";
import { WaivedAssets } from "../reducers/risks.actions";
import { RiskProfileRiskTypes } from "../../_common/constants";
import { getRepoManifestLocation } from "../../appguard/api/manifestLocation.helper";
import { RiskDescription } from "./RiskSummaryDetails";
import SeverityIcon from "../../_common/components/SeverityIcon";
import { SeverityAsString, SeverityInt } from "../../_common/types/severity";

export interface RiskPanelRowData extends checkItemSlidePanelSectionProps {
  key: string; // Used as a React key, needs to be unique/stable within RiskPanelProps.trailingRows
}

interface RiskPanelRisk {
  title: string;
  riskType: VendorSummaryRiskType;
  description: string;
  saasAppName?: string;
  summary?: string;
  riskDetails?: string;
  recommendedRemediation?: string;
  severity?: SeverityInt;
}

interface RiskPanelProps {
  risk: RiskPanelRisk;
  titleButtonsContent: JSX.Element;
  leadingRows?: RiskPanelRowData[];
  trailingRows?: RiskPanelRowData[];
}

const RiskPanel: FC<RiskPanelProps> = ({
  risk,
  titleButtonsContent,
  leadingRows,
  trailingRows,
}) => {
  return (
    <div className="risk-panel">
      <div className="risk-panel-wrapper">
        <div className="risk-flex">
          <div className="title-and-button">
            <div className="risk-severity">
              {risk.severity && (
                <SeverityIcon severity={SeverityAsString(risk.severity)} />
              )}
              <h2>{risk.title}</h2>
            </div>
            {titleButtonsContent ? (
              <div className="button-bar">{titleButtonsContent}</div>
            ) : (
              <></>
            )}
          </div>
          <ScrollableDiv newStyles>
            {leadingRows &&
              leadingRows.map((s, _) => (
                <CheckItemSlidePanelSection
                  key={s.title}
                  title={s.title}
                  startExpanded={s.startExpanded}
                >
                  {s.children}
                </CheckItemSlidePanelSection>
              ))}
            <CheckItemSlidePanelSection title="Summary" startExpanded={true}>
              <RiskDescription risk={risk} />
            </CheckItemSlidePanelSection>
            {risk.riskDetails ? (
              <CheckItemSlidePanelSection
                title="Risk details"
                startExpanded={false}
              >
                <p>{risk.riskDetails}</p>
              </CheckItemSlidePanelSection>
            ) : (
              <></>
            )}
            {risk.recommendedRemediation ? (
              <CheckItemSlidePanelSection
                title="Recommended remediation"
                startExpanded={false}
              >
                {risk.riskType === VendorSummaryRiskType.SaaS ? (
                  <p
                    className={"saas-risk-remediation"}
                    dangerouslySetInnerHTML={{
                      __html: risk.recommendedRemediation,
                    }}
                  />
                ) : (
                  <p>{risk.recommendedRemediation}</p>
                )}
              </CheckItemSlidePanelSection>
            ) : (
              <></>
            )}
            {trailingRows &&
              trailingRows.map((s, _) => (
                <CheckItemSlidePanelSection
                  key={s.title}
                  title={s.title}
                  startExpanded={s.startExpanded}
                >
                  {s.children}
                </CheckItemSlidePanelSection>
              ))}
          </ScrollableDiv>
        </div>
      </div>
    </div>
  );
};

export default RiskPanel;

export const CalculateRiskNumberOfOccurrences = (
  risk: VendorSummaryRisk | undefined,
  includeWaived: boolean,
  waivedAssets?: WaivedAssets
): number => {
  if (!risk) {
    return 0;
  }

  if (
    !risk.passed &&
    (risk.riskType === RiskProfileRiskTypes.Survey ||
      risk.id === "questionnaires_incomplete")
  ) {
    return (risk.surveys?.filter((s) => includeWaived || !s.isWaived) || [])
      .length;
  }
  if (!risk.passed && risk.riskType === RiskProfileRiskTypes.Cloudscan) {
    if (risk.failedCloudscans) {
      // We have the full domain info so we need to work out which of the current domains have been waived
      let numFailed = 0;

      risk.failedCloudscans.forEach((w) => {
        if (
          includeWaived ||
          waivedAssets?.assetsWaived[w.hostname] === undefined
        ) {
          numFailed = numFailed + 1;
        }
      });

      return numFailed;
    } else {
      // We don't have the full domain info so just return numFailed (best effort)
      return risk.numFailedCloudscans;
    }
  } else if (!risk.passed && risk.riskType === RiskProfileRiskTypes.Evidence) {
    return (risk.additionalEvidences || []).length;
  } else if (!risk.passed && risk.riskType === RiskProfileRiskTypes.SaaS) {
    return (
      risk.saasUsers?.filter(
        (u) => includeWaived || waivedAssets?.assetsWaived[u.uuid] === undefined
      ) || []
    ).length;
  } else if (
    !risk.passed &&
    risk.riskType === RiskProfileRiskTypes.AppguardPackageVuln
  ) {
    return (
      risk.manifests?.filter(
        (m) =>
          includeWaived ||
          waivedAssets?.assetsWaived[
            `${getRepoManifestLocation(m.repoName, m.manifestLocation)}`
          ] === undefined
      ) || []
    ).length;
  } else if (
    !risk.passed &&
    risk.riskType === RiskProfileRiskTypes.AppguardRepoConfig
  ) {
    return (
      risk.repositories?.filter(
        (r) =>
          includeWaived || waivedAssets?.assetsWaived[r.repoName] === undefined
      ) || []
    ).length;
  }
  return -1;
};

export const CalculatedAssetsAffectedHasOccurrences = (
  r: VendorSummaryRisk
): boolean => {
  if (
    (r.riskType === RiskProfileRiskTypes.Survey ||
      r.id === "questionnaires_incomplete") &&
    !r.passed
  ) {
    return true;
  }

  if (
    (r.riskType === RiskProfileRiskTypes.Survey ||
      r.id === "questionnaires_incomplete") &&
    r.passed
  ) {
    return false;
  }

  if (r.riskType === RiskProfileRiskTypes.Cloudscan && !r.passed) {
    return true;
  }

  if (r.riskType === RiskProfileRiskTypes.Cloudscan && r.passed) {
    return false;
  }

  if (r.riskType === RiskProfileRiskTypes.Evidence) {
    return true;
  }

  if (r.riskType === RiskProfileRiskTypes.SaaS && !r.passed) {
    return true;
  }

  if (r.riskType === RiskProfileRiskTypes.SaaS && r.passed) {
    return false;
  }

  if (r.riskType === RiskProfileRiskTypes.AppguardPackageVuln) {
    return true;
  }

  if (r.riskType === RiskProfileRiskTypes.AppguardRepoConfig && !r.passed) {
    return true;
  }

  if (r.riskType === RiskProfileRiskTypes.AppguardRepoConfig && r.passed) {
    return false;
  }

  if (r.riskType === RiskProfileRiskTypes.ThreatMonitoring) {
    return true;
  }

  return false;
};

import { Severity, SeverityInt } from "../../_common/types/severity";
import {
  BreachDataClass,
  BreachSource,
  BreachType,
} from "../../_common/types/emailExposures";
import { IScore } from "../../_common/types/score";

export * from "./types_events_user";

export interface SaaSUser {
  name: string;
  email: string;
  firstDetected?: string;
  teams: string[];
  roles: string[];
  uuid: string;
}

export interface UserWithStats {
  name: string;
  email: string;
  teams: string[] | null;
  roles: string[] | null;
  uuid: string;
  rating: number;
  adjustedRating: number;
  trend: number;
  totalApps: number;
  notApprovedApps: number;
  waivedApps: number;
}

export interface Scope {
  name: string;
  description: string;
  riskLevel: ScopeRiskLevel;
  externalID: string;
  // Using explicit null over optional (category?: string) here as API always returns these fields
  category: string | null;
  isReadWrite: boolean | null;
}

export interface AppUser {
  uuid: string;
  name: string;
  email: string;
  teams: string[];
  roles: string[];
  rating: number;
  adjustedRating: number;
  approved: boolean;
  waived: boolean;
  highestRiskLevel: ScopeRiskLevel;
  firstDetected: string;
}

export interface App {
  orgID: number;
  name: string;
  description: string;
  summary: string;
  category: string;
  domain?: string;

  status: AppStatus;
  approvedForAll: boolean;
  approvedForTeams: Team[];
  approvedForRoles: Role[];
  numCurrentUsers: number;
  numUnapprovedUsers: number;
  numWaivedUsers: number;
  highestScopeRiskLevel?: ScopeRiskLevel;
  note?: string;
}

export interface AppWithVendor extends App {
  vendorName: string;
  vendorId: number | null;
  securityScore: number;
  isVendorMonitored: boolean;
  isUsingAI: boolean;
}

export interface AppForEdit {
  name: string;
  description: string;
  summary: string;
  category: string;
  domain: string | null;
  reviewedByUpGuard: boolean;
}

export enum AppStatus {
  ApprovedAppStatus = "approved",
  NeedsReviewAppStatus = "needs_review",
  NotApprovedAppStatus = "not_approved",
  InReviewAppStatus = "in_review",
}

export interface AppStatusWithCount {
  appStatus: AppStatus;
  count: number;
}

export enum ScopeRiskLevel {
  LowScopeRiskLevel = 1,
  MediumLowScopeRiskLevel = 2,
  MediumScopeRiskLevel = 3,
  MediumHighScopeRiskLevel = 4,
  HighScopeRiskLevel = 5,
}

export interface Team {
  id: number;
  name: string;
  userCount: number;
}

export interface Role {
  id: number;
  name: string;
  userCount: number;
}

export enum AppEventType {
  ReviewedAppUpdateEvent = "reviewed_app_update",
  AppFirstDetectedEvent = "app_first_detected",
}

interface BaseAppEvent {
  at: string;
  userName?: string;
  userEmail?: string;
}

interface ReviewedAppUpdateEvent extends BaseAppEvent {
  eventType: AppEventType.ReviewedAppUpdateEvent;
  metadata: ReviewedAppUpdateMetadata;
}

export interface ReviewedAppUpdateMetadata {
  status: AppStatus;
  note?: string;
  approvedForAll?: boolean;
  approvedTeams?: string[];
  approvedRoles?: string[];
}

interface AppFirstDetectedEvent extends BaseAppEvent {
  eventType: AppEventType.AppFirstDetectedEvent;
  metadata: null;
}

export type AppEvent = ReviewedAppUpdateEvent | AppFirstDetectedEvent;

export interface RiskBreakdown {
  numRisks: number;
  severity: Severity;
  severityNum: SeverityInt;
}

export interface AppScope extends Scope {
  numUsers: number;
}

export interface UserScopeApp extends Scope {
  appNames: string[];
}
export interface AppScopeUser {
  uuid: string;
  name: string;
  email: string;
  roles: string[];
  teams: string[];

  rating: number;
  adjustedRating: number;
  approved: boolean;
  waived: boolean;
}

export interface UserSummary {
  name: string;
  email: string;
  teams: string[];
  roles: string[];
  uuid: string;
  rating: number;
  adjustedRating: number;

  scores: IScore[];
  adjustedScores: IScore[];
  categoryScores: Record<string, IScore[]>;
  adjustedCategoryScores: Record<string, IScore[]>;
}

export interface UserApp {
  name: string;
  description: string;
  domain?: string;
  industry?: string;
  highestScopeRiskLevel: ScopeRiskLevel;
  firstDetected: string;
  securityScore: number;
  userIsApproved: boolean;
  userIsWaived: boolean;
}

export interface IdentityBreach {
  id: number;
  title: string;
  name: string;
  domain: string;
  breachDate?: string;
  dataClasses?: BreachDataClass[];
  exposuresCount: number;
  riskScore: number;
  description: string;
  isBreach: boolean;
  source: BreachSource;
  publishedDate: string;
  orgCount: number;
  archived: boolean;
  breachType: BreachType;
  isDarkWebBreach: boolean;
  pasteURL?: string;
}
export interface DashboardStats {
  numUsers: number;
  numApps: number;
  numPermissions: number;
  appStatusCounts: AppStatusWithCount[];
}

export interface OrgScope extends Scope {
  userUUIDs: string[];
}

export enum UserDirectoryType {
  Google = "Google",
  Microsoft365 = "Microsoft365",
}
export interface UserDirectoryStats {
  origin: UserDirectoryType;

  numUsersInDir: number;
  numUsersInDirWithoutEmail: number;
  numAdminApprovedApps: number;
}

export interface UnmonitoredUser {
  uuid: string;
  name: string;
  email: string;
}

export enum ScanStatus {
  None = "none",
  Queued = "queued",
  Started = "started",
  Completed = "completed",
  Errored = "errored",
}

export interface UsersFilter {
  userUUIDs?: string[];
  excludeUsers?: boolean;
  appNames?: string[];
  excludeApps?: boolean;
  teams?: string[];
  excludeTeams?: boolean;
  roles?: string[];
  excludeRoles?: boolean;
  minScore?: number;
  maxScore?: number;
}

export interface AppsFilter {
  appNames?: string[];
  excludeApps?: boolean;
  statuses?: AppStatus[];
  excludeStatuses?: boolean;
  approvedTeams?: string[];
  excludeApprovedTeams?: boolean;
  approvedRoles?: string[];
  excludeApprovedRoles?: boolean;
  permissions?: string[];
  excludePermissions?: boolean;
  minRiskLevel?: ScopeRiskLevel;
  maxRiskLevel?: ScopeRiskLevel;
  minFirstDetected?: string;
  maxFirstDetected?: string;
  minScore?: number;
  maxScore?: number;
}

export interface ScopesFilter {
  minRiskLevel?: ScopeRiskLevel;
  maxRiskLevel?: ScopeRiskLevel;
  readOnly?: boolean;
  readWrite?: boolean;
  categories?: string[];
  excludeCategories?: boolean;
}

export enum MonitoringRuleType {
  EmailPrefixRuleType = "prefix",
  EmailSuffixRuleType = "suffix",
  EmailContainsRuleType = "contains",
  EmailMatchesRuleType = "matches",
}

export interface EmailPrefixRule {
  type: MonitoringRuleType.EmailPrefixRuleType;
  rule: { value: string };
}

export interface EmailSuffixRule {
  type: MonitoringRuleType.EmailSuffixRuleType;
  rule: { value: string };
}

export interface EmailContainsRule {
  type: MonitoringRuleType.EmailContainsRuleType;
  rule: { value: string };
}

export interface EmailMatchesRule {
  type: MonitoringRuleType.EmailMatchesRuleType;
  rule: { value: string };
}

export type Rule =
  | EmailPrefixRule
  | EmailSuffixRule
  | EmailContainsRule
  | EmailMatchesRule;

import { FC } from "react";

import FilledIcon from "../../../_common/components/core/FilledIcon";
import { useHistory } from "react-router-dom";
import { vendorSummaryUrl } from "../../UserBaseAppRouter";
import Button from "../../../_common/components/core/Button";
import "./AppVendorMonitored.scss";

export interface AppVendorMonitoredProps {
  isMonitored: boolean;
  showLink: boolean;
  vendorId?: number | null;
}
const AppVendorMonitored: FC<AppVendorMonitoredProps> = ({
  vendorId,
  isMonitored,
  showLink,
}) => {
  const history = useHistory();

  const onClick = () => {
    if (vendorId) {
      history.push(vendorSummaryUrl(vendorId), {
        backContext: {
          backTo: history.location.pathname,
          backToText: `Back to application`,
        },
      });
    }
  };

  return (
    <div className="app-vendor-monitored">
      {/* Show an indicator */}
      {isMonitored ? (
        <div className="monitored">
          <FilledIcon iconClass="cr-icon-check" />
        </div>
      ) : (
        <div className="not-monitored">
          <FilledIcon iconClass="icon-x" />
        </div>
      )}

      {/* Optionally show a link to the vendor summary (if monitored) */}
      {isMonitored && vendorId && showLink && (
        <div className={"vendor-link"}>
          <Button tertiary onClick={onClick}>
            View vendor summary <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AppVendorMonitored;

import classnames from "classnames";
import ScoreIconUnknown from "../../../_common/images/score-icon-unknown.svg";
import ScoreIconLow from "../../../_common/images/score-icon-low.svg";
import ScoreIconAverage from "../../../_common/images/score-icon-average.svg";
import ScoreIconFair from "../../../_common/images/score-icon-fair.svg";
import ScoreIconGood from "../../../_common/images/score-icon-good.svg";
import ScoreIconExcellent from "../../../_common/images/score-icon-excellent.svg";
import ScoreIconNA from "../../../_common/images/score-icon-na.svg";
import "../../style/components/executive_summary/ColorGrade.scss";

export enum ColorGradeSize {
  Small = "small",
  Medium = "medium",
}

export interface IColorGradeProps {
  score?: number;
  size: ColorGradeSize;
  useNaIcon?: boolean;
}

const ColorGrade = (props: IColorGradeProps) => {
  const { score, size } = props;
  let alt;
  let svg;
  if (score === -1 && props.useNaIcon) {
    alt = "n/a";
    svg = ScoreIconNA;
  } else if (score === null || score === undefined || score < 0) {
    alt = "?";
    svg = ScoreIconUnknown;
  } else if (score < 201) {
    alt = "F";
    svg = ScoreIconLow;
  } else if (score < 401) {
    alt = "D";
    svg = ScoreIconAverage;
  } else if (score < 601) {
    alt = "C";
    svg = ScoreIconFair;
  } else if (score < 801) {
    alt = "B";
    svg = ScoreIconGood;
  } else {
    alt = "A";
    svg = ScoreIconExcellent;
  }

  return (
    <img className={classnames("color-grade", size)} src={svg} alt={alt} />
  );
};

ColorGrade.defaultProps = {
  size: ColorGradeSize.Medium,
};

export default ColorGrade;

import { FC } from "react";
import PillLabel from "../PillLabel";
import "../../style/components/shared_profile/SharedProfileAccessControlPill.scss";

interface SharedProfileAccessControlPillProps {
  accessProtection: boolean;
  requireNda: boolean;
}

const SharedProfileAccessControlPill: FC<
  SharedProfileAccessControlPillProps
> = ({ accessProtection, requireNda }) => {
  if (!accessProtection && !requireNda) {
    return <></>;
  }

  let labelText = "";
  let labelTooltip = "";
  if (accessProtection && requireNda) {
    labelText = "NDA + Access Protection";
    labelTooltip =
      "Customers and organizations must request access and accept the terms of your NDA (non-disclosure agreement) before they can view the documents and questionnaires in your Trust Page.";
  } else if (accessProtection) {
    labelText = "Access Protection";
    labelTooltip =
      "Customers and organizations must request access before they can view the documents and questionnaires in your Trust Page.";
  } else if (requireNda) {
    labelText = "NDA Protection";
    labelTooltip =
      "Customers and organizations must accept the terms of your NDA (non-disclosure agreement) before accessing the documents in your Trust Page.";
  }

  return (
    <PillLabel
      className={"shared-profile-access-control-pill"}
      popupClassName={"shared-profile-access-control-pill-popup"}
      popupContent={labelTooltip}
      popupPosition={"bottom"}
    >
      {labelText}
      <i className={"cr-icon-lock"} />
    </PillLabel>
  );
};

export default SharedProfileAccessControlPill;

import { IVendor } from "./vendor";
import { ISurveyListItemResponse } from "../../_common/types/survey";
import { IUserMiniMap } from "../../_common/types/user";
import { Evidence } from "./evidence";
import { WaiverType } from "../../_common/types/vendorRiskWaivers";
import { Severity } from "../../_common/types/severity";
import { ContentLibraryDocumentRedacted } from "../../contentlibrary/types/contentLibrary.types";

export enum SharedAssessmentAccessResult {
  Error = "error",
  NotPublished = "not_published",
  MustRequest = "must_request",
  PendingRequest = "pending_request",
  Success = "success",
  NdaRequired = "nda_required",
}

export interface ISharedAssessmentAddToProfileSurvey {
  surveyId: number;
  name: string;
  baseName: string;
  description?: string;
}

export enum ProfileLogo {
  DefaultLogo = "default_logo",
  NoLogo = "no_logo",
  CustomLogo = "custom_logo",
}

export interface ISharedAssessment {
  status: SharedAssessmentAccessResult;
  accessExpires?: string;
  currentScore: number;
  currentPublicScore?: number;
  industryAverage?: {
    industrySector: string;
    industryGroup: string;
    score: number;
  };
  linkedVendor: IVendor;
  questionnaires: ISurveyListItemResponse[];
  addToSharedProfileQuestionnaires?: ISharedAssessmentAddToProfileSurvey[];
  contentLibraryDocuments: ContentLibraryDocumentRedacted[];
  includeRiskRating: boolean;
  includeIndustryAverage: boolean;
  contactName: string;
  contactEmail: string;
  companyDescription: string;
  canReRequestAccess: boolean;
  profileLogo: ProfileLogo;
  profileLogoUrl?: string;
  vendorIsMonitored: boolean;
  userHasAccessToWatchedVendor?: boolean;
  ndaText?: string;
  ndaId?: number;
  signedNdas?: IVerifiedVendorAccessItemNda[];
  orgLevelStatus?: SharedAssessmentAccessResult;
  evidencePages?: Evidence[];
}

export interface ISharedAssessmentAccess {
  uuid?: string;
  organisationId: number;
  datastoreVendorId: number;
  vendorName: string;
  contactName: string;
  contactEmail: string;
  companyDescription: string;
  published: boolean;
  publicAccess: boolean;
  requireNda: boolean;
  ndaText?: string;
  ndaId?: number;

  // These are user-specific access flags
  accessGranted: boolean;
  accessRequested: boolean;
}

export interface IVerifiedVendorAccessItem {
  accessId: number;
  userId?: number;
  orgId?: number;
  expires?: string;
  revokedAt?: string;
  createdAt: string;
  updatedAt: string;
  ndaExempt: boolean;
  ndaManuallyApproved: boolean;
  signedNdasForUser?: IVerifiedVendorAccessItemNda[];
}

export interface IVerifiedVendorAccessItemNda {
  id: number;
  signedAt: string;
  revokedAt?: string;
}

export interface IVerifiedVendorAccessRequest {
  id: number;
  userId?: number;
  orgId?: number;
  message?: string;
  rejectedAt?: string;
  createdAt: string;
  updatedAt: string;
  signedNdasForUserOrOrg?: IVerifiedVendorAccessItemNda[];
}

export interface IOrgNameAndDomain {
  name: string;
  primaryHostname: string;
}

export interface IMySharedAssessmentAccess {
  access: ISharedAssessmentAccess;
  hasAnythingPublished: boolean;
  usersById: IUserMiniMap;
  orgsById: Record<number, IOrgNameAndDomain>;
  orgNamesById: Record<number, string>; // Deprecated
  usersAndOrgsWithAccess: IVerifiedVendorAccessItem[];
  accessRequests: IVerifiedVendorAccessRequest[];
  invitedEmails: string[];
}

export interface IMySharedAssessmentAccessLogItem {
  userId: number;
  createdAt: string;
}

export interface IMySharedAssessmentAccessLog {
  accessLog: IMySharedAssessmentAccessLogItem[];
  usersById: IUserMiniMap;
  more: boolean;
}

export interface IOrganisationWithSharedAssetsForVendor {
  isSharedProfile: boolean;
  orgId: number;
  orgName: string;
  orgPrimaryHostname: string;
  requested: boolean;
  granted: boolean;
  rejected: boolean;
  riskAssessmentDraft: boolean;
  riskAssessmentPublished: boolean;
  numQuestionnaires: number;
  numAdditionalEvidence: number;
}

export enum PublicWaiverAcceptStatus {
  Pending,
  Accepted,
  Ignored,
  Shared = 3,
}

export interface PublicRiskWaiver {
  kind: "publicRiskWaiver";
  id: number;
  riskId: string;
  domains: string[];
  justification: string;
  createdAt: string;
  expiresAt?: string;
  status: PublicWaiverAcceptStatus;
  riskName: string;
  riskText: string;
  riskSummary: string;
  riskDetails: string;
  riskRecommendedRemediation: string;
  riskCategory: string;
  riskCategoryText: string;
  riskSeverity: number;
  acceptedVendorRiskWaiverId?: number;

  adjustedSeverity?: Severity; // NOTE: unused currently
  waiverType?: WaiverType; // NOTE: unused currently
}

import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { useDefaultHistory } from "../../_common/types/router";
import SurveyImportAPI from "../api/surveyImportAPI";
import {
  ExcelConfigReducer,
  ExcelConfigStateInitial,
  excelConfigValidationError,
  ExcelExtractorConfigs,
} from "../../_common/components/types/excelConfig.types";
import { LogError } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import Button, { TooltipButton } from "../../_common/components/core/Button";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import { ConfigureDocumentStep } from "../../_common/components/modals/ConfigureXLSQuestionnaireModal";
import { FC, useCallback, useEffect, useReducer, useState } from "react";
import { updateVendorSurveyAddToSharedProfile } from "../../vendorrisk/reducers/cyberRiskActions";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../verifiedvendors/api/trustpage.api";

interface IImportQuestionnaireModalConfigureStepProps extends BaseModalProps {
  surveyImportUUID?: string;
  surveyName?: string;
  fromCompany?: string;
  dueDate?: string;
  includeToTrustPage?: boolean;
}

const ImportQuestionnaireModalConfigureStep: FC<
  IImportQuestionnaireModalConfigureStepProps
> = ({
  active,
  onClose,
  surveyImportUUID,
  surveyName,
  fromCompany,
  dueDate,
  includeToTrustPage = false,
}) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory();
  const [msgDismissed, setMsgDismissed] = useState(false);

  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined
  );
  const { data } = SurveyImportAPI.useGetImportedSurveyStatusQuery(
    {
      uuid: surveyImportUUID ?? "",
    },
    {
      skip: !surveyImportUUID,
      pollingInterval,
    }
  );

  const autoDetectJobInProgress =
    data &&
    data.autodetectColumnsJobValid &&
    !data.autodetectColumnsJobFailed &&
    data.autodetectColumnsJobProgress < 1;

  useEffect(() => {
    // Keep polling the job status while it's still in progress
    if (autoDetectJobInProgress) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(undefined);
    }
  }, [autoDetectJobInProgress]);

  const [configState, configReducer] = useReducer(
    ExcelConfigReducer,
    ExcelConfigStateInitial
  );
  useEffect(() => {
    if (data && !autoDetectJobInProgress) {
      // Once we have fully loaded data, we can initialize the config state
      configReducer({
        type: "FROM_STATE",
        state: data.excelConfig.reduce((prev: ExcelExtractorConfigs, next) => {
          prev[next.sheetName] = next;
          return prev;
        }, {}),
      });
    }
  }, [data, autoDetectJobInProgress]);

  const validationError = excelConfigValidationError(configState, true);

  const [createImportedSurveyStep2] =
    SurveyImportAPI.useCreateImportedSurveyStep2Mutation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    if (validationError) {
      return;
    }

    try {
      setSubmitLoading(true);
      const { surveyID } = await createImportedSurveyStep2({
        surveyImportUUID: surveyImportUUID ?? "",
        surveyName: surveyName ?? "",
        fromCompany: fromCompany ?? "",
        dueDate: dueDate || undefined,
        documentConfig: Object.values(configState.sheets),
      }).unwrap();
      if (includeToTrustPage) {
        // when creation of imported survey is successful, mark that imported survey to be added to Trust Page upon completion
        // this action is called from manageTrustPage - so invalidate own trust page tag
        await dispatch(
          updateVendorSurveyAddToSharedProfile(surveyID, true)
        ).then(() =>
          dispatch(
            TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
          )
        );
        // navigate user to start answering the questionnaire
        history.push(`/vendors/surveys/${surveyID}/answers?editable=true`, {
          backContext: {
            backToText: "Imported Questionnaire details",
            backTo: `/vendors/surveys/${surveyID}`,
          },
        });
      } else {
        history.push(`/vendors/surveys/${surveyID}`);
      }
    } catch (e) {
      setSubmitLoading(false);
      LogError("error importing questionnaire", e);
      dispatch(addDefaultUnknownErrorAlert("Error importing questionnaire"));
    }
  }, [
    validationError,
    surveyImportUUID,
    surveyName,
    fromCompany,
    dueDate,
    configState,
    createImportedSurveyStep2,
    dispatch,
    history,
  ]);

  const closeWithConfirmation = () => {
    if (
      window.confirm(
        "Are you sure you want to leave? Your progress will be lost."
      )
    ) {
      onClose();
    }
  };

  if (!data || autoDetectJobInProgress) {
    return (
      <ModalV2
        active={active}
        disallowClose
        className="import-questionnaire-modal-configure-loading"
      >
        <div>
          <h4>Importing questionnaire</h4>
          <LoadingBanner tight />
          <div className="loading-sub">
            Sit tight while we process your questionnaire - it won&apos;t take
            long.
          </div>
        </div>
      </ModalV2>
    );
  }

  return (
    <ModalV2
      active={active}
      onClose={closeWithConfirmation}
      headerClassName="import-questionnaire-modal-configure-header"
      className="import-questionnaire-modal-configure"
      headerContent="Edit Q&A columns"
      subHeaderContent="Specify which columns contain questions, requirements, and answer options based on the sheet preview."
      footerContent={
        <>
          <Button tertiary onClick={closeWithConfirmation}>
            Cancel
          </Button>
          <TooltipButton
            tooltipContent={validationError}
            disabled={!!validationError}
            loading={submitLoading}
            primary
            onClick={onSubmit}
          >
            Set Q&A columns
          </TooltipButton>
        </>
      }
    >
      {data?.autodetectColumnsJobValid &&
      data.autodetectColumnsJobProgress === 1 &&
      !msgDismissed ? (
        <InfoBanner
          type={BannerType.SUCCESS}
          onDismiss={() => setMsgDismissed(true)}
          message="We were able to automatically process your questionnaire"
          subItems={[
            "Please review the selection of sheets and columns to include, and rows to exclude.",
          ]}
        />
      ) : data?.autodetectColumnsJobFailed && !msgDismissed ? (
        <InfoBanner
          type={BannerType.INFO}
          onDismiss={() => setMsgDismissed(true)}
          message="We were unable to automatically process your questionnaire"
          subItems={[
            "Specify which sheets and columns to include and which rows to exclude.",
          ]}
        />
      ) : undefined}
      <ConfigureDocumentStep
        canSetInvalidRows
        canSetQuestionNumberColumn
        canSetHeadingCells
        singleQuestionAnswerColumns
        configState={configState}
        configReducer={configReducer}
      />
    </ModalV2>
  );
};

export default ImportQuestionnaireModalConfigureStep;

import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FC, useEffect, useState } from "react";
import { DefaultRouteProps } from "../../_common/types/router";
import ReportCard from "../../_common/components/ReportCard";
import ToggleWithLabel from "../components/ToggleWithLabel";
import PageHeader from "../../_common/components/PageHeader";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { updateSharedAssessmentSharingSettings } from "../reducers/verifiedVendors.actions";
import Button, { TooltipButton } from "../../_common/components/core/Button";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NdaSigningModal from "../components/shared_profile/NdaSigningModal";
import { UserWritePrefilledSurveys } from "../../_common/permissions";
import "../style/views/MySharedProfileSettings.scss";
import { getCurrentOrgFromUserData } from "../../_common/helpers";
import RichTextEditV2 from "../../_common/components/RichTextEditV2";
import { appConnect } from "../../_common/types/reduxHooks";
import trustpageApi from "../../verifiedvendors/api/trustpage.api";
import TrustPageCustomDomainsCard from "../../verifiedvendors/components/trust_page_custom_domains/TrustPageCustomDomainsCard";

type MySharedProfileSettingsOwnProps = DefaultRouteProps;

interface MySharedProfileSettingsConnectedProps {
  orgName: string;
  userHasWritePrefilledSurveysPermission: boolean;
}

type MySharedProfileSettingsProps = MySharedProfileSettingsOwnProps &
  MySharedProfileSettingsConnectedProps &
  DefaultThunkDispatchProp;

const MySharedProfileSettings: FC<MySharedProfileSettingsProps> = ({
  dispatch,
  history,
  location,
  orgName,
  userHasWritePrefilledSurveysPermission,
}) => {
  const { data: sharedAssessmentAccess } =
    trustpageApi.useGetOwnSharedAssessmentAccessV1Query();

  const [isSaving, setIsSaving] = useState(false);

  const [accessProtection, setAccessProtection] = useState(
    !sharedAssessmentAccess?.access.publicAccess
  );
  const [requireNda, setRequireNda] = useState(
    sharedAssessmentAccess?.access.requireNda ?? false
  );
  const [ndaText, setNdaText] = useState(
    sharedAssessmentAccess?.access.ndaText ?? ""
  );
  const [showNdaPreviewModal, setShowNdaPreviewModal] = useState(false);

  // On receiving new assessment access, update the UI
  useEffect(() => {
    setAccessProtection(sharedAssessmentAccess?.access.publicAccess === false);
    setRequireNda(sharedAssessmentAccess?.access.requireNda ?? false);
    setNdaText(sharedAssessmentAccess?.access.ndaText ?? "");
  }, [sharedAssessmentAccess]);

  const saveSettings = (publishedValue: boolean, goBack: boolean) => {
    setIsSaving(true);

    dispatch(
      updateSharedAssessmentSharingSettings(
        publishedValue,
        !accessProtection,
        requireNda,
        ndaText
      )
    )
      .then(() => {
        dispatch(addDefaultSuccessAlert("Trust Page settings updated"));
        if (goBack) {
          navigateBack();
        }
        setIsSaving(false);
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert("Error updating Trust Page settings")
        );
        setIsSaving(false);
      });
  };

  const navigateBack = location.state?.backContext?.goBack
    ? history.goBack
    : () =>
        history.push(location.state?.backContext?.backTo ?? "/trustpage", {
          noRemoveWhispers: true,
        });

  const saveButtonTooltip =
    requireNda && ndaText.replace(/(^[\s\u200b]$|[\s\u200b]$)/, "").length === 0
      ? "NDA terms must be provided if you’ve chosen this as an option"
      : undefined;

  const isNotChanged =
    !sharedAssessmentAccess ||
    (accessProtection === !sharedAssessmentAccess.access.publicAccess &&
      requireNda === sharedAssessmentAccess.access.requireNda &&
      ndaText === sharedAssessmentAccess.access.ndaText) ||
    saveButtonTooltip !== undefined ||
    !userHasWritePrefilledSurveysPermission;

  return (
    <div className={"my-shared-profile-settings"}>
      <PageHeader
        title={"Trust Page Settings"}
        history={history}
        backText={location.state?.backContext?.backToText}
        backAction={navigateBack}
      />
      {sharedAssessmentAccess === undefined && <LoadingBanner />}
      {sharedAssessmentAccess !== undefined && (
        <>
          <ReportCard newStyles>
            <div className={"header"}>
              <div>Publish Trust Page</div>
              <div>
                <ToggleWithLabel
                  name={"tgl-shared-profile"}
                  onClick={() => {
                    saveSettings(
                      !sharedAssessmentAccess.access.published,
                      false
                    );
                  }}
                  selected={sharedAssessmentAccess.access.published}
                  label={sharedAssessmentAccess.access.published ? "ON" : "OFF"}
                  disabled={isSaving}
                />
              </div>
            </div>
            <div className={"report-card-content"}>
              <p>
                If toggled on, your Trust Page will be visible to other users.
              </p>
            </div>
          </ReportCard>
          <TrustPageCustomDomainsCard />
          <ReportCard newStyles>
            <div className={"header"}>
              <div>Protection</div>
            </div>
            <div className={"report-card-content"}>
              <div className={"report-card-section"}>
                <ToggleWithLabel
                  name={"tgl-access-protection"}
                  onClick={() => setAccessProtection(!accessProtection)}
                  selected={accessProtection}
                  label={"Access protection"}
                  disabled={isSaving}
                />
                <p>
                  If toggled on, customers and organizations must request access
                  before they can view the documents and questionnaires in your
                  Trust Page. Access requests can be managed in the access tab.
                </p>
              </div>
              <div className={"report-card-section"}>
                <ToggleWithLabel
                  name={"tgl-nda"}
                  onClick={() => setRequireNda(!requireNda)}
                  selected={requireNda}
                  label={"NDA protection"}
                  disabled={isSaving}
                />
                <p>
                  If toggled on, all customers and organizations must accept the
                  terms of your NDA (non-disclosure agreement) before accessing
                  the documents in your Trust Page.
                </p>
                <TransitionGroup component={null}>
                  {requireNda && (
                    <CSSTransition classNames={"expand"} timeout={250}>
                      <div>
                        <div className={"nda-preview-button-container"}>
                          <div>
                            <p>
                              Add your NDA terms below. This will be presented
                              to a representative of each organization who views
                              your Trust Page. These terms are between you and
                              the organizations who wish to access documents
                              within your Trust Page. *
                            </p>
                            <p className={"qualifier"}>
                              * UpGuard is not a party to this agreement. By
                              using this NDA, you agree to indemnify UpGuard
                              against all claims, loss, damages, expense, and
                              liability (&quot;losses&quot;) that may arise out
                              of this NDA.
                            </p>
                          </div>
                          <Button
                            onClick={() => setShowNdaPreviewModal(true)}
                            disabled={
                              ndaText.replace(/(^[\s\u200b]$|[\s\u200b]$)/, "")
                                .length === 0
                            }
                          >
                            <i className={"cr-icon-eye"} />
                            Preview
                          </Button>
                        </div>
                        <RichTextEditV2
                          placeholder={"Add your NDA wording here…"}
                          value={ndaText}
                          onChange={(v) => setNdaText(v)}
                          disabled={isSaving}
                        />
                      </div>
                    </CSSTransition>
                  )}
                </TransitionGroup>
              </div>
              <div className={"report-card-section controls"}>
                <Button
                  tertiary
                  onClick={navigateBack}
                  disabled={isNotChanged || isSaving}
                >
                  Cancel
                </Button>
                <TooltipButton
                  primary
                  onClick={() =>
                    saveSettings(sharedAssessmentAccess.access.published, true)
                  }
                  loading={isSaving}
                  disabled={isNotChanged}
                  tooltipContent={saveButtonTooltip}
                >
                  <i className={"cr-icon-check"} /> Save changes
                </TooltipButton>
              </div>
            </div>
          </ReportCard>
          <NdaSigningModal
            isPreview
            vendorName={orgName}
            ndaText={ndaText}
            dispatch={dispatch}
            active={showNdaPreviewModal}
            onClose={() => setShowNdaPreviewModal(false)}
          />
        </>
      )}
    </div>
  );
};

export default appConnect<
  MySharedProfileSettingsConnectedProps,
  never,
  MySharedProfileSettingsOwnProps
>((state: DefaultRootState) => {
  const currentOrg = getCurrentOrgFromUserData(state.common.userData);

  const result: MySharedProfileSettingsConnectedProps = {
    orgName: currentOrg?.name ?? "",
    userHasWritePrefilledSurveysPermission:
      state.common.userData.userPermissions.includes(UserWritePrefilledSurveys),
  };

  return result;
})(MySharedProfileSettings);

import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { LogError } from "../../_common/helpers";
import { useCallback, useEffect, useState } from "react";
import { Location } from "history";
import {
  fetchPublicQuestionnaire,
  QuestionnaireAndAnswers,
} from "../reducers/questionnaireAnswers.actions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import SurveyViewer, {
  SurveyViewerType,
} from "../../survey_viewer/components/SurveyViewer";
import { SurveyLockState } from "../../survey_viewer/reducers/apiActions";
import { SurveyRiskVisibility } from "../../_common/types/survey";
import { appConnect } from "../../_common/types/reduxHooks";
import { GetQueryParams } from "../../_common/query";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../verifiedvendors/api/trustpage.api";

interface PublicQuestionnaireAnswersOwnProps
  extends DefaultRouteProps<{
    id: string;
  }> {
  location: Location<locationState>;
}

type PublicQuestionnaireAnswersProps = PublicQuestionnaireAnswersOwnProps &
  DefaultThunkDispatchProp;

const PublicQuestionnaireAnswers = (props: PublicQuestionnaireAnswersProps) => {
  const [questionnaire, setQuestionnaire] = useState<
    QuestionnaireAndAnswers | undefined
  >(undefined);
  const [lockState, setLockState] = useState<SurveyLockState | undefined>(
    undefined
  );

  const sId = parseInt(props.match.params.id, 10);
  const params = GetQueryParams(props.location.search);

  useEffect(() => {
    props
      .dispatch(fetchPublicQuestionnaire(sId, params.editable, params.version))
      .then((s) => {
        setQuestionnaire(s);
        setLockState({
          isLocked: s.locked,
          lockedBy: s.lockedBy,
        });
      })
      .catch((e) => {
        LogError(e);
        props.dispatch(
          addDefaultUnknownErrorAlert("Error loading questionnaire answers")
        );
      });
  }, []);

  const close = useCallback(() => {
    //Re-fetch shared assessment details
    if (params.editable) {
      props.dispatch(
        TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
      );

      props.history.push(
        props.location.state?.backContext?.backTo ??
          getDefaultBackTo(params.editable),
        {
          noRemoveWhispers: true,
        }
      );
    } else if (props.location.state?.backContext?.goBack) {
      props.history.goBack();
    } else {
      props.history.push(
        props.location.state?.backContext?.backTo ??
          getDefaultBackTo(params.editable),
        {
          noRemoveWhispers: true,
        }
      );
    }
  }, [props.dispatch, props.history, props.location]);

  return (
    <SurveyViewer
      viewerType={
        params.editable ? SurveyViewerType.Editing : SurveyViewerType.Viewing
      }
      surveyId={sId}
      initialAnswers={questionnaire?.answers}
      vsaqQuestionStructure={questionnaire?.structure[0]}
      onClose={close}
      isPublicSurvey={true}
      includeToc={questionnaire?.includeTOC ?? false}
      usageType={questionnaire?.usageType}
      hasRequiredAttachments={questionnaire?.hasRequiredAttachments}
      initialLockState={lockState}
      orgName={questionnaire?.issuingOrgName}
      initialQuestionId={params.scroll_to}
      hasCommentWritePermission={false}
      riskVisibility={SurveyRiskVisibility.Visible}
      surveyStatus={questionnaire?.surveyStatus}
      isSystemSurvey={questionnaire?.isSystemSurvey}
      isGapSurvey={questionnaire?.isGapSurvey}
    />
  );
};

// Best-effort return e.g. if user F5 refresh
const getDefaultBackTo = (isEditable: boolean) => {
  if (isEditable) {
    return `/trustpage`;
  } else {
    return `/sharedassessments`;
  }
};

export default appConnect()(PublicQuestionnaireAnswers);

import { useState } from "react";
import { Node, NodeType } from "../types/types";
import {
  getAllNodesMatchingTypes,
  getAllNodesMatchingTypesResult,
  getAnswerLetters,
  NodeSummary,
} from "../helpers";
import NodeTypeIcon, {
  nodeToNodeTypeIconType,
  NodeTypeIconType,
} from "./NodeTypeIcon";

import "../style/ConditionalVisibilityEditor.scss";
import Button from "../../_common/components/core/Button";
import {
  addConditionalExpression,
  deleteConditionalExpression,
  removeSurveyValidationErrorsForNode,
  updateConditionalExpression,
  updateNode,
} from "../reducers/actions";
import {
  Comparator,
  conditionalExpressionIsValid,
  Expression,
  getComparatorLabel,
  getOperatorLabel,
  Operator,
  SingleAnswerComparison,
} from "../types/conditionalLogic";
import { OptionType, SelectV2 } from "../../_common/components/SelectV2";
import classnames from "classnames";
import IconButton from "../../_common/components/IconButton";
import ModalV2 from "../../_common/components/ModalV2";
import { ValidationError } from "../types/validation";
import { useAppSelector } from "../../_common/types/reduxHooks";
import { AppDispatch } from "../../_common/types/reduxStore";

export interface IConditionalVisibilityEditorProps {
  dispatch: AppDispatch;
  surveyId: string;
  node: Node;
  questionNumber: string;
  closeModal: () => void;
}

// Adds a new blank expression to the top level. Includes some special logic for when
// the first condition is added.
export const addBlankConditionalExpressionToTopLevel = (
  dispatch: AppDispatch,
  surveyId: string,
  currentExpression: Expression[],
  nodeId: string
) => {
  const addAtIndex = [currentExpression.length];

  const newBlankExpr = [
    {
      nodeId: "",
      comparator: Comparator.Equals,
      answerId: "",
    },
  ];

  // If this is not the first expression to be added, we need to connect it with an operator. (AND by default).
  if (currentExpression.length > 0) {
    dispatch(
      addConditionalExpression(surveyId, nodeId, addAtIndex, [
        Operator.And,
        newBlankExpr,
      ])
    );
  } else {
    dispatch(
      addConditionalExpression(surveyId, nodeId, addAtIndex, [newBlankExpr])
    );
  }
};

// Adds a new blank expression to the second level, which will always include a new AND operator.
const addBlankConditionalExpressionToSecondLevel = (
  dispatch: AppDispatch,
  surveyId: string,
  nodeId: string,
  addAtIndex: number[]
) => {
  dispatch(
    addConditionalExpression(surveyId, nodeId, addAtIndex, [
      Operator.And,
      {
        nodeId: "",
        comparator: Comparator.Equals,
        answerId: "",
      },
    ])
  );
};

const comparatorSelectorOptions = [
  { value: Comparator.Equals, label: getComparatorLabel(Comparator.Equals) },
  {
    value: Comparator.NotEquals,
    label: getComparatorLabel(Comparator.NotEquals),
  },
  { value: Comparator.Visible, label: getComparatorLabel(Comparator.Visible) },
  {
    value: Comparator.NotVisible,
    label: getComparatorLabel(Comparator.NotVisible),
  },
];

const comparatorSelectorVisibilityOptsOnly = [
  { value: Comparator.Visible, label: getComparatorLabel(Comparator.Visible) },
  {
    value: Comparator.NotVisible,
    label: getComparatorLabel(Comparator.NotVisible),
  },
];

const ComparatorSelector = (props: {
  value: Comparator;
  visibilityOpsOnly: boolean;
  onChange: (newOp: Comparator) => void;
}) => {
  return (
    <SelectV2
      options={
        props.visibilityOpsOnly
          ? comparatorSelectorVisibilityOptsOnly
          : comparatorSelectorOptions
      }
      value={{ value: props.value, label: getComparatorLabel(props.value) }}
      onChange={(newVal) => {
        if (newVal && !Array.isArray(newVal)) {
          props.onChange((newVal as OptionType).value as Comparator);
        }
      }}
    />
  );
};

const operatorSelectorOptions = [
  { value: Operator.Or, label: getOperatorLabel(Operator.Or) },
  { value: Operator.And, label: getOperatorLabel(Operator.And) },
];

const OperatorSelector = (props: {
  value: Operator;
  onChange: (newOp: Operator) => void;
}) => {
  return (
    <SelectV2
      options={operatorSelectorOptions}
      value={{ value: props.value, label: getOperatorLabel(props.value) }}
      onChange={(newVal) => {
        if (newVal && !Array.isArray(newVal)) {
          props.onChange((newVal as OptionType).value as Operator);
        }
      }}
    />
  );
};

interface questionOptionValue {
  value: string;
  label: string;
  questionNumber: string;
  mainText: string;
  iconType: NodeTypeIconType;
}

const QuestionSelector = (props: {
  allSelectQuestions: NodeSummary[];
  selectedNodeId: string;
  onChange: (newNodeId: string, nodeIsSelectable: boolean) => void;
  excludeNodeId: string;
}) => {
  const options = [];
  let selectedValue = null;
  for (let i = 0; i < props.allSelectQuestions.length; i++) {
    if (props.allSelectQuestions[i].nodeId === props.excludeNodeId) {
      continue;
    }

    const optionValue: questionOptionValue = {
      value: props.allSelectQuestions[i].nodeId,
      label:
        props.allSelectQuestions[i].questionNumber +
        " " +
        props.allSelectQuestions[i].titleOrMainText,
      questionNumber: props.allSelectQuestions[i].questionNumber,
      mainText: props.allSelectQuestions[i].titleOrMainText,
      iconType: props.allSelectQuestions[i].nodeType,
    };

    options.push(optionValue);

    if (props.allSelectQuestions[i].nodeId === props.selectedNodeId) {
      selectedValue = optionValue;
    }
  }

  return (
    <SelectV2
      options={options}
      value={selectedValue}
      className="q-selector"
      placeholder="Select a question"
      formatOptionLabel={(opt, ctx) => {
        // Custom styling for the options to include question numbers and icons
        const selected =
          ctx.context === "menu" &&
          Array.isArray(ctx.selectValue) &&
          ctx.selectValue.length > 0 &&
          ctx.selectValue[0].value === opt.value;

        const questionOpt = opt as questionOptionValue;

        return (
          <div className={classnames("q-selector-opt", { selected })}>
            <NodeTypeIcon
              nodeType={questionOpt.iconType}
              questionNumber={questionOpt.questionNumber}
            />
            <div className="main-text">{questionOpt.mainText}</div>
          </div>
        );
      }}
      onChange={(newVal) => {
        if (newVal && !Array.isArray(newVal)) {
          const val = newVal as questionOptionValue;
          props.onChange(
            val.value,
            val.iconType === NodeTypeIconType.SelectCheckbox ||
              val.iconType === NodeTypeIconType.SelectRadio
          );
        }
      }}
    />
  );
};

const AnswerSelector = (props: {
  selectedNode?: NodeSummary;
  selectedAnswerId: string;
  onChange: (newAnswerId: string) => void;
}) => {
  const options = [];
  let selectedValue = null;
  if (props.selectedNode && props.selectedNode.answers) {
    for (let i = 0; i < props.selectedNode.answers.length; i++) {
      const optionValue = {
        value: props.selectedNode.answers[i].id,
        label: getAnswerLetters(i) + ") " + props.selectedNode.answers[i].text,
      };
      options.push(optionValue);

      if (props.selectedNode.answers[i].id === props.selectedAnswerId) {
        selectedValue = optionValue;
      }
    }
  }

  return (
    <SelectV2
      options={options}
      value={selectedValue}
      placeholder="Select an option"
      isDisabled={!props.selectedNode}
      onChange={(newVal) => {
        if (newVal && !Array.isArray(newVal)) {
          props.onChange((newVal as OptionType).value as string);
        }
      }}
    />
  );
};

const renderSecondLevelExpressions = (
  dispatch: AppDispatch,
  surveyId: string,
  node: Node,
  allSelectQuestions: getAllNodesMatchingTypesResult,
  exprs: Expression[],
  topLevelIndex: number
) => {
  const toRender = [];
  let prevOp = Operator.Or;

  for (let i = 0; i < exprs.length; i++) {
    // Every second expression should be an operator
    if (i % 2 === 1) {
      prevOp = exprs[i] as Operator;
      continue;
    }

    const expr = exprs[i] as SingleAnswerComparison;
    let selectedNode;
    if (expr.nodeId) {
      selectedNode = allSelectQuestions.asMap[expr.nodeId];
    }

    let deleteIndex: number[];
    let numToDelete = 0;

    if (exprs.length === 1) {
      // if this is the last expression in this level, instead delete the parent expression.
      if (topLevelIndex === 0) {
        // if the parent is the first expression in its level, then delete itself plus the next connecting operator
        deleteIndex = [topLevelIndex];
        numToDelete = 2;
      } else {
        // Deleting any other expression should delete itself plus the prevoius connecting operator
        deleteIndex = [topLevelIndex - 1];
        numToDelete = 2;
      }
    } else if (i === 0) {
      // if this is the first expression in this level, then delete itself plus the next connecting operator
      deleteIndex = [topLevelIndex, i];
      numToDelete = 2;
    } else {
      // Deleting any other expression should delete itself plus the previous connecting operator
      deleteIndex = [topLevelIndex, i - 1];
      numToDelete = 2;
    }

    toRender.push(
      <div key={i} className="second-expr-wrapper">
        <div className="second-expr">
          {i > 0 && (
            <div className="op">
              <OperatorSelector
                value={prevOp}
                onChange={(newOp) =>
                  dispatch(
                    updateConditionalExpression(
                      surveyId,
                      node.nodeId,
                      [topLevelIndex, i - 1],
                      newOp
                    )
                  )
                }
              />
            </div>
          )}
          <div>
            <QuestionSelector
              allSelectQuestions={allSelectQuestions.asArray}
              selectedNodeId={expr.nodeId}
              excludeNodeId={node.nodeId}
              onChange={(newNodeId, nodeIsSelectable) => {
                const updateObj = {
                  nodeId: newNodeId,
                  answerId: "", // Make sure we clear the answer id if the node changes
                  comparator: nodeIsSelectable
                    ? Comparator.Equals
                    : Comparator.Visible, // Make sure a valid option is selected
                };

                dispatch(
                  updateConditionalExpression(
                    surveyId,
                    node.nodeId,
                    [topLevelIndex, i],
                    updateObj
                  )
                );
              }}
            />
            <ComparatorSelector
              value={expr.comparator}
              visibilityOpsOnly={
                !!selectedNode &&
                selectedNode.nodeType !== NodeTypeIconType.SelectRadio &&
                selectedNode.nodeType !== NodeTypeIconType.SelectCheckbox
              }
              onChange={(newComp) => {
                const updateObj = {
                  comparator: newComp,
                };

                if (
                  newComp === Comparator.Visible ||
                  newComp === Comparator.NotVisible
                ) {
                  // Clear the answer ID as we're doing a visibility condition
                  (updateObj as SingleAnswerComparison).answerId = "";
                }

                dispatch(
                  updateConditionalExpression(
                    surveyId,
                    node.nodeId,
                    [topLevelIndex, i],
                    updateObj
                  )
                );
              }}
            />
          </div>
          {(expr.comparator === Comparator.Equals ||
            expr.comparator === Comparator.NotEquals) && (
            <div>
              <AnswerSelector
                selectedNode={selectedNode}
                selectedAnswerId={expr.answerId}
                onChange={(newAnswerId) =>
                  dispatch(
                    updateConditionalExpression(
                      surveyId,
                      node.nodeId,
                      [topLevelIndex, i],
                      {
                        answerId: newAnswerId,
                      }
                    )
                  )
                }
              />
              <div />
            </div>
          )}
        </div>
        <IconButton
          className={classnames("delete-icon", { "top-margin": i > 0 })}
          icon={<div className="cr-icon-trash" />}
          onClick={() =>
            dispatch(
              deleteConditionalExpression(
                surveyId,
                node.nodeId,
                deleteIndex,
                numToDelete
              )
            )
          }
        />
      </div>
    );
  }

  return toRender;
};

const renderTopLevelExpressions = (
  dispatch: AppDispatch,
  surveyId: string,
  node: Node,
  allSelectQuestions: getAllNodesMatchingTypesResult
) => {
  const exprs = node.conditionalExpression || [];

  const toRender = [];
  let prevOp = Operator.Or;

  for (let i = 0; i < exprs.length; i++) {
    // Every second expression should be an operator
    if (i % 2 === 1) {
      prevOp = exprs[i] as Operator;
      continue;
    }

    toRender.push(
      <div key={i} className="expr-wrapper">
        <div className="op">
          {i === 0 ? (
            "IF"
          ) : (
            <OperatorSelector
              value={prevOp}
              onChange={(newOp) =>
                dispatch(
                  updateConditionalExpression(
                    surveyId,
                    node.nodeId,
                    [i - 1],
                    newOp
                  )
                )
              }
            />
          )}
        </div>
        <div className="second-lvl-exprs">
          {renderSecondLevelExpressions(
            dispatch,
            surveyId,
            node,
            allSelectQuestions,
            exprs[i] as Expression[],
            i
          )}
          <Button
            tertiary
            onClick={() =>
              addBlankConditionalExpressionToSecondLevel(
                dispatch,
                surveyId,
                node.nodeId,
                [i, (exprs[i] as Expression[]).length]
              )
            }
          >
            + AND/OR
          </Button>
        </div>
      </div>
    );
  }

  return toRender;
};

const ConditionalVisibilityEditor = (
  props: IConditionalVisibilityEditorProps
) => {
  // Grab a reference to the redux store so we can get a full listing of just the questions
  // we're interested in, on mount.
  const currentSurvey = useAppSelector(
    (state) => state.surveyBuilder.surveys[props.surveyId]
  );
  const [allSelectQuestions] = useState(() => {
    // Passing a function to useState allows it to be only calculated on the initial render.
    return getAllNodesMatchingTypes(
      currentSurvey,
      [], // Get all types as we can do visibility ops on anything
      props.questionNumber
    );
  });

  const nodeSummaryText =
    props.node.type === NodeType.Section
      ? props.node.title
      : props.node.mainText;

  const conditionValid = conditionalExpressionIsValid(
    props.node.conditionalExpression,
    allSelectQuestions
  );

  return (
    <ModalV2
      active
      disallowClose
      headerContent={
        <div>
          <h2>Conditional visibility</h2>
          <p>
            Add conditional visibility to this item to determine when it is
            shown.
          </p>
        </div>
      }
      footerContent={
        <>
          <div className="btn-group footer-left">
            <Button
              danger
              onClick={() => {
                // Clear the conditional expression entirely
                props.dispatch(
                  updateNode(props.surveyId, props.node.nodeId, {
                    conditionalExpression: [],
                  })
                );

                // Any validation errors for this condition would now be irrelevant
                props.dispatch(
                  removeSurveyValidationErrorsForNode(
                    props.surveyId,
                    props.node.nodeId,
                    [
                      ValidationError.conditionalInvalidNodeId,
                      ValidationError.conditionalInvalidAnswerId,
                    ]
                  )
                );

                props.closeModal();
              }}
            >
              <span className="cr-icon-trash" /> Delete
            </Button>
          </div>
          <div className="btn-group">
            {!conditionValid && (
              <div className="validation-error">
                Some conditions have not been filled out.
              </div>
            )}
            <Button
              disabled={!conditionValid}
              onClick={() => {
                // We can assume when closing this modal that the condition is valid, so remove any validation
                // errors that have been raised for this node.
                props.dispatch(
                  removeSurveyValidationErrorsForNode(
                    props.surveyId,
                    props.node.nodeId,
                    [
                      ValidationError.conditionalInvalidAnswerId,
                      ValidationError.conditionalInvalidNodeId,
                    ]
                  )
                );
                props.closeModal();
              }}
            >
              Done
            </Button>
          </div>
        </>
      }
    >
      <div className="conditional-visibility-editor">
        <div className="show-node">
          Show{" "}
          <div className="node-summary-wrapper">
            <NodeTypeIcon
              nodeType={nodeToNodeTypeIconType(props.node)}
              questionNumber={
                props.node.type === NodeType.Risk ? "" : props.questionNumber
              }
            />
            <div className="truncate-text" title={nodeSummaryText}>
              {nodeSummaryText}
            </div>
          </div>
        </div>
        {renderTopLevelExpressions(
          props.dispatch,
          props.surveyId,
          props.node,
          allSelectQuestions
        )}
        <Button
          tertiary
          onClick={() =>
            addBlankConditionalExpressionToTopLevel(
              props.dispatch,
              props.surveyId,
              props.node.conditionalExpression || [],
              props.node.nodeId
            )
          }
        >
          + Add condition
        </Button>
      </div>
    </ModalV2>
  );
};

export default ConditionalVisibilityEditor;

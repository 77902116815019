import "../style/components/ActionBar.scss";
import { FC, ReactNode, useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { throttle } from "lodash";

interface IActionBarProps {
  active: boolean;
  children: ReactNode;
  className?: string;
  newStyles?: boolean;
  noMaxWidth?: boolean;
}

// Max width of the container must equal our $max-page-width global variable
const containerMaxWidth = 1600;

// This must be equal to the right padding of .action-bar plus the right margin of .container
const containerPaddingPlusMargin = 120;
const containerMaxLeftMargin = containerPaddingPlusMargin / 2;

const ActionBar: FC<IActionBarProps> = ({
  active,
  children,
  className = "",
  newStyles = false,
  noMaxWidth = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerLeftMargin, setContainerLeftMargin] = useState(0);

  useLayoutEffect(() => {
    let observer: ResizeObserver;
    if (active && !noMaxWidth && containerRef.current) {
      // On mount, and whenever the action bar is resized, we need to check what negative margin we need to give
      // to the container component. We do this so that the container can stay aligned with the centered page content
      // whilst being pushed in on the right side by the intercom widget.
      // At maximum width, we should have no left margin on the container.
      // When below maximum width, we gradually add a negative left margin on the container to push the whole action bar left,
      // until we hit the maximum amount that the intercom widget will push from the right.

      const observeFunc = throttle((entries: ResizeObserverEntry[]) => {
        const width = entries[0].contentRect.width;
        let diffPct =
          (width - (containerMaxWidth - containerMaxLeftMargin * 2)) /
          containerMaxLeftMargin /
          2;

        // Clamp between 0 and 1
        if (diffPct > 1) {
          diffPct = 1;
        } else if (diffPct < 0) {
          diffPct = 0;
        }
        diffPct = 1 - diffPct;

        const newLeftMargin = Math.round(containerMaxLeftMargin * diffPct * -1);
        setContainerLeftMargin(newLeftMargin);
      }, 50);

      observer = new ResizeObserver(observeFunc);

      observer.observe(containerRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [active, noMaxWidth]);

  const classes = classnames("action-bar", className, {
    "new-styles": newStyles,
    "no-max-width": noMaxWidth,
  });

  return (
    <TransitionGroup component={null}>
      {active && (
        <CSSTransition key="actionbar" timeout={250} classNames="slide-up">
          <div className={classes}>
            <div className="container-outer" ref={containerRef}>
              <div
                className="container"
                style={
                  noMaxWidth
                    ? undefined
                    : {
                        marginLeft: `${containerLeftMargin}px`,
                      }
                }
              >
                {children}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default ActionBar;

import { useMemo } from "react";
import UserAssignment from "../../_common/components/UserAssignment";
import { useCurrentUser } from "../../_common/selectors/commonSelectors";
import { IUserMini } from "../../_common/types/user";
import { userHasWriteThreatMonitoring } from "../../_common/permissions";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { useRWUserMap } from "../funcs/useUserMap";
import { TMUserAndAvatar } from "./Users";
import "./Investigator.scss";

import noInvestigatorSvg from "../images/no-investigator.svg";
import { SuppressHotjar } from "../../_common/components/HotjarWrapper";

export interface InvestigatorProps {
  threatUUID: string;
  investigatorId?: number | null;
  actors?: IUserMini[];
}

// Investigator is a component that allows the user to assign an investigator to a threat.
export default function Investigator({
  threatUUID,
  investigatorId,
  actors,
}: InvestigatorProps) {
  const dispatch = useAppDispatch();
  const userData = useCurrentUser();
  const userHasWritePermission = useMemo(() => {
    return userHasWriteThreatMonitoring(userData.userPermissions);
  }, [userData]);
  const { userMap } = useRWUserMap(actors);

  const [updateAssignee] =
    ThreatMonitoringAPI.useUpdateAssigneeSimpleV1Mutation();

  const reassignInvestigator = async (newAssignee?: IUserMini) => {
    try {
      await updateAssignee({
        uuids: [threatUUID],
        userID: newAssignee?.id ?? 0,
      });
      dispatch(
        addDefaultSuccessAlert(
          newAssignee
            ? `Investigator assigned successfully.`
            : `Investigator unassigned successfully.`
        )
      );
    } catch (e) {
      addDefaultUnknownErrorAlert(
        newAssignee
          ? `Failed to assign investigator to threat.`
          : `Failed to unassign investigator`
      );
    }
  };

  const { data, isLoading } = ThreatMonitoringAPI.useGetReadWriteUsersV1Query();

  if (isLoading || !data) {
    return <LoadingIcon size={24} />;
  }

  const readWriteUsersList = data.users;
  const investigator = investigatorId ? userMap?.[investigatorId] : null;
  const assignedUsers = investigator ? [investigator] : [];

  return (
    <SuppressHotjar>
      <UserAssignment
        className="tm-investigator-assignment"
        placeHolder={"Select investigator"}
        assignedUsers={assignedUsers}
        assigneeNoun={{ singular: "Investigator", plural: "Investigators" }}
        useSelectPortal
        closeWhenScrollingSelector="#main-content-area"
        currentUserCanEdit={
          userHasWritePermission && !isLoading && readWriteUsersList.length > 0
        }
        availableUsers={readWriteUsersList}
        onChangeAssignees={async (newAssignees: IUserMini[]) => {
          reassignInvestigator(newAssignees[0]);
        }}
        requestUnassign={() => {
          reassignInvestigator();
        }}
        userAvatarAndNameComponent={TMUserAndAvatar}
        noAssigneeElement={<img src={noInvestigatorSvg} />}
      />
    </SuppressHotjar>
  );
}

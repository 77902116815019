import { FC } from "react";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";
import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";

interface UserRisksAppStatusesFilterProps {
  isUsingAI?: boolean;
  onChange: (isUsingAI?: boolean) => void;
}

const UserRiskAppIsUsingAIFilter: FC<UserRisksAppStatusesFilterProps> = ({
  isUsingAI,
  onChange,
}) => {
  return (
    <SlidePanelSection title={"AI integration"}>
      <ColorCheckbox
        radio
        label="Yes"
        onClick={() => {
          onChange(true);
        }}
        checked={isUsingAI === true}
      />
      <ColorCheckbox
        radio
        label="No"
        onClick={() => {
          onChange(false);
        }}
        checked={isUsingAI === false}
      />
      <ColorCheckbox
        radio
        label="Show All"
        onClick={() => {
          onChange(undefined);
        }}
        checked={isUsingAI === undefined}
      />
    </SlidePanelSection>
  );
};

export default withRequireEntitlementAndPermission(
  UserRiskAppIsUsingAIFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);

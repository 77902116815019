import moment from "moment";

import "../../style/components/core/DateTimeFormat.scss";

export interface DateTimeFormatProps {
  dateTime?: string | number;
  dateOnly?: boolean;
  fallback?: string;
}

const className = "date-time-format";

const DateTimeFormat = ({
  dateTime,
  dateOnly,
  fallback = "",
}: DateTimeFormatProps) => {
  if (!dateTime) {
    return <span className={className}>{fallback}</span>;
  }

  const showTime = !dateOnly;
  const dt = moment(dateTime);
  return (
    <span className={className}>
      {dt.format("ll")}
      {showTime && (
        <span className="secondary-text">{dt.format("HH:mm zz")}</span>
      )}
    </span>
  );
};

export default DateTimeFormat;

import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  IAssessmentRiskWaivers,
  ISubsidiaryRiskWaiverCreateRequest,
  ISubsidiaryRiskWaiverUpdateRequest,
  IVendorRiskWaiver,
  IVendorRiskWaiverApproveRequest,
  IVendorRiskWaiverCreateRequest,
  IVendorRiskWaiverUpdateRequest,
  VendorRiskWaiverStatusType,
} from "../../_common/types/vendorRiskWaivers";
import { DefaultRootState } from "react-redux";
import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
} from "../../_common/reducers/commonActions";
import { ITpvmSession } from "../../_common/types/thirdPartyMangedVendors";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { GetVendorRiskWaiversV1Resp } from "../../vendor_portal/reducers/vendorRiskWaiver.actions";
import { produce } from "immer";

export const SET_VENDOR_RISK_WAIVERS = "SET_VENDOR_RISK_WAIVERS";
export const SET_VENDOR_ASSESSMENT_RISK_WAIVERS =
  "SET_VENDOR_ASSESSMENT_RISK_WAIVERS";
export const SET_SUBSIDIARY_RISK_WAIVERS = "SET_SUBSIDIARY_RISK_WAIVERS";
export const SET_SUBSIDIARY_RISK_WAIVERS_FOR_APPROVAL =
  "SET_SUBSIDIARY_RISK_WAIVERS_FOR_APPROVAL";

export const setVendorRiskWaivers = (
  vendorId: number,
  waiversResp: GetVendorRiskWaiversV1Resp,
  tpvmSession: ITpvmSession
) => {
  return {
    type: SET_VENDOR_RISK_WAIVERS,
    vendorId,
    waiversResp,
    tpvmSession,
  };
};
export const setVendorAssessmentRiskWaivers = (
  vendorId: number,
  assessmentId: number,
  waivers: IAssessmentRiskWaivers,
  tpvmSession: ITpvmSession
) => {
  return {
    type: SET_VENDOR_ASSESSMENT_RISK_WAIVERS,
    vendorId,
    assessmentId,
    waivers,
    tpvmSession,
  };
};

export const fetchVendorRiskWaivers = (
  datastoreVendorId: number,
  forceRefresh?: boolean
) => {
  let resp: GetVendorRiskWaiversV1Resp | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // Check if we have already loaded waivers for this vendor
    const loadedWaivers = getVendorRiskWaiversFromState(
      getState(),
      datastoreVendorId
    );
    if (!forceRefresh && loadedWaivers) {
      return;
    }

    try {
      resp = await FetchCyberRiskUrl<GetVendorRiskWaiversV1Resp>(
        "vendor/riskwaivers/v1/",
        {
          datastore_vendor_id: datastoreVendorId,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving vendor risk waivers", e);

      throw e;
    }

    const tpvmSession = grabTPVMSession(getState);
    dispatch(setVendorRiskWaivers(datastoreVendorId, resp, tpvmSession));
  };
};

export const getVendorAssessmentRiskWaivers = (
  datastoreVendorId: number,
  assessmentId: number,
  forceRefresh?: boolean
) => {
  let resp: IAssessmentRiskWaivers | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // Check if we have already loaded waivers for this vendor
    const loadedWaivers = getVendorAssessmentRiskWaiversFromState(
      getState(),
      datastoreVendorId,
      assessmentId
    );
    if (!forceRefresh && loadedWaivers) {
      return loadedWaivers;
    }

    try {
      resp = await FetchCyberRiskUrl<IAssessmentRiskWaivers>(
        "vendor/assessment/riskwaivers/v1/",
        {
          assessment_id: assessmentId,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving vendor assessment risk waivers", e);

      throw e;
    }

    const tpvmSession = grabTPVMSession(getState);
    dispatch(
      setVendorAssessmentRiskWaivers(
        datastoreVendorId,
        assessmentId,
        resp ?? {},
        tpvmSession
      )
    );

    return resp;
  };
};

export const createVendorRiskWaiver = (
  toCreate: IVendorRiskWaiverCreateRequest
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: IVendorRiskWaiver | undefined;

    try {
      resp = await FetchCyberRiskUrl<IVendorRiskWaiver>(
        "vendor/riskwaivers/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify(toCreate),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating vendor risk waiver", e);

      throw e;
    }

    // Update global state
    let allWaiversForVendor = getVendorRiskWaiversFromState(
      getState(),
      toCreate.datastoreVendorID
    );

    if (!allWaiversForVendor) {
      allWaiversForVendor = [];
    }

    if (resp) {
      allWaiversForVendor = [...allWaiversForVendor, resp];
    }

    const tpvmSession = grabTPVMSession(getState);
    dispatch(
      setVendorRiskWaivers(
        toCreate.datastoreVendorID,
        {
          privateWaivers: allWaiversForVendor,
          publicWaivers:
            getPublicVendorRiskWaiversFromState(
              getState(),
              toCreate.datastoreVendorID
            ) ?? [],
        },
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return resp;
  };
};

export const updateVendorRiskWaiver = (
  datastoreVendorId: number,
  toUpdate: IVendorRiskWaiverUpdateRequest
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<IVendorRiskWaiver>(
        "vendor/riskwaivers/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(toUpdate),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating vendor risk waiver", e);

      throw e;
    }

    // Update global state
    const waivers: IVendorRiskWaiver[] | undefined =
      getVendorRiskWaiversFromState(getState(), datastoreVendorId);
    if (waivers) {
      const newWaivers = produce(waivers, (draftWaivers) => {
        for (let i = 0; i < draftWaivers.length; i++) {
          if (draftWaivers[i].id === toUpdate.ID) {
            draftWaivers[i].expiresAt = toUpdate.expiresAt;
            draftWaivers[i].justification = toUpdate.justification;
            draftWaivers[i].approverEmail = toUpdate.approverEmail;
            draftWaivers[i].isAllDomains = toUpdate.isAllDomains;
            draftWaivers[i].domains = toUpdate.domains;
            draftWaivers[i].surveys = toUpdate.surveys;
            draftWaivers[i].publicSurveys = toUpdate.publicSurveys;
          }
        }
      });

      const tpvmSession = grabTPVMSession(getState);
      dispatch(
        setVendorRiskWaivers(
          datastoreVendorId,
          {
            privateWaivers: newWaivers,
            publicWaivers:
              getPublicVendorRiskWaiversFromState(
                getState(),
                datastoreVendorId
              ) ?? [],
          },
          tpvmSession
        )
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const deleteVendorRiskWaiver = (
  datastoreVendorId: number,
  id: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<IVendorRiskWaiver>(
        "vendor/riskwaivers/v1/",
        {
          id: id,
        },
        {
          method: "DELETE",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting vendor risk waiver", e);

      throw e;
    }

    // Update global state
    const waivers: IVendorRiskWaiver[] | undefined =
      getVendorRiskWaiversFromState(getState(), datastoreVendorId);
    if (waivers) {
      const tpvmSession = grabTPVMSession(getState);
      const deletedWaiver = waivers.find((w) => w.id === id);

      if (deletedWaiver?.publicWaiverId) {
        await dispatch(fetchVendorRiskWaivers(datastoreVendorId, true));
      } else if (deletedWaiver) {
        // Just update state directly
        dispatch(
          setVendorRiskWaivers(
            datastoreVendorId,
            {
              privateWaivers: waivers.filter((w) => w.id !== id),
              publicWaivers:
                getPublicVendorRiskWaiversFromState(
                  getState(),
                  datastoreVendorId
                ) ?? [],
            },
            tpvmSession
          )
        );
      }
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const getVendorRiskWaiversFromState = (
  state: DefaultRootState,
  datastoreVendorId: number
) => {
  const tpvmSession = grabTPVMSession(() => state);

  if (tpvmSession && tpvmSession.tpvm) {
    return state.cyberRisk.managedVendorData[tpvmSession.tpvm_o as any][
      tpvmSession.tpvm_v as any
    ]?.riskWaivers;
  } else {
    return state.cyberRisk.vendors[datastoreVendorId]?.riskWaivers;
  }
};

export const getPublicVendorRiskWaiversFromState = (
  state: DefaultRootState,
  datastoreVendorId: number
) => {
  const tpvmSession = grabTPVMSession(() => state);

  if (tpvmSession && tpvmSession.tpvm) {
    return state.cyberRisk.managedVendorData[tpvmSession.tpvm_o as any][
      tpvmSession.tpvm_v as any
    ]?.publicWaivers;
  } else {
    return state.cyberRisk.vendors[datastoreVendorId]?.publicWaivers;
  }
};

export const getVendorAssessmentRiskWaiversFromState = (
  state: DefaultRootState,
  datastoreVendorId: number,
  assessmentId: number
) => {
  const tpvmSession = grabTPVMSession(() => state);

  if (tpvmSession && tpvmSession.tpvm) {
    return state.cyberRisk.managedVendorData[tpvmSession.tpvm_o as any][
      tpvmSession.tpvm_v as any
    ].assessmentRiskWaivers
      ? state.cyberRisk.managedVendorData[tpvmSession.tpvm_o as any][
          tpvmSession.tpvm_v as any
        ].assessmentRiskWaivers[assessmentId]
      : undefined;
  } else {
    return state.cyberRisk.vendors[datastoreVendorId]?.assessmentRiskWaivers
      ? state.cyberRisk.vendors[datastoreVendorId]?.assessmentRiskWaivers[
          assessmentId
        ]
      : undefined;
  }
};

export const setSubsidiaryRiskWaivers = (
  vendorId: number,
  waivers: IVendorRiskWaiver[]
) => {
  return {
    type: SET_SUBSIDIARY_RISK_WAIVERS,
    vendorId,
    waivers,
  };
};

export const setSubsidiaryRiskWaiversForApproval = (
  waivers: IVendorRiskWaiver[]
) => {
  return {
    type: SET_SUBSIDIARY_RISK_WAIVERS_FOR_APPROVAL,
    waivers,
  };
};

export const createSubsidiaryRiskWaiver = (
  body: ISubsidiaryRiskWaiverCreateRequest
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "subsidiary/riskwaivers/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating subsidiary risk waiver", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const fetchSubsidiaryRiskWaivers = (
  datastoreVendorId: number,
  forceRefresh?: boolean
) => {
  let resp: IVendorRiskWaiver[] | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // Check if we have already loaded waivers for this vendor
    if (
      !forceRefresh &&
      getState().cyberRisk.subsidiaries[datastoreVendorId]?.riskWaivers
    ) {
      return;
    }

    try {
      resp = await FetchCyberRiskUrl<IVendorRiskWaiver[]>(
        "subsidiary/riskwaivers/v1/",
        {
          datastore_vendor_id: datastoreVendorId,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving subsidiary risk waivers", e);

      throw e;
    }

    dispatch(setSubsidiaryRiskWaivers(datastoreVendorId, resp));
  };
};

export const fetchSubsidiaryRiskWaiversForApproval = () => {
  let resp: IVendorRiskWaiver[] | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      resp = await FetchCyberRiskUrl<IVendorRiskWaiver[]>(
        "subsidiary/riskwaivers/v1/",
        {
          approvals_only: true,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving subsidiary risk waivers", e);

      throw e;
    }

    dispatch(setSubsidiaryRiskWaiversForApproval(resp));
  };
};

export const updateSubsidiaryRiskWaiver = (
  datastoreVendorId: number,
  toUpdate: ISubsidiaryRiskWaiverUpdateRequest
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<IVendorRiskWaiver>(
        "subsidiary/riskwaivers/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(toUpdate),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating subsidiary risk waiver", e);

      throw e;
    }

    // Update global state
    const waivers: IVendorRiskWaiver[] | undefined =
      getSubsidiaryRiskWaiversFromState(getState(), datastoreVendorId);
    if (waivers) {
      const newWaivers = produce(waivers, (draftWaivers) => {
        for (let i = 0; i < draftWaivers.length; i++) {
          if (draftWaivers[i].id === toUpdate.ID) {
            draftWaivers[i].expiresAt = toUpdate.expiresAt;
            draftWaivers[i].justification = toUpdate.justification;
            draftWaivers[i].approverEmail = toUpdate.approverEmail;
            draftWaivers[i].isAllDomains = toUpdate.isAllDomains;
            draftWaivers[i].domains = toUpdate.domains;
          }
        }
      });

      dispatch(setSubsidiaryRiskWaivers(datastoreVendorId, newWaivers));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const deleteSubsidiaryRiskWaiver = (
  datastoreVendorId: number,
  id: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<IVendorRiskWaiver>(
        "subsidiary/riskwaivers/v1/",
        {
          id: id,
        },
        {
          method: "DELETE",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting subsidiary risk waiver", e);

      throw e;
    }

    // Update global state
    const waivers: IVendorRiskWaiver[] | undefined =
      getVendorRiskWaiversFromState(getState(), datastoreVendorId);
    if (waivers) {
      const deletedWaiver = waivers.find((w) => w.id === id);

      if (deletedWaiver) {
        // Just update state directly
        dispatch(
          setSubsidiaryRiskWaivers(
            datastoreVendorId,
            waivers.filter((w) => w.id !== id)
          )
        );
      }
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const getSubsidiaryRiskWaiversFromState = (
  state: DefaultRootState,
  datastoreVendorId: number
) => {
  return state.cyberRisk.customerData?.riskProfileIncludingSubsidiaries
    ?.subsidiaryWaiversByVendorId?.[datastoreVendorId];
};

export const approveSubsidiaryRiskWaiver = (
  toUpdate: IVendorRiskWaiverApproveRequest
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<void>(
        "subsidiary/riskwaivers/approve/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(toUpdate),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        `error ${
          toUpdate.approve ? "approving" : "rejecting"
        } subsidiary risk waiver`,
        e
      );

      throw e;
    }

    const waivers = getState().cyberRisk.subsidiaryRiskWaiversForApproval;

    if (waivers) {
      const newWaivers = produce(waivers, (draft) => {
        const waiver = draft?.find((w) => w.id === toUpdate.ID);
        if (waiver) {
          waiver.status = toUpdate.approve
            ? VendorRiskWaiverStatusType.Active
            : VendorRiskWaiverStatusType.Rejected;
          waiver.approverReason = toUpdate.justification;
        }
      });

      dispatch(setSubsidiaryRiskWaiversForApproval(newWaivers));
    }
  };
};

// Use the dummy <img> to house the SVG (for sizing/ratio), create a canvas and then create a PNG
export const generatePngFromSvg = (
  svgFile: File,
  save: (pngFile: File, svgFile: File) => void,
  elementSelector = ".svg-preview"
) => {
  const svgPreviewImg =
    document.querySelector<HTMLImageElement>(elementSelector);

  if (!svgPreviewImg) {
    throw new Error(`unable to find ${elementSelector} for image generation`);
  }

  const svgData = URL.createObjectURL(svgFile);

  svgPreviewImg.removeAttribute("height");
  svgPreviewImg.removeAttribute("width");
  svgPreviewImg.src = svgData;

  const fileName = svgFile.name;

  const svgImg = new Image();
  svgImg.onload = () => {
    const canvas = document.createElement("canvas");
    svgPreviewImg.width = Math.max(
      Math.min(svgPreviewImg.clientWidth, 800),
      200
    );
    svgPreviewImg.height = Math.max(
      Math.min(svgPreviewImg.clientHeight, 800),
      200
    );
    canvas.width = svgPreviewImg.clientWidth;
    canvas.height = svgPreviewImg.clientHeight;

    // const dpr = window.devicePixelRatio || 1;

    const ctx = canvas.getContext("2d");

    //ctx.scale(dpr, dpr);

    ctx?.drawImage(svgImg, 0, 0, svgPreviewImg.width, svgPreviewImg.height);

    canvas.toBlob((b) => {
      if (!b) {
        return;
      }

      const pngFilename = fileName.replace(/.svg/gi, ".png");
      const pngFile = new File([b], pngFilename, {
        type: "image/png",
      });
      save(pngFile, svgFile);
    }, "image/png");
  };

  const imgData = URL.createObjectURL(svgFile);
  svgImg.src = imgData;
};

import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { useEffect, useState } from "react";
import { updateVerifiedVendorEvidence } from "../../../vendorrisk/reducers/verifiedVendors.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import Button from "../../../_common/components/core/Button";
import { validateUrlWithProtocol } from "../../../_common/helpers";
import { SelectV2 } from "../../../_common/components/SelectV2";
import {
  Evidence,
  EvidenceCategory,
  EvidenceSource,
} from "../../../vendorrisk/types/evidence";
import { evidenceCategoryToStr } from "../../../vendorrisk/components/shared_profile/SharedProfileEvidencePagesTable";
import TextField from "../../../_common/components/TextField";
import IconButton, { HoverColor } from "../../../_common/components/IconButton";
import { cloneDeep as _cloneDeep } from "lodash";
import "../../style/EditSecurityLinksModal.scss";

const modalSubHeader =
  "Add links to commonly requested pages like your privacy policy, security policy, " +
  "and other important online resources.";

interface IEditSecurityLinksModalProps extends BaseModalProps {
  evidencePages: Evidence[];
}

const EditSecurityLinksModal = ({
  active,
  onClose,
  evidencePages,
}: IEditSecurityLinksModalProps) => {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentEvidencePages, setCurrentEvidencePages] = useState(
    _cloneDeep(evidencePages)
  );
  const [isEdited, setIsEdited] = useState(false);

  // Reset state on active change
  useEffect(() => {
    setCurrentEvidencePages(_cloneDeep(evidencePages));
    if (currentEvidencePages.length === 0) {
      onAddEvidence();
    }
  }, []);

  const onSubmit = () => {
    setIsUpdating(true);

    dispatch(updateVerifiedVendorEvidence(currentEvidencePages))
      .then(() =>
        dispatch(
          addDefaultSuccessAlert(
            "Successfully updated security and privacy pages"
          )
        )
      )
      .then(() => onClose())
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error updating security and privacy pages"
          )
        );
      })
      .finally(() => setIsUpdating(false));
  };

  const onEditCategory = (idx: number, newCategory: EvidenceCategory) => {
    if (idx < 0 || idx >= currentEvidencePages.length) return;

    const newEvidence = [...currentEvidencePages];
    newEvidence[idx].category = newCategory;
    setCurrentEvidencePages(newEvidence);
    setIsEdited(true);
  };

  const onEditUrl = (idx: number, newUrl: string) => {
    if (idx < 0 || idx >= currentEvidencePages.length) return;

    const newEvidence = [...currentEvidencePages];
    newEvidence[idx].url = newUrl;
    setCurrentEvidencePages(newEvidence);
    setIsEdited(true);
  };

  const onDeleteEvidence = (idx: number) => {
    if (idx < 0 || idx >= currentEvidencePages.length) return;

    const newEvidence = [...currentEvidencePages];
    newEvidence.splice(idx, 1);
    setCurrentEvidencePages(newEvidence);
    setIsEdited(true);
  };

  const onAddEvidence = () => {
    const newEvidences = [
      ...currentEvidencePages,
      {
        category: EvidenceCategory.Security,
        url: "",
        source: EvidenceSource.VendorRisk,
      },
    ];
    setCurrentEvidencePages(newEvidences);
    setIsEdited(true);
  };

  // check if the current set is valid by checking if there is any duplicate pairs of category and URL
  const hasDuplicates =
    new Set(currentEvidencePages.map((ev) => `${ev.category}:${ev.url}`))
      .size != currentEvidencePages.length;
  const hasInvalidUrls = currentEvidencePages.some(
    (ev) => !validateUrlWithProtocol(ev.url)
  );
  const isValid = !hasDuplicates && !hasInvalidUrls;

  return (
    <ModalV2
      className="edit-security-links-modal"
      width={800}
      active={active}
      onClose={onClose}
      headerContent="Edit security links"
      footerContent={
        <>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            onClick={onSubmit}
            disabled={!isValid || !isEdited}
            loading={isUpdating}
          >
            Save changes
          </Button>
        </>
      }
    >
      <p>{modalSubHeader}</p>

      <div className={"evidences edit-grid"}>
        <div className="edit-grid-row header-row">
          <div className="title-col">Link title</div>
          <div className="url-col">URL</div>
          <div className="actions-col"></div>
        </div>
        {currentEvidencePages.map((ev, idx) => {
          const errorMsgs: string[] = [];

          const isDuplicate =
            currentEvidencePages.findIndex(
              (o) =>
                o.category === ev.category &&
                o.url.toLowerCase() === ev.url.toLowerCase()
            ) !== idx;
          if (isDuplicate) errorMsgs.push("Duplicate page");
          if (!validateUrlWithProtocol(ev.url)) {
            if (!ev.url.startsWith("http")) {
              errorMsgs.push("URL must start with http or https");
            } else {
              errorMsgs.push("Invalid URL");
            }
          }

          return (
            <div className="edit-grid-row" key={idx}>
              <div className="title-col">
                <SelectV2
                  options={Object.values(EvidenceCategory).map((c) => ({
                    value: c,
                    label: evidenceCategoryToStr(c),
                  }))}
                  onChange={(val) =>
                    onEditCategory(idx, val?.value as EvidenceCategory)
                  }
                  isDisabled={isUpdating}
                  value={{
                    value: ev.category,
                    label: evidenceCategoryToStr(ev.category),
                  }}
                />
              </div>
              <div className="url-col">
                <TextField
                  className={"url-text-field"}
                  value={ev.url}
                  placeholder={"URL"}
                  onChanged={(val) => onEditUrl(idx, val)}
                  errorTexts={errorMsgs}
                  disabled={isUpdating}
                  errorsTimeout={0}
                  renderErrorsOnStart={true}
                />
              </div>
              <div className="actions-col">
                <IconButton
                  icon={<div className={"cr-icon-trash"} />}
                  hoverColor={HoverColor.Red}
                  onClick={() => onDeleteEvidence(idx)}
                />
              </div>
            </div>
          );
        })}
        <div className="edit-grid-row">
          <div className="add-evidence-btn">
            <Button className={"add-evidence"} primary onClick={onAddEvidence}>
              <span className="cr-icon-plus" />
              Add link
            </Button>
          </div>
        </div>
      </div>
    </ModalV2>
  );
};

export default EditSecurityLinksModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../_common/types/reduxStore";

interface VendorRiskState {
  securityProfileResults: number[];
}

const initialState: VendorRiskState = {
  securityProfileResults: [], // holds the vendorIDs of security profile results to show when the page is opened
};

const vendorRiskSlice = createSlice({
  name: "vendorRisk",
  initialState,
  reducers: {
    addSecurityProfileResult: (state, action: PayloadAction<number>) => {
      state.securityProfileResults.push(action.payload);
    },
    removeSecurityProfileResult: (state, action: PayloadAction<number>) => {
      state.securityProfileResults = state.securityProfileResults.filter(
        (result) => result !== action.payload
      );
    },
  },
});

export const selectSecurityProfileResults = (state: RootState) =>
  state.vendorRisk.securityProfileResults;

export default vendorRiskSlice;

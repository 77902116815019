import { FC, useCallback, useEffect, useMemo, useState } from "react";
import "../style/components/RiskAssetsPanel.scss";
import { FixedSlidePanelSection } from "./filter/SlidePanelSection";
import ScrollableDiv from "./ScrollableDiv";
import { calculateWaivedAssetsForRisks } from "../reducers/risks.actions";
import { RiskProfileRiskTypes } from "../../_common/constants";
import RiskVendorDomains from "./RiskVendorDomains";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { getSubsidiaryName } from "../reducers/subsidiary.actions";
import {
  UserWriteRemediation,
  hasUserPermission,
  usePermissions,
} from "../../_common/permissions";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import SeverityIcon from "../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../_common/types/severity";
import { OpenSidePanel } from "./DomainsAndIPsPanelGroup";
import { fetchCustomerAcceptedRisks } from "../reducers/customerAcceptedRisks.actions";
import { fetchVendorSummaryAndCloudscans } from "../reducers/cyberRiskActions";
import { fetchCustomerSummaryAndCloudscans } from "../reducers/customer.actions";
import { useRisk } from "../reducers/vendorSummary.hooks";
import { WaiverType } from "../../_common/types/vendorRiskWaivers";
import { VendorRiskWaiverTabType } from "../views/VendorRiskWaivers";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { fetchSubsidiaryRiskWaivers } from "../reducers/vendorRiskWaiver.actions";
import { CalculateRiskNumberOfOccurrences } from "./RiskPanel";
import { RiskWaiverLocationState } from "../views/CreateRiskWaiver.view";

interface RiskAssetsPanelProps {
  riskID: string;
  showWaived: boolean;
  vendorID?: number;
}

const RiskAssetsPanel: FC<RiskAssetsPanelProps> = ({
  riskID,
  showWaived,
  vendorID,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [waivedAssetsLoading, setWaivedAssetsLoading] = useState(true);
  const [assetsCount, setAssetsCount] = useState(0);

  const { permissions } = usePermissions();
  const userHasWriteRemediationPermission = hasUserPermission(
    permissions,
    UserWriteRemediation
  );

  const org = useCurrentOrg();
  const subsidiaries = useAppSelector(
    (state) => state.cyberRisk.customerData.subsidiaries
  );

  const isVendor = matchPath<{ vendorId: string }>(location.pathname, {
    path: "/vendor/:vendorId",
  });

  const isCustomer =
    !isVendor && (!vendorID || vendorID == org?.verifiedDatastoreVendorID);

  const companyName = isCustomer
    ? org?.name
    : getSubsidiaryName(vendorID ?? 0, subsidiaries);
  const isSubsidiary = !isVendor && !isCustomer;

  useEffect(() => {
    setIsLoading(true);

    const fetchAcceptedRisksPromise = dispatch(
      fetchCustomerAcceptedRisks(false)
    );

    const fetchSummaryPromise = dispatch(
      isCustomer
        ? fetchCustomerSummaryAndCloudscans(false)
        : fetchVendorSummaryAndCloudscans(
            vendorID ?? 0,
            false,
            false,
            false,
            isSubsidiary
          )
    );

    const proms: Promise<any>[] = [
      fetchAcceptedRisksPromise,
      fetchSummaryPromise,
    ];
    if (isSubsidiary && vendorID) {
      proms.push(dispatch(fetchSubsidiaryRiskWaivers(vendorID, false)));
    }

    Promise.all(proms)
      .catch((e: Error) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [vendorID, isCustomer, isSubsidiary, dispatch]);

  const [allDomainsRemediationID, setAllDomainsRemediationID] = useState<
    number | undefined
  >(undefined);
  const [domainsInRemediation, setDomainsInRemediation] = useState<{
    [key: string]: number[];
  }>({});

  const cloudscansInRemediation = useAppSelector(
    (state) =>
      state.cyberRisk.customerData.riskProfileIncludingSubsidiaries
        ?.cloudscansInRemediation
  );

  const risk = useRisk(riskID, {
    isCustomer: isCustomer,
    isSubsidiary,
    vendorID,
  });

  useEffect(() => {
    if (risk?.riskType === RiskProfileRiskTypes.Cloudscan) {
      const remediation = cloudscansInRemediation?.[risk.id];
      setAllDomainsRemediationID(remediation?.allWebsitesRequestId);
      setDomainsInRemediation(remediation?.websites ?? {});
    }
  }, [risk, cloudscansInRemediation]);

  const customerRiskWaivers = useAppSelector(
    (state) => state.cyberRisk.customerData.acceptedRisks?.acceptedRisks
  );

  const subsidiaryRiskWaivers = useAppSelector(
    (state) => state.cyberRisk.subsidiaries[vendorID ?? 0]?.riskWaivers
  );

  const waivedAssets = useMemo(() => {
    if (!risk) {
      return;
    }

    const result = calculateWaivedAssetsForRisks(
      [risk],
      isCustomer,
      [],
      [],
      customerRiskWaivers,
      undefined,
      subsidiaryRiskWaivers
    );

    return result[risk.id];
  }, [risk, isCustomer, subsidiaryRiskWaivers, customerRiskWaivers]);

  // handle waived assets loading state
  useEffect(() => {
    setWaivedAssetsLoading(true);

    if (risk && (customerRiskWaivers || subsidiaryRiskWaivers)) {
      setWaivedAssetsLoading(false);
    }
  }, [
    risk,
    isCustomer,
    isSubsidiary,
    subsidiaryRiskWaivers,
    customerRiskWaivers,
  ]);

  useEffect(() => {
    setAssetsCount(
      CalculateRiskNumberOfOccurrences(risk, showWaived, waivedAssets)
    );
  }, [risk, showWaived, waivedAssets]);

  const backToText = "Back to affected assets";

  const handleViewRemediations = (remediationRequestID?: number) => {
    let loc = "/selfremediation/";
    if (vendorID && !isCustomer) {
      loc =
        (isSubsidiary ? "/subsidiaries/" : "/vendor/") +
        vendorID +
        "/remediation/";
    }

    if (!!remediationRequestID) {
      loc = loc + remediationRequestID;
    }

    history.push(loc, {
      backContext: {
        goBack: true,
        backToText: backToText,
      },
    });
  };

  const handleViewWaivers = (
    id?: number,
    isPublic?: boolean,
    waiverType?: WaiverType
  ) => {
    let loc = "/riskwaivers";
    if (isSubsidiary) {
      loc += "?tab=subsidiaries";
    }
    if (!isCustomer && !isSubsidiary && !!vendorID) {
      loc = "/vendor/" + vendorID + "/riskwaivers";
    }
    history.push(loc, {
      waiverId: !isPublic ? id : undefined,
      publicWaiverId: isPublic ? id : undefined,
      currentTab:
        waiverType === WaiverType.SeverityAdjustment
          ? VendorRiskWaiverTabType.SeverityAdjustments
          : VendorRiskWaiverTabType.Waivers,
      backContext: {
        goBack: true,
        backToText: backToText,
      },
    });
  };

  const onCreateWaiver = useCallback(
    (hostname: string) => {
      const context: RiskWaiverLocationState = {};
      context.selectedRiskId = riskID;
      context.selectedWebsites = [hostname];

      if (!isCustomer) {
        context.vendorId = vendorID;
      }

      let loc = "/riskwaivers/add";

      if (isSubsidiary) {
        loc = "/subsidiaries/" + vendorID + "/riskwaivers/add";
      } else if (!isCustomer && vendorID) {
        loc = "/vendor/" + vendorID + "/riskwaivers/add";
      }

      history.push(loc, {
        backContext: {
          backTo: location.pathname + location.search,
          backToText: `Back to Risk Profile`,
        },
        ...context,
      });
    },
    [
      isSubsidiary,
      vendorID,
      isCustomer,
      riskID,
      location.pathname,
      location.search,
    ]
  );

  return isLoading || waivedAssetsLoading ? (
    <LoadingBanner />
  ) : (
    <div className="risk-assets-panel">
      <div className="risk-assets-panel-wrapper">
        <div className="assets-flex">
          <div className="title-and-button">
            {risk && (
              <div className="risk-severity">
                <SeverityIcon severity={SeverityAsString(risk?.severity)} />
                <h2>{risk?.title}</h2>
              </div>
            )}
          </div>
          <ScrollableDiv newStyles>
            <FixedSlidePanelSection key={"affected-assets"} title={companyName}>
              <h3>Assets affected ({assetsCount})</h3>
              <RiskVendorDomains
                userHasWriteRiskWaiversPermission={
                  userHasWriteRemediationPermission
                }
                vendorId={!isCustomer ? vendorID : undefined}
                riskId={riskID}
                isSubsidiary={isSubsidiary}
                onClickDomain={(
                  riskID: string,
                  _vendorId: number | undefined,
                  hostname: string
                ) => {
                  OpenSidePanel(
                    history,
                    { scan: hostname, risk: riskID },
                    "affected assets"
                  );
                }}
                allDomainsRemediationID={allDomainsRemediationID}
                domainsInRemediation={domainsInRemediation}
                onRequestRemediation={
                  userHasWriteRemediationPermission
                    ? () =>
                        history.push(
                          isSubsidiary
                            ? "/subsidiaries/remediation/add"
                            : isCustomer
                              ? "/selfremediation"
                              : `/vendor/${vendorID}/remediation`,
                          {
                            backContext: {
                              backTo: location.pathname + location.search,
                              backToText: backToText,
                            },
                          }
                        )
                    : undefined
                }
                onCreateWaiver={onCreateWaiver}
                passed={risk?.passed}
                isWaived={risk?.isWaived ?? false}
                waivedAssets={waivedAssets}
                showWaived={showWaived}
                readonly={!userHasWriteRemediationPermission}
                onViewRemediations={handleViewRemediations}
                onViewWaivers={handleViewWaivers}
              />
            </FixedSlidePanelSection>
          </ScrollableDiv>
        </div>
      </div>
    </div>
  );
};

export default RiskAssetsPanel;

import { IOrgUser, IOrgUserInvite } from "../../../_common/types/user";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { NamedRole } from "../../../_common/types/namedRole";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ReportCard from "../../../_common/components/ReportCard";
import { useSorting } from "../../../_common/hooks";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { UserAvatarAndName } from "../../../_common/components/UserAvatar";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import moment from "moment/moment";

import "../../style/components/SharedProfileFreeOrgUserList.scss";
import { HoverColor } from "../../../_common/components/IconButton";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  addOrgUsers,
  cancelUserInvite,
  deleteOrgUsers,
  editOrgUsers,
  getOrgUserInvites,
  getOrgUsers,
} from "../../reducers/cyberRiskActions";
import { SelectV2 } from "../../../_common/components/SelectV2";
import { CannedTypes } from "../../helpers/roles";
import { useEffect, useState } from "react";
import ModalV2 from "../../../_common/components/ModalV2";
import TextField from "../../../_common/components/TextField";
import Button from "../../../_common/components/core/Button";
import { validateEmail } from "../../../_common/helpers";

export const anyCollaborators = (
  currentUserId: number,
  orgUsers: IOrgUser[],
  orgInvites: IOrgUserInvite[]
) => {
  return (
    orgInvites.length > 0 || orgUsers.some((user) => user.id !== currentUserId)
  );
};

interface ISharedProfileFreeOrgInviteUserModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  adminNamedRoleId: number;
  trustExchangeReadOnlyNamedRoleId: number;
  orgPrimaryHostname: string;
}

export const SharedProfileFreeOrgInviteUserModal = ({
  dispatch,
  active,
  onClose,
  adminNamedRoleId,
  trustExchangeReadOnlyNamedRoleId,
  orgPrimaryHostname,
}: ISharedProfileFreeOrgInviteUserModalProps) => {
  const [loading, setLoading] = useState(false);

  const blankInvite = { email: "", namedRoleId: adminNamedRoleId };
  const [invites, setInvites] = useState<
    {
      email: string;
      namedRoleId: number;
    }[]
  >([blankInvite, blankInvite, blankInvite]);

  const setInvite = (
    index: number,
    email: string | undefined,
    namedRoleId: number | undefined
  ) =>
    setInvites((invites) => {
      const newInvites = [...invites];
      const newInvite = { ...newInvites[index] };
      if (email != undefined) {
        newInvite.email = email;
      }
      if (namedRoleId) {
        newInvite.namedRoleId = namedRoleId;
      }

      newInvites[index] = newInvite;
      return newInvites;
    });

  useEffect(() => {
    // Reset the modal state when active changes
    if (active) {
      setInvites([blankInvite, blankInvite, blankInvite]);
    }
  }, [active]);

  let anyNonBlankEmails = false;
  let anyInvalidEmails = false;
  for (let i = 0; i < invites.length; i++) {
    const email = invites[i].email.trim();
    if (email !== "") {
      anyNonBlankEmails = true;
      if (!validateEmail(email)) {
        anyInvalidEmails = true;
        break;
      }
    }
  }

  const canSendInvites = anyNonBlankEmails && !anyInvalidEmails;

  const sendInvites = async () => {
    if (!canSendInvites) {
      return;
    }

    const actualInvites = invites.filter((i) => i.email.trim() !== "");

    setLoading(true);
    try {
      await Promise.all(
        actualInvites.map((invite) =>
          dispatch(
            addOrgUsers([invite.email], [], [], invite.namedRoleId, true)
          )
        )
      );
      await Promise.all([
        dispatch(getOrgUsers()),
        dispatch(getOrgUserInvites()),
      ]);
      dispatch(addDefaultSuccessAlert("Invites sent successfully"));
      onClose();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(
        addDefaultUnknownErrorAlert("Error inviting users to organization")
      );
    }
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="shared-profile-free-org-invite-user-modal"
      headerContent="Invite colleagues"
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={!canSendInvites}
            onClick={sendInvites}
          >
            Send invites
          </Button>
        </div>
      }
    >
      <div>
        <p>Invite your colleagues to view or collaborate on your Trust Page.</p>
        <div className="invites-list">
          {invites.map((invite, i) => (
            <div key={i}>
              <TextField
                type="email"
                placeholder={`name@${orgPrimaryHostname}`}
                value={invite.email}
                onChanged={(val) => setInvite(i, val, undefined)}
              />
              <SelectV2
                isSearchable={false}
                options={[
                  { value: "trustexchangereadonly", label: "View only" },
                  { value: "admin", label: "Editor" },
                ]}
                value={
                  invite.namedRoleId === adminNamedRoleId
                    ? { value: "admin", label: "Editor" }
                    : { value: "trustexchangereadonly", label: "View only" }
                }
                onChange={(newVal) => {
                  if (newVal && !Array.isArray(newVal)) {
                    setInvite(
                      i,
                      undefined,
                      newVal.value === "admin"
                        ? adminNamedRoleId
                        : trustExchangeReadOnlyNamedRoleId
                    );
                  }
                }}
              />
            </div>
          ))}
        </div>
        {invites.length < 8 && (
          <Button
            tertiary
            onClick={() => setInvites((invites) => [...invites, blankInvite])}
          >
            <div className="cr-icon-plus" /> Add more
          </Button>
        )}
      </div>
    </ModalV2>
  );
};

interface ISharedProfileFreeOrgUserListProps {
  dispatch: DefaultThunkDispatch;
  currentUserId: number;
  orgUsers?: IOrgUser[];
  orgUserInvites?: IOrgUserInvite[];
  namedRoles?: NamedRole[];
  orgPrimaryHostname: string;
}

type userOrInvite = {
  user?: IOrgUser;
  invite?: IOrgUserInvite;
};

const columnHeaders: IXTableColumnHeader[] = [
  {
    id: "name",
    text: "Name",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "email",
    text: "Email",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "added",
    text: "Added",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
  { id: "permission", text: "Access permission", sortable: false },
];

export const getAdminAndTrustExchangeRoleIds = (namedRoles: NamedRole[]) => [
  namedRoles.find((role) => role.cannedType === CannedTypes.Admin)?.id || 0,
  namedRoles.find(
    (role) => role.cannedType === CannedTypes.TrustExchangeReadOnly
  )?.id || 0,
];

const SharedProfileFreeOrgUserList = ({
  dispatch,
  orgUsers,
  orgUserInvites,
  namedRoles,
  currentUserId,
  orgPrimaryHostname,
}: ISharedProfileFreeOrgUserListProps) => {
  const items: userOrInvite[] = [
    ...(orgUsers || []).map((u) => ({ user: u })),
    ...(orgUserInvites || []).map((i) => ({ invite: i })),
  ];

  const [sortedItems, sortedBy, onSortChange] = useSorting<
    userOrInvite,
    "name" | "email" | "added"
  >(items, "added", SortDirection.DESC, {
    name: {
      orderFuncs: [(v) => v.user?.name || v.invite?.email],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    email: {
      orderFuncs: [(v) => v.user?.emailAddress || v.invite?.email],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    added: {
      orderFuncs: [(v) => v.user?.createdAt],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });

  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  const [loading, setLoading] = useState(false);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

  if (!orgUsers || !orgUserInvites || !namedRoles) {
    return <LoadingBanner />;
  }

  const [adminNamedRoleId, trustExchangeReadOnlyNamedRoleId] =
    getAdminAndTrustExchangeRoleIds(namedRoles);

  const changeUserRole = (user: IOrgUser, namedRoleId: number) => {
    if (user.namedRoleId === namedRoleId) {
      return;
    }

    setLoading(true);
    dispatch(editOrgUsers([user.id], [], [], namedRoleId))
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error updating user"));
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ReportCard className="shared-profile-free-org-user-list" newStyles>
        <div className="header">
          Users
          <div className="header-right">
            <Button onClick={() => setInviteUserModalOpen(true)}>
              <div className="cr-icon-plus" /> Invite user
            </Button>
          </div>
        </div>
        <XTable
          loading={loading}
          iconOptions
          columnHeaders={columnHeaders}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          rows={sortedItems.map((item) => {
            const iconOptions: IIconOption[] = [];
            const isCurrentUser = !!item.user && item.user.id === currentUserId;

            if (!isCurrentUser) {
              iconOptions.push({
                id: "delete",
                icon: <div className="cr-icon-trash" />,
                hoverText: item.user ? "Remove user" : "Cancel invite",
                hoverColor: HoverColor.Red,
                onClick: item.user
                  ? () =>
                      openConfirmationModal({
                        title: `Remove ${item.user?.name}?`,
                        description: `${item.user?.name} will lose access to your organization's Trust Page.`,
                        buttonText: "Remove",
                        cancelText: "Cancel",
                        dangerousAction: true,
                        buttonAction: async () => {
                          try {
                            await dispatch(deleteOrgUsers([item.user?.id]));
                            dispatch(addDefaultSuccessAlert("Removed user"));
                          } catch (e) {
                            console.error(e);
                            dispatch(
                              addDefaultUnknownErrorAlert("Error removing user")
                            );
                          }
                        },
                      })
                  : item.invite
                    ? () =>
                        openConfirmationModal({
                          title: `Cancel invite to ${item.invite?.email}?`,
                          description: `${item.invite?.email} not be able to claim access to your organization's Trust Page anymore.`,
                          buttonText: "Cancel invite",
                          cancelText: "Back",
                          dangerousAction: true,
                          buttonAction: async () => {
                            try {
                              await dispatch(cancelUserInvite(item.invite?.id));
                              dispatch(
                                addDefaultSuccessAlert("Cancelled invite")
                              );
                            } catch (e) {
                              console.error(e);
                              dispatch(
                                addDefaultUnknownErrorAlert(
                                  "Error cancelling invite"
                                )
                              );
                            }
                          },
                        })
                    : undefined,
              });
            }

            return {
              id: item.user ? item.user.id : `invite_${item.invite?.email}`,
              iconOptions,
              cells: [
                <XTableCell key="name">
                  {item.user && (
                    <div className="user-cell">
                      <UserAvatarAndName
                        avatar={item.user.avatar}
                        email={item.user.emailAddress}
                        name={item.user.name}
                      />
                      {item.user.id === currentUserId && (
                        <PillLabel color={LabelColor.Blue}>You</PillLabel>
                      )}
                    </div>
                  )}
                </XTableCell>,
                <XTableCell key="email">
                  {item.user?.emailAddress || item.invite?.email}
                </XTableCell>,
                <XTableCell key="added">
                  {item.user ? moment(item.user.createdAt).format("ll") : ""}
                  {item.invite && <em>(invited)</em>}
                </XTableCell>,
                <XTableCell key="permission">
                  {!isCurrentUser && item.user && (
                    <SelectV2
                      isSearchable={false}
                      options={[
                        { value: "trustexchangereadonly", label: "View only" },
                        { value: "admin", label: "Editor" },
                      ]}
                      value={
                        item.user.namedRoleId === adminNamedRoleId
                          ? { value: "admin", label: "Editor" }
                          : {
                              value: "trustexchangereadonly",
                              label: "View only",
                            }
                      }
                      onChange={(newVal) => {
                        if (newVal && !Array.isArray(newVal)) {
                          changeUserRole(
                            item.user!,
                            newVal.value === "admin"
                              ? adminNamedRoleId
                              : trustExchangeReadOnlyNamedRoleId
                          );
                        }
                      }}
                    />
                  )}
                  {(isCurrentUser || item.invite) && (
                    <>
                      {(item.user?.namedRoleId || item.invite?.namedRoleId) ===
                      adminNamedRoleId
                        ? "Editor"
                        : "View only"}
                    </>
                  )}
                </XTableCell>,
              ],
            };
          })}
        />
      </ReportCard>
      <SharedProfileFreeOrgInviteUserModal
        dispatch={dispatch}
        active={inviteUserModalOpen}
        onClose={() => setInviteUserModalOpen(false)}
        adminNamedRoleId={adminNamedRoleId}
        trustExchangeReadOnlyNamedRoleId={trustExchangeReadOnlyNamedRoleId}
        orgPrimaryHostname={orgPrimaryHostname}
      />
      {confirmationModalComponent}
    </>
  );
};

export default SharedProfileFreeOrgUserList;

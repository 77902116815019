import {
  ISurveyListItemResponse,
  SurveyRiskVisibility,
  SurveyStatus,
} from "../../types/survey";
import { DefaultThunkDispatchProp } from "../../types/redux";
import { FC, useEffect, useState } from "react";
import {
  UserSurveyShareAnswersWithOrg,
  UserWritePrefilledSurveys,
  UserWriteSurveyImportExport,
} from "../../permissions";
import {
  updateVendorSurveyAddToSharedProfile,
  updateVendorSurveyShareAnswersWithOrg,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import { fetchSurveyDetails } from "../../reducers/surveyDetails.actions";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import { surveySubmitted } from "../../views/SurveyDetails";
import { SurveyUsageType } from "../../types/surveyTypes";
import ReportCard from "../ReportCard";
import ToggleSwitch from "../core/ToggleSwitch";
import "../../style/components/SurveyDetailsOptionsCard.scss";
import { appConnect } from "../../types/reduxHooks";
import { SidePopupV2 } from "../DismissablePopup";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../../verifiedvendors/api/trustpage.api";
import { LabelColor } from "../../types/label";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import Button from "../core/Button";
import { useCurrentUser } from "../../selectors/commonSelectors";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDefaultHistory } from "../../types/router";

interface ISurveyDetailsOptionsCardOwnProps {
  isVendorPortal: boolean;
  survey: ISurveyListItemResponse;
}

interface ISurveyDetailsOptionsCardConnectedProps {
  userIsInVerifiedOrg: boolean;
  userHasWritePrefilledSurveysInVerifiedOrg: boolean;
  userHasAnswerShareInVerifiedOrg: boolean;
  userHasWriteSurveyImportExportPermission: boolean;
  userIsListedRecipient: boolean;
}

type ISurveyDetailsOptionsCardProps = ISurveyDetailsOptionsCardOwnProps &
  ISurveyDetailsOptionsCardConnectedProps &
  DefaultThunkDispatchProp;

const SurveyDetailsOptionsCard: FC<ISurveyDetailsOptionsCardProps> = ({
  dispatch,
  isVendorPortal,
  survey,
  userIsInVerifiedOrg,
  userHasWritePrefilledSurveysInVerifiedOrg,
  userHasAnswerShareInVerifiedOrg,
  userHasWriteSurveyImportExportPermission,
  userIsListedRecipient,
}) => {
  const history = useDefaultHistory();
  const userData = useCurrentUser();
  const currentOrgIsVerifiedVendor = !!userData.orgList?.some(
    (org) => org.id === userData.currentOrgID && org.isVerifiedVendor
  );

  const [addToSharedProfileToggle, setAddToSharedProfileToggle] = useState(
    survey?.addToSharedProfile || false
  );

  const [showCopiedButton, setShowCopiedButton] = useState(false);
  const [shareAnswersWithOrgToggle, setShareAnswersWithOrgToggle] = useState(
    survey.answersSharedWithVerifiedOrg ?? false
  );

  const { data: mySharedAssessment } =
    TrustPageAPI.useGetOwnSharedAssessmentV1Query(undefined, {
      skip: !isVendorPortal || !currentOrgIsVerifiedVendor,
    });
  const [existingPublicSurvey, setExistingPublicSurvey] = useState<
    undefined | ISurveyListItemResponse
  >(undefined);

  useEffect(() => {
    setExistingPublicSurvey(
      mySharedAssessment?.questionnaires.find(
        (publicSurvey) => publicSurvey.originalID === survey.id
      )
    );
  }, [mySharedAssessment]);

  // Make sure we keep the toggles up to date when the survey data updates
  useEffect(() => {
    if (survey) {
      setAddToSharedProfileToggle(survey.addToSharedProfile);
      setShareAnswersWithOrgToggle(
        survey.answersSharedWithVerifiedOrg ?? false
      );
    }
  }, [survey]);

  const asyncSetAddToSharedProfile = async (
    newVal: boolean,
    refreshSharedProfile = false
  ) => {
    setAddToSharedProfileToggle(newVal);
    return dispatch(updateVendorSurveyAddToSharedProfile(survey.id, newVal))
      .then(() => dispatch(fetchSurveyDetails(survey.id, true)))
      .then(() => {
        // Refresh the shared profile in the background if necessary
        if (refreshSharedProfile) {
          dispatch(
            TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
          );
        }
      })
      .catch((e) => {
        console.error(e);
        setAddToSharedProfileToggle(!newVal);
        dispatch(addDefaultUnknownErrorAlert("Error updating questionnaire"));
      });
  };

  const toggleAddOrRemoveFromSharedProfile = () => {
    // We can just set the toggle immediately without asking the user anything.
    // Update the toggle and refetch the survey in the background
    const newVal = !addToSharedProfileToggle;
    if (newVal) {
      setShowCopiedButton(true);
    }

    asyncSetAddToSharedProfile(newVal, true).finally(() =>
      setTimeout(() => setShowCopiedButton(false), 1000)
    );
  };

  const setShareAnswersWithOrg = async () => {
    const newVal = !survey.answersSharedWithVerifiedOrg;
    setShareAnswersWithOrgToggle(newVal);

    try {
      await dispatch(updateVendorSurveyShareAnswersWithOrg(survey.id, newVal));
      await dispatch(fetchSurveyDetails(survey.id, true));
    } catch (e) {
      console.error(e);
      setShareAnswersWithOrgToggle(!newVal);
      dispatch(addDefaultUnknownErrorAlert("Error updating questionnaire"));
    }
  };

  const showAddToSharedProfileButton =
    isVendorPortal &&
    currentOrgIsVerifiedVendor &&
    (survey.isSystemSurvey || survey.isImportedSurvey) &&
    userIsInVerifiedOrg &&
    survey.status !== SurveyStatus.Cancelled &&
    !survey.archived &&
    survey.usageType === SurveyUsageType.Security &&
    survey.riskVisibility === SurveyRiskVisibility.Visible;

  const canAddToSharedProfile =
    showAddToSharedProfileButton &&
    ((!survey.isImportedSurvey && userHasWritePrefilledSurveysInVerifiedOrg) ||
      (survey.isImportedSurvey &&
        userHasWritePrefilledSurveysInVerifiedOrg &&
        userHasWriteSurveyImportExportPermission));

  // In order for a user to share answers with their verified org, the current user needs to not be a limited user,
  // and also a listed recipient on the questionnaire.
  const showShareAnswersWithOrgToggle =
    isVendorPortal &&
    userIsInVerifiedOrg &&
    userHasAnswerShareInVerifiedOrg &&
    !survey.isPrefilledSurvey &&
    survey.usageType === SurveyUsageType.Security &&
    userIsListedRecipient;

  if (!showAddToSharedProfileButton && !showShareAnswersWithOrgToggle) {
    return null;
  }

  return (
    <ReportCard newStyles className="survey-details-options-card">
      <div className="header">Options</div>
      <div className="options-content">
        {showAddToSharedProfileButton && (
          <>
            <div className={"add-to-shared-profile-option"}>
              <div className={"option-header"}>
                Include in Trust Page
                {existingPublicSurvey ? (
                  existingPublicSurvey.status === SurveyStatus.Published ? (
                    <PillLabel color={LabelColor.Green}>Included</PillLabel>
                  ) : (
                    <PillLabel color={LabelColor.Grey}>Draft</PillLabel>
                  )
                ) : (
                  !surveySubmitted(survey.status) && (
                    <PillLabel color={LabelColor.Orange}>
                      Awaiting completion
                    </PillLabel>
                  )
                )}
              </div>
              {existingPublicSurvey ? (
                <div className={"option-sub-header"}>
                  This questionnaire has been copied to your Trust Page.{" "}
                  <a
                    className={"view-copy-link"}
                    onClick={() =>
                      history.push(
                        `/trustpage/surveys/${existingPublicSurvey.id}`,
                        {
                          backContext: {
                            backToText: "Back to Questionnaire Details",
                            goBack: true,
                          },
                        }
                      )
                    }
                  >
                    View copy
                  </a>
                </div>
              ) : (
                <>
                  <div className={"option-sub-header"}>
                    {addToSharedProfileToggle
                      ? "This questionnaire will be added to your Trust Page when it's complete"
                      : "Add to your Trust Page once completed"}
                    <a
                      href="https://help.upguard.com/en/articles/3797568-what-is-an-upguard-trust-page"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SidePopupV2
                        position={"left"}
                        text={"Click for more information about Trust Pages"}
                        popupHoverable={true}
                      >
                        <div className="cr-icon-info" />
                      </SidePopupV2>
                    </a>
                  </div>
                  <div className={"action-button"}>
                    <SidePopupV2
                      position={"left"}
                      text={
                        !userHasWritePrefilledSurveysInVerifiedOrg
                          ? "You currently only have read access to your Trust Page. Administrators of your company's account have been notified and may grant you full access."
                          : !survey.isImportedSurvey
                            ? ""
                            : userHasWriteSurveyImportExportPermission
                              ? ""
                              : "You currently only have read access to imported questionnaires. Administrators of your company's account may grant you full access."
                      }
                    >
                      <TransitionGroup>
                        <CSSTransition
                          timeout={350}
                          classNames="fade-transition"
                        >
                          {showCopiedButton ? (
                            <Button
                              disabled={!canAddToSharedProfile}
                              onClick={toggleAddOrRemoveFromSharedProfile}
                            >
                              <i className="cr-icon-check" /> Copied
                            </Button>
                          ) : addToSharedProfileToggle ? (
                            <Button
                              danger
                              disabled={!canAddToSharedProfile}
                              onClick={toggleAddOrRemoveFromSharedProfile}
                            >
                              Remove from Trust Page
                            </Button>
                          ) : (
                            <Button
                              disabled={!canAddToSharedProfile}
                              onClick={toggleAddOrRemoveFromSharedProfile}
                            >
                              <i className="cr-icon-plus" /> Add to Trust Page
                            </Button>
                          )}
                        </CSSTransition>
                      </TransitionGroup>
                    </SidePopupV2>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {showShareAnswersWithOrgToggle && (
          <>
            <div className="option-toggle">
              <ToggleSwitch
                name="share-answers"
                selected={shareAnswersWithOrgToggle}
                onClick={setShareAnswersWithOrg}
              />{" "}
              Allow others in my account to re-use answers from this
              questionnaire{" "}
              <a
                href="https://help.upguard.com/en/articles/6434680-how-to-autofill-a-questionnaire-based-on-your-previous-responses"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SidePopupV2
                  position={"left"}
                  text={
                    "When enabled, other people in your account will be able to draw on answers from this questionnaire when autofilling future questionnaires. Click here for more information."
                  }
                >
                  <div className="cr-icon-info" />
                </SidePopupV2>
              </a>
            </div>
          </>
        )}
      </div>
    </ReportCard>
  );
};

export default appConnect<
  ISurveyDetailsOptionsCardConnectedProps,
  never,
  ISurveyDetailsOptionsCardOwnProps
>((state, props) => {
  let userIsInVerifiedOrg = false;
  let userHasWritePrefilledSurveysInVerifiedOrg = false;
  let userHasAnswerShareInVerifiedOrg = false;
  let userHasWriteSurveyImportExportPermission = false;

  if (state.common.userData.orgList) {
    // Look through the user's org and see if they are part of the verified org for this survey.
    for (let i = 0; i < state.common.userData.orgList.length; i++) {
      const org = state.common.userData.orgList[i];
      if (
        org.isVerifiedVendor &&
        (org.verifiedDatastoreVendorID === props.survey.vendorId ||
          (props.survey.isImportedSurvey &&
            org.id === props.survey.organisationId))
      ) {
        userIsInVerifiedOrg = true;
        userHasWritePrefilledSurveysInVerifiedOrg =
          org.permissions?.includes(UserWritePrefilledSurveys) ?? false;
        userHasAnswerShareInVerifiedOrg =
          org.permissions?.includes(UserSurveyShareAnswersWithOrg) ?? false;
        userHasWriteSurveyImportExportPermission =
          org.permissions?.includes(UserWriteSurveyImportExport) ?? false;
      }
    }
  }

  const userIsListedRecipient = props.survey.sharedWith.some(
    (u) => u.id === state.common.userData.id
  );

  return {
    userIsInVerifiedOrg,
    userHasWritePrefilledSurveysInVerifiedOrg,
    userHasAnswerShareInVerifiedOrg,
    userHasWriteSurveyImportExportPermission,
    userIsListedRecipient,
  };
})(SurveyDetailsOptionsCard);

import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PublicTrustPagePreviewView from "./views/PublicTrustPagePreviewView";
import PublicQuestionnaireAnswers from "../vendorrisk/views/PublicQuestionnaireAnswers";
import PrefilledSurveyDetailsView from "./views/PrefilledSurveyDetailsView";
import MySharedProfileSettings from "../vendorrisk/views/MySharedProfileSettings";
import MySharedProfile from "../vendorrisk/views/MySharedProfile";

export const trustPageRoute = `/trustpage`;
export const trustPagePreviewRoute = `${trustPageRoute}/preview`;
export const trustPageSettingsRoute = `${trustPageRoute}/settings`;

// TrustPageAppRouter contains the routes for a logged in user in the Trust Page admin area
const TrustPageAppRouter: FC = () => {
  return (
    <Switch>
      <Route
        path={trustPagePreviewRoute}
        component={PublicTrustPagePreviewView}
      />
      <Redirect
        exact
        path="/mysharedassessment/surveys/:id/answers"
        to="/trustpage/surveys/:id/answers"
      />
      <Route
        path="/trustpage/surveys/:id/answers"
        component={PublicQuestionnaireAnswers}
      />
      <Route
        path="/trustpage/surveys/:surveyId"
        component={PrefilledSurveyDetailsView}
      />
      <Redirect
        exact
        path="/mysharedassessment/settings"
        to={trustPageSettingsRoute}
      />
      <Route
        path={trustPageSettingsRoute}
        component={MySharedProfileSettings}
      />
      <Redirect exact path="/mysharedassessment" to="/trustpage" />
      <Route path={trustPageRoute} component={MySharedProfile} />
    </Switch>
  );
};

export default TrustPageAppRouter;

import { useEffect } from "react";
import { useAppSelector } from "../types/reduxHooks";

// This component loads the Hotjar tracking script under certain conditions:
// - Current user region is not Europe and not blank
// - Current org region not EMEA
//   - Note: we'll allow blank here
const HotjarWrapper = () => {
  const currentOrgId = useAppSelector(
    (state) => state.common.userData.currentOrgID
  );
  const orgs = useAppSelector((state) => state.common.userData.orgList);

  const currentOrg = orgs?.find((o) => o.id === currentOrgId);
  const userRegion = useAppSelector(
    (state) => state.common.userData.userRegion
  );

  const isNonEuropean =
    userRegion && userRegion !== "Europe" && currentOrg?.region !== "EMEA";

  // On org change, load the script if needed
  useEffect(() => {
    if (window.HOTJAR_SITE_ID && isNonEuropean) {
      // Only load it if we haven't yet
      if (!isHotjarLoaded()) {
        const script = document.createElement("script");
        script.setAttribute("id", "hotjar-script");

        const body = document.getElementsByTagName("body")[0];

        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${window.HOTJAR_SITE_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        body.appendChild(script);
      }

      // Note this cleanup helps with testing - this component is never removed in practise
      return () => {
        const script = document.getElementById("hotjar-script");
        if (script) {
          script.remove();
        }
      };
    }

    return;
  }, [isNonEuropean]);

  return <></>;
};

// Check if the Hotjar script is currently loaded
const isHotjarLoaded = () => {
  return !!window.hj && typeof window.hj === "function";
};

// Any children of this component will be blurred in hotjar recordings
export function SuppressHotjar({ children }: { children: React.ReactNode }) {
  return <div data-hj-suppress>{children}</div>;
}

export default HotjarWrapper;

import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/securityprofile/ControlCheckPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import VendorSecurityProfileAPI, {
  downloadUpguardDocument,
  useRemoveDocument,
} from "../../reducers/vendorSecurityProfileAPI";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import { LabelColor } from "../../../_common/types/label";
import PillLabel from "../PillLabel";
import SeverityIcon, {
  AdjustedSeverityIcon,
} from "../../../_common/components/SeverityIcon";
import { SeverityAsString, SeverityInt } from "../../../_common/types/severity";
import { debounce, sortBy, upperFirst } from "lodash";
import moment from "moment";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { HoverLocation } from "../../../_common/components/IconButton";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import {
  ControlState,
  DocTypeFromID,
  DocTypeStringFromID,
  SecurityProfileDocumentID,
} from "../../types/securityProfile";
import RiskVendorDomains from "../RiskVendorDomains";
import { useHistory } from "react-router-dom";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { useDefaultLocation } from "../../../_common/types/router";
import {
  OrgSecurityProfileRiskAdjustments,
  useBasicPermissions,
  UserManageVendorRiskWaivers,
  UserVendorRiskWrite,
} from "../../../_common/permissions";
import {
  useAssuranceType,
  useVendorURLPrefix,
  useVendorWords,
} from "../../../_common/hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchRiskVendorWebsites,
  getRiskVendorWebsites,
} from "../../reducers/vendorRiskPortfolio.actions";
import { useVendorDataSelector } from "../../../_common/selectors/vendorSelectors";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import Button, { TooltipButton } from "../../../_common/components/core/Button";
import ManageRiskButton from "../risk_profile/ManageRiskButton";
import { RiskSource } from "../../../_common/types/risks";
import RiskSummaryComment from "../RiskSummaryComment";
import {
  SecurityProfileRisk,
  VendorSummaryRiskType,
} from "../../../_common/types/vendorSummary";
import classNames from "classnames";
import { getVendorPageBreadcrumbs } from "../../../_common/components/Breadcrumbs";
import { CompensatingControlInfoV2 } from "../questionnaires/CompensatingControlInfo";

interface CitationSectionProps {
  checks: SecurityProfileRisk[];
  vendorId: number;
  overrideCheckResult?: (
    scanCheckID?: number,
    publicScanCheckID?: number
  ) => void;
  onRemoveDocument?: (docID: SecurityProfileDocumentID) => void;
}

export const CitationSection = ({
  checks,
  vendorId,
  overrideCheckResult,
  onRemoveDocument,
}: CitationSectionProps) => {
  const { data: documents } =
    VendorSecurityProfileAPI.useGetSecurityDocumentsQuery({ vendorId });

  const urlPrefix = useVendorURLPrefix(vendorId);

  const onClickDocument = useCallback(
    (docID: SecurityProfileDocumentID) => {
      const docType = DocTypeFromID(docID);
      const id = docID.split(":")[1];

      switch (docType) {
        case "PrivateSurvey":
          window.open(`${urlPrefix}/surveys/${id}`, "_blank");
          break;
        case "AdditionalEvidence":
          window.open(`${urlPrefix}/evidence/details/${id}`, "_blank");
          break;
        case "UpguardDoc":
          downloadUpguardDocument(vendorId, id);
      }
    },
    [urlPrefix, vendorId]
  );

  return (
    <div className="citations-table">
      <XTable
        iconOptions={!!overrideCheckResult}
        rows={checks.map((check) => {
          // find the document from our security profile documents
          const document = documents?.availableDocuments.find(
            (d) => check.documentID == d.id
          );

          return {
            id: `${check.documentID}`,
            cells: [
              <XTableCell key="citation" className="citation">
                <div className="document">
                  {[
                    "PrivateSurvey",
                    "AdditionalEvidence",
                    "UpguardDoc",
                  ].includes(DocTypeFromID(check.documentID)) ? (
                    <a
                      onClick={() => onClickDocument(check.documentID)}
                      className="name"
                    >
                      {document?.name}
                    </a>
                  ) : (
                    <div>{document?.name}</div>
                  )}

                  <div className="source">
                    {DocTypeStringFromID(check.documentID)}, Uploaded by{" "}
                    {document?.author}
                    {document?.lastUpdated &&
                      `, ${moment(document?.lastUpdated).format(timeFormat)}`}
                  </div>
                </div>
                {check.citations.map((c) => (
                  <div key={c.text} className={"extract"}>
                    {c.text}
                  </div>
                ))}
              </XTableCell>,
            ],
            iconOptions:
              // we only show the options to remove citations/documents for document citations
              // if there is scanCheckID this means its coming from the gap questionnaire
              (check.scanCheckID || check.publicScanCheckID) &&
              overrideCheckResult
                ? [
                    {
                      id: `${check.documentID}-action`,
                      icon: <i className={"cr-icon-dots-menu"} />,
                      dropdownItems: [
                        <DropdownItem
                          onClick={() =>
                            overrideCheckResult(
                              check.scanCheckID,
                              check.publicScanCheckID
                            )
                          }
                          key="reject-citation"
                        >
                          <div className="dropdown-with-text">
                            <div className="dropdown-title">
                              <i className="cr-icon-cancel" />
                              Reject citation
                            </div>
                            <div className="dropdown-text">
                              Excludes this specific citation from scanning
                            </div>
                          </div>
                        </DropdownItem>,
                        <DropdownItem
                          onClick={() => onRemoveDocument?.(check.documentID)}
                          key="archive-document"
                          disabled={!onRemoveDocument}
                        >
                          <div className="dropdown-with-text">
                            <div className="dropdown-title">
                              <i className="cr-icon-archive" />
                              Remove document
                            </div>
                            <div className="dropdown-text">
                              Excludes entire document from scanning
                            </div>
                          </div>
                        </DropdownItem>,
                      ],
                      hoverText: "Manage this citation",
                      hoverLocation: HoverLocation.Left,
                    },
                  ]
                : undefined,
          };
        })}
      />
    </div>
  );
};

interface IControlCheckPanelProps {
  onClickBack?: () => void;
  vendorId: number;
  checkId: string;
  scanRiskId?: string;
  surveyRiskId?: string;
}

const timeFormat = "D MMM YYYY HH:mm";

const ControlCheckPanel = ({
  checkId,
  onClickBack,
  vendorId,
  scanRiskId,
  surveyRiskId,
}: IControlCheckPanelProps) => {
  const history = useHistory();
  const location = useDefaultLocation();
  const dispatch = useAppDispatch();

  const controlId = checkId.substring(0, 8);

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  const { data: controlData, isFetching: controlLoading } =
    VendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });
  const control = controlData?.control;

  const { data: riskComments } =
    VendorSecurityProfileAPI.useGetRiskCommentsQuery({ vendorId });

  const [updateRiskComments] =
    VendorSecurityProfileAPI.useUpdateRiskCommentsMutation();

  const onUpdateRiskComments = useCallback(
    (val: string) => {
      updateRiskComments({
        vendorID: vendorId,
        comments: [
          {
            riskID: scanRiskId && scanRiskId != "" ? scanRiskId : checkId,
            comment: val,
          },
        ],
      })
        .unwrap()
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert("Error updating risk treatment plan")
          );
        });
    },
    [vendorId, checkId, scanRiskId]
  );

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const scanningRisks = securityProfile?.scanningChecks[checkId];
  const surveyRisks = securityProfile?.surveyChecks[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];

  const scanningRisk = useMemo(() => {
    if (scanningRisks && scanRiskId) {
      return scanningRisks.find((s) => s.id == scanRiskId);
    }
    return undefined;
  }, [scanRiskId, scanningRisks]);

  const surveyRisk = useMemo(() => {
    if (surveyRisks && surveyRiskId) {
      return surveyRisks.find((s) => s.id == surveyRiskId);
    }
    return undefined;
  }, [surveyRiskId, surveyRisks]);

  const risk = useMemo(() => {
    return scanningRisk || surveyRisk || documentCheck;
  }, [scanningRisk, surveyRisk, documentCheck]);

  const securityProfiles = useMemo(() => {
    return [
      ...(surveyRisk?.securityProfiles || []),
      ...(documentCheck?.securityProfiles || []),
    ];
  }, [surveyRisk, documentCheck]);

  // fetch data
  useEffect(() => {
    if (scanningRisk) {
      dispatch(
        fetchRiskVendorWebsites(scanningRisk.id, vendorId, false, true, true)
      );
    }
  }, [scanningRisk, vendorId]);

  const riskVendorWebsites = useAppSelector((state) =>
    scanningRisk
      ? getRiskVendorWebsites(
          state,
          scanningRisk.id,
          vendorId,
          false,
          false,
          undefined
          // TODO - do we need to take filters into accoun there?
        )
      : undefined
  );

  const scoredAt = useVendorDataSelector(
    (state) => state.watching.result?.scoredAt,
    vendorId
  );

  // find the 'scannedAt' date, this is a fudge
  // if there's a scanning risk try to find the most recent time we scanned an affected domain
  // if its a doc risk its when the doc was scanned
  // just default to the last scored time for the vendor otherwise
  const scannedAt = useMemo(() => {
    if (scanningRisk) {
      if (
        riskVendorWebsites &&
        riskVendorWebsites.data?.riskDetailsByHostname
      ) {
        const scanDates = sortBy(
          Object.values(riskVendorWebsites.data.riskDetailsByHostname),
          [(r) => r.scannedAt]
        );

        if (scanDates.length == 0) {
          if (securityProfile?.lastPrimaryScanTime) {
            return securityProfile.lastPrimaryScanTime;
          }

          return scoredAt;
        }

        return scanDates[0].scannedAt;
      }

      return undefined;
    } else if (documentCheck?.securityProfiles) {
      const sorted = sortBy(documentCheck.securityProfiles, [
        (d) => d.scannedAt,
      ]);
      if (sorted.length == 0) {
        return undefined;
      }

      return sorted[0].scannedAt;
    }

    if (securityProfile?.lastPrimaryScanTime) {
      return securityProfile.lastPrimaryScanTime;
    }
    return scoredAt;
  }, [
    scoredAt,
    scanningRisk,
    documentCheck,
    riskVendorWebsites,
    securityProfile?.lastPrimaryScanTime,
  ]);

  const scanRiskInRemediation = useVendorDataSelector(
    (state) =>
      scanRiskId
        ? state.summary?.result?.cloudscansInRemediation?.[scanRiskId]
        : undefined,
    vendorId
  );

  const inRemediation = scanRiskId
    ? scanRiskInRemediation
    : surveyRisk?.surveys?.[0]?.inRemediation;

  const urlPrefix = useVendorURLPrefix(vendorId);

  const permissions = useBasicPermissions();
  const userHasWriteWaiversPermission =
    permissions.userPermissions[UserManageVendorRiskWaivers];
  const userHasWriteRemediationPermission =
    permissions.userPermissions[UserVendorRiskWrite];
  const orgHasSecurityProfileRiskAdjustments =
    permissions.orgPermissions[OrgSecurityProfileRiskAdjustments];

  const [overrideDocumentCheckResult] =
    vendorSecurityProfileAPI.useOverrideDocumentCheckResultV1Mutation();
  const [overrideLoading, setOverrideLoading] = useState(false);

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const overrideCheckResult = useCallback(
    (scanCheckID?: number, publicScanCheckID?: number) => {
      const onlyCheck =
        documentCheck?.securityProfiles &&
        documentCheck.securityProfiles.length == 1;

      openConfirmationModal({
        buttonAction: () => {
          setOverrideLoading(true);
          return overrideDocumentCheckResult({
            vendorId,
            checks: [
              {
                privateId: scanCheckID,
                publicId: publicScanCheckID,
              },
            ],
          })
            .unwrap()
            .then(() => {
              dispatch(addDefaultSuccessAlert("Rejected citation"));
            })
            .catch(() => {
              dispatch(addDefaultUnknownErrorAlert("Error rejecting citation"));
            })
            .finally(() => {
              setOverrideLoading(false);
            });
        },
        buttonText: "Reject citation",
        dangerousAction: true,
        title: "Are you sure you want to reject this citation?",
        description: (
          <>
            {onlyCheck && (
              <p>
                This is the only citation associated with this check. Rejecting
                it will revert its status to “Requires evidence”.
              </p>
            )}
            <p>This action cannot be undone.</p>
          </>
        ),
      });
    },
    [vendorId, documentCheck]
  );

  const [removeDocument, removeDocumentReady] = useRemoveDocument(vendorId);

  const onRemoveDocument = useCallback(
    (documentID: SecurityProfileDocumentID) => {
      openConfirmationModal({
        buttonAction: () => {
          removeDocument(documentID);
        },
        buttonText: "Remove document",
        title: "Are you sure you want to remove this document?",
        dangerousAction: true,
        description: (
          <>
            <p>
              This will remove matches and citations from this document from the
              Security Profile and may revert some checks to “Evidence
              required”.
            </p>
          </>
        ),
      });
    },
    [removeDocument, openConfirmationModal]
  );

  const rejectResult = useCallback((documentChecks: SecurityProfileRisk[]) => {
    openConfirmationModal({
      buttonAction: () => {
        setOverrideLoading(true);
        return overrideDocumentCheckResult({
          vendorId,
          checks: documentChecks.map((c) => ({
            privateId: c.scanCheckID,
            publicId: c.publicScanCheckID,
          })),
        })
          .unwrap()
          .then(() => {
            dispatch(addDefaultSuccessAlert("Rejected citation"));
          })
          .catch(() => {
            dispatch(addDefaultUnknownErrorAlert("Error rejecting citation"));
          })
          .finally(() => {
            setOverrideLoading(false);
          });
      },
      dangerousAction: true,
      buttonText: "Reject check results",
      title: "Are you sure you want to reject results of this check?",
      description: (
        <>
          <p>
            Rejecting it will revert it’s status to “Evidence required”.
            Citations for this check will also be rejected.
          </p>

          <p>This action cannot be undone.</p>
        </>
      ),
    });
  }, []);

  const vendorWords = useVendorWords();
  const assuranceType = useAssuranceType();
  const vendorName =
    useVendorDataSelector((data) => data?.name, vendorId) ??
    vendorWords.singularTitleCase;

  const [excludeCheckMutation] =
    VendorSecurityProfileAPI.useSetExcludedCheckMutation();
  const markCheckAsNA = useCallback(
    (include: boolean) => {
      openConfirmationModal({
        dangerousAction: !include,
        buttonText: include ? "Include check" : "Mark as N/A",
        title: `Are you sure you want to ${
          include ? "include check" : "mark check as N/A"
        }`,
        description: (
          <>
            <p>
              This check will {!include ? "excluded from" : "included in"} the
              security profile for this {vendorWords.singular}.
            </p>
            <p>This change will apply to all users in your organisation.</p>
          </>
        ),
        buttonAction: () => {
          setOverrideLoading(true);
          excludeCheckMutation({
            vendorId,
            checkId,
            include,
          })
            .unwrap()
            .then(() => {
              dispatch(
                addDefaultSuccessAlert(
                  include ? "Included check" : "Marked check as N/A"
                )
              );
            })
            .catch(() => {
              dispatch(addDefaultUnknownErrorAlert("Error updating check"));
            })
            .finally(() => {
              setOverrideLoading(false);
            });
        },
      });
    },
    [checkId, vendorId, vendorWords]
  );

  if (controlLoading || overrideLoading || !control || !check) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  return (
    <div
      className={classNames("check-panel", {
        ignored: checkState == ControlState.NA,
      })}
    >
      {!!onClickBack && <BackArrow popup={"Back"} onClick={onClickBack} />}
      <div className="check-header">
        <div className="title">{risk?.title ?? check.text}</div>
        {(checkState === ControlState.Unknown ||
          checkState === ControlState.NA) && (
          <Button onClick={() => markCheckAsNA(checkState === ControlState.NA)}>
            {checkState == ControlState.NA ? "Include check" : "Mark as N/A"}
          </Button>
        )}
        {checkState === ControlState.Failed &&
          risk &&
          (risk.riskType != VendorSummaryRiskType.SecurityProfile ||
            orgHasSecurityProfileRiskAdjustments) &&
          !risk.isWaived &&
          risk.severity !== SeverityInt.InfoSeverity && (
            <ManageRiskButton
              autoCloseOnMouseLeave
              hideRemediate={!!inRemediation || !!documentCheck}
              onRemediate={
                userHasWriteRemediationPermission
                  ? () => {
                      history.push(`${urlPrefix}/remediation/add`, {
                        backContext: {
                          goBack: true,
                          backToText: "Back to security profile",
                        },
                        goBackAfterSubmit: true,
                        breadcrumbs: [
                          ...getVendorPageBreadcrumbs(
                            vendorId,
                            vendorName,
                            assuranceType
                          ),
                          {
                            text: "Security profile",
                            to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&checkId=${checkId}&scanRiskId=${scanRiskId}&surveyRiskId=${surveyRiskId}`,
                          },
                        ],
                        riskId: risk.id,
                        riskType: scanningRisk
                          ? RiskSource.Cloudscan
                          : documentCheck
                            ? RiskSource.SecurityProfile
                            : RiskSource.Survey,
                      });
                    }
                  : undefined
              }
              onWaive={
                userHasWriteWaiversPermission
                  ? () => {
                      history.push(
                        `${urlPrefix}/riskwaivers/add?initialRiskId=${risk.id}`,
                        {
                          backContext: {
                            goBack: true,
                            backToText: `Back to security profile`,
                          },
                          breadcrumbs: [
                            ...getVendorPageBreadcrumbs(
                              vendorId,
                              vendorName,
                              assuranceType
                            ),
                            {
                              text: "Security profile",
                              to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&checkId=${checkId}&scanRiskId=${scanRiskId}&surveyRiskId=${surveyRiskId}`,
                            },
                          ],
                        }
                      );
                    }
                  : undefined
              }
              hideAdjust={
                ((!documentCheck || !!documentCheck.baseSeverity) &&
                  (!surveyRisk || !!surveyRisk.baseSeverity)) ||
                !userHasWriteWaiversPermission
              }
              onAdjust={() => {
                history.push(
                  `${urlPrefix}/riskwaivers/severityadjustments/add?initialRiskId=${
                    surveyRiskId && surveyRiskId != "" ? surveyRiskId : risk.id
                  }`,
                  {
                    backContext: {
                      backTo: `${urlPrefix}/securityprofile?vendorId=${vendorId}&controlId=${controlId}`,
                      backToText: "Back to security profile",
                    },
                    breadcrumbs: [
                      ...getVendorPageBreadcrumbs(
                        vendorId,
                        vendorName,
                        assuranceType
                      ),
                      {
                        text: "Security profile",
                        to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&controlId=${controlId}`,
                      },
                    ],
                  }
                );
              }}
            />
          )}
        {risk?.isWaived && (
          <TooltipButton
            primary
            disabled
            tooltipContent={"Manage risk not available for waived risks"}
            popupPosition="left"
            popupNoWrap
          >
            <div className="manage-risk-text">Manage risk</div>
            <div className="cr-icon-chevron rot90" />
          </TooltipButton>
        )}
      </div>
      <ScrollableDiv newStyles>
        <CheckItemSlidePanelSection title="Check details" startExpanded={true}>
          <div className="detail-table">
            <InfoTable>
              <InfoTableRow
                label="RESULT"
                rowClass="detail-row"
                valueClass="status-value"
                value={
                  <>
                    {checkState === ControlState.Passed && (
                      <>
                        {risk?.isWaived ? (
                          <PillLabel color={LabelColor.Grey}>
                            Risk waived
                          </PillLabel>
                        ) : risk?.severity === SeverityInt.InfoSeverity ? (
                          <PillLabel color={LabelColor.Grey}>
                            Info risk detected
                          </PillLabel>
                        ) : (
                          <PillLabel color={LabelColor.Green}>
                            Check passed
                          </PillLabel>
                        )}
                      </>
                    )}
                    {checkState === ControlState.Failed && (
                      <>
                        {inRemediation ? (
                          <PillLabel color={LabelColor.Blue}>
                            Risk in remediation
                          </PillLabel>
                        ) : risk?.severity === SeverityInt.InfoSeverity ? (
                          <PillLabel color={LabelColor.Grey}>
                            Info risk detected
                          </PillLabel>
                        ) : (
                          <PillLabel color={LabelColor.Grey}>
                            Risk detected
                          </PillLabel>
                        )}
                      </>
                    )}
                    {checkState === ControlState.Unknown && (
                      <PillLabel color={LabelColor.Grey}>
                        Evidence required
                      </PillLabel>
                    )}
                    {checkState == ControlState.NA && (
                      <PillLabel color={LabelColor.Grey}>N/A</PillLabel>
                    )}
                  </>
                }
                thirdCol={
                  checkState != ControlState.Unknown &&
                  documentCheck?.securityProfiles && (
                    <Button
                      tertiary
                      onClick={() =>
                        rejectResult(documentCheck?.securityProfiles ?? [])
                      }
                    >
                      <i className={"cr-icon-cancel"} /> Reject result
                    </Button>
                  )
                }
              />
              {(checkState == ControlState.Failed ||
                risk?.severity === SeverityInt.InfoSeverity) && (
                <InfoTableRow
                  rowClass="detail-row"
                  label="RISK SEVERITY"
                  value={
                    risk ? (
                      <>
                        <AdjustedSeverityIcon
                          severity={SeverityAsString(risk.severity)}
                          baseSeverity={
                            risk.baseSeverity
                              ? SeverityAsString(risk.baseSeverity)
                              : undefined
                          }
                        />
                        {upperFirst(SeverityAsString(risk.severity))}
                      </>
                    ) : (
                      <SeverityIcon
                        severity={SeverityAsString(check.severity)}
                      />
                    )
                  }
                />
              )}
              {checkState != ControlState.NA && (
                <InfoTableRow
                  rowClass="detail-row"
                  label="LAST SCANNED"
                  value={scannedAt ? moment(scannedAt).format(timeFormat) : "-"}
                />
              )}
            </InfoTable>
          </div>
        </CheckItemSlidePanelSection>
        {(risk?.summary || risk?.description) && (
          <CheckItemSlidePanelSection title="Summary" startExpanded={true}>
            <p>{risk?.summary || risk?.description}</p>
          </CheckItemSlidePanelSection>
        )}
        {risk?.riskDetails && (
          <CheckItemSlidePanelSection title="Risk details" startExpanded={true}>
            <p>{risk.riskDetails}</p>
          </CheckItemSlidePanelSection>
        )}
        {risk?.recommendedRemediation && (
          <CheckItemSlidePanelSection
            title="Recommended remediation"
            startExpanded={false}
          >
            <p>{risk.recommendedRemediation}</p>
          </CheckItemSlidePanelSection>
        )}
        {checkState == ControlState.Failed && (
          <CheckItemSlidePanelSection
            title={"Comments"}
            startExpanded={
              (riskComments?.[
                scanRiskId && scanRiskId != "" ? scanRiskId : checkId
              ]?.comments ?? "") != ""
            }
          >
            <RiskSummaryComment
              comment={
                riskComments?.[
                  scanRiskId && scanRiskId != "" ? scanRiskId : checkId
                ]?.comments ?? ""
              }
              title={"Comments"}
              readonly={false}
              onUpdateComment={debounce(onUpdateRiskComments, 1000)}
              hideSideContent
            />
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk && (
          <CheckItemSlidePanelSection
            title={"Assets affected"}
            startExpanded={false}
          >
            <RiskVendorDomains
              riskId={scanningRisk.id}
              vendorId={vendorId}
              onClickDomain={(
                _riskId: string,
                _vendorId: number | undefined,
                hostname: string
              ) => {
                OpenSidePanel(
                  history,
                  {
                    scan: hostname,
                  },
                  check.text,
                  location
                );
              }}
              userHasWriteRiskWaiversPermission={userHasWriteWaiversPermission}
              onRequestRemediation={
                userHasWriteRemediationPermission && !inRemediation
                  ? (hostname) => {
                      history.push(`${urlPrefix}/remediation/add`, {
                        backContext: {
                          goBack: true,
                          backToText: "Back to security profile",
                        },
                        breadcrumbs: [
                          ...getVendorPageBreadcrumbs(
                            vendorId,
                            vendorName,
                            assuranceType
                          ),
                          {
                            text: "Security profile",
                            to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&checkId=${checkId}&scanRiskId=${scanRiskId}&surveyRiskId=${surveyRiskId}`,
                          },
                        ],
                        filterForWebsite: hostname,
                        preselectedRisks: [{ checkId: scanningRisk.id }],
                      });
                    }
                  : undefined
              }
              onCreateWaiver={
                userHasWriteWaiversPermission
                  ? (hostname) => {
                      history.push(
                        `${urlPrefix}/riskwaivers/add?initialRiskId=${scanningRisk.id}&hostname=${hostname}`,
                        {
                          backContext: {
                            goBack: true,
                            backToText: "Back to security profile",
                          },
                          breadcrumbs: [
                            ...getVendorPageBreadcrumbs(
                              vendorId,
                              vendorName,
                              assuranceType
                            ),
                            {
                              text: "Security profile",
                              to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&checkId=${checkId}&scanRiskId=${scanRiskId}&surveyRiskId=${surveyRiskId}`,
                            },
                          ],
                        }
                      );
                    }
                  : undefined
              }
              loadWaived={false}
              showWaived={false}
              isWaived={false}
              isSubsidiary={false}
            />
          </CheckItemSlidePanelSection>
        )}
        {surveyRisk && (
          <CheckItemSlidePanelSection
            title="Compensating Controls"
            startExpanded={true}
          >
            <div className="compensating-controls-container">
              {surveyRisk.surveys?.map((survey) => (
                <CompensatingControlInfoV2
                  key={survey.surveyId}
                  risk={survey}
                />
              ))}
            </div>
          </CheckItemSlidePanelSection>
        )}
        {securityProfiles && (
          <CheckItemSlidePanelSection
            title={`Citations (${securityProfiles.reduce(
              (acc, sp) => acc + sp.citations.length,
              0
            )})`}
            startExpanded={true}
          >
            <CitationSection
              checks={securityProfiles}
              vendorId={vendorId}
              overrideCheckResult={overrideCheckResult}
              onRemoveDocument={
                removeDocumentReady ? onRemoveDocument : undefined
              }
            />
          </CheckItemSlidePanelSection>
        )}
      </ScrollableDiv>
      {confirmationModal}
    </div>
  );
};

export default ControlCheckPanel;

import { FC, ReactNode } from "react";
import ReportCard from "../../_common/components/ReportCard";
import "../style/PublicTrustPageContentView.scss";
import GradeWithScoreCard from "../../_common/components/GradeWithScoreCard";
import ResourcePanel from "../components/ResourcePanel";
import QuestionnaireItem from "../components/QuestionnaireItem";
import DocumentItem from "../components/DocumentItem";
import { ContentLibraryDocumentRedacted } from "../../contentlibrary/types/contentLibrary.types";
import classnames from "classnames";
import { ComplianceBadgeTypes, ISecurityLink } from "../helpers/types";
import { isNotEmpty, openInNewTab } from "../helpers/helpers";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import CircledIcon from "../../_common/components/CircledIcon";
import RichTextEditV2 from "../../_common/components/RichTextEditV2";
import ComplianceBadge from "../components/ComplianceBadge";

const getSecurityLinksCard = (securityLinks?: ISecurityLink[]): ReactNode => {
  return securityLinks ? (
    <ReportCard newStyles={true} className={"security-links-card"}>
      <h2 className="header">Security links</h2>
      <div className={classnames("card-content", "security-links-card-inner")}>
        {securityLinks &&
          securityLinks.map((link) => (
            <div
              key={link.title}
              className={classnames("security-link", "clickable")}
              onClick={() => openInNewTab(link.url)}
            >
              {link.title}
              <div className="actions-col">
                <i className={"cr-icon-external-link"} />
              </div>
            </div>
          ))}
      </div>
    </ReportCard>
  ) : (
    <></>
  );
};

const getRightColPlaceholder = (vendorName: string): ReactNode => {
  return (
    <ReportCard className={"empty-card"}>
      <EmptyCardWithAction
        iconJSX={<CircledIcon iconClass={"cr-icon-trust-center-outline"} />}
        emptyText={`${vendorName}'s Trust Page is under construction`}
        emptySubText={
          `Looks like ${vendorName} is still getting their Trust Page ready. ` +
          "Security documents, compliance badges, and other important links may be added here later."
        }
      />
    </ReportCard>
  );
};

interface IPublicTrustPageContentViewProps {
  vendorName: string;
  companyDescription?: string;
  includeRiskRating: boolean;
  score: {
    currentScore: number;
    industryAvg?: number;
  };
  complianceBadges: ComplianceBadgeTypes[];
  questionnaires: {
    id: number;
    name?: string;
    description?: string;
    dateCompleted?: string;
  }[];
  attachments?: ContentLibraryDocumentRedacted[];
  securityLinks?: ISecurityLink[];
  onRequestAccess: () => void;
}

const PublicTrustPageContentView: FC<IPublicTrustPageContentViewProps> = ({
  vendorName,
  companyDescription,
  score,
  includeRiskRating,
  complianceBadges,
  questionnaires,
  attachments,
  securityLinks,
  onRequestAccess,
}) => {
  const showAboutCard = !!companyDescription;
  const showSecurityLinksCard = isNotEmpty(securityLinks);
  const showComplianceBadgesCard = isNotEmpty(complianceBadges);
  const showScoreCard = includeRiskRating || showComplianceBadgesCard;
  const showSurveys = isNotEmpty(questionnaires);
  const showDocs = isNotEmpty(attachments);
  const rightColumnIsEmpty = !showAboutCard && !showSurveys && !showDocs;
  const leftColumnIsEmpty =
    (!showScoreCard && !showSecurityLinksCard && !rightColumnIsEmpty) ||
    (rightColumnIsEmpty && showSecurityLinksCard);

  const showRightColPlaceholder = rightColumnIsEmpty && !leftColumnIsEmpty;

  const securityLinksCardElement: ReactNode =
    getSecurityLinksCard(securityLinks);

  return (
    <div className="trust-page-content">
      <div
        className={classnames("left-col", { empty: leftColumnIsEmpty })}
        data-testid="left-col"
      >
        {showScoreCard && (
          <ReportCard
            newStyles={true}
            className={"security-and-compliance-card"}
          >
            <h4 className="header">Security and compliance</h4>
            <div
              className={classnames(
                "card-content",
                "security-and-compliance-inner"
              )}
            >
              {includeRiskRating && (
                <GradeWithScoreCard
                  score={score.currentScore}
                  outOf={950}
                  industryAvg={score.industryAvg}
                />
              )}
              {showComplianceBadgesCard && (
                <div className="badges-container">
                  {complianceBadges.map((badge) => (
                    <ComplianceBadge key={badge} badgeType={badge} />
                  ))}
                </div>
              )}
            </div>
          </ReportCard>
        )}

        {showSecurityLinksCard &&
          // if right column is empty we want to move security links card to the right column
          !rightColumnIsEmpty &&
          securityLinksCardElement}
      </div>

      <div className="right-col" data-testid="right-col">
        {showAboutCard && (
          <ReportCard newStyles={true} className={"about-card"}>
            <h2 className="header">About</h2>
            <div className={classnames("card-content", "about-card-inner")}>
              <RichTextEditV2 readOnly value={companyDescription} />
            </div>
          </ReportCard>
        )}

        {(showSurveys || showDocs) && (
          <ReportCard newStyles={true} className={"trust-page-resources"}>
            <h2 className="header">Resources</h2>
            <div
              className={classnames(
                "card-content",
                "trust-page-resources-inner"
              )}
            >
              {showSurveys && (
                <ResourcePanel
                  header="Security questionnaires"
                  content={
                    questionnaires &&
                    questionnaires.map((q) => (
                      <QuestionnaireItem
                        key={q.id}
                        name={q.name ?? ""}
                        description={q.description}
                        updatedAt={q.dateCompleted}
                        readOnly={true}
                        onRequestAccess={onRequestAccess}
                      />
                    ))
                  }
                />
              )}

              {showDocs && (
                <ResourcePanel
                  header="Documents"
                  content={
                    attachments &&
                    attachments.map((doc) => (
                      <DocumentItem
                        key={doc.uuid}
                        name={doc.name}
                        dateUpdated={doc.updatedAt}
                        readOnly={true}
                        onRequestAccess={onRequestAccess}
                      />
                    ))
                  }
                />
              )}
            </div>
          </ReportCard>
        )}

        {rightColumnIsEmpty &&
          showSecurityLinksCard &&
          securityLinksCardElement}
        {showRightColPlaceholder && getRightColPlaceholder(vendorName)}
      </div>
    </div>
  );
};

export default PublicTrustPageContentView;

import { FC } from "react";
import TrustPageHeader from "../components/TrustPageHeader";
import TrustPageFooter from "../components/TrustPageFooter";
import "../style/PublicTrustPageView.scss";
import PublicTrustPageContentView from "./PublicTrustPageContentView";
import {
  ISharedAssessment,
  SharedAssessmentAccessResult,
} from "../../vendorrisk/types/sharedAssessment";
import DefaultCoverImage from "../../_common/images/verifiedvendor/default-verified-vendor-cover.svg";
import { mapEvidenceToISecurityLink } from "../helpers/types";
import { trackEvent } from "../../_common/tracking";
import { useHistory } from "react-router-dom";
import Tumbleweed from "../components/Tumbleweed";
import { isEmpty } from "../helpers/helpers";

interface IPublicTrustPageViewProps {
  sharedAssessment?: ISharedAssessment;
  publishedStateOverride?: boolean;
}

const PublicTrustPageView: FC<IPublicTrustPageViewProps> = ({
  sharedAssessment,
  publishedStateOverride,
}) => {
  const history = useHistory();

  if (!sharedAssessment) {
    // Shared assessment is undefined - not found or error fetching
    return (
      <div className="public-trust-page">
        <img
          className={"cover-img"}
          src={DefaultCoverImage}
          alt="Trust Page Cover"
        />
        <div className={"public-trust-page-wrapper"}>
          <TrustPageHeader
            canRequestAccess={false}
            onRequestAccess={() => {}}
          />
          <>
            <div className={"empty-content"}>
              <i className={"cr-icon-info"} />
              <span>
                An error occurred loading this page. Please contact
                support@upguard.com
              </span>
            </div>
          </>
          <TrustPageFooter />
        </div>
      </div>
    );
  }

  const navigateToAccountRegister = (sa: ISharedAssessment) => {
    trackEvent("SharedProfile_ExternalRequestAccess", { existingUser: false });

    if (window.CR_HOSTNAME !== window.location.origin) {
      window.location.href = `${window.CR_HOSTNAME}/trustpagepreview/${sa.uuid}/createaccount`;
    } else {
      history.push(`${sa.uuid}/createaccount`, {
        linkedVendorId: [sa.linkedVendor.id],
        vendorName: sa.linkedVendor.name,
      });
    }
  };

  const notPublished =
    !publishedStateOverride &&
    sharedAssessment.status === SharedAssessmentAccessResult.NotPublished;

  const noContent =
    sharedAssessment.companyDescription.length === 0 &&
    !sharedAssessment.includeRiskRating &&
    isEmpty(sharedAssessment.questionnaires) &&
    isEmpty(sharedAssessment.contentLibraryDocuments) &&
    isEmpty(sharedAssessment.evidencePages);

  return (
    <div className="public-trust-page">
      <img
        className={"cover-img"}
        src={sharedAssessment.coverImageMeta?.logoUrl ?? DefaultCoverImage}
        alt="Trust Page Cover"
      />
      <div className={"public-trust-page-wrapper"}>
        <TrustPageHeader
          vendorName={sharedAssessment.linkedVendor?.name}
          profileLogoType={sharedAssessment.profileLogo}
          profileLogoUrl={sharedAssessment.profileLogoMeta?.logoUrl}
          primaryHostName={sharedAssessment.linkedVendor?.primary_hostname}
          primaryContact={sharedAssessment.contactName}
          emailAddress={sharedAssessment.contactEmail}
          canRequestAccess={!notPublished}
          onRequestAccess={() => navigateToAccountRegister(sharedAssessment)}
        />
        {notPublished ? (
          <>
            <div
              className={"not-published-content"}
              data-testid={"not-published-content"}
            >
              <i className={"cr-icon-info"} />
              <span>
                The company you are looking for has no longer published their
                Trust Page.
              </span>
            </div>
          </>
        ) : (
          <>
            {noContent ? (
              <>
                <div className={"empty-content"} data-testid={"empty-content"}>
                  <Tumbleweed />
                  <span>
                    Looks like {sharedAssessment.linkedVendor.name} hasn&apos;t
                    set up their Trust Page. Check again soon.
                  </span>
                </div>
              </>
            ) : (
              <>
                <PublicTrustPageContentView
                  vendorName={sharedAssessment.linkedVendor.name}
                  companyDescription={sharedAssessment.companyDescription}
                  questionnaires={sharedAssessment.questionnaires}
                  includeRiskRating={sharedAssessment.includeRiskRating}
                  score={{
                    currentScore:
                      sharedAssessment.currentPublicScore ??
                      sharedAssessment.currentScore,
                    industryAvg: sharedAssessment.industryAverage?.score,
                  }}
                  complianceBadges={sharedAssessment.badges ?? []}
                  attachments={sharedAssessment.contentLibraryDocuments}
                  securityLinks={
                    sharedAssessment.evidencePages?.map((e) =>
                      mapEvidenceToISecurityLink(e)
                    ) ?? []
                  }
                  onRequestAccess={() =>
                    navigateToAccountRegister(sharedAssessment)
                  }
                />
              </>
            )}
          </>
        )}
        <TrustPageFooter />
      </div>
    </div>
  );
};

export default PublicTrustPageView;

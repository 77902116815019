import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import { useCallback } from "react";
import SurveyImportAPI from "../api/surveyImportAPI";
import { LogError } from "../../_common/helpers";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useDefaultHistory } from "../../_common/types/router";
import { VendorSurveysRouteState } from "../views/VendorSurveys";

export const useDeleteImportedSurvey = () => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory<VendorSurveysRouteState>();
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const [deleteImportedSurvey] =
    SurveyImportAPI.useDeleteImportedSurveyMutation();

  const deleteSurvey = useCallback(
    (surveyID: number) =>
      openConfirmationModal({
        title: "Are you sure you want to delete this questionnaire?",
        description: (
          <p>All users will lose access. This action is not reversible.</p>
        ),
        buttonText: "Delete",
        iconClass: "cr-icon-trash",
        dangerousAction: true,
        buttonAction: async () => {
          try {
            await deleteImportedSurvey({ surveyID }).unwrap();

            // return to the imported surveys page if we're on the specific survey page
            if (history.location.pathname.startsWith("/vendors/surveys/")) {
              history.replace("/vendors/surveys", {
                currentTab: "imported",
              });
            }

            dispatch(addDefaultSuccessAlert("Questionnaire deleted"));
          } catch (e) {
            LogError("error deleting imported survey", e);
            dispatch(
              addDefaultUnknownErrorAlert("Error deleting questionnaire")
            );
          }
        },
      }),
    [history, deleteImportedSurvey, dispatch, openConfirmationModal]
  );

  return [deleteSurvey, confirmationModal] as const;
};

import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import { Scope, UserApp, UserWithStats } from "../api/types";
import ScrollableDiv from "../../vendorrisk/components/ScrollableDiv";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSorting } from "../../_common/hooks";
import UserBaseAPI from "../api/userbase.api";
import { pluralise } from "../../_common/helpers";
import UserApprovalPill, { userApprovalStatus } from "./UserApprovalPill";
import CompanyLogo from "../../_common/components/CompanyLogo";
import Button from "../../_common/components/core/Button";

import classnames from "classnames";
import { useDefaultHistory } from "../../_common/types/router";
import { appSummaryUrl, userSummaryUrl } from "../UserBaseAppRouter";
import InfoTable, { InfoTableRow } from "../../_common/components/InfoTable";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";

import SecurityRatingDisplay, { RatingSize } from "./SecurityRatingDisplay";
import "./UserAppsForSpecificScopePanel.scss";

export interface UserAppsForSpecificScopePanelProps {
  user: UserWithStats;
  scope: Scope;
  className?: string;
}

const UserAppsForSpecificScopePanel: FC<UserAppsForSpecificScopePanelProps> = ({
  user,
  scope,
  className,
}) => {
  const history = useDefaultHistory();

  const onViewApplication = (app: UserApp) => {
    history.push(appSummaryUrl(app.name));
  };

  const onViewUser = () => {
    history.push(userSummaryUrl(user.uuid));
  };

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppsForUserScopeV1Query(
    {
      scopeExternalId: scope.externalID,
      userUUID: user.uuid,
    }
  );

  const appsToDisplay = data?.apps ?? [];

  const [sortedApps, sortedBy, onSortChange] = useSorting<
    UserApp,
    "name" | "security_rating" | "user_status"
  >(appsToDisplay ?? [], "name", SortDirection.ASC, {
    name: {
      orderFuncs: [(a) => a.name.toLocaleLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    security_rating: {
      orderFuncs: [(a) => a.securityScore],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    user_status: {
      orderFuncs: [(a) => userApprovalStatus(a.userIsApproved, a.userIsWaived)],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "app_name",
      text: "App name",
      sortable: true,
      className: "app-name-col",
    },
    {
      id: "security_rating",
      text: "Security rating",
      sortable: true,
      className: "security-rating-col",
    },
    {
      id: "user_status",
      text: "User status",
      sortable: true,
      className: "user-status-col",
    },
  ];

  const scopeIntoXTableRow = (a: UserApp): IXTableRow<string> => {
    return {
      id: a.name,
      className: "app-row",
      onClick: () => onViewApplication(a),
      iconOptions: [
        {
          id: "select",
          icon: <div className={"cr-icon-chevron"} />,
          onClick: () => onViewApplication(a),
        },
      ],
      cells: [
        <XTableCell key="company_details">
          <CompanyLogo name={a.name} domain={a.domain} />
        </XTableCell>,
        <XTableCell key="security_rating">
          <SecurityRatingDisplay
            rating={a.securityScore}
            size={RatingSize.Small}
          />
        </XTableCell>,

        <XTableCell key="user_status">
          <UserApprovalPill
            approved={a.userIsApproved}
            waived={a.userIsWaived}
          />
        </XTableCell>,
      ],
    };
  };

  const appsNumberString = isLoading
    ? "Apps detected"
    : `${pluralise(sortedApps.length, "App", "Apps")} detected (${
        sortedApps.length
      })`;

  return (
    <ReportCard
      newStyles
      className={classnames("ub-user-apps-for-specific-panel", className)}
    >
      <div className="header">
        {user.name}
        <div className="header-link">
          <Button onClick={onViewUser}>
            View user profile
            <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>

      <div className="content">
        <ScrollableDiv newStyles>
          <div>
            <div className="panel-section-desc">Permission details</div>
            <div key="permission_details">
              <InfoTable>
                <InfoTableRow
                  label="Exposure level"
                  rowClass="bottom-border top-border"
                  value={<ScopeRiskLevelDisplay riskLevel={scope.riskLevel} />}
                />
                <InfoTableRow
                  label="Scope"
                  rowClass="bottom-border"
                  value={scope.description}
                />
                <InfoTableRow
                  label="Source"
                  rowClass="bottom-border"
                  value={scope.name}
                />
              </InfoTable>
            </div>

            <div className={classnames("panel-section-desc gap32")}>
              {appsNumberString}
            </div>
          </div>

          <XTable
            className="app-list"
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            loading={isLoading}
            columnHeaders={columnHeaders}
            iconOptions
            rows={sortedApps.map(scopeIntoXTableRow)}
          />
        </ScrollableDiv>
      </div>
    </ReportCard>
  );
};

export default UserAppsForSpecificScopePanel;

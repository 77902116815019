import { FetchURLInRegions } from "../../_common/regionalDataStorageHelpers";
import { LogError } from "../../_common/helpers";
import { setRemediationRequestData } from "../../_common/reducers/commonActions";
import { setVendorAppRemediationRequestList } from "./actions";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { RemediationRequestWithMeta } from "../../_common/types/remediation";

export const fetchVendorAppRemediationRequestList = (force = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // Don't fetch if currently loading or we're not forcing
    if (!force && getState().vendorPortal.remediationRequestList) {
      return getState().vendorPortal.remediationRequestList;
    }

    let requests: RemediationRequestWithMeta[];

    dispatch(setVendorAppRemediationRequestList(undefined, true));

    try {
      const allResponses = await FetchURLInRegions<
        RemediationRequestWithMeta[]
      >(
        "remediationrequest/listforvendor/v1/",
        undefined,
        undefined,
        undefined,
        dispatch,
        getState
      );

      requests = allResponses.flat();
    } catch (e) {
      LogError("Error retrieving vendor app request list: ", e);

      dispatch(setVendorAppRemediationRequestList(undefined, false));
      throw new Error("Error retrieving list of remediation requests.");
    }

    // For each request, update the risks in common state first
    const requestIds = [];
    for (let i = 0; i < requests.length; i++) {
      const requestId = requests[i].id;
      requestIds.push(requestId);
      dispatch(
        setRemediationRequestData(requestId, {
          details: requests[i],
        })
      );
    }

    dispatch(setVendorAppRemediationRequestList(requestIds, false));

    return requestIds;
  };
};

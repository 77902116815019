import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { GetResultsV1ReqBody } from "./api/threatmonitoring.api";
import {
  ThreatMonitoringFeedType,
  ThreatMonitoringResultsDatePeriodOption,
  ThreatMonitoringResultsFilter,
} from "./api/types";
import { Tab } from "./views/ThreatMonitoringView";
import { Tab as DetailViewTab } from "./views/ThreatDetailView";
import { RootState } from "../_common/types/reduxStore";
import { OpenFeedSortOptions } from "./components/ThreatMonitoringFeed";

export type FeedTypes =
  | ThreatMonitoringFeedType.Investigating
  | ThreatMonitoringFeedType.Closed
  | ThreatMonitoringFeedType.Open
  | ThreatMonitoringFeedType.Remediating;

// default page sizes
export const OPENFEED_PAGESIZE = 20;
export const FEEDTABLES_PAGESIZE = 30;

type FeedState = {
  query: GetResultsV1ReqBody;
  filtersExpanded: boolean;
};
type SessionSnapshot =
  | { uuids: [] }
  | {
      uuids: string[];
      query: GetResultsV1ReqBody;
    };

export function isEmptySnapshot(
  snapshot: SessionSnapshot
): snapshot is { uuids: [] } {
  return snapshot.uuids.length === 0;
}

interface ThreatmonState {
  feeds: Record<FeedTypes, FeedState>;
  sessionSnapshot: SessionSnapshot;
  currentTab: Tab;
  countSince: number;
  currentDetailTab: DetailViewTab;
}

export const maxDatePeriod =
  ThreatMonitoringResultsDatePeriodOption.LastTwelveMonths;

export const defaultFilters = {
  [ThreatMonitoringFeedType.Open]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
  },
  [ThreatMonitoringFeedType.Investigating]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.AllTime,
  },
  [ThreatMonitoringFeedType.Remediating]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.AllTime,
  },
  [ThreatMonitoringFeedType.Closed]: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
  },
};

const initialState: ThreatmonState = {
  currentTab: Tab.Open,
  currentDetailTab: DetailViewTab.Details,
  countSince: 0,
  sessionSnapshot: { uuids: [] },
  feeds: {
    [ThreatMonitoringFeedType.Open]: {
      query: {
        feedType: ThreatMonitoringFeedType.Open,
        filters: defaultFilters[ThreatMonitoringFeedType.Open],
        pageOpts: {
          pageSize: OPENFEED_PAGESIZE,
          pageNum: 0,
          sortBy: OpenFeedSortOptions[0].option,
          sortDesc: OpenFeedSortOptions[0].desc,
        },
      },
      filtersExpanded: true,
    },
    [ThreatMonitoringFeedType.Investigating]: {
      query: {
        feedType: ThreatMonitoringFeedType.Investigating,
        filters: defaultFilters[ThreatMonitoringFeedType.Investigating],
        pageOpts: {
          pageSize: FEEDTABLES_PAGESIZE,
          pageNum: 0,
          sortBy: "updated_at",
          sortDesc: true,
        },
      },
      filtersExpanded: true,
    },
    [ThreatMonitoringFeedType.Closed]: {
      query: {
        feedType: ThreatMonitoringFeedType.Closed,
        filters: defaultFilters[ThreatMonitoringFeedType.Closed],
        pageOpts: {
          pageSize: FEEDTABLES_PAGESIZE,
          pageNum: 0,
          sortBy: "date_closed",
          sortDesc: true,
        },
      },
      filtersExpanded: true,
    },
    [ThreatMonitoringFeedType.Remediating]: {
      query: {
        feedType: ThreatMonitoringFeedType.Remediating,
        filters: defaultFilters[ThreatMonitoringFeedType.Remediating],
        pageOpts: {
          pageSize: FEEDTABLES_PAGESIZE,
          pageNum: 0,
          sortBy: "remediation_req_updated_at",
          sortDesc: true,
        },
      },
      filtersExpanded: true,
    },
  },
};

const tmSlice = createSlice({
  name: "threatmon",
  reducerPath: "threatmon",
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<Tab>) => {
      state.currentTab = action.payload;
    },
    setCurrentDetailTab: (state, action: PayloadAction<DetailViewTab>) => {
      state.currentDetailTab = action.payload;
    },
    setPageFilters: (
      state,
      action: PayloadAction<{
        feed: FeedTypes;
        filters: ThreatMonitoringResultsFilter;
      }>
    ) => {
      state.feeds[action.payload.feed].query.filters = action.payload.filters;
      state.feeds[action.payload.feed].query.pageOpts.pageNum = 0;
    },
    updatePageFilters: (
      state,
      action: PayloadAction<{
        feed: FeedTypes;
        filters: Partial<ThreatMonitoringResultsFilter>;
      }>
    ) => {
      state.feeds[action.payload.feed].query.filters = {
        ...state.feeds[action.payload.feed].query.filters,
        ...action.payload.filters,
      };
      state.feeds[action.payload.feed].query.pageOpts.pageNum = 0;
    },
    resetPageFiltersExcludingDateFilter: (
      state,
      action: PayloadAction<FeedTypes>
    ) => {
      const datePeriod = state.feeds[action.payload].query.filters.datePeriod;

      state.feeds[action.payload].query.filters = {
        ...defaultFilters[action.payload],
        datePeriod: datePeriod,
      };
    },
    setPageNumber: (
      state,
      action: PayloadAction<{ feed: FeedTypes; page: number }>
    ) => {
      state.feeds[action.payload.feed].query.pageOpts.pageNum =
        action.payload.page;
    },
    nextPage: (state, action: PayloadAction<FeedTypes>) => {
      state.feeds[action.payload].query.pageOpts.pageNum += 1;
    },
    prevPage: (state, action: PayloadAction<FeedTypes>) => {
      if (state.feeds[action.payload].query.pageOpts.pageNum > 0)
        state.feeds[action.payload].query.pageOpts.pageNum -= 1;
    },
    setSortBy: (
      state,
      action: PayloadAction<{
        feed: FeedTypes;
        sort: { option: string; desc: boolean };
      }>
    ) => {
      const pageOpts = state.feeds[action.payload.feed].query.pageOpts;

      pageOpts.sortBy = action.payload.sort.option;
      pageOpts.sortDesc = action.payload.sort.desc;
      pageOpts.pageNum = 0;
    },
    snapshotSession: (state, action: PayloadAction<SessionSnapshot>) => {
      state.sessionSnapshot = action.payload;
    },
    clearSessionSnapshot: (state) => {
      state.sessionSnapshot = { uuids: [] };
    },
    setCountSince: (state, action: PayloadAction<number>) => {
      state.countSince = action.payload;
    },
    updateFeedState: (
      state,
      action: PayloadAction<{
        feed: FeedTypes;
        state: Partial<FeedState>;
      }>
    ) => {
      state.feeds[action.payload.feed] = {
        ...state.feeds[action.payload.feed],
        ...action.payload.state,
      };
    },
  },
  selectors: {
    selectOpenFeedPageReq: (state) =>
      state.feeds[ThreatMonitoringFeedType.Open],
    feedPages: (state) => state.feeds,
    selectPageOpts: (state) =>
      state.feeds[ThreatMonitoringFeedType.Open].query.pageOpts,
    pageNumber: (state) =>
      state.feeds[ThreatMonitoringFeedType.Open].query.pageOpts.pageNum,
    pageNumberForFeed: (state, feed: FeedTypes) =>
      state.feeds[feed].query.pageOpts.pageNum,
    feedPageReq: (state, feed: FeedTypes) => state.feeds[feed],
    sessionSnapshot: (state) => state.sessionSnapshot,
    currentTab: (state) => state.currentTab,
    currentDetailTab: (state) => state.currentDetailTab,
    countSince: (state) => state.countSince,
  },
});

export const selectFeedState = createSelector(
  [
    (feed: FeedTypes) => feed,
    (_: FeedTypes, state: RootState) => tmSlice.selectSlice(state),
  ],
  (feed, sliceState) => ({
    ...sliceState.feeds[feed],
  })
);

export const selectFeedPageReq = createSelector(
  [
    (feed: FeedTypes) => feed,
    (_: FeedTypes, state: RootState) => tmSlice.selectSlice(state),
  ],
  (feed, sliceState) => ({
    ...sliceState.feeds[feed].query,
  })
);

export default tmSlice;

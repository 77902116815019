import { FC, useCallback } from "react";
import withRequireEntitlement from "../../../_common/hocs/WithRequireEntitlement";
import { OrgTrustPageCustomDomain } from "../../../_common/permissions";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";
import { useModalV2 } from "../../../_common/components/ModalV2";
import TrustPageAddCustomDomainModal from "./TrustPageAddCustomDomainModal";
import TrustPageCustomDomainsTable from "./TrustPageCustomDomainsTable";
import { useInterval } from "../../../_common/hooks";
import TrustPageCustomDomainDnsConfigModal from "./TrustPageCustomDomainDnsConfigModal";
import TrustPageCustomDomainsAPI from "../../api/trustPageCustomDomains.api";
import NewFeaturePill, {
  NewFeatureType,
} from "../../../_common/components/NewFeaturePill";
import "./TrustPageCustomDomainsCard.scss";

interface TrustPageCustomDomainsCardProps {}

const TrustPageCustomDomainsCard: FC<
  TrustPageCustomDomainsCardProps
> = ({}) => {
  const { data, isLoading } =
    TrustPageCustomDomainsAPI.useGetTrustPageCustomDomainsV1Query();
  const [verifyDomain] =
    TrustPageCustomDomainsAPI.useVerifyTrustPageCustomDomainV1Mutation();

  const [openAddDomainModal, addDomainModal] = useModalV2(
    TrustPageAddCustomDomainModal
  );

  const [openDnsConfigModal, dnsConfigModal] = useModalV2(
    TrustPageCustomDomainDnsConfigModal
  );

  const verifyPendingDomains = useCallback(() => {
    if (data?.domains) {
      data.domains.forEach((d) => {
        if (d.status == "pending_dns") {
          verifyDomain(d);
        }
      });
    }
  }, [data]);

  const domainClicked = useCallback((domain: string) => {
    openDnsConfigModal({ domain });
  }, []);

  // Start an interval to trigger polling any pending domains
  useInterval(verifyPendingDomains, 15000);

  let descText =
    "Set up your Trust Page to live on a domain that you already own.";
  if (data?.domains?.some((d) => d.status === "complete")) {
    descText = "Your custom domain is active.";
  } else if (
    data?.domains?.some(
      (d) => d.status === "pending_dns" || d.status === "issue_detected"
    )
  ) {
    descText = "Your custom domain is not active.";
  }

  return (
    <ReportCard newStyles className={"trust-page-custom-domains-card"}>
      <div className={"header"}>
        <div>
          <div className={"header-title"}>
            Custom domain
            <NewFeaturePill
              featureType={NewFeatureType.TrustPageCustomDomain}
            />
          </div>
          <div className={"description"}>{descText}</div>
        </div>
        {!isLoading && !data?.domains && (
          <Button onClick={() => openAddDomainModal({})}>
            <i className="icon-x rotate-45"></i> Add custom domain
          </Button>
        )}
      </div>
      {!isLoading && data?.domains?.length && (
        <TrustPageCustomDomainsTable
          domains={data.domains}
          onDomainClick={domainClicked}
        />
      )}
      {addDomainModal}
      {dnsConfigModal}
    </ReportCard>
  );
};

export default withRequireEntitlement(
  TrustPageCustomDomainsCard,
  OrgTrustPageCustomDomain
);

export type product =
  | "vendor_risk"
  | "breachsight"
  | "user_risk"
  | "trust_exchange"
  | "analyst_portal"
  | undefined;

export type breachSightNavigationItems =
  | "executive_summary"
  | "risk_profile"
  | "remediation"
  | "risk_waivers"
  | "domains"
  | "ip_addresses"
  | "vulnerabilities"
  | "detected_products"
  | "subsidiaries"
  | "subsidiaries_menu"
  | "identity_breaches"
  | "typosquatting"
  | "cloud";

export type trustExchangeNavigationItems =
  | "content_library"
  | "trust_page"
  | "questionnaires"
  | "additional_evidence_requests"
  | "remediation_requests"
  | "risk_waiver_approvals"
  | "risk_adjustment_approvals";

export type NavigationItem = {
  navigationPath: string;
};

export const TrustExchangeNavigationItems: {
  [key in trustExchangeNavigationItems]: NavigationItem;
} = {
  content_library: {
    navigationPath: "/contentlibrary",
  },
  trust_page: {
    navigationPath: "/trustpage",
  },
  questionnaires: {
    navigationPath: "/vendors/surveys",
  },
  additional_evidence_requests: {
    navigationPath: "/vendors/additionalevidence",
  },
  remediation_requests: {
    navigationPath: "/vendors/remediation",
  },
  risk_waiver_approvals: {
    navigationPath: "/vendors/riskwaiverapprovals",
  },
  risk_adjustment_approvals: {
    navigationPath: "/vendors/riskadjustmentapprovals",
  },
};

export type AnalystPortalNavigationItems =
  | "vendor_assessments"
  | "cyber_research";

import "./EventTimeSeriesChart.scss";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { addSimpleErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import classnames from "classnames";
import { IntervalFrequency } from "../api/threatmonitoring.api";
import {
  ThreatMonitoringFeedType,
  ThreatMonitoringResultsDatePeriodOption,
} from "../api/types";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { selectFeedPageReq } from "../Slice";
import { getDateOfLastFeedDownload } from "../views/ThreatMonitoringView";

type intervalType = "hour" | "day" | "month";

const datePeriodOptionToIntervalType = (
  datePeriod?: ThreatMonitoringResultsDatePeriodOption
): intervalType => {
  if (!datePeriod) {
    return "hour";
  }

  switch (datePeriod) {
    case ThreatMonitoringResultsDatePeriodOption.LastTwentyFourHours:
      return "hour";
    case ThreatMonitoringResultsDatePeriodOption.LastSevenDays:
      return "day";
    case ThreatMonitoringResultsDatePeriodOption.LastThirtyDays:
      return "day";
    case ThreatMonitoringResultsDatePeriodOption.LastThreeMonths:
      return "month";
    case ThreatMonitoringResultsDatePeriodOption.LastSixMonths:
      return "month";
    case ThreatMonitoringResultsDatePeriodOption.LastTwelveMonths:
      return "month";
    default: {
      console.error(`unhandled date period ${datePeriod}`);
      return "hour";
    }
  }
};

interface EventTimeSeriesChartProps {
  hidden?: boolean;
}

// EventTimeSeries displays a temporal distribution of threat monitoring results.
const EventTimeSeriesChart = (props: EventTimeSeriesChartProps) => {
  const dispatch = useAppDispatch();
  const feedQuery = useAppSelector((s) =>
    selectFeedPageReq(ThreatMonitoringFeedType.Open, s)
  );

  const { data, isFetching, isError, error } =
    ThreatMonitoringAPI.useGetResultsTemporalDistributionV1Query(
      { ...feedQuery, feedUpTo: getDateOfLastFeedDownload() },
      {
        refetchOnMountOrArgChange: false,
        skip: props.hidden,
      }
    );

  const intervalCalculations = data ? data.interval_calculations : [];
  const interval = datePeriodOptionToIntervalType(
    feedQuery.filters?.datePeriod
  );

  // Mutually exclusive states this component can be in.
  const renderError = isError;
  const renderLoading = !isError && isFetching;
  const renderEmpty =
    !isError && !isFetching && intervalCalculations.length == 0;
  const renderResults =
    !isError && !isFetching && intervalCalculations.length > 0;

  // Raise error only once.
  const [previousIsError, setPreviousIsError] = useState(false);
  if (previousIsError != isError) {
    setPreviousIsError(isError);

    console.error(` loading event times series data: ${JSON.stringify(error)}`);

    dispatch(
      addSimpleErrorAlert("Failed to load threat graph", [
        "Refresh your browser to try again. If the issue persists please contact support.",
      ])
    );
  }

  return (
    !renderEmpty && (
      <div
        className={classnames(
          "threat-monitoring-event-time-series-chart-container",
          { hidden: props.hidden }
        )}
      >
        {renderError && (
          <div className="error-container">
            <span>
              {
                "Failed to load threat graph. Refresh your browser to try again."
              }
            </span>
          </div>
        )}
        {renderLoading && (
          <div className="loading-icon-container">
            <LoadingIcon />
          </div>
        )}
        {renderResults && renderChart(intervalCalculations, interval)}
      </div>
    )
  );
};

const renderChart = (
  intervalCalculations: IntervalFrequency[],
  interval: intervalType
) => {
  const lastIntervalCalculation =
    intervalCalculations[intervalCalculations.length - 1];
  const allIntervalsInSameYear =
    intervalCalculations[0].start.getFullYear() ===
    lastIntervalCalculation.start.getFullYear();
  const allIntervalsInSameMonth =
    allIntervalsInSameYear &&
    intervalCalculations[0].start.getMonth() ===
      lastIntervalCalculation.start.getMonth();
  const allIntervalsOnSameDay =
    allIntervalsInSameMonth &&
    intervalCalculations[0].start.getDay() ===
      lastIntervalCalculation.start.getDay();

  let hasOnlyOneAxis2Label = true;
  switch (interval) {
    case "hour": {
      hasOnlyOneAxis2Label = allIntervalsOnSameDay;
      break;
    }

    case "day": {
      hasOnlyOneAxis2Label = allIntervalsInSameMonth;
      break;
    }

    case "month": {
      hasOnlyOneAxis2Label = allIntervalsInSameYear;
      break;
    }

    default: {
      console.error(`unhandled interval "${interval}"`);
    }
  }

  const xAxis1ID = "xAxis1";
  const xAxis2ID = "xAxis2";

  let middleIntervalCalculationIndex = intervalCalculations.length / 2;
  if (intervalCalculations.length % 2 === 0) {
    middleIntervalCalculationIndex =
      Math.floor(middleIntervalCalculationIndex) - 1;
  } else {
    middleIntervalCalculationIndex =
      Math.ceil(middleIntervalCalculationIndex) - 1;
  }

  const hasResults = intervalCalculations.some((i) => i.frequency > 0);

  return (
    <div className="chart-container">
      <Bar
        data={{
          labels: intervalCalculations.map((i) => {
            const detectedStr = `${i.frequency} open threat${
              i.frequency == 1 ? "" : "s"
            }`;

            switch (interval) {
              case "hour": {
                return `${detectedStr} at ${moment(i.start).format(
                  "HH:mm, D MMM YYYY"
                )}`;
              }

              case "day": {
                return `${detectedStr} on ${moment(i.start).format(
                  "D MMM YYYY"
                )}`;
              }

              case "month": {
                return `${detectedStr} in ${moment(i.start).format(
                  "MMM YYYY"
                )}`;
              }

              default: {
                console.error(`unhandled interval "${interval}"`);

                return i.start.toISOString();
              }
            }
          }),
          datasets: [
            {
              xAxisID: xAxis1ID,
              label: "main",
              data: intervalCalculations.map((i) => i.frequency),
              backgroundColor: "#E8EEF9",
            },
          ],
        }}
        options={{
          layout: {
            padding: 0,
          },
          tooltips: {
            mode: "nearest",
            intersect: false,
            callbacks: {
              title: function (
                item: Chart.ChartTooltipItem[]
              ): string | string[] {
                return item[0]?.label || "";
              },
              label: function (): string | string[] {
                return "";
              },
            },
          },
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                id: xAxis1ID,
                offset: true,
                gridLines: {
                  drawBorder: false,
                  display: false,
                },
                stacked: true,
                ticks: {
                  autoSkip: false,
                  maxRotation: 0,
                  minRotation: 0,
                  fontColor: "#7A81AE",
                  fontSize: 12,
                  callback: function (_: string | number, index: number) {
                    const label = intervalCalculations[index].start;

                    switch (interval) {
                      case "hour": {
                        return moment(label).format("HH:mm");
                      }

                      case "day": {
                        return moment(label).format("D");
                      }

                      case "month": {
                        return moment(label).format("MMM");
                      }

                      default: {
                        console.error(`unhandled interval "${interval}"`);

                        return label.toISOString();
                      }
                    }
                  },
                },
              },
              {
                id: xAxis2ID,
                offset: true,
                gridLines: {
                  drawBorder: false,
                  display: false,
                },
                stacked: true,
                ticks: {
                  autoSkip: false,
                  maxRotation: 0,
                  minRotation: 0,
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontStyle: "bold",
                  fontColor: "#131520",
                  callback: function (_: string | number, index: number) {
                    const label = intervalCalculations[index].start;

                    switch (interval) {
                      case "hour": {
                        if (
                          (hasOnlyOneAxis2Label &&
                            index === middleIntervalCalculationIndex) ||
                          (!hasOnlyOneAxis2Label &&
                            (index === 0 || label.getHours() === 0))
                        ) {
                          return moment(label).format("ddd Do MMM");
                        }

                        return "";
                      }

                      case "day": {
                        if (
                          (hasOnlyOneAxis2Label &&
                            index === middleIntervalCalculationIndex) ||
                          (!hasOnlyOneAxis2Label &&
                            (index === 0 || label.getDate() === 1))
                        ) {
                          return moment(label).format("MMM");
                        }

                        return "";
                      }

                      case "month": {
                        if (
                          (hasOnlyOneAxis2Label &&
                            index === middleIntervalCalculationIndex) ||
                          (!hasOnlyOneAxis2Label &&
                            (index === 0 || label.getMonth() === 0))
                        ) {
                          return moment(label).format("YYYY");
                        }

                        return "";
                      }

                      default: {
                        console.error(`unhandled interval "${interval}"`);

                        return label.toISOString();
                      }
                    }
                  },
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: hasResults,
                  beginAtZero: true,
                  autoSkip: true,
                  maxTicksLimit: 5,
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontColor: "#7A81AE",
                  padding: 8,
                  stepSize: 1,
                },
                gridLines: {
                  display: true,
                  drawBorder: false,
                  drawOnChartArea: true,
                  color: "#F9F9F9",
                  zeroLineColor: "#F9F9F9",
                  lineWidth: 1,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default EventTimeSeriesChart;

import "../style/components/CircledIcon.scss";
import classNames from "classnames";

interface ICircledIconProps {
  iconClass: string;
  green?: boolean;
}

const CircledIcon = ({ iconClass, green }: ICircledIconProps) => (
  <div className={classNames("circled-icon", { green })}>
    <div className={iconClass} />
  </div>
);

export default CircledIcon;

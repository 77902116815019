import { memo, Fragment } from "react";
import PropTypes from "prop-types";

// HighlightWordPart takes a string and a search term, and adds <strong> tags around
// any parts of the word that match the term.
const HighlightWordPart = ({ text, searchTerm }) => {
  if (!text) {
    return null;
  }

  if (searchTerm.length === 0) {
    // Empty search term so just return the text as-is
    return <>{text}</>;
  }

  const searchTermLower = searchTerm.toLowerCase();

  // Split the text into words, looking for the search term
  const words = [];
  let curWord = "";
  for (let i = 0; i < text.length; i++) {
    curWord += text[i];
    const matchIdx = curWord.toLowerCase().indexOf(searchTermLower);
    if (matchIdx > -1) {
      const firstPart = curWord.slice(0, matchIdx);
      const secondPart = curWord.slice(matchIdx);

      if (firstPart) {
        words.push({ word: firstPart, strong: false });
      }

      words.push({ word: secondPart, strong: true });
      curWord = "";
    }
  }

  if (curWord) {
    words.push({ word: curWord, strong: false });
  }

  return (
    <>
      {words.map((w, i) =>
        w.strong ? (
          <strong key={i}>{w.word}</strong>
        ) : (
          <Fragment key={i}>{w.word}</Fragment>
        )
      )}
    </>
  );
};

HighlightWordPart.propTypes = {
  text: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default memo(HighlightWordPart);

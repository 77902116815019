import { useState } from "react";
import { Node, NodeType } from "../types/types";
import Button from "../../_common/components/core/Button";
import ConditionalVisibilityEditor, {
  addBlankConditionalExpressionToTopLevel,
} from "./ConditionalVisibilityEditor";
import {
  removeSurveyValidationErrorsForNode,
  updateNode,
} from "../reducers/actions";
import { numConditionsInExpression } from "../types/conditionalLogic";

import "../style/ConditionalVisibilityStatus.scss";
import IconButton, { HoverColor } from "../../_common/components/IconButton";
import { ValidationError } from "../types/validation";
import classnames from "classnames";
import { AppDispatch } from "../../_common/types/reduxStore";

export interface IConditionalVisibilityStatusProps {
  dispatch: AppDispatch;
  surveyId: string;
  node: Node;
  questionNumber: string;
  validationErrors: {
    [validationError: string]: true; // The keys should map to the ValidationError enum
  };
  readOnly: boolean;
}

const ConditionalVisibilityStatus = (
  props: IConditionalVisibilityStatusProps
) => {
  const [modalActive, setModalActive] = useState(false);

  const numConditions = numConditionsInExpression(
    props.node.conditionalExpression
  );

  const hasValidationError =
    props.validationErrors[ValidationError.conditionalInvalidNodeId] ||
    props.validationErrors[ValidationError.conditionalInvalidAnswerId];

  let questionNumber = props.questionNumber;

  // if this is a risk node, we need to add one to the question number as they aren't assigned their own. This is so
  // the previous question will be included in the list available.
  if (props.node.type === NodeType.Risk) {
    const questionNumberArr = questionNumber
      .split(".")
      .slice(0, -1)
      .map((n) => parseInt(n));
    questionNumberArr[questionNumberArr.length - 1] += 1;
    questionNumber = questionNumberArr.join(".") + ".";
  }

  return (
    <>
      {modalActive && (
        <ConditionalVisibilityEditor
          dispatch={props.dispatch}
          surveyId={props.surveyId}
          node={props.node}
          questionNumber={questionNumber}
          closeModal={() => setModalActive(false)}
        />
      )}
      {numConditions > 0 ? (
        <div
          className={classnames("cond-description", {
            error: hasValidationError,
          })}
        >
          <div>
            Only show if{" "}
            <span
              className="cond-link"
              onClick={props.readOnly ? undefined : () => setModalActive(true)}
            >
              {numConditions}{" "}
              {numConditions === 1 ? "condition is met" : "conditions are met"}
            </span>
          </div>
          <div className="actions">
            {!props.readOnly && (
              <>
                <IconButton
                  onClick={() => setModalActive(true)}
                  icon={<div className="cr-icon-pencil" />}
                  keepPropagation
                />
                <IconButton
                  onClick={() => {
                    props.dispatch(
                      updateNode(props.surveyId, props.node.nodeId, {
                        conditionalExpression: [],
                      })
                    );

                    // Any validation errors for this condition would now be irrelevant
                    props.dispatch(
                      removeSurveyValidationErrorsForNode(
                        props.surveyId,
                        props.node.nodeId,
                        [
                          ValidationError.conditionalInvalidNodeId,
                          ValidationError.conditionalInvalidAnswerId,
                        ]
                      )
                    );
                  }}
                  icon={<div className="cr-icon-trash" />}
                  hoverColor={HoverColor.Red}
                  keepPropagation
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <Button
          disabled={props.readOnly}
          tertiary
          onClick={() => {
            // Open the modal and add in the first condition
            addBlankConditionalExpressionToTopLevel(
              props.dispatch,
              props.surveyId,
              props.node.conditionalExpression || [],
              props.node.nodeId
            );
            setModalActive(true);
          }}
        >
          + Add conditional visibility
        </Button>
      )}
    </>
  );
};

export default ConditionalVisibilityStatus;

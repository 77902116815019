import { uniq as _uniq } from "lodash";

import { SeverityInt } from "./severityInt";
import { LabelClassification } from "./label";
import { CVSSInt } from "./cvss";

// given a list of enabled attributes it removes the ones not selected
// from an object representing selected values for attribute definitions
const filterAttributes = (
  enabledAttributes: number[],
  attributes?: { [key: number]: AttributeState }
): { [key: number]: AttributeState } =>
  Object.entries(attributes ?? {})
    .filter(([attrDefId, _]) => enabledAttributes.includes(parseInt(attrDefId)))
    .reduce(
      (acc, [attrDefId, v]) => {
        acc[parseInt(attrDefId)] = v;
        return acc;
      },
      {} as { [key: number]: AttributeState }
    );
const filterDateAttributes = (
  enabledAttributes: number[],
  attributes?: { [key: number]: DateAttributeState }
): { [key: number]: DateAttributeState } =>
  Object.entries(attributes ?? {})
    .filter(([attrDefId, _]) => enabledAttributes.includes(parseInt(attrDefId)))
    .reduce(
      (acc, [attrDefId, v]) => {
        acc[parseInt(attrDefId)] = v;
        return acc;
      },
      {} as { [key: number]: DateAttributeState }
    );

const isAttributeStateInvalid = (s: AttributeState): boolean => {
  if (s.matchesNone && s.matchesAll) return true;
  if (s.matchesAll && s.includesEmpty) return true;
  return (s.selectedValues?.length ?? 0) === 0 && !s.includesEmpty;
};

const isDateAttributeStateInvalid = (s: DateAttributeState): boolean =>
  (s.operator === DateAttributeOperator.Before && s.endDate === undefined) ||
  (s.operator === DateAttributeOperator.After && s.startDate === undefined) ||
  (s.operator === DateAttributeOperator.Between &&
    (s.startDate === undefined || s.endDate === undefined));

export enum NotificationType {
  VendorCSTARUnderThreshold = "VendorCSTARUnderThreshold",
  VendorCSTARAboveThreshold = "VendorCSTARAboveThreshold",
  VendorCSTARDroppedByIn = "VendorCSTARDroppedByIn",
  DomainCSTARAboveThreshold = "DomainCSTARAboveThreshold",
  DomainCSTARUnderThreshold = "DomainCSTARUnderThreshold",
  QuestionnaireCorrespondence = "QuestionnaireCorrespondence",
  QuestionnaireUserAdded = "QuestionnaireUserAdded",
  QuestionnaireAwaitingReview = "QuestionnaireAwaitingReview",
  QuestionnaireCompleted = "QuestionnaireCompleted",
  QuestionnaireResent = "QuestionnaireResent",
  QuestionnaireCancelled = "QuestionnaireCancelled",
  QuestionnaireRemediationRisksAdded = "QuestionnaireRemediationRisksAdded",
  QuestionnaireRemediationRisksRemoved = "QuestionnaireRemediationRisksRemoved",
  RemediationCorrespondence = "RemediationCorrespondence",
  RemediationUserAdded = "RemediationUserAdded",
  RemediationRisksAdded = "RemediationRisksAdded",
  RemediationRiskRemoved = "RemediationRiskRemoved",
  DataLeaksFindingPublished = "DataLeaksFindingPublished",
  EmailExposureDetected = "EmailExposureDetected",
  DataLeaksCorrespondence = "DataLeaksCorrespondence",
  QuestionnaireDueDateUpdated = "QuestionnaireDueDateUpdated",
  RemediationMarkedComplete = "RemediationMarkedComplete",
  TyposquatRegistered = "TyposquatRegistered",
  TyposquatRegistrationChanged = "TyposquatRegistrationChanged",
  RiskAcceptanceApproveReject = "RiskAcceptanceApproveReject",
  RiskAcceptanceExpiringSoon = "RiskAcceptanceExpiringSoon",
  DomainVulnerabilitiesDetected = "DomainVulnerabilitiesDetected",
  DomainVulnerabilityResolved = "DomainVulnerabilityResolved",
  VendorVulnerabilityDetected = "VendorVulnerabilityDetected",
  VendorVulnerabilityResolved = "VendorVulnerabilityResolved",
  NewFeatures = "NewFeatures",
  CustomerCSTARUnderThreshold = "CustomerCSTARUnderThreshold",
  CustomerCSTARDroppedByIn = "CustomerCSTARDroppedByIn",
  VerifiedVendorAccessRequest = "VerifiedVendorAccessRequest",
  RiskAssessmentPublished = "RiskAssessmentPublished",
  VendorDataLeakPublished = "VendorDataLeakPublished",
  VendorDataLeakClosed = "VendorDataLeakClosed",
  VendorAssetSharingRequested = "VendorAssetSharingRequested",
  VendorAssetSharingRequestActioned = "VendorAssetSharingRequestActioned",
  BreachNewsFeedArticle = "BreachNewsFeedArticle",
  ManagedServicesRequested = "ManagedServicesRequested",
  ManagedServicesQuestionnaire = "ManagedServicesQuestionnaire",
  ManagedServicesRemediation = "ManagedServicesRemediation",
  BreachNewsFeedNewsArticle = "BreachNewsFeedNewsArticle",
  VendorCSTARIncreasedByIn = "VendorCSTARIncreasedByIn",
  CustomerCSTARIncreasedByIn = "CustomerCSTARIncreasedByIn",
  RemediationRequestAutoClosedNotification = "RemediationRequestAutoClosedNotification",
  DomainRiskAdded = "DomainRiskAdded",
  DomainRiskRemoved = "DomainRiskRemoved",
  VendorRiskAdded = "VendorRiskAdded",
  VendorRiskRemoved = "VendorRiskRemoved",
  SurveyRiskAdded = "SurveyRiskAdded",
  SurveyRiskRemoved = "SurveyRiskRemoved",
  AdditionalEvidenceRiskAdded = "AdditionalEvidenceRiskAdded",
  AdditionalEvidenceRiskRemoved = "AdditionalEvidenceRiskRemoved",
  BreachNewsFeedDarkWebArticle = "BreachNewsFeedDarkWebArticle",
  SharedProfilePublish = "SharedProfilePublish",
  SharedProfileUpdated = "SharedProfileUpdated",
  RiskAssessmentDue = "RiskAssessmentDue",
  RiskAssessmentDueSoon = "RiskAssessmentDueSoon",
  SurveyDueNotificationType = "SurveyDue",
  SurveyResendDueNotificationType = "SurveyResendDue",
  SelfRemediationDueNotificationType = "SelfRemediationDue",
  VendorRemediationDueNotificationType = "VendorRemediationDue",
  VendorDateAttributeNotificationType = "VendorDateAttribute",
  ThreatMonitoringResultAdded = "ThreatMonitoringResultAdded",
}

export const NotificationTypeIDMap = {
  VendorCSTARUnderThreshold: 1,
  VendorCSTARAboveThreshold: 2,
  VendorCSTARDroppedByIn: 3,
  DomainCSTARAboveThreshold: 4,
  DomainCSTARUnderThreshold: 5,
  QuestionnaireCorrespondence: 6,
  QuestionnaireUserAdded: 7,
  QuestionnaireAwaitingReview: 8,
  QuestionnaireCompleted: 9,
  QuestionnaireResent: 10,
  QuestionnaireCancelled: 11,
  QuestionnaireRemediationRisksAdded: 12,
  QuestionnaireRemediationRisksRemoved: 13,
  RemediationCorrespondence: 14,
  RemediationUserAdded: 15,
  RemediationRisksAdded: 16,
  RemediationRiskRemoved: 17,
  DataLeaksFindingPublished: 18,
  EmailExposureDetected: 19,
  DataLeaksCorrespondence: 20,
  QuestionnaireDueDateUpdated: 21,
  RemediationMarkedComplete: 22,
  TyposquatRegistered: 23,
  TyposquatRegistrationChanged: 24,
  RiskAcceptanceApproveReject: 25,
  RiskAcceptanceExpiringSoon: 26,
  DomainVulnerabilitiesDetected: 27,
  NewFeatures: 28,
  CustomerCSTARUnderThreshold: 29,
  CustomerCSTARDroppedByIn: 31,
  VerifiedVendorAccessRequest: 32,
  RiskAssessmentPublished: 33,
  VendorDataLeakPublished: 34,
  VendorDataLeakClosed: 35,
  VendorAssetSharingRequested: 36,
  VendorAssetSharingRequestActioned: 37,
  BreachNewsFeedArticle: 38,
  ManagedServicesRequested: 39,
  ManagedServicesQuestionnaire: 40,
  ManagedServicesRemediation: 41,
  BreachNewsFeedNewsArticle: 42,
  VendorCSTARIncreasedByIn: 43,
  CustomerCSTARIncreasedByIn: 44,
  RemediationRequestAutoClosedNotificationType: 45,
  DomainRiskAdded: 46,
  DomainRiskRemoved: 47,
  VendorRiskAdded: 48,
  VendorRiskRemoved: 49,
  SurveyRiskAdded: 50,
  SurveyRiskRemoved: 51,
  AdditionalEvidenceRiskAdded: 52,
  AdditionalEvidenceRiskRemoved: 53,
  BreachNewsFeedDarkWebArticle: 54,
  SharedProfilePublish: 55,
  SharedProfileUpdated: 56,
  RiskAssessmentDue: 65,
  RiskAssessmentDueSoon: 66,
  SurveyDueNotificationType: 67,
  SurveyResendDueNotificationType: 68,
  SelfRemediationDueNotificationType: 69,
  VendorRemediationDueNotificationType: 70,
};

// Note: the below functions don't exactly match those in notifications_engine/domain.go
export const IsCustomerNotificationType = (type: NotificationType): boolean => {
  return (
    type === NotificationType.CustomerCSTARUnderThreshold ||
    type === NotificationType.CustomerCSTARDroppedByIn ||
    type === NotificationType.CustomerCSTARIncreasedByIn ||
    type === NotificationType.DomainCSTARAboveThreshold ||
    type === NotificationType.DomainCSTARUnderThreshold ||
    type === NotificationType.DomainRiskAdded ||
    type === NotificationType.DomainRiskRemoved
  );
};

export const IsVendorNotificationType = (type: NotificationType): boolean => {
  return (
    type === NotificationType.VendorCSTARUnderThreshold ||
    type === NotificationType.VendorCSTARDroppedByIn ||
    type === NotificationType.VendorCSTARIncreasedByIn ||
    type === NotificationType.VendorCSTARAboveThreshold ||
    type === NotificationType.VendorRiskAdded ||
    type === NotificationType.VendorRiskRemoved ||
    type == NotificationType.SurveyRiskAdded ||
    type == NotificationType.SurveyRiskRemoved ||
    type == NotificationType.AdditionalEvidenceRiskAdded ||
    type == NotificationType.AdditionalEvidenceRiskRemoved ||
    type == NotificationType.SharedProfilePublish ||
    type == NotificationType.SharedProfileUpdated ||
    type == NotificationType.RiskAssessmentPublished ||
    type == NotificationType.RiskAssessmentDue ||
    type == NotificationType.RiskAssessmentDueSoon ||
    type == NotificationType.SurveyDueNotificationType ||
    type == NotificationType.SurveyResendDueNotificationType ||
    type == NotificationType.VendorRemediationDueNotificationType ||
    type == NotificationType.VendorDateAttributeNotificationType
  );
};

export const IsRisksType = (type: NotificationType): boolean => {
  return (
    type === NotificationType.DomainRiskAdded ||
    type === NotificationType.DomainRiskRemoved ||
    type === NotificationType.VendorRiskAdded ||
    type === NotificationType.VendorRiskRemoved ||
    type == NotificationType.SurveyRiskAdded ||
    type == NotificationType.SurveyRiskRemoved ||
    type == NotificationType.AdditionalEvidenceRiskAdded ||
    type == NotificationType.AdditionalEvidenceRiskRemoved
  );
};

export enum NotificationsDefinitionParameter {
  Threshold = "Threshold",
  InDays = "InDays",
  Severity = "Severity",
  Attribute = "Attribute",
  CVSS = "CVSS",
}

export type NotificationParameters = Partial<{
  [NotificationsDefinitionParameter.Threshold]: number;
  [NotificationsDefinitionParameter.InDays]: number;
  [NotificationsDefinitionParameter.Severity]: SeverityInt;
  [NotificationsDefinitionParameter.Attribute]: number;
  [NotificationsDefinitionParameter.CVSS]: CVSSInt;
}>;

export const NotificationParameterLimits = {
  Threshold: {
    min: 1,
    max: 950,
  },
  InDays: {
    min: 1,
    Max: 30,
  },
  Severity: {
    min: SeverityInt.InfoSeverity,
    max: SeverityInt.CriticalSeverity,
  },
};

export enum NotificationConditionMode {
  Labels = "Labels",
  DomainPortfolios = "DomainPortfolios",
  Tiers = "Tiers",
  VendorPortfolios = "Portfolios",
  Attributes = "Attributes",
}

export enum NotificationConditionOperator {
  Any = "Any",
  All = "All",
}

export enum NotificationModeOperator {
  And = "And",
  Or = "Or",
}

export interface AttributeState {
  selectedValues?: string[];
  matchesNone?: boolean;
  matchesAll?: boolean;
  includesEmpty?: boolean;
}

export enum DateAttributeOperator {
  Before = "before",
  Between = "between",
  After = "after",
}

export interface DateAttributeState {
  startDate?: string;
  endDate?: string;
  operator?: DateAttributeOperator;
}

export interface NotificationConditionalLogic {
  modes: NotificationConditionMode[];
  labelOperator?: NotificationConditionOperator;
  modeOperator?: NotificationModeOperator;
  labels?: number[];
  domainPortfolioIDs?: number[];
  tiers?: number[];
  portfolioIDs?: number[];
  attributes?: { [key: number]: AttributeState };
  dateAttributes?: { [key: number]: DateAttributeState };
}

export interface NotificationConditionalLogicState
  extends NotificationConditionalLogic {
  cleared: boolean;
  enabledAttributes: number[];
}

export const InitialConditionalLogic = (
  supportedModes: NotificationConditionMode[] = []
): NotificationConditionalLogicState => {
  // Pre-set the supported mode if there is only one available
  const modes = supportedModes.length === 1 ? [supportedModes[0]] : [];
  const labelOperator = modes.includes(NotificationConditionMode.Labels)
    ? NotificationConditionOperator.Any
    : undefined;
  return {
    modes,
    modeOperator: NotificationModeOperator.And,
    labelOperator,
    cleared: true, // cleared indicates weather additional checks other than required ones are present or not
    enabledAttributes: [],
  };
};

export type NotificationConditionalLogicAction =
  | { type: "init" }
  | { type: "clear" }
  | { type: "load"; data?: NotificationConditionalLogic }
  | { type: "setModes"; modes: NotificationConditionMode[] }
  | { type: "setLabelOperator"; operator: NotificationConditionOperator }
  | { type: "setModeOperator"; operator: NotificationModeOperator }
  | { type: "setLabels"; labels: number[] }
  | { type: "setTiers"; tiers: number[] }
  | { type: "setDomainPortfolioIDs"; portfolioIDs: number[] }
  | { type: "setVendorPortfolioIDs"; portfolioIDs: number[] }
  | { type: "setEnabledAttributes"; enabledAttributes: number[] }
  | { type: "setAttributeState"; attrDefId: number; newState: AttributeState }
  | {
      type: "setDateAttributeState";
      attrDefId: number;
      newState: DateAttributeState;
    };

export const NotificationConditionalLogicReducer = (
  state: NotificationConditionalLogicState,
  action: NotificationConditionalLogicAction
): NotificationConditionalLogicState => {
  if (action.type === "clear") {
    return { ...state, cleared: true, enabledAttributes: [] };
  } else {
    state = { ...state, cleared: false };
  }

  switch (action.type) {
    case "init":
      return InitialConditionalLogic();
    case "load":
      // load from a possible definition
      if (!action.data) {
        return InitialConditionalLogic();
      } else {
        return {
          ...action.data,
          cleared: false,
          // populate the list of enabled attribute definitions
          enabledAttributes: _uniq([
            ...Object.keys(action.data.attributes ?? {}).map((id) =>
              parseInt(id, 10)
            ),
            ...Object.keys(action.data.dateAttributes ?? {}).map((id) =>
              parseInt(id, 10)
            ),
          ]),
        };
      }
    case "setModes":
      // make sure the operators and other data match the nodes
      const newState = { ...state };
      if (
        !newState.labelOperator &&
        action.modes.includes(NotificationConditionMode.Labels)
      ) {
        newState.labelOperator = NotificationConditionOperator.Any;
      }
      if (!action.modes.includes(NotificationConditionMode.Labels)) {
        delete newState.labelOperator;
        delete newState.labels;
      }
      if (!action.modes.includes(NotificationConditionMode.DomainPortfolios)) {
        delete newState.domainPortfolioIDs;
      }
      if (!action.modes.includes(NotificationConditionMode.Tiers)) {
        delete newState.tiers;
      }
      if (!action.modes.includes(NotificationConditionMode.VendorPortfolios)) {
        delete newState.portfolioIDs;
      }
      if (!action.modes.includes(NotificationConditionMode.Attributes)) {
        delete newState.attributes;
        delete newState.dateAttributes;
        newState.enabledAttributes = [];
      }
      newState.modes = action.modes;
      return newState;
    case "setLabelOperator":
      return { ...state, labelOperator: action.operator };
    case "setModeOperator":
      return { ...state, modeOperator: action.operator };
    case "setLabels":
      return { ...state, labels: action.labels };
    case "setTiers":
      return { ...state, tiers: action.tiers };
    case "setDomainPortfolioIDs":
      return { ...state, domainPortfolioIDs: action.portfolioIDs };
    case "setVendorPortfolioIDs":
      return { ...state, portfolioIDs: action.portfolioIDs };
    case "setEnabledAttributes":
      const newEnabledAttributesState = {
        ...state,
        enabledAttributes: action.enabledAttributes,
      };

      // make sure the we update the rest of the state
      if (newEnabledAttributesState.enabledAttributes.length > 0) {
        newEnabledAttributesState.modes = _uniq([
          ...newEnabledAttributesState.modes,
          NotificationConditionMode.Attributes,
        ]);

        // remove all the attributes states for the attributes that are not enabled
        newEnabledAttributesState.attributes = filterAttributes(
          action.enabledAttributes,
          newEnabledAttributesState.attributes
        );
        newEnabledAttributesState.dateAttributes = filterDateAttributes(
          action.enabledAttributes,
          newEnabledAttributesState.dateAttributes
        );
      } else {
        newEnabledAttributesState.modes =
          newEnabledAttributesState.modes.filter(
            (m) => m != NotificationConditionMode.Attributes
          );
        delete newEnabledAttributesState.attributes;
        delete newEnabledAttributesState.dateAttributes;
      }

      return newEnabledAttributesState;
    case "setAttributeState":
      const newAttributeValuesState = { ...state };
      newAttributeValuesState.attributes = {
        ...newAttributeValuesState.attributes,
        [action.attrDefId]: action.newState,
      };
      return newAttributeValuesState;
    case "setDateAttributeState":
      const newDateAttributeValuesState = { ...state };
      newDateAttributeValuesState.dateAttributes = {
        ...newDateAttributeValuesState.dateAttributes,
        [action.attrDefId]: action.newState,
      };
      return newDateAttributeValuesState;
    default:
      return { ...state };
  }
};

export const validateNotificationConditions = (
  c: NotificationConditionalLogicState
) => {
  if (c.cleared) {
    return true;
  }

  if (
    c.modes.includes(NotificationConditionMode.Labels) &&
    (!c.labels || c.labels.length <= 0 || !c.labelOperator)
  ) {
    return false;
  }

  if (
    c.modes.includes(NotificationConditionMode.DomainPortfolios) &&
    (!c.domainPortfolioIDs || c.domainPortfolioIDs.length === 0)
  ) {
    return false;
  }

  if (
    c.modes.includes(NotificationConditionMode.Tiers) &&
    (!c.tiers || c.tiers.length <= 0)
  ) {
    return false;
  }

  if (
    c.modes.includes(NotificationConditionMode.VendorPortfolios) &&
    (!c.portfolioIDs || c.portfolioIDs.length <= 0)
  ) {
    return false;
  }

  if (c.modes.includes(NotificationConditionMode.Attributes)) {
    // there should be some attributes selected
    if (
      Object.values(c.attributes ?? {}).length === 0 &&
      Object.values(c.dateAttributes ?? {}).length === 0
    ) {
      return false;
    }

    if (
      Object.values(c.attributes ?? {}).some((attrState) =>
        isAttributeStateInvalid(attrState)
      )
    ) {
      return false;
    }

    if (
      Object.values(c.dateAttributes ?? {}).some((attrState) =>
        isDateAttributeStateInvalid(attrState)
      )
    ) {
      return false;
    }
  }

  if (c.modes.length > 1 && !c.modeOperator) {
    return false;
  }

  return true;
};

export interface NotificationDefinition {
  id: number;
  organisationID: number;
  uuid: string;
  notificationType: NotificationType;
  notificationTypeID: number;
  enabled: boolean;
  severity: SeverityInt;
  parameters: NotificationParameters;
  enableWebhook: boolean;
  webhookTarget: string;
  scoringSurveys: boolean;
  conditionalLogic?: NotificationConditionalLogic;
  isZapier: boolean;
  hasIntegration: boolean;
}

export interface NotificationDefinitionMetadata {
  type: NotificationType;
  description: string;
  entityName: string;
  entityNamePlural: string;
  actionDescription: string;
  actionURL: string;
  icon: string;
  configDescription: string;
  createable: boolean;
  requiredOrgPermissions: string[];
  requiredGroupEntitlements: string[];
  sortOrder: number;
  defaultParameters: NotificationParameters;
  defaultSeverity: SeverityInt;
  defaultUUID: string;
  defaultEmailEnabled: boolean;
  defaultEmailEnabledAll: boolean;
  defaultDisplayEnabled: boolean;
  defaultDisplayEnabledAll: boolean;
  dontCreateIfUnresolved: boolean;
  minDaysBetweenEmails: number;
  activityStreamDescription: string;
  activityStreamActionText: string;
  activityStreamUserNameContext: string;
  activityStreamUserEmailContext: string;
  activityStreamSignificance: string;
  supportedConditionalModes?: NotificationConditionMode[];
  supportedLabelClassifications?: LabelClassification[];
}

export interface INotificationTrigger {
  type: string;
  uuid: string;
  configDescription: string;
  parameters: { [name: string]: number };
  displayEnabled: boolean;
  displayEnabledAll: boolean;
  emailEnabled: boolean;
  emailEnabledAll: boolean;
  testJSON: Record<string, any>;
  defaultPayloadScript: string;
  group: string;
  conditionalLogic?: any;
  exampleLiquidTextMessage?: string;
  templateVariablesList?: {
    [varname: string]: { name: string; path: string; example: string };
  };
  description: string;
}

export interface INotificationConfigSubcategory<T> {
  subcategory: string;
  options: T[];
}

export interface INotificationConfigCategory<T> {
  category: string;
  subcategories: INotificationConfigSubcategory<T>[];
}

// Filter nested categories and subcategories with a provided function.
// Results will be split into an object by keys provided in the filterAndSplitFunc.
export const filterNotificationConfigCategories = <T>(
  categories: INotificationConfigCategory<T>[],
  filterAndSplitFunc: (opt: T) => [boolean, string]
) => {
  const result: Record<string, INotificationConfigCategory<T>[] | undefined> =
    {};

  categories.forEach((optionCat) => {
    const subcategories: Record<string, INotificationConfigSubcategory<T>[]> =
      {};

    optionCat.subcategories.forEach((subCat) => {
      const subCatOptions: Record<string, T[]> = {};

      subCat.options.forEach((opt) => {
        const [shouldInclude, bucket] = filterAndSplitFunc(opt);
        if (shouldInclude) {
          if (!subCatOptions[bucket]) {
            subCatOptions[bucket] = [];
          }
          subCatOptions[bucket].push(opt);
        }
      });

      Object.entries(subCatOptions).forEach(([bucket, opts]) => {
        if (!subcategories[bucket]) {
          subcategories[bucket] = [];
        }
        subcategories[bucket].push({
          ...subCat,
          options: opts,
        });
      });
    });

    Object.entries(subcategories).forEach(([bucket, subcats]) => {
      if (!result[bucket]) {
        result[bucket] = [];
      }

      result[bucket]?.push({
        ...optionCat,
        subcategories: subcats,
      });
    });
  });

  return result;
};

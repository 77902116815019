import { DefaultRootState } from "react-redux";
import { Filters } from "../components/filter/types";
import { IRisk } from "../components/RiskSummaryDetails";
import {
  IVendorRiskWaiver,
  VendorRiskWaiverRiskType,
  VendorRiskWaiverStatusType,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import {
  PublicRiskWaiver,
  PublicWaiverAcceptStatus,
} from "../types/sharedAssessment";
import {
  OrganisationAcceptedRisk,
  OrganisationAcceptedRiskStatus,
  RiskAssetType,
} from "./customerAcceptedRisks.actions";
import { VendorSummaryRiskType } from "../../_common/types/vendorSummary";
import { intersection as _intersection } from "lodash";
import { IRiskHostnameKeyData } from "../../_common/types/risks";

// Retrieve hostnames for a risk from redux state
export const getCustomerRiskHostnames = (
  state: DefaultRootState,
  riskId: string,
  overrideFilters?: Filters,
  skipWaiverId?: number,
  includeWaived = false
): IRiskHostnameKeyData => {
  const filters = overrideFilters ?? state.cyberRisk.customerData.filters;

  const {
    websiteLabelIds,
    domainPortfolioIds,
    cloudConnectionProviderTypes,
    cloudConnectionUUIDs,
  } = filters;
  const currentState = state.cyberRisk.customerData.riskHostnames[riskId];

  // if we have current state and the label IDs, portfolios and skipWaiverId match, return that
  if (
    currentState &&
    JSON.stringify(currentState.websiteLabelIds) ===
      JSON.stringify(websiteLabelIds) &&
    JSON.stringify(currentState.domainPortfolioIds) ===
      JSON.stringify(domainPortfolioIds) &&
    currentState.skipWaiverId === skipWaiverId &&
    currentState.includeWaived === includeWaived &&
    JSON.stringify(currentState.cloudConnectionProviderTypes) ===
      JSON.stringify(cloudConnectionProviderTypes) &&
    JSON.stringify(currentState.cloudConnectionUUIDs) ===
      JSON.stringify(cloudConnectionUUIDs)
  ) {
    return currentState;
  }

  // Else, return a blank state
  return {
    loading: false,
    error: null,
    data: undefined,
    websiteLabelIds: [],
    domainPortfolioIds: [],
    skipWaiverId: undefined,
    includeWaived: false,
    cloudConnectionUUIDs: [],
    cloudConnectionProviderTypes: [],
  };
};

export interface WaivedAssets {
  assetsWaived: { [asset: string]: number };
  assetsPendingWaiver: { [asset: string]: number };
  assetsPendingSharedWaiver: { [asset: string]: number };
  assetsAdjusted: { [asset: string]: number };
  assetsPendingAdjustment: { [asset: string]: number };
  allDomainsWaiverId?: number;
}

/* A map of risks, severities and waivers e.g.
{
   risk_1: {
        assetsWaived: {},
        assetsPendingWaiver: {},
        assetsPendingSharedWaiver: {},
        assetsAdjusted: {},
        assetsPendingAdjustment: {},
    }
}
 */
export type WaivedRiskAssetsMap = {
  [riskId: string]: WaivedAssets;
};

// Calculate and return a map of waived or pending waived assets for each risk
export const calculateWaivedAssetsForRisks = (
  risks: IRisk[],
  isCustomer: boolean,
  vendorWaivers?: IVendorRiskWaiver[],
  publicRiskWaivers?: PublicRiskWaiver[],
  customerRiskWaivers?: OrganisationAcceptedRisk[],
  skipWaiverId?: number,
  subsidiaryWaivers?: IVendorRiskWaiver[]
): WaivedRiskAssetsMap => {
  const waivedAssetRiskMap: WaivedRiskAssetsMap = {};

  risks.forEach((r) => {
    if (r.passed) {
      return; // Skip passed risks
    }

    // Initialize empty waived assets for this risk
    waivedAssetRiskMap[r.id] = {
      assetsWaived: {},
      assetsPendingWaiver: {},
      assetsPendingSharedWaiver: {},
      assetsAdjusted: {},
      assetsPendingAdjustment: {},
      allDomainsWaiverId: undefined,
    };

    // Process customer waivers if this is a customer risk
    if (isCustomer && customerRiskWaivers && customerRiskWaivers.length > 0) {
      const customerWaived = calculateCustomerWaivedAssetsForRisk(
        r,
        customerRiskWaivers,
        skipWaiverId
      );

      // Add customer waived assets
      Object.keys(customerWaived.assetsWaived).forEach((asset) => {
        waivedAssetRiskMap[r.id].assetsWaived[asset] =
          customerWaived.assetsWaived[asset];
      });

      Object.keys(customerWaived.assetsPendingWaiver).forEach((asset) => {
        waivedAssetRiskMap[r.id].assetsPendingWaiver[asset] =
          customerWaived.assetsPendingWaiver[asset];
      });

      if (customerWaived.allDomainsWaiverId) {
        waivedAssetRiskMap[r.id].allDomainsWaiverId =
          customerWaived.allDomainsWaiverId;
      }
    }

    // Handle subsidiary waivers separately
    if (subsidiaryWaivers && subsidiaryWaivers.length > 0) {
      const subsidiaryWaived = calculateVendorWaivedAssetsForRisk(
        r,
        subsidiaryWaivers,
        undefined,
        skipWaiverId,
        true
      );

      if (!isCustomer) {
        waivedAssetRiskMap[r.id] = subsidiaryWaived;
        return;
      }

      // Add subsidiary waived assets without overwriting customer ones
      Object.keys(subsidiaryWaived.assetsWaived).forEach((asset) => {
        waivedAssetRiskMap[r.id].assetsWaived[asset] =
          subsidiaryWaived.assetsWaived[asset];
      });

      Object.keys(subsidiaryWaived.assetsPendingWaiver).forEach((asset) => {
        waivedAssetRiskMap[r.id].assetsPendingWaiver[asset] =
          subsidiaryWaived.assetsPendingWaiver[asset];
      });

      Object.keys(subsidiaryWaived.assetsPendingSharedWaiver).forEach(
        (asset) => {
          waivedAssetRiskMap[r.id].assetsPendingSharedWaiver[asset] =
            subsidiaryWaived.assetsPendingSharedWaiver[asset];
        }
      );

      Object.keys(subsidiaryWaived.assetsAdjusted).forEach((asset) => {
        waivedAssetRiskMap[r.id].assetsAdjusted[asset] =
          subsidiaryWaived.assetsAdjusted[asset];
      });

      Object.keys(subsidiaryWaived.assetsPendingAdjustment).forEach((asset) => {
        waivedAssetRiskMap[r.id].assetsPendingAdjustment[asset] =
          subsidiaryWaived.assetsPendingAdjustment[asset];
      });

      // Set allDomainsWaiverId if not already set
      if (
        subsidiaryWaived.allDomainsWaiverId &&
        !waivedAssetRiskMap[r.id].allDomainsWaiverId
      ) {
        waivedAssetRiskMap[r.id].allDomainsWaiverId =
          subsidiaryWaived.allDomainsWaiverId;
      }
    } else if (!isCustomer) {
      const vendorWaived = calculateVendorWaivedAssetsForRisk(
        r,
        vendorWaivers,
        publicRiskWaivers,
        skipWaiverId
      );

      waivedAssetRiskMap[r.id] = vendorWaived;
    }
  });

  return waivedAssetRiskMap;
};

// Work out which domains / surveys / docs are waived / awaiting approval / pending shared waiver for the risk
export const calculateVendorWaivedAssetsForRisk = (
  risk: IRisk,
  waivers?: IVendorRiskWaiver[],
  publicWaivers?: PublicRiskWaiver[],
  skipWaiverId?: number,
  isSubsidiary?: boolean
): WaivedAssets => {
  // For deriving waived/adjusted assets use the base id
  const riskId = risk.baseId ?? risk.id;

  const assetsWaived: { [asset: string]: number } = {};
  const assetsPendingWaiver: { [asset: string]: number } = {};
  const assetsAdjusted: { [asset: string]: number } = {};
  const assetsPendingAdjustment: { [asset: string]: number } = {};
  const assetsPendingSharedWaiver: { [asset: string]: number } = {};
  let allDomainsWaiverId: number | undefined;

  // Apply vendor risk waivers to risk assets
  waivers?.forEach((w) => {
    if (
      w.riskID !== riskId ||
      w.id === skipWaiverId ||
      (w.status !== VendorRiskWaiverStatusType.Active &&
        w.status !== VendorRiskWaiverStatusType.AwaitingApproval)
    ) {
      return; // Skip if not for this risk
    }

    // Derive which assets are being waived for this waiver
    let waiverAssets: string[] = [];
    if (w.riskType === VendorRiskWaiverRiskType.Cloudscan) {
      const riskFailedCloudscansMap: { [hostname: string]: boolean } = {};
      risk.failedCloudscans?.map((c) => {
        riskFailedCloudscansMap[c.hostname] = true;
      });
      w.domains.forEach((d) => {
        if (riskFailedCloudscansMap[d] || isSubsidiary) {
          waiverAssets.push(d);
        }
      });
      allDomainsWaiverId =
        w.isAllDomains && w.status === VendorRiskWaiverStatusType.Active
          ? w.id
          : allDomainsWaiverId;
    } else if (w.riskType === VendorRiskWaiverRiskType.Survey) {
      if (w.isAllSurveys) {
        waiverAssets = [
          ...(risk.surveys?.map(
            (s) =>
              `${
                s.publicSurvey ? "public_survey" : "survey"
              }_${s.surveyId.toString(10)}`
          ) ?? []),
        ];
      } else {
        // Intersect assets on risk with what is actually applied via this waiver
        const allSurveyIds = risk.surveys
          ? risk.surveys.filter((s) => !s.publicSurvey).map((s) => s.surveyId)
          : [];
        const allPublicSurveyIds = risk.surveys
          ? risk.surveys.filter((s) => s.publicSurvey).map((s) => s.surveyId)
          : [];

        waiverAssets = [
          ..._intersection(allSurveyIds, w.surveys).map(
            (s) => `survey_${s.toString(10)}`
          ),
          ..._intersection(allPublicSurveyIds, w.publicSurveys).map(
            (ps) => `public_survey_${ps.toString(10)}`
          ),
        ];
      }
    } else if (w.riskType === VendorRiskWaiverRiskType.Evidence) {
      waiverAssets =
        risk.additionalEvidences?.map(
          (a) => `add_evi_doc_${a.id.toString(10)}`
        ) ?? [];
    }

    let assetMap: { [asset: string]: number } = assetsWaived;

    // Derive appropriate map to assign to
    switch (w.status) {
      case VendorRiskWaiverStatusType.Active:
        assetMap =
          w.waiverType === WaiverType.SeverityAdjustment
            ? assetsAdjusted
            : assetsWaived;
        break;
      case VendorRiskWaiverStatusType.AwaitingApproval:
        assetMap =
          w.waiverType === WaiverType.SeverityAdjustment
            ? assetsPendingAdjustment
            : assetsPendingWaiver;
        break;
    }

    waiverAssets.reduce((acc, curr) => ((acc[curr] = w.id), acc), assetMap);
  });

  // Work out which domains have pending shared waivers
  publicWaivers?.forEach((w) => {
    if (
      risk.riskType === VendorSummaryRiskType.Cloudscan &&
      w.riskId === risk.id &&
      w.status === PublicWaiverAcceptStatus.Pending
    ) {
      w.domains.reduce(
        (acc, curr) => ((acc[curr] = w.id), acc),
        assetsPendingSharedWaiver
      );
    }
  });

  return {
    assetsWaived,
    assetsPendingWaiver,
    assetsPendingSharedWaiver,
    assetsAdjusted,
    assetsPendingAdjustment,
    allDomainsWaiverId,
  };
};

// Work out which domains are waived / awaiting approval / pending shared waiver for the risk
export const calculateCustomerWaivedAssetsForRisk = (
  risk: IRisk,
  waivers?: OrganisationAcceptedRisk[],
  skipWaiverId?: number
): WaivedAssets => {
  const assetsWaived: { [asset: string]: number } = {};
  const assetsPendingWaiver: { [asset: string]: number } = {};
  let allDomainsWaiverId: number | undefined;

  // For deriving waived/adjusted assets use the base id
  const riskId = risk.baseId ?? risk.id;

  waivers?.forEach((w) => {
    if (w.riskId !== riskId || w.id === skipWaiverId) {
      return;
    }

    const waiverAssets: string[] = [];

    switch (w.riskAssetType) {
      case RiskAssetType.cloudscan:
        const riskFailedCloudscansMap: { [hostname: string]: boolean } = {};
        risk.failedCloudscans?.map((c) => {
          riskFailedCloudscansMap[c.hostname] = true;
        });
        w.websites.forEach((d) => {
          // If we don't have failedCloudscans, still add the websites
          if (!risk.failedCloudscans || riskFailedCloudscansMap[d]) {
            waiverAssets.push(d);
          }
        });
        break;
      case RiskAssetType.appguardManifest:
        waiverAssets.push(...w.manifestLocations);
        break;
      case RiskAssetType.appguardRepo:
        waiverAssets.push(...w.repoNames);
        break;
      case RiskAssetType.userbaseUser:
        waiverAssets.push(...w.userInfos.map((u) => u.uuid));
        break;
    }

    // Assign to appropriate map
    if (w.status === OrganisationAcceptedRiskStatus.Active) {
      waiverAssets.reduce(
        (acc, curr) => ((acc[curr] = w.id), acc),
        assetsWaived
      );
      allDomainsWaiverId = w.allWebsites ? w.id : allDomainsWaiverId;
    } else if (w.status === OrganisationAcceptedRiskStatus.AwaitingApproval) {
      waiverAssets.reduce(
        (acc, curr) => ((acc[curr] = w.id), acc),
        assetsPendingWaiver
      );
    }
  });

  return {
    assetsWaived,
    assetsPendingWaiver,
    assetsPendingSharedWaiver: {},
    // Adjustements not currently supported for BreachSight waivers
    assetsAdjusted: {},
    assetsPendingAdjustment: {},
    allDomainsWaiverId,
  };
};

// Work out if all the assets on a risk are fully covered by waivers or pending waivers
export const isFullyCoveredByWaivers = (
  risk: IRisk,
  waivedAssets: WaivedAssets
): boolean => {
  const assetsWaivedOrPendingWaived = {
    ...waivedAssets.assetsWaived,
    ...waivedAssets.assetsPendingWaiver,
    ...waivedAssets.assetsPendingSharedWaiver,
  };

  const unwaivedAssets: { [asset: string]: boolean } = {};

  if (risk.riskType === VendorSummaryRiskType.Cloudscan) {
    if (risk.failedCloudscans === undefined) {
      // We hanve't got a full list of the failed cloudscans so best we can determine if all are waived
      return waivedAssets.allDomainsWaiverId !== undefined;
    }

    // Get remaining unwaived domains/ips
    risk.failedCloudscans?.forEach(
      (fc) => (unwaivedAssets[fc.hostname] = true)
    );
  } else if (risk.riskType === VendorSummaryRiskType.Survey) {
    risk.surveys
      ?.filter((s) => !s.isWaived)
      .forEach(
        (s) =>
          (unwaivedAssets[
            `${s.publicSurvey ? "public_survey" : "survey"}_${s.surveyId}`
          ] = true)
      );
  } else if (risk.riskType === VendorSummaryRiskType.Evidence) {
    // Additional evidence is always all or nothing
    // Plus, the counts are completely f'ed as we seem to count each file against an additional evidence as a separate
    // instance of a risk. We should clean this up elsewhere...
    return Object.keys(assetsWaivedOrPendingWaived).length > 0;
  } else if (risk.riskType === VendorSummaryRiskType.SaaS) {
    risk.saasUsers?.forEach((u) => (unwaivedAssets[u.uuid] = true));
  } else {
    // Risk type not supported
    return false;
  }

  // Check which if any of the remaining domains/ips are covered by a pending waiver
  const uncoveredAssets: string[] = [];
  Object.keys(unwaivedAssets).forEach((h) => {
    if (!assetsWaivedOrPendingWaived[h]) {
      uncoveredAssets.push(h);
    }
  });

  return uncoveredAssets.length === 0;
};

// Work out if all the assets on a risk are fully covered by pending adjustments
export const isFullyCoveredByAdjustments = (
  risk: IRisk,
  waivedAssets: WaivedAssets
): boolean => {
  // Check if this is already an adjusted risk
  if (risk.baseSeverity !== undefined && risk.baseSeverity !== null) {
    return true;
  }

  const assetsPendingAdjusted = {
    ...waivedAssets.assetsPendingAdjustment,
  };

  const unadjustedAssets: { [asset: string]: boolean } = {};

  if (risk.riskType === VendorSummaryRiskType.Cloudscan) {
    if (risk.failedCloudscans === undefined) {
      // We haven't got a full list of the failed cloudscans so we can't determine if all are adjusted
      return false;
    }

    // Get remaining unadjusted domains/ips
    risk.failedCloudscans?.forEach(
      (fc) => (unadjustedAssets[fc.hostname] = true)
    );
  } else if (risk.riskType === VendorSummaryRiskType.Survey) {
    // All remaining surveys are unadjusted
    risk.surveys?.forEach(
      (s) =>
        (unadjustedAssets[
          `${s.publicSurvey ? "public_survey" : "survey"}_${s.surveyId}`
        ] = true)
    );
  } else if (risk.riskType === VendorSummaryRiskType.Evidence) {
    // Additional evidence is always all or nothing
    // Plus, the counts are completely f'ed as we seem to count each file against an additional evidence as a separate
    // instance of a risk. We should clean this up elsewhere...
    return Object.keys(assetsPendingAdjusted).length > 0;
  } else {
    // Risk type not supported
    return false;
  }

  // Check which if any of the remaining domains/ips are covered by pending adjustments
  const uncoveredAssets: string[] = [];
  Object.keys(unadjustedAssets).forEach((h) => {
    if (!assetsPendingAdjusted[h]) {
      uncoveredAssets.push(h);
    }
  });

  return uncoveredAssets.length === 0;
};

import Modal from "../../../_common/components/ModalV2";
import { FC, useEffect } from "react";
import Button from "../../../_common/components/core/Button";
import "../../style/components/modals/SubmittedModal.scss";
import { History } from "history";
import {
  useBasicPermissions,
  UserReadSurveyImportExport,
  UserWriteSurveyImportExport,
} from "../../../_common/permissions";
import SurveyImportAPI from "../../../vendor_portal/api/surveyImportAPI";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { useDefaultHistory } from "../../../_common/types/router";
import { VendorSurveysRouteState } from "../../../vendor_portal/views/VendorSurveys";
import { useCurrentUser } from "../../../_common/selectors/commonSelectors";
import { SurveyUsageType } from "../../../_common/types/surveyTypes";
import { trackEvent } from "../../../_common/tracking";

interface SubmittedModalProps {
  active: boolean;
  onDone: () => void;
  isImportedSurvey: boolean;
  surveyType: SurveyUsageType;
  alternateTitle?: string;
  alternateText?: string;
}

const SubmittedModal: FC<SubmittedModalProps> = ({
  active,
  onDone,
  isImportedSurvey,
  surveyType,
  alternateTitle,
  alternateText,
}) => {
  const userData = useCurrentUser();
  const perms = useBasicPermissions();
  const history = useDefaultHistory();
  const canViewImportedSurveys =
    !!perms.userPermissions[UserReadSurveyImportExport];
  const canEditImportedSurveys =
    canViewImportedSurveys &&
    !!perms.userPermissions[UserWriteSurveyImportExport];
  const { data: importedSurveysData } =
    SurveyImportAPI.useGetImportedSurveysListQuery(undefined, {
      skip: !isImportedSurvey && !canViewImportedSurveys,
    });

  const showImportPromo =
    userData.currentOrgID > 0 &&
    surveyType === SurveyUsageType.Security &&
    !isImportedSurvey &&
    (!canViewImportedSurveys ||
      (!!importedSurveysData && importedSurveysData.surveys.length === 0));

  const loading =
    !(!!alternateText && !showImportPromo) && // If we have alternateText and no promo, nothing to load.
    !isImportedSurvey &&
    canViewImportedSurveys &&
    !importedSurveysData;

  useEffect(() => {
    // Track when people see the import survey promo
    if (showImportPromo) {
      trackEvent("SurveyViewer_ImportSurveyPromoViewed");
    }
  }, [showImportPromo]);

  return (
    <Modal
      className={"submitted-modal-content"}
      active={active}
      onClose={() => {
        trackEvent("SurveyViewer_ImportSurveyPromoClosed");
        onDone();
      }}
      headerContent={
        alternateTitle ? alternateTitle : "Questionnaire submitted"
      }
    >
      {loading ? (
        <LoadingBanner />
      ) : (
        <>
          {alternateText ? (
            <p>{alternateText}</p>
          ) : isImportedSurvey ? (
            <p>
              You may edit your answers and export this questionnaire at any
              time.
            </p>
          ) : (
            <p>
              You have successfully submitted the questionnaire. You can edit
              these answers from the questionnaire summary page.
            </p>
          )}
          {showImportPromo && (
            <>
              <div className="promo">
                <div className="promo-header">
                  Want to complete questionnaires faster?
                </div>
                <div className="promo-blurb">
                  <div>
                    Try out Trust Exchange, UpGuard&apos;s free suite of
                    AI-powered productivity and collaboration tools.
                  </div>
                  <div>
                    <i className={"cr-icon-check"} />
                    Auto-populate answers from previously completed
                    questionnaires
                  </div>
                  <div>
                    <i className={"cr-icon-check"} />
                    Create a central repository, and share documents instantly
                  </div>
                  <div>
                    <i className={"cr-icon-check"} />
                    Collaborate with colleagues to complete questionnaires
                  </div>
                  {canEditImportedSurveys ? (
                    <Button
                      filledPrimary
                      arrow
                      onClick={() => {
                        trackEvent("SurveyViewer_ImportSurveyPromoClicked");
                        (history as History<VendorSurveysRouteState>).push(
                          `/vendors/surveys`,
                          {
                            openImportModal: true,
                          }
                        );
                      }}
                    >
                      Import a questionnaire
                    </Button>
                  ) : (
                    <div className={"extra-gap"}>
                      <strong>
                        Talk to your organization admin about enabling these
                        free features on your account.
                      </strong>
                    </div>
                  )}

                  <div className={"extra-gap"}>
                    <Button
                      tertiary
                      onClick={() => {
                        trackEvent("SurveyViewer_ImportSurveyPromoLearnMore");
                        window.open(
                          "https://www.upguard.com/product/trust-exchange",
                          "_blank",
                          "nofollow noreferrer"
                        );
                      }}
                    >
                      Learn more about Trust Exchange
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default SubmittedModal;

import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import { App, AppScopeUser } from "../../api/types";
import { useDefaultHistory } from "../../../_common/types/router";
import { appSummaryUrl, permissionAppsUrl } from "../../UserBaseAppRouter";
import ScrollableDiv from "../../../vendorrisk/components/ScrollableDiv";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import UserBaseAPI from "../../api/userbase.api";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import "./PermissionAppUsersPanel.scss";
import { pluralise } from "../../../_common/helpers";
import { CheckItemSlidePanelSection } from "../../../vendorrisk/components/filter/SlidePanelSection";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import SlidePanelKVTable from "../SlidePanelKVTable";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import UserApprovalPill from "../users/UserApprovalPill";
import AppStatusDisplay from "../applications/AppStatusDisplay";
import Score from "../../../vendorrisk/components/Score";

const PermissionAppUsersPanel: FC<{ scopeExternalId: string; app: App }> = ({
  scopeExternalId,
  app,
}) => {
  const history = useDefaultHistory();

  const onViewApplication = () => {
    history.push(appSummaryUrl(app.name), {
      backContext: {
        backTo: permissionAppsUrl(scopeExternalId),
        backToText: `Back to scope applications`,
      },
    });
  };

  const { data, isLoading } = UserBaseAPI.useGetUserBaseScopeAppUsersV1Query({
    appName: app.name,
    scopeExternalId: scopeExternalId,
  });

  const { data: appSummaryData, isLoading: isAppLoading } =
    UserBaseAPI.useGetUserBaseAppSummaryV1Query({
      app: app.name,
    });

  const goToUserPage = (user: AppScopeUser) => {
    history.push(`/userbase/user/${user.uuid}/summary`, {
      backContext: {
        backTo: permissionAppsUrl(scopeExternalId),
        backToText: "Back to scope applications",
      },
    });
  };

  const userIntoXTableRow = (u: AppScopeUser): IXTableRow<string> => {
    return {
      id: u.uuid,
      className: "user-row",
      cells: [
        <XTableCell className="user" key="user">
          <div className="user-name">{u.name}</div>
        </XTableCell>,
        <XTableCell className="team" key="team">
          <div className="team-name">{u.teams[0]}</div>
        </XTableCell>,
        <XTableCell className="security-rating" key="security-rating">
          <div className="score-container">
            <ColorGrade score={u?.rating} size={ColorGradeSize.Small} />
            <Score score={u?.rating} small />
          </div>
        </XTableCell>,
        <XTableCell className="approval-status" key="approval-status">
          <UserApprovalPill approved={u.approved} waived={u.waived} />
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => {
            goToUserPage(u);
          },
        },
      ],
    };
  };

  const usersNumberString = isLoading
    ? "Users granted this permission"
    : `${pluralise(
        data?.users.length,
        "User",
        "Users granted this permission"
      )} (${data?.users.length})`;
  return (
    <ReportCard newStyles className="userbase-permission-app-users-panel">
      <div className="header">
        <CompanyLogo name={app.name} domain={app.domain} />
        <div className={"header-link"}>
          <Button onClick={onViewApplication}>
            View app profile
            <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {/* Consider extracting the section below to a common component */}
      <CheckItemSlidePanelSection title={"App overview"} startExpanded={true}>
        {isAppLoading ? (
          <div className="loading-icon-container">
            <LoadingIcon />
          </div>
        ) : (
          <SlidePanelKVTable
            rows={[
              {
                key: "Security Rating",
                value: (
                  <div className="score-container">
                    <ColorGrade
                      score={appSummaryData?.app.securityScore}
                      size={ColorGradeSize.Small}
                    />
                  </div>
                ),
              },
              {
                key: "Exposure Level",
                value: (
                  <ScopeRiskLevelDisplay
                    riskLevel={app.highestScopeRiskLevel}
                  />
                ),
              },
              {
                key: "Current users",
                value: (
                  <div className="number-of-users">{data?.users.length}</div>
                ),
              },

              {
                key: "First detected",
                value: (
                  <DateTimeFormat
                    dateTime={appSummaryData?.app.firstDetected}
                    dateOnly
                  />
                ),
              },
              {
                key: "Category",
                value: <div className="category">{app.category}</div>,
              },
              {
                key: "App status",
                value: <AppStatusDisplay app={app} />,
              },
              {
                key: "Approved teams",
                value: (
                  <div className="approved-teams">
                    {app.approvedForTeams.map((t) => t.name).join(", ")}
                  </div>
                ),
              },
              {
                key: "Approved roles",
                value: (
                  <div className="approved-roles">
                    {app.approvedForRoles.map((r) => r.name).join(", ")}
                  </div>
                ),
              },
            ]}
          />
        )}
      </CheckItemSlidePanelSection>

      <div className="content">
        <ScrollableDiv newStyles>
          <CheckItemSlidePanelSection
            title={usersNumberString}
            startExpanded={true}
          >
            <XTable
              className="user-list"
              // sortedBy={sortedBy}
              // onSortChange={onSortChange}
              loading={isLoading}
              columnHeaders={[
                { id: "User", text: "User", sortable: false },
                { id: "Team", text: "Team", sortable: false },
                {
                  id: "Security Rating",
                  text: "Security Rating",
                  sortable: false,
                },
                {
                  id: "Status",
                  text: "Status",
                  sortable: false,
                },
              ]}
              rows={data?.users.map(userIntoXTableRow) ?? []}
              iconOptions={true}
            />
          </CheckItemSlidePanelSection>
        </ScrollableDiv>
      </div>
    </ReportCard>
  );
};

export default PermissionAppUsersPanel;

import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import ListFilter from "./ListFilter";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";
import { OrgScope } from "../../api/types";
import { useLocaleCompare } from "../../../_common/hooks";

interface UserRisksScopeFilterProps {
  selectedScopes: string[];
  excluded: boolean;
  onChange: (selectedCategories: string[], exclude: boolean) => void;
}

const UserRiskScopeFilter: FC<UserRisksScopeFilterProps> = ({
  selectedScopes,
  excluded,
  onChange,
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseScopesV1Query({});
  const { caseInsensitiveCompare } = useLocaleCompare();

  // get the sorted, unique list of scopes to display to the user
  const allScopes: OrgScope[] = data?.scopes ?? [];
  const sortedScopes = [...allScopes].sort((a, b) =>
    caseInsensitiveCompare(a.description, b.description)
  );

  return (
    <ListFilter
      title={"Permissions"}
      isLoading={isLoading}
      options={
        sortedScopes.map((scope) => {
          return {
            value: scope.externalID,
            label: scope.description,
          };
        }) ?? []
      }
      selectedValues={selectedScopes}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRiskScopeFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);

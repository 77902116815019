import { FC } from "react";

import { MicrosoftAppMetadata } from "../../api/types";

import "./EntraAppLink.scss";
import { entraAppLink } from "../../helpers/apps";

export interface EntraAppLinkProps {
  metadata: MicrosoftAppMetadata;
}
const EntraAppLink: FC<EntraAppLinkProps> = ({ metadata }) => {
  const link = entraAppLink(metadata);

  return (
    <div className="entra-app-link">
      <span className="domain">
        <a href={link} target="_blank" rel="nofollow noreferrer">
          {metadata.microsoftAppID}
        </a>
      </span>
    </div>
  );
};

export default EntraAppLink;

import { ReactNode } from "react";
import { pluralise } from "../../_common/helpers";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import tmSlice, { selectFeedPageQuery } from "../Slice";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { ThreatMonitoringFeedType } from "../api/types";
import {
  getDateOfLastFeedDownload,
  resetDateOfLastFeedDownload,
} from "../views/ThreatMonitoringView";
import "./NewThreatsBanner.scss";
import { Module } from "../Slice";

interface NewThreatsBannerProps {
  hidden?: boolean;
  refetchFeedData: () => void;
  module: Module;
}

// export for stories
export function NewThreatsBanner({
  hidden,
  refetchFeedData,
  module,
}: NewThreatsBannerProps) {
  const dispatch = useAppDispatch();

  let feedQuery = useAppSelector(
    selectFeedPageQuery(module, ThreatMonitoringFeedType.Open)
  );
  feedQuery = { ...feedQuery, feedUpTo: getDateOfLastFeedDownload() };

  const {
    filtered: previousCountSinceFiltered,
    unfiltered: previousCountSinceUnfiltered,
  } = useAppSelector((s) => tmSlice.selectors.countSince(s, module));

  const countPollingInterval = 5 * 60 * 1000; // 5 minutes

  const { data: countSince } = ThreatMonitoringAPI.useGetNewResultsSinceV1Query(
    {
      identifiedAfter: getDateOfLastFeedDownload(),
      filters: feedQuery.filters,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: hidden,
      pollingInterval: countPollingInterval,
    }
  );

  const countSinceFiltered = countSince?.totalResults || 0;
  const countSinceUnfiltered = countSince?.totalResultsUnfiltered || 0;

  const fetchNewResults = () => {
    dispatch(
      tmSlice.actions.setCountSince({
        module,
        countSince: {
          unfiltered: countSinceUnfiltered,
          filtered: countSinceFiltered,
        },
      })
    );
    resetDateOfLastFeedDownload();
    refetchFeedData();
  };

  const areNewResultsAvailable = countSinceFiltered || countSinceUnfiltered;
  const newResultsAdded =
    previousCountSinceFiltered > 0 || previousCountSinceUnfiltered > 0;

  if (hidden) {
    return null;
  }

  const plThreat = (x: number) => pluralise(x, "threat", "threats");

  // some new results - blue info banner
  if (areNewResultsAvailable) {
    const numHiddenByFilter = countSinceUnfiltered - countSinceFiltered;

    let line2: ReactNode = null;

    // filter reduces the number of new results
    if (numHiddenByFilter > 0) {
      if (numHiddenByFilter === countSinceUnfiltered) {
        line2 = (
          <div>
            <strong>No new threats</strong> will be visible due to your applied
            filters. Adjust or reset your filters to see all newly detected
            threats.
          </div>
        );
      } else {
        line2 = (
          <div>
            <strong>{`${numHiddenByFilter} ${plThreat(
              numHiddenByFilter
            )}`}</strong>{" "}
            will not be visible due to your applied filters. Adjust or reset
            your filters to see all newly detected threats.
          </div>
        );
      }
    }

    return (
      <InfoBanner
        className="threat-monitoring-new-threats-banner"
        type={BannerType.INFO}
        message={
          <>
            <div className="message-lines">
              <div>
                <strong>{`${countSinceUnfiltered} new ${plThreat(
                  countSinceUnfiltered
                )}`}</strong>{" "}
                detected.{" "}
              </div>
              {line2}
            </div>
            <div className="action" onClick={fetchNewResults}>
              <div className="cr-icon-redo" />
              Load new threats
            </div>
          </>
        }
      />
    );

    // green success banner once the new results have been loaded
  } else if (newResultsAdded) {
    const numHiddenByFilter =
      previousCountSinceUnfiltered - previousCountSinceFiltered;
    let line2: ReactNode = null;

    // filter reduces the number of new results
    if (numHiddenByFilter > 0) {
      if (numHiddenByFilter === previousCountSinceUnfiltered) {
        line2 = (
          <div>
            <strong>No new threats</strong> will be visible due to your applied
            filters. Adjust or reset your filters to see all newly added
            threats.
          </div>
        );
      } else {
        line2 = (
          <div>
            <strong>{`${numHiddenByFilter} ${plThreat(
              numHiddenByFilter
            )}`}</strong>{" "}
            will not be visible due to your applied filters. Adjust or reset
            your filters to see all newly added threats.
          </div>
        );
      }
    }

    return (
      <InfoBanner
        className="threat-monitoring-new-threats-banner"
        type={BannerType.SUCCESS}
        onDismiss={() => {
          dispatch(tmSlice.actions.resetCountSince(module));
        }}
        message={
          <div className={"message-lines"}>
            <div>
              <strong>{`${previousCountSinceUnfiltered} new ${plThreat(
                previousCountSinceUnfiltered
              )}`}</strong>{" "}
              added.
              {line2}
            </div>
          </div>
        }
      />
    );
  }

  return null;
}

import { FC, useMemo } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import SecurityRatingDisplay, { RatingSize } from "../SecurityRatingDisplay";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import { App, AppWithVendor } from "../../api/types";
import { useSortingWithPagination } from "../../../_common/hooks";
import { useHistory, useLocation } from "react-router-dom";
import "./RecentlyDetectedApps.scss";
import { dashboardUrl } from "../../UserBaseAppRouter";
import { appUrl } from "../../UserBaseAppRouter";
import DashboardContainer from "./DashboardContainer";

const PAGE_SIZE = 5;
const HEADERS = {
  APP_NAME_HEADER: {
    id: "app_name_col",
    text: "App name",
  },
  SECURITY_RATING_HEADER: {
    id: "security_rating_col",
    text: "Security Rating",
  },
  EXPOSURE_LEVEL_HEADER: {
    id: "exposure_level_col",
    text: "Exposure level",
  },
};

interface RecentlyDetectedAppsProps {
  lookbackDays?: number; //days to look backwards from today (e.g. 7 for apps detected the past week
}

const RecentlyDetectedApps: FC<RecentlyDetectedAppsProps> = ({
  lookbackDays = 7,
}) => {
  // Fetch apps detected in the past <searchRangeDays> days
  const weekAgoIsoString = useMemo(
    () => new Date(Date.now() - daysToMilliseconds(lookbackDays)).toISOString(),
    [lookbackDays]
  );
  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppsV1Query({
    minFirstDetected: weekAgoIsoString,
  });
  const appsToUse = data?.apps ?? [];

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: HEADERS.APP_NAME_HEADER.id,
      className: HEADERS.APP_NAME_HEADER.id,
      text: HEADERS.APP_NAME_HEADER.text,
      sortable: true,
    },
    {
      id: HEADERS.SECURITY_RATING_HEADER.id,
      className: HEADERS.SECURITY_RATING_HEADER.id,
      text: HEADERS.SECURITY_RATING_HEADER.text,
      sortable: true,
    },
    {
      id: HEADERS.EXPOSURE_LEVEL_HEADER.id,
      className: HEADERS.EXPOSURE_LEVEL_HEADER.id,
      text: HEADERS.EXPOSURE_LEVEL_HEADER.text,
      sortable: true,
    },
  ];

  const { pathname } = useLocation();
  const [
    sortedApps,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    AppWithVendor,
    | typeof HEADERS.APP_NAME_HEADER.id
    | typeof HEADERS.SECURITY_RATING_HEADER.id
    | typeof HEADERS.EXPOSURE_LEVEL_HEADER.id
  >(
    appsToUse,
    HEADERS.APP_NAME_HEADER.id,
    SortDirection.ASC,
    {
      [HEADERS.APP_NAME_HEADER.id]: {
        orderFuncs: [(a: AppWithVendor) => a.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [HEADERS.SECURITY_RATING_HEADER.id]: {
        orderFuncs: [(a: AppWithVendor) => a.securityScore],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [HEADERS.EXPOSURE_LEVEL_HEADER.id]: {
        orderFuncs: [(a: AppWithVendor) => a.highestScopeRiskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  // onclick function to navigate to app details page
  const history = useHistory();
  const goToAppPage = (app: App) => {
    history.push(appUrl(app.name), {
      from: "dashboard",
      backContext: {
        backTo: dashboardUrl,
        backToText: "Back to Dashboard",
      },
    });
  };

  const rows = sortedApps.map((app: AppWithVendor): IXTableRow<string> => {
    return {
      id: app.name,
      onClick: () => goToAppPage(app),
      cells: [
        <XTableCell key={HEADERS.APP_NAME_HEADER.id}>
          <CompanyLogo
            domain={app.domain}
            name={app.name}
            displayCategory={false}
            size="small"
          />
        </XTableCell>,
        <XTableCell key={HEADERS.SECURITY_RATING_HEADER.id}>
          <SecurityRatingDisplay
            rating={app.securityScore}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key={HEADERS.EXPOSURE_LEVEL_HEADER.id}>
          <ScopeRiskLevelDisplay riskLevel={app.highestScopeRiskLevel} />
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => goToAppPage(app),
        },
      ],
    };
  });

  const hasAppsToShow = sortedApps.length > 0;
  const header = `Recently detected apps over the past ${lookbackDays} days`;
  return (
    <div className="recently-detected-apps">
      <DashboardContainer title={header}>
        {isLoading && <LoadingBanner />}
        {!isLoading && hasAppsToShow && (
          <div className={"content"}>
            <XTable
              columnHeaders={columnHeaders}
              rows={rows}
              loading={isLoading}
              iconOptions={true}
              sortedBy={sortedBy}
              onSortChange={onSortChange}
              pagination={{
                currentPage: currentPage,
                totalPages: totalPages,
                onPageChange: onPageChange,
                hidePaginationIfSinglePage: true,
              }}
            />
          </div>
        )}
        {!isLoading && !hasAppsToShow && (
          <div className={"no-recent-apps-inner"}>
            <div className={"no-recent-apps-icon"}>
              <div className={"cr-icon-grid"} />
            </div>
            <div className={"no-recent-apps-text"}>No apps detected</div>
          </div>
        )}
      </DashboardContainer>
    </div>
  );
};

function daysToMilliseconds(days: number): number {
  return days * 24 * 60 * 60 * 1000;
}

export default RecentlyDetectedApps;

import {
  DefaultRouteProps,
  useDefaultHistory,
} from "../../_common/types/router";
import TrustPageAPI, {
  getPrefilledSurveyTags,
  TrustPageTagTypes,
} from "../api/trustpage.api";
import PrefilledSurveyDetailsOverviewCard from "../components/PrefilledSurveyDetailsOverviewCard";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import PageHeader from "../../_common/components/PageHeader";
import SurveyDetailsRisksCard, {
  getRisksList,
} from "../../_common/components/surveydetails/SurveyDetailsRisksCard";
import { getSurveyEditorPath } from "../../_common/views/SurveyDetails";
import SurveyDetailsUnansweredQuestionsCard from "../../_common/components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import { useRef } from "react";
import EmptyCardWithAction, {
  ErrorCardWithAction,
} from "../../_common/components/EmptyCardWithAction";
import {
  useHasUserOrPortfolioPermissions,
  UserReadSurveyImportExport,
  UserWritePrefilledSurveys,
} from "../../_common/permissions";
import * as Permissions from "../../_common/permissions";
import {
  ISurveyListItemResponse,
  PrefilledSurveyStatus,
} from "../../_common/types/survey";
import Button from "../../_common/components/core/Button";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import {
  setPrefilledSurveyDeleted,
  updateVendorSurveyAddToSharedProfile,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { exportPublicSurveyInNewTab } from "../helpers/helpers";
import NoAccessSvg from "../../_common/images/icon-lock.svg";
import ReportCard from "../../_common/components/ReportCard";
import { fetchSurveyDetails } from "../../_common/reducers/surveyDetails.actions";

interface matchParams {
  surveyId: string;
}

type IPrefilledSurveyDetailsViewProps = DefaultRouteProps<matchParams>;

const usePrefilledSurveyDetailsViewPermissions = () => ({
  userHasWritePrefilledSurveysPermission: useHasUserOrPortfolioPermissions(
    UserWritePrefilledSurveys
  ),
  userCanViewPrefilledSurveys: useHasUserOrPortfolioPermissions(
    Permissions.UserAccessPrefilledSurveys
  ),
  userCanViewImportedSurveys: useHasUserOrPortfolioPermissions(
    UserReadSurveyImportExport
  ),
});

// This view is used to manage the details of own prefilled survey
// For vendors' public surveys details view, see SharedProfileSurveyDetails.tsx
const PrefilledSurveyDetailsView = ({
  match,
  location,
}: IPrefilledSurveyDetailsViewProps) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory();
  const surveyId = parseInt(match.params.surveyId);
  const {
    userHasWritePrefilledSurveysPermission,
    userCanViewPrefilledSurveys,
    userCanViewImportedSurveys,
  } = usePrefilledSurveyDetailsViewPermissions();

  const {
    data: surveyData,
    isLoading,
    isError,
    refetch,
  } = TrustPageAPI.useGetPrefilledSurveyDetailsV1Query({
    surveyId,
    publishedOnly: false,
  });

  const survey = surveyData?.survey;

  const risksCount = getRisksList(survey?.risks || [], [], false).length;
  const hasUnansweredQuestions =
    survey?.unansweredQuestions && survey.unansweredQuestions.length > 0;
  const unansweredQuestionsRef = useRef<HTMLDivElement>(null);
  const onUnansweredQuestionsClick = () =>
    unansweredQuestionsRef.current?.scrollIntoView();

  // Figure out where the back button goes...
  let { backTo, backToText } = location.state?.backContext || {};
  const { backToContext } = location.state?.backContext || {};
  const { goBack } = location.state?.backContext || {};
  if (!backTo && !goBack) {
    // if no back context provided and user can view Trust Page - go back to Trust Page
    if (userCanViewPrefilledSurveys) {
      backTo = "/trustpage";
      backToText = "Back to Trust Page";
    } else {
      // if user can't view Trust Page - go back to Questionnaires
      backTo = "/tasks/trustexchange/questionnaires";
      backToText = "Back to Questionnaires";
    }
  }
  const backAction = () =>
    goBack ? history.goBack() : history.push(backTo as string, backToContext);

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const handleDeletePrefilledSurvey = (survey: ISurveyListItemResponse) =>
    openConfirmationModal({
      title: "Delete questionnaire",
      description:
        "The questionnaire will be permanently deleted and cannot be undone.",
      buttonText: "Delete",
      dangerousAction: true,
      cancelText: "Cancel",
      buttonAction: async () => {
        try {
          dispatch(setPrefilledSurveyDeleted(surveyId))
            .then(() => {
              if (survey.originalID > 0) {
                dispatch(
                  //switch off original survey add to shared profile flag
                  updateVendorSurveyAddToSharedProfile(survey.originalID, false)
                ).then(() =>
                  //re-fetch original survey details
                  dispatch(fetchSurveyDetails(survey.originalID, true))
                );
              }
            })
            .then(() =>
              dispatch(
                TrustPageAPI.util.invalidateTags([
                  TrustPageTagTypes.ownTrustPage,
                  ...getPrefilledSurveyTags(surveyId),
                ])
              )
            );
          dispatch(
            addDefaultSuccessAlert("Public questionnaire has been deleted")
          );
          backAction();
        } catch (e) {
          console.error(e);
          addDefaultUnknownErrorAlert("Error deleting public questionnaire");
          throw e;
        }
      },
    });

  const getSurveyPath = (
    editMode: boolean,
    surveyId: number,
    questionId?: string
  ) => {
    return getSurveyEditorPath({
      surveyId: surveyId,
      editMode: editMode,
      questionId,
      publicSurvey: true,
      location: history.location,
    });
  };

  return (
    <div className={"prefilled-survey-details"}>
      <PageHeader
        history={history}
        title={survey?.name ?? "Questionnaire details"}
        breadcrumbs={[
          {
            text: "Trust Page",
            to: `/trustpage`,
          },
          { text: "Questionnaire Details" },
        ]}
        backText={backToText}
        backAction={backAction}
        rightSection={
          survey && (
            <>
              <Button
                onClick={() => exportPublicSurveyInNewTab(survey.id, false)}
                className="download-btn"
              >
                <div className="cr-icon-export-thin" />
                Download
              </Button>
              <SidePopupV2
                text={
                  !userHasWritePrefilledSurveysPermission
                    ? "You do not have permission to delete this questionnaire."
                    : survey?.status !== PrefilledSurveyStatus.Draft
                      ? "This questionnaire has been shared and cannot be deleted."
                      : undefined
                }
                position="left"
              >
                <Button
                  danger
                  onClick={() => handleDeletePrefilledSurvey(survey)}
                  disabled={
                    !userHasWritePrefilledSurveysPermission ||
                    survey?.status !== PrefilledSurveyStatus.Draft
                  }
                >
                  <div className="cr-icon-trash" />
                  Delete
                </Button>
              </SidePopupV2>
            </>
          )
        }
      />

      {isLoading ? (
        <LoadingBanner />
      ) : isError ? (
        userHasWritePrefilledSurveysPermission ? (
          <ErrorCardWithAction actionText={"Try again"} action={refetch} />
        ) : (
          <ReportCard newStyles>
            <EmptyCardWithAction
              iconSrc={NoAccessSvg}
              emptyText={
                "You don’t have permission to access questionnaire details"
              }
              emptySubText={
                "If you believe you should have access, please contact an administrator of your UpGuard account."
              }
            />
          </ReportCard>
        )
      ) : survey ? (
        <>
          <PrefilledSurveyDetailsOverviewCard
            survey={survey}
            onOpenSurveyEditor={() =>
              getSurveyPath(userHasWritePrefilledSurveysPermission, survey.id)
            }
            userHasWritePermission={userHasWritePrefilledSurveysPermission}
            userCanViewImportedSurveys={userCanViewImportedSurveys}
            hasUnansweredQuestions={hasUnansweredQuestions || false}
            onUnansweredQuestionsClick={onUnansweredQuestionsClick}
          />
          {risksCount > 0 && (
            <SurveyDetailsRisksCard
              history={history}
              location={location}
              surveyId={survey.id}
              vendorId={survey.vendorId}
              surveyRisks={survey.risks || []}
              riskWaivers={survey.riskWaivers || []}
              userHasWaivePermission={false}
              userHasRemediationPermission={false}
              isManagementAnalystSession={false}
              managedOrgId={0}
              title={"Risks from questionnaire"}
              getSurveyPath={(_editMode, surveyId, questionId) =>
                getSurveyPath(
                  userHasWritePrefilledSurveysPermission,
                  surveyId!,
                  questionId
                )
              }
              publicSurvey={true}
              publicSurveyNotIncludedInProfile={survey.excludeFromVendorScore}
              isVendorPortal={true}
              surveyStatus={survey.status}
            />
          )}
          {hasUnansweredQuestions && (
            <SurveyDetailsUnansweredQuestionsCard
              ref={unansweredQuestionsRef}
              history={history}
              surveyId={survey.id}
              unansweredQuestions={survey.unansweredQuestions || []}
              fromCompanyName={survey.fromCompany}
              numQuestions={survey.numQuestions}
              numAnswers={survey.numAnswers}
              isVendorPortal={true}
              getSurveyPath={getSurveyPath}
              survey={survey}
              isCurrentUserRecipient={false}
            />
          )}
        </>
      ) : null}
      {confirmationModal}
    </div>
  );
};

export default PrefilledSurveyDetailsView;

import { ISharedAssessment } from "../../vendorrisk/types/sharedAssessment";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create a seperate rtkQuery API for the public verified vendors part
const VerifiedVendorsPublicBaseAPI = createApi({
  reducerPath: "verifiedvendorspublicapi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  keepUnusedDataFor: 600, // Default to keeping cached data for 10 mins after all subscriptions removed
  endpoints: () => ({}),
});

export enum VerifiedVendorsTagTypes {
  publicTrustPage = "publicTrustPage",
}

type GetPublicTrustPageV1Resp = ISharedAssessment;

const VerifiedVendorsPublicAPI = VerifiedVendorsPublicBaseAPI
  // Add all our VerifiedVendors cache tags here
  .enhanceEndpoints({
    addTagTypes: [...Object.values(VerifiedVendorsTagTypes)],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      /*
        getPublicTrustPage
        - gets the public trust page for a verified vendor
       */
      getPublicTrustPageV1: builder.query<
        GetPublicTrustPageV1Resp,
        { uuid: string }
      >({
        query: ({ uuid }) => ({
          url: "/api/sharedassessment/public/v1/",
          method: "GET",
          params: {
            uuid,
          },
        }),
        providesTags: (result, _, req) =>
          result
            ? [
                {
                  type: VerifiedVendorsTagTypes.publicTrustPage,
                  id: req.uuid,
                },
              ]
            : [],
      }),

      /*
        GetTrustPagePublicForRequestDomainV1
        - gets the public trust page for a verified vendor via the request domain
       */
      getTrustPagePublicForRequestDomainV1: builder.query<
        GetPublicTrustPageV1Resp,
        void
      >({
        query: () => ({
          url: "/api/trustpage/public/v1/",
          method: "GET",
        }),
        providesTags: (result, _, __) =>
          result
            ? [
                {
                  type: VerifiedVendorsTagTypes.publicTrustPage,
                  id: result.uuid,
                },
              ]
            : [],
      }),
    }),
  });

export default VerifiedVendorsPublicAPI;

import { useEffect, useMemo, useState } from "react";
import { RiskVendorWebsitesData } from "../../../_common/types/redux";
import {
  IVendorRiskWaiver,
  VendorRiskWaiverStatusType,
  WaiverType,
} from "../../../_common/types/vendorRiskWaivers";
import {
  fetchSubsidiaryRiskWaivers,
  fetchVendorRiskWaivers,
  getSubsidiaryRiskWaiversFromState,
  getVendorRiskWaiversFromState,
  updateSubsidiaryRiskWaiver,
  updateVendorRiskWaiver,
} from "../../reducers/vendorRiskWaiver.actions";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  AssuranceType,
  OrgFlagType,
} from "../../../_common/types/organisations";
import { Steps } from "../../../_common/components/StepsWithSections";
import { VendorSummaryRisk } from "../../../_common/types/vendorSummary";
import {
  OrgSecurityProfileRiskAdjustments,
  useBasicPermissions,
} from "../../../_common/permissions";
import {
  AssetSelection,
  getRiskAssetText,
  isAssetSelectionValid,
  RiskAssetSelectionForWaiver,
} from "./SelectRiskTable";
import { calculateWaivedAssetsForRisks } from "../../reducers/risks.actions";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import "../../style/components/risk_waivers/EditVendorRiskWaiverModal.scss";
import ConfirmStep from "./ConfirmStep";
import { TextFieldData } from "../../../_common/components/TextField";
import WaiverDetailsStep, { isWaiverDetailsValid } from "./WaiverDetailsStep";
import moment from "moment";
import { getRiskVendorWebsites } from "../../reducers/vendorRiskPortfolio.actions";
import { Severity, SeverityAsString } from "../../../_common/types/severity";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import {
  appConnect,
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import OrganisationFlagsAPI from "../../../_common/api/organisationFlagsAPI";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { fetchVendorSummaryAndCloudscans } from "../../reducers/cyberRiskActions";
import { fetchBreachsightRiskProfileIncludingSubsidiaries } from "../../reducers/breachsightSubsidiaries.actions";
import VendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { skipToken } from "@reduxjs/toolkit/query";

interface EditWaiverModalOwnProps {
  isShowing: boolean;
  vendorId: number;
  waiver?: IVendorRiskWaiver;
  onClose: (didUpdate: boolean) => Promise<void>;
  assuranceType: AssuranceType;
  managedOrgId?: number;
  isManagementAnalystSession?: boolean;
  isSubsidiary?: boolean;
}

interface EditWaiveModalConnectedProps {
  vendorRiskWaivers?: IVendorRiskWaiver[];
  riskHostnames?: RiskVendorWebsitesData;
}

type EditWaiverModalProps = EditWaiverModalOwnProps &
  EditWaiveModalConnectedProps;

const EditVendorWaiverModal = (props: EditWaiverModalProps) => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hostsLoading, setHostsLoading] = useState(props.isSubsidiary);

  const summaryRisks = useAppSelector((state) => {
    if (props.vendorId || (props.isSubsidiary && props.waiver)) {
      if (!!props.managedOrgId) {
        return state.cyberRisk.managedVendorData[props.managedOrgId]
          ? state.cyberRisk.managedVendorData[props.managedOrgId][
              props.vendorId
            ]?.summary?.result?.risks
          : undefined;
      } else if (props.isSubsidiary) {
        return state.cyberRisk.subsidiaries[
          props.waiver?.datastoreVendorID ?? 0
        ]?.summary?.result?.risks;
      } else {
        return state.cyberRisk.vendors[props.vendorId]?.summary?.result?.risks;
      }
    }

    return undefined;
  });

  const permissions = useBasicPermissions();
  const hasSpAdjustments =
    permissions.orgPermissions[OrgSecurityProfileRiskAdjustments];
  const { data: securityProfile } =
    VendorSecurityProfileAPI.useGetSecurityProfileQuery(
      hasSpAdjustments
        ? {
            vendorId: props.vendorId,
          }
        : skipToken
    );

  const waivedRisk = useMemo(() => {
    if (summaryRisks && props.waiver) {
      const allRisks = [
        ...summaryRisks,
        ...Object.values(securityProfile?.documentChecks ?? {}),
      ];

      if (
        props.waiver.waiverType === WaiverType.SeverityAdjustment &&
        props.waiver.status === VendorRiskWaiverStatusType.Active
      ) {
        return allRisks.find(
          (r) =>
            r.baseId === props.waiver?.riskID &&
            SeverityAsString(r.severity) === props.waiver?.adjustedSeverity
        );
      } else {
        return allRisks.find((r) => r.id === props.waiver?.riskID);
      }
    }

    return undefined;
  }, [securityProfile, summaryRisks, props.waiver]);

  const updateWaiver = () => {
    if (props.waiver) {
      setIsSaving(true);
      dispatch(
        props.isSubsidiary
          ? updateSubsidiaryRiskWaiver(props.waiver.datastoreVendorID, {
              ID: props.waiver.id,
              expiresAt: expiryDate,
              approverEmail: approverEmail.value,
              justification: justification.value,
              isAllDomains:
                selection.hostnameSelection.allSelected &&
                selection.hostnameSelection.includeFuture,
              domains: selection.hostnameSelection.selected,
            })
          : updateVendorRiskWaiver(props.waiver.datastoreVendorID, {
              ID: props.waiver.id,
              expiresAt: expiryDate,
              approverEmail: approverEmail.value,
              justification: justification.value,
              isAllDomains:
                selection.hostnameSelection.allSelected &&
                selection.hostnameSelection.includeFuture,
              domains: selection.hostnameSelection.selected,
              surveys: selection.selectedQuestionnaires,
              publicSurveys: selection.selectedPublicQuestionnaires,
              adjustedSeverity: adjustedSeverity,
            })
      )
        .then(() => {
          const proms: Promise<any>[] = [];
          if (props.isSubsidiary) {
            proms.push(
              dispatch(
                fetchSubsidiaryRiskWaivers(
                  props.waiver?.datastoreVendorID ?? 0,
                  true
                )
              )
            );
            proms.push(
              dispatch(
                fetchBreachsightRiskProfileIncludingSubsidiaries(
                  true,
                  props.waiver?.datastoreVendorID ?? 0
                )
              )
            );
          } else {
            proms.push(
              dispatch(fetchVendorRiskWaivers(props.vendorId ?? 0, true))
            );
          }
          return Promise.all(proms);
        })
        .then(() => {
          dispatch(addDefaultSuccessAlert("Risk waiver updated"));
          props.onClose(true);
        })
        .then(() => setIsSaving(false))
        .catch((e) => {
          setIsSaving(false);
          console.error("error editing waiver", e);
          dispatch(addDefaultUnknownErrorAlert("Failed to update risk waiver"));
        });
    }
  };

  const [selection, setSelection] = useState<AssetSelection>(
    getSelectedAssetsFromVendorWaiver(
      waivedRisk,
      props.waiver,
      props.riskHostnames
    )
  );
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
  const [justification, setJustification] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [adjustedSeverity, setAdjustedSeverity] = useState<
    Severity | undefined
  >(undefined);

  const [approverRequired, setApproverRequired] = useState(false);
  const [approverEmail, setApproverEmail] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });

  const { data: orgFlags } =
    OrganisationFlagsAPI.useGetOrganisationFlagsV1Query();

  useEffect(
    function setInitialStatesWhenDataLoaded() {
      if (!orgFlags) return;

      const requireApprovalsRiskWaivers =
        orgFlags[OrgFlagType.VendorRiskRequireApprovalsRiskWaivers];
      const requireApprovalsRiskAdjustments =
        orgFlags[OrgFlagType.VendorRiskRequireApprovalsRiskAdjustments];

      const isApproverRequired =
        waiverType === WaiverType.SeverityAdjustment
          ? requireApprovalsRiskAdjustments
          : requireApprovalsRiskWaivers;

      setApproverEmail({ value: "", isValid: !isApproverRequired });
      setApproverRequired(isApproverRequired);

      // Now we are in a safe, known state, we can show the modal content and
      // permit the user proceed.
      setCurrentStep(1);
    },
    [orgFlags]
  );

  useEffect(() => {
    if (props.waiver && props.isShowing) {
      setApproverEmail({
        value: props.waiver.approverEmail ?? "",
        isValid: props.waiver.approverEmail !== undefined,
      });
      setExpiryDate(
        props.waiver.expiresAt
          ? moment(props.waiver.expiresAt)
              .utcOffset(moment().utcOffset())
              .format("YYYY-MM-DD")
          : undefined
      );
      setJustification({
        value: props.waiver.justification,
        isValid: true,
      });
      setSelection(
        getSelectedAssetsFromVendorWaiver(
          waivedRisk,
          props.waiver,
          props.riskHostnames
        )
      );
      setCurrentStep(1);
      setAdjustedSeverity(props.waiver.adjustedSeverity);
    }
  }, [props.waiver, waivedRisk, props.riskHostnames]);

  const [risks, setRisks] = useState(waivedRisk ? [waivedRisk] : []);
  useEffect(() => {
    setRisks(waivedRisk ? [waivedRisk] : []);
  }, [waivedRisk]);

  // Retrieve vendor risk waivers if we need to
  useEffect(() => {
    if (props.isShowing && !props.vendorRiskWaivers) {
      fetchVendorRiskWaivers(props.vendorId);
    }
  }, [props.isShowing]);

  useEffect(() => {
    if (props.isSubsidiary && props.waiver) {
      setLoading(true);
      dispatch(
        fetchVendorSummaryAndCloudscans(
          props.waiver?.datastoreVendorID,
          false,
          false,
          false,
          props.isSubsidiary
        )
      )
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }
  }, [props.isSubsidiary, props.waiver, dispatch]);

  useEffect(() => {
    if (props.isSubsidiary && props.waiver && !!props.riskHostnames) {
      setHostsLoading(false);
    }
  }, [props.isSubsidiary, props.waiver, props.riskHostnames]);

  const waivedRiskAssets = calculateWaivedAssetsForRisks(
    risks,
    false,
    props.vendorRiskWaivers ? props.vendorRiskWaivers : [],
    undefined,
    undefined,
    props.waiver ? props.waiver.id : undefined
  );

  const waiverType = props.waiver?.waiverType ?? WaiverType.RiskWaiver;

  const header =
    waiverType === WaiverType.SeverityAdjustment
      ? "Edit risk adjustment"
      : "Edit risk waiver";

  const step2Disabled = waivedRisk
    ? !isAssetSelectionValid(waivedRisk, selection)
    : true;

  const step3Disabled =
    step2Disabled ||
    !isWaiverDetailsValid(approverEmail.isValid, justification.value);

  const severitiesToHide: Severity[] = [];

  if (waivedRisk?.baseSeverity) {
    severitiesToHide.push(SeverityAsString(waivedRisk.baseSeverity));
  }

  return (
    <ModalV2
      width={1000}
      headerContent={header}
      className={"edit-vendor-risk-waiver-modal"}
      footerClassName={"edit-vendor-risk-waiver-modal-footer"}
      active={props.isShowing}
      onClose={() => props.onClose(false)}
      footerContent={
        <>
          {currentStep == 1 && (
            <>
              <div className={"btn-group"}>
                <Button
                  tertiary
                  onClick={() => props.onClose(false)}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                {waivedRisk && (
                  <Button
                    primary
                    arrow
                    disabled={
                      waivedRisk
                        ? !isAssetSelectionValid(waivedRisk, selection)
                        : true
                    }
                    onClick={() => setCurrentStep(2)}
                  >
                    Next
                  </Button>
                )}
              </div>
            </>
          )}
          {currentStep == 2 && (
            <>
              <div className={"btn-group"}>
                <Button onClick={() => setCurrentStep(1)} leftArrow>
                  Previous
                </Button>
              </div>
              <div className={"btn-group"}>
                <Button
                  tertiary
                  onClick={() => props.onClose(false)}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  arrow
                  onClick={() => setCurrentStep(3)}
                  disabled={
                    !isWaiverDetailsValid(
                      approverEmail.isValid,
                      justification.value
                    )
                  }
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep == 3 && (
            <>
              <div className={"btn-group"}>
                <Button onClick={() => setCurrentStep(2)} leftArrow>
                  Previous
                </Button>
              </div>
              <div className={"btn-group"}>
                <Button
                  tertiary
                  onClick={() => props.onClose(false)}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  onClick={updateWaiver}
                  loading={isSaving}
                  disabled={
                    !isWaiverDetailsValid(
                      approverEmail.isValid,
                      justification.value
                    ) ||
                    (waivedRisk
                      ? !isAssetSelectionValid(waivedRisk, selection)
                      : true)
                  }
                >
                  Save changes
                </Button>
              </div>
            </>
          )}
        </>
      }
    >
      {currentStep === 0 || loading || hostsLoading ? (
        <LoadingBanner />
      ) : (
        <>
          {waivedRisk && (
            <Steps
              steps={[
                {
                  id: "1",
                  text: "Select assets",
                  onClick: () => setCurrentStep(1),
                },
                {
                  id: "2",
                  text: "Configure",
                  onClick: () => setCurrentStep(2),
                  disabled: step2Disabled,
                },
                {
                  id: "3",
                  text: "Review",
                  onClick: () => setCurrentStep(3),
                  disabled: step3Disabled,
                },
              ]}
              currentStep={currentStep}
            />
          )}
          {!waivedRisk && (
            <SearchEmptyCard
              searchTextOverride={"Risk not found"}
              subTextOverride={"There are currently no assets with this risk."}
            />
          )}
          {currentStep === 1 && waivedRisk && (
            <div>
              <div className={"risk-header"}>
                <AdjustedSeverityIcon
                  severity={SeverityAsString(waivedRisk.severity)}
                  baseSeverity={
                    waivedRisk.baseSeverity
                      ? SeverityAsString(waivedRisk.baseSeverity)
                      : undefined
                  }
                />
                <div className={"risk-text-container"}>
                  <div className={"risk-text"}>{waivedRisk.title}</div>
                  <div className={"risk-cat-title"}>
                    {waivedRisk.categoryTitle}
                  </div>
                </div>
                <div className={"asset-count"}>
                  {getRiskAssetText(
                    waivedRisk,
                    true,
                    selection,
                    waivedRiskAssets
                  )}
                </div>
              </div>
              <RiskAssetSelectionForWaiver
                risk={waivedRisk}
                selected
                selection={selection}
                vendorId={props.vendorId}
                onSelectionChange={setSelection}
                managedOrgId={props.managedOrgId}
                isVendorManagementAnalyst={props.isManagementAnalystSession}
                skipWaiverId={props.waiver?.id}
                isSubsidiary={props.isSubsidiary}
              />
            </div>
          )}
          {currentStep === 2 && waivedRisk && (
            <WaiverDetailsStep
              waiverType={props.waiver?.waiverType ?? WaiverType.RiskWaiver}
              hideApproverSettings={
                props.waiver?.status !==
                VendorRiskWaiverStatusType.AwaitingApproval
              }
              approverRequired={approverRequired}
              approverEmail={approverEmail.value}
              justification={justification.value}
              onJustificationChanged={(val) =>
                setJustification({ value: val, isValid: val.length > 0 })
              }
              onApproverChanged={(approverEmail: string, valid: boolean) => {
                setApproverEmail({ value: approverEmail, isValid: valid });
              }}
              expiry={expiryDate}
              onExpiryChanged={setExpiryDate}
              assuranceType={props.assuranceType}
              origSeverity={SeverityAsString(waivedRisk.severity)}
              severity={adjustedSeverity}
              onSeverityChanged={setAdjustedSeverity}
              hideSeverities={severitiesToHide}
            />
          )}
          {currentStep === 3 && waivedRisk && (
            <ConfirmStep
              waiverType={waiverType}
              risk={waivedRisk}
              selectedAssets={selection}
              approverEmail={approverEmail.value}
              justification={justification.value}
              assuranceType={props.assuranceType}
              expiry={expiryDate}
              adjustedSeverity={adjustedSeverity}
            />
          )}
        </>
      )}
    </ModalV2>
  );
};

export const getSelectedAssetsFromVendorWaiver = (
  risk?: VendorSummaryRisk,
  waiver?: IVendorRiskWaiver,
  riskVendorWebsites?: RiskVendorWebsitesData
): AssetSelection => {
  if (!waiver || !risk) {
    return {
      hostnameSelection: {
        allSelected: false,
        includeFuture: false,
        selected: [],
      },
      allQuestionnairesSelected: false,
      selectedQuestionnaires: [],
      selectedPublicQuestionnaires: [],
    };
  }

  return {
    hostnameSelection: {
      allSelected: waiver.isAllDomains ?? false,
      includeFuture: waiver.isAllDomains ?? false,
      selected: waiver.isAllDomains
        ? risk.failedCloudscans?.map((c) => c.hostname) ?? []
        : waiver.domains.filter(
            (h) => riskVendorWebsites?.hostnamesWithRisk.find((fc) => fc === h)
          ),
    },
    allQuestionnairesSelected: waiver.isAllSurveys ?? false,
    selectedQuestionnaires: waiver.surveys.filter(
      (sId) => risk.surveys?.find((s) => s.surveyId === sId && !s.publicSurvey)
    ),
    selectedPublicQuestionnaires: waiver.publicSurveys.filter(
      (sId) => risk.surveys?.find((s) => s.surveyId === sId && s.publicSurvey)
    ),
  };
};

export default appConnect<
  EditWaiveModalConnectedProps,
  never,
  EditWaiverModalOwnProps
>((state, props) => {
  const riskVendorWebsites = props.waiver
    ? getRiskVendorWebsites(
        state,
        props.waiver.riskID,
        props.waiver.datastoreVendorID,
        props.isSubsidiary ?? false,
        false,
        props.waiver.id,
        ""
      )
    : undefined;

  return {
    vendorRiskWaivers: props.isSubsidiary
      ? getSubsidiaryRiskWaiversFromState(
          state,
          props.waiver?.datastoreVendorID ?? 0
        )
      : getVendorRiskWaiversFromState(state, props.vendorId ?? 0),
    riskHostnames: riskVendorWebsites?.data ?? undefined,
  };
})(EditVendorWaiverModal);

import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import InfoBanner, {
  BannerType,
  SubItemType,
} from "../../../vendorrisk/components/InfoBanner";
import { useInterval } from "../../../_common/hooks";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import TrustPageCustomDomainsAPI from "../../api/trustPageCustomDomains.api";
import { FlowingExternalLink } from "../../../_common/components/ExternalLink";
import "./TrustPageCustomDomainDnsDisplay.scss";
import { verifyTrustPageCustomDomainV1Resp } from "../../api/trustpage.api.types";

interface TrustPageCustomDomainDnsDisplayProps {
  domain: string;
}

const TrustPageCustomDomainDnsDisplay: FC<
  TrustPageCustomDomainDnsDisplayProps
> = ({ domain }) => {
  const dispatch = useAppDispatch();
  const [verifyDomain] =
    TrustPageCustomDomainsAPI.useVerifyTrustPageCustomDomainV1Mutation();

  const [verifyResult, setVerifyResult] = useState<
    verifyTrustPageCustomDomainV1Resp | undefined
  >(undefined);

  const verifyPendingDomain = useCallback(() => {
    if (
      domain &&
      (!verifyResult ||
        verifyResult.status === "pending_dns" ||
        verifyResult.status === "issue_detected")
    ) {
      verifyDomain({ domain })
        .unwrap()
        .then((val) => {
          setVerifyResult(val);
        })
        .catch(() => {
          dispatch(addDefaultUnknownErrorAlert("Error refreshing DNS status"));
        });
    }
  }, [domain]);

  useEffect(() => {
    verifyPendingDomain();
  }, [domain]);

  // Start an interval to trigger polling if domain pending DNS
  useInterval(verifyPendingDomain, 15000);

  // Show an error banner if the domain was active but now has an issue
  let bannerDisplay: ReactNode | undefined;
  if (verifyResult?.verifyErrors && verifyResult.status === "issue_detected") {
    const messages = verifyResult.verifyErrors.map((i) => {
      switch (i) {
        case "CNAME_INVALID":
          return "Ensure the CNAME is setup as below";
      }
    });

    if (messages.length > 0) {
      bannerDisplay = (
        <InfoBanner
          type={BannerType.ERROR}
          message={"Issue Detected"}
          subItems={messages}
          subItemType={SubItemType.BULLET}
        />
      );
    }
  } else if (verifyResult?.status === "complete") {
    bannerDisplay = (
      <InfoBanner
        type={BannerType.SUCCESS}
        message={"Your domain is setup correctly."}
      />
    );
  }

  if (!verifyResult) {
    return <LoadingBanner />;
  }

  return (
    <div className={"trust-page-custom-domain-dns-display"}>
      {bannerDisplay}
      <label>Recommended action:</label>
      <p>
        Go to your DNS provider and enter the following DNS records by ensuring
        the exact settings listed below.
      </p>
      <table className={"dns-records-table"}>
        <thead>
          <tr>
            <th>Record Type</th>
            <th>Name/Host</th>
            <th>Target/Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CNAME</td>
            <td>{domain}</td>
            <td>{window.PUBLIC_TRUST_PAGE_CNAME_TARGET}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        View the help documentation for{" "}
        <FlowingExternalLink
          hideIcon
          url={"https://help.upguard.com/en/articles/trust-page-custom-url"}
          text={"adding a CNAME record."}
        />{" "}
      </p>
      <p>
        You&apos;ll be notified once we&apos;ve detected your DNS has been
        configured correctly. We&apos;ll continually check the status of your
        domain, and this process may take up to 24 hours.
      </p>
    </div>
  );
};

export default TrustPageCustomDomainDnsDisplay;

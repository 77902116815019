import { useBack } from "../../../_common/types/router";
import { UserRouteParams } from "../../UserBaseNavItems";
import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import UserBaseUserHeader from "../../components/headers/UserBaseUserHeader";
import {
  userApplicationsUrl,
  userUrl,
  usersUrl,
} from "../../UserBaseAppRouter";
import UserApps from "../../components/users/UserApps";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const UserApplicationsView: FC = () => {
  const match = useDecodedRouteMatch<UserRouteParams>();
  const { backAction, backText } = useBack();

  const userUUID = match.params.userUUID;

  const { data } = userbaseApi.useGetUserBaseUserSummaryV1Query({ userUUID });

  return (
    <div className="user-profile">
      <UserBaseUserHeader
        userUUID={userUUID}
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: data?.user.name ?? "User",
            to: userUrl(userUUID),
          },
          {
            text: "Applications",
            to: userApplicationsUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Applications"}
      />

      <UserApps userUUID={userUUID} />
    </div>
  );
};

export default UserApplicationsView;

import { FC } from "react";
import PublicTrustPageView from "./PublicTrustPageView";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  IMySharedAssessmentAccess,
  ISharedAssessment,
} from "../../vendorrisk/types/sharedAssessment";
import MessageBar from "../../_common/components/messageBar/MessageBar";
import Button from "../../_common/components/core/Button";
import "./PublicTrustPagePreviewView.scss";
import useTitleTag from "../../_common/hooks/useTitleTag";
import PublishTrustPageButton from "../components/publishTrustPageButton/PublishTrustPageButton";
import { DefaultRouteProps } from "../../_common/types/router";
import { trustPagePreviewRoute, trustPageRoute } from "../TrustPageAppRouter";
import trustpageApi from "../api/trustpage.api";

type PublicTrustPagePreviewViewProps = DefaultRouteProps;

// PublicTrustPagePreviewView allows the user to view their own orgs trust page in various modes
const PublicTrustPagePreviewView: FC<PublicTrustPagePreviewViewProps> = ({
  history,
  location,
}) => {
  useTitleTag("Trust Page Preview");

  const {
    data: sharedAssessment,
    isLoading,
    error,
  } = trustpageApi.useGetOwnSharedAssessmentV1Query();

  const { data: sharedAssessmentAccess, error: accessError } =
    trustpageApi.useGetOwnSharedAssessmentAccessV1Query();

  const goBack = () => {
    if (location.state?.backContext?.goBack) {
      history.goBack();
    } else {
      history.push(trustPageRoute, {
        backContext: {
          backTo: trustPagePreviewRoute,
          backToText: "Back to Trust Page preview",
        },
      });
    }
  };

  if (isLoading) {
    return <LoadingBanner />;
  }

  return (
    <>
      {(error || accessError) && <PublicTrustPageView />}
      {sharedAssessment && sharedAssessmentAccess && (
        <div className={"public-trust-page-preview-view"}>
          <PreviewMessageBar
            sharedAssessment={sharedAssessment}
            sharedAssessmentAccess={sharedAssessmentAccess}
            goBack={goBack}
          />
          <PublicTrustPageView
            sharedAssessment={sharedAssessment}
            publishedStateOverride={true}
          />
          {/*<PageviewTracker />*/}
        </div>
      )}
    </>
  );
};

interface PreviewMessageBarProps {
  sharedAssessment: ISharedAssessment;
  sharedAssessmentAccess: IMySharedAssessmentAccess;
  goBack: () => void;
}

const PreviewMessageBar: FC<PreviewMessageBarProps> = ({
  sharedAssessment,
  sharedAssessmentAccess,
  goBack,
}) => {
  return (
    <MessageBar>
      <Button primary leftArrow onClick={goBack}>
        Go back
      </Button>
      <PublishTrustPageButton
        sharedAssessment={sharedAssessment}
        sharedAssessmentAccess={sharedAssessmentAccess}
      />
    </MessageBar>
  );
};

export default PublicTrustPagePreviewView;

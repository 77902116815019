import BaseAPI from "../../_common/rtkQueryApi";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";
import {
  AppStatus,
  SaaSUser,
  AppUser,
  Team,
  AppEvent,
  RiskBreakdown,
  AppScope,
  Scope,
  AppScopeUser,
  UserWithStats,
  UserSummary,
  UserApp,
  IdentityBreach,
  UserScopeApp,
  DashboardStats,
  UserEvent,
  OrgScope,
  Role,
  UserDirectoryStats,
  UnmonitoredUser,
  ScanStatus,
  AppWithVendor,
  UsersFilter,
  AppsFilter,
} from "./types";
import { IScore } from "../../_common/types/score";
import { OrganisationAcceptedRisk } from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { IUserMini } from "../../_common/types/user";
import {
  RemediationRequestCounts,
  RemediationRequestWithMeta,
} from "../../_common/types/remediation";

export enum UserBaseTagTypes {
  risks = "risks",
  riskUsers = "riskUsers",
  users = "users",
  apps = "apps",
  teams = "teams",
  roles = "roles",
  app = "app",
  appUsers = "appUsers",
  appUserScopes = "appUserScopes",
  appTimeline = "appTimeline",
  appRiskBreakdown = "appRisksBreakdown",
  appScopes = "appScopes",
  summary = "summary",
  appScopeUsers = "appScopeUsers",
  riskWaivers = "riskWaivers",
  userSummary = "userSummary",
  userRiskProfile = "userRiskProfile",
  userApps = "userApps",
  identityBreaches = "identityBreaches",
  identityBreachSummary = "identityBreachSummary",
  userScopes = "userScopeApps",
  dashboardStats = "dashboardStats",
  userTimeline = "userTimeline",
  orgScopes = "orgScopes",
  appsUserScope = "appsUserScope",
  userDirectoryStats = "userDirectoryStats",
  orgScopeCategories = "orgScopeCategories",
  remediations = "remediations",
  unmonitoredUsers = "unmonitoredUsers",
  scan = "scan",
}

export interface GetUserBaseRisksV1Resp {
  risks: VendorSummaryRisk[];
}

export interface GetUserBaseRiskUsersV1Resp {
  users: SaaSUser[];
}

export interface GetUserBaseUsersV1Resp {
  users: UserWithStats[];
}

export interface GetUserBaseAppsV1Resp {
  apps: AppWithVendor[];
}

export interface GetUserBaseTeamsV1Resp {
  teams: Team[];
}

export interface GetUserBaseRolesV1Resp {
  roles: Role[];
}

export interface UpdateReviewedAppV1Req {
  appName: string;
  status: AppStatus;
  approvedForAll: boolean;
  approvedForTeams?: string[];
  approvedForRoles?: string[];
  note?: string;
}

export interface GetAppSummaryV1Resp {
  app: AppWithVendor;
}

export interface GetAppUsersV1Resp {
  users: AppUser[];
}

export interface GetAppUserScopesV1Resp {
  scopes: Scope[];
}

export interface GetSummaryV1Resp {
  scores: IScore[];
  adjustedScores: IScore[];
  categoryScores: Record<string, IScore[]>;
  adjustedCategoryScores: Record<string, IScore[]>;
}

export interface GetAppTimelineV1Resp {
  events: AppEvent[];
}

export interface GetAppRisksBreakdownV1Resp {
  breakdown: RiskBreakdown[];
}

export interface GetAppScopesV1Resp {
  scopes: AppScope[];
}

export interface GetAppsScopeUsersV1Resp {
  users: AppScopeUser[];
}

export interface UserBaseRiskAcceptanceCreateV1ReqBody {
  riskId: string;
  approverEmail?: string;
  justification: string;
  expiresAt?: string;
  userbaseUserUuids?: string[];
}

export interface GetRiskWaiversV1Resp {
  waivers: OrganisationAcceptedRisk[];
  users: IUserMini[];
}

export interface GetUserSummaryV1Resp {
  user: UserSummary;
}

export interface GetUserRiskProfileV1Resp {
  risks: VendorSummaryRisk[];
}

export interface GetUserAppsV1Resp {
  apps: UserApp[];
}

export interface GetIdentityBreachesV1Resp {
  breaches: IdentityBreach[];
}

export interface GetIdentityBreachSummaryV1Resp {
  breach: IdentityBreach;
}

export interface GetUserScopeAppsV1Resp {
  scopes: UserScopeApp[];
}

export interface GetDashboardStatsV1Resp {
  stats: DashboardStats;
}

export interface GetUserTimelineV1Resp {
  events: UserEvent[];
}

export interface GetScopesV1Resp {
  scopes: OrgScope[];
}

export interface GetAppsForUserScopeV1Resp {
  apps: UserApp[];
}

export interface GetUserDirectoryStatsV1Resp {
  stats: UserDirectoryStats | null;
}

export interface GetScopeCategoriesV1Resp {
  categories: string[];
}

export interface GetUserBaseRemediationsV1Resp {
  requests: RemediationRequestWithMeta[];
  count: RemediationRequestCounts;
}

export interface GetUserBaseUnmonitoredUsersV1Resp {
  users: UnmonitoredUser[];
}

export interface MonitorUsersV1Req {
  user_uuids: string[];
}

export interface UnmonitorUsersV1Req {
  user_uuids: string[];
}

export interface GetUserRiskScanStatusV1Resp {
  status: ScanStatus;
}

const UserBaseAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(UserBaseTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    /*
        getUserBaseRisksV1
        - gets the list of risks
       */
    getUserBaseRisksV1: builder.query<
      GetUserBaseRisksV1Resp,
      { riskId?: string }
    >({
      query: ({ riskId }) => ({
        url: "/userbase/risks/v1",
        method: "GET",
        params: {
          risk_id: riskId,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.risks,
          id: queryArg.riskId ?? "all",
        },
      ],
    }),

    /*
        getUserBaseRiskUsersV1
        - gets the list of users affected by a risk
       */
    getUserBaseRiskUsersV1: builder.query<
      GetUserBaseRiskUsersV1Resp,
      { riskId: string }
    >({
      query: ({ riskId }) => ({
        url: "/userbase/risks/users/v1",
        method: "GET",
        params: {
          risk_id: riskId,
        },
      }),
      providesTags: (result) =>
        (result?.users ?? []).map(({ email: id }) => ({
          type: UserBaseTagTypes.riskUsers,
          id,
        })) ?? [],
    }),

    /*
     * getUserBaseUsersV1
     * - gets the list of userbase users for an org
     */
    getUserBaseUsersV1: builder.query<GetUserBaseUsersV1Resp, UsersFilter>({
      query: ({
        userUUIDs,
        excludeUsers,
        appNames,
        excludeApps,
        teams,
        excludeTeams,
        roles,
        excludeRoles,
      }) => ({
        url: "/userbase/users/v1",
        method: "POST",
        body: JSON.stringify({
          user_uuids: userUUIDs,
          exclude_users: excludeUsers,
          app_names: appNames,
          exclude_apps: excludeApps,
          teams: teams,
          exclude_teams: excludeTeams,
          roles: roles,
          exclude_roles: excludeRoles,
        }),
      }),
      providesTags: [UserBaseTagTypes.users],
    }),

    /*
        getUserBaseAppsV1
        - gets the list of apps
       */
    getUserBaseAppsV1: builder.query<GetUserBaseAppsV1Resp, AppsFilter>({
      query: ({
        appNames,
        excludeApps,
        statuses,
        excludeStatuses,
        approvedTeams,
        excludeApprovedTeams,
        approvedRoles,
        excludeApprovedRoles,
        minRiskLevel,
        maxRiskLevel,
        minFirstDetected,
        maxFirstDetected,
      }) => ({
        url: "/userbase/apps/v1",
        method: "POST",
        body: JSON.stringify({
          app_names: appNames,
          exclude_apps: excludeApps,
          statuses: statuses,
          exclude_statuses: excludeStatuses,
          approved_teams: approvedTeams,
          exclude_approved_teams: excludeApprovedTeams,
          approved_roles: approvedRoles,
          exclude_approved_roles: excludeApprovedRoles,
          min_risk_level: minRiskLevel,
          max_risk_level: maxRiskLevel,
          min_first_detected: minFirstDetected,
          max_first_detected: maxFirstDetected,
        }),
      }),
      providesTags: [UserBaseTagTypes.apps],
    }),

    /*
        getUserBaseTeamsV1
        - gets the list of teams for the org
       */
    getUserBaseTeamsV1: builder.query<GetUserBaseTeamsV1Resp, void>({
      query: () => ({
        url: "/userbase/teams/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.teams],
    }),

    /*
        getUserBaseRolesV1
        - gets the list of roles for the org
       */
    getUserBaseRolesV1: builder.query<GetUserBaseRolesV1Resp, void>({
      query: () => ({
        url: "/userbase/roles/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.roles],
    }),

    /*
        updateUserBaseReviewedAppV1
        - update the information and access for a reviewed app
       */
    updateUserBaseReviewedAppV1: builder.mutation<void, UpdateReviewedAppV1Req>(
      {
        query: (req) => ({
          url: "/userbase/apps/v1",
          method: "PUT",
          body: JSON.stringify(req),
        }),
        invalidatesTags: (_1, _2, queryArgs) => {
          return [
            { type: UserBaseTagTypes.risks },
            { type: UserBaseTagTypes.riskUsers },
            { type: UserBaseTagTypes.apps },
            { type: UserBaseTagTypes.users },
            { type: UserBaseTagTypes.summary },
            { type: UserBaseTagTypes.app, id: queryArgs.appName },
            { type: UserBaseTagTypes.appUsers, id: queryArgs.appName },
            { type: UserBaseTagTypes.appTimeline, id: queryArgs.appName },
            { type: UserBaseTagTypes.appRiskBreakdown, id: queryArgs.appName },
            { type: UserBaseTagTypes.appScopes, id: queryArgs.appName },
            { type: UserBaseTagTypes.userRiskProfile },
            { type: UserBaseTagTypes.userSummary },
            { type: UserBaseTagTypes.userApps },
            { type: UserBaseTagTypes.userTimeline },
            { type: UserBaseTagTypes.dashboardStats },
          ];
        },
      }
    ),

    /*
     * getAppSummaryV1
     * - gets the summary for a single application
     */
    getUserBaseAppSummaryV1: builder.query<
      GetAppSummaryV1Resp,
      { app: string }
    >({
      query: ({ app }) => ({
        url: "/userbase/apps/summary/v1",
        method: "GET",
        params: {
          app_name: app,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        { type: UserBaseTagTypes.app, id: queryArgs.app },
      ],
    }),

    /*
     * getAppUsersV1
     * - gets the users for a single application
     */
    getUserBaseAppUsersV1: builder.query<GetAppUsersV1Resp, { app: string }>({
      query: ({ app }) => ({
        url: "/userbase/apps/users/v1",
        method: "GET",
        params: {
          app_name: app,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        { type: UserBaseTagTypes.appUsers, id: queryArgs.app },
      ],
    }),

    /*
     * getUserBaseAppUserScopesV1
     * - gets the list of scopes for an app user
     */
    getUserBaseAppUserScopesV1: builder.query<
      GetAppUserScopesV1Resp,
      { app: string; userUUID: string }
    >({
      query: ({ app, userUUID }) => ({
        url: "/userbase/apps/users/scopes/v1",
        method: "GET",
        params: {
          app_name: app,
          user_uuid: userUUID,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        {
          type: UserBaseTagTypes.appUserScopes,
          id: queryArgs.app + "_" + queryArgs.userUUID,
        },
      ],
    }),

    /*
     * getUserBaseAppTimelineV1
     * - gets the timeline for an app
     */
    getUserBaseAppTimelineV1: builder.query<
      GetAppTimelineV1Resp,
      { app: string }
    >({
      query: ({ app }) => ({
        url: "/userbase/apps/timeline/v1",
        method: "GET",
        params: {
          app_name: app,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        { type: UserBaseTagTypes.appTimeline, id: queryArgs.app },
      ],
    }),

    /*
     * getUserBaseAppTRisksBreakdownV1
     * - gets the risk breakdown for an app
     */
    getUserBaseAppRisksBreakdownV1: builder.query<
      GetAppRisksBreakdownV1Resp,
      { app: string }
    >({
      query: ({ app }) => ({
        url: "/userbase/apps/breakdown/v1",
        method: "GET",
        params: {
          app_name: app,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        { type: UserBaseTagTypes.appRiskBreakdown, id: queryArgs.app },
      ],
    }),

    /*
     * getUserBaseAppScopesV1
     * - gets the scopes for an app
     */
    getUserBaseAppScopesV1: builder.query<GetAppScopesV1Resp, { app: string }>({
      query: ({ app }) => ({
        url: "/userbase/apps/scopes/v1",
        method: "GET",
        params: {
          app_name: app,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        { type: UserBaseTagTypes.appScopes, id: queryArgs.app },
      ],
    }),

    /*
     getUserBaseSummaryV1
      - gets the summary data
      */
    getUserBaseSummaryV1: builder.query<GetSummaryV1Resp, void>({
      query: () => ({
        url: "/userbase/summary/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.summary],
    }),

    /*
     getUserBaseAppScopeUsersV1
      - gets the users for an app scope
      */
    getUserBaseAppScopeUsersV1: builder.query<
      GetAppsScopeUsersV1Resp,
      { appName: string; scopeId: string }
    >({
      query: ({ appName, scopeId }) => ({
        url: "/userbase/apps/scopes/users/v1",
        method: "GET",
        params: {
          app_name: appName,
          scope_id: scopeId,
        },
      }),
      providesTags: (_1, _2, queryArgs) => [
        {
          type: UserBaseTagTypes.appScopeUsers,
          id: queryArgs.appName + "_" + queryArgs.scopeId,
        },
      ],
    }),

    /*
     getUserBaseRiskWaiversV1
     - gets the list of risk waivers for the org
   */
    getUserBaseRiskWaiversV1: builder.query<GetRiskWaiversV1Resp, void>({
      query: () => ({
        url: "/userbase/risk_waivers/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.riskWaivers],
    }),

    /*
      createUserBaseRiskWaiverV1
       - creates a new userbase risk waiver for the org
       */
    createUserBaseRiskWaiverV1: builder.mutation<
      void,
      UserBaseRiskAcceptanceCreateV1ReqBody
    >({
      query: (req) => ({
        url: "/userbase/risk_waivers/v1",
        method: "POST",
        body: JSON.stringify(req),
      }),
      invalidatesTags: [
        UserBaseTagTypes.risks,
        UserBaseTagTypes.riskUsers,
        UserBaseTagTypes.summary,
        UserBaseTagTypes.riskWaivers,
        UserBaseTagTypes.users,
        UserBaseTagTypes.userSummary,
        UserBaseTagTypes.userRiskProfile,
        UserBaseTagTypes.userApps,
        UserBaseTagTypes.apps,
        UserBaseTagTypes.app,
        UserBaseTagTypes.appUsers,
        UserBaseTagTypes.appRiskBreakdown,
        UserBaseTagTypes.appScopeUsers,
        UserBaseTagTypes.dashboardStats,
      ],
    }),

    /*
      deleteUserBaseRiskWaiverV1
       - delete a waiver for the org
      */
    deleteUserBaseRiskWaiverV1: builder.mutation<void, number>({
      query: (id) => ({
        url: "/userbase/risk_waivers/v1",
        method: "DELETE",
        params: {
          id,
        },
      }),
      invalidatesTags: [
        UserBaseTagTypes.risks,
        UserBaseTagTypes.riskUsers,
        UserBaseTagTypes.summary,
        UserBaseTagTypes.riskWaivers,
        UserBaseTagTypes.users,
        UserBaseTagTypes.userSummary,
        UserBaseTagTypes.userRiskProfile,
        UserBaseTagTypes.userApps,
        UserBaseTagTypes.apps,
        UserBaseTagTypes.app,
        UserBaseTagTypes.appUsers,
        UserBaseTagTypes.appRiskBreakdown,
        UserBaseTagTypes.appScopeUsers,
        UserBaseTagTypes.dashboardStats,
      ],
    }),

    /*
     getUserBaseUserSummaryV1
     - gets the summary for a user
   */
    getUserBaseUserSummaryV1: builder.query<
      GetUserSummaryV1Resp,
      { userUUID: string }
    >({
      query: ({ userUUID }) => ({
        url: "/userbase/users/summary/v1",
        method: "GET",
        params: {
          user_uuid: userUUID,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.userSummary,
          id: queryArg.userUUID,
        },
      ],
    }),

    /*
     getUserBaseUserRiskProfileV1
     - gets the risk profile for a user, i.e. risks and apps
   */
    getUserBaseUserRiskProfileV1: builder.query<
      GetUserRiskProfileV1Resp,
      { userUUID: string }
    >({
      query: ({ userUUID }) => ({
        url: "/userbase/users/risk_profile/v1",
        method: "GET",
        params: {
          user_uuid: userUUID,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.userRiskProfile,
          id: queryArg.userUUID,
        },
      ],
    }),

    /*
     getUserBaseUserAppsV1
     - gets the apps for a user
   */
    getUserBaseUserAppsV1: builder.query<
      GetUserAppsV1Resp,
      { userUUID: string; appNames?: string[] }
    >({
      query: ({ userUUID, appNames }) => ({
        url: "/userbase/users/apps/v1",
        method: "GET",
        params: {
          user_uuid: userUUID,
          // Since appNames is optional, we need to provide a default value if required
          app_names: (appNames ?? []).join(","),
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.userApps,
          id: queryArg.userUUID,
        },
      ],
    }),

    /*
     getUserBaseIdentityBreachesV1
     - gets the list of identity breaches
   */
    getUserBaseIdentityBreachesV1: builder.query<
      GetIdentityBreachesV1Resp,
      void
    >({
      query: () => ({
        url: "userbase/identity_breaches/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.identityBreaches],
    }),

    /*
     getUserBaseIdentityBreachSummaryV1
     - gets the summary for an identity breach
   */
    getUserBaseIdentityBreachSummaryV1: builder.query<
      GetIdentityBreachSummaryV1Resp,
      { breachID: number }
    >({
      query: ({ breachID }) => ({
        url: "userbase/identity_breaches/summary/v1",
        method: "GET",
        params: {
          breach_id: breachID,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.identityBreachSummary,
          id: queryArg.breachID,
        },
      ],
    }),

    /*
       getUserBaseUserScopesV1
       - gets the scopes for a user
     */
    getUserBaseUserScopesV1: builder.query<
      GetUserScopeAppsV1Resp,
      { userUUID: string }
    >({
      query: ({ userUUID }) => ({
        url: "/userbase/users/scopes/v1",
        method: "GET",
        params: {
          user_uuid: userUUID,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.userScopes,
          id: queryArg.userUUID,
        },
      ],
    }),

    /*
       getUserBaseUserTimelineV1
       - gets the event timeline for a user
     */
    getUserBaseUserTimelineV1: builder.query<
      GetUserTimelineV1Resp,
      { userUUID: string }
    >({
      query: ({ userUUID }) => ({
        url: "/userbase/users/timeline/v1",
        method: "GET",
        params: {
          user_uuid: userUUID,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.userTimeline,
          id: queryArg.userUUID,
        },
      ],
    }),

    /*
     getUserBaseDashboardStatsV1
     - gets the dashboard stats 
   */
    getUserBaseDashboardStatsV1: builder.query<GetDashboardStatsV1Resp, void>({
      query: () => ({
        url: "userbase/dashboard/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.dashboardStats],
    }),

    /*
     getUserBaseScopesV1
     - gets the list of scopes granted for an org
   */
    getUserBaseScopesV1: builder.query<GetScopesV1Resp, void>({
      query: () => ({
        url: "userbase/scopes/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.orgScopes],
    }),

    /*
     getUserBaseScopeCategoriesV1
     - gets the list of scope categroies for an org
   */
    getUserBaseScopeCategoriesV1: builder.query<GetScopeCategoriesV1Resp, void>(
      {
        query: () => ({
          url: "userbase/scopes/categories/v1",
          method: "GET",
        }),
        providesTags: [UserBaseTagTypes.orgScopeCategories],
      }
    ),

    /*
     getUserBaseScopesV1
     - gets the list of apps that a user has granted a specific scope for 
   */
    getUserBaseAppsForUserScopeV1: builder.query<
      GetAppsForUserScopeV1Resp,
      { userUUID: string; scopeExternalId: string }
    >({
      query: ({ userUUID, scopeExternalId }) => ({
        url: "userbase/scopes/users/apps/v1",
        method: "GET",
        params: {
          user_uuid: userUUID,
          scope_external_id: scopeExternalId,
        },
      }),
      providesTags: (_1, _2, queryArg) => [
        {
          type: UserBaseTagTypes.appsUserScope,
          id: queryArg.userUUID + "_" + queryArg.scopeExternalId,
        },
      ],
    }),

    /*
     getUserBaseScopesV1
     - gets the list of apps that a user has granted a specific scope for 
   */
    getUserDirectoryStatsV1: builder.query<GetUserDirectoryStatsV1Resp, void>({
      query: () => ({
        url: "userbase/user_directory_stats/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.userDirectoryStats],
    }),

    /*
     getUserBaseRemediationsV1
     - gets the list of UserBase remediations
   */
    getUserBaseRemediationsV1: builder.query<
      GetUserBaseRemediationsV1Resp,
      void
    >({
      query: () => ({
        url: "remediationrequest/list/v1",
        method: "GET",
        params: {
          userrisk: true,
        },
      }),
      providesTags: [UserBaseTagTypes.remediations],
    }),

    // start a new scan for the org in the background
    startUserRiskScanV1: builder.mutation<void, void>({
      query: () => ({
        url: "/userbase/scan/v1",
        method: "POST",
      }),
      invalidatesTags: [UserBaseTagTypes.scan],
    }),

    // get the scan of a UserRisk scan
    getUserRiskScanStatusV1: builder.query<GetUserRiskScanStatusV1Resp, void>({
      query: () => ({
        url: "/userbase/scan/v1",
        method: "GET",
      }),
      providesTags: [UserBaseTagTypes.scan],
    }),

    /*
     * getUserBaseUnmonitoredUsersV1
     * - gets the list of unmonitored userbase users for an org
     */
    getUserBaseUnmonitoredUsersV1: builder.query<
      GetUserBaseUnmonitoredUsersV1Resp,
      void
    >({
      query: () => ({
        url: "/userbase/users/unmonitored/v1",
        method: "GET",
      }),
      providesTags: (_1, _2, _3) => [
        {
          type: UserBaseTagTypes.unmonitoredUsers,
        },
      ],
    }),

    /*
     *  monitorUsersV1
     *  - monitor a bunch of users
     */
    monitorUsersV1: builder.mutation<void, MonitorUsersV1Req>({
      query: (req) => ({
        url: "/userbase/users/monitor/v1",
        method: "PUT",
        body: JSON.stringify(req),
      }),
      invalidatesTags: (_1, _2, _3) => {
        return [
          { type: UserBaseTagTypes.risks },
          { type: UserBaseTagTypes.riskUsers },
          { type: UserBaseTagTypes.apps },
          { type: UserBaseTagTypes.users },
          { type: UserBaseTagTypes.summary },
          { type: UserBaseTagTypes.app },
          { type: UserBaseTagTypes.appUsers },
          { type: UserBaseTagTypes.appTimeline },
          { type: UserBaseTagTypes.appRiskBreakdown },
          { type: UserBaseTagTypes.appScopes },
          { type: UserBaseTagTypes.userRiskProfile },
          { type: UserBaseTagTypes.userSummary },
          { type: UserBaseTagTypes.userApps },
          { type: UserBaseTagTypes.userTimeline },
          { type: UserBaseTagTypes.dashboardStats },
          { type: UserBaseTagTypes.unmonitoredUsers },
        ];
      },
    }),

    /*
     *  unmonitorUsersV1
     *  - unmonitor a bunch of users
     */
    unmonitorUsersV1: builder.mutation<void, UnmonitorUsersV1Req>({
      query: (req) => ({
        url: "/userbase/users/unmonitor/v1",
        method: "PUT",
        body: JSON.stringify(req),
      }),
      invalidatesTags: (_1, _2, _3) => {
        return [
          { type: UserBaseTagTypes.risks },
          { type: UserBaseTagTypes.riskUsers },
          { type: UserBaseTagTypes.apps },
          { type: UserBaseTagTypes.users },
          { type: UserBaseTagTypes.summary },
          { type: UserBaseTagTypes.app },
          { type: UserBaseTagTypes.appUsers },
          { type: UserBaseTagTypes.appTimeline },
          { type: UserBaseTagTypes.appRiskBreakdown },
          { type: UserBaseTagTypes.appScopes },
          { type: UserBaseTagTypes.userRiskProfile },
          { type: UserBaseTagTypes.userSummary },
          { type: UserBaseTagTypes.userApps },
          { type: UserBaseTagTypes.userTimeline },
          { type: UserBaseTagTypes.dashboardStats },
          { type: UserBaseTagTypes.unmonitoredUsers },
        ];
      },
    }),
  }),
});

export default UserBaseAPI;

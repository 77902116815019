import { DefaultThunkDispatch } from "../../../_common/types/redux";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import ModalForm from "../modals/ModalForm";
import { useState } from "react";
import ColorCheckbox from "../ColorCheckbox";
import Button from "../../../_common/components/core/Button";
import "../../style/components/AddQuestionnaireToSharedProfileModal.scss";
import {
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../../_common/types/survey";
import moment from "moment/moment";
import InfoBanner, { BannerType } from "../InfoBanner";
import {
  addExistingSurveyToSharedAssessment,
  addNewSurveyToSharedAssessment,
} from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { fetchSurveyDetails } from "../../../_common/reducers/surveyDetails.actions";
import { surveyType } from "../../../_common/types/surveyTypes";
import TextField from "../../../_common/components/TextField";
import { openPreviewSurveyTab } from "../../../survey_builder/components/SurveyBuilder";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../../verifiedvendors/api/trustpage.api";

interface IAddQuestionnaireToSharedProfileModalProps extends BaseModalProps {
  dispatch: DefaultThunkDispatch;
  receivedSurveys: ISurveyListItemResponse[];
  sharedProfileSurveys: ISurveyListItemResponse[];
  surveyTypes: surveyType[];
}

const AddQuestionnaireToSharedProfileModal = ({
  dispatch,
  active,
  onClose,
  receivedSurveys: originalReceivedSurveys,
  sharedProfileSurveys,
  surveyTypes,
}: IAddQuestionnaireToSharedProfileModalProps) => {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<"existing" | "new" | undefined>();
  const [selectedExistingSurveyId, setSelectedExistingSurveyId] = useState<
    number | undefined
  >();
  const [selectedNewSurveyType, setSelectedNewSurveyType] = useState<
    | {
        surveyTypeId: number;
        selectedSections: number[];
      }
    | undefined
  >();
  const [newSurveyName, setNewSurveyName] = useState("");
  const [newSurveyDescription, setNewSurveyDescription] = useState("");

  const resetState = () => {
    setLoading(false);
    setMode(undefined);
    setSelectedExistingSurveyId(undefined);
    setSelectedNewSurveyType(undefined);
    setNewSurveyName("");
    setNewSurveyDescription("");
  };

  // Filter out archived and cancelled surveys
  const receivedSurveys = originalReceivedSurveys
    .filter((s) => !s.archived && s.status !== SurveyStatus.Cancelled)
    .map((s) => {
      // try to find the survey type from the typeid since the type coming from the survey data
      // might have a custom name assigned by the org to the system survey type
      const surveyType = s.typeId
        ? surveyTypes.find((st) => st.id === s.typeId)?.name
        : undefined;
      return {
        ...s,
        type: surveyType || s.type,
      };
    });

  const selectedSurvey = selectedExistingSurveyId
    ? receivedSurveys.find((s) => s.id === selectedExistingSurveyId)
    : undefined;

  const selectedQuestionnaireIsInProgress =
    !!selectedExistingSurveyId && !selectedSurvey?.dateSubmitted;

  const canSubmit =
    (mode === "existing" && !!selectedExistingSurveyId) ||
    (mode === "new" &&
      selectedNewSurveyType &&
      selectedNewSurveyType.selectedSections.length > 0 &&
      !!newSurveyName);

  const onSubmit = () => {
    if (mode === "existing" && selectedExistingSurveyId) {
      setLoading(true);
      dispatch(addExistingSurveyToSharedAssessment(selectedExistingSurveyId))
        .then(() =>
          Promise.all([
            dispatch(fetchSurveyDetails(selectedExistingSurveyId, true)),
            dispatch(
              TrustPageAPI.util.invalidateTags([
                TrustPageTagTypes.ownTrustPage,
                TrustPageTagTypes.ownTrustPageAccess,
              ])
            ),
          ])
        )
        .then(() => {
          dispatch(addDefaultSuccessAlert("Successfully added questionnaire"));
          resetState();
          onClose();
        })
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("Error adding questionnaire"));
        })
        .finally(() => setLoading(false));
    } else if (mode === "new" && selectedNewSurveyType) {
      // make sure we maintain section order by using the original list for sections
      const surveyType = surveyTypes.find(
        (s) => s.id === selectedNewSurveyType.surveyTypeId
      );
      if (!surveyType) {
        return;
      }

      const sectionIds = surveyType.sections
        .filter((section) =>
          selectedNewSurveyType.selectedSections.includes(section.id)
        )
        .map((section) => section.id);
      setLoading(true);
      dispatch(
        addNewSurveyToSharedAssessment(
          selectedNewSurveyType.surveyTypeId,
          sectionIds,
          newSurveyName,
          newSurveyDescription
        )
      )
        .then(() =>
          dispatch(
            TrustPageAPI.util.invalidateTags([
              TrustPageTagTypes.ownTrustPage,
              TrustPageTagTypes.ownTrustPageAccess,
            ])
          )
        )
        .then(() => {
          dispatch(addDefaultSuccessAlert("Successfully added questionnaire"));
          resetState();
          onClose();
        })
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("Error adding questionnaire"));
        })
        .finally(() => setLoading(false));
    }
  };

  const sharedProfileQuestionnaireOriginalIDs: Record<
    number,
    true | undefined
  > = {};
  for (let i = 0; i < sharedProfileSurveys.length; i++) {
    if (sharedProfileSurveys[i].originalID > 0) {
      sharedProfileQuestionnaireOriginalIDs[
        sharedProfileSurveys[i].originalID
      ] = true;
    }
  }

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="add-qn-shared-profile-modal"
      headerContent="Add a questionnaire to Trust Page"
      footerContent={
        <div className="btn-group">
          <Button tertiary disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} disabled={!canSubmit} onClick={onSubmit}>
            Add to Trust Page
          </Button>
        </div>
      }
    >
      <div>
        <p>Select a filled out questionnaire or start a new questionnaire.</p>
        <ModalForm>
          <div className="form-section">
            <div className="form-section-desc">
              <span>What do you want to do?</span>
            </div>
            <div className="form-section-input">
              {receivedSurveys.length > 0 && (
                <ColorCheckbox
                  radio
                  checked={mode === "existing"}
                  onClick={() => {
                    if (mode === "existing") {
                      return;
                    }
                    setMode("existing");
                    setSelectedNewSurveyType(undefined);
                    setNewSurveyName("");
                    setNewSurveyDescription("");
                  }}
                  label="Use an existing questionnaire"
                />
              )}
              <ColorCheckbox
                radio
                checked={mode === "new"}
                onClick={() => {
                  if (mode === "new") {
                    return;
                  }
                  setMode("new");
                  setSelectedExistingSurveyId(undefined);
                }}
                label="Start a new, blank questionnaire"
              />
            </div>
          </div>
          {mode === "existing" && (
            <div className="form-section">
              <div className="form-section-desc">
                <span>Select questionnaire</span>
                <p>
                  Select from the list of questionnaires that you have been sent
                </p>
              </div>
              <div className="form-section-input">
                {receivedSurveys.map((s) => {
                  const alreadyAdded =
                    s.addToSharedProfile ||
                    !!sharedProfileQuestionnaireOriginalIDs[s.id];

                  return (
                    <div key={s.id} className="q-select-item">
                      <div className="checkbox-and-link">
                        <ColorCheckbox
                          radio
                          checked={s.id === selectedExistingSurveyId}
                          onClick={() => setSelectedExistingSurveyId(s.id)}
                          label={s.type ? s.type : s.name}
                          disabled={alreadyAdded}
                        />
                        <Button
                          tertiary
                          onClick={() =>
                            window.open(`/vendors/surveys/${s.id}`)
                          }
                        >
                          View
                        </Button>
                      </div>
                      <div className="desc-text">
                        {alreadyAdded ? (
                          <em className="disabled-text">Already added</em>
                        ) : (
                          <>
                            From {s.fromCompany}
                            <br />
                            {s.dateSubmitted
                              ? s.isImportedSurvey
                                ? `Completed ${moment(s.dateSubmitted).format(
                                    "ll"
                                  )}`
                                : `Submitted ${moment(s.dateSubmitted).format(
                                    "ll"
                                  )}`
                              : s.isImportedSurvey
                                ? `Imported ${moment(s.dateCreated).format(
                                    "ll"
                                  )}`
                                : `Received ${moment(s.dateCreated).format(
                                    "ll"
                                  )}`}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {mode === "new" && (
            <>
              <div className="form-section">
                <div className="form-section-desc">
                  <span>Type of questionnaire</span>
                  <p>What type of questionnaire would you like to create?</p>
                </div>
                <div className="form-section-input">
                  {surveyTypes
                    .filter((s) => s.enabled && s.hasStructure)
                    .map((s) => (
                      <div className="type-select-item" key={s.id}>
                        <div className="checkbox-and-link">
                          <ColorCheckbox
                            radio
                            checked={
                              s.id === selectedNewSurveyType?.surveyTypeId
                            }
                            onClick={() =>
                              setSelectedNewSurveyType({
                                surveyTypeId: s.id,
                                // Select all sections by default
                                selectedSections: s.sections.map(
                                  (section) => section.id
                                ),
                              })
                            }
                            label={s.name}
                          />
                          <Button
                            tertiary
                            onClick={() => {
                              openPreviewSurveyTab(s.id, false);
                            }}
                          >
                            Preview
                          </Button>
                        </div>
                        {selectedNewSurveyType &&
                          selectedNewSurveyType.surveyTypeId === s.id &&
                          s.sections.length > 1 && (
                            <div className="sections-select">
                              {s.sections.map((section) => (
                                <ColorCheckbox
                                  key={section.id}
                                  checked={selectedNewSurveyType.selectedSections.includes(
                                    section.id
                                  )}
                                  onClick={() =>
                                    setSelectedNewSurveyType(
                                      (newSurveyType) => {
                                        if (!newSurveyType) {
                                          return newSurveyType;
                                        }
                                        const newSections = [
                                          ...newSurveyType.selectedSections,
                                        ];
                                        const sectionIdx = newSections.indexOf(
                                          section.id
                                        );
                                        if (sectionIdx > -1) {
                                          newSections.splice(sectionIdx, 1);
                                        } else {
                                          newSections.push(section.id);
                                        }
                                        return {
                                          ...newSurveyType,
                                          selectedSections: newSections,
                                        };
                                      }
                                    )
                                  }
                                  label={section.displayName}
                                />
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              </div>
              <div className="form-section">
                <div className="form-section-desc">
                  <span>Name and description</span>
                  <p>Provide a name and description for the questionnaire.</p>
                </div>
                <div className="form-section-input">
                  <TextField
                    value={newSurveyName}
                    onChanged={(val) => setNewSurveyName(val)}
                    placeholder="Enter a name"
                  />
                  <TextField
                    value={newSurveyDescription}
                    onChanged={(val) => setNewSurveyDescription(val)}
                    multiLine
                    placeholder="Enter a description (optional)"
                  />
                </div>
              </div>
            </>
          )}
        </ModalForm>
        {selectedQuestionnaireIsInProgress && (
          <InfoBanner
            type={BannerType.INFO}
            message="The selected questionnaire is still in progress."
            subItems={[
              "If you proceed, it will be automatically added to your Trust Page when it is completed.",
            ]}
          />
        )}
      </div>
    </ModalV2>
  );
};

export default AddQuestionnaireToSharedProfileModal;

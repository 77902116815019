import { Component, ReactNode } from "react";

import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { History, Location } from "history";
import { locationState } from "../../_common/types/router";
// @ts-ignore
import { stringify as queryStringify, ParsedUrlQueryInput } from "querystring";
import SlidePanel from "../../_common/components/SlidePanel";
import IPAddressPanel from "./IPAddressPanel";
import CloudscanPanel from "./CloudscanPanel";
import "../style/components/DomainsAndIPsPanelGroup.scss";
import BackArrow from "../../_common/components/BackArrow";
import IPRangePanel from "./IPRangePanel";
import VulnPanel from "./VulnPanel";
import {
  IWithPermissionsProps,
  withPermissions,
} from "../../_common/permissions";
import { IPRange } from "../../_common/types/ipAddresses";
import ProductPanel from "./detected_products/ProductPanel";
import { appConnect } from "../../_common/types/reduxHooks";
import ControlPanel from "./securityprofile/ControlPanel";
import ControlCheckPanel from "./securityprofile/ControlCheckPanel";
import SaaSUserRiskPanel from "../../userbase/components/side_panels/SaaSUserRiskPanel";
import SaaSAppRiskPanel from "../../userbase/components/side_panels/SaaSAppRiskPanel";
import { GetQueryParams } from "../../_common/query";
import RiskAssetsPanel from "./RiskAssetsPanel";

// SidePanelParams is the union of possible query string
// parameters for opening various side panels.
type SidePanelParams =
  | CloudscanPanelParams
  | IPPanelParams
  | IPRangePanelParams
  | VulnPanelParams
  | ProductPanelParams
  | ControlPanelParams
  | ControlCheckPanelParams
  | RiskPanelParams
  | SaaSUserRiskPanelParams
  | SaaSAppRiskPanelParams
  | RiskAssetsPanelParams;

export interface CloudscanPanelParams extends VendorParams {
  // scan is the domain/IP to load
  scan: string;
  risk?: string;
  cve?: string;
  disableLabelAdd?: boolean;
}

interface IPPanelParams extends VendorParams {
  ip: string;
  risk?: string;
}

interface IPRangePanelParams extends VendorParams {
  range?: number;
  customRange?: number;
  risk?: string;
}

export interface VulnPanelParams extends VendorParams {
  vuln: string;
  verified: boolean;
}

export interface ProductPanelParams {
  productUUID?: string;
}

export interface ControlPanelParams {
  vendorId: number;
  controlId: string;
}

export interface ControlCheckPanelParams {
  vendorId: number;
  checkId: string;
  scanRiskId?: string;
  surveyRiskId?: string;
}

export interface RiskPanelParams {
  risk: string;
}

export interface SaaSUserRiskPanelParams {
  saasUser: string;
}

export interface SaaSAppRiskPanelParams {
  saasAppName: string;
}

export interface RiskAssetsPanelParams {
  risk: string;
  showWaived: string | boolean;
  vendorId?: number;
}

// VendorParams are passed in the query string when it's necessary to override the props passed
// to DomainsAndIPsPanelGroup e.g. when multiple vendors/subs domains are accessible from a single view.
// This is required in the portfolio risk profile for vendors and subsidiaries.
interface VendorParams {
  vendorId?: number;

  // subsidiaryId is just a regular vendor ID renamed
  // to avoid requiring an extra isSubsidiary query param
  subsidiaryId?: number;

  // If true, force hide any request remediation buttons
  readOnly?: boolean;
}

export const OpenSidePanel = (
  history: History,
  params: SidePanelParams,
  currentPanelName?: string,
  location?: Location<locationState>
): void => {
  const sidePanelBackStackCount =
    (location?.state?.sidePanelBackStackCount ?? 0) + 1;
  history.push("?" + queryStringify({ ...params }), {
    backContext: location?.state?.backContext,
    keepScrollPosition: true,
    sidePanelBackText: currentPanelName
      ? `Back to ${currentPanelName}`
      : undefined,
    sidePanelBackStackCount,
  });
};

export const CloseSidePanel = (
  history: History,
  location?: Location<locationState>
): void => {
  // if we have a back stack count then we want to go back that many back stacks
  if (
    location?.state?.sidePanelBackStackCount &&
    location.state.sidePanelBackStackCount > 0
  ) {
    history.go(-location.state.sidePanelBackStackCount);
  } else {
    history.push("?", {
      keepScrollPosition: true,
      backContext: location?.state?.backContext,
    });
  }
};

export type GetVulnPanel = (
  vendorID: number | undefined,
  isSubsidiary: boolean,
  isManagedVendorAnalyst: boolean | undefined,
  managedOrgId: number | undefined,
  cveName: string,
  verified: boolean
) => JSX.Element;

interface IDomainsAndIPsPanelGroupOwnProps {
  history: History;
  // the location query string will determine which panel is shown
  location: Location<locationState>;
  allCloudscans?: unknown;
  vendorId?: number;
  isSubsidiary: boolean;
  isVendorPortal: boolean;
  onRequestRemediationForDomain?: (domain: string, vendorId: number) => void;
  onRequestRemediationForVuln?: (cve: string, vendorId: number) => void;
  onDeleteCustomRange?: (range: IPRange) => void;
  onEditCustomRange?: (range: IPRange) => void;
  remediationRequestId?: number;
  riskPanel?: JSX.Element;
  getVulnPanel?: GetVulnPanel;
  isManagedVendorAnalyst?: boolean;
  managedOrgId?: number;
}

interface IDomainsAndIPsPanelGroupConnectedProps {
  allCloudscans?: unknown;
}

type IDomainsAndIPsPanelGroupProps = IDomainsAndIPsPanelGroupOwnProps &
  IDomainsAndIPsPanelGroupConnectedProps &
  IWithPermissionsProps &
  DefaultThunkDispatchProp;

class DomainsAndIPsPanelGroup extends Component<IDomainsAndIPsPanelGroupProps> {
  static defaultProps = {
    isSubsidiary: false,
    isVendorPortal: false,
  };

  getActivePanel = (): ReactNode | undefined => {
    const params = GetQueryParams(this.props.location.search);

    // ensure numbers are decoded properly
    if (params.vendorId) {
      params.vendorId = parseInt(params.vendorId, 10);
    }
    if (params.subsidiaryId) {
      params.subsidiaryId = parseInt(params.subsidiaryId, 10);
    }
    if (params.range) {
      params.range = parseInt(params.range, 10);
    }
    if (params.customRange) {
      params.customRange = parseInt(params.customRange, 10);
    }

    // reconstruct VendorParams so it can be easily wired through to other panels
    const vendorParams: VendorParams = {};
    if (params.vendorId) {
      vendorParams.vendorId = params.vendorId;
    }
    if (params.subsidiaryId) {
      vendorParams.subsidiaryId = params.subsidiaryId;
    }
    if (params.readOnly) {
      vendorParams.readOnly = params.readOnly == "true";
    }

    if (params.scan) {
      return this.getCloudscanPanel(
        params as CloudscanPanelParams,
        vendorParams
      );
    } else if (params.ip) {
      return this.getIPPanel(params as IPPanelParams, vendorParams);
    } else if (params.range || params.customRange) {
      return this.getIPRangePanel(params, vendorParams);
    } else if (params.vuln) {
      return this.getVulnPanel(params as VulnPanelParams, vendorParams);
    } else if (params.productUUID) {
      return this.getProductPanel(params);
    } else if (params.checkId) {
      return this.getControlCheckPanel(params as ControlCheckPanelParams);
    } else if (params.controlId) {
      return this.getControlPanel(params as ControlPanelParams);
    } else if (params.risk && params.saasUser) {
      return this.getSaaSUserPanel(params as SaaSUserRiskPanelParams);
    } else if (params.saasAppName) {
      return this.getSaaSAppPanel(params as SaaSAppRiskPanelParams);
    } else if (params.risk && !!params.showWaived) {
      return this.getRiskAssetsPanel(params as RiskAssetsPanelParams);
    } else if (params.risk) {
      return this.getRiskPanel();
    }

    return undefined;
  };

  vendorId = (params: VendorParams): number | undefined =>
    params.subsidiaryId || params.vendorId || this.props.vendorId;

  isSubsidiary = (params: VendorParams): boolean =>
    !!params.subsidiaryId || this.props.isSubsidiary;

  getCloudscanPanel = (
    params: CloudscanPanelParams,
    vendorParams: VendorParams
  ): ReactNode => (
    <div className="cloudscan-panel">
      <CloudscanPanel
        history={this.props.history}
        webscans={this.props.allCloudscans}
        isVendorPortal={this.props.isVendorPortal}
        isCustomer={
          !this.props.isVendorPortal &&
          !this.vendorId(vendorParams) &&
          !this.isSubsidiary(vendorParams)
        }
        isSubsidiary={this.isSubsidiary(vendorParams)}
        vendorId={this.vendorId(vendorParams)}
        onRequestRemediation={
          vendorParams.readOnly
            ? undefined
            : this.props.onRequestRemediationForDomain
        }
        hostname={params.scan}
        cloudscanPanelOpenInitialId={params.risk}
        cloudscanPanelOpenCVEName={params.cve}
        onClickIP={(ip: string) =>
          OpenSidePanel(
            this.props.history,
            { ...vendorParams, ip, risk: params.risk },
            params.scan,
            this.props.location
          )
        }
        onClickIPRange={(ipRangeID: number, customRangeID?: number) =>
          OpenSidePanel(
            this.props.history,
            {
              ...vendorParams,
              ...(customRangeID
                ? { customRange: customRangeID }
                : { range: ipRangeID }),
              risk: params.risk,
            },
            params.scan,
            this.props.location
          )
        }
        onClickCVE={(vuln: string, verified: boolean) =>
          OpenSidePanel(
            this.props.history,
            { vuln, verified, risk: params.risk, ...vendorParams },
            params.scan,
            this.props.location
          )
        }
        disableLabelAdd={params.disableLabelAdd}
        remediationRequestId={this.props.remediationRequestId}
        orgSupportsDomainsPortfolios={this.props.orgHasDomainPortfoliosEnabled}
        editableDomainPortfolioIds={this.props.editableDomainPortfolioIds}
      />
    </div>
  );

  getIPPanel = (
    params: IPPanelParams,
    vendorParams: VendorParams
  ): ReactNode => (
    <IPAddressPanel
      ip={params.ip}
      vendorId={this.vendorId(vendorParams)}
      isSubsidiary={this.isSubsidiary(vendorParams)}
      onClickDomain={(d) =>
        OpenSidePanel(
          this.props.history,
          { ...vendorParams, scan: d, risk: params.risk },
          params.ip,
          this.props.location
        )
      }
      onClickIPRange={(ipRangeID, customRangeID) =>
        OpenSidePanel(
          this.props.history,
          {
            ...vendorParams,
            ...(customRangeID
              ? { customRange: customRangeID }
              : { range: ipRangeID }),
            risk: params.risk,
          },
          params.ip,
          this.props.location
        )
      }
    />
  );

  getIPRangePanel = (
    params: IPRangePanelParams,
    vendorParams: VendorParams
  ): ReactNode => (
    <IPRangePanel
      rangeId={params.range}
      customRangeId={params.customRange}
      vendorId={this.vendorId(vendorParams)}
      isSubsidiary={this.isSubsidiary(vendorParams)}
      onClickDomain={(d) =>
        OpenSidePanel(
          this.props.history,
          { ...vendorParams, scan: d, risk: params.risk },
          "IP range",
          this.props.location
        )
      }
      onClickScoredIP={(ip) =>
        OpenSidePanel(
          this.props.history,
          { ...vendorParams, scan: ip, risk: params.risk },
          "IP range",
          this.props.location
        )
      }
      onClickUnscoredIP={(ip) =>
        OpenSidePanel(
          this.props.history,
          { ...vendorParams, ip, risk: params.risk },
          "IP range",
          this.props.location
        )
      }
      onDeleteCustomRange={this.props.onDeleteCustomRange}
      onEditCustomRange={this.props.onEditCustomRange}
    />
  );

  getVulnPanel = (
    params: VulnPanelParams,
    vendorParams: VendorParams
  ): ReactNode => {
    const vendorID = this.vendorId(vendorParams);
    const isSubsidiary = this.isSubsidiary(vendorParams);
    const cveName = params.vuln;
    const verified = params.verified;

    let vulnPanel: JSX.Element;

    if (this.props.getVulnPanel) {
      vulnPanel = this.props.getVulnPanel(
        vendorID,
        isSubsidiary,
        this.props.isManagedVendorAnalyst,
        this.props.managedOrgId,
        cveName,
        verified
      );
    } else {
      vulnPanel = (
        <VulnPanel
          vendorId={vendorID}
          isSubsidiary={isSubsidiary}
          onRequestRemediationForVuln={
            vendorParams.readOnly
              ? undefined
              : this.props.onRequestRemediationForVuln
          }
          cveName={cveName}
          verified={verified}
        />
      );
    }

    return (
      <div className={"vulns-slide-panel vulns-slide-panel-new-risk-designs"}>
        {vulnPanel}
      </div>
    );
  };

  getSaaSUserPanel = (params: SaaSUserRiskPanelParams): ReactNode => {
    return <SaaSUserRiskPanel userUUID={params.saasUser} />;
  };

  getSaaSAppPanel = (params: SaaSAppRiskPanelParams): ReactNode => {
    return <SaaSAppRiskPanel appName={params.saasAppName} />;
  };

  getProductPanel = (params: ProductPanelParams): ReactNode => (
    <div className="product-slide-panel">
      <ProductPanel
        productUUID={params.productUUID}
        history={this.props.history}
        userCanAccessVendorRisk={this.props.canAccessVendorRisk}
      />
    </div>
  );

  getControlPanel = (params: ControlPanelParams): ReactNode => {
    return (
      <div className="product-slide-panel">
        <ControlPanel vendorId={params.vendorId} controlId={params.controlId} />
      </div>
    );
  };

  getControlCheckPanel = (params: ControlCheckPanelParams): ReactNode => {
    return (
      <div className="product-slide-panel">
        <ControlCheckPanel
          vendorId={params.vendorId}
          checkId={params.checkId}
          scanRiskId={params.scanRiskId}
          surveyRiskId={params.surveyRiskId}
        />
      </div>
    );
  };

  getRiskAssetsPanel = (params: RiskAssetsPanelParams): ReactNode => {
    return (
      <div className="product-slide-panel">
        <RiskAssetsPanel
          riskID={params.risk}
          vendorID={params.vendorId}
          showWaived={
            params.showWaived === "true" || params.showWaived === true
          }
        />
      </div>
    );
  };

  getRiskPanel = (): ReactNode => this.props.riskPanel;

  render() {
    const activePanel: ReactNode = this.getActivePanel();

    return (
      <SlidePanel
        active={!!activePanel}
        className="domains-ips-panel-group"
        newStyles
        dimContent
        title={
          this.props.location.state?.sidePanelBackText && (
            <BackArrow
              popup={this.props.location.state.sidePanelBackText}
              onClick={this.props.history.goBack}
            />
          )
        }
        onClose={() => CloseSidePanel(this.props.history, this.props.location)}
      >
        {activePanel}
      </SlidePanel>
    );
  }
}

export default withPermissions(
  appConnect<
    IDomainsAndIPsPanelGroupConnectedProps,
    never,
    IDomainsAndIPsPanelGroupOwnProps
  >((state, props) => {
    const globalWebscans = props.isVendorPortal
      ? state.common.webscans
      : state.cyberRisk.webscans;

    return {
      // some callers need to override this, but most won't, so we can fall back to global state
      allCloudscans: props.allCloudscans || globalWebscans,
    };
  })(DomainsAndIPsPanelGroup)
);

import { GetThreatMonitoringTotalsV2Resp } from "../api/threatmonitoring.api";
import { ThreatMonitoringFeedType } from "../api/types";
import { Tab } from "../views/ThreatMonitoringView";
import "./ThreatMonitoringTabs.scss";
import classnames from "classnames";

export interface ThreatMonitoringTabsProps {
  selectedIdx?: Tab;
  onSelect: (idx: Tab) => void;
  totals?: GetThreatMonitoringTotalsV2Resp;
}

const threatLabel = {
  open: "Open",
  investigating: "Investigating",
  remediating: "Remediating",
  closed: "Closed",
};

const ThreatMonitoringTabs = (props: ThreatMonitoringTabsProps) => {
  const { selectedIdx, onSelect, totals } = props;

  const tabLabel = (feedType: ThreatMonitoringFeedType) => {
    if (totals === undefined) {
      return "";
    }

    const count = totals.filtered[feedType];
    const total = totals.unfiltered[feedType];

    if (count === 0) {
      return threatLabel[feedType];
    }
    if (count === total) {
      return `${threatLabel[feedType]} (${total})`;
    }

    return `${threatLabel[feedType]} (${count}/${total})`;
  };

  return (
    <div className={"threat-monitoring-tabs"}>
      <div
        className={classnames("tab", { selected: selectedIdx == Tab.Open })}
        onClick={() => onSelect(Tab.Open)}
      >
        <div className={"label bracL"}>{`(`}</div>
        <div className={"cr-icon-risk"} />
        <div className={"label bracR"}>{`)`}</div>
        <div className={"label"}>{tabLabel(ThreatMonitoringFeedType.Open)}</div>
      </div>
      <div className={"sep"} />
      <div
        className={classnames("tab", {
          selected: selectedIdx == Tab.Investigating,
        })}
        onClick={() => onSelect(Tab.Investigating)}
      >
        <div className={"cr-icon-bookmark"} />
        <div className={"label"}>
          {tabLabel(ThreatMonitoringFeedType.Investigating)}
        </div>
      </div>
      <div className={"sep"} />
      <div
        className={classnames("tab", {
          selected: selectedIdx == Tab.Remediating,
        })}
        onClick={() => onSelect(Tab.Remediating)}
      >
        <div className={"cr-icon-spanner-outline"} />
        <div className={"label"}>
          {tabLabel(ThreatMonitoringFeedType.Remediating)}
        </div>
      </div>
      <div className={"sep"} />
      <div
        className={classnames("tab", {
          selected: selectedIdx == Tab.Closed,
        })}
        onClick={() => onSelect(Tab.Closed)}
      >
        <div className={"cr-icon-cancel"} />
        <div className={"label"}>
          {tabLabel(ThreatMonitoringFeedType.Closed)}
        </div>
      </div>
      <div className={"sep all"} />
    </div>
  );
};

export default ThreatMonitoringTabs;

import "../style/components/SelectableDocument.scss";
import { DocumentWithRisks } from "../views/RequestRemediationV2";
import classNames from "classnames";
import ColorCheckbox from "./ColorCheckbox";
import IconButton from "../../_common/components/IconButton";
import ExpandableItem from "../../_common/components/ExpandableItem";
import { AdjustedSeverityIcon } from "../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../_common/types/severity";
import { GetIconForFilename } from "../helpers/icons";

interface SelectableDocumentProps {
  docWithRisks: DocumentWithRisks;
  selected: boolean;
  onToggle: () => void;
  onDownload: () => void;
}

export const SelectableDocument = ({
  docWithRisks,
  selected,
  onToggle,
  onDownload,
}: SelectableDocumentProps) => (
  <div className={classNames("selectable-document", { selected })}>
    <div className="filename" onClick={onToggle}>
      <ColorCheckbox checked={selected} onClick={onToggle}></ColorCheckbox>
      <div className="filename-link">
        <img
          alt="File type icon"
          src={GetIconForFilename(docWithRisks.document.filename)}
        />
        <a
          onClick={(e) => {
            e.stopPropagation();
            onDownload();
          }}
        >
          {docWithRisks.document.name}
        </a>
      </div>
      {docWithRisks.document.virusSafe && (
        <IconButton
          icon={<span className="cr-icon-export-thin" />}
          onClick={onDownload}
        />
      )}
    </div>
    <ExpandableItem
      header={
        <div className="risk-count">
          {docWithRisks.risks.length === 1
            ? "1 risk"
            : `${docWithRisks.risks.length} risks`}
        </div>
      }
      content={docWithRisks.risks.map((risk) => (
        <div className="document-risk" key={risk.id} onClick={onToggle}>
          <AdjustedSeverityIcon
            severity={SeverityAsString(risk.severity)}
            baseSeverity={
              risk.baseSeverity
                ? SeverityAsString(risk.baseSeverity)
                : undefined
            }
          />
          <div className="risk-details">
            <div className="risk-title">{risk.title}</div>
            {risk.categoryTitle && (
              <div className="risk-description">{risk.categoryTitle}</div>
            )}
          </div>
        </div>
      ))}
    />
  </div>
);

import { ReactElement, useCallback, useEffect, useState } from "react";
import ModalV2, { IModalProps } from "../ModalV2";
import Button from "../core/Button";

import "../../style/components/ConfirmationModalV2.scss";

export type useConfirmationModalV2Props = Omit<
  IConfirmationModalV2Props,
  "active" | "onClose"
> & {
  // Optional onClose callback after confirmation modal has closed
  onClose?: () => void;
};

export type openConfirmationModalType = (
  props: useConfirmationModalV2Props
) => void;

// useConfirmationModalV2 is a hook to provide a way to easily call up a single ConfirmationModalV2 component
// with the correct props, on demand. The two values returned by this function are
// 1. a method for calling up the confirmation modal with any props applicable to ConfirmationModalV2
// 2. the rendered confirmation modal component
export const useConfirmationModalV2 = (): [
  (props: useConfirmationModalV2Props) => void,
  ReactElement<IConfirmationModalV2Props>,
] => {
  const [active, setActive] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<useConfirmationModalV2Props>({
      title: "",
      buttonAction: () => null,
      description: "",
    });

  const openConfirmationModal: openConfirmationModalType = useCallback(
    (props: useConfirmationModalV2Props) => {
      setActive(true);
      setConfirmationModalProps(props);
    },
    []
  );

  const confirmationModal = (
    <ConfirmationModalV2
      {...confirmationModalProps}
      active={active}
      onClose={() => {
        setActive(false);
        if (confirmationModalProps.onClose) {
          confirmationModalProps.onClose();
        }
      }}
    />
  );

  return [openConfirmationModal, confirmationModal];
};

export interface IConfirmationModalV2Props {
  title: string | JSX.Element;
  buttonAction: () => any | Promise<any>;
  description?: string | JSX.Element;
  descriptionClassName?: string;
  buttonText?: string;
  cancelText?: string;
  hideCancel?: boolean;
  dangerousAction?: boolean;
  filledPrimaryAction?: boolean;
  iconClass?: string;
  active: boolean;
  onClose: () => void;
  onCancel?: () => void;
  verticalCenter?: boolean;
  loading?: boolean;
  hideCloseButton?: boolean;
  hideActionButton?: boolean;
  width?: IModalProps["width"];
}

/**
 * <ConfirmationModalV2 />
 * This is a generic modal that can be used to show a simple modal to confirm or cancel an action.
 * This can take title, description, buttonText, buttonOnClick and hideCancel properties in the modalData.
 * buttonOnClick must be the function that's called when the primary button is clicked. If it is a promise,
 * the confirmation modal will go into a loading state and close when the promise resolves, or stay open if
 * the promise throws.
 */
const ConfirmationModalV2 = (props: IConfirmationModalV2Props) => {
  const {
    buttonAction,
    title,
    onClose,
    onCancel,
    active,
    hideCancel,
    cancelText,
    dangerousAction,
    filledPrimaryAction,
    iconClass,
    buttonText,
    description,
    descriptionClassName = "",
    verticalCenter = false,
    loading = false,
    hideCloseButton = false,
    hideActionButton = false,
    width,
  } = props;

  const [isLoading, setLoading] = useState(loading);
  const [isActioning, setIsActioning] = useState(false);

  // Cleanup on hide
  useEffect(() => {
    if (!active) {
      setLoading(false);
    }
  }, [active]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const onClick = () => {
    setIsActioning(true);

    // Using Promise.resolve to handle "thenable" promises as well as regular synchronous functions.
    // Returning true from the buttonAction will ensure the closeModal action is not called, such as
    // for opening another modal as a result of the action.
    Promise.resolve(buttonAction())
      .then((noClose = false) => {
        if (!noClose) {
          onClose();
        }
        setIsActioning(false);
      })
      .catch((e) => {
        // On error returned from promise, take us out of loading state but keep modal open.
        console.error(e);
        setIsActioning(false);
      });
  };

  const descContent =
    typeof description === "string" ? <p>{description}</p> : <>{description}</>;

  return (
    <ModalV2
      hideCloseButton={hideCloseButton}
      active={active}
      onClose={onClose}
      headerContent={title}
      footerContent={
        <div className="btn-group">
          {!hideCancel && (
            <Button
              disabled={isLoading}
              onClick={(ev) => {
                ev.stopPropagation();
                if (onCancel) {
                  onCancel();
                }
                onClose();
              }}
              tertiary
            >
              {cancelText || "Cancel"}
            </Button>
          )}
          {!hideActionButton && (
            <Button
              danger={dangerousAction}
              filledPrimary={filledPrimaryAction}
              loading={isActioning}
              disabled={isLoading}
              onClick={onClick}
            >
              {iconClass && <i className={iconClass} />}
              {buttonText || "OK"}
            </Button>
          )}
        </div>
      }
      className={"confirmation-modal " + descriptionClassName}
      verticalCenter={verticalCenter}
      width={width}
    >
      {descContent}
    </ModalV2>
  );
};

export default ConfirmationModalV2;

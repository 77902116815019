import { LogError } from "../../_common/helpers";
import { setSurveyData } from "../../_common/reducers/commonActions";
import {
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../_common/types/survey";
import { setVendorAppSurveyList, setVendorAppSurveysLoading } from "./actions";
import { DefaultThunkDispatch, SurveyCounts } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchURLInRegions } from "../../_common/regionalDataStorageHelpers";

export const fetchVendorAppSurveyList = (
  forceRefresh = false,
  background = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const state = getState();
    const surveys = state.vendorPortal.vendorAppSurveys;

    if (!forceRefresh && surveys && !surveys.loading && surveys.surveyCounts) {
      return null;
    }

    if (!background) {
      dispatch(setVendorAppSurveysLoading(true));
    }

    let foundSurveys: ISurveyListItemResponse[];

    try {
      // Make the request in every active region, we'll collate the results into one slice
      const allResults = await FetchURLInRegions<{
        surveys: ISurveyListItemResponse[];
      }>(
        "vendorapp/surveylist/v1/",
        undefined,
        undefined,
        undefined,
        dispatch,
        getState
      );

      foundSurveys = allResults.map((result) => result.surveys).flat();
    } catch (e) {
      LogError("Error retrieving vendorApp surveys", e);
      throw e;
    }

    // update the common cache of surveys by id
    const surveyCounts: SurveyCounts = {};

    for (let i = 0; i < foundSurveys.length; i++) {
      dispatch(setSurveyData(foundSurveys[i].id, { survey: foundSurveys[i] }));

      const usageType = foundSurveys[i].usageType;
      const isPending =
        foundSurveys[i].status === SurveyStatus.Sent ||
        foundSurveys[i].status === SurveyStatus.InProgress;

      if (!surveyCounts[usageType]) {
        surveyCounts[usageType] = {
          total: 1,
          active: isPending ? 1 : 0,
          surveyIds: [foundSurveys[i].id],
        };
      } else {
        surveyCounts[usageType] = {
          total: surveyCounts[usageType]!.total + 1,
          active: isPending
            ? surveyCounts[usageType]!.active + 1
            : surveyCounts[usageType]!.active,
          surveyIds: [
            ...surveyCounts[usageType]!.surveyIds,
            foundSurveys[i].id,
          ],
        };
      }
    }

    dispatch(
      setVendorAppSurveyList({
        loading: false,
        error: null,
        surveyCounts,
      })
    );

    return null;
  };
};

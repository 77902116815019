import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import "../style/components/TourHighlight.scss";
import DismissablePopup, { PopupPosition } from "./DismissablePopup";

import { useAppSelector } from "../types/reduxHooks";
import {
  isTaskIncomplete,
  getTask,
} from "../../vendorrisk/reducers/plgOnboardingChecklistActions";
import classnames from "classnames";
import Button from "./core/Button";

type TourHighlightProps = {
  tourHighlightId: string;
  tooltipHeader: string;
  tooltipText: string;
  stepNum: number;
  stepTotal: number;
  visible: boolean;
  className?: string;
  popupClassName?: string;
  scrollToMeOnMount?: boolean;
  position?: PopupPosition;
  onDismiss?: () => void;
  children: React.ReactNode;
};

type TourHighlightFromPLGContentProps = {
  onboardingTaskId: string;
  highlightIndex: number;
  visible: boolean;
  isV2Flow?: boolean;
  className?: string;
  popupClassName?: string;
  scrollToMeOnMount?: boolean;
  position?: PopupPosition;
  onDismiss?: () => void;
  children: React.ReactNode;
};

const StepDots = (props: {
  tooltipHeader: string;
  stepNum: number;
  stepTotal: number;
}) => {
  if (!props.stepTotal) {
    return <></>;
  }
  if (props.stepTotal < 2) {
    return <></>;
  }
  return (
    <div className={"progress-dots"}>
      {Array.from({ length: props.stepTotal }, (_, i) => (
        <span key={props.tooltipHeader + i.toString()}>
          {i == props.stepNum - 1 ? (
            <div className={"progress-dot light-dot"} />
          ) : (
            <div className={"progress-dot dark-dot"} />
          )}
        </span>
      ))}
    </div>
  );
};

export const TourHighlightFromPLGContent = ({
  onboardingTaskId,
  highlightIndex,
  visible,
  position,
  onDismiss,
  className,
  popupClassName,
  scrollToMeOnMount,
  isV2Flow,
  children,
}: TourHighlightFromPLGContentProps) => {
  const plgOnboarding = useAppSelector(
    (state) => state.common.userData.plgOnboarding
  );

  // let's return now to avoid extra work
  if (!visible) {
    return children;
  }

  const task = getTask(onboardingTaskId);
  const highlight = task.tourHighlights[highlightIndex];
  if (task.dependsOn) {
    // if this task depends on another and THAT one is incomplete,
    // prevent the highlight from showing
    if (isTaskIncomplete(task.dependsOn, plgOnboarding)) {
      return children;
    }
  }
  if (visible && isTaskIncomplete(task.id, plgOnboarding)) {
    if (isV2Flow) {
      return (
        <TourHighlight
          tourHighlightId={highlight.id}
          tooltipHeader={highlight.tooltipHeader}
          tooltipText={highlight.tooltipText}
          visible={visible}
          className={className}
          popupClassName={popupClassName}
          stepNum={1}
          stepTotal={1}
          position={position}
          scrollToMeOnMount={scrollToMeOnMount}
          onDismiss={onDismiss}
        >
          {children}
        </TourHighlight>
      );
    }

    return (
      <TourHighlight
        tourHighlightId={highlight.id}
        tooltipHeader={highlight.tooltipHeader}
        tooltipText={highlight.tooltipText}
        visible={visible}
        className={className}
        popupClassName={popupClassName}
        stepNum={highlightIndex + 1}
        stepTotal={task.tourHighlights.length}
        position={position}
        scrollToMeOnMount={scrollToMeOnMount}
        onDismiss={onDismiss}
      >
        {children}
      </TourHighlight>
    );
  }
  return children;
};

export const TourHighlight = ({
  tourHighlightId,
  tooltipHeader,
  tooltipText,
  stepNum,
  stepTotal,
  visible,
  position,
  onDismiss,
  className,
  popupClassName,
  scrollToMeOnMount,
  children,
}: TourHighlightProps) => {
  const [urlArgs, setUrlArgs] = useState(
    new URLSearchParams(window.location.search)
  );
  const [visibility, setVisibility] = useState(visible);

  function getTourArgs() {
    const freshArgs = new URLSearchParams(window.location.search);
    if (!freshArgs.has("th")) {
      return [];
    }
    const valString = freshArgs.get("th");
    if (!valString) {
      return [];
    }
    return valString.split("|");
  }

  const amIRendering = useMemo(() => {
    // if the condition in visibility is not met, we are not rendering
    if (!visibility) {
      return false;
    }

    const tourArgs = getTourArgs();

    // if there are multiple tour highlight url args,
    // only render if we're the first one.
    // this prevents multiple tooltips from popping at the same time on pages
    // where multiple actions need to be performed
    if (tourArgs.length > 0) {
      if (tourArgs[0] != tourHighlightId) {
        return false;
      }
    }

    if (stepNum == 1 && tourArgs.length == 0) {
      // all step 1 tasks should have an arg in them
      return false;
    }
    if (tourArgs.length > 0) {
      if (tourArgs.indexOf(tourHighlightId) == -1) {
        return false;
      }
    }
    // okay we're rendering
    return true;
  }, [visibility, tourHighlightId, stepNum, children]);

  const removeTourArg = useCallback(() => {
    const argArray = getTourArgs();
    argArray.splice(argArray.indexOf(tourHighlightId), 1);

    const newUrlArgs = urlArgs;
    if (argArray.length > 0) {
      newUrlArgs.set("th", argArray.join());
    } else {
      newUrlArgs.delete("th");
    }
    setUrlArgs(newUrlArgs);
    setVisibility(false);

    if (onDismiss) {
      onDismiss();
    }

    const url = new URL(window.location.href);
    if (argArray.length > 0) {
      url.searchParams.set("th", argArray.join());
    }
    window.history.pushState({}, "", url.href);
  }, [tourHighlightId, urlArgs]);

  return (
    <SimpleTourHighlight
      tooltipHeader={tooltipHeader}
      tooltipText={tooltipText}
      stepNum={stepNum}
      stepTotal={stepTotal}
      active={amIRendering}
      popupClassName={popupClassName}
      className={className}
      position={position}
      scrollToMeOnMount={scrollToMeOnMount}
      onDismiss={removeTourArg}
    >
      {children}
    </SimpleTourHighlight>
  );
};

interface SimpleTourHighlightProps {
  tooltipHeader: string;
  tooltipText: string;
  stepNum: number;
  stepTotal: number;
  scrollToMeOnMount?: boolean;
  className?: string;
  popupClassName?: string;
  position?: PopupPosition;
  onDismiss?: () => void;
  onNext?: () => void;
  active: boolean;
  children: React.ReactNode;
  noOutline?: boolean;
}

// SimpleTourHighlight allows tour highlights without the need to plug into the PLG context
export const SimpleTourHighlight = ({
  tooltipHeader,
  tooltipText,
  stepNum,
  stepTotal,
  scrollToMeOnMount,
  className,
  position,
  onDismiss,
  onNext,
  popupClassName,
  active,
  noOutline,
  children,
}: SimpleTourHighlightProps) => {
  const tooltipContent = (
    <>
      {tooltipText}{" "}
      <div className={"bottom-row"}>
        <StepDots
          tooltipHeader={tooltipHeader}
          stepNum={stepNum}
          stepTotal={stepTotal}
        />
        {onNext && (
          <Button primary onClick={onNext}>
            {stepNum == stepTotal ? "Done" : "Next"}
          </Button>
        )}
      </div>
    </>
  );

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollToMeOnMount && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [scrollToMeOnMount, ref.current]);

  if (active) {
    return (
      <div className={classnames("tour-highlight", className)}>
        <DismissablePopup
          popupClassName={classnames(
            "tour-highlight",
            "popup-" + position,
            popupClassName
          )}
          text={tooltipContent}
          className={"tour-highlight"}
          title={tooltipHeader}
          popupStyle={"dark"}
          position={position}
          inline={false}
          width={"300px"}
          onDismiss={onDismiss}
        >
          {!noOutline ? (
            <div
              className={classnames("highlight-outline", className)}
              ref={ref}
            >
              {children}
            </div>
          ) : (
            children
          )}
        </DismissablePopup>
      </div>
    );
  }
  return children;
};

// Data regions that organisations can exist in. This should exactly match the
// set of regions defined in goframework/cfg/cluster.go.
export enum DataRegion {
  US = "us",
  AU = "au",
  EU = "eu",
  GB = "gb",
  IN = "in",
}

export const DefaultDataRegion = DataRegion.US;

export const IsValidDataRegion = (region: DataRegion) =>
  Object.values(DataRegion).includes(region);

import { FC } from "react";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Doughnut from "../Doughnut";
import { applicationsUrl } from "../../UserBaseAppRouter";
import { pluralise } from "../../../_common/helpers";
import { AppStatus, DashboardStats } from "../../api/types";
import { hexColorForAppStatus } from "../../helpers/apps";
import { UserRiskFilters } from "../../../vendorrisk/components/filter/types";
import { useNavigateWithUserRiskFilters } from "../../helpers/hooks";
import { supportedFilters } from "../../views/applications/ApplicationsView";
import DashboardContainer from "./DashboardContainer";

const OrgAppsOverview: FC = ({}) => {
  const navigateWithUserRiskFilter =
    useNavigateWithUserRiskFilters(supportedFilters);

  const { data, isLoading } = UserBaseAPI.useGetUserBaseDashboardStatsV1Query();

  const backText = "Back to Dashboard";

  const noStats: DashboardStats = {
    numUsers: 0,
    numApps: 0,
    numPermissions: 0,
    appStatusCounts: [],
  };

  const stats = data?.stats ?? noStats;

  const doughnutData = [
    {
      label: "Approved",
      count: getAppStatusCount(stats, AppStatus.ApprovedAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.ApprovedAppStatus),
      onClick: () => {
        const filters: Partial<UserRiskFilters> = {
          userRiskAppStatuses: [AppStatus.ApprovedAppStatus],
        };
        navigateWithUserRiskFilter(applicationsUrl, backText, filters);
      },
    },
    {
      label: "Not Approved",
      count: getAppStatusCount(stats, AppStatus.NotApprovedAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.NotApprovedAppStatus),
      onClick: () => {
        const filters: Partial<UserRiskFilters> = {
          userRiskAppStatuses: [AppStatus.NotApprovedAppStatus],
        };
        navigateWithUserRiskFilter(applicationsUrl, backText, filters);
      },
    },
    {
      label: "In Review",
      count: getAppStatusCount(stats, AppStatus.InReviewAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.InReviewAppStatus),
      onClick: () => {
        const filters: Partial<UserRiskFilters> = {
          userRiskAppStatuses: [AppStatus.InReviewAppStatus],
        };
        navigateWithUserRiskFilter(applicationsUrl, backText, filters);
      },
    },
    {
      label: "Needs Review",
      count: getAppStatusCount(stats, AppStatus.NeedsReviewAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.NeedsReviewAppStatus),
      onClick: () => {
        const filters: Partial<UserRiskFilters> = {
          userRiskAppStatuses: [AppStatus.NeedsReviewAppStatus],
        };
        navigateWithUserRiskFilter(applicationsUrl, backText, filters);
      },
    },
  ].filter((d) => d.count > 0); // only display non-empty entries

  return (
    <div className="userbase-org-apps-overview">
      <DashboardContainer title="App status breakdown">
        {isLoading && <LoadingBanner />}
        {!isLoading && (
          <div className={"content"}>
            <Doughnut
              data={doughnutData}
              totalLabel={pluralise(stats.numApps, "App", "Apps")}
            />
          </div>
        )}
      </DashboardContainer>
    </div>
  );
};

function getAppStatusCount(
  stats: DashboardStats,
  appStatus: AppStatus
): number {
  // Look for the app status in the appStatusCounts array
  const appStatusCount = (stats.appStatusCounts ?? []).find(
    (status) => status.appStatus === appStatus
  );
  return appStatusCount?.count ?? 0;
}
export default OrgAppsOverview;

import { Steps } from "../../../_common/components/StepsWithSections";
import ReportCard from "../../../_common/components/ReportCard";
import BreachSightWaiverDetailsStep, {
  ApproverType,
  ExpireType,
} from "./BreachSightWaiverDetailsStep";
import BreachSightWaiverConfirmStep from "./BreachSightWaiverConfirmStep";
import ActionBar from "../../../_common/components/ActionBar";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
} from "../../../_common/types/vendorSummary";
import { TextFieldData } from "../../../_common/components/TextField";
import moment from "moment";
import { IRisk } from "../RiskSummaryDetails";
import { Filters } from "../filter/types";
import { calculateWaivedAssetsForRisks } from "../../reducers/risks.actions";
import { OrganisationAcceptedRisk } from "../../reducers/customerAcceptedRisks.actions";
import Button from "../../../_common/components/core/Button";
import SelectRiskTable, { AssetSelection } from "./SelectRiskTable";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import "./BreachSightCreateRiskWaiverFlow.scss";
import SubsidiarySelector from "../SubsidiarySelector";
import { getSubsidiaryName } from "../../reducers/subsidiary.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { fetchSubsidiaryRiskWaivers } from "../../reducers/vendorRiskWaiver.actions";
import { fetchVendorSummaryAndCloudscans } from "../../reducers/cyberRiskActions";
import { fetchBreachsightRiskProfileIncludingSubsidiaries } from "../../reducers/breachsightSubsidiaries.actions";

interface BreachSightCreateRiskWaiverFlowProps {
  orgSupportsDomainPortfolios?: boolean;
  editableDomainPortfolioIds?: number[];
  filters?: Filters;
  requireApprovalsRiskWaivers: boolean;
  canUsePublicWaivers: boolean;

  risks?: IRisk[];
  riskWaivers?: OrganisationAcceptedRisk[];

  waiverDetails: BreachSightCreateRiskWaiverFlowCreationDetails;
  onWaiverDetailsChanged: (
    newDetails: BreachSightCreateRiskWaiverFlowCreationDetails
  ) => void;
  onCancel: () => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  initiallyExpandedRiskId?: string;

  selectRiskUIOverride?: ReactNode;

  // isSubsidiaryWaiver adds an initial step to select the org/subsidiary
  // prior to the Select Assets step.
  isSubsidiaryWaiver?: boolean;
  initialSubsidiaryVendorId?: number;
}

export interface BreachSightCreateRiskWaiverFlowCreationDetails {
  selectedRisk?: VendorSummaryRisk;
  assetSelection: AssetSelection;
  approverType: ApproverType;
  approverEmail: TextFieldData;
  justification: TextFieldData;
  expireType: ExpireType;
  expireDate: string;
  isPublic: boolean;
  subsidiaryVendorID?: number;
}

export const getNewBlankWaiver = (
  selectedRisk?: VendorSummaryRisk,
  allWebsites?: boolean,
  userHasPortfolioSpecificAccess?: boolean,
  selectedWebsites?: string[],
  subsidiaryVendorID?: number
): BreachSightCreateRiskWaiverFlowCreationDetails => {
  return {
    selectedRisk,
    assetSelection: {
      hostnameSelection: {
        allSelected: (allWebsites && !userHasPortfolioSpecificAccess) || false,
        includeFuture: false,
        selected: selectedWebsites || [],
      },
      allQuestionnairesSelected: false,
      selectedQuestionnaires: [],
      selectedPublicQuestionnaires: [],
      manifestSelection: undefined,
      repositorySelection: undefined,
      saasSelection: undefined,
    },
    approverType: ApproverType.Self,
    approverEmail: { value: "", isValid: false },
    justification: { value: "", isValid: false },
    expireType: ExpireType.Never,
    expireDate: moment().add(1, "year").format("YYYY-MM-DD"), // Set to a year in the future by default
    isPublic: false,
    subsidiaryVendorID: subsidiaryVendorID,
  };
};

const BreachSightCreateRiskWaiverFlow: FC<
  BreachSightCreateRiskWaiverFlowProps
> = ({
  orgSupportsDomainPortfolios,
  editableDomainPortfolioIds,
  requireApprovalsRiskWaivers,
  canUsePublicWaivers,
  filters,
  risks,
  riskWaivers,
  waiverDetails,
  onWaiverDetailsChanged,
  onCancel,
  isSubmitting,
  onSubmit,
  initiallyExpandedRiskId,
  selectRiskUIOverride,
  isSubsidiaryWaiver,
  initialSubsidiaryVendorId,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedVendorID, setSelectedVendorID] = useState<number | undefined>(
    initialSubsidiaryVendorId
  );
  const [selectableRisks, setSelectableRisks] = useState(risks);
  const [isLoading, setIsLoading] = useState(false);

  const handleVendorSelection = (vendorID: number | undefined) => {
    setSelectedVendorID(vendorID);
    waiverDetailsUpdated({ subsidiaryVendorID: vendorID });
  };

  const subsidiaries = useAppSelector(
    (state) => state.cyberRisk.customerData.subsidiaries
  );

  const subsidiaryName = useMemo(() => {
    return selectedVendorID
      ? getSubsidiaryName(selectedVendorID, subsidiaries)
      : undefined;
  }, [subsidiaries, selectedVendorID]);

  const subsidiaryRisks = useAppSelector(
    (state) =>
      state.cyberRisk.subsidiaries[selectedVendorID ?? 0]
        ?.riskProfileIncludingSubsidiaries?.risks
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedVendorID) {
      const proms: Promise<any>[] = [
        dispatch(fetchSubsidiaryRiskWaivers(selectedVendorID, false)),
        dispatch(
          fetchVendorSummaryAndCloudscans(
            selectedVendorID,
            false,
            false,
            false,
            true
          )
        ),
        dispatch(
          fetchBreachsightRiskProfileIncludingSubsidiaries(
            false,
            selectedVendorID
          )
        ),
      ];
      setIsLoading(true);
      Promise.all(proms)
        .catch((error) => {
          console.error("Error fetching subsidiary risk profile", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedVendorID]);

  useEffect(() => {
    setSelectableRisks(risks);
    if (selectedVendorID && (subsidiaryRisks?.length ?? 0) > 0) {
      setSelectableRisks(subsidiaryRisks);
    }
  }, [subsidiaryRisks, selectedVendorID, risks]);

  const {
    selectedRisk,
    assetSelection,
    approverType,
    approverEmail,
    justification,
    expireType,
    expireDate,
    isPublic,
  } = waiverDetails;

  const waiverDetailsUpdated = (
    newDetails: Partial<BreachSightCreateRiskWaiverFlowCreationDetails>
  ) => {
    const details = {
      ...waiverDetails,
      ...newDetails,
    };

    onWaiverDetailsChanged(details);
  };

  const subsidiaryRiskWaivers = useAppSelector(
    (state) => state.cyberRisk.subsidiaries[selectedVendorID ?? 0]?.riskWaivers
  );

  const waivedRiskAssets = useMemo(() => {
    const isCustomer = !selectedVendorID;
    return calculateWaivedAssetsForRisks(
      selectableRisks ?? [],
      isCustomer,
      undefined,
      undefined,
      isCustomer ? riskWaivers : undefined,
      undefined,
      isCustomer ? undefined : subsidiaryRiskWaivers
    );
  }, [selectableRisks, riskWaivers, subsidiaryRiskWaivers, selectedVendorID]);

  let disablePublicText: string | undefined = undefined;
  if (
    assetSelection.hostnameSelection.includeFuture ||
    (assetSelection.hostnameSelection.customDomains &&
      assetSelection.hostnameSelection.customDomains.length > 0)
  ) {
    disablePublicText =
      "A public risk waiver cannot include custom domains, or domains & IPs we detect in the future.";
  }

  const isConfigStepDisabled = () => {
    if (!selectedRisk) {
      return true;
    }

    switch (selectedRisk.riskType) {
      case VendorSummaryRiskType.Cloudscan:
        return (
          !assetSelection.hostnameSelection.includeFuture &&
          assetSelection.hostnameSelection.selected.length === 0
        );
      case VendorSummaryRiskType.AppguardPackageVuln:
        return (
          (assetSelection.manifestSelection?.selectedUuids ?? []).length === 0
        );
      case VendorSummaryRiskType.AppguardRepoConfig:
        return (
          (assetSelection.repositorySelection?.selectedUuids ?? []).length === 0
        );
      case VendorSummaryRiskType.SaaS:
        return (assetSelection.saasSelection?.selectedUuids ?? []).length === 0;
      default:
        return false;
    }
  };

  const isReviewStepDisabled = () => {
    if (isConfigStepDisabled()) {
      return true;
    }

    return (
      !approverType ||
      !justification.isValid ||
      (approverType === ApproverType.Other &&
        (!approverEmail.value || !approverEmail.isValid)) ||
      (expireType === ExpireType.Other &&
        (!expireDate || moment().add(1, "day").isAfter(expireDate, "day")))
    );
  };

  enum Step {
    Subsidiary = "subsidiary",
    Assets = "assets",
    Config = "config",
    Review = "review",
  }

  const baseStepDefs: { id: Step; text: string }[] = [
    {
      id: Step.Assets,
      text:
        selectedRisk?.riskType === VendorSummaryRiskType.SaaS
          ? "Select users"
          : "Select assets",
    },
    { id: Step.Config, text: "Configure" },
    { id: Step.Review, text: "Review" },
  ];

  const stepsDef =
    isSubsidiaryWaiver && !initialSubsidiaryVendorId
      ? [{ id: Step.Subsidiary, text: "Select subsidiary" }, ...baseStepDefs]
      : baseStepDefs;

  const isStepDisabled = (stepId: Step): boolean => {
    if (stepId === Step.Assets)
      return !!isSubsidiaryWaiver && !selectedVendorID;
    if (stepId === Step.Config) return isConfigStepDisabled();
    if (stepId === Step.Review) return isReviewStepDisabled();
    return false;
  };

  const steps = stepsDef.map((def, idx) => ({
    id: def.id,
    text: def.text,
    onClick: () => setCurrentStep(idx + 1),
    disabled: isStepDisabled(def.id),
  }));

  const getStepNumber = (id: Step): number =>
    steps.findIndex((step) => step.id === id) + 1;

  return (
    <div className={"breachsight-create-risk-waiver-flow"}>
      <Steps steps={steps} currentStep={currentStep} />

      {isSubsidiaryWaiver && currentStep === getStepNumber(Step.Subsidiary) && (
        <ReportCard newStyles>
          <SubsidiarySelector
            selectedVendorId={selectedVendorID}
            setSelectedVendorId={handleVendorSelection}
            topLevelSelectionDisabled={true}
          />
        </ReportCard>
      )}

      {currentStep === getStepNumber(Step.Assets) &&
        (!!selectRiskUIOverride ? (
          selectRiskUIOverride
        ) : (
          <ReportCard newStyles className={"select-risk-card"}>
            <div className={"header"}>
              Select risk to waive
              {subsidiaryName && ` for ${subsidiaryName}`}
            </div>
            <SelectRiskTable
              isCustomer={!isSubsidiaryWaiver}
              isSubsidiary={isSubsidiaryWaiver}
              datastoreVendorId={selectedVendorID}
              orgSupportsDomainPortfolios={orgSupportsDomainPortfolios}
              editableDomainPortfolioIds={editableDomainPortfolioIds}
              filters={filters}
              risks={(selectableRisks ?? []).filter(
                (r) =>
                  !r.passed &&
                  !r.isWaived &&
                  (!selectedVendorID ||
                    r.failedVendors?.some((v) => v.id === selectedVendorID))
              )}
              isLoading={!selectableRisks}
              selectedRisk={selectedRisk}
              selection={assetSelection}
              waivedRiskAssets={waivedRiskAssets}
              waiverType={WaiverType.RiskWaiver}
              onSelectRisk={(risk, assetSelection) =>
                waiverDetailsUpdated({
                  selectedRisk: risk,
                  assetSelection: assetSelection,
                })
              }
              initiallyExpandedRiskId={initiallyExpandedRiskId}
            />
          </ReportCard>
        ))}

      {currentStep === getStepNumber(Step.Config) && (
        <ReportCard newStyles className={"breachsight-waiver-details-step"}>
          <div className={"header"}>Approvals and Expiry</div>
          <div className={"report-card-content"}>
            <BreachSightWaiverDetailsStep
              requireApprovalsRiskWaivers={requireApprovalsRiskWaivers}
              approverType={approverType}
              approverEmail={approverEmail}
              onSetApprover={(a, v) =>
                waiverDetailsUpdated({
                  approverType: a,
                  approverEmail: v,
                })
              }
              canUsePublicWaivers={canUsePublicWaivers}
              isPublic={isPublic}
              disablePublicText={disablePublicText}
              onSetPublic={(v) => waiverDetailsUpdated({ isPublic: v })}
              justification={justification}
              onSetJustification={(v) =>
                waiverDetailsUpdated({
                  justification: v,
                })
              }
              expireType={expireType}
              expireDate={expireDate}
              onSetExpire={(t, d) =>
                waiverDetailsUpdated({
                  expireType: t,
                  expireDate: d ?? moment().add(1, "year").format("YYYY-MM-DD"),
                })
              }
            />
          </div>
        </ReportCard>
      )}

      {currentStep === getStepNumber(Step.Review) && (
        <ReportCard newStyles>
          <div className={"header"}>Review and complete</div>
          <div className={"report-card-content"}>
            <BreachSightWaiverConfirmStep
              selectedRisk={selectedRisk}
              assetSelection={assetSelection}
              approverType={approverType}
              approverEmail={approverEmail.value}
              justification={justification.value}
              canUsePublicWaivers={canUsePublicWaivers}
              isPublic={isPublic}
              expireType={expireType}
              expireDate={expireDate}
              subsidiaryName={subsidiaryName}
            />
          </div>
        </ReportCard>
      )}

      <ActionBar active>
        <div className="actions">
          {currentStep !== 1 && (
            <Button leftArrow onClick={() => setCurrentStep(currentStep - 1)}>
              Previous
            </Button>
          )}
          <div>
            {currentStep === getStepNumber(Step.Assets)
              ? selectedRisk
                ? `Selected: ${selectedRisk.title}`
                : "Please select a risk"
              : null}
          </div>
          <div className={"buttons"}>
            <Button tertiary onClick={onCancel}>
              Cancel
            </Button>
            {currentStep !== stepsDef.length ? (
              <Button
                arrow
                primary
                disabled={isStepDisabled(stepsDef[currentStep].id) || isLoading}
                onClick={() => setCurrentStep(currentStep + 1)}
                loading={isLoading}
              >
                Next
              </Button>
            ) : (
              <Button primary loading={isSubmitting} onClick={onSubmit}>
                Create waiver
              </Button>
            )}
          </div>
        </div>
      </ActionBar>
    </div>
  );
};

export default BreachSightCreateRiskWaiverFlow;

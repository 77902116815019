import ReportCard from "../../_common/components/ReportCard";
import "../style/TrustPageEditableCard.scss";
import { ReactNode } from "react";
import classnames from "classnames";

interface ITrustPageEditableCardProps {
  className?: string;
  header?: string;
  editButton?: ReactNode;
  children?: ReactNode;
}

const TrustPageEditableCard = ({
  className,
  header,
  editButton,
  children,
}: ITrustPageEditableCardProps) => {
  return (
    <ReportCard
      newStyles={true}
      className={classnames("trust-page-card", className)}
    >
      <div className="header">
        <h4>{header}</h4>
        {editButton && editButton}
      </div>
      <div className={"report-card-content"}>{children}</div>
    </ReportCard>
  );
};

export default TrustPageEditableCard;

import {
  CSSProperties,
  FC,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import "../style/components/TextFieldPerformant.scss";
import { useDebounce } from "../hooks";
import classNames from "classnames";

export interface TextFieldPerformantProps {
  value: string;
  onChanged?: (val: string) => void;

  // Height for the input field
  // Default to 150 for multiline, 32 for single line
  height?: number;

  className?: string;
  multiLine?: boolean;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  inputColor?: "orange";
  maxLength?: number;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;

  // Use strict to enforce layout constraints for further performance improvement.
  strict?: boolean;
}

// TextFieldPerformant is a more performant and stripped down version of <TextField>.
// The input field is absolute positioned.
const TextFieldPerformant: FC<TextFieldPerformantProps> = ({
  value,
  onChanged,
  height,
  className,
  multiLine,
  placeholder,
  required,
  disabled,
  inputColor,
  strict,
  onFocus,
  readonly,
  maxLength,
}) => {
  const [internalVal, setInternalVal] = useState(value);

  // Keep a ref of whether the user is typing or not
  const isTypingRef = useRef(false);

  // Debounce the value change callback
  const debouncedChange = useDebounce(
    (v: string) => {
      if (onChanged) onChanged(v);
      isTypingRef.current = false;
    },
    500,
    [onChanged]
  );

  // Respond to value changed by parent
  // Only apply if not actively typing
  useEffect(() => {
    if (!isTypingRef.current) {
      setInternalVal(value);
    }
  }, [value]);

  const editorHeight = height ? height : multiLine ? 150 : 32;

  const style: CSSProperties = useMemo(() => {
    return {
      height: editorHeight,
    };
  }, [editorHeight, multiLine]);

  // Adjust the container style for height
  // if using contain: strict we'll need to allow space for focus border
  const containerStyle: CSSProperties = useMemo(() => {
    return {
      height: strict ? editorHeight + 6 : editorHeight,
    };
  }, [editorHeight, multiLine, strict]);

  const commonProps = {
    value: internalVal,
    className: classnames(inputColor),
    placeholder,
    required,
    disabled,
    readonly,
    onChange: useCallback((ev: any) => {
      setInternalVal(ev.target.value);
      isTypingRef.current = true;
      debouncedChange(ev.target.value);
    }, []),
    style,
    onFocus: useCallback(
      (e: any) => {
        if (onFocus) onFocus(e);
      },
      [onFocus]
    ),
    maxLength,
  };

  return (
    <div
      className={classNames("text-field-performant", className, {
        strict: strict,
      })}
      style={containerStyle}
    >
      {multiLine && <textarea {...commonProps} />}
      {!multiLine && <input type={"text"} {...commonProps} />}
    </div>
  );
};

export default TextFieldPerformant;

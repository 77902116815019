import {
  ThreatMonitoringCriticality,
  ThreatMonitoringModule,
  ThreatMonitoringRemediationOutcome,
  ThreatMonitoringRemediationStatus,
  ThreatMonitoringSourceType,
  ThreatMonitoringThreatType,
  ThreatMonitoringVendorNetworkType,
} from "../api/types";

export const SYSTEM_USER_ID = -99;

export const ModuleNameDarkWeb = "Dark web";

// return a human-readable name for a module type
export function moduleToString(module: ThreatMonitoringModule) {
  switch (module) {
    case ThreatMonitoringModule.ModuleDarkWeb:
      return ModuleNameDarkWeb;
    case ThreatMonitoringModule.ModuleDataLeaks:
      return "Data leaks";
    case ThreatMonitoringModule.ModuleSocialMedia:
      return "Social media";
  }
}

export function moduleToTitle(module: ThreatMonitoringModule) {
  switch (module) {
    case ThreatMonitoringModule.ModuleDarkWeb:
      return "Dark Web";
    case ThreatMonitoringModule.ModuleDataLeaks:
      return "Data Leaks";
    case ThreatMonitoringModule.ModuleSocialMedia:
      return "Social Media";
  }
}

export const threatTypeToName: Record<ThreatMonitoringThreatType, string> = {
  [ThreatMonitoringThreatType.Unknown]: "Unknown",
  [ThreatMonitoringThreatType.ExposedCredentials]: "Exposed credentials",
  [ThreatMonitoringThreatType.ExposedPII]: "Exposed PII",
  [ThreatMonitoringThreatType.Malware]: "Malware",
  [ThreatMonitoringThreatType.KeywordMention]: "Keyword mention",
  [ThreatMonitoringThreatType.ConfidentialBusiness]:
    "Confidential business data",
  [ThreatMonitoringThreatType.BrandImpersonation]: "Brand impersonation",
};

// return a human-readable name for a threat type
export function threatTypeToString(threat: ThreatMonitoringThreatType) {
  return threatTypeToName[threat] || "[Unknown threat type]";
}

export function threatTypesToStringList(
  threatTypes: ThreatMonitoringThreatType[]
) {
  return threatTypes.map(threatTypeToString).join(", ");
}

// return a human-readable name for a source type
export function sourceTypeToString(source: ThreatMonitoringSourceType) {
  switch (source) {
    case ThreatMonitoringSourceType.Unknown:
      return "Unknown";
    case ThreatMonitoringSourceType.StealerLogs:
      return "Stealer log";
    case ThreatMonitoringSourceType.Forum:
      return "Forum";
    case ThreatMonitoringSourceType.Marketplace:
      return "Illicit marketplace";
    case ThreatMonitoringSourceType.RansomwareBlog:
      return "Ransomware blog";
    case ThreatMonitoringSourceType.Telegram:
      return "Telegram";
    case ThreatMonitoringSourceType.Discord:
      return "Discord";
    case ThreatMonitoringSourceType.ICQ:
      return "ICQ";
    case ThreatMonitoringSourceType.OpenBazaar:
      return "OpenBazaar";
    case ThreatMonitoringSourceType.Paste:
      return "Paste site";
    case ThreatMonitoringSourceType.Blogs:
      return "Blog";
    case ThreatMonitoringSourceType.News:
      return "News";
    case ThreatMonitoringSourceType.SocialMedia:
      return "Social media";
    case ThreatMonitoringSourceType.TikTok:
      return "Tiktok";
    case ThreatMonitoringSourceType.Github:
      return "Github";
    case ThreatMonitoringSourceType.Gitlab:
      return "Gitlab";
    case ThreatMonitoringSourceType.Bitbucket:
      return "Bitbucket";
    case ThreatMonitoringSourceType.Atlassian:
      return "Atlassian";
    case ThreatMonitoringSourceType.JenkinsIssues:
      return "Jenkins issues";
    case ThreatMonitoringSourceType.Trello:
      return "Trello";
    case ThreatMonitoringSourceType.DockerHub:
      return "Docker hub";
    case ThreatMonitoringSourceType.StackOverflow:
      return "Stack overflow";
    case ThreatMonitoringSourceType.CodeRepository:
      return "Code repository";
    case ThreatMonitoringSourceType.IssueTracker:
      return "Issue tracker";
    case ThreatMonitoringSourceType.PackageRepository:
      return "Package repository";
    default:
      return "";
  }
}

export function vendorNetworkTypeToString(
  networkType?: ThreatMonitoringVendorNetworkType
) {
  switch (networkType) {
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeTOR:
      return "Tor";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeI2P:
      return "I2P";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeOpenBazaar:
      return "OpenBazaar";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeTelegram:
      return "Telegram";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeDiscord:
      return "Discord";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeOpenWeb:
      return "Open web";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeICQ:
      return "ICQ";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypePublicChat:
      return "Public chat";
    case undefined:
      return "";
    default:
      return networkType;
  }
}

export function vendorNetworkTypeDescription(
  networkType?: ThreatMonitoringVendorNetworkType
) {
  switch (networkType) {
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeOpenWeb:
      return "The portion of the World Wide Web that is readily available to the general public and searchable with standard web search engines.";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeTOR:
      return "Tor (The Onion Router) is an open-source software and network designed to enable anonymous communication and browsing on the internet. It routes internet traffic through a network of volunteer-operated servers (nodes) using multiple layers of encryption, to conceal user location and usage.";
    case ThreatMonitoringVendorNetworkType.WebzNetworkTypeI2P:
      return "I2P (Invisible Internet Project) is an open-source, decentralized network designed to enable anonymous and secure communication over the internet. Unlike Tor, which focuses on accessing both the regular web and dark web, I2P is a self-contained network primarily for communication within its ecosystem.";
  }

  return;
}

export function vendorNetworkTypeIsDarkWeb(
  networkType?: ThreatMonitoringVendorNetworkType
) {
  return (
    networkType === ThreatMonitoringVendorNetworkType.WebzNetworkTypeTOR ||
    networkType === ThreatMonitoringVendorNetworkType.WebzNetworkTypeI2P
  );
}

export function threatRemediationOutcomeTypeToString(
  outcomeType: ThreatMonitoringRemediationOutcome
) {
  switch (outcomeType) {
    case ThreatMonitoringRemediationOutcome.ResponsiblePartyContacted:
      return "Responsible party identified & contacted";
    case ThreatMonitoringRemediationOutcome.AffectedUsersNotified:
      return "Affected users notified";
    case ThreatMonitoringRemediationOutcome.AffectedUsersCredentialsReset:
      return "Affected users' credentials reset";
    case ThreatMonitoringRemediationOutcome.RequestedTakedown:
      return "Requested takedown";
    case ThreatMonitoringRemediationOutcome.Other:
      return "Other";
    case ThreatMonitoringRemediationOutcome.Remediated:
      return "Remediated";
    case ThreatMonitoringRemediationOutcome.Waived:
      return "Waived";
  }
}

export function threatRemediationStatusTypeToString(
  statusType: ThreatMonitoringRemediationStatus
) {
  switch (statusType) {
    case ThreatMonitoringRemediationStatus.Remediated:
      return "Remediated";
    case ThreatMonitoringRemediationStatus.Waived:
      return "Waived";
    default:
      return "";
  }
}

export const criticalityStr = (
  criticality?: ThreatMonitoringCriticality
): string => {
  return criticality && criticality.length > 0
    ? criticality.charAt(0).toUpperCase() + criticality.slice(1)
    : "Not set";
};

export const criticalityColor = (
  criticality?: ThreatMonitoringCriticality
): string => {
  switch (criticality) {
    case ThreatMonitoringCriticality.High:
      return "dot-red";
    case ThreatMonitoringCriticality.Medium:
      return "dot-orange";
    case ThreatMonitoringCriticality.Low:
      return "dot-green";
    default:
      return "dot-white";
  }
};

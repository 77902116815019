export const ContentLibraryQuestionnaireDocumentType = "Questionnaire";

export const fileEligibleForQAColumnStep = (filename: string) =>
  filename.toLowerCase().endsWith(".xlsx");

export interface DocumentType {
  name: string;
  isCustom: boolean;
  numDocuments: number;
}

interface contentLibraryDocumentHistoryItemBase {
  id: number;
  createdAt: string;
  createdBy: number;
}

export interface DocumentCreatedHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "DocumentCreated";
  historyData: never;
}

export interface VersionAddedHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "VersionAdded";
  historyData: { version: number };
}

export interface DocumentUpdatedHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "DocumentUpdated";
  historyData: {
    updatedFields: { fieldName: string; newValue: any }[];
  };
}

export interface DocumentUsedInSurveyHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "DocumentUsedInSurvey";
  historyData: {
    documentVersion: number;
    surveyID: number;
    isPublic: boolean;
    answerID: number;
    questionID: string;
  };
}

export interface DocumentUsedInEvidenceHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "DocumentUsedInEvidence";
  historyData: {
    documentVersion: number;
    evidenceID: number;
    attached: boolean;
  };
}

export interface DocumentIncludedInSharedProfileHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "DocumentIncludedInSharedProfile";
  historyData: {
    included: boolean;
  };
}

export interface MigratedFromSharedProfileHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "MigratedFromSharedProfile";
  historyData: never;
}

export interface MigratedFromQuestionnaireHistoryItem
  extends contentLibraryDocumentHistoryItemBase {
  historyType: "MigratedFromQuestionnaire";
  historyData: {
    surveyID: number;
    isPublic: boolean;
  };
}

export type ContentLibraryDocumentHistoryItem =
  | DocumentCreatedHistoryItem
  | VersionAddedHistoryItem
  | DocumentUpdatedHistoryItem
  | DocumentUsedInSurveyHistoryItem
  | DocumentUsedInEvidenceHistoryItem
  | DocumentIncludedInSharedProfileHistoryItem
  | MigratedFromSharedProfileHistoryItem
  | MigratedFromQuestionnaireHistoryItem;

export interface ContentLibraryDocumentVersion {
  version: number;
  linkedBy: number;
  filename: string;
  virusScanned: boolean;
  virusSafe: boolean;
  qrdExternalDocsUUID?: string;
  qrdExternalDocProcessing: boolean;
  qrdExternalDocProcessed: boolean;
  createdAt: string;
}

export interface ContentLibraryDocument {
  uuid: string;
  orgID: number;
  documentType: string;
  name: string;
  description: string;
  internalNotes: string;
  archived: boolean;
  versions: ContentLibraryDocumentVersion[];
  includeInSharedProfile: boolean;
  usageCount: number;
  expiresAt?: string;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
}

export interface ContentLibraryDocumentRedacted {
  uuid: string;
  documentType: string;
  name: string;
  description: string;
  filename: string;
  createdAt: string;
  updatedat: string;
  archived: boolean;
}

export enum GetDocumentsSortByCol {
  Name = "name",
  Type = "type",
  Updated = "updated",
}

export interface GetDocumentsSortBy {
  col: GetDocumentsSortByCol;
  desc: boolean;
}

export interface GetDocumentsFilter {
  name?: string;
  searchQuery?: string;
  md5?: string[];
  documentTypes: string[];
  sharedProfileOnly: boolean;
  archived: boolean;
}

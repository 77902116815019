import { Expression } from "./conditionalLogic";
import { Severity } from "../../_common/types/severity";

// This file shares types with survey/surveybuilder/node_types.go
// Do not change anything without checking if the change must also be made on the backend!

export enum NodeType {
  Section = "section",
  InputText = "inputtext",
  Select = "select",
  Upload = "upload",
  Risk = "risk",
  Info = "info",
}

// Union of all potential node interfaces.
export type Node =
  | SectionNode
  | InputTextNode
  | SelectNode
  | UploadNode
  | RiskNode
  | InfoNode;

// BaseNode is the base class shared by all node types.
export interface BaseNode {
  // Unique ID for this node - usually a UUID
  nodeId: string;
  // NodeID of immediate parent. All nodes in the tree except the root node should have a parent.
  parentId?: string;
  // Custom number of the node if survey has custom numbering enabled.
  customNumber?: string;
  // Main text section of the node.
  mainText: string;
  // Optional condition for showing this question
  conditionalExpression?: Expression[];
  // Optional set of controls this node relates to in a given framework
  controls?: string[];
  // Main text section of the node, stripped of HTML.
  mainTextSanitised?: string;
  // If we should skip numbering this particular node.
  skipNumbering?: boolean;
  // If this node has a custom risk attached
  hasCustomRisk?: boolean;
  // If this node is a custom risk the ID of that risk
  customRiskID?: number;
  // If this node is a custom risk the 'parent' ID of that risk
  customRiskParentId?: string;
}

export interface childNodeIdAndType {
  id: string;
  type: NodeType;
}

// SectionNode groups multiple questions together.
export interface SectionNode extends BaseNode {
  type: NodeType.Section;
  // Title text for the section, which goes above the mainText.
  title: string;
  // All child nodeIDs in order.
  childNodeIds: childNodeIdAndType[];
  // Title text for the section, which goes above the mainText, stripped of HTML.
  titleSanitised?: string;
}

// InputTextNode allows the user to enter free text for their answer.
// mainText is used for the text of the question.
export interface InputTextNode extends BaseNode {
  type: NodeType.InputText;
  // If true, a multiline text input will be provided.
  multiLine: boolean;
}

export interface SelectNodeAnswer {
  // Unique ID of the answer
  id: string;
  // Text of the answer
  text: string;
}

// SelectNode creates a radio or check group from a predefined set of answers.
export interface SelectNode extends BaseNode {
  type: NodeType.Select;
  // If true, this will be a radio group. If false, it will be a check group.
  radio: boolean;
  answers: SelectNodeAnswer[];
  allowNotes: boolean;
}

// UploadNode allows the user to upload a file for their answer.
export interface UploadNode extends BaseNode {
  type: NodeType.Upload;
}

// RiskNode displays a risk to the user
export interface RiskNode extends BaseNode {
  type: NodeType.Risk;
  severity?: Severity;
  riskCategory: string; // deprecated
  impact: string;
  name: string;
  nameSanitised?: string;
  passName: string;
  askForCompensatingControls: boolean;
  // Text to show if compensating controls can be specified
  why: string;
}

// InfoNode shows information as a standalone block.
export interface InfoNode extends BaseNode {
  type: NodeType.Info;
}

export const nodeToFriendlyName = (node: Node): string => {
  switch (node.type) {
    case NodeType.Section:
      return "section";
    case NodeType.InputText:
      return "input text";
    case NodeType.Select:
      if (node.radio) {
        return "single-select";
      } else {
        return "multi-select";
      }
    case NodeType.Upload:
      return "file upload";
    case NodeType.Risk:
      return "risk";
    case NodeType.Info:
      return "info";
  }
};

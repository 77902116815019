import { sortBy as _sortBy } from "lodash";
import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RESET_STATE,
  SET_USER_DATA,
  SET_USER_ORG_LOADING,
  SET_CURRENT_ORG_NAME,
  SET_CR_AUTH_INFO,
  SET_CR_AUTH,
  SET_CR_ENDPOINT,
  SET_HANNAH_ENV,
  SET_MODAL_DATA,
  SET_ORG_INFO,
  SET_WHISPER,
  SET_INDUSTRIES,
  SET_CLOUDSCAN_DATA,
  SET_SURVEY_DATA,
  SET_PUBLIC_SURVEY_DATA,
  SET_REMEDIATION_REQUEST_DATA,
  SET_VIEWING_VENDORID,
  SET_SHARED_ASSESSMENT_DATA,
  SET_SHARED_ASSESSMENTS_LIST,
  SET_EXPORT_ITEMS,
  SET_EXPORT_ITEM_POLLING,
  SET_SINGLE_EXPORT_ITEM,
  SET_EXPORT_ITEMS_ARCHIVED,
  SET_SINGLE_EXPORT_ITEM_ARCHIVED,
  SET_VIEWING_MANAGED_VENDOR_FOR_ORG,
  SET_TPVM_SESSION,
  SET_CR_TPVM_AUTH,
  SET_SCHEDULED_EXPORT_ITEMS,
  ADD_ACTIVITY_STREAM_PAGE,
  SET_ACTIVITY_STREAM_LOADING,
  SET_ACTIVITY_STREAM_ACTION_RUNNING,
  REMOVE_FEATURE_RELEASE_FROM_ACTIVITY_STREAM,
  REMOVE_EVENT_FROM_ACTIVITY_STREAM,
  ADD_EVENT_TO_IMPORTANT_LIST_FOR_ACTIVITY_STREAM,
  ADD_EVENT_TO_ACTIVITY_STREAM,
  REMOVE_EVENT_FROM_IMPORTANT_LIST_FOR_ACTIVITY_STREAM,
  REFRESH_ACTIVITY_STREAM,
  SET_ACTIVITY_STREAM_BACKGROUND_REFRESH_RUNNING,
  SET_USER_ACTIVITYSTREAM_SETTINGS,
  DECREMENT_ACTIVITY_STREAM_TASKCOUNT,
  SET_ACTIVITY_STREAM_RELOAD_RUNNING,
  SET_ACTIVITY_STREAM_EVENT_TOUCHED,
  SET_NAMED_ROLES,
  SET_PROJECTION_FOR_REMEDIATION,
  SET_PROJECTION_FOR_SURVEY,
  SET_PROJECTION_REQUEST_TIMESTAMP,
  SET_SECURITY_FRAMEWORK_STRUCTURE,
  SET_SECURITY_FRAMEWORK_COVERAGE,
  CLEAR_REMEDIATION_REQUEST_DATA,
  CLEAR_SURVEY_DATA,
  DELETE_REMEDIATION_REQUESTS,
  SET_FREETRIAL_ELIGIBILITY,
  SET_FREETRIAL_ONBOARDING_CALENDAR,
  SET_FREETRIAL_ONBOARDING_BOOKED,
  REMOVE_PROMOTION_FROM_ACTIVITY_STREAM,
  SET_ASSESSMENT_SURVEY_DATA,
  SET_ASSESSMENT_PUBLIC_SURVEY_DATA,
  UPDATE_EXPORT_ITEMS,
  RENAME_EXPORT_ITEMS,
} from "./commonActions";

import { getLocalStorageItem } from "../session.js";
import {
  defaultFilter,
  SET_BREACH_NEWS_ARTICLES,
  SET_BREACH_NEWS_AVAILABLE_FILTERS,
  SET_BREACH_NEWS_FILTER,
  SET_BREACH_NEWS_PAGE,
  SET_BREACH_NEWS_SORT_COL,
  SET_BREACH_NEWS_SORT_DIR,
} from "./breachNewsFeed.actions";
import {
  PREVIEW_BANNER,
  ADD_MESSAGE_ALERT,
  CLEAR_ALL_MESSAGE_ALERTS,
  CLEAR_MESSAGE_ALERT,
  REMOVE_BANNER_STATE,
} from "./messageAlerts.actions";
import { SET_MANAGED_VENDOR_META_QUESTIONS } from "../../vendorrisk/reducers/managedVendors.actions";
import {
  SET_BATCHED_ACTIVITY_STREAM_EVENT_EXPANDED,
  SET_BATCHED_ACTIVITY_STREAM_EVENTS_FOR_EVENT,
} from "./activityStream.actions";
import {
  commonInitialState,
  getHydratedCommonInitialState,
} from "./commonReducer.initialState";
import { arrayToPermissionSet } from "../permissions";
import { SET_USER_TASK_DATA } from "./userTaskActions";

const Task_StreamEvent_Type = 3;

const hydratedInitialState = getHydratedCommonInitialState("AUTH0");

/**
 * @param state {ICommonState}
 * @param action {object}
 * */
function commonReducer(state = hydratedInitialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...commonInitialState,
        authMode: state.authMode,
        cyberRiskAuth: { loading: true },
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.user,
        permissions: {
          user: arrayToPermissionSet(action.user.userPermissions),
          org: arrayToPermissionSet(action.user.orgPermissions),
        },
        banner: action.user.banner,
      };
    case SET_USER_ORG_LOADING:
      return {
        ...state,
        orgLoading: action.loading,
      };
    case SET_CURRENT_ORG_NAME: {
      const userData = { ...state.userData };
      for (let i = 0; i < userData.orgList.length; i++) {
        if (userData.orgList[i].id === userData.currentOrgID) {
          userData.orgList[i].name = action.orgName;
          break;
        }
      }
      return {
        ...state,
        userData,
      };
    }
    case SET_CR_AUTH:
      if (action.error) {
        return {
          ...state,
          cyberRiskAuth: {
            ...state.cyberRiskAuth,
            loading: false,
            error: action.error,
          },
        };
      }
      return {
        ...state,
        cyberRiskAuth: {
          ...state.cyberRiskAuth,
          loading: false,
          ...action.auth,
          error: false,
        },
      };
    case SET_ORG_INFO:
      return {
        ...state,
        orgInfo: action.info,
      };
    case SET_CR_AUTH_INFO:
      return {
        ...state,
        authInfo: action.info,
      };
    case SET_CR_ENDPOINT:
      return {
        ...state,
        cyberRiskEndpoint: action.endpoint,
      };
    case SET_HANNAH_ENV:
      return {
        ...state,
        hannahEnv: action.env,
      };
    case SET_WHISPER:
      return {
        ...state,
        whisper: {
          ...state.whisper,
          ...action.data,
        },
      };
    case OPEN_MODAL:
      return {
        ...state,
        openModal: action.name,
        modalData: {
          ...state.modalData,
          ...action.data,
          isV2Layout: action.isV2Layout,
          className: action.className,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        openModal: null,
        modalData: {},
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [action.key]: action.data,
        },
      };
    case SET_INDUSTRIES:
      return {
        ...state,
        industries: action.industries,
      };

    case SET_CLOUDSCAN_DATA:
      return {
        ...state,
        webscans: {
          ...state.webscans,
          [action.webscanId]: {
            ...state.webscans[action.webscanId],
            ...action.data,
          },
        },
      };
    case SET_SURVEY_DATA:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            ...action.data,
          },
        },
      };
    case SET_ASSESSMENT_SURVEY_DATA:
      const existingSurveys =
        state.assessmentSurveys[action.assessmentId] || {};
      const existingSurveyData = existingSurveys[action.surveyId] || {};

      return {
        ...state,
        assessmentSurveys: {
          ...state.assessmentSurveys,
          [action.assessmentId]: {
            ...existingSurveys,
            [action.surveyId]: {
              ...existingSurveyData,
              ...action.data,
            },
          },
        },
      };
    case CLEAR_SURVEY_DATA:
      if (action.surveyId) {
        const newSurveys = { ...state.surveys };
        delete newSurveys[action.surveyId];
        return {
          ...state,
          surveys: newSurveys,
        };
      }
      return {
        ...state,
        surveys: {},
      };
    case SET_PUBLIC_SURVEY_DATA:
      return {
        ...state,
        prefilledSurveys: {
          ...state.prefilledSurveys,
          [action.surveyId]: {
            ...state.prefilledSurveys[action.surveyId],
            ...action.data,
          },
        },
      };
    case SET_ASSESSMENT_PUBLIC_SURVEY_DATA:
      const existingPublicSurveys =
        state.assessmentPrefilledSurveys[action.assessmentId] || {};
      const existingPublicSurveyData =
        existingPublicSurveys[action.surveyId] || {};

      return {
        ...state,
        assessmentPrefilledSurveys: {
          ...state.assessmentPrefilledSurveys,
          [action.assessmentId]: {
            ...existingPublicSurveys,
            [action.surveyId]: {
              ...existingPublicSurveyData,
              ...action.data,
            },
          },
        },
      };
    case SET_SHARED_ASSESSMENT_DATA:
      return {
        ...state,
        sharedAssessments: {
          ...state.sharedAssessments,
          [action.vendorId]: {
            ...state.sharedAssessments[action.vendorId],
            ...action.data,
          },
        },
      };
    case SET_SHARED_ASSESSMENTS_LIST:
      return {
        ...state,
        sharedAssessmentsList: action.sharedAssessmentsList,
      };
    case SET_REMEDIATION_REQUEST_DATA:
      return {
        ...state,
        remediationRequests: {
          ...state.remediationRequests,
          [action.remediationRequestId]: {
            ...state.remediationRequests[action.remediationRequestId],
            ...action.data,
          },
        },
      };
    case DELETE_REMEDIATION_REQUESTS: {
      const newRemediationRequests = { ...state.remediationRequests };
      for (let i = 0; i < action.remediationRequestIds.length; i++) {
        delete newRemediationRequests[action.remediationRequestIds[i]];
      }
      return {
        ...state,
        remediationRequests: newRemediationRequests,
      };
    }
    case CLEAR_REMEDIATION_REQUEST_DATA:
      return {
        ...state,
        remediationRequests: {},
      };
    case SET_VIEWING_VENDORID:
      return {
        ...state,
        viewingVendorId: action.vendorId,
      };
    case UPDATE_EXPORT_ITEMS: {
      let allItems = action.isArchivedView
        ? [...state.exportItemsArchived.data]
        : [...state.exportItems.data];
      for (let i = 0; i < allItems.length; i++) {
        const id = allItems[i].id;
        if (action.items.some((o2) => o2.id === id)) {
          allItems[i].read = true;
        }
      }

      if (action.isArchivedView) {
        return {
          ...state,
          exportItemsArchived: {
            ...state.exportItemsArchived,
            data: allItems,
          },
        };
      }
      return {
        ...state,
        exportItems: {
          ...state.exportItems,
          data: allItems,
        },
      };
    }
    case RENAME_EXPORT_ITEMS:
      if (action.isArchivedView) {
        return {
          ...state,
          exportItemsArchived: {
            ...state.exportItemsArchived,
            data: action.items.data,
          },
        };
      }
      return {
        ...state,
        exportItems: {
          ...state.exportItems,
          data: action.items.data,
        },
      };
    case SET_EXPORT_ITEMS:
      return {
        ...state,
        exportItems: {
          data: action.loading ? state.exportItems.data : action.items,
          loading: action.loading,
        },
      };
    case SET_SINGLE_EXPORT_ITEM: {
      let newItems;
      newItems = [...state.exportItems.data];
      for (let i = 0; i < newItems.length; i++) {
        if (newItems[i].id === action.item.id) {
          newItems[i] = action.item;
        }
      }

      return {
        ...state,
        exportItems: {
          ...state.exportItems,
          data: newItems,
        },
      };
    }
    case SET_EXPORT_ITEM_POLLING: {
      if (action.polling) {
        if (state.exportItemsPolling.indexOf(action.id) === -1) {
          return {
            ...state,
            exportItemsPolling: [...state.exportItemsPolling, action.id],
          };
        } else {
          return state;
        }
      } else {
        const idx = state.exportItemsPolling.indexOf(action.id);
        if (idx === -1) {
          return state;
        } else {
          const pollingItems = [...state.exportItemsPolling];
          pollingItems.splice(idx, 1);
          return {
            ...state,
            exportItemsPolling: pollingItems,
          };
        }
      }
    }
    case SET_EXPORT_ITEMS_ARCHIVED:
      return {
        ...state,
        exportItemsArchived: {
          data: action.loading ? state.exportItemsArchived.data : action.items,
          loading: action.loading,
        },
      };
    case SET_SINGLE_EXPORT_ITEM_ARCHIVED: {
      let newArchivedItems;
      newArchivedItems = [...state.exportItemsArchived.data];
      for (let i = 0; i < newArchivedItems.length; i++) {
        if (newArchivedItems[i].id === action.item.id) {
          newArchivedItems[i] = action.item;
        }
      }

      return {
        ...state,
        exportItemsArchived: {
          ...state.exportItemsArchived,
          data: newArchivedItems,
        },
      };
    }
    case SET_SCHEDULED_EXPORT_ITEMS:
      const { scheduledExports, loading } = action;

      return {
        ...state,
        scheduledExports: {
          ...state.scheduledExports,
          loading: loading,
          data: loading ? state.scheduledExports.data : scheduledExports,
        },
      };

    case SET_VIEWING_MANAGED_VENDOR_FOR_ORG:
      return {
        ...state,
        viewingManagedVendorForOrgId: {
          vendorId: action.vendorId,
          orgId: action.orgId,
        },
      };
    case SET_TPVM_SESSION:
      return {
        ...state,
        tpvmSession: action.session,
      };
    case SET_CR_TPVM_AUTH:
      if (action.error) {
        return {
          ...state,
          tpvmAuth: {
            ...state.tpvmAuth,
            [action.orgId]: {
              loading: false,
              error: action.error,
            },
          },
        };
      }
      return {
        ...state,
        tpvmAuth: {
          ...state.tpvmAuth,
          [action.orgId]: {
            loading: false,
            ...(action.auth ? action.auth : {}),
            error: false,
          },
        },
      };

    case SET_ACTIVITY_STREAM_LOADING:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          loading: true,
          error: null,
        },
      };

    case SET_ACTIVITY_STREAM_BACKGROUND_REFRESH_RUNNING:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          backgroundRefreshing: action.running,
          foregroundUpdate: false,
        },
      };

    case SET_ACTIVITY_STREAM_RELOAD_RUNNING:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          reloading: action.running,
          foregroundUpdate: false,
        },
      };

    case SET_ACTIVITY_STREAM_ACTION_RUNNING:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          actionRunning: action.running,
        },
      };

    case ADD_ACTIVITY_STREAM_PAGE:
      if (action.error) {
        return {
          ...state,
          activityStream: {
            ...state.activityStream,
            loading: false,
            error: action.error,
          },
        };
      }

      let currentPage = state.activityStream ? state.activityStream.page : 0;
      let complete = state.activityStream
        ? state.activityStream.complete
        : false;

      // only update if new page is contiguous with end of existing data, and we are expecting more data
      if ((action.page != 0 && action.page != currentPage + 1) || complete) {
        return {
          ...state,
        };
      }

      // on error, don't touch anything. just store the error
      if (action.error != null) {
        return {
          ...state,
          activityStream: {
            ...state.activityStream,
            error: action.error,
          },
        };
      }

      //
      // page 0 is the first page - just blat that shit in. note that only page 0 supplies
      // the user's task list, important events list, and the release event
      //

      if (action.page == 0) {
        const importantTasks = action.importantEvents
          ? action.importantEvents.filter((evt) => {
              return evt.type === Task_StreamEvent_Type;
            })
          : [];
        const numTasks =
          importantTasks.length + (action.tasks ? action.tasks.length : 0);

        //
        // Important note: the tasks list is merged into the events list at this stage
        //

        return {
          ...state,
          activityStream: {
            loading: false,
            error: null,
            numTasks: numTasks,
            events: _sortBy(
              [
                ...(action.events ? action.events : []),
                ...(action.tasks
                  ? action.tasks.filter((t) => {
                      return (
                        t.when <= action.pageStartDate ||
                        (action.page != 0 && t.when > action.pageEndDate)
                      );
                    })
                  : []),
              ],
              ["when"]
            ).reverse(),
            tasks: action.tasks ? action.tasks : [],
            page: action.page,
            complete: action.complete,
            releaseEvent: action.releaseEvent,
            promotionEvent: action.promotionEvent,
            importantEvents: action.importantEvents
              ? action.importantEvents
              : [],
            userLastViewed: action.userLastViewed,
            streamStartDate: action.pageStartDate,
            streamEndDate: action.pageEndDate,
            foregroundUpdate: true,
          },
        };
      }

      //
      // not the first page - so we need to append it to the existing page(s)
      // additionally, we need to merge in any tasks that fall between the current date ranges for this new page
      //

      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          loading: false,
          error: null,
          events: _sortBy(
            [
              ...state.activityStream.events,
              ...(action.events ? action.events : []),
            ],
            ["when"]
          ).reverse(),
          page: action.page,
          complete: action.complete,
          streamEndDate: action.pageEndDate,
          foregroundUpdate: true,
        },
      };

    case REFRESH_ACTIVITY_STREAM:
      // only update if new data has the same endDate as is currently in state
      if (
        state.activityStream.loading ||
        state.activityStream.error ||
        action.pageEndDate != state.activityStream.streamEndDate ||
        state.activityStream.foregroundUpdate === true
      ) {
        return {
          ...state,
          activityStream: {
            ...state.activityStream,
            backgroundRefreshing: false,
            foregroundUpdate: false,
            reloading: false,
          },
        };
      }

      const importantTasks = action.importantEvents
        ? action.importantEvents.filter((evt) => {
            return evt.type === Task_StreamEvent_Type;
          })
        : [];
      const tasks = action.tasks ? action.tasks : [];
      const numTasks = importantTasks.length + tasks.length;

      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          loading: false,
          error: null,
          numTasks: numTasks,
          events: _sortBy(
            [
              ...(action.events ? action.events : []),
              ...tasks.filter((t) => {
                return (
                  t.when <= action.pageStartDate ||
                  (action.page != 0 && t.when > action.pageEndDate)
                );
              }),
            ],
            ["when"]
          ).reverse(),
          tasks: tasks,
          releaseEvent: action.releaseEvent,
          promotionEvent: action.promotionEvent,
          importantEvents: action.importantEvents ? action.importantEvents : [],
          streamStartDate: action.pageStartDate,
          backgroundRefreshing: false,
          foregroundUpdate: false,
          reloading: false,
        },
      };

    case REMOVE_FEATURE_RELEASE_FROM_ACTIVITY_STREAM:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          releaseEvent: undefined,
          foregroundUpdate: true,
        },
      };

    case REMOVE_PROMOTION_FROM_ACTIVITY_STREAM:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          promotionEvent: undefined,
          foregroundUpdate: true,
        },
      };

    case REMOVE_EVENT_FROM_ACTIVITY_STREAM:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          foregroundUpdate: true,
          tasks: state.activityStream.tasks.filter((task) => {
            return task.uuid != action.uuid;
          }),
          events: state.activityStream.events.filter((evt) => {
            return evt.uuid != action.uuid;
          }),
        },
      };

    case ADD_EVENT_TO_IMPORTANT_LIST_FOR_ACTIVITY_STREAM:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          foregroundUpdate: true,
          importantEvents: _sortBy(
            [...state.activityStream.importantEvents, action.event],
            ["when"]
          ).reverse(),
        },
      };

    case ADD_EVENT_TO_ACTIVITY_STREAM:
      // only add to activity stream (events) if event is within scope of current pages
      let taskToAdd = undefined;
      let eventToAdd = undefined;
      if (action.event.when >= state.activityStream.streamEndDate) {
        eventToAdd = action.event;
      }
      if (action.event.type == Task_StreamEvent_Type) {
        taskToAdd = action.event;
      }
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          foregroundUpdate: true,
          tasks: taskToAdd
            ? _sortBy(
                [...state.activityStream.tasks, taskToAdd],
                ["when"]
              ).reverse()
            : state.activityStream.tasks,
          events: eventToAdd
            ? _sortBy(
                [...state.activityStream.events, eventToAdd],
                ["when"]
              ).reverse()
            : state.activityStream.events,
        },
      };

    case REMOVE_EVENT_FROM_IMPORTANT_LIST_FOR_ACTIVITY_STREAM:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          foregroundUpdate: true,
          importantEvents: state.activityStream.importantEvents.filter(
            (evt) => {
              return evt.uuid != action.uuid;
            }
          ),
        },
      };

    case SET_USER_ACTIVITYSTREAM_SETTINGS:
      return {
        ...state,
        userActivityStreamSettings: action.settings,
      };
    case SET_BREACH_NEWS_ARTICLES:
      return {
        ...state,
        breachNewsFeed: {
          ...state.breachNewsFeed,
          articles: action.articles,
        },
      };
    case SET_BREACH_NEWS_FILTER:
      return {
        ...state,
        breachNewsFeed: {
          ...state.breachNewsFeed,
          filter: action.filter,
        },
      };
    case SET_BREACH_NEWS_SORT_DIR:
      return {
        ...state,
        breachNewsFeed: {
          ...state.breachNewsFeed,
          sortDir: action.sortDir,
        },
      };
    case SET_BREACH_NEWS_SORT_COL:
      return {
        ...state,
        breachNewsFeed: {
          ...state.breachNewsFeed,
          sortCol: action.sortCol,
        },
      };
    case SET_BREACH_NEWS_AVAILABLE_FILTERS:
      return {
        ...state,
        breachNewsFeed: {
          ...state.breachNewsFeed,
          availableFilters: action.filters,
        },
      };
    case SET_BREACH_NEWS_PAGE:
      return {
        ...state,
        breachNewsFeed: {
          ...state.breachNewsFeed,
          currentPage: action.page,
        },
      };
    case DECREMENT_ACTIVITY_STREAM_TASKCOUNT:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          numTasks: state.activityStream.numTasks - 1,
        },
      };
    case SET_ACTIVITY_STREAM_EVENT_TOUCHED:
      return {
        ...state,
        activityStream: {
          ...state.activityStream,
          events: state.activityStream.events.map((e) => {
            if (e.uuid == action.uuid) {
              e.touched = true;
            }
            return e;
          }),
          tasks: state.activityStream.tasks.map((t) => {
            if (t.uuid == action.uuid) {
              t.touched = true;
            }
            return t;
          }),
        },
      };
    case SET_NAMED_ROLES:
      return {
        ...state,
        namedRoles: action.namedRoles,
      };

    case ADD_MESSAGE_ALERT:
      return {
        ...state,
        messageAlerts: [...state.messageAlerts, action.alert],
      };
    case PREVIEW_BANNER:
      return {
        ...state,
        bannerPreview: action.banner,
      };
    case REMOVE_BANNER_STATE:
      return {
        ...state,
        bannerPreview: undefined,
        banner: undefined,
      };

    case CLEAR_MESSAGE_ALERT:
      return {
        ...state,
        messageAlerts: state.messageAlerts.filter(
          (a) => a.id !== action.alertId
        ),
      };

    case CLEAR_ALL_MESSAGE_ALERTS:
      return {
        ...state,
        messageAlerts: state.messageAlerts.filter(
          (a) => !!a.noDismissOnClearAll
        ),
      };

    case SET_PROJECTION_FOR_REMEDIATION: {
      return {
        ...state,
        remediationRequests: {
          ...state.remediationRequests,
          [action.requestId]: {
            ...state.remediationRequests[action.requestId],
            projection: {
              loading: action.loading,
              error: action.error,
              initialScore: action.initialScore,
              currentScore: action.currentScore,
              projectedScore: action.projectedScore,
            },
          },
        },
      };
    }
    case SET_PROJECTION_FOR_SURVEY:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            projection: {
              loading: action.loading,
              error: action.error,
              initialScore: action.initialScore,
              currentScore: action.currentScore,
              projectedScore: action.projectedScore,
            },
          },
        },
      };
    case SET_PROJECTION_REQUEST_TIMESTAMP:
      return {
        ...state,
        projection: {
          requestTimestamps: {
            ...state.projection.requestTimestamps,
            [action.key]: action.timestamp,
          },
        },
      };
    case SET_SECURITY_FRAMEWORK_STRUCTURE: {
      const existingFrameworkData = state.securityFrameworks[
        action.frameworkType
      ]
        ? state.securityFrameworks[action.frameworkType]
        : {};
      return {
        ...state,
        securityFrameworks: {
          ...state.securityFrameworks,
          [action.frameworkType]: {
            ...existingFrameworkData,
            loading: action.loading,
            error: action.error,
            structure: action.structure ? action.structure : null,
          },
        },
      };
    }
    case SET_SECURITY_FRAMEWORK_COVERAGE:
      const existingFrameworkData = state.securityFrameworks[
        action.frameworkType
      ]
        ? state.securityFrameworks[action.frameworkType]
        : {};
      const existingCoverageData = existingFrameworkData.coverage
        ? existingFrameworkData.coverage
        : {};
      return {
        ...state,
        securityFrameworks: {
          ...state.securityFrameworks,
          [action.frameworkType]: {
            ...existingFrameworkData,
            coverage: {
              ...existingCoverageData,
              [action.vendorId]: {
                loading: action.loading,
                error: action.error,
                data: action.coverage ? action.coverage : null,
              },
            },
          },
        },
      };
    case SET_FREETRIAL_ELIGIBILITY:
      return {
        ...state,
        freeTrialEligibility: {
          ...state.freeTrialEligibility,
          [action.userId]: {
            ...(state.freeTrialEligibility[action.userId]
              ? state.freeTrialEligibility[action.userId]
              : {}),
            [action.orgId]: action.eligibility,
          },
        },
      };
    case SET_FREETRIAL_ONBOARDING_CALENDAR:
      return {
        ...state,
        freeTrialEligibility: {
          ...state.freeTrialEligibility,
          [action.userId]: {
            ...(state.freeTrialEligibility[action.userId]
              ? state.freeTrialEligibility[action.userId]
              : {}),
            onboardingCalendar: action.calendar,
          },
        },
      };
    case SET_FREETRIAL_ONBOARDING_BOOKED:
      const userData = { ...state.userData };
      for (let i = 0; i < userData.orgList.length; i++) {
        if (userData.orgList[i].id === userData.currentOrgID) {
          userData.orgList[i].isOnboardingBooked = true;
          break;
        }
      }
      return {
        ...state,
        userData,
      };

    case SET_MANAGED_VENDOR_META_QUESTIONS:
      return {
        ...state,
        managedAssessmentMetaQuestions: action.meta,
      };

    case SET_BATCHED_ACTIVITY_STREAM_EVENTS_FOR_EVENT:
      return {
        ...state,
        activityStreamBatchedEvents: {
          ...state.activityStreamBatchedEvents,
          [action.eventId]: action.events,
        },
      };

    case SET_BATCHED_ACTIVITY_STREAM_EVENT_EXPANDED:
      return {
        ...state,
        activityStreamBatchedEventExpanded: {
          ...state.activityStreamBatchedEventExpanded,
          [action.eventId]: action.isExpanded,
        },
      };

    case SET_USER_TASK_DATA:
      return {
        ...state,
        userTaskData: action.userTaskData,
        userTaskDataInitialLoadComplete: true,
      };

    default:
      return state;
  }
}

export default commonReducer;

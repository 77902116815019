import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import "./AboutApp.scss";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../../vendorrisk/components/Score";
import AppVendorMonitored from "./AppVendorMonitored";

interface AboutAppProps {
  appName: string;
}

const AboutApp: FC<AboutAppProps> = ({ appName }) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  return (
    <ReportCard newStyles className="userbase-about-app">
      <div className="header">
        <span>About {appName}</span>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && data && (
        <div className={"content"}>
          <InfoTable>
            <InfoTableRow
              label="Primary domain"
              rowClass={"no-max-height"}
              value={data.app.domain ?? ""}
            />
            <InfoTableRow
              label="Summary"
              rowClass={"no-max-height"}
              value={data.app.summary ?? "-"}
            />
            <InfoTableRow
              label="Description"
              rowClass={"no-max-height"}
              value={data.app.description ?? "-"}
            />
            <InfoTableRow
              label="Category"
              rowClass={"no-max-height bottom-border"}
              value={data.app.category ?? "-"}
            />
            <InfoTableRow
              label="Vendor details"
              rowClass={"no-max-height section-header"}
              value={""}
            />
            <InfoTableRow
              label="Name"
              rowClass={"no-max-height"}
              value={data.app.name ?? ""}
            />
            <InfoTableRow
              label="Domain"
              rowClass={"no-max-height"}
              value={data.app.domain ?? ""}
            />
            <InfoTableRow
              label="Security rating"
              rowClass={"no-max-height"}
              value={
                <>
                  <ColorGrade
                    score={data.app.securityScore}
                    size={ColorGradeSize.Small}
                  />
                  <Score score={data.app.securityScore} outOf={950} small />
                </>
              }
            />
            <InfoTableRow
              label="Vendor Monitored"
              rowClass={"no-max-height"}
              value={
                <AppVendorMonitored
                  isMonitored={data.app.isVendorMonitored}
                  showLink={true}
                  vendorId={data.app.vendorId}
                />
              }
            />
          </InfoTable>
        </div>
      )}
    </ReportCard>
  );
};

export default AboutApp;

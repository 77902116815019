import "../style/SurveyViewer.scss";
import { useCallback, useEffect, useRef, useState } from "react";

import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import {
  closeRightPanelHeader,
  resetState,
  setActiveNode,
  setAddRiskModalState,
  setAnswers,
  setAnswersAndSwap,
  setCurrentCommentQuestion,
  setEditState,
  setLeftAnswers,
  setLockState,
  setNodeTree,
  setRightPanelState,
  setRiskAdjustments,
  setScrollNextUnansweredQuestion,
  setScrollTargetNode,
} from "../reducers/actions";
import SurveyViewerActionBar from "./SurveyViewerActionBar";
import { get as _get, throttle as _throttle } from "lodash";
import SurveyViewerContentScroller from "./SurveyViewerContentScroller";
import SurveyViewerControls from "./SurveyViewerControls";
import SurveyViewerSidebar from "./SurveyViewerSidebar";
import SurveyViewerIntro from "./SurveyViewerIntro";
import QuestionAnswerNodeWrapperConnected from "./nodes/QuestionAnswerNodeWrapper";
import {
  FlattenedNodeSummaryMap,
  getAnswersChanged,
  getAnswersForNodes,
  getNextUnansweredQuestion,
  getNodeSummaryTreeWithNodes,
  getNodeTreeFlattenedMap,
  getNodeVisibilities,
  NodeSummaryAndNode,
} from "../surveyViewer.helpers";
import { vsaqQuestion } from "../../survey_builder/vsaq/vsaqTypes";
import {
  Answers,
  fetchQuestionnaire,
} from "../../vendorrisk/reducers/questionnaireAnswers.actions";
import classnames from "classnames";
import classNames from "classnames";
import SurveyViewerExtraAttachments, {
  ExtraAttachmentsNodeId,
} from "./SurveyViewerExtraAttachments";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { SurveyLockState } from "../reducers/apiActions";
import SurveyViewerLockChecker from "./SurveyViewerLockChecker";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import tickIconSrc from "../../vendorrisk/images/circle_tick.svg";
import SurveyViewerSidebarScroller from "./SurveyViewerSidebarScroller";
import { NodeType } from "../../survey_builder/types/types";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import SurveyViewerTopMessageBar from "./SurveyViewerTopMessageBar";
import PageviewTracker from "../../vendorrisk/components/PageviewTracker";
import SurveyViewerRightPanel from "./SurveyViewerRightPanel";
import {
  ISurveyVersion,
  SurveyRiskVisibility,
  SurveyStatus,
} from "../../_common/types/survey";
import { ChangesViewFrame } from "../../vendorrisk/components/Changes";
import { trackEvent } from "../../_common/tracking";
import { DiffSide } from "./nodes/baseNode";
import infoIcon from "../../vendorrisk/images/circle_exclaim.svg";
import { useModalV2 } from "../../_common/components/ModalV2";
import {
  chatGPTAccessSelector,
  shouldDisplayChatGPT,
} from "../../_common/chatgpt";
import {
  fetchAutomationForSurveyType,
  fetchAutomationRecipe,
} from "../../vendorrisk/reducers/questionnaireAutomation.actions";
import { IAutomationRecipe } from "../../vendorrisk/types/automation";
import { surveyViewerRightPanelMode } from "../reducers/reducer";
import {
  FetchGptAutofillStatus,
  GptAutofillCacheStatus,
  pollGptAutofillSuggestions,
} from "../reducers/autofill.actions";
import { deleteAutomationTestResults } from "../../vendorrisk/reducers/cyberRiskActions";
import { appConnect, useAppSelector } from "../../_common/types/reduxHooks";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import SurveyAddRiskModal from "../../vendorrisk/components/modals/SurveyAddRiskModal";
import ManualRisksAPI from "../../vendorrisk/reducers/manualRisksAPI";
import { skipToken } from "@reduxjs/toolkit/query";
import { produce } from "immer";
import { IVendorRiskWaiver } from "../../_common/types/vendorRiskWaivers";
import AutofillModalV2 from "./modals/AutofillModalV2";
import AutofillProcessModal from "./modals/AutofillProcessModal";

export enum SurveyViewerType {
  // Preview-only mode: interactive but with no backend actions. Also, no file upload.
  Preview = "preview",
  // Edit mode: interactive and firing backend actions. (e.g. for recipients)
  Editing = "editing",
  // Review mode: interactive and firing backend actions. (e.g. for senders pre-send)
  Review = "review",
  // Read only mode: Not interactive. (e.g. for org users who are not a recipient)
  Viewing = "viewing",
  // Diff mode: compare two versions of survey answers
  Diff = "diff",
  // autofillLoading: autofill is processing, viewer is locked and show a message in the action bar
  AutofillLoading = "autofillLoading",
}

interface matchParams {}

interface SurveyViewerOwnProps
  extends DefaultRouteProps<matchParams, locationState> {
  surveyId?: number;
  vsaqQuestionStructure?: vsaqQuestion;
  initialAnswers?: Answers;
  initialLeftAnswers?: Answers;
  orgName?: string;
  logoUrl?: string;
  viewerType: SurveyViewerType;
  initialQuestionId?: string;
  onClose: (addedToSharedProfile?: boolean) => void;
  isPublicSurvey?: boolean;
  includeToc?: boolean;
  usageType?: SurveyUsageType;
  hasRequiredAttachments?: boolean;
  initialLockState?: SurveyLockState;
  previewTypeId?: string;
  previewSectionId?: string;
  vendorName?: string;
  vendorId?: number;
  vendorContacts?: IVendorContactResponse[];
  userMessage?: string;
  hasCommentWritePermission: boolean;
  isArchived?: boolean;
  initiallyShowComments?: boolean;
  isVendorPortal?: boolean;
  versions?: ISurveyVersion[];
  selectedVersion?: number;
  leftVersion?: number;
  redlinesEnabled?: boolean;
  surveyLatestVersion?: ISurveyVersion;
  automationUUID?: string;
  automationDraft?: boolean;
  automation?: IAutomationRecipe;
  automationList?: IAutomationRecipe[];
  riskVisibility: SurveyRiskVisibility;
  alwaysVisibleRiskIDs?: string[];
  surveyImportUUID?: string;
  managedAssessmentId?: number;
  existingManualRiskIds?: number[];
  riskAdjustments?: IVendorRiskWaiver[];
  isCollaborator?: boolean;
  surveyStatus?: SurveyStatus;
  isSystemSurvey?: boolean;
}

interface SurveyViewerConnectedProps {
  nodeTree?: NodeSummaryAndNode;
  rightPanelActive: boolean;
  numAnswersChanged: number;
  shouldDisplayChatGptFeatures?: boolean;
  gptCacheStatus?: GptAutofillCacheStatus;
  lockState: SurveyLockState;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
}

type SurveyViewerProps = SurveyViewerOwnProps &
  SurveyViewerConnectedProps &
  DefaultThunkDispatchProp;

// Main component for viewing a survey. Communicates changes with children via redux for performance.
// Expensive to render, so ensure this only renders when necessary.
const SurveyViewer = ({
  dispatch,
  surveyId,
  vsaqQuestionStructure: _vasqQuestionnaireStructure,
  nodeTree,
  initialAnswers,
  initialLeftAnswers,
  orgName = "UpGuard",
  logoUrl,
  viewerType: initialViewerType,
  initialQuestionId,
  onClose,
  isPublicSurvey = false,
  includeToc = true,
  usageType = SurveyUsageType.Security,
  hasRequiredAttachments = false,
  initialLockState = undefined,
  previewTypeId,
  previewSectionId,
  vendorName,
  vendorId,
  vendorContacts,
  userMessage,
  hasCommentWritePermission,
  isArchived,
  initiallyShowComments = false,
  isVendorPortal = false,
  versions,
  selectedVersion: initSelectedVersion,
  leftVersion,
  rightPanelActive,
  redlinesEnabled,
  numAnswersChanged,
  surveyLatestVersion,
  shouldDisplayChatGptFeatures = false,
  automationUUID,
  automation,
  automationList,
  automationDraft,
  gptCacheStatus,
  lockState,
  riskVisibility,
  alwaysVisibleRiskIDs,
  surveyImportUUID,
  managedAssessmentId,
  isManagementAnalystSession,
  managedOrgId,
  existingManualRiskIds: _existingManualRiskIds,
  riskAdjustments: riskAdjustments,
  isCollaborator = false,
  surveyStatus,
  isSystemSurvey = false,
}: SurveyViewerProps) => {
  // track an event when ever the viewer is opened in view mode
  useEffect(() => {
    if (initialViewerType == SurveyViewerType.Viewing) {
      trackEvent("QuestionnaireViewer_opened");
    }
  }, []);

  // On initial load, get the existing automation instances for the surveyType
  useEffect(() => {
    if (previewTypeId) {
      dispatch(fetchAutomationForSurveyType(parseInt(previewTypeId)));
    }
  }, [previewTypeId]);

  const [vsaqQuestionStructure, setVsaqQuestionStructure] = useState(
    _vasqQuestionnaireStructure
  );
  useEffect(() => {
    if (_vasqQuestionnaireStructure) {
      setVsaqQuestionStructure(_vasqQuestionnaireStructure);
    }
  }, [_vasqQuestionnaireStructure]);

  const [existingManualRiskIds, setExistingManualRiskIds] = useState(
    _existingManualRiskIds
  );
  useEffect(() => {
    if (_existingManualRiskIds) {
      setExistingManualRiskIds(_existingManualRiskIds);
    }
  }, [_existingManualRiskIds]);

  const onRiskAdded = (id: number) => {
    setExistingManualRiskIds((prev) =>
      produce(prev, (risks) => {
        if (risks) {
          risks.push(id);
        }
      })
    );
    if (surveyId && selectedVersion) {
      dispatch(fetchQuestionnaire(surveyId, false, selectedVersion, true));
    }
  };

  const onRiskRemoved = (id: number) => {
    setExistingManualRiskIds((prev) =>
      produce(prev, (risks) => {
        if (risks) {
          const idx = risks.indexOf(id);
          if (idx > -1) {
            risks.splice(idx);
          }
        }
      })
    );
    if (surveyId && selectedVersion) {
      dispatch(fetchQuestionnaire(surveyId, false, selectedVersion, true));
    }
  };

  // allow the testable automation rule to be set by the right hand, but initialise first
  const [selectedAutomationUUID, _setSelectedAutomationUUID] = useState(
    automationUUID || ""
  );
  const [selectedAutomation, setSelectedAutomation] = useState(automation);
  useEffect(() => {
    if (automationUUID && automationUUID != "all") {
      dispatch(fetchAutomationRecipe(automationUUID));
    }
    if (automationUUID) {
      dispatch(
        setRightPanelState(
          surveyViewerRightPanelMode.Automation,
          undefined,
          true
        )
      );
    }
  }, [automationUUID]);
  useEffect(() => {
    if (automation) {
      setSelectedAutomation(automation);
    } else {
      setSelectedAutomation(undefined);
    }
  }, [automation]);

  // cache our adjustments map
  useEffect(() => {
    if (riskAdjustments) {
      dispatch(setRiskAdjustments(riskAdjustments));
    }
  }, [riskAdjustments]);

  const [openAutofillV2Modal, autofillV2Modal] = useModalV2(AutofillModalV2);

  const { shouldDisplayChatGPT } = useAppSelector(chatGPTAccessSelector);

  const onOpenAutofillModal = useCallback(() => {
    if (shouldDisplayChatGPT) {
      openAutofillV2Modal({
        surveyID: surveyId ?? 0,
        isPublic: isPublicSurvey,
      });
    }
  }, [isPublicSurvey, surveyId, shouldDisplayChatGPT, openAutofillV2Modal]);

  const [toolsModalOpen, setToolsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(
    vsaqQuestionStructure === undefined
  );
  // if we have been passed a left version we need to wait for it to load
  const [leftLoading, setLeftLoading] = useState(!!leftVersion);
  const ref = useRef<HTMLDivElement>(null);

  const [flattenedNodeMap, setFlattenedNodeMap] =
    useState<FlattenedNodeSummaryMap>({});

  const [surveyIsAnalystWorkflow, setSurveyIsAnalystWorkflow] = useState(false);
  const [viewerType, setViewerType] = useState(initialViewerType);
  const [selectedVersion, setSelectedVersion] = useState(initSelectedVersion);
  const [selectedLeftVersion, setSelectedLeftVersion] = useState(
    leftVersion ?? 0
  );
  const [changedAnswersOnly, setChangedAnswersOnly] = useState(false);
  const selectedVersionIsLatest =
    versions?.findIndex((v) => v.answerId == selectedVersion) == 0;

  // the component is initialised in edit mode by default, even when the questionnaire data has not loaded yet,
  // so we want to listen to change in initialViewerType to make sure we update the viewerType state to the
  // correct value once the questionnaire data loaded
  useEffect(() => {
    setViewerType(initialViewerType);
  }, [initialViewerType]);

  // this loading state is for the contents of the viewer, ie for loading different answer sets and comparisons
  // used to show a loading state in the main content area after we've loaded the structure etc
  const [diffLoading, setDiffLoading] = useState(false);
  const [versionLoading, setVersionLoading] = useState(false);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    initialViewerType == SurveyViewerType.Diff
  );
  const setLeftSidebarCollapsed = (collapse: boolean) => {
    // if we are in diff mode and we open the table of contents we also want to close the right side bar
    if (viewerType == SurveyViewerType.Diff && !collapse) {
      dispatch(closeRightPanelHeader());
    }
    setSidebarCollapsed(collapse);
  };
  // if the right panel gets opened while we are in diff mode close the left panel
  useEffect(() => {
    if (rightPanelActive && viewerType == SurveyViewerType.Diff) {
      setLeftSidebarCollapsed(true);
    }
  }, [rightPanelActive, viewerType]);

  // if we have a '0' version id, or it hasn't been passed though make sure we find the correct version
  useEffect(() => {
    if (!selectedVersion && !!versions && versions.length > 0) {
      setSelectedVersion(versions[0].answerId);
    }

    if (!surveyId || !vsaqQuestionStructure) return;

    // if we got a selected version check if there is a previous version to fetch
    // so that we can populate the changes label on the sidebar
    // we only do this in viewing mode as diff mode is handled separately and the other modes
    // are not relevant
    if (
      viewerType === SurveyViewerType.Viewing &&
      selectedVersion &&
      !!versions &&
      versions.length > 0
    ) {
      const versionIdx = versions?.findIndex(
        (v) => v.answerId === selectedVersion
      );

      if (versions[versionIdx + 1] && versions[versionIdx + 1].answerId) {
        // there is a valid previous version so load it
        const answersToLoad = versions[versionIdx + 1].answerId;

        dispatch(fetchQuestionnaire(surveyId, false, answersToLoad))
          .then((survey) => {
            const answersForNodes = getAnswersForNodes(
              survey.answers,
              vsaqQuestionStructure
            );
            dispatch(setLeftAnswers(answersForNodes));
            setSelectedLeftVersion(answersToLoad);
            setSurveyIsAnalystWorkflow(!!survey.managedAssessmentId);
          })
          .catch(() => {
            // reset the state, but we let the user continue even in case of an error. The worst that
            // can happen if this errored is that the sidebar does not show the changes labels so not
            // worth interrupting the flow
            setSelectedLeftVersion(0);
            dispatch(setLeftAnswers({}));
          });
      } else {
        // there is no version so make sure we reset the left answers state
        setSelectedLeftVersion(0);
        dispatch(setLeftAnswers({}));
      }
    }
  }, [selectedVersion, versions, surveyId, vsaqQuestionStructure, viewerType]);

  // allows selecting a new version for viewing only
  const onSelectVersion = useCallback(
    (answerId: number) => {
      if (surveyId && vsaqQuestionStructure) {
        setVersionLoading(true);
        dispatch(fetchQuestionnaire(surveyId, false, answerId))
          .then((survey) => {
            // the structure may have changed due to manual risks
            setVsaqQuestionStructure(survey.structure[0]);
            setExistingManualRiskIds(survey.customRiskIDs);

            const answersForNodes = getAnswersForNodes(
              survey.answers,
              survey.structure[0]
            );
            dispatch(setAnswers(answersForNodes));
            setViewerType(SurveyViewerType.Viewing);
            setSelectedVersion(answerId);
          })
          .catch(() =>
            dispatch(
              addDefaultUnknownErrorAlert("error getting previous version")
            )
          )
          .finally(() => setVersionLoading(false));
      }
    },
    [surveyId, vsaqQuestionStructure, existingManualRiskIds]
  );

  // callback for when the compare button is clicked,
  // ie the to transition from viewing/editing mode to compare mode
  // if we are looking at the latest version then we need to load the version before into the left
  // if we are looking at a previous version then we load it into the left and the current into the right
  const onCompareClick = useCallback(() => {
    const sharedVersions = versions?.filter((v) => v.shared);
    if (
      vsaqQuestionStructure &&
      selectedVersion &&
      surveyId &&
      sharedVersions &&
      sharedVersions.length > 1
    ) {
      setDiffLoading(true);

      trackEvent("QuestionnaireCompareVersions");

      // find out current version, if it's not the latest then we need to load the latest
      const answersToLoad =
        sharedVersions[0].answerId == selectedVersion
          ? sharedVersions[1].answerId
          : sharedVersions[0].answerId;

      dispatch(fetchQuestionnaire(surveyId, false, answersToLoad))
        .then((survey) => {
          const answersForNodes = getAnswersForNodes(
            survey.answers,
            vsaqQuestionStructure
          );
          // if we have loaded the latest answers instead of previous ones we need to swap our answers around in redux
          if (sharedVersions[0].answerId == selectedVersion) {
            dispatch(setLeftAnswers(answersForNodes));
            setSelectedLeftVersion(answersToLoad);
          } else {
            dispatch(setAnswersAndSwap(answersForNodes));
            setSelectedVersion(answersToLoad);
            setSelectedLeftVersion(selectedVersion);
          }

          setViewerType(SurveyViewerType.Diff);
          setLeftSidebarCollapsed(true);
        })
        .catch(() => {
          dispatch(addDefaultUnknownErrorAlert("Error loading comparison"));
        })
        .finally(() => setDiffLoading(false));
    }
  }, [versions, surveyId, vsaqQuestionStructure, selectedVersion]);

  const onSelectDiffVersion = useCallback(
    (versionID: number, side: DiffSide) => {
      if (vsaqQuestionStructure && surveyId) {
        setVersionLoading(true);

        dispatch(fetchQuestionnaire(surveyId, false, versionID))
          .then((survey) => {
            const answersForNodes = getAnswersForNodes(
              survey.answers,
              vsaqQuestionStructure
            );
            if (side == "left") {
              dispatch(setLeftAnswers(answersForNodes));
              setSelectedLeftVersion(versionID);
            } else {
              dispatch(setAnswers(answersForNodes));
              setSelectedVersion(versionID);
            }
          })
          .catch(() =>
            dispatch(
              addDefaultUnknownErrorAlert(
                "Error loading answers for comparison"
              )
            )
          )
          .finally(() => setVersionLoading(false));
      }
    },
    [surveyId, vsaqQuestionStructure]
  );

  const exitDiff = () => {
    dispatch(setLeftAnswers({}));
    setViewerType(SurveyViewerType.Viewing);
    setChangedAnswersOnly(false);
    setLeftSidebarCollapsed(false);
    setSelectedLeftVersion(0);
  };

  useEffect(() => {
    // Reset the survey viewer state whenever we navigate away from this view.
    return () => {
      dispatch(resetState());
    };
  }, []);

  // Once passed the vsaqStructure, convert it into the structure used by the survey viewer
  useEffect(() => {
    if (vsaqQuestionStructure) {
      setIsLoading(false);
      const asTree = getNodeSummaryTreeWithNodes(
        surveyId?.toString() ?? "",
        vsaqQuestionStructure
      );
      dispatch(setNodeTree(asTree));
      dispatch(setActiveNode(asTree.nodeId));

      if (initialQuestionId !== undefined) {
        // Scroll to node after loading, and load comments
        setTimeout(() => {
          dispatch(setScrollTargetNode(initialQuestionId, true));
        }, 750);

        // Initially show comment panel if requested
        if (initiallyShowComments) {
          dispatch(setCurrentCommentQuestion(initialQuestionId, true));
        }
      }
    }
  }, [vsaqQuestionStructure, initialQuestionId]);

  // If passed an answer set, set the answers in redux
  useEffect(() => {
    const isAnalystQuestionnaire = !!managedAssessmentId;
    if (initialAnswers && vsaqQuestionStructure) {
      const initialAnswersForNodes = getAnswersForNodes(
        initialAnswers,
        vsaqQuestionStructure
      );
      dispatch(setAnswers(initialAnswersForNodes));
      dispatch(setEditState(false));

      if (
        (viewerType === SurveyViewerType.Editing ||
          viewerType === SurveyViewerType.Review) &&
        initialQuestionId === undefined &&
        Object.keys(initialAnswersForNodes).length > 0 &&
        !isAnalystQuestionnaire
      ) {
        // Questionnaire is partially answered, so skip intro and scroll to next unanswered question

        const asTree = getNodeSummaryTreeWithNodes(
          surveyId?.toString() ?? "",
          vsaqQuestionStructure
        );

        const visibilities = getNodeVisibilities(
          asTree,
          initialAnswersForNodes
        );

        const nextQuestionNodeId = getNextUnansweredQuestion(
          asTree,
          initialAnswersForNodes,
          visibilities
        );

        if (nextQuestionNodeId) {
          // Scroll to node after loading
          setTimeout(() => {
            dispatch(setScrollTargetNode(nextQuestionNodeId, true));
          }, 250);
        }
      }
    }
  }, [initialAnswers, vsaqQuestionStructure]);

  // if we have been passed in a left version load the answers for it into redux as well
  useEffect(() => {
    if (vsaqQuestionStructure && initialLeftAnswers) {
      const initialLeftAnswersForNodes = getAnswersForNodes(
        initialLeftAnswers,
        vsaqQuestionStructure
      );
      dispatch(setLeftAnswers(initialLeftAnswersForNodes));
      setLeftLoading(false);
    }
  }, [initialLeftAnswers, vsaqQuestionStructure]);

  // If passed in a lockState, check if we need to lock the questionnaire
  useEffect(() => {
    if (
      initialLockState &&
      (viewerType === SurveyViewerType.Editing ||
        viewerType === SurveyViewerType.Review)
    ) {
      dispatch(setLockState(initialLockState));
      if (initialLockState.isLocked) {
        dispatch(
          addDefaultWarningAlert(
            `This questionnaire is currently locked by ${initialLockState.lockedBy}.`,
            ["It will become available after they are inactive for 5 minutes"]
          )
        );
      }
    }
  }, [initialLockState]);

  const setSelectedAutomationUUID = async (uuid: string) => {
    // Also make sure we have loaded the org flags
    _setSelectedAutomationUUID(uuid);
    setSelectedAutomation(undefined);
    dispatch(deleteAutomationTestResults(previewTypeId, surveyId));
    if (uuid != "all") {
      await dispatch(fetchAutomationRecipe(uuid))
        .then((a) => {
          setSelectedAutomation(a);
          dispatch(
            setRightPanelState(
              surveyViewerRightPanelMode.Automation,
              undefined,
              true
            )
          );
        })
        .catch((e) => {
          dispatch(
            addDefaultUnknownErrorAlert(
              `Error retrieving automation definition: ${e}`
            )
          );
        });
    }
  };

  // Once nodeTree is set, build a flattened map of it for use in some places
  useEffect(() => {
    if (nodeTree) {
      const nodesFlattened = getNodeTreeFlattenedMap(nodeTree);

      if (hasRequiredAttachments) {
        nodesFlattened[ExtraAttachmentsNodeId] = {
          nodeId: ExtraAttachmentsNodeId,
          node: {
            type: NodeType.Upload,
          },
        } as any as NodeSummaryAndNode;
      }

      setFlattenedNodeMap(nodesFlattened);
    } else {
      setFlattenedNodeMap({});
    }
  }, [nodeTree, hasRequiredAttachments]);

  // Throttle setting the active node due to scroll action
  const throttledSetActiveNode = useCallback(
    _throttle((scrollPos: number) => {
      const allQuestions = ref.current?.querySelectorAll(
        ".question-answer-node:not(.risk-node)"
      );
      let foundNodeId = "";
      if (allQuestions && allQuestions.length > 0) {
        foundNodeId = allQuestions[0].getAttribute("data-node-id") ?? "";
      }
      if (allQuestions) {
        for (const q of allQuestions) {
          if ((q as any).offsetTop > scrollPos + 200) {
            break;
          }
          foundNodeId = q.getAttribute("data-node-id") ?? "";
        }
      }

      if (foundNodeId) {
        dispatch(setActiveNode(foundNodeId));
      } else if (allQuestions && allQuestions.length > 0) {
        foundNodeId =
          allQuestions[allQuestions.length - 1].getAttribute("data-node-id") ??
          "";
        dispatch(setActiveNode(foundNodeId));
      }
    }, 250),
    []
  );

  const setAnswersFromUpload = useCallback(
    (answers: Answers) => {
      if (vsaqQuestionStructure) {
        const answersForNodes = getAnswersForNodes(
          answers,
          vsaqQuestionStructure
        );

        let isChanged = true;
        if (!!initialAnswers) {
          // Check if the initial and uploaded answers are different so we can alert
          // the user on submit if nothing has changed
          const initialAnswersForNodes = getAnswersForNodes(
            initialAnswers,
            vsaqQuestionStructure
          );
          const initialVisibilities = getNodeVisibilities(
            nodeTree,
            initialAnswersForNodes
          );

          const visibilities = getNodeVisibilities(nodeTree, answersForNodes);

          const answersChanged = getAnswersChanged(
            nodeTree,
            initialAnswersForNodes,
            answersForNodes,
            initialVisibilities,
            visibilities
          );

          if (answersChanged.totalChanged === 0) {
            isChanged = false;
          }
        }

        dispatch(setEditState(isChanged));
        dispatch(setAnswers(answersForNodes));
        // TODO - we probably want to show some sort of message here?
      }
    },
    [vsaqQuestionStructure]
  );

  // chatGPT autofill lifecycle control
  useEffect(() => {
    if (
      shouldDisplayChatGptFeatures &&
      surveyId &&
      (viewerType === SurveyViewerType.Editing ||
        viewerType === SurveyViewerType.Review) &&
      usageType == SurveyUsageType.Security &&
      !lockState.isLocked
    ) {
      dispatch(FetchGptAutofillStatus(surveyId, isPublicSurvey)).catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("error checking autofill status"));
      });
    }
  }, [
    dispatch,
    isPublicSurvey,
    lockState.isLocked,
    surveyId,
    usageType,
    shouldDisplayChatGptFeatures,
    viewerType,
  ]);

  // if we are loading gpt answers set the state appropriately, and poll for answers
  useEffect(() => {
    if (surveyId && gptCacheStatus?.jobStarted && !gptCacheStatus.jobFinished) {
      setViewerType(SurveyViewerType.AutofillLoading);
      const pollGptSuggestionsInterval = setInterval(() => {
        dispatch(pollGptAutofillSuggestions(surveyId, isPublicSurvey)).catch(
          () =>
            dispatch(
              addDefaultUnknownErrorAlert("error polling for suggestions")
            )
        );
      }, 10000);
      return () => clearInterval(pollGptSuggestionsInterval);
    }
    return undefined;
  }, [surveyId, gptCacheStatus?.jobFinished, gptCacheStatus?.jobStarted]);

  const addRiskModalState = useAppSelector(
    (state) => state.surveyViewer.addRiskModalState
  );

  const { data: availableManualRisks } =
    ManualRisksAPI.useGetAllManualRisksQuery(
      viewerType == SurveyViewerType.Viewing ? undefined : skipToken
    );

  // Hey Karl! Put all hooks above this line, or you'll keep getting build failures!
  if (isLoading || !nodeTree || leftLoading) {
    return <LoadingBanner />;
  }

  const shouldShowComments =
    viewerType !== SurveyViewerType.Preview &&
    !isPublicSurvey &&
    !surveyImportUUID;
  const nodeDisplays: JSX.Element[] = [];

  const getNodeDisplay = (n: NodeSummaryAndNode) => {
    if (n.node.parentId) {
      nodeDisplays.push(
        <QuestionAnswerNodeWrapperConnected
          key={n.nodeId}
          nodeSummary={n}
          surveyId={surveyId}
          disabled={
            viewerType === SurveyViewerType.Viewing ||
            viewerType == SurveyViewerType.Diff ||
            viewerType == SurveyViewerType.AutofillLoading
          }
          isPublicSurvey={isPublicSurvey}
          showComments={shouldShowComments}
          isEditMode={
            viewerType == SurveyViewerType.Editing ||
            viewerType == SurveyViewerType.Preview ||
            viewerType === SurveyViewerType.Review
          }
          showDiff={viewerType == SurveyViewerType.Diff}
          changedOnly={changedAnswersOnly}
          riskVisibility={riskVisibility}
          alwaysVisibleRiskIDs={alwaysVisibleRiskIDs}
          usageType={usageType}
          canAddManualRisk={
            viewerType == SurveyViewerType.Viewing &&
            selectedVersionIsLatest &&
            !isArchived &&
            (surveyStatus == SurveyStatus.InReview ||
              surveyStatus == SurveyStatus.AwaitingReview)
          }
          availableManualRisks={availableManualRisks}
          onRiskRemoved={onRiskRemoved}
        />
      );
    }

    if (n.node.type === NodeType.Section && n.children) {
      n.children.forEach((n) => getNodeDisplay(n));
    }
  };

  getNodeDisplay(nodeTree);

  const classes = classnames("survey-viewer", {
    "hide-toc": !includeToc,
  });
  return (
    <div className={classNames("survey-viewer-container", "v2-autofill")}>
      {viewerType == SurveyViewerType.AutofillLoading && (
        <div className={"gpt-overlay"} />
      )}
      <SurveyViewerTopMessageBar
        viewerType={viewerType}
        isPublicSurvey={isPublicSurvey}
        isArchived={isArchived}
        isDraft={automationDraft}
        usageType={usageType}
        onGoBack={
          viewerType == SurveyViewerType.Diff &&
          initialViewerType != SurveyViewerType.Diff
            ? exitDiff
            : onClose
        }
        onSelectVersion={onSelectVersion}
        versions={versions}
        redlinesEnabled={!!redlinesEnabled}
        selectedAnswerId={selectedVersion}
        selectedLeftAnswerId={
          viewerType == SurveyViewerType.Diff ? selectedLeftVersion : 0
        }
        onSelectAnswersForCompare={onSelectDiffVersion}
        leftPanelActive={!sidebarCollapsed}
        versionLoading={versionLoading}
        diffLoading={diffLoading}
        onCompare={
          (versions?.filter((v) => v.shared)?.length || 0) > 1
            ? onCompareClick
            : undefined
        }
        orgName={orgName}
        isImportedSurvey={!!surveyImportUUID}
        isAnalystWorkflowSurvey={!!managedAssessmentId}
      />
      <ChangesViewFrame
        active={viewerType == SurveyViewerType.Diff}
        onChangeActiveState={exitDiff}
        dispatch={dispatch}
        surveyDiff
      />
      <div className={classes} ref={ref}>
        {includeToc && (
          <>
            <SurveyViewerSidebar
              nodeTree={nodeTree}
              flattenedNodeMap={flattenedNodeMap}
              hasRequiredAttachments={hasRequiredAttachments}
              collapsed={sidebarCollapsed}
              onClickCollapse={() => setLeftSidebarCollapsed(!sidebarCollapsed)}
              changesOnly={changedAnswersOnly}
              viewerType={viewerType}
            />
            <SurveyViewerSidebarScroller
              parentRef={ref}
              flattenedNodeMap={flattenedNodeMap}
              sidebarCollapsed={sidebarCollapsed}
            />
          </>
        )}
        <div className={"questionnaire-content-container"}>
          <div
            className={classnames("questionnaire-content", {
              loading: diffLoading || versionLoading,
              "use-scroll-margin":
                viewerType == SurveyViewerType.Diff && !rightPanelActive,
              disable: viewerType == SurveyViewerType.AutofillLoading,
            })}
            onScroll={(event) => {
              const scrollPos = (event.target as any).scrollTop;
              throttledSetActiveNode(scrollPos);
            }}
            onClick={(event) => {
              if (gptCacheStatus?.jobStarted && !gptCacheStatus.jobFinished) {
                event.stopPropagation();
              }
            }}
          >
            {viewerType !== SurveyViewerType.Viewing &&
              viewerType != SurveyViewerType.Diff &&
              viewerType != SurveyViewerType.Review &&
              !isPublicSurvey && (
                <div className={"intro-container"}>
                  <SurveyViewerIntro
                    viewerType={viewerType}
                    orgName={orgName}
                    logoUrl={logoUrl}
                    usageType={usageType}
                    userMessage={userMessage}
                    vendorName={vendorName}
                    onStartQuestionnaire={() => {
                      dispatch(setScrollNextUnansweredQuestion());
                    }}
                    onOpenAutofill={onOpenAutofillModal}
                    autofillHasRun={
                      !!gptCacheStatus?.jobStarted && gptCacheStatus.jobFinished
                    }
                    gptAutofillEnabled={shouldDisplayChatGptFeatures}
                    isImportedSurvey={!!surveyImportUUID}
                    rootNodeId={nodeTree.nodeId}
                    dontRecommendAutofill={surveyIsAnalystWorkflow}
                    isSystemSurvey={isSystemSurvey}
                  />
                </div>
              )}
            {nodeDisplays}
            {changedAnswersOnly && numAnswersChanged == 0 && (
              <EmptyCardWithAction
                className={"empty-change-card"}
                iconSrc={infoIcon}
                emptySubText={
                  "No changes were made between the versions you have selected to compare"
                }
              />
            )}
            {hasRequiredAttachments && (
              <SurveyViewerExtraAttachments
                surveyId={surveyId}
                isPublicSurvey={isPublicSurvey}
                disabled={viewerType === SurveyViewerType.Viewing}
                isPreview={viewerType === SurveyViewerType.Preview}
                previewTypeId={previewTypeId}
                previewSectionId={previewSectionId}
              />
            )}

            {viewerType != SurveyViewerType.Diff &&
              viewerType !== SurveyViewerType.Viewing && (
                <div className={"questionnaire-end-block"}>
                  <EmptyCardWithAction
                    iconSrc={tickIconSrc}
                    emptyText={"You’ve reached the end of this questionnaire"}
                    emptySubText={
                      surveyImportUUID ? undefined : isPublicSurvey ? (
                        "Once you have saved your changes, you can publish the questionnaire on your Trust Page."
                      ) : (
                        <>
                          To share your completed responses with {orgName}{" "}
                          select <b>Submit</b>.
                        </>
                      )
                    }
                  />
                </div>
              )}
          </div>
          <SurveyViewerActionBar
            orgName={orgName}
            vendorName={vendorName}
            vendorId={vendorId}
            vendorContacts={vendorContacts}
            nodeTree={nodeTree}
            surveyId={surveyId ?? 0}
            viewerType={viewerType}
            onClose={onClose}
            isPublicSurvey={isPublicSurvey}
            usageType={usageType}
            shouldShowComments={shouldShowComments}
            onCompare={
              (versions?.filter((v) => v.shared)?.length || 0) > 1
                ? onCompareClick
                : undefined
            }
            redlinesEnabled={!!redlinesEnabled}
            changedAnswersOnly={changedAnswersOnly}
            setChangedAnswersOnly={setChangedAnswersOnly}
            versionLoading={versionLoading}
            diffLoading={diffLoading}
            setAnswersFromUpload={setAnswersFromUpload}
            surveyLatestVersion={surveyLatestVersion}
            shouldDisplayChatGptFeatures={shouldDisplayChatGptFeatures}
            automation={selectedAutomation}
            automationRunAll={
              selectedAutomationUUID === "all" && automationList?.length !== 0
            }
            isAssessmentDraft={automationDraft}
            previewTypeId={previewTypeId}
            onExitAutofillLoading={() =>
              setViewerType(SurveyViewerType.Editing)
            }
            onOpenGptAutofillModal={onOpenAutofillModal}
            toolsModalOpen={toolsModalOpen}
            setToolsModalOpen={setToolsModalOpen}
            surveyImportUUID={surveyImportUUID}
            managedAssessmentId={managedAssessmentId}
            isManagementAnalystSession={isManagementAnalystSession}
          />
        </div>
        <SurveyViewerRightPanel
          surveyId={surveyId ?? 0}
          isPublicSurvey={isPublicSurvey}
          surveyTypeId={parseInt(previewTypeId || "")}
          otherVendorName={
            viewerType === SurveyViewerType.Viewing ? vendorName ?? "" : orgName
          }
          flattenedNodeMap={flattenedNodeMap}
          privateMessages={
            !isVendorPortal && viewerType === SurveyViewerType.Viewing
          }
          hasWritePermission={hasCommentWritePermission}
          orgName={orgName}
          diffModeLocked={
            viewerType == SurveyViewerType.Diff && !selectedVersionIsLatest
          }
          automation={selectedAutomation}
          automationUUID={selectedAutomationUUID}
          setSelectedAutomationUUID={setSelectedAutomationUUID}
          automationList={automationList || []}
          isManagementAnalystSession={isManagementAnalystSession}
          managedOrgId={managedOrgId}
          isCollaborator={isCollaborator}
        />
        <SurveyViewerContentScroller parentRef={ref} />
        {surveyId && (
          <>
            {/* These components just control lifecycle of elements of the survey viewer */}
            <SurveyViewerLockChecker
              surveyId={surveyId}
              isPublicSurvey={isPublicSurvey}
            />
          </>
        )}
        <SurveyViewerControls
          nodeTree={nodeTree}
          surveyId={surveyId}
          isPublicSurvey={isPublicSurvey}
          flattenedNodeMap={flattenedNodeMap}
          sidebarCollapsed={sidebarCollapsed}
        />
      </div>
      <PageviewTracker />
      {autofillV2Modal}
      {addRiskModalState.active && surveyId && (
        <SurveyAddRiskModal
          parentID={addRiskModalState.parentID}
          surveyID={surveyId}
          active
          onClose={() => dispatch(setAddRiskModalState(false))}
          editRiskID={addRiskModalState.editRiskId}
          editNodeID={addRiskModalState.editNodeId}
          showCategories={!!managedOrgId}
          existingRiskIDs={existingManualRiskIds ?? []}
          onRiskAdded={onRiskAdded}
        />
      )}
      <AutofillProcessModal
        active={viewerType == SurveyViewerType.AutofillLoading}
        onExitAutofillLoading={() => setViewerType(SurveyViewerType.Editing)}
      />
    </div>
  );
};

export default appConnect<
  SurveyViewerConnectedProps,
  never,
  SurveyViewerOwnProps
>((state, props) => {
  const automationState = props.automationUUID
    ? _get(
        state.cyberRisk,
        `automationRecipes[${props.automationUUID}]`,
        undefined
      )
    : undefined;

  const isManagementAnalystSession =
    props.match.path.startsWith("/analysts/tpvm");
  let managedOrgId: number | undefined = undefined;
  if (isManagementAnalystSession) {
    managedOrgId = state.common.tpvmSession?.tpvm_o
      ? parseInt(state.common.tpvmSession.tpvm_o)
      : undefined;
  }

  const automationForType =
    props.previewTypeId && state.cyberRisk.questionnaireAutomation
      ? state.cyberRisk.questionnaireAutomation[parseInt(props.previewTypeId)]
      : undefined;

  let automationList;
  if (automationForType && props.automationDraft) {
    automationList = automationForType.draft;
  } else if (automationForType && automationForType.published?.length) {
    automationList = automationForType.published?.filter((a) => {
      return a.enabled;
    });
  }

  const automation = automationState?.data;
  return {
    nodeTree: state.surveyViewer.nodeTree,
    rightPanelActive: state.surveyViewer.rightPanel.active,
    numAnswersChanged: state.surveyViewer.answersChanged.totalChanged,
    shouldDisplayChatGptFeatures: shouldDisplayChatGPT(state.common.userData),
    automation,
    automationList,
    gptCacheStatus: state.surveyViewer.gptAutofill,
    lockState: state.surveyViewer.lock,
    isManagementAnalystSession: isManagementAnalystSession,
    managedOrgId: managedOrgId,
  };
})(SurveyViewer);

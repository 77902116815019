import { getCyberRiskAuth } from "../../../_common/session";
import { trackEvent } from "../../../_common/tracking";
import { IAttachment } from "../../../_common/types/attachment";
import { FC, useMemo } from "react";
import XTable, {
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { useSorting } from "../../../_common/hooks";
import { GetIconForFilename } from "../../helpers/icons";
import QuestionnaireIcon from "../../../_common/images/questionnaire-icon.svg";
import { SharedProfileSupportingDocumentationTableStyles } from "./SharedProfileSupportingDocumentationTable";
import moment from "moment/moment";

export const downloadSurveyAttachment = (
  surveyId: number,
  objectName: string,
  attachmentName: string,
  filename: string
) => {
  const { apiKey, token } = getCyberRiskAuth();
  const url = `/api/prefilledsurvey/attachment/v1/?apikey=${encodeURIComponent(
    apiKey
  )}&token=${encodeURIComponent(
    token
  )}&survey_id=${surveyId}&object=${encodeURIComponent(
    objectName
  )}&attachment_name=${encodeURIComponent(attachmentName)}`;
  // window.open(url, "_blank");
  trackEvent("VerifiedVendor_DownloadSurveyAttachment");

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
};

const supportingDocumentationColumnHeaders: IXTableColumnHeader[] = [
  {
    id: "document",
    text: "Document",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "published",
    text: "Date published",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
];

interface ISharedProfileSurveyDocumentationTableProps {
  documents: IAttachment[];
  surveyId: number;
}

const SharedProfileSurveyDocumentationTable: FC<
  ISharedProfileSurveyDocumentationTableProps
> = ({ documents, surveyId }) => {
  const [sortedDocuments, sortedBy, onSortChange] = useSorting<
    IAttachment,
    "document" | "published"
  >(documents, "published", SortDirection.DESC, {
    document: {
      orderFuncs: [(a) => a.attachmentName || a.filename],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    published: {
      orderFuncs: [(a) => a.createdAt],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });

  const rows = useMemo(
    () =>
      sortedDocuments.map((d) => {
        const download = () =>
          downloadSurveyAttachment(
            surveyId,
            d.gcsObjectName,
            d.attachmentName || "",
            d.filename
          );

        return {
          id: d.id,
          onClick: download,
          iconOptions: [
            {
              id: "download",
              hoverText: "Download",
              icon: <div className="cr-icon-export" />,
              onClick: download,
            },
          ],
          cells: [
            <XTableCell key="document">
              <div className="doc-cell">
                <img
                  className="file-type-icon"
                  src={GetIconForFilename(d.filename, QuestionnaireIcon)}
                />
                <div className="name-and-desc">
                  {d.filename}
                  {d.description && (
                    <>
                      <br />
                      <div className="desc">{d.description}</div>
                    </>
                  )}
                </div>
              </div>
            </XTableCell>,
            <XTableCell key="published" className="shrink-cell">
              {moment(d.createdAt).format("ll")}
            </XTableCell>,
          ],
        };
      }),
    [sortedDocuments, surveyId]
  );

  return (
    <SharedProfileSupportingDocumentationTableStyles>
      <XTable
        className="shared-profile-supporting-documentation-table"
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        columnHeaders={supportingDocumentationColumnHeaders}
        iconOptions
        rows={rows}
      />
    </SharedProfileSupportingDocumentationTableStyles>
  );
};

export default SharedProfileSurveyDocumentationTable;

import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import SeverityIcon from "../../_common/components/SeverityIcon";
import { IXTableRow, XTableCell } from "../../_common/components/core/XTable";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringFeedType,
} from "../api/types";
import FeedWithTable, {
  FeedTableEllipsisedText,
  GetRowsProps,
} from "./FeedWithTable";
import ThreatCommentsButton from "./ThreatCommentsButton";
import Investigator from "./Investigator";
import "./InvestigatingFeed.scss";
import { useNavToThreatDetails } from "../funcs/useNav";
import { FeedDate } from "./ResultDate";

export interface InvestigatingFeedProps {
  hidden: boolean;
}

export default function InvestigatingFeed({ hidden }: InvestigatingFeedProps) {
  const navigateToThreatDetails = useNavToThreatDetails({
    backToText: "Back to Threat Monitoring",
  });

  // Note that a sortable column id must be a valid ThreatMonitoringSortBy in threatmonitoring/db_results_filtering.go
  const columns = [
    {
      id: "severity",
      text: "Sev.",
      sortable: true,
      className: "severity",
    },
    { id: "title", text: "Threat name", sortable: true, className: "title" },
    {
      id: "updated_at",
      text: "Last updated",
      sortable: true,
      className: "date",
    },
    {
      id: "actor",
      text: "Investigator",
      sortable: true,
      className: "actor",
    },
    {
      id: "comments",
      text: "Comments",
      sortable: false,
      className: "comments",
    }, // temporarily disallow sorting by comments
    { id: "actions", text: "Actions", sortable: false, className: "actions" },
  ];

  // getInvestigatingFeedRows
  // Given the feed results returned from the back end, render the rows for the XTable component.
  const getRows = (
    feed: DisplayableThreatMonitoringResult[],
    { selectedItems, actors }: GetRowsProps
  ): IXTableRow<string>[] => {
    return feed.map((result) => {
      const cells = [
        <XTableCell key={"severity"} className={"severity-cell"}>
          <SeverityIcon severity={result.severity} />
        </XTableCell>,
        <XTableCell key="title" className="title-cell">
          <FeedTableEllipsisedText text={result.title}>
            {result.title}
          </FeedTableEllipsisedText>
        </XTableCell>,
        <XTableCell key="date" className="date-cell">
          <FeedDate date={result.updatedAt} />
        </XTableCell>,
        <XTableCell key="investigator" className="actor-cell investigator-cell">
          <Investigator
            threatUUID={result.uuid}
            investigatorId={result.investigatorId}
            actors={actors}
          />
        </XTableCell>,
        <XTableCell key={"comments"} className={"comments-cell"}>
          <ThreatCommentsButton
            totalMessages={result.numberOfComments}
            haveUnreadMessages={result.hasUnreadComments}
            onClick={navigateToThreatDetails(result.uuid, true)}
            hiddenVisibilityAttentionLeft={true}
          />
        </XTableCell>,
        <XTableCell key={"actions"} className={"action-cell"}>
          <div className={"actions"}>
            <SidePopupV2
              text={"View threat details"}
              position={"top"}
              width={103}
            >
              <div
                className={"chevron-container hover-border"}
                onClick={navigateToThreatDetails(result.uuid)}
              >
                <div className={"cr-icon-chevron"} />
              </div>
            </SidePopupV2>
          </div>
        </XTableCell>,
      ];
      return {
        id: result.uuid,
        selected: selectedItems?.has(result.uuid),
        cells: cells,
      };
    });
  };

  return (
    <>
      <FeedWithTable
        className="investigating-feed"
        hidden={hidden}
        feedType={ThreatMonitoringFeedType.Investigating}
        columns={columns}
        getRows={getRows}
        selectable
        feedFailedMessage="Failed to read threats under investigation"
        feedEmptyMessage="There are either no threats under investigation, or no threats matching your current filter settings."
        filterPanelDateFilterTitle="Last updated"
      />
    </>
  );
}

import SlidePanel, {
  IBaseSlidePanelProps,
} from "../../../_common/components/SlidePanel";
import {
  PublicRiskWaiver,
  PublicWaiverAcceptStatus,
} from "../../types/sharedAssessment";
import Button, { TooltipButton } from "../../../_common/components/core/Button";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../../_common/components/InfoTable";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import CommentDisplay, { CommentDisplayStyling } from "../CommentDisplay";
import { FC, useState } from "react";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import moment from "moment";
import {
  acceptPublicRiskWaiver,
  ignorePublicRiskWaiver,
} from "../../reducers/verifiedVendors.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import "../../style/components/risk_waivers/PublicRiskWaiverPanel.scss";
import {
  fetchSharedAssessmentForVendor,
  openModal,
} from "../../../_common/reducers/commonActions";
import { reloadAllRemediationRequestDetails } from "../../../_common/reducers/remediationRequest.actions";
import { ConfirmationModalName } from "../../../_common/components/modals/ConfirmationModal";
import { deleteVendorRiskWaiver } from "../../reducers/vendorRiskWaiver.actions";
import {
  clearCloudscanData,
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
} from "../../reducers/cyberRiskActions";
import {
  fetchVendorRiskBreakdownReport,
  fetchVendorRiskOverviewReport,
} from "../../reducers/reportsActions";
import { fetchVendorPortfolioRiskProfile } from "../../reducers/vendorRiskPortfolio.actions";
import { clearDomains } from "../../reducers/domains.actions";
import { IVendorWords } from "../../../_common/constants";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import ScrollableDiv from "../ScrollableDiv";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import { RiskDescription } from "../RiskSummaryDetails";
import { formatDateAsLocal } from "../../../_common/helpers";
import { VendorSummaryRiskType } from "../../../_common/types/vendorSummary";
import ExpandableItem from "../../../_common/components/ExpandableItem";

export const getPublicRiskWaiverStatusPill = (
  waiver: PublicRiskWaiver,
  vendorName?: string
) => {
  let publicStatusPill: React.ReactNode;
  switch (waiver.status) {
    case PublicWaiverAcceptStatus.Shared:
      // No pill needed for this
      break;
    case PublicWaiverAcceptStatus.Accepted:
      publicStatusPill = (
        <PillLabel color={LabelColor.Blue}>Accepted</PillLabel>
      );
      break;
    case PublicWaiverAcceptStatus.Ignored:
      publicStatusPill = (
        <PillLabel color={LabelColor.Grey}>Rejected</PillLabel>
      );
      break;
    default:
      publicStatusPill = (
        <PillLabel color={LabelColor.Orange}>Pending</PillLabel>
      );
  }
  return (
    <>
      {publicStatusPill}
      <PillLabel
        color={LabelColor.Fuchsia}
        popupPosition={"top"}
        popupContent={
          vendorName
            ? `This waiver has been shared by ${vendorName}.`
            : undefined
        }
      >
        Shared
      </PillLabel>
    </>
  );
};

const getExpires = (r: PublicRiskWaiver) => {
  let expiresInDays = r.expiresAt
    ? moment(r.expiresAt).diff(moment(), "days")
    : 0;

  if (expiresInDays < 0) {
    expiresInDays = 0;
  }

  return r.expiresAt ? (
    <>
      <DateTimeFormat dateTime={r.expiresAt} dateOnly />
      {expiresInDays < 14 && (
        <SidePopupV2
          className="expires-soon"
          text={`This waiver will expire in ${expiresInDays} days.`}
          noWrap
        >
          <span className={"cr-icon-clock bouncing"} />
        </SidePopupV2>
      )}
    </>
  ) : (
    <em>Never</em>
  );
};

interface PublicWaiverPanelProps extends IBaseSlidePanelProps {
  waiver?: PublicRiskWaiver;
  vendorId?: number;
  vendorName?: string;
  userHasWriteAccess?: boolean;
  vendorWords: IVendorWords;
}

const PublicWaiverPanel: FC<PublicWaiverPanelProps> = ({
  active,
  onClose,
  vendorId,
  vendorName,
  waiver,
  userHasWriteAccess,
  vendorWords,
}) => {
  const dispatch = useAppDispatch();
  const [accepting, setAccepting] = useState(false);
  const [ignoring, setIgnoring] = useState(false);

  const [prevActive, setPrevActive] = useState(false);
  if (active != prevActive) {
    setPrevActive(active);

    if (active) {
      setAccepting(false);
      setIgnoring(false);
    }
  }

  const deleteWaiver = () => {
    dispatch(
      openModal(
        ConfirmationModalName,
        {
          title: "Delete Risk Waiver",
          description: "Are you sure you want to delete this risk waiver?",
          buttonText: "Delete",
          dangerousAction: true,
          buttonAction: () => {
            return dispatch(
              deleteVendorRiskWaiver(
                vendorId ?? 0,
                waiver?.acceptedVendorRiskWaiverId ?? 0
              )
            )
              .then(() => {
                onClose();

                dispatch(addDefaultSuccessAlert("Waiver deleted"));

                dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
                dispatch(fetchVendorSummaryAndCloudscans(vendorId ?? 0, true));
                dispatch(fetchVendorRiskOverviewReport(true));
                dispatch(fetchVendorRiskBreakdownReport(true));
                dispatch(reloadAllRemediationRequestDetails());
                dispatch(clearCloudscanData());
                dispatch(fetchVendorPortfolioRiskProfile());
                dispatch(clearDomains());
                dispatch(fetchSharedAssessmentForVendor(vendorId ?? 0));
              })
              .catch(() => {
                dispatch(addDefaultUnknownErrorAlert("Error deleting waiver"));
              });
          },
        },
        true
      )
    );
  };

  let waivedAssetsContent = undefined;

  if (waiver?.domains?.length === 1) {
    waivedAssetsContent = <span>{waiver?.domains[0]}</span>;
  } else if (waiver?.domains && waiver.domains.length > 1) {
    waivedAssetsContent = (
      <div>
        <ExpandableItem
          header={`${waiver?.domains.length} domains & IPs`}
          content={
            <ul className={"website-list"}>
              {waiver?.domains.map((w) => <li key={w}>{w}</li>)}
            </ul>
          }
        />
      </div>
    );
  }

  return (
    <SlidePanel
      active={active}
      className={"public-risk-waivers-panel"}
      dimContent
      onClose={onClose}
      newStyles
    >
      <>
        <div className="title-container">
          <h2 className="title">{waiver?.riskName}</h2>
          <div className="button-container">
            {userHasWriteAccess &&
              waiver?.status !== PublicWaiverAcceptStatus.Accepted && (
                <>
                  {(waiver?.status === PublicWaiverAcceptStatus.Pending ||
                    PublicWaiverAcceptStatus.Ignored) && (
                    <TooltipButton
                      green
                      loading={accepting}
                      popupPosition={"left"}
                      tooltipContent={
                        <>
                          Accepting this waiver will <b>remove</b> this risk
                          from the {vendorWords.possessive} risk profile
                        </>
                      }
                      onClick={() => {
                        setAccepting(true);
                        dispatch(
                          acceptPublicRiskWaiver(vendorId ?? 0, waiver?.id ?? 0)
                        )
                          .then(() => {
                            dispatch(
                              addDefaultSuccessAlert(
                                `Public risk waiver accepted.`
                              )
                            );
                            onClose();
                          })
                          .catch(() => {
                            setAccepting(false);
                            dispatch(
                              addDefaultUnknownErrorAlert(
                                "Error accepting risk waiver"
                              )
                            );
                          });
                      }}
                    >
                      <i className={"cr-icon-check"} />
                      Accept
                    </TooltipButton>
                  )}
                  {waiver?.status === PublicWaiverAcceptStatus.Pending && (
                    <TooltipButton
                      danger
                      loading={ignoring}
                      popupPosition={"left"}
                      tooltipContent={
                        <>
                          Rejecting this waiver will <b>add</b> this risk to the{" "}
                          {vendorWords.possessive} risk profile
                        </>
                      }
                      onClick={() => {
                        setIgnoring(true);
                        dispatch(
                          ignorePublicRiskWaiver(
                            vendorId ?? 0,
                            waiver?.id ?? 0,
                            waiver?.status !== PublicWaiverAcceptStatus.Ignored
                          )
                        )
                          .then(() => {
                            dispatch(
                              addDefaultSuccessAlert(
                                `Public risk waiver rejected.`
                              )
                            );
                            onClose();
                          })
                          .catch(() => {
                            setIgnoring(false);
                            dispatch(
                              addDefaultUnknownErrorAlert(
                                "Error ignoring risk waiver"
                              )
                            );
                          });
                      }}
                    >
                      <i className={"cr-icon-minus-circle"} />
                      Reject
                    </TooltipButton>
                  )}
                </>
              )}
            {userHasWriteAccess &&
              waiver?.status === PublicWaiverAcceptStatus.Accepted &&
              waiver?.acceptedVendorRiskWaiverId && (
                <Button danger onClick={deleteWaiver}>
                  <i className={"cr-icon-trash-2"} />
                  Delete
                </Button>
              )}
          </div>
        </div>
        <div className="content-container">
          {waiver && (
            <ScrollableDiv newStyles>
              <CheckItemSlidePanelSection
                title="Waiver details"
                startExpanded={true}
              >
                <InfoTable styling={InfoTableStyling.New} bordered>
                  <InfoTableRow label={"EXPIRY"} value={getExpires(waiver)} />
                  <InfoTableRow
                    label={"DATE CREATED"}
                    value={formatDateAsLocal(waiver.createdAt)}
                  />
                  <InfoTableRow
                    label={"JUSTIFICATION"}
                    value={
                      <CommentDisplay
                        styling={CommentDisplayStyling.Shaded}
                        date={waiver.createdAt}
                        comment={waiver.justification}
                        name={vendorName}
                      />
                    }
                  />
                  <InfoTableRow
                    label={"WAIVED FOR"}
                    value={waivedAssetsContent}
                  />
                </InfoTable>
              </CheckItemSlidePanelSection>
              <CheckItemSlidePanelSection
                title="Risk information"
                startExpanded={true}
              >
                <InfoTable styling={InfoTableStyling.New} bordered>
                  <InfoTableRow
                    label={"SUMMARY"}
                    value={
                      waiver && (
                        <RiskDescription
                          risk={{
                            riskType: VendorSummaryRiskType.Cloudscan,
                            description: waiver.riskText,
                            summary: waiver.riskSummary,
                          }}
                        />
                      )
                    }
                  />
                  <InfoTableRow
                    label={"RISK DETAILS"}
                    value={<p>{waiver.riskDetails}</p>}
                    hide={!waiver.riskDetails}
                  />
                  <InfoTableRow
                    label={"RECOMMENDED REMEDIATION"}
                    value={<p>{waiver.riskRecommendedRemediation}</p>}
                    hide={!waiver.riskRecommendedRemediation}
                  />
                </InfoTable>
              </CheckItemSlidePanelSection>
            </ScrollableDiv>
          )}
        </div>
      </>
    </SlidePanel>
  );
};

export default PublicWaiverPanel;

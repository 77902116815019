import { useBack } from "../../../_common/types/router";
import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import UserBaseUserHeader from "../../components/headers/UserBaseUserHeader";
import { userPermissionsUrl, usersUrl, userUrl } from "../../UserBaseAppRouter";
import UserPermissions from "../../components/users/UserPermissions";
import { UserRouteParams } from "../../UserBaseNavItems";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const UserPermissionsView: FC = () => {
  const match = useDecodedRouteMatch<UserRouteParams>();

  const userUUID = match.params.userUUID;

  const { data } = userbaseApi.useGetUserBaseUserSummaryV1Query({ userUUID });

  const { backAction, backText } = useBack();

  return (
    <div className="user-profile">
      <UserBaseUserHeader
        userUUID={userUUID}
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: data?.user.name ?? "User",
            to: userUrl(userUUID),
          },
          {
            text: "Permissions",
            to: userPermissionsUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Permissions"}
      />

      <UserPermissions userUUID={userUUID} />
    </div>
  );
};

export default UserPermissionsView;

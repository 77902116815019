import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  RemediationRequestDetails,
  RemediationRequestMessages,
  RemediationRequestProjection,
  RemediationRequestRisk,
  RemediationRequestStatus,
  RemediationRequestTimeline,
  RemediationRequestUsers,
  RemediationRequestWithMeta,
} from "../types/remediation";
import { IVendorContactResponse } from "../types/vendorContact";
import { InfoBar } from "../components/InfoBar";
import { History } from "history";
import { DefaultRouteProps, locationState } from "../types/router";
import Button from "../components/core/Button";
import {
  createVendorContact,
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
} from "../../vendorrisk/reducers/cyberRiskActions";
import DismissableBanner from "../components/DismissableBanner";
import TabButtons from "../components/TabButtons";
import RemediationDetailsSummaryCard from "../components/remediationDetails/RemediationDetailsSummaryCard";
import LoadingBanner from "../components/core/LoadingBanner";
import "../style/components/RemediationRequestDetailsV2.scss";
import RemediationDetailsRisksTable from "../components/remediationDetails/RemediationDetailsRisksTable";
import Timeline from "../components/Timeline";
import ConfirmationModalV2 from "../components/modals/ConfirmationModalV2";
import AddRecipientsModal from "../components/modals/AddRecipientsModal";
import {
  addRemediationRequestMessage,
  addRemediationRequestUsers,
  editRemediationRequestMessage,
  fetchProjectionForExistingRemediationRequest,
  fetchRemediationRequestDetails,
  fetchRemediationRequestMessages,
  fetchRemediationRequestTimeline,
  fetchRemediationRequestUsers,
  markRemediationRequestRiskAsRemediated,
  updateRemediationRequestArchived,
  updateRemediationRequestDueDate,
  updateRemediationRequestStatus,
  updateRemediationRequestTitle,
} from "../reducers/remediationRequest.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../reducers/messageAlerts.actions";
import { DefaultThunkDispatch, DefaultThunkDispatchProp } from "../types/redux";
import { getVendorObject } from "../selectors/vendorSelectors";
import { getCurrentOrgFromUserData } from "../helpers";
import {
  getUserPermissionsForVendorFromState,
  UserBreachsightWrite,
  UserSystemRoleVendorManagementAnalyst,
  UserVendorRiskWrite,
} from "../permissions";
import { get as _get } from "lodash";
import { conditionalRefreshActivityStreamForOrgUser } from "../reducers/commonActions";
import SlidePanel from "../components/SlidePanel";
import MessagesPanel from "../components/MessagesPanel";
import RemediationDetailsScoreProjectionCard from "../components/remediationDetails/RemediationDetailsScoreProjectionCard";
import {
  useNewRecipients,
  validateContactSelector,
} from "../../vendorrisk/components/ContactSelector";
import DomainsAndIPsPanelGroup, {
  OpenSidePanel,
} from "../../vendorrisk/components/DomainsAndIPsPanelGroup";
import { WebscanResult } from "../../vendorrisk/types/webscans";
import { handleKnownErrors } from "../../vendorrisk/reducers/errors.actions";
import PageHeader from "../components/PageHeader";
import { RiskSource } from "../types/risks";
import { getSurveyEditorPath } from "./SurveyDetails";
import { useRemediationProgress } from "../components/remediationDetails/helpers";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import { useMessageCounts } from "../components/correspondence/correspondence.helpers";
import ExportReportModal from "../../vendorrisk/components/modals/ExportReportModal";
import {
  ExportFiletype,
  ExportFiletypesBoth,
  ExportType,
} from "../types/exportReport";
import RemediationDetailsExportOptions, {
  InitRemediationDetailsExportOptions,
  ReduceRemediationDetailsExportOptions,
} from "../../vendorrisk/components/RemediationDetailsExportOptions";
import { remediationLocationState } from "../../vendorrisk/views/RequestRemediationV2";
import { crumb, getVendorPageBreadcrumbs } from "../components/Breadcrumbs";
import { AssuranceType } from "../types/organisations";
import { fetchVendorMgtContacts } from "../../vendorrisk/reducers/vendorContacts.actions";
import { DownloadEvidenceFromRemediationRisk } from "../../vendorrisk/views/AdditionalEvidenceDocumentsTab";
import TextField from "../components/TextField";
import { WaiverType } from "../types/vendorRiskWaivers";
import { appConnect, useAppDispatch } from "../types/reduxHooks";
import VendorAssessmentAPI from "../../vendorrisk/reducers/vendorAssessmentAPI";
import { skipToken } from "@reduxjs/toolkit/query";
import { fetchVendorAppSurveyList } from "../../vendor_portal/reducers/actions";
const remediationRisksElementId = "remediation-risks";
import { GetVulnPanel } from "../../vendorrisk/components/DomainsAndIPsPanelGroup";
import { useModalV2 } from "../components/ModalV2";
import RemediateThreatModal from "../../threatmonitoring/components/RemediateThreatModal";
import { urlPrefix as userRiskUrlPrefix } from "../../userbase/UserBaseAppRouter";
import {
  tasksRouterUrlPrefix,
  userRiskTaskUrlPrefix,
} from "../helpers/navigation.helpers";

interface IRemediationRequestDetailsV2OwnProps
  extends DefaultRouteProps<
    {
      requestId?: string;
      vendorId?: string;
      orgId?: string;
    },
    {
      openComments?: boolean;
    }
  > {
  isVendorPortal: boolean;
  remediationTaskListBasePathOverride?: string; // Applied only when isVendorPortal = true, will override the path used by the 'Remediation Request' breadcrumb.
}

interface IRemediationRequestDetailsV2ConnectedProps {
  currentUserId: number;
  requestId: number;
  isManagedVendorAnalyst?: boolean;
  managedOrgId?: number;
  isManagementAnalystSession?: boolean;
  isSubsidiary: boolean;
  isVendor: boolean;
  vendorId?: number;
  details?: RemediationRequestWithMeta;
  timeline?: RemediationRequestTimeline;
  messages?: RemediationRequestMessages;
  users?: RemediationRequestUsers;
  projection?: RemediationRequestProjection;
  allowedPrivateMessages: boolean;
  contacts?: IVendorContactResponse[];
  vendorName?: string;
  vendorPrimaryDomain: string;
  userHasWritePermission: boolean;
  userHasWritePermsInAnyPortfolio: boolean;
  isSelfRemediation: boolean;
  allCloudscans: Record<string, WebscanResult | undefined>;
  assuranceType: AssuranceType;
  isUserRisk: boolean;
}

interface IRemediationRequestDetailsV2DispatchProps
  extends DefaultThunkDispatchProp {
  fetchRemediationRequestDetails: (
    requestId: number,
    force: boolean
  ) => Promise<RemediationRequestDetails>;
  fetchRemediationRequestTimeline: (
    requestId: number,
    force: boolean
  ) => Promise<RemediationRequestTimeline>;
  fetchRemediationRequestMessages: (
    requestId: number,
    force: boolean,
    noMarkRead: boolean,
    noCache?: boolean
  ) => Promise<RemediationRequestMessages>;
  fetchRemediationRequestUsers: (
    requestId: number,
    force: boolean
  ) => Promise<RemediationRequestUsers>;
  refreshUserNotifications: (force: boolean) => Promise<void>;
  fetchVendorWatchStatus: (vendorId: number) => Promise<void>;
  fetchVendorMgtContacts: (vendorId: number, force: boolean) => Promise<void>;
  fetchVendorSummaryAndCloudscans: (
    vendorId: number,
    isSubsidiary: boolean
  ) => Promise<unknown>;
  updateRemediationRequestArchived: (
    requestIds: number[],
    newArchived: boolean,
    isUserRisk: boolean,
    isSubsidiary: boolean,
    vendorIDs: (number | undefined)[]
  ) => Promise<void>;
  updateRemediationRequestStatus: (
    requestId: number,
    newStatus: RemediationRequestStatus,
    isSubsidiary: boolean,
    vendorID?: number,
    message?: string
  ) => Promise<void>;
  addSimpleErrorAlert: (message: string) => void;
  addDefaultUnknownErrorAlert: (errorText: string) => void;
  conditionalRefreshActivityStreamForOrgUser: () => Promise<void>;
  fetchAlertsOrActivityStreamForOrgUser: (
    forced: boolean,
    background: boolean
  ) => Promise<void>;
  addDefaultSuccessAlert: (text: string) => void;
  addRemediationRequestMessage: (
    remediationRequestId: number,
    parentId: number | undefined,
    content: string,
    is_private: boolean
  ) => Promise<any>;
  editRemediationRequestMessage: (
    messageId: number,
    content: string
  ) => Promise<any>;
  fetchProjectionForExistingRemediationRequest: (
    remediationRequestId: number
  ) => Promise<void>;
  createVendorContact: (
    vendorId: number,
    name: string,
    title: string,
    emailAddress: string
  ) => Promise<void>;
  addRemediationRequestUsers: (
    requestId: number,
    emails: string[],
    message: string,
    emailsToRemove?: string[]
  ) => Promise<void>;
  updateRemediationRequestTitle: (
    remediationRequestId: number,
    title: string,
    isSubsidiary: boolean
  ) => Promise<any>;
  updateRemediationRequestDueDate: (
    remediationRequestId: number,
    dueDate?: string,
    reminderDate?: string
  ) => Promise<any>;
  handleKnownErrors: (e: Error) => boolean;
  markRiskAsRemediated: (
    requestId: number,
    checkId: string,
    vendorID?: number,
    justification?: string
  ) => Promise<any>;
  fetchVendorAppSurveyList: (
    forceRefresh: boolean,
    background: boolean
  ) => Promise<false | null>;
}

type IRemediationRequestDetailsV2Props =
  IRemediationRequestDetailsV2ConnectedProps &
    IRemediationRequestDetailsV2DispatchProps &
    IRemediationRequestDetailsV2OwnProps;

const RemediationRequestDetailsV2 = (
  props: IRemediationRequestDetailsV2Props
) => {
  // **** data loading ****
  useEffect(() => {
    const proms: Promise<any>[] = [
      props.fetchRemediationRequestDetails(props.requestId, false),
      props.fetchRemediationRequestTimeline(props.requestId, false),
      // force load messages so that we get correct read status
      props.fetchRemediationRequestMessages(props.requestId, true, true),
      props.fetchRemediationRequestUsers(props.requestId, false),
    ];

    if (!props.isUserRisk) {
      proms.push(
        props.fetchProjectionForExistingRemediationRequest(props.requestId)
      );
    }
    Promise.all(proms).catch((e) => props.addSimpleErrorAlert(e.message));
  }, [props.requestId, props.isVendorPortal, props.isUserRisk]);

  useEffect(() => {
    const proms = [];
    if (
      !props.isVendorPortal &&
      !props.isSubsidiary &&
      props.details &&
      props.details.vendorId
    ) {
      proms.push(
        props.fetchVendorWatchStatus(props.details.vendorId),
        props.fetchVendorMgtContacts(props.details.vendorId, true),
        props.fetchVendorSummaryAndCloudscans(props.details.vendorId, false)
      );
    }
    if (props.isSubsidiary && props.details && props.details.vendorId) {
      proms.push(
        props.fetchVendorSummaryAndCloudscans(
          props.details.vendorId,
          props.isSubsidiary
        )
      );
    }

    Promise.all(proms).catch((e) => props.addSimpleErrorAlert(e));
  }, [props.isVendorPortal, props.isSubsidiary, props.details]);

  // if we have a linked vendor assessment grab it
  const { data: linkedVendorAssessment } =
    VendorAssessmentAPI.useGetVendorAssessmentDataQuery(
      props.details?.vendorAssessmentId && props.vendorId
        ? {
            vendorID: props.vendorId,
            versionID: props.details.vendorAssessmentId,
          }
        : skipToken
    );

  // **** state ****
  const dispatch = useAppDispatch();
  const [backToText, setBackToText] = useState("Back to Remediation List");
  useEffect(() => {
    if (props.location.state && props.location.state.backContext) {
      setBackToText(props.location.state.backContext.backToText);
    } else {
      setBackToText("Back to Remediation List");
    }
  }, [props.location]);

  const [messagesOpen, setMessagesOpen] = useState(
    !!props.location.state?.openComments
  );
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [addRecipientsModalOpen, setAddRecipientsModalOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [selectedFiletype, setSelectedFiletype] = useState(ExportFiletype.PDF);

  const [exportOptions, exportOptionsDispatch] = useReducer(
    ReduceRemediationDetailsExportOptions,
    InitRemediationDetailsExportOptions()
  );

  type tabSelection = "overview" | "timeline";
  const [selectedTab, setSelectedTab] = useState<tabSelection>("overview");

  const [
    cloudscanRisks,
    surveyRisks,
    additionalEvidenceRisks,
    saasRisks,
    threatMonitoringRisks,
  ] = useMemo<
    [
      RemediationRequestRisk[],
      RemediationRequestRisk[],
      RemediationRequestRisk[],
      RemediationRequestRisk[],
      RemediationRequestRisk[],
    ]
  >(() => {
    if (props.details && props.details.risks) {
      return [
        props.details.risks.filter((r) => r.source === RiskSource.Cloudscan),
        props.details.risks.filter((r) => r.source === RiskSource.Survey),
        props.details.risks.filter(
          (r) => r.source === RiskSource.AdditionalEvidence
        ),
        props.details.risks.filter((r) => r.source === RiskSource.SaaS),
        props.details.risks.filter(
          (r) => r.source === RiskSource.ThreatMonitoring
        ),
      ];
    }
    return [[], [], [], [], []];
  }, [props.details]);

  const [progress] = useRemediationProgress(props.details);

  const [
    newRecipients,
    addNewRecipient,
    deleteNewRecipient,
    updateNewRecipient,
    clearNewRecipients,
  ] = useNewRecipients();

  const [emailsToRemove, setEmailsToRemove] = useState([] as string[]);

  const originalMessage = `_Hi there, I'm inviting you to collaborate on a risk remediation request via the UpGuard platform_\n\nPlease review this remediation request on UpGuard.`;
  const [message, setMessage] = useState(originalMessage);

  const isArchived = !!(props.details && props.details.archived);
  const isOpen =
    props.details && props.details.status !== RemediationRequestStatus.Closed;

  let otherVendorName = props.vendorName || "";
  if (props.isVendorPortal) {
    // Vendor name is the remediation request owner
    otherVendorName =
      props.details?.sender.name || props.details?.sender.email || "";
  }

  const [selectedVendorContacts, setSelectedVendorContacts] = useState(
    [] as string[]
  );

  const [submitMessage, setSubmitMessage] = useState<string | undefined>(
    undefined
  );

  interface domainsAndIPsPanelGroupRiskParams {
    riskPanel?: JSX.Element;
    getVulnPanel?: GetVulnPanel;
  }

  const [selectedRiskParams, setSelectedRiskParams] = useState<
    domainsAndIPsPanelGroupRiskParams | undefined
  >(undefined);

  const [openRemediateThreatModal, remediateThreatModal] =
    useModalV2(RemediateThreatModal);

  // **** actions ****

  const goBack = () => {
    if (props.location.state?.backContext?.goBack) {
      props.history.goBack();
    } else if (props.location.state?.backContext?.backTo) {
      props.history.push(props.location.state.backContext.backTo);
    } else {
      backToAllRemediationRequests();
    }
  };

  const remediationBasePath = () => {
    if (props.isManagementAnalystSession) {
      return `/analysts/tpvm/${props.managedOrgId}/${props.vendorId}/remediation`;
    } else if (props.isVendorPortal) {
      return (
        props.remediationTaskListBasePathOverride ?? "/vendors/remediation"
      );
    } else if (props.isSubsidiary) {
      return `/subsidiaries/${props.vendorId}/remediation`;
    } else if (props.vendorId) {
      return `/vendor/${props.vendorId}/remediation`;
    } else {
      return "/selfremediation";
    }
  };

  const backToAllRemediationRequests = () => {
    props.history.push(remediationBasePath());
  };

  // redirect to edit screen if this is a draft
  useEffect(() => {
    if (props.details?.status === RemediationRequestStatus.Draft) {
      (props.history as History<remediationLocationState>).replace(
        remediationBasePath() + "/add",
        {
          backContext: props.location.state?.backContext,
          loadDraftId: props.requestId,
        }
      );
    }
  }, [props.details]);

  const getUrlPrefix = (isPublicSurvey = false) => {
    if (props.isManagementAnalystSession) {
      return `/analysts/tpvm/${props.managedOrgId}/${props.vendorId}/`;
    } else if (props.isVendorPortal) {
      return "/vendors/";
    } else if (props.isSubsidiary) {
      return `/subsidiaries/${props.vendorId}/`;
    } else if (props.vendorId) {
      return `/vendor/${props.vendorId}/${
        isPublicSurvey ? "sharedassessment/" : ""
      }`;
    } else {
      return `/`;
    }
  };

  const goToSurvey = (id: number, isPublicSurvey: boolean) => {
    const url = `${getUrlPrefix(isPublicSurvey)}surveys/${id}`;

    window.open(url, "_blank")?.focus();
  };

  const goToQuestionnaireRisk = (
    id: number,
    risk: string,
    isPublicSurvey: boolean
  ) => {
    const location = getSurveyEditorPath({
      surveyId: id,
      editMode: props.isVendorPortal,
      questionId: risk,
      isManagementAnalystSession: props.isManagementAnalystSession,
      managedOrgId: props.managedOrgId,
      location: props.location,
      publicSurvey: isPublicSurvey,
      backContext: {
        backTo: `${props.history.location.pathname}`,
        backToText: "Back to remediation request",
      },
    });

    window
      .open(
        location.pathname + (location.search ? location.search : ""),
        "_blank"
      )
      ?.focus();
  };

  const goToAdditionalEvidence = (id: number) => {
    props.history.push(`${getUrlPrefix(false)}evidence/details/${id}`, {
      backContext: {
        backTo: `${props.history.location.pathname}`,
        backToText: "Back to remediation request",
      },
    });
  };

  const onCreateRiskWaiver = useCallback(
    (riskID: string, waiverType: WaiverType) => {
      const subPath =
        waiverType === WaiverType.SeverityAdjustment
          ? "severityadjustments/add"
          : "add";

      const url = props.isUserRisk
        ? "/userbase/risk_modifications/create"
        : props.isSelfRemediation
          ? "/riskwaivers/add"
          : `${getUrlPrefix()}riskwaivers/${subPath}?initialRiskId=${riskID}`;
      let context: any = {
        backContext: {
          goBack: true,
          backToText: "Back to remediation request",
        },
      };
      if (props.isSelfRemediation) {
        context = { ...context, selectedRiskId: riskID, allWebsites: true };
      } else if (props.isUserRisk) {
        context = { ...context, riskId: riskID };
      }

      props.history.push(url, context);
    },
    [props.history, props.isSelfRemediation, props.isUserRisk]
  );

  const goToVendorAssessment = () => {
    if (!linkedVendorAssessment) {
      return;
    }
    (
      props.history as History<
        {
          selectedVersion?: number;
        } & locationState
      >
    ).push(
      `${getUrlPrefix()}assessment/${
        linkedVendorAssessment.assessment.streamID
      }`,
      {
        selectedVersion: props.details?.vendorAssessmentId,
        backContext: {
          backTo: `${props.history.location.pathname}`,
          backToText: "Back to remediation request",
        },
      }
    );
  };

  const doArchive = async () => {
    try {
      await props.updateRemediationRequestArchived(
        [props.requestId],
        !isArchived,
        props.isUserRisk,
        props.isSubsidiary,
        [props.vendorId]
      );
      await Promise.all([
        props.fetchRemediationRequestDetails(props.requestId, true),
        props.fetchRemediationRequestTimeline(props.requestId, true),
        props.fetchAlertsOrActivityStreamForOrgUser(true, true),
      ]);
    } catch (e) {
      console.error(e);
      props.addDefaultUnknownErrorAlert(
        "An error occurred updating the status of this remediation request"
      );
      throw e;
    }
  };

  const doAddRecipient = async () => {
    // add vendor contacts if this is for a vendor
    if (!props.isVendorPortal && props.vendorId && !props.isSubsidiary) {
      await Promise.all(
        newRecipients.map((nc) =>
          props.createVendorContact(
            props.vendorId || 0,
            nc.firstName + " " + nc.lastName,
            nc.title ?? "",
            nc.email
          )
        )
      );
      await props.fetchVendorMgtContacts(props.vendorId, true);
    }

    const emailsToAdd = [] as string[];
    // only add new contacts if we have any valid ones, it could be that only vendor contacts have been added
    if (
      validateContactSelector(
        [],
        newRecipients,
        props.isSelfRemediation || props.isSubsidiary
      )
    ) {
      emailsToAdd.push(...newRecipients.map((n) => n.email));
    }
    emailsToAdd.push(...selectedVendorContacts);

    await props
      .addRemediationRequestUsers(
        props.requestId,
        emailsToAdd,
        message,
        emailsToRemove
      )
      .then(() =>
        Promise.all([
          props.fetchRemediationRequestUsers(props.requestId, true),
          props.fetchRemediationRequestTimeline(props.requestId, true),
          props.fetchRemediationRequestDetails(props.requestId, true),
        ])
      )
      .then(() => {
        setSelectedVendorContacts([]);
        clearNewRecipients();
        setEmailsToRemove([]);
      })
      .catch((e) => {
        if (!props.handleKnownErrors(e)) {
          props.addDefaultUnknownErrorAlert("Error adding recipients");
        }
        throw e;
      });
  };

  const doClose = async (message?: string) => {
    try {
      await props.updateRemediationRequestStatus(
        props.requestId,
        RemediationRequestStatus.Closed,
        props.isSubsidiary,
        props.vendorId,
        message
      );
      await Promise.all([
        props.fetchRemediationRequestDetails(props.requestId, true),
        props.fetchRemediationRequestTimeline(props.requestId, true),
        props.fetchAlertsOrActivityStreamForOrgUser(true, true),
      ]);
      props.addDefaultSuccessAlert("Completed remediation request");
    } catch (e) {
      props.addDefaultUnknownErrorAlert(
        "An error occurred updating the status of this remediation request"
      );
      throw e;
    }
  };

  const doReopen = async () => {
    try {
      await props.updateRemediationRequestStatus(
        props.requestId,
        RemediationRequestStatus.Open,
        props.isSubsidiary,
        props.vendorId
      );
      await Promise.all([
        props.fetchRemediationRequestDetails(props.requestId, true),
        props.fetchRemediationRequestTimeline(props.requestId, true),
        props.fetchAlertsOrActivityStreamForOrgUser(true, true),
      ]);
      props.addDefaultSuccessAlert("Reopened remediation request");
    } catch (e) {
      props.addDefaultUnknownErrorAlert(
        "An error occurred updating the status of this remediation request"
      );
      throw e;
    }
  };

  const doSubmit = async () => {
    try {
      await props.updateRemediationRequestStatus(
        props.requestId,
        RemediationRequestStatus.AwaitingReview,
        props.isSubsidiary,
        props.vendorId,
        submitMessage
      );
      await Promise.all([
        props.fetchRemediationRequestDetails(props.requestId, true),
        props.fetchRemediationRequestTimeline(props.requestId, true),
        props.fetchRemediationRequestMessages(props.requestId, true, false),
      ]);
      if (!props.isVendorPortal) {
        await props.fetchAlertsOrActivityStreamForOrgUser(true, true);
      } else {
        // If we are in the VendorPortal, refetch the survey list which was cleared by updateRemediationRequestStatus
        await props.fetchVendorAppSurveyList(true, true);
      }

      props.addDefaultSuccessAlert("Remediation request submitted for review");
    } catch (e) {
      props.addDefaultUnknownErrorAlert(
        "An error occurred updating the status of this remediation request"
      );
      throw e;
    }
  };

  const onClickCve = (cveName: string) => {
    OpenSidePanel(props.history, { vuln: cveName, verified: false });
  };

  const onSelectRisk = (riskId: string | undefined) => {
    if (!riskId) {
      return;
    }

    setSelectedRiskParams(undefined);
    OpenSidePanel(props.history, { risk: riskId });
  };

  const onSelectedRiskParamsChange = useCallback(
    (riskPanel?: JSX.Element, getVulnPanel?: GetVulnPanel) => {
      setSelectedRiskParams({
        riskPanel: riskPanel,
        getVulnPanel: getVulnPanel,
      });
    },
    []
  );

  const onOpenCloudscanPanelFromRiskPanel = useCallback(
    (scan: string, riskTitle: string, risk?: string) => {
      OpenSidePanel(
        props.history,
        { scan: scan, risk: risk },
        "'" + riskTitle + "'",
        props.location
      );
    },
    [props.history, props.location]
  );

  const onOpenCVEPanelFromRiskPanel = useCallback(
    (riskId: string, riskTitle: string, vuln: string, verified?: boolean) => {
      OpenSidePanel(
        props.history,
        { vuln, verified: verified ?? false, risk: riskId },
        "'" + riskTitle + "'",
        props.location
      );
    },
    [props.history, props.location]
  );

  const { totalMessages, unreadMessages } = useMessageCounts(
    props.messages?.messages
  );

  const vendorName =
    props.isVendorPortal || !props.vendorName
      ? props.details
        ? props.details.vendorId && !!props.details.vendorName
          ? props.details.vendorName
          : props.details.organisationId && !!props.details.orgName
            ? props.details.orgName
            : "Unknown"
        : "Unknown"
      : props.vendorName;

  const hasPublicSurveys = surveyRisks.some((sr) =>
    sr.surveys.some((s) => s.publicSurveyId)
  );

  const hasAdditionalEvidenceRisks = additionalEvidenceRisks.length > 0;

  let vendorPortalMsg = (
    <>
      When you’re ready to submit this remediation request for review, click
      &quot;Submit for review&quot;.
    </>
  );
  if (props.isVendorPortal && hasAdditionalEvidenceRisks) {
    vendorPortalMsg = (
      <p>
        View the risks raised in the{" "}
        <a href={`#${remediationRisksElementId}`}>Risks</a> section at the
        bottom of this page. Add any compensating control information in
        &quot;Messages&quot;. When you’re finished, click &quot;Submit for
        review&quot;.
      </p>
    );
  }

  let messages = props.messages?.messages;
  if (messages && messages.length > 0) {
    // disable editing of the first message (the last in the array since its in reverse order)
    // because the first message is generated from the remediation request message which is
    // markdown and we don't support that in the message panel yet
    messages = [
      ...messages.slice(0, -1),
      {
        ...messages[messages.length - 1],
        allowSelfEdit: false,
      },
    ];
  }

  const breadcrumbs: crumb[] = [];
  if (props.isVendorPortal) {
    breadcrumbs.push(
      {
        text: "Remediation Requests",
        to: props.remediationTaskListBasePathOverride ?? "/vendors/remediation",
      },
      { text: "Remediation Request" }
    );
  } else if (props.isSubsidiary) {
    breadcrumbs.push(
      { text: "Subsidiaries", to: "/subsidiaries" },
      { text: vendorName, to: `/subsidiaries/${props.vendorId}/risk_profile` },
      {
        text: "Remediation",
        to: `/subsidiaries/${props.vendorId}/remediation`,
      },
      { text: "Remediation Request" }
    );
  } else if (props.isUserRisk) {
    breadcrumbs.push(
      { text: "Remediation", to: `/userbase/remediation` },
      { text: "Remediation Request" }
    );
  } else if (!!props.vendorId) {
    breadcrumbs.push(
      ...getVendorPageBreadcrumbs(
        props.vendorId ?? 0,
        vendorName,
        props.assuranceType
      ),
      { text: "Remediation", to: `/vendor/${props.vendorId}/remediation` },
      { text: "Remediation Request" }
    );
  } else {
    breadcrumbs.push(
      { text: "Remediation", to: `/selfremediation` },
      { text: "Remediation Request" }
    );
  }

  return (
    <div className={"remediation-request-details-v2"}>
      {props.isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={props.history}
        title="Remediation Request"
        backAction={goBack}
        backText={backToText}
        isSubsidiary={props.isSubsidiary}
        vendorId={props.vendorId}
        isManagementAnalystSession={props.isManagementAnalystSession}
        managedOrgId={props.managedOrgId}
        breadcrumbs={breadcrumbs}
        rightSection={
          <>
            {!props.isVendorPortal && (
              <Button onClick={() => setExportModalOpen(true)}>
                <div className="cr-icon-export" /> Export
              </Button>
            )}
          </>
        }
      />
      <div className={"tabs-and-info"}>
        {!props.details?.deletedAt && props.isVendorPortal && (
          <DismissableBanner
            localStorageKey={"remediation-request-details-v2"}
            message={"Thanks for taking the time to remediate these risks."}
            subItems={[vendorPortalMsg]}
          />
        )}
        {props.isVendorPortal &&
          hasPublicSurveys &&
          !props.details?.isUserPartOfVerifiedVendorOrgForVendor && (
            <InfoBanner
              type={BannerType.WARNING}
              message={
                "This remediation request contains risks raised as part of a shared questionnaire"
              }
              subItems={[
                "Please contact the account administrator for access.",
              ]}
            />
          )}
        <TabButtons
          onChangeTab={(id) => setSelectedTab(id as tabSelection)}
          tabs={[
            {
              id: "overview",
              text: "Overview",
            },
            {
              id: "timeline",
              text: "Timeline",
            },
          ]}
          activeTabId={selectedTab}
        />
      </div>
      {!props.details || !props.users || !props.timeline || !props.messages ? (
        <LoadingBanner />
      ) : (
        <>
          {selectedTab === "overview" ? (
            <>
              <RemediationDetailsSummaryCard
                details={props.details}
                users={props.users}
                setAddRecipientsModalOpen={() =>
                  setAddRecipientsModalOpen(true)
                }
                setMessagesOpen={() => setMessagesOpen(true)}
                setArchiveModalOpen={() => setArchiveModalOpen(true)}
                setCloseModalOpen={() =>
                  threatMonitoringRisks.length > 0
                    ? openRemediateThreatModal({
                        remediationRequestID: props.requestId,
                        onSubmit: doClose,
                      })
                    : setCloseModalOpen(true)
                }
                setSubmitModalOpen={() => setSubmitModalOpen(true)}
                progress={progress}
                onUpdateTitle={(title) =>
                  props.updateRemediationRequestTitle(
                    props.requestId,
                    title,
                    props.isSubsidiary
                  )
                }
                userHasWriteRemediationPermission={props.userHasWritePermission}
                userHasWritePermsInAnyPortfolio={
                  props.userHasWritePermsInAnyPortfolio
                }
                onUpdateDueDate={(dueDate, reminderDate) =>
                  props.updateRemediationRequestDueDate(
                    props.requestId,
                    dueDate,
                    reminderDate
                  )
                }
                isVendorPortal={props.isVendorPortal}
                goToRiskAssessment={goToVendorAssessment}
                riskAssessmentStarted={
                  linkedVendorAssessment?.assessment.createdAt
                }
                isArchived={isArchived}
                unreadMessages={unreadMessages}
                totalMessages={totalMessages}
                sharedDocuments={props.details.sharedEvidence ?? []}
                downloadDocument={(doc) =>
                  DownloadEvidenceFromRemediationRisk(
                    doc,
                    props.isVendorPortal,
                    props.dispatch
                  )
                }
              />
              <RemediationDetailsRisksTable
                id={remediationRisksElementId}
                vendorId={props.vendorId}
                isSubsidiary={props.isSubsidiary}
                surveyRisks={surveyRisks}
                cloudscanRisks={cloudscanRisks}
                additionalEvidenceRisks={additionalEvidenceRisks}
                saasRisks={saasRisks}
                threatMonitoringRisks={threatMonitoringRisks}
                isVendorPortal={props.isVendorPortal}
                isSelfRemediation={props.isSelfRemediation}
                onSelectRisk={onSelectRisk}
                onSelectedRiskParamsChange={onSelectedRiskParamsChange}
                onClickCve={onClickCve}
                onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
                onOpenCloudscanPanelFromRiskPanel={
                  onOpenCloudscanPanelFromRiskPanel
                }
                onClickAssetInVulnPanel={(
                  currentPanelTitle: string,
                  asset: string
                ) => {
                  OpenSidePanel(
                    props.history,
                    { scan: asset },
                    "'" + currentPanelTitle + "'",
                    props.location
                  );
                }}
                goToSurvey={goToSurvey}
                goToSurveyRisk={goToQuestionnaireRisk}
                onCreateRiskWaiver={onCreateRiskWaiver}
                hasSurveyRisksRemoved={props.details.risksRemoved}
                domainsFilteredOut={props.details.domainsFilteredOut ?? false}
                isEditable={
                  props.userHasWritePermission &&
                  (props.details.status === RemediationRequestStatus.Open ||
                    props.details.status ===
                      RemediationRequestStatus.AwaitingReview) &&
                  !props.isUserRisk &&
                  threatMonitoringRisks.length === 0
                }
                history={props.history}
                vendorAssessmentId={props.details.vendorAssessmentId}
                userHasWaiveRiskPermission={
                  props.userHasWritePermission && !props.isSubsidiary
                }
                goToAdditionalEvidence={goToAdditionalEvidence}
                userHasRemediateRiskPermission={
                  props.userHasWritePermission &&
                  !props.isSubsidiary &&
                  !props.isVendorPortal
                }
                onMarkAsRemediated={
                  props.userHasWritePermission &&
                  (props.details.status === RemediationRequestStatus.Open ||
                    props.details.status ===
                      RemediationRequestStatus.AwaitingReview)
                    ? (checkId: string, justification?: string) =>
                        props.markRiskAsRemediated(
                          props.requestId,
                          checkId,
                          props.vendorId,
                          justification
                        )
                    : undefined
                }
                requestId={props.requestId}
                sharedDocuments={props.details.sharedEvidence ?? []}
                downloadDocument={(doc) =>
                  DownloadEvidenceFromRemediationRisk(
                    doc,
                    props.isVendorPortal,
                    props.dispatch
                  )
                }
              />
              {!props.isUserRisk && threatMonitoringRisks.length === 0 && (
                <RemediationDetailsScoreProjectionCard
                  isVendorPortal={props.isVendorPortal}
                  isSelfRemediation={props.isSelfRemediation}
                  vendorName={vendorName}
                  initialScore={props.projection?.initialScore ?? 0}
                  currentScore={props.projection?.currentScore ?? 0}
                  projectedScore={props.projection?.projectedScore ?? 0}
                  loading={!props.projection || props.projection.loading}
                />
              )}
            </>
          ) : (
            <Timeline
              users={props.timeline.users}
              items={props.timeline.items}
              noun={"remediation request"}
              showUsersAdded
              showUsersRemoved
              showRisksAdded
              showRisksRemoved
              showRisksUpdated
              showDocumentsAdded
              showDocumentsRemoved
            />
          )}
        </>
      )}
      <ConfirmationModalV2
        title={
          !isOpen
            ? "Are you sure you want to reopen this request?"
            : "Are you sure you want to mark this as completed?"
        }
        buttonAction={!isOpen ? doReopen : doClose}
        active={closeModalOpen}
        onClose={() => setCloseModalOpen(false)}
        description={
          !isOpen
            ? "This will allow recipients of this request to continue remediating the identified issues."
            : "Marking a remediation request as completed indicates to the recipients that you accept any open risks."
        }
        iconClass={"cr-icon-check"}
        buttonText={!isOpen ? "Mark as open" : "Mark as completed"}
        cancelText={"Cancel"}
      />
      <ConfirmationModalV2
        title={
          isArchived
            ? "Are you sure you want to unarchive this?"
            : "Are you sure you want to archive this?"
        }
        buttonAction={doArchive}
        active={archiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        description={
          isArchived
            ? "The request will remain completed."
            : "Archiving a remediation request will also mark it as completed."
        }
        iconClass={"cr-icon-archive"}
        buttonText={`Yes, ${isArchived ? "un" : ""}archive`}
        cancelText={"Cancel"}
      />
      <ConfirmationModalV2
        title={"Would you like to submit for review?"}
        description={
          <>
            <p>
              By submitting this request for review, you are implying that you
              consider the identified risks to be remediated or not applicable.
            </p>
            <h4>Message</h4>
            <TextField
              value={submitMessage ?? ""}
              onChanged={setSubmitMessage}
              multiLine
              maxLength={4000}
              maxRows={50}
            />
          </>
        }
        descriptionClassName="submit-message"
        buttonAction={doSubmit}
        active={submitModalOpen}
        onClose={() => setSubmitModalOpen(false)}
        iconClass={"cr-icon-check"}
        cancelText={"Cancel"}
        buttonText={"Submit for review"}
      />
      <AddRecipientsModal
        onClose={() => {
          setAddRecipientsModalOpen(false);
          setEmailsToRemove([]);
          clearNewRecipients();
          setMessage(originalMessage);
        }}
        active={addRecipientsModalOpen}
        newContacts={newRecipients}
        addNewContact={addNewRecipient}
        deleteNewContact={deleteNewRecipient}
        updateNewContact={updateNewRecipient}
        onSubmit={doAddRecipient}
        existingRecipientEmails={[
          ...(props.users?.sharedUsers.map((value) => value.email) ?? []),
          ...(props.users?.invitedEmails ?? []),
        ]}
        emailsToRemove={emailsToRemove}
        setEmailsToRemove={setEmailsToRemove}
        vendorContacts={props.contacts}
        newlySelectedVendorContactEmails={selectedVendorContacts}
        setSelectedVendorContactEmails={setSelectedVendorContacts}
        message={message}
        onMessageChange={setMessage}
        emailOnly={props.isSelfRemediation || props.isSubsidiary}
        hasPublicSurveys={hasPublicSurveys && !props.isVendorPortal}
        vendorId={props.vendorId}
        vendorName={props.vendorName}
        isVendorPortal={props.isVendorPortal}
      />
      <SlidePanel
        newStyles
        onClose={() => setMessagesOpen(false)}
        active={messagesOpen}
        dimContent
        className={"messages-panel-container"}
        title={"Messages"}
      >
        <MessagesPanel
          hasWritePermission={
            props.isVendorPortal || props.userHasWritePermission
          }
          allowedPrivateMessages={!props.isVendorPortal}
          currentUserId={props.currentUserId}
          editMessage={props.editRemediationRequestMessage}
          otherVendorName={otherVendorName}
          publicReplyHelp={
            "Public messages will be visible to members of your account as well as any users added to this request."
          }
          addMessage={(parentId, content, isPrivate) =>
            props.addRemediationRequestMessage(
              props.requestId,
              parentId,
              content,
              isPrivate
            )
          }
          fetchMessages={(force) =>
            props.fetchRemediationRequestMessages(props.requestId, force, false)
          }
          messages={messages}
          users={props.messages?.users}
        />
      </SlidePanel>
      {props.details && (
        <DomainsAndIPsPanelGroup
          dispatch={dispatch}
          history={props.history}
          isSubsidiary={props.isSubsidiary}
          vendorId={props.vendorId ?? props.details?.vendorId}
          isVendorPortal={props.isVendorPortal}
          isManagedVendorAnalyst={props.isManagedVendorAnalyst}
          managedOrgId={props.managedOrgId}
          location={props.location}
          remediationRequestId={props.details?.id}
          riskPanel={selectedRiskParams?.riskPanel}
          getVulnPanel={selectedRiskParams?.getVulnPanel}
        />
      )}
      <ExportReportModal
        active={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        title={"Export remediation request details"}
        exportType={ExportType.RemediationRequestDetails}
        exportOptions={{
          remediation_request_id: props.requestId,
          vendor_id: props.vendorId,
          customer: !props.vendorId && !props.isSubsidiary,
          is_subsidiary: props.isSubsidiary,
          is_userrisk_remediation: props.isUserRisk,
          ...exportOptions,
        }}
        isManagementAnalystSession={props.isManagementAnalystSession}
        managedOrgId={props.managedOrgId}
        vendorId={props.vendorId}
        isSubsidiary={props.isSubsidiary}
        supportedFilters={[]}
        supportedFileTypes={ExportFiletypesBoth}
        exportTypeSelected={(filetype) => setSelectedFiletype(filetype)}
      >
        <RemediationDetailsExportOptions
          state={exportOptions}
          dispatch={exportOptionsDispatch}
          selectedFiletype={selectedFiletype}
          isUserRisk={props.isUserRisk}
        />
      </ExportReportModal>
      {remediateThreatModal}
    </div>
  );
};

RemediationRequestDetailsV2.defaultProps = {
  managedOrgId: undefined,
  details: undefined,
  timeline: undefined,
  messages: undefined,
  users: undefined,
};

export default appConnect<
  IRemediationRequestDetailsV2ConnectedProps,
  IRemediationRequestDetailsV2DispatchProps,
  IRemediationRequestDetailsV2OwnProps
>(
  (state, props) => {
    const isSelfRemediation = props.match.path.includes("selfremediation");

    const requestId = props.match.params.requestId
      ? parseInt(props.match.params.requestId)
      : 0;

    const userSystemRoles: Record<string, boolean> =
      state.common.userData.system_roles.reduce((prev, perm) => {
        return { ...prev, [perm]: true };
      }, {});

    const userIsManagedVendorAnalyst =
      userSystemRoles[UserSystemRoleVendorManagementAnalyst];

    const isManagementAnalystSession =
      userIsManagedVendorAnalyst &&
      props.match.path.startsWith("/analysts/tpvm");
    let orgId;
    if (isManagementAnalystSession) {
      orgId = props.match.params.orgId
        ? parseInt(props.match.params.orgId)
        : undefined;
    }

    const isSubsidiary = props.match.path.startsWith("/subsidiaries");
    const isUserRisk =
      props.match.path.startsWith(userRiskUrlPrefix) ||
      (props.isVendorPortal &&
        props.match.path.startsWith(
          `/${tasksRouterUrlPrefix}/${userRiskTaskUrlPrefix}`
        ));

    const vendorId = props.match.params.vendorId
      ? parseInt(props.match.params.vendorId)
      : undefined;

    let allowedPrivateMessages = false;
    let contacts;

    const request = state.common.remediationRequests[requestId] ?? {};

    if (
      !props.isVendorPortal &&
      !isSubsidiary &&
      request.details &&
      request.details.vendorId
    ) {
      allowedPrivateMessages = true;

      contacts = _get(
        state.cyberRisk.vendors[request.details.vendorId],
        "mgtlists.contacts.result",
        undefined
      );
    }

    let vendorName = "";
    let vendorPrimaryDomain = "";
    let userHasWritePermsInAnyPortfolio = false;
    if (!vendorId) {
      const currentOrg = getCurrentOrgFromUserData(state.common.userData);
      if (currentOrg) {
        vendorName = currentOrg.name;
        vendorPrimaryDomain = currentOrg.mainHostname;
      }

      userHasWritePermsInAnyPortfolio = !!Object.values(
        state.common.userData.domainPortfolioSpecificPermissions
      ).find((p) => p?.includes(UserBreachsightWrite));
    } else {
      const vendorObj = getVendorObject(state, vendorId, isSubsidiary, orgId);
      if (vendorObj) {
        vendorName = vendorObj.name;
        vendorPrimaryDomain = vendorObj.primary_hostname;
      }
    }

    let userHasWritePermission = false;
    const userPerms = getUserPermissionsForVendorFromState(
      state,
      isSubsidiary || props.isVendorPortal || !vendorId ? 0 : vendorId
    );
    if (!props.isVendorPortal) {
      if (isSubsidiary || !vendorId) {
        userHasWritePermission = !!userPerms[UserBreachsightWrite];
      } else {
        userHasWritePermission = !!userPerms[UserVendorRiskWrite];
      }

      // Self remediation requests can have a canWrite flag set if the user has permission for this specific one
      if (!isSubsidiary && !vendorId && request.details?.canWrite) {
        userHasWritePermission = true;
      }
    }

    const connectProps: IRemediationRequestDetailsV2ConnectedProps = {
      currentUserId: state.common.userData.id,
      requestId,
      isManagementAnalystSession,
      isManagedVendorAnalyst:
        userIsManagedVendorAnalyst &&
        isManagementAnalystSession &&
        (orgId ?? 0) > 0,
      isSubsidiary,
      isUserRisk,
      isVendor: !!props.match.params.vendorId,
      managedOrgId: orgId,
      vendorId,
      allowedPrivateMessages,
      contacts,
      vendorName,
      vendorPrimaryDomain,
      isSelfRemediation,
      allCloudscans: state.cyberRisk.webscans,
      userHasWritePermission,
      userHasWritePermsInAnyPortfolio,
      assuranceType: state.common.userData.assuranceType,
      ...request,
    };

    return connectProps;
  },
  (
    dispatch: DefaultThunkDispatch
  ): IRemediationRequestDetailsV2DispatchProps => {
    return {
      dispatch,
      fetchRemediationRequestDetails: (requestId, force) =>
        dispatch(fetchRemediationRequestDetails(requestId, force)),
      fetchRemediationRequestTimeline: (requestId, force) =>
        dispatch(fetchRemediationRequestTimeline(requestId, force)),
      fetchRemediationRequestMessages: (
        requestId,
        force,
        noMarkRead,
        noCache = false
      ) =>
        dispatch(
          fetchRemediationRequestMessages(requestId, force, noMarkRead, noCache)
        ),
      fetchRemediationRequestUsers: (requestId, force) =>
        dispatch(fetchRemediationRequestUsers(requestId, force)),
      refreshUserNotifications: (force) =>
        dispatch(fetchAlertsOrActivityStreamForOrgUser(force, true)),
      fetchVendorWatchStatus: (vendorId) =>
        dispatch(fetchVendorWatchStatus(vendorId)),
      fetchVendorMgtContacts: (vendorId, force) =>
        dispatch(fetchVendorMgtContacts(vendorId, force)),
      fetchVendorSummaryAndCloudscans: (vendorId, isSubsidiary) =>
        dispatch(
          fetchVendorSummaryAndCloudscans(
            vendorId,
            false,
            false,
            false,
            isSubsidiary
          )
        ),
      updateRemediationRequestArchived: (
        requestIds,
        newArchived,
        isUserRisk,
        isSubsidiary,
        vendorIDs
      ) =>
        dispatch(
          updateRemediationRequestArchived(
            requestIds,
            newArchived,
            isUserRisk,
            isSubsidiary,
            vendorIDs
          )
        ),
      updateRemediationRequestStatus: (
        requestId,
        newStatus,
        isSubsidiary,
        vendorID,
        message
      ) =>
        dispatch(
          updateRemediationRequestStatus(
            requestId,
            newStatus,
            isSubsidiary,
            vendorID,
            message
          )
        ).then(() => {
          if (message) {
            // mark our new message read
            dispatch(fetchRemediationRequestMessages(requestId, true, false));
          }
        }),
      addSimpleErrorAlert: (message) => dispatch(addSimpleErrorAlert(message)),
      addDefaultUnknownErrorAlert: (errorText) =>
        dispatch(addDefaultUnknownErrorAlert(errorText)),
      conditionalRefreshActivityStreamForOrgUser: () =>
        dispatch(conditionalRefreshActivityStreamForOrgUser()),
      fetchAlertsOrActivityStreamForOrgUser: (forced, background) =>
        dispatch(fetchAlertsOrActivityStreamForOrgUser(forced, background)),
      addDefaultSuccessAlert: (text) => dispatch(addDefaultSuccessAlert(text)),
      addRemediationRequestMessage: (
        remediationRequestId,
        parentId,
        content,
        is_private
      ) =>
        dispatch(
          addRemediationRequestMessage(
            remediationRequestId,
            parentId,
            content,
            is_private
          )
        ).then(() =>
          dispatch(
            fetchRemediationRequestMessages(remediationRequestId, true, false)
          )
        ),
      editRemediationRequestMessage: (messageId, content) =>
        dispatch(editRemediationRequestMessage(messageId, content)),
      fetchProjectionForExistingRemediationRequest: (remediationRequestId) =>
        dispatch(
          fetchProjectionForExistingRemediationRequest(remediationRequestId)
        ),
      createVendorContact: (vendorId, name, title, emailAddress) =>
        dispatch(createVendorContact(vendorId, { name, title, emailAddress })),
      addRemediationRequestUsers: (
        requestId,
        emails,
        message,
        emailsToRemove
      ) =>
        dispatch(
          addRemediationRequestUsers(requestId, emails, message, emailsToRemove)
        ),
      updateRemediationRequestTitle: (
        remediationRequestId,
        title,
        isSubsidiary
      ) =>
        dispatch(
          updateRemediationRequestTitle(
            remediationRequestId,
            title,
            isSubsidiary
          )
        )
          .then(() => dispatch(addDefaultSuccessAlert("Title updated")))
          .catch(() =>
            dispatch(addDefaultUnknownErrorAlert("Unable to update title"))
          ),
      updateRemediationRequestDueDate: (
        remediationRequestId,
        dueDate,
        reminderDate
      ) =>
        dispatch(
          updateRemediationRequestDueDate(
            remediationRequestId,
            dueDate,
            reminderDate
          )
        )
          .then(() => dispatch(addDefaultSuccessAlert("Dates updated")))
          .catch(() =>
            dispatch(addDefaultUnknownErrorAlert("Unable to update dates"))
          ),
      handleKnownErrors: (e) => dispatch(handleKnownErrors(e)),
      markRiskAsRemediated: (requestId, checkId, vendorID, justification) =>
        dispatch(
          markRemediationRequestRiskAsRemediated({
            requestId,
            checkId,
            justification: justification ?? "",
          })
        ).then(() => {
          dispatch(conditionalRefreshActivityStreamForOrgUser());
          dispatch(fetchRemediationRequestDetails(requestId, true));
          if (vendorID) {
            dispatch(
              fetchVendorSummaryAndCloudscans(
                vendorID,
                true,
                false,
                false,
                false
              )
            );
          }
        }),
      fetchVendorAppSurveyList: (forceRefresh: boolean, background: boolean) =>
        dispatch(fetchVendorAppSurveyList(forceRefresh, background)),
    };
  }
)(RemediationRequestDetailsV2);

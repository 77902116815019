import { useAppSelector } from "../types/reduxHooks";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../types/reduxStore";

const currentOrgSelector = createSelector(
  [
    (state: RootState) => state.common.userData.currentOrgID,
    (state: RootState) => state.common.userData.orgList,
  ],
  (currentOrgID, orgList) => orgList?.find((o) => o.id === currentOrgID)
);
export const useCurrentOrg = () => useAppSelector(currentOrgSelector);

export const useCurrentUser = () =>
  useAppSelector((state) => state.common.userData);

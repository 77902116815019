import {
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../_common/types/survey";
import { ContentLibraryDocumentRedacted } from "../../contentlibrary/types/contentLibrary.types";
import CCPABadge from "../../_common/images/verifiedvendor/compliance-badge-CCPA.svg";
import CIS71Badge from "../../_common/images/verifiedvendor/compliance-badge-CIS-7.1.svg";
import CIS81Badge from "../../_common/images/verifiedvendor/compliance-badge-CIS-8.1.svg";
import CPS230Badge from "../../_common/images/verifiedvendor/compliance-badge-CPS-230.svg";
import CPS234Badge from "../../_common/images/verifiedvendor/compliance-badge-CPS-234.svg";
import CSABadge from "../../_common/images/verifiedvendor/compliance-badge-CSA.svg";
import CSASTARBadge from "../../_common/images/verifiedvendor/compliance-badge-CSA-STAR.svg";
import DORABadge from "../../_common/images/verifiedvendor/compliance-badge-DORA.svg";
import DPDPBadge from "../../_common/images/verifiedvendor/compliance-badge-DPDP.svg";
import DPFBadge from "../../_common/images/verifiedvendor/compliance-badge-DPF.svg";
import EssentialEightBadge from "../../_common/images/verifiedvendor/compliance-badge-Essential-Eight.svg";
import EuCloudCOCBadge from "../../_common/images/verifiedvendor/compliance-badge-EU-Cloud-COC.svg";
import GDPRBadge from "../../_common/images/verifiedvendor/compliance-badge-GDPR.svg";
import HECVATBadge from "../../_common/images/verifiedvendor/compliance-badge-HECVAT.svg";
import HIPAABadge from "../../_common/images/verifiedvendor/compliance-badge-HIPAA.svg";
import HITRUSTR2Badge from "../../_common/images/verifiedvendor/compliance-badge-HITRUST-R2.svg";
import ISO22301Badge from "../../_common/images/verifiedvendor/compliance-badge-ISO-22301.svg";
import ISO27001Badge from "../../_common/images/verifiedvendor/compliance-badge-ISO-27001.svg";
import ISO270012013Badge from "../../_common/images/verifiedvendor/compliance-badge-ISO-27001-2013.svg";
import ISO27001SoABadge from "../../_common/images/verifiedvendor/compliance-badge-ISO-27001-SoA.svg";
import ISO27017Badge from "../../_common/images/verifiedvendor/compliance-badge-ISO-27017.svg";
import ISO27018Badge from "../../_common/images/verifiedvendor/compliance-badge-ISO-27018.svg";
import ISO277012019Badge from "../../_common/images/verifiedvendor/compliance-badge-ISO-27701-2019.svg";
import NISTSP800Badge from "../../_common/images/verifiedvendor/compliance-badge-NIST-SP-800-53-Rev5.svg";
import NISTCSF11Badge from "../../_common/images/verifiedvendor/compliance-badge-NIST-CSFv1.1.svg";
import NISTCSF20Badge from "../../_common/images/verifiedvendor/compliance-badge-NIST-CSFv2.0.svg";
import PCIDSSBadge from "../../_common/images/verifiedvendor/compliance-badge-PCI-DSS.svg";
import PIPEDABadge from "../../_common/images/verifiedvendor/compliance-badge-PIPEDA.svg";
import SIGBadge from "../../_common/images/verifiedvendor/compliance-badge-SIG.svg";
import SOC1Badge from "../../_common/images/verifiedvendor/compliance-badge-SOC-1.svg";
import SOC2Badge from "../../_common/images/verifiedvendor/compliance-badge-SOC-2.svg";
import SOC2TypeIIBadge from "../../_common/images/verifiedvendor/compliance-badge-SOC-2-Type-II.svg";
import SOC3Badge from "../../_common/images/verifiedvendor/compliance-badge-SOC-3.svg";
import UKCyberEssentialsBadge from "../../_common/images/verifiedvendor/compliance-badge-UK-Cyber-Essentials.svg";
import VPATBadge from "../../_common/images/verifiedvendor/compliance-badge-VPAT.svg";
import { Evidence, EvidenceCategory } from "../../vendorrisk/types/evidence";
import { ISharedAssessmentAddToProfileSurvey } from "../../vendorrisk/types/sharedAssessment";

// Types for new Trust page components
// Separated from original types to avoid changes to existing components

export interface ISurveyRecord {
  id: number;
  originalSurveyId?: number;
  name?: string;
  kind: "prefilled" | "private";
  type?: string;
  description?: string;
  status: SurveyStatus;
  vendorId: number;
  vendorName?: string;
  dateCompleted?: string;
  userHasAccess: boolean;
  userCanViewOriginalSurvey: boolean;
}

export const mapISurveyListItemResponseToSurveyRecord = (
  survey: ISurveyListItemResponse,
  userHasAccess: boolean,
  userCanViewOriginalSurvey: boolean = false
): ISurveyRecord => {
  return {
    id: survey.id,
    originalSurveyId: survey.originalID,
    kind: "prefilled",
    name: survey.name,
    type: survey.type,
    description: survey.description,
    status: survey.status,
    vendorId: survey.vendorId,
    vendorName: survey.vendorName,
    dateCompleted: survey.dateCompleted,
    userHasAccess: userHasAccess,
    userCanViewOriginalSurvey: userCanViewOriginalSurvey,
  };
};

export const mapISharedAssessmentAddToProfileSurveyToISurveyRecord = (
  vendorId: number,
  survey: ISharedAssessmentAddToProfileSurvey,
  userHasAccess: boolean,
  userCanViewOriginalSurvey: boolean = false
): ISurveyRecord => {
  return {
    id: survey.surveyId,
    kind: "private",
    name: survey.name,
    vendorId: vendorId,
    status: SurveyStatus.New,
    description: survey.description,
    userHasAccess: userHasAccess,
    userCanViewOriginalSurvey: userCanViewOriginalSurvey,
  };
};

export interface IDocumentRecord {
  document: ContentLibraryDocumentRedacted;
  userHasAccess: boolean;
}

export const mapContentLibraryDocumentToIDocumentRecord = (
  document: ContentLibraryDocumentRedacted,
  userHasAccess: boolean
) => {
  return {
    document: document,
    userHasAccess: userHasAccess,
  };
};

export interface ISecurityLink {
  title: string;
  url: string;
  category: EvidenceCategory;
  createdAt?: string;
}

const evidenceCategoryToSecurityLinkTitleMap = {
  [EvidenceCategory.Security]: "Security Policy",
  [EvidenceCategory.Privacy]: "Privacy Policy",
  [EvidenceCategory.BugBounty]: "Bug Bounty Page",
  [EvidenceCategory.ToS]: "Terms & Conditions",
  [EvidenceCategory.Certifications]: "Certification",
  [EvidenceCategory.Other]: "Other",
};

export const mapEvidenceToISecurityLink = (evidence: Evidence) => {
  return {
    title: evidenceCategoryToSecurityLinkTitleMap[evidence.category],
    url: evidence.url,
    category: evidence.category,
    createdAt: evidence.createdAt,
  };
};

export enum ComplianceBadgeTypes {
  CCPA = "CCPA",
  CIS_7_1 = "CIS_7_1",
  CIS_8_1 = "CIS_8_1",
  CPS_230 = "CPS_230",
  CPS_234 = "CPS_234",
  CSA = "CSA",
  CSA_STAR = "CSA_STAR",
  DORA = "DORA",
  DPDP = "DPDP",
  DPF = "DPF",
  Essential_Eight = "Essential_Eight",
  EU_Cloud_COC = "EU_Cloud_COC",
  GDPR = "GDPR",
  HECVAT = "HECVAT",
  HIPAA = "HIPAA",
  HITRUST_R2 = "HITRUST_R2",
  ISO_22301 = "ISO_22301",
  ISO_27001 = "ISO_27001",
  ISO_27001_2013 = "ISO_27001_2013",
  ISO_27001_SoA = "ISO_27001_SoA",
  ISO_27017 = "ISO_27017",
  ISO_27018 = "ISO_27018",
  ISO_27701_2019 = "ISO_27701_2019",
  NIST_SP_800 = "NIST_SP_800",
  NIST_CSF_1_1 = "NIST_CSF_1_1",
  NIST_CSF_2_0 = "NIST_CSF_2_0",
  PCI_DSS = "PCI_DSS",
  SIG = "SIG",
  SOC_1 = "SOC_1",
  SOC_2 = "SOC_2",
  SOC_2_Type_II = "SOC_2_Type_II",
  SOC_3 = "SOC_3",
  UK_Cyber_Essentials = "UK_Cyber_Essentials",
  VPAT = "VPAT",
  PIPEDA = "PIPEDA",
}

export const ComplianceBadgeMap = {
  CCPA: { title: "CCPA", badgeImg: CCPABadge },
  CIS_7_1: { title: "CIS 7.1", badgeImg: CIS71Badge },
  CIS_8_1: { title: "CIS 8.1", badgeImg: CIS81Badge },
  CPS_230: { title: "CPS 230", badgeImg: CPS230Badge },
  CPS_234: { title: "CPS 234", badgeImg: CPS234Badge },
  CSA: { title: "CSA", badgeImg: CSABadge },
  CSA_STAR: { title: "CSA STAR", badgeImg: CSASTARBadge },
  DORA: { title: "DORA", badgeImg: DORABadge },
  DPDP: { title: "DPDP", badgeImg: DPDPBadge },
  DPF: { title: "DPF", badgeImg: DPFBadge },
  Essential_Eight: { title: "Essential Eight", badgeImg: EssentialEightBadge },
  EU_Cloud_COC: { title: "EU Cloud CoC", badgeImg: EuCloudCOCBadge },
  GDPR: { title: "GDPR", badgeImg: GDPRBadge },
  HECVAT: { title: "HECVAT", badgeImg: HECVATBadge },
  HIPAA: { title: "HIPAA", badgeImg: HIPAABadge },
  HITRUST_R2: { title: "HITRUST r2", badgeImg: HITRUSTR2Badge },
  ISO_22301: { title: "ISO 22301", badgeImg: ISO22301Badge },
  ISO_27001: { title: "ISO 27001", badgeImg: ISO27001Badge },
  ISO_27001_2013: { title: "ISO 27001:2013", badgeImg: ISO270012013Badge },
  ISO_27001_SoA: { title: "ISO 27001 SoA", badgeImg: ISO27001SoABadge },
  ISO_27017: { title: "ISO 27017", badgeImg: ISO27017Badge },
  ISO_27018: { title: "ISO 27018", badgeImg: ISO27018Badge },
  ISO_27701_2019: { title: "ISO 27701:2019", badgeImg: ISO277012019Badge },
  NIST_SP_800: { title: "NIST SP 800-53", badgeImg: NISTSP800Badge },
  NIST_CSF_1_1: { title: "NIST CSF v1.1", badgeImg: NISTCSF11Badge },
  NIST_CSF_2_0: { title: "NIST CSF v2.0", badgeImg: NISTCSF20Badge },
  PCI_DSS: { title: "PCI DSS", badgeImg: PCIDSSBadge },
  PIPEDA: { title: "PIPEDA", badgeImg: PIPEDABadge },
  SIG: { title: "SIG", badgeImg: SIGBadge },
  SOC_1: { title: "SOC 1", badgeImg: SOC1Badge },
  SOC_2: { title: "SOC 2", badgeImg: SOC2Badge },
  SOC_2_Type_II: { title: "SOC 2 Type II", badgeImg: SOC2TypeIIBadge },
  SOC_3: { title: "SOC 3", badgeImg: SOC3Badge },
  UK_Cyber_Essentials: {
    title: "UK Cyber Essentials",
    badgeImg: UKCyberEssentialsBadge,
  },
  VPAT: { title: "VPAT", badgeImg: VPATBadge },
};

import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/securityprofile/ControlPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import VendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import { formatDate } from "../../../_common/helpers";
import StatusIcon from "./StatusIcon";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import CheckResultLine from "./CheckResultLine";
import {
  ControlState,
  ControlStateIcon,
  ControlStateText,
} from "../../types/securityProfile";

interface IControlPanelProps {
  onClickBack?: () => void;
  vendorId: number;
  controlId: string;
}

const ControlPanel = (props: IControlPanelProps) => {
  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: props.vendorId,
    });
  const controlStates = securityProfile?.controlStates;

  const controlState = controlStates?.[props.controlId];

  const { data: controlData, isLoading: controlLoading } =
    VendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: props.vendorId,
      controlId: props.controlId,
    });
  const control = controlData?.control;

  if (controlLoading || !control) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  return (
    <div className="control-panel">
      {!!props.onClickBack && (
        <BackArrow popup={"Back"} onClick={props.onClickBack} />
      )}
      <h2>{control.text}</h2>
      <ScrollableDiv newStyles>
        <CheckItemSlidePanelSection
          title="Control details"
          startExpanded={true}
        >
          <div className="detail-table">
            <InfoTable>
              <InfoTableRow
                label="STATUS"
                rowClass="detail-row"
                valueClass="status-value"
                value={
                  <>
                    <StatusIcon
                      iconClass={
                        ControlStateIcon[controlState ?? ControlState.Unknown]
                      }
                      ariaLabel={
                        ControlStateText[controlState ?? ControlState.Unknown]
                      }
                      className={controlState ?? ControlState.Unknown}
                    />
                    <div>
                      {ControlStateText[controlState ?? ControlState.Unknown]}
                    </div>
                  </>
                }
              />
              <InfoTableRow
                rowClass="detail-row"
                label="LAST SCANNED"
                value={formatDate(control.lastScannedAt)}
              />
              <InfoTableRow
                rowClass="detail-row"
                label="OBJECTIVE"
                value={control.objective}
              />
            </InfoTable>
          </div>
        </CheckItemSlidePanelSection>
        <CheckItemSlidePanelSection title="Risks / Checks" startExpanded={true}>
          <div className="check-table">
            {control.checks.map((c) => (
              <CheckResultLine
                key={c.id}
                vendorId={props.vendorId}
                controlId={props.controlId}
                checkId={c.id}
              />
            ))}
          </div>
        </CheckItemSlidePanelSection>
      </ScrollableDiv>
    </div>
  );
};

export default ControlPanel;

import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useState } from "react";
import Button from "../../../_common/components/core/Button";
import { validUUIDRegExpExcludingNil } from "../../../vendorrisk/helpers/util";
import oauthAPI from "../../../_common/api/oauthAPI";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  fetchExistingOAuthConnections,
  MICROSOFT_CLIENT_CREDENTIALS_SERVICE,
} from "../../../vendorrisk/reducers/oauth.actions";
import ModalForm from "../../../vendorrisk/components/modals/ModalForm";
import TextField from "../../../_common/components/TextField";
import { usePollForScan } from "../../helpers/scanPoller";

const Microsoft365ClientCredentialsModal: FC<BaseModalProps> = ({
  active,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tenantId, setTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [processOAuth2ClientCredentials] =
    oauthAPI.useProcessOAuth2ClientCredentialsV1Mutation();

  const kickoffScanPolling = usePollForScan();

  const onSubmit = () => {
    setIsSubmitting(true);
    processOAuth2ClientCredentials({
      tenantId: tenantId,
      clientId: clientId,
      clientSecret: clientSecret,
      service: "Microsoft365ClientCredentials",
      isUserRisk: true,
    })
      .unwrap()
      .then(() =>
        dispatch(
          addDefaultSuccessAlert("Successfully saved Microsoft 365 credentials")
        )
      )
      .then(() =>
        dispatch(
          fetchExistingOAuthConnections(
            MICROSOFT_CLIENT_CREDENTIALS_SERVICE,
            "",
            false,
            false,
            true
          )
        )
      )
      .then(() => kickoffScanPolling())
      .then(() => onLocalClose())
      .catch((e) => {
        console.error(e);
        let errMsg = "Failed to save Microsoft 365 credentials.";
        if (
          e.status === 400 &&
          e.body &&
          (e.body.errorCode === "invalid_request" ||
            e.body.errorCode === "invalid_client" ||
            e.body.errorCode === "insufficient_permissions")
        ) {
          errMsg +=
            " Please check the credentials provided and that the application has the appropriate permissions.";
        }
        dispatch(addDefaultUnknownErrorAlert(errMsg));
      })
      .finally(() => setIsSubmitting(false));
  };

  const onLocalClose = () => {
    setTenantId("");
    setClientId("");
    setClientSecret("");
    onClose();
  };

  const hasValidTenantId = tenantId.match(validUUIDRegExpExcludingNil);
  const hasValidClientId = clientId.match(validUUIDRegExpExcludingNil);
  const hasValidClientSecret = clientSecret.length;
  const canSubmit =
    hasValidTenantId && hasValidClientId && hasValidClientSecret;

  return (
    <ModalV2
      active={active}
      onClose={onLocalClose}
      className="microsoft365-client-credentials-modal"
      headerContent={`Create Microsoft 365 OAuth Connection without user`}
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={onLocalClose}>
            Cancel
          </Button>
          <Button
            disabled={!canSubmit}
            loading={isSubmitting}
            onClick={onSubmit}
          >
            Save credentials
          </Button>
        </div>
      }
    >
      <ModalForm>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Application (client) ID</span>
            <p>
              The application ID that&apos;s assigned to your app. You can find
              this information in the portal where you registered your app.
            </p>
          </div>
          <div className="form-section-input">
            <div className="client-id">
              <TextField
                value={clientId}
                type={"text"}
                onChanged={(value) => setClientId(value)}
                placeholder={"Client ID"}
                minLength={1}
                maxLength={36}
                required={true}
                errorTexts={
                  !hasValidClientId ? ["Invalid Client ID, must be a UUID"] : []
                }
                inputMode={"text"}
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Directory (tenant) ID</span>
            <p>
              The directory tenant the application plans to operate against, in
              GUID or domain-name format.
            </p>
          </div>
          <div className="form-section-input">
            <div className="tenant-id">
              <TextField
                value={tenantId}
                type={"text"}
                onChanged={(value) => setTenantId(value)}
                placeholder={"Tenant ID"}
                minLength={1}
                maxLength={36}
                required={true}
                errorTexts={
                  !hasValidTenantId ? ["Invalid Tenant ID, must be a UUID"] : []
                }
                inputMode={"text"}
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Client Secret Value</span>
            <p>
              The client secret that you generated for your app in the app
              registration portal.
            </p>
          </div>
          <div className="form-section-input">
            <div className="client-secret">
              <TextField
                value={clientSecret}
                type={"password"}
                onChanged={(value) => setClientSecret(value)}
                placeholder={"Client Secret"}
                minLength={1}
                required={true}
                inputMode={"text"}
              />
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export default Microsoft365ClientCredentialsModal;

import { FC } from "react";

import AiIMG from "../../images/ai-icon.svg";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import "./AppUsingAI.scss";

export interface AppUsingAIProps {
  isUsingAI: boolean;
  showPopup?: boolean;
}

const AppUsingAI: FC<AppUsingAIProps> = ({ isUsingAI, showPopup }) => {
  // If the application is not using AI, do not show anything
  if (isUsingAI === false) {
    return <></>;
  }

  // Standard AI indicator
  const aiIndicator = (
    <div className="icon">
      <img src={AiIMG} />
    </div>
  );

  // Wrap the indicator in the popup if the showPopup is true
  return (
    <div className="app-using-ai">
      {showPopup ? (
        <SidePopupV2
          className="icon-display"
          text="This indicates the vendor of this application uses artificial intelligence (AI) to provide its services."
        >
          {aiIndicator}
        </SidePopupV2>
      ) : (
        aiIndicator
      )}
    </div>
  );
};

export default AppUsingAI;

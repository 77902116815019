import moment from "moment/moment";
import {
  getLocalStorageItemString,
  setLocalStorageItemString,
} from "../session";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../types/reduxHooks";

// This file provides functions for determining if a user should see in-app messages.
// When dismissed, the state for these messages should be stored in localStorage.

export enum InAppMessageType {
  TrustPageRedesignNewFeature = "TrustPageRedesignNewFeature",
  QuestionnaireBuilderImportNewFeature = "QuestionnaireBuilderImportNewFeature",
  TrustPageCustomDomainFeature = "TrustPageCustomDomainFeature",
}

// If a date is set for a message type, we will not show the message
// for any users created after that date.
const inAppMessageMaximumUserCreatedAt: Record<
  InAppMessageType,
  string | undefined
> = {
  [InAppMessageType.TrustPageRedesignNewFeature]: undefined,
  [InAppMessageType.QuestionnaireBuilderImportNewFeature]: undefined,
  [InAppMessageType.TrustPageCustomDomainFeature]: undefined,
};

const inAppMessageMaximumDateShown: Record<
  InAppMessageType,
  string | undefined
> = {
  [InAppMessageType.TrustPageRedesignNewFeature]: "2025-02-15T12:00:00Z",
  [InAppMessageType.QuestionnaireBuilderImportNewFeature]:
    "2025-04-10T12:00:00Z",
  [InAppMessageType.TrustPageCustomDomainFeature]: "2025-04-25T12:00:00Z",
};

export const localStorageKey = (messageType: InAppMessageType) =>
  `inAppMessageDismissed_${messageType}`;

const shouldShowInAppMessage = (
  messageType: InAppMessageType,
  userCreatedAt: string
) => {
  if (
    inAppMessageMaximumDateShown[messageType] &&
    moment().isAfter(moment(inAppMessageMaximumDateShown[messageType]))
  ) {
    return false;
  }

  if (
    inAppMessageMaximumUserCreatedAt[messageType] &&
    (!userCreatedAt ||
      moment(userCreatedAt).isAfter(
        moment(inAppMessageMaximumUserCreatedAt[messageType])
      ))
  ) {
    return false;
  }

  if (getLocalStorageItemString(localStorageKey(messageType))) {
    return false;
  }

  // Default to showing the message if no local storage item is found for this message
  return true;
};

export const dismissInAppMessage = (messageType: InAppMessageType) =>
  setLocalStorageItemString(localStorageKey(messageType), "true");

// Defines behaviour for setting in app messages as dismissed in local storage.
export enum InAppMessageDismissBehaviour {
  NoDismiss, // Message should not be automatically set as dismissed
  OnHover, // Message should be auto dismissed when the component is hovered over
  OnClick, // Message should be auto dismissed when the component is clicked
  OnRendered, // Message should be auto dismissed as soon as this component is rendered
}

interface IInAppMessageOwnProps {
  messageType: InAppMessageType;
  dismissBehaviour: InAppMessageDismissBehaviour;
  renderIfMessageShown: ReactNode;
  renderIfMessageHidden?: ReactNode;
}

const InAppMessage: FC<IInAppMessageOwnProps> = ({
  messageType,
  dismissBehaviour,
  renderIfMessageShown,
  renderIfMessageHidden,
}) => {
  const userCreatedAt = useAppSelector(
    (state) => state.common.userData.createdAt
  );
  const [shouldShow, setShouldShow] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setShouldShow(shouldShowInAppMessage(messageType, userCreatedAt));
  }, [messageType, userCreatedAt]);

  useEffect(() => {
    if (dismissBehaviour === InAppMessageDismissBehaviour.OnRendered) {
      // Immediately set to dismissed since this component was rendered
      dismissInAppMessage(messageType);
    }
  }, [messageType, dismissBehaviour]);

  useEffect(() => {
    if (hovered && dismissBehaviour === InAppMessageDismissBehaviour.OnHover) {
      // Set to dismissed only when the component is first hovered
      dismissInAppMessage(messageType);
    }
  }, [hovered, messageType, dismissBehaviour]);

  const onClick = useMemo(
    () =>
      shouldShow && dismissBehaviour === InAppMessageDismissBehaviour.OnClick
        ? () => dismissInAppMessage(messageType)
        : undefined,
    [dismissBehaviour, shouldShow, messageType]
  );

  const onMouseEnter = useMemo(
    () => (shouldShow && !hovered ? () => setHovered(true) : undefined),
    [shouldShow, hovered]
  );

  return (
    <div onClick={onClick} onMouseEnter={onMouseEnter}>
      {shouldShow ? renderIfMessageShown : renderIfMessageHidden}
    </div>
  );
};

export default InAppMessage;

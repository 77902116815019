import { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import ColorGrade from "./executive_summary/ColorGrade";
import { stringify as querystringify } from "querystring";
import { find as _find, get as _get } from "lodash";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";

import Button from "../../_common/components/core/Button";

import LoadingBanner from "../../_common/components/core/LoadingBanner";

import LoadingIcon from "../../_common/components/core/LoadingIcon";

import {
  severityMap,
  cloudscanSeverityNumberToText,
  isIPAddress,
  formatDateAsLocal,
} from "../../_common/helpers";
import {
  fetchCloudscanByHostname as fetchCloudscanByHostnameCommon,
  scanHostname as scanHostnameCommon,
} from "../../_common/reducers/commonActions";

import {
  fetchCloudscanByHostname,
  scanHostname,
  fetchChangesForCloudscan,
  setCloudscanData,
} from "../reducers/cyberRiskActions";

import Score from "./Score";
import ScrollableDiv from "./ScrollableDiv";

import "../style/components/CloudscanPanel.scss";
import { getLocalStorageItem } from "../../_common/session";
import { SlidePanelSection } from "./filter/SlidePanelSection";
import CVEMiniList, {
  GetCPEFromRiskID,
  GetCVEFromRiskID,
  IsCPERisk,
  IsCVERisk,
} from "./CVEMiniList";
import {
  fetchCustomerIPAddressDetails,
  fetchVendorIPAddressDetails,
  getIPAddressDetailsFromState,
} from "../reducers/ipAddresses.actions";

import { IPAddressInfoTable } from "./IPInfoTable";
import InfoBanner from "./InfoBanner";
import PillLabel from "./PillLabel";
import { LabelClassification, LabelColor } from "../../_common/types/label";
import {
  getUserPermissionsForVendorFromState,
  UserBreachsightWrite,
  UserVendorRiskWrite,
  UserVendorRiskEnabled,
} from "../../_common/permissions";
import LabelList from "./LabelList";
import { setLabelsForWebsites } from "../reducers/domainLabels.actions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { fetchIpAddressDetailsForVendorPortalRemediationRequest } from "../../vendor_portal/reducers/ipAddress.actions";
import { NoneReasonType } from "../types/webscans";
import KnownExploitedVulnPill from "./KnownExploitedVulnPill";
import { adjustLabels } from "../reducers/domains.actions";
import EditablePortfolioList from "./portfolios/EditablePortfolioList";
import { PortfolioType } from "../reducers/portfolios.actions";
import { appConnect } from "../../_common/types/reduxHooks";
import DetectedProductsMiniTable from "./detected_products/DetectedProductsMiniTable";
import { trackEvent } from "../../_common/tracking";
import { TourHighlightFromPLGContent } from "../../_common/components/TourHighlight";
import { ConfettiFullPage } from "../../_common/components/ConfettiFullPage";
import DomainSourceLabelList from "./domains/DomainSourceLabelList";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../_common/components/InfoTable";
import CloudProviderLogo from "../../appguard/components/CloudProviderLogo";
import { GenerateCyberRiskUrl } from "../../_common/api";
import { setPLGTaskCompleteIfIncomplete } from "../reducers/plgOnboardingChecklistActions";

class IPAddresses extends PureComponent {
  static propTypes = {
    hostname: PropTypes.string.isRequired,
    showHostnameLabel: PropTypes.bool,
    scanMeta: PropTypes.shape({
      aRecords: PropTypes.arrayOf(PropTypes.string),
      wwwARecords: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    onClickIP: PropTypes.func,
  };

  static defaultProps = { onClickIP: null };

  render() {
    const { aRecords, wwwARecords } = this.props.scanMeta;
    const { hostname } = this.props;

    const wwwHost = "www." + hostname;

    const hostsByIP = {};

    if (aRecords) {
      aRecords.forEach((ip) => {
        hostsByIP[ip] = [hostname];
      });
    }
    if (wwwARecords) {
      wwwARecords.forEach((ip) => {
        hostsByIP[ip] = hostsByIP[ip] ? [...hostsByIP[ip], wwwHost] : [wwwHost];
      });
    }

    const allIPs = Object.keys(hostsByIP);

    const hostColors = {
      [hostname]: LabelColor.Grey,
      [wwwHost]: LabelColor.Blue,
    };

    const rowClasses = classnames("ip-address-row", {
      clickable: !!this.props.onClickIP,
    });

    return (
      <div className="ip-addresses">
        {allIPs.map((ip) => (
          <div
            key={ip}
            className={rowClasses}
            onClick={
              this.props.onClickIP ? () => this.props.onClickIP(ip) : null
            }
          >
            <div className="ip-address-and-domains">
              <div className="ip-address">{ip}</div>
              <div className="ip-domain-labels">
                {this.props.showHostnameLabel &&
                  hostsByIP[ip].map((host) => (
                    <PillLabel
                      key={host}
                      color={hostColors[host]}
                      className="ip-domain-label"
                      capitalized={false}
                    >
                      {host}
                    </PillLabel>
                  ))}
              </div>
            </div>
            {this.props.onClickIP && <i className="cr-icon-chevron" />}
          </div>
        ))}
      </div>
    );
  }
}

const skippedReasonMap = {
  [NoneReasonType.BlockedByWAF]: "Blocked by Web Application Firewall",
  [NoneReasonType.BlockedByCaptcha]: "Blocked by Captcha",
};

class CloudscanPanelCheckItem extends PureComponent {
  static propTypes = {
    checkID: PropTypes.string.isRequired,
    pass: PropTypes.bool.isRequired,
    expected: PropTypes.array,
    actual: PropTypes.array,
    sources: PropTypes.arrayOf(PropTypes.string),
    severity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dateDetected: PropTypes.string,
    isCustomer: PropTypes.bool,
    hostname: PropTypes.string.isRequired,
    highlight: PropTypes.bool,
    diffItem: PropTypes.object,
    diffStartDate: PropTypes.number,
    diffEndDate: PropTypes.number,
    onClickCVE: PropTypes.func,
    highlightCVEName: PropTypes.string,
    none: PropTypes.bool,
    noneReason: PropTypes.string,
    knownExploitedCount: PropTypes.number,
    isVerifiedVuln: PropTypes.bool,
    isNowScored: PropTypes.bool,
  };

  static defaultProps = {
    expected: [],
    actual: [],
    highlight: false,
    isCustomer: false,
    diffItem: null,
    diffStartDate: 0,
    diffEndDate: 0,
    onClickCVE: null,
    highlightCVEName: "",
    none: null,
    noneReason: null,
    knownExploitedCount: -1,
    isVerifiedVuln: false,
    isNowScored: false,
  };

  static getMetaValue = (status, meta) => {
    if (status === "UNKNOWN") {
      return "[no data]";
    } else if (status === "WAIVED") {
      return "[risk waived]";
    }

    return meta;
  };

  constructor(props) {
    super(props);

    this.state = { open: props.highlight };
  }

  componentDidMount() {
    if (this.props.highlight) {
      const offset = this.checkItemEl.offsetTop - 50;
      const container = this.checkItemEl.closest(".scrollable-content");

      // Only scroll to the element if it is actually off the screen. Otherwise the element
      // will already be highlighted on mount.
      if (
        offset > 0 &&
        this.checkItemEl.getBoundingClientRect().top + 175 >
          window.innerHeight &&
        container &&
        typeof container.scrollTo === "function"
      ) {
        setTimeout(() => container.scrollTo(0, offset), 0);
      }
    }
  }

  // Get the icon for the check's specified severity
  severityIcon() {
    let severity;
    if (this.props.pass) {
      severity = 0;
    } else if (this.props.none) {
      severity = 1;
    } else {
      severity = this.props.severity;
    }
    return severityMap[cloudscanSeverityNumberToText(severity)].icon;
  }

  toggleOpen = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  // Divides an array into an array of rows with a given number of columns,
  // where the elements are placed from top to bottom in each column
  divideIntoRowsWithSortedColumns = (items, numColumns) => {
    const columnLength = Math.ceil(items.length / numColumns);

    // create an array of empty row arrays
    const rows = new Array(columnLength).fill().map(() => []);

    for (let i = 0; i < items.length; i++) {
      const col = Math.floor(i / columnLength),
        row = i % columnLength;

      rows[row][col] = items[i];
    }

    return rows;
  };

  renderCookies = (value) =>
    value.split(",").map((s, i) => (
      <div className="value-split-block" key={i}>
        {s.trim()}
      </div>
    ));

  renderAIMentions = (value) =>
    value.split("\n").map((pageMentions) => (
      <div className="value-split-block" key={pageMentions}>
        {pageMentions}
      </div>
    ));

  render() {
    const expectedActuals = [];

    if (this.props.diffItem) {
      const { expected, property, statusA, statusB } = this.props.diffItem;
      let { metaValueA, metaValueB } = this.props.diffItem;
      const dateA = moment(this.props.diffStartDate).format("ll").toUpperCase();
      const dateB = moment(this.props.diffEndDate).format("ll").toUpperCase();

      expectedActuals.push(
        <div className="expected-actual" key="expected">
          <div className="label">Expected</div>
          <div className="content">
            {property}: {expected}
          </div>
        </div>
      );

      switch (this.props.checkID) {
        case "httponly_cookies":
        case "secure_cookies":
          metaValueA = this.renderCookies(metaValueA);
          metaValueB = this.renderCookies(metaValueB);
          break;
        case "ai_mentioned":
          metaValueA = this.renderAIMentions(metaValueA);
          metaValueB = this.renderAIMentions(metaValueB);
          break;
        default:
          break;
      }

      !this.props.isNowScored &&
        expectedActuals.push(
          <div className="expected-actual" key="start">
            <div className="label">{dateA}</div>
            <div className="content">
              {CloudscanPanelCheckItem.getMetaValue(statusA, metaValueA)}
            </div>
          </div>
        );

      expectedActuals.push(
        <div className="expected-actual" key="end">
          <div className="label">
            {this.props.isNowScored ? "Actual" : dateB}
          </div>
          <div className="content">
            {CloudscanPanelCheckItem.getMetaValue(statusB, metaValueB)}
          </div>
        </div>
      );
    } else if (IsCPERisk(this.props.checkID)) {
      if (!this.props.pass && this.props.dateDetected) {
        expectedActuals.push(
          <div className="expected-actual" key="date-detected">
            <div className="label">First detected</div>
            <p>{formatDateAsLocal(this.props.dateDetected)}</p>
          </div>
        );
      }

      expectedActuals.push(
        <CVEMiniList
          key={GetCPEFromRiskID(this.props.checkID)}
          cpeName={GetCPEFromRiskID(this.props.checkID)}
          isCustomer={this.props.isCustomer}
          hostnames={[this.props.hostname]}
          onClickCVE={this.props.onClickCVE}
          firstCVEName={this.props.highlightCVEName}
        />
      );
    } else if (this.props.none) {
      if (this.props.noneReason && skippedReasonMap[this.props.noneReason]) {
        expectedActuals.push(
          <div
            className="expected-actual"
            key={`reason-${this.props.noneReason}`}
          >
            <div className="label">Reason</div>
            <div className="content">
              {skippedReasonMap[this.props.noneReason]}
            </div>
          </div>
        );
      }

      this.props.expected.forEach((expectedItem) => {
        expectedActuals.push(
          <div
            className="expected-actual"
            key={`expected-${expectedItem.property}`}
          >
            <div className="label">Expected</div>
            <div className="content">
              {expectedItem.property}: {expectedItem.value}
            </div>
          </div>
        );
      });
    } else {
      if (!this.props.pass && this.props.dateDetected) {
        expectedActuals.push(
          <div className="expected-actual" key="date-detected">
            <div className="label">First detected</div>
            <p>{moment(this.props.dateDetected).format("D MMM, YYYY")}</p>
          </div>
        );
      }

      for (let i = 0; i < this.props.expected.length; i++) {
        const expectedItem = this.props.expected[i];
        const actualItem = _find(
          this.props.actual,
          (item) => item.property === expectedItem.property
        );

        expectedActuals.push(
          <div
            className="expected-actual"
            key={`expected-${expectedItem.property}`}
          >
            <div className="label">Expected</div>
            <div className="content">
              {expectedItem.property}: {expectedItem.value}
            </div>
          </div>
        );

        let actualValue = actualItem.value;
        switch (this.props.checkID) {
          case "httponly_cookies":
          case "secure_cookies":
            actualValue = this.renderCookies(actualValue);
            break;
          case "ai_mentioned":
            actualValue = this.renderAIMentions(actualValue);
            break;
          default:
            break;
        }

        expectedActuals.push(
          <div
            className="expected-actual"
            key={`actual-${actualItem.property}`}
          >
            <div className="label">Actual</div>
            <div className="content">{actualValue}</div>
          </div>
        );
      }

      if (IsCVERisk(this.props.checkID)) {
        expectedActuals.push(
          <>
            <div className="label">CVE DETAILS</div>
            <CVEMiniList
              key={GetCVEFromRiskID(this.props.checkID)}
              cveName={GetCVEFromRiskID(this.props.checkID)}
              isCustomer={this.props.isCustomer}
              hostnames={[this.props.hostname]}
              onClickCVE={this.props.onClickCVE}
              firstCVEName={this.props.highlightCVEName}
            />
          </>
        );
      }
    }

    return (
      <div
        className={classnames("check-item", {
          highlight: this.props.highlight,
        })}
        ref={(ref) => (this.checkItemEl = ref)}
      >
        <SlidePanelSection
          toggleExpand={this.toggleOpen}
          title={
            <div className={"section-title"}>
              {this.severityIcon()}
              {this.props.title}
              {this.props.knownExploitedCount > 0 && !this.props.pass && (
                <KnownExploitedVulnPill
                  count={
                    this.props.isVerifiedVuln
                      ? undefined
                      : this.props.knownExploitedCount
                  }
                />
              )}
            </div>
          }
          expanded={this.state.open}
        >
          <>
            {this.props.sources && this.props.sources.length && (
              <>
                <div className="label">
                  {this.props.sources.length == 1 ? "ASSET" : "ASSETS"}
                </div>
                <div className="check-sources">
                  {this.props.sources.map((s) => (
                    <div key={s} className="check-source">
                      {s}
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="check-content">{expectedActuals}</div>
            {this.props.pass || !this.props.summary ? (
              <>
                <div className="label">SUMMARY</div>
                <div className="content">
                  <p>{this.props.description}</p>
                </div>
              </>
            ) : (
              <>
                <div className="label">SUMMARY</div>
                <div className="content">
                  <p>{this.props.summary}</p>
                </div>
              </>
            )}
            {!this.props.pass && this.props.riskDetails ? (
              <>
                <div className="label">RISK DETAILS</div>
                <div className="content">
                  <p>{this.props.riskDetails}</p>
                </div>
              </>
            ) : (
              <></>
            )}
            {!this.props.pass && this.props.recommendedRemediation ? (
              <>
                <div className="label">RECOMMENDED REMEDIATION</div>
                <div className="content">
                  <p>{this.props.recommendedRemediation}</p>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        </SlidePanelSection>
      </div>
    );
  }
}

export class CloudscanDiffPanel extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    hostname: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    isSuppliedDateRange: PropTypes.bool,
    cloudscanPanelOpenInitialId: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
    isCustomer: PropTypes.bool,
    vendorId: PropTypes.number,
    subsidiary: PropTypes.bool,
  };

  static defaultProps = {
    isCustomer: false,
    subsidiary: false,
    vendorId: null,
    hostname: "",
    startDate: 0,
    endDate: 0,
    isSuppliedDateRange: false,
    cloudscanPanelOpenInitialId: null,
  };

  static sortRisksBySeverity(risks) {
    return [...risks].sort((a, b) => b.severity - a.severity);
  }

  state = {
    loading: true,
    diff: null,
  };

  componentDidMount() {
    if (this.props.hostname && this.props.startDate && this.props.endDate) {
      this.fetchCloudscanDiff(
        this.props.hostname,
        this.props.startDate,
        this.props.endDate,
        this.props.isSuppliedDateRange,
        this.props.isCustomer,
        this.props.isSubsidiary,
        this.props.vendorId
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.hostname &&
      this.props.startDate &&
      this.props.endDate &&
      (prevProps.hostname !== this.props.hostname ||
        prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate)
    ) {
      this.fetchCloudscanDiff(
        this.props.hostname,
        this.props.startDate,
        this.props.endDate,
        this.props.isSuppliedDateRange,
        this.props.isCustomer,
        this.props.isSubsidiary,
        this.props.vendorId
      );
    }
  }

  fetchCloudscanDiff(
    hostname,
    startDate,
    endDate,
    isSuppliedDateRange,
    isCustomer,
    isSubsidiary,
    vendorId
  ) {
    this.setState({ loading: true, diff: null });

    this.props
      .dispatch(
        fetchChangesForCloudscan(
          hostname,
          startDate,
          endDate,
          isSuppliedDateRange,
          isCustomer,
          isSubsidiary,
          vendorId
        )
      )
      .then((diff) => {
        this.setState({ loading: false, diff });
      })
      .catch(() => {
        this.props.dispatch(
          addDefaultUnknownErrorAlert(`Error fetching changes for ${hostname}`)
        );
        this.props.closePanel();
      });
  }

  render() {
    if (this.state.loading || !this.state.diff) {
      return <LoadingBanner />;
    }

    const { risksIntroduced, risksResolved, risksNowScored, newHostnames } =
      this.state.diff;

    let mainContent = [];

    if (
      risksNowScored.length === 0 &&
      risksIntroduced.length === 0 &&
      risksResolved.length === 0
    ) {
      mainContent = (
        <div className="no-changes">
          No changes found for {this.props.hostname} between{" "}
          {moment(this.props.startDate).format("MMM DD YYYY")} and{" "}
          {moment(this.props.endDate).format("MMM DD YYYY")}.
        </div>
      );
    } else {
      mainContent = [];
      if (risksNowScored.length > 0) {
        mainContent.push(
          <div className="meta" key="risks-now-scored-meta">
            <div>
              <div className="stat red">
                Provisional {risksNowScored.length === 1 ? "risk" : "risks"} now
                scored ({risksNowScored.length})
              </div>
            </div>
          </div>
        );
        mainContent.push(
          <div key="risks-now-scored">
            {CloudscanDiffPanel.sortRisksBySeverity(risksNowScored).map(
              (item) => (
                <CloudscanPanelCheckItem
                  key={item.id}
                  checkID={item.id}
                  isCustomer={this.props.isCustomer}
                  hostname={this.props.hostname}
                  highlight={item.id === this.props.cloudscanPanelOpenInitialId}
                  pass={false}
                  severity={item.severity}
                  title={item.title}
                  knownExploitedCount={item.knownExploitedVulnCount}
                  isVerifiedVuln={item.isVerifiedVuln}
                  description={item.summary || item.description}
                  diffItem={item}
                  diffStartDate={this.props.startDate}
                  diffEndDate={this.props.endDate}
                  isNowScored
                />
              )
            )}
          </div>
        );
      }

      if (risksIntroduced.length > 0) {
        mainContent.push(
          <div className="meta" key="risks-introduced-meta">
            <div>
              <div className="stat red">
                {risksIntroduced.length === 1 ? "Risk" : "Risks"} introduced (
                {risksIntroduced.length})
              </div>
            </div>
          </div>
        );
        mainContent.push(
          <div key="risks-introduced">
            {CloudscanDiffPanel.sortRisksBySeverity(risksIntroduced).map(
              (item) => (
                <CloudscanPanelCheckItem
                  key={item.id}
                  checkID={item.id}
                  isCustomer={this.props.isCustomer}
                  hostname={this.props.hostname}
                  highlight={item.id === this.props.cloudscanPanelOpenInitialId}
                  pass={false}
                  severity={item.severity}
                  title={item.title}
                  knownExploitedCount={item.knownExploitedVulnCount}
                  isVerifiedVuln={item.isVerifiedVuln}
                  description={item.summary || item.description}
                  diffItem={item}
                  diffStartDate={this.props.startDate}
                  diffEndDate={this.props.endDate}
                />
              )
            )}
          </div>
        );
      }

      if (risksResolved.length > 0) {
        mainContent.push(
          <div className="meta" key="risks-resolved-meta">
            <div>
              <div className="stat green">
                {risksResolved.length === 1 ? "Risk" : "Risks"} resolved (
                {risksResolved.length})
              </div>
            </div>
          </div>
        );

        mainContent.push(
          <div key="risks-resolved">
            {CloudscanDiffPanel.sortRisksBySeverity(risksResolved).map(
              (item) => (
                <CloudscanPanelCheckItem
                  key={item.id}
                  checkID={item.id}
                  isCustomer={this.props.isCustomer}
                  hostname={this.props.hostname}
                  highlight={item.id === this.props.cloudscanPanelOpenInitialId}
                  pass
                  severity={item.severity}
                  title={item.title}
                  knownExploitedCount={item.knownExploitedVulnCount}
                  isVerifiedVuln={item.isVerifiedVuln}
                  description={item.summary || item.description}
                  diffItem={item}
                  diffStartDate={this.props.startDate}
                  diffEndDate={this.props.endDate}
                />
              )
            )}
          </div>
        );
      }
    }

    return (
      <div className="cloudscan-panel-wrapper">
        <div className="cloudscan-flex">
          <div className="title-and-button">
            <h2 title={this.props.hostname}>
              Changes for {this.props.hostname}
            </h2>
          </div>
          <ScrollableDiv newStyles>{mainContent}</ScrollableDiv>
        </div>
      </div>
    );
  }
}

const getRandomTimeout = (estimatedTime) => {
  const min =
    estimatedTime && estimatedTime >= 10000 ? estimatedTime / 10 : 1000;
  // Get a random interval between min and min + 2 seconds
  return min + 2000 - Math.random() * 2000;
};

/**
 * <CloudscanPanel />
 * Panel content for the details of a given cloudscan, superceding the SingleWebscan view.
 * Takes the full webscans state for finding a webscan with a given hostname.
 */
class CloudscanPanel extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    hostname: PropTypes.string.isRequired,
    ipDetails: PropTypes.object,
    webscans: PropTypes.object.isRequired,
    cloudscanPanelOpenInitialId: PropTypes.string,
    cloudscanPanelOpenCVEName: PropTypes.string,
    isVendorPortal: PropTypes.bool.isRequired,
    isCustomer: PropTypes.bool,
    isSubsidiary: PropTypes.bool,
    vendorId: PropTypes.number,
    onRequestRemediation: PropTypes.func,
    onClickIP: PropTypes.func,
    onClickIPRange: PropTypes.func,
    onClickCVE: PropTypes.func.isRequired,
    userHasWriteVendorInfoPermission: PropTypes.bool.isRequired,
    userHasReadVendorInfoPermission: PropTypes.bool.isRequired,
    disableLabelAdd: PropTypes.bool,
    remediationRequestId: PropTypes.number,
    orgSupportsDomainsPortfolios: PropTypes.bool.isRequired,
    editableDomainPortfolioIds: PropTypes.arrayOf(PropTypes.number),
    detectedProducts: PropTypes.arrayOf(PropTypes.object),
    orgRegion: PropTypes.string,
  };

  static defaultProps = {
    ipDetails: null,
    isCustomer: false,
    isSubsidiary: false,
    vendorId: undefined,
    cloudscanPanelOpenInitialId: null,
    cloudscanPanelOpenCVEName: null,
    onRequestRemediation: null,
    onClickIP: null,
    onClickIPRange: null,
    disableLabelAdd: false,
    remediationRequestId: undefined,
    editableDomainPortfolioIds: undefined,
    detectedProducts: [],
    orgRegion: null,
  };

  constructor(props) {
    super(props);

    this._isMounted = true;
    if (props.hostname) {
      if (props.isVendorPortal) {
        props.dispatch(
          fetchCloudscanByHostnameCommon(
            props.hostname,
            false,
            this.props.remediationRequestId
          )
        );
      } else {
        props.dispatch(
          fetchCloudscanByHostname(
            props.hostname,
            false,
            props.isCustomer,
            props.vendorId
          )
        );
      }

      if (isIPAddress(props.hostname)) {
        if (props.isVendorPortal && props.remediationRequestId) {
          props.dispatch(
            fetchIpAddressDetailsForVendorPortalRemediationRequest(
              props.hostname,
              props.remediationRequestId
            )
          );
        } else if (props.isCustomer) {
          props.dispatch(fetchCustomerIPAddressDetails(props.hostname));
        } else {
          props.dispatch(
            fetchVendorIPAddressDetails(
              props.hostname,
              props.vendorId,
              props.isSubsidiary
            )
          );
        }
      }
    }

    this.state = {
      rescanLoading: props.webscans[props.hostname]
        ? props.webscans[props.hostname].rescanning ?? false
        : false,
      rescanLoadingPercent: 0,
      openWaivedChecks: {},
      fireConfetti: false,
      showTourHighlight: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hostname !== this.props.hostname) {
      if (this.props.isVendorPortal) {
        this.props.dispatch(
          fetchCloudscanByHostnameCommon(
            this.props.hostname,
            false,
            this.props.remediationRequestId
          )
        );
      } else {
        this.props.dispatch(
          fetchCloudscanByHostname(
            this.props.hostname,
            false,
            this.props.isCustomer,
            this.props.vendorId
          )
        );
      }

      if (isIPAddress(this.props.hostname)) {
        if (this.props.isVendorPortal && this.props.remediationRequestId) {
          this.props.dispatch(
            fetchIpAddressDetailsForVendorPortalRemediationRequest(
              this.props.hostname,
              this.props.remediationRequestId
            )
          );
        } else if (this.props.isCustomer) {
          this.props.dispatch(
            fetchCustomerIPAddressDetails(this.props.hostname)
          );
        } else {
          this.props.dispatch(
            fetchVendorIPAddressDetails(
              this.props.hostname,
              this.props.vendorId,
              this.props.isSubsidiary
            )
          );
        }
      }
    }

    const cloudscan = this.props.webscans[this.props.hostname];
    const prevCloudscan = prevProps.webscans[prevProps.hostname];
    if (cloudscan && prevCloudscan) {
      if (!cloudscan.rescanning && prevCloudscan.rescanning) {
        // Just stopped rescanning. Stop the progress bar
        this.stopRandomProgressBar();
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showTourHighlight: true });
    }, 300);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.randomProgressBarTimeout);
  }

  /*
    The patented fake progress bar algorithm...
    Every (1-5 seconds), add a random amount to the progress bar. Never add more than
    60% of what is remaining in the progress bar, so it should never reach the end
    before our query actually returns.
  */
  startRandomProgressBar = (estimatedTime) => {
    clearTimeout(this.randomProgressBarTimeout);
    this.randomProgressBarTimeoutInProgress = true;

    setTimeout(
      this.randomProgressBarTimeoutFunc(estimatedTime),
      getRandomTimeout(estimatedTime)
    );
  };

  stopRandomProgressBar = () => {
    this.randomProgressBarTimeoutInProgress = false;
    clearTimeout(this.randomProgressBarTimeout);
    this.setState({ rescanLoading: true, rescanLoadingPercent: 100 });
    // Give it 500ms to animate to the end before removing the loading bar entirely
    setTimeout(
      () =>
        this.setState({
          rescanLoading: false,
          rescanLoadingPercent: 0,
        }),
      500
    );
  };

  randomProgressBarTimeoutFunc = (estimatedTime) => () => {
    if (!this.randomProgressBarTimeoutInProgress) {
      return;
    }

    this.setState(({ rescanLoadingPercent }) => ({
      rescanLoadingPercent:
        rescanLoadingPercent +
        Math.random() * ((100 - rescanLoadingPercent) * 0.4),
    }));

    this.randomProgressBarTimeout = setTimeout(
      this.randomProgressBarTimeoutFunc(estimatedTime),
      getRandomTimeout(estimatedTime)
    );
  };

  rescanHost = () => {
    this.setState({ rescanLoading: true, rescanLoadingPercent: 0 });

    const estimatedScanTimeCallback = (estimatedScanTime) => {
      if (!this._isMounted) {
        return;
      }

      this.startRandomProgressBar(estimatedScanTime);
    };
    if (this.props.isVendorPortal) {
      this.props.dispatch(
        scanHostnameCommon(this.props.hostname, estimatedScanTimeCallback)
      );
    } else {
      this.props.dispatch(
        scanHostname(
          this.props.hostname,
          this.props.isCustomer,
          estimatedScanTimeCallback
        )
      );
    }

    this.props
      .dispatch(setPLGTaskCompleteIfIncomplete("Checklist_BreachSight_Rescan"))
      .then((result) => {
        if (result) {
          this.setState({ fireConfetti: true });
        }
      });

    let eventName = "BreachSight_Rescan_Domain";
    if (!this.props.isCustomer) {
      eventName = "Vendor_Risk_Rescan_Domain";
    }
    trackEvent(eventName);
  };

  exportPDF = () => {
    const auth = getLocalStorageItem("cyberRiskAuth");

    const opts = {
      hostname: this.props.hostname,
      for_customer: this.props.isCustomer,
    };

    if (this.props.vendorId) opts["vendor_id"] = this.props.vendorId;

    // Build the API URL including the org's region if we have it
    const url = GenerateCyberRiskUrl(
      `/cloudscan/details/pdf/v2/`,
      opts,
      auth,
      this.props.orgRegion || ""
    );

    window.open(url);
  };

  haveRootAndWWWResults = (result, root, www) => {
    // first see if we have checks sourced from both domains
    const haveRootResults = result.checkResults.some(
      (c) => c.sources && c.sources.some((s) => s.startsWith(root))
    );
    const haveWWWResults = result.checkResults.some(
      (c) => c.sources && c.sources.some((s) => s.startsWith(www))
    );

    if (haveRootResults && haveWWWResults) {
      return true;
    }

    // otherwise, we need DNS records for both domains
    return (
      result.scanMeta.aRecords &&
      result.scanMeta.aRecords.length &&
      result.scanMeta.wwwARecords &&
      result.scanMeta.wwwARecords.length
    );
  };

  render() {
    const cloudscan = this.props.webscans[this.props.hostname];

    if (
      !cloudscan ||
      cloudscan.loading ||
      (isIPAddress(this.props.hostname) && !this.props.ipDetails)
    ) {
      return <LoadingBanner />;
    }
    const result = cloudscan.result || {};

    const hasResult =
      result.checkResults && result.checkResults.length > 0 && !result.noResult;
    const hasSkippedResults =
      result.checkResultsSkipped && result.checkResultsSkipped.length > 0;

    // use the wwwActive flag in the scan meta to work out which is the "main" domain
    const wwwHost = `www.${this.props.hostname}`;
    const scannedHostname =
      hasResult && result.scanMeta.wwwActive ? wwwHost : this.props.hostname;

    const haveRootAndWWWResults =
      hasResult &&
      !isIPAddress(this.props.hostname) &&
      this.haveRootAndWWWResults(result, this.props.hostname, wwwHost);

    const haveARecords =
      hasResult && (result.scanMeta.aRecords || result.scanMeta.wwwARecords);

    const haveCerts =
      hasResult && result.scanMeta.certs && result.scanMeta.certs.length > 0;
    if (haveCerts) {
      result.scanMeta.certs.forEach((cert) => cert.ports.sort((a, b) => a - b));
      result.scanMeta.certs.sort((a, b) => a.ports[0] - b.ports[0]);
    }

    const failedItems = (result.checkResults || [])
      .filter((r) => !r.pass || r.pass === false)
      .sort((a, b) => b.severity - a.severity);

    const passedItems = (result.checkResults || [])
      .filter((r) => r.pass === true)
      .sort((a, b) => b.severity - a.severity);

    const skippedItems = (result.checkResultsSkipped || []).sort(
      (a, b) => b.severity - a.severity
    );

    const detectedProducts = [];
    this.props.detectedProducts.forEach((tech) => {
      if (tech.hosts.some((host) => host.hostname === this.props.hostname)) {
        detectedProducts.push(tech);
      }
    });

    // use the SSL enabled check to work out if we can link to HTTPS
    const supportsHTTPS = passedItems.some((p) => p.id === "ssl_enabled");

    const inEditablePortfolio =
      this.props.isCustomer &&
      this.props.orgSupportsDomainsPortfolios &&
      result.portfolios &&
      this.props.editableDomainPortfolioIds
        ? !!result.portfolios.find((p) =>
            this.props.editableDomainPortfolioIds.includes(p.id)
          )
        : false;

    return (
      <div
        className={"cloudscan-panel-wrapper cloudscan-panel-new-risk-designs"}
      >
        {this.state.fireConfetti && <ConfettiFullPage />}

        <div className="cloudscan-flex">
          <div className="title-and-button">
            <a
              href={`${supportsHTTPS ? "https" : "http"}://${scannedHostname}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2 title={scannedHostname}>{scannedHostname}</h2>
            </a>
            <div className="button-bar">
              {this.state.rescanLoading && (
                <div className="rescan-loading">
                  <div
                    className="percent-background"
                    style={{
                      width: `${this.state.rescanLoadingPercent}%`,
                    }}
                  />
                  <LoadingIcon /> Rescan in progress
                </div>
              )}
              {hasResult &&
                !cloudscan.rescanning &&
                !this.state.rescanLoading && (
                  <>
                    <Button onClick={this.exportPDF}>
                      <div className="cr-icon-export" />
                      Export PDF
                    </Button>
                  </>
                )}
              {!cloudscan.rescanning && !this.state.rescanLoading && (
                <>
                  <div style={{ width: "10px" }} />
                  <TourHighlightFromPLGContent
                    onboardingTaskId={"Checklist_BreachSight_Rescan"}
                    highlightIndex={1}
                    visible={this.state.showTourHighlight}
                    position={"left"}
                  >
                    <Button onClick={this.rescanHost}>Rescan</Button>
                  </TourHighlightFromPLGContent>
                </>
              )}
            </div>
          </div>
          <ScrollableDiv newStyles>
            {haveRootAndWWWResults && (
              <InfoBanner
                message={
                  <>
                    This scan includes results from both {this.props.hostname}{" "}
                    and {wwwHost}.
                  </>
                }
              />
            )}
            <h3 className="info-header">
              {isIPAddress(scannedHostname)
                ? "IP information"
                : "Domain information"}
            </h3>
            <div className={"bordered-box"}>
              <div className={"rating-table-row"}>
                <div className={"rating-table-title"}>{"Risk rating"}</div>
                <div className={"rating-table-data"}>
                  {hasResult && (
                    <>
                      <ColorGrade score={result.cstarScore || 0} />
                      <Score colored score={result.cstarScore} outOf={950} />
                    </>
                  )}
                </div>
              </div>
              <div className={"rating-table-row"}>
                <div className={"rating-table-title"}>Last scanned</div>
                <div className={"rating-table-data"}>
                  {!!result.scannedAt && (
                    <DateTimeFormat
                      dateTime={moment(result.scannedAt).toISOString()}
                    />
                  )}
                </div>
              </div>
              {(!!result.lastEnabledDate || !!result.scannedAt) && (
                <div className={"rating-table-row"}>
                  <div className={"rating-table-title"}>First Scanned</div>
                  <div className={"rating-table-data"}>
                    <DateTimeFormat
                      dateTime={moment(
                        !!result.lastEnabledDate
                          ? result.lastEnabledDate
                          : result.scannedAt
                      ).toISOString()}
                    />
                  </div>
                </div>
              )}
              {result.sources && (
                <div className={"rating-table-row"}>
                  <div className={"rating-table-title"}>Sources</div>
                  <div className={"rating-table-data"}>
                    <DomainSourceLabelList sources={result.sources} />
                  </div>
                </div>
              )}
              {!this.props.ipDetails && !this.props.isVendorPortal && (
                <div className={"rating-table-row"}>
                  <div className={"rating-table-title"}>Labels</div>
                  <div className={"rating-table-data labels"}>
                    <>
                      <LabelList
                        labels={result.labels}
                        classification={LabelClassification.WebsiteLabel}
                        updateLabelsHeader={`Update labels for ${this.props.hostname}`}
                        onLabelsUpdated={
                          this.props.userHasWriteVendorInfoPermission &&
                          !this.props.disableLabelAdd &&
                          !this.props.isVendorPortal
                            ? (
                                availableLabels,
                                addedLabelIds,
                                removedLabelIds
                              ) => {
                                return this.props
                                  .dispatch(
                                    setLabelsForWebsites(
                                      [this.props.hostname],
                                      addedLabelIds,
                                      removedLabelIds,
                                      this.props.vendorId,
                                      this.props.isSubsidiary,
                                      false
                                    )
                                  )
                                  .then(() => {
                                    // Manually set cloudscan detail cache (to avoid refreshing the page)
                                    const updatedDomains = adjustLabels(
                                      [result],
                                      () => true,
                                      availableLabels,
                                      addedLabelIds,
                                      removedLabelIds
                                    );

                                    this.props.dispatch(
                                      setCloudscanData(this.props.hostname, {
                                        result: updatedDomains[0],
                                        rescanning: false,
                                        loading: false,
                                        error: null,
                                      })
                                    );
                                  });
                              }
                            : undefined
                        }
                      />
                    </>
                  </div>
                </div>
              )}
              {!this.props.ipDetails &&
                this.props.isCustomer &&
                this.props.orgSupportsDomainsPortfolios &&
                result.portfolios && (
                  <div className={"rating-table-row"}>
                    <div className={"rating-table-title"}>Portfolio</div>
                    <div className={"rating-table-data"}>
                      <EditablePortfolioList
                        itemId={this.props.hostname}
                        itemName={this.props.hostname}
                        portfolios={result.portfolios}
                        portfolioType={PortfolioType.Domain}
                      />
                    </div>
                  </div>
                )}

              {!!this.props.ipDetails && (
                <IPAddressInfoTable
                  dispatch={this.props.dispatch}
                  ip={this.props.ipDetails}
                  onClickIPRange={
                    !this.props.isVendorPortal
                      ? this.props.onClickIPRange
                      : undefined
                  }
                  isSubsidiary={this.props.isSubsidiary}
                  showUpdateLabel={
                    this.props.userHasWriteVendorInfoPermission &&
                    this.props.disableLabelAdd &&
                    !this.props.isVendorPortal
                  }
                  showLabels={!this.props.isVendorPortal}
                />
              )}
              {haveCerts &&
                result.scanMeta.certs.map((cert) => (
                  <div className="webscan-cert" key={cert.fingerprint}>
                    <h3>Certificate</h3>
                    <div className="rating-table-row">
                      <div className="rating-table-title">Port</div>
                      <div className="rating-table-data">
                        {cert.ports.join(", ")}
                      </div>
                    </div>
                    <div className="rating-table-row">
                      <div className="rating-table-title">Common Name</div>
                      <div className="rating-table-data">
                        {cert.common_name}
                      </div>
                    </div>
                    {cert.san_domains && cert.san_domains.length > 0 && (
                      <div className="rating-table-row">
                        <div className="rating-table-title">SAN Domains</div>
                        <div className="rating-table-data">
                          {cert.san_domains.join(", ")}
                        </div>
                      </div>
                    )}
                    <div className="rating-table-row">
                      <div className="rating-table-title">Expires</div>
                      <div className="rating-table-data">{cert.expires}</div>
                    </div>
                  </div>
                ))}
              {haveARecords && (
                <>
                  <h3>IP addresses</h3>
                  <IPAddresses
                    hostname={this.props.hostname}
                    showHostnameLabel={haveRootAndWWWResults}
                    scanMeta={result.scanMeta}
                    onClickIP={
                      !this.props.isVendorPortal
                        ? this.props.onClickIP
                        : undefined
                    }
                  />
                </>
              )}

              {!!this.props.ipDetails?.cloudConnections && (
                <div className={"cloud-connections"}>
                  <h3>
                    Cloud Connections (
                    {this.props.ipDetails.cloudConnections.length})
                  </h3>
                  <InfoTable
                    styling={InfoTableStyling.New}
                    bordered
                    borderedFirstRow
                  >
                    {this.props.ipDetails.cloudConnections.map((d) => (
                      <InfoTableRow
                        key={d.connectionName}
                        rowClass={"cloud-connection-row"}
                        value={
                          <>
                            <CloudProviderLogo
                              provider={d.connectionProviderType}
                            />
                            <div>{d.connectionName}</div>
                          </>
                        }
                      />
                    ))}
                  </InfoTable>
                </div>
              )}
            </div>
            <div className="meta">
              {hasResult ? (
                <>
                  <div>
                    <div className="stat red">
                      {failedItems.length === 1 ? "Risk" : "Risks"} (
                      {failedItems.length})
                    </div>
                  </div>
                  {!this.props.isVendorPortal &&
                    (this.props.userHasWriteVendorInfoPermission ||
                      inEditablePortfolio) &&
                    !!this.props.onRequestRemediation &&
                    failedItems.length > 0 && (
                      <Button
                        onClick={() =>
                          this.props.onRequestRemediation(
                            this.props.hostname,
                            this.props.vendorId
                          )
                        }
                      >
                        Request remediation
                      </Button>
                    )}
                </>
              ) : (
                <div>
                  This{" "}
                  {isIPAddress(this.props.hostname) ? "IP address" : "domain"}{" "}
                  is inactive and does not have any risk data.
                  <br />
                  <br />
                  Please try clicking Rescan to initiate a new scan.
                </div>
              )}
            </div>
            {hasResult && (
              <>
                {failedItems.map((item) => (
                  <CloudscanPanelCheckItem
                    key={item.id}
                    checkID={item.id}
                    onClickCVE={this.props.onClickCVE}
                    isCustomer={this.props.isCustomer}
                    hostname={this.props.hostname}
                    knownExploitedCount={item.knownExploitedVulnCount}
                    isVerifiedVuln={item.isVerifiedVuln}
                    highlight={
                      item.id === this.props.cloudscanPanelOpenInitialId
                    }
                    highlightCVEName={this.props.cloudscanPanelOpenCVEName}
                    {...item}
                  />
                ))}
                {result.waivedCheckResults &&
                  result.waivedCheckResults.length > 0 && (
                    <>
                      <div className="meta">
                        <div>
                          <div className="stat">
                            {result.waivedCheckResults.length === 1
                              ? "Check"
                              : "Checks"}{" "}
                            waived ({result.waivedCheckResults.length})
                          </div>
                        </div>
                      </div>
                      {result.waivedCheckResults.map((r) => (
                        <div className="check-item" key={r.id}>
                          <SlidePanelSection
                            toggleExpand={() =>
                              this.setState((state) => ({
                                openWaivedChecks: {
                                  ...state.openWaivedChecks,
                                  [r.id]: !state.openWaivedChecks[r.id],
                                },
                              }))
                            }
                            title={r.title}
                            expanded={!!this.state.openWaivedChecks[r.id]}
                          >
                            <>
                              <div className="label">SUMMARY</div>
                              <div className="content">
                                <p>{r.description}</p>
                              </div>
                            </>
                          </SlidePanelSection>
                        </div>
                      ))}
                    </>
                  )}
                {hasSkippedResults && (
                  <>
                    <div className="meta">
                      <div>
                        <div className="stat">
                          Blocked{" "}
                          {skippedItems.length === 1 ? "check" : "checks"} (
                          {skippedItems.length})
                          <div className={"description"}>
                            We could not perform the following checks because we
                            detected a web application firewall or a captcha.
                          </div>
                        </div>
                      </div>
                    </div>
                    {skippedItems.map((item) => (
                      <CloudscanPanelCheckItem
                        key={item.id}
                        checkID={item.id}
                        isCustomer={this.props.isCustomer}
                        hostname={this.props.hostname}
                        onClickCVE={this.props.onClickCVE}
                        knownExploitedCount={item.knownExploitedVulnCount}
                        isVerifiedVuln={item.isVerifiedVuln}
                        highlight={
                          item.id === this.props.cloudscanPanelOpenInitialId
                        }
                        highlightCVEName={this.props.cloudscanPanelOpenCVEName}
                        {...item}
                      />
                    ))}
                  </>
                )}
                <div className="meta">
                  <div>
                    <div className="stat">
                      Additional {passedItems.length === 1 ? "check" : "checks"}{" "}
                      ({passedItems.length})
                    </div>
                  </div>
                </div>
                {passedItems.map((item) => (
                  <CloudscanPanelCheckItem
                    key={item.id}
                    checkID={item.id}
                    isCustomer={this.props.isCustomer}
                    hostname={this.props.hostname}
                    onClickCVE={this.props.onClickCVE}
                    knownExploitedCount={item.knownExploitedVulnCount}
                    isVerifiedVuln={item.isVerifiedVuln}
                    highlight={
                      item.id === this.props.cloudscanPanelOpenInitialId
                    }
                    highlightCVEName={this.props.cloudscanPanelOpenCVEName}
                    {...item}
                  />
                ))}
                {detectedProducts.length > 0 && (
                  <div className="meta">
                    <DetectedProductsMiniTable
                      products={detectedProducts}
                      currentPanelName={`${
                        isIPAddress(this.props.hostname) ? "IP" : "Domain"
                      } Information`}
                      userCanAccessVendorRisk={
                        this.props.userHasReadVendorInfoPermission
                      }
                    />
                  </div>
                )}
                {!!result.cloudConnections && (
                  <div className={"cloud-connections"}>
                    <SlidePanelSection
                      expanded={true}
                      title={
                        <>
                          Cloud Connections ({result.cloudConnections.length})
                        </>
                      }
                    >
                      <InfoTable styling={InfoTableStyling.New} bordered>
                        {result.cloudConnections.map((d) => (
                          <InfoTableRow
                            key={d.connectionName}
                            rowClass={"cloud-connection-row"}
                            value={
                              <>
                                <CloudProviderLogo
                                  provider={d.connectionProviderType}
                                />
                                <div>{d.connectionName}</div>
                              </>
                            }
                          />
                        ))}
                      </InfoTable>
                    </SlidePanelSection>
                  </div>
                )}
              </>
            )}
          </ScrollableDiv>
        </div>
      </div>
    );
  }
}

export default appConnect((state, props) => {
  const isBreachSight = !props.vendorId || props.isSubsidiary;
  const isVendor = !!props.vendorId && !props.isSubsidiary;
  const userPerms = getUserPermissionsForVendorFromState(
    state,
    isVendor ? props.vendorId || 0 : 0
  );

  let userHasWriteVendorInfoPermission =
    (isBreachSight && !!userPerms[UserBreachsightWrite]) ||
    (isVendor && !!userPerms[UserVendorRiskWrite]);

  const userHasReadVendorInfoPermission = !!userPerms[UserVendorRiskEnabled];
  const detectedProducts = state.cyberRisk.detectedProducts?.technologies;

  // Get the region here and then plumb it in to the URL when we generate a PDF
  const orgRegion = state.common?.userData?.currentOrgDataRegion;

  if (!isIPAddress(props.hostname)) {
    return {
      ...props,
      userHasWriteVendorInfoPermission,
      userHasReadVendorInfoPermission,
      detectedProducts,
      orgRegion,
    };
  }

  const ipDetails = getIPAddressDetailsFromState(
    state,
    props.hostname,
    props.vendorId,
    props.isSubsidiary,
    props.isVendorPortal
  );

  return {
    ...props,
    ipDetails,
    userHasWriteVendorInfoPermission,
    userHasReadVendorInfoPermission,
    detectedProducts,
    orgRegion,
  };
})(CloudscanPanel);

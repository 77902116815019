import { useRef, ChangeEvent } from "react";

import ConnectedQuestionNode from "./QuestionNode";
import { Survey } from "../reducers/reducer";
import { setFramework, setFullSurvey } from "../reducers/actions";
import IconButton from "../../_common/components/IconButton";

import "../style/RootNode.scss";
import {
  convertSurveyToVsaqStructure,
  convertVsaqStructureToSurvey,
} from "../vsaq/vsaqConvert";
import {
  SurveyFramework,
  SurveyFrameworkDisplayNames,
} from "../types/frameworks";
import { OptionType, SelectV2 } from "../../_common/components/SelectV2";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { appConnect, useAppSelector } from "../../_common/types/reduxHooks";

interface VSAQLoaderOwnProps {
  surveyId: string;
}

export interface VSAQLoaderConnectedProps {
  nodeId: string;
  framework: SurveyFramework;
  includeTOC: boolean;
  customNumbering: boolean;
  usageType: SurveyUsageType;
}

type VSAQLoaderProps = VSAQLoaderOwnProps &
  VSAQLoaderConnectedProps &
  DefaultThunkDispatchProp;

// VSAQLoader wraps the Questionnaire Builder with some utility functions
// to import and export VSAQ-format JSON. This is not used in the CyberRisk app,
// only in our storybook. It may be handy later on if we create a standalone editor for
// staff too.
const VSAQLoader = (props: VSAQLoaderProps) => {
  const currentSurvey = useAppSelector(
    (state) => state.surveyBuilder.surveys[props.surveyId]
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    let newSurvey: Survey;
    try {
      const json = await e.target.files[0].text();
      let parsed = JSON.parse(json);
      if (Array.isArray(parsed)) {
        parsed = parsed[0];
      }
      newSurvey = convertVsaqStructureToSurvey(props.surveyId, parsed, true);
    } catch (e) {
      alert("Error parsing file: " + e);
      return;
    }

    props.dispatch(setFullSurvey(props.surveyId, newSurvey));
  };

  const onDownloadFile = () => {
    const rootQ = convertSurveyToVsaqStructure(currentSurvey);
    const json = JSON.stringify([rootQ], null, 2);

    // Create a fake element so we can force a download
    const blob = new Blob([json], { type: "application/json" });
    const objectURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectURL;
    a.download = "vsaq.json";

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(objectURL);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };

    a.addEventListener("click", clickHandler);
    a.click();
  };

  return (
    <div className="vsaq-loader">
      <div className="vsaq-toolbar">
        <SelectV2
          value={{
            value: props.framework,
            label: SurveyFrameworkDisplayNames[props.framework],
          }}
          options={Object.values(SurveyFramework).map((framework) => ({
            value: framework,
            label: SurveyFrameworkDisplayNames[framework],
          }))}
          onChange={(newVal) => {
            if (newVal && !Array.isArray(newVal)) {
              props.dispatch(
                setFramework(
                  props.surveyId,
                  (newVal as OptionType).value as SurveyFramework
                )
              );
            }
          }}
        />
        <input
          ref={fileInputRef}
          type="file"
          accept="application/json"
          onChange={onUploadFile}
        />
        <IconButton
          icon={<div className="cr-icon-upload" />}
          hoverText="Import JSON"
          onClick={() => fileInputRef.current?.click()}
        />
        <IconButton
          icon={<div className="cr-icon-export" />}
          hoverText="Export JSON"
          onClick={onDownloadFile}
        />
      </div>
      <RootNode
        surveyId={props.surveyId}
        nodeId={props.nodeId}
        surveyLocked={false}
        includeTOC={props.includeTOC}
        customNumbering={props.customNumbering}
        usageType={props.usageType}
      />
    </div>
  );
};

export const ConnectedVSAQLoader = appConnect<
  VSAQLoaderConnectedProps,
  never,
  VSAQLoaderOwnProps
>((state, props) => {
  const survey = state.surveyBuilder.surveys[props.surveyId];
  return {
    nodeId: survey.rootNodeId,
    framework: survey.framework,
    includeTOC: survey.includeTOC,
    customNumbering: survey.customNumbering,
    usageType: survey.usageType,
  };
})(VSAQLoader);

// RootNode simply mounts the root node in the redux tree.

interface IRootNodeProps extends IConnectedRootNodeProps {
  surveyTypeId?: number;
  nodeId: string;
  surveyLocked: boolean;
  includeTOC: boolean;
  customNumbering: boolean;
  usageType: SurveyUsageType;
  hideTitleAndDescription?: boolean;
}

const RootNode = (props: IRootNodeProps) => {
  return (
    <ConnectedQuestionNode
      surveyTypeId={props.surveyTypeId}
      surveyId={props.surveyId}
      usageType={props.usageType}
      nodeId={props.nodeId}
      isRootNode
      includeTOC={props.includeTOC}
      customNumbering={props.customNumbering}
      index={0}
      indentLevel={0}
      isLastChild={false}
      readOnly={props.surveyLocked}
      hideEditFields={props.hideTitleAndDescription}
    />
  );
};

interface IConnectedRootNodeProps {
  surveyId: string;
}

export default appConnect((state, props: IConnectedRootNodeProps) => {
  return {
    nodeId: state.surveyBuilder.surveys[props.surveyId].rootNodeId,
    surveyLocked:
      !!state.surveyBuilder.surveys[props.surveyId].lockedBy ||
      !!state.surveyBuilder.surveys[props.surveyId].saveError,
    includeTOC: state.surveyBuilder.surveys[props.surveyId].includeTOC,
    customNumbering:
      state.surveyBuilder.surveys[props.surveyId].customNumbering,
  };
})(RootNode);

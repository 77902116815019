import { RiskNode } from "../../../survey_builder/types/types";
import { BaseNodeContentProps } from "./baseNode";
import { DiffTextBox } from "./InputNodeContent";
import TextFieldWithActions from "../../../_common/components/TextFieldWithActions";
import "../../style/RiskNode.scss";
import InfoBanner, {
  SubItemType,
} from "../../../vendorrisk/components/InfoBanner";

interface RiskNodeContentProps extends BaseNodeContentProps {
  node: RiskNode;
}

const RiskNodeContent = (props: RiskNodeContentProps) => {
  const currentAnswer =
    (props.suggestedAnswer as string) ?? (props.answer as string) ?? "";
  const otherAnswer = (props.otherAnswer as string) ?? "";

  const hideManualRiskCompensatingControls =
    props.disabled && currentAnswer == "" && !!props.node.customRiskID;

  return (
    <div className={"question-answer-node-content"}>
      {(props.node.askForCompensatingControls || props.node.why) &&
        !hideManualRiskCompensatingControls && (
          <div className={"risk-response"}>
            {props.diffSide ? (
              <DiffTextBox
                answer={currentAnswer}
                otherAnswer={otherAnswer}
                side={props.diffSide}
                multiline={true}
              />
            ) : (
              <>
                <div
                  className={"desc"}
                  dangerouslySetInnerHTML={{ __html: props.node.why }}
                />
                <div className={"answer-section"}>
                  {props.nodeHiddenFromRecipient && (
                    <InfoBanner
                      message={
                        "This risk is hidden from recipients due to the Risk Visibility setting on this questionnaire."
                      }
                      subItemType={SubItemType.BULLET}
                      subItems={[
                        "Requesting remediation for this risk will make it visible to recipients",
                      ]}
                    />
                  )}
                  {!props.nodeHiddenFromRecipient && (
                    <TextFieldWithActions
                      value={currentAnswer as string}
                      onChanged={(val) =>
                        props.onAnswerChanged(
                          props.node.nodeId,
                          val !== "" ? val : undefined
                        )
                      }
                      multiLine
                      placeholder={
                        !props.disabled ? "Type your answer here..." : ""
                      }
                      disabled={props.disabled}
                      actionButtons={props.actionButtons}
                      inputColor={
                        !!props.suggestedAnswer ? "orange" : undefined
                      }
                    />
                  )}

                  {props.gptSuggestionBox}
                </div>
              </>
            )}
          </div>
        )}
    </div>
  );
};

export default RiskNodeContent;

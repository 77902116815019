import {
  addBlankRiskNode,
  addSelectNodeAnswer,
  deleteSelectNodeAnswer,
  setCurrentFocusedNodeId,
  setEditRiskModalNodeId,
  SimpleErrorAlert,
  updateSelectNodeAnswer,
} from "../reducers/actions";
import { SelectNodeAnswer } from "../types/types";
import { createUUID, getAnswerLetters, maxPossibleAnswers } from "../helpers";
import IconButton, { HoverColor } from "../../_common/components/IconButton";
import { Comparator, Expression } from "../types/conditionalLogic";
import Button from "../../_common/components/core/Button";
import "../style/SelectNodeAnswers.scss";
import { ValidationError } from "../types/validation";
import ValidationErrorText from "./ValidationErrorText";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { AppDispatch } from "../../_common/types/reduxStore";
import TextFieldPerformant from "../../_common/components/TextFieldPerformant";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";

export interface ISelectNodeAnswers {
  dispatch: AppDispatch;
  surveyId: string;
  usageType: SurveyUsageType;
  parentId: string;
  nodeId: string;
  index: number;
  answers: SelectNodeAnswer[];
  radio: boolean;
  validationErrors: {
    [validationError: string]: true; // The keys should map to the ValidationError enum
  };
  onFocus: () => void;
  readOnly: boolean;
  answerCanBeDeletedFunc?: (nodeId: string, answerId: string) => boolean;
}

export const SelectNodeAnswers = (props: ISelectNodeAnswers) => (
  <>
    <div className="select-node-answers">
      {props.answers?.length === 0 ? (
        <div className="no-select-answers">
          Add options for this{" "}
          {props.radio ? "single-select question" : "multi-select question"}{" "}
          here
        </div>
      ) : (
        props.answers?.map((a, i) => (
          <div key={a.id} className="select-node-answer">
            <div className="letter">{getAnswerLetters(i)}</div>
            <TextFieldPerformant
              strict
              multiLine
              value={a.text}
              placeholder="Enter an option"
              readonly={props.readOnly}
              onFocus={props.onFocus}
              onChanged={(val) =>
                props.dispatch(
                  updateSelectNodeAnswer(props.surveyId, props.nodeId, i, val)
                )
              }
            />
            <div className="actions">
              {!props.readOnly && (
                <>
                  {props.usageType !== SurveyUsageType.Relationship && (
                    <SidePopupV2
                      text="Trigger risk if this answer is selected"
                      inline
                      noWrap={true}
                      position="top"
                      popupDelay={250}
                    >
                      <IconButton
                        icon={<div className="cr-icon-risk" />}
                        onClick={() => {
                          // Create a new risk node with a conditional based on this answer.
                          const cond: Expression[] = [
                            [
                              {
                                nodeId: props.nodeId,
                                comparator: Comparator.Equals,
                                answerId: a.id,
                              },
                            ],
                          ];

                          const newNodeId = createUUID();
                          props.dispatch(
                            addBlankRiskNode(
                              props.surveyId,
                              props.parentId,
                              newNodeId,
                              props.index + 1,
                              cond
                            )
                          );

                          // Focus the new item
                          props.dispatch(
                            setCurrentFocusedNodeId(props.surveyId, newNodeId)
                          );

                          // And start editing the new risk
                          props.dispatch(
                            setEditRiskModalNodeId(props.surveyId, newNodeId)
                          );
                        }}
                      />
                    </SidePopupV2>
                  )}
                  <SidePopupV2
                    text="Delete"
                    inline
                    noWrap={true}
                    position="top"
                    popupDelay={250}
                  >
                    <IconButton
                      hoverColor={HoverColor.Red}
                      icon={<div className="cr-icon-trash" />}
                      onClick={() => {
                        if (
                          props.answerCanBeDeletedFunc &&
                          !props.answerCanBeDeletedFunc(
                            props.nodeId,
                            props.answers[i].id
                          )
                        ) {
                          // raise an error
                          props.dispatch(
                            SimpleErrorAlert(
                              "This answer cannot be deleted as automation rules exist which refer to it. Update your automation rules and try again."
                            )
                          );
                          return;
                        }
                        props.dispatch(
                          deleteSelectNodeAnswer(
                            props.surveyId,
                            props.nodeId,
                            i
                          )
                        );
                      }}
                    />
                  </SidePopupV2>
                </>
              )}
            </div>
          </div>
        ))
      )}
    </div>
    {props.validationErrors[ValidationError.selectNeedsMoreAnswers] && (
      <ValidationErrorText>
        {props.radio
          ? "At least two answers are required"
          : "At least one answer is required"}
      </ValidationErrorText>
    )}
    {props.validationErrors[ValidationError.selectAnswerMissingText] && (
      <ValidationErrorText>One or more answers are empty</ValidationErrorText>
    )}
    {props.answers?.length < maxPossibleAnswers && (
      <Button
        tertiary
        disabled={props.readOnly}
        onClick={() =>
          props.dispatch(addSelectNodeAnswer(props.surveyId, props.nodeId))
        }
      >
        {props.answers.length === 0 ? "+ Add option" : "+ Add another option"}
      </Button>
    )}
  </>
);

export default SelectNodeAnswers;

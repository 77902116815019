import { FC, useEffect, useState } from "react";
import TextField, { TextFieldData } from "../../_common/components/TextField";
import Button from "../../_common/components/core/Button";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  addDefaultSuccessAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import {
  deleteOrgSenderEmail,
  fetchOrgSenderEmail,
  OrganisationSenderEmailV1Resp,
  OrgSenderEmailDomain,
  rotateDkimOrgSenderEmail,
  updateOrgSenderEmail,
  updateOrgSenderEmailReturnPath,
  VerificationType,
  verifyOrgEmailDomain,
} from "../reducers/orgEmailSenderSettings.actions";
import "../style/components/OrgSenderEmailSettingsCard.scss";
import {
  VerticalStep,
  VerticalSteps,
} from "../../_common/components/VerticalSteps";
import ReportCard from "../../_common/components/ReportCard";
import ColorCheckbox from "./ColorCheckbox";
import { validateDomainName, validateEmail } from "../../_common/helpers";
import InfoBanner, { BannerType } from "./InfoBanner";
import ToggleWithLabel from "./ToggleWithLabel";

import XTable, {
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import PillLabel from "./PillLabel";
import { LabelColor } from "../../_common/types/label";
import SeverityIcon from "../../_common/components/SeverityIcon";
import IconButton from "../../_common/components/IconButton";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import ModalV2, {
  BaseModalProps,
  useModalV2,
} from "../../_common/components/ModalV2";
import ModalForm from "./modals/ModalForm";
import { trackEvent } from "../../_common/tracking";
import { Severity } from "../../_common/types/severity";
import { appConnect } from "../../_common/types/reduxHooks";

type OrgSenderEmailSettingsCardProps = DefaultThunkDispatchProp;

const OrgSenderEmailSettingsCard: FC<OrgSenderEmailSettingsCardProps> = ({
  dispatch,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isVerifyingDkim, setIsVerifyingDkim] = useState(false);
  const [isVerifyingReturnPath, setIsVerifyingReturnPath] = useState(false);
  const [settings, setSettings] = useState<
    OrganisationSenderEmailV1Resp | null | undefined
  >(undefined);
  const [emailDomain, setEmailDomain] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [fromName, setFromName] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [fromEmailAddress, setFromEmailAddress] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [useUserEmailAddress, setUseUserEmailAddress] = useState(false);

  const [domainValueError, setDomainValueError] = useState<string[]>([]);
  const [emailValueError, setEmailValueError] = useState<string[]>([]);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  // Onload, get existing email address if it is defined
  useEffect(() => {
    dispatch(fetchOrgSenderEmail()).then((r) => {
      setSettings(r);

      if (r) {
        if (r.emailDomains && r.emailDomains.length > 0) {
          setEmailDomain({
            value: r.emailDomains[0].emailDomain,
            isValid: true,
          });
        }

        if (r.currentDefaultSenderEmailAddress) {
          setFromName({
            value: r.currentDefaultSenderEmailAddress.fromName,
            isValid: true,
          });
          setFromEmailAddress({
            value:
              r.currentDefaultSenderEmailAddress.fromEmailAddress.split("@")[0],
            isValid: true,
          });
          setUseUserEmailAddress(
            r.currentDefaultSenderEmailAddress.useUserEmailAddresses
          );
          setDomainValueError([]);
          setEmailValueError([]);
        }
      }
    });
  }, [dispatch]);

  const save = (isActiveVal = false) => {
    setIsSaving(true);
    dispatch(
      updateOrgSenderEmail(
        `${fromEmailAddress.value}@${emailDomain.value}`,
        fromName.value,
        useUserEmailAddress,
        [emailDomain.value],
        isActiveVal
      )
    )
      .then((r) => {
        setIsSaving(false);
        setSettings(r);
        dispatch(addDefaultSuccessAlert("Custom email settings saved"));

        if (
          isActiveVal &&
          !settings?.currentDefaultSenderEmailAddress?.isActive
        ) {
          trackEvent("OrgBranding_CustomEmailAddressActivated");
        }
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const remove = () => {
    openConfirmationModal({
      title: `Remove custom email address`,
      description:
        "This will remove the custom email address settings and disable this feature in your account.",
      dangerousAction: true,
      cancelText: "Cancel",
      buttonText: "Remove",
      buttonAction: () => {
        setIsDeleting(true);

        return dispatch(deleteOrgSenderEmail())
          .then(() => {
            setIsDeleting(false);
            setEmailDomain({
              value: "",
              isValid: false,
            });
            setFromName({
              value: "",
              isValid: false,
            });
            setFromEmailAddress({
              value: "",
              isValid: false,
            });
            setUseUserEmailAddress(false);
            setSettings(null);
            setEmailValueError([]);
            setDomainValueError([]);
            dispatch(addDefaultSuccessAlert("Custom email settings removed"));
          })
          .catch(() => {
            setIsDeleting(false);
          });
      },
    });
  };

  const verifyDkim = (domain: string, isRotate = false) => {
    setIsVerifyingDkim(true);
    dispatch(verifyOrgEmailDomain(domain, VerificationType.DKIM))
      .then((r) => {
        setIsVerifyingDkim(false);
        setSettings(r);

        if (isRotate) {
          if (isKeyRotateComplete(r)) {
            dispatch(
              addDefaultSuccessAlert("New DKIM entry verified", [
                "You can now remove the existing DKIM DNS entry.",
              ])
            );
          } else {
            dispatch(
              addDefaultWarningAlert(
                "We could not verify a replacement DKIM DNS record exists yet",
                ["Please try again in a few minutes."]
              )
            );
          }
        } else if (isEmailDomainVerified(r)) {
          dispatch(addDefaultSuccessAlert("DNS entries verified"));
        } else {
          if (isDkimVerified(r)) {
            dispatch(addDefaultSuccessAlert("DKIM DNS record verified"));
          } else {
            dispatch(
              addDefaultWarningAlert(
                "We could not verify a DKIM DNS record exists yet",
                ["Please try again in a few minutes."]
              )
            );
          }
        }
      })
      .catch(() => {
        setIsVerifyingDkim(false);
      });
  };

  const verifyReturnPath = (domain: string) => {
    setIsVerifyingReturnPath(true);
    dispatch(verifyOrgEmailDomain(domain, VerificationType.ReturnPath))
      .then((r) => {
        setIsVerifyingReturnPath(false);
        setSettings(r);

        if (isEmailDomainVerified(r)) {
          dispatch(addDefaultSuccessAlert("DNS entries verified"));
        } else {
          if (isReturnPathVerified(r)) {
            dispatch(addDefaultSuccessAlert("Return-Path DNS record verified"));
          } else {
            dispatch(
              addDefaultWarningAlert(
                "We could not verify a Return-Path DNS record exists yet",
                ["Please try again in a few minutes."]
              )
            );
          }
        }
      })
      .catch(() => {
        setIsVerifyingReturnPath(false);
      });
  };

  const rotateDkim = (domain: string) => {
    return dispatch(rotateDkimOrgSenderEmail(domain)).then((r) => {
      setSettings(r);
      dispatch(
        addDefaultSuccessAlert("DKIM rotated successfully", [
          "Please add the new DNS record before removing the existing one.",
        ])
      );
    });
  };

  const updateReturnPath = (domain: string, newReturnPath: string) => {
    return dispatch(updateOrgSenderEmailReturnPath(domain, newReturnPath)).then(
      (r) => {
        setSettings(r);
        dispatch(addDefaultSuccessAlert("Return-Path updated"));
      }
    );
  };

  let currentEmailDomain: OrgSenderEmailDomain | undefined = undefined;
  if (settings?.emailDomains && settings.emailDomains.length > 0) {
    currentEmailDomain = settings?.emailDomains[0];
  }

  return (
    <VerticalSteps classname={"org-sender-email-settings-card"}>
      <VerticalStep
        stepNumber={1}
        name={"Customize sender details"}
        description={"Customize the sender information for your emails."}
      >
        {confirmationModal}
        <ReportCard newStyles>
          {settings === undefined && <LoadingBanner />}
          {(settings || settings === null) && (
            <>
              <div
                className={"report-card-content"}
                key={!!settings ? "edit" : ""}
              >
                <label>Email domain</label>
                <TextField
                  value={emailDomain.value}
                  onChanged={(value, isValid) => {
                    const domainValid = validateDomainName(value);
                    setDomainValueError(
                      !domainValid ? ["A valid domain is required"] : []
                    );
                    setEmailDomain({ value, isValid: isValid && domainValid });

                    if (isValid && domainValid) {
                      const emailValid = validateEmail(
                        `${fromEmailAddress.value}@${emailDomain.value}`
                      );
                      setEmailValueError(
                        !emailValid ? ["A valid email address is required"] : []
                      );
                      setFromEmailAddress({
                        value: fromEmailAddress.value,
                        isValid: emailValid,
                      });
                    }
                  }}
                  errorTexts={domainValueError}
                />
                <label>Default sender name</label>
                <TextField
                  required
                  value={fromName.value}
                  onChanged={(value, isValid) =>
                    setFromName({ value, isValid })
                  }
                />
                <label>Default sender email address</label>
                <div className={"default-email-address"}>
                  <TextField
                    value={fromEmailAddress.value}
                    onChanged={(value, isValid) => {
                      const emailValid = validateEmail(
                        `${value}@${emailDomain.value}`
                      );
                      setEmailValueError(
                        !emailValid ? ["A valid email address is required"] : []
                      );
                      setFromEmailAddress({
                        value,
                        isValid: isValid && emailValid,
                      });
                    }}
                    errorTexts={emailValueError}
                  />
                  <TextField
                    disabled
                    value={`@${emailDomain.value}`}
                    onChanged={() => {
                      // Nothing to do here
                    }}
                  />
                </div>
                <ColorCheckbox
                  radio
                  checked={!useUserEmailAddress}
                  label={"Always use default sender"}
                  helpPopup={
                    "External emails sent from actions on your account will be sent with your configured default sender."
                  }
                  onClick={() => setUseUserEmailAddress(!useUserEmailAddress)}
                />
                <ColorCheckbox
                  radio
                  checked={useUserEmailAddress}
                  label={"Use user email address when possible"}
                  helpPopup={
                    "External emails will be sent with the user's email address if possible. Note this will only apply to users whose email address belongs to the configured email domain."
                  }
                  onClick={() => setUseUserEmailAddress(!useUserEmailAddress)}
                />
              </div>
              <div className={"report-card-btn-group"}>
                {settings && settings.currentDefaultSenderEmailAddress && (
                  <Button
                    danger
                    onClick={remove}
                    loading={isDeleting}
                    disabled={
                      isSaving || isVerifyingDkim || isVerifyingReturnPath
                    }
                  >
                    Remove
                  </Button>
                )}
                <Button
                  primary
                  onClick={() => save()}
                  loading={isSaving}
                  disabled={
                    !emailDomain.isValid ||
                    !fromName.isValid ||
                    !fromEmailAddress.isValid ||
                    isDeleting ||
                    isVerifyingDkim ||
                    isVerifyingReturnPath
                  }
                >
                  Save details
                </Button>
              </div>
            </>
          )}
        </ReportCard>
      </VerticalStep>
      <VerticalStep
        stepNumber={2}
        name={"Configure DNS Settings"}
        description={
          "Add these two DNS records to your hosting provider then verify these two records are in place. It can take up to 48 hours for DNS records to propagate."
        }
        hidden={!currentEmailDomain}
      >
        <ReportCard newStyles>
          <div className={"verification-status"}>
            {currentEmailDomain && (
              <EmailDomainVerificationDisplay
                domain={currentEmailDomain}
                isVerifyingDkim={isVerifyingDkim}
                isVerifiyingReturnPath={isVerifyingReturnPath}
                onVerifyDkim={verifyDkim}
                onVerifyReturnPath={verifyReturnPath}
                onRotateDkim={rotateDkim}
                onUpdateReturnPath={updateReturnPath}
              />
            )}
          </div>
        </ReportCard>
      </VerticalStep>
      <VerticalStep
        stepNumber={3}
        name={"Activate"}
        description={
          "After you’ve verified your DNS settings, switch on the setting to start using custom email addresses."
        }
        hidden={!currentEmailDomain}
      >
        {!isEmailDomainVerified(settings) && (
          <InfoBanner
            type={BannerType.WARNING}
            message={
              "Your DNS records are not verified yet. Verify them to activate custom email addresses."
            }
          />
        )}
        {isEmailDomainVerified(settings) && (
          <InfoBanner
            type={BannerType.SUCCESS}
            message={
              "DNS records are verified. You can now activate custom email addresses."
            }
          />
        )}
        <ReportCard newStyles>
          <div className={"report-card-content"}>
            <ToggleWithLabel
              name={"tgl-u"}
              label={"Send externally facing emails from my custom domain"}
              selected={
                settings?.currentDefaultSenderEmailAddress?.isActive ?? false
              }
              disabled={
                !isEmailDomainVerified(settings) || isSaving || isDeleting
              }
              onClick={() => {
                save(
                  settings?.currentDefaultSenderEmailAddress?.isActive !==
                    undefined
                    ? !settings.currentDefaultSenderEmailAddress.isActive
                    : false
                );
              }}
            />
          </div>
        </ReportCard>
      </VerticalStep>
    </VerticalSteps>
  );
};

const isKeyRotateComplete = (
  settings: OrganisationSenderEmailV1Resp | null | undefined
): boolean => {
  if (
    !settings ||
    !settings.emailDomains ||
    settings.emailDomains.length === 0
  ) {
    return false;
  }

  return (
    settings.emailDomains[0].latestPostmarkApiDomainDetail.DKIMPendingHost ===
      "" && settings.emailDomains[0].latestPostmarkApiDomainDetail.DKIMVerified
  );
};

export const isEmailDomainVerified = (
  settings: OrganisationSenderEmailV1Resp | null | undefined
): boolean => {
  if (
    !settings ||
    !settings.emailDomains ||
    settings.emailDomains.length === 0
  ) {
    return false;
  }

  return (
    settings.emailDomains[0].latestPostmarkApiDomainDetail.DKIMVerified &&
    settings.emailDomains[0].latestPostmarkApiDomainDetail
      .ReturnPathDomainVerified
  );
};

const isDkimVerified = (
  settings: OrganisationSenderEmailV1Resp | null | undefined
) => {
  if (
    !settings ||
    !settings.emailDomains ||
    settings.emailDomains.length === 0
  ) {
    return false;
  }

  return settings.emailDomains[0].latestPostmarkApiDomainDetail.DKIMVerified;
};

const isReturnPathVerified = (
  settings: OrganisationSenderEmailV1Resp | null | undefined
) => {
  if (
    !settings ||
    !settings.emailDomains ||
    settings.emailDomains.length === 0
  ) {
    return false;
  }

  return settings.emailDomains[0].latestPostmarkApiDomainDetail
    .ReturnPathDomainVerified;
};

interface EmailDomainVerificationDisplayProps {
  domain: OrgSenderEmailDomain;
  isVerifyingDkim: boolean;
  isVerifiyingReturnPath: boolean;
  onVerifyDkim: (domain: string, isRotate: boolean) => void;
  onVerifyReturnPath: (domain: string) => void;
  onRotateDkim: (domain: string) => Promise<void>;
  onUpdateReturnPath: (domain: string, newReturnPath: string) => Promise<void>;
}

const EmailDomainVerificationDisplay: FC<
  EmailDomainVerificationDisplayProps
> = ({
  domain,
  isVerifyingDkim,
  isVerifiyingReturnPath,
  onVerifyDkim,
  onVerifyReturnPath,
  onRotateDkim,
  onUpdateReturnPath,
}) => {
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [openReturnPathUpdateModal, returnPathUpdateModal] = useModalV2(
    UpdateReturnPathModal
  );

  const getRows = () => {
    const rows: IXTableRow[] = [];

    // If we have a verified DKIM record
    if (domain.latestPostmarkApiDomainDetail.DKIMHost) {
      rows.push({
        id: "DKIM",
        cells: [
          <XTableCell key={"type"}>
            <PillLabel color={LabelColor.Blue}>TXT</PillLabel>
          </XTableCell>,
          <XTableCell className={"setting-type"} key={"setting-type"}>
            DKIM
          </XTableCell>,
          <XTableCell key={"value"}>
            <RecordNameAndValueDisplay
              recordName={domain.latestPostmarkApiDomainDetail.DKIMHost}
              value={domain.latestPostmarkApiDomainDetail.DKIMTextValue}
              onAction={
                domain.latestPostmarkApiDomainDetail.DKIMVerified &&
                !domain.latestPostmarkApiDomainDetail.DKIMPendingHost
                  ? () => {
                      openConfirmationModal({
                        title: `Rotate DKIM keys`,
                        description:
                          "It's recommended to rotate DKIM keys quarterly. Once a new DKIM key is generated, we'll keep using the existing key until the new one is verified.",
                        cancelText: "Cancel",
                        buttonText: "Rotate",
                        buttonAction: () => onRotateDkim(domain.emailDomain),
                      });
                    }
                  : undefined
              }
              actionIconClass={"cr-icon-redo"}
              actionText={"Rotate DKIM key"}
            />
          </XTableCell>,
          <XTableCell className={"verification"} key={"verification"}>
            <VerificationDisplay
              isVerified={domain.latestPostmarkApiDomainDetail.DKIMVerified}
              isVerifying={isVerifyingDkim}
              onVerify={() => onVerifyDkim(domain.emailDomain, false)}
            />
          </XTableCell>,
        ],
      });
    }

    const dkimPendingName =
      domain.latestPostmarkApiDomainDetail.DKIMPendingHost &&
      domain.latestPostmarkApiDomainDetail.DKIMHost
        ? "DKIM (pending)"
        : "DKIM";

    // If we have a pending DKIM record
    if (domain.latestPostmarkApiDomainDetail.DKIMPendingHost) {
      rows.push({
        id: "DKIM_pending",
        cells: [
          <XTableCell key={"type"}>
            <PillLabel color={LabelColor.Blue}>TXT</PillLabel>
          </XTableCell>,
          <XTableCell className={"setting-type"} key={"setting-type"}>
            {dkimPendingName}
          </XTableCell>,
          <XTableCell key={"value"}>
            <RecordNameAndValueDisplay
              recordName={domain.latestPostmarkApiDomainDetail.DKIMPendingHost}
              value={domain.latestPostmarkApiDomainDetail.DKIMPendingTextValue}
            />
          </XTableCell>,
          <XTableCell className={"verification"} key={"verification"}>
            <VerificationDisplay
              isVerified={false}
              isVerifying={isVerifyingDkim}
              onVerify={() =>
                onVerifyDkim(
                  domain.emailDomain,
                  domain.latestPostmarkApiDomainDetail.DKIMVerified &&
                    domain.latestPostmarkApiDomainDetail.DKIMHost !== ""
                )
              }
            />
          </XTableCell>,
        ],
      });
    }

    // Return-Path record
    rows.push({
      id: "CNAME",
      cells: [
        <XTableCell key={"type"}>
          <PillLabel color={LabelColor.Blue}>CNAME</PillLabel>
        </XTableCell>,
        <XTableCell className={"setting-type"} key={"setting-type"}>
          Return-Path
        </XTableCell>,
        <XTableCell key={"value"}>
          <RecordNameAndValueDisplay
            recordName={domain.latestPostmarkApiDomainDetail.ReturnPathDomain}
            value={
              domain.latestPostmarkApiDomainDetail.ReturnPathDomainCNAMEValue
            }
            onAction={() =>
              openReturnPathUpdateModal({
                emailDomain: domain.emailDomain,
                existingReturnPath:
                  domain.latestPostmarkApiDomainDetail.ReturnPathDomain,
                onUpdate: onUpdateReturnPath,
              })
            }
            actionIconClass={"cr-icon-pencil"}
            actionText={"Edit Return-Path subdomain"}
          />
        </XTableCell>,
        <XTableCell className={"verification"} key={"verification"}>
          <VerificationDisplay
            isVerified={
              domain.latestPostmarkApiDomainDetail.ReturnPathDomainVerified
            }
            isVerifying={isVerifiyingReturnPath}
            onVerify={() => onVerifyReturnPath(domain.emailDomain)}
          />
        </XTableCell>,
      ],
    });

    return rows;
  };

  return (
    <>
      {confirmationModal}
      {returnPathUpdateModal}
      <XTable
        className={"email-domain-verification-display"}
        columnHeaders={[
          {
            id: "type",
            text: "Type",
          },
          {
            id: "setting-type",
            text: "",
          },
          {
            id: "record_name_value",
            text: "Record Name / Value",
          },
          {
            id: "verified",
            text: "Verified?",
            className: "verification",
          },
        ]}
        rows={getRows()}
      />
    </>
  );
};

interface RecordNameAndValueDisplayProps {
  recordName: string;
  value: string;
  onAction?: () => void;
  actionIconClass?: string;
  actionText?: string;
}

const RecordNameAndValueDisplay: FC<RecordNameAndValueDisplayProps> = ({
  recordName,
  value,
  onAction,
  actionIconClass,
  actionText,
}) => {
  const copyRecordName = () => {
    navigator.clipboard.writeText(recordName);
  };

  const copyVal = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className={"record-name-and-value-display"}>
      <div className={"with-action"}>
        <div className={"record-name-and-value"}>
          <div className={"desc"}>Record Name</div>
          <div className={"val-container"}>
            <pre className={"val"}>{recordName}</pre>
            <IconButton
              icon={<i className={"cr-icon-copy"} />}
              hoverText={"Copy"}
              onClick={copyRecordName}
            />
            {onAction && (
              <IconButton
                icon={<div className={actionIconClass} />}
                onClick={onAction}
                hoverText={actionText}
              />
            )}
          </div>
        </div>
      </div>
      <div className={"record-name-and-value"}>
        <div className={"desc"}>Value</div>
        <div className={"val-container"}>
          <pre className={"val"}>{value}</pre>
          <IconButton
            icon={<i className={"cr-icon-copy"} />}
            hoverText={"Copy"}
            onClick={copyVal}
          />
        </div>
      </div>
    </div>
  );
};

interface VerificationDisplayProps {
  isVerified: boolean;
  isVerifying: boolean;
  onVerify: () => void;
}

const VerificationDisplay: FC<VerificationDisplayProps> = ({
  isVerified,
  isVerifying,
  onVerify,
}) => {
  if (isVerified) {
    return <SeverityIcon severity={Severity.Pass} />;
  } else {
    return (
      <Button loading={isVerifying} onClick={onVerify}>
        Check now
      </Button>
    );
  }
};

interface UpdateReturnPathModalProps extends BaseModalProps {
  emailDomain: string;
  existingReturnPath: string;
  onUpdate: (emailDomain: string, returnPath: string) => Promise<void>;
}

const UpdateReturnPathModal: FC<UpdateReturnPathModalProps> = ({
  active,
  onClose,
  emailDomain,
  existingReturnPath,
  onUpdate,
}) => {
  const [subdomain, setSubdomain] = useState<TextFieldData>({
    value: existingReturnPath.split(".", 1)[0],
    isValid: true,
  });
  const [subdomainValueError, setSubdomainValueError] = useState<string[]>([]);

  const [saving, setSaving] = useState(false);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerClassName={"update-return-path-modal-header"}
      className={"update-return-path-modal"}
      headerContent={`Update Return-Path for ${emailDomain}`}
      subHeaderContent={"Enter a new subdomain for the Return-Path"}
      footerContent={
        <>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!subdomain.isValid}
            onClick={() => {
              setSaving(true);
              onUpdate(emailDomain, `${subdomain.value}.${emailDomain}`)
                .then(() => {
                  setSaving(true);
                  onClose();
                })
                .catch(() => {
                  setSaving(false);
                });
            }}
            loading={saving}
          >
            Update
          </Button>
        </>
      }
      disallowClose
    >
      <InfoBanner
        type={BannerType.WARNING}
        message={
          "Updating the Return-Path to your email domain will disable your custom email address until the new Return-Path DNS record is verified."
        }
      />
      <ModalForm>
        <div className="form-section">
          <div className="form-section-input">
            <TextField
              value={subdomain.value}
              errorTexts={subdomainValueError}
              onChanged={(value, isValid) => {
                const isSubdomainValid = validateDomainName(
                  `${value}.${emailDomain}`
                );
                if (!isSubdomainValid) {
                  setSubdomainValueError(["A valid domain is required"]);
                } else {
                  setSubdomainValueError([]);
                }
                setSubdomain({
                  value: value,
                  isValid: isValid && isSubdomainValid,
                });
              }}
            />
            <TextField
              disabled
              value={`.${emailDomain}`}
              onChanged={() => {
                // Nothing to do here
              }}
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export default appConnect()(OrgSenderEmailSettingsCard);

import {
  DefaultAction,
  ISingleVendorData,
  SurveyListData,
  SurveysData,
} from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  getVendorData,
  SET_VENDOR_SURVEYS_DATA,
  setCustomerSurveys,
} from "./cyberRiskActions";
import {
  surveyDefaultSortCol,
  surveyDefaultSortDir,
  surveyListPageLimit,
} from "../views/Questionnaires";
import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
  setSurveyData,
} from "../../_common/reducers/commonActions";
import { Filters } from "../components/filter/types";
import {
  ISurveyListItemResponse,
  SurveyRiskVisibility,
  SurveyStatus,
} from "../../_common/types/survey";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { ITpvmSession } from "../../_common/types/thirdPartyMangedVendors";
import { isEqual as _isEqual, zip as _zip } from "lodash";
import { getDefaultFilters } from "./defaults";
import { refreshLatestVendorAssessmentForVendor } from "../../analyst_portal/reducers/analystManagedVendors.actions";
import { getFiltersFromState, getVendorFilterParams } from "./filters.actions";
import {
  fetchSurveyDetails,
  fetchSurveyMessages,
  fetchSurveyReminders,
  fetchSurveyTimeline,
} from "../../_common/reducers/surveyDetails.actions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { IUserMini } from "../../_common/types/user";

export interface BulkContactInfo {
  vendorContacts: {
    name: string;
    email: string;
    jobTitle?: string;
  }[];
  otherRecipients?: string[]; // Emails
}

export interface BulkCreateQuestionnaireVendorDetails {
  [vendorId: number]: BulkContactInfo;
}

export interface SurveyBulkCreateReq {
  surveyName: string;
  emailText: string;
  typeId: number;
  sections: number[];
  customerVendorName?: string;
  dueDate?: string;
  recipientReminderDate?: string;
  resendDate?: string;
  vendors: BulkCreateQuestionnaireVendorDetails;
  riskVisibility: SurveyRiskVisibility;
  vendorManagementRequestId?: number;
}

export interface SendExistingSurveyReq {
  emailText: string;
  emailSubject?: string;
  customerVendorName?: string;
  dueDate?: string;
  recipientReminderDate?: string;
  resendDate?: string;
  riskVisibility: SurveyRiskVisibility;
  existingSurveyId: number;
  contacts: BulkContactInfo;
  isPrefilledAnalystSurvey: boolean;
}

export interface SurveyBulkCreatResp {
  successful: Record<number, number>; // vendor ids where survey was sent successfully
  failed: Record<number, string>; // vendor ids where they weren't
  total: number; // the total number of vendors we tried to send this to
}

export const createSurveyBulk =
  (
    req: SurveyBulkCreateReq,
    usageType: SurveyUsageType = SurveyUsageType.Security
  ): DefaultAction<SurveyBulkCreatResp> =>
  async (dispatch, getState) => {
    const tpvm = grabTPVMSession(getState);

    let resp: SurveyBulkCreatResp;
    try {
      resp = await FetchCyberRiskUrl(
        "newsurvey/bulk/v1",
        {},
        {
          method: "POST",
          body: JSON.stringify(req),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating bulk surveys", e);

      throw e;
    }

    // refresh redux state
    for (const id in req.vendors) {
      dispatch(getSurveyListV2(true, parseInt(id)));
    }

    // Refresh the customer surveys list
    dispatch(
      fetchCustomerSurveyData(
        true,
        null,
        surveyListPageLimit,
        0,
        "date_due",
        "desc",
        false,
        "",
        usageType
      )
    );
    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // if it's a management session refresh the assessment list
    if (tpvm.tpvm) {
      Object.keys(req.vendors).forEach((id) => {
        dispatch(refreshLatestVendorAssessmentForVendor(parseInt(id)));
      });
    }

    return resp;
  };

export const sendExistingSurvey =
  (
    req: SendExistingSurveyReq,
    managedRequestId: number,
    vendorId: number,
    usageType: SurveyUsageType = SurveyUsageType.Security
  ): DefaultAction =>
  async (dispatch, getState) => {
    const tpvm = grabTPVMSession(getState);
    try {
      await FetchCyberRiskUrl(
        "newsurvey/send/v1",
        { managed_assessment_id: managedRequestId },
        {
          method: "POST",
          body: JSON.stringify(req),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error sending existing survey", e);
      throw e;
    }

    // refresh redux state
    dispatch(getSurveyListV2(true, vendorId));

    // Refresh the customer surveys list
    dispatch(
      fetchCustomerSurveyData(
        true,
        null,
        surveyListPageLimit,
        0,
        "date_due",
        "desc",
        false,
        "",
        usageType
      )
    );
    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // if it's a management session refresh the assessment list
    if (tpvm.tpvm) {
      dispatch(refreshLatestVendorAssessmentForVendor(vendorId));
    }
  };

interface surveyListV1Resp {
  archived: boolean;
  limit: number;
  offset: number;
  resendDueResults: any[];
  result: ISurveyListItemResponse[];
  sharedByOtherOrgs: boolean;
  sortCol: string;
  sortDir: string;
  status: "OK" | "Error";
  totalResults: number;
  filterText: string;
}

const fetchCustomerSurveyDataLastCall: { [k in SurveyUsageType]: string } = {
  [SurveyUsageType.Relationship]: "",
  [SurveyUsageType.Security]: "",
};

// fetches all the surveys issued by a customer for all vendors
export const fetchCustomerSurveyData = (
  forceRefresh: boolean,
  filters: Filters | null,
  limit: number,
  offset: number,
  sortCol = "date_due",
  sortDir = "desc",
  archived = false,
  filterText = "",
  usageType: SurveyUsageType = SurveyUsageType.Security
): DefaultAction => {
  return async (dispatch, getState) => {
    const surveys = getState().cyberRisk.customerData.surveys[usageType];
    const currentFilters = getState().cyberRisk.customerData.filters;
    let json: surveyListV1Resp | undefined = undefined;

    const newFilters = filters || currentFilters;

    if (
      !forceRefresh &&
      surveys &&
      !surveys.loading &&
      surveys.result &&
      surveys.limit === limit &&
      surveys.offset === offset &&
      surveys.sortCol === sortCol &&
      surveys.sortDir === sortDir &&
      surveys.archived === archived &&
      surveys.filterText === filterText &&
      _isEqual(
        currentFilters.selectedSurveyStatuses,
        newFilters.selectedSurveyStatuses
      )
    ) {
      // cached, don't fetch again
      return;
    }

    // set state of crState.surveys to loading (before loading)
    dispatch(
      setCustomerSurveys(
        {
          ...surveys,
          loading: true,
          error: null,
        },
        usageType
      )
    );

    // now load the customer's surveys
    const thisCall = crypto.randomUUID();
    fetchCustomerSurveyDataLastCall[usageType] = thisCall;

    try {
      json = await FetchCyberRiskUrl<surveyListV1Resp>(
        "surveys/list/v1",
        {
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          include_resend_due: true,
          limit: limit,
          offset: offset,
          sort_col: sortCol,
          sort_dir: sortDir,
          archived,
          vendor_tiers: newFilters.vendorTiers,
          vendor_assessment_classifications:
            newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          usage_type: usageType,
          filter_text: filterText,
          vendor_ids: newFilters.vendorIds,
          portfolio_ids: newFilters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          has_status: newFilters.selectedSurveyStatuses,
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching customer survey data", e);
    }

    // make sure this hasn't been called again while we were waiting
    if (thisCall !== fetchCustomerSurveyDataLastCall[usageType]) {
      return;
    }

    // handle any errors first
    if (!json || json.status !== "OK") {
      dispatch(
        setCustomerSurveys(
          {
            loading: false,
            error: {
              errorText: "Error fetching questionnaires",
              actionText: "Retry",
              actionOnClick: () =>
                dispatch(
                  fetchCustomerSurveyData(true, null, surveyListPageLimit, 0)
                ),
            },
            result: null,
          },
          usageType
        )
      );
      return;
    }

    // update the common cache of surveys by id
    const surveyIDs = [];
    if (json.result && json.result.length > 0) {
      for (let i = 0; i < json.result.length; i++) {
        dispatch(setSurveyData(json.result[i].id, { survey: json.result[i] }));
        surveyIDs.push(json.result[i].id);
      }
    }

    const resendDueSurveyIds = [];
    if (json.resendDueResults) {
      for (let i = 0; i < json.resendDueResults.length; i++) {
        dispatch(
          setSurveyData(json.resendDueResults[i].id, {
            survey: json.resendDueResults[i],
          })
        );
        resendDueSurveyIds.push(json.resendDueResults[i].id);
      }
    }

    // set the good data
    dispatch(
      setCustomerSurveys(
        {
          loading: false,
          error: null,
          result: surveyIDs,
          resendDueResults: resendDueSurveyIds,
          limit: json.limit,
          offset: json.offset,
          totalResults: json.totalResults,
          sortCol: json.sortCol,
          sortDir: json.sortDir,
          archived: json.archived,
          filterText: json.filterText,
        },
        usageType
      )
    );
  };
};

// fetchVendorRelationshipSurveys fetches all the relationship surveys associated with a vendor.
export const fetchVendorRelationshipSurveys = (
  vendorId: number,
  limit: number | null,
  offset: number | null,
  forceRefresh = false,
  sortCol: string | null = "date_due",
  sortDir: string | null = "desc",
  archived = false,
  sharedByOtherOrgs = false,
  filterText = "",
  noCache = false,
  filters?: Filters
): DefaultAction<ISurveyListItemResponse[] | undefined> => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(
      getState,
      vendorId,
      false,
      tpvmSession
    ) as ISingleVendorData;
    const usageType = SurveyUsageType.Relationship;

    const surveys =
      vendor && vendor.surveys ? vendor.surveys[usageType] ?? null : null;

    // use previous values if everything is null
    if (limit === null) limit = surveys && surveys.limit ? surveys.limit : 20;
    if (offset === null)
      offset = surveys && surveys.offset ? surveys.offset : 0;
    if (sortCol === null)
      sortCol = surveys && surveys.sortCol ? surveys.sortCol : "date_due";
    if (sortDir === null)
      sortDir = surveys && surveys.sortDir ? surveys.sortDir : "desc";

    const currentFilters = getFiltersFromState(getState(), vendorId, false);
    const newFilters = filters ?? currentFilters ?? getDefaultFilters();

    // if using shared by others we ignore the limit and offset looking at the cache since this is overridden in the endpoint
    const pagingMatches =
      (surveys && surveys.offset === offset && surveys.sortCol === sortCol) ||
      sharedByOtherOrgs;

    if (
      !noCache &&
      !forceRefresh &&
      surveys &&
      !surveys.loading &&
      surveys.result &&
      pagingMatches &&
      surveys.sortCol === sortCol &&
      surveys.sortDir === sortDir &&
      surveys.archived === archived &&
      surveys.sharedByOtherOrgs === sharedByOtherOrgs &&
      surveys.filterText === filterText &&
      _isEqual(
        currentFilters.selectedSurveyStatuses,
        newFilters.selectedSurveyStatuses
      )
    ) {
      // cached, dont fetch again
      return;
    }

    let json: surveyListV1Resp | undefined = undefined;

    if (!noCache) {
      dispatch(
        setVendorSurveysData(
          vendorId,
          {
            loading: true,
            error: null,
          } as SurveysData,
          usageType,
          tpvmSession
        )
      );
    }

    try {
      json = await FetchCyberRiskUrl<surveyListV1Resp>(
        "surveys/list/v1/",
        {
          vendorId,
          archived,
          shared_by_other_orgs: sharedByOtherOrgs,
          limit,
          offset,
          include_resend_due: !sharedByOtherOrgs,
          sort_col: sortCol,
          sort_dir: sortDir,
          usage_type: usageType,
          filter_text: filterText,
          has_status: newFilters.selectedSurveyStatuses,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendors surveys", e);
    }

    if (!json || json.status !== "OK") {
      if (!noCache) {
        dispatch(
          setVendorSurveysData(
            vendorId,
            {
              loading: false,
              error: {
                errorText: "Error fetching questionnaires",
                actionText: "Retry",
                actionOnClick: () =>
                  dispatch(
                    fetchVendorRelationshipSurveys(
                      vendorId,
                      surveyListPageLimit,
                      0,
                      true,
                      sortCol,
                      sortDir,
                      archived,
                      sharedByOtherOrgs,
                      filterText
                    )
                  ),
              },
            } as SurveysData,
            usageType,
            tpvmSession
          )
        );
      }
      return;
    }

    // update the common cache of surveys by id
    if (!noCache) {
      const surveyIDs = [];
      for (let i = 0; i < json.result.length; i++) {
        dispatch(setSurveyData(json.result[i].id, { survey: json.result[i] }));
        surveyIDs.push(json.result[i].id);
      }

      const resendDueSurveyIds = [];
      if (json.resendDueResults) {
        for (let i = 0; i < json.resendDueResults.length; i++) {
          dispatch(
            setSurveyData(json.resendDueResults[i].id, {
              survey: json.resendDueResults[i],
            })
          );
          resendDueSurveyIds.push(json.resendDueResults[i].id);
        }
      }

      dispatch(
        setVendorSurveysData(
          vendorId,
          {
            loading: false,
            error: null,
            result: surveyIDs,
            resendDueResults: resendDueSurveyIds,
            totalResults: json.totalResults,
            offset: json.offset,
            limit: json.limit,
            sortCol: json.sortCol,
            sortDir: json.sortDir,
            archived: json.archived,
            sharedByOtherOrgs: json.sharedByOtherOrgs,
            filterText: json.filterText,
          },
          usageType,
          tpvmSession
        )
      );
    }

    return json.result;
  };
};

export const setVendorSurveysData = (
  vendorId: number,
  data: SurveysData,
  usageType: SurveyUsageType,
  tpvmSession: ITpvmSession | null = null
) => {
  return {
    type: SET_VENDOR_SURVEYS_DATA,
    vendorId,
    data,
    usageType,
    tpvmSession,
  };
};

interface surveyResendV1Resp {
  status: string;
  newSurveyId: number;
}

interface surveyResendReq {
  surveyID: number;
  message: string;
  incrementVersion: boolean;
  recipients?: {
    firstName?: string;
    email: string;
  }[];
  dueDate?: string;
  reminderDate?: string;
  conditionalResend?: boolean;
}

interface surveyRelationshipResendReq {
  surveyID: number;
  message: string;
  incrementVersion: boolean;
  recipients?: {
    firstName?: string;
    jobTitle?: string;
    email: string;
  }[];
  dueDate?: string;
  reminderDate?: string;
  conditionalResend?: boolean;
}

// reopenSurvey takes a survey id for a completed survey and re-opens it. if the flag 'resendOnTemplateChange' is set, and
// if the survey type structure has been updated since the survey was last sent, then a new survey instance is resent (with
// the existing answers populated) instead of reopenning.
export const reopenSurvey = (
  surveyID: number,
  message: string,
  incrementVersion: boolean,
  emailAddresses: string[],
  toFirstNames: (string | undefined)[],
  dueDate?: string,
  recipientReminderDate?: string
): DefaultAction<number> => {
  return async (dispatch, getState) => {
    let json: surveyResendV1Resp;

    const req: surveyResendReq = {
      surveyID,
      message,
      incrementVersion,
    };

    if (incrementVersion) {
      req.dueDate = dueDate;
      req.recipients = [];
      if (emailAddresses) {
        req.recipients = _zip(emailAddresses, toFirstNames).map(
          ([email, firstName]) => ({ email: email ?? "", firstName })
        );
      }

      if (recipientReminderDate) {
        req.reminderDate = recipientReminderDate;
      }
    }

    try {
      json = await FetchCyberRiskUrl<surveyResendV1Resp>(
        "reopen_survey/v1",
        {},
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error reopening survey", e);

      throw new Error("Error reopening the survey. Please try again later.");
    }

    if (!json || json.status !== "OK") {
      throw new Error("Error reopening the survey. Please try again later.");
    }

    // update global survey data. Also fetch the reminders, timeline and messages
    // as they will me updated by this action.
    dispatch(fetchSurveyDetails(surveyID, true));
    dispatch(fetchSurveyMessages(surveyID, true, true));
    dispatch(fetchSurveyTimeline(surveyID, true));
    dispatch(fetchSurveyReminders(surveyID, true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return surveyID;
  };
};

// reopenRelationshipSurvey takes a survey id for a completed survey and re-opens it. However, if the survey type structure has been
// updated since the survey was last sent, then a new survey instance is resent (with the existing answers populated) instead of reopening.
export const reopenRelationshipSurvey = (
  surveyID: number,
  message: string,
  incrementVersion: boolean,
  emailAddresses: string[],
  toFirstNames: (string | undefined)[],
  toJobTitles: (string | undefined)[],
  dueDate?: string,
  recipientReminderDate?: string
): DefaultAction<number> => {
  return async (dispatch, getState) => {
    let json: surveyResendV1Resp;

    const req: surveyRelationshipResendReq = {
      surveyID,
      message,
      incrementVersion,
    };

    req.recipients = [];
    if (emailAddresses) {
      req.recipients = _zip(emailAddresses, toFirstNames, toJobTitles).map(
        ([email, firstName, jobTitle]) => ({
          email: email ?? "",
          firstName,
          jobTitle,
        })
      );
    }
    if (incrementVersion) {
      req.dueDate = dueDate;
      if (recipientReminderDate) {
        req.reminderDate = recipientReminderDate;
      }
    }

    try {
      json = await FetchCyberRiskUrl<surveyResendV1Resp>(
        "reopen_relationship_survey/v1",
        { survey_id: req.surveyID },
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error reopening relationship survey", e);
      throw new Error(
        "Error reopening the relationship survey. Please try again later."
      );
    }

    if (!json || json.status !== "OK") {
      throw new Error(
        "Error reopening the relationship survey. Please try again later."
      );
    }

    // update global survey data. Also fetch the reminders, timeline and messages
    // as they will me updated by this action.
    if (json.newSurveyId > 0) {
      surveyID = json.newSurveyId;
    }
    dispatch(fetchSurveyDetails(surveyID, true));
    dispatch(fetchSurveyMessages(surveyID, true, true));
    dispatch(fetchSurveyTimeline(surveyID, true));
    dispatch(fetchSurveyReminders(surveyID, true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return surveyID;
  };
};

// conditionalReopenResendSurvey takes a survey id for a completed survey and re-opens it. However, if the survey type structure
// has been updated since the survey was last sent, then a new survey instance is resent (with the existing answers populated)
// instead of reopening.
export const conditionalReopenResendSurvey = (
  surveyID: number,
  message: string,
  incrementVersion: boolean,
  emailAddresses: string[],
  toFirstNames: (string | undefined)[],
  dueDate?: string,
  recipientReminderDate?: string
): DefaultAction<number> => {
  return async (dispatch, getState) => {
    let json: surveyResendV1Resp;

    const req: surveyResendReq = {
      surveyID,
      message,
      incrementVersion,
      conditionalResend: true,
    };

    if (incrementVersion) {
      req.dueDate = dueDate;
      req.recipients = [];
      if (emailAddresses) {
        req.recipients = _zip(emailAddresses, toFirstNames).map(
          ([email, firstName]) => ({ email: email ?? "", firstName })
        );
      }

      if (recipientReminderDate) {
        req.reminderDate = recipientReminderDate;
      }
    }

    try {
      json = await FetchCyberRiskUrl<surveyResendV1Resp>(
        "reopen_survey/v1",
        {},
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error conditionally reopening survey", e);

      throw new Error(
        "Error conditionally reopening the survey. Please try again later."
      );
    }

    if (!json || json.status !== "OK") {
      throw new Error(
        "Error conditionally reopening the survey. Please try again later."
      );
    }

    // update global survey data. Also fetch the reminders, timeline and messages
    // as they will me updated by this action.
    dispatch(fetchSurveyDetails(surveyID, true));
    dispatch(fetchSurveyMessages(surveyID, true, true));
    dispatch(fetchSurveyTimeline(surveyID, true));
    dispatch(fetchSurveyReminders(surveyID, true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return surveyID;
  };
};

export const SET_CUSTOMER_SURVEYS_DATA_V2 = "SET_CUSTOMER_SURVEYS_DATA_V2";
export const setCustomerSurveysDataV2 = (
  data: Partial<SurveyListData>,
  tpmvSession: ITpvmSession
) => ({
  type: SET_CUSTOMER_SURVEYS_DATA_V2,
  data,
  tpmvSession,
});

export const SET_VENDOR_SURVEYS_DATA_V2 = "SET_VENDOR_SURVEYS_DATA_V2";
export const setVendorSurveysDataV2 = (
  vendorId: number,
  data: Partial<SurveyListData>,
  tpvmSession: ITpvmSession
) => ({
  type: SET_VENDOR_SURVEYS_DATA_V2,
  vendorId,
  data,
  tpvmSession,
});

interface SurveyListV2RespItemSource {
  isVendorShared: boolean;
  isOrgGroupShared: boolean;
  fromUser?: IUserMini;
}

export interface SurveyListV2RespItem {
  surveyId: number;
  orgOriginalPrivateSurveyID?: number;
  orgId: number;
  orgName: string;
  isPublic: boolean;
  datastoreVendorID: number;
  vendorName: string;
  vendorHostname: string;
  questionnaireName: string;
  questionnaireTypeName: string;
  dueDate?: string;
  status: SurveyStatus;
  archived: boolean;
  score: number;
  isExcludedFromScoring: boolean;
  resendDueDate?: string;
  hasAttachments: boolean;
  source: SurveyListV2RespItemSource;
  hasAccess: boolean;
  canInclude: boolean;
  numQuestions: number;
  numAnswers: number;
  numAttachments: number;
  numMessages: number;
  numUnreadMessages: number;
  numPossibleRisks: number;
  numActiveRisks: number;
  numRisksInRemediation: number;
  previousStatus: string;
}

interface SurveyListV2Resp {
  result: SurveyListV2RespItem[];
  totalResults: number;
  limit: number;
  offset: number;
  sortCol: string;
  sortDir: string;
  archived: boolean;
  filterText: string;
}

export const getSurveyListV2 =
  (
    force = false,
    vendor_id?: number,
    offset = 0,
    limit: number = surveyListPageLimit,
    sort_col: string = surveyDefaultSortCol,
    sort_dir: string = surveyDefaultSortDir,
    archived = false,
    filter_text = "",
    newFilters?: Filters
  ): DefaultAction =>
  async (dispatch, getState) => {
    const tpmvSession = grabTPVMSession(getState);
    const surveys: SurveyListData | undefined = !vendor_id
      ? getState().cyberRisk.customerData.surveyList
      : getVendorData(getState, vendor_id, false, tpmvSession)?.surveyList;

    const filters =
      newFilters ??
      getFiltersFromState(getState(), vendor_id, false) ??
      getDefaultFilters();

    // check cache
    if (
      !force &&
      surveys &&
      !surveys.loading &&
      surveys.result.length > 0 &&
      surveys.sortCol === sort_col &&
      surveys.sortDir === sort_dir &&
      surveys.archived === archived &&
      surveys.filterText === filter_text
    ) {
      return;
    }

    if (vendor_id) {
      dispatch(
        setVendorSurveysDataV2(vendor_id, { loading: true }, tpmvSession)
      );
    } else {
      dispatch(setCustomerSurveysDataV2({ loading: true }, tpmvSession));
    }

    let filterParams = {
      include_status: filters.selectedSurveyStatuses,
      include_attribute: filters.selectedSurveyAttributes,
    };

    if (!vendor_id) {
      filterParams = { ...filterParams, ...getVendorFilterParams(filters) };
    }

    let json: SurveyListV2Resp;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/list/v2",
        {
          vendor_id,
          offset,
          limit,
          sort_col,
          sort_dir,
          archived,
          force,
          filter_text,
          ...filterParams,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting survey list V2", e);
      if (vendor_id) {
        dispatch(
          setVendorSurveysDataV2(
            vendor_id,
            { loading: false, error: true },
            tpmvSession
          )
        );
      } else {
        dispatch(
          setCustomerSurveys({ loading: false, error: true }, tpmvSession)
        );
      }

      throw e;
    }

    if (vendor_id) {
      dispatch(
        setVendorSurveysDataV2(
          vendor_id,
          {
            loading: false,
            error: false,
            result: json.result,
            archived: json.archived,
            filterText: json.filterText,
            limit: json.limit,
            offset: json.offset,
            sortCol: json.sortCol,
            sortDir: json.sortDir,
            totalResults: json.totalResults,
          },
          tpmvSession
        )
      );
    } else {
      dispatch(
        setCustomerSurveysDataV2(
          {
            loading: false,
            error: false,
            result: json.result,
            archived: json.archived,
            filterText: json.filterText,
            limit: json.limit,
            offset: json.offset,
            sortCol: json.sortCol,
            sortDir: json.sortDir,
            totalResults: json.totalResults,
          },
          tpmvSession
        )
      );
    }
  };

export const refreshSurveyListsIfNecessary =
  (vendorID?: number): DefaultAction =>
  async (dispatch, getState) => {
    // First refresh the all-vendors list if necesary
    const surveyData = getState().cyberRisk.customerData.surveyList;
    if (surveyData) {
      dispatch(
        getSurveyListV2(
          true,
          undefined,
          surveyData.offset,
          surveyData.limit ?? surveyListPageLimit,
          surveyData.sortCol,
          surveyData.sortDir,
          surveyData.archived,
          surveyData.filterText,
          getState().cyberRisk.customerData.filters
        )
      );
    }

    if (vendorID) {
      const vendorSurveyData =
        getState().cyberRisk.vendors[vendorID]?.surveyList;
      if (vendorSurveyData) {
        dispatch(
          getSurveyListV2(
            true,
            vendorID,
            vendorSurveyData.offset,
            vendorSurveyData.limit ?? surveyListPageLimit,
            vendorSurveyData.sortCol,
            vendorSurveyData.sortDir,
            vendorSurveyData.archived,
            vendorSurveyData.filterText
          )
        );
      }
    }
  };

export const SET_RESEND_DUE_SURVEYS = "SET_RESEND_DUE_SURVEYS";
export const setResendDueSurveys = (
  result: SurveyListV2RespItem[],
  tpvmSession: ITpvmSession,
  vendorId?: number
) => ({
  type: SET_RESEND_DUE_SURVEYS,
  tpvmSession,
  result,
  vendorId,
});

export const getResendDueSurveys =
  (vendor_id?: number, force = false): DefaultAction =>
  async (dispatch, getState) => {
    const tpmvSession = grabTPVMSession(getState);

    const surveys: SurveyListV2RespItem[] | undefined = !vendor_id
      ? getState().cyberRisk.customerData.resendDueSurveys
      : (
          getVendorData(
            getState,
            vendor_id,
            false,
            tpmvSession
          ) as ISingleVendorData
        ).resendDueSurveys;

    const filters =
      getFiltersFromState(getState(), vendor_id, false) ?? getDefaultFilters();

    if (!force && Array.isArray(surveys) && surveys.length > 0) {
      // Request was not forced, and cached state was found
      // Therefore bail out now and let the code continue to use the cached state.
      return;
    }

    const filterParms = vendor_id ? getVendorFilterParams(filters) : {};

    let json: SurveyListV2RespItem[];
    try {
      json = await FetchCyberRiskUrl(
        "surveys/resend_due/v1",
        {
          vendor_id,
          ...filterParms,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting surveys that are due to be resent");
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching surveys that are due to be resent"
        )
      );
      throw e;
    }

    dispatch(setResendDueSurveys(json, tpmvSession, vendor_id));
  };

export const setSharedSurveyArchived = (
  surveyID: number,
  isPublic: boolean,
  archived = false,
  vendorId?: number
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "sharedsurveyarchive/v1/",
        {
          survey_id: surveyID,
          is_public: isPublic,
          archived: archived,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting shared survey archived status", e);

      throw new Error(
        "Error setting shared questionnaire archived status. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(getSurveyListV2(true, vendorId));
  };
};

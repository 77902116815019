import { Fragment } from "react";
import moment from "moment";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import PillLabel from "../PillLabel";
import { userReportExportStatuses } from "../../views/reporting/ReportExportsV2";
import * as ReportTypes from "../../constants/reportTypes";
import "../../style/components/reporting/ReportExportsList.scss";
import {
  BreachsightExecutiveSummaryExportMeta,
  ComplianceReportExportMeta,
  DisplayableExportSchedule,
  ExecSummaryReportExportMeta,
  ExportFiletype,
  ExportItem,
  ExportStatus,
  ExportType,
  IPsReportExportMeta,
  RemediationRequestDetailsExportMeta,
  RemediationRequestExportMeta,
  RiskProfileIncludingSubsidiariesExportMeta,
  RiskProfileReportExportMeta,
  RiskWaiversExportMeta,
  VendorRiskExecutiveSummaryExportMeta,
  VulnerabilitiesReportExportMeta,
  WebsitesReportExportMeta,
} from "../../../_common/types/exportReport";
import { LabelColor } from "../../../_common/types/label";
import FileTypeIconExcel from "../../../_common/images/file-type-icon-excel.svg";
import FileTypeIconWord from "../../../_common/images/file-type-icon-word.svg";
import FileTypeIconPDF from "../../../_common/images/file-type-icon-pdf.svg";
import FileTypeIconPPTX from "../../../_common/images/file-type-icon-pptx.svg";
import { getVendorWords } from "../../../_common/constants";
import classnames from "classnames";
import { AssuranceType } from "../../../_common/types/organisations";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import { HoverLocation } from "../../../_common/components/IconButton";
import { breachSightProductDisplayName } from "../../../_common/types/products";

const columnHeaders: IXTableColumnHeader[] = [
  { id: "read", text: "", sortable: false },
  { id: "filetype-icon", text: "", sortable: false },
  {
    id: "filename",
    text: "Filename",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "module",
    text: "Source",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "type",
    text: "Report Type",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "requested",
    text: "Date Created",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
];

interface ISortedBy {
  columnId: string;
  direction: SortDirection;
}

interface IReportExportListProps {
  sortedAndFilteredExports: ExportItem[];
  sortedBy: ISortedBy;
  selectedIds: number[];
  loading: boolean;
  onSortChange: (columnId: string, direction: SortDirection) => void;
  pagination: {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
  };
  onSelectAll: (select: boolean) => void;
  onSelect: (id: string | number) => void;
  onDownloadItem: (item: ExportItem, isArchivedView?: boolean) => void;
  onArchiveItems: (items: ExportItem[], isArchive: boolean) => void;
  onDeleteItems: (items: ExportItem[]) => void;
  onRenameItems: (items: ExportItem[]) => void;
  assuranceType: AssuranceType;
}

const ReportExportsList = (props: IReportExportListProps) => {
  return (
    <XTable
      className="report-exports-list"
      columnHeaders={columnHeaders}
      loading={props.loading}
      // numLoadingRows={6}
      rows={getRows(
        props.sortedAndFilteredExports,
        props.selectedIds,
        props.onDownloadItem,
        props.onArchiveItems,
        props.onDeleteItems,
        props.onRenameItems,
        props.assuranceType
      )}
      iconOptions
      sortedBy={props.sortedBy}
      onSortChange={props.onSortChange}
      selectable
      onSelectAllClick={() => props.onSelectAll(true)}
      onSelectNoneClick={() => props.onSelectAll(false)}
      onSelectToggle={props.onSelectAll}
      onSelectClick={(id: string | number) => {
        props.onSelect(id);
      }}
      pagination={
        props.pagination.totalPages > 1 ? props.pagination : undefined
      }
    />
  );
};

export default ReportExportsList;

// Row processing logic

export const userReportExportModules = {
  breachSight: 0,
  vendorRisk: 1,
  platform: 2,
  userRisk: 3,
};

const customerVendorReportSectionsToNameAndModules = (
  reportSections: string[],
  isCustomer: boolean,
  assuranceType: AssuranceType
) => {
  const vendorWords = getVendorWords(assuranceType);
  const sections = [...reportSections];
  sections.sort();

  let breachSightModule = false;
  let vendorRiskModule = false;

  const sectionNames = reportSections.map((sec) => {
    switch (sec) {
      case "vendor":
        if (isCustomer) {
          breachSightModule = true;
        } else {
          vendorRiskModule = true;
        }
        return "Risk Profile";
      case "vendor_summary":
        vendorRiskModule = true;
        return `${vendorWords.singularTitleCase} Summary`;
      case "websites":
        if (isCustomer) {
          breachSightModule = true;
        } else {
          vendorRiskModule = true;
        }
        return "Domains";
      case "portfolio":
        vendorRiskModule = true;
        return vendorWords.vendorsPageTitle;
      case "assessments":
        vendorRiskModule = true;
        return "Questionnaires";
      case "risk_assessment":
        vendorRiskModule = true;
        return "Risk Assessment";
      default:
        return "";
    }
  });

  const sectionModules = [];
  if (breachSightModule) {
    sectionModules.push(userReportExportModules.breachSight);
  }
  if (vendorRiskModule) {
    sectionModules.push(userReportExportModules.vendorRisk);
  }

  return {
    sectionNames,
    sectionModules,
  };
};

const execSummaryReportSectionsToNameAndModules = (
  reportSections: string[],
  assuranceType: AssuranceType
) => {
  const vendorWords = getVendorWords(assuranceType);

  let breachsightModule = false;
  let vendorRiskModule = false;
  const sectionNames = [];

  for (let i = 0; i < reportSections.length; i++) {
    switch (reportSections[i]) {
      case ReportTypes.BreachSightOverview:
        breachsightModule = true;
        sectionNames.push(`${breachSightProductDisplayName} Overview`);
        break;
      case ReportTypes.BreachSightRiskBreakdown:
        breachsightModule = true;
        sectionNames.push(
          "${breachSightProductDisplayName} Risk Category Breakdown"
        );
        break;
      case ReportTypes.BreachSightCompetitorAnalysis:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Competitor Analysis`
        );
        break;

      case ReportTypes.BreachSightSubsidiariesOverview:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Subsidiaries Overview`
        );
        break;
      case ReportTypes.BreachSightSubsidiariesScoreDistribution:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Subsidiaries Security Rating Distribution`
        );
        break;
      case ReportTypes.BreachSightSubsidiariesRatings:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Highest and Lowest Rated Subsidiaries`
        );
        break;
      case ReportTypes.BreachSightSubsidiariesAnalysis:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Subsidiaries Organization Analysis`
        );
        break;
      case ReportTypes.BreachSightSubsidiariesRiskBreakdown:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Subsidiaries Risk Category Breakdown`
        );
        break;
      case ReportTypes.BreachSightSubsidiariesGeolocation:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Subsidiaries Geolocation`
        );
        break;
      case ReportTypes.BreachSightSubsidiariesCompetitors:
        breachsightModule = true;
        sectionNames.push(
          `${breachSightProductDisplayName} Subsidiaries Competitors`
        );
        break;

      case ReportTypes.VendorRiskRiskMatrix:
        vendorRiskModule = true;
        sectionNames.push(
          `${vendorWords.singularTitleCase} Risk by Business Impact`
        );
        break;
      case ReportTypes.VendorRiskOverview:
        vendorRiskModule = true;
        sectionNames.push(`${vendorWords.vendorRiskModuleName} Overview`);
        break;
      case ReportTypes.VendorRiskGeolocation:
        vendorRiskModule = true;
        sectionNames.push(`${vendorWords.singularTitleCase} Geolocation`);
        break;
      case ReportTypes.VendorRiskRatingsBreakdown:
        vendorRiskModule = true;
        sectionNames.push(
          `Highest and Lowest Rated ${vendorWords.singularTitleCase}`
        );
        break;
      case ReportTypes.VendorRiskSupplyChain:
        vendorRiskModule = true;
        sectionNames.push("Fourth Parties");
        break;
    }
  }

  const sectionModules = [];
  if (breachsightModule) {
    sectionModules.push(userReportExportModules.breachSight);
  }
  if (vendorRiskModule) {
    sectionModules.push(userReportExportModules.vendorRisk);
  }

  return {
    sectionNames,
    sectionModules,
  };
};

export const getReportMeta = (
  item: ExportItem | DisplayableExportSchedule,
  assuranceType: AssuranceType
) => {
  const modules = [];
  let reportName = "";
  let sections: string[] = [];
  const vendorWords = getVendorWords(assuranceType);
  switch (item.exportType) {
    case ExportType.CustomerOrVendorReports: {
      // This export type is no longer supported. Kept here for compatibility with old reports.
      const exportMeta = item.exportMeta as {
        reportSections: string[];
        customer: boolean;
      };
      const { sectionNames, sectionModules } =
        customerVendorReportSectionsToNameAndModules(
          exportMeta.reportSections,
          exportMeta.customer,
          assuranceType
        );
      modules.push(...sectionModules);
      sections = sectionNames;

      if (sections.length > 1) {
        reportName = "Multiple Reports";
      } else {
        reportName = sections[0];
      }

      break;
    }
    case ExportType.ExecSummaryReports: {
      const exportMeta = item.exportMeta as ExecSummaryReportExportMeta;
      const { sectionNames, sectionModules } =
        execSummaryReportSectionsToNameAndModules(
          exportMeta.reportSections,
          assuranceType
        );
      modules.push(...sectionModules);
      sections = sectionNames;

      if (isBreachSightModule(modules)) {
        reportName = `${breachSightProductDisplayName} Executive Summary`;
        if (exportMeta.isIncludingSubsidiaries) {
          if (
            exportMeta.subsidiariesFilter &&
            exportMeta.subsidiariesFilter.subsidiariesOnly
          ) {
            reportName = `${breachSightProductDisplayName} Executive Summary (subsidiaries only)`;
          } else {
            reportName = `${breachSightProductDisplayName} Executive Summary (with subsidiaries)`;
          }
        }
      } else if (isVendorRiskModule(modules)) {
        if (assuranceType === AssuranceType.MSSP) {
          reportName = vendorWords.vendorRiskExecutiveSummaryPageTitle;
        } else {
          reportName = `${vendorWords.vendorRiskModuleName} ${vendorWords.vendorRiskExecutiveSummaryPageTitle}`;
        }
      } else {
        reportName = "Executive Summary";
      }
      break;
    }
    case ExportType.RiskProfileReport: {
      reportName = "Risk Profile";
      const exportMeta = item.exportMeta as RiskProfileReportExportMeta;
      if (exportMeta.customer || exportMeta.subsidiary) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }

      break;
    }
    case ExportType.RiskProfileIncludingSubsidiariesReport: {
      reportName = "Risk Profile Including Subsidiaries";
      const exportMeta =
        item.exportMeta as RiskProfileIncludingSubsidiariesExportMeta;
      if (exportMeta.customer === false && exportMeta.isSubsidiary === false) {
        modules.push(userReportExportModules.vendorRisk);
      } else {
        modules.push(userReportExportModules.breachSight);
      }
      break;
    }
    case ExportType.WebsitesReport: {
      reportName = "Domains";
      const exportMeta = item.exportMeta as WebsitesReportExportMeta;
      if (exportMeta.customer || exportMeta.subsidiary) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }

      break;
    }
    case ExportType.IPsReport: {
      reportName = "IP Addresses";
      const exportMeta = item.exportMeta as IPsReportExportMeta;
      if (exportMeta.customer || exportMeta.subsidiary) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }

      break;
    }
    case ExportType.VendorListReport: {
      reportName = vendorWords.vendorsPageTitle;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.QuestionnairesReport: {
      reportName = "Questionnaires";
      modules.push(userReportExportModules.vendorRisk);
      break;
    }
    case ExportType.QuestionnaireDetailsReport: {
      reportName = "Questionnaire";
      modules.push(userReportExportModules.vendorRisk);
      break;
    }
    case ExportType.VendorSummaryReport: {
      reportName = `${vendorWords.singularTitleCase} Summary`;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.VendorAssessmentReport: {
      reportName = `${vendorWords.singularTitleCase} Risk Assessment`;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.PortfolioRiskProfileReport: {
      reportName = vendorWords.portfolioRiskProfilePageTitle;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.VulnerabilitiesReport: {
      reportName = "Vulnerabilities";
      const exportMeta = item.exportMeta as VulnerabilitiesReportExportMeta;
      if (exportMeta.customer || exportMeta.subsidiary) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }

      break;
    }
    case ExportType.EmailExposuresReport: {
      reportName = "Identity Breaches";
      modules.push(userReportExportModules.breachSight);

      break;
    }
    // EXPORT TYPE REMOVED: Kept here to support old exports
    case ExportType.DetailedVendorSummaryReport: {
      reportName = `${vendorWords.singularTitleCase} Summary Report`;
      if ((item.exportMeta as any).reportType == "Detailed") {
        reportName = `${vendorWords.singularTitleCase} Detailed Report`;
      } else if ((item.exportMeta as any).reportType == "Assessment") {
        reportName = `Risk Assessment ${vendorWords.singularTitleCase} Report`;
      }
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.DetailedVendorReportV2: {
      reportName =
        item.cannedReportName ?? `${vendorWords.singularTitleCase} Report`;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.ManagedVendorRiskAssessmentReport: {
      reportName =
        item.cannedReportName ?? `${vendorWords.singularTitleCase} Report`;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.SecurityProfileRiskAssessmentReport: {
      reportName =
        item.cannedReportName ?? `${vendorWords.singularTitleCase} Report`;
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.DetailedBreachsightReportV2: {
      reportName =
        item.cannedReportName ?? `${breachSightProductDisplayName} Report`;
      modules.push(userReportExportModules.breachSight);

      break;
    }
    case ExportType.TyposquatMonitoredDomainsReport: {
      reportName = "Typosquatting Domains";
      modules.push(userReportExportModules.breachSight);

      break;
    }
    case ExportType.TyposquatPermutationsReport: {
      reportName = "Typosquatting Permutations";
      modules.push(userReportExportModules.breachSight);

      break;
    }
    case ExportType.AuditLogReport: {
      reportName = "Audit Log";
      modules.push(userReportExportModules.platform);

      break;
    }
    case ExportType.RemediationRequests: {
      reportName = "Remediation Requests";
      const exportMeta = item.exportMeta as RemediationRequestExportMeta;
      if (
        exportMeta.isSelfRemediation ||
        exportMeta.isForSubsidiaries ||
        exportMeta.isSubsidiary
      ) {
        modules.push(userReportExportModules.breachSight);
      } else if (exportMeta.isUserRiskRemediation) {
        modules.push(userReportExportModules.userRisk);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }
      break;
    }
    case ExportType.RemediationRequestDetails: {
      reportName = "Remediation Request Details";
      const exportMeta = item.exportMeta as RemediationRequestDetailsExportMeta;
      if (exportMeta.isSelfRemediation) {
        modules.push(userReportExportModules.breachSight);
      } else if (exportMeta.isUserRisk) {
        modules.push(userReportExportModules.userRisk);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }
      break;
    }
    // EXPORT TYPE REMOVED: Kept here to support old exports
    case ExportType.DetailedBreachsightSummaryReport: {
      reportName = `${breachSightProductDisplayName} Detailed Report`;
      if ((item.exportMeta as any).reportType == "Summary") {
        reportName = `${breachSightProductDisplayName} Summary Report`;
      }
      modules.push(userReportExportModules.breachSight);
      break;
    }
    case ExportType.VendorComparisonReport: {
      reportName = `${vendorWords.singularTitleCase} Comparison Report`;
      modules.push(userReportExportModules.vendorRisk);
      break;
    }
    case ExportType.ChangesViewReport: {
      reportName = "Risk Changes";
      const exportMeta = item.exportMeta as RiskProfileReportExportMeta;
      if (exportMeta.customer || exportMeta.subsidiary) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }
      break;
    }
    case ExportType.ComplianceReport: {
      reportName = "Compliance Report";
      const exportMeta = item.exportMeta as ComplianceReportExportMeta;
      if (exportMeta.customer || exportMeta.subsidiary) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
      }
      break;
    }
    case ExportType.BoardSummaryReport:
    case ExportType.BoardSummaryReportV2: {
      reportName = "Board Summary Report";
      modules.push(userReportExportModules.platform);

      break;
    }
    case ExportType.RiskAssessmentSummaryReport: {
      reportName = "Risk Assessment Summary Report";
      modules.push(userReportExportModules.vendorRisk);

      break;
    }
    case ExportType.RiskWaiversExport: {
      reportName = "Risk Waivers";
      const exportMeta = item.exportMeta as RiskWaiversExportMeta;
      if (exportMeta.customer) {
        modules.push(userReportExportModules.breachSight);
      } else {
        modules.push(userReportExportModules.vendorRisk);
        reportName = "Modified Risks";
      }
      break;
    }
    case ExportType.ManagedVendorsExport: {
      reportName = "Managed Vendors";
      modules.push(userReportExportModules.vendorRisk);
      break;
    }
    case ExportType.FourthPartiesExport: {
      reportName = "Fourth Parties";
      modules.push(userReportExportModules.vendorRisk);
      break;
    }
    case ExportType.SubsidiariesExport: {
      reportName = "Subsidiaries";
      modules.push(userReportExportModules.breachSight);
      break;
    }
    case ExportType.BreachSightExecutiveSummaryExport: {
      const exportMeta =
        item.exportMeta as BreachsightExecutiveSummaryExportMeta;
      reportName = `${breachSightProductDisplayName} Executive Summary`;
      if (exportMeta.includeSubsidiaries) {
        reportName = `${breachSightProductDisplayName} Executive Summary (including subsidiaries)`;
      } else if (exportMeta.includeSubsidiariesOnly) {
        reportName = `${breachSightProductDisplayName} Executive Summary (subsidiaries only)`;
      }

      modules.push(userReportExportModules.breachSight);

      const metaSections = (
        item.exportMeta as BreachsightExecutiveSummaryExportMeta
      ).sections;
      for (let i = 0; i < metaSections.length; i++) {
        if (!metaSections[i].Value) {
          continue;
        }
        switch (metaSections[i].ID) {
          case "scores":
            sections.push("Scores and risk counts by category");
            break;
          case "performance":
            sections.push("Performance over time");
            break;
          case "geolocation":
            sections.push("Geolocation details");
            break;
        }
      }

      break;
    }
    case ExportType.VendorRiskExecutiveSummaryExport: {
      reportName = "Vendor Risk Executive Summary Report";
      modules.push(userReportExportModules.vendorRisk);

      const metaSections = (
        item.exportMeta as VendorRiskExecutiveSummaryExportMeta
      ).sections;
      for (let i = 0; i < metaSections.length; i++) {
        if (!metaSections[i].Value) {
          continue;
        }
        switch (metaSections[i].ID) {
          case "matrix":
            sections.push("Security ratings by business impact");
            break;
          case "performance":
            sections.push("Performance over time");
            break;
          case "geolocation":
            sections.push("Geolocation details");
            break;
        }
      }
      break;
    }
    case ExportType.DetailedBreachsightReportV2: {
      reportName =
        item.cannedReportName ?? `${breachSightProductDisplayName} Report`;
      modules.push(userReportExportModules.breachSight);

      break;
    }
    case ExportType.BreachsightSubsidiariesReportV2: {
      reportName =
        item.cannedReportName ??
        `${breachSightProductDisplayName} Subsidiaries Report`;
      modules.push(userReportExportModules.breachSight);

      break;
    }
    case ExportType.ManagedVendorsAnalystExport: {
      reportName = "Managed Vendors For Analyst";
      modules.push(userReportExportModules.platform);
      break;
    }
    case ExportType.DetectedProductsExport: {
      reportName = "Detected Products";
      modules.push(userReportExportModules.breachSight);
      break;
    }
    case ExportType.UserRiskUsersExport: {
      reportName = "Users";
      modules.push(userReportExportModules.userRisk);
      break;
    }
    case ExportType.UserRiskApplicationsExport: {
      reportName = "Applications";
      modules.push(userReportExportModules.userRisk);
      break;
    }
  }

  return { modules, reportName, sections };
};

const isBreachSightModule = (modules: number[]): boolean =>
  modules.indexOf(userReportExportModules.breachSight) !== -1;
const isVendorRiskModule = (modules: number[]): boolean =>
  modules.indexOf(userReportExportModules.vendorRisk) !== -1;
const isUserRiskModule = (modules: number[]): boolean =>
  modules.indexOf(userReportExportModules.userRisk) !== -1;

const getRows = (
  items: ExportItem[],
  selectedIds: number[],
  onDownloadItem: (item: ExportItem, isArchivedView?: boolean) => void,
  onArchiveItems: (items: ExportItem[], isArchive: boolean) => void,
  onDeleteItems: (items: ExportItem[]) => void,
  onRenameItems: (items: ExportItem[]) => void,
  assuranceType: AssuranceType
): IXTableRow[] => {
  const vendorWords = getVendorWords(assuranceType);
  const rows = items.map((item) => {
    const { modules, reportName, sections } = getReportMeta(
      item,
      assuranceType
    );

    const rowDropdownItems = [];
    const iconOptions = [];

    if (item.status == ExportStatus.StatusComplete) {
      rowDropdownItems.push(
        <DropdownItem
          key={"rename"}
          stopPropagation
          onClick={() => onRenameItems([item])}
        >
          <i className={"cr-icon-rename"} />
          Rename
        </DropdownItem>
      );
    }

    rowDropdownItems.push(
      <DropdownItem
        key={"archive"}
        stopPropagation
        onClick={() => onArchiveItems([item], !item.archivedAt)}
      >
        <i className={"cr-icon-archive"} />
        {!item.archivedAt ? "Archive" : "Unarchive"}
      </DropdownItem>
    );
    rowDropdownItems.push(
      <DropdownItem
        key={"delete"}
        stopPropagation
        onClick={() => onDeleteItems([item])}
      >
        <i className={"cr-icon-trash"} />
        Delete
      </DropdownItem>
    );

    if (item.status === userReportExportStatuses.Complete) {
      iconOptions.push({
        id: "download",
        hoverText: "Download",
        icon: <i className="cr-icon-download" />,
        onClick: () => onDownloadItem(item),
      });
    } else {
      iconOptions.push({
        id: "placeholder",
        icon: <i className="cr-icon-noop" />,
        disabled: true,
      });
    }

    iconOptions.push({
      id: "actions",
      icon: <i className={"cr-icon-dots-menu"} />,
      dropdownItems: rowDropdownItems,
      autoCloseOnMouseLeave: false,
      hoverText: "More actions",
      hoverLocation: HoverLocation.Top,
      hoverMicro: true,
      popupDelay: 0,
    });

    const moduleDisplays = [];
    const buildModuleDisplay = (key: number, displayText: string) => (
      <div key={key} className="module-container">
        {displayText}
      </div>
    );

    if (isBreachSightModule(modules)) {
      moduleDisplays.push(
        buildModuleDisplay(
          userReportExportModules.breachSight,
          `${breachSightProductDisplayName}`
        )
      );
    }

    if (isVendorRiskModule(modules)) {
      moduleDisplays.push(
        buildModuleDisplay(
          userReportExportModules.vendorRisk,
          vendorWords.vendorRiskModuleName
        )
      );
    }

    if (modules.indexOf(userReportExportModules.platform) !== -1) {
      moduleDisplays.push(
        buildModuleDisplay(
          userReportExportModules.vendorRisk,
          vendorWords.vendorRiskModuleName
        )
      );
    }

    if (isUserRiskModule(modules)) {
      moduleDisplays.push(
        buildModuleDisplay(userReportExportModules.userRisk, "User Risk")
      );
    }

    return {
      id: item.id,
      iconOptions,
      selected: selectedIds && selectedIds.indexOf(item.id) !== -1,
      cells: [
        <XTableCell key="read" className="read-cell">
          <div>
            {!item.read &&
              item.status === userReportExportStatuses.Complete && (
                <SidePopupV2 position={"left"} text={"Unread"} noWrap>
                  <span className="unread-dot" />
                </SidePopupV2>
              )}
          </div>
        </XTableCell>,
        <XTableCell key={"filetype-icon"} className={"filetype-icon-cell"}>
          <img
            className={"file-icon"}
            src={
              item.exportFiletype === ExportFiletype.PDF
                ? FileTypeIconPDF
                : item.exportFiletype === ExportFiletype.PPTX
                  ? FileTypeIconPPTX
                  : item.exportFiletype === ExportFiletype.DOCX
                    ? FileTypeIconWord
                    : FileTypeIconExcel
            }
            alt={
              item.exportFiletype === ExportFiletype.PDF
                ? "pdf"
                : item.exportFiletype === ExportFiletype.PPTX
                  ? "pptx"
                  : item.exportFiletype == ExportFiletype.DOCX
                    ? "docx"
                    : "excel"
            }
          />
        </XTableCell>,
        <XTableCell
          key="filename"
          className={classnames("filename-cell", {
            "filename-error": item.status === userReportExportStatuses.Error,
            "filename-pending":
              item.status === userReportExportStatuses.Pending,
          })}
          onClick={
            item.status === userReportExportStatuses.Complete
              ? () => onDownloadItem(item)
              : () => null
          }
        >
          <div className="filename-cell-content">
            <div
              className={classnames("filename", {
                inactive:
                  item.status === userReportExportStatuses.Pending ||
                  item.status === userReportExportStatuses.Error,
              })}
            >
              {item.filename}
            </div>
            <div className={"status-container"}>
              {item.status === userReportExportStatuses.Pending && (
                <PillLabel color={LabelColor.Blue}>
                  <i className="cr-icon-clock" />
                  Report in queue
                </PillLabel>
              )}
              {item.status === userReportExportStatuses.Error && (
                <PillLabel color={LabelColor.Red}>
                  <i className="cr-icon-warning" />
                  Error producing report
                </PillLabel>
              )}
              {item.generateOutput?.xlsxExceededMaxRows && (
                <PillLabel
                  color={LabelColor.Orange}
                  popupContent={
                    "Data may have been truncated as the XLSX row limit was reached"
                  }
                  popupPosition={"top"}
                >
                  <i className="cr-icon-warning" />
                  Row limit reached
                </PillLabel>
              )}
            </div>
          </div>
        </XTableCell>,
        <XTableCell key="module" className="module-cell">
          {moduleDisplays}
        </XTableCell>,
        <XTableCell key="type">
          {sections.length > 0 && sections[0] !== reportName ? (
            <SidePopupV2
              position={"left"}
              className="report-type-popup"
              text={
                <>
                  {sections.map((s) => (
                    <Fragment key={s}>
                      {s}
                      <br />
                    </Fragment>
                  ))}
                </>
              }
            >
              {reportName}
            </SidePopupV2>
          ) : (
            reportName
          )}
        </XTableCell>,
        <XTableCell key="requested">
          {moment(item.createdAt).format("ll")}
        </XTableCell>,
      ],
    };
  });

  return rows;
};

import { useCallback, useEffect } from "react";

export const useGroupInputKeyTrap = (
  containerRef: React.RefObject<HTMLElement>,
  onFocusOutsideContainer: () => void,
  moveFocusOnEnter: boolean = true
) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        (event.key === "Tab" || event.key === "Enter") &&
        containerRef.current
      ) {
        const inputElements = containerRef.current.querySelectorAll(
          "input, textarea"
        ) as NodeListOf<HTMLElement>;

        if (inputElements.length === 0) return;

        const firstElement = inputElements[0];
        const lastElement = inputElements[inputElements.length - 1];

        if (event.key === "Tab") {
          // Trap focus within the container and keep moving focus only within the container
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }

        if (event.key === "Enter" && moveFocusOnEnter) {
          if (document.activeElement === lastElement) {
            // Call save function when Enter is pressed on the last input
            event.preventDefault();
            onFocusOutsideContainer();
          } else {
            const currentIndex = Array.from(inputElements).findIndex(
              (el) => el === document.activeElement
            );
            if (currentIndex >= 0 && currentIndex < inputElements.length - 1) {
              event.preventDefault();
              inputElements[currentIndex + 1].focus();
            }
          }
        }
      }
    },
    [containerRef, onFocusOutsideContainer]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (container) {
        container.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [containerRef, handleKeyDown]);
};

import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import { addDefaultUnknownErrorAlert } from "./messageAlerts.actions";
import { setUserActivityStreamSettings } from "./commonActions";
import { DefaultThunkDispatch } from "../types/redux";
import { DefaultRootState } from "react-redux";
import {
  INotificationConfigCategory,
  NotificationConditionalLogic,
} from "../types/notifications";
import { produce } from "immer";

export enum UserActivityStreamConfigSource {
  Notification = "notifications",
  Audit = "audits",
  Tasks = "tasks",
  Other = "other",
}

export enum SpecialType {
  BreachSightDigest = "BreachSightDigest",
  VendorRiskDigest = "VendorRiskDigest",
}

export interface IUserActivityStreamConfigOption {
  source: UserActivityStreamConfigSource;
  notificationType: string;
  auditType: string;
  uuid: string;
  headline: string;
  description: string;
  category: string[2];
  parameters: Record<string, number | undefined>;
  displayEnabled: boolean;
  displayEnabledAll: boolean;
  emailEnabled: boolean;
  emailEnabledAll: boolean;
  canSelectQuestionnaires: boolean;
  canSelectRemediation: boolean;
  canSelectThreats: boolean;
  canSelectEvidence: boolean;
  cannotSelectInApp: boolean;
  cannotSelectEmail: boolean;
  conditionalLogic?: NotificationConditionalLogic;
}

export interface IUserActivityStreamConfig {
  optionCategories: INotificationConfigCategory<IUserActivityStreamConfigOption>[];
}

export const fetchUserActivityStreamSettings = (forced = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!forced && getState().common.userActivityStreamSettings) {
      return getState().common.userActivityStreamSettings;
    }

    let resp;
    try {
      resp = await FetchCyberRiskUrl<IUserActivityStreamConfig>(
        "activitystream/user_opts/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching user activity stream settings", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching your activity stream notification settings"
        )
      );
      throw e;
    }

    dispatch(setUserActivityStreamSettings(resp));

    return resp;
  };
};

export interface IUserActivityStreamConfigUpdateRequest {
  uuid: string;
  source: UserActivityStreamConfigSource;
  displayEnabled: boolean;
  displayEnabledAll: boolean;
  emailEnabled: boolean;
  emailEnabledAll: boolean;
}

export const updateUserActivityStreamSettings = (
  updateRequests: IUserActivityStreamConfigUpdateRequest[]
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "activitystream/user_opts/v1",
        null,
        {
          method: "PUT",
          body: JSON.stringify(updateRequests),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating user notification settings", e);
      throw e;
    }

    // Success - set the update requests in state
    const updateReqsMap = updateRequests.reduce(
      (obj: Record<string, IUserActivityStreamConfigUpdateRequest>, req) => {
        obj[req.uuid] = req;
        return obj;
      },
      {}
    );

    const newUserSettings = produce(
      getState().common.userActivityStreamSettings,
      (draft) => {
        if (draft) {
          for (let i = 0; i < draft.optionCategories.length; i++) {
            for (
              let j = 0;
              j < draft.optionCategories[i].subcategories.length;
              j++
            ) {
              for (
                let k = 0;
                k < draft.optionCategories[i].subcategories[j].options.length;
                k++
              ) {
                const opt =
                  draft.optionCategories[i].subcategories[j].options[k];
                const updateReq = updateReqsMap[opt.uuid];
                if (updateReq) {
                  opt.displayEnabled = updateReq.displayEnabled;
                  opt.displayEnabledAll = updateReq.displayEnabledAll;
                  opt.emailEnabled = updateReq.emailEnabled;
                  opt.emailEnabledAll = updateReq.emailEnabledAll;
                }
              }
            }
          }
        }
      }
    );

    dispatch(setUserActivityStreamSettings(newUserSettings));
  };
};

export const resetDefaultUserActivityStreamSettings = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp;
    try {
      resp = await FetchCyberRiskUrl<IUserActivityStreamConfig>(
        "activitystream/user_opts/defaults/v1",
        null,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting user activity stream settings to defaults", e);
      throw e;
    }
    dispatch(setUserActivityStreamSettings(resp));
    return resp;
  };
};

import { matchPath, useHistory } from "react-router-dom";
import classnames from "classnames";
import { getRiskProfileDisplayPrefs } from "../../../vendorrisk/helpers/riskProfile.helpers";
import {
  useCurrentOrg,
  useCurrentUser,
  useCurrentUserTasks,
} from "../../selectors/commonSelectors";
import NavLink from "../NavLink";
import Icon from "../core/Icon";
import * as Permissions from "../../permissions";
import { pageDescriptions } from "../../pageDescriptions";
import { useBaseVendorData } from "../../selectors/vendorSelectors";
import { get as _get } from "lodash";
import { getSubtitleDisplay } from "../../helpers/string.helpers";
import {
  isExpiredTrial,
  userCurrentOrgHasSubsidiaries,
} from "../../helpers/userOrg.helpers";
import {
  breachRiskTaskUrlPrefix,
  getUserBreachSightSections,
  tasksRouterUrlPrefix,
  userCanViewCyberResearch,
} from "../../helpers/navigation.helpers";
import {
  breachSightNavigationItems,
  breachSightProductDisplayName,
  Product,
} from "../../types/products";
import { AppGuardBreachsightCloudNavItemsNew } from "../../../appguard/AppGuardNavItems";
import { ThreatMonitoringBreachsightNavItemsNew } from "../../../threatmonitoring/ThreatMonitoringNavItems";
import { useHasOrgEntitlement } from "../../permissions";

export interface ClickNavLinkFunc {
  (path: string, linkName: string, viewingVendorId?: string): () => void;
}

type BreachSightNavItemsProps = {
  clickNavLinkFunc: ClickNavLinkFunc;
};

const getSubsidiaryVendorIdFromPath = (pathname: string) => {
  const isOnSubsidiaryRouteMatch = matchPath<{ vendorId: string }>(pathname, {
    path: "/subsidiaries/:vendorId",
  });
  if (isOnSubsidiaryRouteMatch) {
    return parseInt(isOnSubsidiaryRouteMatch.params.vendorId);
  }
  return undefined;
};

const BreachSightNavItems = (props: BreachSightNavItemsProps) => {
  const history = useHistory();
  const currentOrg = useCurrentOrg();
  const userData = useCurrentUser();
  const userTaskData = useCurrentUserTasks();

  const hasUserPermission = (perm: string) =>
    userData.userPermissions.includes(perm);
  const hasOrgPermission = (perm: string) =>
    userData.orgPermissions.includes(perm);

  const currentOrgHasSubsidiaries = userCurrentOrgHasSubsidiaries(currentOrg);

  const hasSubsidiaryRiskWaiversPermission = useHasOrgEntitlement(
    Permissions.OrgAccessSubsidiaryRiskWaivers
  );

  // get the last visited risk profile tab so we can shoot back there
  const riskProfileDisplayPrefs = getRiskProfileDisplayPrefs(
    undefined,
    undefined
  );
  const riskProfileSubsidiariesTab =
    currentOrgHasSubsidiaries &&
    riskProfileDisplayPrefs.tab === "with_subsidiaries";

  const closeSubsidiariesMenu = (isOnSubsidiaryRoute: boolean) => {
    if (isOnSubsidiaryRoute) {
      history.push("/subsidiaries");
    }
  };
  const selectedSubsidiaryVendorId = getSubsidiaryVendorIdFromPath(
    history.location.pathname
  );
  const isOnSubsidiaryRoute = !!selectedSubsidiaryVendorId;
  const selectedSubsidiary = useBaseVendorData(
    selectedSubsidiaryVendorId,
    true
  );

  const breachSightNavSections = getUserBreachSightSections(
    userData,
    currentOrg,
    selectedSubsidiaryVendorId,
    userTaskData
  );

  const showBreachSight = breachSightNavSections.length > 0;

  const showCyberResearch = userCanViewCyberResearch(
    userData,
    Product.BreachSight,
    currentOrg
  );

  // Only show the sub items of data leaks if on a data leaks path
  const showDataLeaksSubItems =
    matchPath(history.location?.pathname, "/breachsight") ||
    matchPath(history.location?.pathname, "/dataleaks");

  const showTasks =
    breachSightNavSections.includes("remediation_tasks") ||
    breachSightNavSections.includes("risk_waiver_approvals") ||
    breachSightNavSections.includes("subsidiary_risk_waiver_approvals");

  const trialExpired = isExpiredTrial(currentOrg);

  return (
    <>
      {(showBreachSight || showCyberResearch) && (
        <div className={classnames("subtitle", "product-name")}>
          {`${breachSightProductDisplayName}`}
        </div>
      )}
      <div className="product-navigation-items">
        {breachSightNavSections.includes("executive_summary") && (
          <NavLink
            disabled={trialExpired}
            text="Executive Summary"
            hoverText={pageDescriptions.ExecutiveSummaryReport}
            onClick={props.clickNavLinkFunc(
              "/summaryreport/breachsight",
              `${breachSightProductDisplayName} Executive Summary`
            )}
            to="/summaryreport/breachsight"
          />
        )}
        {breachSightNavSections.includes("risk_profile") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Risk Profile"
            hoverText={pageDescriptions.RiskProfile}
            onClick={props.clickNavLinkFunc(
              riskProfileSubsidiariesTab
                ? "/risk_profile/include_subsidiaries"
                : "/risk_profile",
              "Risk Profile"
            )}
            to={
              riskProfileSubsidiariesTab
                ? "/risk_profile/include_subsidiaries"
                : "/risk_profile"
            }
            isActive={(_match, location) =>
              !!matchPath(location.pathname, "/risk_profile")
            }
          />
        )}
        {breachSightNavSections.includes("vulnerabilities") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Vulnerabilities"
            hoverText={pageDescriptions.Vulns}
            exact
            onClick={props.clickNavLinkFunc("/vulns/", "Vulnerabilities")}
            to="/vulns"
          />
        )}
        {breachSightNavSections.includes("cloud") && (
          <AppGuardBreachsightCloudNavItemsNew
            clickNavLinkFunc={props.clickNavLinkFunc}
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
          />
        )}
        {(breachSightNavSections.includes("remediation") ||
          breachSightNavSections.includes("risk_waivers")) && (
          <>
            <div className="section-divider">
              <hr />
            </div>
            <div className="section-header">
              <span className="section-header-text">Workflows</span>
            </div>
          </>
        )}
        {breachSightNavSections.includes("remediation") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Remediation"
            hoverText={pageDescriptions.SelfRemediation}
            onClick={props.clickNavLinkFunc("/selfremediation", "Remediation")}
            to="/selfremediation"
          />
        )}
        {breachSightNavSections.includes("risk_waivers") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Risk Waivers"
            hoverText={pageDescriptions.RiskWaivers}
            onClick={props.clickNavLinkFunc("/riskwaivers", "Risk Waivers")}
            to="/riskwaivers"
          />
        )}
        {breachSightNavSections.some((b) => {
          // Only show the inventory section if one of the following are present
          const inventorySections: breachSightNavigationItems[] = [
            "domains",
            "ip_addresses",
            "detected_products",
            "subsidiaries",
          ];

          return inventorySections.includes(b);
        }) && (
          <>
            <div className="section-divider">
              <hr />
            </div>
            <div className="section-header">
              <span className="section-header-text">Inventory</span>
            </div>
          </>
        )}
        {breachSightNavSections.includes("domains") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Domains"
            hoverText={pageDescriptions.WebsitesAndAPIs}
            exact
            onClick={props.clickNavLinkFunc("/webscans", "Domains")}
            to="/webscans"
          />
        )}
        {breachSightNavSections.includes("ip_addresses") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="IP Addresses"
            hoverText={pageDescriptions.IPAddresses}
            exact
            onClick={props.clickNavLinkFunc("/ips", "IP Addresses")}
            to="/ips"
          />
        )}
        {breachSightNavSections.includes("detected_products") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Detected Products"
            hoverText={pageDescriptions.DetectedProducts}
            exact
            onClick={props.clickNavLinkFunc(
              "/detected_products",
              "Detected Products"
            )}
            to="/detected_products"
          />
        )}
        {breachSightNavSections.includes("subsidiaries") && (
          <NavLink
            disabled={
              trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
            }
            text="Subsidiaries"
            hoverText={pageDescriptions.Subsidiaries}
            exact
            onClick={props.clickNavLinkFunc("/subsidiaries", "Subsidiaries")}
            to="/subsidiaries"
          />
        )}
        {breachSightNavSections.includes("subsidiaries_menu") && (
          <>
            <div
              className={classnames("nav-sub-section", {
                active: isOnSubsidiaryRoute,
              })}
            >
              <div
                className={classnames("subtitle header-section-name", {
                  active: isOnSubsidiaryRoute,
                })}
                onClick={() => closeSubsidiariesMenu(isOnSubsidiaryRoute)}
              >
                <div className="subtitle vendor-name">
                  {getSubtitleDisplay(
                    _get(selectedSubsidiary!, "display_name", null)
                  )}
                </div>
                <Icon name="x" />
              </div>

              <NavLink
                disabled={trialExpired}
                text="Summary"
                hoverText={pageDescriptions.SubsidiarySummary}
                onClick={props.clickNavLinkFunc(
                  `/subsidiaries/${selectedSubsidiaryVendorId}/summary`,
                  "Subsidiary Summary",
                  `${selectedSubsidiaryVendorId}`
                )}
                to={`/subsidiaries/${selectedSubsidiaryVendorId}/summary`}
              />
              <NavLink
                disabled={
                  trialExpired ||
                  hasOrgPermission(Permissions.OrgHideVendorRisks)
                }
                text="Risk Profile"
                hoverText={pageDescriptions.SubsidiaryRiskProfile}
                onClick={props.clickNavLinkFunc(
                  `/subsidiaries/${selectedSubsidiaryVendorId}/risk_profile`,
                  "Subsidiary Risk Profile",
                  `${selectedSubsidiaryVendorId}`
                )}
                to={`/subsidiaries/${selectedSubsidiaryVendorId}/risk_profile`}
              />
              {hasOrgPermission(Permissions.OrgAccessVulns) && (
                <NavLink
                  disabled={
                    trialExpired ||
                    hasOrgPermission(Permissions.OrgHideVendorRisks)
                  }
                  text="Vulnerabilities"
                  hoverText={pageDescriptions.SubsidiaryVulns}
                  exact
                  onClick={props.clickNavLinkFunc(
                    `/subsidiaries/${selectedSubsidiaryVendorId}/vulns`,
                    "Subsidiary Vulnerabilities"
                  )}
                  to={`/subsidiaries/${selectedSubsidiaryVendorId}/vulns`}
                />
              )}
              {
                <NavLink
                  disabled={
                    trialExpired ||
                    hasOrgPermission(Permissions.OrgHideVendorRisks)
                  }
                  text="Remediation"
                  hoverText={pageDescriptions.SubsidiaryRemediation}
                  onClick={props.clickNavLinkFunc(
                    `/subsidiaries/${selectedSubsidiaryVendorId}/remediation`,
                    "Subsidiary Remediation"
                  )}
                  to={`/subsidiaries/${selectedSubsidiaryVendorId}/remediation`}
                />
              }
              {hasSubsidiaryRiskWaiversPermission && (
                <NavLink
                  disabled={trialExpired || !hasSubsidiaryRiskWaiversPermission}
                  text="Risk Waivers"
                  hoverText={pageDescriptions.SubsidiaryRiskProfile}
                  onClick={props.clickNavLinkFunc(
                    `/subsidiaries/${selectedSubsidiaryVendorId}/riskwaivers`,
                    "Subsidiary Risk Waivers",
                    `${selectedSubsidiaryVendorId}`
                  )}
                  to={`/subsidiaries/${selectedSubsidiaryVendorId}/riskwaivers`}
                />
              )}
              <NavLink
                disabled={
                  trialExpired ||
                  hasOrgPermission(Permissions.OrgHideVendorRisks)
                }
                text="Domains"
                hoverText={pageDescriptions.SubsidiaryWebsitesAndAPIs}
                exact
                onClick={props.clickNavLinkFunc(
                  `/subsidiaries/${selectedSubsidiaryVendorId}/webscans`,
                  `Subsidiary Domains`,
                  `${selectedSubsidiaryVendorId}`
                )}
                to={`/subsidiaries/${selectedSubsidiaryVendorId}/webscans`}
              />
              <NavLink
                disabled={
                  trialExpired ||
                  hasOrgPermission(Permissions.OrgHideVendorRisks)
                }
                text="IP Addresses"
                hoverText={pageDescriptions.SubsidiaryIPAddresses}
                exact
                onClick={props.clickNavLinkFunc(
                  `/subsidiaries/${selectedSubsidiaryVendorId}/ips`,
                  "Subsidiary IP Addresses"
                )}
                to={`/subsidiaries/${selectedSubsidiaryVendorId}/ips`}
              />
              {selectedSubsidiaryVendorId &&
                _get(selectedSubsidiary, "has_subsidiaries", false) && (
                  <NavLink
                    disabled={trialExpired}
                    text="Subsidiaries"
                    hoverText={pageDescriptions.Subsidiaries}
                    exact
                    onClick={props.clickNavLinkFunc(
                      `/subsidiaries/${selectedSubsidiaryVendorId}/subsidiaries`,
                      "Subsidiary Subsidiaries"
                    )}
                    to={`/subsidiaries/${selectedSubsidiaryVendorId}/subsidiaries`}
                  />
                )}
            </div>
          </>
        )}
        {(breachSightNavSections.includes("typosquatting") ||
          breachSightNavSections.includes("identity_breaches") ||
          breachSightNavSections.includes("threatmonitoring") ||
          showCyberResearch) && (
          <>
            <div className="section-divider">
              <hr />
            </div>
            <div className="section-header">
              <span className="section-header-text">Threat Intel</span>
            </div>
          </>
        )}
        {breachSightNavSections.includes("identity_breaches") && (
          <NavLink
            disabled={trialExpired}
            text="Identity Breaches"
            hoverText={pageDescriptions.Identities}
            onClick={props.clickNavLinkFunc(
              "/email_exposures",
              "Identity Breaches"
            )}
            to="/email_exposures"
          />
        )}
        {breachSightNavSections.includes("typosquatting") && (
          <NavLink
            disabled={trialExpired}
            text="Typosquatting"
            hoverText={pageDescriptions.Typosquatting}
            onClick={props.clickNavLinkFunc("/typosquatting", "Typosquatting")}
            to="/typosquatting"
          />
        )}
        {breachSightNavSections.includes("threatmonitoring") && (
          <ThreatMonitoringBreachsightNavItemsNew
            clickNavLinkFunc={props.clickNavLinkFunc}
            disabled={!!trialExpired}
            pathname={history.location.pathname}
          />
        )}
        {showCyberResearch && (
          <>
            <NavLink
              disabled={trialExpired}
              text="Managed Data Leaks"
              hoverText={pageDescriptions.DataLeaks}
              onClick={props.clickNavLinkFunc("/breachsight", "Data Leaks")}
              to="/breachsight"
              featurePreviewAvailable
              hasOrgPermission={hasOrgPermission(
                Permissions.OrgAccessBreachSight
              )}
              hasUserPermission={hasUserPermission(
                Permissions.UserAccessDataLeaks
              )}
              isActive={(_, location) =>
                !!matchPath(location.pathname, {
                  path: "/breachsight",
                  exact: true,
                })
              }
            />
            {showDataLeaksSubItems && (
              <NavLink
                disabled={trialExpired}
                text="Disclosures"
                isSubMenuItem
                hoverText={pageDescriptions.DataLeaksStatistics}
                onClick={props.clickNavLinkFunc(
                  "/dataleaks/disclosures",
                  "Disclosures"
                )}
                to="/dataleaks/disclosures"
                hasOrgPermission={hasOrgPermission(
                  Permissions.OrgAccessBreachSight
                )}
                hasUserPermission={hasUserPermission(
                  Permissions.UserAccessDataLeaks
                )}
                isActive={(_, location) => {
                  // Temporary fix to highlight Disclosures nav
                  // when on either findings list, or a finding.
                  // Remove this once we properly fix up the routes for Data Leaks
                  const result = matchPath(
                    location.pathname,
                    "/breachsight/:findingId"
                  );

                  if (result) {
                    return !!result;
                  }

                  return !!matchPath(
                    location.pathname,
                    "/dataleaks/disclosures"
                  );
                }}
              />
            )}
            {showDataLeaksSubItems && (
              <NavLink
                disabled={trialExpired}
                text={"Detection Reporting"}
                isSubMenuItem
                hoverText={pageDescriptions.DataLeaksStatistics}
                onClick={props.clickNavLinkFunc(
                  "/dataleaks/reporting",
                  "Detection Reporting"
                )}
                to="/dataleaks/reporting"
                hasOrgPermission={hasOrgPermission(
                  Permissions.OrgAccessBreachSight
                )}
                hasUserPermission={hasUserPermission(
                  Permissions.UserAccessDataLeaks
                )}
              />
            )}
          </>
        )}
        {showTasks && (
          <>
            <div className="section-divider">
              <hr />
            </div>
            <div className="section-header">
              <span className="section-header-text">Tasks</span>
            </div>
            {breachSightNavSections.includes("remediation_tasks") && (
              <NavLink
                text="Remediation Requests"
                onClick={props.clickNavLinkFunc(
                  `/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation`,
                  "Remediation Requests"
                )}
                to={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation`}
              />
            )}
            {breachSightNavSections.includes("risk_waiver_approvals") && (
              <NavLink
                text="Risk Waiver Approvals"
                onClick={props.clickNavLinkFunc(
                  `/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/riskwaiverapprovals`,
                  "Risk Waiver Approvals"
                )}
                to={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/riskwaiverapprovals`}
              />
            )}
            {breachSightNavSections.includes(
              "subsidiary_risk_waiver_approvals"
            ) && (
              <NavLink
                text="Subsidiary Risk Waiver Approvals"
                onClick={props.clickNavLinkFunc(
                  `/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/subsidiaryriskwaiverapprovals`,
                  "Subsidiary Risk Waiver Approvals"
                )}
                to={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/subsidiaryriskwaiverapprovals`}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BreachSightNavItems;

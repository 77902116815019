import { FC } from "react";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { TrustPageCustomDomainStatus } from "../../api/trustpage.api.types";

interface TrustPageCustomDomainStatusDisplayProps {
  domain: string;
  status: TrustPageCustomDomainStatus;
  onClick: () => void;
}

const TrustPageCustomDomainStatusDisplay: FC<
  TrustPageCustomDomainStatusDisplayProps
> = ({ status, onClick }) => {
  switch (status) {
    case "pending_dns":
      return (
        <PillLabel color={LabelColor.Orange} onClick={onClick}>
          Awaiting external DNS <i className={"cr-icon-risk"} />
        </PillLabel>
      );
    case "complete":
      return (
        <PillLabel color={LabelColor.Green} onClick={onClick}>
          Verified <i className={"cr-icon-check"} />{" "}
        </PillLabel>
      );
    case "issue_detected":
      return (
        <PillLabel color={LabelColor.Red} onClick={onClick}>
          Issue detected <i className={"cr-icon-risk"} />
        </PillLabel>
      );
    default:
      return <div>Unknown status</div>;
  }
};

export default TrustPageCustomDomainStatusDisplay;

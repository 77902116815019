import { FormEvent, FormEventHandler, ReactNode, useState } from "react";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { addCustomerCloudscan } from "../../reducers/cyberRiskActions";
import { trackEvent } from "../../../_common/tracking";
import {
  hasPort,
  isIPAddress,
  validateDomainName,
} from "../../../_common/helpers";
import InfoBanner, { BannerType } from "../InfoBanner";
import {
  addDefaultWarningAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { AppDispatch } from "../../../_common/types/reduxStore";
import "./AddCustomCloudscanModal.scss";

export const AddCustomCloudscanModalName = "AddCustomCloudscanModalName";

interface AddCustomCloudscanModalProps {
  modalData: Record<string, any>;
  history: Record<string, any>;
  dispatch: AppDispatch;
}

function AddCustomCloudscanModal({
  modalData,
  history,
  dispatch,
}: AddCustomCloudscanModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [url, _setUrl] = useState<string>("");
  const setUrl = (url: string) => _setUrl(url.trim());

  const onSubmit: FormEventHandler<HTMLFormElement> = (
    evt: FormEvent<HTMLFormElement>
  ) => {
    evt.preventDefault();

    trackEvent("AddCustomCloudscan");

    setLoading(true);

    dispatch(addCustomerCloudscan(url, modalData.vendorId || 0))
      .then(() => {
        dispatch(closeModal());
      })
      .catch((e) => {
        setLoading(false);
        if (typeof e === "string") {
          dispatch(addDefaultWarningAlert(e));
        } else {
          dispatch(addSimpleErrorAlert(`Error adding domain: ${e.message}`));
        }
      });
  };

  const urlIsIPAddress = isIPAddress(url);
  const urlHasPort = hasPort(url);
  const urlIsInvalidDomainName = !validateDomainName(url);

  let footer: ReactNode = null;

  if (!url) {
    footer = null;
  } else if (urlIsIPAddress) {
    let ipsUrl: string;
    if (modalData.vendorId) {
      ipsUrl = `/vendor/${modalData.vendorId}/ips`;
    } else {
      ipsUrl = "/ips";
    }
    footer = (
      <div className="validation-container">
        <InfoBanner
          type={BannerType.WARNING}
          message={
            <>
              Go to&nbsp;
              <a
                onClick={() => {
                  history.push(ipsUrl);
                  dispatch(closeModal());
                }}
              >
                IP Addresses
              </a>
              &nbsp;to add an IP address
            </>
          }
        />
      </div>
    );
  } else if (urlHasPort) {
    const removePortFromUrl = () => {
      setUrl(url.replace(/:\d+$/, ""));
    };
    footer = (
      <div className="validation-container">
        <InfoBanner
          type={BannerType.WARNING}
          message={<>Entered domains cannot have a port number.</>}
          subItems={[
            <a onClick={removePortFromUrl} key="1">
              Click here to remove the port number.
            </a>,
          ]}
        />
      </div>
    );
  } else if (urlIsInvalidDomainName) {
    footer = (
      <div className="validation-container">
        <InfoBanner
          type={BannerType.WARNING}
          message={
            <>Please enter a valid domain name (e.g., your-domain.com)</>
          }
        />
      </div>
    );
  }

  if (!footer) {
    footer = (
      <>
        <Button
          id="cloudscan-cancel"
          onClick={() => dispatch(closeModal())}
          tertiary
        >
          Cancel
        </Button>
        <Button
          id="cloudscan-submit"
          type="submit"
          primary
          loading={loading}
          disabled={loading}
        >
          Add
        </Button>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit} className="add-custom-cloudscan-modal">
      <div className="modal-header">
        <h2>Add a domain</h2>
      </div>
      <div className="modal-content">
        {modalData.vendorId ? (
          <p>If this vendor has domains not listed, add them here.</p>
        ) : (
          <p>If your company has domains not listed, add them here.</p>
        )}
        <input
          name="website"
          type="text"
          required
          placeholder="your-domain.com"
          onChange={(evt) => setUrl(evt.target.value)}
          value={url}
        />
      </div>
      <div className="modal-footer">{footer}</div>
    </form>
  );
}

export default AddCustomCloudscanModal;

import { FC } from "react";
import "../style/components/CyberTrendScore.scss";
import PositiveIcon from "../images/cyber-trend-score-positive-icon.svg";
import NegativeIcon from "../images/cyber-trend-score-negative-icon.svg";
import NoChangeIcon from "../images/cyber-trend-score-no-change-icon.svg";

const getTrendScoreIcon = (trend: number) => {
  if (trend > 0) {
    return PositiveIcon;
  } else if (trend < 0) {
    return NegativeIcon;
  }
  return NoChangeIcon;
};

const getTrendChangeClass = (trend: number) => {
  if (trend > 0) {
    return "positive-trend";
  } else if (trend < 0) {
    return "negative-trend";
  }
  return "no-change-trend";
};

const formatTrendScore = (trend: number) => {
  return Math.round(Math.abs(trend) * 10) / 10;
};

interface ICyberTrendScoreProps {
  trend: number;
}

/**
 * <CyberTrendScore />
 * Renders a cyber trend score with the correct up/own arrow next to it
 * @param props
 */
const CyberTrendScore: FC<ICyberTrendScoreProps> = (
  props: ICyberTrendScoreProps
) => {
  const { trend } = props;
  const trendScoreClass = getTrendChangeClass(trend);
  return (
    <div className={"trend-score"}>
      <img
        src={getTrendScoreIcon(trend)}
        alt="Trend Icon"
        className={"trend-score-icon"}
      />
      <div className={trendScoreClass}>{formatTrendScore(trend)}</div>
    </div>
  );
};

export default CyberTrendScore;

import "./FilterPanel.scss";

import { ReactNode, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { cloneDeep as _cloneDeep } from "lodash";
import classnames from "classnames";

import { useCurrentUser } from "../../_common/selectors/commonSelectors";
import { SelectV2, SelectV2Multi } from "../../_common/components/SelectV2";
import TextField from "../../_common/components/TextField";
import Button from "../../_common/components/core/Button";
import Icon from "../../_common/components/core/Icon";
import UserAvatar from "../../_common/components/UserAvatar";
import { noAssigneeSVG } from "../../_common/components/UserAssignment";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import IconButton from "../../_common/components/IconButton";
import { Severity } from "../../_common/types/severity";
import { LabelColor } from "../../_common/types/label";
import { severityMap } from "../../_common/helpers";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import PillLabel from "../../vendorrisk/components/PillLabel";
import {
  ThreatMonitoringResultsDatePeriodOption,
  ThreatMonitoringResultsFilter,
  ThreatMonitoringThreatType,
  ThreatMonitoringSourceType,
  DisplayableThreatMonitoringResultStatus,
  DisplayableThreatMonitoringResultStatusToLabel,
  ThreatMonitoringFeedType,
  BreachRiskUserName,
} from "../api/types";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import tmSlice, { defaultFilters, maxDatePeriod } from "../Slice";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import CircledIcon from "../../_common/components/CircledIcon";

interface User {
  id: number;
  name: string;
  avatar: string;
}

interface FilterPanelProps {
  feedType: ThreatMonitoringFeedType;
  filters: ThreatMonitoringResultsFilter;
  allowAllTimeDatePeriod?: boolean;
  dateFilterTitle?: string;
  hidden?: boolean;
}

// FilterPanel provides controls to modify a ThreatMonitoringResultsFilter.
const FilterPanel = ({
  feedType,
  filters,
  allowAllTimeDatePeriod,
  dateFilterTitle,
  hidden,
}: FilterPanelProps) => {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(
    (state) => tmSlice.selectSlice(state).feeds[feedType].filtersExpanded
  );
  const setExpanded = (expanded: boolean) => {
    dispatch(
      tmSlice.actions.updateFeedState({
        feed: feedType,
        state: { filtersExpanded: expanded },
      })
    );
  };

  const { data: userData } = ThreatMonitoringAPI.useGetReadWriteUsersV1Query();

  const { data: keywordsData } = ThreatMonitoringAPI.useGetKeywordsV1Query({});

  const allUsers = userData?.users || [];
  const keywords = keywordsData?.keywords || [];

  const filtersAreDefault = areFiltersDefaultExcludingDate(filters, feedType);

  const squashArray = <K,>(k?: K[]) => (k?.length ? k : undefined);
  const updateFilters = (filters: Partial<ThreatMonitoringResultsFilter>) =>
    dispatch(
      tmSlice.actions.updatePageFilters({
        feed: feedType,
        filters,
      })
    );

  return (
    <div
      className={classnames("threat-monitoring-filter-panel", {
        expanded: expanded,
        collapsed: !expanded,
      })}
      hidden={hidden}
    >
      <div
        className={classnames("header", {
          expanded: expanded,
          collapsed: !expanded,
        })}
        onClick={() => {
          if (!expanded) {
            setExpanded(!expanded);
          }
        }}
      >
        <div
          className={classnames("title", {
            "no-display": !expanded,
          })}
        >
          <span className="cr-icon-filter" />
          <h5>Filters</h5>
        </div>
        <SidePopupV2
          text={expanded ? "Collapse filters" : "Expand filters"}
          position={"right"}
          width={expanded ? 130 : 140}
          transitionTimeout={{
            appear: 250,
            enter: 0,
            exit: 0,
          }}
        >
          <IconButton
            className={"navigation-expander"}
            icon={
              <div
                className={classnames(
                  "cr-icon-expander",
                  "nav-expander-button",
                  {
                    "rotate-180": expanded,
                  }
                )}
              />
            }
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </SidePopupV2>
        {!expanded && <div className="collapsed-title">FILTERS</div>}
        {!expanded && !areFiltersDefaultExcludingDate(filters, feedType) && (
          <div className="active-filters-circle" />
        )}
      </div>
      <div className="content" hidden={!expanded}>
        <div className="filters-container">
          <div className="filter-container">
            <div className="title">{dateFilterTitle || "Date"}</div>
            <SelectV2
              options={getAllDateFilterOptions(allowAllTimeDatePeriod)}
              value={getDateFilterOptionByValue(filters.datePeriod)}
              onChange={(v) => {
                updateFilters({
                  datePeriod: v?.value || filters.datePeriod,
                });
              }}
              controlShouldRenderValue={true}
              captureMenuScroll={false}
            />
          </div>
          <div className="filter-separator" />
          <div className="refresh-filters-button">
            <Button
              tertiary
              onClick={(ev) => {
                ev.preventDefault();
                dispatch(
                  tmSlice.actions.resetPageFiltersExcludingDateFilter(feedType)
                );
              }}
              disabled={filtersAreDefault}
            >
              <span className="icon-refresh" />
              <span>Reset filters</span>
            </Button>
          </div>
          <KeywordFilter
            options={keywords
              ?.filter((k) => k.active)
              ?.map((k) => {
                return k.keyword;
              })}
            checkedValues={filters.keywords}
            onCheckedValuesChange={(newCheckedValues) => {
              updateFilters({
                keywords: squashArray(newCheckedValues),
              });
            }}
          />
          <div className="filter-separator" />
          <SeverityFilter
            checkedValues={filters.severities}
            onCheckedValuesChange={(newCheckedValues) => {
              updateFilters({
                severities: squashArray(newCheckedValues),
              });
            }}
          />
          <div className="filter-separator" />
          <ThreatTypeFilter
            checkedValues={filters.threatTypes}
            onCheckedValuesChange={(newCheckedValues) => {
              updateFilters({
                threatTypes: squashArray(newCheckedValues),
              });
            }}
          />
          <div className="filter-separator" />
          <SourceTypeFilter
            checkedValues={filters.sourceTypes}
            onCheckedValuesChange={(newCheckedValues) => {
              updateFilters({
                sourceTypes: squashArray(newCheckedValues),
              });
            }}
          />
          <div className="filter-separator" />
          <CollapsableFilter title="Text" startExpanded={true}>
            <div className="text-filter-label">Contains</div>
            <TextFilter
              value={filters.containsText}
              maxLength={120}
              onValidChange={(val: string) => {
                updateFilters({
                  containsText: val ? val : undefined,
                });
              }}
            />
            <div className="text-filter-label">Does not contain</div>
            <TextFilter
              value={filters.doesNotContainText}
              maxLength={120}
              onValidChange={(val: string) => {
                updateFilters({
                  doesNotContainText: val ? val : undefined,
                });
              }}
            />
          </CollapsableFilter>
          {feedType === ThreatMonitoringFeedType.Investigating && (
            <>
              <div className="filter-separator" />
              <InvestigatorFilter
                allUsers={allUsers}
                filters={filters}
                onFiltersChange={updateFilters}
              />
            </>
          )}
          {feedType === ThreatMonitoringFeedType.Remediating && (
            <>
              <div className="filter-separator" />
              <RemediatorFilter
                allUsers={allUsers}
                filters={filters}
                onFiltersChange={updateFilters}
              />
            </>
          )}
          {feedType === ThreatMonitoringFeedType.Closed && (
            <>
              <div className="filter-separator" />
              <ClosedByFilter
                allUsers={allUsers}
                filters={filters}
                onFiltersChange={updateFilters}
              />
              <div className="filter-separator" />
              <StatusFilter
                checkedValues={filters.statuses}
                onCheckedValuesChange={(newCheckedValues) => {
                  updateFilters({
                    statuses: squashArray(newCheckedValues),
                  });
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;

const checkboxColour = "#CCCFE0";

interface dateFilterOption {
  value: ThreatMonitoringResultsDatePeriodOption;
  label: string;
}

const getAllDateFilterOptions = (
  allowAllTimeDatePeriod?: boolean
): dateFilterOption[] => {
  const options = [
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastTwentyFourHours,
      label: "Last 24 hours",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastSevenDays,
      label: "Last 7 days",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
      label: "Last 30 days",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastThreeMonths,
      label: "Last 3 months",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastSixMonths,
      label: "Last 6 months",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastTwelveMonths,
      label: "Last 12 months",
    },
  ];

  if (allowAllTimeDatePeriod) {
    options.unshift({
      value: ThreatMonitoringResultsDatePeriodOption.AllTime,
      label: "All time",
    });
  }

  return options;
};

const getDateFilterOptionByValue = (
  value: ThreatMonitoringResultsDatePeriodOption
): dateFilterOption | undefined => {
  return getAllDateFilterOptions(true).find((o) => o.value === value);
};

interface collapsableFilterProps {
  title: string;
  startExpanded?: boolean;
  children: ReactNode;
}

// CollapsableFilter wraps a filter control in a collapsable section.
const CollapsableFilter = (props: collapsableFilterProps) => {
  const [expanded, setExpanded] = useState(props.startExpanded || false);

  return (
    <div className="filter-container">
      <div
        className="clickable-title"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <span>{props.title}</span>
        <Icon name="chevron" direction={expanded ? 0 : 180} />
      </div>
      <TransitionGroup component={null}>
        {expanded && (
          <CSSTransition
            key={"expand-section"}
            timeout={250}
            classNames="expand"
          >
            <div className="section-body">{props.children}</div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

// multiSelectFilterOption contains option data in a multi-select control used for filtering.
interface multiSelectFilterOption<T> {
  // Used for rendering a list in React & should be unique within it's siblings.
  key: string;

  // The value to toggle, or undefined if nestedOptions is provided
  value?: T;

  // The label to show next to the checkbox.
  label: JSX.Element;

  // The options nested under this option, or undefined if value is provided.
  nestedOptions?: multiSelectFilterOption<T>[];

  disabled?: boolean;
}

// countAllLeafNodeMultiSelectFilterOptions traverses multiSelectFilterOption, counting all
// multiSelectFilterOptions that don't have any nested options.
const countAllLeafNodeMultiSelectFilterOptions = function <T>(
  options: multiSelectFilterOption<T>[]
): number {
  let count = 0;

  for (let a = 0; a < options.length; a++) {
    const option = options[a];

    if (!option.nestedOptions) {
      if (!option.disabled) {
        count += 1;
      }
    } else {
      count += countAllLeafNodeMultiSelectFilterOptions(option.nestedOptions);
    }
  }

  return count;
};

interface MultiSelectFilterOptionCheckboxProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  option: multiSelectFilterOption<T>;
  disabled?: boolean;
}

// MultiSelectFilterOptionCheckbox is a checkbox used in a multi select control to choose one option for filtering.
const MultiSelectFilterOptionCheckbox = function <T>(
  props: MultiSelectFilterOptionCheckboxProps<T>
) {
  if (!props.option.value) {
    return <></>;
  }

  return (
    <ColorCheckbox
      color={checkboxColour}
      label={props.option.label}
      checked={props.checkedValues?.includes(props.option.value)}
      onClick={() => {
        if (!props.option.value) {
          return;
        }

        let newCheckedValues = _cloneDeep(props.checkedValues);

        if (!newCheckedValues) {
          newCheckedValues = [];
        }

        if (newCheckedValues?.includes(props.option.value)) {
          newCheckedValues.splice(
            newCheckedValues.indexOf(props.option.value),
            1
          );
        } else {
          newCheckedValues?.push(props.option.value);
        }

        props.onCheckedValuesChange(newCheckedValues);
      }}
      disabled={props.disabled}
    />
  );
};

interface MultiSelectFilterOptionsProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  options: multiSelectFilterOption<T>[];
  disabled?: boolean;
}

// MultiSelectFilterOptions renders props.options in a multi select control.
const MultiSelectFilterOptions = function <T>(
  props: MultiSelectFilterOptionsProps<T>
) {
  return (
    <ul>
      {props.options.map((o) => (
        <li key={o.key}>
          {o.nestedOptions && (
            <MultiSelectNestedFilterOptions
              checkedValues={props.checkedValues}
              onCheckedValuesChange={props.onCheckedValuesChange}
              options={o.nestedOptions}
              label={o.label}
              disabled={props.disabled}
            />
          )}
          {!o.nestedOptions && (
            <MultiSelectFilterOptionCheckbox
              checkedValues={props.checkedValues}
              onCheckedValuesChange={props.onCheckedValuesChange}
              option={o}
              disabled={o.disabled || props.disabled}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

interface MultiSelectNestedFilterOptionsProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  options: multiSelectFilterOption<T>[];
  label: JSX.Element;
  disabled?: boolean;
}

// MultiSelectNestedFilterOptions renders a single filtering option with nested filtering options in a multi select control.
const MultiSelectNestedFilterOptions = function <T>(
  props: MultiSelectNestedFilterOptionsProps<T>
) {
  const [expanded, setExpanded] = useState(true);

  let numberOfOptionsChecked = 0;
  if (props.checkedValues) {
    for (let a = 0; a < props.checkedValues.length; a++) {
      for (let b = 0; b < props.options.length; b++) {
        if (props.checkedValues[a] === props.options[b].value) {
          numberOfOptionsChecked += 1;
          break;
        }
      }
    }
  }

  const allValuesSelected = numberOfOptionsChecked === props.options.length;
  const someValuesSelected = !allValuesSelected && numberOfOptionsChecked > 0;

  return (
    <div className="nested-filter-option">
      <div
        className="filter-option-label"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <ColorCheckbox
          color={checkboxColour}
          label={
            <>
              {props.label}
              <Icon name="chevron" direction={expanded ? 0 : 180} />
            </>
          }
          indeterminate={someValuesSelected}
          checked={allValuesSelected}
          onClick={(ev) => {
            // If the checkbox was clicked
            if ((ev.target as HTMLDivElement).className === "box") {
              ev.stopPropagation();

              const setOptionsChecked = !allValuesSelected;

              let newCheckedValues: T[] = [];
              if (props.checkedValues) {
                newCheckedValues = _cloneDeep(props.checkedValues);
              }

              for (let a = 0; a < props.options.length; a++) {
                const option = props.options[a];
                if (!option.value) {
                  continue;
                }

                const isOptionChecked = newCheckedValues.includes(option.value);

                if (isOptionChecked && !setOptionsChecked) {
                  newCheckedValues.splice(
                    newCheckedValues.indexOf(option.value),
                    1
                  );
                } else if (!isOptionChecked && setOptionsChecked) {
                  newCheckedValues?.push(option.value);
                }
              }

              props.onCheckedValuesChange(newCheckedValues);
            }
          }}
          disabled={props.disabled}
        />
      </div>
      <TransitionGroup component={null}>
        {expanded && (
          <CSSTransition
            key={"expand-section"}
            timeout={250}
            classNames="expand"
          >
            <div className="section-body">
              <div className="nested-filter-option-spacer"></div>
              <MultiSelectFilterOptions
                checkedValues={props.checkedValues}
                onCheckedValuesChange={props.onCheckedValuesChange}
                options={props.options}
                disabled={props.disabled}
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

interface CollapsableMultiSelectFilterProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  options: multiSelectFilterOption<T>[];
  name: string;
  disabled?: boolean;
}

// CollapsableMultiSelectFilter is a control used to choose one or more options for filtering.
const CollapsableMultiSelectFilter = function <T>(
  props: CollapsableMultiSelectFilterProps<T>
) {
  const maxNumberOfOptions = countAllLeafNodeMultiSelectFilterOptions(
    props.options
  );

  let title = props.name;
  if (maxNumberOfOptions > 0) {
    title += ` (${
      (props.checkedValues ? props.checkedValues.length : 0) ||
      maxNumberOfOptions
    }/${maxNumberOfOptions})`;
  }

  return (
    <CollapsableFilter title={title} startExpanded={true}>
      <MultiSelectFilterOptions
        checkedValues={props.checkedValues}
        onCheckedValuesChange={props.onCheckedValuesChange}
        options={props.options}
        disabled={props.disabled}
      />
    </CollapsableFilter>
  );
};

interface severityOption {
  value: Severity;
  label: string;
  icon: JSX.Element;
}

export const GetAllSeverityFilterOptions = (): severityOption[] => {
  return [
    {
      value: Severity.Critical,
      label: "Critical",
      icon: severityMap[Severity.Critical].icon,
    },
    {
      value: Severity.High,
      label: "High",
      icon: severityMap[Severity.High].icon,
    },
    {
      value: Severity.Medium,
      label: "Medium",
      icon: severityMap[Severity.Medium].icon,
    },
    {
      value: Severity.Low,
      label: "Low",
      icon: severityMap[Severity.Low].icon,
    },
  ];
};

interface SeverityFilterProps {
  checkedValues?: Severity[];
  onCheckedValuesChange: (newCheckedValues: Severity[]) => void;
}

// SeverityFilter is a control used to choose one or more severities for filtering.
const SeverityFilter = (props: SeverityFilterProps) => {
  const options = GetAllSeverityFilterOptions().map((o) => {
    return {
      key: o.value as string,
      value: o.value,
      label: (
        <>
          {o.icon}
          <span>{o.label}</span>
        </>
      ),
    } as multiSelectFilterOption<Severity>;
  });

  return (
    <CollapsableMultiSelectFilter<Severity>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      options={options}
      name="Severity"
    />
  );
};

interface threatTypeOption {
  value: ThreatMonitoringThreatType;
  label: string;
}

export const GetAllThreatTypeFilterOptions = (): threatTypeOption[] => {
  return [
    {
      value: ThreatMonitoringThreatType.ConfidentialBusiness,
      label: "Confidential business data",
    },
    {
      value: ThreatMonitoringThreatType.ExposedCredentials,
      label: "Exposed credentials",
    },
    {
      value: ThreatMonitoringThreatType.ExposedPII,
      label: "Exposed PII",
    },
    {
      value: ThreatMonitoringThreatType.KeywordMention,
      label: "Keyword mention",
    },
    {
      value: ThreatMonitoringThreatType.Malware,
      label: "Malware",
    },
  ];
};

interface ThreatTypeFilterProps {
  checkedValues?: ThreatMonitoringThreatType[];
  onCheckedValuesChange: (
    newCheckedValues: ThreatMonitoringThreatType[]
  ) => void;
}

// ThreatTypeFilter is a control used to choose one or more threat types for filtering.
const ThreatTypeFilter = (props: ThreatTypeFilterProps) => {
  const options = GetAllThreatTypeFilterOptions().map((o) => {
    return {
      key: o.value as string,
      value: o.value,
      label: (
        <>
          <span>{o.label}</span>
        </>
      ),
    } as multiSelectFilterOption<ThreatMonitoringThreatType>;
  });

  return (
    <CollapsableMultiSelectFilter<ThreatMonitoringThreatType>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      options={options}
      name="Threat type"
    />
  );
};

interface sourceTypeOption {
  value: ThreatMonitoringSourceType;
  label: string;
}

interface sourceTypeTopLevelOption {
  key?: string;
  label: string;
  nestedOptions?: sourceTypeOption[];
  value?: ThreatMonitoringSourceType;
}

const getAllSourceTypeFilterTopLevelOptions =
  (): sourceTypeTopLevelOption[] => {
    return [
      {
        value: ThreatMonitoringSourceType.Forum,
        label: "Forum",
      },
      {
        value: ThreatMonitoringSourceType.Marketplace,
        label: "Illicit marketplace",
      },
      {
        key: "messaging_platforms",
        label: "Messaging platforms",
        nestedOptions: [
          {
            value: ThreatMonitoringSourceType.Discord,
            label: "Discord",
          },
          {
            value: ThreatMonitoringSourceType.Telegram,
            label: "Telegram",
          },
        ],
      },
      {
        value: ThreatMonitoringSourceType.RansomwareBlog,
        label: "Ransomware blog",
      },
      {
        value: ThreatMonitoringSourceType.StealerLogs,
        label: "Stealer log",
      },
    ];
  };

export const GetAllSourceTypeFilterOptions = (): sourceTypeOption[] => {
  const options: sourceTypeOption[] = [];

  const topLevelOptions = getAllSourceTypeFilterTopLevelOptions();

  for (let a = 0; a < topLevelOptions.length; a++) {
    const topLevelOption = topLevelOptions[a];

    if (topLevelOption.value) {
      options.push({
        label: topLevelOption.label,
        value: topLevelOption.value,
      });
    }

    if (topLevelOption.nestedOptions) {
      for (let b = 0; b < topLevelOption.nestedOptions.length; b++) {
        options.push(topLevelOption.nestedOptions[b]);
      }
    }
  }

  return options;
};

interface SourceTypeFilterProps {
  checkedValues?: ThreatMonitoringSourceType[];
  onCheckedValuesChange: (
    newCheckedValues: ThreatMonitoringSourceType[]
  ) => void;
}

// SourceTypeFilter is a control used to choose one or more threat types for filtering.
const SourceTypeFilter = (props: SourceTypeFilterProps) => {
  const options = getAllSourceTypeFilterTopLevelOptions().map(
    (topLevelOption) => {
      return {
        key: topLevelOption.key ? topLevelOption.key : topLevelOption.value,
        label: <span>{topLevelOption.label}</span>,
        value: topLevelOption.value,
        nestedOptions: topLevelOption.nestedOptions?.map((nestedOption) => {
          return {
            key: nestedOption.value as string,
            value: nestedOption.value,
            label: <span>{nestedOption.label}</span>,
          } as multiSelectFilterOption<ThreatMonitoringSourceType>;
        }),
      } as multiSelectFilterOption<ThreatMonitoringSourceType>;
    }
  );

  return (
    <CollapsableMultiSelectFilter<ThreatMonitoringSourceType>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      options={options}
      name="Source"
    />
  );
};

interface keywordFilterProps {
  options?: string[]; // If not provided, it's assumed they are loading.
  checkedValues?: string[];
  onCheckedValuesChange: (newCheckedValues: string[]) => void;
}

// KeywordFilter is a control used to choose one or more severities for filtering.
const KeywordFilter = (props: keywordFilterProps) => {
  const filterName = "Keywords";

  if (props.options === undefined) {
    return (
      <CollapsableMultiSelectFilter<string>
        onCheckedValuesChange={() => {}}
        name={filterName}
        options={[
          {
            key: "a",
            value: "a",
            disabled: true,
            label: <div className="loading-filter-option"></div>,
          },
          {
            key: "b",
            value: "b",
            disabled: true,
            label: <div className="loading-filter-option"></div>,
          },
          {
            key: "c",
            value: "c",
            disabled: true,
            label: <div className="loading-filter-option"></div>,
          },
        ]}
      />
    );
  }

  const options = props.options.map((o) => {
    return {
      key: o,
      value: o,
      label: (
        <SidePopupV2
          className={"btn-icon-popup"}
          text={o}
          position={"right"}
          width={250}
          popupDelay={500}
          noWrap={true}
        >
          <div className="keyword-label">{o}</div>
        </SidePopupV2>
      ),
    } as multiSelectFilterOption<string>;
  });

  return (
    <CollapsableMultiSelectFilter<string>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      name={filterName}
      options={options}
      disabled={options.length === 0}
    />
  );
};

// sortUsers returns a copy of users sorted by name ascending, with the current
// user always first.
const sortUsers = (currentUserID: number, users: User[]) => {
  const usersSorted = _cloneDeep(users);
  usersSorted.sort((l, r) => {
    if (l.name < r.name || l.id === currentUserID) {
      return -1;
    }

    if (l.name > r.name) {
      return 1;
    }

    return 0;
  });

  return usersSorted;
};

interface userFilterOption {
  value: string;
  label: string;
  labelIcon: JSX.Element;
}

const usersToUserFilterOption = (users: User[]): userFilterOption[] => {
  return users.map((u) => {
    return {
      value: u.id.toString(),
      label: u.name,
      labelIcon: <UserAvatar avatar={u.avatar} name={u.name} />,
    };
  });
};

const findUserFilterOptionsByUserIDs = (
  options: userFilterOption[],
  ids: number[]
): userFilterOption[] | undefined => {
  return options.filter((o) => {
    return ids.includes(+o.value);
  });
};

interface UserFilterProps {
  title: string;
  placeholder: string;
  options: userFilterOption[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  values: userFilterOption[];
  onChange: (newValues: userFilterOption[]) => void;
}

const UserFilter = (props: UserFilterProps) => {
  return (
    <CollapsableFilter title={props.title} startExpanded={true}>
      <SelectV2Multi
        isMulti
        options={props.options}
        value={props.values}
        formatOptionLabel={(userFilterOption) => (
          <div className="avatar-and-label-option">
            {userFilterOption.labelIcon}
            <div className="label" title={userFilterOption.label}>
              {userFilterOption.label}
            </div>
          </div>
        )}
        onChange={(v) => {
          props.onChange(v && v.values ? Array.from(v.values()) : []);
        }}
        controlShouldRenderValue={true}
        captureMenuScroll={false}
        placeholder={props.placeholder}
      />
    </CollapsableFilter>
  );
};

interface InvestigatorFilterProps {
  allUsers: User[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  filters: ThreatMonitoringResultsFilter;
  onFiltersChange: (newFilters: ThreatMonitoringResultsFilter) => void;
}

const noInvestigatorOption: userFilterOption = {
  value: "no_investigator",
  label: "No investigator",
  labelIcon: noAssigneeSVG,
};

const InvestigatorFilter = (props: InvestigatorFilterProps) => {
  const currentUser = useCurrentUser();

  const users = sortUsers(currentUser.id, props.allUsers);

  const options = usersToUserFilterOption(users);
  options.unshift(noInvestigatorOption);

  const selectedOptions =
    findUserFilterOptionsByUserIDs(
      options,
      props.filters.investigatorUserIDs ? props.filters.investigatorUserIDs : []
    ) || [];
  if (props.filters.notBeingInvestigated) {
    selectedOptions.unshift(noInvestigatorOption);
  }

  return (
    <UserFilter
      title="Investigator"
      placeholder="Select an investigator"
      options={options}
      values={selectedOptions}
      onChange={(newValues: userFilterOption[]) => {
        const newFilters = _cloneDeep(props.filters);

        const includesNoInvestigator = newValues.includes(noInvestigatorOption);
        newFilters.notBeingInvestigated = includesNoInvestigator
          ? includesNoInvestigator
          : undefined;

        const selectedUserIDs = newValues
          .filter((v) => v.value != noInvestigatorOption.value)
          .map((v) => +v.value);
        newFilters.investigatorUserIDs = selectedUserIDs.length
          ? selectedUserIDs
          : undefined;

        props.onFiltersChange(newFilters);
      }}
    />
  );
};

interface RemediatorFilterProps {
  allUsers: User[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  filters: ThreatMonitoringResultsFilter;
  onFiltersChange: (newFilters: ThreatMonitoringResultsFilter) => void;
}

const RemediatorFilter = (props: RemediatorFilterProps) => {
  const currentUser = useCurrentUser();

  const users = sortUsers(currentUser.id, props.allUsers);

  const options = usersToUserFilterOption(users);

  const selectedOptions =
    findUserFilterOptionsByUserIDs(
      options,
      props.filters.remediatorUserIDs ? props.filters.remediatorUserIDs : []
    ) || [];

  return (
    <UserFilter
      title="Remediator"
      placeholder="Select a remediator"
      options={options}
      values={selectedOptions}
      onChange={(newValues: userFilterOption[]) => {
        const newFilters = _cloneDeep(props.filters);

        const selectedUserIDs = newValues.map((v) => +v.value);
        newFilters.remediatorUserIDs = selectedUserIDs.length
          ? selectedUserIDs
          : undefined;

        props.onFiltersChange(newFilters);
      }}
    />
  );
};

interface ClosedByFilterProps {
  allUsers: User[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  filters: ThreatMonitoringResultsFilter;
  onFiltersChange: (newFilters: ThreatMonitoringResultsFilter) => void;
}

const upguardSystemUserOption: userFilterOption = {
  value: "upguard_system",
  label: BreachRiskUserName,
  labelIcon: <CircledIcon iconClass="cr-icon-upguard-logo-pick upguard-logo" />,
};

const ClosedByFilter = (props: ClosedByFilterProps) => {
  const currentUser = useCurrentUser();

  const users = sortUsers(currentUser.id, props.allUsers);

  const options = usersToUserFilterOption(users);
  options.unshift(upguardSystemUserOption);

  const selectedOptions =
    findUserFilterOptionsByUserIDs(
      options,
      props.filters.closedByUserIDs ? props.filters.closedByUserIDs : []
    ) || [];
  if (props.filters.autoDismissed) {
    selectedOptions.unshift(upguardSystemUserOption);
  }

  return (
    <UserFilter
      title="Closed by"
      placeholder="Select user"
      options={options}
      values={selectedOptions}
      onChange={(newValues: userFilterOption[]) => {
        const newFilters = _cloneDeep(props.filters);

        const includesSystem = newValues.includes(upguardSystemUserOption);
        newFilters.autoDismissed = includesSystem ? includesSystem : undefined;

        const selectedUserIDs = newValues
          .filter((v) => v.value != upguardSystemUserOption.value)
          .map((v) => +v.value);
        newFilters.closedByUserIDs = selectedUserIDs.length
          ? selectedUserIDs
          : undefined;

        props.onFiltersChange(newFilters);
      }}
    />
  );
};

interface textFilterProps {
  value?: string;
  maxLength: number;
  onValidChange: (newValue: string) => void;
}

const TextFilter = (props: textFilterProps) => {
  const [value, setValue] = useState(props.value);
  const [prevPropsValue, setPrevPropsValue] = useState(props.value);

  if (props.value !== prevPropsValue) {
    setPrevPropsValue(props.value);
    setValue(props.value);
  }

  return (
    <TextField
      value={value || ""}
      maxLength={props.maxLength}
      allowTextOverflow={true}
      onChanged={(newValue: string, valid: boolean) => {
        setValue(newValue);

        if (!valid) {
          return;
        }

        props.onValidChange(newValue);
      }}
    />
  );
};

interface statusOption {
  value: DisplayableThreatMonitoringResultStatus;
  label: string;
  color: LabelColor;
}

export const GetAllStatusFilterOptions = (): statusOption[] => {
  return [
    {
      value: DisplayableThreatMonitoringResultStatus.Dismissed,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Dismissed
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Dismissed
      ).color,
    },
    {
      value: DisplayableThreatMonitoringResultStatus.Remediated,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Remediated
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Remediated
      ).color,
    },
    {
      value: DisplayableThreatMonitoringResultStatus.SmartFiltered,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.SmartFiltered
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.SmartFiltered
      ).color,
    },
    {
      value: DisplayableThreatMonitoringResultStatus.Waived,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Waived
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Waived
      ).color,
    },
  ];
};

interface StatusFilterProps {
  checkedValues?: DisplayableThreatMonitoringResultStatus[];
  onCheckedValuesChange: (
    newCheckedValues: DisplayableThreatMonitoringResultStatus[]
  ) => void;
}

// StatusFilter is a control used to choose one or more statuses for filtering.
const StatusFilter = (props: StatusFilterProps) => {
  const options = GetAllStatusFilterOptions().map((o) => {
    return {
      key: o.value as string,
      value: o.value,
      label: <PillLabel color={o.color}>{o.label}</PillLabel>,
    } as multiSelectFilterOption<DisplayableThreatMonitoringResultStatus>;
  });

  return (
    <CollapsableMultiSelectFilter<DisplayableThreatMonitoringResultStatus>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      options={options}
      name="Status"
    />
  );
};

export function filterAtMaxDatePeriod(
  filters: ThreatMonitoringResultsFilter
): boolean {
  return filters.datePeriod === maxDatePeriod;
}

export const areFiltersDefaultExcludingDate = (
  filters: ThreatMonitoringResultsFilter,
  feedType: ThreatMonitoringFeedType
): boolean => {
  const defaultFilter = defaultFilters[feedType];

  return (
    JSON.stringify({ ...filters, datePeriod: defaultFilter.datePeriod }) ===
    JSON.stringify(defaultFilter)
  );
};

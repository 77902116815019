import { FC, useMemo, useState } from "react";
import userbaseApi from "../../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";

import { useSortingWithPagination } from "../../../_common/hooks";
import { OrgScope } from "../../api/types";
import ReportCard from "../../../_common/components/ReportCard";
import SearchBox from "../../../_common/components/SearchBox";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { useLocation } from "react-router-dom";
import { useFilters } from "../../../vendorrisk/reducers/filters.actions";
import { permissionUrl } from "../../UserBaseAppRouter";
import { useDefaultHistory } from "../../../_common/types/router";
import {
  getPermissionCategoryDisplayInformation,
  getPermissionReadWriteDisplayInformation,
} from "../../helpers/scopes";
import "./OrgPermissionsTable.scss";

const PAGE_SIZE = 50;

const EXPOSURE_LEVEL_COL = "exposure_level_col";
const PERMISSION_NAME_COL = "permission_name_col";
const NUM_USERS_GRANTED_PERMISSION_COL = "num_users_col";
const SCOPE_CATEGORY_COL = "scope_category_col";
const SCOPE_READWRITE_COL = "scope_readwrite_col";

const OrgPermissionsTable: FC = ({}) => {
  const history = useDefaultHistory();
  const [searchText, setSearchText] = useState("");
  const { pathname } = useLocation();
  const filters = useFilters();

  const { data, isLoading } = userbaseApi.useGetUserBaseScopesV1Query({
    minRiskLevel: filters.userRiskScopeMinRiskLevel,
    maxRiskLevel: filters.userRiskScopeMaxRiskLevel,
    readOnly: filters.userRiskScopeReadOnly,
    readWrite: filters.userRiskScopeReadWrite,
    categories: filters.userRiskScopeCategories,
    excludeCategories: filters.userRiskExcludeScopeCategories,
  });

  const scopesToUse = data?.scopes ?? [];

  const filteredPermissions = useMemo(
    () =>
      searchText.length > 1
        ? scopesToUse.filter(
            (a) =>
              a.description.toLowerCase().indexOf(searchText.toLowerCase()) >
                -1 ||
              a.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )
        : scopesToUse,
    [data, searchText]
  );

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: EXPOSURE_LEVEL_COL,
      text: "Exposure level",
      sortable: true,
    },
    {
      id: PERMISSION_NAME_COL,
      text: "Description",
      sortable: true,
    },
    {
      id: SCOPE_CATEGORY_COL,
      text: "Category",
      sortable: true,
    },
    {
      id: SCOPE_READWRITE_COL,
      text: "Read/Write?",
      sortable: true,
    },
    {
      id: NUM_USERS_GRANTED_PERMISSION_COL,
      text: "Users",
      sortable: true,
    },
  ];

  const [
    sortedPermissions,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    OrgScope,
    | typeof PERMISSION_NAME_COL
    | typeof EXPOSURE_LEVEL_COL
    | typeof NUM_USERS_GRANTED_PERMISSION_COL
    | typeof SCOPE_CATEGORY_COL
    | typeof SCOPE_READWRITE_COL
  >(
    filteredPermissions,
    NUM_USERS_GRANTED_PERMISSION_COL,
    SortDirection.DESC,
    {
      [PERMISSION_NAME_COL]: {
        orderFuncs: [
          (p) => p.description.toLocaleLowerCase(),
          (p) => p.name.toLocaleLowerCase(),
        ],
        sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC, SortDirection.DESC],
      },
      [EXPOSURE_LEVEL_COL]: {
        orderFuncs: [(p) => p.riskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [NUM_USERS_GRANTED_PERMISSION_COL]: {
        orderFuncs: [(p) => p.userUUIDs.length],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SCOPE_CATEGORY_COL]: {
        orderFuncs: [
          (p) => {
            const { displayString } = getPermissionCategoryDisplayInformation(
              p.category
            );
            return displayString.toLocaleLowerCase();
          },
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SCOPE_READWRITE_COL]: {
        orderFuncs: [
          (p) => {
            const { displayString } = getPermissionReadWriteDisplayInformation(
              p.isReadWrite
            );
            return displayString.toLocaleLowerCase();
          },
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const rows = sortedPermissions.map((scope: OrgScope): IXTableRow<string> => {
    const { displayString: scopeCategoryToDisplay, color: scopeCategoryColor } =
      getPermissionCategoryDisplayInformation(scope?.category);
    const {
      displayString: scopeReadWriteToDisplay,
      color: scopeReadWriteColor,
    } = getPermissionReadWriteDisplayInformation(scope?.isReadWrite);

    const onClick = () => {
      history.push(permissionUrl(scope.externalID));
    };

    return {
      id: scope.externalID,
      onClick: () => onClick(),
      cells: [
        <XTableCell key={EXPOSURE_LEVEL_COL}>
          <ScopeRiskLevelDisplay riskLevel={scope.riskLevel} />
        </XTableCell>,
        <XTableCell
          className="description-with-subtext"
          key={PERMISSION_NAME_COL}
        >
          <div className="description-maintext">{scope.description}</div>
          <div className="description-subtext">{scope.name}</div>
        </XTableCell>,
        <XTableCell key={SCOPE_CATEGORY_COL}>
          <PillLabel color={scopeCategoryColor}>
            {scopeCategoryToDisplay}
          </PillLabel>
        </XTableCell>,
        <XTableCell key={SCOPE_READWRITE_COL}>
          <PillLabel color={scopeReadWriteColor}>
            {scopeReadWriteToDisplay}
          </PillLabel>
        </XTableCell>,
        <XTableCell key={NUM_USERS_GRANTED_PERMISSION_COL}>
          {scope.userUUIDs.length}
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: onClick,
        },
      ],
    };
  });

  return (
    <div className="org-permissions-table">
      <ReportCard newStyles>
        <SearchBox
          onChanged={(v) => {
            onPageChange(1);
            setSearchText(v);
          }}
          value={searchText}
          disabled={isLoading}
        />
        {!isLoading &&
        searchText.length > 0 &&
        sortedPermissions.length == 0 ? (
          <SearchEmptyCard
            onClear={() => setSearchText("")}
            searchItemText={"permission"}
          />
        ) : (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default OrgPermissionsTable;

import { AssuranceType } from "./types/organisations";

export const LabelClassifications = {
  System: "S",
  Vendor: "V",
  Website: "W",
};

export const RiskProfileRiskTypes = {
  Cloudscan: "cloudscan",
  Survey: "survey",
  Evidence: "evidence",
  SaaS: "saas",
  Other: "other",
  AppguardPackageVuln: "appguard_package_vuln",
  AppguardRepoConfig: "appguard_repo_config",
};

export const AsyncJobStatuses = {
  Created: 0,
  Pending: 1,
  Complete: 2,
  Error: 3,
};

// Define the various words we use to refer to a 'vendor' in our UI
// in different cases.

export interface IVendorWords {
  vendorRiskModuleName: string;
  vendorRiskModuleFilename: string;

  vendorRiskExecutiveSummaryPageTitle: string;
  portfolioRiskProfilePageTitle: string;
  portfolioRiskProfileFilename: string;
  vendorsPageTitle: string;

  singular: string;
  singularTitleCase: string;
  singularIndefiniteArticle: string;
  plural: string;
  pluralTitleCase: string;
  possessive: string;
  pluralPossessive: string;
}

const assuranceVendorWords: IVendorWords = {
  vendorRiskModuleName: "Assurance",
  vendorRiskModuleFilename: "assurance",

  vendorRiskExecutiveSummaryPageTitle: "Executive Summary",
  portfolioRiskProfilePageTitle: "Portfolio Risk Profile",
  portfolioRiskProfileFilename: "portfolio-risk-profile",
  vendorsPageTitle: "Portfolio",

  singular: "customer",
  singularTitleCase: "Customer",
  singularIndefiniteArticle: "a customer",
  plural: "customers",
  pluralTitleCase: "Customers",
  possessive: "customer's",
  pluralPossessive: "customers'",
};

const msspVendorWords: IVendorWords = {
  vendorRiskModuleName: "Customers",
  vendorRiskModuleFilename: "customers",

  vendorRiskExecutiveSummaryPageTitle: "Customer Summary",
  portfolioRiskProfilePageTitle: "Threat Landscape",
  portfolioRiskProfileFilename: "threat-landscape",
  vendorsPageTitle: "Customer Portfolio",

  singular: "customer",
  singularTitleCase: "Customer",
  singularIndefiniteArticle: "a customer",
  plural: "customers",
  pluralTitleCase: "Customers",
  possessive: "customer's",
  pluralPossessive: "customers'",
};

const defaultVendorWords: IVendorWords = {
  vendorRiskModuleName: "Vendor Risk",
  vendorRiskModuleFilename: "vendor-risk",

  vendorRiskExecutiveSummaryPageTitle: "Executive Summary",
  portfolioRiskProfilePageTitle: "Portfolio Risk Profile",
  portfolioRiskProfileFilename: "portfolio-risk-profile",
  vendorsPageTitle: "Vendors",

  singular: "vendor",
  singularTitleCase: "Vendor",
  singularIndefiniteArticle: "a vendor",
  plural: "vendors",
  pluralTitleCase: "Vendors",
  possessive: "vendor's",
  pluralPossessive: "vendors'",
};

export const getVendorWords = (assuranceType: AssuranceType): IVendorWords =>
  assuranceType === AssuranceType.Assurance
    ? assuranceVendorWords
    : assuranceType === AssuranceType.MSSP
      ? msspVendorWords
      : defaultVendorWords;

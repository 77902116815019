import { Component } from "react";

import { get as _get, debounce as _debounce } from "lodash";
import SurveyListCard from "../components/SurveyListCard";
import {
  fetchVendorSurveyCounts,
  fetchVendorWatchStatus,
} from "../reducers/cyberRiskActions";
import {
  VendorOverlayInjectedProps,
  wrapInVendorOverlay,
} from "./VendorOverlayWrapper";
import {
  getUserPermissionsForVendorFromState,
  GroupAccessVendorAssetSharing,
  OrgAccessVendorPortfolios,
  OrgAccessVendorTechnologies,
  UserBreachsightWrite,
  UserSystemRoleVendorManagementAnalyst,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import Button from "../../_common/components/core/Button";
import SlidePanel from "../../_common/components/SlidePanel";
import {
  FilterBarContainer,
  FilterLabelContainer,
  FilterPanelContainer,
  FilterTypes,
  isFilterActive,
} from "../components/filter";
import { getCurrentOrgFromUserData } from "../../_common/helpers";

import "../style/views/Questionnaires.scss";
import { InfoBar } from "../../_common/components/InfoBar";
import ExportReportModal from "../components/modals/ExportReportModal";
import {
  ExportFiletypesBoth,
  ExportType,
} from "../../_common/types/exportReport";
import PageHeader from "../../_common/components/PageHeader";
import { getVendorPageBreadcrumbs } from "../../_common/components/Breadcrumbs";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
  SurveyCounts,
  SurveyListData,
  VendorSurveyCounts,
} from "../../_common/types/redux";
import { Filters } from "../components/filter/types";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import {
  getResendDueSurveys,
  getSurveyListV2,
  SurveyListV2RespItem,
} from "../reducers/survey.actions";
import {
  AssuranceType,
  IUserOrganisation,
} from "../../_common/types/organisations";
import { getVendorWords } from "../../_common/constants";
import { getDefaultFilters } from "../reducers/defaults";
import { SortDirection } from "../../_common/components/core/XTable";
import { appConnect } from "../../_common/types/reduxHooks";
import { createUUID } from "../../survey_builder/helpers";
import { trackEvent } from "../../_common/tracking";

const defaultSupportedFilters = [FilterTypes.SURVEY_STATUS];

export const surveyListPageLimit = 20;
export const surveyDefaultSortCol = "date_due";
export const surveyDefaultSortDir = "desc";

interface QuestionnairesLocationState {
  tabId?: SurveyUsageType;
  createSessionUUID?: string;
}

interface QuestionnairesConnectedProps {
  surveys?: SurveyListV2RespItem[];
  resendDueSurveys?: SurveyListV2RespItem[];
  offset: number;
  limit: number;
  totalResults: number;
  sortCol: string;
  sortDir: string;
  loading: boolean;
  error: boolean;
  archived: boolean;
  filterText: string;
  surveyTypes?: any; // TODO,
  userHasWriteVendorInfoPermission: boolean;
  filters?: Filters;
  assuranceType: AssuranceType;
  totalCloudscansOverExportMax: boolean;
  exportMaxCloudscans: number;
  userIsManagedVendorAnalyst: boolean;
  mayHaveSharedAssets: boolean;
  surveyCounts?: VendorSurveyCounts;
  orgHasVendorPortfolioPermission: boolean;
  orgHasFourthPartyPermission: boolean;
}

type QuestionnairesProps = QuestionnairesConnectedProps &
  VendorOverlayInjectedProps<never, QuestionnairesLocationState> &
  DefaultThunkDispatchProp;

export type SurveyTab = "upcoming" | "active" | "archived";

interface QuestionnairesState {
  watchedStateLoading: boolean;
  filterPanelOpen: boolean;
  exportModalOpen: boolean;
  selectUsageTypeModalOpen: boolean;
  filterText: string;
  currentSurveyTab: SurveyTab;
}

class Questionnaires extends Component<
  QuestionnairesProps,
  QuestionnairesState
> {
  constructor(props: QuestionnairesProps) {
    super(props);

    this.state = {
      watchedStateLoading: false,
      filterPanelOpen: false,
      exportModalOpen: false,
      selectUsageTypeModalOpen: false,
      filterText: this.props.filterText ?? "",
      currentSurveyTab: props.archived ? "archived" : "active",
    };
  }

  componentDidMount() {
    const props = this.props;

    // this is the data that is missing on reload
    if (props.vendorId) {
      this.props.dispatch(fetchVendorWatchStatus(props.vendorId, false));
    }

    if (!props.vendorId || props.watching) {
      this.fetchData(
        props.dispatch,
        props.vendorId,
        props.offset,
        props.sortCol,
        props.sortDir,
        props.archived,
        false,
        props.filterText
      );
    }
  }

  componentDidUpdate(prevProps: QuestionnairesProps) {
    if (prevProps.vendorId !== this.props.vendorId) {
      this.fetchData(
        this.props.dispatch,
        this.props.vendorId,
        0,
        surveyDefaultSortCol,
        surveyDefaultSortDir,
        false,
        false,
        this.state.filterText
      );

      // reset to current tab on component reload
      this.setState({ currentSurveyTab: "active" });
    } else if (
      this.props.vendorId &&
      !prevProps.watching &&
      this.props.watching /*&&
      this.props.newlyWatched*/
    ) {
      this.fetchData(
        this.props.dispatch,
        this.props.vendorId,
        0,
        surveyDefaultSortCol,
        surveyDefaultSortDir,
        false,
        true,
        this.state.filterText
      );
      this.setState({ currentSurveyTab: "active" });
    }
  }

  fetchData = (
    dispatch: DefaultThunkDispatch,
    vendorId?: number,
    offset?: number,
    sortCol?: string,
    sortDir?: string,
    archived?: boolean,
    force?: boolean,
    filterText?: string
  ) => {
    // only send filter text if its 3 chars or more
    if (filterText && filterText.length < 3) {
      filterText = "";
    }

    if (vendorId) {
      dispatch(fetchVendorSurveyCounts(vendorId, true));
    }

    dispatch(
      getSurveyListV2(
        force,
        vendorId,
        offset,
        surveyListPageLimit,
        sortCol,
        sortDir,
        archived,
        filterText
      )
    );

    dispatch(getResendDueSurveys(vendorId, force));
  };

  urlPrefix = (vendorId: number) => {
    if (this.props.isManagementAnalystSession) {
      return `/analysts/tpvm/${this.props.managedOrgId}/${
        vendorId || this.props.vendorId
      }`;
    }
    return `/vendor/${vendorId || this.props.vendorId}`;
  };

  backTo = () => {
    return this.props.vendorId
      ? `${this.urlPrefix(this.props.vendorId)}/surveys`
      : "/surveys";
  };

  // either this is called with no parameters (basic send from current customer to current vendor)
  // or it is sent with the id of a specific survey instance to resend, and the vendor that the original survey was sent to.
  onCreateSurvey = (
    resendSurvey?: SurveyListV2RespItem | undefined,
    vendorId?: number
  ) => {
    let createSessionUUID: string | undefined;

    if (resendSurvey === undefined) {
      // The user is creating a new survey - we want to track how far through the process they get
      // Create a UUID to include in each tracking event for grouping, and send an initial event starting the flow
      createSessionUUID = createUUID();
      const eventName = this.props.vendorId
        ? "Questionnaire_Vendor_Flow_Started"
        : "Questionnaire_Generic_Flow_Started";

      const eventProperties = this.props.vendorId
        ? { flowUUID: createSessionUUID, vendors: [this.props.vendorId] }
        : { flowUUID: createSessionUUID };

      trackEvent(eventName, eventProperties);
    }

    const backContext = {
      backTo: this.backTo(),
      backToText: "Back to Questionnaires",
    };

    const vId = vendorId || this.props.vendorId;

    if (vId) {
      this.props.history.push(`${this.urlPrefix(vId)}/surveys/create`, {
        resendSurvey: resendSurvey
          ? { id: resendSurvey.surveyId, name: resendSurvey.questionnaireName }
          : null,
        createSessionUUID,
        backContext,
      } as any);
    } else {
      this.props.history.push(`/surveys/create`, {
        createSessionUUID,
        backContext,
      });
    }
  };

  onVendorWatched = () => {
    this.fetchData(
      this.props.dispatch,
      this.props.vendorId,
      0,
      surveyDefaultSortCol,
      surveyDefaultSortDir,
      false,
      true,
      ""
    );
  };

  onToggleFilterPanel = () => {
    this.setState(({ filterPanelOpen }) => ({
      filterPanelOpen: !filterPanelOpen,
    }));
  };

  debouncedUpdateFilters = _debounce(() => {
    if (this.state.filterText.length > 2 || this.state.filterText == "") {
      this.fetchData(
        this.props.dispatch,
        this.props.vendorId,
        this.props.offset,
        this.props.sortCol,
        this.props.sortDir,
        this.props.archived,
        false,
        this.state.filterText
      );
    }
  }, 300);

  onFilterTextChange = (val: string) => {
    this.setState({ filterText: val });
    this.debouncedUpdateFilters();
  };

  onSetCurrentTab = (newTab: SurveyTab) => {
    if (this.state.currentSurveyTab == newTab) {
      return;
    }

    const oldTab = this.state.currentSurveyTab;
    this.setState({ currentSurveyTab: newTab });

    // No need to refresh data if switching between active and upcoming tabs
    if (
      (newTab === "upcoming" && oldTab === "active") ||
      (newTab === "active" && oldTab === "upcoming")
    ) {
      return;
    }

    let archived = false;

    let sortCol = "";
    let sortDir = "";

    if (newTab === "archived") {
      archived = true;
      sortCol = "date_due";
      sortDir = "desc";
    } else {
      sortCol = "date_due";
      sortDir = "desc";
    }

    this.fetchData(
      this.props.dispatch,
      this.props.vendorId,
      this.props.offset,
      sortCol,
      sortDir,
      archived,
      true,
      this.state.filterText
    );
  };

  render() {
    const isVendor = !!this.props.vendorId;

    let supportedFilters = defaultSupportedFilters;
    if (!this.props.vendorId) {
      supportedFilters = [
        ...defaultSupportedFilters,
        FilterTypes.VENDOR_LABEL,
        FilterTypes.VENDOR_TIER,
        FilterTypes.VENDOR_ASSESSMENT_STATUS,
        FilterTypes.VENDOR_ASSESSMENT_AUTHOR,
        FilterTypes.VENDOR_REASSESSMENT_DATE,
        FilterTypes.SCORE,
        FilterTypes.VENDOR,
        this.props.orgHasVendorPortfolioPermission
          ? FilterTypes.VENDOR_PORTFOLIO
          : null,
        FilterTypes.VENDOR_ATTRIBUTES,
        FilterTypes.VENDOR_SURVEY_TYPES,
        FilterTypes.VENDOR_EVIDENCE_TYPES,
        this.props.orgHasFourthPartyPermission
          ? FilterTypes.FOURTH_PARTY_PRODUCT
          : null,
        FilterTypes.VENDOR_DATE_ADDED,
      ].filter(Boolean) as FilterTypes[];
    }

    const filterActive =
      this.props.filters &&
      isFilterActive(
        {
          ...this.props.filters,
          websiteLabelIds: [],
        },
        supportedFilters
      );

    const userHasWriteSurveysPermission =
      this.props.userHasWriteVendorInfoPermission ||
      (this.props.userIsManagedVendorAnalyst && this.props.vendorIsManaged);

    const vendorWords = getVendorWords(this.props.assuranceType);

    return (
      <div>
        {this.props.isManagementAnalystSession && (
          <InfoBar
            message={
              "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
            }
          />
        )}
        <FilterBarContainer
          vendorId={this.props.vendorId}
          isCustomer
          supportedFilters={supportedFilters}
        />
        <PageHeader
          history={this.props.history}
          title="Security Questionnaires"
          breadcrumbs={
            this.props.vendorId
              ? [
                  ...getVendorPageBreadcrumbs(
                    this.props.vendorId,
                    this.props.vendorName,
                    this.props.assuranceType
                  ),
                  { text: "Questionnaires" },
                ]
              : undefined
          }
          backAction={
            this.props.location.state?.backContext?.goBack
              ? this.props.history.goBack
              : this.props.location.state &&
                  this.props.location.state.backContext
                ? () =>
                    this.props.history.push(
                      this.props.location.state.backContext?.backTo || ""
                    )
                : undefined
          }
          backText={
            this.props.location.state && this.props.location.state.backContext
              ? this.props.location.state.backContext.backToText
              : ""
          }
          vendorId={this.props.vendorId}
          rightSection={
            <>
              <FilterLabelContainer
                onClick={this.onToggleFilterPanel}
                supportedFilters={supportedFilters}
                vendorId={this.props.vendorId}
              />
              {(!isVendor || this.props.watching) && (
                <Button
                  onClick={() => this.setState({ exportModalOpen: true })}
                >
                  <div className="cr-icon-export" /> Export
                </Button>
              )}
              {userHasWriteSurveysPermission &&
                (!isVendor || this.props.watching) && (
                  <Button primary onClick={() => this.onCreateSurvey()}>
                    Send questionnaires
                  </Button>
                )}
            </>
          }
          isManagementAnalystSession={this.props.isManagementAnalystSession}
          managedOrgId={this.props.managedOrgId}
          infoSectionPageKey="infoSection_vendorriskQuestionnaires"
          showVendorPortfoliosSelector={!isVendor}
          infoSection={
            <>
              Security questionnaires are designed to help you identify
              potential weaknesses in {vendorWords.plural} that aren&apos;t
              accessible through automated scanning. You can send a pre-built
              security questionnaire based on a variety of security standards to
              any of your monitored {vendorWords.plural} and we&apos;ll
              automatically score it and highlight any identified risks.
            </>
          }
        />
        <div id="questionnaires_view">
          <SurveyListCard
            dispatch={this.props.dispatch}
            history={this.props.history}
            vendorId={
              this.props.vendorId ? this.props.vendorId.toString() : undefined
            }
            vendorName={this.props.vendorName}
            vendorIs
            vendorPrimaryHostname={this.props.vendorPrimaryHostname}
            surveys={this.props.surveys || []}
            assuranceType={this.props.assuranceType}
            resendDueSurveys={this.props.resendDueSurveys || []}
            pagination={{
              currentPage: this.props.limit
                ? this.props.offset / this.props.limit + 1
                : 1,
              onPageChange: (newPage: number) =>
                this.fetchData(
                  this.props.dispatch,
                  this.props.vendorId,
                  (newPage - 1) * this.props.limit,
                  this.props.sortCol,
                  this.props.sortDir,
                  this.props.archived,
                  true,
                  this.state.filterText
                ),
              totalPages:
                this.props.limit && this.props.totalResults
                  ? Math.ceil(this.props.totalResults / this.props.limit)
                  : 1,
              hidePaginationIfSinglePage: true,
            }}
            sortedBy={{
              columnId: this.props.sortCol,
              direction: this.props.sortDir as SortDirection,
            }}
            onSortChange={(columnId: string, direction: string) =>
              this.fetchData(
                this.props.dispatch,
                this.props.vendorId,
                0, // Reset page number when changing the sort direction
                columnId,
                direction,
                this.props.archived,
                true,
                this.props.filterText
              )
            }
            currentTab={this.state.currentSurveyTab}
            setCurrentTab={this.onSetCurrentTab}
            loading={this.props.loading}
            error={this.props.error}
            isFullList={!this.props.vendorId}
            watchedState={this.props.watching}
            watchedStateLoading={this.state.watchedStateLoading}
            filterActive={filterActive}
            onCreateSurvey={this.onCreateSurvey}
            onVendorWatched={this.onVendorWatched}
            vendorIsManaged={this.props.vendorIsManaged}
            userIsManagedVendorAnalyst={this.props.userIsManagedVendorAnalyst}
            userHasWriteSurveysPermission={userHasWriteSurveysPermission}
            urlPrefix={this.urlPrefix}
            isManagementAnalystSession={this.props.isManagementAnalystSession}
            managedOrgId={this.props.managedOrgId}
            mayHaveSharedAssets={
              !!this.props.vendorId && this.props.mayHaveSharedAssets
            }
            surveyCounts={
              this.props.surveyCounts
                ? this.props.surveyCounts[SurveyUsageType.Security]
                : undefined
            }
            filterText={this.state.filterText}
            onFilterTextChange={this.onFilterTextChange}
          />
          <ExportReportModal
            title="Export Questionnaires"
            exportType={ExportType.QuestionnairesReport}
            supportedFilters={supportedFilters}
            isFilterActive={filterActive}
            active={this.state.exportModalOpen}
            onClose={() => this.setState({ exportModalOpen: false })}
            supportedFileTypes={ExportFiletypesBoth}
            exportOptions={{
              vendor_id: this.props.vendorId,
            }}
            vendorId={this.props.vendorId}
          />
          <SlidePanel
            active={this.state.filterPanelOpen}
            title="Filter by"
            newStyles
            dimContent
            onClose={this.onToggleFilterPanel}
          >
            <FilterPanelContainer
              closePanel={this.onToggleFilterPanel}
              supportedFilters={supportedFilters}
              vendorId={this.props.vendorId}
              startAllExpanded={!!this.props.vendorId}
            />
          </SlidePanel>
        </div>
      </div>
    );
  }
}

export default wrapInVendorOverlay(
  appConnect<
    QuestionnairesConnectedProps,
    never,
    VendorOverlayInjectedProps<never, QuestionnairesLocationState>
  >((state, props) => {
    const userSystemRoles = state.common.userData.system_roles.reduce(
      (prev: Record<string, boolean>, perm) => {
        prev[perm] = true;
        return prev;
      },
      {}
    );

    const userIsManagedVendorAnalyst =
      !!userSystemRoles[UserSystemRoleVendorManagementAnalyst];

    const isSubsidiary = props.match.path.startsWith("/subsidiaries") ?? false;

    const isManagedVendorAnalyst =
      (userIsManagedVendorAnalyst &&
        props.isManagementAnalystSession &&
        (props.managedOrgId || 0) > 0) ??
      false;

    const currentOrg = getCurrentOrgFromUserData(
      state.common.userData
    ) as IUserOrganisation | null;
    let data: any;
    if (isManagedVendorAnalyst) {
      const managedVendorData =
        state.cyberRisk.managedVendorData[props.managedOrgId || 0];
      data =
        managedVendorData && managedVendorData[props.vendorId || 0]
          ? managedVendorData[props.vendorId || 0]
          : {};
    } else if (props.vendorId) {
      data = state.cyberRisk.vendors[props.vendorId];
    } else {
      data = state.cyberRisk.customerData;
    }

    let surveyData: SurveyListData | undefined;
    let resendDue: SurveyListV2RespItem[] | undefined;
    let filters: Filters = getDefaultFilters();
    if (isManagedVendorAnalyst && props.managedOrgId && props.vendorId) {
      const data =
        state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId];
      if (data?.surveyList) {
        surveyData = data?.surveyList;
      }
      resendDue = data.resendDueSurveys;
      filters = data.filters;
    } else if (props.vendorId) {
      const data = state.cyberRisk.vendors[props.vendorId];
      if (data?.surveyList) {
        surveyData = data?.surveyList;
      }
      if (data) {
        filters = data.filters;
        resendDue = data.resendDueSurveys;
      }
    } else {
      const data = state.cyberRisk.customerData;
      if (data?.surveyList) {
        surveyData = data?.surveyList;
      }
      resendDue = data.resendDueSurveys;
      filters = state.cyberRisk.customerData.filters;
    }

    const mayHaveSharedAssets =
      !!props.vendorVerified ||
      (!!currentOrg?.organisationGroupId &&
        !!currentOrg.organisationGroupEntitlements &&
        currentOrg.organisationGroupEntitlements.includes(
          GroupAccessVendorAssetSharing
        ));

    const orgHasVendorPortfolioPermission =
      state.common.userData.orgPermissions.includes(OrgAccessVendorPortfolios);
    const orgHasFourthPartyPermission =
      state.common.userData.orgPermissions.includes(
        OrgAccessVendorTechnologies
      );

    const isVendor = !!props.vendorId && !isSubsidiary;
    const userPerms = getUserPermissionsForVendorFromState(
      state,
      isVendor ? props.vendorId || 0 : 0
    );
    let userHasWriteVendorInfoPermission =
      (isSubsidiary && !!userPerms[UserBreachsightWrite]) ||
      (!isSubsidiary && !!userPerms[UserVendorRiskWrite]);

    if (
      !props.vendorId &&
      !userHasWriteVendorInfoPermission &&
      orgHasVendorPortfolioPermission
    ) {
      // Looking at the overall qns list, we should also check if the user has write access to ANY portfolios
      userHasWriteVendorInfoPermission = !!Object.values(
        state.common.userData.vendorPortfolioSpecificPermissions
      ).find((perms) => perms?.includes(UserVendorRiskWrite));
    }

    const c: QuestionnairesConnectedProps = {
      surveys: surveyData?.result ?? [],
      resendDueSurveys: resendDue,
      offset: surveyData?.offset ?? 0,
      limit: surveyData?.limit ?? surveyListPageLimit,
      totalResults: surveyData?.totalResults ?? 0,
      sortCol: surveyData?.sortCol ?? surveyDefaultSortCol,
      sortDir: surveyData?.sortDir ?? surveyDefaultSortDir,
      loading: surveyData?.loading ?? true,
      error: surveyData?.error ?? false,
      archived: surveyData?.archived ?? false,
      filterText: surveyData?.filterText ?? "",
      surveyTypes: state.cyberRisk.customerData.surveyTypes,
      userHasWriteVendorInfoPermission,
      filters,
      assuranceType: state.common.userData.assuranceType,
      totalCloudscansOverExportMax: false, // TODO remove
      exportMaxCloudscans: 0, // TODO remove
      userIsManagedVendorAnalyst:
        !!userSystemRoles[UserSystemRoleVendorManagementAnalyst],
      mayHaveSharedAssets,
      surveyCounts: _get(data, "surveyCounts", {} as SurveyCounts),
      orgHasVendorPortfolioPermission,
      orgHasFourthPartyPermission: orgHasFourthPartyPermission,
    };

    return c;
  })(Questionnaires)
);

import { IAttachment, IAttachmentGroup } from "../../types/attachment";
import ReportCard from "../ReportCard";

import "../../style/components/SurveyDetailsDocumentsCard.scss";
import XTable, {
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../core/XTable";
import { usePagination, useSorting } from "../../hooks";
import { separateQuestionNumberFromQuestion } from "./SurveyDetailsUnansweredQuestionsCard";
import NodeTypeIcon, {
  NodeTypeIconType,
} from "../../../survey_builder/components/NodeTypeIcon";
import moment from "moment/moment";
import { UserAvatarAndName } from "../UserAvatar";
import { getAuthFromLocalStorage } from "../../reducers/commonActions";
import { useAppDispatch } from "../../types/reduxHooks";
import { downloadContentLibraryDocumentFile } from "../../../contentlibrary/api/downloadDocument";

export const openAttachmentUrlFunc =
  (
    gcsObjectName: string,
    attachmentName: string,
    surveyId: number,
    isPrefilledSurvey = false,
    isManagementAnalystSession = false,
    managedOrgId = 0
  ) =>
  () => {
    const auth = getAuthFromLocalStorage(
      isManagementAnalystSession ? { tpvm: true, tpvm_o: managedOrgId } : null
    );

    let url = "/api/survey/attachment/v1/";
    if (isPrefilledSurvey) {
      url = "/api/prefilledsurvey/attachment/v1/";
    }
    url =
      url +
      `?object=${encodeURIComponent(
        gcsObjectName
      )}&attachment_name=${encodeURIComponent(
        attachmentName
      )}&survey_id=${encodeURIComponent(surveyId)}&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}`;

    window.open(url);
  };

export interface ISurveyDetailsDocumentsCardProps {
  attachmentGroups: IAttachmentGroup[];
  isManagementAnalystSession: boolean;
  managedOrgId: number;
}

const tableColumnHeaders: IXTableColumnHeader[] = [
  {
    id: "question",
    text: "Question",
    sortable: true,
  },
  {
    id: "desc",
    text: "Description",
    sortable: false,
  },
  { id: "name", text: "Name", sortable: true },
  { id: "uploaded", text: "Uploaded", sortable: true },
  { id: "addedby", text: " Added by", sortable: true },
];

const SurveyDetailsDocumentsCard = (
  props: ISurveyDetailsDocumentsCardProps
) => {
  const dispatch = useAppDispatch();
  const attachments = getAttachmentsFromGroups(props.attachmentGroups);

  const [rows, sortedBy, onSortChange] = useSorting<
    IAttachment,
    "question" | "name" | "uploaded" | "addedby"
  >(attachments, "question", SortDirection.ASC, {
    question: {
      orderFuncs: [
        (a) => a.questionText,
        (a) => a.contentLibraryName ?? a.filename,
      ],
      sortDirsAsc: ["asc", "asc"],
      sortDirsDesc: ["desc", "asc"],
    },
    name: {
      orderFuncs: [(a) => a.contentLibraryName ?? a.filename],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    uploaded: {
      orderFuncs: [(a) => new Date(a.createdAt).valueOf()],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    addedby: {
      orderFuncs: [
        (a) => a.uploadedByName,
        (a) => a.questionText,
        (a) => a.contentLibraryName ?? a.filename,
      ],
      sortDirsAsc: ["asc", "asc", "asc"],
      sortDirsDesc: ["desc", "asc", "asc"],
    },
  });

  const [currentPage, currentPageNumber, totalPages, setCurrentPage] =
    usePagination(rows, 10);

  return (
    <ReportCard newStyles className="survey-details-documents-card">
      <div className="header">Documents</div>
      <XTable
        columnHeaders={tableColumnHeaders}
        rows={currentPage.map((a) => {
          const [questionNumber, questionText] = a.questionNumber
            ? [a.questionNumber, a.questionText || ""]
            : separateQuestionNumberFromQuestion(a.questionText || "");

          const hasDescription = a.description && a.description.length > 0;

          return {
            id: a.id,
            onClick: a.contentLibraryUUID
              ? () => {
                  dispatch(
                    downloadContentLibraryDocumentFile(
                      a.contentLibraryUUID!,
                      a.contentLibraryVersion ?? 0,
                      a.surveyId,
                      false
                    )
                  );
                }
              : openAttachmentUrlFunc(
                  a.gcsObjectName,
                  a.attachmentName || "",
                  a.surveyId,
                  false,
                  props.isManagementAnalystSession,
                  props.managedOrgId
                ),
            cells: [
              <XTableCell
                key="question"
                className="ellipsis-cell question-cell"
              >
                <div>
                  {questionNumber && (
                    <NodeTypeIcon
                      nodeType={NodeTypeIconType.Upload}
                      questionNumber={questionNumber}
                    />
                  )}
                  <div
                    className="question-text"
                    dangerouslySetInnerHTML={{ __html: questionText }}
                  />
                </div>
              </XTableCell>,
              <XTableCell
                key="desc"
                className={
                  hasDescription
                    ? "ellipsis-cell description-cell"
                    : "shrink-cell"
                }
              >
                <div title={a.description}>
                  {hasDescription ? a.description : "-"}
                </div>
              </XTableCell>,
              <XTableCell
                key="filename"
                className="ellipsis-cell filename-cell"
              >
                <div title={a.contentLibraryName ?? a.filename}>
                  {a.contentLibraryName ?? a.filename}
                </div>
              </XTableCell>,
              <XTableCell key="uploaded" className="shrink-cell">
                {moment(a.createdAt).format("LLL")}
              </XTableCell>,
              <XTableCell key="addedby" className="shrink-cell">
                <UserAvatarAndName
                  avatar={a.uploadedByAvatar}
                  name={a.uploadedByName}
                />
              </XTableCell>,
            ],
          };
        })}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        pagination={{
          currentPage: currentPageNumber,
          totalPages,
          onPageChange: setCurrentPage,
          hidePaginationIfSinglePage: true,
        }}
      />
    </ReportCard>
  );
};

export const getAttachmentsFromGroups = (
  attachmentGroups: IAttachmentGroup[] | undefined
) => {
  if (!attachmentGroups) {
    return [];
  }

  return attachmentGroups.reduce((prev: IAttachment[], next) => {
    if (next.attachments) {
      for (let i = 0; i < next.attachments.length; i++) {
        const a = {
          ...next.attachments[i],
          // Fill in the question text from the attachment group name if this wasn't
          // for an upload question
          questionText: next.attachments[i].questionText || next.friendlyName,
        };
        prev.push(a);
      }
    }
    return prev;
  }, []);
};

export default SurveyDetailsDocumentsCard;

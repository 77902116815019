import {
  ISurveyListItemResponse,
  SurveyRiskVisibility,
} from "../../_common/types/survey";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { useEffect, useMemo } from "react";
import { fetchVendorAppSurveyList } from "../../vendor_portal/reducers/surveys.actions";

export default function useReceivedSurveys(
  vendorId?: number
): ISurveyListItemResponse[] {
  const dispatch = useAppDispatch();
  const vendorAppSurveys = useAppSelector(
    (state) => state.vendorPortal.vendorAppSurveys
  );

  const surveysState = useAppSelector((state) => state.common.surveys);

  useEffect(() => {
    if (vendorAppSurveys.surveyCounts === undefined) {
      dispatch(fetchVendorAppSurveyList(false, true));
    }
  }, [vendorAppSurveys, dispatch]);

  return useMemo(() => {
    const receivedSurveys: ISurveyListItemResponse[] = [];

    if (!vendorAppSurveys?.surveyCounts || !vendorId) {
      return receivedSurveys;
    }

    const surveyCounts =
      vendorAppSurveys.surveyCounts[SurveyUsageType.Security];

    if (surveyCounts?.surveyIds) {
      for (let i = 0; i < surveyCounts.surveyIds.length; i++) {
        const surveyId = surveyCounts.surveyIds[i];
        const survey = surveysState[surveyId]?.survey;
        if (
          survey &&
          survey.vendorId === vendorId &&
          survey.isSystemSurvey &&
          survey.riskVisibility === SurveyRiskVisibility.Visible
        ) {
          receivedSurveys.push(survey);
        }
      }
    }
    return receivedSurveys;
  }, [vendorId, vendorAppSurveys, surveysState]);
}

import PageHeader from "../../_common/components/PageHeader";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useBack } from "../../_common/types/router";
import ReportCard from "../../_common/components/ReportCard";
import OrgScoresChart from "../components/OrgScoresChart";

import OrgAppsOverview from "../components/dashboard/OrgAppsOverview";

import "./DashboardView.scss";
import OrgScopeCategoriesOverview from "../components/dashboard/OrgScopeCategoriesOverview";
import AboutUserDirectory from "../components/dashboard/AboutUserDirectory";
import DashboardStat, {
  DashboardStatToShow,
} from "../components/stats/DashboardStat";
import OrgAppCategoriesOverview from "../components/dashboard/OrgAppCategoriesOverview";
import RecentlyDetectedApps from "../components/dashboard/RecentlyDetectedApps";
import Button from "../../_common/components/core/Button";
import { useNavigateWithUserRiskFilters } from "../helpers/hooks";
import {
  applicationsUrl,
  permissionsUrl,
  usersUrl,
} from "../UserBaseAppRouter";

import { supportedFilters as appFilters } from "../views/applications/ApplicationsView";
import { supportedFilters as permissionFilters } from "../views/permissions/PermissionsView";
import { supportedFilters as userFilters } from "../views/permissions/PermissionsView";
import OrgUsersGradeCounts from "../components/dashboard/OrgUsersGradeCounts";

interface ApplicationsViewProps {}

const DashboardView: FC<ApplicationsViewProps> = ({}) => {
  const { backAction, backText } = useBack();
  const history = useHistory();

  const navToApps = useNavigateWithUserRiskFilters(appFilters);
  const navToPermissions = useNavigateWithUserRiskFilters(permissionFilters);
  const navToUsers = useNavigateWithUserRiskFilters(userFilters);

  return (
    <div className="userbase-dashboard">
      <PageHeader
        history={history}
        title="Dashboard"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey="infoSection_userBaseDashboard"
        infoSection={
          <>
            <p>
              The dashboard provides an overview of the cybersecurity posture of
              your users.
            </p>
          </>
        }
      />

      <div className="three-grid no-stretch">
        <ReportCard>
          <DashboardStat statToShow={DashboardStatToShow.UserCount} />
        </ReportCard>
        <ReportCard>
          <DashboardStat statToShow={DashboardStatToShow.AppCount} />
        </ReportCard>
        <ReportCard>
          <DashboardStat statToShow={DashboardStatToShow.PermissionCount} />
        </ReportCard>
      </div>

      <OrgScoresChart />

      <div className="two-grid no-stretch">
        {/* First column */}
        <div>
          <ReportCard newStyles>
            <div className="header">
              <span>Users</span>
              <div>
                <Button
                  tertiary
                  onClick={() => navToUsers(usersUrl, "Back to Dashboard")}
                >
                  View all <div className="cr-icon-arrow-right" />
                </Button>
              </div>
            </div>
            <OrgUsersGradeCounts />
            <AboutUserDirectory />
          </ReportCard>

          <ReportCard newStyles>
            <div className="header">
              <span>Permissions</span>
              <div>
                <Button
                  tertiary
                  onClick={() =>
                    navToPermissions(permissionsUrl, "Back to Dashboard")
                  }
                >
                  View all <div className="cr-icon-arrow-right" />
                </Button>
              </div>
            </div>
            <OrgScopeCategoriesOverview />
          </ReportCard>
        </div>

        {/* Second column */}
        <div>
          <ReportCard newStyles>
            <div className="header">
              <span>Apps</span>
              <div>
                <Button
                  tertiary
                  onClick={() =>
                    navToApps(applicationsUrl, "Back to Dashboard")
                  }
                >
                  View all <div className="cr-icon-arrow-right" />
                </Button>
              </div>
            </div>
            <OrgAppsOverview />
            <RecentlyDetectedApps />
            <OrgAppCategoriesOverview />
          </ReportCard>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;

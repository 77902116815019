import { useState } from "react";
import classnames from "classnames";
import Button from "../../../_common/components/core/Button";
import ColorGrade from "../executive_summary/ColorGrade";
import Score from "../Score";
import Icon from "../../../_common/components/core/Icon";
import ReportCard from "../../../_common/components/ReportCard";
import { ISharedAssessment } from "../../types/sharedAssessment";
import "../../style/components/SharedProfileAboutCard.scss";
import { ShareProfileLogo } from "./SharedProfileLogo";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

interface ISharedProfileAboutCardProps {
  sharedAssessment: ISharedAssessment;
  isOwnSharedProfile: boolean;
  userHasWritePrefilledSurveysPermission: boolean;
  onCustomiseClick?: () => void;
}

export const SharedProfileAboutCard = ({
  sharedAssessment,
  isOwnSharedProfile,
  userHasWritePrefilledSurveysPermission,
  onCustomiseClick,
}: ISharedProfileAboutCardProps): JSX.Element => {
  const [ratingCollapsed, setRatingCollapsed] = useState(false);

  const userCanMakeChanges =
    isOwnSharedProfile && userHasWritePrefilledSurveysPermission;
  const includeScores = sharedAssessment.includeRiskRating;

  // check whether we have anything to show at all
  const shouldShowAboutCard =
    isOwnSharedProfile ||
    includeScores ||
    sharedAssessment.contactName ||
    sharedAssessment.contactEmail ||
    sharedAssessment.companyDescription;
  if (!shouldShowAboutCard) return <></>;

  return (
    <ReportCard newStyles className={"shared-profile-about-card"}>
      <div className="header">
        <ShareProfileLogo
          sharedAssessment={sharedAssessment}
          displayName={false}
        />
        <div
          className="header-company-name"
          title={sharedAssessment.linkedVendor.name}
        >
          About {sharedAssessment.linkedVendor.name}
        </div>
        {isOwnSharedProfile &&
          userHasWritePrefilledSurveysPermission &&
          onCustomiseClick && (
            <div className="header-right">
              <Button onClick={onCustomiseClick}>
                <div className="cr-icon-pencil" />
                Customize page
              </Button>
            </div>
          )}
      </div>
      <div>
        {(isOwnSharedProfile || includeScores) && (
          <div
            className={classnames("score-card", {
              greyed: !includeScores,
            })}
          >
            <div className="two-scores">
              {(isOwnSharedProfile || sharedAssessment.includeRiskRating) &&
                sharedAssessment.currentScore && (
                  <div>
                    <div className="score-subheader">
                      Current security rating
                      {sharedAssessment.currentPublicScore !== undefined &&
                        sharedAssessment.currentPublicScore -
                          sharedAssessment.currentScore !==
                          0 && (
                          <SidePopupV2
                            text={
                              <div>
                                <div>
                                  This rating includes public risk waivers
                                  adjusting the automated scanning score by{" "}
                                  {sharedAssessment.currentPublicScore -
                                    sharedAssessment.currentScore}{" "}
                                  points
                                </div>
                              </div>
                            }
                          >
                            <div className={"cr-icon-info"} />
                          </SidePopupV2>
                        )}
                    </div>
                    <div className="score-inner">
                      <ColorGrade
                        score={
                          sharedAssessment.currentPublicScore ??
                          sharedAssessment.currentScore
                        }
                      />
                      <Score
                        score={
                          sharedAssessment.currentPublicScore ??
                          sharedAssessment.currentScore
                        }
                        outOf={950}
                        colored
                      />
                    </div>
                  </div>
                )}
              {(isOwnSharedProfile ||
                sharedAssessment.includeIndustryAverage) &&
                sharedAssessment.industryAverage && (
                  <div
                    className={classnames({
                      greyed:
                        !sharedAssessment.includeIndustryAverage &&
                        includeScores, // avoid greying out the industry average element twice if the whole score-card is already greyed out
                    })}
                  >
                    <div className="score-subheader">
                      Industry average
                      <SidePopupV2
                        text={
                          <>
                            {sharedAssessment.industryAverage.industrySector} -{" "}
                            {sharedAssessment.industryAverage.industryGroup}
                          </>
                        }
                      >
                        <div className="cr-icon-help" />
                      </SidePopupV2>
                    </div>
                    <div className="score-inner">
                      <ColorGrade
                        score={sharedAssessment.industryAverage.score}
                      />
                      <Score
                        score={sharedAssessment.industryAverage.score}
                        outOf={950}
                        colored
                      />
                    </div>
                  </div>
                )}
            </div>
            <div
              className="collapsible-header"
              onClick={() => setRatingCollapsed(!ratingCollapsed)}
            >
              What are security ratings?
              <Icon name="chevron" direction={ratingCollapsed ? 90 : 180} />
            </div>
            {!ratingCollapsed && (
              <>
                <div className="collapsible-text">
                  UpGuard’s security ratings are based on the analysis of
                  hundreds of individual checks across ten risk categories:
                  Website, IP/Domain Reputation, Encryption, Vulnerability
                  Management, Attack Surface, Network, Email, Data Leakage, DNS,
                  and Brand Reputation . The resultant score provides a
                  data-driven, objective, and dynamic measurement of{" "}
                  {sharedAssessment.linkedVendor.name}’s cybersecurity
                  performance. The higher the rating, the better the security
                  posture.
                </div>
                <div className="collapsible-text">
                  As we rely on externally verifiable information, we can update
                  our security rating multiple times a day. This ensures they
                  remain an accurate and useful representation of an
                  organization’s security posture.{" "}
                  <a
                    href="https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </div>
              </>
            )}
          </div>
        )}
        <div className={"overview"}>
          {((sharedAssessment.contactName && sharedAssessment.contactEmail) ||
            userCanMakeChanges) && (
            <div className={"contact"}>
              <div className={"overview-header"}>Contact</div>
              <div className={"overview-content"}>
                {sharedAssessment.contactName &&
                sharedAssessment.contactEmail ? (
                  <>
                    {sharedAssessment.contactName}
                    <br />
                    <a href={`mailto:${sharedAssessment.contactEmail}`}>
                      {sharedAssessment.contactEmail}
                    </a>
                  </>
                ) : (
                  <div className="set-data-link" onClick={onCustomiseClick}>
                    Add a security contact
                  </div>
                )}
              </div>
            </div>
          )}
          {(sharedAssessment.companyDescription || userCanMakeChanges) && (
            <div className={"about"}>
              <div className={"overview-header"}>About</div>
              <div className={"overview-content"}>
                {sharedAssessment.companyDescription ? (
                  sharedAssessment.companyDescription
                ) : (
                  <div className="set-data-link" onClick={onCustomiseClick}>
                    Add a company description
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ReportCard>
  );
};

import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import CircledIcon from "../../_common/components/CircledIcon";
import SurveyName from "../../vendorrisk/components/surveys/SurveyName";
import { useSorting } from "../../_common/hooks";
import "../style/QuestionnairesTable.scss";
import classNames from "classnames";
import { formatDateAsLocal } from "../../_common/helpers";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import {
  exportPublicSurveyInNewTab,
  openSurveyDetailsURL,
} from "../helpers/helpers";
import { useDefaultHistory } from "../../_common/types/router";
import { ISurveyRecord } from "../helpers/types";
import { DropdownItem } from "../../_common/components/core/DropdownV2";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { useModalV2 } from "../../_common/components/ModalV2";
import { trackEvent } from "../../_common/tracking";
import EditQuestionnaireModal from "./modals/EditQuestionnaireModal";
import ExcludeQuestionnaireConfirmationModal from "./modals/ExcludeQuestionnaireConfirmationModal";
import { SurveyStatus } from "../../_common/types/survey";

const getQuestionnairesTableHeaders = (): IXTableColumnHeader[] => {
  return [
    {
      id: "questionnaires",
      text: "Questionnaire",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "date_completed",
      text: "Last updated",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
  ];
};

interface IQuestionnairesTableProps {
  surveys: ISurveyRecord[];
  isOwnSharedProfile: boolean;
  userHasWritePrefilledSurveysPermission: boolean;
  onRequestAccess?: () => void;
}

const QuestionnairesTable = ({
  surveys,
  isOwnSharedProfile,
  userHasWritePrefilledSurveysPermission,
  onRequestAccess = () => {},
}: IQuestionnairesTableProps) => {
  const [sortedSurveys, sortedBy, onSortChange] = useSorting<
    ISurveyRecord,
    "questionnaires" | "date_completed"
  >(surveys, "date_completed", SortDirection.DESC, {
    questionnaires: {
      orderFuncs: [(q) => q.name, (q) => q.dateCompleted],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
    date_completed: {
      orderFuncs: [(q) => q.dateCompleted, (q) => q.name],
      sortDirsAsc: ["asc", "asc"],
      sortDirsDesc: ["desc", "asc"],
    },
  });

  const handleDownload = (surveyId: number) => {
    exportPublicSurveyInNewTab(surveyId, false);
    trackEvent("VerifiedVendor_ExportQuestionnairePDF");
  };

  const history = useDefaultHistory();
  const [openEditQuestionnaireModal, editQuestionnaireModal] = useModalV2(
    EditQuestionnaireModal
  );
  const [
    openExcludeQuestionnaireConfirmationModal,
    excludeQuestionnaireConfirmationModal,
  ] = useModalV2(ExcludeQuestionnaireConfirmationModal);

  const handleEditQuestionnaire = (survey: ISurveyRecord) => {
    trackEvent("VerifiedVendor_EditQuestionnaireDetails");
    openEditQuestionnaireModal({
      surveyId: survey.id,
      originalSurveyName: survey.name ?? "",
      originalSurveyDescription: survey.description,
    });
  };

  const handleExcludeQuestionnaire = (survey: ISurveyRecord) => {
    trackEvent("VerifiedVendor_TurnOffIncludeInSharedProfile");
    if (survey.kind === "private") {
      // mark original private survey as not shared
      openExcludeQuestionnaireConfirmationModal({
        originalSurveyId: survey.id,
      });
    } else {
      // exclude existing prefilled survey from Trust Page
      openExcludeQuestionnaireConfirmationModal({
        prefilledSurveyId: survey.id,
        originalSurveyId: survey.originalSurveyId,
      });
    }
  };

  if (surveys.length === 0) {
    return (
      <EmptyCardWithAction
        iconJSX={<CircledIcon iconClass={"cr-icon-questionnaire"} />}
        emptyText={"No published questionnaires"}
        emptySubText={
          "Save time and reduce requests by publishing a security questionnaire on your page."
        }
      />
    );
  }

  const rows: IXTableRow[] = [];

  for (let i = 0; i < sortedSurveys.length; i++) {
    const q = sortedSurveys[i];
    // private survey is not completed, only users who have access to private survey can view it
    const privateSurveyHasAccess =
      q.kind === "private" && q.userCanViewOriginalSurvey;

    const iconOptions: IIconOption[] = [];

    let viewSurveyAction;

    if (isOwnSharedProfile) {
      if (q.kind === "prefilled" && userHasWritePrefilledSurveysPermission) {
        viewSurveyAction = () => {
          history.push(`/trustpage/surveys/${q.id}`, {
            backContext: {
              backTo: "/trustpage",
              backToText: "Back to Trust Page",
            },
          });
        };
      }

      if (privateSurveyHasAccess) {
        viewSurveyAction = () => {
          history.push(`/vendors/surveys/${q.id}`, {
            backContext: {
              backTo: "/trustpage",
              backToText: "Back to Trust Page",
            },
          });
        };
      }
    } else {
      if (q.userHasAccess) {
        viewSurveyAction = () =>
          openSurveyDetailsURL({
            surveyId: q.id,
            vendorId: q.vendorId,
            history,
          });
      }
    }

    const dropdownItems = [];

    if (isOwnSharedProfile) {
      const userCanExcludeSurvey =
        (q.status === SurveyStatus.Published &&
          userHasWritePrefilledSurveysPermission) ||
        (privateSurveyHasAccess && userHasWritePrefilledSurveysPermission);
      const userCanViewSurvey = viewSurveyAction !== undefined;
      const userCanEditSurveyDetails =
        q.status === SurveyStatus.Published &&
        userHasWritePrefilledSurveysPermission;
      const userCanDownloadSurvey = q.status === SurveyStatus.Published;

      if (userCanExcludeSurvey) {
        dropdownItems.push(
          <DropdownItem
            key="exclude"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={() => handleExcludeQuestionnaire(q)}
          >
            <div className="action-label">
              <i className="cr-icon-minus-circle" />
              <div>Exclude</div>
            </div>
          </DropdownItem>
        );
      }
      if (userCanViewSurvey) {
        dropdownItems.push(
          <DropdownItem
            key="view"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={viewSurveyAction}
          >
            <div className="action-label">
              <i className="cr-icon-eye" />
              <div>View</div>
            </div>
          </DropdownItem>
        );
      }
      if (userCanEditSurveyDetails) {
        dropdownItems.push(
          <DropdownItem
            key="edit"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={() => handleEditQuestionnaire(q)}
          >
            <div className="action-label">
              <i className="cr-icon-pencil-2" />
              <div>Edit</div>
            </div>
          </DropdownItem>
        );
      }
      if (userCanDownloadSurvey) {
        dropdownItems.push(
          <DropdownItem
            key="export"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={() => handleDownload(q.id)}
          >
            <div className="action-label">
              <i className="cr-icon-export" />
              <div>Download</div>
            </div>
          </DropdownItem>
        );
      }
    } else {
      if (viewSurveyAction) {
        dropdownItems.push(
          <DropdownItem
            key="view"
            stopPropagation
            className="dropdown-action-meatball"
            onClick={viewSurveyAction}
          >
            <div className="action-label">
              <i className="cr-icon-eye" />
              <div>View</div>
            </div>
          </DropdownItem>
        );
      }
    }

    if (isOwnSharedProfile) {
      if (dropdownItems.length > 0) {
        iconOptions.push({
          id: "meatball",
          icon: <div className={"cr-icon-dots-menu"} />,
          disabled: false,
          dropdownItems: [dropdownItems],
        });
      }
    } else if (!q.userHasAccess) {
      iconOptions.push({
        id: "request-access",
        icon: <div className="cr-icon-lock" />,
        hoverText: "Request access",
        onClick: onRequestAccess,
      });
    }

    const rowClick = viewSurveyAction;

    const cells = [
      <XTableCell key="questionnaire" className="questionnaire">
        <div className="q-cell">
          <CircledIcon iconClass="cr-icon-questionnaire" />
          <div className={"q-cell-main"}>
            <div className="cell-top-content">
              <SurveyName survey={q} />
            </div>
            {q.description ? (
              <div className="qn-description">{q.description}</div>
            ) : null}
          </div>
        </div>
      </XTableCell>,
      <XTableCell key="date_completed" className="q-cell-date">
        {q.dateCompleted ? (
          <div className="cell-top-content">
            {formatDateAsLocal(q.dateCompleted)}
          </div>
        ) : (
          <PillLabel color={LabelColor.Orange}>Awaiting completion</PillLabel>
        )}
      </XTableCell>,
    ];

    const rowId = `survey${q.id}`;
    rows.push({
      id: rowId,
      className: classNames("q-row", { clickable: rowClick }),
      onClick: rowClick,
      iconOptions,
      expandDisabled: true,
      cells: cells,
    });
  }

  return (
    <>
      {isOwnSharedProfile && (
        <>
          {editQuestionnaireModal}
          {excludeQuestionnaireConfirmationModal}
        </>
      )}
      <XTable
        iconOptions
        className="trust-page-questionnaire-table"
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        columnHeaders={getQuestionnairesTableHeaders()}
        rows={rows}
      />
    </>
  );
};

export default QuestionnairesTable;

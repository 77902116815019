import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import Button from "../../../_common/components/core/Button";
import Modal from "../../../_common/components/ModalV2";
import { useEffect, useState } from "react";
import ModalForm from "../modals/ModalForm";
import { LabelColor } from "../../../_common/types/label";
import TextAreaWithPills, {
  IToken,
} from "../../../_common/components/TextAreaWithPills";
import "../../style/components/ShareSharedProfileModal.scss";
import { validateEmail } from "../../../_common/helpers";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { handleKnownErrors } from "../../reducers/errors.actions";
import { BadgeInfo, ScoreBadgeTheme } from "../../types/scoreBadge";
import { getScoreBadgeInfo } from "../../reducers/verifiedVendors.actions";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import TabButtons from "../../../_common/components/TabButtons";
import classnames from "classnames";
import { scoreBadgeHelpArticleUrl } from "../../../_common/components/ScoreBadgeModal";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../../verifiedvendors/api/trustpage.api";
import TrustPageCustomDomainsAPI from "../../../verifiedvendors/api/trustPageCustomDomains.api";
import {
  OrgTrustPageCustomDomain,
  useHasOrgEntitlement,
} from "../../../_common/permissions";

type ShareSharedProfileTabId = "link" | "badge" | "email";

interface ShareSharedProfileModalProps {
  active: boolean;
  onClose: () => void;

  uuid?: string;
  usersMustRequestAccess: boolean;
}

const clipboardAPISupported = !!navigator.clipboard;

const ShareSharedProfileModal = (
  props: ShareSharedProfileModalProps & DefaultThunkDispatchProp
) => {
  const hasCustomTrustPageFeature = useHasOrgEntitlement(
    OrgTrustPageCustomDomain
  );

  const [inviteUserToTrustPage] =
    TrustPageAPI.useInviteUserToTrustPageV1Mutation();
  const [getTrustPageCustomDomainsV1Query] =
    TrustPageCustomDomainsAPI.useLazyGetTrustPageCustomDomainsV1Query();

  const [activeTabId, setActiveTabId] =
    useState<ShareSharedProfileTabId>("link");
  const [isSaving, setIsSaving] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [badges, setBadges] = useState<BadgeInfo[]>([]);
  const [chosenTheme, setChosenTheme] = useState<ScoreBadgeTheme | undefined>(
    undefined
  );
  const [hasCopied, setHasCopied] = useState(false);
  const [customDomain, setCustomDomain] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setIsLoading(true);
    props
      .dispatch(getScoreBadgeInfo())
      .then((resp) => {
        setBadges(resp);
        if (resp && resp.length) {
          setChosenTheme(resp[0].theme);
        }
      })
      .catch(() =>
        props.dispatch(
          addDefaultUnknownErrorAlert("Failed to get fetch badge information")
        )
      )
      .finally(() => setIsLoading(false));

    if (hasCustomTrustPageFeature) {
      getTrustPageCustomDomainsV1Query()
        .unwrap()
        .then((resp) => {
          let domain: string | undefined = undefined;

          resp.domains?.forEach((d) => {
            if (d.status === "complete" && (!domain || d.isPrimary)) {
              domain = d.domain;
            }
          });

          setCustomDomain(domain);
        });
    }
  }, []);

  useEffect(() => {
    if (props.active) {
      setEmails([]);
    }
  }, [props.active]);

  const save = () => {
    setIsSaving(true);

    try {
      emails.map((email) =>
        inviteUserToTrustPage({
          email: email.trim().toLowerCase(),
          noRefresh: true,
        })
      );
      props.dispatch(
        TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPageAccess])
      );
      props.dispatch(
        addDefaultSuccessAlert("Successfully invited new viewers")
      );
      setIsSaving(false);
      props.onClose();
    } catch (e) {
      if (!props.dispatch(handleKnownErrors(e))) {
        props.dispatch(
          addDefaultUnknownErrorAlert("Error inviting new viewers")
        );
      }
      setIsSaving(false);
    }
  };

  const newEmails = (val: string[]) => {
    setEmails([...emails, ...val]);
  };

  const removedEmail = (idx: number) => {
    const newEmails = [...emails];
    newEmails.splice(idx, 1);
    setEmails(newEmails);
  };

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  };

  const basePath =
    window.PUBLIC_TRUST_PAGE_HOSTNAME ||
    `${window.location.host}/trustpagepreview`;

  const queryParam = window.PUBLIC_TRUST_PAGE_HOSTNAME ? "" : "?preview=true";

  let publicLink = `${window.location.protocol}//${basePath}/${props.uuid}${queryParam}`;
  if (customDomain) {
    publicLink = `${window.location.protocol}//${customDomain}`;
  }

  let isEmailsValid = true;
  const tokens = emails.reduce<IToken[]>((acc, email) => {
    const isValid =
      validateEmail(email) &&
      acc.findIndex((token) => token.value === email) === -1;
    let errMsg = "";
    if (!validateEmail(email)) {
      errMsg = "Invalid email address";
    } else if (acc.findIndex((token) => token.value === email) !== -1) {
      errMsg = "Duplicate address";
    }

    isEmailsValid = isEmailsValid && isValid;

    return [
      ...acc,
      {
        value: email,
        color: isValid ? LabelColor.Blue : LabelColor.Red,
        popupMsg: errMsg,
      },
    ];
  }, []);

  if (emails.length === 0) {
    isEmailsValid = false;
  }

  const badgeBase64Content = badges.find((badge) => badge.theme === chosenTheme)
    ?.base64Data;
  const snippet = badges.find((badge) => badge.theme === chosenTheme)?.snippet;

  return (
    <Modal
      headerContent={`Share your page`}
      className={"share-shared-profile-modal"}
      active={props.active}
      onClose={props.onClose}
      footerClassName={"share-shared-profile-modal-footer"}
      footerContent={
        <div className={"btn-group"}>
          <Button tertiary onClick={() => props.onClose()} disabled={isSaving}>
            Cancel
          </Button>
          {activeTabId === "link" && clipboardAPISupported && publicLink && (
            <Button primary onClick={() => onCopy(publicLink)}>
              {hasCopied ? (
                <>
                  {" "}
                  <div className="cr-icon-check" /> Copied
                </>
              ) : (
                <>
                  {" "}
                  <div className="cr-icon-copy" /> Copy link
                </>
              )}
            </Button>
          )}
          {activeTabId === "badge" && clipboardAPISupported && snippet && (
            <Button
              primary
              className={"copy-button"}
              onClick={() => onCopy(snippet)}
              loading={isLoading}
              disabled={isLoading}
            >
              {hasCopied ? (
                <>
                  {" "}
                  <div className="cr-icon-check" /> Copied
                </>
              ) : (
                <>
                  {" "}
                  <div className="cr-icon-copy" /> Copy code
                </>
              )}
            </Button>
          )}
          {activeTabId === "email" && (
            <Button
              primary
              onClick={save}
              loading={isSaving}
              disabled={isSaving || !isEmailsValid}
            >
              <div className="cr-icon-message" />
              Send invites
            </Button>
          )}
        </div>
      }
    >
      <ModalForm>
        <TabButtons
          onChangeTab={(tabId) => setActiveTabId(tabId)}
          tabs={[
            { id: "link", text: "Link to your page" },
            { id: "badge", text: "Share your rating" },
            { id: "email", text: "Share by email" },
          ]}
          activeTabId={activeTabId}
        />
        {activeTabId === "link" && (
          <>
            <p>
              Embed this link on your website or email signature to link to your
              Trust Page. Customers will be prompted to create a free UpGuard
              account for their organisation.{" "}
              {props.usersMustRequestAccess
                ? "They will only be able to view your Trust Page if you grant them access."
                : ""}
            </p>
            <pre>{publicLink}</pre>
            <div className={"actions"}>
              <a
                className={"btn btn-default"}
                target={"_blank"}
                rel="noreferrer"
                href={`${publicLink}`}
              >
                View public page
              </a>
            </div>
          </>
        )}
        {activeTabId === "badge" && (
          <>
            <p className={"score-badge-description"}>
              Embed this code on your website to display your rating. Visit our{" "}
              <a
                href={scoreBadgeHelpArticleUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                support article
              </a>{" "}
              to read our recommendations for embedding this badge on your
              website.
            </p>
            {isLoading && <LoadingIcon />}
            {!isLoading && badges && (
              <div className={"score-badge-content"}>
                <div className={"score-badge-themes"}>
                  <div className={"label"}>Badge color</div>
                  {badges.map((badge) => {
                    return (
                      <div
                        key={badge.theme}
                        className={classnames("theme", badge.theme, {
                          active: badge.theme === chosenTheme,
                        })}
                        onClick={() => setChosenTheme(badge.theme)}
                      ></div>
                    );
                  })}
                </div>
                <div className={"score-badge-preview"}>
                  {badgeBase64Content && (
                    <img
                      src={`data:image/svg+xml;base64,${badgeBase64Content}`}
                      alt="Score badge"
                      width="184"
                      height="138"
                    />
                  )}
                </div>
                <div className={"score-badge-code"}>
                  {snippet && <pre>{snippet}</pre>}
                </div>
              </div>
            )}
          </>
        )}
        {activeTabId === "email" && (
          <>
            <p>Invite access to your Trust Page by email</p>
            <TextAreaWithPills
              onNewTokens={newEmails}
              onRemovedToken={removedEmail}
              tokens={tokens}
              defaultColor={LabelColor.Blue}
              delimiters={[",", " ", "\n"]}
              placeholder={"email@example.com"}
            />
          </>
        )}
      </ModalForm>
    </Modal>
  );
};

export default ShareSharedProfileModal;

import { FC } from "react";
import NavLink from "../_common/components/NavLink";
import { pageDescriptions } from "../_common/pageDescriptions";

interface ThreatMonitoringNavItemsProps {
  pathname: string;
  navLinkClickFunc: (path: string, linkName: string, _?: number) => () => void;
}

export const ThreatMonitoringBreachsightCloudNavItems: FC<
  ThreatMonitoringNavItemsProps
> = ({ pathname, navLinkClickFunc }) => {
  return (
    <>
      <NavLink
        icon="threat-monitoring"
        text="Threat Monitoring"
        onClick={navLinkClickFunc("/threatmonitoring", "Threat Monitoring")}
        to="/threatmonitoring"
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
      {pathname.startsWith("/threatmonitoring") && (
        <>
          <NavLink
            icon="cyberrisk"
            text={"Analytics"}
            isSubMenuItem
            hoverText={pageDescriptions.ThreatMonitoringAnalytics}
            onClick={navLinkClickFunc(
              "/threatmonitoring/analytics",
              "Analytics"
            )}
            to="/threatmonitoring/analytics"
            hasOrgPermission={true}
            hasUserPermission={true}
          />
          <NavLink
            icon="cyberrisk"
            text={"Dark Web"}
            isSubMenuItem
            hoverText={pageDescriptions.ThreatMonitoringDarkWeb}
            onClick={navLinkClickFunc("/threatmonitoring/darkweb", "Dark Web")}
            to="/threatmonitoring/darkweb"
            hasOrgPermission={true}
            hasUserPermission={true}
          />
        </>
      )}
    </>
  );
};

export default ThreatMonitoringBreachsightCloudNavItems;

import DropdownV2, { DropdownItem, DropdownSeparator } from "./core/DropdownV2";
import { FC } from "react";
import { TooltipButton } from "./core/Button";
import classnames from "classnames";
import "../style/components/DropdownButton.scss";

interface DropdownButtonProps {
  title: string;
  disable?: boolean;
  classname?: string;
  itemClassName?: string;
  dropdownPopup?: React.ReactNode;
  autoCloseOnMouseLeave?: boolean;

  items: DropdownItemContentProps[];
}

const DropdownButton: FC<DropdownButtonProps> = ({
  title,
  disable,
  dropdownPopup,
  autoCloseOnMouseLeave,
  classname,
  items,
}) => {
  const nonHiddenItems = items.filter((item) => !item.hide);
  if (nonHiddenItems.length === 0 && !disable) {
    return <></>;
  }

  return (
    <DropdownV2
      stopPropagation
      autoCloseOnMouseLeave={autoCloseOnMouseLeave}
      className={classnames("dropdown-button", classname)}
      popupItem={
        <TooltipButton
          primary
          disabled={disable}
          tooltipContent={dropdownPopup}
        >
          {title}
          <div className="cr-icon-chevron rot90" />
        </TooltipButton>
      }
    >
      {items.map((item) => (
        <DropdownItemContent
          key={item.headerText}
          {...item}
        ></DropdownItemContent>
      ))}
    </DropdownV2>
  );
};

export interface DropdownItemContentProps {
  headerText: string;
  description: string;
  hide?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  icon?: string;
  separator?: boolean;
}

const DropdownItemContent: FC<DropdownItemContentProps> = ({
  headerText,
  description,
  hide,
  onClick,
  disabled,
  className,
  icon,
  separator = false,
}) => {
  if (hide || !onClick) {
    return <></>;
  }

  return (
    <div>
      <DropdownItem
        stopPropagation
        onClick={!disabled ? onClick : undefined}
        disabled={disabled}
      >
        <div
          className={classnames(
            "dropdown-button-dropdown-item-content",
            className
          )}
        >
          {!!icon && <div className={classnames("dropdown-icon", icon)}></div>}
          <div className={"dropdown-button-body"}>
            <div className={"header-text"}>{headerText}</div>
            <div className={"description"}>{description}</div>
          </div>
        </div>
      </DropdownItem>
      {separator && <DropdownSeparator />}
    </div>
  );
};

export default DropdownButton;

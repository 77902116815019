import { Node, NodeType, SelectNodeAnswer } from "../types/types";
import { Expression, SingleAnswerComparison } from "../types/conditionalLogic";
import { createUUID, NodeSummary } from "../helpers";
import { Survey } from "./reducer";
import { Severity } from "../../_common/types/severity";
import { SurveyFramework } from "../types/frameworks";
import { ValidationError, ValidationErrors } from "../types/validation";
import { IUserMini } from "../../_common/types/user";

import {
  ADD_MESSAGE_ALERT,
  addSimpleErrorAlert,
  IMessageAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { DefaultAction } from "../../_common/types/redux";

// The functionality of this reducer is mirrored on the backend in survey/surveybuilder/actions.go.
// Whenever making any changes to actions or the reducer, make sure you check the backend to
// see if any changes need to be made there too.

export type SurveyBuilderActions =
  | SetFullSurveyAction
  | ClearHistoryAction
  | SetSurveySavingAction
  | SetSurveyDraftInProgressAction
  | SetSurveyValidationErrorsAction
  | RemoveSurveyValidationErrorsForNodeAction
  | SetSurveyLockedByAction
  | SetCurrentFocusedNodeIdAction
  | SetEditRiskModalNodeIdAction
  | SetFrameworkAction
  | SetIncludeTOCAction
  | SetCustomNumberingAction
  | AddChildNodeAction
  | UpdateNodeAction
  | DeleteNodeAction
  | SwapQuestionNodeAction
  | AddSelectNodeAnswerAction
  | DeleteSelectNodeAnswerAction
  | UpdateSelectNodeAnswerAction
  | AddConditionalExpressionAction
  | UpdateConditionalExpressionAction
  | DeleteConditionalExpressionAction
  | ReorderTreeAction
  | SimpleErrorAlertAction
  | SetSurveySaveErrorAction;

export const SET_FULL_SURVEY = "SET_FULL_SURVEY";

interface SetFullSurveyAction {
  type: typeof SET_FULL_SURVEY;
  surveyId: string;
  survey: Survey;
}

export function setFullSurvey(surveyId: string, survey: Survey) {
  return {
    type: SET_FULL_SURVEY,
    surveyId,
    survey,
  };
}

export const CLEAR_HISTORY = "CLEAR_HISTORY";
interface ClearHistoryAction {
  type: typeof CLEAR_HISTORY;
  surveyId: string;
}

export function clearHistory(surveyId: string) {
  return {
    type: CLEAR_HISTORY,
    surveyId,
  };
}

export const SET_SURVEY_SAVING = "SET_SURVEY_SAVING";

interface SetSurveySavingAction {
  type: typeof SET_SURVEY_SAVING;
  surveyId: string;
  saving: boolean;
}

export function setSurveySaving(surveyId: string, saving: boolean) {
  return {
    type: SET_SURVEY_SAVING,
    surveyId,
    saving,
  };
}

export const SET_SURVEY_DRAFT_IN_PROGRESS = "SET_SURVEY_DRAFT_IN_PROGRESS";

interface SetSurveyDraftInProgressAction {
  type: typeof SET_SURVEY_DRAFT_IN_PROGRESS;
  surveyId: string;
  draftInProgress: boolean;
}

export function setSurveyDraftInProgress(
  surveyId: string,
  draftInProgress: boolean
) {
  return {
    type: SET_SURVEY_DRAFT_IN_PROGRESS,
    surveyId,
    draftInProgress,
  };
}

export const SET_SURVEY_VALIDATION_ERRORS = "SET_SURVEY_VALIDATION_ERRORS";

interface SetSurveyValidationErrorsAction {
  type: typeof SET_SURVEY_VALIDATION_ERRORS;
  surveyId: string;
  validationErrors: ValidationErrors;
}

export function setSurveyValidationErrors(
  surveyId: string,
  validationErrors: ValidationErrors
) {
  return {
    type: SET_SURVEY_VALIDATION_ERRORS,
    surveyId,
    validationErrors,
  };
}

export const REMOVE_SURVEY_VALIDATION_ERRORS_FOR_NODE =
  "REMOVE_SURVEY_VALIDATION_ERRORS_FOR_NODE";

interface RemoveSurveyValidationErrorsForNodeAction {
  type: typeof REMOVE_SURVEY_VALIDATION_ERRORS_FOR_NODE;
  surveyId: string;
  nodeId: string;
  validationErrors: ValidationError[];
}

export function removeSurveyValidationErrorsForNode(
  surveyId: string,
  nodeId: string,
  validationErrors: ValidationError[]
) {
  return {
    type: REMOVE_SURVEY_VALIDATION_ERRORS_FOR_NODE,
    surveyId,
    nodeId,
    validationErrors,
  };
}

export const SET_SURVEY_LOCKED_BY = "SET_SURVEY_LOCKED_BY";

interface SetSurveyLockedByAction {
  type: typeof SET_SURVEY_LOCKED_BY;
  surveyId: string;
  lockedBy?: IUserMini;
}

export function setSurveyLockedBy(surveyId: string, lockedBy?: IUserMini) {
  return {
    type: SET_SURVEY_LOCKED_BY,
    surveyId,
    lockedBy,
  };
}

export const SET_CURRENT_FOCUSED_NODEID = "SET_CURRENT_FOCUSED_NODEID";

interface SetCurrentFocusedNodeIdAction {
  type: typeof SET_CURRENT_FOCUSED_NODEID;
  surveyId: string;
  nodeId?: string;
}

export function setCurrentFocusedNodeId(surveyId: string, nodeId?: string) {
  return {
    type: SET_CURRENT_FOCUSED_NODEID,
    surveyId,
    nodeId,
  };
}

export const SET_EDIT_RISK_MODAL_NODE_ID = "SET_EDIT_RISK_MODAL_NODE_ID";

interface SetEditRiskModalNodeIdAction {
  type: typeof SET_EDIT_RISK_MODAL_NODE_ID;
  surveyId: string;
  nodeId?: string;
}

export function setEditRiskModalNodeId(surveyId: string, nodeId?: string) {
  return {
    type: SET_EDIT_RISK_MODAL_NODE_ID,
    surveyId,
    nodeId,
  };
}

export const SET_FRAMEWORK = "SET_FRAMEWORK";

interface SetFrameworkAction {
  type: typeof SET_FRAMEWORK;
  surveyId: string;
  framework: SurveyFramework;
}

export function setFramework(surveyId: string, framework: SurveyFramework) {
  return {
    type: SET_FRAMEWORK,
    surveyId,
    framework,
  };
}

export const SET_INCLUDE_TOC = "SET_INCLUDE_TOC";

interface SetIncludeTOCAction {
  type: typeof SET_INCLUDE_TOC;
  surveyId: string;
  includeTOC: boolean;
}

export function setIncludeTOC(surveyId: string, includeTOC: boolean) {
  return {
    type: SET_INCLUDE_TOC,
    surveyId,
    includeTOC,
  };
}

export const SET_CUSTOM_NUMBERING = "SET_CUSTOM_NUMBERING";

interface SetCustomNumberingAction {
  type: typeof SET_CUSTOM_NUMBERING;
  surveyId: string;
  customNumbering: boolean;
}

export function setCustomNumbering(surveyId: string, customNumbering: boolean) {
  return {
    type: SET_CUSTOM_NUMBERING,
    surveyId,
    customNumbering,
  };
}

export const ADD_CHILD_NODE = "ADD_CHILD_NODE";

interface AddChildNodeAction {
  type: typeof ADD_CHILD_NODE;
  surveyId: string;
  // New node that should be added to the tree. The node's parent ID must point to a Section node.
  newNode: Node;
  // Adds the node at the specified index in the parent's child array.
  addAtIndex: number;
}

export function addChildNode(
  surveyId: string,
  newNode: Node,
  addAtIndex: number
) {
  return {
    type: ADD_CHILD_NODE,
    surveyId,
    newNode,
    addAtIndex,
  };
}

// Specific actions for creating blank versions of new nodes
export function addBlankSectionNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.Section,
      nodeId: newNodeId,
      parentId: parentNodeId,
      title: "",
      mainText: "",
      childNodeIds: [],
    },
    addAtIndex
  );
}

export function addBlankInputTextNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.InputText,
      nodeId: newNodeId,
      parentId: parentNodeId,
      multiLine: true,
      mainText: "",
    },
    addAtIndex
  );
}

export function addBlankRadioGroupNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.Select,
      nodeId: newNodeId,
      parentId: parentNodeId,
      mainText: "",
      radio: true,
      answers: [
        { id: createUUID(), text: "" },
        { id: createUUID(), text: "" },
      ],
      allowNotes: false,
    },
    addAtIndex
  );
}

export function addBlankCheckGroupNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.Select,
      nodeId: newNodeId,
      parentId: parentNodeId,
      mainText: "",
      radio: false,
      answers: [
        { id: createUUID(), text: "" },
        { id: createUUID(), text: "" },
      ],
      allowNotes: false,
    },
    addAtIndex
  );
}

export function addBlankUploadNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.Upload,
      nodeId: newNodeId,
      parentId: parentNodeId,
      mainText: "",
    },
    addAtIndex
  );
}

export function addBlankRiskNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number,
  conditionalExpression?: Expression[]
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.Risk,
      nodeId: newNodeId,
      parentId: parentNodeId,
      mainText: "",
      riskCategory: "", // deprecated
      impact: "",
      name: "",
      passName: "",
      askForCompensatingControls: false,
      why: "",
      severity: Severity.Medium,
      conditionalExpression,
      skipNumbering: true,
    },
    addAtIndex
  );
}

export function addBlankInfoNode(
  surveyId: string,
  parentNodeId: string,
  newNodeId: string,
  addAtIndex: number
) {
  return addChildNode(
    surveyId,
    {
      type: NodeType.Info,
      nodeId: newNodeId,
      parentId: parentNodeId,
      mainText: "",
      skipNumbering: true,
    },
    addAtIndex
  );
}

export const UPDATE_NODE = "UPDATE_NODE";

interface UpdateNodeAction {
  type: typeof UPDATE_NODE;
  surveyId: string;
  nodeId: string;
  fieldsToUpdate: Partial<Node>;
}

// This action can update any top level field in any node type.
export function updateNode<T extends Node>(
  surveyId: string,
  nodeId: string,
  fieldsToUpdate: Partial<T>
) {
  return {
    type: UPDATE_NODE,
    surveyId,
    nodeId,
    fieldsToUpdate: fieldsToUpdate as Partial<Node>,
  };
}

export const DELETE_NODE = "DELETE_NODE";

interface DeleteNodeAction {
  type: typeof DELETE_NODE;
  surveyId: string;
  nodeId: string;
}

// This action will delete a node at the specified ID. It will cascase
// the delete to all its children.
export function deleteNode(surveyId: string, nodeId: string) {
  return {
    type: DELETE_NODE,
    surveyId,
    nodeId,
  };
}

export const SWAP_QUESTION_NODE_TYPE = "SWAP_QUESTION_NODE";

interface SwapQuestionNodeAction {
  type: typeof SWAP_QUESTION_NODE_TYPE;
  surveyId: string;
  nodeId: number;
  newNodeType: NodeType;
  radio: boolean;
  answers: SelectNodeAnswer[];
}

// This action will replace a question node with a new question node, retaining
// question text and answers if available.
export function swapQuestionNode(
  surveyId: string,
  nodeId: string,
  newNodeType: NodeType,
  radio: boolean,
  answers: SelectNodeAnswer[]
) {
  return {
    type: SWAP_QUESTION_NODE_TYPE,
    surveyId,
    nodeId: nodeId,
    newNodeType: newNodeType,
    radio: radio,
    answers: answers,
  };
}

export const ADD_SELECT_NODE_ANSWER = "ADD_SELECT_NODE_ANSWER";

interface AddSelectNodeAnswerAction {
  type: typeof ADD_SELECT_NODE_ANSWER;
  surveyId: string;
  nodeId: string;
  newAnswerId: string;
}

// Adds a new blank answer to the end of a select node's set of answers.
export function addSelectNodeAnswer(surveyId: string, nodeId: string) {
  return {
    type: ADD_SELECT_NODE_ANSWER,
    surveyId,
    nodeId,
    newAnswerId: createUUID(),
  };
}

export const DELETE_SELECT_NODE_ANSWER = "DELETE_SELECT_NODE_ANSWER";

interface DeleteSelectNodeAnswerAction {
  type: typeof DELETE_SELECT_NODE_ANSWER;
  surveyId: string;
  nodeId: string;
  deleteAtIndex: number;
}

// Adds a new blank answer to the end of a select node's set of answers.
export function deleteSelectNodeAnswer(
  surveyId: string,
  nodeId: string,
  deleteAtIndex: number
) {
  return {
    type: DELETE_SELECT_NODE_ANSWER,
    surveyId,
    nodeId,
    deleteAtIndex,
  };
}

interface SimpleErrorAlertAction {
  type: typeof ADD_MESSAGE_ALERT;
  alert: IMessageAlert;
}

export const SimpleErrorAlert = (
  errorText: string,
  subMessages: string[] = [],
  id?: string
) => {
  const a = addSimpleErrorAlert(errorText, subMessages, id);

  return {
    type: ADD_MESSAGE_ALERT,
    alert: a.alert,
  };
};

export const UPDATE_SELECT_NODE_ANSWER = "UPDATE_SELECT_NODE_ANSWER";

interface UpdateSelectNodeAnswerAction {
  type: typeof UPDATE_SELECT_NODE_ANSWER;
  surveyId: string;
  nodeId: string;
  updateIndex: number;
  text: string;
}

// Adds a new blank answer to the end of a select node's set of answers.
export function updateSelectNodeAnswer(
  surveyId: string,
  nodeId: string,
  updateIndex: number,
  text: string
) {
  return {
    type: UPDATE_SELECT_NODE_ANSWER,
    surveyId,
    nodeId,
    updateIndex,
    text,
  };
}

export const ADD_CONDITIONAL_EXPRESSION = "ADD_CONDITIONAL_EXPRESSION";

interface AddConditionalExpressionAction {
  type: typeof ADD_CONDITIONAL_EXPRESSION;
  surveyId: string;
  nodeId: string;
  addAtIndex: number[]; // Multi level index refers to nested conditions
  expressionsToAdd: Expression[];
}

// This action adds the specified conditional expressions to a node.
// addAtIndex is an array to allow addressing nested conditions.
export function addConditionalExpression(
  surveyId: string,
  nodeId: string,
  addAtIndex: number[],
  expressionsToAdd: Expression[]
) {
  return {
    type: ADD_CONDITIONAL_EXPRESSION,
    surveyId,
    nodeId,
    addAtIndex,
    expressionsToAdd,
  };
}

export const UPDATE_CONDITIONAL_EXPRESSION = "UPDATE_CONDITIONAL_EXPRESSION";

interface UpdateConditionalExpressionAction {
  type: typeof UPDATE_CONDITIONAL_EXPRESSION;
  surveyId: string;
  nodeId: string;
  updateIndex: number[]; // Multi level index refers to nested conditions
  updateExpression: Expression | Partial<SingleAnswerComparison>;
}

export function updateConditionalExpression(
  surveyId: string,
  nodeId: string,
  updateIndex: number[],
  updateExpression: Expression | Partial<SingleAnswerComparison>
) {
  return {
    type: UPDATE_CONDITIONAL_EXPRESSION,
    surveyId,
    nodeId,
    updateIndex,
    updateExpression,
  };
}

export const DELETE_CONDITIONAL_EXPRESSION = "DELETE_CONDITIONAL_EXPRESSION";

interface DeleteConditionalExpressionAction {
  type: typeof DELETE_CONDITIONAL_EXPRESSION;
  surveyId: string;
  nodeId: string;
  startIndex: number[]; // Multi level index refers to nested conditions
  numToDelete: number;
}

export function deleteConditionalExpression(
  surveyId: string,
  nodeId: string,
  startIndex: number[],
  numToDelete: number
) {
  return {
    type: DELETE_CONDITIONAL_EXPRESSION,
    surveyId,
    nodeId,
    startIndex,
    numToDelete,
  };
}

export const REORDER_TREE = "REORDER_TREE";

interface ReorderTreeAction {
  type: typeof REORDER_TREE;
  surveyId: string;
  allNodes: NodeSummary[];
}

export function reorderTree(surveyId: string, allNodes: NodeSummary[]) {
  return {
    type: REORDER_TREE,
    surveyId,
    allNodes,
  };
}

export const SET_SURVEY_SAVE_ERROR = "SET_SURVEY_SAVE_ERROR";

interface SetSurveySaveErrorAction {
  type: typeof SET_SURVEY_SAVE_ERROR;
  surveyId: string;
  errorState?: boolean;
}

export function setSurveySaveError(surveyId: string, errorState?: boolean) {
  return {
    type: SET_SURVEY_SAVE_ERROR,
    surveyId,
    errorState,
  };
}

// deleteCustomNumbers runs through all nodes in  a survey and deletes their custom numbers.
export const deleteCustomNumbers =
  (surveyId: string): DefaultAction =>
  async (dispatch, getState) => {
    // Clear the customNumber on every node in the tree.
    const nodes = getState().surveyBuilder.surveys[surveyId]?.nodes;
    if (!nodes) {
      return;
    }

    for (const nodeID in nodes) {
      dispatch(
        updateNode(surveyId, nodeID, {
          customNumber: "",
        })
      );
    }
  };

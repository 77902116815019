import { History } from "history";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import StatusIcon from "./StatusIcon";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { SeverityAsString, SeverityInt } from "../../../_common/types/severity";
import { useHistory, useLocation } from "react-router-dom";
import { FC, useCallback, useEffect } from "react";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { locationState } from "../../../_common/types/router";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import "../../style/components/securityprofile/CheckResultLine.scss";
import classnames from "classnames";
import { ControlState } from "../../types/securityProfile";
import { useVendorDataSelector } from "../../../_common/selectors/vendorSelectors";
import classNames from "classnames";
import { pluralise } from "../../../_common/helpers";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import {
  VendorRiskWaiverTabType,
  VendorRiskWaiversLocationState,
} from "../../views/VendorRiskWaivers";
import {
  useAssuranceType,
  useVendorURLPrefix,
  useVendorWords,
} from "../../../_common/hooks";
import { VendorSummaryRisk } from "../../../_common/types/vendorSummary";
import { getVendorPageBreadcrumbs } from "../../../_common/components/Breadcrumbs";
import { RemediationRequestDetailsLocationState } from "../../../_common/views/RemediationRequestDetailsV2";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { fetchVendorRiskWaivers } from "../../reducers/vendorRiskWaiver.actions";
import { trackEvent } from "../../../_common/tracking";

interface CheckResultLineProps {
  vendorId: number;
  controlId: string;
  checkId: string;
}

const CheckResultLine: FC<CheckResultLineProps> = ({
  vendorId,
  controlId,
  checkId,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (vendorId) {
      dispatch(fetchVendorRiskWaivers(vendorId));
    }
  }, [vendorId, dispatch]);

  const { data: controlData } =
    vendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  const cloudscansInRemediation = useVendorDataSelector(
    (state) => state.summary?.result?.cloudscansInRemediation,
    vendorId
  );

  const riskWaivers = useVendorDataSelector(
    (state) => state.riskWaivers,
    vendorId
  );

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];
  const scanningChecks = securityProfile?.scanningChecks[checkId];
  const surveyChecks = securityProfile?.surveyChecks[checkId];

  const history = useHistory();
  const location = useLocation<locationState>();
  const urlPrefix = useVendorURLPrefix(vendorId);

  const openCheckPanel = useCallback(
    (scanRiskId?: string, surveyRiskId?: string) => {
      trackEvent("VendorSecurityProfile_CheckExpanded");
      OpenSidePanel(
        history,
        {
          vendorId,
          checkId,
          scanRiskId,
          surveyRiskId,
        },
        controlData?.control.text,
        location
      );
    },
    [vendorId, checkId, controlData, history, location]
  );

  const scanCheckWaiver = useCallback(
    (scanCheck: VendorSummaryRisk) => {
      return riskWaivers?.find(
        (w) =>
          (w.isAllDomains ||
            w.domains?.filter(
              (h) => scanCheck.failedCloudscans?.find((fc) => fc.hostname === h)
            )) &&
          (w.riskID === scanCheck.id || w.riskID === scanCheck.baseId)
      );
    },
    [riskWaivers]
  );

  const surveyCheckWaiver = useCallback(
    (surveyCheck: VendorSummaryRisk, waiverType: WaiverType) => {
      const surveyId = surveyCheck.surveys?.[0]?.surveyId;
      if (!surveyId) {
        return undefined;
      }
      return riskWaivers?.find(
        (w) =>
          (w.isAllSurveys || w.surveys?.includes(surveyId)) &&
          w.waiverType === waiverType &&
          (w.riskID === surveyCheck.id || w.riskID === surveyCheck.baseId)
      );
    },
    [riskWaivers]
  );

  const documentCheckWaiver = useCallback(
    (documentCheck: VendorSummaryRisk) => {
      return riskWaivers?.find(
        (w) =>
          w.riskID === documentCheck.id || w.riskID === documentCheck.baseId
      );
    },
    [riskWaivers]
  );

  const vendorWords = useVendorWords();
  const assuranceType = useAssuranceType();
  const vendorName =
    useVendorDataSelector((data) => data?.name, vendorId) ??
    vendorWords.singularTitleCase;

  const navigateToWaiver = useCallback(
    (id?: number, waiverType?: WaiverType) => {
      (history as History<VendorRiskWaiversLocationState>).push(
        `${urlPrefix}/riskwaivers`,
        {
          waiverId: id,
          currentTab:
            waiverType === WaiverType.SeverityAdjustment
              ? VendorRiskWaiverTabType.SeverityAdjustments
              : VendorRiskWaiverTabType.Waivers,
          backContext: {
            goBack: true,
            backToText: "Back to Control details",
          },
          breadcrumbs: [
            ...getVendorPageBreadcrumbs(vendorId, vendorName, assuranceType),
            {
              text: "Security profile",
              to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&controlId=${controlId}`,
            },
          ],
        }
      );
    },
    [assuranceType, controlId, history, urlPrefix, vendorId, vendorName]
  );

  const navigateToRemediation = useCallback(
    (id?: number, riskId?: string) => {
      (history as History<RemediationRequestDetailsLocationState>).push(
        `${urlPrefix}/remediation/${id ?? ""}`,
        {
          riskId: riskId,
          backContext: {
            goBack: true,
            backToText: "Back to Control details",
          },
          breadcrumbs: [
            ...getVendorPageBreadcrumbs(vendorId, vendorName, assuranceType),
            {
              text: "Security profile",
              to: `${urlPrefix}/securityprofile?vendorId=${vendorId}&controlId=${controlId}`,
            },
          ],
        }
      );
    },
    [assuranceType, controlId, history, urlPrefix, vendorId, vendorName]
  );

  const numCitations = documentCheck?.securityProfiles
    ? documentCheck.securityProfiles.reduce(
        (acc, sp) => acc + sp.citations.length,
        0
      )
    : undefined;

  // always 1 extra citation for the gap survey
  const numSurveyCitations = (numCitations || 0) + 1;

  if (!check) {
    return <></>;
  }

  // if we have scanning risks then output one line per result
  if (scanningChecks) {
    return (
      <>
        {scanningChecks.map((scanCheck) => (
          <div
            key={scanCheck.id}
            className={"check-result-line"}
            onClick={() => openCheckPanel(scanCheck.id)}
          >
            <div className={"icon"}>
              {checkState == ControlState.Failed ||
              scanCheck.isWaived ||
              scanCheck.severity === SeverityInt.InfoSeverity ? (
                <AdjustedSeverityIcon
                  severity={SeverityAsString(scanCheck.severity)}
                  baseSeverity={
                    scanCheck.baseSeverity
                      ? SeverityAsString(scanCheck.baseSeverity)
                      : undefined
                  }
                />
              ) : (
                <StatusIcon controlState={checkState ?? ControlState.Unknown} />
              )}
            </div>
            <div className={"check-text"}>
              <div
                className={classnames("risk-name", {
                  waived: scanCheck.isWaived,
                })}
              >
                {scanCheck.title}
              </div>
              {scanCheck.isWaived && (
                <PillLabel
                  className="managed-status-pill"
                  color={LabelColor.Grey}
                  onClick={(e) => {
                    e.stopPropagation();
                    const w = scanCheckWaiver(scanCheck);
                    navigateToWaiver(w?.id, WaiverType.RiskWaiver);
                  }}
                >
                  Waived &gt;
                </PillLabel>
              )}
              {!!cloudscansInRemediation?.[scanCheck.id] && (
                <PillLabel
                  className="managed-status-pill"
                  color={LabelColor.Blue}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateToRemediation(
                      cloudscansInRemediation?.[scanCheck.id]
                        ?.remediationRequestIds?.[0],
                      scanCheck.id
                    );
                  }}
                >
                  In remediation &gt;
                </PillLabel>
              )}
            </div>
            <i className={"cr-icon-chevron "} />
          </div>
        ))}
      </>
    );
  }

  // if we have survey risks then output one line per result
  if (surveyChecks) {
    return (
      <>
        {surveyChecks.map((surveyCheck) => (
          <div
            key={surveyCheck.id}
            className={"check-result-line"}
            onClick={() => openCheckPanel(undefined, surveyCheck.id)}
          >
            <div className={"icon"}>
              {checkState == ControlState.Failed ||
              surveyCheck.isWaived ||
              surveyCheck.severity === SeverityInt.InfoSeverity ? (
                <AdjustedSeverityIcon
                  severity={SeverityAsString(surveyCheck.severity)}
                  baseSeverity={
                    surveyCheck.baseSeverity
                      ? SeverityAsString(surveyCheck.baseSeverity)
                      : undefined
                  }
                />
              ) : (
                <StatusIcon controlState={checkState ?? ControlState.Unknown} />
              )}
            </div>
            <div className={"check-text"}>
              <div
                className={classnames("risk-name", {
                  waived: surveyCheck.isWaived,
                })}
              >
                {surveyCheck.title}
              </div>
              {surveyCheck.isWaived && (
                <PillLabel
                  className="managed-status-pill"
                  color={LabelColor.Grey}
                  onClick={(e) => {
                    e.stopPropagation();
                    const w = surveyCheckWaiver(
                      surveyCheck,
                      WaiverType.RiskWaiver
                    );
                    navigateToWaiver(w?.id, WaiverType.RiskWaiver);
                  }}
                >
                  Waived &gt;
                </PillLabel>
              )}
              {surveyCheck.baseSeverity && !surveyCheck.isWaived && (
                <PillLabel
                  className="managed-status-pill"
                  color={LabelColor.Violet}
                  onClick={(e) => {
                    e.stopPropagation();
                    const w = surveyCheckWaiver(
                      surveyCheck,
                      WaiverType.SeverityAdjustment
                    );
                    navigateToWaiver(w?.id, WaiverType.SeverityAdjustment);
                  }}
                >
                  Adjusted &gt;
                </PillLabel>
              )}
              {surveyCheck.surveys?.[0]?.inRemediation && (
                <PillLabel
                  className="managed-status-pill"
                  color={LabelColor.Blue}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateToRemediation(
                      surveyCheck.surveys?.[0]?.remediationRequestId,
                      surveyCheck.id
                    );
                  }}
                >
                  In remediation &gt;
                </PillLabel>
              )}
              {numSurveyCitations && (
                <PillLabel className="citations-pill" color={LabelColor.Blue}>
                  {`${numSurveyCitations} ${pluralise(
                    numSurveyCitations,
                    "citation",
                    "citations"
                  )}`}
                </PillLabel>
              )}
            </div>
            <i className={"cr-icon-chevron "} />
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      className={classNames("check-result-line", {
        ignored: checkState == ControlState.NA,
      })}
      onClick={() => openCheckPanel()}
    >
      <div className={"icon"}>
        {checkState == ControlState.Failed ||
        check.severity == SeverityInt.InfoSeverity ||
        documentCheck?.isWaived ? (
          <AdjustedSeverityIcon
            severity={SeverityAsString(
              documentCheck?.severity ?? check.severity
            )}
            baseSeverity={
              documentCheck?.baseSeverity
                ? SeverityAsString(documentCheck.baseSeverity)
                : undefined
            }
          />
        ) : (
          <StatusIcon controlState={checkState ?? ControlState.Unknown} />
        )}
      </div>
      <div className={"check-text"}>
        <div
          className={classnames("risk-name", {
            waived: documentCheck?.isWaived,
          })}
        >
          {documentCheck?.title ?? check.text}
        </div>
        {documentCheck?.isWaived && (
          <PillLabel
            className="managed-status-pill"
            color={LabelColor.Grey}
            onClick={(e) => {
              e.stopPropagation();
              const w = documentCheckWaiver(documentCheck);
              navigateToWaiver(w?.id, WaiverType.RiskWaiver);
            }}
          >
            Waived &gt;
          </PillLabel>
        )}
        {documentCheck?.isPartiallyWaived && (
          <PillLabel
            className="managed-status-pill"
            color={LabelColor.Grey}
            onClick={(e) => {
              e.stopPropagation();
              const w = documentCheckWaiver(documentCheck);
              navigateToWaiver(w?.id, WaiverType.SeverityAdjustment);
            }}
          >
            Adjusted &gt;
          </PillLabel>
        )}
        {numCitations && (
          <PillLabel className="citations-pill" color={LabelColor.Blue}>
            {numCitations} citation
            {numCitations != 1 && "s"}
          </PillLabel>
        )}
      </div>
      <i className={"cr-icon-chevron "} />
    </div>
  );
};

export default CheckResultLine;

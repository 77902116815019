import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import UserIcon from "../../_common/images/remediation-icon.svg";
import Button from "../../_common/components/core/Button";
import ActionBar from "../../_common/components/ActionBar";

import UserBaseAPI from "../api/userbase.api";
import { UnmonitoredUser } from "../api/types";
import "./UnmonitoredUsers.scss";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";

const NAME_COL = "name_col";
const EMAIL_COL = "role_col";
const MONITOR_COL = "monitor_col";

const PAGE_SIZE = 100;

const UnmonitoredUsers: FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [bulkMonitorInProgress, setBulkMonitorInProgress] =
    useState<boolean>(false);

  const bySearchText = (u: UnmonitoredUser): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    return (
      u.name.toLocaleLowerCase().indexOf(toFind) >= 0 ||
      u.email.toLocaleLowerCase().indexOf(toFind) >= 0
    );
  };

  const [monitorUsersV1] = UserBaseAPI.useMonitorUsersV1Mutation();
  const dispatch = useAppDispatch();

  const [openMonitorConfirmationModal, monitorConfirmationModal] =
    useConfirmationModalV2();

  const monitorUsers = (userUUIDs: Set<string>) => {
    const numSelected = userUUIDs.size;
    openMonitorConfirmationModal({
      title: `Confirm monitoring`,
      description: (
        <>
          <p>
            Enabling monitoring for{" "}
            {numSelected == 1 ? "this user" : "these users"} will include them
            in your risk profile, allowing you to view their risks, SaaS app
            usage, and permissions.
          </p>
          <p>
            It may take a few minutes for the user information to be fully
            available on the platform as the data is processed.
          </p>
        </>
      ),
      filledPrimaryAction: true,
      cancelText: "Cancel",
      buttonText: "Monitor users",
      buttonAction: () => {
        setBulkMonitorInProgress(true);
        monitorUsersV1({
          user_uuids: Array.from(userUUIDs.values()),
        })
          .unwrap()
          .then(() => {
            // If the user's we're monitoring don't match the selected rows, then remove the users
            // from the selected rows
            const diff = new Set<string>();
            for (const v of selectedRows) {
              if (!userUUIDs.has(v)) {
                diff.add(v);
              }
            }
            setSelectedRows(diff);
            dispatch(
              addDefaultSuccessAlert(
                `${
                  numSelected == 1 ? "1 user is" : `${numSelected} users are`
                } now monitored, their full information will be available after the next organisation directory scan`
              )
            );
          })
          .catch(() => {
            dispatch(addDefaultUnknownErrorAlert(`Unable to monitor users`));
          })
          .finally(() => {
            setBulkMonitorInProgress(false);
          });
      },
    });
  };

  const intoXTableRow = (u: UnmonitoredUser): IXTableRow<string> => {
    return {
      id: u.uuid,
      onClick: () => {},
      cells: [
        <XTableCell key={NAME_COL} className="dimmed">
          {u.name}
        </XTableCell>,
        <XTableCell key={EMAIL_COL} className="dimmed">
          {u.email}
        </XTableCell>,
        <XTableCell key={MONITOR_COL} className="actions-cell shrink-cell">
          <Button onClick={() => monitorUsers(new Set([u.uuid]))}>
            <i className="icon-x rotate-45" />
            Monitor user
          </Button>
        </XTableCell>,
      ],
    };
  };

  const { data, isLoading } =
    UserBaseAPI.useGetUserBaseUnmonitoredUsersV1Query();

  const { pathname } = useLocation();

  const filteredUsers = (data?.users ?? []).filter(bySearchText);

  const [
    usersToDisplay,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UnmonitoredUser,
    typeof NAME_COL | typeof EMAIL_COL
  >(
    filteredUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u: UnmonitoredUser) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [EMAIL_COL]: {
        orderFuncs: [(u: UnmonitoredUser) => u.email.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  return (
    <>
      <div className={"table-filters"}>
        <SearchBox
          placeholder={"Search users"}
          onChanged={(q) => {
            onPageChange(1);
            setSearchText(q);
          }}
          value={searchText}
        />
      </div>

      <XTable
        className={"userbase-unmonitored-users-list"}
        loading={isLoading}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        pagination={{
          currentPage: currentPage,
          totalPages: totalPages,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
        columnHeaders={[
          { id: NAME_COL, text: "Name", sortable: true },
          { id: EMAIL_COL, text: "Email", sortable: true },
          { id: MONITOR_COL, text: "", sortable: false },
        ]}
        selectable
        onSelectClick={(rowId, newSelectedState) => {
          const newSelectedRows = new Set(selectedRows.values());
          if (newSelectedState) {
            newSelectedRows.add(rowId);
          } else {
            newSelectedRows.delete(rowId);
          }
          setSelectedRows(newSelectedRows);
        }}
        onSelectToggle={(shouldSelectAll) => {
          if (shouldSelectAll) {
            setSelectedRows(new Set(filteredUsers.map((u) => u.uuid)));
          } else {
            setSelectedRows(new Set());
          }
        }}
        onSelectAllClick={() => {
          setSelectedRows(new Set(filteredUsers.map((u) => u.uuid)));
        }}
        onSelectNoneClick={() => {
          setSelectedRows(new Set());
        }}
        rows={usersToDisplay.map(intoXTableRow).map((r) => ({
          ...r,
          selected: selectedRows.has(r.id),
        }))}
      />

      {searchText.length == 0 && usersToDisplay.length == 0 && !isLoading && (
        <EmptyCardWithAction
          emptyText="No current users not monitored"
          emptySubText="All users are currently monitored. When you disable monitoring for a user, they will appear here."
          iconSrc={UserIcon}
        />
      )}

      {searchText.length > 0 && usersToDisplay.length == 0 && !isLoading && (
        <SearchEmptyCard
          onClear={() => setSearchText("")}
          searchItemText={"unmonitored users"}
        />
      )}
      {monitorConfirmationModal}

      <ActionBar className="bulk-action-bar" active={selectedRows.size > 0}>
        <div className="bulk-actions">
          <div className="num-selected">
            {selectedRows.size == 1
              ? "1 user selected"
              : `${selectedRows.size} users selected`}
          </div>
          <div className="bulk-action-buttons">
            <Button tertiary onClick={() => setSelectedRows(new Set())}>
              Cancel
            </Button>
            <Button
              className="enable-monitoring"
              onClick={() => monitorUsers(selectedRows)}
              loading={bulkMonitorInProgress}
              filledPrimary
            >
              <i className="icon-x rotate-45" />
              Monitor users
            </Button>
          </div>
        </div>
      </ActionBar>
    </>
  );
};

export default UnmonitoredUsers;

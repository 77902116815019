import "../style/components/VendorRiskWaiversCard.scss";
import "../style/views/CreateVendorRiskWaiver.scss";

import { PureComponent, useState } from "react";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import {
  IVendorRiskWaiver,
  VendorRiskWaiverRiskType,
  VendorRiskWaiverStatusType,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import { openModal } from "../../_common/reducers/commonActions";
import { ConfirmationModalName } from "../../_common/components/modals/ConfirmationModal";
import { reloadAllRemediationRequestDetails } from "../../_common/reducers/remediationRequest.actions";
import {
  deleteSubsidiaryRiskWaiver,
  deleteVendorRiskWaiver,
} from "../reducers/vendorRiskWaiver.actions";
import ReportCard from "../../_common/components/ReportCard";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import IconSvg from "../images/risk-assessment-header-icon.svg";
import Button from "../../_common/components/core/Button";
import { formatDateAsLocal } from "../../_common/helpers";
import { LabelColor } from "../../_common/types/label";
import PillLabel from "./PillLabel";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { isEqual as _isEqual, sortBy as _sortBy } from "lodash";
import {
  AdjustedSeverityIcon,
  SeverityChangeDisplay,
} from "../../_common/components/SeverityIcon";
import ApproveRiskWaiverModal from "./modals/ApproveRiskWaiverModal";
import {
  clearCloudscanData,
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
} from "../reducers/cyberRiskActions";
import { surveyListPageLimit } from "../views/Questionnaires";
import {
  fetchVendorRiskBreakdownReport,
  fetchVendorRiskOverviewReport,
} from "../reducers/reportsActions";
import { getSurveyListV2 } from "../reducers/survey.actions";
import { getVendorWords } from "../../_common/constants";
import {
  fetchRiskVendorWebsites,
  fetchVendorPortfolioRiskProfile,
} from "../reducers/vendorRiskPortfolio.actions";
import { History } from "history";
import { clearDomains } from "../reducers/domains.actions";
import { AssuranceType } from "../../_common/types/organisations";
import {
  PublicRiskWaiver,
  PublicWaiverAcceptStatus,
} from "../types/sharedAssessment";
import VendorRiskWaiverPanel from "./risk_waivers/VendorRiskWaiverPanel";
import PublicWaiverPanel, {
  getPublicRiskWaiverStatusPill,
} from "./risk_waivers/PublicRiskWaiverPanel";
import ToggleWithLabel from "./ToggleWithLabel";
import RiskName from "./risk_profile/RiskName";
import EditVendorWaiverModal from "./risk_waivers/EditVendorRiskWaiverModal";
import { addDefaultSuccessAlert } from "../../_common/reducers/messageAlerts.actions";
import { SeverityAsString } from "../../_common/types/severity";
import { factCategories } from "../../_common/factCategoryHelpers";
import { fetchBreachsightRiskProfileIncludingSubsidiaries } from "../reducers/breachsightSubsidiaries.actions";
import PublicPrivatePill from "../../appguard/components/PublicPrivatePill";

export type RiskWaiver = IVendorRiskWaiver | PublicRiskWaiver;

export const mergeWaiverArrays = (
  vWaivers: IVendorRiskWaiver[],
  pWaivers?: PublicRiskWaiver[]
): RiskWaiver[] => {
  const merged: RiskWaiver[] = [];
  const acceptedPublicWaiverIds: Record<number, boolean> = {};

  if (vWaivers) {
    merged.push(
      ...vWaivers.map((v) => {
        if (
          !!v.publicWaiverId &&
          v.status === VendorRiskWaiverStatusType.Active
        ) {
          acceptedPublicWaiverIds[v.publicWaiverId] = true;
        }

        return {
          ...v,
          kind: "vendorRiskWaiver",
        } as RiskWaiver;
      })
    );
  }

  // Add in any public waivers that are not currently accepted
  if (pWaivers) {
    pWaivers.forEach((p) => {
      if (!acceptedPublicWaiverIds[p.id]) {
        merged.push({
          ...p,
          kind: "publicRiskWaiver",
          waiverType: WaiverType.RiskWaiver,
        });
      }
    });
  }

  return merged;
};

interface IVendorRiskWaiversCardProps {
  dispatch: DefaultThunkDispatch;
  history: History;

  vendorId?: number;
  vendorName?: string;
  riskWaivers?: RiskWaiver[];

  headerTextOverride?: string;
  waiverType?: WaiverType;

  hasPossibleRisks?: boolean;

  hideSearch?: boolean;
  showVendorName?: boolean;
  allowEdit?: boolean;
  allowApprove?: boolean;
  allowCreate?: boolean;

  shouldTriggerDataFetchOnUpdate?: boolean;
  initiallySelectedId?: number;
  initiallySelectedPublicId?: number;
  assuranceType?: AssuranceType;
  onCreateClick?: () => void;
  assessmentContext?: boolean;

  isManagementAnalystSession?: boolean;
  managedOrgId?: number;

  isSubsidiary?: boolean;
}

interface IVendorRiskWaiversCardState {
  searchText: string;
  filteredRiskWaivers: RiskWaiver[];
  editing?: IVendorRiskWaiver;
  isShowingEditModal: boolean;
  approving?: IVendorRiskWaiver;
  isApproving: boolean;
  isShowingApproveModal: boolean;
  viewingVendorRiskWaiver?: IVendorRiskWaiver;
  lastViewedInitialSelectedId?: number;
  viewingPublicRiskWaiver?: PublicRiskWaiver;
  lastViewedInitialSelectedPublicId?: number;
  isShowingSharedWaivers: boolean;
}

class VendorRiskWaiversCard extends PureComponent<
  IVendorRiskWaiversCardProps,
  IVendorRiskWaiversCardState
> {
  constructor(props: IVendorRiskWaiversCardProps) {
    super(props);

    this.state = {
      searchText: "",
      filteredRiskWaivers: this.props.riskWaivers ?? [],
      isShowingEditModal: false,
      isShowingApproveModal: false,
      isApproving: false,
      isShowingSharedWaivers: true,
    };

    this.state = {
      ...this.state,
      ...this.getState(),
    };
  }

  filterWaivers = (
    searchText: string,
    includePublicWaivers: boolean
  ): RiskWaiver[] => {
    let waivers =
      this.props.riskWaivers?.filter(
        (rw) =>
          rw.riskName
            .toLocaleLowerCase()
            .indexOf(searchText.toLocaleLowerCase()) !== -1
      ) ?? [];

    if (!includePublicWaivers) {
      waivers = waivers.filter(
        (w) => w.kind !== "publicRiskWaiver" && !w.publicWaiverId
      );
    }

    return waivers;
  };

  componentDidUpdate(prevProps: Readonly<IVendorRiskWaiversCardProps>) {
    const newState = this.getState(prevProps);

    if (Object.keys(newState).length > 0) {
      this.setState({ ...this.state, ...newState });
    }
  }

  getState = (
    prevProps?: Readonly<IVendorRiskWaiversCardProps>
  ): Partial<IVendorRiskWaiversCardState> => {
    let partialState: Partial<IVendorRiskWaiversCardState> = {};

    if (
      this.props.riskWaivers &&
      prevProps?.riskWaivers !== this.props.riskWaivers
    ) {
      const filteredRiskWaivers = this.filterWaivers(
        this.state.searchText,
        this.state.isShowingSharedWaivers
      );

      if (!_isEqual(this.state.filteredRiskWaivers, filteredRiskWaivers)) {
        partialState = {
          ...partialState,
          filteredRiskWaivers,
        };
      }
    }

    // Check if we've been passed a new waiver id to show
    if (
      this.props.riskWaivers &&
      this.props.initiallySelectedId &&
      (prevProps?.initiallySelectedId !== this.props.initiallySelectedId ||
        !_isEqual(prevProps.riskWaivers, this.props.riskWaivers))
    ) {
      const initiallySelectedWaiver = this.props.riskWaivers.find(
        (w) =>
          w.id === this.props.initiallySelectedId &&
          w.kind === "vendorRiskWaiver"
      );

      partialState = {
        ...partialState,
        filteredRiskWaivers: this.filterWaivers(
          this.state.searchText,
          this.state.isShowingSharedWaivers
        ),
        viewingVendorRiskWaiver:
          initiallySelectedWaiver?.kind === "vendorRiskWaiver"
            ? initiallySelectedWaiver
            : undefined,
        lastViewedInitialSelectedId: initiallySelectedWaiver?.id,
      };
    }

    // check if the viewing waiver has been updated, eg approved
    if (this.state.viewingVendorRiskWaiver?.id !== undefined) {
      const found = this.props.riskWaivers?.find(
        (w) => w.id === this.state.viewingVendorRiskWaiver?.id
      );
      if (found && found.kind === "vendorRiskWaiver") {
        partialState.viewingVendorRiskWaiver = found;
      }
    }

    // Check if we've been passed a new public waiver id to show
    if (
      this.props.riskWaivers &&
      this.props.initiallySelectedPublicId &&
      (prevProps?.initiallySelectedPublicId !==
        this.props.initiallySelectedPublicId ||
        !_isEqual(prevProps.riskWaivers, this.props.riskWaivers))
    ) {
      const initiallySelectedPublicWaiver = this.props.riskWaivers.find(
        (w) =>
          w.id === this.props.initiallySelectedPublicId &&
          w.kind === "publicRiskWaiver"
      );
      partialState = {
        ...partialState,
        viewingPublicRiskWaiver:
          initiallySelectedPublicWaiver?.kind === "publicRiskWaiver"
            ? initiallySelectedPublicWaiver
            : undefined,
        lastViewedInitialSelectedPublicId: initiallySelectedPublicWaiver?.id,
      };
    }

    return partialState;
  };

  fetchNewData = (waiver?: IVendorRiskWaiver) => {
    if (this.props.shouldTriggerDataFetchOnUpdate && waiver) {
      this.props.dispatch(
        fetchVendorSummaryAndCloudscans(
          waiver.datastoreVendorID,
          true,
          false,
          false,
          this.props.isSubsidiary
        )
      );

      if (waiver.riskType === VendorRiskWaiverRiskType.Survey) {
        this.props.dispatch(
          getSurveyListV2(
            true,
            waiver.datastoreVendorID,
            0,
            surveyListPageLimit
          )
        );
      }

      if (this.props.isSubsidiary) {
        this.props.dispatch(
          fetchBreachsightRiskProfileIncludingSubsidiaries(true)
        );
      }
    }
  };

  searchChanged = (value: string) => {
    this.setState({
      searchText: value,
      filteredRiskWaivers: this.filterWaivers(
        value,
        this.state.isShowingSharedWaivers
      ),
    });
  };

  waiverSelected = (waiver: RiskWaiver) => {
    if (waiver.kind === "vendorRiskWaiver") {
      this.setState({
        viewingVendorRiskWaiver: waiver,
        viewingPublicRiskWaiver: undefined,
      });
    } else if (waiver.kind === "publicRiskWaiver") {
      this.setState({
        viewingPublicRiskWaiver: waiver,
        viewingVendorRiskWaiver: undefined,
      });
    }
  };

  updateWaiver = (waiver?: IVendorRiskWaiver) => {
    if (this.props.isSubsidiary && waiver) {
      this.props.dispatch(
        fetchRiskVendorWebsites(
          waiver.riskID,
          waiver.datastoreVendorID,
          true,
          false,
          true,
          waiver.id
        )
      );
    }
    this.setState({
      editing: waiver,
      isShowingEditModal: true,
      viewingVendorRiskWaiver: undefined,
    });
  };

  deleteWaiver = (waiver?: IVendorRiskWaiver) => {
    if (!waiver) {
      return;
    }

    const isActivePublic =
      !!waiver.publicWaiverId &&
      waiver.status === VendorRiskWaiverStatusType.Active;

    const waiverTypeText =
      waiver.waiverType === WaiverType.SeverityAdjustment
        ? "risk adjustment"
        : "risk waiver";

    this.props.dispatch(
      openModal(
        ConfirmationModalName,
        {
          title: isActivePublic
            ? "Reject Public Risk Waiver"
            : `Delete ${waiverTypeText}`,
          description: isActivePublic ? (
            <>
              <p>
                Are you sure you want to cancel your acceptance of this public
                risk waiver?
              </p>
              <p>You can choose to accept this waiver again at a later time.</p>
            </>
          ) : (
            `Are you sure you want to delete this ${waiverTypeText}?`
          ),
          buttonText: isActivePublic ? "Reject" : "Delete",
          dangerousAction: true,
          buttonAction: () => {
            return this.props
              .dispatch(
                this.props.isSubsidiary
                  ? deleteSubsidiaryRiskWaiver(
                      waiver.datastoreVendorID,
                      waiver.id
                    )
                  : deleteVendorRiskWaiver(waiver.datastoreVendorID, waiver.id)
              )
              .then(() => {
                this.setState({ viewingVendorRiskWaiver: undefined });
                this.fetchNewData(waiver);
                this.props.dispatch(
                  fetchAlertsOrActivityStreamForOrgUser(true, true)
                );
                this.props.dispatch(
                  fetchVendorSummaryAndCloudscans(
                    waiver.datastoreVendorID,
                    true,
                    false,
                    false,
                    this.props.isSubsidiary
                  )
                );
                this.props.dispatch(fetchVendorRiskOverviewReport(true));
                this.props.dispatch(fetchVendorRiskBreakdownReport(true));
                if (this.props.isSubsidiary) {
                  this.props.dispatch(
                    fetchBreachsightRiskProfileIncludingSubsidiaries(true)
                  );
                }
                this.props.dispatch(reloadAllRemediationRequestDetails());
                this.props.dispatch(clearCloudscanData());
                this.props.dispatch(fetchVendorPortfolioRiskProfile());
                this.props.dispatch(clearDomains());
                this.props.dispatch(
                  addDefaultSuccessAlert(
                    `Risk ${
                      this.props.waiverType === WaiverType.SeverityAdjustment
                        ? "adjustment"
                        : "waiver"
                    } deleted. It might take a few seconds for the ${
                      this.props.isSubsidiary
                        ? "subsidiary"
                        : getVendorWords(
                            this.props.assuranceType ?? AssuranceType.None
                          ).singular
                    } security rating to update.`
                  )
                );
              });
          },
        },
        true
      )
    );
  };

  approveWaiver = (waiver: IVendorRiskWaiver, approve: boolean) => {
    this.setState({
      isShowingApproveModal: true,
      isApproving: approve,
      approving: waiver,
    });
  };

  toggleShowingSharedWaivers = () => {
    this.setState({
      isShowingSharedWaivers: !this.state.isShowingSharedWaivers,
      filteredRiskWaivers: this.filterWaivers(
        this.state.searchText,
        !this.state.isShowingSharedWaivers
      ),
    });
  };

  render() {
    const vendorWords = getVendorWords(
      this.props.assuranceType ?? AssuranceType.None
    );
    const isLoading = this.props.riskWaivers === undefined;

    const isSearchEmpty =
      !isLoading &&
      this.props.riskWaivers &&
      this.props.riskWaivers.length > 0 &&
      this.state.filteredRiskWaivers.length === 0;

    const sharedWaiverCount =
      this.props.riskWaivers?.filter(
        (w) => w.kind === "publicRiskWaiver" || w.publicWaiverId
      ).length ?? 0;

    return (
      <ReportCard newStyles className={"vendor-risk-waivers-card"}>
        <div className="header">
          <div className="header-title">
            {this.props.headerTextOverride ??
              `${vendorWords.singularTitleCase} Risk Waivers`}
          </div>
          <div className={"header-right"}>
            {sharedWaiverCount > 0 && (
              <ToggleWithLabel
                name={"tgl-public-waivers"}
                label={`Show shared waivers (${sharedWaiverCount})`}
                onClick={this.toggleShowingSharedWaivers}
                selected={this.state.isShowingSharedWaivers}
              />
            )}
            {this.props.allowCreate &&
              this.props.onCreateClick &&
              this.props.hasPossibleRisks && (
                <Button primary onClick={this.props.onCreateClick}>
                  <div className={"icon-x rotate-45"} />
                  {this.props.waiverType === WaiverType.SeverityAdjustment
                    ? "Adjust risk"
                    : "Create risk waiver"}
                </Button>
              )}
          </div>
        </div>
        {!this.props.hideSearch &&
          this.props.riskWaivers &&
          this.props.riskWaivers?.length > 0 && (
            <div className={"vendor-risk-waivers-filters"}>
              <SearchBox
                value={this.state.searchText}
                disabled={
                  !this.props.riskWaivers || this.props.riskWaivers.length === 0
                }
                onChanged={this.searchChanged}
              />
            </div>
          )}
        <div>
          {(isLoading ||
            (this.props.riskWaivers &&
              this.props.riskWaivers.length > 0 &&
              !isSearchEmpty)) && (
            <VendorRiskWaiversTable
              isLoading={isLoading}
              riskWaivers={this.state.filteredRiskWaivers}
              onWaiverSelected={this.waiverSelected}
              onEditWaiver={this.updateWaiver}
              onArchiveWaiver={this.deleteWaiver}
              onApproveWaiver={this.approveWaiver}
              includeVendorName={this.props.showVendorName}
              allowEdit={this.props.allowEdit}
              allowApprove={this.props.allowApprove}
              vendorName={this.props.vendorName}
              isSubsidiary={this.props.isSubsidiary}
            />
          )}
          {((this.props.riskWaivers && this.props.riskWaivers.length === 0) ||
            !this.props.riskWaivers) &&
            !isLoading &&
            this.renderEmptyState()}
          {this.props.riskWaivers && isSearchEmpty && (
            <SearchEmptyCard
              onClear={() => {
                this.setState(
                  {
                    isShowingSharedWaivers: true,
                  },
                  () => this.searchChanged("")
                );
              }}
              searchItemText={
                this.props.waiverType === WaiverType.SeverityAdjustment
                  ? "adjustments"
                  : "risk waivers"
              }
            />
          )}
        </div>
        <VendorRiskWaiverPanel
          isShowing={this.state.viewingVendorRiskWaiver !== undefined}
          waiver={this.state.viewingVendorRiskWaiver}
          onClose={() => this.setState({ viewingVendorRiskWaiver: undefined })}
          allowEdit={this.props.allowEdit}
          onEdit={() => this.updateWaiver(this.state.viewingVendorRiskWaiver)}
          onDelete={() => this.deleteWaiver(this.state.viewingVendorRiskWaiver)}
          allowApprove={this.props.allowApprove}
          onApprove={(approve: boolean) => {
            if (this.state.viewingVendorRiskWaiver) {
              this.approveWaiver(this.state.viewingVendorRiskWaiver, approve);
            }
          }}
          datastoreVendorId={this.props.vendorId ?? 0}
          vendorName={this.props.vendorName ?? ""}
          vendorWords={vendorWords}
          isSubsidiary={this.props.isSubsidiary}
        />
        <PublicWaiverPanel
          active={this.state.viewingPublicRiskWaiver !== undefined}
          onClose={() => this.setState({ viewingPublicRiskWaiver: undefined })}
          vendorId={this.props.vendorId}
          vendorName={this.props.vendorName}
          waiver={this.state.viewingPublicRiskWaiver}
          userHasWriteAccess={this.props.allowEdit}
          vendorWords={vendorWords}
        />
        <EditVendorWaiverModal
          isShowing={this.state.isShowingEditModal}
          waiver={this.state.editing}
          vendorId={
            this.props.vendorId ?? this.state.editing?.datastoreVendorID ?? 0
          }
          onClose={(didUpdate) => {
            if (didUpdate) {
              this.fetchNewData(this.state.editing);
            }
            this.setState({ isShowingEditModal: false, editing: undefined });

            return Promise.resolve();
          }}
          assuranceType={this.props.assuranceType ?? AssuranceType.None}
          managedOrgId={this.props.managedOrgId}
          isManagementAnalystSession={this.props.isManagementAnalystSession}
          isSubsidiary={this.props.isSubsidiary}
        />
        <ApproveRiskWaiverModal
          isVendorRiskWaiver
          isShowing={this.state.isShowingApproveModal}
          waiverId={this.state.approving?.id ?? 0}
          isApproving={this.state.isApproving}
          onClose={(didUpdate) => {
            if (didUpdate) {
              this.fetchNewData(this.state.approving);
            }

            this.setState({
              isShowingApproveModal: false,
              approving: undefined,
            });
          }}
          waiverType={this.props.waiverType}
          isSubsidiary={this.props.isSubsidiary}
        />
      </ReportCard>
    );
  }

  urlPrefix = (): string => {
    if (this.props.isManagementAnalystSession && this.props.managedOrgId) {
      return `/analysts/tpvm/${this.props.managedOrgId}/${this.props.vendorId}`;
    }
    return `/vendor/${this.props.vendorId}`;
  };

  renderEmptyState() {
    if (this.props.waiverType === WaiverType.SeverityAdjustment) {
      if (!this.props.hasPossibleRisks) {
        return (
          <EmptyCardWithAction
            iconSrc={IconSvg}
            emptyText={"Adjusted Risks"}
            emptySubText={`Creating a risk adjustment will modify how the risk from ${this.props.vendorName}
                        impacts their score and risk profile. At the moment only risks found in security questionnaires and evidence can be adjusted. Send a questionnaire to get started.`}
            actionButtonText={"Send questionnaire"}
            actionDisabled={!this.props.allowCreate}
            onActionClick={() => {
              const backContext = {
                goBack: true,
                backToText: `Back to risk adjustments`,
              };

              const url = `${this.urlPrefix()}/surveys/create`;

              this.props.history.push(url, { backContext });
            }}
          />
        );
      } else {
        return (
          <EmptyCardWithAction
            iconSrc={IconSvg}
            emptyText={"Adjusted Risks"}
            emptySubText={`Creating a risk adjustment will modify how the risk from ${this.props.vendorName}
                        impacts their score and risk profile. We recommend creating a risk adjustment only
                        if you have compensating control information.`}
            actionButtonText={"Adjust risk"}
            actionDisabled={!this.props.allowCreate}
            onActionClick={this.props.onCreateClick}
            supportLinkHref={
              "https://help.upguard.com/en/articles/8668528-how-to-adjust-the-severity-of-a-vendor-risk"
            }
          />
        );
      }
    } else {
      if (this.props.assessmentContext) {
        return (
          <EmptyCardWithAction
            iconSrc={IconSvg}
            emptyText={"Risk Waivers"}
            emptySubText={`At the time of this assessment, ${this.props.vendorName} has no risk waivers
                        assigned to them.`}
            actionButtonText={"Create risk waiver"}
            actionDisabled={true}
            supportLinkHref={
              "https://help.upguard.com/en/articles/4862611-what-are-vendor-risk-waivers"
            }
          />
        );
      }
      return (
        <EmptyCardWithAction
          iconSrc={IconSvg}
          emptyText={
            this.props.isSubsidiary ? "Subsidiary Risk Waivers" : "Risk Waivers"
          }
          emptySubText={
            this.props.isSubsidiary
              ? `Creating a risk waiver will remove the risk from your Subsidiary's Risk Profile. We recommend creating a risk waiver only if you are accepting a risk or have compensating control information.`
              : `Creating a risk waiver will remove the risk from ${this.props.vendorName}'s
                        risk profile. We recommend creating a risk waiver only if you are accepting
                        a risk or have compensating control information.`
          }
          actionButtonText={"Create risk waiver"}
          actionDisabled={
            !this.props.allowCreate || !this.props.hasPossibleRisks
          }
          onActionClick={this.props.onCreateClick}
          supportLinkHref={
            !this.props.isSubsidiary
              ? "https://help.upguard.com/en/articles/4862611-what-are-vendor-risk-waivers"
              : undefined // TODO: Add when published, draft: https://app.hubspot.com/knowledge/228391/edit/187882777740
          }
        />
      );
    }
  }
}

const RiskWaiverStatuses = [
  {
    id: VendorRiskWaiverStatusType.Inactive,
    text: "Inactive",
    color: LabelColor.Grey,
  },
  {
    id: VendorRiskWaiverStatusType.Active,
    text: "Active",
    color: LabelColor.Blue,
  },
  {
    id: VendorRiskWaiverStatusType.AwaitingApproval,
    text: "Awaiting Approval",
    color: LabelColor.Yellow,
  },
  {
    id: VendorRiskWaiverStatusType.Expired,
    text: "Expired",
    color: LabelColor.Red,
  },
  {
    id: VendorRiskWaiverStatusType.Cancelled,
    text: "Cancelled",
    color: LabelColor.Grey,
  },
  {
    id: VendorRiskWaiverStatusType.Rejected,
    text: "Rejected",
    color: LabelColor.Red,
  },
];

export const getRiskWaiverStatusPill = (
  waiver: RiskWaiver,
  vendorName?: string
) => {
  if (waiver.kind === "vendorRiskWaiver") {
    let sharedPill = <></>;
    if (waiver.publicWaiverId) {
      sharedPill = (
        <PillLabel
          color={LabelColor.Fuchsia}
          popupPosition={"top"}
          popupContent={
            vendorName
              ? `This waiver has been shared by ${vendorName}.`
              : undefined
          }
        >
          Shared
        </PillLabel>
      );
    }
    const status =
      RiskWaiverStatuses.find((s) => s.id === waiver.status) ??
      RiskWaiverStatuses[0];

    return (
      <>
        <PillLabel color={status.color}>{status.text}</PillLabel>
        {sharedPill}
      </>
    );
  } else {
    return getPublicRiskWaiverStatusPill(waiver, vendorName);
  }
};

interface IVendorRiskWaiversTableProps {
  isLoading: boolean;
  riskWaivers: RiskWaiver[];

  onWaiverSelected: (waiver: RiskWaiver) => void;
  onEditWaiver: (waiver: IVendorRiskWaiver) => void;
  onArchiveWaiver: (waiver: IVendorRiskWaiver) => void;
  onApproveWaiver: (waiver: IVendorRiskWaiver, approve: boolean) => void;

  includeVendorName?: boolean;
  allowEdit?: boolean;
  allowApprove?: boolean;
  vendorName?: string;
  isSubsidiary?: boolean;
}

const VendorRiskWaiversTable = (props: IVendorRiskWaiversTableProps) => {
  const [sortDir, setSortDir] = useState(SortDirection.DESC);
  const [sortCol, setSortCol] = useState("sev");

  const showSevChange = props.riskWaivers.some((w) => w.adjustedSeverity);

  const assetNoun = props.isSubsidiary ? "asset" : "Domain";

  const getRows = () => {
    const rows: IXTableRow[] = [];

    let sortedWaivers: RiskWaiver[];

    switch (sortCol) {
      case "vendorname":
        sortedWaivers = _sortBy(props.riskWaivers, (w) =>
          w.kind === "vendorRiskWaiver" ? w.vendorName : ""
        );
        break;
      case "risk":
        sortedWaivers = _sortBy(props.riskWaivers, (w) => w.riskName);
        break;
      case "status":
        sortedWaivers = _sortBy(props.riskWaivers, (w) => {
          if (
            (w.kind === "vendorRiskWaiver" &&
              (w.status === VendorRiskWaiverStatusType.AwaitingApproval ||
                w.status === VendorRiskWaiverStatusType.Rejected)) ||
            (w.kind === "publicRiskWaiver" &&
              w.status === PublicWaiverAcceptStatus.Pending)
          ) {
            return 0;
          }

          return w.status;
        });
        break;
      case "created":
        sortedWaivers = _sortBy(props.riskWaivers, (w) => w.createdAt);
        break;
      case "expiry":
        sortedWaivers = _sortBy(props.riskWaivers, (w) => w.expiresAt);
        break;
      default:
        sortedWaivers = _sortBy(props.riskWaivers, (w) => w.riskSeverity);
        break;
    }

    if (sortDir === SortDirection.DESC) {
      sortedWaivers.reverse();
    }

    const getAssetText = (count: number, assetText: string) => {
      return `${count} ${assetText}${count > 1 ? "s" : ""}`;
    };

    sortedWaivers.forEach((item) => {
      let waivedForDisplay = <></>;

      if (
        item.kind == "vendorRiskWaiver" &&
        item.riskType == VendorRiskWaiverRiskType.SecurityProfile
      ) {
        waivedForDisplay = <>Security Profile</>;
      } else if (
        item.kind == "vendorRiskWaiver" &&
        item.riskType === VendorRiskWaiverRiskType.Evidence
      ) {
        waivedForDisplay = <>Additional Evidence</>;
      } else if (
        item.riskCategory === factCategories.QuestionnaireRisks &&
        item.kind === "vendorRiskWaiver"
      ) {
        waivedForDisplay = (
          <>
            {item.isAllSurveys
              ? "All Questionnaires"
              : getAssetText(
                  item.surveys.length + item.publicSurveys.length,
                  "Questionnaire"
                )}
          </>
        );
      } else if (item.kind === "vendorRiskWaiver") {
        waivedForDisplay = (
          <>
            {item.isAllDomains
              ? `All ${assetNoun}s`
              : getAssetText(item.domains.length, assetNoun)}
          </>
        );
      } else if (item.kind === "publicRiskWaiver") {
        waivedForDisplay = <>{getAssetText(item.domains.length, assetNoun)}</>;
      }

      const cells = [
        <XTableCell key="sev">
          <AdjustedSeverityIcon
            severity={
              item.adjustedSeverity
                ? item.adjustedSeverity
                : SeverityAsString(item.riskSeverity)
            }
            baseSeverity={SeverityAsString(item.riskSeverity)}
          />
        </XTableCell>,
        <XTableCell key="risk">
          <RiskName
            riskName={item.riskName}
            riskCategoryGroupName={item.riskCategoryText}
          />
        </XTableCell>,
        <XTableCell key={"sevChange"} hide={!showSevChange}>
          {item.adjustedSeverity && (
            <SeverityChangeDisplay
              baseSeverity={SeverityAsString(item.riskSeverity)}
              severity={item.adjustedSeverity}
              hideIcons
            />
          )}
        </XTableCell>,
        <XTableCell key="waivedFor">{waivedForDisplay}</XTableCell>,
        <XTableCell key="status" className="status-cell">
          {getRiskWaiverStatusPill(item, props.vendorName)}
          {
            // Always show a private pill label for subsidiaries
            props.isSubsidiary && (
              <PublicPrivatePill
                colorOverride={LabelColor.Grey}
                privatePopupContent="This risk waiver is only visible to your organization."
                isPrivate
              />
            )
          }
        </XTableCell>,
        <XTableCell key="created">
          {formatDateAsLocal(item.createdAt)}
        </XTableCell>,
        <XTableCell key="expiry">
          {item.expiresAt ? formatDateAsLocal(item.expiresAt) : "Never"}
        </XTableCell>,
      ];

      if (props.includeVendorName) {
        const vendorNameCell = (
          <XTableCell key={"vendorname"}>
            {item.kind === "vendorRiskWaiver" ? item.vendorName : ""}
          </XTableCell>
        );

        if (props.isSubsidiary && !props.allowApprove) {
          cells.splice(2, 0, vendorNameCell);
        } else {
          cells.unshift(vendorNameCell);
        }
      }

      const options: IIconOption[] = [];

      if (props.allowEdit && item.kind === "vendorRiskWaiver") {
        if (
          item.status !== VendorRiskWaiverStatusType.Rejected &&
          item.status !== VendorRiskWaiverStatusType.Expired &&
          !item.publicWaiverId
        ) {
          options.push({
            id: "edit",
            icon: <div className={"cr-icon-pencil"} />,
            hoverText: "Edit",
            onClick: () => props.onEditWaiver(item),
          });
        }

        const isActivePublic =
          !!item.publicWaiverId &&
          item.status === VendorRiskWaiverStatusType.Active;

        options.push({
          id: "archive",
          icon: isActivePublic ? (
            <div className={"cr-icon-minus-circle"} />
          ) : (
            <div
              className={
                props.isSubsidiary ? "cr-icon-trash-2" : "cr-icon-trash"
              }
            />
          ),
          hoverText: isActivePublic ? "Reject" : "Delete",
          onClick: () => props.onArchiveWaiver(item),
        });
      }

      if (
        props.allowApprove &&
        item.status == VendorRiskWaiverStatusType.AwaitingApproval
      ) {
        options.push(
          ...[
            {
              id: "approve",
              icon: <div className={"cr-icon-accepted"} />,
              hoverText: "Approve",
              onClick: () => props.onApproveWaiver(item, true),
            },
            {
              id: "reject",
              icon: <div className={"cr-icon-exclamation"} />,
              hoverText: "Reject",
              onClick: () => props.onApproveWaiver(item, false),
            },
          ]
        );
      }

      const newRow: IXTableRow = {
        id: `${item.kind}_${item.id}`,
        onClick: () => props.onWaiverSelected(item),
        iconOptions: [
          ...options,
          {
            id: "select",
            icon: <div className={"cr-icon-chevron"} />,
            onClick: () => props.onWaiverSelected(item),
          },
        ],
        cells: cells,
      };

      rows.push(newRow);
    });

    return rows;
  };

  const colHeaders: IXTableColumnHeader[] = [
    {
      id: "sev",
      text: "Sev.",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "risk",
      text: "Risk",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "sevChange",
      text: "Sev. change",
      sortable: false,
      hide: !showSevChange,
    },
    {
      id: "waivedFor",
      text: props.isSubsidiary ? "Waived for" : "Assets affected",
      sortable: false,
    },
    {
      id: "status",
      text: "Status",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "created",
      text: "Created",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "expiry",
      text: "Expiry",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
  ];

  if (props.includeVendorName) {
    const vendorNameColumn = {
      id: "vendorname",
      text: props.isSubsidiary ? "Subsidiary" : "Vendor",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    };

    if (props.isSubsidiary && !props.allowApprove) {
      // On the Subsidiary page, we want to show the vendor name after the Risk column
      colHeaders.splice(2, 0, vendorNameColumn);
    } else {
      // On the risk approvals page, we want to show the vendor name at the beginning
      colHeaders.unshift(vendorNameColumn);
    }
  }

  return (
    <XTable
      className={"vendor-risk-waivers-table"}
      loading={props.isLoading}
      rows={getRows()}
      iconOptions
      columnHeaders={colHeaders}
      sortedBy={{ columnId: sortCol, direction: sortDir }}
      onSortChange={(col, dir) => {
        setSortCol(col);
        setSortDir(dir);
      }}
    />
  );
};

export default VendorRiskWaiversCard;

import { useCallback, useEffect, useState } from "react";
import BaseAPI, { vendorDataTag } from "../../_common/rtkQueryApi";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";
import {
  ControlDetail,
  ControlState,
  ControlTotals,
  DocumentScanProgress,
  RiskDomain,
  SecurityProfileDocument,
  SecurityProfileDocumentID,
  SecurityProfileRiskComment,
} from "../types/securityProfile";
import {
  BulkContactInfo,
  refreshSurveyListsIfNecessary,
} from "./survey.actions";
import { SurveyStatus } from "../../_common/types/survey";
import { JobStatus } from "../types/jobs";
import { skipToken } from "@reduxjs/toolkit/query";

import { useAppDispatch } from "../../_common/types/reduxHooks";
import { getAuthFromLocalStorage } from "../../_common/reducers/commonActions";
import { difference, union } from "lodash";
import vendorAssessmentAPI from "./vendorAssessmentAPI";
import { VendorAssessmentRiskComment } from "../types/vendorAssessments";
import { Patch } from "immer";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";

export interface SecurityProfileResp {
  controlTotals: ControlTotals;
  controlStates: Record<string, ControlState>;
  rawControlStates: Record<string, ControlState>;
  riskCounts: Record<string, number>;
  documentChecks: Record<string, VendorSummaryRisk>;
  scanningChecks: Record<string, VendorSummaryRisk[]>;
  surveyChecks: Record<string, VendorSummaryRisk[]>;
  currentScore: number;
  scanningScore: number;
  documentScore: number;
  lastPrimaryScanTime?: string;
  assessmentStreamID: number;
  draftAssessmentID: number;
}

export interface GetSecurityProfileDocumentsResp {
  availableDocuments: SecurityProfileDocument[];
  selectedDocuments: SecurityProfileDocumentID[];
}

interface getVendorControlDataV1Req {
  vendorId: number;
  controlId: string;
}

interface getVendorControlDataV1Resp {
  control: ControlDetail;
}

interface overrideDocumentCheckResultV1Req {
  vendorId: number;
  checks: {
    privateId?: number;
    publicId?: number;
  }[];
}

export const securityProfileTag = "securityProfileTag" as const;
export const excludedControlsTag = "selectedControlsTag" as const;
export const securityDocumentsTag = "securityDocumentsTag" as const;
export const documentScanProgressTag =
  "securityDocumentsScanProgressTag" as const;
export const gapQuestionnaireStatusTag = "gapQuestionnaireStatusTag" as const;
export const securityProfileRiskCommentsTag =
  "securityProfileRiskCommentsTag" as const;

export interface SendGapQuestionnaireReq {
  vendorId: number;
  emailText: string;
  emailSubject?: string;
  dueDate?: string;
  recipientReminderDate?: string;
  contacts: BulkContactInfo;
}

interface sendGapQuestionnaireResp {
  questionnaireId: number;
}

export interface getGapQuestionnaireStatusResp {
  questionnaireId?: number;
  dueDate?: string;
  dateLastSent?: string;
  createdAt: string;
  jobStatus?: JobStatus;
  surveyStatus?: SurveyStatus;
}

const VendorSecurityProfileAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [
    excludedControlsTag,
    securityDocumentsTag,
    documentScanProgressTag,
    securityProfileTag,
    gapQuestionnaireStatusTag,
    securityProfileRiskCommentsTag,
  ],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendorControlData: builder.query<
      getVendorControlDataV1Resp,
      getVendorControlDataV1Req
    >({
      query: ({ vendorId, controlId }) => ({
        url: "/vendor/control/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
          control_id: controlId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result
          ? [
              {
                type: vendorDataTag,
                id: vendorId,
              },
              {
                type: securityProfileTag,
                id: vendorId,
              },
            ]
          : [],
    }),

    getSecurityProfile: builder.query<
      SecurityProfileResp,
      { vendorId: number; assessmentId?: number }
    >({
      query: ({ vendorId, assessmentId }) => ({
        url: "/vendor/security_profile/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
          assessment_id: assessmentId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result
          ? [
              { type: vendorDataTag, id: vendorId },
              {
                type: securityProfileTag,
                id: vendorId,
              },
            ]
          : [],
    }),

    getExcludedControls: builder.query<
      {
        excludedControls: string[];
        excludedChecks: string[];
      },
      { vendorId: number; assessmentId?: number }
    >({
      query: ({ vendorId, assessmentId }) => ({
        url: "/vendor/security_profile/settings/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
          assessment_id: assessmentId,
        },
      }),
      providesTags: (_resp, err, { vendorId }) =>
        err ? [] : [{ type: excludedControlsTag, id: vendorId }],
    }),

    setExcludedControls: builder.mutation<
      void,
      {
        excludedControls: string[];
        vendorId: number;
      }
    >({
      query: (args) => ({
        url: "/vendor/security_profile/settings/v1/",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorSecurityProfileAPI.util.updateQueryData(
            "getExcludedControls",
            { vendorId: arg.vendorId },
            (draft) => {
              draft.excludedControls = arg.excludedControls;
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
      invalidatesTags: (_result, error, { vendorId }) =>
        !error ? [{ type: securityProfileTag, id: vendorId }] : [],
    }),

    setExcludedCheck: builder.mutation<
      void,
      {
        vendorId: number;
        checkId: string;
        include: boolean;
      }
    >({
      query: ({ vendorId, checkId, include }) => ({
        url: "/vendor/security_profile/settings/check/v1/",
        method: "PUT",
        params: {
          vendor_id: vendorId,
          check_id: checkId,
          include,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorSecurityProfileAPI.util.updateQueryData(
            "getExcludedControls",
            { vendorId: arg.vendorId },
            (draft) => {
              draft.excludedChecks = arg.include
                ? union(draft.excludedChecks, [arg.checkId])
                : difference(draft.excludedChecks, [arg.checkId]);
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
      invalidatesTags: (_result, error, { vendorId }) =>
        !error ? [{ type: securityProfileTag, id: vendorId }] : [],
    }),

    getRiskControlData: builder.query<
      { domains: RiskDomain[] },
      { assessmentId?: number }
    >({
      query: ({ assessmentId }) => ({
        url: "/controls/v1/",
        method: "GET",
        params: {
          assessment_id: assessmentId,
        },
      }),
    }),

    getSecurityDocuments: builder.query<
      GetSecurityProfileDocumentsResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/security_profile/document/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (_resp, error, { vendorId }) =>
        error ? [] : [{ type: securityDocumentsTag, id: vendorId }],
    }),

    selectDocuments: builder.mutation<
      void,
      { vendorId: number; selectedDocs: SecurityProfileDocumentID[] }
    >({
      query: (args) => ({
        url: "vendor/security_profile/evaluate/v1/",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      invalidatesTags: (_result, error, { vendorId }) =>
        !error
          ? [
              { type: documentScanProgressTag, id: vendorId },
              { type: securityProfileTag, id: vendorId },
            ]
          : [],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorSecurityProfileAPI.util.updateQueryData(
            "getSecurityDocuments",
            { vendorId: args.vendorId },
            (draft) => {
              draft.selectedDocuments = args.selectedDocs;
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    getDocumentScanProgress: builder.query<
      DocumentScanProgress,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "vendor/security_profile/evaluate/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result ? [{ type: documentScanProgressTag, id: vendorId }] : [],
    }),

    // sendGapQuestionnaireV1
    // create and send a new gap questionnaire for the current security profile state
    sendGapQuestionnaireV1: builder.mutation<
      sendGapQuestionnaireResp,
      SendGapQuestionnaireReq
    >({
      query: ({
        vendorId,
        emailText,
        emailSubject,
        dueDate,
        recipientReminderDate,
        contacts,
      }) => ({
        url: "/vendor/securityprofile/questionnaire/v1",
        method: "POST",
        body: JSON.stringify({
          vendor_id: vendorId,
          email_text: emailText,
          email_subject: emailSubject,
          due_date: dueDate,
          recipient_reminder_date: recipientReminderDate,
          contacts: contacts,
        }),
      }),
      invalidatesTags: (result, _error, { vendorId }) =>
        result ? [{ type: gapQuestionnaireStatusTag, id: vendorId }] : [],
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(refreshSurveyListsIfNecessary(arg.vendorId));
        });
      },
    }),

    // getGapQuestionnaireStatusV1
    // get the current status of the gap questionnaire
    getGapQuestionnaireStatusV1: builder.query<
      getGapQuestionnaireStatusResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/securityprofile/questionnaire/status/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result ? [{ type: gapQuestionnaireStatusTag, id: vendorId }] : [],
      onQueryStarted: async ({ vendorId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (
            data.jobStatus === JobStatus.Complete &&
            data.surveyStatus === SurveyStatus.Sent
          ) {
            dispatch(
              VendorSecurityProfileAPI.util.invalidateTags([
                { type: securityProfileTag, id: vendorId },
              ])
            );
          }
        } catch {
          // Do nothing on error
        }
      },
    }),

    overrideDocumentCheckResultV1: builder.mutation<
      void,
      overrideDocumentCheckResultV1Req
    >({
      query: (req) => ({
        url: "vendor/securityprofile/document/override/v1/",
        method: "POST",
        body: JSON.stringify(req),
      }),
      invalidatesTags: (_res, error, { vendorId }) =>
        !error ? [{ type: securityProfileTag, id: vendorId }] : [],
    }),

    getRiskComments: builder.query<
      Record<string, SecurityProfileRiskComment>,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "vendor/securityprofile/comments/v1/",
        method: "GET",
        params: { vendor_id: vendorId },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result
          ? [
              { id: vendorId, type: securityProfileRiskCommentsTag },
              { id: vendorId, type: vendorDataTag },
            ]
          : [],
    }),

    updateRiskComments: builder.mutation<
      void,
      {
        vendorAssessmentId?: number;
        vendorID: number;
        comments: {
          riskID: string;
          comment: string;
        }[];
      }
    >({
      query: (req) => {
        delete req.vendorAssessmentId;
        return {
          url: "vendor/securityprofile/comments/v1/",
          method: "POST",
          body: JSON.stringify(req),
        };
      },
      onQueryStarted: (req, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorSecurityProfileAPI.util.updateQueryData(
            "getRiskComments",
            { vendorId: req.vendorID },
            (draft) => {
              req.comments.forEach((comment) => {
                draft[comment.riskID] = draft[comment.riskID]
                  ? {
                      ...draft[comment.riskID],
                      comments: comment.comment,
                    }
                  : {
                      riskID: comment.riskID,
                      comments: comment.comment,
                      securityProfileID: 0,
                      orgID: 0,
                    };
              });
            }
          )
        );

        // also patch the vendor assessment risks
        // for some reason I can't import the type for this so have to do this instead...
        let patch2: { undo: any; patches?: Patch[]; inversePatches?: Patch[] };
        if (req.vendorAssessmentId) {
          patch2 = dispatch(
            vendorAssessmentAPI.util.updateQueryData(
              "getRisksForAssessment",
              { vendorId: req.vendorID, assessmentId: req.vendorAssessmentId },
              (draft) => {
                const allComments =
                  draft.riskComments?.reduce(
                    (map, c) => {
                      map[c.riskID] = c;
                      return map;
                    },
                    {} as Record<string, VendorAssessmentRiskComment>
                  ) ?? {};

                req.comments.forEach((c) => {
                  allComments[c.riskID] = {
                    riskID: c.riskID,
                    comments: c.comment,
                    vendorAssessmentID: req.vendorAssessmentId ?? 0,
                  };
                });

                draft.riskComments = Object.values(allComments);
              }
            )
          );
        }

        queryFulfilled.catch(() => {
          patch.undo();
          if (patch2) {
            patch2.undo();
          }
        });
      },
    }),
  }),
});

// usePolledDocumentScanProgress
// gets the document scan progress with automatic polling based on document status
export const usePolledDocumentScanProgress = (
  vendorId: number,
  documents?: string[],
  skip?: boolean
) => {
  const [pollingInterval, setPollingInterval] = useState(0);

  const result = VendorSecurityProfileAPI.useGetDocumentScanProgressQuery(
    skip
      ? skipToken
      : {
          vendorId,
        },
    { pollingInterval }
  );

  useEffect(() => {
    const fiveSeconds = 5_000;
    // If any of our documents are still in progress, keep polling.
    setPollingInterval(
      result?.data && result.data?.progress < 1 ? fiveSeconds : 0
    );
  }, [result?.data?.progress, vendorId, documents]);

  return result.data;
};

// useInvalidateSecurityProfileOnProgressChange
// Adds an effect that causes the security profile for the vendor to be invalidated and thus refetched on any progress change.
// This is a poor reflection on the API design, but invalidateSecurityProfileOnProgressChange should only be called in one component on the page.
export const useInvalidateSecurityProfileOnProgressChange = (
  vendorId: number,
  progress: number | undefined
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      VendorSecurityProfileAPI.util.invalidateTags([
        { type: securityProfileTag, id: vendorId },
      ])
    );
  }, [vendorId, progress]);
};

export const downloadUpguardDocument = (vendorId: number, uuid: string) => {
  const auth = getAuthFromLocalStorage();
  const url = `/api/vendor/securityprofile/document/download/v1/?apikey=${encodeURIComponent(
    auth.apiKey
  )}&token=${encodeURIComponent(
    auth.token
  )}&vendor_id=${vendorId}&doc_uuid=${uuid}`;
  window.open(url, "_blank");
};

// useRemoveDocument
// Hook to remove a single document, returns callback to remove the document
// and a boolean indicating if the hook is ready
export const useRemoveDocument = (vendorId: number) => {
  const { data: documents, isFetching: loading } =
    VendorSecurityProfileAPI.useGetSecurityDocumentsQuery({ vendorId });

  const [updateDocuments] =
    VendorSecurityProfileAPI.useSelectDocumentsMutation();

  const dispatch = useAppDispatch();

  const removeDocument = useCallback(
    (documentID: SecurityProfileDocumentID) => {
      if (!documents) {
        return Promise.resolve();
      }

      return updateDocuments({
        vendorId,
        selectedDocs:
          documents.selectedDocuments.filter((id) => id !== documentID) ?? [],
      })
        .unwrap()
        .then(() => {
          dispatch(addDefaultSuccessAlert("Document removed"));
        })
        .catch(() => {
          dispatch(addDefaultUnknownErrorAlert("Error removing document"));
        });
    },
    [documents, updateDocuments, vendorId]
  );

  return [removeDocument, !loading] as const;
};

export default VendorSecurityProfileAPI;

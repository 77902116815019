import {
  MergeTag,
  replaceMergeTags,
} from "../../../_common/types/orgDefaultTexts";
import { FC, memo, useCallback, useEffect, useState } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import { TooltipButton } from "../../../_common/components/core/Button";
import { useMergeTagsModal } from "../reporting/MergeTagsModal";
import "../../style/components/vendor_assessment/VendorAssessmentV3TextCard.scss";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";

interface VendorAssessmentV3TextCardProps {
  copyType: string;
  title: string;
  text: string;
  setText?: (val: string) => void;
  onSave?: (type: string, val: string) => Promise<void>;
  mergeTags: MergeTag[];
  hideEditButton?: boolean;
  disableEditButton?: boolean;
  editButtonToolTip?: string;
  hideSectionToggle?: boolean;
  disableSectionToggle?: boolean;
  goToTemplates?: () => void;
  orgHasCustomTemplatesAccess: boolean;
  loading?: boolean;
  sectionHidden?: boolean;
  onToggleHideSection?: (type: string, hidden: boolean) => void;
  noReportCard?: boolean; // prevent wrapping with ReportCard component
  textEditModeOverride?: boolean; // allow parent to control edit mode
  headerSubText?: string;
}

const VendorAssessmentV3TextCard: FC<VendorAssessmentV3TextCardProps> = (
  props: VendorAssessmentV3TextCardProps
) => {
  const [editing, setEditing] = useState(props.textEditModeOverride ?? false);
  const [openModal, mergeTagModal] = useMergeTagsModal(
    props.mergeTags,
    "risk assessment"
  );
  const [saving, setSaving] = useState(false);

  const doSave = useCallback(
    (val: string) => {
      if (props.onSave) {
        setSaving(true);
        props.onSave(props.copyType, val).finally(() => setSaving(false));
      }
    },
    [props.onSave]
  );

  useEffect(() => {
    setEditing(props.textEditModeOverride ?? false);
  }, [props.textEditModeOverride]);

  const content = (
    <div className="vendor-assessment-text-card">
      <div className={"header"}>
        <div className="header-left">
          {props.title}
          {props.headerSubText && (
            <div className="header-sub">{props.headerSubText}</div>
          )}
        </div>
        <div className={"header-right"}>
          <>
            {!props.hideSectionToggle && (
              <div className="section-toggle">
                <ToggleSwitch
                  name={"section-toggle-" + props.copyType}
                  disabled={props.disableSectionToggle}
                  selected={!props.sectionHidden}
                  onClick={
                    !props.disableSectionToggle
                      ? () => {
                          if (props.textEditModeOverride === undefined) {
                            setEditing(false);
                          }

                          props.onToggleHideSection?.(
                            props.copyType,
                            !props.sectionHidden
                          );
                        }
                      : undefined
                  }
                />
                <div className="section-toggle-label">Include section</div>
              </div>
            )}
            {!props.hideEditButton && (
              <TooltipButton
                loading={saving}
                disabled={props.sectionHidden || props.disableEditButton}
                onClick={() => {
                  setEditing((val) => !val);
                }}
                tooltipContent={props.editButtonToolTip}
                popupWidth={160}
              >
                <i className={editing ? "cr-icon-eye" : "cr-icon-pencil"} />
                {editing ? "Preview" : "Edit"}
              </TooltipButton>
            )}
          </>
        </div>
      </div>
      {props.loading ? (
        <LoadingBanner />
      ) : (
        !props.sectionHidden && (
          <div className={"contents"}>
            <RichTextEditV2
              value={
                editing
                  ? props.text
                  : // characters can be escaped with \ which is \\ in the string literal so remove them first
                    replaceMergeTags(
                      props.text.replace(/\\/g, ""),
                      props.mergeTags
                    )
              }
              onChange={(val) => {
                if (editing) {
                  props.setText ? props.setText(val) : undefined;
                  doSave(val);
                }
              }}
              readOnly={!editing}
            />
            {editing && (
              <div className={"bottom-section"}>
                <div className={"buttons"}>
                  Insert{" "}
                  <span className={"underline"} onClick={openModal}>
                    merge tags
                  </span>{" "}
                  to prefill commentary for this risk assessment{" "}
                  {props.orgHasCustomTemplatesAccess && props.goToTemplates && (
                    <>
                      or{" "}
                      <span
                        className={"underline"}
                        onClick={props.goToTemplates}
                      >
                        update template
                      </span>{" "}
                      to apply updates to future risk assessments
                    </>
                  )}
                </div>
                <div className={"remaining"}>
                  {10000 - props.text.length} of 10000 characters remaining
                </div>
              </div>
            )}
          </div>
        )
      )}
      {mergeTagModal}
    </div>
  );

  return props.noReportCard ? (
    content
  ) : (
    <ReportCard newStyles>{content}</ReportCard>
  );
};

export default memo(VendorAssessmentV3TextCard);

import { FC } from "react";
import Button from "../components/core/Button";
import { trackEvent } from "../tracking";
import { Product } from "../types/products";
import { organisationAccountType } from "../types/organisations";
import { ProductStatus, SelfServiceAPI } from "../api/selfServiceAPI";

interface TrialExpiryBannerProps {
  fallbackTrialMessage?: string; // Optional fallback message if product status doesn't have dates
  isSelfService: boolean;
  isVendorTrial: boolean;
  currentProduct: Product | undefined;
  onUpgradeClick: () => void;
  onContactExpertClick: () => void;
  onContactSalesClick: () => void;
  // Following props are needed for conditional display logic
  orgAccountType?: organisationAccountType;
  orgTrialEndDate?: Date; // Optional trial end date from org for fallback
}

// Define a type for product status objects
type ProductStatusObject = {
  status: ProductStatus;
  currentStatusEnd?: string | null;
};

export const getTrialMessage = (
  breachSight: ProductStatusObject | null | undefined,
  vendorRisk: ProductStatusObject | null | undefined,
  currentProduct: Product | undefined,
  orgTrialEndDate?: Date,
  fallbackTrialMessage = "Trial ending soon"
): string => {
  // If we don't have product status data, use organization trial end date if available
  if (!breachSight || !vendorRisk || !currentProduct) {
    if (orgTrialEndDate) {
      const now = new Date();
      const daysLeft = Math.ceil(
        (orgTrialEndDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
      );

      if (daysLeft <= 0) return "Your trial has expired";

      return `${daysLeft} day${daysLeft !== 1 ? "s" : ""} left in your trial`;
    }
    return fallbackTrialMessage;
  }

  // Determine which product status to use based on current page and trial status
  let statusToUse: ProductStatusObject | null = null;

  if (
    currentProduct === Product.BreachSight &&
    breachSight.status === ProductStatus.Trial
  ) {
    statusToUse = breachSight;
  } else if (
    currentProduct === Product.VendorRisk &&
    vendorRisk.status === ProductStatus.Trial
  ) {
    statusToUse = vendorRisk;
  } else if (
    breachSight.status === ProductStatus.Trial &&
    vendorRisk.status === ProductStatus.Trial
  ) {
    // If both products are in trial, use the one with the earlier expiration date
    const breachSightEnd = breachSight.currentStatusEnd
      ? new Date(breachSight.currentStatusEnd)
      : null;
    const vendorRiskEnd = vendorRisk.currentStatusEnd
      ? new Date(vendorRisk.currentStatusEnd)
      : null;

    if (breachSightEnd && vendorRiskEnd) {
      statusToUse = breachSightEnd < vendorRiskEnd ? breachSight : vendorRisk;
    } else if (breachSightEnd) {
      statusToUse = breachSight;
    } else if (vendorRiskEnd) {
      statusToUse = vendorRisk;
    } else {
      return fallbackTrialMessage;
    }
  } else {
    return fallbackTrialMessage;
  }

  // If we have a specific product status to use with an end date
  if (statusToUse.currentStatusEnd) {
    const endDate = new Date(statusToUse.currentStatusEnd);
    const now = new Date();
    const daysLeft = Math.ceil(
      (endDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (daysLeft <= 0) return "Your trial has expired";

    return `${daysLeft} day${daysLeft !== 1 ? "s" : ""} left in your trial`;
  }

  return fallbackTrialMessage;
};

export const shouldShowBanner = (
  breachSight: ProductStatusObject | null | undefined,
  vendorRisk: ProductStatusObject | null | undefined,
  currentProduct: Product | undefined,
  isLoading: boolean,
  orgAccountType?: organisationAccountType
): boolean => {
  // If we're still loading product status, check org account type as fallback
  if (isLoading) {
    return orgAccountType === organisationAccountType.trial;
  }

  // If we don't have product status info after loading completed, check org account type
  if (!breachSight || !vendorRisk || !currentProduct) {
    return orgAccountType === organisationAccountType.trial;
  }

  const breachSightIsPaid = breachSight.status === ProductStatus.Paid;
  const breachSightIsTrial = breachSight.status === ProductStatus.Trial;
  const vendorRiskIsPaid = vendorRisk.status === ProductStatus.Paid;
  const vendorRiskIsTrial = vendorRisk.status === ProductStatus.Trial;

  // Both products in trial: Show on all pages
  if (breachSightIsTrial && vendorRiskIsTrial) {
    return true;
  }

  // Breach Risk in trial, Vendor Risk paid: Show on Breach Risk pages
  if (
    breachSightIsTrial &&
    vendorRiskIsPaid &&
    currentProduct === Product.BreachSight
  ) {
    return true;
  }

  // Vendor Risk in trial, Breach Risk paid: Show on Vendor Risk pages
  if (
    vendorRiskIsTrial &&
    breachSightIsPaid &&
    currentProduct === Product.VendorRisk
  ) {
    return true;
  }

  // Free Vendor Risk: Show on Vendor Risk pages
  if (
    vendorRisk.status === ProductStatus.Free &&
    currentProduct === Product.VendorRisk
  ) {
    return true;
  }

  return false;
};

const TrialExpiryBanner: FC<TrialExpiryBannerProps> = ({
  fallbackTrialMessage = "Trial ending soon",
  isSelfService,
  isVendorTrial,
  currentProduct,
  orgAccountType,
  orgTrialEndDate,
  onUpgradeClick,
  onContactExpertClick,
  onContactSalesClick,
}) => {
  // Fetch product status data directly in the component
  const { data: productStatus, isLoading } =
    SelfServiceAPI.useGetSelfServiceProductsV1Query();

  const breachSight = productStatus?.breachSight;
  const vendorRisk = productStatus?.vendorRisk;

  if (
    !shouldShowBanner(
      breachSight,
      vendorRisk,
      currentProduct,
      isLoading,
      orgAccountType
    )
  ) {
    return null;
  }

  const trialMessage = getTrialMessage(
    breachSight,
    vendorRisk,
    currentProduct,
    orgTrialEndDate,
    fallbackTrialMessage
  );

  return (
    <div className="trial-expiry-banner" data-testid="trial-expiry-banner">
      <div className={"trial-expiry-banner-text"}>
        <div className="cr-icon-hourglass trial-expiry-banner-icon" />
        {trialMessage}
      </div>
      {isSelfService || isVendorTrial ? (
        <>
          <Button tertiarySecondary rounded onClick={onUpgradeClick}>
            {isSelfService ? "Upgrade" : "Book a call"}
          </Button>
          <Button
            tertiaryFilled
            rounded
            onClick={() => {
              trackEvent("Nav_Sales_CTA");
              onContactExpertClick();
            }}
          >
            Talk to an expert
          </Button>
        </>
      ) : (
        <Button tertiary onClick={onContactSalesClick}>
          Contact us
          <div className="cr-icon-arrow-right" />
        </Button>
      )}
    </div>
  );
};

export default TrialExpiryBanner;

import { FC } from "react";
import "../style/TrustPageHeader.scss";
import Button from "../../_common/components/core/Button";
import TrustPageLogo, { TrustPageLogoSize } from "./TrustPageLogo";
import { ProfileLogo } from "../../vendorrisk/types/sharedAssessment";

interface TrustPageHeaderProps {
  vendorName?: string;
  profileLogoType?: ProfileLogo;
  profileLogoUrl?: string;
  primaryHostName?: string;
  primaryContact?: string;
  emailAddress?: string;
  canRequestAccess: boolean;
  onRequestAccess: () => void;
}

const TrustPageHeader: FC<TrustPageHeaderProps> = ({
  vendorName,
  profileLogoType = ProfileLogo.DefaultLogo,
  profileLogoUrl,
  primaryHostName,
  primaryContact,
  emailAddress,
  canRequestAccess,
  onRequestAccess,
}) => {
  return (
    <div className="trust-page-header" data-testid={"trust-page-header"}>
      <TrustPageLogo
        vendorName={vendorName}
        profileLogoType={profileLogoType}
        profileLogoUrl={profileLogoUrl}
        size={TrustPageLogoSize.Large}
      />

      <div className="company-details-wrapper">
        {vendorName && (
          <div className={"vendor-title"}>
            <span>{vendorName}</span>
            <span className={"trust-page"}>Trust Page</span>
          </div>
        )}
        <div className="inner-content">
          <div className={"contact-details"}>
            {primaryHostName && (
              <a
                href={`https://${primaryHostName}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className={"cr-icon-link-2"} />
                <span>{primaryHostName}</span>
              </a>
            )}
            {primaryContact && (
              <div>
                <i className="cr-icon-account-circle" />
                <span>{primaryContact}</span>
              </div>
            )}
            {emailAddress && (
              <a href={`mailto:${emailAddress}`}>
                <i className="cr-icon-envelope" />
                <span>{emailAddress}</span>
              </a>
            )}
          </div>
          {canRequestAccess && (
            <Button
              className={"request-access-btn"}
              primary={true}
              onClick={onRequestAccess}
            >
              <span className="cr-icon-lock" />
              Request Access
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrustPageHeader;

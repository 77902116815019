import { RiskNode } from "../types/types";
import "../style/RiskQuestion.scss";
import { Severity } from "../../_common/types/severity";
import SeverityIcon from "../../_common/components/SeverityIcon";
import { ValidationError } from "../types/validation";
import { AppDispatch } from "../../_common/types/reduxStore";

export const getMissingFieldsTextForRiskQuestion = (validationErrors: {
  [validationError: string]: true;
}): string => {
  const missingFieldNames = [];

  if (validationErrors[ValidationError.riskMissingName]) {
    missingFieldNames.push("Finding");
  }
  if (validationErrors[ValidationError.riskMissingPassName]) {
    missingFieldNames.push("Finding-Pass");
  }
  if (validationErrors[ValidationError.riskMissingMainText]) {
    missingFieldNames.push("Description");
  }
  if (validationErrors[ValidationError.riskMissingCategory]) {
    missingFieldNames.push("Risk");
  }
  if (validationErrors[ValidationError.riskMissingWhy]) {
    missingFieldNames.push("Compensating control information");
  }

  return "Required fields are missing: " + missingFieldNames.join(", ");
};

export interface IRiskQuestionIconProps {
  severity: Severity;
  questionNumber: string;
}

export const RiskQuestionIcon = (props: IRiskQuestionIconProps) => (
  <div className="risk-question-icon">
    <SeverityIcon severity={props.severity} /> {props.questionNumber}
  </div>
);

export interface IRiskQuestionProps {
  dispatch: AppDispatch;
  node: RiskNode;
}

const RiskQuestion = (props: IRiskQuestionProps) => {
  return (
    <div className="risk-question">
      <p>{props.node.mainText}</p>
    </div>
  );
};

export default RiskQuestion;

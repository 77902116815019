import "./FilterPanel.scss";

import { ReactNode, useMemo, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { cloneDeep as _cloneDeep } from "lodash";
import classnames from "classnames";

import { useCurrentUser } from "../../_common/selectors/commonSelectors";
import { SelectV2, SelectV2Multi } from "../../_common/components/SelectV2";
import Button from "../../_common/components/core/Button";
import Icon from "../../_common/components/core/Icon";
import UserAvatar from "../../_common/components/UserAvatar";
import { noAssigneeSVG } from "../../_common/components/UserAssignment";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import IconButton from "../../_common/components/IconButton";
import { Severity } from "../../_common/types/severity";
import { LabelColor } from "../../_common/types/label";
import { severityMap } from "../../_common/helpers";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import PillLabel from "../../vendorrisk/components/PillLabel";
import {
  ThreatMonitoringResultsDatePeriodOption,
  ThreatMonitoringResultsFilter,
  ThreatMonitoringThreatType,
  ThreatMonitoringSourceType,
  DisplayableThreatMonitoringResultStatus,
  DisplayableThreatMonitoringResultStatusToLabel,
  ThreatMonitoringFeedType,
  BreachRiskUserName,
  filterableThreatTypesForModule,
  ThreatMonitoringModule,
} from "../api/types";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import tmSlice, { Module, defaultFilters, maxDatePeriod } from "../Slice";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import CircledIcon from "../../_common/components/CircledIcon";
import _sortBy from "lodash/sortBy";
import TextFieldPerformant from "../../_common/components/TextFieldPerformant";
import { threatTypeToName } from "../funcs/domain";

interface User {
  id: number;
  name: string;
  avatar: string;
}

interface FilterPanelProps {
  feedType: ThreatMonitoringFeedType;
  filters: ThreatMonitoringResultsFilter;
  dateFilterTitle?: string;
  hidden?: boolean;
  module: Module;
}

const FilterPanel = ({
  feedType,
  filters,
  dateFilterTitle,
  hidden,
  module,
}: FilterPanelProps) => {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(
    (state) =>
      tmSlice.selectSlice(state).modules[module].feeds[feedType].filtersExpanded
  );
  const setExpanded = (expanded: boolean) => {
    dispatch(
      tmSlice.actions.updateFeedState({
        module,
        feed: feedType,
        state: { filtersExpanded: expanded },
      })
    );
  };

  const { data: userData } = ThreatMonitoringAPI.useGetReadWriteUsersV1Query();

  const { data: keywordsData } = ThreatMonitoringAPI.useGetKeywordsV1Query({});

  const allUsers = userData?.users || [];
  const keywords = keywordsData?.keywords || [];

  const filtersAreDefault = areFiltersDefaultExcludingDate(
    filters,
    feedType,
    module
  );

  const squashArray = <K,>(k?: K[]) => (k?.length ? k : undefined);
  const updateFilters = (filters: Partial<ThreatMonitoringResultsFilter>) =>
    dispatch(
      tmSlice.actions.updatePageFilters({
        module,
        feed: feedType,
        filters,
      })
    );

  const sep = <div className="filter-separator" />;

  const filterComponents: ReactNode[] = [
    <div className="filter-container" key="date-filter">
      <div className="title">{dateFilterTitle || "Date"}</div>
      <SelectV2
        options={getAllDateFilterOptions()}
        value={getDateFilterOptionByValue(filters.datePeriod)}
        onChange={(v) => {
          updateFilters({
            datePeriod: v?.value || filters.datePeriod,
          });
        }}
        controlShouldRenderValue={true}
        captureMenuScroll={false}
      />
    </div>,
    sep,
    <div className="refresh-filters-button" key="refresh-filters-button">
      <Button
        tertiary
        onClick={(ev) => {
          ev.preventDefault();
          dispatch(
            tmSlice.actions.resetPageFiltersExcludingDateFilter({
              module,
              feed: feedType,
            })
          );
        }}
        disabled={filtersAreDefault}
      >
        <span className="icon-refresh" />
        <span>Reset filters</span>
      </Button>
    </div>,
    <KeywordFilter
      key="keyword-filter"
      options={keywords
        ?.filter((k) => k.active)
        ?.map((k) => {
          return k.keyword;
        })}
      checkedValues={filters.keywords}
      onCheckedValuesChange={(newCheckedValues) => {
        updateFilters({
          keywords: squashArray(newCheckedValues),
        });
      }}
    />,
    sep,
    <SeverityFilter
      key="severity-filter"
      checkedValues={filters.severities}
      onCheckedValuesChange={(newCheckedValues) => {
        updateFilters({
          severities: squashArray(newCheckedValues),
        });
      }}
    />,
    sep,
    <ThreatTypeFilter
      key="threat-type-filter"
      module={module}
      checkedValues={filters.threatTypes}
      onCheckedValuesChange={(newCheckedValues) => {
        updateFilters({
          threatTypes: squashArray(newCheckedValues),
        });
      }}
    />,
    sep,
    <SourceTypeFilter
      key="source-type-filter"
      module={module}
      checkedValues={filters.sourceTypes}
      onCheckedValuesChange={(newCheckedValues) => {
        updateFilters({
          sourceTypes: squashArray(newCheckedValues),
        });
      }}
    />,
    sep,
    <CollapsableFilter title="Text" startExpanded={true} key="text-filter">
      <div className="text-filter-label">Contains</div>
      <TextFilter
        value={filters.containsText}
        maxLength={120}
        onValidChange={(val: string) => {
          updateFilters({
            containsText: val ? val : undefined,
          });
        }}
      />
      <div className="text-filter-label">Does not contain</div>
      <TextFilter
        value={filters.doesNotContainText}
        maxLength={120}
        onValidChange={(val: string) => {
          updateFilters({
            doesNotContainText: val ? val : undefined,
          });
        }}
      />
    </CollapsableFilter>,
  ];

  if (feedType === ThreatMonitoringFeedType.Investigating) {
    filterComponents.push(
      sep,
      <InvestigatorFilter
        key="investigator-filter"
        allUsers={allUsers}
        filters={filters}
        onFiltersChange={updateFilters}
      />
    );
  }

  if (feedType === ThreatMonitoringFeedType.Remediating) {
    filterComponents.push(
      sep,
      <RemediatorFilter
        key="remediator-filter"
        allUsers={allUsers}
        filters={filters}
        onFiltersChange={updateFilters}
      />
    );
  }

  if (feedType === ThreatMonitoringFeedType.Closed) {
    filterComponents.push(
      sep,
      <ClosedByFilter
        key="closed-by-filter"
        allUsers={allUsers}
        filters={filters}
        onFiltersChange={updateFilters}
      />,
      sep,
      <StatusFilter
        key="status-filter"
        checkedValues={filters.statuses}
        onCheckedValuesChange={(newCheckedValues) => {
          updateFilters({
            statuses: squashArray(newCheckedValues),
          });
        }}
      />
    );
  }

  return (
    <FilterPanelShell
      expanded={expanded}
      setExpanded={setExpanded}
      hidden={hidden}
      filtersAreDefault={filtersAreDefault}
      filters={filterComponents}
    />
  );
};

interface FilterPanelShellProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  hidden?: boolean;
  filtersAreDefault: boolean;
  filters: ReactNode[];
}

function FilterPanelShell({
  expanded,
  setExpanded,
  hidden,
  filtersAreDefault,
  filters,
}: FilterPanelShellProps) {
  return (
    <div
      className={classnames("threat-monitoring-filter-panel", {
        expanded: expanded,
        collapsed: !expanded,
      })}
      hidden={hidden}
    >
      <div
        className={classnames("header", {
          expanded: expanded,
          collapsed: !expanded,
        })}
        onClick={() => {
          if (!expanded) {
            setExpanded(!expanded);
          }
        }}
      >
        <div
          className={classnames("title", {
            "no-display": !expanded,
          })}
        >
          <span className="cr-icon-filter" />
          <h5>Filters</h5>
        </div>
        <SidePopupV2
          text={expanded ? "Collapse filters" : "Expand filters"}
          position={"right"}
          width={expanded ? 130 : 140}
          transitionTimeout={{
            appear: 250,
            enter: 0,
            exit: 0,
          }}
        >
          <IconButton
            className={"navigation-expander"}
            icon={
              <div
                className={classnames(
                  "cr-icon-expander",
                  "nav-expander-button",
                  {
                    "rotate-180": expanded,
                  }
                )}
              />
            }
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </SidePopupV2>
        {!expanded && <div className="collapsed-title">FILTERS</div>}
        {!expanded && !filtersAreDefault && (
          <div className="active-filters-circle" />
        )}
      </div>
      <div className="content" hidden={!expanded}>
        <div className="filters-container">{filters}</div>
      </div>
    </div>
  );
}

export default FilterPanel;

const checkboxColour = "#CCCFE0";

interface dateFilterOption {
  value: ThreatMonitoringResultsDatePeriodOption;
  label: string;
}

const getAllDateFilterOptions = (): dateFilterOption[] => {
  const options = [
    {
      value: ThreatMonitoringResultsDatePeriodOption.AllTime,
      label: "All time",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastTwentyFourHours,
      label: "Last 24 hours",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastSevenDays,
      label: "Last 7 days",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
      label: "Last 30 days",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastThreeMonths,
      label: "Last 3 months",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastSixMonths,
      label: "Last 6 months",
    },
    {
      value: ThreatMonitoringResultsDatePeriodOption.LastTwelveMonths,
      label: "Last 12 months",
    },
  ];

  return options;
};

const getDateFilterOptionByValue = (
  value: ThreatMonitoringResultsDatePeriodOption
): dateFilterOption | undefined => {
  return getAllDateFilterOptions().find((o) => o.value === value);
};

interface collapsableFilterProps {
  title: string;
  startExpanded?: boolean;
  children: ReactNode;
}

// CollapsableFilter wraps a filter control in a collapsable section.
const CollapsableFilter = (props: collapsableFilterProps) => {
  const [expanded, setExpanded] = useState(props.startExpanded || false);

  return (
    <div className="filter-container">
      <div
        className="clickable-title"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <span>{props.title}</span>
        <Icon name="chevron" direction={expanded ? 0 : 180} />
      </div>
      <TransitionGroup component={null}>
        {expanded && (
          <CSSTransition
            key={"expand-section"}
            timeout={250}
            classNames="expand"
          >
            <div className="section-body">{props.children}</div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

// multiSelectFilterOption contains option data in a multi-select control used for filtering.
type multiSelectFilterOption<T> = {
  // Used for rendering a list in React & should be unique within it's siblings.
  key: string;

  // The label to show next to the checkbox.
  label: JSX.Element;

  disabled?: boolean;
} & (
  | {
      // The value to toggle, or undefined if nestedOptions is provided
      value: T;
      nestedOptions?: undefined;
    }
  | {
      value?: undefined;
      // The options nested under this option, or undefined if value is provided.
      nestedOptions: multiSelectFilterOption<T>[];
    }
);

type OptionsSorter<T> = (
  checkedOptions: T[],
  options: multiSelectFilterOption<T>[]
) => multiSelectFilterOption<T>[];

const nullOptionsSorter: OptionsSorter<any> = (_checkedOptions, options) =>
  options;

// countAllLeafNodeMultiSelectFilterOptions traverses multiSelectFilterOption, counting all
// multiSelectFilterOptions that don't have any nested options.
const countAllLeafNodeMultiSelectFilterOptions = function <T>(
  options: multiSelectFilterOption<T>[]
): number {
  let count = 0;

  for (let a = 0; a < options.length; a++) {
    const option = options[a];

    if (!option.nestedOptions) {
      if (!option.disabled) {
        count += 1;
      }
    } else {
      count += countAllLeafNodeMultiSelectFilterOptions(option.nestedOptions);
    }
  }

  return count;
};

interface MultiSelectFilterOptionCheckboxProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  option: multiSelectFilterOption<T>;
  disabled?: boolean;
}

// MultiSelectFilterOptionCheckbox is a checkbox used in a multi select control to choose one option for filtering.
const MultiSelectFilterOptionCheckbox = function <T>(
  props: MultiSelectFilterOptionCheckboxProps<T>
) {
  if (!props.option.value) {
    return <></>;
  }

  return (
    <ColorCheckbox
      color={checkboxColour}
      label={props.option.label}
      checked={props.checkedValues?.includes(props.option.value)}
      onClick={() => {
        if (!props.option.value) {
          return;
        }

        let newCheckedValues = _cloneDeep(props.checkedValues);

        if (!newCheckedValues) {
          newCheckedValues = [];
        }

        if (newCheckedValues?.includes(props.option.value)) {
          newCheckedValues.splice(
            newCheckedValues.indexOf(props.option.value),
            1
          );
        } else {
          newCheckedValues?.push(props.option.value);
        }

        props.onCheckedValuesChange(newCheckedValues);
      }}
      disabled={props.disabled}
    />
  );
};

interface MultiSelectFilterOptionsProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  options: multiSelectFilterOption<T>[];
  disabled?: boolean;
  maxNumberInitialOptions?: number; // if > 0, we'll only display this many followed by a 'show more...' option
  optionSorter?: OptionsSorter<T>;
}

export function calculateLimits<T, U>(
  limited: boolean,
  maxNumberInitialOptions: number | undefined,
  checkedValues: T[],
  options: U[]
) {
  let numberToShow: number;
  let showMoreLessButton: boolean;

  // no limit
  if (
    maxNumberInitialOptions === undefined ||
    maxNumberInitialOptions >= options.length
  ) {
    numberToShow = options.length;
    showMoreLessButton = false;
  } else if (checkedValues.length === options.length) {
    numberToShow = options.length;
    showMoreLessButton = false;

    // limit to length of checked values
  } else if (checkedValues.length > maxNumberInitialOptions) {
    numberToShow = limited ? checkedValues.length : options.length;
    showMoreLessButton = true;

    // limit to passed limit
  } else {
    numberToShow = limited ? maxNumberInitialOptions : options.length;
    showMoreLessButton = true;
  }

  return [numberToShow, showMoreLessButton] as const;
}

// MultiSelectFilterOptions renders props.options in a multi select control.
const MultiSelectFilterOptions = function <T>({
  maxNumberInitialOptions,
  options,
  disabled,
  onCheckedValuesChange,
  checkedValues = [],
  optionSorter = nullOptionsSorter,
}: MultiSelectFilterOptionsProps<T>) {
  const [limited, setLimited] = useState(true);

  const sortedOptions = useMemo(
    () => optionSorter(checkedValues, options),
    [checkedValues, options]
  );

  const [numToShow, showMoreLessButton] = calculateLimits(
    limited,
    maxNumberInitialOptions,
    checkedValues,
    options
  );

  return (
    <>
      <ul>
        {sortedOptions.slice(0, numToShow).map((o) => (
          <li key={o.key}>
            {o.nestedOptions && (
              <MultiSelectNestedFilterOptions
                checkedValues={checkedValues}
                onCheckedValuesChange={onCheckedValuesChange}
                options={o.nestedOptions}
                label={o.label}
                disabled={disabled}
              />
            )}
            {!o.nestedOptions && (
              <MultiSelectFilterOptionCheckbox
                checkedValues={checkedValues}
                onCheckedValuesChange={onCheckedValuesChange}
                option={o}
                disabled={o.disabled || disabled}
              />
            )}
          </li>
        ))}
      </ul>
      {showMoreLessButton && (
        <Button
          key={"showmore"}
          tertiary
          onClick={() => {
            setLimited(!limited);
          }}
          data-testid="show-more-button"
        >
          <div className={"show-more"}>
            {limited ? (
              <div className={"cr-icon-chevron rotate-90"} />
            ) : (
              <div className={"cr-icon-chevron rotate-270"} />
            )}
            {!limited ? `Show less` : `Show ${options.length - numToShow} more`}
          </div>
        </Button>
      )}
    </>
  );
};

interface MultiSelectNestedFilterOptionsProps<T> {
  checkedValues?: T[];
  onCheckedValuesChange: (newCheckedValues: T[]) => void;
  options: multiSelectFilterOption<T>[];
  label: JSX.Element;
  disabled?: boolean;
}

// MultiSelectNestedFilterOptions renders a single filtering option with nested filtering options in a multi select control.
const MultiSelectNestedFilterOptions = function <T>(
  props: MultiSelectNestedFilterOptionsProps<T>
) {
  const [expanded, setExpanded] = useState(true);

  let numberOfOptionsChecked = 0;
  if (props.checkedValues) {
    for (let a = 0; a < props.checkedValues.length; a++) {
      for (let b = 0; b < props.options.length; b++) {
        if (props.checkedValues[a] === props.options[b].value) {
          numberOfOptionsChecked += 1;
          break;
        }
      }
    }
  }

  const allValuesSelected = numberOfOptionsChecked === props.options.length;
  const someValuesSelected = !allValuesSelected && numberOfOptionsChecked > 0;

  return (
    <div className="nested-filter-option">
      <div
        className="filter-option-label"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <ColorCheckbox
          color={checkboxColour}
          label={
            <>
              {props.label}
              <Icon name="chevron" direction={expanded ? 0 : 180} />
            </>
          }
          indeterminate={someValuesSelected}
          checked={allValuesSelected}
          onClick={(ev) => {
            // If the checkbox was clicked
            if ((ev.target as HTMLDivElement).className === "box") {
              ev.stopPropagation();

              const setOptionsChecked = !allValuesSelected;

              let newCheckedValues: T[] = [];
              if (props.checkedValues) {
                newCheckedValues = _cloneDeep(props.checkedValues);
              }

              for (let a = 0; a < props.options.length; a++) {
                const option = props.options[a];
                if (!option.value) {
                  continue;
                }

                const isOptionChecked = newCheckedValues.includes(option.value);

                if (isOptionChecked && !setOptionsChecked) {
                  newCheckedValues.splice(
                    newCheckedValues.indexOf(option.value),
                    1
                  );
                } else if (!isOptionChecked && setOptionsChecked) {
                  newCheckedValues?.push(option.value);
                }
              }

              props.onCheckedValuesChange(newCheckedValues);
            }
          }}
          disabled={props.disabled}
        />
      </div>
      <TransitionGroup component={null}>
        {expanded && (
          <CSSTransition
            key={"expand-section"}
            timeout={250}
            classNames="expand"
          >
            <div className="section-body">
              <div className="nested-filter-option-spacer"></div>
              <MultiSelectFilterOptions
                checkedValues={props.checkedValues}
                onCheckedValuesChange={props.onCheckedValuesChange}
                options={props.options}
                disabled={props.disabled}
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

interface CollapsableMultiSelectFilterProps<T>
  extends MultiSelectFilterOptionsProps<T> {
  name: string;
}

// CollapsableMultiSelectFilter is a control used to choose one or more options for filtering.
const CollapsableMultiSelectFilter = function <T>({
  name,
  options,
  checkedValues,
  ...props
}: CollapsableMultiSelectFilterProps<T>) {
  const maxNumberOfOptions = countAllLeafNodeMultiSelectFilterOptions(options);

  if (maxNumberOfOptions > 0) {
    name += ` (${
      (checkedValues ? checkedValues.length : 0) || maxNumberOfOptions
    }/${maxNumberOfOptions})`;
  }

  return (
    <CollapsableFilter title={name} startExpanded={true}>
      <MultiSelectFilterOptions
        checkedValues={checkedValues}
        options={options}
        {...props}
      />
    </CollapsableFilter>
  );
};

interface severityOption {
  value: Severity;
  label: string;
  icon: JSX.Element;
}

export const GetAllSeverityFilterOptions = (): severityOption[] => {
  return [
    {
      value: Severity.Critical,
      label: "Critical",
      icon: severityMap[Severity.Critical].icon,
    },
    {
      value: Severity.High,
      label: "High",
      icon: severityMap[Severity.High].icon,
    },
    {
      value: Severity.Medium,
      label: "Medium",
      icon: severityMap[Severity.Medium].icon,
    },
    {
      value: Severity.Low,
      label: "Low",
      icon: severityMap[Severity.Low].icon,
    },
  ];
};

interface SeverityFilterProps {
  checkedValues?: Severity[];
  onCheckedValuesChange: (newCheckedValues: Severity[]) => void;
}

// SeverityFilter is a control used to choose one or more severities for filtering.
const SeverityFilter = (props: SeverityFilterProps) => {
  const options: multiSelectFilterOption<Severity>[] =
    GetAllSeverityFilterOptions().map((o) => {
      return {
        key: o.value as string,
        value: o.value,
        label: (
          <>
            {o.icon}
            <span>{o.label}</span>
          </>
        ),
      };
    });

  return (
    <CollapsableMultiSelectFilter<Severity>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      options={options}
      name="Severity"
    />
  );
};

interface ThreatTypeFilterProps {
  checkedValues?: ThreatMonitoringThreatType[];
  onCheckedValuesChange: (
    newCheckedValues: ThreatMonitoringThreatType[]
  ) => void;
  module: Module;
}

// ThreatTypeFilter is a control used to choose one or more threat types for filtering.
const ThreatTypeFilter = ({
  module,
  checkedValues,
  onCheckedValuesChange,
}: ThreatTypeFilterProps) => {
  const filterableThreatTypes = filterableThreatTypesForModule(module);

  const options: multiSelectFilterOption<ThreatMonitoringThreatType>[] =
    filterableThreatTypes.map((o) => {
      return {
        key: o,
        value: o,
        label: (
          <>
            <span>{threatTypeToName[o]}</span>
          </>
        ),
      };
    });

  return (
    <CollapsableMultiSelectFilter<ThreatMonitoringThreatType>
      checkedValues={checkedValues}
      onCheckedValuesChange={onCheckedValuesChange}
      options={options}
      name="Threat type"
    />
  );
};

interface SourceTypeFilterProps {
  module: Module;
  checkedValues?: ThreatMonitoringSourceType[];
  onCheckedValuesChange: (
    newCheckedValues: ThreatMonitoringSourceType[]
  ) => void;
}

type sourceTypeFilterOption =
  multiSelectFilterOption<ThreatMonitoringSourceType> & {
    modules: Module[];
  };

const sourceTypeFilterOptions: sourceTypeFilterOption[] = [
  {
    key: ThreatMonitoringSourceType.Forum,
    value: ThreatMonitoringSourceType.Forum,
    label: <span>Forum</span>,
    modules: [ThreatMonitoringModule.ModuleDarkWeb],
  },
  {
    key: ThreatMonitoringSourceType.Github,
    value: ThreatMonitoringSourceType.Github,
    label: <span>Github</span>,
    modules: [ThreatMonitoringModule.ModuleDataLeaks],
  },
  {
    key: ThreatMonitoringSourceType.Marketplace,
    value: ThreatMonitoringSourceType.Marketplace,
    label: <span>Illicit marketplace</span>,
    modules: [ThreatMonitoringModule.ModuleDarkWeb],
  },
  {
    key: "messaging_platforms",
    label: <span>Messaging platforms</span>,
    modules: [ThreatMonitoringModule.ModuleDarkWeb],
    nestedOptions: [
      {
        key: ThreatMonitoringSourceType.Discord,
        value: ThreatMonitoringSourceType.Discord,
        label: <span>Discord</span>,
      },
      {
        key: ThreatMonitoringSourceType.Telegram,
        value: ThreatMonitoringSourceType.Telegram,
        label: <span>Telegram</span>,
      },
    ],
  },
  {
    key: ThreatMonitoringSourceType.Paste,
    value: ThreatMonitoringSourceType.Paste,
    label: <span>Paste site</span>,
    modules: [ThreatMonitoringModule.ModuleDarkWeb],
  },
  {
    key: ThreatMonitoringSourceType.RansomwareBlog,
    value: ThreatMonitoringSourceType.RansomwareBlog,
    label: <span>Ransomware blog</span>,
    modules: [ThreatMonitoringModule.ModuleDarkWeb],
  },
  {
    key: ThreatMonitoringSourceType.SocialMedia,
    label: <span>Social media</span>,
    modules: [ThreatMonitoringModule.ModuleSocialMedia],
    nestedOptions: [
      {
        key: ThreatMonitoringSourceType.Facebook,
        value: ThreatMonitoringSourceType.Facebook,
        label: <span>Facebook</span>,
      },
      {
        key: ThreatMonitoringSourceType.LinkedIn,
        value: ThreatMonitoringSourceType.LinkedIn,
        label: <span>LinkedIn</span>,
      },
      {
        key: ThreatMonitoringSourceType.Twitter,
        value: ThreatMonitoringSourceType.Twitter,
        label: <span>X (Twitter)</span>,
      },
    ],
  },
  {
    key: ThreatMonitoringSourceType.StealerLogs,
    value: ThreatMonitoringSourceType.StealerLogs,
    label: <span>Stealer log</span>,
    modules: [ThreatMonitoringModule.ModuleDarkWeb],
  },
];

// SourceTypeFilter is a control used to choose one or more threat types for filtering.
const SourceTypeFilter = ({
  module,
  checkedValues,
  onCheckedValuesChange,
}: SourceTypeFilterProps) => {
  const options: multiSelectFilterOption<ThreatMonitoringSourceType>[] =
    useMemo(() => {
      let filter: (o: sourceTypeFilterOption) => boolean;
      if (module === "all") {
        filter = () => true;
      } else {
        filter = (o) => o.modules.includes(module);
      }

      return sourceTypeFilterOptions.filter(filter);
    }, [module]);

  return (
    <CollapsableMultiSelectFilter<ThreatMonitoringSourceType>
      checkedValues={checkedValues}
      onCheckedValuesChange={onCheckedValuesChange}
      options={options}
      name="Source"
    />
  );
};

interface keywordFilterProps {
  options: string[];
  checkedValues?: string[];
  onCheckedValuesChange: (newCheckedValues: string[]) => void;
}

// KeywordFilter is a control used to choose one or more severities for filtering.
export const KeywordFilter = ({
  options,
  checkedValues,
  onCheckedValuesChange,
}: keywordFilterProps) => {
  const filterName = "Keywords";

  // sort ticked options first, otherwise, leave ordering the same
  const sorter: OptionsSorter<string> = (checkedOptions, options) => {
    if (!checkedOptions) {
      return options;
    }
    return _sortBy(
      options,
      (o) => !(o.value && checkedOptions.includes(o.value))
    );
  };

  const selectOptions: multiSelectFilterOption<string>[] = options.map((o) => {
    return {
      key: o,
      value: o,
      label: (
        <SidePopupV2
          className={"btn-icon-popup"}
          text={o}
          position={"right"}
          width={250}
          popupDelay={500}
          noWrap={true}
        >
          <div className="keyword-label">{o}</div>
        </SidePopupV2>
      ),
    };
  });

  return (
    <CollapsableMultiSelectFilter<string>
      checkedValues={checkedValues}
      onCheckedValuesChange={onCheckedValuesChange}
      name={filterName}
      options={selectOptions}
      disabled={options.length === 0}
      maxNumberInitialOptions={5}
      optionSorter={sorter}
    />
  );
};

// sortUsers returns a copy of users sorted by name ascending, with the current
// user always first.
const sortUsers = (currentUserID: number, users: User[]) => {
  const usersSorted = _cloneDeep(users);
  usersSorted.sort((l, r) => {
    if (l.name < r.name || l.id === currentUserID) {
      return -1;
    }

    if (l.name > r.name) {
      return 1;
    }

    return 0;
  });

  return usersSorted;
};

interface userFilterOption {
  value: string;
  label: string;
  labelIcon: JSX.Element;
}

const usersToUserFilterOption = (users: User[]): userFilterOption[] => {
  return users.map((u) => {
    return {
      value: u.id.toString(),
      label: u.name,
      labelIcon: <UserAvatar avatar={u.avatar} name={u.name} />,
    };
  });
};

const findUserFilterOptionsByUserIDs = (
  options: userFilterOption[],
  ids: number[]
): userFilterOption[] | undefined => {
  return options.filter((o) => {
    return ids.includes(+o.value);
  });
};

interface UserFilterProps {
  title: string;
  placeholder: string;
  options: userFilterOption[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  values: userFilterOption[];
  onChange: (newValues: userFilterOption[]) => void;
}

const UserFilter = (props: UserFilterProps) => {
  return (
    <CollapsableFilter title={props.title} startExpanded={true}>
      <SelectV2Multi
        isMulti
        options={props.options}
        value={props.values}
        formatOptionLabel={(userFilterOption) => (
          <div className="avatar-and-label-option">
            {userFilterOption.labelIcon}
            <div className="label" title={userFilterOption.label}>
              {userFilterOption.label}
            </div>
          </div>
        )}
        onChange={(v) => {
          props.onChange(v && v.values ? Array.from(v.values()) : []);
        }}
        controlShouldRenderValue={true}
        captureMenuScroll={false}
        placeholder={props.placeholder}
      />
    </CollapsableFilter>
  );
};

interface InvestigatorFilterProps {
  allUsers: User[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  filters: ThreatMonitoringResultsFilter;
  onFiltersChange: (newFilters: ThreatMonitoringResultsFilter) => void;
}

const noInvestigatorOption: userFilterOption = {
  value: "no_investigator",
  label: "No investigator",
  labelIcon: noAssigneeSVG,
};

const InvestigatorFilter = (props: InvestigatorFilterProps) => {
  const currentUser = useCurrentUser();

  const users = sortUsers(currentUser.id, props.allUsers);

  const options = usersToUserFilterOption(users);
  options.unshift(noInvestigatorOption);

  const selectedOptions =
    findUserFilterOptionsByUserIDs(
      options,
      props.filters.investigatorUserIDs ? props.filters.investigatorUserIDs : []
    ) || [];
  if (props.filters.notBeingInvestigated) {
    selectedOptions.unshift(noInvestigatorOption);
  }

  return (
    <UserFilter
      title="Investigator"
      placeholder="Select an investigator"
      options={options}
      values={selectedOptions}
      onChange={(newValues: userFilterOption[]) => {
        const newFilters = _cloneDeep(props.filters);

        const includesNoInvestigator = newValues.includes(noInvestigatorOption);
        newFilters.notBeingInvestigated = includesNoInvestigator
          ? includesNoInvestigator
          : undefined;

        const selectedUserIDs = newValues
          .filter((v) => v.value != noInvestigatorOption.value)
          .map((v) => +v.value);
        newFilters.investigatorUserIDs = selectedUserIDs.length
          ? selectedUserIDs
          : undefined;

        props.onFiltersChange(newFilters);
      }}
    />
  );
};

interface RemediatorFilterProps {
  allUsers: User[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  filters: ThreatMonitoringResultsFilter;
  onFiltersChange: (newFilters: ThreatMonitoringResultsFilter) => void;
}

const RemediatorFilter = (props: RemediatorFilterProps) => {
  const currentUser = useCurrentUser();

  const users = sortUsers(currentUser.id, props.allUsers);

  const options = usersToUserFilterOption(users);

  const selectedOptions =
    findUserFilterOptionsByUserIDs(
      options,
      props.filters.remediatorUserIDs ? props.filters.remediatorUserIDs : []
    ) || [];

  return (
    <UserFilter
      title="Remediator"
      placeholder="Select a remediator"
      options={options}
      values={selectedOptions}
      onChange={(newValues: userFilterOption[]) => {
        const newFilters = _cloneDeep(props.filters);

        const selectedUserIDs = newValues.map((v) => +v.value);
        newFilters.remediatorUserIDs = selectedUserIDs.length
          ? selectedUserIDs
          : undefined;

        props.onFiltersChange(newFilters);
      }}
    />
  );
};

interface ClosedByFilterProps {
  allUsers: User[]; // The users with write access to threat monitoring. If empty, the filter is disabled.
  filters: ThreatMonitoringResultsFilter;
  onFiltersChange: (newFilters: ThreatMonitoringResultsFilter) => void;
}

const upguardSystemUserOption: userFilterOption = {
  value: "upguard_system",
  label: BreachRiskUserName,
  labelIcon: <CircledIcon iconClass="cr-icon-upguard-logo-pick upguard-logo" />,
};

const ClosedByFilter = (props: ClosedByFilterProps) => {
  const currentUser = useCurrentUser();

  const users = sortUsers(currentUser.id, props.allUsers);

  const options = usersToUserFilterOption(users);
  options.unshift(upguardSystemUserOption);

  const selectedOptions =
    findUserFilterOptionsByUserIDs(
      options,
      props.filters.closedByUserIDs ? props.filters.closedByUserIDs : []
    ) || [];
  if (props.filters.autoDismissed) {
    selectedOptions.unshift(upguardSystemUserOption);
  }

  return (
    <UserFilter
      title="Closed by"
      placeholder="Select user"
      options={options}
      values={selectedOptions}
      onChange={(newValues: userFilterOption[]) => {
        const newFilters = _cloneDeep(props.filters);

        const includesSystem = newValues.includes(upguardSystemUserOption);
        newFilters.autoDismissed = includesSystem ? includesSystem : undefined;

        const selectedUserIDs = newValues
          .filter((v) => v.value != upguardSystemUserOption.value)
          .map((v) => +v.value);
        newFilters.closedByUserIDs = selectedUserIDs.length
          ? selectedUserIDs
          : undefined;

        props.onFiltersChange(newFilters);
      }}
    />
  );
};

interface textFilterProps {
  value?: string;
  maxLength: number;
  onValidChange: (newValue: string) => void;
}

const TextFilter = ({ value, onValidChange, maxLength }: textFilterProps) => {
  return (
    <TextFieldPerformant
      value={value || ""}
      maxLength={maxLength}
      onChanged={onValidChange}
    />
  );
};

interface statusOption {
  value: DisplayableThreatMonitoringResultStatus;
  label: string;
  color: LabelColor;
}

export const GetAllStatusFilterOptions = (): statusOption[] => {
  return [
    {
      value: DisplayableThreatMonitoringResultStatus.Dismissed,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Dismissed
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Dismissed
      ).color,
    },
    {
      value: DisplayableThreatMonitoringResultStatus.Remediated,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Remediated
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Remediated
      ).color,
    },
    {
      value: DisplayableThreatMonitoringResultStatus.SmartFiltered,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.SmartFiltered
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.SmartFiltered
      ).color,
    },
    {
      value: DisplayableThreatMonitoringResultStatus.Waived,
      label: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Waived
      ).value,
      color: DisplayableThreatMonitoringResultStatusToLabel(
        DisplayableThreatMonitoringResultStatus.Waived
      ).color,
    },
  ];
};

interface StatusFilterProps {
  checkedValues?: DisplayableThreatMonitoringResultStatus[];
  onCheckedValuesChange: (
    newCheckedValues: DisplayableThreatMonitoringResultStatus[]
  ) => void;
}

// StatusFilter is a control used to choose one or more statuses for filtering.
const StatusFilter = (props: StatusFilterProps) => {
  const options: multiSelectFilterOption<DisplayableThreatMonitoringResultStatus>[] =
    GetAllStatusFilterOptions().map((o) => {
      return {
        key: o.value as string,
        value: o.value,
        label: <PillLabel color={o.color}>{o.label}</PillLabel>,
      };
    });

  return (
    <CollapsableMultiSelectFilter<DisplayableThreatMonitoringResultStatus>
      checkedValues={props.checkedValues}
      onCheckedValuesChange={props.onCheckedValuesChange}
      options={options}
      name="Status"
    />
  );
};

export function filterAtMaxDatePeriod(
  filters: ThreatMonitoringResultsFilter
): boolean {
  return filters.datePeriod === maxDatePeriod;
}

export const areFiltersDefaultExcludingDate = (
  filters: ThreatMonitoringResultsFilter,
  feedType: ThreatMonitoringFeedType,
  module: Module
): boolean => {
  const defaultFilter = defaultFilters(module)[feedType];

  return (
    JSON.stringify({ ...filters, datePeriod: defaultFilter.datePeriod }) ===
    JSON.stringify(defaultFilter)
  );
};

import {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { getCurrentOrgFromUserData, isExpiredTrial } from "../helpers";
import {
  GroupAccessVendorAssetSharing,
  OrgAccessDomainPortfolios,
  OrgAccessVendorPortfolios,
  userHasPermissionForVendorFromState,
  UserVendorRiskWrite,
  UserWriteScoreBadge,
} from "../permissions";
import "../style/components/PageHeader.scss";
import Button from "./core/Button";
import VerifiedBadge from "./VerifiedBadge";
import VendorTierBadge from "../../vendorrisk/components/vendor_tiers/VendorTierBadge";
import LabelList from "../../vendorrisk/components/LabelList";
import Breadcrumbs, { IBreadcrumbsProps } from "./Breadcrumbs";
import ButtonGroup from "./ButtonGroup";
import DropdownV2 from "./core/DropdownV2";
import {
  AssuranceType,
  IUserOrganisation,
  organisationAccountType,
} from "../types/organisations";
import { DefaultThunkDispatchProp, IVendorFreeTrial } from "../types/redux";
import { History } from "history";
import {
  fetchCustomerLookupsData,
  setLabelsForVendors,
  updateVendorWatchStatus,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { setViewingVendorId } from "../reducers/commonActions";
import { ILabel, LabelClassification } from "../types/label";
import { useModalV2 } from "./ModalV2";
import UpdateVendorTierModal from "../../vendorrisk/components/vendor_tiers/UpdateVendorTierModal";
import { updateSubsidiaryLabels } from "../../vendorrisk/reducers/subsidiary.actions";
import UpdateLabelsModal from "../../vendorrisk/components/labels/UpdateLabelsModal";
import {
  getLocalStorageItemString,
  setLocalStorageItemString,
} from "../session";
import { dismissableBannerLocalStorageKey } from "./DismissableBanner";
import classnames from "classnames";
import { BannerType } from "../../vendorrisk/components/InfoBanner";
import PortfolioFilterDropdown from "../../vendorrisk/components/portfolios/PortfolioFilterDropdown";
import {
  Portfolio,
  PortfolioType,
} from "../../vendorrisk/reducers/portfolios.actions";
import { getVendorWords } from "../constants";
import ExecutiveReportingModal, {
  ExecutiveReportingMode,
  IExecutiveReportingModalOwnProps,
} from "./modals/ExecutiveReportingModal";
import { ExecutiveReportType } from "../../vendorrisk/components/reporting/ReportTypeBadge";
import PageviewTracker from "../../vendorrisk/components/PageviewTracker";
import VendorPortfolioSelectionModal from "../../vendorrisk/components/portfolios/VendorPortfolioSelectionModal";
import VendorFreeTrialIcon from "./VendorFreeTrialIcon";
import { fetchVendorPortfolioRiskProfile } from "../../vendorrisk/reducers/vendorRiskPortfolio.actions";
import {
  ILookupVendorSearchResult,
  IWatchedVendorSearchResult,
} from "../types/vendor";
import { setVendorComparisonVendors } from "../../vendorrisk/reducers/vendorComparison.actions";
import StopMonitoringVendorConfirmationModal from "../../vendorrisk/components/modals/StopMonitoringVendorsConfirmationModal";
import ScoreBadgeModal from "./ScoreBadgeModal";
import ReportsLibraryModal from "../../vendorrisk/components/reporting/ReportsLibraryModal";
import { ExportType } from "../types/exportReport";
import { appConnect } from "../types/reduxHooks";
import { SidePopupV2 } from "./DismissablePopup";
import { FilterTypes } from "../../vendorrisk/components/filter";
import { TourHighlightFromPLGContent } from "./TourHighlight";
import useTitleTag from "../hooks/useTitleTag";
import { ConsumeQueryParams, GetQueryParams } from "../query";

const stopClickPropagation = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
) => e.stopPropagation();

export const ButtonSeparator: FC = () => <div className="btn-separator" />;

interface IHeaderPlusButtonProps {
  onClick: () => void;
  label: string;
}

const HeaderPlusButton: FC<IHeaderPlusButtonProps> = ({ onClick, label }) => (
  <div className="header-plus-btn" onClick={onClick}>
    <div className="plus" />
    <div className="label">{label}</div>
  </div>
);

interface IHeaderIconItemProps {
  iconClass: string;
  label: string;
  popupContent: string;
  onClick?: () => void;
}

const HeaderIconItem: FC<IHeaderIconItemProps> = ({
  iconClass,
  label,
  popupContent,
  onClick,
}) => (
  <SidePopupV2 text={popupContent} className="header-icon-item">
    <div onClick={onClick} className={classnames({ clickable: !!onClick })}>
      <div className={`header-icon ${iconClass}`} />
      <div className="header-label">{label}</div>
    </div>
  </SidePopupV2>
);

interface IAssignTierButtonProps {
  vendorId: number;
}

const AssignTierButton: FC<IAssignTierButtonProps> = ({ vendorId }) => {
  const [openVendorTierModal, vendorTierModal] = useModalV2(
    UpdateVendorTierModal
  );

  return (
    <>
      <HeaderPlusButton
        onClick={() =>
          openVendorTierModal({
            vendorIds: [vendorId],
          })
        }
        label="Assign tier"
      />
      {vendorTierModal}
    </>
  );
};

interface IAddLabelButtonProps {
  labels: ILabel[];
  onUpdate: (
    availableLabels: ILabel[],
    addedLabelIds: number[],
    removedLabelIds: number[]
  ) => Promise<void>;
}

const AddLabelButton: FC<IAddLabelButtonProps> = ({ labels, onUpdate }) => {
  const [openUpdateLabelsModal, updateLabelsModal] =
    useModalV2(UpdateLabelsModal);
  return (
    <div className={"add-label-button"}>
      <HeaderPlusButton
        onClick={() =>
          openUpdateLabelsModal({
            showSystemLabels: true,
            classification: LabelClassification.VendorLabel,
            selectedItems: [
              {
                labels,
              },
            ],
            onUpdate,
          })
        }
        label="Add label"
      />
      {updateLabelsModal}
    </div>
  );
};

interface IPortfolioBadgeProps {
  vendorId: number;
  vendorName: string;
  canEditPortfolio: boolean;
  vendorPortfolios?: Portfolio[];
}

const PortfolioBadge: FC<IPortfolioBadgeProps> = ({
  vendorPortfolios,
  canEditPortfolio,
  vendorId,
  vendorName,
}) => {
  const [openPortfoliosModal, portfoliosModal] = useModalV2(
    VendorPortfolioSelectionModal
  );

  if (!vendorPortfolios) {
    return null;
  }

  return (
    <div className="header-icon-item portfolio-badge">
      <div
        onClick={
          canEditPortfolio
            ? () =>
                openPortfoliosModal({
                  selectedVendors: [
                    {
                      id: vendorId,
                      name: vendorName,
                      portfolios: vendorPortfolios,
                    },
                  ],
                })
            : undefined
        }
        className={classnames({ clickable: canEditPortfolio })}
      >
        <div className={`header-icon cr-icon-folder`} />
        <div className="header-label">
          {vendorPortfolios.map((p) => p.name).join(", ")}
        </div>
      </div>
      {portfoliosModal}
    </div>
  );
};

const getInfoSectionActiveStatus = (infoSectionPageKey: string | undefined) => {
  // If there is no local storage key, we should default the info section to closed.
  if (!infoSectionPageKey) {
    return false;
  }

  // Check localStorage to see if this info section has been dismissed before
  const isActive = getLocalStorageItemString(
    dismissableBannerLocalStorageKey(infoSectionPageKey)
  );

  // Default to open unless it's been explicitly dismissed
  return isActive !== "false";
};

interface IPageHeaderOwnProps extends Partial<IBreadcrumbsProps> {
  history: History;
  title?: ReactNode;
  className?: string;
  vendorId?: number;
  isSubsidiary?: boolean;
  backAction?: () => void;
  backText?: string;
  rightSection?: JSX.Element;
  infoSection?: React.ReactNode; // Content for the page description section
  infoSectionButtons?: JSX.Element; // Optional buttons for the page description section
  infoSectionPageKey?: string; // LocalStorage key for remembering that the info section is dismissed for a page
  infoBarText?: ReactNode; // if defined will appear in an info bar above the breadcumbs section
  infoBarColour?: BannerType; // override the default colour of the info bar
  aboveHeadingOverride?: JSX.Element; // Optional component to mount above the main page heading
  showVendorPortfoliosSelector?: boolean;
  showDomainPortfoliosSelector?: boolean;
  isBreachsight?: boolean;
  hideInfoSectionButtons?: boolean;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  userHasWriteVendorInfoPermission?: boolean;
  selectedVendors?: IWatchedVendorSearchResult[];
  selectedLookups?: ILookupVendorSearchResult[];
  isSnapshotView?: boolean;
  supportedExportFilters?: FilterTypes[];
  titleTag?: string;
}

interface IPageHeaderConnectedProps {
  vendorName?: string;
  vendorPrimaryDomain?: string;
  vendorWatched: boolean;
  vendorViewable: boolean;
  vendorIsManaged: boolean;
  vendorTier?: number;
  vendorTierName?: string;
  labels?: ILabel[];
  vendorPortfolios?: Portfolio[];
  sharedAssessmentPublished: boolean;
  sharedAssetsAvailable: boolean;
  userHasWriteVendorInfoPermission: boolean;
  assuranceType: AssuranceType;
  orgSupportsVendorAssetSharing: boolean;
  orgSupportsVendorPortfolios: boolean;
  userCanEditVendorPortfolios: boolean;
  orgSupportsDomainPortfolios: boolean;
  vendorFreeTrialInfo?: IVendorFreeTrial;
  hasAccessToScoreBadge: boolean;
  isPaidAccountType: boolean;
  isTrialAccountType: boolean;
  isExpiredTrialAccount: boolean;
}

export type IPageHeaderProps = IPageHeaderOwnProps &
  IPageHeaderConnectedProps &
  DefaultThunkDispatchProp;

const PageHeader: FC<IPageHeaderProps> = ({
  dispatch,
  history,
  title,
  className = "",
  vendorId = undefined,
  isSubsidiary = false,
  vendorName = "",
  vendorPrimaryDomain = "",
  vendorWatched,
  vendorViewable,
  vendorIsManaged,
  vendorTier = undefined,
  vendorTierName = undefined,
  labels = undefined,
  backAction = undefined,
  backText = "",
  rightSection = undefined,
  isManagementAnalystSession = false,
  managedOrgId = undefined,
  sharedAssessmentPublished,
  sharedAssetsAvailable,
  userHasWriteVendorInfoPermission,
  breadcrumbs = undefined,
  infoSection = undefined,
  infoSectionButtons = undefined,
  infoSectionPageKey = undefined,
  infoBarText,
  infoBarColour,
  assuranceType,
  isBreachsight = false,
  orgSupportsVendorAssetSharing,
  aboveHeadingOverride = undefined,
  orgSupportsVendorPortfolios,
  orgSupportsDomainPortfolios,
  vendorPortfolios,
  showVendorPortfoliosSelector = false,
  showDomainPortfoliosSelector = false,
  userCanEditVendorPortfolios,
  hideInfoSectionButtons = false,
  vendorFreeTrialInfo,
  selectedVendors = undefined,
  selectedLookups = undefined,
  isSnapshotView,
  hasAccessToScoreBadge,
  isPaidAccountType,
  isTrialAccountType,
  isExpiredTrialAccount,
  supportedExportFilters,
  titleTag,
}) => {
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const hasInfoSection = !!infoSection;
  const [infoSectionVisible, setInfoSectionVisible] = useState(() =>
    getInfoSectionActiveStatus(infoSectionPageKey)
  );
  // const [watchVendorModalVisible, setWatchVendorModalVisible] = useState(false);
  const [isShowingStopMonitoringModal, setIsShowingStopMonitoringModal] =
    useState(false);

  // support for new breachsight summary report functionality (modal)
  const [openExecutiveReportingModal, executiveReportingModal] =
    useModalV2<IExecutiveReportingModalOwnProps>(ExecutiveReportingModal);
  const [openReportsLibraryModal, reportsLibraryModal] =
    useModalV2(ReportsLibraryModal);

  // check the query params to see if we need to show the score badge modal
  const params = GetQueryParams(location.search);
  const [isShowingScoreBadgeModal, setIsShowingScoreBadgeModal] = useState(
    "score_badge" in params
  );

  const articleId = parseInt(params["article_id"]);

  useTitleTag(titleTag || (typeof title === "string" ? title : undefined));

  useEffect(() => {
    if (articleId > 0) {
      ConsumeQueryParams(history, ["article_id"]);
    }
  }, []);

  useEffect(() => {
    //  Make sure we update the info section status when the page key changes
    setInfoSectionVisible(getInfoSectionActiveStatus(infoSectionPageKey));
  }, [infoSectionPageKey]);

  const toggleInfoSection = useCallback(() => {
    setInfoSectionVisible(!infoSectionVisible);

    // And set this to active/dismissed in localStorage
    if (infoSectionPageKey) {
      setLocalStorageItemString(
        dismissableBannerLocalStorageKey(infoSectionPageKey),
        infoSectionVisible ? "false" : "true"
      );
    }
  }, [setInfoSectionVisible, infoSectionVisible, infoSectionPageKey]);

  const toggleWatchVendor = useCallback(() => {
    const watch = !vendorWatched;

    dispatch(
      updateVendorWatchStatus(
        vendorId || 0,
        watch,
        history,
        [],
        vendorName,
        vendorPrimaryDomain
      )
    ).then((success) => {
      if (success && !watch) {
        // Go back to the vendor list.
        history.replace("/vendorlist");
        dispatch(setViewingVendorId(null));
        dispatch(fetchCustomerLookupsData(true));
        dispatch(fetchVendorPortfolioRiskProfile());
      }
    });
  }, [
    vendorWatched,
    dispatch,
    vendorId,
    history,
    vendorName,
    vendorPrimaryDomain,
  ]);

  const selectedVendorIds = [
    ...(selectedVendors?.map((v) => v.id) ?? []),
    ...(selectedLookups?.map((v) => v.vendorId) ?? []),
  ];

  const compareVendors = () => {
    if (selectedVendorIds.length > 0) {
      dispatch(setVendorComparisonVendors(selectedVendorIds));
    }
    history.push("/vendorcomparison", {
      vendorIds: selectedVendorIds,
      backContext: {
        backTo: history.location.pathname,
        backToText: "Back",
      },
    });
  };

  const compareVendor = useCallback(
    () =>
      history.push("/vendorcomparison", {
        vendorIds: [vendorId],
        backContext: {
          backTo: history.location.pathname,
          backToText: "Back",
        },
      }),
    [history, vendorId]
  );

  const exportVendorReportV2 = useCallback(
    () =>
      openReportsLibraryModal({
        history,
        initialVendorId: vendorId,
        initialVendorName: vendorName,
        managedOrgId: managedOrgId,
        filterAvailableReportTypes: [
          ExportType.DetailedVendorReportV2,
          ExportType.ManagedVendorRiskAssessmentReport,
        ],
      }),
    [history, openReportsLibraryModal, vendorId, vendorName]
  );

  const exportPortfolioReport = useCallback(() => {
    openExecutiveReportingModal({
      vendorId: vendorId,
      mode: isSnapshotView
        ? ExecutiveReportingMode.SnapshotReporting
        : ExecutiveReportingMode.PortfolioReporting,
      preSelectedVendors: selectedVendors,
      managedOrgId: managedOrgId || 0,
      isManagementAnalystSession: isManagementAnalystSession || false,
      preSelectedReportType: ExecutiveReportType.PortfolioReport,
      history: history,
      supportedFilters: supportedExportFilters,
    });
  }, [
    openExecutiveReportingModal,
    vendorId,
    selectedVendors,
    selectedLookups,
    isSnapshotView,
    isManagementAnalystSession,
    managedOrgId,
    history,
  ]);

  const exportBreachsightSummaryReport = useCallback(
    () =>
      openReportsLibraryModal({
        history,
        filterAvailableReportTypes: [
          ExportType.DetailedBreachsightReportV2,
          ExportType.BreachsightSubsidiariesReportV2,
        ],
      }),
    [history, openReportsLibraryModal]
  );

  const onLabelsUpdated = useCallback(
    async (
      allLabels: ILabel[],
      addedLabelIds: number[],
      removedLabelIds: number[]
    ) => {
      if (!vendorId) {
        return;
      }

      if (isSubsidiary) {
        await dispatch(
          updateSubsidiaryLabels({
            allLabels,
            vendorIds: [vendorId],
            labelsToAdd: addedLabelIds,
            labelsToRemove: removedLabelIds,
          })
        );
      } else {
        await dispatch(
          setLabelsForVendors([vendorId], addedLabelIds, removedLabelIds)
        );
      }
    },
    [dispatch, vendorId, isSubsidiary]
  );

  const [headerHeight, setHeaderHeight] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);

  useLayoutEffect(() => {
    let observer: ResizeObserver;
    if (pageHeaderRef.current) {
      setHeaderHeight(pageHeaderRef.current.getBoundingClientRect().height);

      observer = new ResizeObserver((entries) => {
        if (entries.length > 0) {
          const height = entries[0].contentRect.height;
          setHeaderHeight(height);
        }
      });

      observer.observe(pageHeaderRef.current, {});
    }
    setPageLoaded(true);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const onCloseScoreBadgeModal = () => {
    setIsShowingScoreBadgeModal(false);
    ConsumeQueryParams(history, ["score_badge"]);
  };

  const detailsSectionAvailable = !!vendorId && !isSubsidiary && vendorWatched;
  const vendorWords = getVendorWords(assuranceType);

  const onVendorSummaryPage =
    history.location.pathname == `/vendor/${vendorId}/summary`;
  const onBreachsightSummaryPage =
    history.location.pathname == "/summaryreport/breachsight";

  // build a vendor path prefix that can be used to normal and TPRM sessions
  let vendorPrefix = `/vendor/${vendorId}`;
  if (isManagementAnalystSession) {
    vendorPrefix = `/analysts/tpvm/${managedOrgId}/${vendorId}`;
  }

  // make sure breadcrumbs are correct for TPRM sessions
  if (breadcrumbs && isManagementAnalystSession) {
    for (let i = 0; i < breadcrumbs.length; i++) {
      if (breadcrumbs[i].to) {
        let path = (breadcrumbs[i].to ?? "").toString();
        if (path.startsWith("/vendor/")) {
          path = path.replace(/\/vendor\//, `/analysts/tpvm/${managedOrgId}/`);
          breadcrumbs[i].to = path;
        } else if (path.startsWith("/vendorlist")) {
          path = path.replace(/\/vendorlist/, `/analysts/tpvm`);
          breadcrumbs[i].to = path;
        }
      }
    }
  }

  return (
    <div
      className="ug-page-header-outer"
      style={{ height: `${headerHeight}px` }}
    >
      <div className={`ug-page-header ${className}`} ref={pageHeaderRef}>
        {vendorName && (
          <div className="vendor-section">
            <div className="vendor-section-inner">
              <div className="vendor-name-actions">
                <div className="vendor-name">
                  {vendorName}{" "}
                  {vendorPrimaryDomain && (
                    <span className="primary-domain">
                      (
                      <a
                        href={`https://${vendorPrimaryDomain}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        {vendorPrimaryDomain}
                      </a>
                      )
                    </span>
                  )}
                </div>

                {vendorPrimaryDomain && sharedAssessmentPublished && (
                  <VerifiedBadge isCustomer={!vendorId} />
                )}
                {!!vendorId &&
                  !isSubsidiary &&
                  vendorWatched &&
                  userHasWriteVendorInfoPermission &&
                  !isManagementAnalystSession &&
                  vendorPrimaryDomain &&
                  (isPaidAccountType ||
                    (isTrialAccountType && !isExpiredTrialAccount)) && (
                    <VendorFreeTrialIcon
                      vendorId={vendorId}
                      vendorName={vendorName}
                      vendorPrimaryDomain={vendorPrimaryDomain}
                      dispatch={dispatch}
                      freeTrialInfo={vendorFreeTrialInfo}
                      assuranceType={assuranceType}
                    />
                  )}
                <div className="vendor-actions">
                  {hasAccessToScoreBadge && isBreachsight && (
                    <ButtonGroup>
                      <>
                        <Button
                          onClick={() => setIsShowingScoreBadgeModal(true)}
                        >
                          Share rating
                        </Button>
                        <ScoreBadgeModal
                          active={isShowingScoreBadgeModal}
                          onClose={onCloseScoreBadgeModal}
                          dispatch={dispatch}
                        />
                      </>
                    </ButtonGroup>
                  )}
                  {!isBreachsight && !vendorId && !isSubsidiary && (
                    <ButtonGroup>
                      {!isManagementAnalystSession && selectedVendors && (
                        <>
                          {selectedVendorIds.length > 4 && (
                            <SidePopupV2
                              position={"bottom"}
                              className={"btn-compare-container"}
                              text={`A maximum of 4 ${vendorWords.plural} can be compared. Please reduce your selection.`}
                            >
                              <Button disabled={true}>Compare</Button>
                            </SidePopupV2>
                          )}
                          {selectedVendorIds.length <= 4 && (
                            <Button onClick={compareVendors}>Compare</Button>
                          )}
                        </>
                      )}
                      {showVendorPortfoliosSelector && (
                        <>
                          <Button onClick={exportPortfolioReport}>
                            Generate report
                          </Button>
                        </>
                      )}
                    </ButtonGroup>
                  )}
                  {!!vendorId && !isSubsidiary && vendorViewable && (
                    <ButtonGroup>
                      {!isManagementAnalystSession && (
                        <Button onClick={compareVendor}>Compare</Button>
                      )}
                      <TourHighlightFromPLGContent
                        onboardingTaskId={"Checklist_VendorRisk_Report"}
                        highlightIndex={1}
                        visible={pageLoaded && onVendorSummaryPage}
                        position={"bottom"}
                      >
                        <Button onClick={exportVendorReportV2}>
                          Generate report
                        </Button>
                      </TourHighlightFromPLGContent>
                      {vendorWatched && (
                        <DropdownV2
                          className="dot-menu"
                          popupItem={
                            <Button>
                              <div className="cr-icon-dots-menu" />
                            </Button>
                          }
                        >
                          <div
                            className="menu-vendor-section"
                            onClick={stopClickPropagation}
                          >
                            <div
                              className={classnames("menu-vendor-item", {
                                disabled:
                                  !userHasWriteVendorInfoPermission ||
                                  vendorIsManaged,
                              })}
                              onClick={
                                userHasWriteVendorInfoPermission &&
                                !vendorIsManaged
                                  ? () => setIsShowingStopMonitoringModal(true)
                                  : undefined
                              }
                            >
                              {vendorWatched ? (
                                <div className="menu-icon cr-icon-trash" />
                              ) : (
                                <div className="menu-icon cr-icon-plus" />
                              )}
                              {vendorWatched ? "Remove" : "Add"}{" "}
                              {vendorWords.singular}
                            </div>
                            <div
                              className={classnames("menu-vendor-item", {
                                disabled: !userHasWriteVendorInfoPermission,
                              })}
                              onClick={
                                userHasWriteVendorInfoPermission
                                  ? () =>
                                      history.push(`/vendor/${vendorId}/edit`, {
                                        backContext: {
                                          backTo: history.location.pathname,
                                          backToText: "Back",
                                        },
                                      })
                                  : undefined
                              }
                            >
                              <div className="menu-icon cr-icon-pencil" />
                              Edit {vendorWords.singular}
                            </div>
                          </div>
                        </DropdownV2>
                      )}
                    </ButtonGroup>
                  )}
                  {vendorWatched && (
                    <StopMonitoringVendorConfirmationModal
                      active={isShowingStopMonitoringModal}
                      onClose={() => setIsShowingStopMonitoringModal(false)}
                      vendors={[
                        {
                          id: vendorId,
                          name: vendorName,
                          isManaged: vendorIsManaged,
                        } as IWatchedVendorSearchResult,
                      ]}
                      onRemoved={() => {
                        setIsShowingStopMonitoringModal(false);
                        toggleWatchVendor();
                      }}
                    />
                  )}
                  {isBreachsight && (
                    <ButtonGroup>
                      <>
                        <TourHighlightFromPLGContent
                          onboardingTaskId={"Checklist_BreachSight_Report"}
                          highlightIndex={0}
                          visible={pageLoaded && onBreachsightSummaryPage}
                          position={"bottom"}
                        >
                          <Button onClick={exportBreachsightSummaryReport}>
                            Generate report
                          </Button>
                        </TourHighlightFromPLGContent>
                      </>
                    </ButtonGroup>
                  )}
                </div>
              </div>
              {((orgSupportsVendorPortfolios && showVendorPortfoliosSelector) ||
                (orgSupportsDomainPortfolios &&
                  showDomainPortfoliosSelector)) && (
                <div className="portfolio-metadata-row">
                  <span>Current Portfolio:</span>
                  <PortfolioFilterDropdown
                    portfolioType={
                      showVendorPortfoliosSelector
                        ? PortfolioType.Vendor
                        : PortfolioType.Domain
                    }
                    renderPopupItem={(currentPortfolioName, dropdownActive) => (
                      <div
                        className={`portfolio-filter-btn ${
                          dropdownActive ? "active" : ""
                        }`}
                      >
                        <div className="portfolio-filter-btn-inner">
                          {currentPortfolioName}{" "}
                          <div
                            className={`cr-icon-chevron ${
                              dropdownActive ? "rot270" : "rot90"
                            }`}
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
              {detailsSectionAvailable && (
                <>
                  <div className="vendor-metadata-row">
                    {vendorWatched && orgSupportsVendorPortfolios && (
                      <PortfolioBadge
                        canEditPortfolio={
                          userCanEditVendorPortfolios &&
                          !isManagementAnalystSession
                        }
                        vendorId={vendorId}
                        vendorName={vendorName}
                        vendorPortfolios={vendorPortfolios}
                      />
                    )}
                    {vendorWatched && vendorTier ? (
                      <VendorTierBadge
                        tier={vendorTier}
                        name={vendorTierName}
                        showName
                        canUpdate={
                          userHasWriteVendorInfoPermission &&
                          !isManagementAnalystSession
                        }
                        vendorIds={[vendorId as number]}
                      />
                    ) : vendorWatched &&
                      userHasWriteVendorInfoPermission &&
                      !isManagementAnalystSession ? (
                      <AssignTierButton vendorId={vendorId as number} />
                    ) : null}
                    {(sharedAssessmentPublished || sharedAssetsAvailable) && (
                      <HeaderIconItem
                        iconClass="cr-icon-shared-assessment"
                        label="Shared assets available"
                        popupContent=""
                        onClick={() =>
                          orgSupportsVendorAssetSharing
                            ? history.push(`${vendorPrefix}/sharedassets`)
                            : history.push(`${vendorPrefix}/sharedassessment`)
                        }
                      />
                    )}
                    {vendorWatched && labels && labels.length > 0 && (
                      <LabelList
                        labels={labels}
                        onLabelsUpdated={
                          userHasWriteVendorInfoPermission &&
                          !isManagementAnalystSession
                            ? onLabelsUpdated
                            : undefined
                        }
                        classification={LabelClassification.VendorLabel}
                        showSystemLabels
                        updateLabelsText={""}
                        maxNumber={3}
                        popupPosition={"bottom"}
                      />
                    )}
                    {vendorWatched &&
                      labels &&
                      labels.length === 0 &&
                      userHasWriteVendorInfoPermission &&
                      !isManagementAnalystSession && (
                        <AddLabelButton
                          labels={labels}
                          onUpdate={onLabelsUpdated}
                        />
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {infoBarText && (
          <div className={classnames("info-bar-section", infoBarColour)}>
            <div className="info-bar-section-inner">
              <div className="info-bar">{infoBarText}</div>
            </div>
          </div>
        )}
        {aboveHeadingOverride}
        <div className="page-header-section">
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          {title && (
            <div className="page-title">
              {backAction && (
                <SidePopupV2
                  text={backText || "Back"}
                  className="back-btn-popup"
                  position={"right"}
                  noWrap
                >
                  <div className="back-btn" onClick={backAction} />
                </SidePopupV2>
              )}
              <h2>{title}</h2>
              <div className="page-title-right">{rightSection}</div>
              {hasInfoSection && !hideInfoSectionButtons && (
                <>
                  {rightSection && <ButtonSeparator />}
                  <Button
                    className="info-button"
                    primary={infoSectionVisible}
                    tertiary={!infoSectionVisible}
                    onClick={toggleInfoSection}
                  >
                    <div className="cr-icon-info" />
                  </Button>
                </>
              )}
            </div>
          )}
          {infoSectionVisible && (infoSection || infoSectionButtons) && (
            <div className="info-section">
              <div className="info-desc">{infoSection}</div>
              {!hideInfoSectionButtons && (
                <div className="btns-and-control">
                  {infoSectionButtons}
                  <div className="collapse-control" onClick={toggleInfoSection}>
                    Collapse <div className="cr-icon-chevron rot270" />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <PageviewTracker />
      {executiveReportingModal}
      {reportsLibraryModal}
    </div>
  );
};

export default appConnect<
  IPageHeaderConnectedProps,
  never,
  IPageHeaderOwnProps
>((state, props) => {
  let sharedAssessmentPublished = false;
  let sharedAssetsAvailable = false;
  let vendorWatched = false;
  let vendorViewable = false;
  let vendorIsManaged = false;
  let vendorName;
  let vendorPrimaryDomain;
  let vendorTier;
  let vendorTierName;
  let labels;
  let vendorPortfolios;

  const currentOrg = getCurrentOrgFromUserData(
    state.common?.userData
  ) as IUserOrganisation | null;

  if (props.isSubsidiary) {
    // Looking at a subsidiary
    const subsidiaryData = state.cyberRisk.subsidiaries[props.vendorId || -1];
    if (subsidiaryData) {
      vendorName = subsidiaryData.name;
      vendorPrimaryDomain = subsidiaryData.primary_hostname;
    }
  } else if (props.vendorId !== undefined && props.vendorId > 0) {
    // Looking at a vendor
    let vendorData;
    if (!!props.managedOrgId) {
      vendorData =
        state.cyberRisk.managedVendorData?.[props.managedOrgId]?.[
          props.vendorId
        ] || {};
    } else {
      vendorData = state.cyberRisk.vendors[props.vendorId];
    }

    if (vendorData) {
      vendorName = vendorData.name;
      vendorPrimaryDomain = vendorData.primary_hostname;
      vendorWatched = vendorData.watching?.result?.watching ?? false;
      vendorViewable = vendorData.watching?.result?.canView ?? false;
      vendorIsManaged = vendorData.watching?.result?.managed ?? false;
      vendorTier = vendorData.watching?.result?.vendorTier;
      vendorTierName = vendorData.watching?.result?.vendorTierName;
      vendorPortfolios = vendorData.watching?.result?.vendorPortfolios;
      sharedAssessmentPublished =
        vendorData.watching?.result?.sharedAssessmentPublished ?? false;
      sharedAssetsAvailable =
        vendorData.watching?.result?.sharedAssetsAvailable ?? false;
      labels = vendorData.labels;
    }
  } else if (currentOrg) {
    // Looking at a non-vendor specific page, we should use
    // the current org to populate the vendor header, if there is a current org.
    vendorName = currentOrg.name;
    vendorPrimaryDomain = currentOrg.mainHostname;
    sharedAssessmentPublished = currentOrg.isSharedAssessmentPublished;
  }

  const userHasWriteVendorInfoPermission =
    props.userHasWriteVendorInfoPermission === null ||
    props.userHasWriteVendorInfoPermission === undefined
      ? state.common.userData.userPermissions.includes(UserVendorRiskWrite) ||
        userHasPermissionForVendorFromState(
          state,
          UserVendorRiskWrite,
          props.vendorId || 0
        )
      : props.userHasWriteVendorInfoPermission;

  const orgIsVerifiedVendor = currentOrg?.isVerifiedVendor ?? false;
  const userHasWriteScoreBadgePermission =
    state.common.userData.userPermissions.includes(UserWriteScoreBadge);
  const hasAccessToScoreBadge =
    orgIsVerifiedVendor && userHasWriteScoreBadgePermission;

  const orgSupportsVendorAssetSharing =
    (state.common.userData.inOrganisationGroup &&
      state.common.userData.organisationGroupEntitlements?.includes(
        GroupAccessVendorAssetSharing
      )) ||
    false;

  const orgSupportsVendorPortfolios =
    state.common.userData.orgPermissions.includes(OrgAccessVendorPortfolios);

  const orgSupportsDomainPortfolios =
    state.common.userData.orgPermissions.includes(OrgAccessDomainPortfolios);

  // The user should be able to edit the portfolios if they have edit access to ANY portfolio in the system.
  // The portfolio editor will not let them select/deselect portfolios they don't have edit access to.
  const userCanEditVendorPortfolios =
    userHasWriteVendorInfoPermission ||
    !!Object.values(
      state.common.userData.vendorPortfolioSpecificPermissions
    ).find((perms) => perms?.includes(UserVendorRiskWrite));

  return {
    sharedAssessmentPublished,
    sharedAssetsAvailable,
    userHasWriteVendorInfoPermission,
    vendorName,
    vendorPrimaryDomain,
    vendorWatched,
    vendorViewable,
    vendorIsManaged,
    vendorTier,
    vendorTierName,
    vendorPortfolios,
    labels,
    assuranceType: currentOrg?.assuranceType ?? AssuranceType.None,
    isBreachsight: props.isBreachsight,
    orgSupportsVendorAssetSharing,
    orgSupportsVendorPortfolios,
    userCanEditVendorPortfolios,
    orgSupportsDomainPortfolios,
    vendorFreeTrialInfo: props.vendorId
      ? state.cyberRisk.vendorsFreeTrial[props.vendorId]
      : undefined,
    hasAccessToScoreBadge,
    isPaidAccountType: currentOrg?.accountType === organisationAccountType.paid,
    isTrialAccountType:
      currentOrg?.accountType === organisationAccountType.trial,
    isExpiredTrialAccount: isExpiredTrial(currentOrg),
  };
})(memo(PageHeader));

import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useState } from "react";
import Button from "../../../_common/components/core/Button";
import "../../style/AddQuestionnairesModal.scss";
import TextField, {
  useTextWithValid,
} from "../../../_common/components/TextField";
import { updatePrefilledSurveyDetails } from "../../../_common/reducers/commonActions";
import TrustPageAPI, {
  getPrefilledSurveyTags,
  TrustPageTagTypes,
} from "../../api/trustpage.api";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import "../../style/EditQuestionnaireModal.scss";

interface EditQuestionnaireModalProps extends BaseModalProps {
  surveyId: number;
  originalSurveyName?: string;
  originalSurveyDescription?: string;
}

const EditQuestionnaireModal: FC<EditQuestionnaireModalProps> = ({
  surveyId,
  originalSurveyName,
  originalSurveyDescription,
  active,
  onClose,
}: EditQuestionnaireModalProps) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newSurveyName, newSurveyNameIsValid, setNewSurveyName] =
    useTextWithValid(originalSurveyName);
  const [
    newSurveyDescription,
    newSurveyDescriptionIsValid,
    setNewSurveyDescription,
  ] = useTextWithValid(originalSurveyDescription ?? "");

  const handleSubmit = () => {
    setIsSubmitting(true);
    dispatch(
      updatePrefilledSurveyDetails(
        surveyId,
        newSurveyName,
        newSurveyDescription
      )
    )
      .then(() =>
        dispatch(
          TrustPageAPI.util.invalidateTags([
            TrustPageTagTypes.ownTrustPage,
            ...getPrefilledSurveyTags(surveyId),
          ])
        )
      )
      .then(() =>
        dispatch(
          addDefaultSuccessAlert(`Questionnaire details updated successfully`)
        )
      )
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert("Error updating questionnaire details")
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        onClose();
      });
  };

  const isValid = newSurveyNameIsValid || newSurveyDescriptionIsValid;

  return (
    <>
      <ModalV2
        className="edit-questionnaire-modal"
        width={600}
        active={active}
        onClose={onClose}
        headerContent="Edit questionnaire details"
        footerContent={
          <div className="btn-group">
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
            <Button
              primary
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            >
              Save changes
            </Button>
          </div>
        }
      >
        <div className={"edit-questionnaire-details"}>
          <div>
            <div className={"label required"}>Questionnaire name</div>
            <TextField
              required
              value={newSurveyName}
              onChanged={(name, valid) => setNewSurveyName(name, valid)}
              debounceTime={500}
              minLength={3}
              maxLength={120}
              placeholder={"Provide a name"}
            />
          </div>
          <div>
            <div className={"label"}>Questionnaire description</div>
            <TextField
              value={newSurveyDescription}
              multiLine
              debounceTime={500}
              onChanged={(name, valid) => setNewSurveyDescription(name, valid)}
              placeholder={"Enter questionnaire description"}
            />
          </div>
        </div>
      </ModalV2>
    </>
  );
};

export default EditQuestionnaireModal;

import { FC } from "react";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { UserDirectoryType } from "../../api/types";
import { NumberWithCommas } from "../../../_common/helpers";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";
import DashboardContainer from "./DashboardContainer";
import "./AboutUserDirectory.scss";

const AboutUserDirectory: FC = ({}) => {
  const { data, isLoading } = UserBaseAPI.useGetUserDirectoryStatsV1Query();
  const stats = data?.stats;

  // Stats might not be available yet so set some defaults
  let dirTypeToDisplay = "Directory information";
  let numUsersInDirDisplay = "-";
  let numUsersNoEmailDisplay = "-";
  let numAdminAppsDisplay = "-";

  if (stats) {
    // We have stats so change what we display
    dirTypeToDisplay =
      stats.origin === UserDirectoryType.Google ? "Google" : "Microsoft 365";

    numUsersInDirDisplay = NumberWithCommas(stats?.numUsersInDir ?? 0);
    numUsersNoEmailDisplay = NumberWithCommas(
      stats?.numUsersInDirWithoutEmail ?? 0
    );
    numAdminAppsDisplay = NumberWithCommas(stats?.numAdminApprovedApps ?? 0);
  }

  return (
    <div className="userbase-about-user-directory">
      <DashboardContainer title={"Directory information"}>
        {isLoading && <LoadingBanner />}

        {!isLoading && stats === null && (
          <EmptyCard text="No information available" />
        )}

        {!isLoading && stats && (
          // Note reverted to a simple table here as custom styling the InfoTable component
          // was too complex.
          <table className="directory-table">
            <tbody>
              <tr>
                <td className="label">Type</td>
                <td className="number">{dirTypeToDisplay}</td>
              </tr>
              <tr>
                <td className="label">Directory accounts</td>
                <td className="number">{numUsersInDirDisplay}</td>
              </tr>
              <tr>
                <td className="label">Accounts without email</td>
                <td className="number">{numUsersNoEmailDisplay}</td>
              </tr>
              <tr>
                <td className="label">Admin approved apps</td>
                <td className="number">{numAdminAppsDisplay}</td>
              </tr>
            </tbody>
          </table>
        )}
      </DashboardContainer>
    </div>
  );
};

export default AboutUserDirectory;

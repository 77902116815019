import { FC } from "react";
import NavLink from "../_common/components/NavLink";
import { pageDescriptions } from "../_common/pageDescriptions";
import { ClickNavLinkFunc } from "../_common/components/navigation/BreachSightNavItems";
import {
  usePermissions,
  hasUserPermission,
  UserThreatMonitoringRead,
} from "../_common/permissions";

interface ThreatMonitoringNavItemsProps {
  pathname: string;
  clickNavLinkFunc: ClickNavLinkFunc;
}

export const ThreatMonitoringBreachsightNavItems: FC<
  ThreatMonitoringNavItemsProps
> = ({ clickNavLinkFunc }) => {
  const rootPath = "/breachrisk/threatmonitoring";

  return (
    <>
      <NavLink
        icon="threat-monitoring"
        text="Threat Monitoring"
        onClick={clickNavLinkFunc(rootPath, "Threat Monitoring")}
        to={rootPath}
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
    </>
  );
};

interface ThreatMonitoringNavItemsNewProps
  extends ThreatMonitoringNavItemsProps {
  disabled: boolean;
}
export function ThreatMonitoringBreachsightNavItemsNew({
  disabled,
  clickNavLinkFunc,
}: ThreatMonitoringNavItemsNewProps) {
  const { permissions } = usePermissions();

  if (!hasUserPermission(permissions, UserThreatMonitoringRead)) {
    return null;
  }

  const rootPath = "/breachrisk/threatmonitoring";

  return (
    <>
      <NavLink
        text="Threat Monitoring"
        className="unstyled"
        disabled={disabled}
        onClick={clickNavLinkFunc(rootPath, "Threat Monitoring")}
        to={rootPath}
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
    </>
  );
}

export default ThreatMonitoringBreachsightNavItems;

import DefaultCoverImage from "../../_common/images/verifiedvendor/default-verified-vendor-cover.svg";
import TrustPageLogo, { TrustPageLogoSize } from "./TrustPageLogo";
import { LogoMeta, ProfileLogo } from "../../vendorrisk/types/sharedAssessment";
import GradeWithScoreCard from "../../_common/components/GradeWithScoreCard";
import ReportCard from "../../_common/components/ReportCard";
import "../style/TrustPageAboutCard.scss";
import IconButton from "../../_common/components/IconButton";
import EditableContactSection from "./EditableContactSection";
import { useModalV2 } from "../../_common/components/ModalV2";
import ManageSecurityRatingModal from "./modals/ManageSecurityRatingModal";
import EditTrustPageLogoModal from "./modals/EditTrustPageLogoModal";
import classNames from "classnames";
import TrustPageAPI from "../api/trustpage.api";
import { useDispatch } from "react-redux";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useState } from "react";
import useToggle from "../hooks/useToggle";
import TextField from "../../_common/components/TextField";
import RichTextEditV2 from "../../_common/components/RichTextEditV2";
import Button from "../../_common/components/core/Button";
import FocusedInputGroup from "./FocusedInputGroup";

interface ITrustPageAboutCardProps {
  companyName: string;
  companyDescription?: string;
  contactName?: string;
  contactEmail?: string;
  currentScore?: number;
  industryAvg?: number;
  includeRiskRating: boolean;
  includeIndustryAverage: boolean;

  profileLogo: ProfileLogo;
  profileLogoMeta?: LogoMeta;
  coverImageMeta?: LogoMeta;

  userCanMakeChanges: boolean;
}

const TrustPageAboutCard = (props: ITrustPageAboutCardProps) => {
  const dispatch = useDispatch();
  const showScore =
    props.userCanMakeChanges || (props.currentScore && props.includeRiskRating);

  const isEmpty = !(
    props.companyDescription ||
    props.contactName ||
    props.contactEmail ||
    showScore
  );
  const [companyDescription, setCompanyDescription] = useState(
    props.companyDescription ?? ""
  );
  const [editCompanyDescription, toggleEditCompanyDescription] =
    useToggle(false);

  const [updateMeta] =
    TrustPageAPI.useUpdateOwnSharedAssessmentMetadataV1Mutation();

  const [openManageSecurityRatingModal, manageSecurityRatingModal] = useModalV2(
    ManageSecurityRatingModal
  );
  const [openEditTrustPageLogoModal, editTrustPageLogoModal] = useModalV2(
    EditTrustPageLogoModal
  );

  const onContactDetailsChange = (name: string, email: string) => {
    updateMeta({
      contactName: name,
      contactEmail: email,
      companyDescription: props.companyDescription,
      includeRiskRating: props.includeRiskRating,
      includeIndustryAverage: props.includeIndustryAverage,
      profileLogo: props.profileLogo,
    })
      .unwrap()
      .then(() =>
        dispatch(addDefaultSuccessAlert(`Contact details updated successfully`))
      )
      .catch((e) => {
        console.error(e);
        dispatch(
          addSimpleErrorAlert(
            "Error updating Contact details on Trust Page. Please try again."
          )
        );
      });
  };

  const onCompanyDescriptionChange = (description: string) => {
    if (description === props.companyDescription) {
      return;
    } else {
      updateMeta({
        contactName: props.contactName,
        contactEmail: props.contactEmail,
        companyDescription: companyDescription,
        includeRiskRating: props.includeRiskRating,
        includeIndustryAverage: props.includeIndustryAverage,
        profileLogo: props.profileLogo,
      })
        .unwrap()
        .then(() =>
          dispatch(
            addDefaultSuccessAlert(`Company description updated successfully`)
          )
        )
        .catch((e) => {
          console.error(e);
          dispatch(
            addSimpleErrorAlert(
              "Error updating Company description on Trust Page. Please try again."
            )
          );
        });
    }
  };

  const onRatingVisibilityChange = (
    includeRiskRating: boolean,
    includeIndustryAverage: boolean
  ) => {
    updateMeta({
      contactName: props.contactName,
      contactEmail: props.contactEmail,
      companyDescription: props.companyDescription,
      includeRiskRating: includeRiskRating,
      includeIndustryAverage: includeIndustryAverage,
      profileLogo: props.profileLogo,
    })
      .unwrap()
      .then(() =>
        dispatch(addDefaultSuccessAlert(`Security rating updated successfully`))
      )
      .catch((e) => {
        console.error(e);
        dispatch(
          addSimpleErrorAlert(
            "Error updating security rating visibility on Trust Page. Please try again."
          )
        );
      });
  };

  return (
    <>
      {manageSecurityRatingModal}
      {editTrustPageLogoModal}
      <ReportCard newStyles={true} className={"about-card"}>
        <div className={"about-card-cover"}>
          <img
            className={"cover-image"}
            src={props.coverImageMeta?.logoUrl || DefaultCoverImage}
            alt="Trust Page Cover"
          />
          {props.userCanMakeChanges && (
            <IconButton
              className={"edit-cover-icon"}
              icon={<div className="cr-icon-pencil-2" />}
              onClick={() =>
                openEditTrustPageLogoModal({
                  profileLogo: props.profileLogoMeta,
                  coverImage: props.coverImageMeta,
                })
              }
            />
          )}
        </div>
        <div className={"logo-container"}>
          <TrustPageLogo
            vendorName={props.companyName}
            profileLogoType={props.profileLogo}
            profileLogoUrl={props.profileLogoMeta?.logoUrl}
            size={TrustPageLogoSize.Small}
          />
        </div>
        <div className={"about-card-inner"}>
          {isEmpty ? (
            <div className={"section"}>
              <div className={"section-header"}>
                {props.companyName} is updating their Trust Page
              </div>
              <div className={"section-content"}>
                It looks like the Trust Page is still in the process of being
                populated with security questionnaires, compliance badges,
                documents, and other important links.
              </div>
            </div>
          ) : (
            <>
              {showScore && (
                <div className={"section"}>
                  <div className={"section-header"}>
                    {props.userCanMakeChanges && (
                      <>
                        Your Score
                        <IconButton
                          icon={<div className="cr-icon-pencil-2" />}
                          onClick={() =>
                            openManageSecurityRatingModal({
                              currentScore: props.currentScore,
                              industryAvg: props.industryAvg,
                              includeRiskRating: props.includeRiskRating,
                              includeIndustryAverage:
                                props.includeIndustryAverage,
                              onRatingVisibilityChange:
                                onRatingVisibilityChange,
                            })
                          }
                        />
                      </>
                    )}
                  </div>

                  <div
                    className={classNames("section-content", {
                      greyed: !props.includeRiskRating,
                    })}
                  >
                    <GradeWithScoreCard
                      score={props.currentScore!}
                      outOf={950}
                      industryAvg={
                        props.includeIndustryAverage
                          ? props.industryAvg
                          : undefined
                      }
                    />
                  </div>
                </div>
              )}

              {(props.userCanMakeChanges || props.companyDescription) && (
                <FocusedInputGroup
                  className={"section"}
                  moveFocusOnEnter={false}
                  onClickOutside={() => {
                    if (editCompanyDescription) {
                      toggleEditCompanyDescription();
                      onCompanyDescriptionChange(companyDescription);
                    }
                  }}
                >
                  <>
                    <div className={"section-header"}>
                      About
                      {props.userCanMakeChanges && (
                        <>
                          {editCompanyDescription ? (
                            <Button
                              tertiary
                              onClick={() => {
                                toggleEditCompanyDescription();
                                setCompanyDescription(
                                  props.companyDescription ?? ""
                                );
                              }}
                            >
                              Cancel
                            </Button>
                          ) : (
                            <IconButton
                              icon={<div className="cr-icon-pencil-2" />}
                              onClick={() => toggleEditCompanyDescription()}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className={"section-content"}>
                      {editCompanyDescription && (
                        <TextField
                          value={companyDescription}
                          autoFocus={true}
                          debounceTime={500}
                          onChanged={(val) => setCompanyDescription(val)}
                          maxLength={500}
                          multiLine={true}
                          showCharCountAlways={true}
                          infoText={" "}
                        />
                      )}
                      {!editCompanyDescription && (
                        <>
                          {props.companyDescription ? (
                            <RichTextEditV2
                              readOnly
                              value={props.companyDescription}
                            />
                          ) : (
                            "Introduce your organization"
                          )}
                        </>
                      )}
                    </div>
                  </>
                </FocusedInputGroup>
              )}

              <EditableContactSection
                contactName={props.contactName}
                contactEmail={props.contactEmail}
                userCanMakeChanges={props.userCanMakeChanges}
                onSaveChanges={onContactDetailsChange}
              />
            </>
          )}
        </div>
      </ReportCard>
    </>
  );
};

export default TrustPageAboutCard;

import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import CompanyLogo from "../../_common/components/CompanyLogo";
import { Scope, UserApp } from "../api/types";
import { useDefaultHistory } from "../../_common/types/router";
import { appSummaryUrl, userApplicationsUrl } from "../UserBaseAppRouter";
import ScrollableDiv from "../../vendorrisk/components/ScrollableDiv";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSorting } from "../../_common/hooks";
import UserBaseAPI from "../api/userbase.api";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import "./UserAppPermissionsPanel.scss";
import { pluralise } from "../../_common/helpers";

const UserAppPermissionsPanel: FC<{ userUUID: string; app: UserApp }> = ({
  userUUID,
  app,
}) => {
  const history = useDefaultHistory();

  const onViewApplication = () => {
    history.push(appSummaryUrl(app.name), {
      backContext: {
        backTo: userApplicationsUrl(userUUID),
        backToText: `Back to users applications`,
      },
    });
  };

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppUserScopesV1Query({
    app: app.name,
    userUUID: userUUID,
  });

  const [sortedScopes, sortedBy, onSortChange] = useSorting<
    Scope,
    "exposure_level" | "permission"
  >(data?.scopes ?? [], "exposure_level", SortDirection.DESC, {
    exposure_level: {
      orderFuncs: [(s: Scope) => s.riskLevel, (s: Scope) => s.description],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
    },
    permission: {
      orderFuncs: [(s: Scope) => s.description, (s: Scope) => s.riskLevel],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
    },
  });

  const scopeIntoXTableRow = (s: Scope): IXTableRow<string> => {
    return {
      id: s.externalID,
      className: "permission-row",
      cells: [
        <XTableCell className="description-with-subtext" key="permission">
          <div className="description-main-text">{s.description}</div>
          <div className="description-subtext">{s.name}</div>
        </XTableCell>,
        <XTableCell className="risk-level" key="exposure_level">
          <ScopeRiskLevelDisplay riskLevel={s.riskLevel} />
        </XTableCell>,
      ],
    };
  };

  const permissionsNUmberString = `${pluralise(
    sortedScopes.length,
    "Permission",
    "Permissions"
  )} (${sortedScopes.length})`;

  return (
    <ReportCard newStyles className="userbase-user-app-permissions-panel">
      <div className="header">
        <CompanyLogo name={app.name} domain={app.domain} />
        <div className={"header-link"}>
          <Button onClick={onViewApplication}>
            View app profile
            <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>

      <div className="content">
        <ScrollableDiv newStyles>
          {!isLoading && <div className="stat">{permissionsNUmberString}</div>}

          <XTable
            className="permission-list"
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            loading={isLoading}
            columnHeaders={[
              { id: "permission", text: "Description", sortable: true },
              { id: "exposure_level", text: "Exposure level", sortable: true },
            ]}
            rows={sortedScopes.map(scopeIntoXTableRow)}
          />
        </ScrollableDiv>
      </div>
    </ReportCard>
  );
};

export default UserAppPermissionsPanel;

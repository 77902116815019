export interface getTrustPageCustomDomainsV1Resp {
  domains?: TrustPageCustomDomain[];
}

export enum AddCustomDomainError {
  NotScanned = "NOT_SCANNED",
  NotPartOfVendor = "NOT_PART_OF_VENDOR",
}

export type TrustPageCustomDomainStatus =
  | "pending_dns" // Setup ongoing
  | "complete" // Setup completed successfully
  | "issue_detected"; // Post-setup complete, something has gone wrong

export interface TrustPageCustomDomain {
  domain: string;
  isPrimary: boolean;
  status: TrustPageCustomDomainStatus;
}

export type TrustPageCustomDomainVerifyErrorType = "CNAME_INVALID";

export interface verifyTrustPageCustomDomainV1Resp {
  domain: string;
  status: TrustPageCustomDomainStatus;
  verifyErrors?: TrustPageCustomDomainVerifyErrorType[];
}

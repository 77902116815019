import { match, matchPath } from "react-router-dom";
import { useAppSelector } from "../../types/reduxHooks";
import { OrgAccessAuditLog, UserManageAuditLog } from "../../permissions";
import { isAdmin } from "../../../vendorrisk/helpers/roles";
import NavLink from "../NavLink";
import { canAccessSystemAdmin } from "../../selectors/permissions";
import { useCurrentOrg } from "../../selectors/commonSelectors";
import { organisationAccountType } from "../../types/organisations";
import * as H from "history";

type AccountNavItemsProps = {
  trialExpired: boolean;
  clickNavLinkFunc: (
    path: string,
    linkName: string,
    viewingVendorId?: string
  ) => () => void;
};

// Some settings pages are not nested under /settings, so we have a function to
// determine if we are on an active settings path.
const matchSettingsPath = (
  _match: match | null,
  location: H.Location<H.LocationState>
): boolean =>
  (matchPath(location.pathname, "/settings") ||
    matchPath(location.pathname, "/namedroleinvite") ||
    matchPath(location.pathname, "/rolebaseduseredit") ||
    matchPath(location.pathname, "/namedroleedit") ||
    matchPath(location.pathname, "/namedrolecreate")) !== null;

const AccountNavItems = (props: AccountNavItemsProps) => {
  const currentOrg = useCurrentOrg();
  const userData = useAppSelector((state) => state.common.userData);
  const isSystemAdmin = useAppSelector((state) => canAccessSystemAdmin(state));

  const hasUserPermission = (perm: string) =>
    userData.userPermissions.includes(perm);
  const hasOrgPermission = (perm: string) =>
    userData.orgPermissions.includes(perm);

  const isUserAdmin = isAdmin(userData.currentOrgRoles);
  const currentOrgIsFreeOrg =
    currentOrg?.accountType === organisationAccountType.free;

  // items visibility
  const showAuditLog =
    hasUserPermission(UserManageAuditLog) &&
    hasOrgPermission(OrgAccessAuditLog);
  const showSettings = isUserAdmin || currentOrgIsFreeOrg;
  const showSystemAdmin = isSystemAdmin;

  // section visibility
  const showAccountSection = showAuditLog || showSettings || showSystemAdmin;

  return (
    <>
      {showAccountSection && (
        <div className="subtitle" id="vendor_risk_subtitle">
          Account
        </div>
      )}
      {showAuditLog && (
        <NavLink
          disabled={props.trialExpired}
          icon="audit-log"
          text="Audit Log"
          onClick={props.clickNavLinkFunc("/audit_log", "Audit Log")}
          to="/audit_log"
        />
      )}
      {showSettings && (
        <NavLink
          disabled={props.trialExpired}
          icon="focus"
          text="Settings"
          onClick={props.clickNavLinkFunc("/settings", "Settings")}
          to="/settings"
          isActive={matchSettingsPath}
        />
      )}
      {showSystemAdmin && (
        <NavLink
          icon="spanner"
          text="System Admin"
          onClick={props.clickNavLinkFunc("/admin", "System Admin")}
          to="/admin"
        />
      )}
    </>
  );
};

export default AccountNavItems;

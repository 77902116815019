import TrustPageEditableCard from "./TrustPageEditableCard";
import IconButton from "../../_common/components/IconButton";
import { FC, useMemo } from "react";
import QuestionnairesTable from "./QuestionnairesTable";
import { ISharedAssessment } from "../../vendorrisk/types/sharedAssessment";
import {
  mapISharedAssessmentAddToProfileSurveyToISurveyRecord,
  mapISurveyListItemResponseToSurveyRecord,
} from "../helpers/types";
import { useModalV2 } from "../../_common/components/ModalV2";
import AddQuestionnairesModal from "./modals/AddQuestionnairesModal";
import { surveyType } from "../../_common/types/surveyTypes";
import {
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../_common/types/survey";
import {
  useHasUserOrPortfolioPermissions,
  UserWritePrefilledSurveys,
} from "../../_common/permissions";

interface IQuestionnairesCardProps {
  vendorId: number;
  sharedAssessment: ISharedAssessment;
  surveyTypes: surveyType[];
  receivedSurveys: ISurveyListItemResponse[];
  isOwnSharedProfile: boolean;
}

const QuestionnairesCard: FC<IQuestionnairesCardProps> = ({
  vendorId,
  sharedAssessment,
  receivedSurveys: originalReceivedSurveys,
  surveyTypes,
  isOwnSharedProfile,
}: IQuestionnairesCardProps) => {
  const [openAddQuestionnairesModal, addQuestionnairesModal] = useModalV2(
    AddQuestionnairesModal
  );
  const userHasWritePrefilledSurveysPermission =
    useHasUserOrPortfolioPermissions(UserWritePrefilledSurveys);

  const canViewPrivateSurvey = (originalSurveyId: number): boolean =>
    originalReceivedSurveys.some((s) => s.id === originalSurveyId);

  const [surveys, trustPageSurveyIds] = useMemo(() => {
    const surveys = sharedAssessment.questionnaires
      // We do not include any draft surveys in new Trust page table anymore
      .filter((q) => q.status === SurveyStatus.Published)
      .map((q) =>
        mapISurveyListItemResponseToSurveyRecord(
          q,
          true,
          q.originalID > 0 && canViewPrivateSurvey(q.originalID)
        )
      );

    if (
      isOwnSharedProfile &&
      sharedAssessment.addToSharedProfileQuestionnaires
    ) {
      surveys.push(
        ...sharedAssessment.addToSharedProfileQuestionnaires.map((q) =>
          mapISharedAssessmentAddToProfileSurveyToISurveyRecord(
            vendorId,
            q,
            true,
            q.surveyId > 0 && canViewPrivateSurvey(q.surveyId)
          )
        )
      );
    }

    const trustPageSurveyIds = surveys.map((s) =>
      s.kind === "prefilled" ? s.originalSurveyId : s.id
    );
    return [surveys, trustPageSurveyIds];
  }, [sharedAssessment, isOwnSharedProfile, vendorId]);

  // Filter out archived and cancelled surveys
  const receivedSurveys = useMemo(() => {
    return originalReceivedSurveys
      .filter((s) => !s.archived && s.status !== SurveyStatus.Cancelled)
      .filter((s) => !trustPageSurveyIds.includes(s.id))
      .map((s) => {
        // try to find the survey type from the typeid since the type coming from the survey data
        // might have a custom name assigned by the org to the system survey type
        const surveyType = s.typeId
          ? surveyTypes.find((st) => st.id === s.typeId)?.name
          : undefined;
        return {
          ...s,
          type: surveyType || s.type,
        };
      });
  }, [originalReceivedSurveys, trustPageSurveyIds, surveyTypes]);

  const handleOpenAddQuestionnairesModal = () => {
    openAddQuestionnairesModal({
      surveyTypes: surveyTypes,
      receivedSurveys: receivedSurveys,
    });
  };

  return (
    <>
      {addQuestionnairesModal}
      <TrustPageEditableCard
        className={"questionnaires-card"}
        header={"Security questionnaires"}
        editButton={
          isOwnSharedProfile && userHasWritePrefilledSurveysPermission ? (
            <IconButton
              icon={<div className="cr-icon-plus" />}
              onClick={handleOpenAddQuestionnairesModal}
            />
          ) : null
        }
      >
        <QuestionnairesTable
          onRequestAccess={() => {}}
          surveys={surveys}
          isOwnSharedProfile={isOwnSharedProfile}
          userHasWritePrefilledSurveysPermission={
            userHasWritePrefilledSurveysPermission
          }
        />
      </TrustPageEditableCard>
    </>
  );
};

export default QuestionnairesCard;

import PageHeader from "../../_common/components/PageHeader";
import SearchBox from "../../_common/components/SearchBox";
import ReportCard from "../../_common/components/ReportCard";
import { useDefaultHistory } from "../../_common/types/router";
import XTable, {
  SortDirection,
  XTableCell,
  IXTableColumnHeader,
  IXTableRow,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import PivotTabs, { PivotTab } from "../../_common/components/PivotTabs";
import { tabButtonsStylingType } from "../../_common/components/TabButtons";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import UserIcon from "../../_common/images/remediation-icon.svg";
import ActionBar from "../../_common/components/ActionBar";
import Button from "../../_common/components/core/Button";
import { useAppDispatch } from "../../_common/types/reduxHooks";

import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import CyberTrendScore from "../../vendorrisk/components/CyberTrendScore";

import { UserWithStats } from "../api/types";
import UserBaseAPI from "../api/userbase.api";
import "./UsersView.scss";

import { FC, useEffect, useState } from "react";
import TeamsPills from "../components/TeamsPills";
import { userUrl } from "../UserBaseAppRouter";
import { useLocation } from "react-router-dom";
import { SingleStatSize } from "../components/SingleStat";
import DashboardStat, {
  DashboardStatToShow,
} from "../components/DashboardStat";
import UnmonitoredUsers from "../components/UnmonitoredUsers";
import { DropdownItem } from "../../_common/components/core/DropdownV2";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import {
  FilterBar,
  FilterLabelContainer,
  FilterPanelContainer,
  FilterTypes,
  isFilterActive,
} from "../../vendorrisk/components/filter";
import { useFilters } from "../../vendorrisk/reducers/filters.actions";
import SlidePanel from "../../_common/components/SlidePanel";
import { parseUserRiskFilterFromQuery } from "../helpers/filters";
import { getClearedFilters } from "../../vendorrisk/components/filter/FilterPanel";
import { Filters } from "../../vendorrisk/components/filter/types";
import { setCustomerDataFilters } from "../../vendorrisk/reducers/cyberRiskActions";

const PAGE_SIZE = 100;

const NAME_COL = "name_col";
const ROLE_COL = "role_col";
const TEAM_COL = "team_col";
const SECURITY_RATING_COL = "security_rating_col";
const TREND_COL = "trend_col";
const TOTAL_APPS_COL = "total_apps_col";
const NOT_APPROVED_APPS_COL = "not_approved_apps_col";
const WAIVED_APPS_COL = "waived_apps_col";

const supportedFilters = [
  FilterTypes.USERRISK_USER_UUIDS,
  FilterTypes.USERRISK_APPS,
  FilterTypes.USERRISK_TEAMS,
  FilterTypes.USERRISK_ROLES,
];

const UsersView: FC = () => {
  const history = useDefaultHistory();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState<string>("");
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const filters = useFilters();
  const filtersActive = isFilterActive(filters, supportedFilters);

  const { data: monitoredUsers, isFetching: monitoredIsFetching } =
    UserBaseAPI.useGetUserBaseUsersV1Query({
      userUUIDs: filters.userRiskUserUUIDs,
      excludeUsers: filters.userRiskExcludeUsers,
      appNames: filters.userRiskAppNames,
      excludeApps: filters.userRiskExcludeApps,
      teams: filters.userRiskTeams,
      excludeTeams: filters.userRiskExcludeTeams,
      roles: filters.userRiskRoles,
      excludeRoles: filters.userRiskExcludeRoles,
    });

  useEffect(() => {
    const queryParamFilters = {
      ...getClearedFilters(supportedFilters),
      ...parseUserRiskFilterFromQuery(history),
    } as Filters;
    if (isFilterActive(queryParamFilters, supportedFilters)) {
      dispatch(setCustomerDataFilters(queryParamFilters));
    }
  }, []);

  const { data: unmonitoredUsers, isFetching: unmonitoredIsFetching } =
    UserBaseAPI.useGetUserBaseUnmonitoredUsersV1Query();

  const [bulkUnmonitorInProgress, setBulkUnmonitorInProgress] =
    useState<boolean>(false);

  const [openUnmonitorConfirmationModal, unmonitorConfirmationModal] =
    useConfirmationModalV2();

  // Get the users to display from the API
  const usersToUse = monitoredUsers?.users ?? [];

  // Filter the users by the search text
  const bySearchText = (user: UserWithStats): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    if (
      user.roles &&
      user.roles.some((r) => r.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    if (
      user.teams &&
      user.teams.some((t) => t.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    return user.name.toLocaleLowerCase().indexOf(toFind) >= 0;
  };

  const filteredUsers = usersToUse.filter(bySearchText);

  // Sort and Paginate the users
  const [
    usersToDisplay,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserWithStats,
    | typeof NAME_COL
    | typeof ROLE_COL
    | typeof TEAM_COL
    | typeof SECURITY_RATING_COL
    | typeof TOTAL_APPS_COL
    | typeof NOT_APPROVED_APPS_COL
    | typeof WAIVED_APPS_COL
    | typeof TREND_COL
  >(
    filteredUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [
          (u) =>
            (u.roles ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            (u.teams ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(u) => u.adjustedRating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TOTAL_APPS_COL]: {
        orderFuncs: [(u) => u.totalApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [NOT_APPROVED_APPS_COL]: {
        orderFuncs: [(u) => u.notApprovedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [WAIVED_APPS_COL]: {
        orderFuncs: [(u) => u.waivedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TREND_COL]: {
        orderFuncs: [(u) => u.trend],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const columnHeaders: IXTableColumnHeader[] = [
    { id: NAME_COL, text: "Name", sortable: true },
    { id: ROLE_COL, text: "Role", sortable: true },
    { id: TEAM_COL, text: "Team", sortable: true },
    { id: SECURITY_RATING_COL, text: "Security rating", sortable: true },
    { id: TREND_COL, text: "Trend", sortable: true },
    { id: TOTAL_APPS_COL, text: "Total apps", sortable: true },
    {
      id: NOT_APPROVED_APPS_COL,
      text: "Not approved apps",
      sortable: true,
    },
    {
      id: WAIVED_APPS_COL,
      text: "Waived apps",
      sortable: true,
    },
  ];

  const [unmonitorUsersV1] = UserBaseAPI.useUnmonitorUsersV1Mutation();

  const unmonitorUsers = (userUUIDs: Set<string>) => {
    const numSelected = userUUIDs.size;
    openUnmonitorConfirmationModal({
      title: `Confirm disable monitoring`,
      description: (
        <>
          <p>
            Disabling monitoring will remove{" "}
            {numSelected == 1 ? "this user" : "these users"} and any associated
            risks from your risk profile, which will no longer impact your
            overall UserRisk score.
          </p>
          <p>
            You will also lose access to{" "}
            {numSelected == 1 ? "this user's profile" : "these users' profiles"}
            , including their SaaS app usage and permissions.
          </p>
        </>
      ),
      dangerousAction: true,
      cancelText: "Cancel",
      buttonText: "Disable monitoring",
      buttonAction: () => {
        setBulkUnmonitorInProgress(true);
        unmonitorUsersV1({
          user_uuids: Array.from(userUUIDs.values()),
        })
          .unwrap()
          .then(() => {
            // If the user's we're unmonitoring don't match the selected rows, then remove the
            // users from the selected rows
            const diff = new Set<string>();
            for (const v of selectedRows) {
              if (!userUUIDs.has(v)) {
                diff.add(v);
              }
            }
            setSelectedRows(diff);
            dispatch(
              addDefaultSuccessAlert(
                `${
                  numSelected == 1 ? "1 user is" : `${numSelected} users are`
                } no longer monitored`
              )
            );
          })
          .catch(() => {
            dispatch(
              addDefaultUnknownErrorAlert(
                `Unable to unmonitor ${numSelected} users`
              )
            );
          })
          .finally(() => {
            setBulkUnmonitorInProgress(false);
          });
      },
    });
  };

  const rows: IXTableRow<string>[] = usersToDisplay.map((u) => {
    return {
      id: u.uuid,
      onClick: () => {
        history.push(userUrl(u.uuid));
      },
      cells: [
        <XTableCell key={NAME_COL}>{u.name}</XTableCell>,
        <XTableCell key={ROLE_COL}>{u.roles ? u.roles[0] : ""}</XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <div className="score-container">
            <ColorGrade score={u.adjustedRating} size={ColorGradeSize.Small} />
            <Score score={u.adjustedRating} small />
          </div>
        </XTableCell>,
        <XTableCell key={TREND_COL}>
          <div className="cyber-trend">
            <CyberTrendScore trend={u.trend} />
          </div>
        </XTableCell>,
        <XTableCell key={TOTAL_APPS_COL}>{u.totalApps}</XTableCell>,
        <XTableCell key={NOT_APPROVED_APPS_COL}>
          {u.notApprovedApps}
        </XTableCell>,
        <XTableCell key={WAIVED_APPS_COL}>{u.waivedApps}</XTableCell>,
      ],
      iconOptions: [
        {
          id: "meatball",
          icon: <div className={"cr-icon-dots-menu"} />,
          disabled: false,
          dropdownItems: [
            <DropdownItem
              key="unmomitor"
              stopPropagation
              className="unmonitor-meatball"
              onClick={() => unmonitorUsers(new Set([u.uuid]))}
            >
              <div className="unmonitor-label">
                <i className="cr-icon-minus-circle" />
                <div>Disable monitoring</div>
              </div>
              <div className="unmonitor-sublabel">
                Do not include in the risk profile
              </div>
            </DropdownItem>,
          ],
        },
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => {
            history.push(userUrl(u.uuid));
          },
        },
      ],
    };
  });

  const isFetching = monitoredIsFetching || unmonitoredIsFetching;

  return (
    <div className="userbase-users">
      <FilterBar
        dispatch={dispatch}
        isCustomer={true}
        active={filtersActive}
        supportedFilters={supportedFilters}
      />
      <PageHeader
        history={history}
        title={
          <div className="title-and-count">
            Users
            <DashboardStat
              statToShow={DashboardStatToShow.UserCount}
              statSize={SingleStatSize.Small}
            />
          </div>
        }
        infoSection={
          <>
            <p>
              Users shows all the users associated with your organization. Each
              user is given a security rating based on the user-related
              activities we have scanned. To get more information about a
              particular user, click on the user.
            </p>
          </>
        }
        rightSection={
          <FilterLabelContainer
            onClick={() => setFilterPanelOpen(!filterPanelOpen)}
            supportedFilters={supportedFilters}
            isCustomer={true}
            disabled={isFetching}
          />
        }
      />

      <ReportCard newStyles>
        <PivotTabs styling={tabButtonsStylingType.Flat}>
          <PivotTab
            id="monitored-users"
            headerText={
              <div className="monitored-users-tab">
                <i className="cr-icon-accepted" />
                <div className="tab-text">
                  {monitoredIsFetching
                    ? "Monitored"
                    : `Monitored (${(monitoredUsers?.users ?? []).length})`}
                </div>
              </div>
            }
          >
            <div className={"table-filters"}>
              <SearchBox
                placeholder={"Search users"}
                onChanged={(q) => {
                  onPageChange(1);
                  setSearchText(q);
                }}
                value={searchText}
              />
            </div>

            <XTable
              className={"userbase-users-list"}
              loading={monitoredIsFetching}
              sortedBy={sortedBy}
              onSortChange={onSortChange}
              iconOptions
              pagination={{
                currentPage: currentPage,
                totalPages: totalPages,
                onPageChange: onPageChange,
                hidePaginationIfSinglePage: true,
              }}
              columnHeaders={columnHeaders}
              selectable
              onSelectClick={(rowId, newSelectedState) => {
                const newSelectedRows = new Set(selectedRows.values());
                if (newSelectedState) {
                  newSelectedRows.add(rowId);
                } else {
                  newSelectedRows.delete(rowId);
                }
                setSelectedRows(newSelectedRows);
              }}
              onSelectToggle={(shouldSelectAll) => {
                if (shouldSelectAll) {
                  setSelectedRows(new Set(filteredUsers.map((u) => u.uuid)));
                } else {
                  setSelectedRows(new Set());
                }
              }}
              onSelectAllClick={() => {
                setSelectedRows(new Set(filteredUsers.map((u) => u.uuid)));
              }}
              onSelectNoneClick={() => {
                setSelectedRows(new Set());
              }}
              rows={rows.map((r) => ({
                ...r,
                selected: selectedRows.has(r.id),
              }))}
            />

            {searchText.length == 0 &&
              !filtersActive &&
              rows.length == 0 &&
              !monitoredIsFetching && (
                <EmptyCardWithAction
                  emptyText="No current users monitored"
                  emptySubText="You haven’t monitored any users yet. When you start monitoring a user, they will appear here."
                  iconSrc={UserIcon}
                />
              )}

            {(searchText.length > 0 || filtersActive) &&
              rows.length == 0 &&
              !monitoredIsFetching && (
                <SearchEmptyCard
                  clearText={"Clear filter"}
                  onClear={() => {
                    setSearchText("");
                    dispatch(
                      setCustomerDataFilters(
                        getClearedFilters(supportedFilters)
                      )
                    );
                  }}
                  searchItemText={"monitored users"}
                />
              )}

            <ActionBar
              className="bulk-action-bar"
              active={selectedRows.size > 0}
            >
              <div className="bulk-actions">
                <div className="num-selected">
                  {selectedRows.size == 1
                    ? "1 user selected"
                    : `${selectedRows.size} users selected`}
                </div>
                <div className="bulk-action-buttons">
                  <Button tertiary onClick={() => setSelectedRows(new Set())}>
                    Cancel
                  </Button>
                  <Button
                    className="disable-monitoring"
                    onClick={() => unmonitorUsers(selectedRows)}
                    loading={bulkUnmonitorInProgress}
                    danger
                  >
                    <i className="cr-icon-minus-circle" />
                    Disable monitoring
                  </Button>
                </div>
              </div>
            </ActionBar>
          </PivotTab>

          <PivotTab
            id="unmonitored-users"
            headerText={
              <div className="unmonitored-users-tab">
                <i className="cr-icon-minus-circle" />
                <div className="tab-text">
                  {unmonitoredIsFetching
                    ? "Not monitored"
                    : `Not monitored (${
                        (unmonitoredUsers?.users ?? []).length
                      })`}
                </div>
              </div>
            }
          >
            <UnmonitoredUsers />
          </PivotTab>
        </PivotTabs>
      </ReportCard>
      <SlidePanel
        active={filterPanelOpen}
        title="Filter by"
        newStyles
        dimContent
        onClose={() => setFilterPanelOpen(false)}
      >
        <FilterPanelContainer
          closePanel={() => setFilterPanelOpen(false)}
          supportedFilters={supportedFilters}
          isCustomer={true}
          startAllExpanded={true}
        />
      </SlidePanel>
      {unmonitorConfirmationModal}
    </div>
  );
};

export default UsersView;

export enum ControlState {
  Unknown = "unknown",
  Passed = "passed",
  Failed = "failed",
  Partial = "partial",
}

export const ControlStateIcon: Record<ControlState, string> = {
  [ControlState.Unknown]: "cr-icon-question", // TODO - replace with real icon
  [ControlState.Failed]: "cr-icon-cross",
  [ControlState.Partial]: "cr-icon-dash",
  [ControlState.Passed]: "cr-icon-check",
};
export const ControlStateText: Record<ControlState, string> = {
  [ControlState.Passed]: "Control met",
  [ControlState.Failed]: "Risks detected",
  [ControlState.Partial]: "Control partially met",
  [ControlState.Unknown]: "Evidence required",
};

export interface RiskControl {
  controlFamily: string;
  id: string;
  text: string;
}

export interface ControlFamily {
  domain: string;
  id: string;
  name: string;
  controls: RiskControl[];
}

export interface RiskDomain {
  id: string;
  name: string;
  controlFamilies: ControlFamily[];
}

export interface ControlTotals {
  passedCount: number;
  failedCount: number;
  partiallyMetCount: number;
  unknownCount: number;
}

export interface ControlCheck {
  id: string;
  text: string;
  riskId?: string;
}

export interface CheckWithSource extends SecurityProfileCheck {
  vendorDocumentID?: string;
  documentRepositoryID?: string;
  additionalEvidenceID?: number;
  surveyID?: number;
}

export interface SecurityProfileCitation {
  text: string;
}

export interface SecurityProfileCheck {
  id: string;
  pass: boolean;
  citations: SecurityProfileCitation[];
}

export interface SharedVendorDocument {
  uuid: string;
  vendorId: number;
  name?: string;
  filename: string;
  createdAt: string;
  updatedAt: string;
}

export interface ControlDetail {
  id: string;
  text: string;
  objective: string;
  lastScannedAt: string;
  checks: ControlCheck[];
}

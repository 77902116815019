import { SurveyStatus } from "../../_common/types/survey";
import { SurveyFramework } from "../../survey_builder/types/frameworks";
import { IAttachment } from "../../_common/types/attachment";
import {
  AdditionalEvidenceSummary,
  PublicAdditionalEvidenceSummary,
} from "./additionalEvidence";
import { LabelColor } from "../../_common/types/label";
import { RiskAssessmentEvidencePage } from "./evidence";
import { SeverityInt } from "../../_common/types/severity";

export interface VendorAssessmentSummary {
  id: number;
  name: string;
  streamID: number;
  commentary?: string;
  includeWebsiteRisks: boolean;
  includeWaivers: boolean;
  authorID: number;
  authorName: string;
  authorEmail: string;
  publishedAt?: string;
  reassessmentDate?: string;
}

export enum VendorAssessmentStatus {
  Draft = "draft",
  Published = "published",
  Cancelled = "cancelled",
  WithCustomer = "WithCustomer", // used exclusively by managed vendor assessments
  NotStarted = "Not started", // used exclusively by managed vendor assessments
}

export enum AssessmentClassificationType {
  NotAssessed = 0,
  InProgress = 1,
  Overdue = 2,
  DueWithin30Days = 3,
  DueOutside30Days = 4,
  Reschedule = 5,
}

export const VendorAssessmentStatusPill: Record<
  VendorAssessmentStatus,
  { color: LabelColor; text: string }
> = {
  [VendorAssessmentStatus.Draft]: {
    color: LabelColor.Orange,
    text: "In progress",
  },
  [VendorAssessmentStatus.Published]: {
    color: LabelColor.Green,
    text: "Completed",
  },
  [VendorAssessmentStatus.NotStarted]: {
    color: LabelColor.Grey,
    text: "Not started",
  },
  [VendorAssessmentStatus.WithCustomer]: {
    color: LabelColor.Violet,
    text: "Customer review",
  },
  [VendorAssessmentStatus.Cancelled]: {
    color: LabelColor.Red,
    text: "Cancelled",
  },
};

export interface VendorAssessment {
  id: number;
  organisationID: number;
  vendorWatchID: number;
  datastoreVendorID: number;
  status: VendorAssessmentStatus;
  commentary?: string;
  introduction?: string;
  background?: string;
  conclusion?: string;
  authorID: number;
  authorName: string;
  authorEmail: string;
  authorAvatar: string;
  publishedAt?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  editInProgressAt: string;
  editInProgressBy: number;
  editingName: string;
  editingEmail: string;
  includeWebsiteRisks: boolean;
  numDomains: number;
  includeWaivers: boolean;
  withCustomer: boolean;
  customerCommentary?: string;
  remediationRequestId?: number;
  managedAssessmentId?: number;
  numWaivers?: number;
  hideIntroduction?: boolean;
  hideBackground?: boolean;
  hideCommentary?: boolean;
  hideConclusion?: boolean;
  publishedIntroduction?: string;
  publishedBackground?: string;
  publishedCommentary?: string;
  publishedConclusion?: string;
  includeAllHostnames: boolean;
  streamID: number;
  name: string;
  versionNum: number;
  canAutofill?: boolean;
  autofilled?: boolean;
  hideAutofillSummary?: boolean;
}

export interface SurveyAsEvidence {
  id: number;
  name: string;
  type: string;
  state: SurveyStatus;
  organisationID: number;
  vendorID: number;
  originalID: number;
  answersID?: number;
  framework: SurveyFramework;
  author: string;
  authorName: string;
  authorAvatar: string;
  sharedAt?: string;
  createdAt: string;
  selected: boolean;
  riskScore?: number;
  attachments?: IAttachment;
  adjustedRiskScore?: number;
  numRisks: number;
  numTotalRisks: number;
  numWaivers: number;
  numQuestions?: number;
  numAnswers?: number;
  latestActivityAt?: string;
}

export interface PublicSurveyAsEvidence extends SurveyAsEvidence {
  accessible: boolean;
}

export interface VendorAssessmentHostnameSnapshot {
  assessmentId: number;
  hostname: string;
  scannedAt?: string;
  score?: number;
  labels?: { name: string; colour: LabelColor }[];
}

export interface VendorAssessmentEvidence {
  surveys: SurveyAsEvidence[];
  publicSurveys: PublicSurveyAsEvidence[];
  additional: AdditionalEvidenceSummary[];
  publicAdditional: PublicAdditionalEvidenceSummary[];
  pages?: RiskAssessmentEvidencePage[];
  includedHostnames?: number[];
  numCloudscanRiskWaivers: number;
  numScanningRisks: number;
  numScanningHosts: number;
  numActiveRiskWaivers: number;
  numUnapprovedRiskWaivers: number;
  numPendingPublicRiskWaivers: number;
  loading: boolean;
  hostnameSnapshots?: VendorAssessmentHostnameSnapshot[]; // only on published assessments
}

export interface VendorAssessmentRiskComment {
  vendorAssessmentID: number;
  riskID: string;
  comments: string;
}

export interface AssessmentRiskHostnames {
  assessmentID: number;
  riskID: string;
  countHostnamesFailed: number;
  hostnamesSnapshotPending: boolean;
  hostnamesFailed: string[];
}

export interface VendorAssessmentClassification {
  classification: number;
  title: string;
  usageCount: number;
}

export interface VendorAssessmentClassificationMap {
  [classification: string]: VendorAssessmentClassification;
}

export interface VendorAssessmentAuthor {
  id: number;
  name: string;
  emailAddress: string;
}

// helper types and functions to convert shared and non-shared evidence types into a single type
// in a future release we should consider doing this in the backend but we don't want to break backwards compatibility
// with the current vendor assessment feature before release and minimise the changes for this one

export interface SurveyEvidence {
  id: number;
  isPublic: boolean;
  selected: boolean;
  name: string;
  status: SurveyStatus;
  numRisks: number;
  numTotalRisks: number;
  numWaived: number;
  score: number;
  dateCompleted?: string;
  accessible: boolean;
}

export const GetSurveyEvidence = (
  evidence: VendorAssessmentEvidence,
  includeWaivers: boolean
): SurveyEvidence[] => [
  ...evidence.surveys?.map((s) => ({
    id: s.id,
    isPublic: false,
    selected: s.selected,
    name: s.name,
    status: s.state,
    numRisks: s.numRisks,
    numTotalRisks: s.numTotalRisks,
    numWaived: includeWaivers ? s.numWaivers : 0,
    score: (includeWaivers ? s.adjustedRiskScore : s.riskScore) ?? -1,
    dateCompleted: s.sharedAt,
    accessible: true,
  })),
  ...evidence.publicSurveys?.map((s) => ({
    id: s.id,
    isPublic: true,
    selected: s.selected,
    name: s.name,
    status: SurveyStatus.Complete,
    numRisks: s.numRisks,
    numTotalRisks: s.numTotalRisks,
    numWaived: includeWaivers ? s.numWaivers : 0,
    score: (includeWaivers ? s.adjustedRiskScore : s.riskScore) ?? -1,
    dateCompleted: s.sharedAt,
    accessible: s.accessible,
  })),
];

export interface AdditionalEvidenceEvidence {
  id: number;
  shared: boolean;
  selected: boolean;
  name: string;
  numRisks: number;
  numWaived: number;
  dateAdded: string;
}

export interface VendorAssessmentKeyRisk {
  riskID: string;
  severity: SeverityInt;
}

export const GetAdditionalEvidenceEvidence = (
  evidence: VendorAssessmentEvidence,
  includeWaivers: boolean
): AdditionalEvidenceEvidence[] => [
  ...evidence.additional?.map((a) => ({
    id: a.id,
    shared: false,
    selected: a.selected,
    name: a.name,
    numRisks: a.totalRisks,
    numWaived: includeWaivers ? a.numWaivedRisks : 0,
    dateAdded: a.createdAt,
  })),
  ...evidence.publicAdditional?.map((a) => ({
    id: a.id,
    shared: true,
    selected: a.selected,
    name: a.name,
    numRisks: a.totalRisks,
    numWaived: includeWaivers ? a.numWaivedRisks : 0,
    dateAdded: a.createdAt,
  })),
];

export const GetAssessmentWaiverText = (
  numRiskWaivers: number,
  numUnapprovedRiskWaivers: number,
  numPendingPublicRiskWaivers: number
): string => {
  let waiverText;

  const pendingWaiverCounts: string[] = [];
  let activeWaiverCount = 0;

  if (numRiskWaivers > 0) {
    activeWaiverCount = numRiskWaivers;
  }
  if (numUnapprovedRiskWaivers > 0) {
    pendingWaiverCounts.push(`${numUnapprovedRiskWaivers} awaiting approval`);
  }
  if (numPendingPublicRiskWaivers > 0) {
    pendingWaiverCounts.push(`${numPendingPublicRiskWaivers} shared pending`);
  }

  waiverText = `${activeWaiverCount} active`;
  if (pendingWaiverCounts.length > 0) {
    waiverText = `${waiverText} (${pendingWaiverCounts.join(", ")})`;
  }

  return waiverText;
};

export interface VendorAssessmentNarrativeSection {
  section: string;
  published: boolean;
  content: string;
  hidden: boolean;
}

export const AutofillSummarySection = "autofill_summary";

export type VendorAssessmentScoreStatus = "NotStarted" | "Started" | "Complete";

export interface VendorAssessmentScores {
  scanningScore: number;
  surveyScore: number;
  overallScore: number;
  overallScoreDescription: string;
  categoryScores: { [category: string]: number };
}

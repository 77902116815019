import { DefaultThunkDispatch } from "../types/redux";
import { backContext, DefaultRouteProps, locationState } from "../types/router";

import { Location, LocationDescriptorObject } from "history";
import { Link } from "react-router-dom";
import "../style/components/SurveyDetails.scss";
import LoadingBanner from "../components/core/LoadingBanner";
import {
  IScoreProjection,
  ISurveyImportDetails,
  ISurveyListItemResponse,
  ISurveyReminder,
  SurveyRiskVisibility,
  SurveyStatus,
} from "../types/survey";
import { ICorrespondenceMessage } from "../types/correspondenceMessage";
import { IUserMiniMap } from "../types/user";
import {
  fetchSurveyDetails,
  fetchSurveyDetailsForAssessment,
  fetchSurveyMessages,
  fetchSurveyMessagesForAssessment,
  fetchSurveyReminders,
  fetchSurveyTimeline,
  fetchSurveyTimelineForAssessment,
  timelineData,
} from "../reducers/surveyDetails.actions";
import { useCallback, useEffect, useState } from "react";
import { formatDateAsLocal, LogError, vendorUrlPrefix } from "../helpers";
import {
  OrgAccessRelationshipQuestionnaireAutomation,
  OrgExtraQuestionnaireStatusChanges,
  OrgQuestionnaireScores,
  userHasPermissionForVendorFromState,
  UserSystemRoleVendorManagementAnalyst,
  UserWriteSurveyImportExport,
  UserWriteSurveys,
} from "../permissions";
import TabButtons from "../components/TabButtons";
import { getAuthFromLocalStorage } from "../reducers/commonActions";
import SurveyDetailsProgressCard from "../components/surveydetails/SurveyDetailsProgressCard";
import SurveyDetailsOverviewCard from "../components/surveydetails/SurveyDetailsOverviewCard";
import SurveyDetailsUnansweredQuestionsCard from "../components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import Button from "../components/core/Button";
import SurveyDetailsMessagesPanel from "../components/surveydetails/SurveyDetailsMessagesPanel";
import SurveyDetailsHistoryCard from "../components/surveydetails/SurveyDetailsHistoryCard";
import SurveyDetailsRemediationCard from "../components/surveydetails/SurveyDetailsRemediationCard";
import SurveyDetailsDocumentsCard, {
  getAttachmentsFromGroups,
} from "../components/surveydetails/SurveyDetailsDocumentsCard";
import Timeline from "../components/Timeline";
import {
  fetchProjectionForExistingRemediatingSurvey,
  fetchVendorWatchStatus,
} from "../../vendorrisk/reducers/cyberRiskActions";
import SurveyDetailsScoreProjectionCard from "../components/surveydetails/SurveyDetailsScoreProjectionCard";
import SurveyDetailsRisksCard from "../components/surveydetails/SurveyDetailsRisksCard";
import DropdownV2, { DropdownItem } from "../components/core/DropdownV2";
import { useModalV2 } from "../components/ModalV2";
import SurveyDetailsInviteUsersModal from "../components/surveydetails/SurveyDetailsInviteUsersModal";
import { InfoBar } from "../components/InfoBar";
import PageHeader from "../components/PageHeader";
import { surveyStatusIn } from "../components/surveydetails/SurveyDetailsStatusDropdown";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../reducers/messageAlerts.actions";
import { SurveyUsageType } from "../types/surveyTypes";
import { CreateQuestionnaireBulkLocationState } from "../../vendorrisk/views/CreateQuestionnaireBulk";
import { CreateRelationshipQuestionnaireLocationState } from "../../vendorrisk/views/CreateVendorRelationshipQuestionnaire";
import { crumb, getVendorPageBreadcrumbs } from "../components/Breadcrumbs";
import { useMessageCounts } from "../components/correspondence/correspondence.helpers";
import SurveyDetailsOptionsCard from "../components/surveydetails/SurveyDetailsOptionsCard";
import { trackEvent } from "../tracking";
import { AssuranceType } from "../types/organisations";
import ExportReportModal from "../../vendorrisk/components/modals/ExportReportModal";
import {
  ExportFiletype,
  ExportFiletypesBoth,
  ExportType,
} from "../types/exportReport";

import SurveyDetailsExportOptions from "../../vendorrisk/components/SurveyDetailsExportOptions";
import useSurveyViewerTipsModal from "../../survey_viewer/components/modals/SurveyViewerTipsModal";
import Dot from "../components/Dot";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import { useLocalStorageState } from "../hooks";
import { CorrespondenceIconButton } from "../../vendorrisk/components/CorrespondenceIconButton";
import { appConnect } from "../types/reduxHooks";
import { removeByAssessmentPathSuffix } from "../../vendorrisk/views/VendorAssessmentV3";
import VendorAssessmentAPI from "../../vendorrisk/reducers/vendorAssessmentAPI";
import { skipToken } from "@reduxjs/toolkit/query";
import { useVendorDataSelector } from "../selectors/vendorSelectors";
import { downloadSurveyImportExcelFile } from "../../vendor_portal/api/surveyImportDownload";
import SurveyImportAPI from "../../vendor_portal/api/surveyImportAPI";
import { useDeleteImportedSurvey } from "../../vendor_portal/hooks/importedSurveys.hooks";
import { SidePopupV2 } from "../components/DismissablePopup";
import { ResponseError } from "../api";
import { ErrorCardWithAction } from "../components/EmptyCardWithAction";
import ReportCard from "../components/ReportCard";
import { getCyberRiskAuth, getUserLastActive } from "../session";
import { ConfettiFullPage } from "../components/ConfettiFullPage";
import { setPLGTaskCompleteIfIncomplete } from "../../vendorrisk/reducers/plgOnboardingChecklistActions";

import {
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
  vendorRiskTaskUrlPrefix,
} from "../helpers/navigation.helpers";

const exportFunc =
  (
    surveyId: number,
    fileType: ExportFiletype,
    isManagementAnalystSession: boolean,
    managedOrgId?: number,
    byAssessmentId?: number
  ) =>
  () => {
    const { apiKey, token } = getAuthFromLocalStorage(
      isManagementAnalystSession ? { tpvm: true, tpvm_o: managedOrgId } : null
    );
    const byAssessmentPathSuffix = byAssessmentId ? "/byassessment" : "";
    window.open(
      `/api/survey/export${byAssessmentPathSuffix}/v1/?file_type=${fileType}&survey_id=${encodeURIComponent(
        surveyId
      )}&by_assessment_id=${encodeURIComponent(
        byAssessmentId || ""
      )}&apikey=${encodeURIComponent(apiKey)}&token=${encodeURIComponent(
        token
      )}`
    );
  };

export const surveySubmitted = (status: SurveyStatus) =>
  status === SurveyStatus.AwaitingReview ||
  status === SurveyStatus.InReview ||
  status === SurveyStatus.Complete;

export const getSurveyEditorPath = ({
  surveyId,
  editMode,
  answerId = 0,
  questionId = "",
  isManagementAnalystSession = false,
  managedOrgId = 0,
  publicSurvey = false,
  location,
  backContext,
  vendorPortal = false,
  vendorId,
  showComments = false,
  compareAnswerId,
  isCollaborator = false,
}: {
  surveyId: number;
  editMode: boolean;
  answerId?: number;
  questionId?: string;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  publicSurvey?: boolean;
  location: Location;
  backContext?: backContext;
  vendorPortal?: boolean;
  vendorId?: number;
  showComments?: boolean;
  compareAnswerId?: number;
  isCollaborator?: boolean;
}): LocationDescriptorObject<locationState> => {
  const searchParts = [];

  if (editMode) {
    searchParts.push("editable=true");
  }

  if (answerId > 0) {
    searchParts.push(`version=${answerId}`);
  }

  if (publicSurvey) {
    searchParts.push(`public=true`);
  }

  if (questionId) {
    searchParts.push(`scroll_to=${encodeURIComponent(questionId)}`);
  }

  if (showComments) {
    searchParts.push(`show_comments=true`);
  }

  if (compareAnswerId) {
    searchParts.push(`compare=${compareAnswerId}`);
  }

  // TODO KW: I don't think these are needed anymore for any of the endpoints?
  if (isManagementAnalystSession) {
    searchParts.push(`managed=true`, `org=${managedOrgId}`);
  }

  const search = "?" + searchParts.join("&");

  if (publicSurvey) {
    let pathname;
    if (
      location.pathname.includes("sharedassessment") ||
      location.pathname.includes("trustpage")
    ) {
      // we are coming from a sharedassessment page or subpage
      pathname = location.pathname.includes("surveys")
        ? `${location.pathname}/answers`
        : `${location.pathname}/surveys/${surveyId}/answers`;
    } else if (vendorId) {
      pathname = `/vendor/${vendorId}/sharedassessment/surveys/${surveyId}/answers`;
    } else {
      // we are coming from somewhere else, need to replace the part after /vendor/x/
      const matches = /\/vendor\/\d+/gm.exec(location.pathname);
      if (!matches) {
        // Shouldn't happen.. bail out
        console.error("Could not match path with /vendor");
        return { pathname: location.pathname };
      }
      pathname = `${matches[0]}/sharedassessment/surveys/${surveyId}/answers`;
    }
    return {
      pathname,
      search,
      state: {
        backContext: backContext ?? {
          backTo: `${location.pathname}`,
          backToText: "Back to profile",
        },
      },
    };
  } else if (editMode || vendorPortal) {
    return {
      pathname: `/vendors/surveys/${surveyId}/answers`,
      search,
      state: {
        backContext:
          backContext ??
          (!vendorPortal && vendorId
            ? {
                backTo: `/vendor/${vendorId}/surveys/${surveyId}`,
                backToText: "Back to questionnaire overview",
              }
            : undefined),
      },
    };
  } else {
    if (
      (location.pathname.startsWith("/vendor/") ||
        location.pathname.startsWith("/analysts/")) &&
      location.pathname.includes("/surveys")
    ) {
      const parts = location.pathname.split("/surveys");

      return {
        pathname: `${parts[0]}/surveys/${surveyId}/answers`,
        search,
        state: {
          backContext: backContext ?? {
            backTo: `${location.pathname}`,
            backToText: "Back to questionnaire overview",
          },
        },
      };
    } else if (
      (location.pathname.startsWith("/vendor/") ||
        location.pathname.startsWith("/analysts/")) &&
      location.pathname.endsWith("/assessment")
    ) {
      const parts = location.pathname.split("/assessment");

      return {
        pathname: `${parts[0]}/surveys/${surveyId}/answers`,
        search,
        state: {
          backContext: backContext ?? {
            backTo: `${location.pathname}`,
            backToText: "Back to risk assessment",
          },
        },
      };
    } else if (isCollaborator) {
      return {
        pathname: `/surveycollaboration/${surveyId}/answers`,
        search,
        state: {
          backContext: backContext ?? {
            backTo: `${location.pathname}`,
            backToText: "Back to questionnaires",
          },
        },
      };
    }
    {
      return {
        pathname: `/surveys/${surveyId}/answers`,
        search,
        state: {
          backContext,
        },
      };
    }
  }
};

export interface ISurveyDetailsProps extends IConnectedSurveyDetailsProps {
  dispatch: DefaultThunkDispatch;
  surveyId: number;
  vendorId?: number;
  assessmentId?: number;
  survey?: ISurveyListItemResponse;
  surveyImport?: ISurveyImportDetails;
  projection?: IScoreProjection;
  messages?: ICorrespondenceMessage[];
  messageUsers?: IUserMiniMap;
  users?: IUserMiniMap;
  reminders?: ISurveyReminder[];
  timeline?: timelineData;
  currentUserId: number;
  currentUserEmail: string;
  hasScoresFeatureEnabled: boolean;
  userHasWriteSurveysPermission: boolean;
  userHasWriteSurveyImportExportPermission: boolean;
  isManagementAnalystSession: boolean;
  userIsManagedVendorAnalyst: boolean;
  managedOrgId?: number;
  sharedAssetOrgId?: number;
  orgPermissions: string[];
  assuranceType: AssuranceType;
  orgHasAutomationEnabled?: boolean;
  orgHasExtraQuestionnaireStatusChanges?: boolean;
}

type surveyDetailsTabs = "overview" | "documents" | "timeline";

const fetchSurveyData = (
  dispatch: DefaultThunkDispatch,
  surveyId: number,
  userHasWriteSurveysPermission: boolean
) =>
  dispatch(fetchSurveyDetails(surveyId, false)).then((survey) => {
    const promises = [];

    // Only make further fetches if we got survey details successfully.
    if (survey && survey.risksInRemediation > 0) {
      promises.push(
        dispatch(fetchProjectionForExistingRemediatingSurvey(surveyId))
      );
    }

    if (userHasWriteSurveysPermission) {
      promises.push(dispatch(fetchSurveyReminders(surveyId, false)));
    }

    // Do not mark messages as read yet, we can't assume the user has opened the message panel to view them
    // force reload the messages in case the user navigated here from a home page notification
    promises.push(dispatch(fetchSurveyMessages(surveyId, true, true)));

    promises.push(dispatch(fetchSurveyTimeline(surveyId, false)));

    return Promise.all(promises);
  });

const fetchSurveyDataForAssessment = (
  dispatch: DefaultThunkDispatch,
  assessmentId: number,
  surveyId: number
) => dispatch(fetchSurveyDetailsForAssessment(assessmentId, surveyId));

const SurveyDetails = (props: ISurveyDetailsProps) => {
  const {
    dispatch,
    currentUserId,
    currentUserEmail,
    isVendorPortal,
    surveyId,
    vendorId,
    assessmentId,
    survey,
    surveyImport,
    reminders,
    messages,
    messageUsers,
    timeline,
    location,
    history,
    match,
    userHasWriteSurveysPermission,
    userHasWriteSurveyImportExportPermission,
    userIsManagedVendorAnalyst,
    isManagementAnalystSession,
    managedOrgId,
    hasScoresFeatureEnabled,
    projection,
    sharedAssetOrgId,
    assuranceType,
    orgHasAutomationEnabled,
    orgHasExtraQuestionnaireStatusChanges,
    isCollaboratorPortal,
  } = props;

  // Make sure we fetch what we need on mount
  const [errorFetching, setErrorFetching] = useState(false);
  const [errorCannotAccess, setErrorCannotAccess] = useState(false);

  useEffect(() => {
    // Refresh the survey timeline every 5 minutes, if the user is active
    const autoRefreshEvery = 5 * 60 * 1000;

    const autoRefreshInterval = setInterval(() => {
      const userLastActive = getUserLastActive();
      if (userLastActive > new Date().getTime() - autoRefreshEvery) {
        dispatch(fetchSurveyTimeline(surveyId, true)).catch((e) =>
          console.error(e)
        );
      }
    }, autoRefreshEvery);

    return () => {
      clearInterval(autoRefreshInterval);
    };
  }, []);

  useEffect(() => {
    let prom: Promise<unknown>;
    if (assessmentId && vendorId) {
      prom = fetchSurveyDataForAssessment(dispatch, assessmentId, surveyId);
    } else {
      prom = fetchSurveyData(dispatch, surveyId, userHasWriteSurveysPermission);
    }
    prom.catch((e) => {
      console.error(e);
      setErrorFetching(true);

      if (e instanceof ResponseError && e.response.status === 403) {
        setErrorCannotAccess(true);
        trackEvent("QuestionnairePermissionError");
      }
    });
  }, [
    surveyId,
    userHasWriteSurveysPermission,
    assessmentId,
    dispatch,
    vendorId,
  ]);

  useEffect(() => {
    if (
      !isVendorPortal &&
      !isCollaboratorPortal &&
      survey &&
      survey.vendorId > 0
    ) {
      // We're viewing this page as a customer, we should make sure we have the vendor's basic data
      dispatch(fetchVendorWatchStatus(survey.vendorId));
    }
  }, [dispatch, isVendorPortal, survey]);

  // Set up various bits of state
  const [currentTab, setCurrentTab] = useState<surveyDetailsTabs>("overview");
  const [messagesPanelOpen, setMessagesPanelOpen] = useState(
    location.state?.openComments ?? false
  );

  const [exportModalShown, setExportModalShown] = useState(false);
  const [includeMessagesInExport, setIncludeMessagesInExport] = useState(false);
  const [includeFormattingInExport, setIncludeFormattingInExport] =
    useState(false);

  const [openInviteUsersModal, inviteUsersModal] = useModalV2(
    SurveyDetailsInviteUsersModal
  );

  const [selectedReportType, setSelectedReportType] = useState(
    ExportFiletype.PDF
  );

  const [fireConfetti, setFireConfetti] = useState(false);

  const [openTipsModal, tipsModal] = useSurveyViewerTipsModal(
    survey?.organisationName ?? "",
    survey?.usageType ?? SurveyUsageType.Relationship,
    !!surveyImport
  );
  const [hasSeenTips, setHasSeenTips] = useLocalStorageState<boolean>(
    "SurveyDetails/tipsViewed",
    false
  );

  const vendorName =
    useVendorDataSelector((vendorData) => vendorData?.name, vendorId ?? 0) ||
    survey?.vendorName;

  const { totalMessages, unreadMessages } = useMessageCounts(messages);

  // Figure out where the back button goes...
  let { backTo, backToText, backToContext } = location.state?.backContext || {};
  const { goBack } = location.state?.backContext || {};

  if (!backTo && !goBack) {
    if (isVendorPortal) {
      if (survey?.usageType === SurveyUsageType.Relationship) {
        backTo = `/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/questionnaires`;
      } else {
        backTo = `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires`;
      }

      backToText = "Back to Questionnaire List";

      if (surveyImport) {
        backToContext = {
          currentTab: "imported",
        };
      }
    } else if (isCollaboratorPortal) {
      backTo = "/surveycollaboration";
      backToText = "Back to Questionnaire List";
    } else if (sharedAssetOrgId) {
      backTo = `/vendor/${vendorId}/sharedassets/${sharedAssetOrgId}`;
      backToText = "Back";
    } else if (match.params.vendorId) {
      if (survey?.usageType === SurveyUsageType.Relationship) {
        backTo = vendorUrlPrefix(
          vendorId,
          isManagementAnalystSession,
          managedOrgId
        );
        backToText = "Back to summary";
      } else {
        backTo = `${vendorUrlPrefix(
          vendorId,
          isManagementAnalystSession,
          managedOrgId
        )}/surveys`;
        backToText = "Back to Questionnaire List";
      }
    } else {
      backTo = "/surveys";
      backToText = "Back to Questionnaire List";
    }
  }

  const { data: assessment } =
    VendorAssessmentAPI.useGetVendorAssessmentDataQuery(
      assessmentId && vendorId
        ? {
            vendorID: vendorId,
            versionID: assessmentId,
          }
        : skipToken
    );

  useEffect(() => {
    if (assessment?.assessment.publishedAt && assessmentId) {
      dispatch(
        fetchSurveyMessagesForAssessment(
          assessmentId,
          assessment.assessment.publishedAt,
          surveyId
        )
      );
      dispatch(
        fetchSurveyTimelineForAssessment(
          assessmentId,
          assessment.assessment.publishedAt,
          surveyId
        )
      );
    }
  }, []);

  useEffect(() => {
    if (survey?.dateCreated) {
      const dateCreated = new Date(survey.dateCreated);
      const now = new Date();
      const oneMinuteInMilliseconds = 60000;
      if (now.getTime() - dateCreated.getTime() < oneMinuteInMilliseconds) {
        // if this survey was created less than 1 min ago, let's
        // assume the user has just sent it
        dispatch(
          setPLGTaskCompleteIfIncomplete("Checklist_VendorRisk_Questionnaire")
        ).then((result) => {
          if (result) {
            setFireConfetti(true);
          }
        });
      }
    }
  }, [survey]);

  const [setImportedSurveyArchivedMutation] =
    SurveyImportAPI.useSetImportedSurveyArchivedMutation();
  const [settingImportedSurveyArchived, setSettingImportedSurveyArchived] =
    useState(false);
  const setImportedSurveyArchived = useCallback(async () => {
    try {
      setSettingImportedSurveyArchived(true);
      setImportedSurveyArchivedMutation({
        surveyID: surveyId,
        archived: !survey?.archived,
      });
      dispatch(
        addDefaultSuccessAlert(
          survey?.archived
            ? "Successfully unarchived questionnaire"
            : "Successfully archived questionnaire"
        )
      );
    } catch (e) {
      LogError("error setting imported survey archived", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          survey?.archived
            ? "Error unarchiving questionnaire"
            : "Error archiving questionnaire"
        )
      );
    }
    setSettingImportedSurveyArchived(false);
  }, [surveyId, survey, setImportedSurveyArchivedMutation, dispatch]);
  const [deleteImportedSurvey, deleteImportedSurveyModal] =
    useDeleteImportedSurvey();

  const exportSurveyTemplate = (surveyTypeId: string): void => {
    const { apiKey, token } = getCyberRiskAuth();
    const url = `/api/survey/template/export/v1/?survey_type_id=${encodeURIComponent(
      surveyTypeId
    )}&file_type=xlsx&apikey=${encodeURIComponent(
      apiKey
    )}&token=${encodeURIComponent(token)}`;
    window.open(url, "_blank");
    trackEvent("VerifiedVendor_ExportSurveyTemplate");
  };

  if (
    !survey ||
    (assessmentId && !assessment?.assessment.publishedAt) ||
    errorFetching
  ) {
    return (
      <div id="survey_details">
        <PageHeader
          history={history}
          title="Questionnaire details"
          vendorId={
            isVendorPortal || isCollaboratorPortal ? undefined : vendorId
          }
          isManagementAnalystSession={isManagementAnalystSession}
          managedOrgId={managedOrgId}
          backText={backToText}
          backAction={
            goBack
              ? history.goBack
              : () => history.push(backTo as string, backToContext)
          }
        />
        {errorFetching ? (
          <ReportCard newStyles>
            {errorCannotAccess ? (
              <ErrorCardWithAction
                errorText="You do not have access to this questionnaire"
                errorSubText="Ask the sender to add you as a collaborator in the questionnaire details section."
                actionText="How to add a collaborator to a questionnaire"
                action={() =>
                  window.open(
                    "https://help.upguard.com/en/articles/9537348-how-to-add-your-colleagues-to-a-questionnaire",
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              />
            ) : (
              <ErrorCardWithAction />
            )}
          </ReportCard>
        ) : (
          <LoadingBanner />
        )}
      </div>
    );
  }

  const getSurveyPath = (
    editMode: boolean,
    specificSurveyId?: number,
    questionId?: string,
    answerId?: number,
    compareAnswerId?: number
  ) => {
    // if we're in an assessment context and no answer ID is specified here,
    // make sure we load the answer ID corresponding to the snapshotted survey
    if (!answerId && assessmentId) {
      answerId = latestSubmittedVersion?.answerId;
    }
    return getSurveyEditorPath({
      surveyId: specificSurveyId ?? surveyId,
      editMode,
      isManagementAnalystSession,
      managedOrgId,
      questionId,
      location: props.history.location,
      answerId,
      vendorPortal: isVendorPortal,
      vendorId: vendorId,
      compareAnswerId,
      isCollaborator: isCollaboratorPortal,
    });
  };

  const goToPreSendReview = (surveyId: number) => {
    if (!isManagementAnalystSession) {
      throw new Error("Only management analysts can access pre-send review");
    }
    const urlPrefix = `/analysts/tpvm/${managedOrgId}/${vendorId}/`;
    const url = `${urlPrefix}${surveyId}/presendreview?editable=true&show_comments=true`;
    props.history.push(url, {
      backContext: {
        backTo: props.history.location.pathname,
        backToText: "Back to managed service overview",
      },
    });
  };

  const canCompare =
    (props.survey?.versions ?? []).filter((v) => v.shared).length > 1;

  const onOpenCompare = () => {
    if (!props.survey?.versions) {
      return;
    }

    const versions = props.survey.versions.filter((v) => v.shared);
    if (versions.length < 2) {
      return;
    }

    const currentVersion = versions[0].answerId;
    const previousVersion = versions[1].answerId;

    history.push(
      getSurveyPath(
        false,
        undefined,
        undefined,
        currentVersion,
        previousVersion
      )
    );
  };

  const sentFromUser = survey.fromUser || { email: survey.fromEmail, name: "" };
  let otherVendorName = vendorName || "";
  if (isVendorPortal) {
    // Vendor name is the survey owner
    otherVendorName = sentFromUser.name || sentFromUser.email;
  }

  const showExportButton =
    survey.status === SurveyStatus.AwaitingReview ||
    survey.status === SurveyStatus.InReview ||
    survey.status === SurveyStatus.Complete;
  const showExportTemplateButton = !showExportButton;

  const inRemediation = survey.risksInRemediation > 0;

  const submittedVersions = (survey.versions || []).filter((v) => v.shared);
  const latestSubmittedVersion =
    submittedVersions.length > 0 ? submittedVersions[0] : undefined;

  const sharedWithCurrentUser = survey.sharedWith.some(
    (sharedUser) => sharedUser.id === props.currentUserId
  );

  const documentCount = getAttachmentsFromGroups(
    survey.attachmentGroups
  ).length;

  const getBreadcrumbs = (): crumb[] | undefined => {
    if (isVendorPortal || isCollaboratorPortal) {
      return undefined;
    } else if (assessmentId) {
      return [
        ...getVendorPageBreadcrumbs(
          vendorId ?? 0,
          vendorName ?? "",
          assuranceType
        ),
        {
          text: "Risk Assessment",
          to: `/vendor/${vendorId}/assessment`,
        },
        { text: "Questionnaire Details" },
      ];
    } else if (sharedAssetOrgId) {
      return [
        ...getVendorPageBreadcrumbs(
          vendorId ?? 0,
          vendorName ?? "",
          assuranceType
        ),
        {
          text: "Shared Assets",
          to: `/vendor/${vendorId}/sharedassets`,
        },
        {
          text: "Details",
          to: `/vendor/${vendorId}/sharedassets/${sharedAssetOrgId}`,
        },
        { text: "Questionnaire Details" },
      ];
    } else if (survey?.usageType === SurveyUsageType.Relationship) {
      return [
        ...getVendorPageBreadcrumbs(
          vendorId ?? 0,
          vendorName ?? "",
          assuranceType
        ),
        { text: "Questionnaire Details" },
      ];
    } else {
      return [
        ...getVendorPageBreadcrumbs(
          vendorId ?? 0,
          vendorName ?? "",
          assuranceType
        ),
        { text: "Questionnaires", to: `/vendor/${vendorId}/surveys` },
        { text: "Questionnaire Details" },
      ];
    }
  };

  const isCurrentUserRecipient =
    survey?.sharedWith.some((s) => s.email === currentUserEmail) ||
    // For vendorless (imported) surveys, we look for the WriteSurveyImportExport permission
    (survey && !survey.vendorId && userHasWriteSurveyImportExportPermission);

  const isSharedSurvey = !!sharedAssetOrgId || survey.isPrefilledSurvey;

  const isNotEditable =
    (survey.usageType == SurveyUsageType.Relationship &&
      !survey.isLatestInstanceForVendor) ||
    !!assessmentId ||
    (survey.status == SurveyStatus.Complete && !survey.canEditIfComplete);

  const risksVisibleToVendors =
    survey.riskVisibility !== SurveyRiskVisibility.Hidden;

  const correspondenceButton = (
    <CorrespondenceIconButton
      totalMessages={totalMessages}
      haveUnreadMessages={unreadMessages > 0}
      onClick={() => setMessagesPanelOpen(true)}
    />
  );

  const pageTitle =
    isVendorPortal && survey.fromCompany
      ? `Questionnaire from ${survey.fromCompany}`
      : survey.name || "Questionnaire details";

  return (
    <div id="survey_details">
      {fireConfetti && <ConfettiFullPage />}
      {isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={history}
        breadcrumbs={getBreadcrumbs()}
        titleTag={pageTitle}
        title={<div className={"title"}>{pageTitle}</div>}
        vendorId={isVendorPortal || isCollaboratorPortal ? undefined : vendorId}
        backAction={
          goBack
            ? history.goBack
            : () => history.push(backTo as string, backToContext)
        }
        backText={backToText}
        rightSection={
          <>
            {/* No messaging on imported questionnaires for now */}
            {!surveyImport && (
              <>
                {!isVendorPortal &&
                  survey.isAnalystWorkflowSurvey &&
                  !userIsManagedVendorAnalyst && (
                    <SidePopupV2
                      initDismissed
                      popupHoverable
                      position={"left"}
                      width={300}
                      text={
                        "Message are being exchanged between the vendor and the analyst"
                      }
                    >
                      {correspondenceButton}
                    </SidePopupV2>
                  )}
                {(isVendorPortal ||
                  !survey.isAnalystWorkflowSurvey ||
                  userIsManagedVendorAnalyst) &&
                  correspondenceButton}
              </>
            )}
            {isVendorPortal && isCurrentUserRecipient && (
              <Button
                onClick={() => {
                  openTipsModal();
                  setHasSeenTips(true);
                }}
              >
                {!hasSeenTips && <Dot color={"green"} />}
                <i className={"cr-icon-bulb"} />
                Tips
              </Button>
            )}
            {!!surveyImport && isCurrentUserRecipient && (
              <>
                <Button
                  onClick={setImportedSurveyArchived}
                  loading={settingImportedSurveyArchived}
                >
                  <div className="cr-icon-archive" />
                  {survey.archived ? "Unarchive" : "Archive"}
                </Button>
                {survey.archived && (
                  <Button danger onClick={() => deleteImportedSurvey(surveyId)}>
                    <div className="cr-icon-trash" />
                    Delete
                  </Button>
                )}
              </>
            )}
            {showExportButton && (isVendorPortal || isCollaboratorPortal) && (
              <DropdownV2
                popupItem={
                  <Button primary={!!surveyImport}>
                    Export <div className="cr-icon-chevron" />
                  </Button>
                }
              >
                <DropdownItem
                  onClick={
                    surveyImport
                      ? () =>
                          dispatch(
                            downloadSurveyImportExcelFile(
                              surveyImport.uuid!,
                              true
                            )
                          )
                      : exportFunc(
                          surveyId,
                          ExportFiletype.XLSX,
                          isManagementAnalystSession,
                          managedOrgId,
                          assessmentId
                        )
                  }
                >
                  Export to Excel
                </DropdownItem>
                <DropdownItem
                  onClick={exportFunc(
                    surveyId,
                    ExportFiletype.PDF,
                    isManagementAnalystSession,
                    managedOrgId,
                    assessmentId
                  )}
                >
                  Export to PDF
                </DropdownItem>
              </DropdownV2>
            )}
            {showExportButton && !isVendorPortal && !isCollaboratorPortal && (
              <Button onClick={() => setExportModalShown(true)}>
                <div className="cr-icon-export" />
                Export
              </Button>
            )}
            {showExportTemplateButton && survey?.typeId && !isVendorPortal && (
              <Button
                onClick={() =>
                  exportSurveyTemplate(survey.typeId?.toString() ?? "")
                }
              >
                <div className="cr-icon-export" />
                Export
              </Button>
            )}
            {userHasWriteSurveysPermission &&
              !isVendorPortal &&
              surveyStatusIn(survey.status, [
                SurveyStatus.Complete,
                SurveyStatus.Cancelled,
              ]) &&
              (isManagementAnalystSession ||
                !survey.isAnalystWorkflowSurvey) && (
                <Button
                  disabled={
                    survey?.usageType === SurveyUsageType.Relationship
                      ? !survey.isLatestInstanceForVendor
                      : false
                  }
                  onClick={() => {
                    if (survey?.usageType === SurveyUsageType.Relationship) {
                      history.push(
                        `${vendorUrlPrefix(
                          survey.vendorId,
                          isManagementAnalystSession,
                          managedOrgId
                        )}/summary/relationship/create`,
                        {
                          resendSurvey: survey,
                          backContext: {
                            backTo: history.location.pathname,
                            backToText: "Back to questionnaire",
                          },
                        } as CreateRelationshipQuestionnaireLocationState
                      );
                    } else {
                      history.push(
                        `${vendorUrlPrefix(
                          survey.vendorId,
                          isManagementAnalystSession,
                          managedOrgId
                        )}/surveys/create`,
                        {
                          resendSurvey: survey,
                          backContext: {
                            backTo: history.location.pathname,
                            backToText: "Back to questionnaire",
                          },
                        } as CreateQuestionnaireBulkLocationState
                      );
                    }
                  }}
                >
                  Resend questionnaire
                </Button>
              )}

            {!props.isVendorPortal && canCompare && (
              <Button onClick={onOpenCompare}>Show changes</Button>
            )}
            {!isVendorPortal &&
              latestSubmittedVersion &&
              (!survey.isAnalystWorkflowSurvey ||
                survey.status === SurveyStatus.AwaitingReview ||
                survey.status === SurveyStatus.InReview ||
                survey.status === SurveyStatus.Complete) &&
              survey.status !== SurveyStatus.Cancelled && (
                <Link
                  to={getSurveyPath(
                    false,
                    survey.id,
                    undefined,
                    latestSubmittedVersion.answerId
                  )}
                >
                  <Button primary className="view-answers-btn">
                    View answers
                    <div className="cr-icon-arrow-right" />
                  </Button>
                </Link>
              )}
            {!isVendorPortal &&
              isManagementAnalystSession &&
              (survey.status === SurveyStatus.InAutofillReview ||
                survey.status === SurveyStatus.Autofilled) && (
                <Button
                  primary
                  className="view-answers-btn"
                  onClick={() => goToPreSendReview(surveyId)}
                >
                  Continue questionnaire
                  <div className="cr-icon-arrow-right" />
                </Button>
              )}
          </>
        }
      />
      {assessmentId && assessment?.assessment.publishedAt && (
        <InfoBanner
          className={"old-version-banner"}
          type={BannerType.WARNING}
          overrideIcon={BannerType.INFO}
          message={`This is a snapshot of the questionnaire on ${formatDateAsLocal(
            assessment?.assessment.publishedAt
          )}`}
          button={
            <Link to={removeByAssessmentPathSuffix(props.location.pathname)}>
              View latest version{" "}
              <span className={"cr-icon-arrow-right"}></span>
            </Link>
          }
        />
      )}
      <div className="tabs-n-toggle">
        <TabButtons
          onChangeTab={(tabId) => setCurrentTab(tabId as surveyDetailsTabs)}
          tabs={[
            {
              id: "overview",
              text: "Overview",
            },
            ...(documentCount > 0
              ? [
                  {
                    id: "documents",
                    text: `Documents (${documentCount})`,
                  },
                ]
              : []),
            {
              id: "timeline",
              text: "Timeline",
            },
          ]}
          activeTabId={currentTab}
        />
      </div>
      {currentTab === "overview" ? (
        <>
          <div className="two-grid no-stretch">
            <SurveyDetailsOverviewCard
              dispatch={dispatch}
              history={history}
              isManagementAnalystSession={isManagementAnalystSession}
              userIsManagedVendorAnalyst={props.userIsManagedVendorAnalyst}
              managedOrgId={managedOrgId || 0}
              survey={survey}
              surveyImport={surveyImport}
              reminders={reminders}
              isVendorPortal={isVendorPortal}
              hasScoresFeatureEnabled={hasScoresFeatureEnabled}
              userHasWritePermission={userHasWriteSurveysPermission}
              currentUserId={currentUserId}
              openShareSurveyModal={() =>
                openInviteUsersModal({
                  dispatch,
                  isVendorPortal,
                  surveyId,
                  sharedWith: survey?.sharedWith || [],
                  openInvites: survey?.openInvites || [],
                  fromCompany: survey?.fromCompany || "",
                  surveyUsageType:
                    survey?.usageType ?? SurveyUsageType.Security,
                })
              }
              hasUnansweredQuestions={
                (survey?.unansweredQuestions?.length || 0) > 0
              }
              vendorId={vendorId}
              assuranceType={assuranceType}
              orgHasAutomationEnabled={orgHasAutomationEnabled}
              isSharedSurvey={isSharedSurvey}
              orgHasExtraQuestionnaireStatusChanges={
                orgHasExtraQuestionnaireStatusChanges
              }
              isCollaboratorPortal={isCollaboratorPortal}
            />
            <div>
              <SurveyDetailsProgressCard
                dispatch={dispatch}
                survey={survey}
                isImportedSurvey={!!surveyImport}
                history={history}
                isManagementAnalystSession={isManagementAnalystSession}
                userIsManagedVendorAnalyst={props.userIsManagedVendorAnalyst}
                managedOrgId={managedOrgId || 0}
                userHasWritePermission={userHasWriteSurveysPermission}
                isVendorPortal={isVendorPortal}
                hasSharedAnswers={latestSubmittedVersion !== undefined}
                getSurveyPath={getSurveyPath}
                isCurrentUserRecipient={isCurrentUserRecipient}
                isNotEditable={isNotEditable}
                disableArchiveUnArchive={
                  (survey.usageType == SurveyUsageType.Relationship &&
                    !survey.isLatestInstanceForVendor) ||
                  !!assessmentId
                }
                isSharedSurvey={isSharedSurvey}
                orgHasExtraQuestionnaireStatusChanges={
                  orgHasExtraQuestionnaireStatusChanges
                }
              />
              <SurveyDetailsOptionsCard
                survey={survey}
                isVendorPortal={isVendorPortal}
              />
            </div>
          </div>
          {survey.status !== SurveyStatus.Cancelled && (
            <>
              {inRemediation && survey.isScoreMerged && !assessmentId && (
                <SurveyDetailsScoreProjectionCard
                  isVendorPortal={isVendorPortal}
                  vendorName={survey?.vendorName || ""}
                  initialScore={projection ? projection.initialScore : 0}
                  currentScore={projection ? projection.currentScore : 0}
                  projectedScore={projection ? projection.projectedScore : 0}
                  loading={!projection || projection.loading}
                />
              )}
              {isVendorPortal && inRemediation && (
                <SurveyDetailsRemediationCard
                  surveyId={surveyId}
                  canEditSurvey={
                    isVendorPortal &&
                    isCurrentUserRecipient &&
                    !survey.deletedAt
                  }
                  risks={survey.risks ?? []}
                  getSurveyPath={getSurveyPath}
                />
              )}
              {((isVendorPortal && risksVisibleToVendors) ||
                (!isVendorPortal && !survey.risksAreFromPreSendAutofill)) &&
                !!survey.risks && (
                  <SurveyDetailsRisksCard
                    history={history}
                    location={location}
                    surveyId={survey.id}
                    vendorId={survey.vendorId}
                    surveyRisks={survey.risks ?? []}
                    surveyRisksAreFromPreSendAutofill={
                      survey.risksAreFromPreSendAutofill
                    }
                    riskVisibility={survey.riskVisibility}
                    riskWaivers={survey.riskWaivers}
                    userHasWaivePermission={userHasWriteSurveysPermission}
                    userHasRemediationPermission={userHasWriteSurveysPermission}
                    isManagementAnalystSession={isManagementAnalystSession}
                    managedOrgId={managedOrgId || 0}
                    getSurveyPath={getSurveyPath}
                    archivedSurvey={survey.archived}
                    isVendorPortal={isVendorPortal}
                    surveyStatus={survey.status}
                    isAnalystWorkflowSurvey={survey.isAnalystWorkflowSurvey}
                  />
                )}

              {(surveySubmitted(survey.status) ||
                !!survey.risks ||
                isVendorPortal) &&
                survey.unansweredQuestions &&
                survey.unansweredQuestions.length > 0 && (
                  <SurveyDetailsUnansweredQuestionsCard
                    history={history}
                    surveyId={surveyId}
                    unansweredQuestions={survey.unansweredQuestions}
                    isVendorPortal={isVendorPortal}
                    surveyDeleted={!!survey.deletedAt}
                    fromCompanyName={survey.fromCompany}
                    numQuestions={survey.numQuestions}
                    numAnswers={survey.numAnswers}
                    getSurveyPath={getSurveyPath}
                    survey={survey}
                    isCurrentUserRecipient={isCurrentUserRecipient}
                    isNotEditable={isNotEditable}
                  />
                )}
            </>
          )}
        </>
      ) : currentTab === "documents" ? (
        <>
          {survey.attachmentGroups && (
            <SurveyDetailsDocumentsCard
              attachmentGroups={survey.attachmentGroups}
              isManagementAnalystSession={isManagementAnalystSession}
              managedOrgId={managedOrgId || 0}
            />
          )}
        </>
      ) : (
        <>
          <div className="two-grid no-stretch">
            <SurveyDetailsHistoryCard
              survey={survey}
              canEditSurvey={
                isVendorPortal && isCurrentUserRecipient && !survey.deletedAt
              }
              openQuestionnaireEditor={(
                editMode,
                answerId,
                compareAnswerId
              ) => {
                if (!!compareAnswerId) {
                  trackEvent("QuestionnareComparePreviousVersion");
                }

                history.push(
                  getSurveyPath(
                    editMode,
                    undefined,
                    undefined,
                    answerId,
                    compareAnswerId
                  )
                );
              }}
              redlinesEnabled={!isVendorPortal}
            />
            {timeline ? (
              <Timeline
                items={timeline.items}
                users={timeline.users}
                noun={"questionnaire"}
                isImportedSurvey={!!surveyImport}
              />
            ) : (
              <LoadingBanner tight />
            )}
          </div>
        </>
      )}
      <SurveyDetailsMessagesPanel
        active={messagesPanelOpen}
        onClose={() => setMessagesPanelOpen(false)}
        surveyId={surveyId}
        messages={messages}
        users={messageUsers}
        otherVendorName={otherVendorName}
        currentUserId={currentUserId}
        hasWritePermission={
          !survey.deletedAt &&
          // if we're viewing an assessment snapshot don't allow new messages to be sent
          !assessmentId &&
          (isVendorPortal ||
            (survey.sharable && userHasWriteSurveysPermission) ||
            sharedWithCurrentUser ||
            (survey.isAnalystWorkflowSurvey &&
              isManagementAnalystSession &&
              userIsManagedVendorAnalyst))
        }
        allowedPrivateMessages={!isVendorPortal && !sharedWithCurrentUser}
        location={history.location}
        isVendorPortal={isVendorPortal}
        surveyUsageType={survey.usageType}
        isManagementAnalystSession={isManagementAnalystSession}
        userIsManagedVendorAnalyst={userIsManagedVendorAnalyst}
        isAnalystWorkflowSurvey={survey.isAnalystWorkflowSurvey}
      />
      {inviteUsersModal}
      {tipsModal}
      {!isVendorPortal && !isCollaboratorPortal && (
        <ExportReportModal
          title="Export Questionnaire"
          exportType={ExportType.QuestionnaireDetailsReport}
          supportedFilters={[]}
          active={exportModalShown}
          onClose={() => setExportModalShown(false)}
          disableScheduledExport={true}
          supportedFileTypes={ExportFiletypesBoth}
          exportOptions={{
            vendor_id: props.vendorId,
            survey_id: props.surveyId,
            by_assessment_id: props.assessmentId,
            public_survey: false,
            include_survey_messages: includeMessagesInExport,
            include_survey_formatting: includeFormattingInExport,
          }}
          exportTypeSelected={(selected: ExportFiletype) => {
            setSelectedReportType(selected);
          }}
        >
          <SurveyDetailsExportOptions
            excelFormatSelected={selectedReportType == ExportFiletype.XLSX}
            includeMessages={includeMessagesInExport}
            includeFormattingDetails={includeFormattingInExport}
            toggleIncludeMessages={() => {
              setIncludeMessagesInExport(!includeMessagesInExport);
            }}
            toggleIncludeFormattingDetails={() => {
              setIncludeFormattingInExport(!includeFormattingInExport);
            }}
          />
        </ExportReportModal>
      )}
      {deleteImportedSurveyModal}
    </div>
  );
};

interface IConnectedSurveyDetailsProps
  extends DefaultRouteProps<
    {
      id: string;
      vendorId?: string;
      assessmentId?: string;
      orgId?: string;
      sharedAssetOrgId?: string;
    },
    {
      openComments?: boolean;
    }
  > {
  isVendorPortal: boolean;
  isCollaboratorPortal?: boolean;
}

export default appConnect((state, props: IConnectedSurveyDetailsProps) => {
  const surveyId = parseInt(props.match.params.id);
  let assessmentId: number | undefined;
  if (props.match.params.assessmentId) {
    assessmentId = parseInt(props.match.params.assessmentId);
  }

  const surveyData = assessmentId
    ? state.common.assessmentSurveys[assessmentId]?.[surveyId]
    : state.common.surveys[surveyId];

  let vendorId = surveyData?.survey?.vendorId;
  if (!vendorId && props.match.params.vendorId) {
    vendorId = parseInt(props.match.params.vendorId);
  }

  const userIsManagedVendorAnalyst = (
    state.common.userData.system_roles || []
  ).includes(UserSystemRoleVendorManagementAnalyst);

  const isAnalystManagedVendor =
    userIsManagedVendorAnalyst && props.match.path.startsWith("/analysts/tpvm");

  let managedOrgId;
  if (isAnalystManagedVendor) {
    managedOrgId = parseInt(props.match.params.orgId || "");
  }

  let sharedAssetOrgId;
  if (props.match.params.sharedAssetOrgId) {
    sharedAssetOrgId = parseInt(props.match.params.sharedAssetOrgId);
  }

  const userHasWriteSurveysPermission =
    // Viewing an assessment or shared asset questionnaire should always be read only
    !assessmentId &&
    !sharedAssetOrgId &&
    // user has write surveys permission for their current logged in org
    (state.common.userData.userPermissions.includes(UserWriteSurveys) ||
      (!!vendorId &&
        // user has permission for the current vendor portfolio
        userHasPermissionForVendorFromState(
          state,
          UserWriteSurveys,
          vendorId
        )) ||
      // user is an analyst and the org being managed is the owner of the survey being viewed
      (!!managedOrgId && managedOrgId === surveyData?.survey?.organisationId));

  const orgPerms = state.common.userData.orgPermissions
    ? state.common.userData.orgPermissions
    : [];

  const orgHasAutomationEnabled = orgPerms.includes(
    OrgAccessRelationshipQuestionnaireAutomation
  );
  const orgHasExtraQuestionnaireStatusChanges = orgPerms.includes(
    OrgExtraQuestionnaireStatusChanges
  );

  return {
    surveyId,
    vendorId,
    assessmentId,
    survey: surveyData?.survey,
    surveyImport: surveyData?.surveyImport,
    projection: surveyData?.projection,
    messages: surveyData?.messages?.messages,
    messageUsers: surveyData?.messages?.users,
    users: surveyData?.users,
    reminders: surveyData?.reminders,
    timeline: surveyData?.timeline,
    currentUserId: state.common.userData.id,
    currentUserEmail: state.common.userData.emailAddress,
    hasScoresFeatureEnabled:
      !!state.common.permissions.org[OrgQuestionnaireScores],
    userHasWriteSurveysPermission,
    userHasWriteSurveyImportExportPermission:
      !!state.common.permissions.user[UserWriteSurveyImportExport],
    isManagementAnalystSession: isAnalystManagedVendor,
    userIsManagedVendorAnalyst: !!userIsManagedVendorAnalyst,
    managedOrgId,
    orgPermissions: state.common.userData.orgPermissions,
    sharedAssetOrgId,
    assuranceType: state.common.userData.assuranceType || AssuranceType.None,
    orgHasAutomationEnabled,
    orgHasExtraQuestionnaireStatusChanges,
  } as ISurveyDetailsProps;
})(SurveyDetails);

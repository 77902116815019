import { FC } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../../../_common/hooks";
import SurveyName from "../../../../vendorrisk/components/surveys/SurveyName";
import { formatDateAsLocal } from "../../../../_common/helpers";
import { ISurveyListItemResponse } from "../../../../_common/types/survey";
import "../../../style/SelectExistingQuestionnairesComponent.scss";

type selectSurveyRecord = {
  id: number;
  vendorId: number;
  name?: string;
  type?: string;
  description?: string;
  lastUpdatedOn?: string;
};

interface ISelectExistingQuestionnairesComponentProps {
  surveys: ISurveyListItemResponse[];
  selectedSurveyIds: number[];
  onSelectedSurveysChange: (surveyIds: number[]) => void;
}

const mapSurveyListItemResponseToSelectSurveyRecord = (
  survey: ISurveyListItemResponse
): selectSurveyRecord => {
  return {
    id: survey.id,
    vendorId: survey.vendorId,
    name: survey.name,
    type:
      survey.type.length > 0
        ? `${survey.type} ${
            survey.fromCompany ? ` sent by ${survey.fromCompany}` : ""
          }`
        : survey.isImportedSurvey
          ? "Imported Questionnaire"
          : undefined,
    description: survey.description,
    lastUpdatedOn: survey.dateCompleted ?? survey.dateSubmitted,
  };
};

const getQuestionnairesTableHeaders = (): IXTableColumnHeader[] => {
  return [
    {
      id: "questionnaires",
      text: "Questionnaire",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "last_updated",
      text: "Last updated",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
  ];
};

const SelectExistingQuestionnairesComponent: FC<
  ISelectExistingQuestionnairesComponentProps
> = ({
  surveys,
  selectedSurveyIds,
  onSelectedSurveysChange,
}: ISelectExistingQuestionnairesComponentProps) => {
  const isSelected = (id: number) => selectedSurveyIds.includes(id) ?? false;
  const onSelectSurvey = (id: number, setSelected: boolean) => {
    setSelected
      ? onSelectedSurveysChange([...selectedSurveyIds, id])
      : onSelectedSurveysChange(selectedSurveyIds.filter((s) => s !== id));
  };
  const onSelectAllToggle = (selectAll: boolean) => {
    if (selectAll) {
      onSelectedSurveysChange(surveys.map((s) => s.id));
    } else {
      onSelectedSurveysChange([]);
    }
  };

  const [
    pageItems,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    selectSurveyRecord,
    "questionnaires" | "last_updated"
  >(
    surveys.map((it) => mapSurveyListItemResponseToSelectSurveyRecord(it)),
    "last_updated",
    SortDirection.DESC,
    {
      questionnaires: {
        orderFuncs: [(q) => q.name, (q) => q.lastUpdatedOn],
        sortDirsAsc: ["asc", "desc"],
        sortDirsDesc: ["desc", "desc"],
      },
      last_updated: {
        orderFuncs: [
          (q) => (q.lastUpdatedOn ? q.lastUpdatedOn : ""),
          (q) => q.name,
        ],
        sortDirsAsc: ["asc", "asc"],
        sortDirsDesc: ["desc", "asc"],
      },
    },
    5
  );

  const rows: IXTableRow<number>[] = pageItems.map<IXTableRow<number>>(
    (survey) => {
      const cells = [
        <XTableCell key="questionnaire" className="questionnaire">
          <div className="q-cell">
            <div className={"q-cell-main"}>
              <SurveyName
                survey={{
                  name: survey.name,
                  type: survey.type,
                }}
              />
            </div>
          </div>
        </XTableCell>,
        <XTableCell key="last_updated" className="q-cell-date">
          {survey.lastUpdatedOn ? (
            formatDateAsLocal(survey.lastUpdatedOn)
          ) : (
            <em>Not completed</em>
          )}
        </XTableCell>,
      ];

      return {
        id: survey.id,
        className: "q-row",
        selected: isSelected(survey.id),
        expandDisabled: true,
        cells: cells,
      };
    }
  );

  return (
    <div className={"select-existing-questionnaires"}>
      <div className={"header"}>
        <div className={"label"}>Select questionnaire</div>
        <div className={"label"}>{selectedSurveyIds.length} selected</div>
      </div>
      <XTable
        selectable={true}
        iconOptions
        className="select-existing-questionnaires-table"
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        columnHeaders={getQuestionnairesTableHeaders()}
        rows={rows}
        onSelectToggle={onSelectAllToggle}
        onSelectAllClick={() =>
          onSelectedSurveysChange(surveys.map((s) => s.id))
        }
        onSelectNoneClick={() => onSelectedSurveysChange([])}
        onSelectClick={onSelectSurvey}
        pagination={{
          currentPage,
          totalPages,
          onPageChange,
          hidePaginationIfSinglePage: true,
        }}
      />
    </div>
  );
};

export default SelectExistingQuestionnairesComponent;

import { locationState } from "../../../_common/types/router";
import { FC, useEffect, useState } from "react";
import { History, Location } from "history";

import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import LightningSVG from "../../images/automation_lightning_icon.svg";
import {
  createDuplicateAutomationRecipe,
  createNewAutomationRecipe,
  createNewDraftAutomationRecipe,
  deleteAutomationRecipe,
  enableDisableAutomationRecipe,
  fetchAutomationForSurveyType,
  fetchAutomationRecipe,
  resetDraftEditsForRecipe,
  setSortOrder,
} from "../../reducers/questionnaireAutomation.actions";
import { IAutomationRecipe } from "../../types/automation";
import "../../style/components/automation/ListQuestionnaireAutomation.scss";
import EmptyCardWithAction, {
  ErrorCardWithAction,
} from "../../../_common/components/EmptyCardWithAction";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import UserAvatar from "../../../_common/components/UserAvatar";
import ToggleWithLabel from "../ToggleWithLabel";
import {
  OrgAccessRelationshipQuestionnaireAutomation,
  WriteQuestionnaireAutomation,
} from "../../../_common/permissions";
import EmptyCard from "../EmptyCard";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { AutomationAttributeTypes } from "../../views/EditQuestionnaireAutomation";
import { moveRecipeEntry } from "../../reducers/cyberRiskActions";
import ConfirmationModalV2, {
  useConfirmationModalV2,
} from "../../../_common/components/modals/ConfirmationModalV2";
import classnames from "classnames";
import IconButton, {
  HoverLocation,
} from "../../../_common/components/IconButton";
import moment from "moment";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { RelationshipQuestionnaireStateType } from "../../../_common/types/organisations";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";

interface IListQuestionnaireAutomationOwnProps {
  loading?: boolean;
  history: History;
  location: Location<locationState>;
  surveyTypeId: number;
  draftTemplateExists: boolean;
  isEditing: boolean;
  locked?: boolean;
}

interface IListQuestionnaireAutomationConnectedProps {
  surveyTypeId: number;
  relationshipQuestionnaireState: RelationshipQuestionnaireStateType;
  loading?: boolean;
  error?: IError | null;
  automation?: IAutomationRecipe[];
  orgHasAutomationEnabled: boolean;
  userHasWriteAutomationEnabled: boolean;
}

type ListQuestionnaireAutomationProps = DefaultThunkDispatchProp &
  IListQuestionnaireAutomationOwnProps &
  IListQuestionnaireAutomationConnectedProps;

const ListQuestionnaireAutomation: FC<ListQuestionnaireAutomationProps> = ({
  history,
  surveyTypeId,
  draftTemplateExists,
  relationshipQuestionnaireState,
  loading,
  error,
  automation,
  orgHasAutomationEnabled,
  userHasWriteAutomationEnabled,
  isEditing,
  locked,
}) => {
  const dispatch = useAppDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [isRecipeWorking, setRecipeIsWorking] = useState("");
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [deletingUUID, setDeletingUUID] = useState("");
  const [deletingName, setDeletingName] = useState("");
  const [isEnableRunning, setIsEnableRunning] = useState("");
  const [openEditConfirmationModal, editConfirmationModal] =
    useConfirmationModalV2();

  const [isSorting, setIsSorting] = useState(false);
  const [draggingOverIdx, setDraggingOverIdx] = useState(-1);
  const [draggingIdx, setDraggingIdx] = useState(-1);
  const setDeleting = (uuid: string, name: string) => {
    setDeletingUUID(uuid);
    setDeletingName(name);
  };

  // On initial load, get the existing automation instances for the surveyType
  useEffect(() => {
    // Also make sure we have loaded the org flags
    dispatch(fetchAutomationForSurveyType(surveyTypeId));
  }, [surveyTypeId]);

  const openAddRecipe = (surveyTypeId: number) => {
    createNewRecipe(surveyTypeId).then((recipeUUID) => {
      if (recipeUUID != "") {
        openRecipe(surveyTypeId, recipeUUID, true);
      }
    });
  };

  const createNewRecipe = async (surveyTypeId: number): Promise<string> => {
    setIsCreating(true);
    let recipeUUID = "";
    try {
      await dispatch(createNewAutomationRecipe(surveyTypeId)).then((uuid) => {
        recipeUUID = uuid;
        dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
      });
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error creating new automation"));
    }
    setIsCreating(false);
    return recipeUUID;
  };

  const openRecipe = (
    surveyTypeId: number,
    recipeUUID: string,
    isEditing = true
  ) => {
    history.push(
      `/settings/automation/edit/${surveyTypeId}/${recipeUUID}/${isEditing}`,
      {
        surveyTypeId: surveyTypeId,
        backContext: {
          backTo: history.location.pathname,
          backToText: "Back",
          backToContext: {
            currentTab: "automation",
            isEditing: isEditing,
            surveyTypeId: surveyTypeId,
          },
        },
      }
    );
  };

  const duplicateRecipe = async (surveyTypeId: number, masterUUID: string) => {
    setRecipeIsWorking(masterUUID);
    try {
      await dispatch(
        createDuplicateAutomationRecipe(surveyTypeId, masterUUID)
      ).then((uuid) => {
        dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
        openRecipe(surveyTypeId, uuid, !locked);
      });
      await dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error creating duplicate automation for questionnaire"
        )
      );
    }
    setRecipeIsWorking("");
  };

  const deleteRecipe = async (recipeUUID: string) => {
    setRecipeIsWorking(recipeUUID);
    try {
      await dispatch(deleteAutomationRecipe(surveyTypeId, recipeUUID));
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert(
          `Error deleting automation for questionnaire: ${e}`
        )
      );
    }
    setRecipeIsWorking("");
  };

  const setEnabled = async (idx: number, uuid: string, enabled: boolean) => {
    if (automation && idx < automation.length) {
      setIsEnableRunning(uuid);
      try {
        await dispatch(
          enableDisableAutomationRecipe(uuid, surveyTypeId, enabled)
        );
        dispatch(
          addDefaultSuccessAlert(
            `Your automation rule is ${enabled ? "now" : "no longer"} active`
          )
        );
      } catch (e: any) {
        dispatch(addDefaultUnknownErrorAlert(e.toString()));
      }
      setIsEnableRunning("");
    }
  };

  const moveRecipeToIdx = async (fromIdx: number, toIdx: number) => {
    if (
      automation &&
      fromIdx >= 0 &&
      fromIdx < (automation.length || 0) &&
      toIdx >= 0 &&
      toIdx <= (automation.length || 0)
    ) {
      setIsSorting(true);

      const uuid = automation[fromIdx].uuid;
      const sortOrder =
        toIdx < automation.length
          ? automation[toIdx].sortOrder
          : automation[toIdx - 1].sortOrder + 1;

      try {
        await Promise.all([
          dispatch(moveRecipeEntry(surveyTypeId, fromIdx, toIdx)),
          dispatch(setSortOrder(uuid, sortOrder)),
        ]);
        await dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
      } catch (e) {
        dispatch(
          addDefaultUnknownErrorAlert(
            `Failed to reorder automation rules: ${e}`
          )
        );
      }

      setIsSorting(false);
    }
  };

  const editRecipe = (recipe: IAutomationRecipe, surveyTypeId: number) => {
    if (!recipe.draft) {
      editNewVersion(recipe.uuid, surveyTypeId);
    } else {
      editExistingVersion(recipe);
    }
  };

  const editNewVersion = async (existingUUID: string, surveyTypeId: number) => {
    // edit a new draft based on the current
    setRecipeIsWorking(existingUUID);
    let draftUUID;
    try {
      draftUUID = await dispatch(createNewDraftAutomationRecipe(existingUUID));
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error creating new draft version for automation"
        )
      );
      return;
    } finally {
      setRecipeIsWorking("");
    }
    await dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
    openRecipe(surveyTypeId, draftUUID, !locked);
  };

  // editExistingVersion
  // edit the existing draft of an automation, takign into account the fact that we may have existing edits pending on the draft
  const editExistingVersion = async (recipe: IAutomationRecipe) => {
    const doEdit = async (resetEdits: boolean) => {
      try {
        setRecipeIsWorking(recipe.uuid);
        if (resetEdits) {
          await dispatch(resetDraftEditsForRecipe(recipe.uuid));
        }
        await dispatch(fetchAutomationRecipe(recipe.uuid, true));
        openRecipe(surveyTypeId, recipe.uuid, !locked);
        setRecipeIsWorking("");
      } catch (e) {
        setRecipeIsWorking("");
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred attempting to edit the existing automation"
          )
        );
      }
    };

    if (recipe.editsExist) {
      openEditConfirmationModal({
        title: "Automation rule has unsaved changes",
        description: "Do you want to discard changes or continue editing?",
        cancelText: "Discard changes",
        buttonText: "Continue editing",
        onCancel: async () => {
          await doEdit(true);
        },
        buttonAction: async () => {
          await doEdit(false);
        },
      });
    } else {
      await doEdit(false);
    }
  };

  const viewExistingVersion = async (existingUUID: string) => {
    // view existing without edit
    await dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
    openRecipe(surveyTypeId, existingUUID, false);
  };

  const openTestRecipe = async (surveyTypeId: number, recipeUUID: string) => {
    const url = `/questionnairepreview?type_id=${surveyTypeId}&automationUUID=${recipeUUID}&draft=${isEditing}`;
    window.open(url, "_blank");
  };

  const getTask = (automation: IAutomationRecipe): string => {
    switch (automation?.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        return "Assign tier";
      case AutomationAttributeTypes.Portfolios:
        return "Assign portfolio";
      case AutomationAttributeTypes.Labels:
        return "Assign label";
      case AutomationAttributeTypes.Custom:
        return `Assign attribute: '${automation.customAttributeName || ""}'`;
    }
    return "unknown";
  };

  const renderRows = () => {
    const rows: React.ReactNode[] = [];
    automation?.map((a, idx) => {
      const onEnableClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        setEnabled(idx, a.uuid, !a.enabled);
      };

      const cells: React.ReactNode[] = [];
      if (isEditing) {
        cells.push(
          <td
            key={"drag"}
            className={classnames("td-drag", { disabled: !isEditing })}
          >
            <div className="cr-icon-drag-handle" />
          </td>
        );
      }

      if (!isEditing) {
        cells.push(
          <td key={"enabled"} className={"td-enabled"}>
            <div className={"enabled"}>
              {isEnableRunning && isEnableRunning == a.uuid && (
                <div className={"loader"}>
                  <LoadingIcon size={18} />
                </div>
              )}
              {isEnableRunning !== a.uuid && (
                <ToggleWithLabel
                  name={a.uuid}
                  selected={a.enabled}
                  disabled={
                    !userHasWriteAutomationEnabled ||
                    relationshipQuestionnaireState !==
                      RelationshipQuestionnaireStateType.active
                  }
                  className={a.enabled ? "" : "disabled"}
                  onClick={(evt: React.MouseEvent<HTMLElement>) =>
                    onEnableClick(evt)
                  }
                />
              )}
            </div>
          </td>
        );
      }

      cells.push([
        <td key={"recipe"} className={"td-recipe"}>
          <div className={"title"}>{a.name ? a.name : "(not set)"}</div>
          {a.description && <div className={"recipe"}>{a.description}</div>}
          <PillLabel color={LabelColor.Blue}>{getTask(a)}</PillLabel>
          {a.isWeighted && (
            <PillLabel color={LabelColor.Blue}>Advanced</PillLabel>
          )}
        </td>,
        <td key={"author"} className={"td-author"}>
          <div className={"user"}>
            <UserAvatar
              avatar={a.updatedByAvatar ?? ""}
              name={a.updatedByName}
              hoverPopup={
                a.updatedByName ? (
                  <span>
                    {a.updatedByName}
                    <br />
                    <em>{a.updatedByEmail}</em>
                  </span>
                ) : (
                  <span>
                    <em>{a.updatedByEmail}</em>
                  </span>
                )
              }
            />
            <div className={"name"}>
              {a.updatedByName ? a.updatedByName : a.updatedByEmail}
            </div>
          </div>
          <div className={"date"}>{moment(a.updatedAt).format("ll")}</div>
        </td>,
        <td key={"icons"} className={"td-icons"}>
          <div className={"icons"}>
            {isEditing && isRecipeWorking == a.uuid && (
              <IconButton
                key={"creating"}
                hoverText={"opening new draft"}
                icon={<LoadingIcon size={18} />}
              />
            )}
            {isEditing && isRecipeWorking !== a.uuid && (
              <IconButton
                key={"creating"}
                hoverText={""}
                disabled={true}
                icon={<div className={"missing"} />}
              />
            )}
            {isEditing && (
              <>
                <IconButton
                  key={"trash"}
                  hoverText={"Delete"}
                  hoverLocation={HoverLocation.Top}
                  disabled={!!locked}
                  onClick={() => {
                    setDeleting(a.uuid, a.name);
                    setDeleteModalShown(true);
                  }}
                  icon={<div className={"cr-icon-trash"} />}
                  popupDelay={150}
                />
                <IconButton
                  key={"duplicate"}
                  hoverText={"Duplicate"}
                  hoverLocation={HoverLocation.Top}
                  disabled={!!locked}
                  onClick={() => {
                    duplicateRecipe(surveyTypeId, a.uuid);
                  }}
                  icon={<div className={"cr-icon-copy"} />}
                  popupDelay={150}
                />
                <IconButton
                  key={"test"}
                  hoverText={"Test automation"}
                  hoverLocation={HoverLocation.Top}
                  onClick={() => {
                    openTestRecipe(a.surveyTypeId, a.uuid);
                  }}
                  icon={<div className="cr-icon-play" />}
                  popupDelay={150}
                />
                <IconButton
                  key={"edit"}
                  hoverText={
                    !!locked
                      ? "View"
                      : a.editsExist
                        ? "Unsaved changes"
                        : "Edit"
                  }
                  hoverLocation={HoverLocation.Top}
                  onClick={() => {
                    editRecipe(a, surveyTypeId);
                  }}
                  icon={<div className="cr-icon-pencil" />}
                  attention={a.editsExist}
                  popupDelay={150}
                />
              </>
            )}
            {!isEditing && (
              <>
                <IconButton
                  key="test"
                  hoverText="Test automation rule"
                  hoverLocation={HoverLocation.Top}
                  onClick={() => {
                    openTestRecipe(a.surveyTypeId, a.uuid);
                  }}
                  icon={<div className="cr-icon-play" />}
                  popupDelay={150}
                />
                <IconButton
                  key="view"
                  hoverText="View summary"
                  hoverLocation={HoverLocation.Top}
                  onClick={() => {
                    viewExistingVersion(a.uuid);
                  }}
                  icon={<div className="cr-icon-chevron" />}
                  popupDelay={150}
                />
              </>
            )}
          </div>
        </td>,
      ]);
      if (draggingIdx >= 0 && idx < draggingIdx && draggingOverIdx == idx) {
        rows.push(
          <tr
            key={"drop1"}
            className={"row tr-drop-target"}
            draggable={!isSorting}
            onDrop={() => {
              moveRecipeToIdx(draggingIdx, idx).then(() => {
                setDraggingOverIdx(-1);
                setDraggingIdx(-1);
              });
            }}
            onDragOver={(event) => {
              event.preventDefault();
            }}
          >
            <td colSpan={7} className={"td-drop-target"}>
              <div className={"drop-target"}>{"Drop automation here"}</div>
            </td>
          </tr>
        );
      }
      rows.push(
        <tr
          key={a.uuid}
          className={classnames("", {
            disabled: !a.enabled && !isEditing && !isSorting,
          })}
          draggable={isEditing && !isSorting}
          onDragStart={() => {
            setDraggingOverIdx(-1);
            setDraggingIdx(idx);
          }}
          onDragEnd={() => {
            setDraggingOverIdx(-1);
            setDraggingIdx(-1);
          }}
          onDragOver={(event) => {
            setDraggingOverIdx(idx);
            event.preventDefault();
          }}
        >
          {cells}
        </tr>
      );
      if (idx > draggingIdx && draggingOverIdx == idx) {
        rows.push(
          <tr
            key={"drop2"}
            className={"row tr-drop-target"}
            onDrop={() => {
              moveRecipeToIdx(draggingIdx, idx + 1).then(() => {
                setDraggingIdx(-1);
                setDraggingOverIdx(-1);
              });
            }}
            onDragOver={(event) => {
              event.preventDefault();
            }}
          >
            <td colSpan={7} className={"td-drop-target"}>
              <div className={"drop-target"}>{"Drop automation here"}</div>
            </td>
          </tr>
        );
      }
    });
    return rows;
  };

  const renderTable = () => {
    return (
      <div className={"x-table"}>
        <table className={"automation-table"}>
          <tbody>
            <tr>
              {isEditing && <th></th>}
              {!isEditing && <th>Active</th>}
              <th>Automation rule</th>
              <th>Updated by</th>
              <th></th>
            </tr>
            {renderRows()}
          </tbody>
        </table>
      </div>
    );
  };

  if (!orgHasAutomationEnabled) {
    return (
      <EmptyCard
        text={"Relationship questionnaire automation is not available"}
      />
    );
  }

  return (
    <div className={"list-questionnaire-automation-v2"}>
      <div className={"automation-list-card"}>
        {loading && !error && <LoadingBanner />}
        {!loading && !!error && (
          <ErrorCardWithAction
            errorText={"Failed to read automations"}
            actionText={"Retry"}
            action={async () => {
              dispatch(fetchAutomationForSurveyType(surveyTypeId, true));
            }}
          />
        )}
        {!loading && !error && (!automation || automation.length == 0) && (
          <>
            {isEditing && (
              <EmptyCardWithAction
                iconSrc={LightningSVG}
                actionDisabled={!userHasWriteAutomationEnabled}
                emptyText={`No automation rules`}
                actionButtonText="New rule"
                onActionClick={() => openAddRecipe(surveyTypeId)}
                actionButtonLoading={isCreating}
                actionButtonIconClass={"cr-icon-plus"}
              />
            )}
            {!isEditing && (
              <EmptyCardWithAction
                iconSrc={LightningSVG}
                emptyText={`No automation rules`}
                emptySubText={`No default automation rules exist. ${
                  draftTemplateExists ? "Edit" : "Create"
                } draft to make changes.`}
              />
            )}
          </>
        )}
        {!loading && !error && automation && automation.length > 0 && (
          <>{renderTable()}</>
        )}
      </div>
      <ConfirmationModalV2
        title={"Delete automation rule?"}
        buttonAction={() => deleteRecipe(deletingUUID)}
        active={deleteModalShown}
        onClose={() => setDeleteModalShown(false)}
        description={`Your automation rule '${deletingName}' will be permanently deleted when you publish your draft questionnaire template.`}
        dangerousAction
        buttonText={"Delete"}
        cancelText={"Cancel"}
      />
      {editConfirmationModal}
    </div>
  );
};

export default appConnect<
  IListQuestionnaireAutomationConnectedProps,
  never,
  IListQuestionnaireAutomationOwnProps
>((state, props) => {
  const automation = state.cyberRisk.questionnaireAutomation
    ? state.cyberRisk.questionnaireAutomation[props.surveyTypeId]
    : null;

  const orgPerms = state.common.userData.orgPermissions;
  const userPerms = state.common.userData.userPermissions;
  const orgHasAutomationEnabled = orgPerms.includes(
    OrgAccessRelationshipQuestionnaireAutomation
  );
  const userHasWriteAutomationEnabled = userPerms.includes(
    WriteQuestionnaireAutomation
  );
  const connectedProps: IListQuestionnaireAutomationConnectedProps = {
    surveyTypeId: props.surveyTypeId,
    relationshipQuestionnaireState:
      state.cyberRisk.orgFlags?.RelationshipQuestionnaireState ??
      RelationshipQuestionnaireStateType.initial,
    loading: props.loading || (automation ? automation.loading : true),
    error: automation ? automation.error : null,
    automation: automation
      ? props.isEditing
        ? automation?.draft
        : automation?.published
      : ([] as IAutomationRecipe[]),
    orgHasAutomationEnabled,
    userHasWriteAutomationEnabled,
  };
  return connectedProps;
})(ListQuestionnaireAutomation);

import PageHeader from "../../_common/components/PageHeader";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useBack } from "../../_common/types/router";

import OrgPermissionsTable from "../components/OrgPermissionsTable";
import { SingleStatSize } from "../components/SingleStat";
import "./PermissionsView.scss";
import DashboardStat, {
  DashboardStatToShow,
} from "../components/DashboardStat";

interface PermissionsViewProps {}

const PermissionsView: FC<PermissionsViewProps> = ({}) => {
  const { backAction, backText } = useBack();

  const history = useHistory();

  return (
    <div className="userbase-permissions">
      <PageHeader
        history={history}
        title={
          <div className="title-and-count">
            Permissions
            <DashboardStat
              statToShow={DashboardStatToShow.PermissionCount}
              statSize={SingleStatSize.Small}
            />
          </div>
        }
        backAction={backAction}
        backText={backText}
        infoSectionPageKey="infoSection_userBasePermissions"
        infoSection={
          <>
            <p>
              Permissions shows all the SaaS permissions granted by users for
              your organisation
            </p>
          </>
        }
      />

      <OrgPermissionsTable />
    </div>
  );
};

export default PermissionsView;

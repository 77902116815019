import { FC } from "react";
import Doughnut, { DoughnutDataPoint } from "../Doughnut";
import { Grades } from "../../../_common/helpers";
import userbaseApi from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";

import { UserRiskFilters } from "../../../vendorrisk/components/filter/types";
import { useNavigateWithUserRiskFilters } from "../../helpers/hooks";
import { supportedFilters } from "../../views/users/UsersView";
import { usersUrl } from "../../UserBaseAppRouter";
import { useBack } from "../../../_common/types/router";
import DashboardContainer from "./DashboardContainer";

const OrgUsersGradeCounts: FC = ({}) => {
  const { data, isLoading } = userbaseApi.useGetUserGradeCountsV1Query();

  const countsToUse = data?.userGradeCounts ?? [];

  // Map all the grade counts from the API  to a grade that has colours
  const gradedUserCounts = Grades.map((grade) => {
    // Find the count for this grade
    const gradeFromApi = countsToUse.filter(
      (count) => grade.label === count.grade
    );

    const count = gradeFromApi.length > 0 ? gradeFromApi[0].count : 0;

    return {
      grade,
      count,
    };
  });

  // Remove any grades that have no users
  const gradedUserCountsToDisplay = gradedUserCounts.filter(
    (gradedUserCount) => gradedUserCount.count > 0
  );

  // Allow for navigation
  const navigateWithUserRiskFilter =
    useNavigateWithUserRiskFilters(supportedFilters);
  const { backText } = useBack();

  // Map this into dougnut data points
  const doughnutData: DoughnutDataPoint[] = gradedUserCountsToDisplay.map(
    (gradedUserCount) => {
      return {
        label: `${gradedUserCount.grade.label}`,
        count: gradedUserCount.count,
        hexColor: gradedUserCount.grade.color,
        onClick: () => {
          const filters: Partial<UserRiskFilters> = {
            userRiskUserMinScore: gradedUserCount.grade.min,
            userRiskUserMaxScore: gradedUserCount.grade.max,
          };

          navigateWithUserRiskFilter(usersUrl, backText, filters);
        },
      };
    }
  );

  // Sort the data points by grade
  doughnutData.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="org-users-grade-count">
      <DashboardContainer title="User rating breakdown">
        {isLoading && <LoadingBanner />}
        {!isLoading && data && (
          <div className={"content"}>
            <Doughnut data={doughnutData} totalLabel={"Total users"} />
          </div>
        )}
      </DashboardContainer>
    </div>
  );
};

export default OrgUsersGradeCounts;

import {
  GetQuestionnaireStatusItem,
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../types/survey";
import VerticalProgress, { IVerticalProgressStep } from "../VerticalProgress";
import moment from "moment/moment";
import { UserAvatarAndName } from "../UserAvatar";
import ProgressBar from "../ProgressBar";
import ReportCard from "../ReportCard";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import Button from "../core/Button";

import { Link } from "react-router-dom";
import { History, LocationDescriptor } from "history";

import "../../style/components/SurveyDetailsProgressCard.scss";
import { DefaultThunkDispatch } from "../../types/redux";
import SurveyDetailsStatusDropdown from "./SurveyDetailsStatusDropdown";
import { LabelColor } from "../../types/label";
import { getViewQuestionButtonText } from "./SurveyDetailsUnansweredQuestionsCard";
import SharedSurveyDetailsStatusDropdown from "./SharedSurveyDetailsStatusDropdown";

interface ISurveyDetailsProgressCardProps {
  dispatch: DefaultThunkDispatch;
  survey: ISurveyListItemResponse;
  isImportedSurvey: boolean;
  history: History;
  isManagementAnalystSession: boolean;
  userIsManagedVendorAnalyst: boolean;
  managedOrgId: number;
  userHasWritePermission: boolean;
  isVendorPortal: boolean;
  hasSharedAnswers: boolean;
  getSurveyPath: (editMode: boolean) => LocationDescriptor;
  isCurrentUserRecipient: boolean;
  isNotEditable?: boolean;
  disableArchiveUnArchive?: boolean;
  isSharedSurvey: boolean;
  orgHasExtraQuestionnaireStatusChanges?: boolean;
}

const dateFormat = "ll";

const SurveyDetailsProgressCard = ({
  dispatch,
  history,
  isManagementAnalystSession,
  userIsManagedVendorAnalyst,
  managedOrgId,
  userHasWritePermission,
  survey,
  isImportedSurvey,
  isVendorPortal,
  hasSharedAnswers,
  getSurveyPath,
  isCurrentUserRecipient,
  isNotEditable,
  disableArchiveUnArchive,
  isSharedSurvey,
  orgHasExtraQuestionnaireStatusChanges,
}: ISurveyDetailsProgressCardProps) => {
  const timelineStatusMapItem = GetQuestionnaireStatusItem(
    survey.status,
    survey.dueDate,
    isVendorPortal
  );

  const getStepsForAutofilledAnalystQuestionnaire = (
    survey: ISurveyListItemResponse
  ): {
    steps: IVerticalProgressStep[];
    currentStepIndex: number;
  } => {
    const steps: IVerticalProgressStep[] = [];
    let currentStepIndex = 0;

    const validActualSendDate =
      moment(survey.dateLastSent).utc().unix() !==
      moment(survey.dateCreated).utc().unix();

    steps.push({
      id: "autoFilled",
      name: "Prefilled by Analyst",
      stepDescription: !!survey.dateAnalystAutofill
        ? moment(survey.dateAnalystAutofill).format(dateFormat)
        : "",
      rightContent: survey.fromUser ? (
        <UserAvatarAndName
          avatar={survey.fromUser.avatar}
          name={survey.fromUser.name}
        />
      ) : undefined,
    });

    // has the sucker been autofilled by the analyst?
    if (!!survey.dateAnalystAutofill) {
      currentStepIndex = steps.length;
    }

    // If this survey was cancelled, just end it here.
    if (survey.status === SurveyStatus.Cancelled) {
      currentStepIndex = 1;
      steps.push({
        id: "cancelled",
        name: "Cancelled",
        stepDescription: moment(survey.dateCancelled).format(dateFormat),
      });
      return { steps, currentStepIndex };
    }

    steps.push({
      id: "autoFilled",
      name: "Reviewed by Analyst",
      stepDescription: survey.dateAnalystAutofillReview
        ? moment(survey.dateAnalystAutofillReview).format(dateFormat)
        : "",
      rightContent: survey.fromUser ? (
        <UserAvatarAndName
          avatar={survey.fromUser.avatar}
          name={survey.fromUser.name}
        />
      ) : undefined,
    });

    // has the sucker been reviewed by the analyst?
    if (!!survey.dateAnalystAutofillReview && validActualSendDate) {
      currentStepIndex = steps.length;
    }

    steps.push({
      id: "sent",
      name: "Sent",
      stepDescription: validActualSendDate
        ? moment(survey.dateLastSent).format(dateFormat)
        : "",
      rightContent:
        !!survey.fromUser && validActualSendDate ? (
          <UserAvatarAndName
            avatar={survey.fromUser.avatar}
            name={survey.fromUser.name}
          />
        ) : undefined,
    });

    // has the sucker been sent?
    if (validActualSendDate) {
      currentStepIndex = steps.length;
    }

    // for an analyst workflow Qn we display a future step of 'awaiting your review' until they actually open the sucker
    // then it just reverts to the normal 'opened' progress step
    if (
      survey.isAnalystWorkflowSurvey &&
      survey.dateAnalystAutofill &&
      !survey.dateOpened &&
      isVendorPortal
    ) {
      steps.push({
        id: "opened",
        name:
          survey.isAnalystWorkflowSurvey &&
          survey.dateAnalystAutofill &&
          !survey.dateOpened &&
          isVendorPortal
            ? "Awaiting your review"
            : "Opened",
        stepDescription: survey.dateOpened
          ? moment(survey.dateOpened).format(dateFormat)
          : undefined,
      });
      if (!!survey.dateOpened) {
        currentStepIndex = steps.length;
      }
    }

    // The third step can differ based on whether the survey is in progress or submitted.
    steps.push({
      id: "third",
      name:
        survey.status === SurveyStatus.InProgress
          ? "In progress"
          : survey.status === SurveyStatus.AwaitingReview
            ? "Awaiting review"
            : survey.status === SurveyStatus.InReview
              ? "In review"
              : "Submitted",
      stepDescription:
        survey.status !== SurveyStatus.InProgress && survey.dateSubmitted
          ? moment(survey.dateSubmitted).format(dateFormat)
          : undefined,
      rightContent:
        isCurrentUserRecipient || survey.numQuestions > 0 ? (
          <>
            <ProgressBar
              progress={
                survey.numQuestions
                  ? survey.numAnswers / survey.numQuestions
                  : 0
              }
              leftText={`${Math.floor(
                (survey.numQuestions > 0
                  ? survey.numAnswers / survey.numQuestions
                  : 0) * 100
              )}% complete`}
              rightText={
                survey.numQuestions > 0
                  ? `${survey.numAnswers} of ${survey.numQuestions} answered`
                  : ""
              }
            />
            {survey.dateAnalystAutofill &&
              !survey.dateSubmitted &&
              !isManagementAnalystSession &&
              !userIsManagedVendorAnalyst && (
                <div className={"progress-subtext"}>
                  {
                    "Our analyst has prefilled some answers using supplied documents."
                  }
                </div>
              )}
          </>
        ) : undefined,
    });

    if (
      (survey.dateOpened && survey.status === SurveyStatus.InProgress) ||
      survey.status === SurveyStatus.AwaitingReview ||
      survey.status === SurveyStatus.InReview
    ) {
      currentStepIndex = steps.length - 1;
    }

    // And finally, Completed
    steps.push({
      id: "complete",
      name: "Completed",
      stepDescription:
        survey.status === SurveyStatus.Complete && survey.dateCompleted
          ? moment(survey.dateCompleted).format(dateFormat)
          : undefined,
      rightContent:
        survey.status === SurveyStatus.Complete
          ? `The questionnaire has been marked as completed.`
          : undefined,
    });
    if (survey.status === SurveyStatus.Complete) {
      currentStepIndex = steps.length; // Go a step higher so this step looks complete, rather than in-progress
    }

    return { steps, currentStepIndex };
  };

  let steps: IVerticalProgressStep[] = [];
  let currentStepIndex = 0;

  const isAutofilledAnalystQuestionnaire =
    (survey.isAnalystWorkflowSurvey && !!survey.dateAnalystAutofill) ||
    (survey.isAnalystWorkflowSurvey && survey.status === SurveyStatus.New);

  // Note: a Qn can be a workflowAnalyst Qn, but which skipped the autofill step (they just sent it to the vendor)
  // So we only show the 'autofilled' step if the survey has been actually autofilled. Until it's autofilled we dont know what the type is.
  if (isAutofilledAnalystQuestionnaire) {
    const { steps: s, currentStepIndex: idx } =
      getStepsForAutofilledAnalystQuestionnaire(survey);
    steps = s;
    currentStepIndex = idx;
  } else {
    steps.push({
      id: "sent",
      name: isImportedSurvey ? "Imported" : "Sent",
      stepDescription: moment(survey.dateLastSent).format(dateFormat),
      rightContent: survey.fromUser ? (
        <UserAvatarAndName
          avatar={survey.fromUser.avatar}
          name={survey.fromUser.name}
        />
      ) : undefined,
    });

    // If this survey was cancelled, just end it here.
    if (survey.status === SurveyStatus.Cancelled) {
      currentStepIndex = 1;
      steps.push({
        id: "cancelled",
        name: "Cancelled",
        stepDescription: moment(survey.dateCancelled).format(dateFormat),
      });
    } else {
      // Next we have the opened step. We're up to this if dateOpened is set.
      steps.push({
        id: "opened",
        name: "Opened",
        stepDescription: survey.dateOpened
          ? moment(survey.dateOpened).format(dateFormat)
          : undefined,
      });
      if (survey.dateOpened) {
        currentStepIndex = steps.length - 1;
      }

      // The third step can differ based on whether the survey is in progress or submitted.
      steps.push({
        id: "third",
        name:
          survey.status === SurveyStatus.InProgress || isImportedSurvey
            ? "In progress"
            : survey.status === SurveyStatus.AwaitingReview
              ? "Awaiting review"
              : survey.status === SurveyStatus.InReview
                ? "In review"
                : "Submitted",
        stepDescription:
          survey.status !== SurveyStatus.InProgress && survey.dateSubmitted
            ? moment(survey.dateSubmitted).format(dateFormat)
            : undefined,
        rightContent:
          isCurrentUserRecipient || survey.numQuestions > 0 ? (
            <>
              <ProgressBar
                progress={
                  survey.numQuestions
                    ? survey.numAnswers / survey.numQuestions
                    : 0
                }
                leftText={`${Math.floor(
                  (survey.numQuestions > 0
                    ? survey.numAnswers / survey.numQuestions
                    : 0) * 100
                )}% complete`}
                rightText={
                  survey.numQuestions > 0
                    ? `${survey.numAnswers} of ${survey.numQuestions} answered`
                    : ""
                }
              />
            </>
          ) : undefined,
      });
      if (
        (survey.dateOpened && survey.status === SurveyStatus.InProgress) ||
        survey.status === SurveyStatus.AwaitingReview ||
        survey.status === SurveyStatus.InReview
      ) {
        currentStepIndex = steps.length - 1;
      }

      // And finally, Completed
      steps.push({
        id: "complete",
        name: "Completed",
        stepDescription:
          survey.status === SurveyStatus.Complete && survey.dateCompleted
            ? moment(survey.dateCompleted).format(dateFormat)
            : undefined,
        rightContent:
          survey.status === SurveyStatus.Complete
            ? `The questionnaire has been marked as completed.`
            : undefined,
      });
      if (survey.status === SurveyStatus.Complete) {
        currentStepIndex = steps.length; // Go a step higher so this step looks complete, rather than in-progress
      }
    }
  }

  for (let i = 0; i < steps.length; i++) {
    if (i < currentStepIndex) {
      steps[i].completed = true;
    } else if (i === currentStepIndex) {
      steps[i].current = true;
    }
  }

  const showAnswerButton = !isNotEditable || hasSharedAnswers;
  const answerButtonText = getViewQuestionButtonText(survey, isNotEditable);

  // if this is an analyst workflow questionnaire, and the current user is not an analyst, then they shouldnt be editing the status or any other data point.
  const shouldNotBeChangingStatusEtc =
    survey.isAnalystWorkflowSurvey &&
    (!isManagementAnalystSession || !userIsManagedVendorAnalyst);

  let statusDropdown = <></>;
  if (isSharedSurvey && !disableArchiveUnArchive) {
    statusDropdown = (
      <SharedSurveyDetailsStatusDropdown
        dispatch={dispatch}
        survey={survey}
        popupItem={
          <Button>
            Update status <div className="cr-icon-chevron" />
          </Button>
        }
      />
    );
  } else if (!isSharedSurvey && !shouldNotBeChangingStatusEtc) {
    statusDropdown = (
      <SurveyDetailsStatusDropdown
        dispatch={dispatch}
        history={history}
        survey={survey}
        isManagementAnalystSession={isManagementAnalystSession}
        managedOrgId={managedOrgId}
        disableArchiveUnArchive={disableArchiveUnArchive}
        popupItem={
          <Button>
            Update status <div className="cr-icon-chevron" />
          </Button>
        }
        orgHasExtraQuestionnaireStatusChanges={
          orgHasExtraQuestionnaireStatusChanges
        }
      />
    );
  }

  return (
    <>
      <ReportCard newStyles className="survey-details-progress-card">
        <div className="header">
          <div>
            <span>Progress</span>
            <PillLabel color={timelineStatusMapItem.labelColor}>
              {isImportedSurvey && timelineStatusMapItem.importedSurveyText
                ? timelineStatusMapItem.importedSurveyText
                : timelineStatusMapItem.text}
            </PillLabel>
            {survey.risksInRemediation > 0 && (
              <PillLabel color={LabelColor.Green}>In remediation</PillLabel>
            )}
          </div>
          <div className={"header-buttons"}>
            <>
              {/* TODO: GT - determine if we want to enable the button for the case where there are no shared answers (hasSharedAnswers=false) but it's an analyst survey and I'm an analyst */}
              {userHasWritePermission && !isVendorPortal ? (
                statusDropdown
              ) : hasSharedAnswers && !isCurrentUserRecipient ? (
                <Link to={getSurveyPath(false)}>
                  <Button>
                    View answers <div className="cr-icon-arrow-right" />
                  </Button>
                </Link>
              ) : null}
            </>
            {survey &&
              !survey.deletedAt &&
              isCurrentUserRecipient &&
              showAnswerButton && (
                <>
                  {survey.status !== SurveyStatus.Cancelled && (
                    <Button
                      primary={answerButtonText != "Edit answers"}
                      onClick={
                        (survey.archived && hasSharedAnswers) || isNotEditable
                          ? () => history.push(getSurveyPath(false))
                          : () => history.push(getSurveyPath(true))
                      }
                    >
                      {answerButtonText == "Edit answers" && (
                        <div className="cr-icon-pencil" />
                      )}
                      {answerButtonText}
                      {answerButtonText != "Edit answers" && (
                        <div className="cr-icon-arrow-right" />
                      )}
                    </Button>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="vertical-progress-wrapper">
          <VerticalProgress steps={steps} showRightContent />
        </div>
      </ReportCard>
    </>
  );
};

export default SurveyDetailsProgressCard;

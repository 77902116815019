import { FC } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import { UserApp } from "../api/types";
import CompanyLogo from "../../_common/components/CompanyLogo";
import SecurityRatingDisplay, { RatingSize } from "./SecurityRatingDisplay";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import UserApprovalPill, { userApprovalStatus } from "./UserApprovalPill";
import ReportCard from "../../_common/components/ReportCard";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import { useLocation } from "react-router-dom";

const PAGE_SIZE = 50;

const APP_NAME_COL = "name_col";
const SECURITY_RATING_COL = "rating_col";
const USER_EXPOSURE_COL = "exposure_col";
const FIRST_DETECTED_COL = "first_detected_col";
const USER_STATUS_COL = "status_col";

interface UserAppsTableProps {
  // Whether the data is loading
  isLoading: boolean;

  // The apps to show
  appsToShow: UserApp[];

  // Action to perform when an app is clicked
  onClick?: (app: UserApp) => void;

  // Allow for custom class names for the table, list and row
  className?: string;
}

/**
 * Table to display the apps for a user
 */
const UserAppsTable: FC<UserAppsTableProps> = ({
  isLoading,
  appsToShow,
  onClick,
  className,
}) => {
  const { pathname } = useLocation();

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: APP_NAME_COL,
      text: "App name",
      sortable: true,
    },
    {
      id: SECURITY_RATING_COL,
      text: "Security rating",
      sortable: true,
    },
    {
      id: USER_EXPOSURE_COL,
      text: "User exposure",
      sortable: true,
    },
    {
      id: FIRST_DETECTED_COL,
      text: "First detected",
      sortable: true,
    },
    {
      id: USER_STATUS_COL,
      text: "User Status",
      sortable: true,
    },
  ];

  const [
    sortedApps,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserApp,
    | typeof APP_NAME_COL
    | typeof SECURITY_RATING_COL
    | typeof USER_EXPOSURE_COL
    | typeof USER_STATUS_COL
  >(
    appsToShow,
    APP_NAME_COL,
    SortDirection.ASC,
    {
      [APP_NAME_COL]: {
        orderFuncs: [(a) => a.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(a) => a.securityScore],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [USER_EXPOSURE_COL]: {
        orderFuncs: [(a) => a.highestScopeRiskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [USER_STATUS_COL]: {
        orderFuncs: [
          (a) => userApprovalStatus(a.userIsApproved, a.userIsWaived),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const rows = sortedApps.map((userApp: UserApp): IXTableRow<string> => {
    return {
      id: userApp.name,
      onClick: () => (onClick !== undefined ? onClick(userApp) : null),
      cells: [
        <XTableCell key={APP_NAME_COL}>
          <CompanyLogo
            domain={userApp.domain}
            name={userApp.name}
            displayCategory={true}
            category={userApp.industry}
          />
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <SecurityRatingDisplay
            rating={userApp.securityScore}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key={USER_EXPOSURE_COL}>
          <ScopeRiskLevelDisplay riskLevel={userApp.highestScopeRiskLevel} />
        </XTableCell>,
        <XTableCell key={FIRST_DETECTED_COL}>
          <DateTimeFormat dateTime={userApp.firstDetected} dateOnly />
        </XTableCell>,
        <XTableCell key={USER_STATUS_COL}>
          <UserApprovalPill
            approved={userApp.userIsApproved}
            waived={userApp.userIsWaived}
          />
        </XTableCell>,
      ],
    };
  });

  const showEmptyCard = !isLoading && appsToShow.length === 0;

  return (
    <div className={className}>
      <ReportCard newStyles>
        {showEmptyCard && (
          <EmptyCard text={"There are no applications for this user."} />
        )}

        {!showEmptyCard && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default UserAppsTable;

import { FC, useState } from "react";

import Button from "../../_common/components/core/Button";

import "./EditKeywordModal.scss";
import TextField, { MaxLengthType } from "../../_common/components/TextField";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import {
  MinKeywordLength,
  ThreatMonitoringCriticality,
  ThreatMonitoringKeyword,
} from "../api/types";
import { SelectV2 } from "../../_common/components/SelectV2";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { criticalityColor, criticalityStr } from "../funcs/domain";
import { pluralise } from "../../_common/helpers";
import classNames from "classnames";
import { ResponseError } from "../../_common/api";
import { Steps } from "../../_common/components/StepsWithSections";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import { useHasOrgEntitlement } from "../../_common/permissions";
import { OrgAccessThreatMonitoringNewKeywordManagement } from "../../_common/permissions";
interface EditKeywordModalProps extends BaseModalProps {
  keyword?: ThreatMonitoringKeyword;
}

const EditKeywordModal: FC<EditKeywordModalProps> = (props) => {
  const accessNewKeywordManagement = useHasOrgEntitlement(
    OrgAccessThreatMonitoringNewKeywordManagement
  );

  const [keyword, setKeyword] = useState(props.keyword?.keyword);
  const [description, setDescription] = useState(props.keyword?.description);
  const [criticality, setCriticality] = useState(props.keyword?.criticality);
  const [validationError, setValidationError] = useState("");

  enum AddKeywordSteps {
    Settings = 1,
    Review = 2,
  }
  const [step, setStep] = useState(AddKeywordSteps.Settings);

  const dispatch = useAppDispatch();

  const [addKeyword, addStatus] =
    ThreatMonitoringAPI.useCreateKeywordV1Mutation();
  const [editKeyword, editStatus] =
    ThreatMonitoringAPI.useEditKeywordV1Mutation();
  const [scanKeyword, scanKeywordStatus] =
    ThreatMonitoringAPI.useScanKeywordV1Mutation();

  const submitStatus = props.keyword ? editStatus : addStatus;

  const handleError = (err: string) => {
    if (err === "KEYWORD_EXISTS") {
      dispatch(
        addSimpleErrorAlert("Failed to create keyword", [
          "This keyword already exists.",
        ])
      );
      setStep(AddKeywordSteps.Settings);
    } else {
      dispatch(addDefaultUnknownErrorAlert("Failed to create keyword"));
    }
  };
  const fetchKeywordResultCount = async (keyword: string) => {
    setStep(AddKeywordSteps.Review);
    scanKeyword({
      keyword: keyword,
    })
      .unwrap()
      .catch((e) => {
        console.error(e);
        handleError(e.message);
      });
  };

  const submit = () => {
    setValidationError("");
    const k = keyword?.trim();
    if (!k || k.length < MinKeywordLength) {
      setValidationError(
        `Keyword must contain at least ${MinKeywordLength} characters`
      );
      return;
    }
    props.keyword
      ? editKeyword({
          uuid: props.keyword.uuid,
          description,
          criticality: criticality ?? "",
          active: props.keyword.active, // new keywords are active by default
        })
          .unwrap()
          .then(() => {
            dispatch(addDefaultSuccessAlert("Keyword updated"));
            props.onClose();
          })
          .catch((e: ResponseError) => {
            console.error(e);
            dispatch(addDefaultUnknownErrorAlert("Failed to update keyword"));
          })
      : addKeyword({
          keyword: keyword ? keyword.trim() : "",
          description,
          criticality: criticality,
          active: true,
        })
          .unwrap()
          .then(() => {
            dispatch(addDefaultSuccessAlert("Keyword created"));
            props.onClose();
          })
          .catch((e: ResponseError) => {
            console.error(e);
            handleError(e.message);
          });
  };

  const criticalityOptions = () => {
    const opts = Object.values(ThreatMonitoringCriticality).map((val) => ({
      value: val,
      label: criticalityStr(val),
    }));

    opts.push({
      value: "" as ThreatMonitoringCriticality,
      label: "Not set",
    });

    return opts;
  };

  const isSubmitButtonDisabled = () => {
    if (scanKeywordStatus.isLoading) return true;

    if (accessNewKeywordManagement) {
      return (
        !keyword ||
        !!validationError ||
        (props.keyword && description === props.keyword.description)
      );
    }

    return (
      !keyword ||
      !!validationError ||
      criticality === undefined ||
      (props.keyword && criticality === props.keyword.criticality)
    );
  };

  const getButtonAction = () => {
    if (props.keyword || step !== AddKeywordSteps.Settings) {
      return submit;
    }
    if (keyword) {
      return () => fetchKeywordResultCount(keyword);
    }
    return submit;
  };

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      disallowClickOutside={true}
      headerContent={props.keyword ? "Edit keyword" : "Add keyword"}
      headerClassName={"edit-keyword-modal-header"}
      footerClassName={"edit-keyword-modal-footer"}
      className={"edit-keyword-modal"}
      footerContent={
        <>
          {step === AddKeywordSteps.Review && (
            <Button
              className="previous-keyword-step"
              leftArrow
              disabled={submitStatus.isLoading || scanKeywordStatus.isLoading}
              onClick={() => setStep(AddKeywordSteps.Settings)}
            >
              Previous
            </Button>
          )}
          <Button
            tertiary
            onClick={props.onClose}
            disabled={submitStatus.isLoading}
          >
            Cancel
          </Button>
          <Button
            primary
            loading={submitStatus.isLoading}
            onClick={getButtonAction()}
            arrow={!props.keyword && step === AddKeywordSteps.Settings}
            disabled={isSubmitButtonDisabled()}
          >
            {props.keyword
              ? "Save changes"
              : step === AddKeywordSteps.Settings
                ? "Next"
                : "Add keyword"}
          </Button>
        </>
      }
    >
      <div className={"keyword-details"}>
        {!props.keyword && (
          <div className="steps">
            <Steps
              currentStep={step}
              steps={[
                {
                  id: AddKeywordSteps.Settings,
                  text: "Settings",
                },
                {
                  id: AddKeywordSteps.Review,
                  text: "Review",
                },
              ]}
            />
          </div>
        )}
        {step === AddKeywordSteps.Settings ? (
          <>
            <div className={"label"}>Keyword</div>
            <div className={"input-field-wrapper"}>
              <TextField
                value={keyword || ""}
                errorTexts={keyword && validationError ? [validationError] : []}
                onChanged={setKeyword}
                required
                maxLength={MaxLengthType.label}
                placeholder={`Enter your keyword (e.g. "globalbank.ca")`}
                className={"input-field"}
                disabled={!!props.keyword}
              />
            </div>
            {accessNewKeywordManagement ? (
              <>
                <div className={"label"}>Description</div>
                <div className={"input-field-wrapper"}>
                  <TextField
                    value={description || ""}
                    onChanged={setDescription}
                    maxLength={MaxLengthType.reasonableLength}
                    placeholder={`Enter a description for this keyword`}
                    className={"input-field"}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={"label no-margin"}>Service criticality</div>
                <div className={"caption"}>
                  This will affect the severity of detected threats.
                </div>
                <SelectV2
                  onChange={(val) => {
                    setCriticality(val?.value);
                  }}
                  placeholder={"Select a service criticality"}
                  options={criticalityOptions()}
                  formatOptionLabel={(opt) => (
                    <div
                      className={classNames(
                        "criticality-selection",
                        criticalityColor(opt.value)
                      )}
                    >
                      {opt.label}
                    </div>
                  )}
                  value={
                    criticality
                      ? {
                          value: criticality,
                          label: criticalityStr(criticality),
                        }
                      : undefined
                  }
                />
              </>
            )}
          </>
        ) : scanKeywordStatus.isLoading ? (
          <div className={"loading"}>
            <LoadingBanner tight size={32} />
            <p>Keyword scanning in progress...</p>
          </div>
        ) : (
          <div className={"keyword-results"}>
            <div className={"keyword-results-info"}>
              <i className="cr-icon-check" />
              <div>
                <div>Scan completed successfully.</div>
                <div>
                  <strong>
                    {scanKeywordStatus.data?.totalResults &&
                    scanKeywordStatus.data?.totalResults > 0
                      ? scanKeywordStatus.data?.totalResults.toLocaleString()
                      : "No"}{" "}
                    {pluralise(
                      scanKeywordStatus.data?.totalResults,
                      "result",
                      "results"
                    )}
                  </strong>{" "}
                  {`detected for "${keyword}" in the last 12 months.`}
                </div>
              </div>
            </div>
            {scanKeywordStatus.data?.totalResults &&
            scanKeywordStatus.data.totalResults > 2500 ? (
              <InfoBanner
                type={BannerType.WARNING}
                message={
                  <div className="warning-banner">
                    <div>
                      <strong>Warning - noisy keyword</strong>
                    </div>
                    <div className="warning-message">
                      We recommend monitoring keywords with fewer than 2,500
                      results in the last 12 months to maintain a focused threat
                      feed.
                    </div>
                  </div>
                }
              />
            ) : (
              <></>
            )}
            {scanKeywordStatus.data?.totalResults &&
            scanKeywordStatus.data.totalResults > 0 ? (
              <div>
                Add this keyword to start continuously monitoring it for new
                threats, and send the latest
                <strong>
                  {" "}
                  {scanKeywordStatus.data?.totalResults > 100
                    ? "~100"
                    : scanKeywordStatus.data?.totalResults}{" "}
                  {pluralise(
                    scanKeywordStatus.data?.totalResults,
                    "result",
                    "results"
                  )}
                </strong>{" "}
                to your threat monitoring feed.
              </div>
            ) : (
              <div>
                Although no recent results were found, threats can appear at any
                time. Add this keyword to start continuously monitoring it for
                new threats.
              </div>
            )}
          </div>
        )}
      </div>
    </ModalV2>
  );
};

export default EditKeywordModal;

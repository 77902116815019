import { FC } from "react";
import OrgScopeCategories from "../permissions/OrgScopeCategories";
import userbaseApi from "../../api/userbase.api";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { useLocaleCompare } from "../../../_common/hooks";
import DashboardContainer from "./DashboardContainer";

const OrgScopeCategoriesOverview: FC = ({}) => {
  // Get the org permissions
  const { data, isLoading } =
    userbaseApi.useGetUserBaseScopeCategoriesV1Query();
  const scopesToUse = data?.categories ?? [];

  const { caseInsensitiveCompare } = useLocaleCompare();

  const sortedCategories = scopesToUse.slice().sort(caseInsensitiveCompare);

  const permissionHeader = `Permission categories`;

  return (
    <div className="userbase-org-scope-categories-overview">
      <DashboardContainer title={permissionHeader}>
        {isLoading && <LoadingBanner />}
        {!isLoading && <OrgScopeCategories categories={sortedCategories} />}
      </DashboardContainer>
    </div>
  );
};

export default OrgScopeCategoriesOverview;

import { PureComponent } from "react";
import memoize from "memoize-one";
import { isUndefined as _isUndefined } from "lodash";
import {
  ISortedBy,
  IXTableColumnHeader,
  SortDirection,
} from "../../_common/components/core/XTable";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import Card from "../../_common/components/core/Card";
import {
  isIPAddress,
  NumberWithCommas,
  pluralise,
} from "../../_common/helpers";
import moment from "moment";
import "../style/components/WebscanTreeView.scss";
import {
  removeCustomerCloudscan,
  scanHostname,
} from "../reducers/cyberRiskActions";
import { openModal } from "../../_common/reducers/commonActions";
import {
  IWithPermissionsProps,
  withPermissions,
} from "../../_common/permissions";
import LabelList from "./LabelList";
import PillLabel from "./PillLabel";
import { ConfirmationModalName } from "../../_common/components/modals/ConfirmationModal";
import ReportCard from "../../_common/components/ReportCard";
import ColorGrade, { ColorGradeSize } from "./executive_summary/ColorGrade";
import {
  ILabel,
  LabelClassification,
  LabelColor,
} from "../../_common/types/label";
import { setLabelsForWebsites } from "../reducers/domainLabels.actions";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { Domain } from "../../_common/types/domains";
import TreeTableWindowed, { NodeCell, TreeNode } from "./TreeTableWindowed";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import IconButton, { HoverColor } from "../../_common/components/IconButton";
import DomainIPActionBar from "./DomainIPActionBar";
import EllipsizedText from "../../_common/components/EllipsizedText";
import DomainSourceLabelList from "./domains/DomainSourceLabelList";

export interface IDomainWithChildren extends Domain {
  children: IDomainWithChildren[] | string[];
  isTopLevel: boolean;
}

// parses a list of scans, and returns an array of domains
// with subdomain scans nested below. For example:
// [
//   {
//     hostname: "upguard.com",
//
//     ...,
//
//     children: [
//       {
//         hostname: "cyber-risk.upguard.com",
//         children: [],
//       },
//     ],
//   },
//   {
//     hostname: "scriptrock.com",
//
//     ...,
//
//     children: [],
//   },
// ];
export const domainListAsTree = (
  originalHostsMap: { [hostname: string]: IDomainWithChildren },
  _: string[],
  filterText: string
) => {
  const hostsMap: { [hostname: string]: IDomainWithChildren } = {};

  // Next, run through each of the hosts and add any children, if they exist
  for (const host in originalHostsMap) {
    if (!originalHostsMap.hasOwnProperty(host)) {
      continue;
    }

    if (!hostsMap[host]) {
      hostsMap[host] = {
        ...originalHostsMap[host],
        children: [],
      };
    }

    // Break off pieces of the hostname until we find one in the map
    let truncatedHost = host;
    let hasParent = false;

    // IP addresses should always be considered top level
    if (!isIPAddress(host)) {
      while (true) {
        const dotIdx = truncatedHost.indexOf(".");
        if (dotIdx === -1) {
          break;
        }

        truncatedHost = truncatedHost.slice(dotIdx + 1);

        if (originalHostsMap[truncatedHost]) {
          if (!hostsMap[truncatedHost]) {
            hostsMap[truncatedHost] = {
              ...originalHostsMap[truncatedHost],
              children: [],
            };
          }

          const hostchildren = hostsMap[truncatedHost].children as string[];
          hostchildren.push(host);
          hasParent = true;
          break;
        }
      }
    }

    if (!hasParent) {
      hostsMap[host].isTopLevel = true;
    }
  }

  // findChildrenRecursive takes a list of hostnames and
  // recursively finds their scans and own children
  const findChildrenRecursive = (hostnames: string[]) => {
    const scans = [];
    for (let i = 0; i < hostnames.length; i++) {
      const scan = hostsMap[hostnames[i]];
      if (!scan) {
        continue;
      }

      if (scan.children.length > 0) {
        scan.children = findChildrenRecursive(scan.children as string[]);
      }

      const shouldBeFilteredOut =
        filterText && hostnames[i].indexOf(filterText) === -1;

      if (scan.children.length === 0 && shouldBeFilteredOut) {
        // if we don't have any children after the filtering, and this should be filtered out, get outta here
        continue;
      }

      scan.hostname = hostnames[i];

      scans.push(scan);
    }

    return scans;
  };

  // Now lets run through each of the top level domains and recursively find their children, and add to the final array
  const treeScans: IDomainWithChildren[] = [];
  for (const host in hostsMap) {
    if (!hostsMap.hasOwnProperty(host)) {
      continue;
    }

    const scan = hostsMap[host];
    if (!scan || !scan.isTopLevel) {
      continue;
    }

    const children = findChildrenRecursive(scan.children as string[]);
    if (
      children.length === 0 &&
      filterText &&
      host.indexOf(filterText) === -1
    ) {
      continue;
    }

    treeScans.push({
      ...scan,
      children,
    });
  }

  return treeScans;
};

// Memoised function so we only recompute everything if the data changes
export const domainListAsTreeMemoised = memoize(
  domainListAsTree,
  (newArgs, oldArgs) => {
    // if the filter has changed, this needs to recompute
    if (
      newArgs[2] !== oldArgs[2] ||
      newArgs[3] !== oldArgs[3] ||
      newArgs[4] !== oldArgs[4]
    ) {
      return false;
    }

    if (_isUndefined(newArgs[1]) && _isUndefined(oldArgs[1])) {
      return true;
    }

    if (_isUndefined(newArgs[1]) || _isUndefined(oldArgs[1])) {
      return false;
    }

    // Next we need to compare the string arrays
    if (newArgs[1].length !== oldArgs[1].length) {
      return false;
    }

    for (let i = 0; i < newArgs[1].length; i++) {
      if (newArgs[1][i] !== oldArgs[1][i]) {
        return false;
      }
    }

    return true;
  }
);

export const getSortedHostsTree = (
  hostsMap: { [hostname: string]: IDomainWithChildren },
  allHostnames: string[],
  filterText: string,
  primaryHostname?: string,
  sortedBy?: ISortedBy
) => {
  const hostsTree = [
    ...domainListAsTreeMemoised(hostsMap, allHostnames, filterText),
  ];

  return sortHostTree(hostsTree, hostsMap, primaryHostname, sortedBy);
};

const sortHostTree = (
  hostsTree: IDomainWithChildren[],
  hostsMap: { [hostname: string]: IDomainWithChildren },
  primaryHostname?: string,
  sortedBy?: ISortedBy
) => {
  switch ((sortedBy ?? {}).columnId) {
    case "url":
      // sort the hosts alphabetically, bringing the primary hostname to the top
      hostsTree.sort((a, b) => a.hostname.localeCompare(b.hostname));
      break;
    case "score":
      hostsTree.sort((a, b) => {
        const aHost = hostsMap[a.hostname] || { score: 0 };
        const bHost = hostsMap[b.hostname] || { score: 0 };
        const aScore = !aHost.enabled ? 0 : aHost.score ?? 0;
        const bScore = !bHost.enabled ? 0 : bHost.score ?? 0;

        return aScore - bScore;
      });
      break;
    case "date_scanned":
      hostsTree.sort((a, b) => {
        const aHost = hostsMap[a.hostname] || {};
        const bHost = hostsMap[b.hostname] || {};
        const aScanned = !aHost.enabled ? 0 : aHost.scanned_at;
        const bScanned = !bHost.enabled ? 0 : bHost.scanned_at;
        return moment(aScanned).isBefore(bScanned) ? -1 : 1;
      });
      break;
    default:
      // sort the hosts alphabetically, bringing the primary hostname to the top
      hostsTree.sort((a, b) => {
        if (a.hostname === primaryHostname) {
          return -1;
        }
        if (b.hostname === primaryHostname) {
          return 1;
        }

        return a.hostname.localeCompare(b.hostname);
      });
      break;
  }

  if (sortedBy && sortedBy.direction !== "asc") {
    hostsTree.reverse();
  }

  // Sort subdomains recursively
  hostsTree.forEach((host) => {
    if (host.children && host.children.length > 0) {
      sortHostTree(
        host.children as IDomainWithChildren[],
        hostsMap,
        primaryHostname,
        sortedBy
      );
    }
  });

  return hostsTree;
};

export const getChildrenRowIds = (
  parentId: string | number,
  hostsTree: IDomainWithChildren[],
  filterByPortfolioIds?: number[],
  field: "hostname" | "hostnameID" = "hostname"
): (string | number)[] => {
  const childrenIds: (string | number)[] = [];

  const getChildrenFromParent = (
    host: IDomainWithChildren,
    isTarget: boolean
  ) => {
    const children = host.children as IDomainWithChildren[];
    children.forEach((ch) => {
      if (
        isTarget &&
        (!filterByPortfolioIds ||
          filterByPortfolioIds.length === 0 ||
          ch.portfolios?.find((p) => filterByPortfolioIds?.includes(p.id)))
      ) {
        childrenIds.push(ch[field]);
      }

      if (ch.children) {
        getChildrenFromParent(ch, isTarget || ch[field] === parentId);
      }
    });
  };

  hostsTree.forEach((h) => {
    getChildrenFromParent(h, h[field] === parentId);
  });

  return childrenIds;
};

interface IWebscanTreeViewOwnProps {
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  webscans: Domain[];
  webscansData: any;
  primaryHostname?: string;
  onOpenCloudscanPanel: (hostname: string) => void;
  filterText: string;
  dataIsHistorical?: boolean;
  error?: any;
  isManagedVendorAnalyst?: boolean;
  // GT: note - this is a combination of BS and VR perms depending on context. it's calculated in the parent
  userHasWriteVendorInfoPerm?: boolean;
  isSubsidiary: boolean;
  disableLabelAdd?: boolean;
  orgHasDomainPortfoliosEnabled?: boolean;
  userHasWritePermsInDomainPortfolios?: number[]; // Only include this if relevant, ie. this is a customer view.

  // Optional vendor id for labelling websites
  vendorId?: number;
  onFilterClear: () => void;
}

interface IWebscantreeViewState {
  openWebsites: { [hostname: string]: boolean };
  sortedBy?: ISortedBy;
  removingCustomerCloudscan: boolean;
  selectedHostnames: string[];
}

type IWebscanTreeViewProps = IWebscanTreeViewOwnProps & IWithPermissionsProps;

class WebscanTreeView extends PureComponent<
  IWebscanTreeViewProps,
  IWebscantreeViewState
> {
  static defaultProps = {
    webscans: [],
    primaryHostname: "",
    dataIsHistorical: false,
    error: null,
    isManagedVendorAnalyst: false,
    vendorId: undefined,
  };

  state = {
    openWebsites: {},
    sortedBy: {
      columnId: "domain",
      direction: SortDirection.ASC,
    },
    removingCustomerCloudscan: false,
    selectedHostnames: [] as string[],
  };

  onSortChange = (columnId: string, direction: SortDirection) => {
    this.setState({
      sortedBy: {
        columnId,
        direction,
      },
      openWebsites: {}, // Close any opened accordions too, we're not sorting deep stuff
    });
  };

  toggleOpenWebsite = (host: string) =>
    this.setState(({ openWebsites }) => {
      const newOpenWebsites = { ...openWebsites };
      if (openWebsites[host]) {
        delete newOpenWebsites[host];
      } else {
        newOpenWebsites[host] = true;
      }

      return { openWebsites: newOpenWebsites };
    });

  removeCustomerCloudscan = (hostname: string) => {
    this.props.dispatch(
      openModal(
        ConfirmationModalName,
        {
          title: "Remove this domain?",
          description: this.props.vendorId ? (
            <p>
              The domain will no longer appear in this vendor&apos;s list or
              contribute to their score.
            </p>
          ) : (
            <p>
              The domain will no longer appear in your list or contribute to
              your score.
            </p>
          ),
          dangerousAction: true,
          buttonAction: () => {
            this.setState({ removingCustomerCloudscan: true });
            this.props
              .dispatch(removeCustomerCloudscan(hostname, this.props.vendorId))
              .catch((e) => {
                this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
              })
              .then(() => this.setState({ removingCustomerCloudscan: false }));
          },
        },
        true
      )
    );
  };

  rescanHost = (hostname: string) => {
    this.props.dispatch(
      scanHostname(
        hostname,
        !!this.props.vendorId && !this.props.isSubsidiary,
        null
      )
    );
  };

  updateDomainLabels(
    items: Domain[],
    _: ILabel[],
    addedLabelIds: number[],
    removedLabelIds: number[]
  ): Promise<void> {
    return this.props.dispatch(
      setLabelsForWebsites(
        items.map((i) => i.hostname),
        addedLabelIds,
        removedLabelIds,
        this.props.vendorId,
        this.props.isSubsidiary
      )
    );
  }

  getUpdateLabelsModalHeader(items: Domain[]) {
    if (!items || items.length === 0) {
      return undefined;
    } else if (items.length === 1) {
      return `Update labels for ${items[0].hostname}`;
    } else {
      return `Update labels for ${items.length} domains`;
    }
  }

  selectClicked = (hostnames: string[], selected: boolean) => {
    this.setState((state) => {
      if (selected) {
        return {
          selectedHostnames: [...state.selectedHostnames, ...hostnames],
        };
      } else {
        return {
          selectedHostnames: state.selectedHostnames.filter(
            (h) => hostnames.indexOf(h) === -1
          ),
        };
      }
    });
  };

  render() {
    const { error } = this.props;
    if (error) {
      const { errorText, actionText, actionOnClick } = error;

      return (
        <Card error className="webscan-table-card">
          <div className="card-content">
            <div>{errorText}</div>
            {actionText && (
              <a className="btn btn-default" onClick={actionOnClick}>
                {actionText}
              </a>
            )}
          </div>
        </Card>
      );
    }

    const allHostnames: string[] = [];
    const hostsMap = this.props.webscans.reduce(
      (obj: { [hostname: string]: IDomainWithChildren }, scan) => {
        allHostnames.push(scan.hostname);
        obj[scan.hostname] = scan as IDomainWithChildren;
        return obj;
      },
      {}
    );

    allHostnames.sort((a, b) => a.localeCompare(b));

    const hostsTree = getSortedHostsTree(
      hostsMap,
      allHostnames,
      this.props.filterText,
      this.props.primaryHostname,
      this.state.sortedBy
    );

    const showDomainPortfolios =
      !this.props.vendorId &&
      !this.props.isSubsidiary &&
      this.props.orgHasDomainPortfoliosEnabled;

    const columnHeaders: IXTableColumnHeader[] = [
      {
        id: "url",
        text: "Domain",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      { id: "score", text: "Score", sortable: true, className: "score-header" },
      {
        id: "date_scanned",
        text: this.props.dataIsHistorical ? "Scanned on" : "Most recent scan",
        sortable: true,
        className: "date-scanned-header",
      },
      ...(showDomainPortfolios
        ? [
            {
              id: "portfolio",
              text: "Portfolio",
              sortable: false,
              className: "portfolio-header",
            },
          ]
        : []),
      {
        id: "labels",
        text: "Labels",
        sortable: false,
        className: "labels-header",
      },
      {
        id: "icons",
        text: "",
        sortable: false,
        className: "icons-header",
      },
      {
        id: "options-icons",
        text: "",
        sortable: false,
        className: "options-icons-header",
      },
    ];

    const generateTreeNodes = (
      nodes: TreeNode[],
      hostname: string,
      children: IDomainWithChildren[]
    ) => {
      // First construct the node for the host
      const scan = hostsMap[hostname] || {};
      const isPrimaryHostname = this.props.primaryHostname === hostname;
      const unscanned = !scan.enabled || (!scan.score && !scan.scanned_at);
      const isRescanning = !!(
        this.props.webscansData[hostname] &&
        this.props.webscansData[hostname].rescanning
      );
      const selected = this.state.selectedHostnames.indexOf(hostname) !== -1;
      const selectionDisabled =
        this.props.userHasWritePermsInDomainPortfolios &&
        this.props.userHasWritePermsInDomainPortfolios.length > 0 &&
        !scan.portfolios?.find(
          (p) => this.props.userHasWritePermsInDomainPortfolios?.includes(p.id)
        );

      const iconOptions = [];

      if (scan.removable && this.props.userHasWriteVendorInfoPerm) {
        iconOptions.push(
          <IconButton
            key="remove"
            icon={<div className="cr-icon-trash" />}
            hoverText="Remove"
            hoverColor={HoverColor.Red}
            onClick={() => this.removeCustomerCloudscan(hostname)}
          />
        );
      }

      if (unscanned) {
        iconOptions.push(
          <IconButton
            key="rescan"
            icon={<div className="cr-icon-redo" />}
            hoverText="Rescan"
            disabled={isRescanning}
            onClick={() => this.rescanHost(hostname)}
          />
        );
      }

      const { sources } = scan;

      const labels = (scan.labels || []).map((label: ILabel) => ({
        id: label.id,
        name: label.name,
        color: label.colour,
        bordered: true,
        removeable: false,
      }));

      const node: TreeNode = {
        id: hostname,
        className: unscanned ? "inactive" : "",
        children: [],
        onClick: !unscanned
          ? () => this.props.onOpenCloudscanPanel(hostname)
          : undefined,
        selected,
        selectionDisabled,
        selectionDisabledHelpText: selectionDisabled
          ? `You have read-only access to this domain's ${
              scan.portfolios?.length === 1 ? "portfolio" : "portfolios"
            }.`
          : undefined,
        mainCellContent: (
          <div className="url-cell">
            <div className="url">
              <EllipsizedText
                text={scan.hostname}
                popupClassName="ellipsized-treeview-url"
              >
                <div>{scan.hostname}</div>
              </EllipsizedText>
              {children.length > 0 && (
                <span className="num-subdomains">
                  {NumberWithCommas(children.length)}{" "}
                  {pluralise(children.length, "subdomain", "subdomains")}
                </span>
              )}
            </div>
            {isPrimaryHostname && (
              <PillLabel color={LabelColor.Blue} bordered>
                Primary domain
              </PillLabel>
            )}
            {!!unscanned && (
              <PillLabel color={LabelColor.Grey} bordered>
                Inactive
              </PillLabel>
            )}
            <DomainSourceLabelList sources={sources} />
          </div>
        ),
        otherCells: (
          <>
            <NodeCell className="score-cell">
              {!unscanned && (
                <div className="grade-with-score">
                  {scan.score !== undefined && scan.score >= 0 && (
                    <>
                      <ColorGrade
                        size={ColorGradeSize.Small}
                        score={scan.score}
                      />
                      {scan.score}
                    </>
                  )}
                </div>
              )}
            </NodeCell>
            <NodeCell className="date-scanned-cell">
              {!unscanned && (
                <span className="caps-time">
                  <DateTimeFormat dateTime={scan.scanned_at} dateOnly />
                </span>
              )}
            </NodeCell>
            {showDomainPortfolios && (
              <NodeCell className="portfolio-cell">
                {scan.portfolios?.map((p) => p.name).join(", ")}
              </NodeCell>
            )}
            <NodeCell className="labels-cell">
              <LabelList
                labels={labels}
                maxWidth={300}
                onLabelsUpdated={
                  !this.props.disableLabelAdd &&
                  this.props.userHasWriteVendorInfoPerm &&
                  !this.props.isManagedVendorAnalyst
                    ? (availableLabels, addedLabelIds, removedLabelIds) =>
                        this.updateDomainLabels(
                          [scan],
                          availableLabels,
                          addedLabelIds,
                          removedLabelIds
                        )
                    : undefined
                }
                updateLabelsHeader={this.getUpdateLabelsModalHeader([scan])}
                classification={LabelClassification.WebsiteLabel}
              />
            </NodeCell>
            <NodeCell className="icons-cell">
              {isRescanning && <LoadingIcon />}
            </NodeCell>
            <NodeCell className="options-icons-cell">{iconOptions}</NodeCell>
          </>
        ),
      };

      if (children.length > 0) {
        for (let i = 0; i < children.length; i++) {
          generateTreeNodes(
            node.children,
            children[i].hostname,
            children[i].children as IDomainWithChildren[]
          );
        }
      }

      nodes.push(node);
    };

    const selectedItems = this.props.webscans
      ? this.props.webscans.filter(
          (d) => this.state.selectedHostnames.indexOf(d.hostname) !== -1
        )
      : [];

    const nodesTree: TreeNode[] = [];
    for (let i = 0; i < hostsTree.length; i++) {
      generateTreeNodes(
        nodesTree,
        hostsTree[i].hostname,
        hostsTree[i].children as IDomainWithChildren[]
      );
    }

    const canEdit = !this.props.vendorId
      ? this.props.userHasWriteBreachSight ||
        (this.props.userHasWritePermsInDomainPortfolios &&
          this.props.userHasWritePermsInDomainPortfolios.length > 0)
      : this.props.userHasVendorRiskWrite;

    return (
      <div className="webscan-tree-view">
        <ReportCard>
          {this.props.loading ? (
            <LoadingBanner />
          ) : nodesTree.length === 0 ? (
            <SearchEmptyCard
              searchItemText="domains"
              onClear={this.props.onFilterClear}
            />
          ) : (
            <TreeTableWindowed
              nodes={nodesTree}
              columnHeaders={columnHeaders}
              sortedBy={this.state.sortedBy}
              onSortChange={this.onSortChange}
              onSelectClick={(hostname, selected) =>
                this.selectClicked([hostname as string], selected)
              }
              childrenSelection={
                canEdit
                  ? {
                      onSelectChildrenClick: (parentRowId, selected) => {
                        const childrenIds = getChildrenRowIds(
                          parentRowId,
                          hostsTree,
                          this.props.userHasWritePermsInDomainPortfolios
                        );
                        this.selectClicked(childrenIds as string[], selected);
                      },
                      selectAllText: "Select all subdomains",
                      selectNoneText: "Unselect all subdomains",
                    }
                  : undefined
              }
            />
          )}
        </ReportCard>
        <DomainIPActionBar
          vendorId={this.props.vendorId}
          isSubsidiary={this.props.isSubsidiary}
          selectedDomains={selectedItems}
          onCancel={() => this.setState({ selectedHostnames: [] })}
          onDeselectAll={() => this.setState({ selectedHostnames: [] })}
        />
      </div>
    );
  }
}

export default WebscanTreeView;
export const WebscanTreeViewWithPermissions = withPermissions(WebscanTreeView);

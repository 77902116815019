import { FC } from "react";
import NavLink from "../_common/components/NavLink";
import { pageDescriptions } from "../_common/pageDescriptions";
import { ClickNavLinkFunc } from "../_common/components/navigation/BreachSightNavItems";
import {
  usePermissions,
  hasUserPermission,
  UserThreatMonitoringRead,
  hasOrgPermission,
  OrgAccessThreatMonitoringSocialMedia,
  OrgAccessThreatMonitoringDarkWeb,
  OrgAccessThreatMonitoringDataLeaks,
} from "../_common/permissions";
import { ThreatMonitoringModule } from "./api/types";

interface ThreatMonitoringNavItemsProps {
  pathname: string;
  clickNavLinkFunc: ClickNavLinkFunc;
}

export const ThreatMonitoringBreachsightNavItems: FC<
  ThreatMonitoringNavItemsProps
> = ({ clickNavLinkFunc }) => {
  const rootPath = "/breachrisk/threatmonitoring";

  return (
    <>
      <NavLink
        icon="threat-monitoring"
        text="Threat Monitoring"
        onClick={clickNavLinkFunc(rootPath, "Threat Monitoring")}
        to={rootPath}
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
    </>
  );
};

interface ThreatMonitoringNavItemsNewProps
  extends ThreatMonitoringNavItemsProps {
  disabled: boolean;
}
// Multi product Nav Items
export function ThreatMonitoringBreachsightNavItemsNew({
  pathname,
  disabled,
  clickNavLinkFunc,
}: ThreatMonitoringNavItemsNewProps) {
  const { permissions } = usePermissions();

  const hasDarkWeb = hasOrgPermission(
    permissions,
    OrgAccessThreatMonitoringDarkWeb
  );
  const hasSocialMedia = hasOrgPermission(
    permissions,
    OrgAccessThreatMonitoringSocialMedia
  );
  const hasDataLeaks = hasOrgPermission(
    permissions,
    OrgAccessThreatMonitoringDataLeaks
  );

  if (!hasUserPermission(permissions, UserThreatMonitoringRead)) {
    return null;
  }
  if (!hasDarkWeb && !hasSocialMedia && !hasDataLeaks) {
    return null;
  }

  const rootPath = "/breachrisk/threatmonitoring";
  const p = (p: string) => `${rootPath}${p}`;
  const moduleLink = (module: ThreatMonitoringModule) => p(`/${module}`);

  const tmSelected = pathname.startsWith(rootPath);

  const canSeeDarkWeb = tmSelected && hasDarkWeb;
  const canSeeSocialMedia = tmSelected && hasSocialMedia;
  const canSeeDataLeaks = tmSelected && hasDataLeaks;

  return (
    <>
      <NavLink
        text="Threat Monitoring"
        className="unstyled"
        disabled={disabled}
        onClick={clickNavLinkFunc(rootPath, "Threat Monitoring")}
        to={rootPath}
        exact
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
      {canSeeDarkWeb && (
        <NavLink
          text={"Dark Web"}
          className="unstyled"
          isSubMenuItem
          disabled={disabled}
          hoverText={pageDescriptions.ThreatMonitoringDarkWeb}
          onClick={clickNavLinkFunc(
            moduleLink(ThreatMonitoringModule.ModuleDarkWeb),
            "Dark Web"
          )}
          to={moduleLink(ThreatMonitoringModule.ModuleDarkWeb)}
          hasOrgPermission={true}
          hasUserPermission={true}
        />
      )}
      {canSeeSocialMedia && (
        <NavLink
          text={"Social Media"}
          className="unstyled"
          isSubMenuItem
          disabled={disabled}
          hoverText={pageDescriptions.ThreatMonitoringSocialMedia}
          onClick={clickNavLinkFunc(
            moduleLink(ThreatMonitoringModule.ModuleSocialMedia),
            "Social Media"
          )}
          to={moduleLink(ThreatMonitoringModule.ModuleSocialMedia)}
          hasOrgPermission={true}
          hasUserPermission={true}
        />
      )}
      {canSeeDataLeaks && (
        <NavLink
          text={"Data Leaks"}
          className="unstyled"
          isSubMenuItem
          disabled={disabled}
          hoverText={pageDescriptions.ThreatMonitoringDataLeaks}
          onClick={clickNavLinkFunc(
            moduleLink(ThreatMonitoringModule.ModuleDataLeaks),
            "Data Leaks"
          )}
          to={moduleLink(ThreatMonitoringModule.ModuleDataLeaks)}
          hasOrgPermission={true}
          hasUserPermission={true}
        />
      )}
    </>
  );
}

export default ThreatMonitoringBreachsightNavItems;

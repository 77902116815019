import { SelectV2 } from "../../../../_common/components/SelectV2";
import Button from "../../../../_common/components/core/Button";
import ColorCheckbox from "../../../../vendorrisk/components/ColorCheckbox";
import TextField, {
  useTextWithValid,
} from "../../../../_common/components/TextField";
import { surveyType } from "../../../../_common/types/surveyTypes";
import { FC, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import "../../../style/StartNewQuestionnaire.scss";
import { openInNewTab } from "../../../helpers/helpers";
import { newSurveyData } from "../AddQuestionnairesModal";

interface AddNewSurveyProps {
  surveyTypes: surveyType[];
  onFormChange: (data: newSurveyData) => void;
}

const StartNewQuestionnaireComponent: FC<AddNewSurveyProps> = ({
  surveyTypes,
  onFormChange,
}) => {
  const [selectedSurveyType, setSelectedSurveyType] = useState<surveyType>(
    surveyTypes[0]
  );
  const [allSectionsSelected, setAllSectionsSelected] = useState<boolean>(true);
  const [selectedSections, setSelectedSections] = useState<number[]>([]);
  const [newSurveyName, newSurveyNameIsValid, setNewSurveyName] =
    useTextWithValid("");
  const [
    newSurveyDescription,
    newSurveyDescriptionIsValid,
    setNewSurveyDescription,
  ] = useTextWithValid("");

  const getPreviewLink = (surveyTypeId: number) => {
    return `/questionnairepreview?type_id=${surveyTypeId}&risk_visibility=visible`;
  };

  const getSectionPreviewLink = (sectionId: number, sectionName: string) => {
    return `/questionnairepreview?section_id=${sectionId}&section_name=${sectionName}&risk_visibility=visible`;
  };

  useEffect(() => {
    //init states on survey type changes
    setAllSectionsSelected(true);
    setSelectedSections([]);
    setNewSurveyName("", false);
    setNewSurveyDescription("", true);
  }, [selectedSurveyType]);

  useEffect(() => {
    const sectionIds = allSectionsSelected
      ? selectedSurveyType.sections.map((section) => section.id)
      : selectedSections;

    onFormChange({
      name: newSurveyName,
      description: newSurveyDescription,
      surveyType: selectedSurveyType,
      sectionIds: sectionIds,
      isValid: newSurveyNameIsValid && newSurveyDescriptionIsValid,
    });
  }, [
    selectedSurveyType,
    selectedSections,
    newSurveyName,
    newSurveyDescription,
    newSurveyNameIsValid,
    newSurveyDescriptionIsValid,
    allSectionsSelected,
  ]);

  const isSurveySectionSelected = useCallback(
    (sectionId: number) => selectedSections.includes(sectionId),
    [selectedSections]
  );

  const toggleSectionSelected = useCallback((sectionId: number) => {
    setSelectedSections((prevSelected) =>
      prevSelected.includes(sectionId)
        ? prevSelected.filter((s) => s !== sectionId)
        : [...prevSelected, sectionId]
    );
  }, []);

  return (
    <div className="add-new-questionnaire">
      <div className="questionnaire-type-selector">
        <div className={"label"}>
          What type of questionnaire would you like to complete?
        </div>
        <SelectV2
          value={{
            label: selectedSurveyType.name,
            value: selectedSurveyType.id,
          }}
          options={surveyTypes.map((st) => ({
            label: st.name,
            value: st.id,
          }))}
          onChange={(option) => {
            setSelectedSurveyType(
              surveyTypes.find((st) => st.id === option!.value)!
            );
          }}
          placeholder="Select a questionnaire type"
        />
        <div
          className={classnames("survey-sections", {
            ["no-sections"]: selectedSurveyType.sections.length <= 1,
          })}
        >
          <div className={"name-preview"}>
            <h3>
              {selectedSurveyType.overrideName ?? selectedSurveyType.name}
            </h3>
            {selectedSurveyType.sections.length === 1 && (
              <Button
                tertiary
                onClick={(evt) => {
                  evt.stopPropagation();
                  openInNewTab(getPreviewLink(selectedSurveyType.id));
                }}
              >
                Preview
              </Button>
            )}
          </div>
          {selectedSurveyType.description !== "" && (
            <p>{selectedSurveyType.description}</p>
          )}
          {selectedSurveyType.sections.length > 1 && (
            <>
              <ColorCheckbox
                onClick={() => setAllSectionsSelected(!allSectionsSelected)}
                checked={allSectionsSelected}
                label={
                  <div className={"survey-section"}>
                    <div className={"label"}>All sections</div>
                    <Button
                      tertiary
                      onClick={(evt) => {
                        evt.stopPropagation();
                        openInNewTab(getPreviewLink(selectedSurveyType.id));
                      }}
                    >
                      Preview
                    </Button>
                  </div>
                }
              />
              <div className={"horz-line"} />
              {selectedSurveyType.sections.map((section) => (
                <ColorCheckbox
                  key={section.id}
                  onClick={() => toggleSectionSelected(section.id)}
                  checked={
                    isSurveySectionSelected(section.id) || allSectionsSelected
                  }
                  label={
                    <div className={"survey-section"}>
                      <div className={"label"}>{section.displayName}</div>
                      <Button
                        onClick={(evt) => {
                          evt.stopPropagation();
                          openInNewTab(
                            getSectionPreviewLink(section.id, section.name)
                          );
                        }}
                        tertiary
                      >
                        Preview
                      </Button>
                    </div>
                  }
                  disabled={allSectionsSelected}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <div className={"horz-line"} />
      <div className="new-questionnaire-details">
        <div>
          <div className={"label required"}>Questionnaire name</div>
          <TextField
            required
            value={newSurveyName}
            onChanged={(name, valid) => setNewSurveyName(name, valid)}
            debounceTime={500}
            minLength={3}
            maxLength={120}
            placeholder={"Provide a name"}
          />
        </div>
        <div>
          <div className={"label"}>Description</div>
          <TextField
            value={newSurveyDescription}
            multiLine
            debounceTime={500}
            onChanged={(name, valid) => setNewSurveyDescription(name, valid)}
            placeholder={"Describe the purpose of the questionnaire (optional)"}
          />
        </div>
      </div>
    </div>
  );
};

export default StartNewQuestionnaireComponent;

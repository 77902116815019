import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice, { FeedTypes, isEmptySnapshot, Module } from "../Slice";
import ThreatMonitoringAPI, {
  GetResultsV1ReqBody,
} from "../api/threatmonitoring.api";
import {
  ThreatMonitoringFeedType,
  ThreatMonitoringResultsDatePeriodOption,
} from "../api/types";
import { useDefaultHistory } from "../../_common/types/router";
import { getDateOfLastFeedDownload } from "../views/ThreatMonitoringView";
import { threatLink } from "./useNav";

// useXTablePagination manages pagination for a feed shown in an XTable component.
// The returned object can be used for the XTable pagination prop, but also includes additional information
// which can be used to display the current page, displayed ranges and totals etc.
export function useXTablePagination<I = any>({
  feedType,
  feedItems,
  totalResults,
  pageSize,
  module,
}: {
  feedType: FeedTypes;
  feedItems?: I[];
  totalResults?: number;
  pageSize: number;
  module: Module;
}) {
  const dispatch = useAppDispatch();
  const pageNum = useAppSelector((s) =>
    tmSlice.selectors.pageNumberForFeed(s, { module, feed: feedType })
  );

  feedItems = feedItems || [];
  totalResults = totalResults || 0;

  const totalPages = Math.ceil(totalResults / pageSize);
  const startIdx = pageNum * pageSize + 1;
  const endIdx = startIdx + feedItems.length - 1;

  return {
    startIdx,
    endIdx,
    totalPages,
    totalResults,
    pageNum,
    currentPage: pageNum + 1,
    setPageNum: (pageNum: number) => {
      dispatch(
        tmSlice.actions.setPageNumber({
          module,
          feed: feedType,
          page: pageNum,
        })
      );
    },
    onPageChange: (page: number) => {
      dispatch(
        tmSlice.actions.setPageNumber({
          module,
          feed: feedType,
          page: page - 1,
        })
      );
    },
    hidePaginationIfSinglePage: true,
  };
}

const nullPageOpts = {
  feedType: ThreatMonitoringFeedType.Open,
  filters: {
    datePeriod: ThreatMonitoringResultsDatePeriodOption.LastThirtyDays,
  },
  pageOpts: {
    pageSize: 10,
    pageNum: 0,
    sortBy: "riskScore",
    sortDesc: true,
  },
};

// useResultSessionPagination manages pagination and navigation for a threat assessment session.
// This uses a snapshot of the current open feed page, so that workflow actions on threat results don't modify the set of results in the session.
export function useResultSessionPagination({
  uuid,
  module,
}: {
  uuid: string;
  module: Module;
}) {
  const history = useDefaultHistory<{ feedQuery: GetResultsV1ReqBody }>();
  const session = useAppSelector((s) =>
    tmSlice.selectors.sessionSnapshot(s, module)
  );

  const noSession = isEmptySnapshot(session);
  const feedQuery: GetResultsV1ReqBody = noSession
    ? nullPageOpts
    : session.query;

  // get the currently selected result
  const { data, isLoading, error } = ThreatMonitoringAPI.useGetResultV1Query(
    {
      uuid: uuid,
    },
    { skip: noSession }
  );

  const { data: totalsData } = ThreatMonitoringAPI.useGetResultTotalsV1Query(
    {
      feedType: ThreatMonitoringFeedType.Open,
      feedUpTo: feedQuery.feedUpTo || getDateOfLastFeedDownload(),
      filters: feedQuery.filters,
    },
    {
      skip: noSession,
    }
  );

  if (isLoading || error || noSession) {
    return {
      isLoading,
      error,
    };
  }

  const indexOfResult = session.uuids.indexOf(uuid);

  if (indexOfResult === -1) {
    return {
      result: null,
      error: new Error("Result not found"),
    };
  }

  const result = data?.result;
  const actors = data?.actors;

  const next = async () => {
    if (indexOfResult + 1 >= session.uuids.length) {
      return;
    }

    const nextItemUUID = session.uuids[indexOfResult + 1];

    history.replace(threatLink(nextItemUUID, module), {
      ...history.location.state,
      feedQuery,
    });
  };

  const prev = async () => {
    if (indexOfResult === 0) {
      return;
    }

    const prevItemUUID = session.uuids[indexOfResult - 1];

    history.replace(threatLink(prevItemUUID, module), {
      ...history.location.state,
      feedQuery,
    });
  };

  return {
    result,
    actors,
    idx: indexOfResult + 1,
    totalInSession: session.uuids.length,
    totalOpen: totalsData?.count,
    isLoading: false,
    next,
    prev,
    hasNext: indexOfResult + 1 < session.uuids.length,
    hasPrev: indexOfResult > 0,
  };
}

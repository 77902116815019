import { produce } from "immer";
import { invalidRowsToString, stringToInvalidRows } from "./invalidRows";
import { Reducer } from "react";

export type KnownSurveyType = "SIG" | "CAIQ" | "HECVAT" | "unknown";

export interface ExcelQnExtractorConfig {
  sheetName: string;
  answerRowOffset?: number;
  questionColumns: number[];
  answerColumns: number[];
  questionNumberColumn?: number;
  headingColumn?: number;
  headingRows?: Record<number, true | undefined>;
  selected: boolean;
  preview: (string[] | null)[];
  numValidColumns: number;
  numValidRows: number;
  invalidRows?: Record<number, true | undefined>;
  hidden?: boolean;
}

export type ExcelExtractorConfigs = Record<string, ExcelQnExtractorConfig>;

// State for things we want to keep track of on the frontend only
export interface ExcelConfigFrontendSheetState {
  invalidRowsAsString?: string;
  invalidRowsAsStringValid?: boolean;
  headingRowsAsString?: string;
  headingRowsAsStringValid?: boolean;
}

export interface ExcelConfigState {
  sheets: ExcelExtractorConfigs;

  // State for things we want to keep track of on the frontend only
  frontendSheetState: Record<string, ExcelConfigFrontendSheetState>;
}

export interface ExcelExtractorConfig {
  type: KnownSurveyType;
  sheetNames?: string[];
  config: ExcelExtractorConfigs;
}

export type ExcelConfigAction =
  | { type: "FROM_STATE"; state: ExcelExtractorConfigs }
  | { type: "SET_ANSWER_COLUMNS"; sheet: string; columns: number[] }
  | { type: "SET_QUESTION_COLUMNS"; sheet: string; columns: number[] }
  | { type: "SET_INVALID_ROWS"; sheet: string; inputString: string }
  | {
      type: "SET_QUESTION_NUMBER_COLUMN";
      sheet: string;
      column: number | undefined;
    }
  | {
      type: "SET_HEADING_COLUMN";
      sheet: string;
      column: number | undefined;
    }
  | { type: "SET_HEADING_ROWS"; sheet: string; inputString: string }
  | { type: "TOGGLE_SELECTED"; sheet: string }
  | { type: "SELECT_SHEET"; sheet: string }
  | { type: "SET_ROW_OFFSET"; sheet: string; offset?: number }
  | { type: "SET_SELECT_SHEETS"; sheets: string[] };

export const ExcelConfigStateInitial: ExcelConfigState = {
  sheets: {},
  frontendSheetState: {},
};

export const ExcelConfigReducer: Reducer<
  ExcelConfigState,
  ExcelConfigAction
> = (state, action) => {
  switch (action.type) {
    case "FROM_STATE":
      return {
        sheets: action.state,
        frontendSheetState: Object.values(action.state).reduce(
          (prev: ExcelConfigState["frontendSheetState"], next) => {
            prev[next.sheetName] = {};
            if (next.invalidRows) {
              prev[next.sheetName].invalidRowsAsString = invalidRowsToString(
                next.invalidRows
              );
              prev[next.sheetName].invalidRowsAsStringValid = true;
            }
            if (next.headingRows) {
              prev[next.sheetName].headingRowsAsString = invalidRowsToString(
                next.headingRows
              );
              prev[next.sheetName].headingRowsAsStringValid = true;
            }
            return prev;
          },
          {}
        ),
      };
    case "SET_ANSWER_COLUMNS":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].answerColumns = action.columns;
      });
    case "SET_QUESTION_COLUMNS":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].questionColumns = action.columns;
      });
    case "SET_QUESTION_NUMBER_COLUMN":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].questionNumberColumn = action.column;
      });
    case "SET_HEADING_COLUMN":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].headingColumn = action.column;
      });
    case "SET_HEADING_ROWS": {
      return produce(state, (draft) => {
        if (
          !draft.sheets[action.sheet] ||
          !draft.frontendSheetState[action.sheet]
        ) {
          return;
        }
        const [newHeadingRows, valid] = stringToInvalidRows(action.inputString);
        if (valid) {
          draft.sheets[action.sheet].headingRows = newHeadingRows;
        }
        draft.frontendSheetState[action.sheet].headingRowsAsString =
          action.inputString;
        draft.frontendSheetState[action.sheet].headingRowsAsStringValid = valid;
      });
    }
    case "SET_INVALID_ROWS": {
      return produce(state, (draft) => {
        if (
          !draft.sheets[action.sheet] ||
          !draft.frontendSheetState[action.sheet]
        ) {
          return;
        }
        const [newInvalidRows, valid] = stringToInvalidRows(action.inputString);
        if (valid) {
          draft.sheets[action.sheet].invalidRows = newInvalidRows;
        }
        draft.frontendSheetState[action.sheet].invalidRowsAsString =
          action.inputString;
        draft.frontendSheetState[action.sheet].invalidRowsAsStringValid = valid;
      });
    }
    case "TOGGLE_SELECTED":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].selected =
          !draft.sheets[action.sheet].selected;
      });
    case "SELECT_SHEET":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].selected = true;
      });
    case "SET_ROW_OFFSET":
      return produce(state, (draft) => {
        if (!draft.sheets[action.sheet]) {
          return;
        }
        draft.sheets[action.sheet].answerRowOffset = action.offset;
      });
    case "SET_SELECT_SHEETS":
      return produce(state, (draft) => {
        action.sheets.forEach((sheetName) => {
          if (draft.sheets[sheetName]) {
            draft.sheets[sheetName].selected = true;
          }
        });
      });
    default:
      return state;
  }
};

// Validates that ExcelExtractorConfigs can be submitted.
// At least one sheet must be selected.
export const excelConfigValidationError = (
  config: ExcelConfigState,
  singleQuestionAnswerColumns?: boolean
): string | undefined => {
  let anySheetsSelected = false;

  for (const sheetName in config.sheets) {
    const sheet = config.sheets[sheetName];
    if (!sheet.selected) {
      continue;
    }

    if (singleQuestionAnswerColumns && sheet.questionColumns.length !== 1) {
      return "Question column must be selected in sheet: " + sheetName;
    } else if (sheet.questionColumns.length < 0) {
      return (
        "At least one question column must be selected in sheet: " + sheetName
      );
    }

    if (singleQuestionAnswerColumns && sheet.answerColumns.length !== 1) {
      return "Answer column must be selected in sheet: " + sheetName;
    } else if (sheet.answerColumns.length < 0) {
      return (
        "At least one answer column must be selected in sheet: " + sheetName
      );
    }

    if (
      config.frontendSheetState[sheetName]?.invalidRowsAsString &&
      !config.frontendSheetState[sheetName].invalidRowsAsStringValid
    ) {
      return "Excluded rows are not valid in sheet: " + sheetName;
    }

    anySheetsSelected = true;
  }

  if (!anySheetsSelected) {
    return "At least one sheet must be selected";
  }

  return;
};

export interface AutoFillSource {
  name: string;
  id: number;
  isPublic: boolean;
  isExternal: boolean;
}

export interface ExcelDocumentConfig {
  excelConfig: ExcelExtractorConfig;
}

export type ExternalDocumentConfig = ExcelDocumentConfig; // other types will be unioned with this as we get them

export interface ExternalDocument<
  docType = string,
  docConfig = ExternalDocumentConfig,
> {
  uuid: string;
  documentRepositoryUUID?: string;
  documentRepositoryType?: string;
  orgID?: number;
  userID: number;
  asyncJobID?: number;
  extractionProgress?: number;
  name: string;
  docType: docType;
  processed: boolean;
  createdAt: string;
  documentConfig: docConfig;
}

export interface GptUserPreferences {
  platformSurveys: Record<string, boolean>;
  externalDocuments: Record<string, boolean>;
  contentLibraryDocuments: Record<string, boolean>;
}

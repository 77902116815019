import { useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import "../style/MoveQuestion.scss";
import {
  getAllNodesAsTree,
  getChildNodeQuestionNumber,
  moveNodeInTree,
  NodeSummary,
} from "../helpers";
import NodeTypeIcon, { NodeTypeIconType } from "./NodeTypeIcon";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import ModalV2 from "../../_common/components/ModalV2";
import Button from "../../_common/components/core/Button";
import { useAppSelector } from "../../_common/types/reduxHooks";

interface IDroppableSeparatorProps {
  moveToIndex: number[];
  droppable: boolean;
}

interface dropResult {
  moveToIndex: number[];
}

// DroppableSeparator is used to render lines between items in the tree,
// and expose a droppable target used when dragging an item.
const DroppableSeparator = (props: IDroppableSeparatorProps) => {
  const indentLevel = props.moveToIndex.length;

  const [dropProps, drop] = useDrop({
    accept: draggableNodeType,
    drop() {
      // Return a dropResult which can be read in the endDrag method for the dragged item
      return {
        moveToIndex: props.moveToIndex,
      };
    },
    // Collect returns the dropProps object, which is reactive based on the drop target monitor.
    collect(monitor) {
      return {
        dropActive: monitor.isOver(),
      };
    },
  });

  return (
    <div
      className={classnames("sep-line", `indent-${indentLevel}`, {
        active: dropProps.dropActive,
      })}
      ref={props.droppable ? drop : undefined}
    >
      <div className="sep-line-inner" />
    </div>
  );
};

interface nodeSummaryWithNewQuestionNumber extends NodeSummary {
  newQuestionNumber: string;
}

interface IDraggableNodeProps {
  node: nodeSummaryWithNewQuestionNumber;
  highlight: boolean;
  treeIndex: number[];
  startDrag: (nodeId: string) => void;
  endDrag: (
    nodeId: string,
    treeIndex: number[],
    dropResult?: dropResult | null
  ) => void;
}

const draggableNodeType = "NODE";

const draggableNodeDOMId = (nodeId: string) =>
  `draggable_node_${nodeId.replace(/\./g, "_")}`;

// DraggableNode renders a single item in the tree and makes it draggable, able
// to be dropped on DroppableSeparators.
const DraggableNode = (props: IDraggableNodeProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: draggableNodeType,
    item: () => {
      props.startDrag(props.node.nodeId);
      return {
        type: draggableNodeType,
        id: props.node.nodeId,
      };
    },
    end(_, monitor) {
      props.endDrag(
        props.node.nodeId,
        props.treeIndex,
        monitor.getDropResult()
      );
    },
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  // We're keeping track of new question numbers but not displaying them yet.
  // const wasMoved = props.node.questionNumber !== props.node.newQuestionNumber;

  return (
    <div
      className={classnames("draggable-node", {
        dragging: isDragging,
        highlight: props.highlight,
      })}
      ref={drag}
      id={draggableNodeDOMId(props.node.nodeId)}
    >
      <NodeTypeIcon
        questionNumber={props.node.questionNumber}
        nodeType={props.node.nodeType}
      />
      <div className="truncate-text" title={props.node.titleOrMainText}>
        {props.node.titleOrMainText || <em>Untitled</em>}
      </div>
      <div className="cr-icon-drag-handle" />
    </div>
  );
};

export interface IMoveQuestionModalProps {
  surveyId: string;
  scrollToNodeIdOnMount?: string;
  closeModal: () => void;
  saveChanges: (allQuestions: NodeSummary[]) => void;
}

const MoveQuestionModal = (props: IMoveQuestionModalProps) => {
  // Grab a reference to the redux store so we can get a full listing of just the questions
  // we're interested in, on mount.
  const currentSurvey = useAppSelector(
    (state) => state.surveyBuilder.surveys[props.surveyId]
  );
  const [allQuestions, setAllQuestions] = useState(() => {
    // Passing a function to useState allows it to be only calculated on the initial render.
    return getAllNodesAsTree(currentSurvey);
  });

  // Manually decide when to re-render the node tree so we can keep this performant.
  const [rerenderNodesAt, setRerenderNodesAt] = useState(new Date().valueOf());

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [highlightQuestion, setHighlightQuestion] = useState(
    props.scrollToNodeIdOnMount
  );

  useEffect(() => {
    if (!props.scrollToNodeIdOnMount || !scrollContainerRef.current) {
      return;
    }

    // On mount, make sure we're scrolled to the nodeId we're interested in
    const nodeToFind = scrollContainerRef.current.querySelector(
      "#" + draggableNodeDOMId(props.scrollToNodeIdOnMount)
    );
    if (!nodeToFind) {
      return;
    }

    scrollContainerRef.current.scrollTop =
      nodeToFind.getBoundingClientRect().top -
      scrollContainerRef.current.getBoundingClientRect().top -
      20;
  }, []);

  const [currentlyDragging, setCurrentlyDragging] = useState("");

  const startDrag = (nodeId: string, shouldReRenderNodeTree: boolean) => {
    setCurrentlyDragging(nodeId);
    // Always stop highlighting a question when we start dragging something
    setHighlightQuestion(undefined);

    if (shouldReRenderNodeTree) {
      setRerenderNodesAt(new Date().valueOf());
    }
  };

  const endDrag = (
    _: string,
    treeIndex: number[],
    dropResult?: dropResult | null
  ) => {
    setCurrentlyDragging("");

    if (dropResult?.moveToIndex) {
      // Execute the move in our temporary tree
      setAllQuestions((allQuestions) =>
        moveNodeInTree(allQuestions, treeIndex, dropResult.moveToIndex)
      );
    }

    setRerenderNodesAt(new Date().valueOf());
  };

  const renderNode = (
    node: nodeSummaryWithNewQuestionNumber,
    treeIndex: number[]
  ) => {
    const nextInTree = [...treeIndex];
    nextInTree[nextInTree.length - 1] = nextInTree[nextInTree.length - 1] + 1;

    return (
      <div className="tree-node" key={node.nodeId}>
        <DroppableSeparator droppable moveToIndex={treeIndex} />
        <div className="node-item">
          <DraggableNode
            node={node}
            highlight={highlightQuestion === node.nodeId}
            treeIndex={treeIndex}
            startDrag={(nodeId) =>
              startDrag(nodeId, !!node.children && node.children.length > 0)
            }
            endDrag={endDrag}
          />
        </div>
        <DroppableSeparator
          droppable={!node.children}
          moveToIndex={nextInTree}
        />
        {!!node.children && (
          <div className="node-children">
            <div className="node-indent" />
            <div className="tree-node-container">
              {node.children.length === 0 && (
                <div className="tree-node">
                  <DroppableSeparator
                    droppable
                    moveToIndex={[...treeIndex, 0]}
                  />
                </div>
              )}
              {currentlyDragging !== node.nodeId &&
                (() => {
                  let curQuestionNumber = -1;
                  return node.children.map((n, i) => {
                    if (n.nodeType !== NodeTypeIconType.Risk) {
                      curQuestionNumber += 1;
                    }

                    return renderNode(
                      {
                        ...n,
                        newQuestionNumber:
                          n.nodeType === NodeTypeIconType.Risk
                            ? ""
                            : getChildNodeQuestionNumber(
                                node.newQuestionNumber,
                                curQuestionNumber
                              ),
                      },
                      [...treeIndex, i]
                    );
                  });
                })()}
              {currentlyDragging !== node.nodeId &&
                node.children.length > 0 && (
                  <div className="tree-node">
                    <DroppableSeparator
                      droppable
                      moveToIndex={[...treeIndex, node.children.length]}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Since rendering the full tree is expensive, only do it on demand.
  const nodeTree = useMemo(() => {
    let curQuestionNumber = -1;
    return allQuestions.map((node, i) => {
      if (node.nodeType !== NodeTypeIconType.Risk) {
        curQuestionNumber += 1;
      }
      return renderNode(
        {
          ...node,
          newQuestionNumber:
            node.nodeType === NodeTypeIconType.Risk
              ? ""
              : getChildNodeQuestionNumber("", curQuestionNumber),
        },
        [i]
      );
    });
  }, [rerenderNodesAt]);

  return (
    <ModalV2
      className="move-question-modal"
      active
      disallowClose
      headerContent="Move question"
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={props.closeModal}>
            Cancel
          </Button>
          <Button primary onClick={() => props.saveChanges(allQuestions)}>
            <span className="cr-icon-check" /> Save changes
          </Button>
        </div>
      }
    >
      <DndProvider backend={HTML5Backend}>
        <div
          className={classnames("move-question", {
            dragging: !!currentlyDragging,
          })}
          ref={scrollContainerRef}
        >
          <div className="tree-node-container">
            {nodeTree}
            <div className="tree-node">
              <DroppableSeparator
                droppable
                moveToIndex={[allQuestions.length]}
              />
            </div>
          </div>
        </div>
      </DndProvider>
    </ModalV2>
  );
};

export default MoveQuestionModal;

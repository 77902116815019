import { memo, useState } from "react";
import NodeTypeIcon, { NodeTypeIconType } from "./NodeTypeIcon";
import classnames from "classnames";
import "../style/NodeTypeSelector.scss";

interface INodeTypeSelectorProps {
  excludeOptions?: NodeTypeIconType[]; // Any node types to be excluded from the list
  questionNumber?: string;
  nodeSelectedCallback: (option: NodeTypeIconType) => void;
}

const NodeTypeSelector = (props: INodeTypeSelectorProps) => {
  const [hoveringOverType, setHoveringOverType] = useState<
    NodeTypeIconType | undefined
  >(undefined);

  const shouldRenderNode = (nodeType: NodeTypeIconType) =>
    !props.excludeOptions?.includes(nodeType);

  return (
    <div className="add-btn-popup">
      <div
        className="add-btn-popup-main"
        onMouseLeave={() => setHoveringOverType(undefined)}
      >
        {shouldRenderNode(NodeTypeIconType.Section) && (
          <>
            <div
              onClick={() =>
                props.nodeSelectedCallback(NodeTypeIconType.Section)
              }
              onMouseEnter={() => setHoveringOverType(NodeTypeIconType.Section)}
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.Section}
                questionNumber={props.questionNumber}
              />{" "}
              Section
            </div>
          </>
        )}
        {shouldRenderNode(NodeTypeIconType.SelectRadio) && (
          <>
            <div
              onClick={() =>
                props.nodeSelectedCallback(NodeTypeIconType.SelectRadio)
              }
              onMouseEnter={() =>
                setHoveringOverType(NodeTypeIconType.SelectRadio)
              }
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.SelectRadio}
                questionNumber={props.questionNumber}
              />{" "}
              Single-select question
            </div>
          </>
        )}
        {shouldRenderNode(NodeTypeIconType.SelectCheckbox) && (
          <>
            <div
              onClick={() =>
                props.nodeSelectedCallback(NodeTypeIconType.SelectCheckbox)
              }
              onMouseEnter={() =>
                setHoveringOverType(NodeTypeIconType.SelectCheckbox)
              }
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.SelectCheckbox}
                questionNumber={props.questionNumber}
              />{" "}
              Multi-select question
            </div>
          </>
        )}
        {shouldRenderNode(NodeTypeIconType.InputText) && (
          <>
            <div
              onClick={() => {
                props.nodeSelectedCallback(NodeTypeIconType.InputText);
              }}
              onMouseEnter={() =>
                setHoveringOverType(NodeTypeIconType.InputText)
              }
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.InputText}
                questionNumber={props.questionNumber}
              />{" "}
              Text question
            </div>
          </>
        )}
        {shouldRenderNode(NodeTypeIconType.Upload) && (
          <>
            <div
              onClick={() => {
                props.nodeSelectedCallback(NodeTypeIconType.Upload);
              }}
              onMouseEnter={() => setHoveringOverType(NodeTypeIconType.Upload)}
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.Upload}
                questionNumber={props.questionNumber}
              />{" "}
              File upload
            </div>
          </>
        )}
        {shouldRenderNode(NodeTypeIconType.Risk) && (
          <>
            <div
              onClick={() => {
                props.nodeSelectedCallback(NodeTypeIconType.Risk);
              }}
              onMouseEnter={() => setHoveringOverType(NodeTypeIconType.Risk)}
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.Risk}
                questionNumber={""}
              />{" "}
              Identified risk
            </div>
          </>
        )}
        {shouldRenderNode(NodeTypeIconType.Info) && (
          <>
            <div
              onClick={() => {
                props.nodeSelectedCallback(NodeTypeIconType.Info);
              }}
              onMouseEnter={() => setHoveringOverType(NodeTypeIconType.Info)}
            >
              <NodeTypeIcon
                nodeType={NodeTypeIconType.Info}
                questionNumber={""}
              />{" "}
              Info
            </div>
          </>
        )}
      </div>
      {hoveringOverType !== undefined && (
        <div className={classnames("add-btn-popup-help")}>
          {hoveringOverType === NodeTypeIconType.Section && (
            <>
              <div className="heading">Section</div>
              Group questions together, provide more context, and add to the
              table of contents.
            </>
          )}
          {hoveringOverType === NodeTypeIconType.SelectRadio && (
            <>
              <div className="heading">Single-select question</div>
              Allow respondents to choose only one option from a set of
              predefined options.
            </>
          )}
          {hoveringOverType === NodeTypeIconType.SelectCheckbox && (
            <>
              <div className="heading">Multi-select question</div>
              Allow respondents to choose as many options as they wish from a
              set.
            </>
          )}
          {hoveringOverType === NodeTypeIconType.InputText && (
            <>
              <div className="heading">Text question</div>
              Provide a free-form text field for respondents to answer as they
              see fit.
            </>
          )}
          {hoveringOverType === NodeTypeIconType.Upload && (
            <>
              <div className="heading">File upload</div>
              Allows respondents to upload files and documents to support their
              answers.
            </>
          )}
          {hoveringOverType === NodeTypeIconType.Risk && (
            <>
              <div className="heading">Identified risk</div>
              Identify a risk and ask for compensating control information
              (optional).
            </>
          )}
          {hoveringOverType === NodeTypeIconType.Info && (
            <>
              <div className="heading">Info</div>
              Add additional information to be displayed inline in the
              questionnaire as a seperate block.
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(NodeTypeSelector);

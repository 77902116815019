import { FC } from "react";
import "../style/ResourcePanel.scss";

interface IResourcePanelProps {
  header: string;
  content?: React.ReactNode[];
}

const ResourcePanel: FC<IResourcePanelProps> = ({ header, content }) => {
  return (
    <>
      <div>
        <div className="panel-header">{header}</div>
        <div className="panel-inner">{content ? content : ""}</div>
      </div>
    </>
  );
};

export default ResourcePanel;

import { FC } from "react";

import "./AppVendorMonitored.scss";
import FilledIcon from "../../_common/components/core/FilledIcon";

export interface AppVendorMonitoredProps {
  isMonitored: boolean;
  showText: boolean;
}
const AppVendorMonitored: FC<AppVendorMonitoredProps> = ({
  isMonitored,
  showText,
}) => {
  const tickVersion = isMonitored ? (
    <div className="monitored">
      <FilledIcon iconClass="cr-icon-check" />
    </div>
  ) : (
    <div className="not-monitored">
      <FilledIcon iconClass="icon-x" />
    </div>
  );
  const textVersion = isMonitored ? <div>Yes</div> : <div>No</div>;

  return (
    <div className="app-vendor-monitored">
      {showText ? textVersion : tickVersion}
    </div>
  );
};

export default AppVendorMonitored;

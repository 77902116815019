import { useEffect, useLayoutEffect, useMemo, useState } from "react";

// useParseSnippet parses a snippet of text into an array of Line and an array of highlight mark DOM nodes.
// The input text is
// - split by newline characters
// - split by the keyword (via case-insensitive regex)
// - transformed into Line objects as JSX elements
// - wrapped in a mark element if the line contains the keyword
export function useParseSnippet(text: string, keywords: string[] | undefined) {
  return useMemo(() => parseSnippet(text, keywords), [text, keywords]);
}

// useSnippetHighlighter manages the current highlight index and the total number of highlights.
// "Highlights" are any <mark> elements in the snippetRef.
export function useSnippetHighlighter(
  snippetRef: React.RefObject<HTMLElement>,
  searchTerm?: string
) {
  const [currentHighlight, setCurrentHighlight] = useState<number>(0);
  const [highlightCount, setHighlightCount] = useState<number>(0);

  useLayoutEffect(() => {
    if (!snippetRef.current) return;

    const marks = snippetRef.current.getElementsByTagName("mark");

    setHighlightCount(marks.length);
    setCurrentHighlight(0);

    if (marks.length < 1) return;

    snippetRef.current.scrollTop =
      marks[0].offsetTop - snippetRef.current.offsetTop - 40;
  }, [snippetRef, searchTerm]);

  useEffect(() => {
    if (!snippetRef.current) return;

    const marks = snippetRef.current.getElementsByTagName("mark");

    if (marks.length < 1 || marks.length <= currentHighlight) return;

    snippetRef.current.scrollTop =
      marks[currentHighlight].offsetTop - snippetRef.current.offsetTop - 40;
  }, [snippetRef, currentHighlight, highlightCount]);

  const next = () => {
    setCurrentHighlight((c) => (c < highlightCount - 1 ? c + 1 : c));
  };
  const prev = () => {
    setCurrentHighlight((c) => (c <= 0 ? c : c - 1));
  };

  return {
    currentHighlight,
    highlightCount,
    next,
    prev,
  };
}

export function parseSnippet(text: string, keywords?: string[]) {
  if (!keywords || keywords.length === 0) {
    return [<>{text}</>];
  }

  const lines = text.split("\n");
  const kwRegex = new RegExp(`(${keywords.join("|")})`, "ig");

  return lines.map((line, lineI) => {
    const parts = line.split(kwRegex);
    return (
      <>
        {parts.map((part, i) =>
          i % 2 === 1 ? <mark key={`${part}_${lineI}_${i}`}>{part}</mark> : part
        )}
      </>
    );
  });
}

import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import UserBaseAPI from "../../api/userbase.api";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import "./AboutPermission.scss";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import {
  getPermissionCategoryDisplayInformation,
  getPermissionReadWriteDisplayInformation,
} from "../../helpers/scopes";

interface AboutPermissionProps {
  scopeExternalId: string;
}
const AboutPermission: FC<AboutPermissionProps> = ({ scopeExternalId }) => {
  // Get data
  const { data, isLoading } = UserBaseAPI.useGetUserBaseScopeSummaryV1Query({
    scopeExternalId,
  });
  const permission = data?.scope;

  // Display loading state (if required)
  if (isLoading) {
    return <></>;
  }

  // Display state (if required data is not available)
  if (!permission) {
    return <></>;
  }

  const { displayString: scopeCategoryToDisplay, color: scopeCategoryColor } =
    getPermissionCategoryDisplayInformation(permission.category);

  const { displayString: scopeReadWriteToDisplay, color: scopeReadWriteColor } =
    getPermissionReadWriteDisplayInformation(permission.isReadWrite);

  return (
    <ReportCard newStyles className="userbase-about-permission">
      <div className="header">
        <span>Permission details</span>
      </div>

      <div className={"content"}>
        <InfoTable className="info-table">
          <InfoTableRow
            label="Source"
            rowClass={"no-max-height bottom-border"}
            value={data.scope.name}
          />
          <InfoTableRow
            label="Category"
            rowClass={"no-max-height bottom-border"}
            value={
              <PillLabel color={scopeCategoryColor}>
                {scopeCategoryToDisplay}
              </PillLabel>
            }
          />
          <InfoTableRow
            label="Read/Write?"
            rowClass={"no-max-height bottom-border"}
            value={
              <PillLabel color={scopeReadWriteColor}>
                {scopeReadWriteToDisplay}
              </PillLabel>
            }
          />
        </InfoTable>
      </div>
    </ReportCard>
  );
};

export default AboutPermission;

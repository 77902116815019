import { FC } from "react";
import { permissionSummaryUrl, permissionsUrl } from "../../UserBaseAppRouter";
import { PermissionRouteParams } from "../../UserBaseNavItems";
import userbaseApi from "../../api/userbase.api";
import { useBack } from "../../../_common/types/router";
import UserBasePermissionHeader from "../../components/headers/UserBasePermissionHeader";
import AboutPermission from "../../components/permissions/AboutPermission";
import PermissionUsersOverview from "../../components/permissions/PermissionsUsersOverview";
import "./PermissionSummaryView.scss";
import PermissionAppsOverview from "../../components/permissions/PermissionAppsOverview";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const PermissionSummaryView: FC = ({}) => {
  const { backAction, backText } = useBack();
  const match = useDecodedRouteMatch<PermissionRouteParams>();

  const scopeExternalId = match.params.externalID;

  const { data } = userbaseApi.useGetUserBaseScopeSummaryV1Query({
    scopeExternalId,
  });

  const permission = data?.scope;

  if (!permission) {
    return <></>;
  }

  return (
    <div className="userbase-permission-summary">
      <UserBasePermissionHeader
        scopeExternalId={scopeExternalId}
        breadcrumbs={[
          { text: "Permissions", to: permissionsUrl },
          {
            text: permission.description ?? "Permission Description",
            to: permissionSummaryUrl(scopeExternalId),
          },
          {
            text: "Summary",
            to: permissionSummaryUrl(scopeExternalId),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Summary"}
      />

      <div className="two-grid no-stretch">
        <PermissionUsersOverview scopeExternalId={scopeExternalId} />
        <PermissionAppsOverview scopeExternalId={scopeExternalId} />
      </div>
      <div className="two-grid no-stretch">
        <AboutPermission scopeExternalId={scopeExternalId} />
      </div>
    </div>
  );
};

export default PermissionSummaryView;

import { useCallback, useMemo } from "react";
import BaseAPI, { vendorDataTag } from "../../_common/rtkQueryApi";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";
import {
  CheckWithSource,
  ControlDetail,
  ControlFamily,
  ControlState,
  ControlTotals,
  RiskControl,
  RiskDomain,
  SharedVendorDocument,
} from "../types/securityProfile";

export interface SecurityProfileResp {
  controlTotals: ControlTotals;
  controlStates: Record<string, ControlState>;
  riskCounts: Record<string, number>;
  documentChecks: Record<string, CheckWithSource[]>;
  scanningChecks: Record<string, VendorSummaryRisk>;
}

export interface GetSecurityProfileDocumentsResp {
  sharedDocuments: SharedVendorDocument[];
}

interface getVendorControlDataV1Req {
  vendorId: number;
  controlId: string;
}

interface getVendorControlDataV1Resp {
  control: ControlDetail;
}

const selectedControlsTag = "selectedControlsTag" as const;
const securityDocumentsTag = "securityDocumentsTag" as const;

const VendorSecurityProfileAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [selectedControlsTag, securityDocumentsTag],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendorControlData: builder.query<
      getVendorControlDataV1Resp,
      getVendorControlDataV1Req
    >({
      query: ({ vendorId, controlId }) => ({
        url: "/vendor/control/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
          control_id: controlId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result
          ? [
              {
                type: vendorDataTag,
                id: vendorId,
              },
            ]
          : [],
    }),

    getSecurityProfile: builder.query<
      SecurityProfileResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/security_profile/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result ? [{ type: vendorDataTag, id: vendorId }] : [],
    }),

    // TODO - not yet implemented in backend
    getSelectedControls: builder.query<
      {
        selectedControls: string[];
      },
      void
    >({
      query: () => ({
        url: "/vendor/securityprofile/settings/v1/",
        method: "GET",
      }),
      providesTags: [selectedControlsTag],
    }),

    // TODO - not yet implemented in backend
    setSelectedControls: builder.mutation<
      void,
      {
        selectedControls: string[];
      }
    >({
      query: (args) => ({
        url: "/vendor/securityprofile/settings/v1/",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      invalidatesTags: [selectedControlsTag],
    }),

    getRiskControlData: builder.query<{ domains: RiskDomain[] }, void>({
      query: () => ({
        url: "/controls/v1/",
        method: "GET",
      }),
    }),

    getSecurityDocuments: builder.query<
      GetSecurityProfileDocumentsResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/security_profile/document/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (_resp, error, { vendorId }) =>
        error ? [] : [{ type: securityDocumentsTag, id: vendorId }],
    }),

    // TODO - no endpoint for now
    uploadSecurityDocument: builder.mutation<void, { vendorId: number }>({
      query: () => ({
        url: "/vendor/security_profile/document/v1",
        method: "PUT",
      }),
      invalidatesTags: (_resp, error, { vendorId }) =>
        error
          ? []
          : [
              { type: "securityDocumentsTag", id: vendorId },
              { type: vendorDataTag, id: vendorId },
            ],
    }),
  }),
});

// useControlTree gets the classification/controls from the backend and provides
// some useful common helper methods for using the data
export const useControlTree = () => {
  const { data: controlTree, isLoading } =
    VendorSecurityProfileAPI.useGetRiskControlDataQuery();

  const [domainMap, familyMap, controlMap] = useMemo(() => {
    const domainMap: Record<string, RiskDomain> = {};
    const familyMap: Record<string, ControlFamily> = {};
    const controlMap: Record<string, RiskControl> = {};

    if (controlTree) {
      controlTree.domains.forEach((domain) => {
        domainMap[domain.id] = domain;

        domain.controlFamilies.forEach((cf) => {
          familyMap[cf.id] = cf;

          cf.controls.forEach((control) => {
            controlMap[control.id] = control;
          });
        });
      });
    }

    return [domainMap, familyMap, controlMap];
  }, [controlTree]);

  const getDomain = useCallback(
    (id: string): RiskDomain | undefined => {
      return domainMap[id];
    },
    [domainMap]
  );

  const getFamily = useCallback(
    (id: string): ControlFamily | undefined => {
      return familyMap[id];
    },
    [familyMap]
  );

  const getControl = useCallback(
    (id: string): RiskControl | undefined => {
      return controlMap[id];
    },
    [controlMap]
  );

  return {
    controlTree,
    isLoading,
    getDomain,
    getFamily,
    getControl,
  };
};

// useSecurityProfile
// gets the security profile data needed for a specific vendor and calculates
// the state of the security profile for use in the front end
// TODO - for now this only takes scanning risks into account
// export const useSecurityProfile = (vendorId: number) => {
//   const dispatch = useAppDispatch();
//
//   useEffect(() => {
//     dispatch(fetchVendorSummaryAndCloudscans(vendorId, false));
//   }, [vendorId]);
//
//   const { controlTree, isLoading } = useControlTree();
//
//   const risks = useVendorDataSelector(
//     (state) => state.summary?.result?.risks,
//     vendorId
//   );
//   const risksMap = useMemo(() => {
//     return risks?.reduce(
//       (acc, risk) => {
//         acc[risk.id] = risk;
//         return acc;
//       },
//       {} as Record<string, IRisk>
//     );
//   }, [risks]);
//
//   // calculate the state of each part of the tree. This starts at the check level and bubbles up the tree
//   const states = useMemo(() => {
//     const controlStates: Record<string, ControlState> = {};
//
//     if (controlTree && risksMap) {
//       controlTree.domains.forEach((domain) => {
//         let domainState = ControlState.Unknown
//
//         domain.controlFamilies.forEach(cf => {
//           let familyState = ControlState.Unknown;
//
//           cf.controls.forEach((control) => {
//             let controlState = ControlState.Unknown;
//
//             control.
//           })
//         })
//       })
//     }
//
//     return controlStates;
//   }, [controlTree, risksMap]);
// };

export default VendorSecurityProfileAPI;

import * as React from "react";
import { XTableCell } from "../../_common/components/core/XTable";
import XTable from "../../_common/components/core/XTable";
import { validateDomainName } from "../../_common/helpers";
import classnames from "classnames";
import { set as _set, get as _get, toInteger } from "lodash";
import GitHubLogo from "../../analyst_portal/images/github-64.png";
import AmazonLogo from "../../analyst_portal/images/amazon-64.png";
import GCELogo from "../../analyst_portal/images/gce-64.png";
import "../styles/OrgConfig.scss";
import DatePicker from "../../_common/components/DatePicker";
import Icon from "../../_common/components/core/Icon";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import {
  IOrgConfig,
  organisationUserLimitType,
} from "../../_common/types/organisations";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import TextField from "../../_common/components/TextField";

export const defaultOrgConfig: IOrgConfig = {
  mainHostname: "",
  isVerifiedVendor: false,
  isInternalOnly: false,
  breachSight: {
    enabled: true,
    websites: {
      enabled: true,
    },
    portfolios: {
      enabled: false, // When domain portfolios exit beta, change this
      numPortfolios: 50,
    },
    scoreHistory: true,
    dataExposures: {},
    subsidiaries: {
      enabled: false,
    },
    emailExposures: {
      enabled: false,
    },
    typosquatting: {
      enabled: false,
    },
  },
  vendorRisk: {
    enabled: true,
    defaultVendor: true,
    newVendors: true,
    numVendors: 50,
    vendorWatchLimitIsHard: true,
    numReports: 50,
    oneTimeReports: true,
    vendorLookupLimitIsHard: true,
    portfolios: {
      enabled: true,
      numPortfolios: 50,
    },
    additionalEvidence: {
      enabled: true,
    },
    vendorWebsites: {
      enabled: true,
    },
    vendorFourthParty: {
      enabled: true,
    },
    surveys: {
      enabled: true,
      scores: {
        enabled: true,
        include: true,
      },
    },
    scoreHistory: true,
    riskAssessments: {
      enabled: true,
    },
    questionnaireBuilder: {
      enabled: false,
    },
    relationshipQuestionnaireAutomation: false,
  },
  cyberResearch: {
    vendorManagement: {
      enabled: false,
    },
    vendorDataLeaks: {
      enabled: false,
    },
    breachNewsFeedDarkWeb: false,
    visibleVendorDataLeakUrl: false,
  },
  distributor: { enabled: false },
  customLogo: { enabled: false },
  auditLog: { enabled: false },
  defaultTexts: { enabled: false },
  customReporting: { enabled: false },
  newRolesCreation: { enabled: false },
  userLimit: 0,
  userLimitType: organisationUserLimitType.none,
  bulkData: {
    hostnames: {
      enabled: false,
    },
  },
};

export const defaultFreeOrgConfig: IOrgConfig = {
  mainHostname: "",
  isInternalOnly: false,
  isVerifiedVendor: false,
  breachSight: {
    enabled: false,
    websites: {
      enabled: false,
    },
    portfolios: {
      enabled: false,
      numPortfolios: 0,
    },
    scoreHistory: false,
    dataExposures: {},
    subsidiaries: {
      enabled: false,
    },
    emailExposures: {
      enabled: false,
    },
    typosquatting: {
      enabled: false,
    },
  },
  vendorRisk: {
    enabled: false,
    defaultVendor: false,
    newVendors: false,
    numVendors: 50,
    vendorWatchLimitIsHard: true,
    numReports: 50,
    oneTimeReports: true,
    vendorLookupLimitIsHard: true,
    portfolios: {
      enabled: false,
      numPortfolios: 0,
    },
    additionalEvidence: {
      enabled: false,
    },
    vendorWebsites: {
      enabled: false,
    },
    vendorFourthParty: {
      enabled: false,
    },
    surveys: {
      enabled: false,
      scores: {
        enabled: false,
        include: false,
      },
    },
    scoreHistory: false,
    riskAssessments: {
      enabled: false,
    },
    questionnaireBuilder: {
      enabled: false,
    },
    relationshipQuestionnaireAutomation: false,
  },
  cyberResearch: {
    vendorManagement: {
      enabled: false,
    },
    vendorDataLeaks: {
      enabled: false,
    },
    breachNewsFeedDarkWeb: false,
    visibleVendorDataLeakUrl: false,
  },
  distributor: { enabled: false },
  customLogo: { enabled: false },
  auditLog: { enabled: false },
  defaultTexts: { enabled: false },
  customReporting: { enabled: false },
  newRolesCreation: { enabled: false },
  userLimit: 0,
  userLimitType: organisationUserLimitType.none,
  bulkData: {
    hostnames: {
      enabled: false,
    },
  },
};

export interface OrgConfigProps {
  orgConfig: IOrgConfig;
  onChange: (orgConfig: IOrgConfig) => void;
  allowDistributor: boolean;
  isDistributor: boolean;
  isFreeAccount: boolean;
  enabled: boolean;
}

type sourceLogo = "github" | "s3" | "gcs";

export default class OrgConfig extends React.Component<OrgConfigProps> {
  // used to supply company logos (these live in the analyst_portal)
  static sourceLogos: Record<sourceLogo, any> = {
    github: GitHubLogo,
    s3: AmazonLogo,
    gcs: GCELogo,
  };

  getBreachSightSearchTermRows = () => {
    const breachSightSearchTerms: { name: sourceLogo }[] = [
      { name: "github" },
      { name: "s3" },
      { name: "gcs" },
    ];

    return breachSightSearchTerms.map((source) => {
      return {
        id: source.name,
        cells: [
          <XTableCell key="icon">
            {OrgConfig.sourceLogos[source.name] && (
              <img src={OrgConfig.sourceLogos[source.name]} alt={source.name} />
            )}
          </XTableCell>,
          <XTableCell key="name">{source.name}</XTableCell>,
          <XTableCell key="terms">
            <input
              type="text"
              name={source.name}
              disabled={!this.props.enabled}
              value={_get(
                this.props.orgConfig,
                `breachSight.dataExposures.${source.name}`,
                ""
              )}
              onChange={(e) =>
                this.updateOrgConfig(
                  `breachSight.dataExposures.${source.name}`,
                  e.target.value
                )
              }
            />
          </XTableCell>,
        ],
      };
    });
  };

  toggleOrgConfig = (...paths: string[]) => {
    return () => {
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        const current = _get(this.props.orgConfig, path, false);
        this.updateOrgConfig(path, !current);
      }
    };
  };

  applyRules = (
    orgConfig: IOrgConfig,
    path: string,
    val: boolean | string | number | string[]
  ) => {
    const rules: Record<string, (enabled: boolean) => void> = {
      "breachSight.enabled": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "vendorRisk.scoreHistory", true);
          _set(orgConfig, "breachSight.scoreHistory", true);
        } else {
          _set(orgConfig, "breachSight.typosquatting.enabled", false);
          _set(orgConfig, "breachSight.scoreHistory", false);
        }
      },
      "breachSight.websites.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "breachSight.portfolios.enabled", false);
          _set(orgConfig, "breachSight.typosquatting.enabled", false);
          _set(orgConfig, "breachSight.subsidiaries.enabled", false);
          _set(orgConfig, "breachSight.scoreHistory", false);
        }
      },
      "breachSight.portfolios.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "breachSight.portfolios.numPortfolios", 0);
        }
      },
      "breachSight.typosquatting.enabled": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "breachSight.enabled", true);
          _set(orgConfig, "breachSight.websites.enabled", true);
        }
      },
      "breachSight.dataExposures.enabled": (enabled: boolean) => {
        if (enabled) {
          const cadenceSet =
            _get(
              orgConfig,
              "breachSight.dataExposures.cadence.oneTime",
              false
            ) ||
            _get(
              orgConfig,
              "breachSight.dataExposures.cadence.monthly",
              false
            ) ||
            _get(orgConfig, "breachSight.dataExposures.cadence.yearly", false);
          if (!cadenceSet) {
            _set(orgConfig, "breachSight.dataExposures.cadence.oneTime", true);
          }
        }
      },
      "breachSight.dataExposures.cadence.oneTime": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "breachSight.dataExposures.cadence.monthly", false);
          _set(orgConfig, "breachSight.dataExposures.cadence.yearly", false);
        } else {
          _set(orgConfig, "breachSight.dataExposures.cadence.oneTime", true);
        }
      },
      "breachSight.dataExposures.cadence.monthly": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "breachSight.dataExposures.cadence.oneTime", false);
          _set(orgConfig, "breachSight.dataExposures.cadence.yearly", false);
        } else {
          _set(orgConfig, "breachSight.dataExposures.cadence.monthly", true);
        }
      },
      "breachSight.dataExposures.cadence.yearly": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "breachSight.dataExposures.cadence.oneTime", false);
          _set(orgConfig, "breachSight.dataExposures.cadence.monthly", false);
        } else {
          _set(orgConfig, "breachSight.dataExposures.cadence.yearly", true);
        }
      },
      "vendorRisk.newVendors": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "vendorRisk.vendorWebsites.enabled", true);
          _set(orgConfig, "vendorRisk.scoreHistory", true);
          _set(orgConfig, "vendorRisk.additionalEvidence.enabled", true);
        } else {
          _set(orgConfig, "cyberResearch.vendorManagement.enabled", false);
        }
      },
      "vendorRisk.defaultVendor": (enabled: boolean) => {
        if (enabled) {
          _set(orgConfig, "vendorRisk.vendorWebsites.enabled", true);
          _set(orgConfig, "vendorRisk.scoreHistory", true);
          _set(orgConfig, "vendorRisk.additionalEvidence.enabled", true);
        }
      },
      "vendorRisk.portfolios.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "vendorRisk.portfolios.numPortfolios", 0);
        }
      },
      "vendorRisk.vendorWebsites.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "vendorRisk.newVendors", false);
        }
      },
      "cyberResearch.vendorDataLeaks.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "cyberResearch.visibleVendorDataLeakUrl", false);
        }
      },
      "cyberResearch.vendorManagement.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "cyberResearch.vendorManagement.mva", false);
        }
      },
      "breachSight.emailExposures.enabled": (enabled: boolean) => {
        if (!enabled) {
          _set(orgConfig, "breachSight.emailExposures.domains", []);
          _set(orgConfig, "breachSight.emailExposures.constellaDomains", []);
        }
      },
    };

    if (rules[path]) {
      rules[path](val as boolean);
    }

    return orgConfig;
  };

  updateOrgConfig = (
    path: string,
    value: boolean | string | number | string[]
  ) => {
    const orgConfig = { ...this.props.orgConfig };
    _set(orgConfig, path, value);
    this.props.onChange(this.applyRules(orgConfig, path, value));
  };

  setVendorDataLeaksDomain = (index: number, value: string) => {
    const domains = _get(
      this.props.orgConfig,
      "cyberResearch.vendorDataLeaks.vendorDomains",
      []
    );
    if (domains.length - 1 < index) {
      for (let i = domains.length - 1; i < index; i++) {
        domains.push("");
      }
    }
    domains[index] = value;

    this.updateOrgConfig(
      "cyberResearch.vendorDataLeaks.vendorDomains",
      domains
    );
  };

  toggleVendorRisk = () => {
    const vendorRiskNewVendorsEnabled = _get(
      this.props.orgConfig,
      "vendorRisk.newVendors",
      false
    );
    const vendorRiskDefaultVendorsEnabled = _get(
      this.props.orgConfig,
      "vendorRisk.defaultVendor",
      false
    );
    const websitesEnabled = _get(
      this.props.orgConfig,
      "vendorRisk.vendorWebsites.enabled",
      false
    );
    const surveysEnabled = _get(
      this.props.orgConfig,
      "vendorRisk.surveys.enabled",
      false
    );
    const numVendors: number = _get(
      this.props.orgConfig,
      "vendorRisk.numVendors",
      0
    );
    const numReports: number = _get(
      this.props.orgConfig,
      "vendorRisk.numReports",
      0
    );

    const orgConfig = { ...this.props.orgConfig };

    _set(orgConfig, "vendorRisk.newVendors", !vendorRiskNewVendorsEnabled);
    _set(
      orgConfig,
      "vendorRisk.enabled",
      !vendorRiskNewVendorsEnabled || vendorRiskDefaultVendorsEnabled
    );

    if (!vendorRiskNewVendorsEnabled && !websitesEnabled) {
      _set(orgConfig, "vendorRisk.vendorWebsites.enabled", true);
    }
    // if switching on vendor risk and default the counts
    if (!vendorRiskNewVendorsEnabled && !websitesEnabled && !surveysEnabled) {
      _set(orgConfig, "vendorRisk.surveys.enabled", true);
      if (numVendors === 0 || numVendors === 0) {
        _set(orgConfig, "vendorRisk.numVendors", 50);
      }
      if (numReports === 0 || numReports === 0) {
        _set(orgConfig, "vendorRisk.numReports", 50);
      }
      _set(orgConfig, "vendorRisk.surveys.scores.enabled", true);
      _set(orgConfig, "vendorRisk.surveys.scores.include", true);
    }
    // if switching off vendor risk and default upguard is on, reset the counts
    if (vendorRiskNewVendorsEnabled && vendorRiskDefaultVendorsEnabled) {
      _set(orgConfig, "vendorRisk.numVendors", 0);
      _set(orgConfig, "vendorRisk.numReports", 0);
      _set(orgConfig, "vendorRisk.vendorLookupLimitIsHard", true);
      _set(orgConfig, "vendorRisk.vendorWatchLimitIsHard", true);
    }

    // also disable managed vendors
    if (
      vendorRiskNewVendorsEnabled &&
      _get(orgConfig, "cyberResearch.vendorManagement.enabled", false)
    ) {
      _set(orgConfig, "cyberResearch.vendorManagement.enabled", false);
    }
    this.props.onChange(orgConfig);
  };

  toggleUpGuardAsDefault = () => {
    const vendorRiskNewVendorsEnabled = _get(
      this.props.orgConfig,
      "vendorRisk.newVendors",
      false
    );
    const vendorRiskDefaultVendorsEnabled = _get(
      this.props.orgConfig,
      "vendorRisk.defaultVendor",
      false
    );

    const orgConfig = { ...this.props.orgConfig };

    _set(
      orgConfig,
      "vendorRisk.defaultVendor",
      !vendorRiskDefaultVendorsEnabled
    );
    _set(
      orgConfig,
      "vendorRisk.enabled",
      !vendorRiskDefaultVendorsEnabled || vendorRiskDefaultVendorsEnabled
    );

    // if switching on upguard as default and vendor risk is off, reset the counts
    if (!vendorRiskDefaultVendorsEnabled && !vendorRiskNewVendorsEnabled) {
      _set(orgConfig, "vendorRisk.numVendors", 0);
      _set(orgConfig, "vendorRisk.numReports", 0);
      _set(orgConfig, "vendorRisk.vendorLookupLimitIsHard", true);
      _set(orgConfig, "vendorRisk.vendorWatchLimitIsHard", true);
      _set(orgConfig, "vendorRisk.surveys.enabled", true);
      _set(orgConfig, "vendorRisk.surveys.scores.enabled", true);
      _set(orgConfig, "vendorRisk.surveys.scores.include", true);
    }
    this.props.onChange(orgConfig);
  };

  render() {
    const { breachSight, vendorRisk, cyberResearch, bulkData, isInternalOnly } =
      this.props.orgConfig;
    const oneTimeReports = _get(vendorRisk, "oneTimeReports", false);
    const vendorLookupLimitIsHard = _get(
      vendorRisk,
      "vendorLookupLimitIsHard",
      false
    );
    const oneTimeEditable = _get(vendorRisk, "oneTimeEditable", true);

    return (
      <div
        className={classnames("org-config", {
          "disabled-org": !this.props.enabled,
        })}
      >
        <ColorCheckbox
          label="BreachSight (Monitor yourself)"
          checked={breachSight.enabled || false}
          onClick={this.toggleOrgConfig("breachSight.enabled")}
          disabled={!this.props.enabled || this.props.isFreeAccount}
        />
        {breachSight.enabled && (
          <div className="indent">
            <ColorCheckbox
              label="Your web presence"
              checked={_get(breachSight, "websites.enabled", false)}
              onClick={this.toggleOrgConfig("breachSight.websites.enabled")}
              disabled={!this.props.enabled}
            />
            <ColorCheckbox
              label="Asset Portfolios"
              checked={_get(breachSight, "portfolios.enabled", false)}
              onClick={this.toggleOrgConfig("breachSight.portfolios.enabled")}
            />
            {_get(breachSight, "portfolios.enabled", false) && (
              <div className="one-time">
                <TextField
                  className="one-time-edit"
                  type="number"
                  minNumber={0}
                  disabled={!this.props.enabled}
                  name="numPortfolios"
                  required
                  placeholder="Portfolio limit"
                  value={`${_get(breachSight, "portfolios.numPortfolios", 0)}`}
                  onChanged={(val) =>
                    this.updateOrgConfig(
                      "breachSight.portfolios.numPortfolios",
                      parseInt(val, 10) || 0
                    )
                  }
                />
                <div className="halfspacer" />
                <span>Portfolio limit</span>
                <div className="halfspacer" />
                <SidePopupV2
                  className="help-icon"
                  text={"Enter 0 for unlimited portfolios."}
                  noWrap
                >
                  <Icon name="info" />
                </SidePopupV2>
              </div>
            )}
            <ColorCheckbox
              disabled={!this.props.enabled}
              label="Data Leaks"
              checked={_get(breachSight, "dataExposures.enabled", false)}
              onClick={this.toggleOrgConfig(
                "breachSight.dataExposures.enabled"
              )}
            />
            {_get(breachSight, "dataExposures.enabled", false) && (
              <div className="indent">
                <label htmlFor="breachSightDueDate">
                  When is the first BreachSight review due?
                </label>
                <DatePicker
                  name="breachSightDueDate"
                  disabled={!this.props.enabled}
                  value={_get(breachSight, "dataExposures.dueDate", "")}
                  onChange={(e) =>
                    this.updateOrgConfig(
                      "breachSight.dataExposures.dueDate",
                      e.target.value
                    )
                  }
                />
                <label htmlFor="breachSightCadence">BreachSight Cadence</label>
                <div className="radiolist">
                  <ColorCheckbox
                    radio
                    label="One-time"
                    disabled={!this.props.enabled}
                    checked={_get(
                      breachSight,
                      "dataExposures.cadence.oneTime",
                      false
                    )}
                    onClick={this.toggleOrgConfig(
                      "breachSight.dataExposures.cadence.oneTime"
                    )}
                  />
                  <div className="spacer" />
                  <ColorCheckbox
                    radio
                    label="Monthly"
                    disabled={!this.props.enabled}
                    checked={_get(
                      breachSight,
                      "dataExposures.cadence.monthly",
                      false
                    )}
                    onClick={this.toggleOrgConfig(
                      "breachSight.dataExposures.cadence.monthly"
                    )}
                  />
                  <div className="spacer" />
                  <ColorCheckbox
                    radio
                    label="Yearly"
                    disabled={!this.props.enabled}
                    checked={_get(
                      breachSight,
                      "dataExposures.cadence.yearly",
                      false
                    )}
                    onClick={this.toggleOrgConfig(
                      "breachSight.dataExposures.cadence.yearly"
                    )}
                  />
                  <div className="spacer" />
                </div>
                <label htmlFor="SearchTerms">BreachSight Search Terms</label>
                <div className="search-terms">
                  <XTable
                    columnHeaders={[]}
                    rows={this.getBreachSightSearchTermRows()}
                  />
                </div>
              </div>
            )}
            <ColorCheckbox
              label="Identity Breaches"
              disabled={!this.props.enabled}
              checked={_get(breachSight, "emailExposures.enabled", false)}
              onClick={this.toggleOrgConfig(
                "breachSight.emailExposures.enabled"
              )}
              testId={"identity-breaches"}
            />
            {_get(breachSight, "emailExposures.enabled", false) && (
              <div className="indent">
                <label htmlFor="hibpDomains">
                  Enter the email domains (comma separated) to be monitored on
                  HIBP.
                  <div className="indent">
                    Do not user example.com as it is rejected by HIBP, use
                    testaccount.com instead for a demo.
                  </div>
                  {isInternalOnly && (
                    <div className="indent">
                      N.B. When testing Identity Breaches on internal accounts,
                      usage should be restricted to the following domains:
                      upguard.com, scriptrock.com, testaccount.com.
                    </div>
                  )}
                </label>
                <input
                  type="text"
                  name="emailExposureDomains"
                  value={_get(breachSight, "emailExposures.domains", []).join(
                    ","
                  )}
                  disabled={!this.props.enabled}
                  onChange={(e) =>
                    this.updateOrgConfig(
                      "breachSight.emailExposures.domains",
                      e.target.value
                        .trim()
                        .split(",")
                        .map((domain) => domain.trim())
                    )
                  }
                />
                <>
                  <label htmlFor="constellaDomains">
                    Enter the email domains (comma separated) to be monitored
                    for botnet breaches on Constella.
                    <div className="indent">
                      Do not user example.com as it is rejected, use
                      testaccount.com instead for a demo.
                    </div>
                    {isInternalOnly && (
                      <div className="indent">
                        N.B. When testing Identity Breaches on internal
                        accounts, usage should be restricted to the following
                        domains: upguard.com, scriptrock.com, testaccount.com.
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    name="constellaDomains"
                    value={_get(
                      breachSight,
                      "emailExposures.constellaDomains",
                      []
                    ).join(",")}
                    disabled={!this.props.enabled}
                    onChange={(e) =>
                      this.updateOrgConfig(
                        "breachSight.emailExposures.constellaDomains",
                        e.target.value
                          .trim()
                          .split(",")
                          .map((domain) => domain.trim())
                      )
                    }
                  />
                </>
              </div>
            )}
            <ColorCheckbox
              label="Typosquatting"
              checked={_get(breachSight, "typosquatting.enabled", false)}
              onClick={this.toggleOrgConfig(
                "breachSight.typosquatting.enabled"
              )}
              disabled={!this.props.enabled}
            />
            <ColorCheckbox
              label="Subsidiaries"
              checked={_get(breachSight, "subsidiaries.enabled", false)}
              onClick={this.toggleOrgConfig("breachSight.subsidiaries.enabled")}
              disabled={!this.props.enabled}
            />
            <ColorCheckbox
              label="Score history"
              checked={_get(breachSight, "scoreHistory", false)}
              onClick={this.toggleOrgConfig(
                "vendorRisk.scoreHistory",
                "breachSight.scoreHistory"
              )}
              disabled={!this.props.enabled}
            />
          </div>
        )}

        <div
          className={classnames("product-family", {
            "disabled-org": !this.props.enabled,
          })}
        >
          <ColorCheckbox
            label="Include Upguard as a default Vendor"
            checked={_get(vendorRisk, "defaultVendor", false)}
            onClick={this.toggleUpGuardAsDefault}
            disabled={!this.props.enabled || this.props.isFreeAccount}
          />
          <div
            className={classnames("left-align", {
              "disabled-org": !this.props.enabled,
            })}
          >
            {
              "(Note that for breachsight-only accounts, this will enable vendor risk with Upguard as a single vendor)."
            }
          </div>
        </div>

        <div
          className={classnames("product-family", {
            "disabled-org": !this.props.enabled,
          })}
        >
          <ColorCheckbox
            label="Vendor risk (Monitor your vendors)"
            checked={_get(vendorRisk, "newVendors", false)}
            onClick={this.toggleVendorRisk}
            disabled={!this.props.enabled || this.props.isFreeAccount}
          />
          {_get(vendorRisk, "newVendors", false) && (
            <div className="indent">
              <label htmlFor="watchLimit">Monitored vendors</label>
              <div className="radiolist">
                <ColorCheckbox
                  radio
                  label="Limited"
                  checked={_get(vendorRisk, "vendorWatchLimitIsHard", false)}
                  onClick={this.toggleOrgConfig(
                    "vendorRisk.vendorWatchLimitIsHard"
                  )}
                  disabled={!this.props.enabled}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="Unlimited"
                  checked={!_get(vendorRisk, "vendorWatchLimitIsHard", false)}
                  onClick={this.toggleOrgConfig(
                    "vendorRisk.vendorWatchLimitIsHard"
                  )}
                  disabled={!this.props.enabled}
                />
              </div>
              {_get(vendorRisk, "vendorWatchLimitIsHard", false) && (
                <TextField
                  type="number"
                  minNumber={0}
                  name="watchLimit"
                  required
                  disabled={!this.props.enabled}
                  placeholder="Total watched vendors limit"
                  value={`${_get(vendorRisk, "numVendors", 0)}`}
                  onChanged={(val) =>
                    this.updateOrgConfig(
                      "vendorRisk.numVendors",
                      parseInt(val, 10) || 0
                    )
                  }
                />
              )}
              <label htmlFor="reportLimit">Ad-hoc Reports</label>
              <div className="radiolist">
                <ColorCheckbox
                  radio
                  label="Monthly Ad-hoc Limit"
                  checked={vendorLookupLimitIsHard && !oneTimeReports}
                  onClick={() => {
                    this.updateOrgConfig("vendorRisk.oneTimeReports", false);
                    this.updateOrgConfig(
                      "vendorRisk.vendorLookupLimitIsHard",
                      true
                    );
                  }}
                  disabled={!this.props.enabled}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="One-time Reports"
                  checked={vendorLookupLimitIsHard && oneTimeReports}
                  onClick={() => {
                    this.updateOrgConfig("vendorRisk.oneTimeReports", true);
                    this.updateOrgConfig(
                      "vendorRisk.vendorLookupLimitIsHard",
                      true
                    );
                  }}
                  disabled={!this.props.enabled}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="Unlimited"
                  checked={!vendorLookupLimitIsHard}
                  onClick={this.toggleOrgConfig(
                    "vendorRisk.vendorLookupLimitIsHard"
                  )}
                  disabled={!this.props.enabled}
                />
              </div>
              {vendorLookupLimitIsHard && !oneTimeReports && (
                <div className="one-time">
                  <TextField
                    className="monthly-report-edit"
                    type="number"
                    minNumber={0}
                    name="reportLimit"
                    required
                    disabled={!this.props.enabled}
                    placeholder="Total monthly adhoc report limit"
                    value={`${_get(vendorRisk, "numReports", 0)}`}
                    onChanged={(val) =>
                      this.updateOrgConfig(
                        "vendorRisk.numReports",
                        parseInt(val, 10) || 0
                      )
                    }
                  />
                  <div className="halfspacer" />
                  <span>Ad-hoc reports per month</span>
                </div>
              )}
              {vendorLookupLimitIsHard && oneTimeReports && (
                <div className="one-time">
                  <TextField
                    className="one-time-edit"
                    type="number"
                    minNumber={0}
                    disabled={!oneTimeEditable || !this.props.enabled}
                    name="reportLimit"
                    required
                    placeholder="One-time reports remaining"
                    value={`${_get(vendorRisk, "numReports", 0)}`}
                    onChanged={(val) =>
                      this.updateOrgConfig(
                        "vendorRisk.numReports",
                        parseInt(val, 10) || 0
                      )
                    }
                  />
                  <div className="halfspacer" />
                  <span>One-time Reports remaining</span>
                  <div className="halfspacer" />
                  <SidePopupV2
                    className="help-icon"
                    text={
                      "Represents the current balance of one-time report purchases for this account. "
                    }
                  >
                    <Icon name="info" />
                  </SidePopupV2>
                </div>
              )}
              <ColorCheckbox
                label="Vendor Portfolios"
                checked={_get(vendorRisk, "portfolios.enabled", false)}
                disabled={!this.props.enabled}
                onClick={this.toggleOrgConfig("vendorRisk.portfolios.enabled")}
              />
              {_get(vendorRisk, "portfolios.enabled", false) && (
                <div className="one-time">
                  <TextField
                    className="one-time-edit"
                    type="number"
                    minNumber={0}
                    disabled={!this.props.enabled}
                    name="numPortfolios"
                    required
                    placeholder="Portfolio limit"
                    value={`${_get(vendorRisk, "portfolios.numPortfolios", 0)}`}
                    onChanged={(val) =>
                      this.updateOrgConfig(
                        "vendorRisk.portfolios.numPortfolios",
                        parseInt(val, 10) || 0
                      )
                    }
                  />
                  <div className="halfspacer" />
                  <span>Portfolio limit</span>
                  <div className="halfspacer" />
                  <SidePopupV2
                    className="help-icon"
                    text={"Enter 0 for unlimited portfolios."}
                  >
                    <Icon name="info" />
                  </SidePopupV2>
                </div>
              )}
              <ColorCheckbox
                label="Vendor's web presence"
                checked={_get(vendorRisk, "vendorWebsites.enabled", false)}
                disabled={!this.props.enabled}
                onClick={this.toggleOrgConfig(
                  "vendorRisk.vendorWebsites.enabled"
                )}
              />
              <ColorCheckbox
                label="Security Questionnaires"
                checked={_get(vendorRisk, "surveys.enabled", false)}
                disabled={!this.props.enabled}
                onClick={this.toggleOrgConfig("vendorRisk.surveys.enabled")}
              />

              {_get(vendorRisk, "surveys.enabled", false) && (
                <div className="indent">
                  <ColorCheckbox
                    label="Questionnaire Builder"
                    checked={_get(
                      vendorRisk,
                      "questionnaireBuilder.enabled",
                      false
                    )}
                    disabled={!this.props.enabled}
                    onClick={this.toggleOrgConfig(
                      "vendorRisk.questionnaireBuilder.enabled"
                    )}
                  />
                  <ColorCheckbox
                    label="Score Security Questionnaires"
                    checked={_get(vendorRisk, "surveys.scores.enabled", false)}
                    disabled={!this.props.enabled}
                    onClick={this.toggleOrgConfig(
                      "vendorRisk.surveys.scores.enabled"
                    )}
                  />
                  {_get(vendorRisk, "surveys.scores.enabled", false) && (
                    <div className="indent">
                      <ColorCheckbox
                        label="Include questionnaire scores in vendor scores"
                        disabled={!this.props.enabled}
                        checked={_get(
                          vendorRisk,
                          "surveys.scores.include",
                          false
                        )}
                        onClick={this.toggleOrgConfig(
                          "vendorRisk.surveys.scores.include"
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              <ColorCheckbox
                label="Risk Assessments"
                checked={_get(vendorRisk, "riskAssessments.enabled", false)}
                disabled={!this.props.enabled}
                onClick={this.toggleOrgConfig(
                  "vendorRisk.riskAssessments.enabled"
                )}
              />
              <ColorCheckbox
                label="Fourth party risks"
                checked={_get(vendorRisk, "vendorFourthParty.enabled", false)}
                onClick={this.toggleOrgConfig(
                  "vendorRisk.vendorFourthParty.enabled"
                )}
                disabled={!this.props.enabled}
              />
              <ColorCheckbox
                label="Score history"
                checked={_get(vendorRisk, "scoreHistory", false)}
                disabled={!this.props.enabled}
                onClick={this.toggleOrgConfig(
                  "vendorRisk.scoreHistory",
                  "breachSight.scoreHistory"
                )}
              />
              <ColorCheckbox
                label="Additional evidence"
                checked={_get(vendorRisk, "additionalEvidence.enabled", false)}
                onClick={this.toggleOrgConfig(
                  "vendorRisk.additionalEvidence.enabled"
                )}
                disabled={!this.props.enabled}
              />
              <div className="one-time">
                <span>Attributes limit</span>
                <div className="halfspacer" />
                <TextField
                  className="one-time-edit"
                  type="number"
                  minNumber={0}
                  disabled={!this.props.enabled}
                  name="numAttributes"
                  required
                  placeholder="Attributes limit"
                  value={`${_get(vendorRisk, "attributesLimit", 100)}`}
                  onChanged={(val) =>
                    this.updateOrgConfig(
                      "vendorRisk.attributesLimit",
                      parseInt(val, 10) || 0
                    )
                  }
                />
                <div className="halfspacer" />
                <SidePopupV2
                  className="help-icon"
                  text={"Enter 0 for unlimited attributes."}
                  noWrap
                >
                  <Icon name="info" />
                </SidePopupV2>
              </div>
              <ColorCheckbox
                label="Relationship questionnaire automation"
                checked={_get(
                  vendorRisk,
                  "relationshipQuestionnaireAutomation",
                  false
                )}
                disabled={!this.props.enabled}
                onClick={this.toggleOrgConfig(
                  "vendorRisk.relationshipQuestionnaireAutomation"
                )}
              />
            </div>
          )}
        </div>
        <div className="product-family">
          <ColorCheckbox
            label="Custom Logo (co-branding)"
            disabled={!this.props.enabled || this.props.isFreeAccount}
            checked={_get(this.props.orgConfig, "customLogo.enabled", false)}
            onClick={this.toggleOrgConfig("customLogo.enabled")}
          />
          <ColorCheckbox
            label="Audit Log"
            disabled={!this.props.enabled || this.props.isFreeAccount}
            checked={_get(this.props.orgConfig, "auditLog.enabled", false)}
            onClick={this.toggleOrgConfig("auditLog.enabled")}
          />
          <ColorCheckbox
            label="Templates"
            disabled={!this.props.enabled || this.props.isFreeAccount}
            checked={_get(this.props.orgConfig, "defaultTexts.enabled", false)}
            onClick={this.toggleOrgConfig("defaultTexts.enabled")}
          />
          <ColorCheckbox
            label="Custom Reporting"
            disabled={!this.props.enabled || this.props.isFreeAccount}
            checked={_get(
              this.props.orgConfig,
              "customReporting.enabled",
              false
            )}
            onClick={this.toggleOrgConfig("customReporting.enabled")}
          />
          {_get(this.props.orgConfig, "newRolesCreation.displayed", true) && (
            <ColorCheckbox
              label="Create and manage roles"
              disabled={!this.props.enabled || this.props.isFreeAccount}
              checked={_get(
                this.props.orgConfig,
                "newRolesCreation.enabled",
                false
              )}
              onClick={this.toggleOrgConfig("newRolesCreation.enabled")}
            />
          )}
        </div>
        <>
          <div className={"vertspacer"} />
          <label htmlFor="tpvmLimit">Cyber Research</label>

          {_get(vendorRisk, "newVendors", false) && (
            <div className="product-family">
              <ColorCheckbox
                label="Managed Vendors"
                disabled={!this.props.enabled || this.props.isFreeAccount}
                checked={_get(cyberResearch, "vendorManagement.enabled", false)}
                onClick={this.toggleOrgConfig(
                  "cyberResearch.vendorManagement.enabled"
                )}
              />
            </div>
          )}
          {_get(cyberResearch, "vendorManagement.enabled", false) && (
            <div className={"indent"}>
              <ColorCheckbox
                label={"MVA"}
                disabled={!this.props.enabled || this.props.isFreeAccount}
                checked={_get(cyberResearch, "vendorManagement.mva", false)}
                onClick={this.toggleOrgConfig(
                  "cyberResearch.vendorManagement.mva"
                )}
              />
            </div>
          )}
          {_get(cyberResearch, "vendorManagement.enabled", false) && (
            <div className="indent">
              Managed vendor licenses are now managed in the managed vendor
              license sub menu in the main table
            </div>
          )}
          {
            // Only show the vendor data leaks options if it's already enabled on the account
            // we don't want this being enabled by accident
            _get(cyberResearch, "vendorDataLeaks.enabled", false) && (
              <>
                <div className="product-family">
                  <ColorCheckbox
                    label="Vendor Data Leaks"
                    disabled={!this.props.enabled || this.props.isFreeAccount}
                    checked={_get(
                      cyberResearch,
                      "vendorDataLeaks.enabled",
                      false
                    )}
                    onClick={this.toggleOrgConfig(
                      "cyberResearch.vendorDataLeaks.enabled"
                    )}
                  />
                </div>
                {_get(cyberResearch, "vendorDataLeaks.enabled", false) && (
                  <div className="indent">
                    <label htmlFor="vendorDataLeaksLimit">
                      Total vendor limit
                    </label>
                    <div className="radiolist">
                      <TextField
                        type="number"
                        minNumber={0}
                        name="vendorDataLeaksLimit"
                        required
                        disabled={!this.props.enabled}
                        placeholder="Total vendor limit"
                        value={`${_get(
                          cyberResearch,
                          "vendorDataLeaks.limit",
                          0
                        )}`}
                        onChanged={(val) =>
                          this.updateOrgConfig(
                            "cyberResearch.vendorDataLeaks.limit",
                            parseInt(val, 10) || 0
                          )
                        }
                      />
                    </div>
                    <label htmlFor="vendorDataLeaksContractStart">
                      Vendor Data Leaks Contract Start
                    </label>
                    <div className="radiolist">
                      <DatePicker
                        name="vendorDataLeaksContractStart"
                        disabled={!this.props.enabled}
                        value={_get(
                          cyberResearch,
                          "vendorDataLeaks.contractStart",
                          ""
                        )}
                        onChange={(e) =>
                          this.updateOrgConfig(
                            "cyberResearch.vendorDataLeaks.contractStart",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <label htmlFor="vendorDataLeaksContractStart">
                      Monitored vendor primary domains
                    </label>
                    <div className="inputlist">
                      {new Array(
                        parseInt(
                          _get(cyberResearch, "vendorDataLeaks.limit", 0)
                        ) || 0
                      )
                        .fill(null)
                        .map((_, i) => (
                          <input
                            key={i}
                            type="text"
                            placeholder="example.com"
                            value={_get(
                              cyberResearch,
                              ["vendorDataLeaks", "vendorDomains", i],
                              ""
                            )}
                            onChange={(e) =>
                              this.setVendorDataLeaksDomain(i, e.target.value)
                            }
                          />
                        ))}
                    </div>
                    <ColorCheckbox
                      label="Show vendor leak URL to customer"
                      disabled={!this.props.enabled || this.props.isFreeAccount}
                      checked={_get(
                        cyberResearch,
                        "visibleVendorDataLeakUrl",
                        false
                      )}
                      onClick={this.toggleOrgConfig(
                        "cyberResearch.visibleVendorDataLeakUrl"
                      )}
                    />
                  </div>
                )}
              </>
            )
          }
          <div className="product-family">
            <ColorCheckbox
              label="Dark Web content in breach news feed"
              disabled={!this.props.enabled || this.props.isFreeAccount}
              checked={_get(cyberResearch, "breachNewsFeedDarkWeb", false)}
              onClick={this.toggleOrgConfig(
                "cyberResearch.breachNewsFeedDarkWeb"
              )}
            />
          </div>
        </>
        <>
          <div className={"vertspacer"} />
          <label htmlFor="tpvmLimit">Bulk Data Access</label>

          <div className="product-family">
            <ColorCheckbox
              label="Hostnames"
              disabled={!this.props.enabled || this.props.isFreeAccount}
              checked={_get(bulkData, "hostnames.enabled", false)}
              onClick={this.toggleOrgConfig("bulkData.hostnames.enabled")}
            />
          </div>
          {_get(bulkData, "hostnames.enabled", false) && (
            <div className="indent">
              <label htmlFor="bulkHostnamesLimit">
                Maximum number of hostnames monitored
              </label>
              <div className="radiolist">
                <TextField
                  type="number"
                  minNumber={1}
                  name="bulkHostnamesLimit"
                  required
                  disabled={!this.props.enabled}
                  placeholder="Maximum number of hostnames"
                  value={`${_get(bulkData, "hostnames.limit", 0)}`}
                  onChanged={(val) =>
                    this.updateOrgConfig(
                      "bulkData.hostnames.limit",
                      parseInt(val, 10) || 0
                    )
                  }
                />
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}

interface UserLimitConfigProps {
  userLimit: number;
  setUserLimit: (n: number) => void;
  userLimitType: organisationUserLimitType;
  setUserLimitType: (type: organisationUserLimitType) => void;
}

export const UserLimitConfig: React.VFC<UserLimitConfigProps> = (props) => {
  return (
    <>
      <label htmlFor={"userLimit"}>User limit</label>
      <div className={"radiolist vert"}>
        <ColorCheckbox
          radio
          label={"No user limit"}
          checked={props.userLimitType === organisationUserLimitType.none}
          helpPopup={
            "No limits on the number of users will be shown or enforced in this account."
          }
          onClick={() => props.setUserLimitType(organisationUserLimitType.none)}
        />
        <ColorCheckbox
          radio
          label={"Hard user limits"}
          checked={props.userLimitType === organisationUserLimitType.hard}
          helpPopup={
            "The user limit will be visible to the account and they will not be able to add users that would exceed the limit."
          }
          onClick={() => props.setUserLimitType(organisationUserLimitType.hard)}
        />
        {props.userLimitType === organisationUserLimitType.hard && (
          <TextField
            className={"user-limit-input"}
            type={"number"}
            name={"userLimit"}
            required
            value={props.userLimit.toString()}
            onChanged={(val) => props.setUserLimit(toInteger(val))}
          />
        )}
      </div>
    </>
  );
};

export const validateOrgConfig = (orgConfig: IOrgConfig) => {
  const { breachSight, vendorRisk } = orgConfig;

  vendorRisk.enabled = vendorRisk.newVendors || vendorRisk.defaultVendor;

  if (orgConfig.mainHostname.trim() === "") {
    return "Company domain is mandatory";
  }

  if (!validateDomainName(orgConfig.mainHostname.trim())) {
    return "Company domain must be a valid domain name. Eg. domain.com";
  }

  if (
    breachSight.enabled &&
    _get(breachSight, "dataExposures.enabled", false) &&
    _get(breachSight, "dataExposures.dueDate", "") === ""
  ) {
    return "The due date of the first BreachSight scan must be entered";
  }

  if (breachSight.enabled && breachSight.emailExposures?.enabled) {
    const hibpDomains = breachSight.emailExposures?.domains || [];
    const constellaDomains = breachSight.emailExposures?.constellaDomains || [];
    if (hibpDomains.length === 0 && constellaDomains.length === 0) {
      return "At least one email domain must be provided to enable email exposures";
    }
    const allDomains = [...hibpDomains, ...constellaDomains];

    const invalidDomains: string[] = [];
    allDomains
      .filter((domain: string) => domain.length > 0)
      .forEach((domain: string) => {
        if (!validateDomainName(domain.trim())) {
          invalidDomains.push(domain);
        }
      });

    if (invalidDomains.length > 0) {
      return `Invalid domains for identity breaches: ${invalidDomains.join(
        ","
      )}`;
    }
  }

  return "";
};

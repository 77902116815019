import { nodeToNodeTypeIconType, NodeTypeIconType } from "./NodeTypeIcon";

import { memo } from "react";
import "../style/QuestionnaireBuilderHelpGuide.scss";
import Button from "../../_common/components/core/Button";
import { appConnect } from "../../_common/types/reduxHooks";

const sectionNodeHelpGuide = (
  <>
    <h2>Sections</h2>
    <p>
      Sections are a simple tool to create a group with sub-questions inside.
      Use it to organise your questionnaire into chapters or to segregate
      questions by theme. Sections are also used to create the table of contents
      if you choose to include one.
    </p>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const inputTextNodeHelpGuide = (
  <>
    <h2>Text questions</h2>
    <p>
      Give respondents a free-form text field to answer as they see fit. This is
      useful for situations where you want more detail or a qualitative response
      is required. For example, you may want to give respondents the option to
      provide additional information about their security program that you
      hadn&apos;t asked about.
    </p>
    <h3>Example question</h3>
    <p>
      Is there anything else you want us to know about your security program?
    </p>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const selectCheckboxNodeHelpGuide = (
  <>
    <h2>Multi-select questions</h2>
    <p>
      Allows respondents to choose as many options as they wish from a
      predefined set of answers. For example, you may want to ask a respondent
      what security controls they have in place and then raise a risk for any
      controls they are missing.
    </p>
    <h3>Example question</h3>
    <p>
      Select the controls you currently maintain as elements of your information
      security and privacy program:
    </p>
    <ul>
      <li>
        An external policy or notice to the public, users, or customers,
        describing how you protect the security and privacy of data
      </li>
      <li>
        Written internal policies, guidelines, and documented practices for the
        safe handling and protection of data
      </li>
      <li>Internal audits of the security and privacy program</li>
    </ul>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const selectRadioNodeHelpGuide = (
  <>
    <h2>Single-select questions</h2>
    <p>
      Allows respondents to choose a single option from a predefined set of
      mutually exclusive answers. These questions are great if you want to ask a
      simple question and raise a risk based on a respondent’s answer. A simple
      example would be a &apos;yes&apos; or &apos;no&apos; question.
    </p>
    <h3>Example question</h3>
    <p>Are you using unit tests or similar methods?</p>
    <ul>
      <li>Yes</li>
      <li>No</li>
    </ul>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const uploadNodeHelpGuide = (
  <>
    <h2>File upload</h2>
    <p>
      Allows respondents to upload pdf, doc, docx, jpg, png, xlsx, csv, or pptx
      documents. Each document can be up to 10 MB.
    </p>
    <h3>Example question</h3>
    <p>Please provide any additional independent audit reports.</p>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const riskNodeHelpGuide = (
  <>
    <h2>Identified risks</h2>
    <p>
      Identified risks allow you to automatically raise a risk based on an
      answer to a question or multiple questions. To do this, you&apos;ll need
      to add conditional visibility to the risk. If the identified risk has a
      potential compensating control, you can provide the respondent with the
      chance to provide additional information on how they mitigate it.
    </p>
    <h3>Example risk</h3>
    <p>
      Security policies are an important tool for establishing authoritative
      security requirements and enforcing them throughout the company. Policies
      form the basis of any security program.
    </p>
    <p>
      If you have compensating controls in place or feel that this issue does
      not constitute a risk in your specific circumstances, please explain
      below. If you&apos;re working to address this issue, include an estimate
      of when it will be resolved:
    </p>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const rootNodeHelpGuide = (
  <>
    <h2>Questionnaire introduction</h2>
    <p>
      This is the name and description of the questionnaire and the first thing
      questionnaire respondents will see. If your account has co-branding
      enabled, you can also upload your logo in Account Settings.
    </p>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

const defaultHelpGuide = (
  <>
    <h2>Welcome to the questionnaire builder</h2>
    <p>
      The questionnaire builder helps you create custom questionnaires that can
      be sent through the UpGuard platform. You can start from scratch or use
      one of our growing library of questionnaires as a starting point and then
      adjust it to cater for your needs.
    </p>
    <h3>How to use the questionnaire builder</h3>
    <p>
      Click the + button to create a new question. You&apos;ll be prompted to
      select from a range of question types designed to cater to different
      circumstances. For additional information on how to use the questionnaire
      builder click the link below.
    </p>
    <Button
      tertiary
      onClick={() =>
        window.open(
          "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
        )
      }
    >
      View support article
    </Button>
  </>
);

interface QuestionnaireBuilderHelpGuideOwnProps {
  surveyId: string;
  customDefaultHelpGuide?: JSX.Element;
}

interface QuestionnaireBuilderHelpGuideConnectedProps {
  isRoot: boolean;
  focusedNodeType?: NodeTypeIconType;
}

const QuestionnaireBuilderHelpGuide = (
  props: QuestionnaireBuilderHelpGuideOwnProps &
    QuestionnaireBuilderHelpGuideConnectedProps
) => {
  let inner;

  if (props.isRoot) {
    inner = rootNodeHelpGuide;
  } else {
    switch (props.focusedNodeType) {
      case NodeTypeIconType.Section:
        inner = sectionNodeHelpGuide;
        break;
      case NodeTypeIconType.InputText:
        inner = inputTextNodeHelpGuide;
        break;
      case NodeTypeIconType.SelectCheckbox:
        inner = selectCheckboxNodeHelpGuide;
        break;
      case NodeTypeIconType.SelectRadio:
        inner = selectRadioNodeHelpGuide;
        break;
      case NodeTypeIconType.Upload:
        inner = uploadNodeHelpGuide;
        break;
      case NodeTypeIconType.Risk:
        inner = riskNodeHelpGuide;
        break;
      default:
        inner = props.customDefaultHelpGuide ?? defaultHelpGuide;
        break;
    }
  }

  return <div className="survey-builder-help-guide">{inner}</div>;
};

export default appConnect<
  QuestionnaireBuilderHelpGuideConnectedProps,
  never,
  QuestionnaireBuilderHelpGuideOwnProps
>((state, props) => {
  let focusedNodeType: NodeTypeIconType | undefined;
  let isRoot = false;
  const currentFocusedNodeId =
    state.surveyBuilder.surveys[props.surveyId].currentFocusedNodeId;
  if (currentFocusedNodeId) {
    isRoot =
      currentFocusedNodeId ===
      state.surveyBuilder.surveys[props.surveyId].rootNodeId;
    const focusedNode =
      state.surveyBuilder.surveys[props.surveyId].nodes[currentFocusedNodeId];
    if (focusedNode) {
      focusedNodeType = nodeToNodeTypeIconType(focusedNode);
    }
  }
  return {
    isRoot,
    focusedNodeType,
  };
})(memo(QuestionnaireBuilderHelpGuide));

import { useEffect, useState, useRef } from "react";

interface ChiliResponse {
  routeId: string;
  url: string;
  redirect: {
    enabled: boolean;
    closeStrategy: string;
    timeout: number;
    phoneCallRedirectEnabled: boolean;
    redirectCallTimeoutStrategy: string;
    redirectCallTimeout: number;
  };
}
export interface ChiliFormProps {
  firstName: string;
  lastName: string;
  countryName: string;
  email: string;
  bookingConfirmationTimeout: number;
  availabilityLoadedTimeout?: number;
  router: string;
  onMeetingBooked: () => void;
  onAvailabilityLoaded?: (timedOut: boolean) => void;
}
// Chili Piper is the meeting booking system that the sales team use.
// In Chili Piper you have the concept of a router which 'routes' inbound leads to different
// sales people based on defined rules, in this case the provided country.
// This component makes a request to their api with the details entered in the first
// stage of the form and returns the correct booking form for the users region (APAC etc.) which
// we render inside an iframe.
// The component in the iframe communicates with our page by sending messages.
// https://help.chilipiper.com/hc/en-us/articles/360053799073-Configuring-REST-API
// https://help.chilipiper.com/hc/en-us/articles/1500011381522-Capturing-Javascript-Messaging-in-Browser

export const ChiliBooking: React.FC<ChiliFormProps> = ({
  firstName,
  lastName,
  countryName,
  email,
  onMeetingBooked,
  bookingConfirmationTimeout,
  availabilityLoadedTimeout = 10000,
  onAvailabilityLoaded,
  router,
}) => {
  const [bookingUrl, setBookingUrl] = useState<string>();
  const [error, setError] = useState<string>();
  const availabilityCallbackFired = useRef(false);

  useEffect(() => {
    const fetchBookingUrl = async () => {
      try {
        const response = await fetch(
          "https://api.chilipiper.com/marketing/upguard",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              form: {
                Email: email,
                FirstName: firstName,
                LastName: lastName,
                Country: countryName,
              },
              options: {
                router: router,
                debug: false,
                map: false,
                locale: "en_US",
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch booking URL");
        }

        const data: ChiliResponse = await response.json();
        setBookingUrl(data.url);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Unknown error occurred");
      }
    };

    fetchBookingUrl();
  }, [
    email,
    firstName,
    lastName,
    countryName,
    bookingConfirmationTimeout,
    onMeetingBooked,
    onAvailabilityLoaded,
    availabilityLoadedTimeout,
  ]);

  useEffect(() => {
    const handleBookingEvents = (event: MessageEvent) => {
      switch (event.data.action) {
        case "booking-confirmed":
          // The booking widget shows a confirmation screen after the user selects a time.
          // We want to automatically navigate away after a short delay.
          setTimeout(onMeetingBooked, bookingConfirmationTimeout);
          break;
        case "availability-loaded":
          if (onAvailabilityLoaded && !availabilityCallbackFired.current) {
            availabilityCallbackFired.current = true;
            onAvailabilityLoaded(false);
          }
          break;
      }
    };

    window.addEventListener("message", handleBookingEvents);

    // Set up the timeout to fire if we don't receive the message
    let timeoutId: NodeJS.Timeout | undefined;
    if (onAvailabilityLoaded) {
      timeoutId = setTimeout(() => {
        if (!availabilityCallbackFired.current) {
          availabilityCallbackFired.current = true;
          onAvailabilityLoaded(true);
        }
      }, availabilityLoadedTimeout);
    }

    return () => {
      window.removeEventListener("message", handleBookingEvents);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    onMeetingBooked,
    bookingConfirmationTimeout,
    onAvailabilityLoaded,
    availabilityLoadedTimeout,
  ]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <iframe
      src={bookingUrl + "&footer=false&hide_x=true"}
      title="Booking Calendar"
    />
  );
};

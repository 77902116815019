import {
  AutofillSummarySection,
  VendorAssessment,
  VendorAssessmentEvidence,
  VendorAssessmentKeyRisk,
  VendorAssessmentNarrativeSection,
  VendorAssessmentRiskComment,
  VendorAssessmentScores,
  VendorAssessmentScoreStatus,
  VendorAssessmentStatus,
} from "../types/vendorAssessments";
import BaseAPI, { vendorDataTag } from "../../_common/rtkQueryApi";
import moment from "moment";
import {
  conditionalRefreshActivityStreamForOrgUser,
  getTPMVSession,
} from "../../_common/reducers/commonActions";
import { fetchAssessmentBreakdown } from "./vendorAssessment.actions";
import { analystGetSingleRequest } from "../../analyst_portal/reducers/analystManagedVendors.actions";
import {
  fetchCustomerVendorsData,
  fetchVendorSummaryAndCloudscans,
} from "./cyberRiskActions";
import { clearExportSections } from "./export.actions";
import { MergeTag } from "../../_common/types/orgDefaultTexts";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";
import { IVendorRiskWaiver } from "../../_common/types/vendorRiskWaivers";
import {
  AdditionalEvidenceInRemediation,
  CloudscanInRemediation,
} from "../../_common/types/remediation";
import { refreshVendorListsAfterChange } from "./vendors.actions";
import { RiskClassification } from "../../_common/types/risk/classification";
import { JobStatus } from "../types/jobs";

export const vendorAssessmentListTag = "VendorAssessmentList" as const;
export const vendorAssessmentItemTag = "VendorAssessmentItem" as const;
export const vendorAssessmentDraftTag = "VendorAssessmentLatestDraft" as const;
export const vendorAssessmentScoreStatusTag =
  "VendorAssessmentScoreStatus" as const;
export const vendorAssessmentMergeTagsTag = "VendorAssessmentMergeTag" as const;
export const vendorAssessmentStreamListTag =
  "VendorAssessmentStreamList" as const;
export const vendorAssessmentRisksTag = "VendorAssessmentRisksTag" as const;
export const vendorAssessmentAutofillRunningJobTag =
  "VendorAssessmentAutofillRunningJob" as const;
export const vendorAssessmentAutofillJobTag =
  "VendorAssessmentAutofillJob" as const;

export const managedVendorLatestUnstartedRequestTag =
  "managedVendorLatestUnstartedRequestTag" as const;

export interface VendorAssessmentListItem {
  id: number;
  streamID: number;
  name: string;
  version: number;
  organisationID: number;
  status: VendorAssessmentStatus;
  editingName?: string;
  editingEmail?: string;
  authorName: string;
  authorEmail: string;
  authorAvatar: string;
  editInProgressAt?: string;
  publishedAt?: string;
  managedAssessmentID?: number;
}

export interface SharedVendorAssessmentListItem
  extends VendorAssessmentListItem {
  sharedOrgName: string;
  sharedOrgHostname: string;
  sharedAccessGranted: boolean;
  sharedAccessRequested: boolean;
  sharedAccessRejected: boolean;
}

export interface VendorAssessmentStream {
  id: number;
  orgID: number;
  watchID: number;
  vendorID: number;
  scope?: string[];
  reassessmentDate?: string;
  archivedAt?: string;
  deletedAt?: string;
  name: string;
  versionNumber: number;
  latestVersionID: number;
  authorName: string;
  authorEmail: string;
  authorAvatar: string;
  publishedDate?: string;
  status: VendorAssessmentStatus;
  lastPublishedDate?: string;
  deleted?: boolean; // front end flag that is used to hide this when deleted to allow quick undeleting
  managedAssessmentID?: number;
  managedAssessmentServiceLevel?: string;
  upGuardPublishedAssessmentUUID?: string;
}

interface getVendorAssessmentListV2Req {
  vendorID: number;
}

interface getVendorAssessmentListV2Resp {
  assessments: { [streamID: number]: VendorAssessmentListItem[] };
  sharedAssessments: SharedVendorAssessmentListItem[];
}

interface getVendorAssessmentDataV2Req {
  vendorID: number;
  versionID: number;
}

interface getVendorAssessmentDataV2Resp {
  assessment: VendorAssessment;
  evidence: VendorAssessmentEvidence;
  narrativeSections?: VendorAssessmentNarrativeSection[];
}

interface lockEditsForVendorAssessmentReq {
  vendorID: number;
  assessmentID: number;
}

interface lockEditsForVendorAssessmentResp {
  status: string;
  hasLock: boolean;
  assessment: VendorAssessment;
}

interface toggleAssessmentDraftEvidenceTypeReq {
  vendorID: number;
  assessmentID: number;
  evidenceType: "questionnaires" | "additionalEvidence" | "evidencePages";
  toggleState: boolean;
}

interface startNewDraftAssessmentReq {
  vendorID: number;
  streamID: number;
}
interface startNewDraftAssessmentResp {
  managedAssessmentToRefresh?: number;
  newID: number;
}

type deleteVendorAssessmentReq = getVendorAssessmentDataV2Req;

interface updateVendorAssessmentSelectedHostnamesReq
  extends getVendorAssessmentDataV2Req {
  selectAll: boolean;
  selectedDomains: number[];
}

type startNewVendorAssessmentReq = getVendorAssessmentDataV2Req;

type getVendorAssessmentScoringStatusReq = getVendorAssessmentDataV2Req;
interface getVendorAssessmentScoringStatusResp {
  status: VendorAssessmentScoreStatus;
  scores: VendorAssessmentScores;
}

interface publishDraftForAssessmentReq {
  vendorID: number;
  reassessmentDate?: string;
  streamID: number;
}

interface publishDraftForAssessmentResp {
  assessmentId: number;
}

interface vendorAssessmentRisksV1Resp {
  assessmentId: number;
  risks: VendorSummaryRisk[];
  assessmentPublishDate?: string;
  riskWaivers: IVendorRiskWaiver[];
  pendingRiskWaivers: IVendorRiskWaiver[];
  riskComments: VendorAssessmentRiskComment[];
  keyRisks: VendorAssessmentKeyRisk[];
  cloudscansInRemediation: { [riskId: string]: CloudscanInRemediation };
  evidenceInRemediation: { [riskId: string]: AdditionalEvidenceInRemediation };
}

interface launchVendorAssessmentAutofillReq {
  assessmentId: number;
  includeExecutiveSummary: boolean;
  includeVendorBackground: boolean;
  riskClassifications: RiskClassification[];
  evidenceIds: number[];
}

interface launchVendorAssessmentAutofillResp {
  jobId: number;
}

interface getVendorAssessmentAutofillStatusReq {
  assessmentId: number;
}

interface getVendorAssessmentAutofillJobStatusReq {
  assessmentId: number;
  jobId: number;
}

interface getVendorAssessmentAutofillJobStatusResp {
  autofillJobId: number | undefined;
  autofillStatus: JobStatus | undefined;
  autofillProgress: number | undefined;
}

interface updateVendorAssessmentNarrativeSectionReq {
  vendorId: number;
  assessmentId: number;
  section: VendorAssessmentNarrativeSection;
  skipOptimisticUpdate?: boolean;
}

export type commentsUpdate = { riskID: string; text: string }[];

const VendorAssessmentAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [
    vendorAssessmentListTag,
    vendorAssessmentItemTag,
    vendorAssessmentDraftTag,
    vendorAssessmentScoreStatusTag,
    vendorAssessmentMergeTagsTag,
    vendorAssessmentStreamListTag,
    vendorAssessmentRisksTag,
    vendorAssessmentAutofillRunningJobTag,
    vendorAssessmentAutofillJobTag,
    managedVendorLatestUnstartedRequestTag,
  ],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendorAssessmentList: builder.query<
      getVendorAssessmentListV2Resp,
      getVendorAssessmentListV2Req
    >({
      query: ({ vendorID }) => ({
        url: "/vendor/assessment/list/v2",
        method: "GET",
        params: {
          vendor_id: vendorID,
        },
      }),
      providesTags: (result, _, { vendorID }) =>
        result
          ? [
              { type: vendorAssessmentListTag, id: vendorID },
              { type: vendorDataTag, id: vendorID },
            ]
          : [],
    }),

    getVendorAssessmentData: builder.query<
      getVendorAssessmentDataV2Resp,
      getVendorAssessmentDataV2Req
    >({
      query: ({ versionID }) => ({
        url: "/vendor/assessment/v2",
        method: "GET",
        params: {
          version_id: versionID,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: vendorAssessmentItemTag,
                id: result.assessment.id,
              },
              {
                type: vendorDataTag,
                id: result.assessment.datastoreVendorID,
              },
              ...(result.assessment.status == VendorAssessmentStatus.Draft
                ? [
                    {
                      type: vendorAssessmentDraftTag,
                      id: result.assessment.datastoreVendorID,
                    },
                  ]
                : []),
            ]
          : [],
    }),

    getVendorAssessmentStreamsV1: builder.query<
      {
        streams: VendorAssessmentStream[];
        archivedStreams: VendorAssessmentStream[];
        upGuardPublishedAssessments: VendorAssessmentStream[];
      },
      { vendorID: number }
    >({
      query: (req) => ({
        url: "vendor/assessment/streams/v1/",
        method: "GET",
        params: {
          vendor_id: req.vendorID,
        },
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              {
                type: vendorDataTag,
                id: arg.vendorID,
              },
              {
                type: vendorAssessmentStreamListTag,
                id: arg.vendorID,
              },
            ]
          : [],
    }),

    getRisksForAssessment: builder.query<
      vendorAssessmentRisksV1Resp,
      { vendorId: number; assessmentId: number }
    >({
      query: (req) => ({
        url: "vendor/assessment/risks/v1/",
        method: "GET",
        params: {
          assessment_id: req.assessmentId,
        },
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              { type: vendorDataTag, id: arg.vendorId },
              { type: vendorAssessmentItemTag, id: arg.assessmentId },
              { type: vendorAssessmentRisksTag, id: arg.assessmentId },
            ]
          : [],
    }),

    updateRiskCommentsForVendorAssessment: builder.mutation<
      void,
      { comments: commentsUpdate; assessmentID: number; vendorId: number }
    >({
      query: (arg) => ({
        url: "vendor/assessment/risk_comment/v1",
        method: "PUT",
        body: JSON.stringify({
          assessmentID: arg.assessmentID,
          comments: arg.comments,
        }),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getRisksForAssessment",
            { vendorId: arg.vendorId, assessmentId: arg.assessmentID },
            (draft) => {
              const allComments =
                draft.riskComments?.reduce(
                  (map, c) => {
                    map[c.riskID] = c;
                    return map;
                  },
                  {} as Record<string, VendorAssessmentRiskComment>
                ) ?? {};

              arg.comments.forEach((c) => {
                allComments[c.riskID] = {
                  riskID: c.riskID,
                  comments: c.text,
                  vendorAssessmentID: arg.assessmentID,
                };
              });

              draft.riskComments = Object.values(allComments);
            }
          )
        );

        queryFulfilled.catch(() => patch.undo());
      },
    }),

    lockEditForVendorAssessment: builder.mutation<
      lockEditsForVendorAssessmentResp,
      lockEditsForVendorAssessmentReq
    >({
      query: (req) => ({
        url: "vendor/assessment/lock/v1/",
        method: "PUT",
        params: {
          assessment_id: req.assessmentID,
        },
      }),
      onQueryStarted: (req, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(
            VendorAssessmentAPI.util.updateQueryData(
              "getVendorAssessmentData",
              { vendorID: req.vendorID, versionID: req.assessmentID },
              (draft) => {
                draft.assessment = data.assessment;
              }
            )
          );
        });
      },
    }),

    // not really a mutation but this endpoint was originally designed just to update the evidence in
    // the backgrond without refreshing the whole assessment so replicating that as well as I can here...
    refreshEvidenceForAssessment: builder.mutation<
      { evidence: VendorAssessmentEvidence },
      getVendorAssessmentDataV2Req
    >({
      query: (req) => ({
        url: "vendor/assessment/evidence/v1",
        method: "GET",
        params: {
          assessment_id: req.versionID,
        },
      }),
      onQueryStarted: (req, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(
            VendorAssessmentAPI.util.updateQueryData(
              "getVendorAssessmentData",
              req,
              (draft) => {
                draft.evidence = data.evidence;
              }
            )
          );
        });
      },
    }),

    toggleAssessmentDraftEvidenceType: builder.mutation<
      void,
      toggleAssessmentDraftEvidenceTypeReq
    >({
      query: (req) => ({
        url: "vendor/assessment/edit/evidencetype/v1",
        method: "PUT",
        params: {
          assessment_id: req.assessmentID,
          evidence_type: req.evidenceType,
          state: req.toggleState,
        },
      }),
      onQueryStarted: (req, { queryFulfilled, dispatch }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: req.vendorID, versionID: req.assessmentID },
            (draft) => {
              if (req.evidenceType === "questionnaires") {
                draft.evidence.surveys = draft.evidence.surveys.map((s) => ({
                  ...s,
                  selected: req.toggleState,
                }));
                draft.evidence.publicSurveys = draft.evidence.publicSurveys.map(
                  (s) => ({
                    ...s,
                    selected: req.toggleState,
                  })
                );
                draft.assessment.updatedAt = moment().format();
              } else if (req.evidenceType === "additionalEvidence") {
                draft.evidence.additional = draft.evidence.additional.map(
                  (s) => ({
                    ...s,
                    selected: req.toggleState,
                  })
                );
                draft.evidence.publicAdditional =
                  draft.evidence.publicAdditional.map((s) => ({
                    ...s,
                    selected: req.toggleState,
                  }));
                draft.assessment.updatedAt = moment().format();
              } else if (req.evidenceType === "evidencePages") {
                draft.evidence.pages = draft.evidence.pages?.map((s) => ({
                  ...s,
                  selected: req.toggleState,
                }));
                draft.assessment.updatedAt = moment().format();
              }

              queryFulfilled.catch(() => patch.undo());
            }
          )
        );
      },
      invalidatesTags: (_, __, req) => [
        { type: vendorAssessmentRisksTag, id: req.assessmentID },
      ],
    }),

    startNewDraftAssessment: builder.mutation<
      startNewDraftAssessmentResp,
      startNewDraftAssessmentReq
    >({
      query: (req) => ({
        url: "vendor/assessment/draft/v1",
        method: "PUT",
        params: {
          stream_id: req.streamID,
        },
      }),
      invalidatesTags: (_, __, req) => [
        { type: vendorAssessmentListTag, id: req.vendorID },
        { type: vendorAssessmentStreamListTag, id: req.vendorID },
      ],
      onQueryStarted: (req, { queryFulfilled, dispatch, getState }) =>
        queryFulfilled.then(({ data }) => {
          dispatch(conditionalRefreshActivityStreamForOrgUser());
          dispatch(fetchAssessmentBreakdown());
          dispatch(fetchCustomerVendorsData(true));

          const tpvmState = getTPMVSession(getState);
          if (data.managedAssessmentToRefresh && tpvmState.tpvm) {
            dispatch(
              analystGetSingleRequest(data.managedAssessmentToRefresh, true)
            );
          }
          dispatch(fetchVendorSummaryAndCloudscans(req.vendorID, true));
        }),
    }),

    deleteVendorAssessment: builder.mutation<void, deleteVendorAssessmentReq>({
      query: (req) => ({
        url: "vendor/assessment/delete/v1/",
        method: "DELETE",
        params: {
          assessment_id: req.versionID,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(refreshVendorListsAfterChange([arg.vendorID]));
        });
      },
      invalidatesTags: (_, __, req) => [
        { type: vendorAssessmentListTag, id: req.vendorID },
        { type: vendorAssessmentItemTag, id: req.versionID },
        { type: vendorAssessmentScoreStatusTag, id: req.versionID },
        { type: vendorAssessmentStreamListTag, id: req.vendorID },
      ],
    }),

    updateVendorAssessmentSelectedHostnames: builder.mutation<
      void,
      updateVendorAssessmentSelectedHostnamesReq
    >({
      query: (req) => ({
        url: "vendor/assessment/domains/v1",
        method: "PUT",
        body: JSON.stringify({
          assessmentID: req.versionID,
          all: req.selectAll,
          domains: req.selectedDomains,
        }),
      }),
      invalidatesTags: (_, __, req) => [
        { type: vendorAssessmentItemTag, id: req.versionID },
      ],
    }),

    startNewVendorAssessmentScoringJob: builder.mutation<
      { startedNewJob: boolean },
      startNewVendorAssessmentReq
    >({
      query: (req) => ({
        url: "vendor/assessment/score/v1",
        method: "PUT",
        params: {
          assessment_id: req.versionID,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((data) => {
          // if a new job was started change the status to started in the store
          if (data.data.startedNewJob) {
            dispatch(
              VendorAssessmentAPI.util.updateQueryData(
                "getVendorAssessmentScoringAndRiskCalcStatus",
                arg,
                (draft) => {
                  draft.status = "Started";
                }
              )
            );
          }
        });
      },
    }),

    getVendorAssessmentScoringAndRiskCalcStatus: builder.query<
      getVendorAssessmentScoringStatusResp,
      getVendorAssessmentScoringStatusReq
    >({
      query: (req) => ({
        url: "vendor/assessment/score/v1",
        method: "GET",
        params: {
          assessment_id: req.versionID,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        // if a new score has been calculated then we need to refetch the merge tags for the assessment
        queryFulfilled.then((result) =>
          result.data.status == "Complete"
            ? dispatch(
                VendorAssessmentAPI.util.invalidateTags([
                  { type: vendorAssessmentMergeTagsTag, id: arg.vendorID },
                ])
              )
            : undefined
        );
      },
      providesTags: (result, _, arg) =>
        result
          ? [
              {
                type: vendorDataTag,
                id: arg.vendorID,
              },
              {
                type: vendorAssessmentScoreStatusTag,
                id: arg.versionID,
              },
            ]
          : [],
    }),

    publishDraftForAssessment: builder.mutation<
      publishDraftForAssessmentResp,
      publishDraftForAssessmentReq
    >({
      query: (req) => ({
        url: "vendor/assessment/publish/v1/",
        method: "POST",
        params: {
          reassessment_date: req.reassessmentDate,
          stream_id: req.streamID,
        },
      }),
      invalidatesTags: [
        { type: "VendorAssessmentList" },
        { type: "VendorAssessmentItem" },
        { type: vendorAssessmentStreamListTag },
      ],
      onQueryStarted: (_req, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(() => {
          dispatch(fetchCustomerVendorsData(true));
          dispatch(conditionalRefreshActivityStreamForOrgUser());
          dispatch(fetchAssessmentBreakdown());
          dispatch(clearExportSections());
        });
      },
    }),

    updateVendorAssessmentName: builder.mutation<
      void,
      { vendorID: number; assessmentId: number; name: string }
    >({
      query: (arg) => ({
        url: "vendor/assessment/name/v1/",
        params: {
          version_id: arg.assessmentId,
          new_name: arg.name,
        },
        method: "PUT",
      }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: arg.vendorID, versionID: arg.assessmentId },
            (draft) => {
              draft.assessment.name = arg.name;
            }
          )
        );

        queryFulfilled.catch(() => patch.undo());
      },
    }),

    createNewVendorAssessmentStream: builder.mutation<
      {
        newStream: VendorAssessmentStream;
        newAssessment: VendorAssessment;
        newEvidence: VendorAssessmentEvidence;
        newSections?: VendorAssessmentNarrativeSection[];
      },
      {
        assessmentName: string;
        scope?: string[];
        vendorId: number;
        copyStreamId?: number;
        managedAssessmentId?: number;
      }
    >({
      query: (arg) => ({
        url: "vendor/assessment/stream/v1/",
        method: "POST",
        params: {
          assessment_name: arg.assessmentName,
          scope: arg.scope,
          vendor_id: arg.vendorId,
          copy_stream_id: arg.copyStreamId,
          managed_assessment_id: arg.managedAssessmentId,
        },
      }),
      invalidatesTags: (_, error, req) =>
        !error
          ? [
              { type: vendorAssessmentListTag, id: req.vendorId },
              {
                type: managedVendorLatestUnstartedRequestTag,
                id: req.vendorId,
              },
            ]
          : [],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            VendorAssessmentAPI.util.upsertQueryData(
              "getVendorAssessmentData",
              {
                vendorID: arg.vendorId,
                versionID: data.newAssessment.id,
              },
              {
                assessment: data.newAssessment,
                evidence: data.newEvidence,
                narrativeSections: data.newSections,
              }
            )
          );
          dispatch(
            VendorAssessmentAPI.util.updateQueryData(
              "getVendorAssessmentStreamsV1",
              { vendorID: arg.vendorId },
              (patch) => {
                patch.streams.push(data.newStream);
              }
            )
          );

          dispatch(fetchCustomerVendorsData(true));
        } catch (e) {}
      },
    }),

    archiveVendorAssessmentStream: builder.mutation<
      void,
      { streamID: number; archive: boolean; vendorId: number }
    >({
      query: (arg) => ({
        url: "vendor/assessment/stream/archive/v1/",
        params: {
          stream_id: arg.streamID,
          archive: arg.archive,
        },
        method: "PUT",
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentStreamsV1",
            { vendorID: arg.vendorId },
            (draft) => {
              // find the stream and move it from one list to the other
              if (arg.archive) {
                const idx = draft.streams.findIndex(
                  (val) => val.id == arg.streamID
                );
                if (idx >= 0) {
                  const item = draft.streams.splice(idx, 1);
                  item.forEach((i) => (i.archivedAt = moment().format()));
                  draft.archivedStreams.push(...item);
                }
              } else {
                const idx = draft.archivedStreams.findIndex(
                  (val) => val.id == arg.streamID
                );
                if (idx >= 0) {
                  const item = draft.archivedStreams.splice(idx, 1);
                  item.forEach((i) => (i.archivedAt = undefined));
                  draft.streams.push(...item);
                }
              }
            }
          )
        );

        queryFulfilled
          .then(() => {
            dispatch(fetchCustomerVendorsData(true));
          })
          .catch(() => patch.undo());
      },
    }),

    deleteVendorAssessmentStream: builder.mutation<
      void,
      { streamID: number; undelete?: boolean; vendorId: number }
    >({
      query: (arg) => ({
        url: "vendor/assessment/stream/v1/",
        params: {
          stream_id: arg.streamID,
          undelete: arg.undelete,
        },
        method: "DELETE",
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentStreamsV1",
            { vendorID: arg.vendorId },
            (draft) => {
              // find the stream mark it as deleted or undeleted
              const idx = draft.streams.findIndex((s) => s.id == arg.streamID);
              if (idx >= 0) {
                draft.streams[idx].deleted = !arg.undelete;
              } else {
                const idx = draft.archivedStreams.findIndex(
                  (s) => s.id == arg.streamID
                );
                if (idx >= 0) {
                  draft.archivedStreams[idx].deleted = !arg.undelete;
                }
              }
            }
          )
        );

        queryFulfilled
          .then(() => {
            dispatch(fetchCustomerVendorsData(true));
          })
          .catch(() => patch.undo());
      },
      invalidatesTags: (_, __, req) => [
        {
          type: managedVendorLatestUnstartedRequestTag,
          id: req.vendorId,
        },
      ],
    }),

    renameAssessment: builder.mutation<
      void,
      { vendorId: number; assessmentId: number; name: string }
    >({
      query: (arg) => ({
        url: "vendor/assessment/name/v1/",
        method: "PUT",
        params: {
          version_id: arg.assessmentId,
          new_name: arg.name,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: arg.vendorId, versionID: arg.assessmentId },
            (patch) => {
              patch.assessment.name = arg.name;
            }
          )
        );

        const patch2 = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentStreamsV1",
            { vendorID: arg.vendorId },
            (patch) => {
              const fn = (s: VendorAssessmentStream) => {
                if (s.latestVersionID == arg.assessmentId) {
                  s.name = arg.name;
                }
              };
              patch.streams.forEach(fn);
              patch.archivedStreams.forEach(fn);
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
          patch2.undo();
        });
      },
    }),

    updateReassessmentDate: builder.mutation<
      void,
      { vendorId: number; streamId: number; reassessmentDate?: string }
    >({
      query: (arg) => ({
        url: "vendor/assessment/reassessment/v1",
        method: "POST",
        params: {
          stream_id: arg.streamId,
          reassessment_date: arg.reassessmentDate,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentStreamsV1",
            { vendorID: arg.vendorId },
            (patch) => {
              const fn = (s: VendorAssessmentStream) => {
                if (s.id == arg.streamId) {
                  s.reassessmentDate = arg.reassessmentDate;
                }
              };
              patch.streams.forEach(fn);
              patch.archivedStreams.forEach(fn);
            }
          )
        );
        queryFulfilled.then(() => {
          dispatch(refreshVendorListsAfterChange([arg.vendorId]));
        });
        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    setContentSectionHiddenStatus: builder.mutation<
      void,
      {
        vendorId: number;
        assessmentId: number;
        sectionType: string;
        hidden: boolean;
      }
    >({
      query: (arg) => ({
        url: "vendor/assessment/copy/hide_section/v1",
        method: "PUT",
        params: {
          assessment_id: arg.assessmentId,
          section_type: arg.sectionType,
          hidden: arg.hidden,
        },
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: arg.vendorId, versionID: arg.assessmentId },
            (draft) => {
              draft.assessment.editInProgressAt = moment().format();
              switch (arg.sectionType) {
                case "intro": {
                  draft.assessment.hideIntroduction = arg.hidden;
                  break;
                }
                case "background": {
                  draft.assessment.hideBackground = arg.hidden;
                  break;
                }
                case "commentary": {
                  draft.assessment.hideCommentary = arg.hidden;
                  break;
                }
                case "conclusion": {
                  draft.assessment.hideConclusion = arg.hidden;
                  break;
                }
                case AutofillSummarySection: {
                  draft.assessment.hideAutofillSummary = arg.hidden;
                  break;
                }
              }
            }
          )
        );

        queryFulfilled.catch(() => patch.undo());
      },
    }),

    setKeyRisks: builder.mutation<
      void,
      {
        vendorId: number;
        assessmentId: number;
        keyRisks: VendorAssessmentKeyRisk[];
      }
    >({
      query: (arg) => ({
        url: "vendor/assessment/key_risks/v1",
        method: "PUT",
        params: { assessment_id: arg.assessmentId },
        body: JSON.stringify({ keyRisks: arg.keyRisks }),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: arg.vendorId, versionID: arg.assessmentId },
            (draft) => {
              draft.assessment.editInProgressAt = moment().format();
            }
          )
        );

        const patch2 = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getRisksForAssessment",
            { vendorId: arg.vendorId, assessmentId: arg.assessmentId },
            (draft) => {
              draft.keyRisks = arg.keyRisks;
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
          patch2.undo();
        });
      },
    }),

    getVendorAssessmentMergeTags: builder.query<
      MergeTag[],
      { vendorId: number; versionId: number }
    >({
      query: (args) => ({
        url: "vendor/merge_tags/v1",
        method: "GET",
        params: {
          vendor_id: args.vendorId,
          base_on_draft_assessment_id: args.versionId,
        },
      }),
      providesTags: (result, _error, arg) =>
        !!result
          ? [{ type: vendorAssessmentMergeTagsTag, id: arg.vendorId }]
          : [],
    }),

    toggleAssessmentDraftSurvey: builder.mutation<
      void,
      {
        vendorID: number;
        assessmentID: number;
        surveyID: number;
        shared: boolean;
        state: boolean;
      }
    >({
      query: (args) => ({
        url: "vendor/assessment/edit/survey/v1",
        method: "PUT",
        params: {
          assessment_id: args.assessmentID,
          public: args.shared,
          state: args.state,
          survey_id: args.surveyID,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          toggleVendorAssessmentSurveySelected(
            args.vendorID,
            args.assessmentID,
            args.surveyID,
            args.shared
          )
        );
        queryFulfilled.catch(() => patch.undo());
      },
      invalidatesTags: (_, __, req) => [
        { type: vendorAssessmentRisksTag, id: req.assessmentID },
      ],
    }),

    toggleAssessmentDraftEvidence: builder.mutation<
      void,
      {
        vendorID: number;
        assessmentID: number;
        evidenceID: number;
        shared: boolean;
        state: boolean;
      }
    >({
      query: (args) => ({
        url: "vendor/assessment/edit/additional/v1",
        method: "PUT",
        params: {
          assessment_id: args.assessmentID,
          public: args.shared,
          state: args.state,
          additional_id: args.evidenceID,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          toggleVendorAssessmentEvidenceAdditionalSelected(
            args.vendorID,
            args.assessmentID,
            args.evidenceID,
            args.shared
          )
        );
        queryFulfilled.catch(() => patch.undo());
      },
      invalidatesTags: (_, __, req) => [
        { type: vendorAssessmentRisksTag, id: req.assessmentID },
      ],
    }),

    toggleAssessmentDraftPage: builder.mutation<
      void,
      {
        vendorID: number;
        assessmentID: number;
        category: string;
        url: string;
        state: boolean;
      }
    >({
      query: (args) => ({
        url: "vendor/assessment/edit/pages/v1",
        method: "PUT",
        params: {
          assessment_id: args.assessmentID,
          url: args.url,
          category: args.category,
          state: args.state,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          toggleVendorAssessmentEvidencePageSelected(
            args.vendorID,
            args.assessmentID,
            args.category,
            args.url
          )
        );
        queryFulfilled.catch(() => patch.undo());
      },
    }),

    updateScope: builder.mutation<
      void,
      { vendorId: number; streamId: number; scope?: string[] }
    >({
      query: (args) => ({
        url: "vendor/assessment/scope/v1",
        method: "PUT",
        params: {
          stream_id: args.streamId,
          scope: args.scope,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentStreamsV1",
            { vendorID: args.vendorId },
            (draft) => {
              const fn = (s: VendorAssessmentStream) => {
                if (s.id == args.streamId) {
                  s.scope = args.scope;
                }
              };
              draft.streams.forEach(fn);
              draft.archivedStreams.forEach(fn);
            }
          )
        );
        queryFulfilled.catch(() => patch.undo());
      },
    }),

    // launchVendorAssessmentAutofill - trigger an autofill job for a vendor assessment
    // The autofill job will use the provided evidence documents to fill the requested sections.
    launchVendorAssessmentAutofill: builder.mutation<
      launchVendorAssessmentAutofillResp,
      launchVendorAssessmentAutofillReq
    >({
      query: (req) => ({
        url: "/vendor/assessment/autofill/launch/v1",
        method: "POST",
        params: {
          assessment_id: req.assessmentId,
          include_executive_summary: req.includeExecutiveSummary,
          include_vendor_background: req.includeVendorBackground,
          risk_classifications: req.riskClassifications,
          evidence_ids: req.evidenceIds,
        },
      }),
    }),

    // getVendorAssessmentAutofillStatus - retrieves the data of any queued or currently executing autofill job for a vendor assessment
    getVendorAssessmentAutofillStatus: builder.query<
      getVendorAssessmentAutofillJobStatusResp,
      getVendorAssessmentAutofillStatusReq
    >({
      query: (req) => ({
        url: "vendor/assessment/autofill/status/v1",
        method: "GET",
        params: { assessment_id: req.assessmentId },
      }),
      providesTags: (result, _error, req) =>
        !!result
          ? [
              {
                type: vendorAssessmentAutofillRunningJobTag,
                id: req.assessmentId,
              },
            ]
          : [],
    }),

    // getVendorAssessmentAutofillJobStatus - check the status of a specific autofill job for a vendor assessment
    getVendorAssessmentAutofillJobStatus: builder.query<
      getVendorAssessmentAutofillJobStatusResp,
      getVendorAssessmentAutofillJobStatusReq
    >({
      query: (req) => ({
        url: "vendor/assessment/autofill/job/status/v1",
        method: "GET",
        params: { assessment_id: req.assessmentId, job_id: req.jobId },
      }),
      providesTags: (result, _error, req) =>
        !!result
          ? [
              {
                type: vendorAssessmentAutofillJobTag,
                id: req.jobId,
              },
            ]
          : [],
    }),

    // updateVendorAssessmentNarrativeSection - persist the vendor assessment narrative section provided
    updateVendorAssessmentNarrativeSection: builder.mutation<
      void,
      updateVendorAssessmentNarrativeSectionReq
    >({
      query: (req) => ({
        url: "/vendor/assessment/section/v1",
        method: "PUT",
        body: JSON.stringify({
          assessmentId: req.assessmentId,
          section: req.section,
        }),
      }),
      onQueryStarted: (req, { dispatch, queryFulfilled }) => {
        if (req.skipOptimisticUpdate) {
          return;
        }
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: req.vendorId, versionID: req.assessmentId },
            (draft) => {
              const newSections: VendorAssessmentNarrativeSection[] = [];

              draft.narrativeSections?.forEach((s) => {
                if (s.section !== req.section.section) {
                  newSections.push(s);
                }
              });

              draft.narrativeSections = [...newSections, req.section];
            }
          )
        );

        queryFulfilled.catch(() => patch.undo());
      },
    }),

    // updateVendorAssessmentNarrativeSection - set the with customer status of a vendor assessment to the provided value
    updateWithCustomerStatusForAssessment: builder.mutation<
      void,
      { vendorId: number; assessmentId: number; withCustomer: boolean }
    >({
      query: (req) => ({
        url: "vendor/assessment/withcustomer/v1",
        method: "PUT",
        params: {
          assessment_id: req.assessmentId,
          with_customer: req.withCustomer,
        },
      }),
      onQueryStarted: (req, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorAssessmentAPI.util.updateQueryData(
            "getVendorAssessmentData",
            { vendorID: req.vendorId, versionID: req.assessmentId },
            (draft) => {
              draft.assessment.withCustomer = req.withCustomer;
            }
          )
        );

        queryFulfilled.catch(() => patch.undo());
      },
    }),

    getLatestUnstartedManagedVendorRequestForVendor: builder.query<
      { result: number },
      { vendorId: number }
    >({
      query: (req) => ({
        url: "cyberresearch/managedvendor/requestforvendor/v2",
        method: "GET",
        params: { vendor_id: req.vendorId },
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [{ type: managedVendorLatestUnstartedRequestTag, id: arg.vendorId }]
          : [],
    }),
  }),
});

export const toggleVendorAssessmentSurveySelected = (
  vendorID: number,
  versionID: number,
  surveyID: number,
  isPublic: boolean
) =>
  VendorAssessmentAPI.util.updateQueryData(
    "getVendorAssessmentData",
    { vendorID, versionID },
    (draft) => {
      if (isPublic) {
        draft.evidence.publicSurveys = draft.evidence.publicSurveys.map(
          (s) => ({
            ...s,
            selected: s.id == surveyID ? !s.selected : s.selected,
          })
        );
      } else {
        draft.evidence.surveys = draft.evidence.surveys.map((s) => ({
          ...s,
          selected: s.id == surveyID ? !s.selected : s.selected,
        }));
      }
      draft.assessment.updatedAt = moment().format();
    }
  );

export const toggleVendorAssessmentEvidenceAdditionalSelected = (
  vendorID: number,
  versionID: number,
  evidenceID: number,
  isPublic: boolean
) =>
  VendorAssessmentAPI.util.updateQueryData(
    "getVendorAssessmentData",
    { vendorID, versionID },
    (draft) => {
      if (isPublic) {
        draft.evidence.publicAdditional = draft.evidence.publicAdditional.map(
          (e) => ({
            ...e,
            selected: e.id == evidenceID ? !e.selected : e.selected,
          })
        );
      } else {
        draft.evidence.additional = draft.evidence.additional.map((e) => ({
          ...e,
          selected: e.id == evidenceID ? !e.selected : e.selected,
        }));
      }
      draft.assessment.updatedAt = moment().format();
    }
  );

export const toggleVendorAssessmentEvidencePageSelected = (
  vendorID: number,
  versionID: number,
  category: string,
  url: string
) =>
  VendorAssessmentAPI.util.updateQueryData(
    "getVendorAssessmentData",
    { vendorID, versionID },
    (draft) => {
      draft.evidence.pages = draft.evidence.pages?.map((p) => ({
        ...p,
        selected:
          p.category == category && p.url == url ? !p.selected : p.selected,
      }));
      draft.assessment.updatedAt = moment().format();
    }
  );

export const invalidateVendorAssessmentDraftsForVendor = (vendorId: number) =>
  VendorAssessmentAPI.util.invalidateTags([
    {
      type: vendorAssessmentDraftTag,
      id: vendorId,
    },
  ]);

export const invalidateVendorAssessmentRisks = (assessmentId: number) =>
  VendorAssessmentAPI.util.invalidateTags([
    {
      type: vendorAssessmentRisksTag,
      id: assessmentId,
    },
  ]);

export const invalidateVendorAssessmentStreamListTag = (vendorId: number) =>
  VendorAssessmentAPI.util.invalidateTags([
    {
      type: vendorAssessmentStreamListTag,
      id: vendorId,
    },
  ]);

export const invalidateManagedVendorLatestUnstartedRequestTag = (
  vendorId: number
) =>
  VendorAssessmentAPI.util.invalidateTags([
    {
      type: managedVendorLatestUnstartedRequestTag,
      id: vendorId,
    },
  ]);

export default VendorAssessmentAPI;

import { InputTextNode } from "../../../survey_builder/types/types";
import { BaseNodeContentProps, DiffSide } from "./baseNode";
import classNames from "classnames";
import "../../style/InputNode.scss";
import { diffWords } from "diff";
import TextFieldWithActions from "../../../_common/components/TextFieldWithActions";

interface InputTextNodeContentProps extends BaseNodeContentProps {
  node: InputTextNode;
}

// note: this only looks at 'removed' because it expects for the added side that the answers are passed in the other way around
export const getTextDiff = (
  answer: string,
  otherAnswer: string,
  side: DiffSide
) => {
  const parts = diffWords(answer, otherAnswer, { ignoreCase: true });
  return parts
    .filter((d) => !d.added)
    .map((d, i) => (
      <span
        key={i}
        className={classNames({
          red: side == "left" && d.removed,
          green: side == "right" && d.removed,
        })}
      >
        {d.value}
      </span>
    ));
};

interface DiffTextBoxProps {
  answer: string;
  otherAnswer: string;
  side: DiffSide;
  multiline?: boolean;
}

export const DiffTextBox: React.VFC<DiffTextBoxProps> = ({
  answer,
  otherAnswer,
  side,
  multiline,
}) => (
  <div className={classNames("fake-text-box", { multiline })}>
    {getTextDiff(answer ?? "", otherAnswer ?? "", side)}
  </div>
);
const InputTextNodeContent = (props: InputTextNodeContentProps) => {
  const currentAnswer =
    (props.suggestedAnswer as string) ?? (props.answer as string) ?? "";
  const otherAnswer = (props.otherAnswer as string) ?? "";

  return (
    <div className={"question-answer-node-content"}>
      {props.diffSide ? (
        <DiffTextBox
          answer={currentAnswer}
          otherAnswer={otherAnswer}
          side={props.diffSide}
          multiline={props.node.multiLine}
        />
      ) : (
        <div className={"answer-section"}>
          <TextFieldWithActions
            value={currentAnswer as string}
            onChanged={(val) =>
              props.onAnswerChanged(
                props.node.nodeId,
                val !== "" ? val : undefined
              )
            }
            multiLine={props.node.multiLine}
            placeholder={!props.disabled ? "Type your answer here..." : ""}
            disabled={props.disabled}
            actionButtons={props.actionButtons}
            inputColor={props.suggestedAnswer ? "orange" : undefined}
          />
          {props.gptSuggestionBox}
        </div>
      )}
    </div>
  );
};

export default InputTextNodeContent;

import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Doughnut from "../Doughnut";
import Button from "../../../_common/components/core/Button";
import { useDefaultHistory } from "../../../_common/types/router";
import {
  permissionAppsUrl,
  permissionSummaryUrl,
} from "../../UserBaseAppRouter";
import { pluralise } from "../../../_common/helpers";
import { AppStatus } from "../../api/types";
import { hexColorForAppStatus } from "../../helpers/apps";
import "./PermissionAppsOverview.scss";

interface PermissionAppsOverviewProps {
  scopeExternalId: string;
}

const PermissionAppsOverview: FC<PermissionAppsOverviewProps> = ({
  scopeExternalId,
}) => {
  const history = useDefaultHistory();

  const { data: permissionSummary } =
    UserBaseAPI.useGetUserBaseScopeSummaryV1Query({
      scopeExternalId,
    });

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppsV1Query({
    permissions: [scopeExternalId],
    excludePermissions: false,
  });

  const onViewApps = () => {
    history.push(permissionAppsUrl(scopeExternalId), {
      backContext: {
        backTo: permissionSummaryUrl(scopeExternalId),
        backToText: `Back to ${permissionSummary?.scope?.description}`,
      },
    });
  };

  const appsToUse = data?.apps ?? [];

  const doughnutData = [
    {
      label: "Approved",
      count: appsToUse.filter((a) => a.status === AppStatus.ApprovedAppStatus)
        .length,
      hexColor: hexColorForAppStatus(AppStatus.ApprovedAppStatus),
    },
    {
      label: "Not Approved",
      count: appsToUse.filter(
        (a) => a.status === AppStatus.NotApprovedAppStatus
      ).length,
      hexColor: hexColorForAppStatus(AppStatus.NotApprovedAppStatus),
    },
    {
      label: "In Review",
      count: appsToUse.filter((a) => a.status === AppStatus.InReviewAppStatus)
        .length,
      hexColor: hexColorForAppStatus(AppStatus.InReviewAppStatus),
    },
    {
      label: "Needs Review",
      count: appsToUse.filter(
        (a) => a.status === AppStatus.NeedsReviewAppStatus
      ).length,
      hexColor: hexColorForAppStatus(AppStatus.NeedsReviewAppStatus),
    },
  ].filter((d) => d.count > 0); // only display non-empty entries

  return (
    <ReportCard newStyles className="userbase-permission-apps-overview">
      <div className="header">
        <span>Applications overview</span>
        <div>
          <Button tertiary onClick={onViewApps}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <Doughnut
            data={doughnutData}
            totalLabel={pluralise(appsToUse.length, "App", "Total apps")}
          />
        </div>
      )}
    </ReportCard>
  );
};

export default PermissionAppsOverview;

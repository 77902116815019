import { memo, useEffect, useRef, useState } from "react";
import {
  addBlankCheckGroupNode,
  addBlankInfoNode,
  addBlankInputTextNode,
  addBlankRadioGroupNode,
  addBlankRiskNode,
  addBlankSectionNode,
  addBlankUploadNode,
  setCurrentFocusedNodeId,
} from "../reducers/actions";
import classnames from "classnames";

import "../style/AddItemButton.scss";
import { createUUID } from "../helpers";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { AppDispatch } from "../../_common/types/reduxStore";
import NodeTypeSelector from "./NodeTypeSelector";
import { NodeTypeIconType } from "./NodeTypeIcon";

interface IAddItemButtonProps {
  dispatch: AppDispatch;
  surveyId: string;
  parentNodeId: string;
  indentLevel: number;
  newQuestionNumber: string;
  addAtIndex: number;
  usageType: SurveyUsageType;
}

// AddItemButton renders a button that can be used to add a new node to the questionnaire.
const AddItemButton = (props: IAddItemButtonProps) => {
  const [popupOpen, setPopupOpen] = useState(false);

  // Use a ref to store our click handler so we can maintain a reference to it between renders.
  // clickHandlerRef is used to store the click handler that will close the popup when we click outside of it.
  const clickHandlerRef = useRef<EventListener>();

  const togglePopup = (open: boolean) => {
    if (open) {
      // Add an event listener on the document that will set the popup to closed
      clickHandlerRef.current = (ev: Event) => {
        // Don't do anything if we've clicked inside the popup.
        if ((ev.target as Element).closest(".add-btn-popup")) {
          return;
        }

        togglePopup(false);
      };
      document.addEventListener("click", clickHandlerRef.current);
      setPopupOpen(true);
    } else {
      // Remove the click handler on the document
      document.removeEventListener(
        "click",
        clickHandlerRef.current as EventListener
      );
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    // Clean up the click handler on unmount
    return () =>
      document.removeEventListener(
        "click",
        clickHandlerRef.current as EventListener
      );
  }, []);

  return (
    <div
      className={classnames("add-btn-wrapper", `indent-${props.indentLevel}`)}
    >
      <div
        className="add-btn"
        onClick={(ev) => {
          ev.stopPropagation();
          togglePopup(!popupOpen);
        }}
      />
      {popupOpen && (
        <NodeTypeSelector
          excludeOptions={[]}
          questionNumber={props.newQuestionNumber}
          nodeSelectedCallback={(nodeType) => {
            togglePopup(false);
            const newNodeId = createUUID();
            const nodeTypeIconTypeToAction = {
              [NodeTypeIconType.Section]: addBlankSectionNode,
              [NodeTypeIconType.InputText]: addBlankInputTextNode,
              [NodeTypeIconType.SelectCheckbox]: addBlankCheckGroupNode,
              [NodeTypeIconType.SelectRadio]: addBlankRadioGroupNode,
              [NodeTypeIconType.Upload]: addBlankUploadNode,
              [NodeTypeIconType.Risk]: addBlankRiskNode,
              [NodeTypeIconType.Info]: addBlankInfoNode,
            };

            const addNodeAction = nodeTypeIconTypeToAction[nodeType];
            if (addNodeAction) {
              props.dispatch(
                addNodeAction(
                  props.surveyId,
                  props.parentNodeId,
                  newNodeId,
                  props.addAtIndex
                )
              );
              props.dispatch(
                setCurrentFocusedNodeId(props.surveyId, newNodeId)
              );
            }
          }}
        ></NodeTypeSelector>
      )}
    </div>
  );
};

export default memo(AddItemButton);

import "./Users.scss";
import UserAvatar, {
  IUserAvatarAndNameProps,
} from "../../_common/components/UserAvatar";
import { PopupOnOverflow } from "../../_common/components/EllipsizedText";
import {
  IUserAvatarListProps,
  UserAvatarListUser,
} from "../../_common/components/UserAvatarList";
import { SYSTEM_USER_ID } from "../api/types";
import CircledIcon from "../../_common/components/CircledIcon";
import { sortBy as _sortBy } from "lodash";

export interface TMUserAndAvatarProps extends IUserAvatarAndNameProps {
  userId?: number;
  extraRow?: React.ReactNode;
}

export function TMUserAndAvatar({
  name,
  avatar,
  email,
  extraRow,
  userId,
}: TMUserAndAvatarProps) {
  let avatarElt: JSX.Element;

  if (userId === SYSTEM_USER_ID) {
    avatarElt = (
      <CircledIcon iconClass="cr-icon-upguard-logo-pick upguard-logo" />
    );
  } else {
    avatarElt = <UserAvatar avatar={avatar} />;
  }

  return (
    <div className="tm-user-and-avatar">
      <PopupOnOverflow text={name}>
        <div className="name-row">
          {avatarElt} <div className="name">{name}</div>
        </div>
      </PopupOnOverflow>
      {email && (
        <PopupOnOverflow text={email}>
          <div className="email">{email}</div>
        </PopupOnOverflow>
      )}
      {extraRow}
    </div>
  );
}

interface TMUserListProps extends IUserAvatarListProps {
  sortByLastName?: boolean;
  showUserId?: number;
  extraUserCount?: number;
}

// TMUserList displays a list of users, with the first user shown in full and the rest shown as "+ N more".
// Note that we don't gracefully handle the case where there are 0 users and extraUserCount is > 0.
export function TMUserList({
  users,
  showUserId,
  extraUserCount = 0,
}: TMUserListProps) {
  if (!users || users.length === 0) {
    return null;
  }

  const totalUsers = users.length + extraUserCount;

  if (totalUsers === 1) {
    return <TMUserAndAvatar {...users[0]} />;
  }

  let userToShow: UserAvatarListUser | undefined = undefined;
  if (showUserId) {
    userToShow = users.find((u) => u.id === showUserId) || users[0];
  }
  if (!userToShow) {
    userToShow = users[0];
  }

  const user = { ...userToShow, email: undefined };
  const extraRow = <div className="extra">+ {totalUsers - 1} more</div>;

  return <TMUserAndAvatar {...user} extraRow={extraRow} />;
}

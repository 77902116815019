import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../_common/components/InfoTable";
import ReportCard from "../../_common/components/ReportCard";
import {
  ISurveyListItemResponse,
  PrefilledSurveyStatus,
} from "../../_common/types/survey";
import "../style/PrefilledSurveyDetailsOverviewCard.scss";
import SurveyName from "../../vendorrisk/components/surveys/SurveyName";
import moment from "moment";
import ProgressBar from "../../_common/components/ProgressBar";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import TextField, {
  useTextWithValid,
} from "../../_common/components/TextField";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import ToggleWithLabel from "../../vendorrisk/components/ToggleWithLabel";
import TrustPageAPI from "../api/trustpage.api";
import { useState } from "react";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { addDefaultSuccessAlert } from "../../_common/reducers/messageAlerts.actions";
import Button from "../../_common/components/core/Button";
import useReceivedSurveys from "../hooks/useReceivedSurveys";
import { useDefaultHistory } from "../../_common/types/router";
import { getViewQuestionButtonText } from "../../_common/components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";
import SurveyImportAPI from "../../vendor_portal/api/surveyImportAPI";

interface PrefilledSurveyDetailsOverviewCardProps {
  survey: ISurveyListItemResponse;
  onOpenSurveyEditor: () => LocationDescriptor;
  userHasWritePermission: boolean;
  userCanViewImportedSurveys: boolean;
  hasUnansweredQuestions: boolean;
  onUnansweredQuestionsClick?: () => void;
}

const progressText = (survey: ISurveyListItemResponse) => {
  return survey.numQuestions && survey.numQuestions > 0
    ? `${survey.numAnswers} of ${
        survey.numQuestions
      } Questions answered (${Math.floor(
        (survey.numAnswers / survey.numQuestions) * 100
      )}%)`
    : "";
};

const PrefilledSurveyDetailsOverviewCard = ({
  survey,
  onOpenSurveyEditor,
  userHasWritePermission,
  userCanViewImportedSurveys,
  hasUnansweredQuestions,
  onUnansweredQuestionsClick,
}: PrefilledSurveyDetailsOverviewCardProps) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory();
  const [
    surveyDescription,
    surveyDescriptionValid,
    setSurveyDescription,
    surveyDescriptionHasChanged,
  ] = useTextWithValid(survey.description ?? "", true);

  const [surveyName, surveyNameValid, setSurveyName, surveyNameHasChanged] =
    useTextWithValid(survey.name ?? "", true);

  const [surveyPublished, setSurveyPublished] = useState(
    survey.status === PrefilledSurveyStatus.Published
  );

  const [updatePrefilledSurveyDetails] =
    TrustPageAPI.useUpdatePrefilledSurveyDetailsV1Mutation();
  const [updatePrefilledSurveyState] =
    TrustPageAPI.useSetPrefilledSurveyPublishedV1Mutation();

  const handleDetailsUpdate = () => {
    if (surveyNameHasChanged || surveyDescriptionHasChanged) {
      if (surveyNameValid && surveyDescriptionValid) {
        updatePrefilledSurveyDetails({
          surveyId: survey.id,
          surveyName: surveyName,
          surveyDescription: surveyDescription,
        });
      }
    }
  };

  const toggleIncludeInTrustPage = () => {
    const newStatus = !surveyPublished;
    setSurveyPublished(newStatus);
    updatePrefilledSurveyState({
      surveyId: survey.id,
      published: newStatus,
    });

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
    dispatch(
      addDefaultSuccessAlert(
        `Questionnaire ${newStatus ? "added to" : "removed from"} Trust Page`
      )
    );
  };

  const surveyIsLinkedToPrivateSurvey = survey.originalID > 0;
  const receivedSurveys: ISurveyListItemResponse[] = useReceivedSurveys(
    survey.vendorId
  );
  const { data: importedSurveysData } =
    SurveyImportAPI.useGetImportedSurveysListQuery(undefined, {
      skip: !userCanViewImportedSurveys,
    });

  // Make sure this user actually has permission to view the original questionnaire
  const canViewOriginalSurvey =
    (surveyIsLinkedToPrivateSurvey &&
      receivedSurveys.some((s) => s.id === survey.originalID)) ||
    importedSurveysData?.surveys.some((s) => s.id === survey.originalID);

  const handleViewOriginalSurvey = () => {
    history.push(`/vendors/surveys/${survey.originalID}`, {
      backContext: {
        goBack: true,
        backToText: "Back to Public Questionnaire",
      },
    });
  };

  return (
    <ReportCard newStyles className="prefilled-survey-details-overview-card">
      <div className="header">
        <div className="header-with-subtext">
          <div>Details</div>
        </div>
        <div className="header-right">
          {canViewOriginalSurvey && (
            <Button onClick={handleViewOriginalSurvey}>
              <div className="cr-icon-eye" />
              View original questionnaire
            </Button>
          )}
          <Link to={onOpenSurveyEditor}>
            <SidePopupV2
              text={
                !userHasWritePermission
                  ? "You do not have permission to edit this questionnaire."
                  : undefined
              }
              position="left"
            >
              <Button disabled={!userHasWritePermission}>
                {getViewQuestionButtonText(survey, userHasWritePermission)}
                <div className="cr-icon-arrow-right" />
              </Button>
            </SidePopupV2>
          </Link>
        </div>
      </div>
      <div className="content">
        <div>
          <InfoTable styling={InfoTableStyling.BoldLabels}>
            <InfoTableRow
              label="Name"
              value={
                userHasWritePermission ? (
                  <TextField
                    value={surveyName}
                    onChanged={setSurveyName}
                    placeholder="Provide a name"
                    onBlur={handleDetailsUpdate}
                    minLength={2}
                    maxLength={500}
                    required
                    enterKeyShouldBlur
                    staticEditable
                  />
                ) : (
                  <SurveyName survey={survey} />
                )
              }
            />
            <InfoTableRow
              label="Description"
              value={
                userHasWritePermission ? (
                  <TextField
                    value={surveyDescription}
                    onChanged={setSurveyDescription}
                    placeholder="Enter questionnaire description"
                    onBlur={handleDetailsUpdate}
                    maxLength={500}
                    required={false}
                    multiLine
                    staticEditable
                  />
                ) : (
                  survey.description
                )
              }
            />
            <InfoTableRow
              label="Status"
              value={
                survey.status === PrefilledSurveyStatus.Published ? (
                  <PillLabel color={LabelColor.Green}>Published</PillLabel>
                ) : (
                  <PillLabel color={LabelColor.Grey}>Draft</PillLabel>
                )
              }
            />
            <InfoTableRow
              label="Date published"
              value={
                survey.dateCompleted
                  ? moment(survey.dateCompleted).format("ll")
                  : ""
              }
            />
          </InfoTable>
        </div>
        <div>
          <InfoTable styling={InfoTableStyling.BoldLabels}>
            <InfoTableRow
              rowClass={"taller-row"}
              label={"Progress"}
              value={
                <div className={"survey-progress"}>
                  <ProgressBar
                    progress={
                      survey.numQuestions && survey.numQuestions > 0
                        ? survey.numAnswers / survey.numQuestions
                        : 0
                    }
                    leftText={progressText(survey)}
                  />
                  {hasUnansweredQuestions && onUnansweredQuestionsClick && (
                    <div
                      className={"unanswered-question-link"}
                      onClick={onUnansweredQuestionsClick}
                    >
                      View unanswered questions
                    </div>
                  )}
                </div>
              }
            />
            <InfoTableRow
              label="Access"
              value={
                <SidePopupV2
                  text={
                    !userHasWritePermission
                      ? `You do not have permission to edit the Trust Page. Contact an account administrator for access.`
                      : undefined
                  }
                >
                  <ToggleWithLabel
                    name="include_shared_profile"
                    label="Include in Trust Page"
                    selected={surveyPublished}
                    onClick={() => toggleIncludeInTrustPage()}
                    disabled={!userHasWritePermission}
                  />
                </SidePopupV2>
              }
            />
          </InfoTable>
        </div>
      </div>
    </ReportCard>
  );
};

export default PrefilledSurveyDetailsOverviewCard;

import { IItemWithLabelsAndPortfolios } from "./labelsAndPortfolios";
import { VendorMini } from "./vendor";

export interface DetectedProduct {
  uuid: string;
  orgId?: number;
  tech_name: string;
  version?: string;
  description: string;
  link: string;
  tag: string;
  vendor_id: number;
  vendor: VendorMini;
  hosts: detectedProductHost[];
}

export interface IDetectedProductsFilters {
  detectedProductVendorMonitored: boolean;
  detectedProductVendorUnmonitored: boolean;
  detectedProductVendorMustExist: boolean;
  detectedProductCategory: Record<string, true>;
}

export const detectedProductsDefaultFilter: IDetectedProductsFilters = {
  detectedProductVendorMonitored: false,
  detectedProductVendorUnmonitored: false,
  detectedProductVendorMustExist: false,
  detectedProductCategory: {},
};

interface detectedProductHost extends IItemWithLabelsAndPortfolios {
  hostname_id: number;
  technology_id: number;
  hostname: string;
  version: string;
  created_at: Date;
}

export const filterDetectedProducts = (
  filters: IDetectedProductsFilters,
  searchText: string,
  detectedProducts: DetectedProduct[],
  watchedVendorIDs?: number[]
): DetectedProduct[] => {
  return detectedProducts.filter((detectedProduct) => {
    if (
      filters.detectedProductVendorMustExist &&
      (!detectedProduct.vendor_id || !detectedProduct.vendor.vendor_id)
    ) {
      return false;
    }

    if (
      Object.keys(filters.detectedProductCategory).length &&
      (!detectedProduct.tag ||
        !filters.detectedProductCategory[detectedProduct.tag])
    ) {
      return false;
    }

    // we only filter on monitored/unmonitored vendors if ONE option is selected
    // otherwise the result is the same
    if (
      filters.detectedProductVendorMonitored
        ? !filters.detectedProductVendorUnmonitored
        : filters.detectedProductVendorUnmonitored
    ) {
      return (
        // default to true if we have no watchedVendorIDs - the user may not have
        // perimssion to see vendors in this case
        watchedVendorIDs?.includes(detectedProduct.vendor_id) ===
        filters.detectedProductVendorMonitored
      );
    }
    if (searchText) {
      const toSearch: string[] = [
        detectedProduct.tech_name,
        detectedProduct.vendor?.name,
        detectedProduct.vendor?.primary_hostname,
        detectedProduct.tag,
      ].map((s) => s?.toLowerCase());
      if (!toSearch.some((s) => s && s.includes(searchText.toLowerCase()))) {
        return false;
      }
    }
    return true;
  });
};

// builtWithCategories maps the builtwith tag to a friendly category name
// that lines up with our detected product categories.
// If the rename value is an empty string, any product with that tag will be
// filtered out.
// Note that these values must be kept in sync with the backend
// see technologies/vendor_technologies.go blockedBuiltWithTags
export const builtWithCategories = new Map<string, string>([
  ["ads", "Advertising"],
  ["analytics", "Analytics"],
  ["cdn", "CDN"],
  ["cdns", "CDN"],
  ["cms", "CMS"],
  ["framework", "Framework"],
  ["hosting", "Hosting provider"],
  ["javascript", "JavaScript libraries"],
  ["link", "Link"],
  ["mapping", "Mapping"],
  ["media", "Media"],
  ["mx", "MX records"],
  ["ns", "Nameservers"],
  ["parked", "Parked domain"],
  ["payment", "Payment processor"],
  ["server", "Web servers"],
  ["web server", "Web servers"],
  ["shipping", "Shipping"],
  ["shop", "Shop"],
  ["ssl", "SSL"],
  ["web master", "Webmaster tools"],
  ["widgets", "Widgets"],
  ["copyright", ""],
  ["css", ""],
  ["docinfo", ""],
  ["encoding", ""],
  ["feeds", ""],
  ["language", ""],
  ["mobile", ""],
  ["seo_headers", ""],
  ["seo_meta", ""],
  ["seo_title", ""],
]);

// builtWithBlocklist is a set of strings that will be used to filter out detected products.
// It utilizes exact and partial matching.
// Note that these values must be kept in sync with the backend
// see technologies/vendor_technologies.go blockedProducts and blockedProductsPartial
const builtWithBlocklist = {
  exact: new Set<string>([
    "Wordpress Plugins",
    "Invalid Certificate Dates",
    "USA and Canada Shipping",
    "Shipping",
    "Shopify No Shipping",
    "Scandinavian Shipping",
    "WooCommerce Table Rate Shipping",
  ]),
  partial: ["Shipping to ", "CrUX", "Cloudflare Radar", "Shipping Only"],
};

export const isProductBlocked = (productName: string): boolean => {
  if (builtWithBlocklist.exact.has(productName)) {
    return true;
  }
  return builtWithBlocklist.partial.some((block) =>
    productName.includes(block)
  );
};

import { Component } from "react";
import PropTypes from "prop-types";
import MonitorNewVendorModal from "./modals/MonitorNewVendorModal";
import NewVendorChoiceModal from "./modals/NewVendorChoiceModal";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import PillLabel from "./PillLabel";
import { LabelColor } from "../../_common/types/label";

import "../style/components/VendorOverlay.scss";
import { Redirect } from "react-router-dom";

class VendorOverlay extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    access: PropTypes.object,
    onAdhocLookup: PropTypes.func.isRequired,
    onWatchVendor: PropTypes.func.isRequired,
    assuranceType: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    vendorName: PropTypes.string.isRequired,
    vendorPrimaryHostname: PropTypes.string.isRequired,
    vendorId: PropTypes.number,
  };

  static defaultProps = {
    access: {},
    entrypoint: "none",
  };

  constructor(props) {
    super(props);

    const selection =
      props.location &&
      props.location.state &&
      props.location.state["selection"]
        ? props.location.state["selection"]
        : "none";

    let step = 1;
    let entryPoint = 1;
    switch (selection) {
      case "monitor":
        step = 2;
        entryPoint = 2;
        break;
      case "report":
        step = 3;
        entryPoint = 3;
        break;
      case "snapshot_noconfirm":
        step = 4;
        entryPoint = 4;
        break;
    }
    this.state = {
      step,
      entryPoint,
      selection,
      hasCalledLookup: false,
    };
  }

  componentDidMount() {
    if (this.state.step === 4 && !this.state.hasCalledLookup) {
      this.setState({ hasCalledLookup: true }, () => {
        this.props.onAdhocLookup();
      });
    }
  }

  render() {
    const { access, onAdhocLookup, onWatchVendor } = this.props;

    if (this.state.step === 4) {
      return null;
    }

    return (
      <div className="vendor-overlay">
        <NewVendorChoiceModal
          isShowing={this.state.step === 1}
          assuranceType={this.props.assuranceType}
          vendorName={this.props.vendorName}
          vendorPrimaryHostname={this.props.vendorPrimaryHostname}
          lookupsLeft={access.lookupsAvailable}
          watchSlotsLeft={access.watchSlotsAvailable}
          canWatch={access.couldWatch}
          canUseLookup={access.couldUseLookup}
          onClose={() => {
            if (this.props.history.length > 1) {
              this.props.history.goBack();
            } else {
              this.props.history.replace("/");
            }
          }}
          onNextStep={(isMonitor) => {
            if (isMonitor) {
              // Navigate to vendor onboarding page
              this.props.history.push(`/vendor/${this.props.vendorId}/edit`);
            } else {
              onAdhocLookup();
            }
          }}
        />
        {/* Go straight to the vendor onboarding page if 'monitor vendor' has been specified */}
        {this.state.step === 2 && (
          <Redirect to={`/vendor/${this.props.vendorId}/edit`} />
        )}
        <ConfirmationModalV2
          active={this.state.step === 3}
          verticalCenter
          descriptionClassName={"vendor-report-confirmation-modal"}
          title={
            <h2>
              Create snapshot
              {access.couldUseLookup && !!access.lookupsAvailable && (
                <PillLabel
                  color={LabelColor.Blue}
                  className={"confirmation-header-pill"}
                >
                  {access.lookupsAvailable} remaining
                </PillLabel>
              )}
            </h2>
          }
          description={`Access a snapshot for ${this.props.vendorName} for the next 30 days without monitoring them long-term?`}
          buttonText={"Create"}
          buttonAction={() => {
            onAdhocLookup();
            return true;
          }}
          onClose={this.props.history.goBack}
        />
      </div>
    );
  }
}

export default VendorOverlay;

import ReactMarkdown from "react-markdown";
import { ComponentProps } from "react";

// Update the component to accept all img props but only use alt
const suppressedSrcImg = ({ alt }: ComponentProps<"img">) => (
  <img alt={alt || ""} />
);

export interface IMarkdownProps {
  content: string;
}

/*
strip the src from an img element to avoid attacker embedding
images into markdown that would trigger requests from the user
machine to the attacker website
*/
const Markdown = ({ content }: IMarkdownProps) => (
  <ReactMarkdown
    components={{
      img: suppressedSrcImg,
    }}
  >
    {content}
  </ReactMarkdown>
);

export default Markdown;

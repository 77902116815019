import { useState, useRef, useEffect } from "react";
import { SimpleSearchBox } from "../../_common/components/SearchBox";
import Button from "../../_common/components/core/Button";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringSourceType,
  getChatEntries,
} from "../api/types";
import {
  useParseSnippet,
  useSnippetHighlighter,
} from "../funcs/useParseSnippet";
import "./ThreatDetailPreview.scss";
import { ThreadEntry, ThreadEntryType } from "./Thread";
import classnames from "classnames";

interface ThreatPreviewProps {
  threat: DisplayableThreatMonitoringResult;
}

export function ThreatDetailPreview({ threat }: ThreatPreviewProps) {
  let previewHiddenText: string | undefined = undefined;
  if (threat.source === ThreatMonitoringSourceType.StealerLogs) {
    previewHiddenText =
      "Preview not available for threats originating from stealer logs.";
  }

  if (previewHiddenText) {
    return (
      <div className="threat-preview-control">
        <div className="search"></div>
        <div className="snippet-hidden">{previewHiddenText}</div>
      </div>
    );
  }

  return <SnippetPreview threat={threat} />;
}

export function SnippetPreview({ threat }: ThreatPreviewProps) {
  if (threat.additionalAttributes?.chat) {
    return (
      <ChatPreview
        key={threat.uuid}
        threat={threat}
        entries={getChatEntries(threat.additionalAttributes?.chat)}
      />
    );
  }

  if (threat.additionalAttributes?.post) {
    return (
      <PostPreview
        key={threat.uuid}
        threat={threat}
        snippet={threat.additionalAttributes.post.text}
      />
    );
  }

  return (
    <PostPreview key={threat.uuid} threat={threat} snippet={threat.snippet} />
  );
}

export function ChatPreview({
  threat,
  entries,
}: ThreatPreviewProps & { entries: ThreadEntryType[] }) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    threat.keywords[0]
  );

  const snippetParent = useRef<HTMLDivElement>(null);

  const { currentHighlight, highlightCount, prev, next } =
    useSnippetHighlighter(snippetParent, searchTerm);
  // Update search term when threat changes
  useEffect(() => {
    setSearchTerm(threat.keywords[0]);
  }, [threat.uuid]);

  const snippetText = JSON.stringify(entries, null, 2);

  const highlights = searchTerm ? [searchTerm] : undefined;

  return (
    <PreviewShell
      key={threat.uuid}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      currentHighlight={currentHighlight + 1}
      totalHighlights={highlightCount}
      prev={prev}
      next={next}
      snippetText={snippetText}
    >
      <div className="snippet-preview thread" ref={snippetParent}>
        {entries.map((entry, i) => (
          <ThreadEntry key={i} entry={entry} highlight={highlights} />
        ))}
      </div>
    </PreviewShell>
  );
}

interface PostPreviewProps {
  threat: DisplayableThreatMonitoringResult;
  snippet: string;
}
export function PostPreview({ threat, snippet }: PostPreviewProps) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    threat.keywords[0]
  );
  // Update search term when threat changes
  useEffect(() => {
    setSearchTerm(threat.keywords[0]);
  }, [threat.uuid]);

  const highlights = searchTerm ? [searchTerm] : undefined;

  const lines = useParseSnippet(snippet, highlights);
  const snippetParent = useRef<HTMLDivElement>(null);

  const { currentHighlight, highlightCount, prev, next } =
    useSnippetHighlighter(snippetParent, searchTerm);

  return (
    <PreviewShell
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      currentHighlight={currentHighlight + 1}
      totalHighlights={highlightCount}
      prev={prev}
      next={next}
      snippetText={snippet}
    >
      <div className="snippet-preview snippet" ref={snippetParent}>
        {lines.map((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </div>
    </PreviewShell>
  );
}

interface PreviewShellProps {
  searchTerm: string | undefined;
  setSearchTerm: (keyword: string | undefined) => void;
  currentHighlight: number;
  totalHighlights: number;
  prev: () => void;
  next: () => void;
  children: React.ReactNode;
  snippetText: string;
}
function PreviewShell({
  searchTerm,
  setSearchTerm,
  currentHighlight,
  totalHighlights,
  prev,
  next,
  children,
  snippetText,
}: PreviewShellProps) {
  const downloadSnippet = async () => {
    try {
      const objectURL = URL.createObjectURL(
        new Blob([snippetText], {
          type: "text/plain",
        })
      );

      const a = document.createElement("a");
      a.href = objectURL;
      a.download = `snippet.txt`;

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(objectURL);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };

      a.addEventListener("click", clickHandler);
      a.click();
    } catch (e) {
      console.error(e);
    }
  };

  const _currentHighlight = totalHighlights === 0 ? 0 : currentHighlight;

  const prevArrowDisabled = totalHighlights === 0 || _currentHighlight === 1;
  const nextArrowDisabled =
    totalHighlights === 0 || _currentHighlight === totalHighlights;

  return (
    <div className="threat-preview-control">
      <div className="search">
        <div className="left">
          <SimpleSearchBox value={searchTerm || ""} onChanged={setSearchTerm} />

          <div className="highlight-controls">
            <div className="counts">
              {_currentHighlight}/{totalHighlights}
            </div>

            <div
              className={classnames("arrow", {
                disabled: prevArrowDisabled,
              })}
              onClick={prev}
            >
              <div className={"cr-icon-chevron rot270"} />
            </div>
            <div
              className={classnames("arrow", {
                disabled: nextArrowDisabled,
              })}
              onClick={next}
            >
              <div className={"cr-icon-chevron rot90"} />
            </div>
          </div>
        </div>
        <div className="right">
          <Button tertiary onClick={downloadSnippet}>
            <div className={"cr-icon-export-thin"} />
            Download
          </Button>
        </div>
      </div>

      {children}
    </div>
  );
}

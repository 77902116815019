import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useCallback, useMemo, useReducer, useState } from "react";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { surveyType } from "../../../_common/types/surveyTypes";
import Button from "../../../_common/components/core/Button";
import "../../style/AddQuestionnairesModal.scss";
import StartNewQuestionnaireComponent from "./components/StartNewQuestionnaireComponent";
import ImportQuestionnaireComponent from "./components/ImportQuestionnaireComponent";
import SelectExistingQuestionnairesComponent from "./components/SelectExistingQuestionnairesComponent";
import { ISurveyListItemResponse } from "../../../_common/types/survey";
import { trackEvent } from "../../../_common/tracking";
import ImportQuestionnaireModalConfigureStep from "../../../vendor_portal/components/ImportQuestionnaireModalConfigureStep";
import SurveyImportAPI from "../../../vendor_portal/api/surveyImportAPI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { chatGPTAccessSelector } from "../../../_common/chatgpt";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  addExistingSurveyToSharedAssessment,
  addNewSurveyToSharedAssessment,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import { fetchSurveyDetails } from "../../../_common/reducers/surveyDetails.actions";
import TrustPageAPI, { TrustPageTagTypes } from "../../api/trustpage.api";
import {
  useHasUserOrPortfolioPermissions,
  UserWriteSurveyImportExport,
} from "../../../_common/permissions";
import {
  useDefaultHistory,
  useDefaultLocation,
} from "../../../_common/types/router";
import { getSurveyEditorPath } from "../../../_common/views/SurveyDetails";

interface AddQuestionnairesModalProps extends BaseModalProps {
  surveyTypes: surveyType[];
  receivedSurveys: ISurveyListItemResponse[];
}

type addQuestionnaireModalMode =
  | "new"
  | "existing"
  | "import_upload"
  | "import_configure";

export interface newSurveyData {
  name: string;
  description?: string;
  surveyType: surveyType;
  sectionIds: number[];
  isValid: boolean;
}

const isValidNewSurveyData = (data?: newSurveyData) => {
  return (
    data && data.isValid && data.name.length > 3 && data.sectionIds.length > 0
  );
};

export interface importSurveyData {
  file: File;
  surveyName: string;
  fromCompany: string;
  dueDate?: string;
  isValid: boolean;
}

const isValidImportSurveyData = (data?: importSurveyData) => {
  return data && data.isValid && data.file;
};

export interface configureImportedSurveyData {
  uuid: string;
  surveyName: string;
  fromCompany: string;
  dueDate?: string;
}

type AddQuestionnairesModalState = {
  mode: addQuestionnaireModalMode;
  newSurveyData?: newSurveyData;
  selectedExistingSurveyIds?: number[];
  importSurveyData?: importSurveyData;
  configureImportedSurveyData?: configureImportedSurveyData;
};

type Action =
  | { type: "SWITCH_MODE"; mode: addQuestionnaireModalMode }
  | { type: "SET_NEW_SURVEY_DATA"; data: newSurveyData }
  | { type: "SET_SELECTED_EXISTING"; ids: number[] }
  | { type: "SET_IMPORT_SURVEY_DATA"; data: importSurveyData }
  | {
      type: "SET_IMPORT_SURVEY_CONFIGURATION";
      data: configureImportedSurveyData;
    };

function reducer(
  state: AddQuestionnairesModalState,
  action: Action
): AddQuestionnairesModalState {
  switch (action.type) {
    case "SWITCH_MODE":
      return { mode: action.mode };
    case "SET_NEW_SURVEY_DATA":
      return { ...state, newSurveyData: action.data };
    case "SET_SELECTED_EXISTING":
      return { ...state, selectedExistingSurveyIds: action.ids };
    case "SET_IMPORT_SURVEY_DATA":
      return { ...state, importSurveyData: action.data };
    case "SET_IMPORT_SURVEY_CONFIGURATION":
      return { ...state, configureImportedSurveyData: action.data };
    default:
      return state;
  }
}

const AddQuestionnairesModal: FC<AddQuestionnairesModalProps> = ({
  active,
  onClose,
  surveyTypes,
  receivedSurveys,
}: AddQuestionnairesModalProps) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory();
  const location = useDefaultLocation();
  const { shouldDisplayChatGPT } = useAppSelector(chatGPTAccessSelector);
  const userHasWriteSurveyImportExportPermission =
    useHasUserOrPortfolioPermissions(UserWriteSurveyImportExport) ?? false;

  const [loading, setLoading] = useState(false);
  const initialMode = receivedSurveys.length > 0 ? "existing" : "new";

  const [createImportedSurveyStep1] =
    SurveyImportAPI.useCreateImportedSurveyStep1Mutation();

  const [modalState, modalReducer] = useReducer(reducer, {
    mode: initialMode,
  });

  const dispatchAction = useCallback(
    <T extends Action>(action: T) => {
      modalReducer(action);
    },
    [modalReducer]
  );

  const handleActions = useMemo(
    () => ({
      onModeChange: (modeToSet: addQuestionnaireModalMode) =>
        dispatchAction({ type: "SWITCH_MODE", mode: modeToSet }),

      existingSurveysSelected: (ids: number[]) =>
        dispatchAction({ type: "SET_SELECTED_EXISTING", ids }),

      importSurveyFormChanges: (importSurveyData: importSurveyData) =>
        dispatchAction({
          type: "SET_IMPORT_SURVEY_DATA",
          data: importSurveyData,
        }),

      configureSurveyChanges: (data: configureImportedSurveyData) =>
        dispatchAction({ type: "SET_IMPORT_SURVEY_CONFIGURATION", data }),

      newQuestionnaireDataChanges: (data: newSurveyData) => {
        const sectionIds = data.sectionIds.length
          ? data.surveyType.sections
              .filter((section) => data.sectionIds.includes(section.id))
              .map((section) => section.id)
          : data.sectionIds;

        dispatchAction({
          type: "SET_NEW_SURVEY_DATA",
          data: { ...data, sectionIds },
        });
      },

      onClose: () => {
        dispatchAction({ type: "SWITCH_MODE", mode: initialMode });
        onClose();
      },
    }),
    [dispatchAction, onClose, initialMode] // Dependencies
  );

  const canSubmit = () => {
    if (!modalState.mode) {
      return false;
    }
    switch (modalState.mode) {
      case "new":
        return isValidNewSurveyData(modalState.newSurveyData);
      case "existing":
        return (
          modalState.selectedExistingSurveyIds &&
          modalState.selectedExistingSurveyIds.length > 0
        );
      case "import_upload":
        return isValidImportSurveyData(modalState.importSurveyData);
      case "import_configure":
        return isValidImportSurveyData(modalState.importSurveyData);
    }
  };

  const onSubmitDocumentUpload = useCallback(
    async (file: File, acceptedTerms: boolean) => {
      if (!modalState.importSurveyData) {
        return;
      }

      const { surveyName, fromCompany, dueDate } = modalState.importSurveyData;
      try {
        const response = await createImportedSurveyStep1({
          file,
          aiTermsAccepted: acceptedTerms,
        }).unwrap();
        handleActions.onModeChange("import_configure");
        handleActions.configureSurveyChanges({
          uuid: response.surveyImportUUID,
          surveyName,
          fromCompany,
          dueDate,
        });
      } catch (e) {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error submitting document for processing"
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [modalState, createImportedSurveyStep1, handleActions, dispatch, setLoading]
  );

  const handleAddNewSurvey = useCallback(
    async (data: newSurveyData) => {
      setLoading(true);
      try {
        const newPrefilledDraftSurveyId = await dispatch(
          addNewSurveyToSharedAssessment(
            data.surveyType.id,
            data.sectionIds,
            data.name,
            data.description
          )
        );
        dispatch(
          TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
        );
        dispatch(addDefaultSuccessAlert("Successfully added questionnaire"));
        // navigate user to start answering the questionnaire
        if (newPrefilledDraftSurveyId && newPrefilledDraftSurveyId > 0) {
          history.push(
            getSurveyEditorPath({
              surveyId: newPrefilledDraftSurveyId,
              editMode: true,
              publicSurvey: true,
              location,
              backContext: {
                backTo: `/trustpage/surveys/${newPrefilledDraftSurveyId}`,
                backToText: "Back to questionnaire details",
              },
            })
          );
        }
      } catch (e) {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error adding questionnaire"));
      } finally {
        setLoading(false);
        onClose();
      }
    },
    [setLoading, dispatch, addNewSurveyToSharedAssessment]
  );

  const handleAddExistingSurveys = useCallback(
    async (surveyIds: number[]) => {
      try {
        setLoading(true);
        await Promise.all(
          surveyIds.map(async (surveyId) => {
            await dispatch(addExistingSurveyToSharedAssessment(surveyId));
            await Promise.all([
              dispatch(fetchSurveyDetails(surveyId, true)),
              dispatch(
                TrustPageAPI.util.invalidateTags([
                  TrustPageTagTypes.ownTrustPage,
                ])
              ),
            ]);
            dispatch(
              addDefaultSuccessAlert("Successfully added questionnaire")
            );
          })
        );
        onClose();
      } catch (e) {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error adding questionnaires to Trust Page"
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, setLoading, onClose]
  );

  const onSubmit = useCallback(async () => {
    switch (modalState.mode) {
      case "new":
        if (modalState.newSurveyData) {
          await handleAddNewSurvey(modalState.newSurveyData);
        }
        break;
      case "existing":
        if (modalState.selectedExistingSurveyIds?.length) {
          await handleAddExistingSurveys(modalState.selectedExistingSurveyIds);
        }
        break;
      case "import_upload":
        if (modalState.importSurveyData?.file) {
          await onSubmitDocumentUpload(
            modalState.importSurveyData.file,
            shouldDisplayChatGPT
          );
        }
        break;
    }
  }, [modalState, shouldDisplayChatGPT]);

  return (
    <>
      <ModalV2
        className="add-questionnaires-modal"
        width={800}
        active={active && modalState.mode !== "import_configure"}
        onClose={handleActions.onClose}
        headerContent="Add questionnaire"
        disallowClickOutside={loading}
        footerContent={
          <div className="btn-group">
            <Button tertiary onClick={handleActions.onClose}>
              Cancel
            </Button>
            {modalState.mode === "existing" && (
              <Button
                primary
                disabled={!canSubmit()}
                onClick={onSubmit}
                loading={loading}
              >
                Add to Trust Page
              </Button>
            )}
            {modalState.mode === "new" && (
              <Button
                primary
                disabled={!canSubmit()}
                onClick={onSubmit}
                loading={loading}
              >
                Start questionnaire
              </Button>
            )}
            {modalState.mode === "import_upload" && (
              <Button
                primary
                disabled={!canSubmit()}
                onClick={onSubmit}
                loading={loading}
              >
                Next
              </Button>
            )}
            {modalState.mode === "import_configure" && (
              <Button
                primary
                disabled={!canSubmit()}
                onClick={onSubmit}
                loading={loading}
              >
                Save
              </Button>
            )}
          </div>
        }
      >
        <h3>What do you want to do?</h3>
        <div className={"questionnaire-type-selector"}>
          {receivedSurveys.length > 0 && (
            <ColorCheckbox
              radio
              checked={modalState.mode === "existing"}
              onClick={() => handleActions.onModeChange("existing")}
              label="Use an existing questionnaire"
            />
          )}
          {surveyTypes.length > 0 && (
            <ColorCheckbox
              radio
              checked={modalState.mode === "new"}
              onClick={() => handleActions.onModeChange("new")}
              label="Start a new, blank questionnaire"
            />
          )}
          {userHasWriteSurveyImportExportPermission && (
            <ColorCheckbox
              radio
              checked={modalState.mode === "import_upload"}
              onClick={() => handleActions.onModeChange("import_upload")}
              label="Import questionnaire"
            />
          )}
        </div>

        {surveyTypes.length > 0 && modalState.mode === "new" && (
          <div className={"add-questionnaire-inner"}>
            <StartNewQuestionnaireComponent
              surveyTypes={surveyTypes}
              onFormChange={handleActions.newQuestionnaireDataChanges}
            />
          </div>
        )}
        {receivedSurveys.length > 0 && modalState.mode === "existing" && (
          <div className={"select-existing-questionnaire-inner"}>
            <SelectExistingQuestionnairesComponent
              surveys={receivedSurveys}
              selectedSurveyIds={modalState.selectedExistingSurveyIds ?? []}
              onSelectedSurveysChange={handleActions.existingSurveysSelected}
            />
          </div>
        )}
        {modalState.mode === "import_upload" && (
          <div className={"import-questionnaire-inner"}>
            <ImportQuestionnaireComponent
              onFormChange={handleActions.importSurveyFormChanges}
            />
          </div>
        )}
      </ModalV2>

      <ImportQuestionnaireModalConfigureStep
        active={active && modalState.mode === "import_configure"}
        onClose={() => {
          trackEvent("QuestionnaireImportExport_ImportModalClosed");
          onClose();
        }}
        surveyImportUUID={modalState.configureImportedSurveyData?.uuid}
        surveyName={modalState.configureImportedSurveyData?.surveyName}
        fromCompany={modalState.configureImportedSurveyData?.fromCompany}
        dueDate={modalState.configureImportedSurveyData?.dueDate}
        includeToTrustPage={true}
      />
    </>
  );
};

export default AddQuestionnairesModal;

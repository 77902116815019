import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/securityprofile/ControlCheckPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import VendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import { LabelColor } from "../../../_common/types/label";
import PillLabel from "../PillLabel";
import SeverityIcon, {
  AdjustedSeverityIcon,
} from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { sortBy, upperFirst } from "lodash";
import moment from "moment";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { HoverLocation } from "../../../_common/components/IconButton";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import {
  CheckWithSource,
  ControlState,
  DocTypeFromID,
  DocTypeStringFromID,
  SecurityProfileDocumentID,
} from "../../types/securityProfile";
import RiskVendorDomains from "../RiskVendorDomains";
import { useHistory } from "react-router-dom";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { useDefaultLocation } from "../../../_common/types/router";
import {
  useBasicPermissions,
  UserManageVendorRiskWaivers,
  UserVendorRiskWrite,
} from "../../../_common/permissions";
import { useVendorURLPrefix } from "../../../_common/hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchRiskVendorWebsites,
  getRiskVendorWebsites,
} from "../../reducers/vendorRiskPortfolio.actions";
import { useVendorDataSelector } from "../../../_common/selectors/vendorSelectors";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import Button, { TooltipButton } from "../../../_common/components/core/Button";
import ManageRiskButton from "../risk_profile/ManageRiskButton";
import { RiskSource } from "../../../_common/types/risks";

interface CheckWithCitation {
  citations: { text: string }[];
  documentID: SecurityProfileDocumentID;
  scanCheckID?: number;
  publicScanCheckID?: number;
}

interface CitationSectionProps {
  checks: CheckWithCitation[];
  vendorId: number;
  overrideCheckResult?: (
    scanCheckID?: number,
    publicScanCheckID?: number
  ) => void;
}

export const CitationSection = ({
  checks,
  vendorId,
  overrideCheckResult,
}: CitationSectionProps) => {
  const { data: documents } =
    VendorSecurityProfileAPI.useGetSecurityDocumentsQuery({ vendorId });

  const urlPrefix = useVendorURLPrefix(vendorId);

  const onClickDocument = useCallback(
    (docID: SecurityProfileDocumentID) => {
      const docType = DocTypeFromID(docID);
      const id = docID.split(":")[1];

      switch (docType) {
        case "PrivateSurvey":
          window.open(`${urlPrefix}/surveys/${id}`, "_blank");
          break;
        case "AdditionalEvidence":
          window.open(`${urlPrefix}/evidence/details/${id}`, "_blank");
          break;
      }
    },
    [urlPrefix]
  );

  return (
    <div className="citations-table">
      <XTable
        iconOptions={!!overrideCheckResult}
        rows={checks.map((check) => {
          // find the document from our security profile documents
          const document = documents?.availableDocuments.find(
            (d) => check.documentID == d.id
          );

          return {
            id: `${check.documentID}`,
            cells: [
              <XTableCell key="citation" className="citation">
                <div className="document">
                  {["PrivateSurvey", "AdditionalEvidence"].includes(
                    DocTypeFromID(check.documentID)
                  ) ? (
                    <a
                      onClick={() => onClickDocument(check.documentID)}
                      className="name"
                    >
                      {document?.name}
                    </a>
                  ) : (
                    <div>{document?.name}</div>
                  )}

                  <div className="source">{`${DocTypeStringFromID(
                    check.documentID
                  )}, Uploaded by ${document?.author}, ${moment(
                    document?.lastUpdated
                  ).format(timeFormat)}`}</div>
                </div>
                {check.citations.map((c) => (
                  <div key={c.text} className={"extract"}>
                    {c.text}
                  </div>
                ))}
                {/*{check.question && check.answer && (*/}
                {/*  <>*/}
                {/*    <div className="question">*/}
                {/*      <span className="label">Q</span>*/}
                {/*      <span className="text">{check.question}</span>*/}
                {/*    </div>*/}
                {/*    <div className="answer">*/}
                {/*      <span className="label">A</span>*/}
                {/*      <span className="text">{check.answer}</span>*/}
                {/*    </div>*/}
                {/*  </>*/}
                {/*)}*/}
              </XTableCell>,
            ],
            iconOptions: overrideCheckResult
              ? [
                  {
                    id: `${check.documentID}-action`,
                    icon: <i className={"cr-icon-dots-menu"} />,
                    dropdownItems: [
                      <DropdownItem
                        onClick={() =>
                          overrideCheckResult(
                            check.scanCheckID,
                            check.publicScanCheckID
                          )
                        }
                        key="reject-citation"
                      >
                        <div className="dropdown-with-text">
                          <div className="dropdown-title">Reject citation</div>
                          <div className="dropdown-text">
                            Excludes this specific citation from scanning
                          </div>
                        </div>
                      </DropdownItem>,
                      <DropdownItem key="archive-document">
                        <div className="dropdown-with-text">
                          <div className="dropdown-title">Archive document</div>
                          <div className="dropdown-text">
                            Excludes entire document from scanning
                          </div>
                        </div>
                      </DropdownItem>,
                    ],
                    hoverText: "Manage this citation",
                    hoverLocation: HoverLocation.Left,
                  },
                ]
              : undefined,
          };
        })}
      />
    </div>
  );
};

interface IControlCheckPanelProps {
  onClickBack?: () => void;
  vendorId: number;
  checkId: string;
  scanRiskId?: string;
}

const timeFormat = "D MMM YYYY HH:mm";

const ControlCheckPanel = ({
  checkId,
  onClickBack,
  vendorId,
  scanRiskId,
}: IControlCheckPanelProps) => {
  const history = useHistory();
  const location = useDefaultLocation();
  const dispatch = useAppDispatch();

  const controlId = checkId.substring(0, 8);

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  const { data: controlData, isFetching: controlLoading } =
    VendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });
  const control = controlData?.control;

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const scanningRisks = securityProfile?.scanningChecks[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];

  const scanningRisk = useMemo(() => {
    if (scanningRisks && scanRiskId) {
      return scanningRisks.find((s) => s.id == scanRiskId);
    }
    return undefined;
  }, []);

  // fetch data
  useEffect(() => {
    if (scanningRisk) {
      dispatch(
        fetchRiskVendorWebsites(scanningRisk.id, vendorId, false, true, true)
      );
    }
  }, [scanningRisk, vendorId]);

  const riskVendorWebsites = useAppSelector((state) =>
    scanningRisk
      ? getRiskVendorWebsites(
          state,
          scanningRisk.id,
          vendorId,
          false,
          false,
          undefined
          // TODO - do we need to take filters into accoun there?
        )
      : undefined
  );

  const scoredAt = useVendorDataSelector(
    (state) => state.watching.result?.scoredAt,
    vendorId
  );

  // find the 'scannedAt' date, this is a fudge
  // if there's a scanning risk try to find the most recent time we scanned an affected domain
  // if its a doc risk its when the doc was scanned
  // just default to the last scored time for the vendor otherwise
  const scannedAt = useMemo(() => {
    if (scanningRisk) {
      if (
        riskVendorWebsites &&
        riskVendorWebsites.data?.riskDetailsByHostname
      ) {
        const scanDates = sortBy(
          Object.values(riskVendorWebsites.data.riskDetailsByHostname),
          [(r) => r.scannedAt]
        );

        if (scanDates.length == 0) {
          if (securityProfile?.lastPrimaryScanTime) {
            return securityProfile.lastPrimaryScanTime;
          }

          return scoredAt;
        }

        return scanDates[0].scannedAt;
      }

      return undefined;
    } else if (documentCheck) {
      const sorted = sortBy(documentCheck, [(d) => d.scannedAt]);
      if (sorted.length == 0) {
        return undefined;
      }

      return sorted[0].scannedAt;
    }

    if (securityProfile?.lastPrimaryScanTime) {
      return securityProfile.lastPrimaryScanTime;
    }
    return scoredAt;
  }, [
    scoredAt,
    scanningRisk,
    documentCheck,
    riskVendorWebsites,
    securityProfile?.lastPrimaryScanTime,
  ]);

  const remediation = useVendorDataSelector(
    (state) =>
      state.summary?.result?.cloudscansInRemediation?.[scanRiskId ?? ""],
    vendorId
  );

  const urlPrefix = useVendorURLPrefix(vendorId);

  const permissions = useBasicPermissions();
  const userHasWriteWaiversPermission =
    permissions.userPermissions[UserManageVendorRiskWaivers];
  const userHasWriteRemediationPermission =
    permissions.userPermissions[UserVendorRiskWrite];

  const [overrideDocumentCheckResult] =
    vendorSecurityProfileAPI.useOverrideDocumentCheckResultV1Mutation();
  const [overrideLoading, setOverrideLoading] = useState(false);

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const overrideCheckResult = useCallback(
    (scanCheckID?: number, publicScanCheckID?: number) => {
      const onlyCheck = documentCheck && documentCheck.length == 1;

      openConfirmationModal({
        buttonAction: () => {
          setOverrideLoading(true);
          return overrideDocumentCheckResult({
            vendorId,
            checks: [
              {
                privateId: scanCheckID,
                publicId: publicScanCheckID,
              },
            ],
          })
            .unwrap()
            .then(() => {
              dispatch(addDefaultSuccessAlert("Rejected citation"));
            })
            .catch(() => {
              dispatch(addDefaultUnknownErrorAlert("Error rejecting citation"));
            })
            .finally(() => {
              setOverrideLoading(false);
            });
        },
        buttonText: "Reject citation",
        dangerousAction: true,
        title: "Are you sure you want to reject this citation?",
        description: (
          <>
            {onlyCheck && (
              <p>
                This is the only citation associated with this check. Rejecting
                it will revert its status to “Requires evidence”.
              </p>
            )}
            <p>This action cannot be undone.</p>
          </>
        ),
      });
    },
    [vendorId, documentCheck]
  );

  const rejectResult = useCallback((documentChecks: CheckWithSource[]) => {
    openConfirmationModal({
      buttonAction: () => {
        setOverrideLoading(true);
        return overrideDocumentCheckResult({
          vendorId,
          checks: documentChecks.map((c) => ({
            privateId: c.scanCheckID,
            publicId: c.publicScanCheckID,
          })),
        })
          .unwrap()
          .then(() => {
            dispatch(addDefaultSuccessAlert("Rejected citation"));
          })
          .catch(() => {
            dispatch(addDefaultUnknownErrorAlert("Error rejecting citation"));
          })
          .finally(() => {
            setOverrideLoading(false);
          });
      },
      dangerousAction: true,
      buttonText: "Reject check results",
      title: "Are you sure you want to reject results of this check?",
      description: (
        <>
          <p>
            Rejecting it will revert it’s status to “Evidence required”.
            Citations for this check will also be rejected.
          </p>

          <p>This action cannot be undone.</p>
        </>
      ),
    });
  }, []);

  if (controlLoading || overrideLoading || !control || !check) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  return (
    <div className="check-panel">
      {!!onClickBack && <BackArrow popup={"Back"} onClick={onClickBack} />}
      <div className="check-header">
        <div className="title">
          {scanningRisk ? scanningRisk.title : check.text}
        </div>
        {checkState === ControlState.Failed &&
          scanRiskId &&
          !scanningRisk?.isWaived && (
            <ManageRiskButton
              autoCloseOnMouseLeave
              hideAdjust={!scanningRisk}
              hideRemediate={!!remediation}
              onRemediate={
                userHasWriteRemediationPermission
                  ? () => {
                      history.push(`${urlPrefix}/remediation/add`, {
                        backContext: {
                          goBack: true,
                          backToText: "Back to security profile",
                        },
                        riskId: scanRiskId,
                        riskType: RiskSource.Cloudscan,
                      });
                    }
                  : undefined
              }
              onWaive={
                userHasWriteWaiversPermission
                  ? () => {
                      history.push(
                        `${urlPrefix}/riskwaivers/add?initialRiskId=${scanRiskId}`,
                        {
                          backContext: {
                            goBack: true,
                            backToText: `Back to security profile`,
                          },
                        }
                      );
                    }
                  : undefined
              }
            />
          )}
        {!!scanningRisk?.isWaived && (
          <TooltipButton
            primary
            disabled
            tooltipContent={"Manage risk not available for waived risks"}
            popupPosition="left"
            popupNoWrap
          >
            <div className="manage-risk-text">Manage risk</div>
            <div className="cr-icon-chevron rot90" />
          </TooltipButton>
        )}
      </div>
      <ScrollableDiv newStyles>
        <CheckItemSlidePanelSection title="Check details" startExpanded={true}>
          <div className="detail-table">
            <InfoTable>
              <InfoTableRow
                label="RESULT"
                rowClass="detail-row"
                valueClass="status-value"
                value={
                  <>
                    {checkState === ControlState.Passed && (
                      <>
                        {scanningRisk?.isWaived ? (
                          <PillLabel color={LabelColor.Grey}>
                            Risk waived
                          </PillLabel>
                        ) : (
                          <PillLabel color={LabelColor.Green}>
                            Check passed
                          </PillLabel>
                        )}
                      </>
                    )}
                    {checkState === ControlState.Failed && (
                      <PillLabel color={LabelColor.Grey}>
                        Risk detected
                      </PillLabel>
                    )}
                    {checkState === ControlState.Unknown && (
                      <PillLabel color={LabelColor.Grey}>
                        Evidence required
                      </PillLabel>
                    )}
                  </>
                }
                thirdCol={
                  checkState != ControlState.Unknown &&
                  documentCheck && (
                    <Button
                      tertiary
                      onClick={() => rejectResult(documentCheck)}
                    >
                      <i className={"cr-icon-cancel"} /> Reject result
                    </Button>
                  )
                }
              />
              {checkState == ControlState.Failed && (
                <InfoTableRow
                  rowClass="detail-row"
                  label="RISK SEVERITY"
                  value={
                    scanningRisk ? (
                      <>
                        <AdjustedSeverityIcon
                          severity={SeverityAsString(scanningRisk.severity)}
                          baseSeverity={
                            scanningRisk.baseSeverity
                              ? SeverityAsString(scanningRisk.baseSeverity)
                              : undefined
                          }
                        />
                        {upperFirst(SeverityAsString(scanningRisk.severity))}
                      </>
                    ) : (
                      <SeverityIcon
                        severity={SeverityAsString(check.severity)}
                      />
                    )
                  }
                />
              )}
              <InfoTableRow
                rowClass="detail-row"
                label="LAST SCANNED"
                value={scannedAt ? moment(scannedAt).format(timeFormat) : "-"}
              />
            </InfoTable>
          </div>
        </CheckItemSlidePanelSection>
        {scanningRisk?.summary && (
          <CheckItemSlidePanelSection title="Summary" startExpanded={true}>
            <p>{scanningRisk.summary}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk?.riskDetails && (
          <CheckItemSlidePanelSection title="Risk details" startExpanded={true}>
            <p>{scanningRisk.riskDetails}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk?.recommendedRemediation && (
          <CheckItemSlidePanelSection
            title="Recommended remediation"
            startExpanded={false}
          >
            <p>{scanningRisk.recommendedRemediation}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk && (
          <CheckItemSlidePanelSection
            title={"Assets affected"}
            startExpanded={false}
          >
            <RiskVendorDomains
              riskId={scanningRisk.id}
              vendorId={vendorId}
              onClickDomain={(
                _riskId: string,
                _vendorId: number | undefined,
                hostname: string
              ) => {
                OpenSidePanel(
                  history,
                  {
                    scan: hostname,
                  },
                  check.text,
                  location
                );
              }}
              userHasWriteRiskWaiversPermission={userHasWriteWaiversPermission}
              onRequestRemediation={
                userHasWriteRemediationPermission && !remediation
                  ? (hostname) => {
                      history.push(`${urlPrefix}/remediation/add`, {
                        backContext: {
                          goBack: true,
                          backToText: "Back to security profile",
                        },
                        filterForWebsite: hostname,
                        preselectedRisks: [{ checkId: scanningRisk.id }],
                      });
                    }
                  : undefined
              }
              onCreateWaiver={
                userHasWriteWaiversPermission
                  ? (hostname) => {
                      history.push(
                        `${urlPrefix}/riskwaivers/add?initialRiskId=${scanningRisk.id}&hostname=${hostname}`,
                        {
                          backContext: {
                            goBack: true,
                            backToText: "Back to security profile",
                          },
                        }
                      );
                    }
                  : undefined
              }
              loadWaived={false}
              showWaived={false}
              isWaived={false}
              isSubsidiary={false}
            />
          </CheckItemSlidePanelSection>
        )}
        {documentCheck && documentCheck.length > 0 && (
          <CheckItemSlidePanelSection
            title={`Citations (${documentCheck.length})`}
            startExpanded={true}
          >
            <CitationSection
              checks={documentCheck}
              vendorId={vendorId}
              overrideCheckResult={overrideCheckResult}
            />
          </CheckItemSlidePanelSection>
        )}
      </ScrollableDiv>
      {confirmationModal}
    </div>
  );
};

export default ControlCheckPanel;

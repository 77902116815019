import { FC } from "react";
import ReportCard from "../../../../_common/components/ReportCard";
import ScrollableDiv from "../../../../vendorrisk/components/ScrollableDiv";
import { UserApp, UserScopeApp } from "../../../api/types";
import UserBaseAPI from "../../../api/userbase.api";
// import { useLocation } from "react-router-dom";
import { CheckItemSlidePanelSection } from "../../../../vendorrisk/components/filter/SlidePanelSection";
import SlidePanelKVTable from "../../SlidePanelKVTable";
import ScopeRiskLevelDisplay from "../../permissions/ScopeRiskLevelDisplay";
import {
  getPermissionCategoryDisplayInformation,
  getPermissionReadWriteDisplayInformation,
} from "../../../helpers/scopes";
import PillLabel from "../../../../vendorrisk/components/PillLabel";
import { useDefaultHistory } from "../../../../_common/types/router";
import { appSummaryUrl, userPermissionsUrl } from "../../../UserBaseAppRouter";
import UserAppsTable from "../UserAppsTable";
import "./UserPermissionAppsSlidePanel.scss";

interface UserPermissionAppsSlideTableProps {
  userUUID: string;
  scopeApp: UserScopeApp;
}
const UserPermissionAppsSlideTable: FC<UserPermissionAppsSlideTableProps> = ({
  userUUID,
  scopeApp,
}) => {
  const history = useDefaultHistory();

  const onViewApplication = (app: UserApp) => {
    history.push(appSummaryUrl(app.name), {
      backContext: {
        backTo: userPermissionsUrl(userUUID),
        backToText: `Back to users permissions`,
      },
    });
  };

  const appNames = scopeApp.appNames;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserAppsV1Query({
    userUUID,
    appNames,
  });

  const appsToShow = data?.apps ?? [];

  const appsNumberString = isLoading
    ? "Apps detected"
    : `Apps detected (${appsToShow.length})`;

  const { displayString: scopeCategoryToDisplay, color: scopeCategoryColor } =
    getPermissionCategoryDisplayInformation(scopeApp.category);

  const { displayString: scopeReadWriteToDisplay, color: scopeReadWriteColor } =
    getPermissionReadWriteDisplayInformation(scopeApp.isReadWrite);

  return (
    <ReportCard newStyles className="userbase-user-permission-apps-slide-panel">
      <div className="header">{scopeApp.description}</div>

      <div className="content">
        <ScrollableDiv newStyles>
          <CheckItemSlidePanelSection
            title={"Permission overview"}
            startExpanded={true}
          >
            <SlidePanelKVTable
              rows={[
                {
                  key: "Exposure Level",
                  value: (
                    <ScopeRiskLevelDisplay riskLevel={scopeApp.riskLevel} />
                  ),
                },
                {
                  key: "Scope",
                  value: <div>{scopeApp.description}</div>,
                },
                {
                  key: "Source",
                  value: <div>{scopeApp.name}</div>,
                },
                {
                  key: "Category",
                  value: (
                    <PillLabel color={scopeCategoryColor}>
                      {scopeCategoryToDisplay}
                    </PillLabel>
                  ),
                },
                {
                  key: "Read/Write?",
                  value: (
                    <PillLabel color={scopeReadWriteColor}>
                      {scopeReadWriteToDisplay}
                    </PillLabel>
                  ),
                },
              ]}
            />
          </CheckItemSlidePanelSection>

          <div className="apps-table">
            <CheckItemSlidePanelSection
              title={appsNumberString}
              startExpanded={true}
            >
              <UserAppsTable
                isLoading={false}
                appsToShow={appsToShow}
                onClick={onViewApplication}
                mode="narrow"
              />
            </CheckItemSlidePanelSection>
          </div>
        </ScrollableDiv>
      </div>
    </ReportCard>
  );
};

export default UserPermissionAppsSlideTable;

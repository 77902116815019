import { History } from "history";
import {
  ConsumeQueryParams,
  parseBooleanQueryParamValue,
} from "../../_common/query";
import { UserRiskFilters } from "../../vendorrisk/components/filter/types";
import moment from "moment";

export const parseUserRiskFilterFromQuery = (
  history: History
): Partial<UserRiskFilters> => {
  const filterParamKeys: (keyof UserRiskFilters)[] = [
    "userRiskUserUUIDs",
    "userRiskExcludeUsers",
    "userRiskAppNames",
    "userRiskExcludeApps",
    "userRiskTeams",
    "userRiskExcludeTeams",
    "userRiskRoles",
    "userRiskExcludeRoles",
    "userRiskAppStatuses",
    "userRiskExcludeStatuses",
    "userRiskMinFirstDetected",
    "userRiskMaxFirstDetected",
    "userRiskMinRiskLevel",
    "userRiskMaxRiskLevel",
    "userRiskScopeReadOnly",
    "userRiskScopeReadWrite",
    "userRiskScopeMinRiskLevel",
    "userRiskScopeMaxRiskLevel",
    "userRiskScopeCategories",
    "userRiskExcludeScopeCategories",
    "userRiskUserMinScore",
    "userRiskUserMaxScore",
    "userRiskAppMinScore",
    "userRiskAppMaxScore",
    "userRiskAppCategories",
    "userRiskExcludeAppCategories",
  ];
  const params = ConsumeQueryParams(history, filterParamKeys);
  return {
    ...params,
    userRiskUserUUIDs: toArrayParam(params.userRiskUserUUIDs),
    userRiskAppNames: toArrayParam(params.userRiskAppNames),
    userRiskTeams: toArrayParam(params.userRiskTeams),
    userRiskRoles: toArrayParam(params.userRiskRoles),
    userRiskAppStatuses: toArrayParam(params.userRiskAppStatuses),
    userRiskScopeCategories: toArrayParam(params.userRiskScopeCategories),
    userRiskExcludeScopeCategories: parseBooleanQueryParamValue(
      params.userRiskExcludeScopeCategories
    ),
    userRiskExcludeUsers: parseBooleanQueryParamValue(
      params.userRiskExcludeUsers
    ),
    userRiskExcludeApps: parseBooleanQueryParamValue(
      params.userRiskExcludeApps
    ),
    userRiskExcludeTeams: parseBooleanQueryParamValue(
      params.userRiskExcludeTeams
    ),
    userRiskExcludeRoles: parseBooleanQueryParamValue(
      params.userRiskExcludeRoles
    ),
    userRiskExcludeStatuses: parseBooleanQueryParamValue(
      params.userRiskExcludeStatuses
    ),
    userRiskFirstDetectedBefore:
      !!params.userRiskMaxFirstDetected && !params.userRiskMinFirstDetected,
    userRiskFirstDetectedBetween:
      !!params.userRiskMaxFirstDetected && !!params.userRiskMinFirstDetected,
    userRiskMinFirstDetected: !!params.userRiskMinFirstDetected
      ? moment(params.userRiskMinFirstDetected).toISOString()
      : undefined,
    userRiskMaxFirstDetected: !!params.userRiskMaxFirstDetected
      ? moment(params.userRiskMaxFirstDetected).toISOString()
      : undefined,
    userRiskMinRiskLevel: !!params.userRiskMinRiskLevel
      ? parseInt(params.userRiskMinRiskLevel)
      : undefined,
    userRiskMaxRiskLevel: !!params.userRiskMaxRiskLevel
      ? parseInt(params.userRiskMaxRiskLevel)
      : undefined,
    userRiskUserMinScore: !!params.userRiskUserMinScore
      ? parseInt(params.userRiskUserMinScore)
      : params.userRiskUserMinScore,
    userRiskUserMaxScore: !!params.userRiskUserMaxScore
      ? parseInt(params.userRiskUserMaxScore)
      : params.userRiskUserMaxScore,
    userRiskAppMinScore: !!params.userRiskAppMinScore
      ? parseInt(params.userRiskAppMinScore)
      : params.userRiskAppMinScore,
    userRiskAppMaxScore: !!params.userRiskAppMaxScore
      ? parseInt(params.userRiskAppMaxScore)
      : params.userRiskAppMaxScore,
    userRiskScopeMinRiskLevel: !!params.userRiskScopeMinRiskLevel
      ? parseInt(params.userRiskScopeMinRiskLevel)
      : undefined,
    userRiskScopeMaxRiskLevel: !!params.userRiskScopeMaxRiskLevel
      ? parseInt(params.userRiskScopeMaxRiskLevel)
      : undefined,
    userRiskAppCategories: toArrayParam(params.userRiskAppCategories),
    userRiskExcludeAppCategories: parseBooleanQueryParamValue(
      params.userRiskExcludeAppCategories
    ),
  };
};

export const toArrayParam = (p: any) => {
  if (p === undefined || p === null) return p;
  if (Array.isArray(p)) return p;
  return [p];
};

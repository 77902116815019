import { FC, memo } from "react";
import { Node, NodeType } from "../types/types";

import "../style/NodeTypeIcon.scss";
import classnames from "classnames";
import { vsaqQuestionType } from "../vsaq/vsaqTypes";
import { Severity } from "../../_common/types/severity";
import SeverityIcon from "../../_common/components/SeverityIcon";

// Similar to NodeType, but separates Checkbox and Radio types.
export enum NodeTypeIconType {
  Section,
  InputText,
  SelectCheckbox,
  SelectRadio,
  Upload,
  Risk,
  Info,
}

export const nodeToNodeTypeIconType = (node: Node): NodeTypeIconType => {
  switch (node.type) {
    case NodeType.Section:
      return NodeTypeIconType.Section;
    case NodeType.InputText:
      return NodeTypeIconType.InputText;
    case NodeType.Select:
      if (node.radio) {
        return NodeTypeIconType.SelectRadio;
      } else {
        return NodeTypeIconType.SelectCheckbox;
      }
    case NodeType.Upload:
      return NodeTypeIconType.Upload;
    case NodeType.Risk:
      return NodeTypeIconType.Risk;
    case NodeType.Info:
      return NodeTypeIconType.Info;
  }
};

export const nodeTypeIconTypeToNodeType = (
  iconType: NodeTypeIconType
): NodeType => {
  switch (iconType) {
    case NodeTypeIconType.Section:
      return NodeType.Section;
    case NodeTypeIconType.InputText:
      return NodeType.InputText;
    case NodeTypeIconType.SelectRadio:
    case NodeTypeIconType.SelectCheckbox:
      return NodeType.Select;
    case NodeTypeIconType.Upload:
      return NodeType.Upload;
    case NodeTypeIconType.Risk:
      return NodeType.Risk;
    case NodeTypeIconType.Info:
      return NodeType.Info;
  }
};

export const vsaqQuestionTypeToNodeTypeIconType = (
  qType: vsaqQuestionType
): NodeTypeIconType => {
  switch (qType) {
    case vsaqQuestionType.Block:
      return NodeTypeIconType.Section;
    case vsaqQuestionType.Info:
      return NodeTypeIconType.Info;
    case vsaqQuestionType.Tip:
      return NodeTypeIconType.Risk;
    case vsaqQuestionType.RadioGroup:
      return NodeTypeIconType.SelectRadio;
    case vsaqQuestionType.CheckGroup:
      return NodeTypeIconType.SelectCheckbox;
    case vsaqQuestionType.Line:
    case vsaqQuestionType.Box:
      return NodeTypeIconType.InputText;
    case vsaqQuestionType.Upload:
      return NodeTypeIconType.Upload;
  }

  return NodeTypeIconType.Section;
};

interface INodeTypeIconProps {
  className?: string;
  nodeType: NodeTypeIconType;
  questionNumber?: string;
  severity?: Severity;
  withInput?: boolean;
  placeholder?: string;
  inputValue?: string;
  title?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
}

const NodeTypeIcon: FC<INodeTypeIconProps> = ({
  nodeType,
  severity,
  className,
  questionNumber = "",
  withInput = false,
  placeholder = "",
  inputValue,
  title,
  onChange,
  onFocus,
}) => {
  let icon;

  switch (nodeType) {
    case NodeTypeIconType.Section:
      icon = <div className="cr-icon-q-builder-flag" />;
      break;
    case NodeTypeIconType.InputText:
      icon = <div className="cr-icon-q-builder-document" />;
      break;
    case NodeTypeIconType.SelectCheckbox:
      icon = <div className="cr-icon-q-builder-checkbox" />;
      break;
    case NodeTypeIconType.SelectRadio:
      icon = <div className="cr-icon-q-builder-radio" />;
      break;
    case NodeTypeIconType.Upload:
      icon = <div className="cr-icon-q-builder-attachment" />;
      break;
    case NodeTypeIconType.Risk:
      if (!severity) {
        icon = <div className="cr-icon-warning" />;
      } else {
        icon = <SeverityIcon severity={severity} />;
      }

      break;
    case NodeTypeIconType.Info:
      icon = <div className="cr-icon-info" />;
      break;
  }

  return (
    <div className={classnames("node-type-icon", className)} title={title}>
      {icon}
      {questionNumber && !withInput ? (
        `${questionNumber}`
      ) : (
        <div className="minus-marg" />
      )}
      {withInput && (
        <input
          className="node-type-input"
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
          onFocus={onFocus}
        />
      )}
    </div>
  );
};

export default memo(NodeTypeIcon);

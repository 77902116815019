import "./Thread.scss";
import { LabelColor } from "../../_common/types/label";
import { hashPick } from "../../vendorrisk/helpers/util";
import classnames from "classnames";
import { formatDateTimeAsLocal } from "../../_common/helpers";
import { useParseSnippet } from "../funcs/useParseSnippet";

// This list has a matching list of classes in the css
export const authorColors = [
  LabelColor.Blue,
  LabelColor.LightBlue,
  LabelColor.Green,
  LabelColor.Yellow,
  LabelColor.Gimblet,
  LabelColor.PersianGreen,
  LabelColor.Violet,
  LabelColor.Fuchsia,
  LabelColor.DarkGrey,
  LabelColor.TrendyPink,
  LabelColor.PictonBlue,
  LabelColor.MountainMeadow,
  LabelColor.Bahia,
];

// slimmed down version of the ThreadEntry from the API
export interface ThreadEntryType {
  user: string;
  date: string;
  text: string;
}

export interface ThreadEntryProps {
  entry: ThreadEntryType;
  highlight?: string[];
  className?: string;
}
export function ThreadEntry({ entry, highlight, className }: ThreadEntryProps) {
  const parsedAuthor = useParseSnippet(entry.user, highlight);
  const parsedLines = useParseSnippet(entry.text, highlight);

  if (!entry.text) {
    return null;
  }

  return (
    <BaseThreadEntry
      className={className}
      author={entry.user}
      parsedAuthor={parsedAuthor}
      date={entry.date}
      parsedLines={parsedLines}
    />
  );
}

export interface BaseThreadEntryProps {
  author: string;
  parsedAuthor: JSX.Element[];
  date: string;
  parsedLines: readonly JSX.Element[];
  className?: string;
}
// Styling only ThreadEntry component
export function BaseThreadEntry({
  author,
  parsedAuthor,
  parsedLines,
  date,
  className,
}: BaseThreadEntryProps) {
  const authorColor = hashPick(author, authorColors);

  return (
    <div className={classnames("tm-thread-entry", className)}>
      <div className={classnames("attribution", authorColor)}>
        {parsedAuthor}{" "}
        <div className="date">[{formatDateTimeAsLocal(date)}]:</div>
      </div>
      <div className="text">{parsedLines}</div>
    </div>
  );
}

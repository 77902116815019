import DragDropUpload from "../../../../_common/components/DragDropUpload";
import { ImportQuestionnaireAcceptedFileTypes } from "../../../../vendor_portal/components/ImportQuestionnaireModal";
import TextField, {
  useTextWithValid,
} from "../../../../_common/components/TextField";
import DatePicker from "../../../../_common/components/DatePicker";
import { FC, useEffect, useState } from "react";
import moment from "moment";
import { importSurveyData } from "../AddQuestionnairesModal";
import { trimFileExtension } from "../../../../_common/helpers/string.helpers";

interface ImportSurveyProps {
  onFormChange: (importSurveyState: importSurveyData) => void;
}

const ImportQuestionnaireComponent: FC<ImportSurveyProps> = ({
  onFormChange,
}: ImportSurveyProps) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [
    surveyName,
    surveyNameValid,
    surveyNameOnChanged,
    surveyNameHasChanged,
  ] = useTextWithValid();
  const [fromCompany, setFromCompany] = useState<string>("");
  const [dueDate, setDueDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedFile && !surveyNameHasChanged) {
      const filename = trimFileExtension(selectedFile.name);
      surveyNameOnChanged(filename, filename.length >= 2);
    }
    if (selectedFile && surveyNameValid) {
      onFormChange({
        file: selectedFile,
        surveyName,
        fromCompany,
        dueDate,
        isValid: surveyNameValid,
      });
    }
  }, [
    selectedFile,
    surveyName,
    surveyNameValid,
    fromCompany,
    dueDate,
    surveyNameHasChanged,
    surveyNameOnChanged,
  ]);

  return (
    <>
      <DragDropUpload
        acceptedFileTypeFilters={ImportQuestionnaireAcceptedFileTypes}
        onFileSelected={setSelectedFile}
        selectedFile={selectedFile}
        additionalText=".xlsx files accepted only"
      />

      <div className="horz-line" />

      <div className={"import-questionnaire-details"}>
        <div className={"label required"}>Questionnaire name</div>
        <TextField
          name="questionnairename"
          value={surveyName}
          onChanged={surveyNameOnChanged}
          placeholder="Questionnaire name"
          required
          minLength={2}
          maxLength={120}
        />

        <div className={"label"}>Requested by</div>
        <TextField
          name="requestedby"
          value={fromCompany}
          onChanged={setFromCompany}
          placeholder="Company name"
          minLength={2}
          maxLength={500}
        />

        <div className={"label"}>Due date</div>
        <DatePicker
          name="duedate"
          min={moment().format("YYYY-MM-DD")}
          placeholder="Select due date"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          canClear
          showCalendarIcon
        />
      </div>
    </>
  );
};

export default ImportQuestionnaireComponent;

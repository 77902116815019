import { DefaultRootState } from "react-redux";
import { DefaultThunkDispatch } from "./types/redux";
import { DataRegion } from "./types/dataRegions";
import { FetchCyberRiskUrl } from "./api";

export const activeRegionsSelector = (state: DefaultRootState) =>
  state.common.activeCustomerRegions;

// FetchURLInRegions sends the same request to every available region and combines the results.
// Returns a promise that waits until all requests complete, resolving with an array of responses.
// Rejects with error if any request fails.
export const FetchURLInRegions = async <Response>(
  endpoint: string,
  options: Record<string, any> | undefined,
  fetchOptions: any,
  specificRegions: DataRegion[] | undefined, // Optional subset of regions to request - defaults to all active regions
  dispatch: DefaultThunkDispatch,
  getState: () => DefaultRootState
) => {
  const activeRegions = activeRegionsSelector(getState());

  const regionsToFetch =
    specificRegions && specificRegions.length > 0
      ? specificRegions
      : activeRegions;

  return Promise.all(
    regionsToFetch.map((region) =>
      FetchCyberRiskUrl<Response>(
        endpoint,
        options,
        fetchOptions,
        dispatch,
        getState,
        false,
        false,
        region // Override the current org region here
      )
    )
  );
};

import { IUserData } from "../types/redux";
import {
  breachSightNavigationItems,
  trustExchangeNavigationItems,
} from "../types/products";
import * as Permissions from "../permissions";
import {
  userCurrentOrgHasSubsidiaries,
  userOrgIsFreeOrg,
} from "./userOrg.helpers";
import { AssuranceType, IUserOrganisation } from "../types/organisations";
import { userCurrentOrgIsDistributor, userIsOrgless } from "./userOrg.helpers";

// MultiProductNavigationMaxWidthClass - CSS class name for the maximum width of the content pane with multi-product navigation
// This width varies based on the open/closed state of the 2 navigation menus
// The value is set in AppContentContainer.scss
// Any component that needs to use this max-width should include this CSS classname
export const MultiProductNavContentMaxWidthClass =
  "multi-product-nav-content-max-width";

// getUserBreachSightSections - gets a set of BreachSight sections that the user has access to
export const getUserBreachSightSections = (
  userData: IUserData,
  orgData?: IUserOrganisation,
  selectedSubsidiaryVendorId?: number
): breachSightNavigationItems[] => {
  const result: breachSightNavigationItems[] = [];

  const userHasBreachsight =
    userData.userPermissions.includes(Permissions.UserBreachsightEnabled) ||
    Object.keys(userData.domainPortfolioSpecificPermissions).length > 0;

  const userHasDomainPortfolioSpecificPermissions =
    userData.domainPortfolioSpecificPermissions &&
    Object.keys(userData.domainPortfolioSpecificPermissions).length > 0;

  const currentOrgIsFreeOrg = userOrgIsFreeOrg(orgData);
  const currentOrgHasSubsidiaries = userCurrentOrgHasSubsidiaries(orgData);

  // Add nav items based on user and org configuration
  if (
    (userHasBreachsight &&
      userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo)) ||
    currentOrgIsFreeOrg
  ) {
    result.push("risk_profile");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo)
  ) {
    result.push(
      "executive_summary",
      "risk_waivers",
      "domains",
      "ip_addresses",
      "detected_products"
    );
  }

  if (
    userHasBreachsight &&
    userData.assuranceType === AssuranceType.None &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo)
  ) {
    result.push("remediation");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo) &&
    userData.orgPermissions.includes(Permissions.OrgAccessVulns)
  ) {
    result.push("vulnerabilities");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo) &&
    userData.orgPermissions.includes(Permissions.OrgAccessSubsidiaries) &&
    currentOrgHasSubsidiaries &&
    !userHasDomainPortfolioSpecificPermissions
  ) {
    result.push("subsidiaries");
  }

  if (
    userHasBreachsight &&
    selectedSubsidiaryVendorId &&
    (userData.orgPermissions.includes(Permissions.OrgAccessCustomerInfo) ||
      userData.orgPermissions.includes(Permissions.OrgAccessSubsidiaries))
  ) {
    result.push("subsidiaries_menu");
  }

  // Identity Breaches and Typosquatting features can be toggled on for users who do not have access to the
  // core features, so we don't check the userHasBreachsight flag for them
  if (
    userData.orgPermissions.includes(Permissions.OrgAccessEmailExposures) &&
    userData.userPermissions.includes(Permissions.UserEmailExposureRead)
  ) {
    result.push("identity_breaches");
  }

  if (
    userData.orgPermissions.includes(Permissions.OrgAccessTyposquatting) &&
    userData.userPermissions.includes(Permissions.UserTyposquattingRead)
  ) {
    result.push("typosquatting");
  }

  if (
    userHasBreachsight &&
    userData.orgPermissions.includes(Permissions.OrgAccessAppGuard)
  ) {
    result.push("cloud");
  }

  return result;
};

// userCanViewCyberResearch - determines whether the user can view any CyberResearch sections
// Show cyberresearch if:
// - Org is not a distributor
// AND
// - Org is not an MSSP type org
// AND
// - Org/user has access to any of the relevant features
//  - DataLeaks
//  - VendorDataLeaks
//  - Managed Vendor Assessments
// OR
//  - The org has access to none of the relevant features (show for feature preview purposes)
//  - AND it is not a free/vendor-only user
export const userCanViewCyberResearch = (
  userData: IUserData,
  orgData?: IUserOrganisation
): boolean => {
  const currentOrgIsFreeOrg = userOrgIsFreeOrg(orgData);
  const currentOrgIsDistributor = userCurrentOrgIsDistributor(userData);
  const currentUserIsOrgless = userIsOrgless(userData);

  const userHasDataLeaks =
    userData.orgPermissions.includes(Permissions.OrgAccessBreachSight) &&
    userData.userPermissions.includes(Permissions.UserAccessDataLeaks);

  const userHasVendorDataLeaks =
    userData.orgPermissions.includes(Permissions.OrgAccessVendorDataLeaks) &&
    userData.userPermissions.includes(Permissions.UserAccessDataLeaks);

  const userHasManagedVendors =
    userData.orgPermissions.includes(
      Permissions.OrgAccessVendorsUnderManagement
    ) &&
    userData.userPermissions.includes(
      Permissions.UserManageVendorsUnderManagement
    );

  const userShouldSeeFeaturePreview =
    !currentOrgIsFreeOrg &&
    !currentUserIsOrgless &&
    !userData.orgPermissions.includes(Permissions.OrgAccessBreachSight) &&
    !userData.orgPermissions.includes(Permissions.OrgAccessVendorDataLeaks) &&
    !userData.orgPermissions.includes(
      Permissions.OrgAccessVendorsUnderManagement
    );

  return (
    !currentOrgIsDistributor &&
    userData.assuranceType !== AssuranceType.MSSP &&
    (userHasDataLeaks ||
      userHasVendorDataLeaks ||
      userHasManagedVendors ||
      userShouldSeeFeaturePreview)
  );
};

export const userCanViewVendorRisk = (
  userData: IUserData,
  orgData?: IUserOrganisation
): boolean => {
  const currentOrgIsFreeOrg = userOrgIsFreeOrg(orgData);
  const currentOrgIsDistributor = userCurrentOrgIsDistributor(userData);

  const userHasOrgCollaboration = userData.hasSurveyCollaboration;
  const showVendorPortalSharedWithMeMenuItem =
    (userData.currentOrgID === 0 || currentOrgIsFreeOrg) &&
    userData.hasSharedAssessmentsAccess;
  const showAppSharedWithMeMenuItem =
    userData.assuranceType === AssuranceType.None &&
    userData.currentOrgID > 0 &&
    !currentOrgIsFreeOrg &&
    !currentOrgIsDistributor;
  const showSharedWithMeMenuItem =
    showVendorPortalSharedWithMeMenuItem || showAppSharedWithMeMenuItem;

  const userHasVendorRisk =
    userData.userPermissions.includes(Permissions.UserVendorRiskEnabled) ||
    Object.keys(userData.vendorPortfolioSpecificPermissions).length > 0;

  const showVendorRisk =
    userHasVendorRisk &&
    (userData.orgPermissions.includes(Permissions.OrgAccessVendors) ||
      userData.orgPermissions.includes(Permissions.OrgAccessSurveys));

  return (
    showVendorRisk ||
    showSharedWithMeMenuItem ||
    userHasOrgCollaboration ||
    currentOrgIsFreeOrg
  );
};

export const userCanViewUserRisk = (userData: IUserData): boolean => {
  return (
    userData.orgPermissions.includes(Permissions.OrgAccessUserBase) &&
    (userData.userPermissions.includes(Permissions.UserUserBaseEnabled) ||
      userData.userPermissions.includes(Permissions.UserUserBaseWrite))
  );
};

// getUserTrustExchangeSections - get a set of TrustExchange sections that the user has access to
// The first item is the default display item for the TrustExchange product
export const getUserTrustExchangeSections = (
  userData: IUserData,
  orgData?: IUserOrganisation
): trustExchangeNavigationItems[] => {
  const result: trustExchangeNavigationItems[] = [];

  if (
    userData.currentOrgID !== 0 &&
    orgData?.isVerifiedVendor &&
    userData.userPermissions.includes(Permissions.UserAccessPrefilledSurveys)
  ) {
    result.push("trust_page");
  }

  if (
    userData.userPermissions.includes(Permissions.UserReadContentLibrary) &&
    !userCurrentOrgIsDistributor(userData)
  ) {
    result.push("content_library");
  }

  if (
    userData.hasAssessments ||
    userData.userPermissions.includes(Permissions.UserReadSurveyImportExport)
  ) {
    result.push("questionnaires");
  }

  if (userData.hasRemediation) {
    result.push("remediation_requests");
  }

  if (userData.hasAcceptedRisks) {
    result.push("risk_waiver_approvals");
  }

  if (userData.hasRiskAdjustments) {
    result.push("risk_adjustment_approvals");
  }

  if (userData.hasAdditionalEvidenceRequests) {
    result.push("additional_evidence_requests");
  }

  return result;
};

import { FetchHannahUrl, FetchCyberRiskUrl, ResponseError } from "../api";
import {
  setLocalStorageItem,
  clearLocalStorageItem,
  getLocalStorageItem,
} from "../session";
import { clearAllData as clearAllCyberRiskData } from "../../vendorrisk/reducers/cyberRiskActions";
import { isIPAddress, LogError } from "../helpers";
import moment from "moment";
import { get as _get } from "lodash";
import { produce } from "immer";
import { mapTimelineStatuses } from "./surveyDetails.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
  addMessageAlert,
  addSimpleErrorAlert,
} from "./messageAlerts.actions";
import { BannerType } from "../../vendorrisk/components/InfoBanner";
import { commonInitialState } from "./commonReducer.initialState";
import { clearAllVendorData } from "../rtkQueryApi";
import BaseAPI from "../rtkQueryApi";

/** @typedef { import('../../_common/types/thirdPartyMangedVendors.ts').ITpvmSession } ITpvmSession */

export const CLOSE_MODAL = "COMMON_CLOSE_MODAL";
export const OPEN_MODAL = "COMMON_OPEN_MODAL";
export const RESET_STATE = "COMMON_RESET_STATE";
export const SET_USER_DATA = "COMMON_SET_USER_DATA";
export const SET_USER_ORG_LOADING = "COMMON_SET_USER_ORG_LOADING";
export const SET_CURRENT_ORG_NAME = "COMMON_SET_CURRENT_ORG_NAME";
export const SET_CR_AUTH = "COMMON_SET_CR_AUTH";
export const SET_CR_AUTH_INFO = "COMMON_SET_CR_AUTH_INFO";
export const SET_CR_ENDPOINT = "COMMON_SET_CR_ENDPOINT";
export const SET_HANNAH_ENV = "COMMON_SET_HANNAH_ENV";
export const SET_MODAL_DATA = "COMMON_SET_MODAL_DATA";
export const SET_ORG_INFO = "COMMON_SET_ORG_INFO";
export const SET_WHISPER = "COMMON_SET_WHISPER";
export const SET_INDUSTRIES = "COMMON_SET_INDUSTRIES";
export const SET_SURVEY_DATA = "COMMON_SET_SURVEY_DATA";
export const SET_ASSESSMENT_SURVEY_DATA = "COMMON_SET_ASSESSMENT_SURVEY_DATA";
export const CLEAR_SURVEY_DATA = "COMMON_CLEAR_SURVEY_DATA";
export const SET_PUBLIC_SURVEY_DATA = "COMMON_SET_PUBLIC_SURVEY_DATA";
export const SET_ASSESSMENT_PUBLIC_SURVEY_DATA =
  "COMMON_SET_ASSESSMENT_PUBLIC_SURVEY_DATA";
export const SET_REMEDIATION_REQUEST_DATA =
  "COMMON_SET_REMEDIATION_REQUEST_DATA";
export const DELETE_REMEDIATION_REQUESTS = "COMMON_DELETE_REMEDIATION_REQUESTS";
export const CLEAR_REMEDIATION_REQUEST_DATA =
  "COMMON_CLEAR_REMEDIATION_REQUEST_DATA";
export const SET_SHARED_ASSESSMENTS_LIST = "COMMON_SET_SHARED_ASSESSMENTS_LIST";
export const SET_SHARED_ASSESSMENT_DATA = "COMMON_SET_SHARED_ASSESSMENT_DATA";
export const SET_CLOUDSCAN_DATA = "COMMON_SET_CLOUDSCAN_DATA";
export const SET_VIEWING_VENDORID = "COMMON_SET_VIEWING_VENDORID";

export const SET_EXPORT_ITEMS = "COMMON_SET_EXPORT_ITEMS";
export const UPDATE_EXPORT_ITEMS = "COMMON_UPDATE_EXPORT_ITEMS";
export const RENAME_EXPORT_ITEMS = "COMMON_RENAME_EXPORT_ITEMS";
export const SET_SINGLE_EXPORT_ITEM = "COMMON_SET_SINGLE_EXPORT_ITEM";
export const SET_EXPORT_ITEM_POLLING = "COMMON_SET_EXPORT_ITEM_POLLING";
export const SET_EXPORT_ITEMS_ARCHIVED = "COMMON_SET_EXPORT_ITEMS_ARCHIVED";
export const SET_SINGLE_EXPORT_ITEM_ARCHIVED =
  "COMMON_SET_SINGLE_EXPORT_ITEM_ARCHIVED";
export const SET_SCHEDULED_EXPORT_ITEMS = "COMMON_SET_SCHEDULED_EXPORT_ITEMS";

export const SET_VIEWING_MANAGED_VENDOR_FOR_ORG =
  "COMMON_SET_VIEWING_MANAGED_VENDOR_FOR_ORG";
export const SET_TPVM_SESSION = "COMMON_SET_TPVM_SESSION";
export const SET_CR_TPVM_AUTH = "COMMON_SET_CR_TPVM_AUTH";

export const SET_ACTIVITY_STREAM_LOADING = "COMMON_SET_ACTIVITY_STREAM_LOADING";
export const ADD_ACTIVITY_STREAM_PAGE = "COMMON_ADD_ACTIVITY_STREAM_PAGE";
export const SET_ACTIVITY_STREAM_ACTION_RUNNING =
  "COMMON_SET_ACTIVITY_STREAM_ACTION_RUNNING";
export const REMOVE_FEATURE_RELEASE_FROM_ACTIVITY_STREAM =
  "COMMON_REMOVE_FEATURE_RELEASE_FROM_ACTIVITY_STREAM";
export const REMOVE_PROMOTION_FROM_ACTIVITY_STREAM =
  "COMMON_REMOVE_PROMOTION_FROM_ACTIVITY_STREAM";
export const REMOVE_EVENT_FROM_ACTIVITY_STREAM =
  "COMMON_REMOVE_EVENT_FROM_ACTIVITY_STREAM";
export const ADD_EVENT_TO_IMPORTANT_LIST_FOR_ACTIVITY_STREAM =
  "COMMON_ADD_EVENT_TO_IMPORTANT_LIST_FOR_ACTIVITY_STREAM";
export const ADD_EVENT_TO_ACTIVITY_STREAM =
  "COMMON_ADD_EVENT_TO_ACTIVITY_STREAM";
export const REMOVE_EVENT_FROM_IMPORTANT_LIST_FOR_ACTIVITY_STREAM =
  "COMMON_REMOVE_EVENT_FROM_IMPORTANT_LIST_FOR_ACTIVITY_STREAM";
export const REFRESH_ACTIVITY_STREAM = "COMMON_REFRESH_ACTIVITY_STREAM";
export const SET_ACTIVITY_STREAM_BACKGROUND_REFRESH_RUNNING =
  "COMMON_SET_ACTIVITY_STREAM_BACKGROUND_REFRESH_RUNNING";
export const SET_USER_ACTIVITYSTREAM_SETTINGS =
  "COMMON_SET_USER_ACTIVITYSTREAM_SETTINGS";
export const DECREMENT_ACTIVITY_STREAM_TASKCOUNT =
  "COMMON_DECREMENT_ACTIVITY_STREAM_TASKCOUNT";
export const SET_ACTIVITY_STREAM_RELOAD_RUNNING =
  "COMMON_SET_ACTIVITY_STREAM_RELOAD_RUNNING";
export const SET_ACTIVITY_STREAM_EVENT_TOUCHED =
  "COMMON_SET_ACTIVITY_STREAM_EVENT_TOUCHED";
export const SET_NAMED_ROLES = "COMMON_SET_NAMED_ROLES";

export const SET_PROJECTION_FOR_REMEDIATION =
  "COMMON_SET_PROJECTION_FOR_REMEDATION";
export const SET_PROJECTION_FOR_SURVEY = "COMMON_SET_PROJECTION_FOR_SURVEY";
export const SET_PROJECTION_REQUEST_TIMESTAMP =
  "COMMON_SET_PROJECTION_REQUEST_TIMESTAMP";

export const SET_SECURITY_FRAMEWORK_STRUCTURE =
  "COMMON_SET_SECURITY_FRAMEWORK_STRUCTURE";
export const SET_SECURITY_FRAMEWORK_COVERAGE =
  "COMMON_SET_SECURITY_FRAMEWORK_COVERAGE";

export const SET_FREETRIAL_ELIGIBILITY = "COMMON_SET_FREETRIAL_ELIGIBILITY";
export const SET_FREETRIAL_ONBOARDING_CALENDAR =
  "COMMON_SET_FREETRIAL_ONBOARDING_CALENDAR";

export const SET_FREETRIAL_ONBOARDING_BOOKED =
  "COMMON_SET_FREETRIAL_ONBOARDING_BOOKED";

export const clearCommonState = () => {
  // completely clearing the cyberRiskAuth state here will cause other tabs to try logging in,
  // we don't want this because we might be in the middle of logging out. so just set it to loading
  setLocalStorageItem("cyberRiskAuth", { loading: true });
  clearLocalStorageItem("userData");

  return {
    type: RESET_STATE,
  };
};

export const setCyberRiskAuth = (
  auth,
  error,
  doNotSetLocalStorage = false,
  tpvmSession = null
) => {
  if (tpvmSession && tpvmSession.tpvm) {
    if (error) {
      clearLocalStorageItem(`tpvmAuth_${tpvmSession.tpvm_o}`);
      return {
        type: SET_CR_TPVM_AUTH,
        orgId: tpvmSession.tpvm_o,
        error,
      };
    }
    if (auth === null) {
      // Clearing cyber risk auth, reset to the initial state
      return {
        type: SET_CR_TPVM_AUTH,
        orgId: tpvmSession.tpvm_o,
      };
    }

    if (!doNotSetLocalStorage) {
      setLocalStorageItem(`tpvmAuth_${tpvmSession.tpvm_o}`, auth);
    }

    return {
      type: SET_CR_TPVM_AUTH,
      orgId: tpvmSession.tpvm_o,
      auth,
    };
  }
  if (error) {
    return {
      type: SET_CR_AUTH,
      error,
    };
  }

  if (auth === null) {
    // Clearing cyber risk auth, reset to the initial state
    return {
      type: SET_CR_AUTH,
      auth: { ...commonInitialState.cyberRiskAuth },
    };
  }

  if (!doNotSetLocalStorage) {
    setLocalStorageItem("cyberRiskAuth", auth);
  }

  return {
    type: SET_CR_AUTH,
    auth,
  };
};

export const getAuthFromLocalStorage = (tpvmSession) => {
  if (tpvmSession) {
    return getLocalStorageItem(`tpvmAuth_${tpvmSession.tpvm_o}`);
  }
  return getLocalStorageItem("cyberRiskAuth");
};

/**
 * @returns {ITpvmSession}
 * */
export const grabTPVMSession = (getState, endpoint = null) => {
  //
  // some requests are never made as an analyst, despite the current location. don't inject
  // the tpvm session parameters if the target api is one of these.
  //
  // NB: update this list if additional non-analyst requests are identified.
  //

  let ignoreAnalyst = false;
  if (endpoint) {
    const genericRequests = [
      "alerts/v1",
      "accounts/impersonateorg/v1",
      "cyberresearch/managedvendors/token/v1",
      "activitystreamapi/v1/",
      "activitystreamapi/page/v1",
      "accounts/userdata/v1/",
    ];

    genericRequests.map((path) => {
      if (endpoint.includes(path)) {
        ignoreAnalyst = true;
      }
    });
  }

  if (!ignoreAnalyst) {
    return _get(getState().common, "tpvmSession", {});
  }
  return null;
};

export const setUserData = (data, doNotSetLocalStorage = false) => {
  const user = { ...data };

  // Ensure if profile came through as a string, that is JSON parsed
  if (user && typeof user.profile === "string") {
    try {
      const profile = JSON.parse(user.profile);
      user.profile = profile;
    } catch (e) {
      LogError("error parsing user profile", e);
      user.profile = {};
    }
  }

  if (!doNotSetLocalStorage) {
    setLocalStorageItem("userData", user);
  }

  return {
    type: SET_USER_DATA,
    user,
  };
};

export const setCurrentOrgName = (orgName) => {
  return {
    type: SET_CURRENT_ORG_NAME,
    orgName,
  };
};

export const setOrgLoading = (loading) => {
  return {
    type: SET_USER_ORG_LOADING,
    loading,
  };
};

export const setOrgInfo = (info) => {
  return {
    type: SET_ORG_INFO,
    info,
  };
};

export const setCyberRiskAuthInfo = (info) => {
  return {
    type: SET_CR_AUTH_INFO,
    info,
  };
};

export const setCyberRiskEndpoint = (endpoint) => {
  return {
    type: SET_CR_ENDPOINT,
    endpoint,
  };
};

export const setHannahEnv = (env) => {
  return {
    type: SET_HANNAH_ENV,
    env: env || "production",
  };
};

/*
 * @deprecated use ModalV2 component instead
 */
export const openModal = (name, data = {}, isV2Layout, className) => {
  return {
    type: OPEN_MODAL,
    name,
    data,
    isV2Layout,
    className,
  };
};

export const closeModal = () => {
  return { type: CLOSE_MODAL };
};

export const setModalData = (key, data) => {
  return {
    type: SET_MODAL_DATA,
    key,
    data,
  };
};

export const setIndustries = (industries) => {
  return {
    type: SET_INDUSTRIES,
    industries,
  };
};

export const setSurveyData = (surveyId, data) => {
  return {
    type: SET_SURVEY_DATA,
    surveyId,
    data,
  };
};

export const setSurveyDataForAssessment = (assessmentId, surveyId, data) => {
  return {
    type: SET_ASSESSMENT_SURVEY_DATA,
    assessmentId,
    surveyId,
    data,
  };
};

export const clearSurveyData = (
  surveyId // Optional, clears data for a single survey only
) => ({
  type: CLEAR_SURVEY_DATA,
  surveyId,
});

export const setPrefilledSurveyData = (surveyId, data) => {
  return {
    type: SET_PUBLIC_SURVEY_DATA,
    surveyId,
    data,
  };
};

export const setPrefilledSurveyDataForAssessment = (
  assessmentId,
  surveyId,
  data
) => {
  return {
    type: SET_ASSESSMENT_PUBLIC_SURVEY_DATA,
    assessmentId,
    surveyId,
    data,
  };
};

export const setRemediationRequestData = (remediationRequestId, data) => {
  return {
    type: SET_REMEDIATION_REQUEST_DATA,
    remediationRequestId,
    data,
  };
};

export const deleteRemediationRequestsByIDs = (remediationRequestIds) => {
  return {
    type: DELETE_REMEDIATION_REQUESTS,
    remediationRequestIds,
  };
};

export const clearRemediationRequestData = () => ({
  type: CLEAR_REMEDIATION_REQUEST_DATA,
});

export const setSharedAssessmentData = (vendorId, data) => {
  return {
    type: SET_SHARED_ASSESSMENT_DATA,
    vendorId,
    data,
  };
};

export const setSharedAssessmentsList = (sharedAssessmentsList) => {
  return {
    type: SET_SHARED_ASSESSMENTS_LIST,
    sharedAssessmentsList,
  };
};

export const setViewingVendorId = (vendorId) => {
  return {
    type: SET_VIEWING_VENDORID,
    vendorId,
  };
};

export const setExportItems = (items, loading = false) => {
  return {
    type: SET_EXPORT_ITEMS,
    items,
    loading,
  };
};

export const setExportItemsViewed = (items, isArchivedView) => {
  return {
    type: UPDATE_EXPORT_ITEMS,
    items,
    isArchivedView,
  };
};

export const setExportItemsRenamed = (items, isArchivedView) => {
  return {
    type: RENAME_EXPORT_ITEMS,
    items,
    isArchivedView,
  };
};

export const setSingleExportItem = (item) => {
  return {
    type: SET_SINGLE_EXPORT_ITEM,
    item,
  };
};

export const setExportItemPolling = (id, polling) => {
  return {
    type: SET_EXPORT_ITEM_POLLING,
    id,
    polling,
  };
};

export const setSingleExportItemArchived = (item) => {
  return {
    type: SET_SINGLE_EXPORT_ITEM_ARCHIVED,
    item,
  };
};

export const setExportItemsArchived = (items, loading = false) => {
  return {
    type: SET_EXPORT_ITEMS_ARCHIVED,
    items,
    loading,
  };
};

export const setViewingManagedVendorIdForOrgId = (orgId, vendorId) => {
  return {
    type: SET_VIEWING_MANAGED_VENDOR_FOR_ORG,
    vendorId,
    orgId,
  };
};

export const setCloudscanData = (webscanId, data) => {
  return {
    type: SET_CLOUDSCAN_DATA,
    webscanId,
    data,
  };
};

export const setTPVMSession = (session) => {
  return {
    type: SET_TPVM_SESSION,
    session,
  };
};
export const getTPMVSession = (getState) => {
  return getState().common.tpvmSession;
};

export const setActivityStreamLoading = () => {
  return {
    type: SET_ACTIVITY_STREAM_LOADING,
  };
};
export const setActivityStreamPage = (
  events,
  page,
  error,
  complete,
  releaseEvent,
  promotionEvent,
  tasks,
  importantEvents,
  userLastViewed,
  pageStartDate,
  pageEndDate
) => {
  return {
    type: ADD_ACTIVITY_STREAM_PAGE,
    events: events,
    page: page,
    error: error,
    complete: complete,
    releaseEvent: releaseEvent,
    promotionEvent,
    importantEvents: importantEvents,
    tasks: tasks,
    userLastViewed: userLastViewed,
    pageStartDate: pageStartDate,
    pageEndDate: pageEndDate,
  };
};

export const refreshActivityStream = (
  events,
  releaseEvent,
  promotionEvent,
  tasks,
  importantEvents,
  pageStartDate,
  pageEndDate
) => {
  return {
    type: REFRESH_ACTIVITY_STREAM,
    events: events,
    tasks: tasks,
    releaseEvent: releaseEvent,
    promotionEvent,
    importantEvents: importantEvents,
    pageStartDate: pageStartDate,
    pageEndDate: pageEndDate,
  };
};

export const setActivityStreamActionRunning = (running) => {
  return {
    type: SET_ACTIVITY_STREAM_ACTION_RUNNING,
    running: running,
  };
};

export const setActivityStreamBackgroundRefreshRunning = (running) => {
  return {
    type: SET_ACTIVITY_STREAM_BACKGROUND_REFRESH_RUNNING,
    running: running,
  };
};

export const setActivityStreamReloading = (running) => {
  return {
    type: SET_ACTIVITY_STREAM_RELOAD_RUNNING,
    running: running,
  };
};

export const setEventTouchedFromActivityStream = (uuid) => {
  return {
    type: SET_ACTIVITY_STREAM_EVENT_TOUCHED,
    uuid: uuid,
  };
};

export const removeFeatureReleaseEventFromActivityStream = () => {
  return {
    type: REMOVE_FEATURE_RELEASE_FROM_ACTIVITY_STREAM,
  };
};

export const removePromotionEventFromActivityStream = () => {
  return {
    type: REMOVE_PROMOTION_FROM_ACTIVITY_STREAM,
  };
};

export const removeEventFromActivityStream = (uuid) => {
  return {
    type: REMOVE_EVENT_FROM_ACTIVITY_STREAM,
    uuid: uuid,
  };
};

export const decrementActivityStreamTaskCount = () => {
  return {
    type: DECREMENT_ACTIVITY_STREAM_TASKCOUNT,
  };
};

export const addEventToImportantList = (event) => {
  return {
    type: ADD_EVENT_TO_IMPORTANT_LIST_FOR_ACTIVITY_STREAM,
    event: event,
  };
};

export const addEventToActivityStream = (event) => {
  return {
    type: ADD_EVENT_TO_ACTIVITY_STREAM,
    event: event,
  };
};

export const removeEventFromImportantList = (uuid) => {
  return {
    type: REMOVE_EVENT_FROM_IMPORTANT_LIST_FOR_ACTIVITY_STREAM,
    uuid: uuid,
  };
};

export const setUserActivityStreamSettings = (settings) => {
  return {
    type: SET_USER_ACTIVITYSTREAM_SETTINGS,
    settings,
  };
};

export const setProjectionForRemediationRequest = (
  requestId,
  loading,
  error,
  initialScore,
  currentScore,
  projectedScore
) => {
  return {
    type: SET_PROJECTION_FOR_REMEDIATION,
    requestId,
    error,
    loading,
    initialScore,
    currentScore,
    projectedScore,
  };
};

export const setProjectionForSurvey = (
  surveyId,
  loading,
  error,
  initialScore,
  currentScore,
  projectedScore
) => {
  return {
    type: SET_PROJECTION_FOR_SURVEY,
    surveyId,
    loading,
    error,
    initialScore,
    currentScore,
    projectedScore,
  };
};

export const setProjectionRequestTimestamp = (timestamp, key) => {
  return {
    type: SET_PROJECTION_REQUEST_TIMESTAMP,
    timestamp,
    key,
  };
};

export const setSecurityFrameworkData = (
  frameworkType,
  loading,
  error = null,
  structure = null
) => ({
  type: SET_SECURITY_FRAMEWORK_STRUCTURE,
  frameworkType,
  loading,
  error,
  structure,
});

export const setQuestionnaireCoverageData = (
  frameworkType,
  vendorId,
  loading,
  error = null,
  coverage = null
) => ({
  type: SET_SECURITY_FRAMEWORK_COVERAGE,
  frameworkType,
  vendorId,
  loading,
  error,
  coverage,
});

export const setFreeTrialEligibility = (userId, orgId, eligibility) => {
  return {
    type: SET_FREETRIAL_ELIGIBILITY,
    userId,
    orgId,
    eligibility,
  };
};

export const setFreeTrialOnboardingCalendar = (userId, calendar) => {
  return {
    type: SET_FREETRIAL_ONBOARDING_CALENDAR,
    calendar,
    userId,
  };
};

export const setOnboardingMeetingBooked = () => {
  return {
    type: SET_FREETRIAL_ONBOARDING_BOOKED,
  };
};

/* COMMON ASYNC ACTION CREATORS */

export const setTPVMSessionDetails = (path) => {
  return async (dispatch, getState) => {
    let tpvmAnalyst;
    let tpmvSessionOrg;
    let tpmvSessionVendor;
    let session = {};

    // any ignored path will retain the current tpvm session values rather than dropping them
    // used for cases where the tpvm session is required but would otherwise be dropped
    // (e.g. customized reports launched from the analyst portal will 403 without this)
    const ignoreRequests = ["/reportexports/create"];
    if (ignoreRequests.includes(path)) {
      return;
    }

    tpvmAnalyst =
      getState().common.userData.system_roles &&
      getState().common.userData.system_roles.includes(
        "VendorManagementAnalyst"
      );

    if (tpvmAnalyst) {
      const re = /\/analysts\/tpvm\/(\d+)\/(\d+)/;
      const tokens = path.match(re);
      if (!!tokens && tokens.length === 3) {
        tpmvSessionOrg = tokens[1];
        tpmvSessionVendor = tokens[2];
      }
    }

    if (!!tpvmAnalyst && !!tpmvSessionOrg && !!tpmvSessionVendor) {
      session = {
        tpvm: true,
        tpvm_o: tpmvSessionOrg,
        tpvm_v: tpmvSessionVendor,
      };
    }

    if (tpvmAnalyst) {
      // if we are switching in/out of analyst mode OR changing the org we are managing we need to clear
      // all vendor data from our RTK cache
      const previousState = getTPMVSession(getState);
      if (
        previousState.tpvm !== session.tpvm ||
        previousState.tpvm_o !== session.tpvm_o
      ) {
        dispatch(clearAllVendorData());
      }
    }
    dispatch(setTPVMSession(session));
  };
};

export const fetchConfig = () => {
  return async (dispatch, getState) => {
    let config = {};

    const cyberRiskAuthGeneralError =
      "Error authorizing with Cyber Risk. Try reloading the page or contact UpGuard support.";

    try {
      config = await FetchHannahUrl("config/v1/");
    } catch (e) {
      // On a fetch error, we gotta error out all the dependent dispatches too
      dispatch(setCyberRiskEndpoint());
      dispatch(setHannahEnv()); // Set HannahEnv to what the default is in that action creator
      dispatch(setOrgInfo({})); // We don't have OrgInfo either

      let cyberRiskError = cyberRiskAuthGeneralError;
      if (e.message.indexOf("404") > -1) {
        // Config endpoint 404'd. Probably an old version of Hannah. Give a better error message.
        cyberRiskError =
          "Newer version of Hannah required. Please contact UpGuard support to upgrade.";
      }

      dispatch(setCyberRiskAuth(null, { errorText: cyberRiskError }));

      LogError("Error fetching hannah config", e);

      return config;
    }

    const { cyberRiskAuth, cyberRiskEndpoint, hannahEnv, orgInfo } = config;

    // First, set all the endpoint, hannahEnv etc
    dispatch(setCyberRiskEndpoint(cyberRiskEndpoint));
    dispatch(setHannahEnv(hannahEnv));
    dispatch(setOrgInfo(orgInfo));

    // Then, check the cyberRiskAuth to see if it's set or missing, and set accordingly
    if (cyberRiskAuth && cyberRiskAuth.status === "OK") {
      dispatch(setCyberRiskAuth(cyberRiskAuth));
    } else {
      const errorText =
        cyberRiskAuth.status === "MISSING"
          ? "Cyber Risk API key and secret is not set. Please contact UpGuard support."
          : cyberRiskAuthGeneralError;

      dispatch(setCyberRiskAuth(null, { errorText }));
    }

    // This async action should return all the config it got
    return config;
  };
};

export const loginUser = (idToken, accessToken, trackingID) => {
  return async (dispatch, getState) => {
    dispatch(setCyberRiskAuth({ loading: true }));

    // Timezone offset as interpreted by Go is in seconds east of UTC,
    // which is the reverse of what javascript getTimezoneOffset returns
    const timezoneOffset = new Date().getTimezoneOffset() * -60;

    let json;
    try {
      let url = `/api/accounts/login/v1/?access_token=${encodeURIComponent(
        accessToken
      )}&timezone=${encodeURIComponent(
        timezoneOffset
      )}&tracking_id=${trackingID}`;
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      json = await response.json();
    } catch (e) {
      LogError("error logging in user", e);
    }

    let loggedIn = false;

    if (!json || json.status !== "OK") {
      if (json && json.error && json.error.startsWith("[MISSING_SSO_USER]")) {
        dispatch(
          setCyberRiskAuth(null, {
            errorTitle: "Sorry, you aren't registered as an UpGuard user",
            errorText:
              "Please contact your UpGuard account administrator to request an invite.",
          })
        );
      } else {
        dispatch(
          setCyberRiskAuth(null, {
            errorText:
              "Error authorizing with UpGuard. Please refresh the page and try again.",
          })
        );
      }
      dispatch(setUserData(null));
      return loggedIn;
    }

    if (json.user) {
      dispatch(setUserData(json.user));
    }

    if (json.crAccessToken && json.apiKey) {
      dispatch(
        setCyberRiskAuth({
          loading: false,
          apiKey: json.apiKey,
          token: json.crAccessToken,
          expires: json.expiry,
          ssoWithLogout: json.ssoWithLogout,
        })
      );

      loggedIn = true;
    } else {
      dispatch(setCyberRiskAuth({ loading: false }));
    }

    // The login call can include an extra message to show, eg. if the invite was claimed successfully or not.
    if (json.extraMessage && json.extraMessageType) {
      addMessageAlert({
        message: json.extraMessage,
        type:
          json.extraMessageType === "success"
            ? BannerType.SUCCESS
            : BannerType.ERROR,
      });
    }

    return loggedIn;
  };
};

export const loadUserOrg = (orgID) => {
  return async (dispatch, getState) => {
    let json;

    dispatch(clearAllCyberRiskData());

    // And reset the RTK query state
    dispatch(BaseAPI.util.resetApiState());

    try {
      json = await FetchCyberRiskUrl(
        "accounts/switchorg/v1/",
        { new_org_id: orgID },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error loading user org", e);

      throw new Error(`error loading user org: ${e.message}`);
    }

    dispatch(clearCommonState());

    if (json.user) {
      dispatch(setUserData(json.user));
    }

    if (json.crAccessToken && json.apiKey) {
      dispatch(
        setCyberRiskAuth({
          apiKey: json.apiKey,
          token: json.crAccessToken,
          expires: json.expiry,
        })
      );
    }

    return json;
  };
};

export const loadImpersonatedOrg = (orgID, reason = "", userId) => {
  return async (dispatch, getState) => {
    let json;

    dispatch(clearAllCyberRiskData());

    // And reset the RTK query state
    dispatch(BaseAPI.util.resetApiState());

    try {
      json = await FetchCyberRiskUrl(
        "accounts/impersonateorg/v1/",
        { new_org_id: orgID, reason, user_id: userId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error loading admin (impersonated) org", e);

      throw new Error(`error loading admin (impersonated) org: ${e.message}`);
    }

    dispatch(clearCommonState());

    if (json.user) {
      dispatch(setUserData(json.user));
    }
    if (json.crAccessToken && json.apiKey) {
      dispatch(
        setCyberRiskAuth({
          apiKey: json.apiKey,
          token: json.crAccessToken,
          expires: json.expiry,
        })
      );
    }

    return json;
  };
};

export const fetchTPVMAnalystToken = (tpmvSession) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/managedvendors/token/v1",
        { org_id: tpmvSession.tpvm_o },
        null,
        dispatch,
        getState,
        false,
        true
      );
    } catch (e) {
      LogError("Error loading token for tpvm analyst session", e);

      dispatch(setCyberRiskAuth(auth, true, true, tpmvSession));
      return {
        error: true,
      };
    }

    let auth;
    if (json.crAccessToken && json.apiKey) {
      auth = {
        apiKey: json.apiKey,
        token: json.crAccessToken,
        expires: json.expires,
      };
      dispatch(setCyberRiskAuth(auth, false, true, tpmvSession));
    }
    return auth;
  };
};

export const switchUserOrg = (newOrgID, history) => {
  return async (dispatch, getState) => {
    // switch to the home page first so that whatever react component
    // is currently mounted does try to re-fetch data it might not have access to
    if (history) {
      history.push("/");
    }

    dispatch(setOrgLoading(true));
    let json;

    try {
      json = await loadUserOrg(newOrgID)(dispatch, getState);
    } catch (e) {
      LogError("Error switching user org", e);
    }

    if (!json || json.status !== "OK") {
      // set a whisper to convey the failure. don't update the user or auth data
      dispatch(setOrgLoading(false));
      dispatch(
        addDefaultUnknownErrorAlert("Switch to new Organization failed")
      );
      return;
    }

    // clear vendors table sorting settings after switching org,
    // as some orgs can have custom sorting attributes/columns that are N/A to other orgs
    clearLocalStorageItem("vendorsPaging");
    dispatch(setOrgLoading(false));
  };
};

export const impersonateUserOrg = (newOrgID, reason, userId) => {
  return async (dispatch, getState) => {
    dispatch(setOrgLoading(true));
    let json;

    try {
      json = await loadImpersonatedOrg(
        newOrgID,
        reason,
        userId
      )(dispatch, getState);
    } catch (e) {
      LogError("Error impersonating user org", e);
    }

    if (!json || json.status !== "OK") {
      // set a whisper to convey the failure. don't update the user or auth data
      dispatch(setOrgLoading(false));
      dispatch(
        addDefaultUnknownErrorAlert(
          "Impersonating Admin for Organization failed"
        )
      );
      return "";
    }
    dispatch(setOrgLoading(false));
    if (json.user) return json.user.currentOrgRoles;
    return "";
  };
};

export const fetchUserData = (ignoreAuthUpdate) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "accounts/userdata/v1/",
        null,
        null,
        dispatch,
        getState,
        null,
        ignoreAuthUpdate
      );
    } catch (e) {
      LogError("Error fetching user data", e);
    }

    if (json && json.status === "OK") {
      dispatch(setUserData(json.user));
    }
  };
};

export const updateUserProfile = (profile) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "accounts/user/profile/v1/",
        profile,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating user profile", e);

      throw new Error("Error updating user profile.");
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const setOnboardingStepComplete = (stepName) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "accounts/user/onboardingsteps/v1/",
        { step_name: stepName },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting onboarding step", e);

      throw new Error("Error setting onboarding step.");
    }

    // Ensure we clear the onboarding step in localStorage so it doesn't
    // reappear again on the next reload
    const userData = getLocalStorageItem("userData", true);
    if (userData && userData.onboardingSteps) {
      delete userData.onboardingSteps[stepName];
      setLocalStorageItem("userData", userData);
    }
  };
};

export const setCompletedOnboardingStepLocally = (stepName) => {
  return async (dispatch, getState) => {
    const stateUserData = getState().common.userData;
    if (stateUserData) {
      const stateIdx = stateUserData.orgList?.findIndex(
        (o) => o.id === stateUserData.currentOrgID
      );
      if (stateIdx >= 0) {
        const stateOrg = stateUserData.orgList[stateIdx];
        if (!stateOrg.completedOnboardingSteps.includes(stepName)) {
          stateOrg.completedOnboardingSteps.push(stepName);
          stateUserData.orgList[stateIdx] = stateOrg;
          dispatch(setUserData(stateUserData));
        }
      }
    }
  };
};

export const fetchIndustries = () => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "industries/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching industries", e);

      return;
    }

    dispatch(setIndustries(json));
  };
};

export const fetchCloudscanByHostname = (
  hostname,
  forCustomer = false,
  remediationRequestId = undefined
) => {
  return async (dispatch, getState) => {
    const webscan = getState().common.webscans[hostname];
    let json;

    if (webscan && !webscan.loading && !webscan.error) {
      // We have cached webscan data, don't fetch again
      return;
    }

    dispatch(setCloudscanData(hostname, { loading: true }));

    try {
      json = await FetchCyberRiskUrl(
        "cloudscan/details/v2/",
        {
          hostname,
          for_customer: forCustomer,
          remediation_request_id: remediationRequestId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setCloudscanData(hostname, {
          loading: false,
          error: {
            errorText: "Sorry, there was an error fetching this cloudscan.",
            actionText: "Try again",
            actionOnClick: () => dispatch(fetchCloudscanByHostname(hostname)),
          },
        })
      );

      LogError("Error fetching cloudscan by hostname", e);
      return;
    }

    if (!json.result) {
      // Grab the vendorId and vendorName from the response - they're provided as top level keys
      // when there is no cloudscan record.
      const { vendorId, vendorName } = json;

      // Ask if the user should trigger a rescan
      dispatch(
        setCloudscanData(hostname, {
          loading: false,
          vendorId,
          vendorName,
          error: { errorText: `Sorry, ${hostname} hasn't been scanned yet.` },
        })
      );
      return;
    }

    // Finally update the cloudscan data
    dispatch(
      setCloudscanData(hostname, {
        result: json.result,
        rescanning: false,
        loading: false,
        error: null,
      })
    );

    return json.result;
  };
};

// scanHostname can either scan a new hostname for the first time or
// forcibly rescan an existing hostname.
export const scanHostname = (hostname, estimatedTimeCallback) => {
  return async (dispatch, getState) => {
    dispatch(setCloudscanData(hostname, { rescanning: true }));

    const handleTimeout = () => {
      dispatch(
        addMessageAlert({
          message: "This scan appears to be taking a long time",
          type: BannerType.WARNING,
          subMessages: ["Please check back again later."],
        })
      );
      dispatch(setCloudscanData(hostname, { rescanning: false }));
    };

    const handleUnknownError = () => {
      dispatch(
        setCloudscanData(hostname, {
          rescanning: false,
          error: {
            errorText: `Error performing scan on ${hostname}`,
            actionText: "Try again",
            actionOnClick: () => dispatch(scanHostname(hostname)),
          },
        })
      );
    };

    const handleScanError = (errorCount, isDisabled) => {
      const scanResult = getState().common.webscans[hostname].result || {};
      //dead scan
      dispatch(
        setCloudscanData(hostname, {
          rescanning: false,
          error: {
            errorText: `Host ${hostname} has failed to scan ${errorCount} times`,
            actionText: "Try again",
            actionOnClick: () => dispatch(scanHostname(hostname)),
          },
          result: {
            ...scanResult,
            noResult: isDisabled,
          },
        })
      );

      const label = isIPAddress(hostname) ? "IP address" : "Domain";

      if (isDisabled) {
        const wasActive = scanResult.noResult === false;
        if (wasActive) {
          dispatch(
            addSimpleErrorAlert(`${label} is now inactive`, [
              `${hostname} is now inactive. The host may be offline. It will no longer appear in the list.`,
            ])
          );
        } else {
          dispatch(
            addSimpleErrorAlert(`${label} is inactive`, [
              `${hostname} is still inactive. The host may be offline.`,
            ])
          );
        }
      } else {
        dispatch(
          addDefaultWarningAlert("Rescan failed", [
            `${hostname} has failed to scan ${errorCount} ` +
              (errorCount === 1 ? `time` : `times`) +
              `. The host may be offline. After failing 3 times it will no longer appear in the list.`,
          ])
        );
      }
    };

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "cloudscan/run_scan/v1/",
        { hostname },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      if (e.response.status === 502) {
        handleTimeout();
        return;
      } else if (e.response.status === 422) {
        if (e.json.error_count && e.json.error_count > 0) {
          handleScanError(e.json.error_count);
          return;
        }

        // Some other invalid param
        dispatch(setCloudscanData(hostname, { rescanning: false }));
        dispatch(
          addMessageAlert({
            message: `Error scanning ${hostname}`,
            type: BannerType.WARNING,
            subMessages: [e.json.error],
          })
        );
        return;
      } else {
        LogError("Error scanning hostname", e);
        handleUnknownError();
        return;
      }
    }

    // We've just queued a scan. We now need to poll for results.
    hostname = json.requested_host;
    const {
      error_count: errorCount,
      requested_at: requestedAt,
      estimated_time_ms: estimatedTimeMs,
    } = json;

    if (estimatedTimeCallback) {
      estimatedTimeCallback(estimatedTimeMs);
    }

    // Stop polling after 2 minutes
    const stopPollingAt = moment().add(2, "m");
    while (stopPollingAt.isAfter(moment())) {
      // Wait a couple of seconds between requests
      await new Promise((resolve) => setTimeout(resolve, 2000));

      let pollJson;
      try {
        pollJson = await FetchCyberRiskUrl(
          "cloudscan/poll_scan/v1/",
          { hostname, requested_at: requestedAt },
          { method: "GET" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError("error polling for scan", e);
        handleUnknownError();
        return;
      }

      if (pollJson.status === "NORESULT") {
        // This is a new scan error. Treat it as having an error count one more than the one
        // we know the existing target had.
        handleScanError(errorCount + 1, pollJson.isDisabled);
        return;
      }

      if (pollJson.status === "OK") {
        dispatch(
          setCloudscanData(hostname, {
            result: pollJson.cloudscan,
            rescanning: false,
            loading: false,
            error: null,
          })
        );

        // Add a quick whisper for successful scan returned
        dispatch(addDefaultSuccessAlert(`${hostname} scanned`));
        return;
      }
    }

    // We didn't get a scan back. Likely a timeout.
    handleTimeout();
  };
};

// topic can be one of sales or expand
export const sendSalesNotifyRequest = (request, topic = "sales") => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "salesnotify/v1",
        { sales_request: request, topic },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error sending sales notify request", e);
      throw new Error("Error sending sales notify request.");
    }
  };
};

export const fetchPrefilledSurveyDetailsForAssessment = (
  assessmentId,
  surveyId
) => {
  return async (dispatch, getState) => {
    const assessmentSurveys =
      getState().common.assessmentPrefilledSurveys[assessmentId];
    if (assessmentSurveys && assessmentSurveys[surveyId]) {
      return assessmentSurveys[surveyId].survey;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "prefilledsurveys/details/byassessment/v1/",
        { public_survey_id: surveyId, by_assessment_id: assessmentId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching prefilled survey details", e);

      throw e;
    }

    dispatch(
      setPrefilledSurveyDataForAssessment(assessmentId, surveyId, {
        survey: json.survey,
      })
    );

    return json.survey;
  };
};

export const fetchPrefilledSurveyDetails = (
  surveyId,
  published_only,
  force = false
) => {
  return async (dispatch, getState) => {
    const surveyInState = getState().common.prefilledSurveys[surveyId];
    if (!force && surveyInState && surveyInState.survey) {
      return surveyInState.survey;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "prefilledsurveys/details/v1/",
        { survey_id: surveyId, published_only: published_only },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching prefilled survey details", e);

      throw e;
    }

    dispatch(setPrefilledSurveyData(surveyId, { survey: json.survey }));

    return json.survey;
  };
};

// submitPrefilledSurvey submits the current answers to a questionnaire.
export const submitPrefilledSurvey = (surveyId, noMsg = false) => {
  return async (dispatch, getState) => {
    let res;
    try {
      // Do a partial update with no new answers, so the latest answer set is used.
      res = await FetchCyberRiskUrl(
        "prefilledsurvey/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            surveyId,
            share: true,
            partial: true,
            answers: "{}",
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error submitting prefilled survey", e);

      throw e;
    }

    if (res.status === "LOCKED") {
      dispatch(
        addMessageAlert({
          message: "Sorry, this questionnaire cannot be submitted right now",
          type: BannerType.WARNING,
          subMessages: [
            `Questionnaire is currently locked for editing by ${res.lockedBy}.`,
          ],
        })
      );
      throw "LOCKED";
    } else {
      if (!noMsg) {
        dispatch(addDefaultSuccessAlert("Submitted questionnaire answers"));
      }
    }
  };
};

export const rollbackPrefilledSurvey = (surveyId) => {
  return async (dispatch, getState) => {
    let res;
    try {
      // Do a partial update with no new answers, so the latest answer set is used.
      res = await FetchCyberRiskUrl(
        "prefilledsurvey/rollback/v1/",
        {
          survey_id: surveyId,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error rolling back prefilled survey", e);

      throw e;
    }

    if (res.status === "LOCKED") {
      dispatch(
        addMessageAlert({
          message: "Sorry, this questionnaire cannot be submitted right now",
          type: BannerType.WARNING,
          subMessages: [
            `Questionnaire is currently locked for editing by ${res.lockedBy}.`,
          ],
        })
      );
      throw "LOCKED";
    }
  };
};

export const updatePrefilledSurveyDetails = (surveyId, name, description) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "prefilledsurvey/details/v1",
        {
          survey_id: surveyId,
          name,
          description,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating public survey details", e);

      throw e;
    }
  };
};

export const updateSharedAssessmentDocumentDetails = (
  object,
  name,
  description
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "sharedassessment/documents/details/v1",
        { object, name, description },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating document details", e);

      throw e;
    }
  };
};

export const fetchSharedAssessmentsList = (force = false) => {
  return async (dispatch, getState) => {
    let { sharedAssessmentsList } = getState().common;

    if (!!sharedAssessmentsList && !force) {
      return sharedAssessmentsList;
    }

    try {
      sharedAssessmentsList = await FetchCyberRiskUrl(
        "sharedassessment/list/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching shared assessments list", e);

      throw e;
    }

    dispatch(setSharedAssessmentsList(sharedAssessmentsList));

    return sharedAssessmentsList;
  };
};

export const fetchSharedAssessmentForVendor = (vendorId, force = false) => {
  return async (dispatch, getState) => {
    let sharedAssessment = getState().common.sharedAssessments[vendorId];

    if (!!sharedAssessment && !force) {
      return sharedAssessment;
    }

    try {
      sharedAssessment = await FetchCyberRiskUrl(
        "sharedassessment/v1/",
        {
          vendor_id: vendorId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching shared assessment", e);

      throw e;
    }

    dispatch(setSharedAssessmentData(vendorId, sharedAssessment));

    return sharedAssessment;
  };
};

export const requestAccessToSharedAssessment = (vendorId, message = "") => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "verifiedvendor/requestaccess/v1/",
        {
          vendor_id: vendorId,
          message,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error requesting access to shared assessment", e);
      console.error(e);

      throw e;
    }

    // No issues requesting access. Fetch the shared assessment data now.
    // If access has not been implicitly granted already, we'll at least
    // have an updated access status.

    // Fetch the list again in the background
    dispatch(fetchSharedAssessmentsList(true));

    await dispatch(fetchSharedAssessmentForVendor(vendorId, true));
  };
};

export const fetchUserInitiatedExports = (force = false) => {
  return async (dispatch, getState) => {
    if (!force && getState().common.exportItems.data.length > 0) {
      return getState().common.exportItems.data;
    }

    dispatch(setExportItems([], true));

    let result;
    try {
      result = await FetchCyberRiskUrl(
        "exports/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching user initiated exports", e);
      throw e;
    }

    dispatch(setExportItems(result.exports));

    for (let i = 0; i < result.exports.length; i++) {
      // For any pending exports, start polling for them.
      if (result.exports[i].status === 0) {
        dispatch(pollForPendingUserInitiatedExport(result.exports[i].id));
      }
    }
  };
};

export const fetchAnalystInitiatedExports = (force = false) => {
  return async (dispatch, getState) => {
    if (!force && getState().common.exportItems.data.length > 0) {
      return getState().common.exportItems.data;
    }

    dispatch(setExportItems([], true));

    let result;
    try {
      result = await FetchCyberRiskUrl(
        "exports/v1/",
        {
          archived: false,
          is_analyst_view: true,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching user initiated exports", e);
      throw e;
    }

    dispatch(setExportItems(result.exports));

    for (let i = 0; i < result.exports.length; i++) {
      // For any pending exports, start polling for them.
      if (result.exports[i].status === 0) {
        dispatch(pollForPendingUserInitiatedExport(result.exports[i].id));
      }
    }
  };
};

export const pollForPendingUserInitiatedExport = (itemId) => {
  return async (dispatch, getState) => {
    // Poll for updates on this export every 5 seconds, until we get back a Complete or Error status.
    if (getState().common.exportItemsPolling.indexOf(itemId) > -1) {
      // We're already polling for this one, don't start again.
      return;
    }

    dispatch(setExportItemPolling(itemId, true));

    while (true) {
      await new Promise((resolve) => setTimeout(resolve, 5000));

      let result;
      try {
        result = await FetchCyberRiskUrl(
          "exports/single/v1/",
          {
            id: itemId,
          },
          null,
          dispatch,
          getState
        );
      } catch (e) {
        LogError("error polling for item", e);
        continue;
      }

      if (result.status !== 0) {
        if (result.archivedAt === null) {
          dispatch(setSingleExportItem(result));
        } else {
          dispatch(setSingleExportItemArchived(result));
        }

        break;
      }
    }

    dispatch(setExportItemPolling(itemId, false));
  };
};

export const fetchArchivedReportExports = () => {
  return async (dispatch, getState) => {
    dispatch(setExportItemsArchived([], true));

    let result;
    try {
      result = await FetchCyberRiskUrl(
        "exports/v1/",
        {
          archived: true,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching archived report exports", e);
      throw e;
    }

    dispatch(setExportItemsArchived(result.exports));

    for (let i = 0; i < result.exports.length; i++) {
      // For any pending exports, start polling for them.
      if (result.exports[i].status === 0) {
        dispatch(pollForPendingUserInitiatedExport(result.exports[i].id));
      }
    }
  };
};

export const fetchArchivedAnalystReportExports = () => {
  return async (dispatch, getState) => {
    dispatch(setExportItemsArchived([], true));

    let result;
    try {
      result = await FetchCyberRiskUrl(
        "exports/v1/",
        {
          archived: true,
          is_analyst_view: true,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching archived report exports", e);
      throw e;
    }

    dispatch(setExportItemsArchived(result.exports));

    for (let i = 0; i < result.exports.length; i++) {
      // For any pending exports, start polling for them.
      if (result.exports[i].status === 0) {
        dispatch(pollForPendingUserInitiatedExport(result.exports[i].id));
      }
    }
  };
};

export const loadInitialActivityStream = (force) => {
  return async (dispatch, getState) => {
    if (!force) {
      const activityStream = getState().common.activityStream;
      if (
        activityStream.pageNum >= 0 &&
        !activityStream.loading &&
        !activityStream.reloading
      ) {
        return;
      }
    }
    dispatch(setActivityStreamLoading());
    let result;
    try {
      result = await FetchCyberRiskUrl(
        "activitystreamapi/v1/",
        { quietly: false },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching activity stream", e);
      dispatch(
        setActivityStreamPage(
          [],
          0,
          {
            desc: e,
            retry: () => dispatch(loadInitialActivityStream(force)),
          },
          false,
          null
        )
      );
      throw e;
    }

    dispatch(
      setActivityStreamPage(
        result.stream,
        result.streamPageNum,
        null,
        result.noMorePages,
        result.featureRelease,
        result.promotionEvent,
        result.tasks,
        result.important,
        result.userLastViewed,
        result.pageStartDate,
        result.pageEndDate
      )
    );
  };
};

export const reloadCompleteActivityStream = (background = true) => {
  return async (dispatch, getState) => {
    if (
      getState().common.activityStream.page < 0 ||
      getState().common.activityStream.loading ||
      getState().common.activityStream.error ||
      getState().common.activityStream.reloading
    ) {
      return;
    }
    let result;
    try {
      if (background) {
        dispatch(setActivityStreamBackgroundRefreshRunning(true));
      } else {
        dispatch(setActivityStreamReloading(true));
      }
      result = await FetchCyberRiskUrl(
        "activitystreamapi/v1/",
        {
          quietly: true,
          page_end: getState().common.activityStream.streamEndDate,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      if (background) {
        dispatch(setActivityStreamBackgroundRefreshRunning(false));
      } else {
        dispatch(setActivityStreamReloading(false));
      }
      console.error("failed to refresh activity stream (background)", e);
      throw e;
    }
    // we have re-read the complete activity stream. blat the data into redux
    dispatch(
      refreshActivityStream(
        result.stream,
        result.featureRelease,
        result.promotionEvent,
        result.tasks,
        result.important,
        result.pageStartDate,
        result.pageEndDate
      )
    );
  };
};

export const conditionalRefreshActivityStreamForOrgUser = () => {
  return async (dispatch, getState) => {
    dispatch(reloadCompleteActivityStream());
  };
};

export const getNextActivityStreamPage = (force) => {
  return async (dispatch, getState) => {
    if (
      getState().common.activityStream.complete ||
      getState().common.activityStream.loading ||
      getState().common.activityStream.reloading ||
      (!force && getState().common.activityStream.error)
    ) {
      // there are no more pages, or we are already looking for more so dont start looking again
      // if there's an error then we want to start again from the beginning
      return;
    }
    const currentPageEnd = getState().common.activityStream.streamEndDate;
    dispatch(setActivityStreamLoading());
    const currentPage = getState().common.activityStream
      ? getState().common.activityStream.page
      : -1;
    let result;
    try {
      result = await FetchCyberRiskUrl(
        "activitystreamapi/page/v1/",
        {
          page_num: currentPage + 1,
          page_start: currentPageEnd,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`error fetching activity stream page ${currentPage + 1}`, e);
      dispatch(
        setActivityStreamPage(
          null,
          -1,
          {
            desc: e,
            retry: () => {
              dispatch(getNextActivityStreamPage(true));
            },
          },
          false
        )
      );
      throw e;
    }
    dispatch(
      setActivityStreamPage(
        result.stream,
        result.streamPageNum,
        null,
        result.noMorePages,
        null,
        null,
        null,
        null,
        null,
        result.pageStartDate,
        result.pageEndDate
      )
    );
  };
};

export const performActivityStreamAction = (action, event) => {
  return async (dispatch, getState) => {
    if (getState().common.activityStream.complete) {
      // there are no more pages, so sayeth the database
      return;
    }
    dispatch(setActivityStreamActionRunning(true));
    let result;
    try {
      result = await FetchCyberRiskUrl(
        "activitystreamapi/action/v1/",
        {
          action: action,
        },
        { method: "POST", body: JSON.stringify(event) },
        dispatch,
        getState
      );
      dispatch(setActivityStreamActionRunning(false));
    } catch (e) {
      LogError(`error executing action: ${e}`, e);
      dispatch(setActivityStreamActionRunning(false));
      throw e;
    }
  };
};

export const setUserPrefsForActivityStreamEvent = (
  uuid,
  batchedNotificationIds,
  important,
  dismissed,
  touched,
  dontShow
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "activitystreamapi/updateprefs/v1/",
        {
          uuid: uuid,
          important: important != undefined ? important : null,
          dismissed: dismissed != undefined ? dismissed : null,
          touched: touched != undefined ? touched : null,
          dont_show: dontShow != undefined ? dontShow : null,
          batched_notification_ids: batchedNotificationIds,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`error setting user prefs: ${e}`, e);
      throw e;
    }
  };
};

export const setNamedRoles = (namedRoles) => {
  return {
    type: SET_NAMED_ROLES,
    namedRoles: namedRoles,
  };
};

export const getNamedRoles = () => {
  return async (dispatch, getState) => {
    dispatch(
      setNamedRoles({
        loading: true,
        error: null,
        data: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/named_roles/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching named roles", e);
    }

    if (!json || !json.namedRoles) {
      dispatch(
        setNamedRoles({
          loading: false,
          error: "error fetching named roles",
          data: null,
        })
      );
      return;
    }

    dispatch(
      setNamedRoles({
        loading: false,
        error: null,
        data: json.namedRoles,
      })
    );

    return json.namedRoles;
  };
};

export const editNamedRole = (id, name, roles, portfolioSpecificRoles) => {
  return async (dispatch, getState) => {
    const opts = { id, name, roles };
    if (portfolioSpecificRoles) {
      opts.portfolioSpecificRoles = portfolioSpecificRoles;
    }

    try {
      await FetchCyberRiskUrl(
        "organisation/named_roles/v1",
        undefined,
        { method: "PUT", body: JSON.stringify(opts) },
        dispatch,
        getState
      );
    } catch (e) {
      throw e;
    }
  };
};

export const createNamedRole = (name, roles, portfolioSpecificRoles) => {
  return async (dispatch, getState) => {
    const opts = { name, roles };
    if (portfolioSpecificRoles) {
      opts.portfolioSpecificRoles = portfolioSpecificRoles;
    }

    try {
      await FetchCyberRiskUrl(
        "organisation/named_roles/v1",
        undefined,
        { method: "POST", body: JSON.stringify(opts) },
        dispatch,
        getState
      );
    } catch (e) {
      throw e;
    }
  };
};

export const deleteNamedRole = (id) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/named_roles/v1",
        { id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting named role", e);
    }

    if (json.status === "ERROR") {
      throw new Error(`Error occurred: ${json.desc}`);
    }
  };
};

export const fetchSecurityFrameworkStructure = (frameworkType, force) => {
  return async (dispatch, getState) => {
    let json;
    if (!force) {
      const data = _get(
        getState().common,
        `securityFrameworks[${frameworkType}]`,
        null
      );
      if (!!data && !data.loading && !data.error) {
        return data.structure;
      }
    }

    dispatch(setSecurityFrameworkData(frameworkType, true, null));

    try {
      json = await FetchCyberRiskUrl(
        "secframework/structure/v1/",
        {
          framework: frameworkType,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setSecurityFrameworkData(frameworkType, false, e));
      return {
        loading: false,
        error: `Error fetching ${frameworkType} security framework structure`,
      };
    }

    dispatch(
      setSecurityFrameworkData(frameworkType, false, null, json.structure)
    );
    return json.structure;
  };
};

export const fetchQuestionnaireCoverageForVendor = (
  frameworkType,
  vendorId,
  force
) => {
  return async (dispatch, getState) => {
    let json;
    if (!force) {
      const coverage = _get(
        getState().common,
        `securityFrameworks.${frameworkType}.coverage.${vendorId}`,
        null
      );
      if (!!coverage && !coverage.loading && !coverage.error) {
        return coverage.data;
      }
    }

    dispatch(setQuestionnaireCoverageData(frameworkType, vendorId, true, null));

    try {
      json = await FetchCyberRiskUrl(
        "secframework/coverage/v1/",
        {
          framework: frameworkType,
          vendor_id: vendorId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setQuestionnaireCoverageData(frameworkType, vendorId, false, e));
      LogError(
        `Error fetching coverage for ${frameworkType} vendor ${vendorId}: ${e}`
      );
      return {
        loading: false,
        error: `Error fetching ${frameworkType} security framework structure`,
      };
    }

    dispatch(
      setQuestionnaireCoverageData(frameworkType, vendorId, false, null, {
        sent: json.questionnairesSent,
        submitted: json.questionnairesSubmitted,
      })
    );
    return {
      sent: json.questionnairesSent,
      submitted: json.questionnairesSubmitted,
    };
  };
};

import { FC } from "react";
import { AppRouteParams } from "../../UserBaseNavItems";
import UserBaseApplicationHeader from "../../components/headers/UserBaseApplicationHeader";
import { useBack } from "../../../_common/types/router";
import UserBaseAPI from "../../api/userbase.api";
import { AppStatus } from "../../api/types";
import AppApprovalFlow from "../../components/applications/AppApprovalFlow";
import AppTimeline from "../../components/applications/AppTimeline";
import AppUsersOverview from "../../components/applications/AppUsersOverview";
import "./ApplicationSummaryView.scss";
import AppRisksOverview from "../../components/applications/AppRisksOverview";
import AppPermissionsOverview from "../../components/applications/AppPermissionsOverview";
import AboutApp from "../../components/applications/AboutApp";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { applicationsUrl, appUrlPrefix } from "../../UserBaseAppRouter";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const ApplicationSummaryView: FC = ({}) => {
  const match = useDecodedRouteMatch<AppRouteParams>();
  const { backAction, backText } = useBack();

  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  return (
    <div className="userbase-app-summary">
      <UserBaseApplicationHeader
        appName={appName}
        breadcrumbs={[
          { text: "Applications", to: applicationsUrl },
          {
            text: appName,
            to: `${appUrlPrefix}/${appName}`,
          },
        ]}
        title={<div className="app-overview-score-container">Summary</div>}
        backAction={backAction}
        backText={backText}
      />

      {isLoading && <LoadingBanner size={70} />}
      {!isLoading && (
        <>
          {data?.app.status == AppStatus.NeedsReviewAppStatus && (
            <AppApprovalFlow app={data.app} />
          )}
          <div className="two-grid no-stretch">
            <AppRisksOverview appName={appName} />
            <AppUsersOverview appName={appName} />
          </div>
          <div className="two-grid no-stretch">
            <AppPermissionsOverview appName={appName} />
            <AboutApp appName={appName} />
          </div>
          <AppTimeline appName={appName} />
        </>
      )}
    </div>
  );
};

export default ApplicationSummaryView;

import {
  browserTracingIntegration,
  getClient,
  httpContextIntegration,
  init as sentryInit,
} from "@sentry/browser";
import { Event } from "@sentry/types";

// This regex captures values following 'apikey=' or 'token=' in a URL query string.
//
// Pattern breakdown:
//   - (?<=(apikey|token)=): Positive lookbehind to match 'apikey=' or 'token='
//     as a prefix to the value.
//   - ([a-zA-Z0-9]*): Captures the alphanumeric sequence of any length (value of apikey/token).
//   - (?=&|$|\b): Positive lookahead ensuring the match ends with '&', is the end of a string or a word break.
//
// Flags:
//   - g: Global match to find all instances in the string.
//
// Example: In the query string '?apikey=123abc&token=xyz789', it captures '123abc' and 'xyz789'.
const credentialRegex = /(?<=(apikey|token)=)([a-zA-z0-9]*)(?=&|$|\b)/g;

// disable the sentry client. This is a utility method to be called
// on API session errors, before logging out the users, to avoid
// spamming sentry with unnecessary error logs
export const disableSentry = () => {
  const client = getClient();
  if (client) {
    // Browser is reloading, but in-flight requests can cause spammy errors in the lame-duck application:
    // don't send these to Sentry.
    client.getOptions().enabled = false;
  }
};

export const deepClean = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] == "string") {
        obj[key] = obj[key].replaceAll(credentialRegex, "[REDACTED]");
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        deepClean(obj[key]);
      }
    }
  }

  return obj;
};

// Because we use params for our authentication tokens they can leak into sentry so we want
// to remove them before sending them on. The challenge is that it is hard to know where they
// will pop up within the structure of the event payload we send. Thus, to be safe, we recurse
// through the object and redact any string that matches the param pattern.
const scrubQueryParams = <Type extends Event>(event: Type) => {
  deepClean(event);
  return event;
};

export const initSentry = (sampleRate = 1, tracesSampleRate = 0.1) => {
  sentryInit({
    dsn: "https://c3429bbe62884933b46c92c0653143df@o7749.ingest.sentry.io/220649",
    environment: window.ENV,
    enabled: !(window.ENV === "local"), // Don't enable in local environments
    integrations: [
      httpContextIntegration(),
      browserTracingIntegration({
        idleTimeout: 5000, // Time in ms before the transaction times out when idle. Increasing from 1000 to try and help capture better web vitals.
      }),
    ],
    sampleRate: sampleRate,
    tracesSampleRate: tracesSampleRate,
    beforeSend: scrubQueryParams,
    beforeSendTransaction: scrubQueryParams,
    ignoreErrors: [
      "ChunkLoadError",
      "Unauthorized [TRIAL]",
      // Ignore TypeErrors from fetch for network errors, https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions.
      "TypeError: Failed to fetch",

      // Noise from Outlook Safe link scanning (https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552)
      "Non-Error promise rejection captured with value: Object Not Found Matching Id",
    ],
  });
};

import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import {
  ExcelConfigAction,
  ExcelConfigFrontendSheetState,
  ExcelConfigReducer,
  ExcelConfigState,
  ExcelConfigStateInitial,
  ExcelQnExtractorConfig,
  ExternalDocument,
} from "../types/autofill.types";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { range } from "lodash";
import classNames from "classnames";
import classnames from "classnames";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { SelectV2, SelectV2Multi } from "../../../_common/components/SelectV2";
import LabelList from "../../../vendorrisk/components/LabelList";
import { LabelColor } from "../../../_common/types/label";
import TextField from "../../../_common/components/TextField";
import TabButtons, { tabButton } from "../../../_common/components/TabButtons";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import {
  ExtractExcelAnswers,
  GetGptAutofillSources,
} from "../../reducers/autofill.actions";
import Button from "../../../_common/components/core/Button";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

import "../../style/GptAutofillConfigureSourceModal.scss";
import ContentLibraryAPI, {
  contentLibraryDocumentCacheTag,
} from "../../../contentlibrary/api/contentLibraryAPI";
import SurveyViewerAPI, {
  GptSourceListTag,
} from "../../reducers/SurveyViewerAPI";

interface SheetConfigProps {
  canSetInvalidRows: boolean; // Allow setting excluded rows
  canSetQuestionNumberColumn: boolean; // Allow setting question ID column
  canSetHeadingCells: boolean; // Allow setting heading column and rows
  singleQuestionAnswerColumns: boolean; // Only allow a single question and answer column per sheet
  config: ExcelQnExtractorConfig;
  frontendConfig?: ExcelConfigFrontendSheetState;
  configReducer: React.Dispatch<ExcelConfigAction>;
}

const intToColumn = (i: number): string => {
  let char = "";
  i = i + 1;
  while (i > 0) {
    char = String.fromCharCode(65 + ((i - 1) % 26)) + char;
    i = Math.floor((i - 1) / 26);
  }
  return char;
};
const SheetConfigurator: React.FC<SheetConfigProps> = ({
  canSetInvalidRows,
  canSetQuestionNumberColumn,
  canSetHeadingCells,
  singleQuestionAnswerColumns,
  config,
  frontendConfig,
  configReducer,
}) => {
  // we want to do some conversion of the config in the front end for display

  const columns: IXTableColumnHeader[] = [
    ..." ",
    ...range(config.numValidColumns).map((i) => intToColumn(i)),
  ].map((value, i) => ({
    id: i.toString(),
    text: value,
    className: classNames("header", "preview-cell"),
  }));

  const rows: IXTableRow[] = config.preview
    .slice(0, config.numValidRows)
    .map((row, i) => {
      const cells = [
        <XTableCell key={"header"} className={"preview-cell row-header"}>
          {i + 1}
        </XTableCell>,
      ];

      const rowSelectable =
        config.selected &&
        (config.answerRowOffset !== undefined
          ? i >= config.answerRowOffset
          : i > 0) &&
        !config.invalidRows?.[i];

      const headingRow = config.selected && config.headingRows?.[i];

      cells.push(
        ...range(0, config.numValidColumns).map((j) => (
          <XTableCell
            key={j}
            className={classnames("preview-cell", {
              "answer-selected":
                rowSelectable && config.answerColumns.includes(j),
              "question-selected":
                rowSelectable && config.questionColumns.includes(j),
              "is-question-number":
                rowSelectable && j === config.questionNumberColumn,
              "heading-selected": headingRow && j === config.headingColumn,
            })}
          >
            {!!row && row[j] != undefined ? row[j] : ""}
          </XTableCell>
        ))
      );

      return {
        id: i,
        cells,
      };
    });

  const getColumnOpts = (excludeCols: (number | undefined)[]) =>
    range(0, config.numValidColumns)
      .filter((i) => !excludeCols.includes(i))
      .map((i) => ({
        value: i,
        label: intToColumn(i),
      }));

  const questionOptions = getColumnOpts([
    config.questionNumberColumn,
    ...config.answerColumns,
  ]);

  const answerOptions = getColumnOpts([
    config.questionNumberColumn,
    ...config.questionColumns,
  ]);

  const questionNumberOptions = getColumnOpts([
    ...config.answerColumns,
    ...config.questionColumns,
  ]);

  return (
    <div className={"sheet-config"}>
      <div className={"selectors"}>
        <div className={"included"}>
          <ColorCheckbox
            checked={config.selected}
            label={"Include this sheet"}
            onClick={() =>
              configReducer({
                type: "TOGGLE_SELECTED",
                sheet: config.sheetName,
              })
            }
          />
        </div>
        <div
          className={classNames("selector-inputs", {
            "single-question-answer": singleQuestionAnswerColumns,
            disabled: !config.selected,
          })}
        >
          <div className={"column-selector question-column-selector"}>
            <div className={"label"}>
              <mark>
                Question {singleQuestionAnswerColumns ? "column" : "columns"}{" "}
              </mark>
              <SidePopupV2
                text={
                  singleQuestionAnswerColumns
                    ? "Select which column in the sheet contains questions."
                    : "Select which columns in the sheet contain questions."
                }
                position={"top"}
              >
                <i className={"cr-icon-info"} />
              </SidePopupV2>
            </div>
            {singleQuestionAnswerColumns ? (
              <SelectV2
                key={config.sheetName + "-q-col"} // Force rerender when sheet changes, otherwise SelectV2 may show the old value
                isDisabled={!config.selected}
                placeholder={"Select column"}
                options={questionOptions}
                value={
                  config.questionColumns.length > 0
                    ? {
                        value: config.questionColumns[0],
                        label: intToColumn(config.questionColumns[0]),
                      }
                    : undefined
                }
                onChange={(val) => {
                  configReducer({
                    type: "SET_QUESTION_COLUMNS",
                    sheet: config.sheetName,
                    columns: val ? [Number(val.value)] : [],
                  });
                  // if we change this option assume we want the sheet selected
                  configReducer({
                    type: "SELECT_SHEET",
                    sheet: config.sheetName,
                  });
                }}
                isClearable
              />
            ) : (
              <>
                <SelectV2Multi
                  isDisabled={!config.selected}
                  placeholder={"Select columns"}
                  options={questionOptions}
                  value={config.questionColumns.map((i) => ({
                    value: i,
                    label: intToColumn(i),
                  }))}
                  onChange={(values) => {
                    configReducer({
                      type: "SET_QUESTION_COLUMNS",
                      sheet: config.sheetName,
                      columns: Array.isArray(values)
                        ? values.map((value) => value.value as number)
                        : [],
                    });
                    // if we change this option assume we want the sheet selected
                    configReducer({
                      type: "SELECT_SHEET",
                      sheet: config.sheetName,
                    });
                  }}
                  controlShouldRenderValue={false}
                  isClearable={false}
                />
                {config.selected ? (
                  <LabelList
                    labels={config.questionColumns.map((c) => ({
                      id: c,
                      color: LabelColor.Violet,
                      removeable: true,
                      name: intToColumn(c),
                      onRemoveClick: () =>
                        configReducer({
                          type: "SET_QUESTION_COLUMNS",
                          sheet: config.sheetName,
                          columns: config.questionColumns.filter(
                            (col) => col != c
                          ),
                        }),
                    }))}
                  />
                ) : (
                  <div className="label-list" />
                )}
              </>
            )}
          </div>
          <div className={"column-selector answer-column-selector"}>
            <div className={"label"}>
              <mark>
                Answer or input{" "}
                {singleQuestionAnswerColumns ? "column" : "columns"}{" "}
              </mark>
              <SidePopupV2
                text={
                  singleQuestionAnswerColumns
                    ? "Select the column that contains answers or shows the input type e.g. multiple choice."
                    : "Select the columns in the sheet contain answers or show the input type e.g. multiple choice."
                }
                position={"top"}
              >
                <i className={"cr-icon-info"} />
              </SidePopupV2>
            </div>
            {singleQuestionAnswerColumns ? (
              <SelectV2
                key={config.sheetName + "-a-col"} // Force rerender when sheet changes, otherwise SelectV2 may show the old value
                isDisabled={!config.selected}
                placeholder={"Select column"}
                options={answerOptions}
                value={
                  config.answerColumns.length > 0
                    ? {
                        value: config.answerColumns[0],
                        label: intToColumn(config.answerColumns[0]),
                      }
                    : undefined
                }
                onChange={(val) => {
                  configReducer({
                    type: "SET_ANSWER_COLUMNS",
                    sheet: config.sheetName,
                    columns: val ? [Number(val.value)] : [],
                  });
                  // if we change this option assume we want the sheet selected
                  configReducer({
                    type: "SELECT_SHEET",
                    sheet: config.sheetName,
                  });
                }}
                isClearable
              />
            ) : (
              <>
                <SelectV2Multi
                  isDisabled={!config.selected}
                  placeholder={"Select columns"}
                  options={answerOptions}
                  value={config.answerColumns.map((i) => ({
                    value: i,
                    label: intToColumn(i),
                  }))}
                  onChange={(values) => {
                    configReducer({
                      type: "SET_ANSWER_COLUMNS",
                      sheet: config.sheetName,
                      columns: Array.isArray(values)
                        ? values.map((value) => value.value as number)
                        : [],
                    });
                    // if we change this option assume we want the sheet selected
                    configReducer({
                      type: "SELECT_SHEET",
                      sheet: config.sheetName,
                    });
                  }}
                  controlShouldRenderValue={false}
                  isClearable={false}
                />
                {config.selected ? (
                  <LabelList
                    labels={config.answerColumns.map((c) => ({
                      id: c,
                      color: LabelColor.Orange,
                      removeable: true,
                      name: intToColumn(c),
                      onRemoveClick: () =>
                        configReducer({
                          type: "SET_ANSWER_COLUMNS",
                          sheet: config.sheetName,
                          columns: config.answerColumns.filter(
                            (col) => col != c
                          ),
                        }),
                    }))}
                  />
                ) : (
                  <div className="label-list" />
                )}
              </>
            )}
          </div>

          {canSetQuestionNumberColumn && (
            <div className="column-selector number-column-selector">
              <div className={"label"}>
                Number column (optional)
                <SidePopupV2
                  text="Select the column that contains the questionnaire numbering system."
                  position={"top"}
                >
                  <i className={"cr-icon-info"} />
                </SidePopupV2>
              </div>
              <SelectV2
                key={config.sheetName + "-num-col"} // Force rerender when sheet changes, otherwise SelectV2 may show the old value
                isDisabled={!config.selected}
                placeholder={"Select column"}
                options={questionNumberOptions}
                value={
                  config.questionNumberColumn !== undefined
                    ? {
                        value: config.questionNumberColumn,
                        label: intToColumn(config.questionNumberColumn),
                      }
                    : undefined
                }
                onChange={(val) => {
                  configReducer({
                    type: "SET_QUESTION_NUMBER_COLUMN",
                    sheet: config.sheetName,
                    column: val ? Number(val.value) : undefined,
                  });
                  // if we change this option assume we want the sheet selected
                  configReducer({
                    type: "SELECT_SHEET",
                    sheet: config.sheetName,
                  });
                }}
                isClearable
              />
            </div>
          )}

          {canSetHeadingCells && (
            <div className={"column-selector heading-column-selector"}>
              <div className={"label"}>
                <mark>Heading column (optional)</mark>
                <SidePopupV2
                  text="Select the column that contains the section headings."
                  position={"top"}
                >
                  <i className={"cr-icon-info"} />
                </SidePopupV2>
              </div>
              <SelectV2
                key={config.sheetName + "-heading-col"} // Force rerender when sheet changes, otherwise SelectV2 may show the old value
                isDisabled={!config.selected}
                placeholder={"Select column"}
                options={getColumnOpts([])}
                value={
                  config.headingColumn !== undefined
                    ? {
                        value: config.headingColumn,
                        label: intToColumn(config.headingColumn),
                      }
                    : undefined
                }
                onChange={(val) => {
                  configReducer({
                    type: "SET_HEADING_COLUMN",
                    sheet: config.sheetName,
                    column: val ? Number(val.value) : undefined,
                  });
                  // if we change this option assume we want the sheet selected
                  configReducer({
                    type: "SELECT_SHEET",
                    sheet: config.sheetName,
                  });
                }}
                isClearable
              />
            </div>
          )}

          <div className={"offset-selector"}>
            <div className={"label"}>
              Starting row{" "}
              <SidePopupV2
                text={
                  "Select the row that contains the first question of your questionnaire, ignoring headings"
                }
                position={"top"}
              >
                <i className={"cr-icon-info"} />
              </SidePopupV2>
            </div>
            <TextField
              disabled={!config.selected}
              type={"number"}
              value={
                config.answerRowOffset != undefined
                  ? `${config.answerRowOffset + 1}`
                  : ""
              }
              debounceTime={100}
              minNumber={1}
              maxNumber={config.numValidRows}
              required
              onChanged={(val) => {
                let v: number | undefined = parseInt(val);
                if (isNaN(v)) {
                  v = undefined;
                } else {
                  v -= 1;
                }
                configReducer({
                  type: "SET_ROW_OFFSET",
                  sheet: config.sheetName,
                  offset: v,
                });
              }}
              placeholder={"Starting row"}
            />
          </div>

          {canSetInvalidRows && (
            <div className="exclude-rows-selector">
              <div className="label">
                Non-question rows{" "}
                <SidePopupV2
                  text="Select any rows which don't contain questions. Use commas to separate row numbers and hyphens if there's a range of consecutive rows e.g. 1,4,5-7,10."
                  position={"top"}
                >
                  <i className={"cr-icon-info"} />
                </SidePopupV2>
              </div>
              <TextField
                disabled={!config.selected}
                value={frontendConfig?.invalidRowsAsString ?? ""}
                onChanged={(val) =>
                  configReducer({
                    type: "SET_INVALID_ROWS",
                    sheet: config.sheetName,
                    inputString: val,
                  })
                }
                placeholder="1,4,5-7"
                debounceTime={100}
                pattern={/^[0-9-,\s]*$/}
                errorTexts={
                  frontendConfig?.invalidRowsAsString &&
                  !frontendConfig.invalidRowsAsStringValid
                    ? ["Invalid format"]
                    : undefined
                }
              />
            </div>
          )}

          {canSetHeadingCells && (
            <div className="exclude-rows-selector">
              <div className="label">
                Heading rows{" "}
                <SidePopupV2
                  text="Select which rows contain section headings to group related questions together. Use commas to separate row numbers."
                  position={"top"}
                >
                  <i className={"cr-icon-info"} />
                </SidePopupV2>
              </div>
              <TextField
                disabled={!config.selected}
                value={frontendConfig?.headingRowsAsString ?? ""}
                onChanged={(val) =>
                  configReducer({
                    type: "SET_HEADING_ROWS",
                    sheet: config.sheetName,
                    inputString: val,
                  })
                }
                placeholder="1,4,13"
                debounceTime={100}
                pattern={/^[0-9,\s]*$/}
                errorTexts={
                  frontendConfig?.headingRowsAsString &&
                  !frontendConfig.headingRowsAsStringValid
                    ? ["Invalid format"]
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className={"preview-area"}>
        <XTable rows={rows} columnHeaders={columns} stickyColumnHeaders />
      </div>
    </div>
  );
};

interface ConfigureDocumentStepProps {
  canSetInvalidRows?: boolean;
  canSetQuestionNumberColumn?: boolean;
  canSetHeadingCells?: boolean;
  singleQuestionAnswerColumns?: boolean;
  configState: ExcelConfigState;
  configReducer: React.Dispatch<ExcelConfigAction>;
}

export const ConfigureDocumentStep: FC<ConfigureDocumentStepProps> = ({
  canSetInvalidRows = false,
  singleQuestionAnswerColumns = false,
  canSetQuestionNumberColumn = false,
  canSetHeadingCells = false,
  configReducer,
  configState,
}) => {
  const [currentSheetName, setCurrentSheetName] = useState("");
  // if we get passed a new state make sure that we set the current sheet name to something valid
  useEffect(() => {
    if (
      !Object.keys(configState.sheets).includes(currentSheetName) &&
      Object.keys(configState.sheets).length > 0
    ) {
      // if we have any sheets selected already find the first one and pick that
      const config = Object.values(configState.sheets).find((c) => c.selected);
      if (config) {
        setCurrentSheetName(config.sheetName);
        return;
      } else {
        // If no selected sheets found, select the first visible sheet
        const visibleConfig = Object.values(configState.sheets).find(
          (c) => !c.hidden
        );
        if (visibleConfig) {
          setCurrentSheetName(visibleConfig.sheetName);
          return;
        }
      }
      setCurrentSheetName(Object.keys(configState.sheets)[0]);
    }
  }, [currentSheetName, configState]);

  const tabs: tabButton[] = Object.entries(configState.sheets)
    .filter(([, config]) => !config.hidden ?? false) // If hidden is not set, assume the sheet is visible
    .map(([sheetName, config]) => ({
      id: sheetName,
      text: (
        <span className={"sheet-tab-label"}>
          {sheetName}
          {config.selected && <i className={"cr-icon-accepted"} />}
        </span>
      ),
    }));

  return (
    <div className={"gpt-autofill-modal-excel-configure-step"}>
      <div className={"selector-tabs"}>
        <div className={"title"}>Selected sheet</div>
        <TabButtons
          tabs={tabs}
          activeTabId={currentSheetName}
          onChangeTab={(tabId) => setCurrentSheetName(tabId)}
          newScroller
        />
      </div>
      {configState.sheets[currentSheetName] && (
        <SheetConfigurator
          canSetInvalidRows={canSetInvalidRows}
          singleQuestionAnswerColumns={singleQuestionAnswerColumns}
          canSetQuestionNumberColumn={canSetQuestionNumberColumn}
          canSetHeadingCells={canSetHeadingCells}
          config={configState.sheets[currentSheetName]}
          frontendConfig={configState.frontendSheetState?.[currentSheetName]}
          configReducer={configReducer}
        />
      )}
    </div>
  );
};

interface IGptAutofillConfigureSourceModalProps extends BaseModalProps {
  externalDocUUID: string;
  cancelText?: string;
  onExtractedAnswers?: (uuid: string) => void;
}

const GptAutofillConfigureSourceModal: FC<
  IGptAutofillConfigureSourceModalProps
> = ({ externalDocUUID, onExtractedAnswers, cancelText, active, onClose }) => {
  const dispatch = useAppDispatch();
  const configuringDoc = useAppSelector(
    (state) =>
      (
        state.surveyViewer.autofillSourceDocs as Record<
          string,
          ExternalDocument | undefined
        >
      )[externalDocUUID]
  );

  const [loading, setLoading] = useState(false);

  const [configState, configReducer] = useReducer(
    ExcelConfigReducer,
    ExcelConfigStateInitial
  );

  const canSaveSource = useMemo(
    () =>
      Object.values(configState.sheets).some(
        (c) =>
          c.selected &&
          c.questionColumns.length > 0 &&
          c.answerColumns.length > 0 &&
          c.answerRowOffset != undefined &&
          c.answerRowOffset > 0 &&
          c.answerRowOffset < c.numValidRows
      ),
    [configState.sheets]
  );

  const extractAnswers = useCallback(async () => {
    if (!configuringDoc) {
      return;
    }

    setLoading(true);
    try {
      await dispatch(
        ExtractExcelAnswers(
          Object.values(configState.sheets),
          configuringDoc.uuid
        )
      );

      dispatch(
        SurveyViewerAPI.util.invalidateTags([{ type: GptSourceListTag }])
      );

      if (configuringDoc.documentRepositoryUUID) {
        // Clear the doc repo cache for this uuid
        dispatch(
          ContentLibraryAPI.util.invalidateTags([
            {
              type: contentLibraryDocumentCacheTag,
              id: configuringDoc.documentRepositoryUUID,
            },
          ])
        );
      }
      onExtractedAnswers?.(configuringDoc.uuid);
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(addDefaultUnknownErrorAlert("Error updating Q&A columns"));
    }

    setLoading(false);
  }, [configuringDoc, onExtractedAnswers, onClose, dispatch, configState]);

  useEffect(() => {
    if (!configuringDoc) {
      // Refresh the list of sources if we don't already have the configuring doc loaded
      dispatch(GetGptAutofillSources());
    } else {
      configReducer({
        type: "FROM_STATE",
        state: configuringDoc.documentConfig.excelConfig.config,
      });
    }
  }, [configuringDoc, dispatch]);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="gpt-autofill-configure-source-modal"
      headerContent={
        <h2 className="title">
          Edit Q&A columns
          {configuringDoc ? ` (${configuringDoc.name})` : undefined}
        </h2>
      }
      subHeaderContent="Specify which columns contain questions and answers based on the sheet preview."
      footerContent={
        <>
          <Button onClick={onClose} tertiary>
            {cancelText ?? "Cancel"}
          </Button>
          <Button
            onClick={extractAnswers}
            loading={loading}
            primary
            disabled={!canSaveSource}
          >
            Save
          </Button>
        </>
      }
    >
      {configuringDoc ? (
        <ConfigureDocumentStep
          configState={configState}
          configReducer={configReducer}
        />
      ) : (
        <LoadingBanner />
      )}
    </ModalV2>
  );
};

export default GptAutofillConfigureSourceModal;

import { FC, useState } from "react";
import SearchBox from "../../../_common/components/SearchBox";
import ReportCard from "../../../_common/components/ReportCard";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import userbaseApi from "../../api/userbase.api";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import XTable, {
  IXTableColumnHeader,
  SortDirection,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../../_common/hooks";
import { AppWithVendor } from "../../api/types";
import SecurityRatingDisplay, { RatingSize } from "../SecurityRatingDisplay";
import { useHistory, useLocation } from "react-router-dom";
import "./PermissionApps.scss";
import AppStatusDisplay from "../applications/AppStatusDisplay";
import { appUrl, permissionAppsUrl } from "../../UserBaseAppRouter";

interface PermissionAppsProps {
  scopeExternalId: string;
}

const PAGE_SIZE = 50;

const APP_NAME_COL = "name_col";
const SECURITY_RATING_COL = "rating_col";
const CURRENT_USERS_COL = "current_users_col";
const APP_STATUS_COL = "status_col";

const PermissionApps: FC<PermissionAppsProps> = ({ scopeExternalId }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  // Get all the apps for the permission
  const { data, isLoading } = userbaseApi.useGetUserBaseAppsV1Query({
    permissions: [scopeExternalId],
    excludePermissions: false,
  });

  const appsToShow = data?.apps ?? [];

  // Filter apps returned by the search text
  const [searchText, setSearchText] = useState("");

  const filteredApps = appsToShow.filter(
    (a) =>
      !searchText ||
      a.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  // Build out the columns
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: APP_NAME_COL,
      text: "App name",
      className: APP_NAME_COL,
      sortable: true,
    },
    {
      id: SECURITY_RATING_COL,
      text: "Security rating",
      className: SECURITY_RATING_COL,
      sortable: true,
    },
    {
      id: APP_STATUS_COL,
      text: "Status",
      className: APP_STATUS_COL,
      sortable: true,
    },
    {
      id: CURRENT_USERS_COL,
      text: "Current users",
      className: CURRENT_USERS_COL,
      sortable: true,
    },
  ];

  const [
    sortedApps,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    AppWithVendor,
    | typeof APP_NAME_COL
    | typeof CURRENT_USERS_COL
    | typeof SECURITY_RATING_COL
    | typeof APP_STATUS_COL
  >(
    filteredApps,
    APP_NAME_COL,
    SortDirection.ASC,
    {
      [APP_NAME_COL]: {
        orderFuncs: [(a) => a.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [CURRENT_USERS_COL]: {
        orderFuncs: [(a) => a.numCurrentUsers],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(a) => a.securityScore],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [APP_STATUS_COL]: {
        orderFuncs: [(a) => a.status],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const rows = sortedApps.map((app: AppWithVendor): IXTableRow<string> => {
    const onClick = () => {
      history.push(appUrl(app.name), {
        from: "permissions",
        backContext: {
          backTo: permissionAppsUrl(scopeExternalId),
          backToText: "Back to Permissions",
        },
      });
    };

    return {
      id: app.name,
      onClick: onClick,
      cells: [
        <XTableCell key={APP_NAME_COL}>
          <CompanyLogo
            domain={app.domain}
            name={app.name}
            displayCategory={true}
            category={app.category}
          />
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <SecurityRatingDisplay
            rating={app.securityScore}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key={APP_STATUS_COL}>
          <AppStatusDisplay app={app} />
        </XTableCell>,
        <XTableCell key={CURRENT_USERS_COL}>{app.numCurrentUsers}</XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: onClick,
        },
      ],
    };
  });

  const isAppsEmpty = !isLoading && !searchText && filteredApps.length === 0;
  const isSearchEmpty = !isLoading && !!searchText && filteredApps.length === 0;

  return (
    <div className="permission-apps">
      <ReportCard newStyles>
        <SearchBox
          placeholder={"Search applications"}
          onChanged={(q) => setSearchText(q)}
          value={searchText}
        />
        {isAppsEmpty && (
          <EmptyCard text={"There are no applications for this permission."} />
        )}
        {isSearchEmpty && (
          <SearchEmptyCard
            clearText={"Clear filter"}
            onClear={() => {
              setSearchText("");
            }}
            searchItemText={"applications"}
          />
        )}
        {!isLoading && filteredApps.length > 0 && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default PermissionApps;

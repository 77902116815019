import SearchBox from "../../../_common/components/SearchBox";
import ReportCard from "../../../_common/components/ReportCard";
import XTable, {
  SortDirection,
  XTableCell,
  IXTableColumnHeader,
} from "../../../_common/components/core/XTable";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../../vendorrisk/components/Score";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import { useSortingWithPagination } from "../../../_common/hooks";

import { SaaSUser, AppUser } from "../../api/types";
import UserBaseAPI from "../../api/userbase.api";
import "./ApplicationUsersView.scss";

import { FC, useState } from "react";
import { AppRouteParams } from "../../UserBaseNavItems";
import UserBaseApplicationHeader from "../../components/headers/UserBaseApplicationHeader";
import { useBack } from "../../../_common/types/router";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";
import TeamsPills from "../../components/TeamsPills";
import UserApprovalPill, {
  userApprovalStatus,
} from "../../components/users/UserApprovalPill";
import ScopeRiskLevelDisplay from "../../components/permissions/ScopeRiskLevelDisplay";
import UserPermissionsForApp from "../../components/users/UserPermissionsForApp";
import {
  applicationsUrl,
  appUrlPrefix,
  appUsersUrl,
} from "../../UserBaseAppRouter";
import SlidePanel from "../../../_common/components/SlidePanel";
import { useDecodedRouteMatch } from "../../../_common/hooks/useDecodedRouteMatch";

const PAGE_SIZE = 50;

const NAME_COL = "name_col";
const TEAM_COL = "team_col";
const ROLE_COL = "role_col";
const SECURITY_RATING_COL = "rating_col";
const EXPOSURE_LEVEL_COL = "exposure_col";
const FIRST_DETECTED_COL = "first_detected_col";
const APP_STATUS_COL = "status_col";

const ApplicationUsersView: FC = ({}) => {
  const { backAction, backText } = useBack();

  const match = useDecodedRouteMatch<AppRouteParams>();
  const appName = match.params.appName;

  const [searchText, setSearchText] = useState<string>("");

  const [slidePanelUser, setSlidePanelUser] = useState<AppUser | undefined>(
    undefined
  );

  // Get the list of users
  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppUsersV1Query({
    app: appName,
  });
  const userList = data?.users ?? [];

  // Filter the users by the search text
  const bySearchText = (user: SaaSUser): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    if (
      user.roles &&
      user.roles.some((r) => r.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    if (
      user.teams &&
      user.teams.some((t) => t.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    return user.name.toLocaleLowerCase().indexOf(toFind) >= 0;
  };

  const filteredUsers = userList.filter(bySearchText);

  // Sort and paginate the users
  const [
    sortedUsers,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    AppUser,
    | typeof NAME_COL
    | typeof TEAM_COL
    | typeof ROLE_COL
    | typeof SECURITY_RATING_COL
    | typeof EXPOSURE_LEVEL_COL
    | typeof FIRST_DETECTED_COL
    | typeof APP_STATUS_COL
  >(
    filteredUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            u.teams
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [
          (u) =>
            u.roles
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(u) => u.rating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [EXPOSURE_LEVEL_COL]: {
        orderFuncs: [(u: AppUser) => u.highestRiskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [FIRST_DETECTED_COL]: {
        orderFuncs: [(u) => u.firstDetected],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [APP_STATUS_COL]: {
        orderFuncs: [(u) => userApprovalStatus(u.approved, u.waived)],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE
  );

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: NAME_COL,
      text: "Name",
      sortable: true,
      className: "name-col",
    },
    {
      id: ROLE_COL,
      text: "Role",
      sortable: true,
      className: "roles-col",
    },
    {
      id: TEAM_COL,
      text: "Teams",
      sortable: true,
      className: "teams-col",
    },
    {
      id: SECURITY_RATING_COL,
      text: "User rating",
      sortable: true,
      className: "rating-col",
    },
    {
      id: EXPOSURE_LEVEL_COL,
      text: "Exposure level",
      sortable: true,
      className: "exposure-col",
    },
    {
      id: FIRST_DETECTED_COL,
      text: "First detected",
      sortable: true,
      className: "first-detected-col",
    },
    {
      id: APP_STATUS_COL,
      text: "Status",
      sortable: true,
      className: "status-col",
    },
  ];

  const rows = sortedUsers.map((u) => {
    return {
      id: u.uuid,
      onClick: () => setSlidePanelUser(u),
      cells: [
        <XTableCell key={NAME_COL} className="app-users-cell-with-subtext">
          <div className="app-users-maintext">{u.name}</div>
        </XTableCell>,
        <XTableCell key={ROLE_COL}>
          {u.roles ? u.roles.join(", ") : " "}
        </XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <div className="app-users-score-container">
            <ColorGrade score={u.rating} size={ColorGradeSize.Small} />
            <Score score={u.rating} small />
          </div>
        </XTableCell>,
        <XTableCell key={EXPOSURE_LEVEL_COL}>
          <ScopeRiskLevelDisplay riskLevel={u.highestRiskLevel} />
        </XTableCell>,
        <XTableCell key={FIRST_DETECTED_COL}>
          <DateTimeFormat dateTime={u.firstDetected} dateOnly />
        </XTableCell>,
        <XTableCell key={APP_STATUS_COL}>
          <UserApprovalPill approved={u.approved} waived={u.waived} />
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => setSlidePanelUser(u),
        },
      ],
    };
  });

  const isEmptyResult = !isLoading && sortedUsers.length === 0;
  const isSearching = searchText !== "";

  return (
    <div className="app-users">
      <UserBaseApplicationHeader
        appName={appName}
        breadcrumbs={[
          { text: "Applications", to: applicationsUrl },
          {
            text: appName,
            to: `${appUrlPrefix}/${match.params.appName}`,
          },
          {
            text: "Users",
            to: appUsersUrl(match.params.appName),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Users"}
      />

      <ReportCard newStyles>
        <div className="table-filters">
          <SearchBox
            placeholder={"Search users"}
            onChanged={(q) => {
              onPageChange(1);
              setSearchText(q);
            }}
            value={searchText}
          />
        </div>

        {isEmptyResult && isSearching && (
          <SearchEmptyCard
            searchItemText={"users"}
            onClear={() => setSearchText("")}
          />
        )}
        {isEmptyResult && !isSearching && (
          <EmptyCard
            text={"There are no users for this application at this time."}
          />
        )}
        {(isLoading || !isEmptyResult) && (
          <XTable
            className="app-users-users-list"
            loading={isLoading}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            iconOptions
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
            columnHeaders={columnHeaders}
            rows={rows}
          />
        )}
      </ReportCard>
      <SlidePanel
        newStyles
        dimContent
        active={!!slidePanelUser}
        className={"app-users-slide-panel"}
        onClose={() => {
          setSlidePanelUser(undefined);
        }}
      >
        {!!slidePanelUser && (
          <UserPermissionsForApp appName={appName} user={slidePanelUser} />
        )}
      </SlidePanel>
    </div>
  );
};

export default ApplicationUsersView;

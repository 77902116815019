import { UserEmailAddress } from "../../_common/types/user";
import classNames from "classnames";
import UserDisplay from "../../vendorrisk/components/UserDisplay";
import "../styles/ImpersonateUserSelect.scss";
import { OptionType, SelectV2 } from "../../_common/components/SelectV2";
import { Option } from "react-select/src/filters";
import { useMemo } from "react";
import SystemAdminAPI from "../api/systemAdmin.api";

export interface ImpersonateUserSelectProps {
  className?: string;
  name?: string;
  value?: number;
  orgId: number;
  onSelect: (userId?: number) => void;
}

type UserEmailAddressOption = OptionType & {
  user: UserEmailAddress;
};

function formatOptionLabel(option: UserEmailAddressOption) {
  return (
    <UserDisplay
      avatar={option.user.avatar}
      name={option.user.name ?? ""}
      email={option.user.emailAddress}
    />
  );
}

function filterOption(option: Option, rawInput: string) {
  if (rawInput) {
    const searchLower = rawInput.toLocaleLowerCase();
    return (
      option.data?.user?.name?.toLocaleLowerCase().includes(searchLower) ||
      option.data?.user?.emailAddress.toLocaleLowerCase().includes(searchLower)
    );
  }
  return true;
}

function ImpersonateUserSelect({
  className,
  name,
  orgId,
  value,
  onSelect,
}: ImpersonateUserSelectProps) {
  const { currentData: { users } = {}, isFetching } =
    SystemAdminAPI.useListImpersonateOrgUsersV1Query({ orgId });

  const options = useMemo(
    () =>
      users?.map((user) => ({
        label: user.emailAddress,
        value: user.id,
        user,
      })),
    [users]
  );

  const selectedOption = options?.find((o) => o.user.id === value);

  return (
    <div className={classNames(className, "impersonate-user-select")}>
      <SelectV2<false, UserEmailAddressOption>
        inputId={name}
        isClearable
        backspaceRemovesValue
        openMenuOnFocus
        isSearchable
        placeholder="Search, or select a user..."
        isLoading={isFetching}
        options={options}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
        onChange={(option) => onSelect(option?.user.id)}
        value={selectedOption}
      />
    </div>
  );
}

export default ImpersonateUserSelect;

import {
  GetAdditionalEvidenceEvidence,
  GetAssessmentWaiverText,
  GetSurveyEvidence,
  VendorAssessment,
  VendorAssessmentEvidence,
  VendorAssessmentStatus,
} from "../../types/vendorAssessments";
import { FC, memo, PropsWithChildren } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import IconButton from "../../../_common/components/IconButton";
import VendorAssessmentV3SurveysTable from "./VendorAssessmentV3SurveysTable";
import VendorAssessmentV3AdditionalEvidenceTable from "./VendorAssessmentV3AdditionalEvidenceTable";
import VendorAssessmentV3PagesTable from "./VendorAssessmentV3PagesTable";
import "../../style/components/vendor_assessment/VendorAssessmentV3EvidenceUsedCard.scss";
import classnames from "classnames";

interface EvidenceUsedSectionProps extends PropsWithChildren {
  title: string;
  onClick?: () => void;
}

const EvidenceUsedSection: FC<EvidenceUsedSectionProps> = ({
  title,
  children,
  onClick,
}) => (
  <div className={"evidence-section"}>
    <div className={"evidence-header"}>{title}</div>
    <div
      className={classnames("blue-section", { clickable: !!onClick })}
      onClick={onClick}
    >
      {children}
    </div>
  </div>
);

interface VendorAssessmentV3EvidenceUsedCardProps {
  assessment: VendorAssessment;
  evidence: VendorAssessmentEvidence;
  numRiskWaivers: number;
  numUnapprovedRiskWaivers: number;
  numPendingPublicRiskWaivers: number;
  goToWaivers: () => void;
  goToSurvey: (id: number, isPublic: boolean) => void;
  goToAdditionalEvidence: (id: number, isPublic: boolean) => void;
  goToEvidencePage: (url: string, category: string) => void;
  orgHasSurveyScoresEnabled: boolean;
  onOpenDomains: () => void;
}

const VendorAssessmentV3EvidenceUsedCard: FC<
  VendorAssessmentV3EvidenceUsedCardProps
> = ({
  assessment,
  evidence,
  numRiskWaivers,
  numUnapprovedRiskWaivers,
  numPendingPublicRiskWaivers,
  goToAdditionalEvidence,
  goToEvidencePage,
  goToSurvey,
  goToWaivers,
  orgHasSurveyScoresEnabled,
  onOpenDomains,
}) => {
  const surveys = evidence.surveys
    ? GetSurveyEvidence(evidence, assessment.includeWaivers)?.filter(
        (s) => s.selected
      )
    : [];
  const numSurveys = surveys.filter((s) => s.selected).length;

  const additionalEvidence = evidence.additional
    ? GetAdditionalEvidenceEvidence(
        evidence,
        assessment.includeWaivers
      )?.filter((a) => a.selected)
    : [];
  const numAdditional = additionalEvidence.filter((a) => a.selected).length;

  const trustPages = evidence.pages?.filter((p) => p.selected) ?? [];
  const numPages = trustPages.length;

  const isPublished = assessment.status === VendorAssessmentStatus.Published;
  const unapprovedCount = isPublished ? 0 : numUnapprovedRiskWaivers;
  const pendingPublicCount = isPublished ? 0 : numPendingPublicRiskWaivers;
  const waiverText = GetAssessmentWaiverText(
    numRiskWaivers,
    unapprovedCount,
    pendingPublicCount
  );

  return (
    <ReportCard newStyles className={"evidence-used-card"}>
      <div className={"header"}>Evidence used</div>
      <div className={"main-section"}>
        {(assessment.includeWebsiteRisks || assessment.includeWaivers) && (
          <div className={"top-section"}>
            {assessment.includeWebsiteRisks && (
              <EvidenceUsedSection
                title={`Automated Scanning`}
                onClick={onOpenDomains}
              >
                <div className={"scanning-inner"}>
                  {`${
                    (evidence.includedHostnames?.length ??
                      evidence.numScanningHosts) + "/"
                  }${evidence.numScanningHosts} Domains and IPs`}
                  <div className={"right-section"}>
                    {evidence.numScanningRisks} risks
                  </div>
                </div>
                <IconButton
                  icon={<i className={"cr-icon-chevron"} />}
                  onClick={onOpenDomains}
                />
              </EvidenceUsedSection>
            )}
            {assessment.includeWaivers && (
              <EvidenceUsedSection
                title={"Risk Modifications"}
                onClick={goToWaivers}
              >
                {waiverText}
                {numRiskWaivers + unapprovedCount + pendingPublicCount > 0 && (
                  <IconButton
                    icon={<i className={"cr-icon-chevron"} />}
                    onClick={goToWaivers}
                  />
                )}
              </EvidenceUsedSection>
            )}
          </div>
        )}
        {numSurveys > 0 && (
          <EvidenceUsedSection title={`Questionnaires (${numSurveys})`}>
            <VendorAssessmentV3SurveysTable
              selectable={false}
              onClick={goToSurvey}
              surveys={surveys}
              orgHasSurveyScoresEnabled={orgHasSurveyScoresEnabled}
            />
          </EvidenceUsedSection>
        )}
        {numAdditional > 0 && (
          <EvidenceUsedSection title={`Additional Evidence (${numAdditional})`}>
            <VendorAssessmentV3AdditionalEvidenceTable
              selectable={false}
              onClick={goToAdditionalEvidence}
              evidence={additionalEvidence}
            />
          </EvidenceUsedSection>
        )}
        {evidence.pages && numPages > 0 && (
          <EvidenceUsedSection
            title={`Security and Privacy Pages (${numPages})`}
          >
            <VendorAssessmentV3PagesTable
              selectable={false}
              onClick={goToEvidencePage}
              pages={trustPages}
            />
          </EvidenceUsedSection>
        )}
      </div>
    </ReportCard>
  );
};

export default memo(VendorAssessmentV3EvidenceUsedCard);

import CircledIcon from "../../_common/components/CircledIcon";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import SeverityIcon from "../../_common/components/SeverityIcon";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import { IXTableRow, XTableCell } from "../../_common/components/core/XTable";
import PillLabel from "../../vendorrisk/components/PillLabel";
import {
  BreachRiskUserName,
  DisplayableThreatMonitoringResult,
  DisplayableThreatMonitoringResultStatus,
  DisplayableThreatMonitoringResultStatusToLabel,
  ThreatMonitoringFeedType,
  ThreatMonitoringResultState,
} from "../api/types";
import { useRWUserMap } from "../funcs/useUserMap";
import { SYSTEM_USER_ID } from "../funcs/domain";
import { useState } from "react";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import FeedWithTable, {
  FeedTableEllipsisedText,
  GetRowsProps,
} from "./FeedWithTable";
import useUpdateResultState from "../funcs/useUpdateResultState";
import { IUserMini } from "../../_common/types/user";
import "./ClosedFeed.scss";
import {
  useNavToRemediationRequest,
  useNavToThreatDetails,
} from "../funcs/useNav";
import { TMUserAndAvatar } from "./Users";
import { FeedDate } from "./ResultDate";
import { Module } from "../Slice";

export interface ClosedFeedProps {
  hidden: boolean;
  module: Module;
}

export default function ClosedFeed({ hidden, module }: ClosedFeedProps) {
  const navigateToThreatDetails = useNavToThreatDetails(module, {
    backToText: "Back to Threat Monitoring",
  });

  const [moving, setMoving] = useState<string | null>(null);

  const [investigating] = useUpdateResultState({
    sourceFeedType: ThreatMonitoringFeedType.Closed,
    state: ThreatMonitoringResultState.Investigating,
    successMsg: "Threat was moved to investigating successfully.",
    failMsg: "Failed to move threat to investigating.",
    module,
  });

  const moveToInvestigating = async () => {
    if (!moving) return;

    try {
      await investigating(moving);
      setMoving(null);
    } catch (e) {
      console.error(e);
    }
  };

  // Note that a sortable column id must be a valid ThreatMonitoringSortBy in threatmonitoring/db_results_filtering.go
  const columns = [
    { id: "severity", text: "Sev.", sortable: true, className: "severity" },
    { id: "title", text: "Threat name", sortable: true, className: "title" },
    {
      id: "date",
      text: "Date detected",
      sortable: true,
      className: "date",
    },
    {
      id: "date_closed",
      text: "Date closed",
      sortable: true,
      className: "date",
    },
    {
      id: "status",
      text: "Status",
      sortable: true,
      className: "status",
    },
    {
      id: "actor",
      text: "Closed by",
      sortable: true,
      className: "actor",
    },
    { id: "actions", text: "Actions", sortable: false, className: "actions" },
  ];

  const getRows = (
    feed: DisplayableThreatMonitoringResult[],
    { actors }: GetRowsProps
  ): IXTableRow<string>[] => {
    return feed.map((result) => {
      const closedAt = result.remediatedAt
        ? result.remediatedAt
        : result.dismissedAt;

      const cells = [
        <XTableCell key={"severity"} className={"severity-cell"}>
          <SeverityIcon severity={result.severity} />
        </XTableCell>,
        <XTableCell key="title" className="title-cell">
          <FeedTableEllipsisedText text={result.title}>
            {result.title}
          </FeedTableEllipsisedText>
        </XTableCell>,
        <XTableCell key={"date"} className={"date-cell"}>
          <FeedDate date={result.dateDetected} />
        </XTableCell>,
        <XTableCell key={"date_closed"} className={"date-cell"}>
          <FeedDate date={closedAt} />
        </XTableCell>,
        <XTableCell key={"closed_status"} className={"status-cell"}>
          <StatusPill result={result} />
        </XTableCell>,
        <XTableCell key="actor" className="actor-cell">
          <User actorId={result.actorId} actors={actors} />
        </XTableCell>,
        <XTableCell key="actions" className="action-cell">
          <div className={"actions"}>
            {result.status ===
              DisplayableThreatMonitoringResultStatus.Dismissed ||
            result.status ===
              DisplayableThreatMonitoringResultStatus.SmartFiltered ||
            (result.remediatedAt && !result.remediationRequestID) ? (
              <SidePopupV2
                text={"Reopen to investigating"}
                position={"top"}
                width={120}
              >
                {moving === result.uuid ? (
                  <LoadingIcon size={16} />
                ) : (
                  <div
                    className={"cr-icon-undo1 hover-border"}
                    onClick={() => {
                      setMoving(result.uuid);
                    }}
                  />
                )}
              </SidePopupV2>
            ) : (
              <div className={"empty-action"} />
            )}
            <SidePopupV2
              text={"View threat details"}
              position={"top"}
              width={103}
            >
              <div className={"chevron-container hover-border"}>
                <div
                  className={"cr-icon-chevron"}
                  onClick={navigateToThreatDetails(result.uuid)}
                />
              </div>
            </SidePopupV2>
          </div>
        </XTableCell>,
      ];
      return {
        id: result.uuid,
        cells: cells,
      };
    });
  };

  return (
    <>
      <FeedWithTable
        module={module}
        className="closed-feed"
        hidden={hidden}
        feedType={ThreatMonitoringFeedType.Closed}
        columns={columns}
        getRows={getRows}
        feedFailedMessage="Failed to read closed threats"
        feedEmptyMessage="There are either no closed threats, or no threats matching your current filter settings."
        filterPanelDateFilterTitle="Date closed"
      />
      <ConfirmationModalV2
        title={`Reopen threat?`}
        description={`This will move it back to investigating, and assign you as the Investigator.`}
        width={600}
        buttonText={`Reopen threat`}
        iconClass={"confirm-reopen-threat-modal cr-icon-undo1"}
        cancelText="Cancel"
        buttonAction={async () => {
          moveToInvestigating();
        }}
        active={!!moving}
        onClose={() => {
          setMoving(null);
        }}
      />
    </>
  );
}

// StatusPill displays the status of a threat monitoring result.
// Depending on state, the pill may act as a link to a remediation request.
function StatusPill({ result }: { result: DisplayableThreatMonitoringResult }) {
  const navToRemediationRequest = useNavToRemediationRequest({
    backToText: "Back to Threat Detail",

    useGoBack: true,
  });

  const { value: closedStatus, color: colour } =
    DisplayableThreatMonitoringResultStatusToLabel(result.status);

  if (result.remediationRequestID) {
    return (
      <PillLabel
        color={colour}
        onClick={navToRemediationRequest(result.remediationRequestID)}
      >
        {closedStatus} <i className="cr-icon-chevron" />
      </PillLabel>
    );
  }

  return <PillLabel color={colour}>{closedStatus}</PillLabel>;
}

// User displays a user's avatar and name.
// actorId === SYSTEM_USER_ID is displayed specially.
// If the actorId isn't found in the userMap+actors, the component is empty.
function User({ actorId, actors }: { actorId?: number; actors?: IUserMini[] }) {
  const { userMap } = useRWUserMap(actors);

  if (actorId === SYSTEM_USER_ID) {
    return (
      <div className={"system-avatar"}>
        <CircledIcon iconClass="cr-icon-upguard-logo-pick upguard-logo" />
        {BreachRiskUserName}
      </div>
    );
  }

  if (!userMap) {
    return <LoadingIcon size={16} />;
  }

  const actor = userMap[actorId ?? 0];

  if (!actor) {
    return <></>;
  }

  return <TMUserAndAvatar avatar={actor.avatar} name={actor.name} />;
}

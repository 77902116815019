import { FC, MutableRefObject, useMemo } from "react";
import ReportCard from "../../_common/components/ReportCard";
import "../styles/DocumentHistoryCard.scss";
import ContentLibraryAPI from "../api/contentLibraryAPI";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import moment from "moment/moment";
import { UserAvatarAndName } from "../../_common/components/UserAvatar";
import { usePagination } from "../../_common/hooks";
import { Link } from "react-router-dom";
import {
  useBasicPermissions,
  UserAccessPrefilledSurveys,
} from "../../_common/permissions";

const pageSize = 10;

interface IDocumentHistoryCardProps {
  uuid: string;
  isDeleted?: MutableRefObject<boolean>;
}

const linkBackContext = {
  backContext: {
    goBack: true,
    backToText: "Back to Content Library",
  },
};

const DocumentHistoryCard: FC<IDocumentHistoryCardProps> = ({
  uuid,
  isDeleted,
}) => {
  const perms = useBasicPermissions();
  const canReadSharedProfile =
    !!perms.userPermissions[UserAccessPrefilledSurveys];

  const { data, isFetching } =
    ContentLibraryAPI.useGetContentLibraryDocumentHistoryQuery(
      {
        uuid,
      },
      {
        skip: isDeleted?.current,
      }
    );

  const columnHeaders: IXTableColumnHeader[] = useMemo(
    () => [
      {
        id: "date",
        text: "Date / time",
        sortable: false,
      },
      {
        id: "user",
        text: "User",
        sortable: false,
      },
      {
        id: "description",
        text: "Description",
        sortable: false,
      },
    ],
    []
  );

  const [pagedItems, currentPage, totalPages, onPageChange] = usePagination(
    data ? data.history : [],
    pageSize
  );

  const rows: IXTableRow[] = useMemo(
    () =>
      data
        ? pagedItems.map((item) => {
            const sharedUser = data.sharedUsers[item.createdBy];

            let description;
            switch (item.historyType) {
              case "DocumentCreated":
                description = <>Created document</>;
                break;
              case "VersionAdded":
                description = <>Uploaded version {item.historyData.version}</>;
                break;
              case "DocumentUpdated":
                description = (
                  <table className="updated-fields">
                    <tbody>
                      {item.historyData.updatedFields.map((field) => {
                        switch (field.fieldName.toLowerCase()) {
                          case "archived":
                            return (
                              <tr key={field.fieldName}>
                                <td>
                                  {field.newValue === true
                                    ? "Set to archived"
                                    : "Set to unarchived"}
                                </td>
                                <td></td>
                              </tr>
                            );
                          case "expiry date":
                            return (
                              <tr key={field.fieldName}>
                                <td>Updated {field.fieldName.toLowerCase()}</td>
                                <td>
                                  <em>
                                    {field.newValue
                                      ? moment(field.newValue).format("ll")
                                      : "No expiry"}
                                  </em>
                                </td>
                              </tr>
                            );
                          default:
                            return (
                              <tr key={field.fieldName}>
                                <td>Updated {field.fieldName.toLowerCase()}</td>
                                <td title={field.newValue}>
                                  <em>
                                    {typeof field.newValue === "boolean"
                                      ? field.newValue.toString()
                                      : field.newValue}
                                  </em>
                                </td>
                              </tr>
                            );
                        }
                      })}
                    </tbody>
                  </table>
                );
                break;
              case "DocumentUsedInSurvey":
                if (item.historyData.isPublic) {
                  if (canReadSharedProfile) {
                    description = (
                      <>
                        Attached to{" "}
                        <Link
                          to={{
                            pathname: `/trustpage/surveys/${item.historyData.surveyID}/answers`,
                            search: `?public=true&scroll_to=${item.historyData.questionID}`,
                            state: linkBackContext,
                          }}
                        >
                          questionnaire
                        </Link>{" "}
                        in{" "}
                        <Link
                          to={{
                            pathname: `/trustpage`,
                            state: linkBackContext,
                          }}
                        >
                          Trust Page
                        </Link>
                      </>
                    );
                  } else {
                    description = <>Attached to questionnaire in Trust Page</>;
                  }
                } else {
                  if (data.accessibleSurveyIDs[item.historyData.surveyID]) {
                    description = (
                      <>
                        Attached to{" "}
                        <Link
                          to={{
                            pathname: `/vendors/surveys/${item.historyData.surveyID}/answers`,
                            search: `?version=${item.historyData.answerID}&scroll_to=${item.historyData.questionID}`,
                            state: linkBackContext,
                          }}
                        >
                          questionnaire
                        </Link>
                      </>
                    );
                  } else {
                    description = <>Attached to questionnaire</>;
                  }
                }
                break;
              case "DocumentUsedInEvidence":
                description = (
                  <>
                    {item.historyData.attached ? "Attached to" : "Removed from"}{" "}
                    <Link
                      to={{
                        pathname: `/vendors/additionalevidence/${item.historyData.evidenceID}`,
                        state: linkBackContext,
                      }}
                    >
                      document request
                    </Link>
                  </>
                );
                break;
              case "DocumentIncludedInSharedProfile":
                if (item.historyData.included) {
                  description = "Included in Trust Page";
                } else {
                  description = "Removed from Trust Page";
                }
                break;
              case "MigratedFromSharedProfile":
                description =
                  "This document was automatically migrated from the Trust Page";
                break;
              case "MigratedFromQuestionnaire":
                if (data.accessibleSurveyIDs[item.historyData.surveyID]) {
                  description = (
                    <>
                      This document was automatically migrated from a{" "}
                      <Link
                        to={{
                          pathname: `/vendors/surveys/${item.historyData.surveyID}`,
                          state: linkBackContext,
                        }}
                      >
                        questionnaire
                      </Link>
                    </>
                  );
                } else {
                  description = (
                    <>
                      This document was automatically migrated from a
                      questionnaire
                    </>
                  );
                }
                break;
            }

            return {
              id: item.id,
              cells: [
                <XTableCell key="date" className="date-cell">
                  {moment(item.createdAt).format("lll")}
                </XTableCell>,
                <XTableCell key="user" className="user-cell">
                  {sharedUser ? (
                    <UserAvatarAndName
                      large
                      name={sharedUser.name}
                      avatar={sharedUser.avatar}
                    />
                  ) : undefined}
                </XTableCell>,
                <XTableCell key="description">{description}</XTableCell>,
              ],
            };
          })
        : [],
    [data, pagedItems, canReadSharedProfile]
  );

  return (
    <ReportCard newStyles className="clibrary-document-history">
      <div className="header">History</div>
      <div>
        <XTable
          className="doc-history-table"
          rows={rows}
          loading={isFetching}
          columnHeaders={columnHeaders}
          pagination={{
            currentPage,
            totalPages,
            onPageChange,
            hidePaginationIfSinglePage: true,
          }}
        />
      </div>
    </ReportCard>
  );
};

export default DocumentHistoryCard;

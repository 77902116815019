import { useCurrentOrg } from "../../selectors/commonSelectors";
import { useAppSelector } from "../../types/reduxHooks";
import NavLink, { NewFeatureInAppMessagePopupContent } from "../NavLink";
import { InAppMessageType } from "../InAppMessage";
import { pageDescriptions } from "../../pageDescriptions";
import * as Permissions from "../../permissions";
import { getUserTrustExchangeSections } from "../../helpers/navigation.helpers";
import { ReactNode } from "react";

type TrustExchangeNavItems = {
  clickNavLinkFunc: (
    path: string,
    linkName: string,
    viewingVendorId?: string
  ) => () => void;
};

const TrustExchangeNavItems = (props: TrustExchangeNavItems) => {
  const currentOrg = useCurrentOrg();
  const userData = useAppSelector((state) => state.common.userData);

  const hasUserPermission = (perm: string) =>
    userData.userPermissions.includes(perm);

  const trustExchangeNavItems = getUserTrustExchangeSections(
    userData,
    currentOrg
  );

  const showMySharedProfile = trustExchangeNavItems.includes("trust_page");
  const showContentLibrary = trustExchangeNavItems.includes("content_library");

  const taskRoutes: ReactNode[] = [];

  if (trustExchangeNavItems.includes("questionnaires")) {
    taskRoutes.push(
      <NavLink
        className="unstyled"
        text="Answer Questionnaires"
        onClick={props.clickNavLinkFunc(
          "/vendors/surveys",
          "Answer Questionnaires"
        )}
        to="/vendors/surveys"
        inAppMessageType={
          userData.currentOrgID !== 0 &&
          hasUserPermission(Permissions.UserReadSurveyImportExport)
            ? InAppMessageType.ImportSurveysNewFeature
            : undefined
        }
        inAppMessageText={
          userData.currentOrgID !== 0 &&
          hasUserPermission(Permissions.UserReadSurveyImportExport) ? (
            <NewFeatureInAppMessagePopupContent
              featureName="Import Excel questionnaires"
              description="Been sent an Excel questionnaire? Use our AI Autofill tools to complete and export it in no time."
              learnMoreLink="https://help.upguard.com/en/articles/9034980-how-to-import-and-complete-security-questionnaires"
            />
          ) : undefined
        }
      />
    );
  }

  if (trustExchangeNavItems.includes("additional_evidence_requests")) {
    taskRoutes.push(
      <NavLink
        className="unstyled"
        text="Document Requests"
        onClick={props.clickNavLinkFunc(
          "/vendors/additionalevidence",
          "Document Requests"
        )}
        to="/vendors/additionalevidence"
      />
    );
  }

  if (trustExchangeNavItems.includes("remediation_requests")) {
    taskRoutes.push(
      <NavLink
        className="unstyled"
        text="Remediation Requests"
        onClick={props.clickNavLinkFunc(
          "/vendors/remediation",
          "Remediation Requests"
        )}
        to="/vendors/remediation"
      />
    );
  }

  if (trustExchangeNavItems.includes("risk_waiver_approvals")) {
    taskRoutes.push(
      <NavLink
        className="unstyled"
        text="Risk Waiver Approvals"
        onClick={props.clickNavLinkFunc(
          "/vendors/riskwaiverapprovals",
          "Risk Waiver Approvals"
        )}
        to="/vendors/riskwaiverapprovals"
      />
    );
  }

  if (trustExchangeNavItems.includes("risk_adjustment_approvals")) {
    taskRoutes.push(
      <NavLink
        className="unstyled"
        text="Risk Adjustment Approvals"
        onClick={props.clickNavLinkFunc(
          "/vendors/riskadjustmentapprovals",
          "Risk Adjustment Approvals"
        )}
        to="/vendors/riskadjustmentapprovals"
      />
    );
  }

  return (
    <>
      <div className="product-name">Trust Exchange</div>
      <div className="product-navigation-items">
        {trustExchangeNavItems.length > 0 ? (
          <>
            {showContentLibrary && (
              <NavLink
                className="unstyled"
                text="Content Library"
                onClick={props.clickNavLinkFunc(
                  "/contentlibrary",
                  "Content Library"
                )}
                to="/contentlibrary"
                inAppMessageText={
                  <NewFeatureInAppMessagePopupContent
                    featureName="Content Library"
                    description="Easily manage documents in one central location and build a repository of content to power AI autofill."
                    learnMoreLink={
                      "https://help.upguard.com/en/articles/8973526-what-is-the-content-library"
                    }
                  />
                }
                inAppMessageType={InAppMessageType.ContentLibraryNewFeature}
              />
            )}
            {showMySharedProfile && (
              <NavLink
                className="unstyled"
                inAppMessageText={
                  <NewFeatureInAppMessagePopupContent
                    featureName="Security and Privacy Pages"
                    description="You can now update security and privacy pages on your Trust Page."
                    learnMoreLink={
                      "https://help.upguard.com/en/articles/7051467-how-to-use-security-and-privacy-pages-in-vendor-risk"
                    }
                  />
                }
                inAppMessageType={InAppMessageType.VendorEvidenceNewFeature}
                text="Trust Page"
                hoverText={pageDescriptions.MySharedAssessment}
                onClick={props.clickNavLinkFunc("/trustpage", "Trust Page")}
                to="/trustpage"
              />
            )}
            <>
              {(showContentLibrary || showMySharedProfile) && (
                <div className="section-divider">
                  <hr />
                </div>
              )}
              <div className="section-header">
                <span className="section-header-text">Workflows</span>
              </div>
            </>
            {taskRoutes}
          </>
        ) : null}
      </div>
    </>
  );
};

export default TrustExchangeNavItems;

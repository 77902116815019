import {
  useCurrentOrg,
  useCurrentUser,
  useCurrentUserTasks,
} from "../../selectors/commonSelectors";
import NavLink, { NewFeatureInAppMessagePopupContent } from "../NavLink";
import { InAppMessageType } from "../InAppMessage";
import { pageDescriptions } from "../../pageDescriptions";
import {
  getUserTrustExchangeSections,
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
} from "../../helpers/navigation.helpers";
import { ReactNode } from "react";
import { matchPath } from "react-router-dom";

type TrustExchangeNavItems = {
  clickNavLinkFunc: (
    path: string,
    linkName: string,
    viewingVendorId?: string
  ) => () => void;
};

const TrustExchangeNavItems = (props: TrustExchangeNavItems) => {
  const currentOrg = useCurrentOrg();
  const userData = useCurrentUser();
  const userTaskData = useCurrentUserTasks();

  const trustExchangeNavItems = getUserTrustExchangeSections(
    userData,
    currentOrg,
    userTaskData
  );

  const showMySharedProfile = trustExchangeNavItems.includes("trust_page");
  const showContentLibrary = trustExchangeNavItems.includes("content_library");

  const taskRoutes: ReactNode[] = [];

  const routePrefix = `${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}`;

  if (trustExchangeNavItems.includes("questionnaires")) {
    taskRoutes.push(
      <NavLink
        key="questionnaires"
        className="unstyled"
        text="Answer Questionnaires"
        onClick={props.clickNavLinkFunc(
          `/${routePrefix}/questionnaires`,
          "Answer Questionnaires"
        )}
        to={`/${routePrefix}/questionnaires`}
        isActive={(_, location) => {
          return (
            !!matchPath(location.pathname, {
              path: `/${routePrefix}/questionnaires`,
            }) ||
            !!matchPath(location.pathname, {
              path: "/vendors/surveys",
            })
          );
        }}
      />
    );
  }

  if (trustExchangeNavItems.includes("additional_evidence_requests")) {
    taskRoutes.push(
      <NavLink
        key="additional_evidence"
        className="unstyled"
        text="Document Requests"
        onClick={props.clickNavLinkFunc(
          `/${routePrefix}/additionalevidence`,
          "Document Requests"
        )}
        to={`/${routePrefix}/additionalevidence`}
      />
    );
  }

  if (trustExchangeNavItems.includes("remediation_requests")) {
    taskRoutes.push(
      <NavLink
        key="remediation_requests"
        className="unstyled"
        text="Remediation Requests"
        onClick={props.clickNavLinkFunc(
          `/${routePrefix}/remediation`,
          "Remediation Requests"
        )}
        to={`/${routePrefix}/remediation`}
      />
    );
  }

  return (
    <>
      <div className="product-name">Trust Exchange</div>
      <div className="product-navigation-items">
        {trustExchangeNavItems.length > 0 ? (
          <>
            {showContentLibrary && (
              <NavLink
                className="unstyled"
                text="Content Library"
                onClick={props.clickNavLinkFunc(
                  "/contentlibrary",
                  "Content Library"
                )}
                to="/contentlibrary"
              />
            )}
            {showMySharedProfile && (
              <NavLink
                className="unstyled"
                inAppMessageText={
                  <NewFeatureInAppMessagePopupContent
                    featureName="Trust Page Custom Domain"
                    description="Personalize your Trust Page with a custom domain."
                    learnMoreLink={
                      "https://help.upguard.com/en/articles/3797568-what-is-an-upguard-trust-page"
                    }
                  />
                }
                inAppMessageType={InAppMessageType.TrustPageCustomDomainFeature}
                text="Trust Page"
                hoverText={pageDescriptions.MySharedAssessment}
                onClick={props.clickNavLinkFunc("/trustpage", "Trust Page")}
                to="/trustpage"
              />
            )}
            {taskRoutes?.length > 0 && (
              <>
                {(showContentLibrary || showMySharedProfile) && (
                  <div className="section-divider">
                    <hr />
                  </div>
                )}
                <div className="section-header">
                  <span className="section-header-text">Tasks</span>
                </div>
              </>
            )}
            {taskRoutes}
          </>
        ) : null}
      </div>
    </>
  );
};

export default TrustExchangeNavItems;

import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { ScopeRiskLevel } from "../../api/types";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";
import {
  BasicRiskLevelString,
  ElevatedRiskLevelString,
  isElevatedRiskLevel,
} from "../../helpers/scopes";

interface ActiveRiskLevelFilter {
  minRiskLevel: ScopeRiskLevel;
  maxRiskLevel: ScopeRiskLevel;
}

interface InactiveRiskLevelFilter {
  minRiskLevel: undefined;
  maxRiskLevel: undefined;
}

type RiskLevelFilter = ActiveRiskLevelFilter | InactiveRiskLevelFilter;

interface UserRiskExposureLevelFilterProps {
  filter: RiskLevelFilter;
  onChange: (filter: RiskLevelFilter) => void;
}

const UserRiskExposureLevelFilter = ({
  filter,
  onChange,
}: UserRiskExposureLevelFilterProps) => {
  const basicChecked =
    !!filter.minRiskLevel && !isElevatedRiskLevel(filter.minRiskLevel);
  const elevatedChecked =
    !!filter.maxRiskLevel && isElevatedRiskLevel(filter.maxRiskLevel);

  const onBasicClick = () => {
    const newBasicChecked = !basicChecked;
    const newElevatedChecked = elevatedChecked;
    onCheckChange(newBasicChecked, newElevatedChecked);
  };

  const onElevatedClick = () => {
    const newElevatedChecked = !elevatedChecked;
    const newBasicChecked = basicChecked;
    onCheckChange(newBasicChecked, newElevatedChecked);
  };

  const onCheckChange = (
    newBasicChecked: boolean,
    newElevatedChecked: boolean
  ) => {
    const newIsActive = newBasicChecked || newElevatedChecked;
    if (!newIsActive) {
      onChange({
        minRiskLevel: undefined,
        maxRiskLevel: undefined,
      });
      return;
    }

    const newMinRiskLevel = newBasicChecked
      ? ScopeRiskLevel.LowScopeRiskLevel
      : ScopeRiskLevel.MediumScopeRiskLevel;
    const newMaxRiskLevel = newElevatedChecked
      ? ScopeRiskLevel.HighScopeRiskLevel
      : ScopeRiskLevel.MediumLowScopeRiskLevel;
    onChange({
      minRiskLevel: newMinRiskLevel,
      maxRiskLevel: newMaxRiskLevel,
    });
  };

  return (
    <SlidePanelSection title={"Exposure level"}>
      <ColorCheckbox
        checked={basicChecked}
        label={<>{BasicRiskLevelString}</>}
        onClick={onBasicClick}
      />
      <ColorCheckbox
        checked={elevatedChecked}
        label={<>{ElevatedRiskLevelString}</>}
        onClick={onElevatedClick}
      />
    </SlidePanelSection>
  );
};

export default withRequireEntitlementAndPermission(
  UserRiskExposureLevelFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
